import { isNull, isUndefined } from 'lodash'
import * as React from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Icon,
  IconButton,
  Input,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Tooltip,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'

import { NameAndValue } from '../admin/mapdata/types'
import DatePickerYMD from '../admin/mapdata/utils/DatePickerYMD'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import * as notes from './redux'
import {
  refreshListNotes,
  selectAllPinColorsByTemplateNames,
  selectFilteredNotes,
  selectProjectsById,
} from './selectors'
import { NotesFilterInfo } from './types'
import { useDebounce } from '../hooks/useDebounce'
import { Dayjs } from 'dayjs'
import { ExportProjectsDialog } from '../projects/export/ExportProjectsDialog'

const CompactButton = styled(Button)({
  lineHeight: '25.2px',
  fontSize: '13px',
  py: 0.5,
})

interface Props {
  style?: React.CSSProperties
}

export const NotesFilter = ({ style }: Props) => {
  const [state, dispatch] = useRedux()

  const projectsById = selectProjectsById(state)

  const [showExportDialog, setShowExportDialog] = React.useState(false)
  const [isFilterActive, setIsFilterActive] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const filter = state.notes.filterInfo
  const filteredNoteIds = selectFilteredNotes(state)

  const pinColorByTemplateNames = selectAllPinColorsByTemplateNames(state)

  const showActiveFilter = React.useMemo(() => {
    const { formType, searchTerm, fromDate, toDate } = filter

    const isShowActiveIcon =
      !isUndefined(formType) ||
      searchTerm !== '' ||
      (!isUndefined(fromDate) && !isNull(fromDate)) ||
      (!isUndefined(toDate) && !isNull(toDate))

    setIsFilterActive(isShowActiveIcon)

    return isShowActiveIcon
  }, [filter, setIsFilterActive])

  const changeProject = (event: SelectChangeEvent<string>) => {
    if (Number(event.target.value) === -1) {
      const newFilter: NotesFilterInfo = {
        ...filter,
        projectType: undefined,
      }
      dispatch(notes.actions.setFilterInfo(newFilter))
      return
    }

    const projectId = String(event.target.value)

    const newFilter: NotesFilterInfo = {
      ...filter,
      projectType: projectId,
    }
    dispatch(notes.actions.setFilterInfo(newFilter))
  }

  const changeForm = (event: SelectChangeEvent<string>) => {
    if (Number(event.target.value) === -1) {
      const newFilter: NotesFilterInfo = {
        ...filter,
        formType: undefined,
      }
      dispatch(notes.actions.setFilterInfo(newFilter))
      return
    }

    const templateName = String(event.target.value)

    const newFilter: NotesFilterInfo = {
      ...filter,
      formType: templateName,
    }
    dispatch(notes.actions.setFilterInfo(newFilter))
  }

  const handleClear = () => {
    const filter: NotesFilterInfo = {
      searchTerm: '',
    }
    dispatch(notes.actions.setFilterInfo(filter))
  }

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchTerm = event.target.value
    const newFilter: NotesFilterInfo = {
      ...filter,
      searchTerm,
    }
    dispatch(notes.actions.setFilterInfo(newFilter))
  }

  const handleFromDateChange = ({ value }: NameAndValue) => {
    const fromValue = (value as Dayjs | null)?.toDate()
    const toValue = filter.toDate
    if (!fromValue) {
      handleDateChange(fromValue === null ? undefined : fromValue, toValue)
      return
    }

    if ((toValue && fromValue > toValue) || !toValue) {
      handleDateChange(fromValue)
    } else {
      handleDateChange(fromValue, filter.toDate)
    }
  }

  const handleToDateChange = ({ value }: NameAndValue) => {
    const toValue = (value as Dayjs | null)
      ?.add(1, 'day')
      .subtract(1, 'second')
      .toDate()
    const fromValue = filter.fromDate

    if (!toValue) {
      handleDateChange(fromValue, toValue === null ? undefined : toValue)
      return
    }

    if (fromValue && toValue < fromValue) {
      handleDateChange(undefined, toValue)
    } else {
      handleDateChange(filter.fromDate, toValue)
    }
  }

  const handleDateChange = (fromDate?: Date, toDate?: Date) => {
    const newFilter: NotesFilterInfo = {
      ...filter,
      fromDate,
      toDate,
    }
    dispatch(notes.actions.setFilterInfo(newFilter))
  }

  const refreshNoteList = () => {
    refreshListNotes()
  }

  const { fromDate, toDate } = filter

  const onSearchTermChanged = useDebounce(handleSearchTermChange, 500)

  return (
    <Stack alignItems="stretch" sx={{ my: 1, px: 1 }} style={style}>
      <Card sx={{ mx: 1 }} elevation={0}>
        <CardContent sx={{ p: 0.5 }}>
          <Stack spacing={1} sx={{ px: 2, pt: 2 }}>
            <Input
              defaultValue={filter.searchTerm}
              onChange={(e) => onSearchTermChanged(e)}
              placeholder={i18n.t(keys.notes.filter.search)}
              endAdornment={
                <Stack direction="row" spacing={1}>
                  <Tooltip title={i18n.t(keys.generic.refresh)}>
                    <IconButton onClick={() => refreshNoteList()} size="small">
                      <Icon>refresh</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={i18n.t(keys.legend.filterTooltip)}>
                    <IconButton onClick={() => setOpen(!open)} size="small">
                      <Icon color={showActiveFilter ? 'secondary' : 'inherit'}>
                        filter_list
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
              fullWidth
            />

            <Collapse in={open}>
              <Stack spacing={1}>
                <FormControl variant="standard">
                  <Select
                    value={filter.projectType ?? -1}
                    onChange={changeProject}
                    fullWidth
                    renderValue={() =>
                      filter.projectType ? (
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          sx={{ paddingLeft: 0.5 }}
                        >
                          <ListItemText
                            primary={projectsById[filter.projectType]?.name}
                          />
                        </Stack>
                      ) : (
                        <ListItemText
                          primary={i18n.t(keys.notes.filter.allProjects)}
                        />
                      )
                    }
                  >
                    <MenuItem value={-1}>
                      {i18n.t(keys.notes.filter.allProjects)}
                    </MenuItem>
                    {Object.entries(projectsById).map(
                      ([projectId, project]) => (
                        <MenuItem key={projectId} value={project.id}>
                          <ListItemText primary={project.name} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <FormControl variant="standard">
                  <Select
                    value={filter.formType ?? -1}
                    onChange={changeForm}
                    fullWidth
                    renderValue={() =>
                      filter.formType ? (
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          sx={{ paddingLeft: 0.5 }}
                        >
                          <Box
                            sx={{
                              height: 12,
                              width: 12,
                              borderRadius: 12,
                              background: `${pinColorByTemplateNames.get(
                                filter.formType
                              )}`,
                            }}
                          />
                          <ListItemText
                            primary={i18n.t(
                              `noteForm.reservedNames.${filter.formType}`,
                              {
                                defaultValue: filter.formType,
                              }
                            )}
                          />
                        </Stack>
                      ) : (
                        <ListItemText
                          primary={i18n.t(keys.notes.filter.allTemplates)}
                        />
                      )
                    }
                  >
                    <MenuItem value={-1}>
                      {i18n.t(keys.notes.filter.allTemplates)}
                    </MenuItem>
                    {Array.from(pinColorByTemplateNames.keys()).map(
                      (templateName) => (
                        <MenuItem key={templateName} value={templateName}>
                          <ListItemIcon>
                            <Box
                              sx={{
                                height: 12,
                                width: 12,
                                borderRadius: 12,
                                background: `${pinColorByTemplateNames.get(
                                  templateName
                                )}`,
                              }}
                            />
                          </ListItemIcon>

                          <ListItemText
                            primary={i18n.t(
                              `noteForm.reservedNames.${templateName}`,
                              {
                                defaultValue: templateName,
                              }
                            )}
                          />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <DatePickerYMD
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  disableFuture
                  sx={{ width: '100%' }}
                  placeholder={i18n.t(keys.notes.filter.fromDate)}
                  name="fromDate"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  begin="start-of-day"
                />

                <DatePickerYMD
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  disableFuture
                  sx={{ width: '100%' }}
                  placeholder={i18n.t(keys.notes.filter.toDate)}
                  name="fromDate"
                  value={toDate}
                  onChange={handleToDateChange}
                  begin="end-of-day"
                />
              </Stack>
            </Collapse>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="stretch"
              sx={{ width: '100%' }}
            >
              <CompactButton
                size="small"
                disabled={filteredNoteIds.length === 0}
                variant="outlined"
                fullWidth
                onClick={() => setShowExportDialog(true)}
              >
                {i18n.t(keys.notes.export)}
              </CompactButton>
              {!!isFilterActive && (
                <CompactButton
                  size="small"
                  variant="outlined"
                  fullWidth
                  onClick={handleClear}
                >
                  {i18n.t(keys.legend.clearFilter)}
                </CompactButton>
              )}
            </Stack>

            <ExportProjectsDialog
              open={showExportDialog}
              onClose={() => setShowExportDialog(false)}
            />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
}
