import * as React from 'react'

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'

import { NameAndValue } from '../../../admin/mapdata/types'
import dayjs from 'dayjs'

interface Props
  extends Partial<Omit<DatePickerProps<Date>, 'format' | 'onChange' | 'min'>> {
  placeholder?: string
  name: string
  required?: boolean
  onChange(v: NameAndValue): void
}

class DatePickerYear extends React.PureComponent<Props> {
  render() {
    const {
      name,
      value,
      onChange,
      required,
      placeholder,
      minDate,
      maxDate,
      ...props
    } = this.props

    return (
      <DatePicker
        views={['year']}
        minDate={minDate ? dayjs(minDate) : null}
        maxDate={maxDate ? dayjs(maxDate) : null}
        slotProps={{ field: { clearable: true }, textField: { placeholder } }}
        {...props}
        value={value ? (dayjs(value) as any) : null}
        // dates must remain YMD to avoid timezone issues
        onChange={(date: dayjs.Dayjs) =>
          onChange({
            name,
            value: date.toDate(),
          })
        }
      />
    )
  }
}

export default DatePickerYear
