import publicAlert from '../UI/publicAlert'
import { APP_BAR_ELEMENT_ID } from '../app/Page'
import i18n, { keys } from '../i18n'
import { downloadURI } from './download'
import * as htmlToImage from 'html-to-image'

const generateMapCanvas = async (
  map: mapboxgl.Map,
  sideBarRightOpen: boolean,
  sideBarLeftOpen: boolean
) => {
  const newCanvas = document.createElement('canvas')
  let sideBarLeftWidth = 0
  if (sideBarLeftOpen) {
    sideBarLeftWidth +=
      document.getElementById('sidebar-left')?.offsetWidth ?? 0
  }
  let sideBarRightWidth = 0
  if (sideBarRightOpen) {
    sideBarRightWidth +=
      document.getElementById('sidebar-right')?.offsetWidth ?? 0
  }

  const appBar = document.getElementById(APP_BAR_ELEMENT_ID)
  const betaBanner = document.getElementById('beta-banner')
  const newContext = newCanvas.getContext('2d')
  if (!newContext) {
    throw new Error('Error creating image: cannot create drawing context')
  }
  const mapEl = document.getElementById('main-content')
  if (mapEl) {
    const canvas = await htmlToImage.toCanvas(mapEl, {
      filter: (el) => {
        if (el.id === 'user-avatar') {
          return false
        }
        if (el.className?.includes && el.className.includes('MapControl')) {
          return false
        }
        if (el.className?.includes && el.className.includes('MuiButton')) {
          return false
        }
        if (el.className?.includes && el.className.includes('MuiSelect-icon')) {
          return false
        }

        return true
      },
    })

    newCanvas.width = canvas.width
    newCanvas.width -= sideBarRightWidth * 2
    newCanvas.width -= sideBarLeftWidth * 2
    newCanvas.height = canvas.height

    const mapImg = await getMapImage(map)
    newContext.drawImage(
      mapImg,
      -(sideBarLeftWidth * 2),
      (appBar?.offsetHeight ? appBar.offsetHeight * 2 : 0) +
        (betaBanner?.offsetHeight ? betaBanner?.offsetHeight * 2 : 0)
    )

    newContext.drawImage(canvas, -(sideBarLeftWidth * 2), 0)

    return newCanvas
  }

  return null
}

export async function printMap(
  map: mapboxgl.Map,
  sideBarRightOpen: boolean,
  sideBarLeftOpen: boolean
) {
  const newCanvas = await generateMapCanvas(
    map,
    sideBarRightOpen,
    sideBarLeftOpen
  )

  if (newCanvas) {
    const dataUrl = newCanvas.toDataURL()
    const printWindow = window.open(
      '',
      'to_print',
      `height=${newCanvas.height},width=${newCanvas.width}`
    )
    if (
      !printWindow ||
      printWindow.closed ||
      typeof printWindow.closed == 'undefined'
    ) {
      publicAlert({
        error: new Error('Unable to print map'),
        title: i18n.t(keys.unableToPrintMapTitle),
        message: i18n.t(keys.unableToPrintMapMessage),
      })
    } else {
      printWindow.document.title = window.document.title
      printWindow.document.open()
      printWindow.document.write(
        `<html>
          <head>
            <style>
              body {
                margin: 0;
                font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
		            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
              }
            </style>
            <title>${window.document.title}</title>
          </head>
          <body>
            <img id="map-image" width="100%" src="${dataUrl}"/>
          </body>
        </html>`
      )
      printWindow.addEventListener('DOMContentLoaded', () => {
        const img = printWindow.document.getElementById('map-image')
        if (img) {
          img.onload = () => {
            printWindow.onafterprint = () => {
              printWindow.close()
            }
            printWindow.print()
          }
        }
      })
      printWindow.document.close()
    }
  }
}
export async function downloadMapAsPNG(
  map: mapboxgl.Map,
  sideBarRightOpen: boolean,
  sideBarLeftOpen: boolean
) {
  const newCanvas = await generateMapCanvas(
    map,
    sideBarRightOpen,
    sideBarLeftOpen
  )
  if (newCanvas) {
    const dataURL = newCanvas.toDataURL()
    const name = `${i18n.t(keys.map.map)}.png`
    downloadURI(dataURL, name)
  }
}

const getMapImage = (map: mapboxgl.Map) =>
  new Promise<HTMLImageElement>(async (resolve, reject) => {
    const mapImg = new Image()
    mapImg.onload = () => {
      resolve(mapImg)
    }
    mapImg.onerror = reject
    mapImg.src = await getMapDataURL(map)
  })

const getMapDataURL = (map: mapboxgl.Map): Promise<string> => {
  const canvas: HTMLCanvasElement = map.getCanvas()
  map.setBearing(map.getBearing())

  return new Promise((resolve, reject) => {
    map.once('render', () => {
      const dataURL = canvas.toDataURL()
      resolve(dataURL)
    })
  })
}
