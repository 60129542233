import { selectPagingInfo } from '../../appNavigation/urls'
import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import * as api from '../../graphql/api'
import { RootStore } from '../../redux/types'
import { GetDataSetData, getDataSetDataReturning } from './selectGetDataSet'

type ListDataSetData = GetDataSetData

const { selector: selectListDataSets, refresh: refreshListDataSets } =
  createAsyncSelector({
    resource: 'ListDataSets',
    inputs: {
      pagingInfo: selectPagingInfo,
      searchParams: (state: RootStore) => state.router.searchParams,
    },
    fetcher: async ({ pagingInfo: { page, pageSize }, searchParams }) => {
      const limit = pageSize
      const offset = page * pageSize

      const { notesFilter, packageFilter } = searchParams
      let where = undefined

      if (notesFilter) {
        where = { ...(where ?? {}), notes: { _ilike: `%${notesFilter}%` } }
      }

      if (packageFilter) {
        where = {
          ...(where ?? {}),
          DataSetFlights: {
            Flight: {
              Delivery: {
                TargetDelivery: {
                  TargetDelivery_Parcel_Packages: {
                    Package: { code: { _eq: packageFilter } },
                  },
                },
              },
            },
          },
        }
      }

      const result = await api.dataSet.list<ListDataSetData>({
        limit,
        offset,
        order_by: '{ id: desc }',
        where,
        returning: getDataSetDataReturning,
      })

      return result
    },
  })

export { ListDataSetData, selectListDataSets, refreshListDataSets }
