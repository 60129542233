import * as React from 'react'

import { Avatar, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'

import logoSvg from '../../../logo.svg'
import i18n, { keys } from '../../../i18n';


interface Props {
  productName: string,
  productDescription: string,
  productPrice: number,
  renewalDate: number | string,
  actionButton: React.ReactNode
}


export const SubscriptionCard = ({ productName, productDescription, productPrice, renewalDate, actionButton }: Props) => 
<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
  <CardHeader
    avatar={
      <Avatar variant="rounded" sx={{ width: 56, height: 56, backgroundColor: 'var(--green)'}} src={logoSvg} />
    }
    title={
      <Typography variant="h6">
        {productName}
      </Typography>
    }
  />
  <CardContent>
    <Typography>
      ${productPrice} {i18n.t(keys.productPriceDescription)}
    </Typography>
    <Typography variant="caption" display="block">
      {productDescription}
    </Typography>
    <Typography variant="caption" display="block">
      {i18n.t(keys.renewsOn)}: {i18n.toDateShort(renewalDate)}
    </Typography>
  </CardContent>
  <CardActions>
    {actionButton}
  </CardActions>
</Card>