import * as React from 'react'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useHistory } from 'react-router-dom'
import {
  DEFAULT_PAGE_SIZE,
  url,
  UrlInfo,
  urlMatch,
  urls,
} from '../../appNavigation/urls'

interface NavTab {
  label: string
  value: UrlInfo
}

const NAV: NavTab[] = [
  {
    label: 'Queue',
    value: urls.maestroQueue,
  },
  {
    label: 'Running',
    value: urls.maestroRunning,
  },
]

export const MaestroHomeNavigation = () => {
  const history = useHistory()

  const { pathname } = history.location

  const tab = NAV.find(({ value }) => urlMatch(value, pathname))

  if (!tab) {
    throw new Error(`bad uri ${pathname}`)
  }

  /**
   * Need to render a button to get around a dark-theme issue;
   * also need to pass onClick here because the button steals the
   * click event from the Tab component
   */
  const renderTab = ({
    label,
    value,
    disabled,
  }: {
    label: string
    value: UrlInfo
    disabled: boolean
  }) => {
    return (
      <Button
        disabled={disabled}
        onClick={() =>
          handleTabChange(
            url(value, {}, { page: 0, pageSize: DEFAULT_PAGE_SIZE })
          )
        }
      >
        {label}
      </Button>
    )
  }

  const handleTabChange = (value: string) => {
    history.push(value)
  }

  return (
    <Tabs value={tab.value}>
      {NAV.map(({ label, value }) => (
        <Tab
          key={label}
          value={value}
          component={() =>
            renderTab({
              label,
              value,
              disabled: tab.value === value,
            })
          }
        />
      ))}
    </Tabs>
  )
}
