import * as React from 'react'

import {
  Button,
  Icon,
  IconButton,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/styles'

import { MapSourcePropertyData } from '../../data/types'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import { selectSelectedProduct } from '../../postgis/selectSelectedProduct'
import { Range } from '../../UI/Slider'
import { throttle } from '../../util/throttle-debounce'
import { updateProductSettings } from '../store/redux'
import { selectActiveProductSettings } from '../store/selectors/selectActiveProductSettings'

const StyledPaper = styled(Paper)({
  overflow: 'hidden',
  top: 0,
  marginLeft: 19,
})

const StickyMenuItem = styled(MenuItem)(({ theme }) => ({
  root: {
    top: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'sticky',
    zIndex: 10000,
    backgroundColor: theme.palette.background.paper,
    opacity: 1,
  },
}))

export const Filter = () => {
  const anchorRef = React.useRef(null)
  const [state, dispatch] = useRedux()

  const settings = selectActiveProductSettings(state)
  const product = selectSelectedProduct(state)

  const updateFilterRange = throttle(
    { delay: 100 },
    (filterRange?: [number, number]) => {
      dispatch(
        updateProductSettings({
          productId: product?.id,
          settings: {
            filterRange,
          },
        })
      )
    }
  )

  const changeFilterProperty = (e: SelectChangeEvent) => {
    const filterProperty = e.target.value

    dispatch(
      updateProductSettings({
        productId: product?.id,
        settings: {
          filterProperty,
        },
      })
    )
  }

  const toggleFilterOption = () => {
    dispatch(
      updateProductSettings({
        productId: product?.id,
        settings: {
          filterEnabled: !settings.filterEnabled,
        },
      })
    )
  }

  if (!settings || !product) {
    return null
  }

  const { filterEnabled } = settings
  let { filterProperty } = settings

  const filterableProperties = [
    product.dataProperty,
    product.noDataProperty,
    product.coverageProperty,
    product.weightProperty,
  ].filter(
    (property): property is MapSourcePropertyData =>
      // only value properties are filterable
      (property?.type === 'value' && property?.filterable) ?? false
  )

  if (filterableProperties.length === 0) {
    return null
  }

  if (filterProperty === undefined) {
    filterProperty = filterableProperties[0].property
  }

  const filteringProperty =
    filterableProperties.find(
      (property) => property.property === filterProperty
    ) ?? product.dataProperty

  if (!filteringProperty) {
    return null
  }

  /** min/max range of property */
  const filterPropertyRange = filteringProperty.range ?? [0, 1]

  /** user-selected min/max range */
  const filterRange = settings.filterRange ?? filterPropertyRange

  const isOpen = !!filterEnabled

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Tooltip title={i18n.t(keys.legend.filterTooltip)}>
        <IconButton
          onClick={toggleFilterOption}
          style={{ position: 'relative' }}
          ref={anchorRef}
          size="large"
        >
          <Icon color={isOpen ? 'secondary' : 'inherit'}>filter_list</Icon>
        </IconButton>
      </Tooltip>

      <Popper
        style={{ zIndex: 9500 }}
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="top"
      >
        <StyledPaper>
          <Stack>
            <StickyMenuItem divider disabled>
              <ListItemText
                style={{ paddingLeft: 0 }}
                primary={i18n.t(keys.legend.filterTooltip)}
              />
            </StickyMenuItem>
            <div style={{ outline: 'none', width: 280, padding: 16 }}>
              <InputLabel>
                {i18n.t(keys.visualization.filterProperty)}
              </InputLabel>
              <Select
                value={filterProperty}
                fullWidth
                onChange={changeFilterProperty}
                MenuProps={{ sx: { zIndex: 9501 } }}
              >
                {filterableProperties.map(({ id, property, name }) => (
                  <MenuItem key={id} value={property}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <br />
              <br />
              {filterRange && (
                <Typography variant="subtitle1">
                  {`${i18n.t(keys.visualization.filter)}: (${
                    filterRange[0]
                  } - ${filterRange[1]})`}
                </Typography>
              )}

              <br />

              <Range
                min={filterPropertyRange[0]}
                max={filterPropertyRange[1]}
                step={0.01}
                style={{ flex: 1 }}
                value={filterRange}
                onChange={updateFilterRange as any}
                // https://github.com/react-component/slider/issues/504#issuecomment-466375488
                tabIndex={[null, null, null] as any}
              />

              <br />
              <Button
                fullWidth
                onClick={() => updateFilterRange()}
                style={{ textAlign: 'left' }}
              >
                {i18n.t(keys.legend.clearFilter)}
              </Button>
            </div>
          </Stack>
        </StyledPaper>
      </Popper>
    </div>
  )
}
