import * as Sentry from '@sentry/browser'
import { applySettings } from '../redux/actions'
import migrateStore from '../redux/migrateStore'
import { serializeRedux } from '../redux/serializeRedux'
import { AppDispatch, RootStore } from '../redux/types'
import { isMobileDevice } from '../util/getDeviceType'
import handleError from '../util/handleError'
import vvapi from '../vvapi'

export default async function fetchAndMergeState(
  state: RootStore,
  dispatch: AppDispatch
) {
  let fromServer
  try {
    fromServer = await vvapi.settings.get()
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'api',
      message: 'Failed getting settings from server',
      level: Sentry.Severity.Error,
    })
    handleError(err)
  }

  const newerPreferenceCache = (state?.preferences.lastUpdated ?? 0) > (fromServer?.preferences?.lastUpdated ?? 0)

  // if the settings from the cache are newer than the available settings on the server, use the cache.
  const settings = newerPreferenceCache 
    ? {
      ...fromServer ?? {},
      preferences: {
        ...state.preferences,
      },
    }
    : fromServer ?? {}

  const migrated = settings && migrateStore(settings)
  const filtered = migrated && serializeRedux(migrated as any)

  // Don't show sidebars on load on mobile
  // even if the saved settings specifies
  if (isMobileDevice() && !!filtered?.preferences) {
    filtered.preferences.showSidebarLeft = false
    filtered.preferences.showSidebarRight = false
  }

  if (filtered) {
    dispatch(applySettings(filtered))
  }

  // if the cache is newer than the server, update the server with the migrated cache settings. 
  if (newerPreferenceCache) {
    try {
      await vvapi.settings.update({ ...state, ...migrated})
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'api',
        message: 'Failed updating settings on server with migrated local cache settings',
        level: Sentry.Severity.Error,
      })
      handleError(err)
    }
  }

  return {
    ...state,
    ...migrated,
  }
}
