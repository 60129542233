import * as React from 'react'
import { PureComponent } from 'react'

import {
  Checkbox,
  Icon,
  ListItemText,
  MenuItem,
  Radio,
  Tooltip,
} from '@mui/material'
import { selectInactiveProductIds } from '../data/selectAvailableProductIds'
import { selectIsAdmin } from '../data/selectMe'
import { selectSelectedLegendProductId } from '../data/selectSelectedLegendProductId'
import { selectSelectedProductIds } from '../data/selectSelectedProductIds'
import { MapLayerDefData } from '../data/types'
import * as userSelection from '../data/userSelectionRedux'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import { addArrayValues, toggleArrayValue } from '../util/toggleArrayValue'
import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
  setStatsZonesToolActive,
} from '../postgis/actions'

interface Props {
  product: MapLayerDefData
}

class DataLayerMenuItem extends PureComponent<
  Props & ReduxProps & AppDispatchProps
> {
  render() {
    const {
      product,
      selectedProductIds,
      selectedLegendProductId,
      isAdmin,
      inactiveProducts,
    } = this.props

    const { id, name } = product

    return (
      <MenuItem onClick={this.handleExclusiveLayerSelect}>
        <Checkbox
          checked={selectedProductIds.includes(id)}
          onClick={this.handleLayerToggle}
          color="primary"
        />
        <ListItemText primary={name} style={{ whiteSpace: 'break-spaces' }} />
        {isAdmin && inactiveProducts.has(id) && (
          <Tooltip
            title="Layer is inactive"
            style={{
              position: 'absolute',
              left: 0,
              opacity: 0.7,
              transform: 'scale(0.7)',
            }}
          >
            <Icon fontSize="inherit">visibility_off</Icon>
          </Tooltip>
        )}
        <Radio
          checked={selectedLegendProductId === id}
          onClick={this.handleLegendSelect}
          color="primary"
        />
      </MenuItem>
    )
  }

  handleLayerToggle = (event: React.MouseEvent<HTMLElement>) => {
    const { dispatch, product, selectedProductIds, selectedLegendProductId } =
      this.props

    event.stopPropagation()

    dispatch(
      userSelection.update({
        selectedProductIds: toggleArrayValue(selectedProductIds, product.id),
        selectedLegendProductId: selectedLegendProductId ?? product.id,
      })
    )
    this.closePopups()
  }

  handleLegendSelect = (event: React.MouseEvent<HTMLElement>) => {
    const { dispatch, product, selectedProductIds } = this.props
    event.stopPropagation()
    if (product.mapSourceDef.type !== 'point') {
      dispatch(setStatsZonesToolActive(false))
      dispatch(
        userSelection.update({
          selectedProductIds: [product.id],
          selectedLegendProductId: product.id,
        })
      )
    } else {
      dispatch(
        userSelection.update({
          selectedProductIds: addArrayValues(selectedProductIds, [product.id]),
          selectedLegendProductId: product.id,
        })
      )
    }
  }

  handleExclusiveLayerSelect = (event: React.MouseEvent<HTMLElement>) => {
    const { dispatch, product } = this.props
    event.stopPropagation()
    if (product.mapSourceDef.type !== 'point') {
      dispatch(setStatsZonesToolActive(false))
      dispatch(
        userSelection.update({
          selectedProductIds: [product.id],
          selectedLegendProductId: product.id,
        })
      )
    } else {
      dispatch(
        userSelection.update({
          selectedProductIds: [product.id],
          selectedLegendProductId: product.id,
        })
      )
    }
  }

  closePopups = () => {
    const { dispatch } = this.props
    dispatch(setFocusedPoint(null))
    dispatch(setFocusedPolygon(null))
    dispatch(setFocusedBlockId(undefined))
  }
}

const mapState = (state: RootStore) => ({
  isAdmin: selectIsAdmin(state),
  selectedLegendProductId: selectSelectedLegendProductId(state),
  selectedProductIds: selectSelectedProductIds(state),
  inactiveProducts: selectInactiveProductIds(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, Props, AppDispatchProps>(mapState)(
  DataLayerMenuItem
)
