import { toPercent, toReal } from './helper'

export default function stretch<T extends any[]>(
  stops: T[],
  range: [number, number],
  unStretch?: boolean
): T[] {
  return stops.map(
    ([value, color]) =>
      [unStretch ? toPercent(value, range) : toReal(value, range), color] as T
  )
}
