import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import * as api from '../graphql/api'
import { Organization } from '../graphql/types'
import { RootStore } from '../redux/types'

export type OrganizationData = Pick<Organization, 'id' | 'name' | 'betaEnabled' | 'billingCountry'>

const fetchOrganizations = async ({ isLoggedIn }: { isLoggedIn: boolean }, skip: () => void) => {
  if (!isLoggedIn) {
    return skip()
  }

  const { data = [] } = await api.organization.list<OrganizationData>({
    returning: `{
      id
      name
      betaEnabled
      billingCountry
    }`,
  })

  if (!data) {
    throw new Error('No Data')
  }

  return data
}

const { selector: selectOrganizationList, refresh: refreshOrganizationList } =
  createAsyncSelector({
    resource: 'me.organizations',
    inputs: {
      isLoggedIn: (state: RootStore) => state.login.isLoggedIn,
    },
    fetcher: fetchOrganizations,
  })

export { selectOrganizationList, refreshOrganizationList }
