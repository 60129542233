import * as React from 'react'
import { default as Dialog } from '@mui/material/Dialog'
import { default as DialogActions } from '@mui/material/DialogActions'
import { default as DialogContent } from '@mui/material/DialogContent'
import { default as DialogTitle } from '@mui/material/DialogTitle'
import { Button } from '@mui/material'
import { MapSourceDefFilename } from '../../../graphql/types'
import i18n, { keys } from '../../../i18n'
import errorAlert from '../../errorAlert'
import { NameAndValue } from '../../mapdata/types'
import assertDefinedFields from '../../mapdata/utils/assertDefinedFields'
import { createFilename } from './filenameGQL'
import MapSourceFilenameForm from './MapSourceFilenameForm'

interface Props {
  mapSourceDefId: string
  open: boolean
  onSave(): void
  onClose(): void
}

type State = Partial<Writable<MapSourceDefFilename>>

class CreateMapSourceFilenameDialog extends React.PureComponent<Props, State> {
  state: State = {}

  render() {
    return (
      <Dialog
        id="CreateMapSourceFilenameDialog"
        fullWidth
        open={!!this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Create Filename</DialogTitle>
        <DialogContent>
          <MapSourceFilenameForm
            instance={this.state}
            onChange={this.handleChange}
            onSubmit={this.handleSave}
          />
        </DialogContent>
        <DialogActions className="align-right">
          <Button onClick={this.props.onClose}>
            {i18n.t(keys.generic.cancel)}
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave}>
            {i18n.t(keys.generic.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleChange = ({ name, value }: NameAndValue) =>
    this.setState({
      [name]: value,
    })

  handleSave = async () => {
    const { filename, description } = this.state
    const { mapSourceDefId } = this.props
    const requiredFields = {
      mapSourceDefId,
      filename,
      description
    }

    try {
      if (assertDefinedFields(requiredFields)) {
        const req = await createFilename({
          input: requiredFields,
        })

        if (req.errors) {
          softError(
            req.errors[0],
            'Failed to Create Filename',
            `Could not create Filename: ${req.errors[0].message}`,
            this.state
          )
        } else {
          this.props.onSave()
        }
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Filename', message, this.state)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default CreateMapSourceFilenameDialog
