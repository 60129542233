import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { indexArray, indexMultiArrayMappedKey } from '../util/indexArray'
import { isNumericIdEqual } from '../util/isNumericIdEqual'
import {
  selectMapLayerDefsById,
  selectMapSourceDefsById,
} from './selectListMapSourceDefs'
import { selectDeliveryIds } from './selectOrgMapData'
import { GetDeliveryData } from './types'

const { selector: selectDeliveryData, refresh: refreshDeliveryData } =
  createAsyncSelector({
    resource: 'me.organization.delivery',
    inputs: {
      deliveryIds: selectDeliveryIds,
      mapSourceDefsById: selectMapSourceDefsById,
      mapLayerDefsById: selectMapLayerDefsById,
    },
    fetcher: async (
      { deliveryIds, mapSourceDefsById, mapLayerDefsById },
      skip
    ) => {
      if (
        !deliveryIds ||
        isEmpty(mapSourceDefsById) ||
        isEmpty(mapLayerDefsById)
      ) {
        return skip()
      }

      const { data } = await query<{ data: GetDeliveryData[] }>({
        query: gql`
          query DELIVERY_DATA($deliveryIds: [uuid!]!) {
            data: Delivery(where: { id: { _in: $deliveryIds } }) {
              id

              flights: Flights {
                deliveryId
                date
              }

              parcels: DeliveryParcels(where: { MapSources: {} }) {
                deliveryId
                groupId
                parcelId
                name
                meta
                geometry

                parcel: Parcel {
                  id
                  name
                  meta
                  retiredAt
                  group: Group {
                    id
                    name
                  }
                }

                mapSources: MapSources {
                  id
                  mapSourceDefId
                  deliveryId
                  parcelId
                  meta
                  flightDate

                  type
                  scheme
                  attribution
                  tileSize
                  minzoom
                  maxzoom
                  bounds
                  tiles

                  mapLayers: MapLayers {
                    id
                    deliveryId
                    mapSourceId
                    mapLayerDefId
                    enabled
                  }
                }
              }

              groups: DeliveryGroups {
                deliveryId
                groupId
                name

                deliveryGroupFiles: DeliveryGroupFiles {
                  deliveryId
                  groupId
                  filename
                  enabled
                  status
                  file: File {
                    downloadToken
                  }
                }
              }
            }
          }
        `,
        variables: {
          deliveryIds,
        },
      })

      if (!data) {
        throw new Error('No Data')
      }

      if (data.length === 0) {
        return {
          id: 'none',
          flights: [],
          groups: [],
          parcels: [],
        }
      }

      const result: GetDeliveryData = {
        id: '',
        flights: [],
        groups: [],
        parcels: [],
      }

      data.reduce((acc, delivery) => {
        delivery.flights.forEach((flight) => {
          const foundFlight = acc.flights.find(
            ({ date }) => flight.date === date
          )
          if (!foundFlight) {
            acc.flights.push(flight)
          }
        })

        delivery.parcels = delivery.parcels.filter((parcel) => {
          const parcelRetired =
            parcel.parcel.retiredAt && new Date(parcel.parcel.retiredAt)
          const sources = (parcel.mapSources = parcel.mapSources.filter(
            (ms) => {
              const flightDate = new Date(ms.flightDate)
              let isEligible = true
              if (parcelRetired) {
                isEligible = isEligible &&= flightDate < parcelRetired
              }

              return isEligible
            }
          ))

          return sources.length > 0
        })

        delivery.parcels.forEach((parcel) => {
          const foundParcel = acc.parcels.find(({ parcelId }) =>
            isNumericIdEqual(parcel.parcelId, parcelId)
          )
          if (!foundParcel) {
            acc.parcels.push(parcel)
          } else {
            foundParcel.mapSources = foundParcel.mapSources.concat(
              parcel.mapSources
            )
          }
        })

        delivery.groups.forEach((group) => {
          const foundGroup = acc.groups.find(({ groupId }) =>
            isNumericIdEqual(group.groupId, groupId)
          )
          if (!foundGroup) {
            acc.groups.push(group)
          } else {
            foundGroup.deliveryGroupFiles =
              foundGroup.deliveryGroupFiles.concat(group.deliveryGroupFiles)
          }
        })

        return acc
      }, result)

      result.parcels.forEach((parcel) => {
        // use true parcel name
        parcel.name = parcel.parcel.name
        parcel.groupId = parcel.parcel.group.id
        parcel.group = {
          groupId: parcel.groupId,
          name: parcel.parcel.group.name,
        }
        const foundGroup = result.groups.find(({ groupId }) =>
          isNumericIdEqual(parcel.groupId, groupId)
        )

        if (foundGroup) {
          foundGroup.parcels = [...(foundGroup.parcels ?? []), parcel]
        }

        const mergedMapLayers = {}
        parcel.mapSources.forEach((mapSource) => {
          mapSource.parcel = parcel
          mapSource.mapSourceDef = mapSourceDefsById[mapSource.mapSourceDefId]

          mapSource.mapLayers.forEach((mapLayer) => {
            mergedMapLayers[mapLayer.mapLayerDefId] = mapLayer
            mapLayer.mapSource = mapSource
            mapLayer.mapLayerDef = mapLayerDefsById[mapLayer.mapLayerDefId]

            if (!mapLayer.enabled) {
              parcel.hasInactiveLayers = true
            }
          })
        })

        parcel.mapLayers = Object.values(mergedMapLayers)
      })

      return result
    },
  })

export { selectDeliveryData, refreshDeliveryData }

export const selectDelivery = createSelector(
  selectDeliveryData,
  ({ data: delivery }) => delivery
)

const DELIVERY_GROUPS: GetDeliveryData['groups'] = []
export const selectDeliveryGroups = createSelector(
  selectDelivery,
  (delivery) => delivery?.groups ?? DELIVERY_GROUPS
)

export const selectDeliveryGroupsByGroupId = createSelector(
  selectDeliveryGroups,
  (groups) => indexArray(groups, 'groupId')
)

const DELIVERY_PARCELS: GetDeliveryData['parcels'] = []
export const selectDeliveryParcels = createSelector(
  selectDelivery,
  (delivery) => delivery?.parcels ?? DELIVERY_PARCELS
)

export const selectDeliveryParcelsByParcelId = createSelector(
  selectDeliveryParcels,
  (parcels) => indexArray(parcels, 'parcelId')
)

export const selectDeliveryParcelsByGroupId = createSelector(
  selectDeliveryParcels,
  (parcels) => indexMultiArrayMappedKey(parcels, (p) => p.parcel.group.id)
)
