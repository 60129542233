import { createSelector } from 'reselect'
import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { DeliveryGroupFile } from '../graphql/types'
import { indexArray } from '../util/indexArray'
import { getSelectedGroup } from './getSelectedGroup'

const {
  selector: selectListDeliveryGroupFiles,
  refresh: refreshListDeliveryGroupFiles,
} = createAsyncSelector({
  resource: 'GroupFiles',
  inputs: {
    selectedGroup: getSelectedGroup,
  },

  fetcher: async ({ selectedGroup }) => {
    if (selectedGroup) {
      const { files } = await query<{
        files: Pick<
          DeliveryGroupFile,
          'deliveryId' | 'groupId' | 'url' | 'filename' | 'status' | 'file'
        >[]
      }>({
        query: gql`
          query DELIVERY_GROUP_FILES($deliveryId: uuid!, $groupId: Int!) {
            files: DeliveryGroupFile(
              where: {
                deliveryId: { _eq: $deliveryId }
                groupId: { _eq: $groupId }
              }
              distinct_on: filename
            ) {
              deliveryId
              groupId
              filename
              status
              url
              file: File {
                downloadToken
              }
            }
          }
        `,
        variables: {
          deliveryId: selectedGroup.deliveryId,
          groupId: selectedGroup.groupId,
        },
      })

      return files
    }

    return null
  },
})

const EMPTY_GROUPFILES: Record<
  string,
  Pick<
    DeliveryGroupFile,
    'deliveryId' | 'groupId' | 'url' | 'filename' | 'status' | 'file'
  >
> = {}

export const selectGroupFilesByFilename = createSelector(
  selectListDeliveryGroupFiles,
  (listDeliveryGroupFiles) => {
    if (listDeliveryGroupFiles.data) {
      return indexArray(listDeliveryGroupFiles.data, 'filename')
    }

    return EMPTY_GROUPFILES
  }
)

export { selectListDeliveryGroupFiles, refreshListDeliveryGroupFiles }
