import i18n from '../../../i18n'
import { TableFormatter } from '../../../UI/Table/types'
import { countries } from '../../../util/countries'
import { FetchedOrganizations } from './fetchOrganizations'

const organizationId: TableFormatter<FetchedOrganizations> = {
  header: () => 'ID',
  data: ({ id }) => id,
  orderBy: (direction) => `
    {
      id: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    id: { [`_${op}`]: value },
  }),
}
const organizationName: TableFormatter<FetchedOrganizations> = {
  header: () => 'Name',
  data: ({ name }) => name,
  orderBy: (direction) => `
    {
      name: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    name: { [`_${op}`]: value },
  }),
}
const billingCountry: TableFormatter<FetchedOrganizations> = {
  header: () => 'Billing Country',
  // tslint:disable-next-line: no-shadowed-variable
  data: ({ billingCountry }) =>
    billingCountry ? countries[billingCountry] : '',
  orderBy: (direction) => `
    {
      billingCountry: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    billingCountry: { [`_${op}`]: value },
  }),
}
const betaEnabled: TableFormatter<FetchedOrganizations> = {
  header: () => 'Beta Enabled',
  // tslint:disable-next-line: no-shadowed-variable
  data: ({ betaEnabled }) => `${betaEnabled}`,
  orderBy: (direction) => `
    {
      betaEnabled: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    betaEnabled: { [`_${op}`]: value },
  }),
}
const updatedAt: TableFormatter<FetchedOrganizations> = {
  header: () => 'Updated At',
  // tslint:disable-next-line: no-shadowed-variable
  data: ({ updatedAt }) => i18n.toDateTime(updatedAt),
  orderBy: (direction) => `
    {
      updatedAt: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    updatedAt: { [`_${op}`]: value },
  }),
}
const createdAt: TableFormatter<FetchedOrganizations> = {
  header: () => 'Created At',
  // tslint:disable-next-line: no-shadowed-variable
  data: ({ createdAt }) => i18n.toDateTime(createdAt),
  orderBy: (direction) => `
    {
      createdAt: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    createdAt: { [`_${op}`]: value },
  }),
}
export const tableFormatter = [
  organizationId,
  organizationName,
  billingCountry,
  betaEnabled,
  createdAt,
  updatedAt,
]
