const indent = '  '
export const objectToString = (
  obj: Record<string, any> | undefined | null,
  prefix = ''
): string[] => {
  if (obj === undefined) {
    return [`${undefined}`]
  }

  if (obj === null) {
    return [`${null}`]
  }

  return (
    obj &&
    Object.entries(obj).reduce((str, [key, value]) => {
      if (value === null) {
        return [...str, `${prefix}- ${key}: null`]
      }
      if (typeof value === 'object') {
        return [
          ...str,
          `${prefix}- ${key}: \n${(
            objectToString(value, `${prefix}${indent}`) ?? []
          ).join('\n')}`,
        ]
      }

      return [...str, `${prefix}- ${key}: ${value}`]
    }, [] as string[])
  )
}
