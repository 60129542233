import * as Sentry from '@sentry/browser'
import { Middleware } from 'redux'
import { getImpersonationToken } from '../util/getImpersonationToken'
import { debounce } from '../util/throttle-debounce'
import vvapi from '../vvapi'
import { serializeRedux } from './serializeRedux'

let _canUpdate = false
export function setCanUpdate(canUpdate: boolean) {
  _canUpdate = canUpdate
}

let _isPersisting = false
const persistDebounced = debounce(
  { delay: 5000 },
  async (getState: () => any) => {
    if (getImpersonationToken()) {
      return
    }

    if (!_canUpdate) {
      return
    }

    // Check for his here as opposed to the middleware itself so as to not block
    // any actions from being called.
    if (_isPersisting) {
      return
    }

    _isPersisting = true
    const state = serializeRedux(getState())
    try {
      await vvapi.settings.update(state)
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error('error saving settings', e)

      Sentry.addBreadcrumb({
        level: Sentry.Severity.Error,
        category: 'api',
        message: 'Failed updating settings',
      })
    }
    _isPersisting = false
  }
)

export default function persistenceMiddleware(): Middleware {
  return (store) => (dispatch) => (action) => {
    // Call the action before attempting to persist anything.
    const result = dispatch(action)

    // Pass a ref to the getState function so it has a chance of getting even
    // newer state.
    persistDebounced(store.getState)

    return result
  }
}
