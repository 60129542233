import * as api from '../../../../graphql/api'
import {
  MapColorProfile,
  MapColorProfileVisualization,
} from '../../../../graphql/types'
import filterTruthy from '../../../../util/filterTruthy'
import assertDefinedFields from '../../utils/assertDefinedFields'

interface Args {
  visualizations: (Partial<MapColorProfileVisualization> | undefined)[]
  mapColorProfileId: MapColorProfile['id']
}

const updateOrCreateVisualizations = async ({
  visualizations,
  mapColorProfileId,
}: Args) => {
  // updating visualizations should also remove any that were removed
  await removeMissing({ visualizations, mapColorProfileId })

  for (const visualization of visualizations) {
    if (!visualization) {
      continue
    }

    let visualizationId = visualization.id

    visualization.mapColorProfileId = mapColorProfileId

    // ids may not be set, and visualizations are unique per
    // visualization, default, & order, so we need to check
    // if a visualization exists already
    const existingReq = await getColorProfileVisualization(visualization)

    if (existingReq.data && !!existingReq.data[0]) {
      visualizationId = existingReq.data[0].id
    }
    const required = {
      visualization: visualization.visualization,
      order: Number(visualization.order),
      default: visualization.default || false,
    }

    if (!assertDefinedFields(required)) {
      throw new Error('Missing required fields for visualization')
    }

    if (visualizationId) {
      // update
      await api.mapColorProfileVisualization.update({
        pk: { id: visualizationId },
        input: required,
      })
    } else {
      // create
      await api.mapColorProfileVisualization.create({
        input: {
          mapColorProfileId,
          ...required,
        },
      })
    }
  }
}

/**
 * each color profile should only have ONE related model for each visualization
 * mode: absolute, relative, threshold
 */
const getColorProfileVisualization = (
  visualization: Partial<MapColorProfileVisualization>
) =>
  api.mapColorProfileVisualization.list<
    Pick<MapColorProfileVisualization, 'id'>
  >({
    where: {
      mapColorProfileId: {
        _eq: visualization.mapColorProfileId,
      },
      visualization: { _eq: visualization.visualization },
    },
    returning: `{ id }`,
    limit: 1,
  })

/**
 * If a visualization is not passed, then it should be removed from the db
 */
const removeMissing = async ({ visualizations, mapColorProfileId }: Args) => {
  const modes = visualizations
    .map((vis) => vis?.visualization)
    .filter(filterTruthy)

  // find visualizations where not in modes
  const found = await api.mapColorProfileVisualization.list<
    Pick<MapColorProfileVisualization, 'id'>
  >({
    where: {
      mapColorProfileId: {
        _eq: mapColorProfileId,
      },
      visualization: { _nin: modes },
    },
    returning: `{ id }`,
  })

  if (found.data) {
    const ids = found.data.map(({ id }) => id)

    for (const id of ids) {
      await api.mapColorProfileVisualization.delete({ pk: { id } })
    }
  }
}

export default updateOrCreateVisualizations
