import { createSelector } from 'reselect'

import { selectMapLayerDefsById } from '../data/selectListMapSourceDefs'
import { MapLayerDefData } from '../data/types'
import { SourceTypes } from '../graphql/types'
import { selectAllReportMapLayers } from './selectAllReportMapLayers'

/** sort according to a sensible map layer hierarchy */
const sourceTypeOrder: SourceTypes[] = [
  'raster-background',
  'raster',
  'zone',
  'line',
  'point',
  'field-bounds',
]

/** Note: in reverse-order of mapbox rendering in orderLayers */
export const orderLayerProducts = <T extends MapLayerDefData>(
  layerA: T,
  layerB: T
) =>
  sourceTypeOrder.indexOf(layerB.mapSourceDef.type) -
  sourceTypeOrder.indexOf(layerA.mapSourceDef.type)

const selectOptionalReportProducts = createSelector(
  selectAllReportMapLayers,
  (mapLayers) =>
    Array.from(new Set(mapLayers.map(({ mapLayerDefId }) => mapLayerDefId)))
)

export const selectProducts = createSelector(
  selectOptionalReportProducts,
  selectMapLayerDefsById,
  (productIds, productsById) => {
    const products = productIds
      .map((productId) => productsById[productId])
      .filter(Boolean) as MapLayerDefData[]

    // re-use ordering from map layers
    return products.sort(orderLayerProducts)
  }
)
