import * as React from 'react'
import { Map } from '../../map/Map'
import {
  ButtonGroup,
  Stack,
  Toolbar,
  buttonClasses,
  buttonGroupClasses,
  styled,
} from '@mui/material'
import ZoomControl from '../../map/controls/ZoomControl'
import { useRedux } from '../../hooks/useRedux'
import MeasureToolToggle from '../../map/controls/MeasureToolToggle'
import UserPositionToggle from '../../map/controls/UserPositionToggle'
import ScaleControl from '../../map/controls/ScaleControl'
import MeasureTool from '../../postgis/MeasureTool'
import { MapEventHandler } from '../../map/events/MapEventHandler'
import { MapActionFactory } from '../../map/events/map-actions/MapActionFactory'
import { SampleActionGenerator } from '../../map/events/map-actions/click-generators/SampleActionGenerator'
import { EnterSampleGenerator } from '../../map/events/map-actions/enter-generators/EnterSampleGenerator'
import { SampleForm } from './Map/SampleForm'
import { Sample } from '../../graphql/types'
import { classnames } from '../../util/classnames'
import { useSamplePlanMapContext } from './Context/SamplePlanMapContext'
import { completeSample } from './queries'
import { FocusedSamplePopup } from '../../map/controls/FocusedSamplePopup'
import { setFocusedPoint } from '../../postgis/actions'

interface Props {
  children?: React.ReactNode
  sidePanelOpen?: boolean,
  canEditSamples?: boolean
  onSaveSample?: (sample: Pick<Sample, 'id' | 'content'>) => void
  onCancelSample?: () => void
}

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [`&.${buttonGroupClasses.root}`]: {
    [`& .${buttonClasses.root}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,
      width: 45,
      height: 45,
    },
  },
}))

export const SamplePlanMap = ({ children, sidePanelOpen, canEditSamples }: Props) => {
  const [state, dispatch] = useRedux()
  const mapClickActionFactory = React.useMemo(() => new MapActionFactory([
    new SampleActionGenerator()
  ]), [])
  const mouseEnterActionFactory = React.useMemo(() => new MapActionFactory([
    new EnterSampleGenerator()
  ]), [])

  const { 
    dispatch: samplePlanMapDispatch
  } = useSamplePlanMapContext()

  const onSaveSample = async (sample: Sample) => {
    const updatedSample = (await completeSample([sample?.id, sample?.content]))
      ?.data

    if (!updatedSample) {
      return
    }

    samplePlanMapDispatch({
      type: 'UPDATE_SAMPLE',
      sample: updatedSample,
    })

    dispatch({
      type: 'setEditingSampleId',
      sampleId: undefined,
    })
  }

  const onCancelSample = () => {
    dispatch({
      type: 'setEditingSampleId',
      sampleId: undefined,
    })
  }

  // Clear focused point on mount
  React.useEffect(() => {
    dispatch(setFocusedPoint(null))
  }, [])

  return (
    <Map classNames={[state.preferences.theme]}>
      <div
        className={classnames('MapControl-top-left', [
          'side-panel-open',
          sidePanelOpen,
        ])}
      >
        <ScaleControl />
        {state.postgis.isMeasureToolActive && <MeasureTool />}
      </div>

      <div className="MapControl-right">
        <Stack
          direction="column"
          alignItems="center"
          spacing={2}
          style={{ height: '100%' }}
        >
          <ZoomControl />
          <Toolbar style={{ padding: '0px' }}>
            <StyledButtonGroup
              variant="contained"
              size="large"
              orientation="vertical"
            >
              <MeasureToolToggle />
              <UserPositionToggle />
            </StyledButtonGroup>
          </Toolbar>
        </Stack>

        <MapEventHandler
          overrideClickActionFactory={mapClickActionFactory}
          overrideMouseEnterActionFactory={mouseEnterActionFactory}
        />

        <SampleForm
          open={!!state?.sampling?.editingSampleId}
          sampleId={state.sampling.editingSampleId}
          onSave={onSaveSample}
          onCancel={onCancelSample}
        />
      </div>
      {children}

      <FocusedSamplePopup canEdit={canEditSamples} 
        onClose={() => {
          dispatch({ type: 'setEditingSampleId', sampleId: null })
          dispatch(setFocusedPoint(null))
        }}
        onEdit={(sampleId) => {
          dispatch({ type: 'setEditingSampleId', sampleId: sampleId })
        }}/>
    </Map>
  )
}
