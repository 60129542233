import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import { gql, query } from '../../graphql/client'
import { Languages, Translated } from '../../graphql/types'
import { RootStore } from '../../redux/types'

export interface EditPackageData {
  package: {
    id: string
    name: Translated
    description?: Translated
    code: string
    CreatedBy: {
      firstName: string
    }
    createdAt: string
    UpdatedBy: {
      firstName: string
    }
    updatedAt: string
    packageMapLayerDefs: { mapLayerDefId: string }[]
  }
  mapLayerDefs: { id: string; name: string }[]
  selectedMapLayerDefs: { id: string; name: string }[]
}

const get = (variables: { packageId: string; lang: Languages }) =>
  query<EditPackageData>({
    variables,
    query: gql`
      query EditPackageData($packageId: uuid!, $lang: String) {
        package: Package_by_pk(id: $packageId) {
          id
          name
          description
          code
          CreatedBy {
            firstName
          }
          createdAt
          UpdatedBy {
            firstName
          }
          updatedAt

          packageMapLayerDefs: Package_MapLayerDefs {
            mapLayerDefId
          }
        }
        mapLayerDefs: MapLayerDef {
          id
          name(path: $lang)
        }
      }
    `,
  })

const { selector: selectEditPackage, refresh: refreshEditPackage } =
  createAsyncSelector({
    resource: 'EditPackageData',
    inputs: {
      lang: selectPreferredLanguage,
      packageId: (state: RootStore) => state.router.params.packageId,
    },
    fetcher: async ({ packageId, lang }) => {
      if (!packageId) {
        return
      }

      const data = await get({ packageId, lang })

      const packageMapLayerDefIds = data.package.packageMapLayerDefs.map(
        ({ mapLayerDefId }) => mapLayerDefId
      )

      data.selectedMapLayerDefs = data.mapLayerDefs.filter(({ id }) =>
        packageMapLayerDefIds.includes(id)
      )

      return data
    },
  })

export { selectEditPackage, refreshEditPackage }
