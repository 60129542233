import { DateLike } from '../../i18n'

/** converts current time-zone date to yyyy-mm-dd */
const dateToYMD = (dateLike: DateLike) => {
  if (!dateLike) {
    return ''
  }
  const date = new Date(dateLike)
  const yyyy = date.getFullYear()
  const mm = (date.getMonth() + 1).toString().padStart(2, '0')
  const dd = date.getDate().toString().padStart(2, '0')

  return `${yyyy}-${mm}-${dd}`
}

export default dateToYMD
