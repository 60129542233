import * as React from 'react'
import './Stats.scss'

import {
  Icon,
  IconButton,
  Tooltip,
  Popper,
  Paper,
  useMediaQuery,
} from '@mui/material'
import Draggable from 'react-draggable'
import { ThemeContext } from '@emotion/react'

import * as actions from '../../colorScheme/actions'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import i18n, { keys } from '../../i18n'
import { selectActiveProductSettings } from '../../ProductSettings/store/selectors/selectActiveProductSettings'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { updatePreferences } from '../../redux/preferences/redux'
import { selectProductStats } from '../selectors/selectProductStats'
import { selectConvertedSelectedColorProfiles } from '../selectors/stats'
import { StatsWindow } from './StatsWindow'

interface Props {
  toggle?: boolean
}

interface RenderStatsProps {
  isMobile: boolean
  statsWindowDocked: boolean
  statsWindowPosition: { x: number; y: number }
  handleStop: (
    event: React.MouseEvent<HTMLElement>,
    dragElement: { x: number; y: number }
  ) => void
  draggableRef: React.RefObject<HTMLDivElement>
}

const renderStats = ({
  isMobile,
  statsWindowDocked,
  statsWindowPosition,
  handleStop,
  draggableRef,
}: RenderStatsProps) => {
  return isMobile || statsWindowDocked ? (
    <StatsWindow />
  ) : (
    <Draggable
      position={{ x: statsWindowPosition.x, y: statsWindowPosition.y }}
      onStop={handleStop}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Popper
        ref={draggableRef}
        open={true}
        aria-labelledby="draggable-dialog-title"
      >
        <Paper>
          <StatsWindow />
        </Paper>
      </Popper>
    </Draggable>
  )
}

export function StatsUI(props: Props & AppDispatchProps & ReduxProps) {
  const {
    showStats,
    toggle,
    productDef,
    statsWindowPosition,
    statsWindowMinimized,
    statsWindowDocked,
    dispatch,
  } = props
  const theme = React.useContext(ThemeContext) as any
  const draggableRef = React.useRef<HTMLDivElement>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleToggle = () => {
    dispatch(actions.toggleStats({}))
  }

  const handleStop = (
    event: React.MouseEvent<HTMLElement>,
    dragElement: { x: number; y: number }
  ) => {
    dispatch(
      updatePreferences({
        statsWindowPosition: { x: dragElement.x, y: dragElement.y },
      })
    )
  }

  const handleStatsPosition = React.useCallback(() => {
    if (!draggableRef?.current) {
      return
    }

    const position = { ...statsWindowPosition }
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth
    const statsHeight = draggableRef?.current?.getBoundingClientRect().height
    const statsWidth = draggableRef?.current?.getBoundingClientRect().width
    const legendContainerHeight = 120
    const bannerHeight = 83

    const topMaxPosition = -Math.floor(
      windowHeight - statsHeight - legendContainerHeight - bannerHeight
    )
    const bottomMaxPosition = legendContainerHeight
    const leftMaxPosition = -Math.floor((windowWidth - statsWidth) / 2)
    const rightMaxPosition = Math.floor((windowWidth - statsWidth) / 2)

    if (position.y < topMaxPosition) {
      position.y = topMaxPosition
    }

    if (position.y > bottomMaxPosition) {
      position.y = bottomMaxPosition
    }

    if (position.x < leftMaxPosition) {
      position.x = leftMaxPosition
    }

    if (position.x > rightMaxPosition) {
      position.x = rightMaxPosition
    }

    if (
      position.x !== statsWindowPosition.x ||
      position.y !== statsWindowPosition.y
    ) {
      dispatch(updatePreferences({ statsWindowPosition: position }))
    }
  }, [statsWindowPosition, dispatch])

  const handleWindowResize = React.useCallback(() => {
    handleStatsPosition()
  }, [handleStatsPosition])

  React.useEffect(handleStatsPosition, [
    statsWindowMinimized,
    handleStatsPosition,
  ])

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  const statsAvailable = () => {
    const { productStats, colorProfile } = props

    if (!productStats || !colorProfile) {
      return false
    }

    return true
  }

  if (productDef.mapSourceDef.type === 'raster') {
    return null
  }

  if (toggle) {
    return (
      <Tooltip
        title={`${i18n.t(keys.toggleStats, {
          product: productDef.name,
        })}`}
      >
        <IconButton
          disabled={!statsAvailable() && showStats}
          onClick={handleToggle}
          style={{ position: 'relative' }}
          size="large"
        >
          <Icon color={showStats ? 'primary' : 'inherit'}>bar_chart</Icon>
        </IconButton>
      </Tooltip>
    )
  }

  return showStats
    ? renderStats({
        isMobile,
        statsWindowDocked,
        statsWindowPosition,
        handleStop,
        draggableRef,
      })
    : null
}

const mapState = (state: RootStore) => {
  const settings = selectActiveProductSettings(state)
  const productId = selectSelectedLegendProductId(state)
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId]

  return {
    colorProfile: selectedColorProfiles?.[settings.visualization!],
    productDef: selectMapLayerDefsById(state)[productId],
    productStats: selectProductStats(state)[productId],
    showStats: state.colorScheme.showStats,
    statsWindowDocked: state.preferences.statsWindowDocked,
    statsWindowPosition: state.preferences.statsWindowPosition,
    statsWindowMinimized: state.preferences.statsWindowMinimized,
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, Props, AppDispatchProps>(mapState)(StatsUI)
