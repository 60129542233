import * as React from 'react'
import { url, urls } from '../../../appNavigation/urls'
import { Variable_create_input } from '../../../graphql/types'
import { useHistory } from '../../../hooks/useRouter'
import { AdminPage } from '../AdminPage'
import { createVariable } from './createVariable'
import VariableForm from './VariableForm'

export function VariableNew() {
  const history = useHistory()

  const handleCancel = () => history.push(url(urls.admin_variables))

  const handleSave = async (input: Variable_create_input) => {
    const result = await createVariable(input)
    const key = result?.data?.key

    if (key) {
      history.replace(url(urls.admin_variables_edit, { key }))

      return true
    }

    return false
  }

  return (
    <AdminPage title="New Variable">
      <VariableForm type="new" onCancel={handleCancel} onSave={handleSave} />
    </AdminPage>
  )
}
