import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { default as Table } from '@mui/material/Table'
import { default as TableBody } from '@mui/material/TableBody'
import { default as TableCell, TableCellProps } from '@mui/material/TableCell'
import { default as TableHead } from '@mui/material/TableHead'
import { default as TableRow } from '@mui/material/TableRow'

import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import didPropChange from '../../util/didPropChange'
import { closeReportsDialog, setReportFormValues } from '../redux'
import { selectProducts } from '../selectProducts'
import { PerProductOptions, ProductOptions } from '../types'
import { selectSelectedGroup } from '../../data/selectSelectedGroup'

type Column = 'hasGroupPage' | 'hasBlockPage'

interface State {
  productOptions: ProductOptions
  includeRelative: boolean
  showBlockBoundaries: boolean
}

const DEFAULT_PER_PRODUCT_OPTIONS = { hasGroupPage: true }

class ReportsDialog extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps,
  State
> {
  state: State = {
    productOptions: {},
    includeRelative: false,
    showBlockBoundaries: true,
  }

  componentDidMount() {
    this.initializeProductOptions()
  }

  componentDidUpdate(oldProps: this['props']) {
    if (
      didPropChange('open', oldProps, this.props) ||
      didPropChange('products', oldProps, this.props)
    ) {
      this.initializeProductOptions()
    }
  }

  initializeProductOptions = () => {
    const { products } = this.props

    const productOptions: ProductOptions = {}
    for (const product of products) {
      const id = product.id
      productOptions[id] = DEFAULT_PER_PRODUCT_OPTIONS
    }
    this.setState({ productOptions })
  }

  render() {
    const { open, selectedGroup, selectedDate, products } = this.props
    const { productOptions, includeRelative, showBlockBoundaries } = this.state

    if (!selectedGroup || !selectedDate) {
      return null
    }

    return (
      <Dialog open={open} onClose={this.handleClose} fullWidth>
        <DialogTitle>{i18n.t(keys.reports.singular)}</DialogTitle>
        <DialogContent>
          <Typography paragraph variant="subtitle1">
            {`${selectedGroup.name} - ${i18n.toDateLong(selectedDate)}`}
          </Typography>

          {products.length === 0 ? (
            <Typography>{i18n.t(keys.reports.noProducts)}</Typography>
          ) : (
            <React.Fragment>
              <Typography paragraph variant="subtitle1">
                {i18n.t(keys.reports.chooseDataProducts)}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell />
                    <StyledTableCell>
                      <Button
                        onClick={() => this.handleToggleColumn('hasGroupPage')}
                      >
                        <Typography>
                          {i18n.t(keys.reports.groupPage)}
                        </Typography>
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => this.handleToggleColumn('hasBlockPage')}
                      >
                        <Typography>
                          {i18n.t(keys.reports.individualPages)}
                        </Typography>
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products.map(
                    ({ id, name }) =>
                      productOptions[id] && (
                        <ProductRow
                          key={id}
                          productName={name}
                          options={productOptions[id]!}
                          onChange={(options) =>
                            this.setState({
                              productOptions: {
                                ...productOptions,
                                [id]: options,
                              },
                            })
                          }
                        />
                      )
                  )}
                </TableBody>
              </Table>

              <Typography
                paragraph
                variant="subtitle1"
                style={{ marginTop: '2em' }}
              >
                {i18n.t(keys.reports.reportOptions)}
              </Typography>
              <label>
                <ListItem button dense>
                  <Checkbox
                    checked={includeRelative}
                    onChange={(evt) =>
                      this.setState({ includeRelative: evt.target.checked })
                    }
                  />
                  <ListItemText>
                    <Typography>
                      {i18n.t(keys.reports.includeRelative)}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </label>
              <label>
                <ListItem button dense>
                  <Checkbox
                    checked={showBlockBoundaries}
                    onChange={(evt) =>
                      this.setState({ showBlockBoundaries: evt.target.checked })
                    }
                  />
                  <ListItemText>
                    <Typography>
                      {i18n.t(keys.reports.showBlockBoundaries)}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </label>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={this.handleViewClick}
          >
            {i18n.t(keys.generic.view)}
          </Button>
          <Button size="small" onClick={this.handleClose}>
            {i18n.t(keys.generic.close)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleToggleColumn = (column: Column) => {
    const { products } = this.props
    const { productOptions } = this.state

    if (products.length === 0) {
      return
    }

    // toggle value of first row for the whole column
    const firstRow = productOptions[products[0].id]
    const value = !(firstRow?.[column] || false)

    const newProductOptions: ProductOptions = {}
    for (const product of products) {
      const id = product.id
      newProductOptions[id] = {
        ...productOptions[id],
        [column]: value,
      }
    }

    this.setState({ productOptions: newProductOptions })
  }

  handleClose = () => {
    this.props.dispatch(closeReportsDialog())
  }

  handleViewClick = () => {
    this.handleClose()

    this.props.dispatch(
      setReportFormValues({
        products: this.state.productOptions,
        includeRelative: this.state.includeRelative,
        showBlockBoundaries: this.state.showBlockBoundaries,
      })
    )

    // rendered by report page from here on
    this.props.history.push(`/report`)
  }
}

// tslint:disable-next-line:variable-name
const ProductRow = ({
  productName,
  options: { hasGroupPage, hasBlockPage },
  onChange,
}: {
  productName: string
  options: PerProductOptions
  onChange: (value: PerProductOptions) => void
}) => (
  <TableRow>
    <StyledTableCell>
      <Button
        style={{
          textAlign: 'right',
          justifyContent: 'flex-end',
        }}
        fullWidth
        onClick={() =>
          onChange({ hasGroupPage: !hasGroupPage, hasBlockPage: !hasGroupPage })
        }
      >
        {productName}
      </Button>
    </StyledTableCell>
    <StyledTableCell style={{ textAlign: 'center' }}>
      <Checkbox
        checked={hasGroupPage || false}
        onChange={(evt) =>
          onChange({ hasBlockPage, hasGroupPage: evt.target.checked })
        }
      />
    </StyledTableCell>
    <StyledTableCell style={{ textAlign: 'center' }}>
      <Checkbox
        checked={hasBlockPage || false}
        onChange={(evt) =>
          onChange({ hasGroupPage, hasBlockPage: evt.target.checked })
        }
      />
    </StyledTableCell>
  </TableRow>
)

// tslint:disable-next-line:variable-name
const StyledTableCell = ({ style = {}, ...props }: TableCellProps) => (
  <TableCell
    style={{
      padding: 0,
      ...style,
    }}
    {...props}
  />
)

const mapState = (state: RootStore) => ({
  open: state.reports.isReportsDialogOpen,
  selectedGroup: selectSelectedGroup(state),
  selectedDate: getSelectedFlightDate(state),
  products: selectProducts(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(ReportsDialog)
)
