import * as React from 'react'
import { PureComponent } from 'react'
import { ListItem, ListItemText } from '@mui/material'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { updatePreferences } from '../../redux/preferences/redux'
import { AppDispatchProps } from '../../redux/types'
import ListItemCheckbox from '../../UI/ListItemCheckbox'

interface Props {
  isEnabled: boolean
}

class DrawerFieldBoundariesToggle extends PureComponent<
  Props & AppDispatchProps
> {
  render() {
    return (
      <ListItem button onClick={this.toggle}>
        <ListItemCheckbox checked={this.props.isEnabled} />
        <ListItemText primary={i18n.t(keys.layerDrawer.fieldBoundaries)} />
      </ListItem>
    )
  }

  toggle = () => {
    this.props.dispatch(
      updatePreferences({ showFieldBoundaries: !this.props.isEnabled })
    )
  }
}

export default connect<Props, {}, AppDispatchProps>((state) => ({
  isEnabled: state.preferences.showFieldBoundaries,
}))(DrawerFieldBoundariesToggle)
