const getPadding = (paddingOffset: mapboxgl.PaddingOptions, boundsPadding: number | mapboxgl.PaddingOptions) => {
  if (!paddingOffset || !boundsPadding) {
    return 20
  }

  if (typeof boundsPadding === 'number') {
    return {
      top: boundsPadding + paddingOffset.top,
      right: boundsPadding + paddingOffset.right,
      bottom: boundsPadding + paddingOffset.bottom,
      left: boundsPadding + paddingOffset.left,
    }
  }

  return {
    top: boundsPadding.top + paddingOffset.top,
    right: boundsPadding.right + paddingOffset.right,
    bottom: boundsPadding.bottom + paddingOffset.bottom,
    left: boundsPadding.left + paddingOffset.left,
  }
}

export default getPadding