import * as React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { FilterBy, TableFormatter } from './types'
import { FilterList } from '@mui/icons-material'
import i18n, { keys } from '../../i18n'
import { useHistory } from 'react-router-dom'
import { useSearchParams } from '../../hooks/useRouter'
import { url } from '../../appNavigation/urls'
import { FILTER } from './filterRows'
import { useDebounce } from '../../hooks/useDebounce'

interface Props<T> {
  style?: React.CSSProperties
  tableFormatters: TableFormatter<T>[]
  filterId: string
}

export const Search = <T,>({ tableFormatters, style, filterId }: Props<T>) => {
  const [searchValue, setSearchValue] = React.useState<string>()
  const { filter: paramFilter, ...others } = useSearchParams()
  const history = useHistory()

  React.useEffect(() => {
    // if the search value has a value on load...
    if (!!paramFilter) {
      // find the first filter where the corresponding table formatter has a matching filterId
      const [firstFilter] = FILTER.deserialize(paramFilter).filter(
        (f) => tableFormatters?.[f.index]?.['filterId'] === filterId
      )
      // if there was no search value but the corresponding search param exists...
      if (!!paramFilter) {
        setSearchValue(
          (firstFilter?.value ?? ('' as string)).replaceAll('%', '')
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearchValueChange = (value: string) => {
    // set the value
    setSearchValue(value)
    debounceSearchValueChange(value)
  }

  const setSearchParam = (value: string) => {
    // update the search parameters in the url.
    if (!!value && value.length > 0) {
      if (!tableFormatters || tableFormatters.length === 0) {
        return
      }
      const tableFormatterFilterBy = value
        // find all words
        .match(/()\w+/g)
        ?.reduce((arr, match) => {
          return [
            ...arr,
            ...tableFormatters
              .map(
                (_tf, index) =>
                  ({ index, op: 'ilike', value: `%${match}%` } as FilterBy)
              )
              .filter(
                (f) =>
                  !!tableFormatters[f.index].filterBy &&
                  tableFormatters[f.index]['filterId'] === filterId
              ),
          ]
        }, [])

      // filter out the filters that already exist for the same column index.

      const newFilterBys: FilterBy[] = []

      for (const filterBy of tableFormatterFilterBy ?? []) {
        if (
          newFilterBys.findIndex(
            (fb) =>
              fb.index === filterBy.index &&
              fb.op === filterBy.op &&
              fb.value === filterBy.value
          ) > -1
        ) {
          continue
        }

        newFilterBys.push(filterBy)
      }

      const filter = FILTER.serialize(newFilterBys)
      // set the url if the search value changes (persistence)...
      history.push(
        url({ url: history.location.pathname }, {}, { ...others, filter })
      )
    } else {
      const tableFormatterFilterBy = tableFormatters
        .map((_tf, index) => ({ index } as FilterBy))
        .filter(
          (f) =>
            !!tableFormatters[f.index].filterBy &&
            tableFormatters[f.index]['filterId'] === filterId
        )
      const currentFilterBys = FILTER.deserialize(paramFilter ?? '')

      // filter out the columns that use the search bar.
      const nonSearchFilterBys = currentFilterBys.filter(
        (cfb) => !tableFormatterFilterBy?.some((tff) => tff.index === cfb.index)
      )
      const filter = FILTER.serialize(nonSearchFilterBys ?? '')
      history.push(
        url({ url: history.location.pathname }, {}, { ...others, filter })
      )
    }
  }

  const debounceSearchValueChange = useDebounce(setSearchParam, 200)

  return (
    <TextField
      id="search"
      placeholder={i18n.t(keys.search)}
      value={searchValue}
      style={style}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FilterList />
          </InputAdornment>
        ),
      }}
      onChange={(e) => onSearchValueChange(e.currentTarget.value)}
      variant="outlined"
    />
  )
}
