import createReducer from 'redux-ts-helpers/lib/createReducer'
import * as actions from './actions'
import { AppState } from './types'

export const initialState: AppState = {
  storeVersion: 0,
  isPreferencesDialogOpen: false,
  isOrgSwitchDialogOpen: false,
  isHelpDialogOpen: false,
}

export const reducer = createReducer(initialState, {
  [actions.constants.toggleSettingDialog]: (state) => ({
    ...state,
    isPreferencesDialogOpen: !state.isPreferencesDialogOpen,
  }),

  [actions.constants.setOrgSwitchDialogOpen]: (state, action) => ({
    ...state,
    isOrgSwitchDialogOpen: action.payload,
  }),

  [actions.constants.setHelpDialogOpen]: (
    state,
    action: ReturnType<typeof actions.setHelpDialogOpen>
  ) => ({
    ...state,
    isHelpDialogOpen: action.payload,
  }),
})
