import { Action } from 'redux'
import { applyNamespace } from 'redux-ts-helpers'
import { RouterState } from './types'

const initialState: RouterState = {
  params: {},
  searchParams: {},
}

const typeNamespace = 'appNavigation'
const types = applyNamespace(typeNamespace, {
  setRouterState: 0,
})

export const routerActions = {
  setRouterState: (state: RouterState) => ({
    type: types.setRouterState,
    payload: state,
  }),
}

interface RouterStateAction extends Action {
  payload: RouterState
}

export const routerReducer = (
  state = initialState,
  action: RouterStateAction
): RouterState => {
  switch (action.type) {
    case types.setRouterState:
      // Note: do not use `...state` here, each payload completely replaces the state
      return action.payload
    default:
      return state
  }
}
