import { createSelector } from "reselect";
import { RootStore } from "../../redux/types";

const getStatsZones = (state: RootStore) => state.postgis.statsCustomZones

const getStatsZonesEnabled = (state: RootStore) => state.postgis.isStatsZonesToolActive

export const selectStatsZones = createSelector(getStatsZonesEnabled, getStatsZones, (statsZonesEnabled, statsZones) => {
  if (!statsZonesEnabled) {
    return undefined
  }

  return statsZones
})