import * as React from 'react'
import { Button, Collapse, Stack, styled } from '@mui/material'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Level, LevelInfo } from './types'
import Collapsible from './Collapsible'
import { DrillDownList } from './DrillDownList'
import { NavigateBeforeSharp } from '@mui/icons-material'
import i18n, { keys } from '../../i18n'

interface Props {
  levels: Level[]
  optionsComponent?: React.ReactNode
  style?: React.CSSProperties
}

const BackButton = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root:hover': {
    bgcolor: 'transparent',
  },
  margin: '0 8px 0 16px',
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '21px',
  letterSpacing: '0.25px',
}))

const DrillDownMenu = ({ levels, optionsComponent, style }: Props) => {
  const [currentLevelInfo, setCurrentLevelInfo] = React.useState<LevelInfo>({
    index: 0,
    parentHistory: levels.map(() => undefined),
  })

  const currentLevel = React.useMemo(
    () => levels[currentLevelInfo.index],
    [currentLevelInfo.index, levels]
  )

  return (
    <Stack sx={{ height: '2000px', width: '100%', ...style }}>
      <Collapse orientation="vertical" in={currentLevelInfo.index !== 0}>
        <BackButton
          disableRipple
          onClick={() => {
            levels[currentLevelInfo.index].onAscend?.()
            setCurrentLevelInfo({
              ...currentLevelInfo,
              index: currentLevelInfo.index - 1,
            })
          }}
          startIcon={<NavigateBeforeSharp />}
        >
          {currentLevel?.BackLabel ? (
            <currentLevel.BackLabel
              parent={currentLevelInfo.parentHistory?.[currentLevelInfo.index]}
            />
          ) : (
            i18n.t(keys.generic.back)
          )}
        </BackButton>
      </Collapse>
      {optionsComponent}
      <Stack direction="row" flex={1}>
        {levels.map((level, levelIndex) => {
          return (
            <Collapsible
              key={level.id}
              open={level.id === levels[currentLevelInfo.index].id}
              orientation="horizontal"
            >
              <Stack height="100%" width="100%" zIndex={100 + levelIndex}>
                <AutoSizer>
                  {({ height, width }: { height: number; width: number }) => (
                    <DrillDownList
                      level={level}
                      levelIndex={levelIndex}
                      levels={levels}
                      setCurrentLevelInfo={setCurrentLevelInfo}
                      currentLevelInfo={currentLevelInfo}
                      height={height}
                      width={width}
                    />
                  )}
                </AutoSizer>
              </Stack>
            </Collapsible>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default DrillDownMenu
