import { AdminRole, api, gql } from '../../../hasura/api'

export const createAdminRole = async (input: AdminRole) => {
  const choice = await sweetAlert(
    `Are you sure you want to create this AdminRole?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Create',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (choice == null || choice === false) {
    return
  }

  return await api.adminRoles.insertOne<{ userId: string; role: string }>(
    {
      object: input,
      on_conflict: {
        constraint: 'UserRole_pkey',
        update_columns: [],
      },
    },
    gql`
      {
        userId
        role
      }
    `
  )
}
