import './PackageSelection.scss'

import * as React from 'react'
import { Link } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import {
  Button,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material'

import { Row } from '../../../../admin/UI/Row'
import { url, urls } from '../../../../appNavigation/urls'
import * as api from '../../../../graphql/api'
import i18n from '../../../../i18n'
import { ExpandButton } from '../../../../UI/Expand/ExpandButton'
import { NotificationButton } from '../../../../UI/NotificationButton'
import { Popup } from '../../../../UI/Popup/Popup'
import { SelectBox, SelectBoxProps } from '../../../../UI/SelectBox/SelectBox'
import TooltipIconButton from '../../../../UI/TooltipIconButton'
import { isNumericIdEqual } from '../../../../util/isNumericIdEqual'
import vvapi from '../../../../vvapi'
import EditTargetDelivery from '../../TargetDelivery/EditTargetDelivery'
import NewTargetDelivery from '../../TargetDelivery/NewTargetDelivery'
import {
  FetchOrderPackageSelection,
  ParcelData,
} from './fetchers/fetchOrderPackageSelection'
import { updateOrderPackageSelection } from './fetchers/updateOrderPackageSelection'
import { connect } from '../../../../redux/connect'
import { RootStore } from '../../../../redux/types'
import { selectMe } from '../../../../data/selectMe'

interface OrderPackageSelectionProps {
  data: FetchOrderPackageSelection

  onZoomToSelection?: (
    selection: { parcelId: string } | { groupId: string }
  ) => void

  onSelectionChange?: (selection: SelectedPackage[]) => void

  onRefresh?: () => void
}

export interface SelectedPackage {
  orderId: string
  targetDeliveryDate: string
  parcelId: string
  package: string
}

interface PackageSelectionState {
  selectedPackages?: SelectedPackage[]
  targetDeliveryExpanded?: string
  packagesExpanded: Record<string, boolean>
  groupsExpanded: Record<string, boolean>

  newTargetDelivery?: boolean
  editTargetDelivery?: {
    orderId: string
    date: string
    inhibitFlightNotice: boolean
  }
}

class PackageSelection extends React.PureComponent<
  OrderPackageSelectionProps & ReduxProps,
  PackageSelectionState
> {
  state: PackageSelectionState = {
    targetDeliveryExpanded: undefined,
    packagesExpanded: {},
    groupsExpanded: {},
  }

  componentDidMount() {
    this.handleSelectionChange()
  }

  getSelectedPackages() {
    const { order } = this.props.data
    const { targetDeliveryExpanded, selectedPackages } = this.state

    const targetDelivery = order.targetDeliveries.find(
      ({ date }) => date === targetDeliveryExpanded
    )

    return (
      selectedPackages ?? targetDelivery?.targetDelivery_Parcel_Packages ?? []
    )
  }

  render() {
    const {
      data: { order },
      user,
    } = this.props

    const { targetDeliveryExpanded } = this.state

    return (
      <div>
        <div
          className="PackageSelection__sticky"
          style={{
            top: 0,
            zIndex: 4,
            paddingTop: 8,
          }}
        >
          <h2>Target Flights</h2>
          <TooltipIconButton
            title={'Refresh'}
            onClick={this.refresh}
            iconButtonStyle={{ padding: 4 }}
          >
            <Icon>refresh</Icon>
          </TooltipIconButton>
        </div>
        {order.targetDeliveries.map((targetDelivery) => (
          <div key={targetDelivery.date}>
            <div
              className="PackageSelection__sticky"
              style={{
                top: 76,
                zIndex: 3,
              }}
            >
              <span>
                {user?.roles.includes('admin') && (
                  <ExpandButton
                    expanded={targetDeliveryExpanded === targetDelivery.date}
                    onClick={() =>
                      this.handleTargetDeliverySelection(targetDelivery.date)
                    }
                  />
                )}
                <Tooltip title="Edit date">
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.handleClickEdit({
                        date: targetDelivery.date,
                        orderId: order.id,
                        inhibitFlightNotice: targetDelivery.inhibitFlightNotice,
                      })
                    }
                  >
                    {targetDelivery.date}
                  </span>
                </Tooltip>
              </span>
              {this.renderDeliveryLink(
                {
                  orderId: order.id,
                  targetDeliveryDate: targetDelivery.date,
                },
                targetDelivery.delivery
              )}
              {this.renderTargetDeliveryActions({
                orderId: order.id,
                targetDeliveryDate: targetDelivery.date,
              })}
            </div>
            {this.renderExpandedTargetDelivery(targetDelivery)}
          </div>
        ))}
        <div className="PackageSelection__sticky">
          {user?.roles.includes('admin') && (
            <Tooltip title="Add new date">
              <span
                style={{ cursor: 'pointer' }}
                onClick={this.handleClickCreate}
              >
                <IconButton
                  style={{ padding: 4, backgroundColor: 'transparent' }}
                  disableRipple
                  disableTouchRipple
                  size="large"
                >
                  <Icon>add</Icon>
                </IconButton>
                New Date
              </span>
            </Tooltip>
          )}
        </div>

        <NewTargetDelivery
          open={!!this.state.newTargetDelivery}
          onClose={this.handleCloseTargetDelivery}
        />
        <EditTargetDelivery
          open={!!this.state.editTargetDelivery}
          targetDelivery={this.state.editTargetDelivery as any}
          onClose={this.handleCloseTargetDelivery}
        />
      </div>
    )
  }

  renderDeliveryLink(
    targetDelivery: {
      orderId: string
      targetDeliveryDate: string
    },
    delivery?: { id: string }
  ) {
    return delivery ? (
      <Link
        to={url(urls.editDelivery, { deliveryId: delivery.id })}
        style={{ marginLeft: 16 }}
      >
        Edit Delivery
      </Link>
    ) : (
      <Link
        to={url(urls.newDelivery, targetDelivery)}
        style={{ marginLeft: 16 }}
      >
        New Delivery
      </Link>
    )
  }

  renderTargetDeliveryActions(targetDelivery: {
    orderId: string
    targetDeliveryDate: string
  }) {
    const { user } = this.props

    return (
      <span>
        <Popup
          popup={
            <div>
              <MenuItem
                onClick={() => api.delivery.downloadKML(targetDelivery)}
              >
                KML
              </MenuItem>

              <MenuItem
                onClick={() => api.delivery.downloadGeoJSON(targetDelivery)}
              >
                GeoJSON
              </MenuItem>
            </div>
          }
        >
          <TooltipIconButton
            title={'Download KML/GeoJSON'}
            iconButtonStyle={{ padding: 4 }}
          >
            <Icon>download</Icon>
          </TooltipIconButton>
        </Popup>
        {user?.roles.includes('admin') && (
          <TooltipIconButton
            title={'Delete Target Date'}
            onClick={() =>
              this.handleDeleteTargetDelivery({
                orderId: targetDelivery.orderId,
                date: targetDelivery.targetDeliveryDate,
              })
            }
            iconButtonStyle={{ padding: 4 }}
          >
            <Icon>delete</Icon>
          </TooltipIconButton>
        )}
      </span>
    )
  }

  renderExpandedTargetDelivery(
    targetDelivery: FetchOrderPackageSelection['order']['targetDeliveries'][number]
  ) {
    const { packages, parcels } = this.props.data

    const selectedPackages = this.getSelectedPackages()

    const { targetDeliveryExpanded, packagesExpanded } = this.state

    return (
      targetDeliveryExpanded === targetDelivery.date && (
        <div className="PackageSelection__nest PackageSelection__secondary">
          <div className="PackageSelection__flex">
            <h3>Packages</h3>
            {this.renderCloneSelectionFromOtherDate(targetDelivery.date)}
          </div>
          {packages.map(({ code, name }) => {
            const selectedPackagesForCode = selectedPackages.filter(
              (p) => p.package === code
            )
            const amount = selectedPackagesForCode.length
            const total = parcels.length

            const text = `${name} - ${amount} of ${total}`
            const selection: SelectBoxProps['selection'] =
              amount === 0
                ? 'unselected'
                : amount === total
                ? 'selected'
                : 'partially-selected'

            return (
              <div key={code}>
                <div
                  className="PackageSelection__sticky"
                  style={{
                    top: 76 + 32,
                    zIndex: 2,
                  }}
                >
                  <span>
                    <ExpandButton
                      expanded={!!packagesExpanded[code]}
                      onClick={() => this.handlePackageExpansion(code)}
                    />
                    <SelectBox
                      selection={selection}
                      onClick={() =>
                        this.handlePackageSelection(
                          code,
                          selection,
                          targetDelivery
                        )
                      }
                    />
                    {text}
                  </span>
                </div>
                {this.renderExpandedPackages(
                  code,
                  selectedPackagesForCode,
                  targetDelivery
                )}
              </div>
            )
          })}
          <Stack
            className="actions"
            style={{ paddingTop: 8 }}
            spacing={1}
            direction="row"
          >
            <Button
              variant="contained"
              size="small"
              color="secondary"
              disabled={!this.state.selectedPackages}
              onClick={() => this.setState({ selectedPackages: undefined })}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!this.state.selectedPackages}
              onClick={() => this.handleSaveTargetDeliverySelection()}
            >
              Save
            </Button>
          </Stack>
          <hr />
          {this.renderNotificationButton(targetDelivery)}
        </div>
      )
    )
  }
  renderNotificationButton = (
    targetDelivery: FetchOrderPackageSelection['order']['targetDeliveries'][number]
  ) => {
    const { user } = this.props

    if (!user?.roles.includes('admin')) {
      return null
    }

    return (
      <NotificationButton
        notificationName="Flight Notice"
        sentAt={targetDelivery.flightNoticeSentAt}
        send={async () => {
          await vvapi.notification.sendFlightNotice(
            targetDelivery.orderId,
            targetDelivery.date
          )
          this.refresh()
        }}
      />
    )
  }

  renderCloneSelectionFromOtherDate(date: string) {
    const {
      order: { targetDeliveries },
    } = this.props.data
    const tds = targetDeliveries.filter((td) => td.date !== date)

    return (
      <Popup
        disabled={tds.length === 0}
        popup={
          <>
            {tds.map((td) => (
              <div key={td.date}>
                <MenuItem
                  onClick={() => {
                    this.setState({
                      selectedPackages: td.targetDelivery_Parcel_Packages.map(
                        (selection) => ({
                          ...selection,
                          targetDeliveryDate: date,
                        })
                      ),
                    })
                  }}
                >
                  {td.date}
                </MenuItem>
              </div>
            ))}
          </>
        }
      >
        <TooltipIconButton
          title={'Clone selection from another date'}
          iconButtonStyle={{ padding: 4 }}
          disabled={tds.length === 0}
        >
          <Icon>collections_bookmark</Icon>
        </TooltipIconButton>
      </Popup>
    )
  }

  renderExpandedPackages(
    code: string,
    selectedParcels: SelectedPackage[],
    targetDelivery: FetchOrderPackageSelection['order']['targetDeliveries'][number]
  ) {
    const {
      data: { groups },
      onZoomToSelection,
    } = this.props

    const { packagesExpanded, groupsExpanded } = this.state

    return (
      !!packagesExpanded[code] && (
        <div className="PackageSelection__nest PackageSelection__secondary">
          <h3>Groups</h3>
          {groups.map(({ id: groupId, name, parcels }) => {
            const selectedPackagesForGroup = selectedParcels.filter(
              ({ parcelId }) =>
                parcels.find(({ id }) => isNumericIdEqual(id, parcelId))
            )
            const amount = selectedPackagesForGroup.length
            const total = parcels.length

            const text = `${name} - ${amount} of ${total}`
            const selection: SelectBoxProps['selection'] =
              amount === 0
                ? 'unselected'
                : amount === total
                ? 'selected'
                : 'partially-selected'

            return (
              <div key={groupId}>
                <div
                  className="PackageSelection__sticky"
                  style={{
                    top: 76 + 32 + 32,
                    zIndex: 1,
                  }}
                >
                  <span>
                    <ExpandButton
                      expanded={!!groupsExpanded[groupId]}
                      onClick={() => this.handleGroupExpansion(groupId)}
                    />
                    <SelectBox
                      selection={selection}
                      onClick={() =>
                        this.handleGroupSelection(
                          groupId,
                          code,
                          selection,
                          targetDelivery
                        )
                      }
                    />
                    {text}
                  </span>
                  <span>
                    {onZoomToSelection && (
                      <TooltipIconButton
                        title={'Zoom map to bounds'}
                        onClick={() => onZoomToSelection({ groupId })}
                        iconButtonStyle={{ padding: 4 }}
                      >
                        <Icon>border_outer</Icon>
                      </TooltipIconButton>
                    )}
                  </span>
                </div>
                {this.renderExpandedGroups(
                  groupId,
                  parcels,
                  code,
                  selectedPackagesForGroup,
                  targetDelivery
                )}
              </div>
            )
          })}
        </div>
      )
    )
  }

  renderExpandedGroups(
    groupId: string,
    parcels: ParcelData[],
    code: string,
    selectedParcels: SelectedPackage[],
    targetDelivery: FetchOrderPackageSelection['order']['targetDeliveries'][number]
  ) {
    const { onZoomToSelection } = this.props
    const { groupsExpanded } = this.state
    const targetDate = Date.parse(targetDelivery.date)
    return (
      !!groupsExpanded[groupId] && (
        <div className="PackageSelection__nest PackageSelection__secondary">
          <h3>Parcels</h3>
          {parcels.map(({ id: parcelId, name, retiredAt }) => {
            const selection: SelectBoxProps['selection'] = selectedParcels.find(
              (p) => isNumericIdEqual(p.parcelId, parcelId)
            )
              ? 'selected'
              : 'unselected'

            const retiredDate = retiredAt && Date.parse(retiredAt)
            const isRetired = retiredDate && retiredDate < targetDate

            return (
              <div key={parcelId} className="PackageSelection__sticky">
                <span>
                  <SelectBox
                    selection={selection}
                    onClick={() =>
                      this.handleParcelSelection([parcelId], code, selection, [
                        parcelId,
                      ])
                    }
                  />
                  {name}
                </span>
                <Row>
                  {isRetired && !!retiredDate && (
                    <Tooltip
                      title={`This block is retired as of ${i18n.toDateShort(
                        new Date(retiredDate)
                      )}`}
                    >
                      <Icon sx={{ color: 'orange' }}>info</Icon>
                    </Tooltip>
                  )}

                  {onZoomToSelection && (
                    <TooltipIconButton
                      title={'Zoom map to bounds'}
                      onClick={() =>
                        onZoomToSelection({
                          parcelId,
                        })
                      }
                      iconButtonStyle={{ padding: 4 }}
                    >
                      <Icon>border_outer</Icon>
                    </TooltipIconButton>
                  )}
                </Row>
              </div>
            )
          })}
        </div>
      )
    )
  }

  handlePackageExpansion(expandPackage: string) {
    this.setState(({ packagesExpanded }) => ({
      packagesExpanded: {
        ...packagesExpanded,
        [expandPackage]: !packagesExpanded[expandPackage],
      },
    }))
  }

  handleGroupExpansion(expandgroup: string | number) {
    this.setState(({ groupsExpanded }) => ({
      groupsExpanded: {
        ...groupsExpanded,
        [expandgroup]: !groupsExpanded[expandgroup],
      },
    }))
  }

  async handleTargetDeliverySelection(selectedDate: string) {
    if (await this.handleSaveTargetDeliverySelection(true)) {
      this.setState(
        ({ targetDeliveryExpanded }) => ({
          targetDeliveryExpanded:
            selectedDate === targetDeliveryExpanded ? undefined : selectedDate,
        }),
        () => this.handleSelectionChange()
      )
    }
  }

  handlePackageSelection(
    packageCode: string,
    selection: SelectBoxProps['selection'],
    targetDelivery: FetchOrderPackageSelection['order']['targetDeliveries'][number]
  ) {
    const { parcels } = this.props.data

    const targetDate = Date.parse(targetDelivery.date)

    this.handleParcelSelection(
      parcels.map(({ id }) => id),
      packageCode,
      selection,
      parcels
        .filter(({ retiredAt }) => {
          const retiredDate = retiredAt && Date.parse(retiredAt)
          const isRetired = retiredDate && retiredDate < targetDate

          return !isRetired
        })
        .map(({ id }) => id)
    )
  }

  handleGroupSelection(
    groupId: string,
    packageCode: string,
    selection: SelectBoxProps['selection'],
    targetDelivery: FetchOrderPackageSelection['order']['targetDeliveries'][number]
  ) {
    const { groups } = this.props.data

    const targetDate = Date.parse(targetDelivery.date)

    const group = groups.find(({ id }) => isNumericIdEqual(id, groupId))
    if (!group) {
      return
    }

    this.handleParcelSelection(
      group.parcels.map(({ id }) => id),
      packageCode,
      selection,
      group.parcels
        .filter(({ retiredAt }) => {
          const retiredDate = retiredAt && Date.parse(retiredAt)
          const isRetired = retiredDate && retiredDate < targetDate

          return !isRetired
        })
        .map(({ id }) => id)
    )
  }

  handleParcelSelection(
    parcelIds: string[],
    packageCode: string,
    selection: SelectBoxProps['selection'],
    eligibleIds: string[] = []
  ) {
    const eligibleSet = new Set(eligibleIds)
    const parcelIdSet = new Set(parcelIds)
    const { order } = this.props.data
    const { targetDeliveryExpanded } = this.state
    if (!targetDeliveryExpanded) {
      return
    }

    let selectedPackages = this.getSelectedPackages().filter(
      (p) => !(parcelIdSet.has(p.parcelId) && p.package === packageCode)
    )

    if (selection !== 'selected' && selection !== 'partially-selected') {
      selectedPackages = selectedPackages.concat(
        parcelIds
          .filter((p) => eligibleSet.has(p))
          .map((parcelId) => ({
            orderId: order.id,
            targetDeliveryDate: targetDeliveryExpanded,
            parcelId,
            package: packageCode,
          }))
      )
    }

    this.setState({ selectedPackages }, () => this.handleSelectionChange())
  }

  handleSelectionChange() {
    const { onSelectionChange } = this.props
    const selectedPackages = this.getSelectedPackages()

    if (onSelectionChange) {
      onSelectionChange(selectedPackages)
    }
  }

  async handleDeleteTargetDelivery({
    orderId,
    date,
  }: {
    orderId: string
    date: string
  }) {
    const choice = await swal(`You are about to delete ${date}`, {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Delete',
        },
      },
      dangerMode: true,
    })

    if (choice === true) {
      await api.targetDelivery.delete({ pk: { orderId, date } })

      this.refresh()
    }
  }

  async handleSaveTargetDeliverySelection(leaving = false) {
    const { targetDeliveryExpanded } = this.state
    if (!this.state.selectedPackages) {
      return true
    }
    const parcelPackages = this.getSelectedPackages()
    if (!targetDeliveryExpanded) {
      return true
    }
    const choice = await swal(
      leaving
        ? `You have unsaved changes for ${targetDeliveryExpanded} package selection`
        : `You are about to update the package selection for ${targetDeliveryExpanded}`,
      {
        buttons: {
          cancel: true,
          discard: {
            text: 'Discard',
          },
          confirm: {
            text: 'Save',
          },
        },
        dangerMode: true,
      }
    )

    // swal returns null for "cancel"
    if (!choice) {
      return false
    }

    if (choice === 'discard') {
      this.setState({ selectedPackages: undefined })
    }

    if (choice === true) {
      // todo: save changes to db
      await updateOrderPackageSelection(
        this.props.data.organization.id,
        this.props.data.order.id,
        targetDeliveryExpanded,
        parcelPackages
      )

      this.setState({ selectedPackages: undefined })

      this.refresh()
    }

    return true
  }

  handleClickCreate = async () => {
    if (await this.handleSaveTargetDeliverySelection(true)) {
      this.setState({ newTargetDelivery: true })
    }
  }

  handleClickEdit = async (targetDelivery: {
    orderId: string
    date: string
    inhibitFlightNotice: boolean
  }) => {
    const { user } = this.props
    if (!user?.roles.includes('admin')) {
      return
    }

    if (await this.handleSaveTargetDeliverySelection(true)) {
      this.setState({ editTargetDelivery: targetDelivery })
    }
  }

  handleCloseTargetDelivery = (date?: string) => {
    this.setState(
      ({ targetDeliveryExpanded }) => ({
        targetDeliveryExpanded: date || targetDeliveryExpanded,
        newTargetDelivery: false,
        editTargetDelivery: undefined,
      }),
      () => this.refresh()
    )
  }

  refresh = () => {
    const { onRefresh } = this.props

    if (onRefresh) {
      onRefresh()
    }
  }
}

const mapState = (state: RootStore) => ({
  user: selectMe(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}>(mapState)(PackageSelection)
