import { DeepPartial } from 'redux'

import * as Sentry from '@sentry/browser'

import { DataSetImageState } from '../graphql/types'
import { SendDeliveryDataAvailableParams } from '../pages/Organization/Delivery/Status/SendDeliveryDataAvailableDialog'
import { RootStore } from '../redux/types'
import handleError from '../util/handleError'
import { getJson, postJson } from './apiResource/createApiResource'
import { LoginResponse } from './models'

type SettingsDB = DeepPartial<RootStore>

const NOT_INITIALIZED = '-warning-not-initialized'
let bucketSuffix = NOT_INITIALIZED

const vvapi = {
  config: {
    bucketSuffix: async () => {
      if (bucketSuffix === NOT_INITIALIZED) {
        bucketSuffix = (
          await getJson<{ value: string }>('/api/v1/platform/bucketSuffix')
        ).value
      }

      return bucketSuffix
    },
  },

  auth: {
    login(email: string, password: string) {
      // catch failed login attempts
      Sentry.addBreadcrumb({
        category: 'api',
        data: {
          email,
        },
      })

      return postJson<LoginResponse>('/api/v1/auth/login', {
        body: { email, password },
      }).then(addUserContext)
    },
    register(
      firstName: string,
      lastName: string,
      email: string,
      password: string,
      token: string
    ) {
      // catch failed login attempts
      Sentry.addBreadcrumb({
        category: 'api',
        data: {
          email,
        },
      })

      return postJson<LoginResponse>(`/api/v3/invitation/${token}/accept/new`, {
        body: { user: { firstName, lastName, email, password } },
      }).then(addUserContext)
    },

    checkAuth() {
      return getJson<LoginResponse>('/api/v1/auth/me').then(addUserContext)
    },

    forgotPassword(email: string) {
      return postJson('/api/v1/auth/forgot-password', {
        body: { email },
      })
    },

    resetPassword(token: string, password: string) {
      return postJson('/api/v1/auth/reset-password', {
        body: { token, password },
      })
    },
  },

  notification: {
    sendOrderConfirmation(orderId: string, updated = false) {
      if (updated) {
        return postJson(
          `/api/v3/orders/${orderId}/sendOrderConfirmation?updated=true`
        )
      }
      return postJson(`/api/v3/orders/${orderId}/sendOrderConfirmation`)
    },
    sendFlightNotice(orderId: string, date: string) {
      return postJson(
        `/api/v3/target-deliveries/${orderId}/${date}/sendFlightNotice`
      )
    },
    sendFlightComplete(deliverId: string) {
      return postJson(`/api/v3/deliveries/${deliverId}/sendFlightComplete`)
    },
    sendDataAvailable(
      deliverId: string,
      body: SendDeliveryDataAvailableParams
    ) {
      return postJson(`/api/v3/deliveries/${deliverId}/sendDataAvailable`, {
        body,
      })
    },
  },

  settings: {
    get() {
      return getJson<Record<string, any>>(`/api/v1/settings`)
    },
    update: (settings: SettingsDB) => {
      return postJson<undefined>(`/api/v1/settings`, { body: settings })
    },
  },

  admin: {
    worker: {
      queueDataSetImagePipelineJob: (
        message: DataSetImagePipelineJobParams
      ) => {
        return postJson(`/api/v1/admin/worker/trigger/dataSetImagePipeline`, {
          body: message,
        })
      },
    },
  },
}

export interface DataSetImagePipelineJobParams {
  dataSetId?: number | string
  dataSetImageId?: number | string

  state: DataSetImageState
  keepIntermediateFiles?: boolean
  skip?: boolean
  skipTemporal?: boolean
}

const addUserContext = (resp: LoginResponse) => {
  try {
    const { user } = resp

    // sentry
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: user.email,
      })
    })
  } catch (e) {
    handleError(e)
  }

  return resp
}

export default vvapi
