import { createSelector } from '@reduxjs/toolkit'
import {
  MapEditorToolType,
  MapEditorToolsState,
} from '../../../redux/map-editor-pages/redux'
import { RootStore } from '../../../redux/types'

const getMapOptions = (
  state: MapEditorToolsState,
  pageType: MapEditorToolType
) => state?.[pageType]?.mapOptions

export const selectMapOptions = createSelector(
  [
    (state: RootStore) => state.mapEditorTools,
    (_: RootStore, pageType: MapEditorToolType) => pageType,
  ],
  (toolPages, pageType) => getMapOptions(toolPages, pageType)
)

export const selectMapOption = createSelector(
  [
    selectMapOptions,
    (_state: RootStore, _pageType: MapEditorToolType, id: string) => id,
  ],
  (mapOptions, id) => mapOptions[id]
)
