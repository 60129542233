import * as React from 'react'
import { PureComponent } from 'react'
import {
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { updatePreferences } from '../redux/preferences/redux'
import { AppDispatchProps } from '../redux/types'

interface ReduxProps {
  theme: string
}

const themeOptions = () => [
  {
    value: 'dark',
    title: i18n.t(keys.theme.dark),
  },
  {
    value: 'light',
    title: i18n.t(keys.theme.light),
  },
]

class PreferredTheme extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    return (
      <React.Fragment>
        <ListItem>
          <FormControl fullWidth>
            <InputLabel>{i18n.t(keys.theme.themePicker)}</InputLabel>
            <Select
              value={this.props.theme}
              onChange={this.handleChangeTheme}
              fullWidth
            >
              {themeOptions().map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
      </React.Fragment>
    )
  }

  handleChangeTheme = (event: SelectChangeEvent) => {
    this.props.dispatch(updatePreferences({ theme: event.target.value as any }))
  }
}

export default connect<ReduxProps, {}, AppDispatchProps>((state) => ({
  theme: state.preferences.theme,
}))(PreferredTheme)
