import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import errorAlert from '../../../admin/errorAlert'
import * as React from 'react'
import * as api from '../../../graphql/api'
import {
  ListTargetDeliveriesData,
  refreshListTargetDeliveries,
} from './selectListTargetDeliveries'
import TargetDeliveryForm from './TargetDeliveryForm'

interface Props {
  targetDelivery: ListTargetDeliveriesData
  open: boolean
  onClose: () => void
}

export default class EditTargetDelivery extends React.PureComponent<Props> {
  render() {
    return (
      <Dialog id="EditTargetDelivery" fullWidth open={!!this.props.open}>
        <DialogTitle>Edit Target Delivery</DialogTitle>
        <DialogContent>
          <TargetDeliveryForm
            type="edit"
            data={this.props.targetDelivery}
            onCancel={this.props.onClose}
            onSave={this.handleUpdate}
          />
        </DialogContent>
      </Dialog>
    )
  }

  handleUpdate = async (formData: ListTargetDeliveriesData) => {
    const { targetDelivery } = this.props
    const { date, inhibitFlightNotice } = { ...targetDelivery, ...formData }

    try {
      await api.targetDelivery.update({
        pk: { orderId: targetDelivery.orderId, date: targetDelivery.date },
        input: {
          date: date!,
          inhibitFlightNotice,
        },
      })

      refreshListTargetDeliveries()
      this.props.onClose()
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Delivery', message, this.state)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'EditTargetDelivery',
    },
  })
