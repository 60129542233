import * as React from 'react'
import { Stack, Select, MenuItem } from '@mui/material'
import { FilterBy, TableFormatter } from '../types'
import { FILTER } from '../filterRows'
import { useHistory } from 'react-router-dom'
import { url } from '../../../appNavigation/urls'
import { useSearchParams } from '../../../hooks/useRouter'
import i18n, { keys } from '../../../i18n'
import styled from '@emotion/styled'

interface Props<T> {
  filterId: string
  options?: string[]
  tableFormatters: TableFormatter<T>[],
  allOptionTitle?: string
}

export const DropdownTableFilter = <T,>({
  filterId,
  options,
  tableFormatters,
  allOptionTitle,
}: Props<T>) => {
  const [selectedOption, setSelectedOption] = React.useState<string>()
  const { filter: paramFilter, ...others } = useSearchParams()
  const history = useHistory()

  React.useEffect(() => {
    // if the search value has a value on load...
    if (!!paramFilter) {
      // find the first filter where the corresponding table formatter has a matching filterId
      const [filter] = FILTER.deserialize(paramFilter).filter(
        (f) => tableFormatters[f.index]['filterId'] === filterId
      )
      // if there was no search value but the corresponding search param exists...
      if (!!filter) {
        setSelectedOption(filter.value as string)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramFilter])

  const selectOption = (option?: string) => {
    setSearchParam(option)
    setSelectedOption(option)
  }

  const setSearchParam = (value?: string) => {
    // update the search parameters in the url.
    if (!!value && value.length > 0) {
      if (!tableFormatters || tableFormatters.length === 0) {
        return
      }

      const tableFormatterFilterBy = tableFormatters
        .map((_tf, index) => ({ index, op: 'eq', value } as FilterBy))
        .filter(
          (f) =>
            !!tableFormatters[f.index].filterBy &&
            tableFormatters[f.index]['filterId'] === filterId
        )

      const currentFilterBys = FILTER.deserialize(paramFilter ?? '')

      // filter out the filters that already exist for the same column index.
      const newFilterBys = [
        ...tableFormatterFilterBy,
        ...currentFilterBys,
      ].filter(
        (fb, i, filterBys) =>
          filterBys.findIndex((ifb) => ifb.index === fb.index) === i
      )

      const filter = FILTER.serialize(newFilterBys)
      // set the url if the search value changes (persistence)...
      history.push(
        url({ url: history.location.pathname }, {}, { ...others, filter })
      )
    } else {
      const tableFormatterFilterBy = tableFormatters
        .map((_tf, index) => ({ index } as FilterBy))
        .filter(
          (f) =>
            !!tableFormatters[f.index].filterBy &&
            tableFormatters[f.index]['filterId'] === filterId
        )
      const currentFilterBys = FILTER.deserialize(paramFilter ?? '')

      // filter out the columns that use the search bar.
      const nonInlineFilterBys = currentFilterBys.filter(
        (cfb) => !tableFormatterFilterBy?.some((tff) => tff.index === cfb.index)
      )
      const filter = FILTER.serialize(nonInlineFilterBys ?? '')
      history.push(
        url({ url: history.location.pathname }, {}, { ...others, filter })
      )
    }
  }

  const StyledSelect = styled(Select)({
    minWidth: '150px',
    height: '37px',
  })

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      <StyledSelect 
        value={selectedOption} 
        variant='outlined'
        displayEmpty
        size="small">
        <MenuItem value={undefined} onClick={() => selectOption()}>
            <em style={{color: 'grey'}}>{allOptionTitle ?? i18n.t(keys.all)}</em>
        </MenuItem>
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            value={option}
            onClick={() => selectOption(option)}
          >
            {option}
          </MenuItem>
        ))}
      </StyledSelect>
    </Stack>
  )
}
