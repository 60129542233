import { createSelector } from 'reselect'

import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { SourceTypes } from '../graphql/types'
import { indexArray, indexMultiArray } from '../util/indexArray'
import { selectOrganizationId } from './selectOrganizationId'
import { parcelFilterStatsProducts } from './selectOrgMapData'
import { selectPreferredLanguage } from './selectPreferredLanguage'
import { MapSourceDefData } from './types'

const FETCH_PRODUCT_DEFS = gql`
  query FETCH_PRODUCT_DEFS($orgId: Int!, $lang: String!) {
    mapSourceDefs: MapSourceDef(
      where: {
        _or: [
          { organizationId: { _is_null: true } }
          { organizationId: { _eq: $orgId } }
        ]
      }
    ) {
      id
      type
      properties: MapSourceProperties {
        ...Property
      }

      mapLayerDefs: MapLayerDefs {
        id
        name(path: $lang)
        description(path: $lang)
        order
        mapLayerDefGroupId
        layerGroupLabel(path: $lang)
        pdfFilename
        settings: MapLayerSettings {
          id
          visualization
          threshold
          organizationId
          opacity
          numberOfGroups
          groupBy
          coverageMin
          coverageMax
          coverage
        }
        dataProperty: DataProperty {
          ...Property
        }
        noDataProperty: NoDataProperty {
          ...Property
        }
        coverageProperty: CoverageProperty {
          ...Property
        }
        weightProperty: WeightProperty {
          ...Property
        }
        colorProfiles: MapColorProfiles(
          where: {
            _or: [
              { organizationId: { _is_null: true } }
              { organizationId: { _eq: $orgId } }
            ]
          }
        ) {
          id
          mapLayerDefId
          display
          name(path: $lang)
          description(path: $lang)
          dataStops
          minLabel
          maxLabel
          noDataLabel
          coverageLabel
          coverageColor
          noDataColor
          type
          visualizations: MapColorProfileVisualizations {
            visualization
            order
            default
          }
        }
      }
    }
  }
  fragment Property on MapSourceProperty {
    id
    type
    name(path: $lang)
    range
    valueType
    valueUnit
    measurementType
    filterable
    classes
    acronym(path: $lang)
    property
    showInPopup
    noDataValue
    sourceType: MapSourceDef {
      type
    }
  }
`

const { selector: selectListMapSourceDefs, refresh: refreshListMapSourceDefs } =
  createAsyncSelector({
    resource: 'global.mapSourceDefs',
    inputs: {
      orgId: selectOrganizationId,
      lang: selectPreferredLanguage,
    },
    fetcher: async ({ orgId, lang }) => {
      if (!orgId) {
        return
      }

      const data = await query<{
        mapSourceDefs: MapSourceDefData[]
      }>({
        variables: {
          orgId,
          lang,
        },
        query: FETCH_PRODUCT_DEFS,
      })

      if (data) {
        // Add parent references
        data.mapSourceDefs.forEach((mapSourceDef) => {
          mapSourceDef.properties.forEach(
            (property) => (property.mapSourceDef = mapSourceDef)
          )

          mapSourceDef.mapLayerDefs.forEach((mapLayerDef) => {
            mapLayerDef.mapSourceDef = mapSourceDef
            mapLayerDef.colorProfiles.forEach((colorProfile) => {
              colorProfile.mapLayerDef = mapLayerDef
            })

            mapLayerDef.colorProfiles.forEach((colorProfile) => {
              colorProfile.mapLayerDef = mapLayerDef
            })
          })
        })
      }

      return data
    },
  })

export { selectListMapSourceDefs, refreshListMapSourceDefs }

/** sort according to a sensible map layer hierarchy */
const sourceTypeOrder: SourceTypes[] = [
  'raster-background',
  'raster',
  'zone',
  'line',
  'point',
  'field-bounds',
]

const DEFAULT: MapSourceDefData[] = []
export const selectMapSourceDefs = createSelector(
  [selectListMapSourceDefs],
  ({ data }) =>
    data
      ? data.mapSourceDefs.sort(
          (a, b) =>
            sourceTypeOrder.indexOf(a.type) - sourceTypeOrder.indexOf(b.type)
        )
      : DEFAULT
)

export const selectMapSourceDefsById = createSelector(
  [selectMapSourceDefs],
  (mapSourceDefs) => indexArray(mapSourceDefs, 'id')
)

export const selectMapLayerDefs = createSelector(
  [selectMapSourceDefs],
  (mapSourceDefs) =>
    mapSourceDefs
      .map(({ mapLayerDefs }) => mapLayerDefs)
      .reduce((acc, next) => [...acc, ...next], [])
      .sort(
        (a, b) =>
          sourceTypeOrder.indexOf(a.mapSourceDef.type) -
            sourceTypeOrder.indexOf(b.mapSourceDef.type) || a.order - b.order
      )
)

export const selectProducts = createSelector(
  [selectMapLayerDefs],
  (mapLayerDefs) =>
    mapLayerDefs.filter(
      ({ mapSourceDef: { type } }) => type !== 'raster-background'
    )
)

export const selectProductsForOrg = createSelector(
  [selectProducts, parcelFilterStatsProducts],
  (products, productIds) =>
    products
      .filter(({ id }) => productIds.includes(id))
      .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
)

export const selectProductsForOrgById = createSelector(
  [selectProductsForOrg],
  (products) => indexArray(products, 'id')
)

export const selectPDFEnabledProductsForOrg = createSelector(
  selectProductsForOrg,
  (productsForOrg) => productsForOrg.filter((product) => !!product.pdfFilename)
)

export const selectPDFEnabledProductsForOrgById = createSelector(
  selectPDFEnabledProductsForOrg,
  (pdfProductsForOrg) => indexArray(pdfProductsForOrg, 'id')
)

export const selectMapLayerDefsById = createSelector(
  [selectMapLayerDefs],
  (mapLayerDefs) => indexArray(mapLayerDefs, 'id')
)

export const selectColorProfiles = createSelector(
  [selectMapLayerDefs],
  (mapLayerDefs) =>
    mapLayerDefs
      .map(({ colorProfiles }) => colorProfiles)
      .reduce((acc, next) => [...acc, ...next], [])
)

export const selectColorProfilesById = createSelector(
  [selectColorProfiles],
  (colorProfiles) => indexArray(colorProfiles, 'id')
)

export const selectColorProfilesByProductId = createSelector(
  [selectColorProfiles],
  (colorProfiles) => indexMultiArray(colorProfiles, 'mapLayerDefId')
)
