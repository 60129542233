import { createTableActionIconButton } from '../../../UI/Table/createTableActionIconButton'
import { TableAction } from '../../../UI/Table/types'
import { Job } from './types'

const noop = () => undefined

const refresh = (_refresh: () => void = noop): TableAction<Job> => ({
  button: createTableActionIconButton({
    title: 'Refresh',
    icon: 'autorenew',
    key: 'refresh',
  }),
  action: () => _refresh(),
})

export const actions = {
  refresh,
}

export const tableActions = Object.values(actions)
