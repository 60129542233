import { createGQLResource } from './createGQLResource'
import { ScheduledNotification_update_input } from './types'
import {
  AdminRole,
  AdminRole_create_input,
  AdminRole_update_input,
  DataSet,
  DataSet_create_input,
  DataSet_update_input,
  DataSetImage,
  DataSetImage_create_input,
  DataSetImage_update_input,
  DeliveryGroup,
  DeliveryGroup_create_input,
  DeliveryGroup_update_input,
  DeliveryParcel,
  DeliveryParcel_create_input,
  DeliveryParcel_update_input,
  DeliveryProcGroup,
  DeliveryProcGroup_create_input,
  DeliveryProcGroup_update_input,
  Flight,
  Flight_create_input,
  Flight_update_input,
  Job,
  Job_create_input,
  Job_update_input,
  MapColorProfile,
  MapColorProfile_create_input,
  MapColorProfile_update_input,
  MapColorProfileVisualization,
  MapColorProfileVisualization_create_input,
  MapColorProfileVisualization_update_input,
  MapLayer,
  MapLayer_create_input,
  MapLayer_update_input,
  MapLayerDef,
  MapLayerDef_create_input,
  MapLayerDef_update_input,
  MapLayerDefGroup,
  MapLayerDefGroup_create_input,
  MapLayerDefGroup_update_input,
  MapLayerSettings,
  MapLayerSettings_create_input,
  MapLayerSettings_update_input,
  MapSourceDef,
  MapSourceDef_create_input,
  MapSourceDef_update_input,
  MapSourceProperty,
  MapSourceProperty_create_input,
  MapSourceProperty_update_input,
  MonitorEvent,
  MonitorEvent_create_input,
  MonitorEvent_update_input,
  Notification,
  Notification_create_input,
  Notification_update_input,
  Organization,
  Organization_create_input,
  Organization_update_input,
  OrganizationGroup,
  OrganizationGroup_create_input,
  OrganizationGroup_update_input,
  Package,
  Package_create_input,
  Package_update_input,
  Parcel,
  Parcel_create_input,
  Parcel_update_input,
  ParcelDataSet,
  ParcelDataSet_create_input,
  ParcelDataSet_update_input,
  Pipeline,
  Pipeline_create_input,
  Pipeline_update_input,
  ScheduledNotification,
  ScheduledNotification_create_input,
  Subscription,
  Subscription_create_input,
  Subscription_update_input,
  TargetDelivery,
  TargetDelivery_create_input,
  TargetDelivery_update_input,
  User,
  User_create_input,
  User_update_input,
  Variable,
  Variable_create_input,
  Variable_update_input,
} from './types'

export const variables = createGQLResource<
  Variable,
  Variable_create_input,
  Variable_update_input,
  'key'
>('vv_Variables', { key: 'String' })

export const adminRoles = createGQLResource<
  AdminRole,
  AdminRole_create_input,
  AdminRole_update_input,
  'userId' | 'role'
>('UserRole', { userId: 'Int', role: 'String' })

/** 'package' is a reserved word */
export const productPackage = createGQLResource<
  Package,
  Package_create_input,
  Package_update_input,
  'id'
>('Package', { id: 'uuid' })

export { order } from './Order'

export const targetDelivery = createGQLResource<
  TargetDelivery,
  TargetDelivery_create_input,
  TargetDelivery_update_input,
  'orderId' | 'date'
>('TargetDelivery', { orderId: 'uuid', date: 'date' })

export { delivery } from './Delivery'

export const deliveryParcel = createGQLResource<
  DeliveryParcel,
  DeliveryParcel_create_input,
  DeliveryParcel_update_input,
  'deliveryId' | 'parcelId'
>('DeliveryParcel', { deliveryId: 'uuid', parcelId: 'Int' })

export const deliveryProcGroup = createGQLResource<
  DeliveryProcGroup,
  DeliveryProcGroup_create_input,
  DeliveryProcGroup_update_input,
  'deliveryId' | 'procGroup'
>('Delivery_ProcGroup', { deliveryId: 'uuid', procGroup: 'String' })

export { deliveryParcelFile } from './DeliveryParcelFile'
export { deliveryGroupFile } from './DeliveryGroupFile'

export const deliveryGroup = createGQLResource<
  DeliveryGroup,
  DeliveryGroup_create_input,
  DeliveryGroup_update_input,
  'deliveryId' | 'groupId'
>('DeliveryGroup', { deliveryId: 'uuid', groupId: 'Int' })

export const flight = createGQLResource<
  Flight,
  Flight_create_input,
  Flight_update_input,
  'deliveryId' | 'date'
>('Flight', { deliveryId: 'uuid', date: 'date' })

export const job = createGQLResource<
  Job,
  Job_create_input,
  Job_update_input,
  'id'
>('maestro_Job', { id: 'uuid' })

export const pendingJob = createGQLResource<
  Job,
  Job_create_input,
  Job_update_input,
  'id'
>('maestro_View_PendingJobs', { id: 'uuid' })

export const mapColorProfile = createGQLResource<
  MapColorProfile,
  MapColorProfile_create_input,
  MapColorProfile_update_input,
  'id'
>('MapColorProfile', { id: 'uuid' })

export const mapColorProfileVisualization = createGQLResource<
  MapColorProfileVisualization,
  MapColorProfileVisualization_create_input,
  MapColorProfileVisualization_update_input,
  'id'
>('MapColorProfileVisualization', { id: 'uuid' })

export const mapLayer = createGQLResource<
  MapLayer,
  MapLayer_create_input,
  MapLayer_update_input,
  'id'
>('MapLayer', { id: 'uuid' })

export const mapLayerDef = createGQLResource<
  MapLayerDef,
  MapLayerDef_create_input,
  MapLayerDef_update_input,
  'id'
>('MapLayerDef', { id: 'uuid' })

export { mapSource } from './MapSource'

export const mapLayerDefGroup = createGQLResource<
  MapLayerDefGroup,
  MapLayerDefGroup_create_input,
  MapLayerDefGroup_update_input,
  'id'
>('MapLayerDefGroup', { id: 'uuid' })

export const mapSourceDef = createGQLResource<
  MapSourceDef,
  MapSourceDef_create_input,
  MapSourceDef_update_input,
  'id'
>('MapSourceDef', { id: 'uuid' })

export const mapSourceProperty = createGQLResource<
  MapSourceProperty,
  MapSourceProperty_create_input,
  MapSourceProperty_update_input,
  'id'
>('MapSourceProperty', { id: 'uuid' })

export const monitorEvent = createGQLResource<
  MonitorEvent,
  MonitorEvent_create_input,
  MonitorEvent_update_input,
  'id'
>('MonitorEvent', { id: 'uuid' })

export const organization = createGQLResource<
  Organization,
  Organization_create_input,
  Organization_update_input,
  'id'
>('Organization', { id: 'Int' })

export const organizationGroup = createGQLResource<
  OrganizationGroup,
  OrganizationGroup_create_input,
  OrganizationGroup_update_input,
  'id'
>('OrganizationGroup', { id: 'Int' })

export const parcel = createGQLResource<
  Parcel,
  Parcel_create_input,
  Parcel_update_input,
  'id'
>('Parcel', { id: 'Int' })

export const parcelDataSet = createGQLResource<
  ParcelDataSet,
  ParcelDataSet_create_input,
  ParcelDataSet_update_input,
  'id'
>('ParcelDataSet', { id: 'Int' })

export const pipeline = createGQLResource<
  Pipeline,
  Pipeline_create_input,
  Pipeline_update_input,
  'id'
>('maestro_Pipeline', { id: 'uuid' })

export const user = createGQLResource<
  User,
  User_create_input,
  User_update_input,
  'id'
>('User', { id: 'Int' })

export const subscription = createGQLResource<
  Subscription,
  Subscription_create_input,
  Subscription_update_input,
  'id'
>('Subscription', { id: 'Int' })

export const dataSet = createGQLResource<
  DataSet,
  DataSet_create_input,
  DataSet_update_input,
  'id'
>('DataSet', { id: 'Int' })

export const dataSetImage = createGQLResource<
  DataSetImage,
  DataSetImage_create_input,
  DataSetImage_update_input,
  'id'
>('DataSetImage', { id: 'Int' })

export const mapLayerSettings = createGQLResource<
  MapLayerSettings,
  MapLayerSettings_create_input,
  MapLayerSettings_update_input,
  'id'
>('MapLayerSettings', { id: 'uuid' })

export const scheduledNotifications = createGQLResource<
  ScheduledNotification,
  ScheduledNotification_create_input,
  ScheduledNotification_update_input,
  'id'
>('ScheduledNotification', { id: 'Int' })

export const appNotifications = createGQLResource<
  Notification,
  Notification_create_input,
  Notification_update_input,
  'id'
>('Notification', { id: 'uuid' })
