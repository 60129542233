const identity = <T>(itself: T): T => itself

interface Props<T> {
  array: T[]
  order?: 'asc' | 'desc'
  orderBy?: string
  valueMap?: Record<string, (x: any) => any>
}

/**
 * From https://v3.material-ui.com/demos/tables/
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default <T>(props: Props<T>): T[] => {
  const { array, order, orderBy, valueMap = {} } = props

  if (!orderBy) {
    return array
  }

  const getValue = valueMap[orderBy] || identity

  return [...array].sort((a, b) => {
    const diff = basicCompare(getValue(a[orderBy]), getValue(b[orderBy]))

    if (order === 'desc') {
      return diff * -1
    }

    return diff
  })
}

const basicCompare = (a: any, b: any) => {
  if (a.localeCompare) {
    return a.localeCompare(b, undefined, { numeric: true })
  }

  if (b < a) {
    return -1
  }
  if (b > a) {
    return 1
  }

  return 0
}
