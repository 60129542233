import * as React from 'react'
import { ExportTemplate } from './types'

interface Input<T> {
  data: T
  formatters: ExportTemplate<T>[]
}

export type DownloadFunction = (
  formatName: string,
  downloadName: string,
  ...args: any[]
) => void

export const useExportable = <T>({
  data,
  formatters,
}: Input<T>): [DownloadFunction] => {
  const downloads = React.useMemo(
    () =>
      formatters.reduce((acc, formatter) => {
        acc[formatter.exportId] = (downloadName, ...args: any[]) =>
          formatter.export(data, downloadName, ...args)
        return acc
      }, {} as Record<string, (...args: any[]) => void>),
    [data, formatters]
  )

  const download: DownloadFunction = React.useCallback(
    (formatName: string, downloadName: string, ...args: any[]) => {
      const downloadFn = downloads[formatName]
      if (downloadFn) {
        downloadFn(downloadName, ...args)
      } else {
        console.warn(`No download function found for format: ${formatName}`)
      }
    },
    [downloads]
  )

  return [download]
}
