export const formatLocationError = (locationError: number | undefined) => {
  if (!locationError || locationError >= 10) {
    return '>999cm'
  } else if (locationError > 1) {
    return `${(locationError * 100).toFixed(0)}cm`
  } else if (locationError > 0.1) {
    return `${(locationError * 100).toFixed(1)}cm`
  } else {
    return `${(locationError * 100).toFixed(3)}cm`
  }
}
