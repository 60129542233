import * as React from 'react'

import { CircularProgress, CircularProgressProps } from '@mui/material'

// tslint:disable-next-line:variable-name
const LoadingOverlay = (props: CircularProgressProps) => (
  <div
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress
      {...props}
      style={{
        ...props.style,
      }}
    />
  </div>
)

export default LoadingOverlay
