import * as React from 'react'
import './StatsTable.scss'

interface StatsTableProps {
  plantCount: StatsTableItemProp
  area: StatsTableItemProp
  mean: StatsTableItemProp
  stdev: StatsTableItemProp
  usage: StatsTableItemProp
  variance: StatsTableItemProp
  hideUsageAndVariance?: boolean
}
// tslint:disable-next-line: function-name
export function StatsTable(props: StatsTableProps) {
  return (
    <div className="StatsTable StatsTable__flex_h">
      <div className="StatsTable__flex_h">
        {StatsTableItem(props.plantCount)}
        {StatsTableItem(props.area)}
      </div>
      <div className="StatsTable__flex_v">
        <div className="StatsTable__flex_h">
          {StatsTableItem(props.mean)}
          {StatsTableItem(props.stdev)}
        </div>
        {!props.hideUsageAndVariance && (
          <div className="StatsTable__flex_h">
            {StatsTableItem(props.usage)}
            {StatsTableItem(props.variance)}
          </div>
        )}
      </div>
    </div>
  )
}

interface StatsTableItemProp {
  value: string
  label: string
}

// tslint:disable-next-line: function-name
function StatsTableItem(props: StatsTableItemProp) {
  return (
    <div className="StatsTableItem StatsTable__flex_v">
      <div className="StatsTableItem__value">{props.value}</div>
      <div className="StatsTableItem__label">{props.label}</div>
    </div>
  )
}
