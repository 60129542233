import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import dateFromYMD from '../../../admin/mapdata/dateFromYMD'
import * as api from '../../../graphql/api'
import { RootStore } from '../../../redux/types'

export interface GetFlightData {
  organizationId: string
  deliveryId: string
  comment?: string
  date?: string
  createdAt: string
  updatedAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }
}

export interface FlightData {
  organizationId: string
  deliveryId: string
  comment?: string
  date?: Date
  createdAt: string
  updatedAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }
}

const get = ({
  deliveryId,
  flightDate,
}: {
  deliveryId: string
  flightDate: string
}) =>
  api.flight.get<GetFlightData>({
    pk: { deliveryId, date: flightDate },
    returning: `{
      organizationId
      deliveryId
      comment
      date
      createdAt
      updatedAt

      CreatedBy {
        firstName
      }
      UpdatedBy {
        firstName
      }
    }`,
  })

const { selector: selectGetFlight, refresh: refreshGetFlight } =
  createAsyncSelector({
    resource: 'GetFlightData',
    inputs: {
      deliveryId: (state: RootStore) => state.router.params.deliveryId,
      flightDate: (state: RootStore) => state.router.params.flightDate,
    },
    fetcher: async ({ deliveryId, flightDate }) => {
      if (!deliveryId || !flightDate) {
        return
      }

      const { data } = await get({ deliveryId, flightDate })

      if (!data) {
        throw new Error('No Data')
      }

      return { ...data, date: data.date ? dateFromYMD(data.date) : undefined }
    },
  })

export { selectGetFlight, refreshGetFlight }
