import { createSelector } from 'reselect'

import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { MapLayerDefGroup } from '../graphql/types'
import { indexArray } from '../util/indexArray'
import { selectPreferredLanguage } from './selectPreferredLanguage'

const FETCH_MAP_LAYER_GROUPS = gql`
  query FETCH_MAP_LAYER_GROUPS($lang: String!) {
    mapLayerDefGroups: MapLayerDefGroup {
      id
      name(path: $lang)
    }
  }
`

const { selector: selectListMapLayerDefGroups, refresh: refreshListMapLayerDefGroups } =
  createAsyncSelector({
    resource: 'global.mapLayerDefGroups',
    inputs: {
      lang: selectPreferredLanguage,
    },
    fetcher: async ({ lang }) => {

      const data = await query<{
        mapLayerDefGroups: MapLayerDefGroup[]
      }>({
        variables: {
          lang,
        },
        query: FETCH_MAP_LAYER_GROUPS,
      })


      return data
    },
  })


const EMPTY_MAP_LAYER_DEF_GROUPS: MapLayerDefGroup[] = []

export const selectProductGroups = createSelector(selectListMapLayerDefGroups, (listMapLayerDefGroups) => {
  return listMapLayerDefGroups?.data?.mapLayerDefGroups ?? EMPTY_MAP_LAYER_DEF_GROUPS
})

export const selectProductGroupsById = createSelector(selectProductGroups, (productGroups) =>
  indexArray(productGroups, 'id')
)

export { selectListMapLayerDefGroups, refreshListMapLayerDefGroups }