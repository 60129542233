import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Switch,
  TextField,
} from '@mui/material'
import * as React from 'react'
import {
  FeatureFlag,
  FeatureFlagOrganizationAccess,
  FeatureFlagUserAccess,
} from '../../../graphql/types'
import { selectOrganizationList } from '../../../data/selectOrganizationList'
import { useRedux } from '../../../hooks/useRedux'
import { UserData, selectUserList } from '../../../data/selectUserList'
import { useTheme } from '@mui/styles'
import { indexArray } from '../../../util/indexArray'

interface AutoCompleteParams {
  id?: string
  title?: string
}

const allOption: AutoCompleteParams = { id: '-1', title: 'All' }

interface Props {
  ff?: FeatureFlag
  cancel?: () => void
  commitFeatureFlag?: (featureFlag: FeatureFlag) => void
  deleteFeatureFlag?: (featureFlag: FeatureFlag) => void
}

export const FeatureFlagForm = ({
  ff,
  cancel,
  commitFeatureFlag,
  deleteFeatureFlag,
}: Props) => {
  const [state] = useRedux()
  const theme = useTheme()
  const fetchedOrganizations = selectOrganizationList(state)
  const fetchedUsers = selectUserList(state)

  const getUserFormat = (user: UserData | undefined) => {
    if (!user) {
      return ''
    }
    return `${user.firstName} ${user.lastName} (${user.email})`
  }

  const organizations = React.useMemo(
    () =>
      indexArray(
        [
          allOption,
          ...(fetchedOrganizations?.data?.map<AutoCompleteParams>((org) => ({
            id: org.id,
            title: org.name,
          })) ?? []),
        ],
        'id'
      ),
    [fetchedOrganizations?.data]
  )
  const users = React.useMemo(
    () =>
      indexArray(
        [
          allOption,
          ...(fetchedUsers?.data?.map<AutoCompleteParams>((user) => ({
            id: user.id,
            title: getUserFormat(user),
          })) ?? []),
        ],
        'id'
      ),
    [fetchedUsers?.data]
  )

  const [featureFlag, setFeatureFlag] = React.useState<Partial<FeatureFlag>>(
    ff ?? { active: true }
  )

  const isComplete = React.useMemo(
    () =>
      !!featureFlag?.id &&
      featureFlag?.active !== undefined &&
      !!featureFlag?.name &&
      !!featureFlag?.desc,
    [featureFlag?.id, featureFlag?.active, featureFlag?.name, featureFlag?.desc]
  )

  React.useEffect(() => {
    if (!ff) {
      return
    }
    setFeatureFlag(ff)
  }, [ff])

  const updateOrganizations = (updatedOrgs: AutoCompleteParams[]) => {
    const currentValue =
      featureFlag?.FeatureFlagOrganizationAccesses?.map(
        (ffo) => ffo.organizationId
      ) ?? []
    let idArray = updatedOrgs.map((uo) => Number(uo.id))
    const newValues = idArray.filter((x) => !currentValue.includes(x))

    if (
      (newValues.some((nv) => nv === -1) && currentValue.length !== 0) ||
      idArray.length === 0
    ) {
      setFeatureFlag((old) => ({
        ...old,
        FeatureFlagOrganizationAccesses: undefined,
      }))
      return
    }

    setFeatureFlag((old) => ({
      ...old,
      FeatureFlagOrganizationAccesses: idArray
        .filter((ffo) => ffo !== -1)
        .map<FeatureFlagOrganizationAccess>((v) => ({
          featureFlagId: old?.id ?? '',
          organizationId: v,
        })),
    }))
  }

  const updateUsers = (updatedUsers: AutoCompleteParams[]) => {
    const currentValue =
      featureFlag?.FeatureFlagUserAccesses?.map((ffo) => ffo.userId) ?? []
    let idArray = updatedUsers.map((uu) => Number(uu.id)) as number[]
    const newValues = idArray.filter((x) => !currentValue.includes(x))

    if (
      (newValues.some((nv) => nv === -1) && currentValue.length !== 0) ||
      idArray.length === 0
    ) {
      setFeatureFlag((old) => ({
        ...old,
        FeatureFlagUserAccesses: undefined,
      }))
      return
    }

    setFeatureFlag((old) => ({
      ...old,
      FeatureFlagUserAccesses: idArray
        .filter((ffu) => ffu !== -1)
        .map<FeatureFlagUserAccess>((v) => ({
          featureFlagId: old?.id ?? '',
          userId: v,
        })),
    }))
  }

  return (
    <Paper sx={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            helperText="The id of the feature flag used to identify the feature. Must be unique. Send this to the developer of the new feature."
            name="id"
            label={`Feature Flag ID`}
            fullWidth
            multiline
            required
            value={featureFlag.id}
            disabled={!!ff} // no changing id once created.
            onChange={(e) =>
              setFeatureFlag((old) => ({
                ...old,
                id: e.target.value,
                FeatureFlagOrganizationAccesses:
                  old.FeatureFlagOrganizationAccesses?.map<FeatureFlagOrganizationAccess>(
                    (ffo) => ({ ...ffo, featureFlagId: e.target.value })
                  ),
                FeatureFlagUserAccesses:
                  old.FeatureFlagUserAccesses?.map<FeatureFlagUserAccess>(
                    (ffo) => ({ ...ffo, featureFlagId: e.target.value })
                  ),
              }))
            }
            InputLabelProps={{
              shrink: !!featureFlag.id,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            helperText="The name of the feature flag."
            name="name"
            label={`Feature Name`}
            fullWidth
            multiline
            required
            value={featureFlag.name}
            onChange={(e) =>
              setFeatureFlag((old) => ({ ...old, name: e.target.value }))
            }
            InputLabelProps={{
              shrink: !!featureFlag.name,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            helperText="The description of what the feature flag does."
            name="desc"
            label={`Feature Description`}
            fullWidth
            multiline
            required
            value={featureFlag.desc}
            onChange={(e) =>
              setFeatureFlag((old) => ({
                ...old,
                desc: e.target.value,
              }))
            }
            InputLabelProps={{
              shrink: !!featureFlag.desc,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <Autocomplete
              multiple
              filterSelectedOptions
              options={Object.values(organizations ?? {}) ?? []}
              getOptionLabel={(option) => option?.title ?? ''}
              renderInput={(params) => (
                <TextField {...params} label="Feature Organizations Access" />
              )}
              value={
                (featureFlag?.FeatureFlagOrganizationAccesses?.length ?? 0) > 0
                  ? featureFlag.FeatureFlagOrganizationAccesses?.map(
                      (ffo) => organizations[ffo.organizationId]
                    )
                  : [allOption]
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const props = getTagProps({ index })
                  if (option.id === '-1') {
                    return <Chip label="All" {...props} onDelete={undefined} />
                  }
                  return <Chip {...props} label={option.title} />
                })
              }
              onChange={(_, v) => updateOrganizations(v)}
            />
            <FormHelperText sx={{ marginLeft: 0 }}>
              {'The organizations that have access to the feature.'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <Autocomplete
              multiple
              filterSelectedOptions
              options={Object.values(users ?? {}) ?? []}
              getOptionLabel={(option) => option?.title ?? ''}
              renderInput={(params) => (
                <TextField {...params} label="Feature Users Access" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const props = getTagProps({ index })
                  if (option.id === '-1') {
                    return <Chip label="All" {...props} onDelete={undefined} />
                  }
                  return <Chip {...props} label={option.title} />
                })
              }
              value={
                (featureFlag?.FeatureFlagUserAccesses?.length ?? 0) > 0
                  ? featureFlag.FeatureFlagUserAccesses?.map(
                      (ffo) => users[ffo.userId]
                    )
                  : [allOption]
              }
              onChange={(_, v) => updateUsers(v)}
            />
            <FormHelperText sx={{ marginLeft: 0 }}>
              {
                'The users that have access to a feature. If you have an organizations selected, users will only have access to the feature when accessing that org.'
              }
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  name="userIds"
                  checked={featureFlag.active}
                  required
                  onChange={(e) =>
                    setFeatureFlag((old) => ({
                      ...old,
                      active: e.target.checked,
                    }))
                  }
                />
              }
              label="Active"
            />
            <FormHelperText sx={{ marginLeft: 0 }}>
              {
                'The users that have access to a feature. If you have an organizations selected, users will only have access to the feature when accessing that org.'
              }
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            alignContent="right"
            spacing={2}
            justifyContent="right"
          >
            {!!cancel ? (
              <Grid item>
                <Button onClick={cancel}>Cancel</Button>
              </Grid>
            ) : null}
            {!!deleteFeatureFlag ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => !!ff && deleteFeatureFlag(ff)}
                >
                  Delete
                </Button>
              </Grid>
            ) : null}
            {!!commitFeatureFlag ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isComplete}
                  onClick={() =>
                    !!featureFlag &&
                    commitFeatureFlag(featureFlag as FeatureFlag)
                  }
                >
                  Save
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
