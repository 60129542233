import * as React from 'react'
import { makeStyles } from '@mui/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Stack,
  ListItem,
  List,
  useTheme,
  Typography,
} from '@mui/material'
import i18n, { keys } from '../../i18n'
import { Close, Search } from '@mui/icons-material'
import { setToggle } from '../../util/setToggle'

const useStyles = makeStyles((theme) => ({
  dialog: {
    paddingTop: 0,
    marginTop: theme.spacing(2),
  },
  header: {
    position: 'sticky',
    top: 0,
    background: 'var(--background)',
    zIndex: 3,
  },
  card: {
    paddingLeft: theme.spacing(1),
    padding: '0px',
    borderRadius: '3px',
    backgroundColor: 'var(--background2)',
  },
  checkbox: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  searchBar: {
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  closeButton: {
    cursor: 'pointer',
  },
  cancel: {
    color: 'var(--white)',
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.8px',
    letterSpacing: '0.05em',
    textAlign: 'left',
    opacity: 0.8,
  },
  divider: {
    alignSelf: 'center',
    flexGrow: 1,
    margin: '0px 0px 0px 10px',
  },
}))

interface SelectionProps<T extends { name: string; id: string }> {
  open: boolean
  title: string
  sectionTitle: string
  items: T[] // Generic array of items
  defaultSelectedItems?: T[]
  onClose: () => void
  onCommit: (selectedItems: T[]) => void
  topChildren?: React.ReactNode
  additionalSections?: Record<string, React.ReactNode>
  exportFileName?: string
  itemComponent?: (props: { item: T }) => React.ReactNode
  validate?: (props: SelectionProps<T>) => boolean
}

const ItemSelectionForm = <T extends { name: string; id: string }>(
  props: SelectionProps<T>
) => {
  const {
    open,
    title,
    sectionTitle,
    items,
    defaultSelectedItems,
    onClose,
    onCommit,
    additionalSections,
    itemComponent,
    validate,
  } = props

  const classes = useStyles()
  const theme = useTheme()

  const [selectedItemIds, setSelectedItemIds] = React.useState<Set<string>>(
    new Set<string>()
  )
  const [searchValue, setSearchValue] = React.useState('')

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItemIds(
      event.target.checked
        ? new Set(items.map((item) => item.id.toString()))
        : new Set([])
    )
  }

  const selectAll = React.useMemo(() => {
    return selectedItemIds.size === items.length
  }, [selectedItemIds])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = event.target.value

    setSelectedItemIds(setToggle(selectedItemIds, itemId))
  }

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(e.target.value)
  }

  const filteredItems = React.useMemo(
    () =>
      items.filter((i) =>
        i.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [items, searchValue]
  )

  React.useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setSelectedItemIds(new Set([]))
        setSearchValue('')
      }, theme.transitions.duration.leavingScreen)
    }
    if (open) {
      setSelectedItemIds(new Set(defaultSelectedItems?.map((i) => i.id) || []))
    }
  }, [theme.transitions.duration.leavingScreen, open, defaultSelectedItems])

  const validateFunction = React.useCallback(() => {
    if (validate) {
      return validate(props) && selectedItemIds.size > 0
    }
    return selectedItemIds.size > 0
  }, [validate, selectedItemIds, props])

  const isValid = React.useMemo(() => validateFunction(), [validateFunction])

  return (
    <Dialog className={classes.dialog} open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">{title}</Typography>
          <Close onClick={onClose} className={classes.closeButton} />
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialog}>
        <Stack direction="column" spacing={2}>
          <Stack>
            <Stack className={classes.header} direction="column" spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="overline" className={classes.subTitle}>
                  {sectionTitle}
                </Typography>
                <Divider
                  flexItem
                  orientation="horizontal"
                  className={classes.divider}
                />
              </Stack>
              <TextField
                className={classes.searchBar}
                label="Search"
                variant="standard"
                value={searchValue}
                onChange={handleSearchChange}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.card}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={selectAll}
                      color="primary"
                    />
                  }
                  label={i18n.t(keys.generic.selectAll)}
                />
              </div>
            </Stack>
            <List
              sx={{
                width: '100%',
                maxHeight: '324px',
                overflowY: 'scroll',
                padding: 0,
              }}
            >
              {filteredItems.map((item) => (
                <ListItem key={item.id} className={classes.checkbox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxChange}
                        checked={selectedItemIds.has(item.id.toString())}
                        value={item.id}
                        color="primary"
                      />
                    }
                    label={
                      itemComponent ? (
                        itemComponent({ item })
                      ) : (
                        <Typography variant="body1" component="span">
                          {item.name}
                        </Typography>
                      )
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Stack>
          {additionalSections &&
            Object.entries(additionalSections).map(([key, value]) => (
              <Stack key={key} direction="column" spacing={1}>
                <Stack
                  direction="row"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="overline" className={classes.subTitle}>
                    {key}
                  </Typography>
                  <Divider
                    flexItem
                    orientation="horizontal"
                    className={classes.divider}
                  />
                </Stack>
                {value}
              </Stack>
            ))}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button className={classes.cancel} onClick={onClose}>
          {i18n.t(keys.generic.back)}
        </Button>
        <Button
          onClick={() =>
            onCommit(items.filter((i) => selectedItemIds.has(i.id.toString())))
          }
          disabled={!isValid}
          color="primary"
        >
          {i18n.t(keys.generic.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ItemSelectionForm
