import * as React from 'react'
import { PureComponent } from 'react'

import { Paper, Typography } from '@mui/material'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { classnames } from '../../util/classnames'
import { isMobileDevice } from '../../util/getDeviceType'
import withMap, { WithMap } from '../withMap'

interface MarkerCoords {
  lng: number
  lat: number
}

interface Props {
  className?: string
}

interface State {
  lngLat: MarkerCoords | undefined
}

class CursorLocation extends PureComponent<
  Props & ReduxProps & AppDispatchProps & WithMap
> {
  mobile = isMobileDevice()

  state: State = {
    lngLat: undefined,
  }

  componentDidMount() {
    if (this.mobile) {
      this.props.map.on('move', this.handleMapMove)
    } else {
      this.props.map.on('mousemove', this.handleCursorMove)
    }
  }

  componentWillUnmount() {
    if (this.mobile) {
      this.props.map.off('move', this.handleMapMove)
    } else {
      this.props.map.off('mousemove', this.handleCursorMove)
    }
  }

  render() {
    const { isEnabled } = this.props
    const { lngLat } = this.state

    if (!isEnabled || !lngLat || isMobileDevice()) {
      return null
    }
    const className = classnames('no-print', this.props.className)

    return (
      <Paper className={className} sx={{ padding: 1 }}>
        <Typography variant="caption">
          {i18n.t(keys.cursorLocation)}:
        </Typography>
        &nbsp;
        <Typography variant="caption">{this.getLngLat().lng}</Typography>
        &nbsp;
        <Typography variant="caption">{this.getLngLat().lat}</Typography>
      </Paper>
    )
  }

  handleCursorMove = (e: any) => {
    this.setState({ lngLat: e.lngLat })
  }

  handleMapMove = () => {
    this.setState({ lngLat: this.props.map.getCenter() })
  }

  getLngLat() {
    const { lngLat } = this.state
    if (!lngLat) {
      return {}
    }

    const lng = lngLat.lng.toFixed(6)
    const lat = lngLat.lat.toFixed(6)

    return {
      lng: i18n.t(keys.cursorLon, { lng }),
      lat: i18n.t(keys.cursorLat, { lat }),
    }
  }
}

const mapState = (state: RootStore) => ({
  isEnabled: state.preferences.showCursorLocation,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withMap(CursorLocation)
)
