import * as React from 'react'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material'

import { url, urls } from '../../appNavigation/urls'
import { VV_SURVEY_BUCKET } from '../../config'
import i18n from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps } from '../../redux/types'
import Ellipsis from '../../UI/Ellipsis'
import LinkChild from '../../UI/LinkChild'
import ShallowTable from '../../UI/ShallowTable'
import SmallGridContainer from '../../UI/SmallGridContainer'
import vvapi from '../../vvapi'
import DataSetEditMetaButton from './DataSetEditMetaButton'
import { DatasetEditNotesButton } from './DatasetEditNotesButton'
import DataSetImageTriggerProcessing from './DataSetImageTriggerProcessing'
import { DataSetPriorityDropdown } from './DataSetPriorityDropdown'
import { GetDataSetData } from './selectGetDataSet'

interface Props {
  dataSet: GetDataSetData | undefined | null
  inList?: boolean
}

class DataSetCard extends React.Component<Props & AppDispatchProps> {
  render() {
    const { dataSet } = this.props
    if (!dataSet) {
      return null
    }

    const {
      id,
      notes,
      acquisitionDate,
      createdAt,
      CreatedBy,
      updatedAt,
      status,
      meta,
      region,
    } = dataSet

    return (
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <SmallGridContainer>
            <Grid item>
              <Typography>{id}</Typography>
            </Grid>
            <Grid item xs={5}>
              {notes && (
                <Tooltip title={notes} style={{ maxWidth: '100%' }}>
                  <Typography
                    color="textSecondary"
                    style={{ maxWidth: '100%' }}
                  >
                    <Ellipsis style={{ maxWidth: '100%' }}>
                      Notes: {notes}
                    </Ellipsis>
                  </Typography>
                </Tooltip>
              )}
              {acquisitionDate && (
                <Typography color="textSecondary">
                  Acquisition: {i18n.toDateShort(acquisitionDate)}
                </Typography>
              )}
              <Typography color="textSecondary">
                Created: {i18n.toDateTimeShort(createdAt)}{' '}
                {CreatedBy && `by ${CreatedBy.firstName} ${CreatedBy.lastName}`}
              </Typography>
              <Typography color="textSecondary">
                Region: {region ?? 'Not specified'}
              </Typography>
              <Typography color="textSecondary">
                Updated: {i18n.toDateTimeShort(updatedAt)}
              </Typography>
              <Typography
                color={status === 'error' ? 'secondary' : 'textSecondary'}
              >
                Status: {status}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <ShallowTable data={meta} />
            </Grid>
          </SmallGridContainer>
        </CardContent>
        <CardActions style={{ flexWrap: 'wrap' }}>
          {this.props.inList && (
            <LinkChild
              to={url(urls.editDataSet, {
                dataSetId: id,
                tab: 'images',
              })}
            >
              <Button variant="contained" size="small" color="primary">
                Details
              </Button>
            </LinkChild>
          )}
          {!this.props.inList && (
            <React.Fragment>
              <DatasetEditNotesButton />
              <DataSetEditMetaButton dataSetId={id} />
              <DataSetImageTriggerProcessing dataSetId={id} />
              <Button variant="outlined" onClick={this.handleRawS3Click}>
                Raw S3
              </Button>
              <DataSetPriorityDropdown />
            </React.Fragment>
          )}
        </CardActions>
      </Card>
    )
  }

  handleRawS3Click = async () => {
    const { id: dataSetId } = this.props.dataSet!
    const bucketSuffix = await vvapi.config.bucketSuffix()
    window.open(
      `https://s3.console.aws.amazon.com/s3/buckets/${VV_SURVEY_BUCKET}/raw${bucketSuffix}/${dataSetId}/?region=us-east-1&tab=overview`,
      '_blank'
    )
  }
}

export default connect<{}, Props, AppDispatchProps>()(DataSetCard)
