import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import * as api from '../../graphql/api'
import { VVRegion } from '../../graphql/types'
import { RootStore } from '../../redux/types'

export interface GetDataSetData {
  acquisitionDate?: string
  createdAt: string
  CreatedBy: {
    firstName: string
    lastName: string
  }
  updatedAt: string
  meta: any
  id: string
  notes?: string
  status: string
  region?: VVRegion
  count: number
  priority?: 'LOW' | 'MEDIUM' | 'HIGH'
}

export const getDataSetDataReturning = `{
  acquisitionDate
  createdAt
  CreatedBy {
    firstName
    lastName
  }
  id
  meta
  notes
  region
  status
  priority
  updatedAt
}`

const { selector: selectGetDataSet, refresh: refreshGetDataSet } =
  createAsyncSelector({
    resource: 'GetDataSet',
    inputs: {
      dataSetId: (state: RootStore) => state.router.params.dataSetId,
    },
    fetcher: async ({ dataSetId }) => {
      if (!dataSetId) {
        return null
      }

      const { data } = await api.dataSet.get<GetDataSetData>({
        pk: { id: dataSetId },
        returning: getDataSetDataReturning,
      })

      if (!data) {
        throw new Error(`Error fetching DataSet ${dataSetId}`)
      }

      return data
    },
  })

export { selectGetDataSet, refreshGetDataSet }
