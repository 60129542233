import * as React from 'react'

import Button from '@mui/material/Button'

import { Card, Slider, Stack, Typography, useTheme } from '@mui/material'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import i18n, { keys } from '../../i18n'
import { selectActiveColor } from '../../postgis/selectActiveColor'
import { selectSelectedProduct } from '../../postgis/selectSelectedProduct'

import { formatNum } from '../../util/formatNumber'
import { debounce } from '../../util/throttle-debounce'
import { clearCoverage, updateCoverage } from '../store/redux'
import { selectActiveProductSettings } from '../store/selectors/selectActiveProductSettings'
import { selectSelectedGroup } from '../../data/selectSelectedGroup'
import { DEFAULT_COVERAGE } from '../constants'
import { useRedux } from '../../hooks/useRedux'

export const Coverage = () => {
  const [state, dispatch] = useRedux()
  const theme = useTheme()
  const product = selectSelectedProduct(state)

  const productId = product?.id
  const productName = product?.name

  const defaultSettings = product?.settings
  const preferredLanguage = selectPreferredLanguage(state)
  const settings = selectActiveProductSettings(state)
  const group = selectSelectedGroup(state)
  const dates = state.userSelection.selectedFlightDate
  const colorProfile = selectActiveColor(state)

  const resetCoverage = debounce({ delay: 100 }, () => {
    if (productId && group && dates) {
      dispatch(
        clearCoverage({
          productId,
          groupId: group.id,
          date: dates,
        })
      )
    }
  })

  const handleChangeCoverage = React.useCallback(
    debounce({ delay: 100 }, (coverage?: number) => {
      if (productId && group && dates && coverage !== undefined) {
        dispatch(
          updateCoverage({
            productId,
            groupId: group.id,
            date: dates,
            coverage: Number((1 - coverage).toFixed(2)),
          })
        )
      }
    }),
    [group, dates, settings, productId, dispatch]
  )

  // const calculateValue = (value: number) => {
  //   return 1 - value
  // }

  const formatValue = (value?: number, zeroIsNone = false) => {
    if (zeroIsNone && value !== undefined) {
      return `${formatNum(100 * value, true)}%`
    }

    return value !== undefined
      ? `${formatNum(100 * value, true)}%`
      : i18n.t(keys.generic.none)
  }

  const coverageValue = React.useMemo(() => {
    const { coverage = {} } = settings
    let coverageValueForGroup =
      1 - (defaultSettings?.[0]?.coverage ?? DEFAULT_COVERAGE)

    if (group && dates) {
      if (coverage[`${group.id}_${dates}`] !== undefined) {
        coverageValueForGroup = 1 - coverage[`${group.id}_${dates}`]
      }
    }

    return coverageValueForGroup
  }, [defaultSettings, group, dates, settings])

  if (!colorProfile || !colorProfile.coverageColor || !settings) {
    return null
  }

  const minLabel =
    preferredLanguage === 'en'
      ? `${productName?.replace('PureVine™', '')} ${i18n.t(
          keys.visualization.coverage
        )}`
      : `${i18n.t(keys.visualization.coverage)} ${productName?.replace(
          'PureVine™ ',
          ''
        )}`

  return (
    <Card elevation={0} sx={{ m: 1 }}>
      <Stack spacing={1} sx={{ p: 1, px: 5 }} alignItems="center">
        <Typography style={{ marginTop: 12 }} variant="subtitle1">
          {`${minLabel}: ${formatValue(coverageValue, true)}`}
        </Typography>
        <Slider
          sx={{
            '& .MuiSlider-thumb': {
              backgroundColor: theme.palette.text.primary,
              height: 16,
              width: 16,
              '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
              },
              '&:before': {
                display: 'none',
              },
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#e9e9e9',
            },
          }}
          min={0}
          max={1}
          step={0.01}
          // scale={calculateValue}
          style={{ flex: 1 }}
          value={coverageValue}
          onChange={(_ev, value) => handleChangeCoverage(value as number)}
        />

        <Button
          fullWidth
          onClick={() => resetCoverage()}
          style={{ textAlign: 'left' }}
        >
          {i18n.t(keys.generic.reset)}
        </Button>
      </Stack>
    </Card>
  )
}
