import * as React from 'react'

import {
  Card,
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'

import { Row } from '../../admin/UI/Row'
import { selectSelectedProductIds } from '../../data/selectSelectedProductIds'
import * as userSelection from '../../data/userSelectionRedux'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import { selectSelectedProduct } from '../../postgis/selectSelectedProduct'
import { addArrayValues, removeArrayValues } from '../../util/toggleArrayValue'
import {
  selectOrderedGroupProductsWithExtras,
  selectOrderedGroupProductsWithExtrasIds,
  selectSelectedGroupPointLayer,
  selectSelectedGroupProduct,
} from '../store/selectors/selectSelectedGroupProduct'

export const ViewAs = () => {
  const [state, dispatch] = useRedux()
  const selectedProduct = selectSelectedProduct(state)
  const selectedProductIds = selectSelectedProductIds(state)
  const selectedLayerId =
    selectSelectedGroupProduct(state) ?? selectedProduct?.id
  const orderedProducts = selectOrderedGroupProductsWithExtras(state)
  const pointLayer = selectSelectedGroupPointLayer(state)
  const productIds = selectOrderedGroupProductsWithExtrasIds(state)

  const handleProductChange = (productId: string) => {
    const newSelectedProductIds = addArrayValues(
      removeArrayValues(selectedProductIds, productIds),
      [productId]
    )
    dispatch(
      userSelection.update({
        selectedProductIds: newSelectedProductIds,
        selectedLayerByGroup: {
          ...state.userSelection.selectedLayerByGroup,
          [selectedProduct!.mapLayerDefGroupId!]: productId,
        },
        selectedLegendProductId: pointLayer?.id ?? productId,
      })
    )
  }

  if (!selectedProduct?.mapLayerDefGroupId) {
    return null
  }

  return (
    <Card elevation={0} sx={{ m: 1 }}>
      <FormControl fullWidth sx={{ m: 1 }}>
        <FormLabel id="view-as-label">{i18n.t(keys.viewAs)}</FormLabel>
        <Row style={{ justifyContent: 'center', width: '100%' }}>
          <ToggleButtonGroup color="primary" exclusive value={selectedLayerId}>
            {orderedProducts.map((product) => {
              return (
                <ToggleButton
                  onClick={() => handleProductChange(product.id)}
                  key={product.id}
                  value={product.id}
                >
                  {product?.layerGroupLabel ?? product?.name}
                </ToggleButton>
              )
            })}
          </ToggleButtonGroup>
        </Row>
      </FormControl>
    </Card>
  )
}
