import { sendToLogin } from './sendToLogin'

// tslint:disable:max-classes-per-file
export interface RequestOptions {
  throttleRetries?: number
  retryDelay?: number
}

export const createRequest = (_fetch: typeof fetch) => {
  const processRequest = async (
    requestInfo: RequestInfo,
    requestInit?: RequestInit,
    options: RequestOptions = {},
    _state = {}
  ) => {
    const request = new Request(requestInfo, requestInit)
    const response = await _fetch(request.clone())

    const handleResponseCode = handleResponseCodes[response.status]

    if (handleResponseCode) {
      return handleResponseCode(request, response, options, _state)
    }

    if (response.status >= 400) {
      throw new RequestError(request, response)
    }

    return response
  }

  interface ResponseCodeHandler {
    (
      request: Request,
      response: Response,
      options?: RequestOptions,
      _state?: Record<string, any>
    ): Promise<Response> | Response
  }

  const handleResponseCodes: Record<number, ResponseCodeHandler> = {
    400: async (request, response) => {
      throw new BadRequestError(request, response)
    },
    401: async (request, response) => {
      sendToLogin()
      throw new InvalidAuthError(request, response)
    },
    429: async (request, response, options = {}, _state = {}) => {
      // tslint:disable-next-line: no-console
      console.log('Rate-Limit', { request, response })
      const { throttleRetries = 2, retryDelay = 5 } = options
      const { _throttleRetries = 0 } = _state

      // if options.throttleRetries < 0 it will never giveup.
      if (throttleRetries >= 0 && _throttleRetries > throttleRetries) {
        throw new ToManyRequestsError(request, response)
      }

      const retryAfter = response.headers.get('Retry-After')

      await delay(retryAfter ? parseInt(retryAfter, 10) : retryDelay)

      _state._throttleRetries = _throttleRetries + 1

      return processRequest(request, undefined, options, _state)
    },
  }

  return processRequest
}

const delay = (seconds: number) =>
  new Promise((resolve) => setTimeout(resolve, seconds * 1000))

export async function parseBody(response: Response) {
  const contentTypeHeader = response.headers.get('Content-Type')
  const contentType = contentTypeHeader?.toLowerCase() ?? ''
  let body = null

  if (contentType.includes('json')) {
    body = await response.json()
  } else if (contentType.includes('text')) {
    body = await response.text()
  }

  return body
}

export class RequestError extends Error {
  name = 'RequestError'
  constructor(
    public request: Request,
    public response: Response,
    message = 'Error processing request'
  ) {
    super(message)

    // without this, the instanceof keyword is not working
    // e.g. if(error instanceof RequestError)
    Object.setPrototypeOf(this, RequestError.prototype)
  }
}

export class BadRequestError extends RequestError {
  name = 'BadRequestError'
  constructor(request: Request, response: Response) {
    super(request, response, 'Bad Request')

    // without this, the instanceof keyword is not working
    // e.g. if(error instanceof BadRequestError)
    Object.setPrototypeOf(this, BadRequestError.prototype)
  }
}

export class InvalidAuthError extends RequestError {
  name = 'InvalidAuthError'
  constructor(request: Request, response: Response) {
    super(request, response, 'Invalid or expired auth token')

    // without this, the instanceof keyword is not working
    // e.g. if(error instanceof InvalidAuthError)
    Object.setPrototypeOf(this, InvalidAuthError.prototype)
  }
}

export class ToManyRequestsError extends RequestError {
  name = 'ToManyRequestsError'
  constructor(request: Request, response: Response) {
    super(request, response, 'Request limit has been reached')

    // without this, the instanceof keyword is not working
    // e.g. if(error instanceof ToManyRequestsError)
    Object.setPrototypeOf(this, ToManyRequestsError.prototype)
  }
}
