import {
  MRT_Row,
  MRT_ActionMenuItem as ActionMenuItem,
  MRT_TableInstance,
} from 'material-react-table'
import * as React from 'react'
import { RateMapRow } from '../types'
import { ContentCopy, Delete } from '@mui/icons-material'
import i18n, { keys } from '../../../i18n'

interface Props {
  closeMenu: () => void
  row: MRT_Row<RateMapRow>
  staticRowIndex?: number
  table: MRT_TableInstance<RateMapRow>
}

export const RateMapActionMenuItems = ({ table, row }: Props) => {
  return [
    <ActionMenuItem
      icon={<ContentCopy />}
      key="duplicate"
      label={i18n.t(keys.generic.duplicate)}
      onClick={row.original.duplicate}
      table={table}
    />,
    <ActionMenuItem
      icon={<Delete />}
      key="delete"
      label={i18n.t(keys.generic.delete)}
      onClick={row.original.delete}
      table={table}
    />,
  ]
}
