import * as React from 'react'

import { CircularProgress, Icon, IconButton, Tooltip } from '@mui/material'

import { Row } from '../admin/UI/Row'
import { selectMe } from '../data/selectMe'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import * as api from '../graphql/api'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import { showFeedback } from '../redux/notifications/redux'
import { copyToClipboard } from '../util/copyToClipboard'
import { GroupFile } from './fetchDeliveryGroupFiles'
import { MapLayer } from './fetchMapLayersForGroup'

interface Props {
  groupFile?: GroupFile
  mapLayer: MapLayer
  onDownloadRequested: (mapLayer: MapLayer) => void
  onReprocessRequested: (groupFile: GroupFile, mapLayer: MapLayer) => void
}

export const GeoPdfDownloadStatus = ({
  mapLayer,
  groupFile,
  onDownloadRequested,
  onReprocessRequested,
}: Props) => {
  const [state, dispatch] = useRedux()
  const me = selectMe(state)
  const preferredLanguage = selectPreferredLanguage(state)

  const copyUrl = async () => {
    if (await copyToClipboard(api.deliveryGroupFile.getUrl(groupFile!))) {
      dispatch(
        showFeedback({ message: i18n.t(keys.copySuccess), severity: 'success' })
      )
    }
  }

  const downloadSingle = async () =>
    api.deliveryGroupFile.download(
      groupFile!,
      mapLayer?.pdfFilenameTranslations?.[preferredLanguage] ||
        mapLayer.pdfFilename
    )

  if (!mapLayer) {
    return null
  }

  if (groupFile?.status === 'complete') {
    return (
      <Row
        style={{
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginLeft: 8,
        }}
      >
        {me && me.roles.length > 0 && (
          <Tooltip title={i18n.t(keys.reprocessDownload)} placement="top">
            <IconButton
              onClick={() => onReprocessRequested(groupFile, mapLayer)}
              size="large"
            >
              <Icon>sync</Icon>
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={i18n.t(keys.download)} placement="top">
          <IconButton onClick={() => downloadSingle()} size="large">
            <Icon>cloud_download</Icon>
          </IconButton>
        </Tooltip>

        <Tooltip title={i18n.t(keys.copyUrl)} placement="top">
          <IconButton onClick={() => copyUrl()} size="large">
            <Icon>content_copy</Icon>
          </IconButton>
        </Tooltip>
      </Row>
    )
  }

  if (groupFile?.status === 'pending' || groupFile?.status === 'processing') {
    return (
      <Tooltip title={i18n.t(keys.downloadPending)} placement="top">
        <CircularProgress
          style={{ marginRight: 12 }}
          size={24}
          thickness={4}
          color="inherit"
        />
      </Tooltip>
    )
  }

  if (groupFile?.status === 'error') {
    return (
      <Tooltip title={i18n.t(keys.downloadError)} placement="top">
        <Icon style={{ marginRight: 12 }}>error</Icon>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={i18n.t(keys.requestDownload)} placement="top">
      <IconButton
        onClick={() => {
          onDownloadRequested(mapLayer)
        }}
        size="large"
      >
        <Icon>add</Icon>
      </IconButton>
    </Tooltip>
  )
}
