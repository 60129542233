import i18n, { keys } from '../../i18n'

const getPositionErrorMessage = (error: GeolocationPositionError) => {
  let errorMessage
  switch (error.code) {
    case 1:
      errorMessage = i18n.t(keys.userPosition.error.missingPermission)
      break
    case 2:
      errorMessage = i18n.t(keys.userPosition.error.positionNotAvailable)
      break
    case 0:
    default:
      errorMessage = i18n.t(keys.userPosition.error.unknown)
      break
  }

  return errorMessage
}

export default getPositionErrorMessage
