import { Position } from '@turf/helpers'
import * as React from 'react'
import { PureComponent } from 'react'

import * as ReactDOM from 'react-dom'
import { MapboxGL } from '../MapboxGL'
import withMap, { MapContext, WithMap } from '../withMap'

const noop = () => {}

interface Props {
  coordinates?: Position
  open?: boolean
  onOpen?: () => boolean | void
  onClose?: () => boolean | void
  className?: string
}

class MapPopup extends PureComponent<
  Props & WithMap & { children: React.ReactNode }
> {
  div = document.createElement('div')
  popup = new MapboxGL.Popup({
    closeButton: false,
  })

  componentDidMount() {
    this.popup.setDOMContent(this.div)
    this.popup.addClassName(this.props.className || '')
    this.popup.on('open', this.onOpen)
    this.popup.on('close', this.onClose)
  }

  componentWillUnmount() {
    this.popup.off('open', this.onOpen)
    this.popup.removeClassName(this.props.className || '')
    this.popup.off('close', this.onClose)
    this.popup.remove()
  }

  componentDidUpdate() {
    const { open, coordinates, map } = this.props

    if (!open) {
      if (this.popup.isOpen()) {
        this.popup.remove()
      }
    } else if (coordinates) {
      this.popup.setLngLat([coordinates[0], coordinates[1]])

      if (!this.popup.isOpen()) {
        this.popup.addTo(map)
      }

      this.renderChild()
    }
  }

  render() {
    return null
  }

  onOpen = () => {
    const { onOpen } = this.props
    if (onOpen?.() === false) {
      this.componentDidUpdate()
    }
  }

  onClose = () => {
    const { onClose } = this.props
    if (onClose?.() === false) {
      this.componentDidUpdate()
    }
  }

  renderChild() {
    ReactDOM.render(
      <MapContext.Provider value={{ map: this.props.map, setMap: noop }}>
        {this.props.open && this.props.children}
      </MapContext.Provider>,
      this.div
    )
  }
}

export default withMap(MapPopup)
