import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { Fetched } from '../../AsyncSelector/createAsyncSelector'
import * as React from 'react'
import { Table } from '../../UI/Table/Table'

interface Props {
  summary: Fetched<Promise<Record<string, number> | undefined>>
}

class StatusSummary extends React.PureComponent<Props> {
  render() {
    const { summary } = this.props

    const items = Object.entries(summary.data ?? {})

    return (
      <div style={{ width: '30ch', boxSizing: 'border-box' }}>
        <AsyncSelectorStatusOverlay requests={summary} style={{ padding: 24 }}>
          <Table
            rows={items}
            formatter={[
              {
                header: () => 'Status',
                data: ([status]) => status,
              },
              {
                header: () => 'Count',
                data: ([, count]) => count,
              },
            ]}
          />
          {items.length === 0 && 'loading'}
        </AsyncSelectorStatusOverlay>
      </div>
    )
  }
}

export default StatusSummary
