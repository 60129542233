import * as React from 'react'
import { useParams } from 'react-router-dom'
import useSavable from '../../../hooks/useSavable'
import {
  getAmendmentModeTypes,
  getAmendmentTypes,
  getRateMap,
  getUnitTypes,
  saveRateMap,
} from '../queries'
import {
  RateMap,
  RateMapAmendmentModeType,
  RateMapAmendmentType,
  RateMapUnitType,
} from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import { useRedux } from '../../../hooks/useRedux'
import { indexArray } from '../../../util/indexArray'

export interface RateMapContextState {
  selectedZoneIndex?: number
  setSelectedZoneIndex?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  rateMap?: RateMap | undefined
  setModifiedRateMap?: React.Dispatch<React.SetStateAction<RateMap | undefined>>
  refresh: (level?: 'all' | 'rateMap' | 'unitTypes' | 'amendmentTypes') => void
  isSaved: boolean
  isDirty: boolean
  save: () => void
  loading: boolean
  unitTypes: Record<string, RateMapUnitType>
  amendmentTypes: Record<string, RateMapAmendmentType>
  amendmentModeTypes: Record<string, RateMapAmendmentModeType>
}

export const RateMapContext = React.createContext<RateMapContextState>({
  isDirty: false,
  isSaved: false,
  save: () => {},
  refresh: () => {},
  loading: true,
  unitTypes: {},
  amendmentTypes: {},
  amendmentModeTypes: {},
})

export const useRateMapContext = () => React.useContext(RateMapContext)

export const RateMapProvider: React.FC = ({ children }) => {
  const [state] = useRedux()
  const { rateMapId } = useParams<{ rateMapId: string }>()

  const organizationId = selectOrganizationId(state)

  const [selectedZoneIndex, setSelectedZoneIndex] = React.useState<
    number | undefined
  >()

  const [amendmentTypes, refreshAmendmentTypes] = useAsync(
    async (_, skip) => {
      if (!organizationId) {
        skip()
        return
      }

      return indexArray(await getAmendmentTypes(organizationId), 'id')
    },
    [organizationId]
  )

  const [unitTypes, refreshUnitTypes] = useAsync(
    async (_, skip) => {
      if (!organizationId) {
        skip()
        return
      }

      return indexArray(await getUnitTypes(organizationId), 'id')
    },
    [organizationId]
  )

  const [amendmentModeTypes, refreshAmendmentModeTypes] = useAsync(async () => {
    return indexArray(await getAmendmentModeTypes(), 'id')
  }, [])

  const [
    rateMap,
    setModifiedRateMap,
    refreshRateMap,
    isDirty,
    save,
    isRateMapLoading,
  ] = useSavable(
    async () => {
      return await getRateMap({ rateMapId })
    },
    [rateMapId],
    async (newRateMap: RateMap) => {
      // todo save rate map
      return await saveRateMap(newRateMap)
    }
  )

  const refresh = React.useCallback(
    (level = 'all') => {
      if (level === 'all') {
        refreshRateMap()
        refreshAmendmentTypes()
        refreshUnitTypes()
      } else if (level === 'rateMap') {
        refreshRateMap()
      } else if (level === 'unitTypes') {
        refreshUnitTypes()
      } else if (level === 'amendmentTypes') {
        refreshAmendmentTypes()
      } else {
        refreshAmendmentModeTypes()
      }
    },
    [
      refreshRateMap,
      refreshAmendmentTypes,
      refreshUnitTypes,
      refreshAmendmentModeTypes,
    ]
  )

  React.useEffect(() => {
    setSelectedZoneIndex(undefined)
  }, [rateMap])

  return (
    <RateMapContext.Provider
      value={{
        selectedZoneIndex,
        setSelectedZoneIndex,
        rateMap,
        setModifiedRateMap,
        refresh,
        isDirty,
        isSaved: !!rateMap,
        save,
        loading:
          isRateMapLoading ||
          amendmentTypes.status === 'pending' ||
          unitTypes.status === 'pending',
        unitTypes: unitTypes.result ?? {},
        amendmentTypes: amendmentTypes.result ?? {},
        amendmentModeTypes: amendmentModeTypes.result ?? {},
      }}
    >
      {children}
    </RateMapContext.Provider>
  )
}
