import * as React from 'react'
import { PureComponent } from 'react'
import { default as LinearProgress } from '@mui/material/LinearProgress'
import { Row } from '../../../admin/UI/Row'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import i18n from '../../../i18n'
import { ExpandButton } from '../../../UI/Expand/ExpandButton'
import { indexMultiArrayMappedKey } from '../../../util/indexArray'
import { PreparedFile } from './prepareUpload'
import { FileUploadStatus, UploadProgress } from './uploadFlightFiles'
import './UploadFlightFilesProgressDialog.scss'

interface Props {
  flightFilesProgress?: UploadProgress
  onDone: () => void
}

interface State {
  filesToUploadByGroup: Record<string, PreparedFile[]>
  expandedGroups: Record<string, boolean>
}
class UploadFlightFilesProgressDialog extends PureComponent<Props, State> {
  state: State = {
    filesToUploadByGroup: {},
    expandedGroups: {},
  }

  componentDidUpdate(prevProps: Props) {
    const { flightFilesProgress } = this.props
    if (flightFilesProgress) {
      const { filesToUpload } = flightFilesProgress
      if (
        !prevProps.flightFilesProgress ||
        prevProps.flightFilesProgress.filesToUpload !== filesToUpload
      ) {
        const filesToUploadByGroup = indexMultiArrayMappedKey(
          filesToUpload,
          (f) => f.parcel.groupName
        )
        this.setState({ filesToUploadByGroup })
      }
    }
  }

  getGroupStatus = (groupFiles: PreparedFile[]) => {
    const { flightFilesProgress } = this.props
    let statuses: FileUploadStatus[] = []

    if (flightFilesProgress) {
      statuses = groupFiles
        .map(
          (gf) =>
            flightFilesProgress.fileUploadStatusByPath[
            !!gf.file.webkitRelativePath ? gf.file.webkitRelativePath : gf.file.fullPath
            ]
        )
        .filter((st) => st) as FileUploadStatus[]
    }

    if (statuses.some((st) => st.status === 'error')) {
      return 'error'
    }
    if (statuses.some((st) => st.status === 'skipped')) {
      return 'partially-skipped'
    }
    if (
      statuses.length > 0 &&
      statuses.every((st) => st.status === 'finished')
    ) {
      return 'finished'
    }

    // started is default. Has no visual treatment
    return 'started'
  }

  groupExpanded = (groupKey: string) => this.state.expandedGroups[groupKey]

  expandGroup = (groupKey: string) =>
    this.setState((state) => ({
      expandedGroups: {
        ...state.expandedGroups,
        [groupKey]: !state.expandedGroups[groupKey],
      },
    }))

  render() {
    const { flightFilesProgress, onDone } = this.props
    if (!flightFilesProgress) {
      return null
    }

    const {
      complete,
      fileUploadStatusByPath,
      filesToUpload,
      numFilesProcessed,
      bytesToUpload,
      bytesUploaded,
    } = flightFilesProgress

    const { filesToUploadByGroup } = this.state

    const percentDone = (bytesUploaded / bytesToUpload) * 100

    return (
      <Dialog open fullWidth>
        <DialogTitle>Uploading Flight Files</DialogTitle>
        <DialogContent className="UploadFlightFilesProgressDialog">
          <LinearProgress variant="determinate" value={percentDone} />
          <Typography style={{ marginTop: 12, whiteSpace: 'pre-line' }}>
            {!complete &&
              `Uploading ${numFilesProcessed} of ${filesToUpload.length} files.`}
            {complete && `Finished.`}
            {this.getBytesLabel()}
          </Typography>
          {!complete && <Typography>{this.getEstimateLabel()}</Typography>}
          {Object.entries(filesToUploadByGroup).map(([key, groupFiles]) => {
            const expanded = this.groupExpanded(key)
            const groupStatus = this.getGroupStatus(groupFiles)

            return (
              <div
                className="UploadFlightFilesProgressDialog_group"
                key={key}
                style={{
                  backgroundColor: getBackgroundColorForStatus(groupStatus),
                }}
              >
                <Row
                  style={{ justifyContent: 'space-between', cursor: 'pointer' }}
                  onClick={() => this.expandGroup(key)}
                >
                  <span>
                    <span className="UploadFlightFilesProgressDialog_group-title">
                      {key}
                    </span>
                    <ExpandButton expanded={!!expanded} />
                  </span>
                  <Typography style={{ userSelect: 'none' }}>
                    Status: {groupStatus}
                  </Typography>
                </Row>
                <div className="UploadFlightFilesProgressDialog_group-file-container">
                  {expanded &&
                    groupFiles.map(({ file, parcel }) => {
                      const absFilePath =
                        !!file.webkitRelativePath ? file.webkitRelativePath : file.fullPath

                      const fileUploadStatus =
                        fileUploadStatusByPath[absFilePath]
                      const status = fileUploadStatus?.status
                      const info = fileUploadStatus?.info

                      return (
                        <div
                          key={absFilePath}
                          style={{
                            backgroundColor:
                              getBackgroundColorForStatus(status),
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottomStyle: 'dotted',
                            borderWidth: 'thin',
                          }}
                        >
                          <Typography>
                            {parcel.groupName} -&gt; {parcel.parcelName} (
                            {absFilePath} {file.size.toLocaleString()} bytes)
                          </Typography>
                          <Typography>
                            &nbsp;{status} {`${info ?? ''}`}
                          </Typography>
                        </div>
                      )
                    })}
                </div>
              </div>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={complete === false}
            onClick={onDone}
            color="primary"
            variant="contained"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getEstimateLabel = () => {
    const { flightFilesProgress } = this.props

    if (flightFilesProgress) {
      const { estimate } = flightFilesProgress
      const estimateMessage =
        estimate === 'calculating' ? 'Calculating' : i18n.msToTime(estimate)

      return `Estimated time remaining: ${estimateMessage}`
    }

    return ''
  }

  getBytesLabel = () => {
    const { flightFilesProgress } = this.props

    if (flightFilesProgress) {
      const { filesToUpload, fileUploadStatusByPath } = flightFilesProgress
      const sumBytes = (sum: number, curr: UnwrapArray<typeof filesToUpload>) =>
        sum + curr.file.size
      const uploadedFiles = filesToUpload.filter((f) => {
        const path = !!f.file.webkitRelativePath ? f.file.webkitRelativePath : f.file.fullPath

        return (
          fileUploadStatusByPath[path] &&
          (fileUploadStatusByPath[path]!.status === 'finished' ||
            fileUploadStatusByPath[path]!.status === 'skipped' ||
            fileUploadStatusByPath[path]!.status === 'error')
        )
      })

      return ` (${uploadedFiles
        .reduce(sumBytes, 0)
        .toLocaleString()} bytes of ${filesToUpload
          .reduce(sumBytes, 0)
          .toLocaleString()})`
    }

    return ''
  }
}

const getBackgroundColorForStatus = (
  status:
    | 'started'
    | 'skipped'
    | 'partially-skipped'
    | 'finished'
    | 'error'
    | undefined
) => {
  if (status === 'error') {
    return '#FF000033'
  }
  if (status === 'skipped' || status === 'partially-skipped') {
    return '#CCFF0033'
  }

  return undefined
}

export default UploadFlightFilesProgressDialog
