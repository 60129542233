import { default as swal } from 'sweetalert'

type DefinedFields<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

/**
 * It checks that all fields in an object are not empty.
 * Also serves as a type guard.
 * Also, displays an alert for missing fields
 *
 * Example:
 *
 * const requiredFields: Partial<T> = {
 *   name,
 *   date,
 * }
 * if (assertDefinedFields(requiredFields)) {
 *   ...all fields (name & date) are now defined
 * }
 */
const assertDefinedFields = <T extends Record<string, any>>(
  fields: Partial<T>
): fields is DefinedFields<T> => {
  const missing: (keyof T)[] = []

  Object.keys(fields).forEach((field) => {
    const value = fields[field]

    // check for undefined, null, or empty string
    if (value == null || value === '') {
      missing.push(field)
    }
  })

  if (missing.length > 0) {
    swal({
      title: 'Required Fields',
      text: `The following required fields are missing: ${missing.join(', ')}`,
      icon: 'info',
    })

    return false
  }

  return true
}

export default assertDefinedFields
