import { createSelector } from 'reselect'

import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import distance from '@turf/distance'
import { Feature, lineString, point, Polygon, Properties } from '@turf/helpers'
import lineIntersect from '@turf/line-intersect'
import transformTranslate from '@turf/transform-translate'

import { MapboxGL } from '../../map/MapboxGL'
import { RootStore } from '../../redux/types'
import { selectSpeedMapSourcesAndSourceLayers } from './selectSpeedMapSourcesAndSourceLayers'
import { selectAdjustedUserPosition } from './UserPositionLayerSelector'

const getMapDataHash = (state: RootStore) => state.postgis.mapDataHash

const createSelectSpeedFeatures = (map: mapboxgl.Map) =>
  createSelector(
    selectSpeedMapSourcesAndSourceLayers,
    getMapDataHash,
    (speedMapSourcesAndSourceLayers, _mapDataHash) => {
      if (!map) {
        return []
      }
      const features: Feature<Polygon, Properties>[] = []
      for (const { source, sourceLayer } of speedMapSourcesAndSourceLayers) {
        try {
          const queriedFeatures = map.querySourceFeatures(source, {
            sourceLayer,
            filter: ['==', ['geometry-type'], 'Polygon'],
          }) as Feature<Polygon, Properties>[]
          features.push(...queriedFeatures)
        } catch (e) {
          // tslint:disable-next-line: no-console
          console.log(e)
        }
      }

      return features
    }
  )

export const createSpeedFeaturesSelectors = (map: MapboxGL.Map) => {
  const selectMapSpeedFeatures = createSelectSpeedFeatures(map)
  const selectCurrentSpeedFeature = createSelector(
    selectMapSpeedFeatures,
    selectAdjustedUserPosition,
    (features, position) => {
      if (!position) {
        return null
      }
      for (const feat of features) {
        if (
          booleanPointInPolygon(
            point([position.coords.longitude, position.coords.latitude]),
            feat
          ) &&
          feat.properties
        ) {
          return feat
        }
      }

      return null
    }
  )

  const selectPrescribedSpeed = createSelector(
    selectCurrentSpeedFeature,
    (currentSpeedFeature) =>
      currentSpeedFeature &&
      currentSpeedFeature.properties &&
      (currentSpeedFeature.properties.speed as number)
  )

  const selectNextSpeedFeature = createSelector(
    selectMapSpeedFeatures,
    selectAdjustedUserPosition,
    selectCurrentSpeedFeature,
    (speedFeatures, userPosition, currentFeature) => {
      const headingLineLength = 1000

      if (!userPosition) {
        return
      }

      const { longitude, latitude, heading } = userPosition.coords

      if (longitude === null || latitude === null || heading === null) {
        return
      }

      const startPoint = point([longitude, latitude])
      const { geometry: endPoint } = transformTranslate(
        startPoint,
        headingLineLength,
        heading,
        {
          units: 'meters',
        }
      )

      const headingLine = lineString([
        [
          startPoint.geometry.coordinates[0],
          startPoint.geometry.coordinates[1],
        ],
        [endPoint.coordinates[0], endPoint.coordinates[1]],
      ])

      let closestFeature: Feature<Polygon, Properties> | null = null
      let closestFeatureDistance: number = Infinity

      for (const feat of speedFeatures) {
        // only look for zones which have a different speed than the current zone,
        // if the current zone exists
        if (
          !currentFeature ||
          (currentFeature.properties &&
            feat.properties &&
            feat.properties.speed &&
            feat.properties.speed !== currentFeature.properties.speed)
        ) {
          const intersectPoints = lineIntersect(headingLine, feat)

          for (const intersectPoint of intersectPoints.features) {
            const currentFeatureDistance = distance(
              startPoint,
              intersectPoint,
              {
                units: 'meters',
              }
            )

            if (currentFeatureDistance < closestFeatureDistance) {
              closestFeature = feat
              closestFeatureDistance = currentFeatureDistance
            }
          }
        }
      }

      let nextAreaDistance: number | null = null
      let nextSpeed: number | null = null

      if (closestFeature && closestFeature.properties) {
        const { speed } = closestFeature.properties

        nextSpeed = speed as number

        nextAreaDistance = closestFeatureDistance as number

        return { nextSpeed, nextAreaDistance }
      }

      return null
    }
  )

  return {
    selectCurrentSpeedFeature,
    selectPrescribedSpeed,
    selectNextSpeedFeature,
  }
}
