import * as React from 'react'
import { PureComponent } from 'react'

import Paper from '@mui/material/Paper'
import { Typography } from '@mui/material'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { connect } from '../../redux/connect'
import { AppDispatchProps } from '../../redux/types'
import withMap, { WithMap } from '../withMap'

export const SCALE_CONTROL_ELEMENT_ID = 'VVScaleControl'

type ScaleUnit = 'imperial' | 'metric' | 'nautical'
type DisplayUnit = 'mi' | 'ft' | 'nm' | 'm' | 'km'

interface Props {
  maxWidth?: number
  unit?: ScaleUnit
}

interface ReduxProps {
  scaleUnit: ScaleUnit
  isEnabled: boolean
}

interface State {
  distance: number
  width: number
  unit: DisplayUnit
}

class ScaleControl extends PureComponent<
  Props & WithMap & ReduxProps & AppDispatchProps & WithStyles<typeof styles>,
  State
> {
  static defaultProps: Props = {
    maxWidth: 100,
    unit: 'metric',
  }

  state: State = {
    distance: 0,
    unit: 'm',
    width: 100,
  }

  componentDidUpdate() {
    this.handleMapMove()
  }

  componentDidMount() {
    this.handleMapMove()
    this.props.map.on('moveend', this.handleMapMove)
  }

  componentWillUnmount() {
    this.props.map.off('moveend', this.handleMapMove)
  }

  render() {
    const { classes, isEnabled } = this.props

    if (!isEnabled) {
      return null
    }

    return (
      <Paper
        id={SCALE_CONTROL_ELEMENT_ID}
        square
        className={classes.root}
        style={{
          width: this.state.width,
        }}
      >
        <div className={classes.scaleBar}>
          <Typography variant="caption">
            {this.state.distance}
            {this.state.unit}
          </Typography>
        </div>
      </Paper>
    )
  }

  handleMapMove = () => {
    const { map, maxWidth, scaleUnit } = this.props
    this.setState(updateScale(map, scaleUnit, maxWidth))
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1 / 2),
    },
    scaleBar: {
      border: `2px solid ${theme.palette.text.primary}`,
      borderBottom: 0,
      padding: `0 ${theme.spacing(1 / 2)}`,
    },
  })

export default connect<ReduxProps, {}, AppDispatchProps>((state) => ({
  scaleUnit: state.preferences.preferredUnitSystem,
  isEnabled: state.preferences.showScale,
}))(withStyles(styles)(withMap(ScaleControl)))

function updateScale(map: mapboxgl.Map, scaleUnit: ScaleUnit, maxWidth = 100) {
  // A horizontal scale is imagined to be present at center of the map
  // container with maximum length (Default) as 100px.
  // Using spherical law of cosines approximation, the real distance is
  // found between the two coordinates.
  const y = map.getContainer().clientHeight / 2
  const maxMeters = getDistance(
    map.unproject([0, y]),
    map.unproject([maxWidth, y])
  )

  // The real distance corresponding to 100px scale length is rounded off to
  // near pretty number and the scale length for the same is found out.
  // Default unit of the scale is based on User's locale.
  if (scaleUnit === 'imperial') {
    const maxFeet = 3.2808 * maxMeters
    if (maxFeet > 5280) {
      const maxMiles = maxFeet / 5280

      return setScale(maxWidth, maxMiles, 'mi')
    }

    return setScale(maxWidth, maxFeet, 'ft')
  }

  if (scaleUnit === 'nautical') {
    const maxNauticals = maxMeters / 1852

    return setScale(maxWidth, maxNauticals, 'nm')
  }

  return setScale(maxWidth, maxMeters, 'm')
}

function setScale(maxWidth: number, maxDistance: number, unit: DisplayUnit) {
  let distance = getRoundNum(maxDistance)
  const ratio = distance / maxDistance

  if (unit === 'm' && distance >= 1000) {
    distance = distance / 1000
    // tslint:disable-next-line:no-parameter-reassignment
    unit = 'km'
  }

  return {
    distance,
    unit,
    width: maxWidth * ratio,
  }
}

function getDistance(latlng1: mapboxgl.LngLat, latlng2: mapboxgl.LngLat) {
  // Uses spherical law of cosines approximation.
  const R = 6371000

  const rad = Math.PI / 180
  const lat1 = latlng1.lat * rad
  const lat2 = latlng2.lat * rad
  const a =
    Math.sin(lat1) * Math.sin(lat2) +
    Math.cos(lat1) *
    Math.cos(lat2) *
    Math.cos((latlng2.lng - latlng1.lng) * rad)

  const maxMeters = R * Math.acos(Math.min(a, 1))

  return maxMeters
}

function getRoundNum(num: number) {
  const pow10 = Math.pow(10, `${Math.floor(num)}`.length - 1)
  let d = num / pow10

  d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1

  return pow10 * d
}
