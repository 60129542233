import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { RootStore } from '../../../redux/types'

const list = ({
  limit,
  offset,
  order_by,
  where,
}: {
  limit?: number
  offset?: number
  order_by?: string
  where?: any
}) =>
  api.deliveryGroup.list({
    limit,
    offset,
    order_by,
    where,
    returning: `{
      deliveryId
      name
    }`,
  })

const {
  selector: selectListDeliveryGroupForDelivery,
  refresh: refreshListDeliveryGroupForDelivery,
} = createAsyncSelector({
  resource: 'ListDeliveryGroupForDelivery',
  inputs: {
    deliveryId: (state: RootStore) => state.router.params.deliveryId,
  },
  fetcher: async ({ deliveryId }) => {
    if (!deliveryId) {
      return
    }

    const { data } = await list({
      where: { deliveryId: { _eq: deliveryId } },
    })

    if (!data) {
      throw new Error('No Data')
    }

    return {
      data,
    }
  },
})

export {
  selectListDeliveryGroupForDelivery,
  refreshListDeliveryGroupForDelivery,
}
