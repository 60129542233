import * as React from 'react'

import {
  Autocomplete,
  Button,
  Chip,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { selectOrganizationGroupsById } from '../../../data/selectOrgMapData'
import { useRedux } from '../../../hooks/useRedux'
import i18n, { keys } from '../../../i18n'
import { ListUserAndInvitesData } from './selectUsersAndInvites'

interface Props {
  userOrInvite?: ListUserAndInvitesData
  onCancel: () => void
  onSubmit: (userOrInvite: ListUserAndInvitesData, groupIds: string[]) => void
}

const defaultFilterOptions = createFilterOptions<string>()

interface AutoCompleteParams {
  id?: string
  name?: string
}

const allOption: AutoCompleteParams = { id: '-1', name: i18n.t(keys.allGroups) }

export const EditUserGroupAccessDialog = ({
  userOrInvite,
  onCancel,
  onSubmit,
}: Props) => {
  const open = Boolean(userOrInvite)
  const [state] = useRedux()
  const groupsById = selectOrganizationGroupsById(state)
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([])
  const [selectedValue, setSelectedValue] = React.useState<string>('')

  React.useEffect(() => {
    const newSelectedGroups = userOrInvite?.groupIds ?? []
    handleSelectGroups(newSelectedGroups)
  }, [userOrInvite])

  const handleSubmit = async () => {
    if (!userOrInvite) {
      return
    }

    onSubmit(userOrInvite, selectedGroups)
  }

  const handleSelectGroups = (newSelectedGroups: string[]) => {
    if (newSelectedGroups.length === 0) {
      setSelectedGroups(['-1'])
    } else {
      const groups = newSelectedGroups.filter((g) => g !== '-1')
      setSelectedGroups(groups)
    }
  }

  const handleSetSelectedValue = (value: string) => {
    setSelectedValue(value)
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{i18n.t(keys.editGroupAccess)}</DialogTitle>
      <DialogContent>
        {open && (
          <Stack spacing={1}>
            <Typography>
              {`${i18n.t(keys.user.user)}:`} <em>{userOrInvite?.email}</em>
            </Typography>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                multiple
                filterOptions={(options, state) => {
                  if (
                    state.inputValue === i18n.t(keys.allGroups) ||
                    state.inputValue === ''
                  ) {
                    return options
                  }

                  return defaultFilterOptions(options, state)
                }}
                inputValue={selectedValue}
                onInputChange={(_ev, value) => handleSetSelectedValue(value)}
                getOptionLabel={(option) => groupsById[option]?.name}
                options={Object.keys({ ...groupsById, allOption })}
                value={selectedGroups}
                isOptionEqualToValue={(option, value) => {
                  return Number(option) === Number(value)
                }}
                onChange={(_ev, newValue) => handleSelectGroups(newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    if (option === '-1') {
                      return (
                        <Chip
                          label={
                            {
                              ...groupsById,
                              '-1': { name: i18n.t(keys.allGroups) },
                            }[option]?.name
                          }
                          {...{
                            ...getTagProps({ index }),
                            onDelete: undefined,
                          }}
                        />
                      )
                    }
                    return (
                      <Chip
                        label={
                          {
                            ...groupsById,
                            '-1': { name: i18n.t(keys.allGroups) },
                          }[option]?.name
                        }
                        {...getTagProps({ index })}
                      />
                    )
                  })
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="standard"
                      label={i18n.t(keys.groups)}
                    />
                  )
                }}
              />
            </FormControl>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {i18n.t(keys.generic.cancel)}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {i18n.t(keys.generic.save)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
