import { DeliveryProcGroup } from '../../../../graphql/types'
import * as api from '../../../../graphql/api'

export const setDeliveryProcGroupQACompleteStatus = async (
  deliveryProcGroup: Pick<DeliveryProcGroup, 'deliveryId' | 'procGroup'>,
  qaStatus: 'failed' | 'complete'
) => {
  await api.deliveryProcGroup.update({
    pk: {
      deliveryId: deliveryProcGroup.deliveryId,
      procGroup: deliveryProcGroup.procGroup,
    },
    input: {
      qaStatus,
    },
  })
}
