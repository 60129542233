import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import Paper from '@mui/material/Paper'

import { url, urls } from '../../appNavigation/urls'
import { Pipeline } from '../../graphql/types'
import { connect } from '../../redux/connect'
import { RootStore } from '../../redux/types'
import MaestroPage from './MaestroPage'
import { refreshGetPipeline, selectGetPipeline } from './maestroSelectors'
import { PipelineUI } from './PipelineUI'
import { selectMe } from '../../data/selectMe'

class PipelinePage extends React.PureComponent<
  ReduxProps & RouteComponentProps
> {
  render() {
    const { pipelineSelector, user } = this.props

    const pipeline = pipelineSelector.data as Pipeline | undefined
    const title = pipeline
      ? `Maestro Pipeline | ${pipeline.name}`
      : 'Maestro Pipeline'

    return (
      <MaestroPage
        backTo={
          user?.roles.includes('admin')
            ? url(urls.pipelines)
            : url(urls.mapView)
        }
        backToTitle={user?.roles.includes('admin') ? 'Pipelines' : 'Map'}
        title={title}
      >
        <Paper className="Paper">
          <PipelineUI
            {...this.props}
            onRefresh={refreshGetPipeline}
            onJobClick={this.handleJobClicked}
            onPipelineClick={this.handlePipelineClicked}
          />
        </Paper>
      </MaestroPage>
    )
  }

  handleJobClicked = (jobId: string) => {
    this.props.history.push(url(urls.job, { jobId }, {}))
  }

  handlePipelineClicked = (pipelineId: string) => {
    this.props.history.push(url(urls.pipeline, { pipelineId }, {}))
  }
}

const mapState = (state: RootStore) => {
  return {
    pipelineSelector: selectGetPipeline(state),
    selectedJobId: state.router.searchParams['selected-job'],
    pipelineId: state.router.params.pipelineId,
    user: selectMe(state),
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(withRouter(PipelinePage))
