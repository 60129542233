import * as React from 'react'

import { Paper, Typography } from '@mui/material'

import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import withMap, { WithMap } from '../withMap'
import { classnames } from '../../util/classnames'

interface State {
  lng: number | undefined
  lat: number | undefined
}

interface Props {
  className?: string
}

class GPSControl extends React.PureComponent<
  Props & ReduxProps & AppDispatchProps & WithMap
> {
  geolocationWatchId = 0

  state: State = {
    lng: undefined,
    lat: undefined,
  }

  handleGPSTrackingStart = () => {
    if ('geolocation' in navigator) {
      this.geolocationWatchId = navigator.geolocation.watchPosition(
        (position) => {
          this.setState({
            lng: position.coords.longitude,
            lat: position.coords.latitude,
          })
        }
      )
    }
  }

  handleGPSTrackingStop = () => {
    navigator.geolocation.clearWatch(this.geolocationWatchId)
  }

  componentDidMount() {
    if (this.props.isEnabled) {
      this.handleGPSTrackingStart()
    }
  }

  componentWillUnmount() {
    this.handleGPSTrackingStop()
  }

  componentDidUpdate(prevProps: this['props'], prevState: this['state']) {
    const { isEnabled } = this.props

    if (isEnabled && !this.geolocationWatchId) {
      this.handleGPSTrackingStart()
    } else if (!isEnabled) {
      this.handleGPSTrackingStop()
    }
  }

  render() {
    const { isEnabled } = this.props

    if (!isEnabled) {
      return null
    }

    const className = classnames('no-print', this.props.className)

    const { lng, lat } = this.getLngLat()

    return (
      <Paper className={className} sx={{ padding: 1 }}>
        <Typography variant="caption">{i18n.t(keys.userLocation)}:</Typography>
        &nbsp;
        <Typography variant="caption">{lng}</Typography>
        &nbsp;
        <Typography variant="caption">{lat}</Typography>
      </Paper>
    )
  }

  getLngLat() {
    const { gpsOffset } = this.props

    const { lat, lng } = this.state

    if (lat === undefined || lng === undefined) {
      return {
        lat: i18n.t(keys.generic.na),
        lng: i18n.t(keys.generic.na),
      }
    }
    return {
      lng: i18n.t(keys.cursorLon, { lng: (lng + gpsOffset.lng).toFixed(6) }),
      lat: i18n.t(keys.cursorLat, { lat: (lat + gpsOffset.lat).toFixed(6) }),
    }
  }
}

const mapState = (state: RootStore) => ({
  isEnabled: state.preferences.showGPS,
  gpsOffset: state.preferences.gpsOffset,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withMap(GPSControl)
)
