import { client, gql } from '../graphql/client'
import { FILTER } from '../UI/Table/filterRows'
import { FILTER_INDEXES } from './DownloadFilters'
import { DownloadRequest, DownloadResult } from './types'

export const fetchDownloads = async (
  [organizationId, page, pageSize, filter, userId]: [
    number,
    number,
    number,
    string,
    number
  ],
  skip: () => void
): Promise<DownloadResult | void> => {
  if (organizationId === undefined || userId === undefined) {
    return skip()
  }

  const parsedFilter = FILTER.deserialize(filter)

  const result = Object.values(
    parsedFilter.reduce<Record<string, any>>(
      (filtersByColumn, { index, op, value }) => {
        const column = FILTER_INDEXES[index]

        if (filtersByColumn[column]) {
          filtersByColumn[column]._or.push({ [column]: { [`_${op}`]: value } })
        } else {
          filtersByColumn[column] = {
            _or: [{ [column]: { [`_${op}`]: value } }],
          }
        }

        return filtersByColumn
      },
      {}
    )
  )

  const where =
    result.length > 0
      ? {
          _and: [
            ...result,
            { organizationId: { _eq: organizationId }, OrganizationGroup: {} },
          ],
        }
      : { organizationId: { _eq: organizationId }, OrganizationGroup: {} }

  const offset = page * pageSize
  const limit = pageSize

  const results = await client.request<{
    DownloadRequest: DownloadRequest[]
    DownloadRequest_aggregate: { aggregate: { count: number } }
  }>({
    query: gql`
      query FETCH_ORGANIZATION_DOWNLOADS(
        $organizationId: Int!
        $offset: Int!
        $limit: Int!
        $userId: Int!
        $where: Download_bool_exp
      ) {
        DownloadRequest(
          where: { organizationId: { _eq: $organizationId }, Downloads: $where }
          order_by: { createdAt: desc }
          offset: $offset
          limit: $limit
        ) {
          id
          createdBy: CreatedBy {
            firstName
            lastName
          }
          createdAt
          expiresAt
          name
          Downloads(where: $where, order_by: { createdAt: desc }) {
            id
            requestId
            status
            filename
            createdAt
            settings
            file: File {
              url
              downloadToken
            }
            downloadLogs: DownloadLogs(
              where: { userId: { _eq: $userId }, type: { _eq: "downloaded" } }
            ) {
              id
            }
            organizationGroup: OrganizationGroup {
              name
              id
            }
            delivery: Delivery {
              id
              flights: Flights {
                date
              }
            }
          }
        }

        DownloadRequest_aggregate(
          where: { organizationId: { _eq: $organizationId }, Downloads: $where }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: {
      organizationId,
      offset,
      limit,
      userId,
      where,
    },
  })

  return {
    downloadRequests: results.DownloadRequest,
    count: results.DownloadRequest_aggregate.aggregate.count,
    currentCount: results.DownloadRequest.length,
  }
}
