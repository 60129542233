import './DeliveryUpload.scss'

import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Stack,
  Tooltip,
} from '@mui/material'

import errorAlert from '../../../admin/errorAlert'
import { getStatusIcon } from '../../../admin/Maestro/utils'
import { Row } from '../../../admin/UI/Row'
import warnConfirm from '../../../admin/warnConfirm'
import { RouteParams } from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../../graphql/api'
import { connect } from '../../../redux/connect'
import { RootStore } from '../../../redux/types'
import EnhancedTableToolbar from '../../../UI/EnhancedTable/EnhancedTableToolbar'
import TooltipIconButton from '../../../UI/TooltipIconButton'
import { asyncForEach } from '../../../util/syncArrayHelpers'
import { startJob } from '../../../vvapi/maestro'
import ListFlight from '../Flight/ListFlight'
import DeliveryParcelFileStatusTable from './DeliveryParcelFileStatus/DeliveryParcelFileStatusTable'
import { ListDeliveryParcelFileStatus } from './DeliveryParcelFileStatus/types'
import {
  refreshListDeliveryParcelFileStatusForDelivery,
  selectDeliveryParcelFilesByGroup,
  selectDeliveryParcelFilesByParcelId,
  selectDeliveryParcelFilesByProcGroup,
  selectDeliveryParcelFileStatusesByGroupForDelivery,
  selectDeliveryParcelFileStatusesByKeyForDelivery,
  selectListDeliveryParcelFileStatusForDelivery,
} from './selectListDeliveryParcelFileStatusForDelivery'
import { getColor } from './utils'

interface State {
  selection: ListDeliveryParcelFileStatus[]
  downloadProcessing: boolean
}

const buttonStyle = {
  padding: 5,
  width: 30,
  minWidth: 0,
}

class DeliveryUpload extends React.Component<
  ReduxProps & RouteComponentProps<RouteParams>,
  State
> {
  state: State = { selection: [], downloadProcessing: false }

  render() {
    const {
      deliveryParcelFileStatusesByGroup,
      deliveryParcelFilesByParcelId,
      deliveryParcelFilesByGroup,
      deliveryParcelFilesByProcGroup,
      deliveryParcelFilesByKey,
    } = this.props
    const selectionActionsEnabled = this.state.selection?.length > 0

    return (
      <>
        <div className="Paper">
          <ListFlight />
        </div>
        <div className="Paper">
          <EnhancedTableToolbar
            style={{ paddingLeft: 0, paddingRight: 0 }}
            title="Delivery Parcel Files"
            numSelected={this.state.selection?.length ?? 0}
            onClickRefresh={this.handleRefresh}
            actions={() => (
              <Row>
                {this.renderStatusFilter()}
                <Row>
                  {this.state.downloadProcessing ? (
                    <Stack
                      sx={{ height: 40, width: 40, p: 1 }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        sx={{ position: 'relative', display: 'inline-flex' }}
                      >
                        <CircularProgress />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon>cloud_download</Icon>
                        </Box>
                      </Box>
                    </Stack>
                  ) : (
                    <TooltipIconButton
                      disabled={!selectionActionsEnabled}
                      title="Download DeliveryParcelFile(s)"
                      onClick={() =>
                        this.handleDownloadDeliveryParcelFiles(
                          this.state.selection
                        )
                      }
                    >
                      <Icon>cloud_download</Icon>
                    </TooltipIconButton>
                  )}
                  <TooltipIconButton
                    disabled={!selectionActionsEnabled}
                    title="Re-process DeliveryParcelFile(s)"
                    onClick={() =>
                      this.handleReprocessDeliveryParcelFiles(
                        this.state.selection
                      )
                    }
                  >
                    <Icon>redo</Icon>
                  </TooltipIconButton>
                  <TooltipIconButton
                    disabled={!selectionActionsEnabled}
                    title="Delete DeliveryParcelFile(s)"
                    onClick={() =>
                      this.handleDeleteDeliveryParcelFiles(this.state.selection)
                    }
                  >
                    <Icon>delete</Icon>
                  </TooltipIconButton>
                </Row>
              </Row>
            )}
          />
          <AsyncSelectorStatusOverlay requests={this.props.deliveryParcelFiles}>
            <DeliveryParcelFileStatusTable
              rows={deliveryParcelFileStatusesByGroup}
              filesByGroup={deliveryParcelFilesByGroup}
              filesByProcGroup={deliveryParcelFilesByProcGroup}
              filesByParcel={deliveryParcelFilesByParcelId}
              filesByKey={deliveryParcelFilesByKey}
              selectable
              onSelectionChanged={this.handleSelectionChanged}
              onRefresh={this.handleRefresh}
            />
          </AsyncSelectorStatusOverlay>
        </div>
      </>
    )
  }

  renderStatusFilter = () => {
    const { statusFilter } = this.props

    return (
      <Row>
        <Tooltip title="Filter by status">
          <Icon fontSize="small">filter_alt</Icon>
        </Tooltip>
        <span style={{ marginRight: 8 }}>:</span>
        <Row className="filter-buttons">
          {[
            'created',
            'complete',
            'running',
            'pending',
            'queued',
            'cancelled',
            'error',
            'timeout',
          ].map((status) => (
            <Tooltip key={status} title={status}>
              <Button
                className="button"
                onClick={(ev) => this.handleFilterStatusClick(ev, status)}
                style={{
                  ...buttonStyle,
                }}
                variant={statusFilter.has(status) ? 'contained' : 'outlined'}
                size="small"
              >
                <Icon fontSize="small" style={{ color: getColor(status) }}>
                  {getStatusIcon(status)}
                </Icon>
              </Button>
            </Tooltip>
          ))}
        </Row>
      </Row>
    )
  }

  handleFilterStatusClick = (ev: React.SyntheticEvent, status: string) => {
    ev.stopPropagation()
    ev.preventDefault()

    const { statusFilter, location } = this.props

    const searchParams = new URLSearchParams(location.search)

    if (statusFilter.has(status)) {
      statusFilter.delete(status)
    } else {
      statusFilter.add(status)
    }

    if (statusFilter.size === 0) {
      searchParams.delete('status')
    } else {
      searchParams.set('status', Array.from(statusFilter).join(','))
    }

    this.props.history.replace({ search: searchParams.toString() })
  }

  handleDeleteDeliveryParcelFiles = async (
    selection: ListDeliveryParcelFileStatus[]
  ) => {
    const confirmed = await warnConfirm({
      title: `Are you sure you want to delete ${selection.length} selected DeliveryParcelFile(s)?`,
      message:
        'Deleting a DeliveryParcelFile will delete all associated sources and layers. This is not reversible.',
      action: 'Delete',
    })

    if (!confirmed) {
      return
    }

    try {
      await asyncForEach(
        selection,
        { limit: 2 },
        async ({ deliveryId, parcelId, filename }) => {
          await api.deliveryParcelFile.delete({
            pk: { deliveryId, parcelId, filename },
          })
        }
      )
      this.handleRefresh()
    } catch (e) {
      this.softError(e, 'Failed to Delete Flight', e.message)
    }
  }

  handleReprocessDeliveryParcelFiles = async (
    selection: ListDeliveryParcelFileStatus[]
  ) => {
    await asyncForEach(selection, { limit: 2 }, async ({ Job }) => {
      if (Job?.id) {
        await startJob(Job.id)
      }
    })

    this.handleRefresh()
  }

  handleDownloadDeliveryParcelFiles = async (
    files: ListDeliveryParcelFileStatus[]
  ) => {
    this.setState({ downloadProcessing: true }, async () => {
      await api.deliveryParcelFile.downloadMany(files)
      this.setState({ downloadProcessing: false })
    })
  }

  softError = (
    error: Error,
    title: string,
    message: string,
    extras?: Record<string, any>
  ) =>
    errorAlert({
      error,
      title,
      message,
      extras,
      tags: {
        category: 'EditDelivery',
      },
    })

  handleSelectionChanged = (selection: ListDeliveryParcelFileStatus[]) => {
    this.setState({ selection })
  }

  handleRefresh = () => {
    refreshListDeliveryParcelFileStatusForDelivery()
  }
}

const mapState = (state: RootStore) => ({
  deliveryParcelFiles: selectListDeliveryParcelFileStatusForDelivery(state),
  deliveryParcelFileStatusesByGroup:
    selectDeliveryParcelFileStatusesByGroupForDelivery(state),
  deliveryParcelFilesByGroup: selectDeliveryParcelFilesByGroup(state),
  deliveryParcelFilesByProcGroup: selectDeliveryParcelFilesByProcGroup(state),
  deliveryParcelFilesByParcelId: selectDeliveryParcelFilesByParcelId(state),
  deliveryParcelFilesByKey:
    selectDeliveryParcelFileStatusesByKeyForDelivery(state),
  statusFilter: new Set(state.router.searchParams.status?.split(',') ?? []),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(DeliveryUpload)
