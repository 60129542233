import { Variable } from '../../../graphql/types'
import { TableFormatter } from '../../../UI/Table/types'

const keyColumn: TableFormatter<Variable> = {
  header: () => 'Key',
  data: ({ key }) => key,
  orderBy: (direction) => `
    {
      key: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    key: { [`_${op}`]: value },
  }),
}
const typeColumn: TableFormatter<Variable> = {
  header: () => 'Type',
  data: ({ value }) => typeof value,
}
const valueColumn: TableFormatter<Variable> = {
  header: () => 'Value',
  data: ({ value }) =>
    typeof value === 'object' ? JSON.stringify(value) : value,
  orderBy: (direction) => `
    {
      value: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    value: { [`_${op}`]: value },
  }),
}
export const tableFormatter = [keyColumn, valueColumn, typeColumn]
