export default function didPropChange<T>(
  propName: keyof T,
  prevProps: T | undefined,
  nextProps: T | undefined
) {
  if (!nextProps || !prevProps) {
    return true
  }

  return prevProps[propName] !== nextProps[propName]
}
