import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { gql, query } from '../../../graphql/client'

type MigrationState = 'running' | 'pending' | 'not-running'

interface MaestroJobAggregate {
  running: {
    aggregate: {
      count: number
    }
  }
  pending: {
    aggregate: {
      count: number
    }
  }
}

const fetchMigrationState = async () => {
  let state: MigrationState = 'not-running'

  const {
    pending: {
      aggregate: { count: pendingCount },
    },
    running: {
      aggregate: { count: runningCount },
    },
  } = await query<MaestroJobAggregate>({
    query: gql`
      query MIGRATION_JOB_COUNTS {
        running: maestro_Job_aggregate(
          where: {
            taskName: { _like: "migrate%" }
            JobAttempts: { status: { _eq: "running" } }
          }
        ) {
          aggregate {
            count
          }
        }
        pending: maestro_Job_aggregate(
          where: { taskName: { _like: "migrate%" }, isPending: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
  })

  if (runningCount > 0) {
    state = 'running'
  } else if (pendingCount > 0) {
    state = 'pending'
  }

  return {
    state,
  }
}

const { selector: selectMigrationState, refresh: refreshMigrationState } =
  createAsyncSelector({
    resource: 'GetMigrationState',
    inputs: {},
    fetcher: fetchMigrationState,
  })

export { selectMigrationState, refreshMigrationState }
