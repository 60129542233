import * as React from 'react'

export class Column extends React.PureComponent<{
  style?: React.CSSProperties
  className?: string
}> {
  render() {
    return (
      <div
        className={this.props.className}
        style={{
          justifyContent: 'start',
          alignItems: 'flex-start',
          ...this.props.style,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.props.children}
      </div>
    )
  }
}
