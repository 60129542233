import { client, gql } from '../../../graphql/client'

export const deleteOrganizationUserRole = async (
  userId: number,
  organizationId: string
) => {
  await client.request({
    query: gql`
      mutation DELETE_ORGANIZATION_USER_ROLE(
        $userId: Int!
        $organizationId: Int!
      ) {
        delete_OrganizationUser(
          where: {
            userId: { _eq: $userId }
            organizationId: { _eq: $organizationId }
          }
        ) {
          affected_rows
        }
      }
    `,
    variables: { userId, organizationId },
  })
}
