import { createSelector } from 'reselect'

import circle from '@turf/circle'
import { point } from '@turf/helpers'
import rhumbDestination from '@turf/rhumb-destination'

import { RootStore } from '../../redux/types'
import { OrgFeatureGeometry } from '../../vvapi/models'

export const USER_POSITION_MARKER = 'USER-POSITION-MARKER'
export const USER_POSITION_ACURRACY_MARKER = 'USER-POSITION-ACURRACY-MARKER'
export const USER_POSITION_HEADING_LINE = 'USER-POSITION-HEADING-LINE'

const getUserPosition = (state: RootStore) => state.postgis.userPosition
const getUserPositionOffset = (state: RootStore) => state.preferences.gpsOffset

export const selectAdjustedUserPosition = createSelector(
  getUserPosition,
  getUserPositionOffset,
  (userPosition, gpsOffset) => {
    if (!userPosition) {
      return null
    }

    if (!gpsOffset) {
      return userPosition as GeolocationPosition
    }

    return {
      ...userPosition,
      coords: {
        accuracy: userPosition.coords.accuracy,
        speed: userPosition.coords.speed,
        heading: userPosition.coords.heading,
        latitude: userPosition.coords.latitude + gpsOffset.lat,
        longitude: userPosition.coords.longitude + gpsOffset.lng,
      },
    } as GeolocationPosition
  }
)

export const getUserPositionLayer = createSelector(
  selectAdjustedUserPosition,
  (userPosition) => {
    const sources = {}
    const layers = []

    if (userPosition && !sources[USER_POSITION_HEADING_LINE]) {
      const distance = 0.02
      const heading = userPosition.coords.heading || 0
      const userPositionPoint = point([
        userPosition.coords.longitude,
        userPosition.coords.latitude,
      ])
      const { geometry: destinationPoint } = rhumbDestination(
        userPositionPoint,
        distance,
        heading,
        { units: 'kilometers' }
      )

      if (destinationPoint) {
        sources[USER_POSITION_HEADING_LINE] = {
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [
                [userPosition.coords.longitude, userPosition.coords.latitude],
                [
                  destinationPoint.coordinates[0],
                  destinationPoint.coordinates[1],
                ],
              ],
            },
          },
          type: 'geojson',
          attribution: '&copy; VineView',
        }
        layers.push({
          id: USER_POSITION_HEADING_LINE,
          type: 'line',
          source: USER_POSITION_HEADING_LINE,
          paint: {
            'line-color': '#fff',
            'line-opacity': 0.5,
            'line-width': 2,
          },
        })
      }
    }
    if (userPosition && !sources[USER_POSITION_ACURRACY_MARKER]) {
      const circleFeature = circle(
        [userPosition.coords.longitude, userPosition.coords.latitude],
        userPosition.coords.accuracy,
        { units: 'meters', steps: 100 }
      )

      if (circleFeature && circleFeature.geometry) {
        sources[USER_POSITION_ACURRACY_MARKER] = {
          data: circleFeature,
          type: 'geojson',
          attribution: '&copy; VineView',
        }
        layers.push({
          id: USER_POSITION_ACURRACY_MARKER,
          type: 'fill',
          source: USER_POSITION_ACURRACY_MARKER,
          paint: {
            'fill-opacity': 0.2,
            'fill-color': '#3498DB',
            'fill-outline-color': '#3498DB',
            'fill-antialias': true,
          },
        })
      }
    }
    if (userPosition && !sources[USER_POSITION_MARKER]) {
      const geometry: OrgFeatureGeometry = {
        type: 'Point',
        coordinates: [
          userPosition.coords.longitude,
          userPosition.coords.latitude,
        ],
      }
      const feature: GeoJSON.Feature<OrgFeatureGeometry> = {
        geometry,
        type: 'Feature',
        properties: {},
      }

      sources[USER_POSITION_MARKER] = {
        data: feature,
        type: 'geojson',
        attribution: '&copy; VineView',
      }
      layers.push({
        id: USER_POSITION_MARKER,
        type: 'circle' as 'circle',
        source: USER_POSITION_MARKER,
        paint: {
          'circle-radius': 10,
          'circle-opacity': 0.8,
          'circle-color': '#3498DB',
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff',
          'circle-stroke-opacity': 0.9,
        },
      })
    }

    return { sources, layers }
  }
)
