import { ActionCreator, AnyAction } from 'redux'
import i18n, { keys } from '../../i18n'
import { AppDispatch } from '../../redux/types'
import { RequestError } from '../../util/request'

export function reduxFormSubmissionFactory(
  action: ActionCreator<AnyAction>,
  dispatch: AppDispatch,
  fieldMap?: FieldNameI18n
) {
  return async (values: any) => {
    try {
      await dispatch(action(values))
    } catch (err) {
      if (err instanceof RequestError) {
        if (err.response.status >= 400 && err.response.status < 500) {
          throw err
        }
      }
      if (err.fetchHttpResponse === undefined) {
        throw new Error(i18n.t(keys.serverError))
      }

      const data = await err.fetchHttpResponse.json()
      const errors = data.errors
      handleServerValidationErrors(errors, fieldMap)
    }
  }
}

interface FieldNameI18n {
  [fieldName: string]: string
}

function handleServerValidationErrors(
  errors: object,
  fieldMap?: FieldNameI18n
) {
  const submissionErrors = {}

  // tslint:disable-next-line:forin
  for (const error in errors) {
    const map = {
      field: fieldMap ? fieldMap[error] : error,
    }
    errors[error].map((err: any) => { // eslint-disable-line
      const validationError = err.validatorName
      const errorMessage = i18n.t(keys.forms[validationError], map)
      submissionErrors[error] = errorMessage
    })
  }

  throw new Error(JSON.stringify(submissionErrors))
}
