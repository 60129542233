import * as React from 'react'
import { Badge, styled } from '@mui/material'
import { AuthImage } from './AuthImage'
import { getMediaThumbnailUrl } from './util/getUrl'

interface Props {
  thumbnailMediaId?: string
  mediaCount?: number
  mediaName?: string
  onClickMedia: (mediaId: string | undefined) => void
}

const CountBadge = styled(Badge)({
  height: '20px',
  width: '20px',
  '& .MuiBadge-badge': {
    minWidth: '12px',
    height: '12px',
    backgroundColor: '#E8E8E8',
    color: '#303030',
    padding: '0',
    fontWeight: 700,
    fontSize: '8px',
  },
})

const Image = styled(AuthImage)({
  width: '100%',
  height: '100%',
  borderRadius: '4px',
})

export const MediaIndicator = ({
  thumbnailMediaId,
  mediaCount,
  mediaName,
  onClickMedia,
}: Props) => {
  if (!thumbnailMediaId || !mediaCount || mediaCount === 0) {
    return null
  }

  return (
    <CountBadge
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={mediaCount}
      invisible={mediaCount <= 1}
    >
      <Image
        src={getMediaThumbnailUrl({ id: thumbnailMediaId })}
        alt={mediaName}
        onClick={(event) => {
          onClickMedia(thumbnailMediaId)
          event.stopPropagation()
        }}
      />
    </CountBadge>
  )
}
