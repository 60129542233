import * as React from 'react'

import centroid from '@turf/centroid'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'

import { NoteWithTemplate, OrgFeatureGeometry, User } from '../../vvapi/models'
import { useRedux } from '../../hooks/useRedux'
import {
  selectFilteredNotes,
  selectNotesById,
  selectNotesByProjectId,
  selectProjectsById,
} from '../../notes/selectors'
import {
  downloadCSV,
  downloadXLSX,
  DownloadTableOptions,
} from '../../util/table'
import areaUserString from '../../notes/areaUserString'
import i18n, { keys } from '../../i18n'
import ItemSelectionForm from '../../UI/ItemSelection/ItemSelectionForm'
import { Project } from '../../graphql/types'
import { PIN_POINT_BUTTON_PRESS_TEMPLATE_ID } from '../../notes/NoteForm'
import { convertLength } from '../../util/units/length'
import { formatLocationError } from '../../util/formatLocationError'
import { blendFixType } from '../../notes/PinPointButtonPress'

interface Props {
  open: boolean
  onClose: () => void
}

type ExportFileType = 'csv' | 'xlsx'

interface ProjectWithNoteCount extends Project {
  noteCount: string
}

const ProjectItemComponent = ({ item }: { item: ProjectWithNoteCount }) => {
  const theme = useTheme()

  return (
    <div>
      <Typography variant="body1" component="span">
        {item.name}
      </Typography>
      <Typography
        variant="body1"
        component="span"
        style={{
          color: theme.palette.text.primary,
          opacity: 0.7,
        }}
      >
        &nbsp;({item.noteCount})
      </Typography>
    </div>
  )
}

export const ExportProjectsDialog = ({ open, onClose }: Props) => {
  const [state] = useRedux()

  const selectedProjectIds = state.notes.selectedProjectIds
  const projectsById = selectProjectsById(state)
  const selectedProjects = React.useMemo(() => {
    return selectedProjectIds.reduce((acc, id) => {
      const project = projectsById[id]
      if (project) {
        acc.push(project)
      }
      return acc
    }, [] as Project[])
  }, [selectedProjectIds, projectsById])
  const notesById = selectNotesById(state)
  const notesByProjectId = selectNotesByProjectId(state)
  const filteredNoteIds = selectFilteredNotes(state)
  const isFiltered = React.useMemo(() => {
    const { searchTerm, fromDate, toDate, formType, projectType } =
      state.notes.filterInfo
    return !!searchTerm || !!formType || !!fromDate || !!toDate || !!projectType
  }, [state.notes.filterInfo])
  const filteredProjects = React.useMemo(() => {
    if (!isFiltered) {
      return Object.values(projectsById)
    }
    const projectIds = new Set(
      filteredNoteIds.map((id) => notesById[id].projectId ?? 'general')
    )
    const projs = Object.values(projectsById).filter((project) =>
      projectIds.has(project.id)
    )
    return projs
  }, [isFiltered, filteredNoteIds, projectsById, notesById])
  const preferredUnitSystem = state.preferences.preferredUnitSystem

  const [fileType, setFileType] = React.useState<ExportFileType>('csv')
  const [exportFileName, setExportFileName] = React.useState('Notes')

  const handleExportCSV = (options: DownloadTableOptions) => {
    downloadCSV({
      data: options.data,
      filename: options.filename,
      headers: options.headers,
    })
    onClose()
  }

  const handleExportXLSX = (options: DownloadTableOptions) => {
    downloadXLSX({
      data: options.data,
      filename: options.filename,
      headers: options.headers,
    })
    onClose()
  }

  const makeTableData = (selectedNotes: string[]) => {
    const dataRows: ReturnType<typeof makeTableRow>['row'][] = []
    const unionedContentHeaders: Record<string, string> = {}
    for (const selectedNote of selectedNotes) {
      const note = notesById[selectedNote]
      if (note.templateId === PIN_POINT_BUTTON_PRESS_TEMPLATE_ID) {
        const { carrSoln: _, ...contentWithoutCarrSoln } = note.content
        const { row, contentHeaders } = makeTableRow({
          ...note,
          content: {
            ...contentWithoutCarrSoln,
            horizontalAccuracy: note.content.horizontalAccuracy
              ? {
                  value: formatLocationError(
                    note.content.horizontalAccuracy.value as number
                  ),
                }
              : { value: '' },
            altitude: note.content.altitude
              ? {
                  value: convertLength(
                    note.content.altitude.value as number,
                    'meter',
                    preferredUnitSystem,
                    0.1
                  ).toString(),
                }
              : { value: '' },
            hmsl: note.content.hmsl
              ? {
                  value: convertLength(
                    note.content.hmsl.value as number,
                    'meter',
                    preferredUnitSystem,
                    0.1
                  ).toString(),
                }
              : { value: '' },
            fixType: { value: blendFixType(note.content) },
          },
        })
        contentHeaders.forEach(
          (header) =>
            (unionedContentHeaders[header] = i18n.t(
              `noteForm.reservedNames.pinpoint.${header}`
            ))
        )

        dataRows.push(row)
      } else {
        const { row, contentHeaders } = makeTableRow(note)

        contentHeaders.forEach(
          (header) =>
            (unionedContentHeaders[header] = i18n.t(
              `noteForm.reservedNames.${header}`
            ))
        )

        dataRows.push(row)
      }
    }

    const headers = [
      { label: 'Template', key: 'Template' },
      { label: 'Project', key: 'Project' },
      { label: 'Geometry Type', key: 'Geometry Type' },
      { label: 'Area', key: 'Area' },
      { label: 'Latitude', key: 'Latitude' },
      { label: 'Longitude', key: 'Longitude' },
      { label: 'Coordinates', key: 'Coordinates' },
      { label: 'Created By', key: 'Created By' },
      { label: 'Created At', key: 'Created At' },
      { label: 'Updated By', key: 'Updated By' },
      { label: 'Updated At', key: 'Updated At' },
      ...Object.entries(unionedContentHeaders).map(([key, label]) => ({
        label,
        key,
      })),
    ]

    const data = dataRows.length > 0 ? dataRows : [{}]

    return { data, headers }
  }

  const makeTableRow = (note: NoteWithTemplate) => {
    const {
      createdBy,
      updatedBy,
      feature: { geometry },
      content,
    } = note

    const centerPoint = centroid(geometry as any)
    const lng = centerPoint.geometry!.coordinates[0]
    const lat = centerPoint.geometry!.coordinates[1]

    let areaString = ''
    if (geometry.type === 'Polygon') {
      areaString = areaUserString(geometry, preferredUnitSystem)
    }

    const mappedContent = mapContent(content)
    return {
      row: {
        Template: i18n.t(`noteForm.reservedNames.${note.templateName}`, {
          defaultValue: note.templateName,
        }),
        Project: note.projectId
          ? projectsById[note.projectId].name
          : i18n.t(keys.noteForm.reservedNames.__VV__GENERAL__TEMPLATE__),
        'Geometry Type': geometry.type ? geometry.type : '',
        Area: areaString ? areaString : '',
        Latitude: lat.toString() ? lat.toString() : '',
        Longitude: lng.toString() ? lng.toString() : '',
        Coordinates: JSON.stringify(getCoordinates(geometry))
          ? JSON.stringify(getCoordinates(geometry))
          : '',
        'Created By': getName(createdBy) ? getName(createdBy) : '',
        'Created At': note.createdAt ? note.createdAt : '',
        'Updated By': getName(updatedBy) ? getName(updatedBy) : '',
        'Updated At': note.updatedAt ? note.updatedAt : '',
        ...mappedContent,
      },
      contentHeaders: Object.keys(mappedContent),
    }
  }

  const mapContent = (content: NoteWithTemplate['content']) => {
    const mappedContent = {}
    Object.entries(content).forEach(([key, value]) => {
      if (value.type === 'checkbox') {
        mappedContent[key] = Boolean(value.value)
      } else {
        mappedContent[key] = value.value
      }
    })

    return mappedContent
  }
  const getName = (user?: User) => {
    return user && `${user.firstName} ${user.lastName}`
  }

  const getCoordinates = (geometry: OrgFeatureGeometry) => {
    let coordinates
    if (geometry.type === 'Polygon') {
      coordinates = geometry.coordinates[0]
    } else {
      coordinates = geometry.coordinates
    }

    return coordinates
  }

  const onCommit = (selectedProjects: Project[]) => {
    const filteredNotesSet = new Set(filteredNoteIds)
    const selectedNotes: string[] = []
    for (const project of selectedProjects) {
      if (project.Notes_aggregate.aggregate.count > 0) {
        selectedNotes.push(
          ...Object.keys(notesByProjectId[project.id]).filter((noteId) =>
            filteredNotesSet.has(noteId)
          )
        )
      }
    }

    const { data, headers } = makeTableData(selectedNotes)
    const tableOptions = { data, headers, filename: exportFileName }

    if (fileType === 'csv') {
      handleExportCSV(tableOptions)
    } else {
      handleExportXLSX(tableOptions)
    }
  }

  const filteredProjectWithCountName = React.useMemo<
    ProjectWithNoteCount[]
  >(() => {
    return filteredProjects.map((project) => ({
      ...project,
      noteCount: `${project.Notes_aggregate.aggregate.count}`,
    }))
  }, [filteredProjects])

  return (
    <ItemSelectionForm
      open={open}
      onClose={onClose}
      onCommit={onCommit}
      exportFileName={exportFileName}
      title={i18n.t(keys.notes.exportBoxTitle)}
      sectionTitle={i18n.t(keys.projectsToExport)}
      items={filteredProjectWithCountName}
      defaultSelectedItems={selectedProjects}
      itemComponent={ProjectItemComponent}
      validate={({ exportFileName = '' }) => exportFileName.trim().length > 0}
      additionalSections={{
        [i18n.t(keys.filename)]: (
          <Stack>
            <FormControl error={exportFileName?.trim().length === 0}>
              <TextField
                fullWidth
                autoFocus
                value={exportFileName}
                error={exportFileName?.trim().length === 0}
                onChange={(event) => setExportFileName(event.target.value)}
              />
              {exportFileName.trim().length === 0 && (
                <FormHelperText>Please Enter a Filename</FormHelperText>
              )}
            </FormControl>
          </Stack>
        ),
        [i18n.t(keys.generic.downloadFormat)]: (
          <FormControl>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value as ExportFileType)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="xlsx"
                control={<Radio />}
                label={i18n.t(keys.generic.xlsx)}
              />
              <FormControlLabel
                value="csv"
                control={<Radio />}
                label={i18n.t(keys.generic.csv)}
              />
            </RadioGroup>
          </FormControl>
        ),
      }}
    />
  )
}
