type JSONType = Record<string, any> | any[]

const readJSON = <T = JSONType>(file: File) => {
  return new Promise<T>((resolve, reject) => {
    const reader = new FileReader()
    const onLoad = (event: any) => {
      try {
        const output = JSON.parse(event.target.result)
        resolve(output)
      } catch (e) {
        reject(e)
      }
    }

    reader.onload = onLoad

    reader.readAsText(file)
  })
}

export default readJSON
