import * as React from 'react'

import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'

import MaestroHome from './MaestroHome'

import useAsync from '../../hooks/useAsync'
import { client, gql } from '../../graphql/client'
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table'
import { Job } from './JobTable/types'
import { ArrowForward } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { url, urls } from '../../appNavigation/urls'

interface QueueJob {
  jobId: string
  job: Pick<Job, 'id' | 'taskName'>
  status: string
  createdAt: string
}

const EMPTY_QUEUE_JOBS: QueueJob[] = []

export const MaestroQueue = () => {
  const history = useHistory()
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25, //customize the default page size
  })

  const [sorting, setSorting] = React.useState<MRT_SortingState>([])

  const [maestroQueue, refreshMaestroQueue] = useAsync(async () => {
    const order_by = sorting.map((sort) => {
      return { [sort.id]: sort.desc ? 'desc' : 'asc' }
    })

    const {
      queueJobs,
      queueJobsAggregate: {
        aggregate: { count },
      },
    } = await client.request<{
      queueJobs: QueueJob[]
      queueJobsAggregate: { aggregate: { count: number } }
    }>({
      query: gql`
        query MAESTRO_QUEUE(
          $limit: Int!
          $offset: Int!
          $order_by: [maestro_Queue_order_by!]
        ) {
          queueJobs: maestro_Queue(
            limit: $limit
            offset: $offset
            order_by: $order_by
          ) {
            jobId
            job: Job {
              id
              taskName
            }
            status
            createdAt
          }

          queueJobsAggregate: maestro_Queue_aggregate {
            aggregate {
              count
            }
          }
        }
      `,
      variables: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        order_by,
      },
    })

    return { queueJobs, total: count }
  }, [pagination.pageIndex, pagination.pageSize, sorting])

  const table = useMaterialReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    getRowId: (row) => row.jobId,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 75, 100],
    },
    rowCount: maestroQueue?.result?.total ?? 0,
    data: maestroQueue?.result?.queueJobs ?? EMPTY_QUEUE_JOBS,
    columns: [
      {
        header: 'Task Name',
        accessorKey: 'job.taskName',
      },
      {
        header: 'Status',
        accessorKey: 'status',
      },
      {
        header: 'Status Changed',
        accessorKey: 'createdAt',
      },
      {
        header: 'Actions',
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              history.push(url(urls.job, { jobId: row.id }))
            }}
          >
            <ArrowForward />
          </IconButton>
        ),
      },
    ] as MRT_ColumnDef<QueueJob>[],

    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    onSortingChange: setSorting,
    state: {
      pagination,
      isLoading: maestroQueue.status === 'pending', //cell skeletons and loading overlay
      showProgressBars: maestroQueue.status === 'pending', //progress bars while refetching
    },
  })

  return (
    <MaestroHome>
      <EnhancedTableToolbar
        style={{ paddingLeft: 0, paddingRight: 0 }}
        title={`Maestro Queue`}
        numSelected={0}
        onClickRefresh={refreshMaestroQueue}
      />
      <MaterialReactTable table={table} />
    </MaestroHome>
  )
}
