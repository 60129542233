/**
 * tileset id's: uploaded to https://studio.mapbox.com/tilesets/
 */
const tilesets = [
  { id: 'vineview.3iiuog9l', layer: 'CA_Soil_1-be8q7w' },
  { id: 'vineview.0yoolyed', layer: 'CA_Soil_2-53uvoc' },
  { id: 'vineview.1bzavfzg', layer: 'CA_Soil_3-0f9rbl' },
  { id: 'vineview.4tegivsg', layer: 'CA_Soil_4-55sjsl' },
  { id: 'vineview.8we22588', layer: 'CA_Soil_5-90suce' },
  { id: 'vineview.d89sf5ws', layer: 'CA_Soil_6-aj95cz' },
  { id: 'vineview.czn09mtt', layer: 'CA_Soil_7-detams' },
]

export const SOIL_CALIFORNIA_DATA_LAYER = 'SOIL-CALIFORNIA-polygon'
export const SOIL_CALIFORNIA_LABEL_LAYER = 'SOIL-CALIFORNIA-label'

const COLOR = 'hsl(0, 61%, 26%)'
const TEXT_COLOR = 'hsl(31, 65%, 84%)'
const TEXT_HALO_COLOR = 'hsl(31, 65%, 10%)'
const sources: Record<string, mapboxgl.VectorSource> = {}
const layers: mapboxgl.Layer[] = []

// add layers and sources from tilesets
tilesets.forEach(({ id, layer }, index) => {
  const sourceId = `soil-${index}`

  sources[sourceId] = {
    url: `mapbox://${id}`,
    type: 'vector',
  }

  const commonLayer = {
    source: sourceId,
    'source-layer': layer,
    layout: {},
  }

  // push line layer so that we can control line width
  layers.push({
    ...commonLayer,
    id: `SOIL-CALIFORNIA-line-${index}`,
    type: 'line',
    paint: {
      'line-color': COLOR,
      'line-width': 2,
    },
  })

  // push polygon layer
  layers.push({
    ...commonLayer,
    id: `${SOIL_CALIFORNIA_DATA_LAYER}-${index}`,
    type: 'fill',
    paint: {
      'fill-color': 'rgba(0, 0, 0, 0)',
      'fill-outline-color': COLOR,
    },
  })

  // push polygon layer
  layers.push({
    ...commonLayer,
    id: `${SOIL_CALIFORNIA_LABEL_LAYER}-${index}`,
    type: 'symbol',
    layout: {
      'text-field': [
        'format',
        ['get', 'vv_desc'],
        {},
        '\n',
        {},
        ['get', 'vv_slope'],
        { 'font-scale': 0.8 },
      ] as any,
      'text-size': 12,
      'text-justify': 'auto' as any,
      'symbol-placement': 'point',
      /** in ems */
      'text-offset': [0, 0.6],
    },
    paint: {
      'text-color': TEXT_COLOR,
      'text-halo-color': TEXT_HALO_COLOR,
      'text-halo-blur': 2,
      'text-halo-width': 2,
    },
  })
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sources,
  layers,
}
