import { AnyAction, Store } from 'redux'
import { RootStore } from './types'

let store: Store<RootStore, AnyAction>

export function setStore(value: Store<RootStore, AnyAction>) {
  store = value
}

export function getState(): RootStore | undefined {
  if (!store) {
    return
  }

  return store.getState()
}

export function dispatch(event: AnyAction) {
  if (!store) {
    return
  }

  return store.dispatch(event)
}
