import './SpeedTracker.scss'

import * as React from 'react'

import { Button, Icon, Paper, Typography } from '@mui/material'

import i18n, { keys } from '../../i18n'
import {
  selectIsSpeedTrackerAvailable
} from '../../postgis/selectors/selectIsSpeedTrackerAvailable'
import { createSpeedFeaturesSelectors } from '../../postgis/selectors/selectSpeedFeatures'
import {
  selectSpeedMapSourcesAndSourceLayers
} from '../../postgis/selectors/selectSpeedMapSourcesAndSourceLayers'
import { selectAdjustedUserPosition } from '../../postgis/selectors/UserPositionLayerSelector'
import { connect } from '../../redux/connect'
import { updatePreferences } from '../../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { classnames } from '../../util/classnames'
import { isMobileDevice } from '../../util/getDeviceType'
import length from '../../util/units/length'
import { UnitSystem } from '../../util/units/unitSystem'
import changeAreaMp3 from '../assets/sounds/change-area.mp3'
import withMap, { WithMap } from '../withMap'

const changeAreaSound = new Audio()
changeAreaSound.src = changeAreaMp3

interface Props {
  className?: string
}

class SpeedTracker extends React.PureComponent<
  Props & ReduxProps & AppDispatchProps & WithMap
> {
  mobile = isMobileDevice()

  handleClose = () => {
    this.props.dispatch(
      updatePreferences({ showSpeedTracker: !this.props.isEnabled })
    )
  }

  toggleSoundNotification = () => {
    this.props.dispatch(
      updatePreferences({
        isSpeedTrackerSoundEnabled: !this.props.isSoundEnabled,
      })
    )
  }

  componentDidUpdate(prevProps: this['props']) {
    const { isEnabled, prescribedSpeed, isSoundEnabled } = this.props

    if (isEnabled && isSoundEnabled) {
      if (prescribedSpeed !== prevProps.prescribedSpeed) {
        changeAreaSound.play().catch((error) => {
          // tslint:disable-next-line: no-console
          console.log('sound error')
        })
      }
    }
  }

  render() {
    const {
      isEnabled,
      preferredUnitSystem,
      prescribedSpeed,
      isSoundEnabled,
      nextFeature,
      adjustedPosition,
    } = this.props

    const {
      coords: { speed: currentSpeed },
    } = adjustedPosition || {
      coords: {
        speed: null,
      } as GeolocationCoordinates,
    }

    const { nextAreaDistance, nextSpeed } =
      nextFeature ||
      ({
        nextAreaDistance: undefined,
        nextSpeed: undefined,
      } as {
        nextAreaDistance: number | undefined
        nextSpeed: number | undefined
      })

    if (!isEnabled) {
      return null
    }

    let triangleOrientation = ''
    const noValueProvided = '--'
    const showSpeedMarker = prescribedSpeed && currentSpeed
    const className = classnames('no-print', this.props.className)
    const speedUnits =
      preferredUnitSystem === UnitSystem.Metric ? 'km/h' : 'mph'
    const distanceUnits = preferredUnitSystem === UnitSystem.Metric ? 'm' : 'yd'
    const distanceToNextArea =
      nextAreaDistance &&
      (preferredUnitSystem === UnitSystem.Metric
        ? nextAreaDistance
        : length.convert(
          nextAreaDistance,
          length.units.meter,
          length.units.yard,
          'yard'
        )?.value)

    if (
      prescribedSpeed &&
      currentSpeed &&
      currentSpeed * 3.6 > prescribedSpeed
    ) {
      triangleOrientation = 'down'
    }

    return (
      <>
        <Paper className={`speed_tracker ${className}`}>
          <div className="speed_tracker_top">
            <div className="item">
              <Button onClick={this.toggleSoundNotification}>
                <Icon>
                  {isSoundEnabled
                    ? 'notifications_active'
                    : 'notifications_off'}
                </Icon>
              </Button>
            </div>

            <div className="item center">
              <Typography variant="subtitle1">
                {i18n.t(keys.prescribedSpeed)}
              </Typography>
              {prescribedSpeed ? (
                <div className="prescribed-speed">
                  <Typography variant="h3">
                    {(preferredUnitSystem === UnitSystem.Metric
                      ? prescribedSpeed
                      : length.convert(
                        prescribedSpeed,
                        length.units.kilometer,
                        length.units.mile,
                        'mile'
                      )?.value
                    )?.toFixed(1) || '--'}
                  </Typography>{' '}
                  <Typography variant="h5">{speedUnits}</Typography>
                </div>
              ) : (
                <Typography variant="h6">{noValueProvided}</Typography>
              )}
            </div>

            <div className="item">
              <Button onClick={this.handleClose} className="btn--close">
                <Icon>close</Icon>
              </Button>
            </div>
          </div>

          <div className="speed_tracker_bottom">
            <div className="item">
              <Typography variant="caption">
                {i18n.t(keys.currentSpeed)}
              </Typography>
              <div className="line">
                {showSpeedMarker && (
                  <Icon className={`animated-triangle ${triangleOrientation}`}>
                    change_history
                  </Icon>
                )}
                <Typography variant="subtitle1">
                  {currentSpeed
                    ? `${(preferredUnitSystem === UnitSystem.Metric
                      ? // conversion to km/h
                      currentSpeed * 3.6
                      : // conversion to mph
                      currentSpeed * 2.25
                    ).toFixed(1)} ${speedUnits}`
                    : noValueProvided}
                </Typography>
              </div>
            </div>

            <div className="item">
              <div className="line">
                <Typography variant="caption" className="label">
                  {i18n.t(keys.generic.next)}:{' '}
                </Typography>
                <Typography variant="subtitle1">
                  {nextSpeed
                    ? `${(preferredUnitSystem === UnitSystem.Metric
                      ? nextSpeed
                      : length.convert(
                        nextSpeed,
                        length.units.kilometer,
                        length.units.mile,
                        'mile'
                      )?.value
                    )?.toFixed(1) || '--'
                    } ${speedUnits}`
                    : noValueProvided}
                </Typography>
              </div>
              <div className="line">
                <Typography variant="caption" className="label">
                  {i18n.t(keys.distance)}:{' '}
                </Typography>
                <Typography variant="subtitle1">
                  {distanceToNextArea === undefined ||
                    distanceToNextArea === null ||
                    Number.isNaN(distanceToNextArea)
                    ? noValueProvided
                    : `${distanceToNextArea.toFixed(0)} ${distanceUnits}`}
                </Typography>
              </div>
            </div>
          </div>
        </Paper>
      </>
    )
  }
}

const mapState = (state: RootStore, { map }: WithMap) => {
  const { selectNextSpeedFeature, selectPrescribedSpeed } =
    createSpeedFeaturesSelectors(map)

  return {
    isEnabled:
      state.preferences.showSpeedTracker &&
      selectIsSpeedTrackerAvailable(state),
    speedSourcesAndLayers: selectSpeedMapSourcesAndSourceLayers(state),
    prescribedSpeed: selectPrescribedSpeed(state),
    nextFeature: selectNextSpeedFeature(state),
    adjustedPosition: selectAdjustedUserPosition(state),
    preferredUnitSystem: state.preferences.preferredUnitSystem,
    isSoundEnabled: state.preferences.isSpeedTrackerSoundEnabled,
    gpsOffset: state.preferences.gpsOffset,
  }
}

type ReduxProps = ReturnType<typeof mapState>

const wrappedSpeedTracker = connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  SpeedTracker
)

export default withMap<{}>(wrappedSpeedTracker)
