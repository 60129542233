import * as React from 'react'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { url, UrlInfo, urlMatch, urls } from '../../appNavigation/urls'
import { connect } from '../../redux/connect'
import { selectMe } from '../../data/selectMe'
import { RootStore } from '../../redux/types'

interface NavTab {
  // TODO - translations
  // label: Translated
  label: string
  value: UrlInfo
}

const ADMIN_NAV: NavTab[] = [
  {
    label: 'Source Definitions',
    value: urls.listSourceDefs,
  },
  {
    label: 'Layer Groups',
    value: urls.listLayerDefGroups,
  },
  {
    label: 'Packages',
    value: urls.listPackages,
  }
]

const DATA_PROCESSOR_NAV: NavTab[] = [
  {
    label: 'Orders',
    value: urls.listOrders,
  },
]

class MapDataHomeNavigation extends React.PureComponent<
  RouteComponentProps & ReduxProps
> {
  render() {
    const { user } = this.props
    const { pathname } = this.props.history.location

    const NAV = user?.roles.includes('admin') ? ADMIN_NAV : DATA_PROCESSOR_NAV

    const tab = NAV.find(({ value }) => urlMatch(value, pathname))

    if (!tab) {
      throw new Error(`bad uri ${pathname}`)
    }

    return (
      <Tabs value={tab.value}>
        {NAV.map(({ label, value }) => (
          <Tab
            key={label}
            value={value}
            component={() =>
              this.renderTab({
                label,
                value,
                disabled: tab.value === value,
              })
            }
          />
        ))}
      </Tabs>
    )
  }

  /**
   * Need to render a button to get around a dark-theme issue;
   * also need to pass onClick here because the button steals the
   * click event from the Tab component
   */
  renderTab = ({
    label,
    value,
    disabled,
  }: {
    label: string
    value: UrlInfo
    disabled: boolean
  }) => {
    return (
      <Button
        disabled={disabled}
        onClick={() => this.handleTabChange(url(value))}
      >
        {label}
      </Button>
    )
  }

  handleTabChange = (value: string) => {
    this.props.history.push(value)
  }
}

const mapState = (state: RootStore) => ({
  user: selectMe(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}>(mapState)(
  withRouter(MapDataHomeNavigation)
)
