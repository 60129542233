import { get } from 'lodash'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  Container,
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { default as TablePagination } from '@mui/material/TablePagination'

import Page from '../../app/Page'
import {
  PAGE_SIZE_OPTIONS,
  selectPagingInfo,
  url,
  urls,
} from '../../appNavigation/urls'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import DataSetCard from './DataSetCard'
import {
  ListDataSetData,
  refreshListDataSets,
  selectListDataSets,
} from './selectListDataSets'
import { selectListPackages } from '../Package/selectListPackages'

class DataSetListPage extends React.Component<
  ReduxProps & AppDispatchProps & RouteComponentProps
> {
  refreshData = () => {
    refreshListDataSets()
  }

  render() {
    const dataSets = get<ListDataSetData[]>(
      this.props.dataSets as any,
      'data.data',
      []
    )

    const packages = this.props.packages.data?.data ?? []

    const pagination = this.renderPagination()

    return (
      <Page
        title="Data Sets"
        backTo={url(urls.mapView)}
        backToTitle={i18n.t(keys.map.map)}
        navChildren={
          <IconButton
            onClick={this.refreshData}
            aria-label="Refresh"
            size="large"
          >
            <Icon>refresh</Icon>
          </IconButton>
        }
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            alignItems="flex-end"
            sx={{ py: 1 }}
          >
            <Stack direction="row" spacing={1}>
              <TextField
                value={this.props.notesFilter}
                placeholder="Search by notes..."
                InputProps={{
                  startAdornment: <Icon sx={{ fontSize: 12 }}>search</Icon>,
                }}
                onChange={this.handleChangeCommentsFilter}
              />
              <Select
                value={
                  !this.props.packageFilter ? '-1' : this.props.packageFilter
                }
                onChange={this.handlePackageChange}
              >
                <MenuItem value="-1">Any Package</MenuItem>
                {packages.map((p) => (
                  <MenuItem key={p.code} value={p.code}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            {pagination}
          </Stack>
          {dataSets.map((dataSet) => (
            <DataSetCard key={dataSet.id} inList dataSet={dataSet} />
          ))}
          {pagination}
        </Container>
      </Page>
    )
  }

  handlePackageChange = (ev: SelectChangeEvent) => {
    const { pageSize } = this.props.pagingInfo
    const notesFilter = this.props.notesFilter
    if (ev.target.value === '-1') {
      this.props.history.push(
        url(urls.listDataSets, {}, { notesFilter, pageSize })
      )
    } else {
      this.props.history.push(
        url(
          urls.listDataSets,
          {},
          { notesFilter, packageFilter: ev.target.value, pageSize }
        )
      )
    }
  }

  handleChangeCommentsFilter = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { pageSize } = this.props.pagingInfo
    const packageFilter = this.props.packageFilter

    this.props.history.push(
      url(
        urls.listDataSets,
        {},
        { notesFilter: ev.currentTarget.value, packageFilter, pageSize }
      )
    )
  }

  renderPagination = () => {
    const { page, pageSize } = this.props.pagingInfo
    const count = get<number>(this.props.dataSets as any, 'data.info.count', 0)

    return (
      <TablePagination
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    )
  }

  handleChangePage = (_event: any, page: number) => {
    const { pageSize } = this.props.pagingInfo

    this.props.history.push(url(urls.listDataSets, {}, { page, pageSize }))
  }

  handleChangeRowsPerPage = (event: any) => {
    this.props.history.replace(
      url(urls.listDataSets, {}, { page: 0, pageSize: event.target.value })
    )
  }
}

const mapState = (state: RootStore) => ({
  dataSets: selectListDataSets(state),
  pagingInfo: selectPagingInfo(state),
  packages: selectListPackages(state),
  notesFilter: state.router.searchParams?.notesFilter ?? '',
  packageFilter: state.router.searchParams?.packageFilter ?? '',
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(DataSetListPage)
)
