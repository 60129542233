import { Button, Stack, Tooltip, Typography } from '@mui/material'
import { TableFormatter } from '../../UI/Table/types'
import * as React from 'react'
import { ProcessingUserStatus } from './queries'
import { formatNum } from '../../util/formatNumber'
import area from '../../util/units/area'
import { ProcessingDashboardMode } from './ProcessingDashboard'

interface Scope {
  onViewClicked?: (id: string) => void
  searchBarFilterId?: string
  mode?: ProcessingDashboardMode
}

export const tableFormatterWithScope = ({
  onViewClicked,
  searchBarFilterId,
  mode = 'acres',
}: Scope) => {
  const assigneeColumn: TableFormatter<ProcessingUserStatus> = {
    filterId: searchBarFilterId,
    header: () => (
      <Tooltip title="A user with assigned work." placement="top">
        <Typography variant="overline">Assignee</Typography>
      </Tooltip>
    ),
    data: ({ Assignee }) => `${Assignee?.firstName} ${Assignee?.lastName}`,
    filterBy: (op, value) => ({
      _or: [
        {
          Assignee: {
            firstName: { [`_${op}`]: value },
          },
        },
        {
          Assignee: {
            lastName: { [`_${op}`]: value },
          },
        },
      ],
    }),
  }

  const isPendingColumn: TableFormatter<ProcessingUserStatus> = {
    filterId: searchBarFilterId,
    header: () => (
      <Tooltip
        title="All Kato jobs that are currently in the 'queued' or 'pending' status. A processor has yet to start working on the delivery proc group."
        placement="top"
      >
        <Typography variant="overline">Pending</Typography>
      </Tooltip>
    ),
    data: ({ pending, areaPending }) => {
      return (
        <Stack direction="row" spacing={0.5}>
          {mode == 'acres' ? (
            <Typography sx={{ color: '#7CB1FF' }}>
              {`${
                formatNum(
                  area.convert(
                    areaPending,
                    area.units.squareMeter,
                    area.units.acre,
                    'acre'
                  ).value
                ) ?? '-'
              } Acres`}
            </Typography>
          ) : (
            <Typography sx={{ color: '#7CB1FF' }}>{pending}</Typography>
          )}
        </Stack>
      )
    },
    orderBy: (direction) =>
      mode === 'acres'
        ? `
        {
          areaPending: ${direction}
        }`
        : `
        {
          pending: ${direction}
        }
      `,
  }

  const inProgressColumn: TableFormatter<ProcessingUserStatus> = {
    filterId: searchBarFilterId,
    header: () => (
      <Tooltip
        title="All Kato jobs that are currently in the 'running' status. A processor has started working on the delivery proc group."
        placement="top"
      >
        <Typography variant="overline">Kato in Progress</Typography>
      </Tooltip>
    ),
    data: ({ inProgress, areaInProgress }) => {
      return (
        <Stack direction="row" spacing={0.5}>
          {mode == 'acres' ? (
            <Typography sx={{ color: '#FFC107' }}>
              {`${
                formatNum(
                  area.convert(
                    areaInProgress,
                    area.units.squareMeter,
                    area.units.acre,
                    'acre'
                  ).value
                ) ?? '-'
              } Acres`}
            </Typography>
          ) : (
            <Typography sx={{ color: '#FFC107' }}>{inProgress}</Typography>
          )}
        </Stack>
      )
    },
    orderBy: (direction) =>
      mode === 'acres'
        ? ` 
        {
          areaInProgress: ${direction}
        }`
        : `
        {
          inProgress: ${direction}
        }
      `,
  }

  const completeColumn: TableFormatter<ProcessingUserStatus> = {
    header: () => (
      <Tooltip
        title="All Kato jobs that are currently in the 'complete' status. A processor has finished working on the delivery proc group."
        placement="top"
      >
        <Typography variant="overline">Kato Complete</Typography>
      </Tooltip>
    ),
    data: ({ complete, areaComplete }) => {
      return (
        <Stack direction="row" spacing={0.5}>
          {mode == 'acres' ? (
            <Typography sx={{ color: '#9C12CC' }}>
              {`${
                formatNum(
                  area.convert(
                    areaComplete,
                    area.units.squareMeter,
                    area.units.acre,
                    'acre'
                  ).value
                ) ?? '-'
              } Acres`}
            </Typography>
          ) : (
            <Typography sx={{ color: '#9C12CC' }}>{complete}</Typography>
          )}
        </Stack>
      )
    },
    orderBy: (direction) =>
      mode === 'acres'
        ? `
        {
          areaComplete: ${direction}
        }
      `
        : ` 
        {
          complete: ${direction}
        }
      `,
  }

  const qaCompleteColumn: TableFormatter<ProcessingUserStatus> = {
    header: () => (
      <Tooltip
        title="All delivery proc groups that have been marked as reviewed. Final products have been uploaded and a reviewer has marked them as ready."
        placement="top"
      >
        <Typography variant="overline">
          {mode === 'acres' ? 'Acres' : 'Proc Groups'} QA Complete
        </Typography>
      </Tooltip>
    ),
    data: ({ qaComplete, areaQaComplete }) => {
      return (
        <Stack direction="row" spacing={0.5}>
          {mode == 'acres' ? (
            <Typography sx={{ color: '#5CC46C' }}>
              {`${
                formatNum(
                  area.convert(
                    areaQaComplete,
                    area.units.squareMeter,
                    area.units.acre,
                    'acre'
                  ).value
                ) ?? '-'
              } Acres`}
            </Typography>
          ) : (
            <Typography sx={{ color: '#5CC46C' }}>{qaComplete}</Typography>
          )}
        </Stack>
      )
    },
    orderBy: (direction) =>
      mode === 'acres'
        ? `
        {
          areaQaComplete: ${direction}
        }
      `
        : ` 
        {
          qaComplete: ${direction}
        }
      `,
  }

  const qaFailedColumn: TableFormatter<ProcessingUserStatus> = {
    header: () => (
      <Tooltip
        title="All delivery proc groups that have been marked as failed. Final products have been uploaded and a reviewer has marked them as failed."
        placement="top"
      >
        <Typography variant="overline">
          {mode === 'acres' ? 'Acres' : 'Proc Groups'} QA Failed
        </Typography>
      </Tooltip>
    ),
    data: ({ qaFailed, areaQaFailed }) => {
      return (
        <Stack direction="row" spacing={0.5}>
          {mode == 'acres' ? (
            <Typography color="error">
              {`${
                formatNum(
                  area.convert(
                    areaQaFailed,
                    area.units.squareMeter,
                    area.units.acre,
                    'acre'
                  ).value
                ) ?? '-'
              } Acres`}
            </Typography>
          ) : (
            <Typography color="error">{qaFailed}</Typography>
          )}
        </Stack>
      )
    },
    orderBy: (direction) =>
      mode === 'acres'
        ? `
        {
          areaQaFailed: ${direction}
        }
      `
        : `
        {
          qaFailed: ${direction}
        }
      `,
  }

  const openQueueColumn: TableFormatter<ProcessingUserStatus> = {
    header: () => '',
    data: ({ Assignee }) => (
      <Button
        title={'View Queue'}
        variant="contained"
        onClick={async (e) => {
          e.stopPropagation()
          onViewClicked?.(Assignee?.id ?? '')
        }}
      >
        View Queue
      </Button>
    ),
  }

  return [
    assigneeColumn,
    isPendingColumn,
    inProgressColumn,
    completeColumn,
    qaCompleteColumn,
    qaFailedColumn,
    openQueueColumn,
  ]
}
