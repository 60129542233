import { get } from 'lodash'
import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Button, Typography } from '@mui/material'

import { url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { TargetDelivery } from '../../graphql/types'
import { connect } from '../../redux/connect'
import { RootStore } from '../../redux/types'
import warnConfirm from '../warnConfirm'
import associateFlightWithDataSet from './associateFlightWithDataSet'
import DataSetDeliveryLinkDialog from './DataSetDeliveryLinkDialog'
import DataSetFlightsTable from './DataSetFlightsTable'
import { selectGetDataSet } from './selectGetDataSet'
import { refreshGetOrganizationTargetDeliveries } from './selectGetOrganizationTargetDeliveries'
import {
  refreshListDataSetFlights,
  selectListDataSetFlights,
} from './selectListDataSetFlights'
import { deletedDataSetFlight } from './deleteDataSetFlight'

interface State {
  deliveriesModalOpen: boolean
  deliveriesModifying: boolean
}

class DataSetImages extends React.Component<
  ReduxProps & RouteComponentProps,
  State
> {
  state: State = {
    deliveriesModalOpen: false,
    deliveriesModifying: false,
  }

  componentDidUpdate(prevProps: ReduxProps) {
    const {
      searchParams: { organizationId },
    } = this.props

    if (organizationId && !prevProps.searchParams.organizationId) {
      this.setState({ deliveriesModalOpen: true })
    }
  }

  render() {
    const { dataSetFlightsSelector } = this.props
    const { deliveriesModifying, deliveriesModalOpen } = this.state

    const deliveriesCount = get<number>(
      dataSetFlightsSelector as any,
      'data.count',
      0
    )

    return (
      <React.Fragment>
        <AsyncSelectorStatusOverlay
          requests={dataSetFlightsSelector}
          isLoading={deliveriesModifying}
        >
          <DataSetDeliveryLinkDialog
            open={deliveriesModalOpen}
            onOrganizationSelected={this.handleSelectOrganization}
            onTargetDeliverySelected={this.handleSelectTargetDelivery}
            onClose={this.handleFlightsModalClose}
            onLinkDelivery={this.handleChooseDelivery}
          />
          <Typography variant="h6" align="center" style={{ padding: 12 }}>
            {deliveriesCount} Connected
            {deliveriesCount === 1 ? ' Delivery' : ' Deliveries'}
          </Typography>

          <Button variant="outlined" onClick={this.handleAddFlight}>
            Add Delivery
          </Button>

          <DataSetFlightsTable deleteFlights={this.handleDeleteFlights} />
        </AsyncSelectorStatusOverlay>
      </React.Fragment>
    )
  }

  withLoading = (action: () => Promise<any>) => {
    this.setState(
      { deliveriesModalOpen: false, deliveriesModifying: true },
      async () => {
        await action()
        this.setState({ deliveriesModifying: false })
        this.refresh()
      }
    )
  }

  handleAddFlight = () => {
    this.setState({ deliveriesModalOpen: true })
  }

  handleSelectTargetDelivery = (targetDeliveryPK: string) => {
    const {
      dataSetId,
      searchParams: { organizationId },
    } = this.props
    const [orderId, targetDeliveryDate] = targetDeliveryPK.split('/')
    this.props.history.replace(
      url(
        urls.editDataSet,
        { dataSetId },
        { organizationId, orderId, targetDeliveryDate }
      )
    )
  }

  handleSelectOrganization = async (organizationId: string) => {
    const { dataSetId } = this.props
    this.props.history.replace(
      url(urls.editDataSet, { dataSetId }, { organizationId })
    )
  }

  handleChooseDelivery = async ({
    date: targetDeliveryDate,
    Order,
    Delivery,
  }: TargetDelivery) => {
    const { dataSetSelector } = this.props
    if (dataSetSelector.data) {
      const { id: dataSetId, acquisitionDate } = dataSetSelector.data

      if (Delivery) {
        if (
          !(await warnConfirm({
            title: 'Confirm DataSet-Delivery Association',
            message: `Are you sure you would like to associate the selected Delivery?`,
            action: 'Associate',
          }))
        ) {
          return
        }
      } else {
        if (
          !(await warnConfirm({
            title: 'Confirm DataSet-Delivery Association',
            message: `Are you sure you would like to create a new Delivery/Flight and associate them with this Data Set?`,
            action: 'Associate',
          }))
        ) {
          return
        }
      }
      refreshGetOrganizationTargetDeliveries()

      this.withLoading(() =>
        associateFlightWithDataSet(
          dataSetId,
          targetDeliveryDate,
          Order!,
          acquisitionDate!,
          Delivery
        )
      )
    }
  }

  handleDeleteFlights = async (flightPKs: string[]) => {
    if (!this.props.dataSetId) {
      return
    }

    if (
      await warnConfirm({
        title: 'Confirm DataSet-Delivery Dissociation',
        message: `Are you sure you would like to disassociate the selected (${flightPKs.length}) Deliveries? (no undo)`,
        action: 'Disassociate',
      })
    ) {
      this.withLoading(async () => {
        for (const flightPK of flightPKs) {
          const [deliveryId, flightDate] = flightPK.split('/')
          await deletedDataSetFlight({ deliveryId, flightDate, dataSetId: this.props.dataSetId! })
        }
      })
    }
  }

  handleFlightsModalClose = () => {
    this.setState({
      deliveriesModalOpen: false,
    })
    const { dataSetId } = this.props
    this.props.history.replace(url(urls.editDataSet, { dataSetId }, {}))
  }

  refresh = () => {
    refreshListDataSetFlights()
  }
}

const mapState = (state: RootStore) => ({
  dataSetSelector: selectGetDataSet(state),
  dataSetId: state.router.params.dataSetId,
  searchParams: state.router.searchParams,
  dataSetFlightsSelector: selectListDataSetFlights(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(withRouter(DataSetImages))
