import mapEditorReducerFactory from '../../../map-editor/redux/mapEditorReducerFactory'
import {
  RateMapMapConfiguration,
  RateMapHeaderConfiguration,
  RateMapDrawerConfiguration,
} from '../types'

const pageType = 'rateMap'

const reducer = mapEditorReducerFactory.createMapEditorReducer<
  RateMapHeaderConfiguration,
  RateMapMapConfiguration,
  RateMapDrawerConfiguration
>(pageType, undefined, { rightDrawerWidth: 400 })

type RateMapState = ReturnType<typeof reducer>

export { reducer, RateMapState, pageType }
