import * as React from 'react'
import { default as Icon } from '@mui/material/Icon'
import { default as Typography } from '@mui/material/Typography'

import TooltipIconButton from '../../UI/TooltipIconButton'
import { GetMapSourceDefData } from '../MapSourceDef/selectGetMapSourceDef'
import * as api from '../../graphql/api'
import { AddMapLayerDefToMapLayerDefGroupDialog } from './AddMapLayerDefToMapLayerDefGroupDialog'

interface Props {
  mapLayerDefGroupId: string
  layerDefs: GetMapSourceDefData['MapLayerDefs']
  onChange(): void
}

export const MapLayerDefGroupMapLayerDefList = ({ mapLayerDefGroupId, layerDefs, onChange }: Props) => {

  const [addDialogOpen, setAddDialogOpen] = React.useState(false)

  const handleDelete = async (
    layerDef: UnwrapArray<GetMapSourceDefData['MapLayerDefs']>
  ) => {
    await api.mapLayerDef.update({ pk: { id: layerDef.id! }, input: { mapLayerDefGroupId: null }, returning: '{ id }' })
    onChange()
  }

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true)
  }

  const handleAddDialogClose = () => {
    setAddDialogOpen(false)
    onChange()
  }

  return (
    <>
      <AddMapLayerDefToMapLayerDefGroupDialog open={addDialogOpen}
        mapLayerDefGroupId={mapLayerDefGroupId!}
        onClose={handleAddDialogClose} />
      <div className="grid-xs-12">
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1">Layers Definitions in Group</Typography>
          <TooltipIconButton
            tooltipProps={{
              placement: 'right',
            }}
            title={`Add a layer to group`}
            onClick={handleAddDialogOpen}
          >
            <Icon fontSize="small">add_circle_outline</Icon>
          </TooltipIconButton>
        </div>
        <div>
          {(layerDefs ?? []).map((layerDef) => (
            <div key={layerDef.id}>
              <Typography component="span">
                {layerDef.name}
                <TooltipIconButton
                  title={`Remove layer from group`}
                  onClick={() => handleDelete(layerDef)}
                >
                  <Icon fontSize="small">delete</Icon>
                </TooltipIconButton>
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}