import { createSelector } from 'reselect'
import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { RootStore } from '../redux/types'
import { selectOrganization } from './selectOrganization'
import { OrganizationData } from './selectOrganizationList'

export interface GetMeData {
  id: string

  firstName: string
  lastName: string
  email: string
  roles: string[]
  permissions: string[]

  orgRoles?: string[]
  orgPermissions?: string[]

  createdAt: string
  updatedAt: string
}
const getMe = async ({
  isLoggedIn,
  isLoginInProgress,
  organization,
}: {
  isLoggedIn: boolean
  isLoginInProgress: boolean
  organization?: OrganizationData
}) => {
  if (!isLoggedIn || isLoginInProgress) {
    return null
  }

  if (!organization) {
    const data = await query<{ me: GetMeData }>({
      query: gql`
        query ME {
          me {
            id
            firstName
            lastName
            email
            roles
            permissions

            createdAt
            updatedAt
          }
        }
      `,
    })

    if (!data) {
      throw new Error('No Data')
    }

    // data.me.orgRoles = []
    // data.me.orgPermissions = []

    return data.me
  } else {
    const data = await query<{ me: GetMeData }>({
      query: gql`
        query ME($organizationId: ID) {
          me {
            id
            firstName
            lastName
            email
            roles
            permissions

            orgRoles(orgId: $organizationId)
            orgPermissions(orgId: $organizationId)

            createdAt
            updatedAt
          }
        }
      `,
      variables: { organizationId: `${organization.id}` },
    })

    if (!data) {
      throw new Error('No Data')
    }

    return data.me
  }
}

const { selector: selectGetMe, refresh: refreshGetMe } = createAsyncSelector({
  resource: 'me',
  inputs: {
    isLoggedIn: (state: RootStore) => state.login.isLoggedIn,
    isLoginInProgress: (state: RootStore) => state.login.isInProgress,
    organization: selectOrganization,
  },
  fetcher: getMe,
})

export { selectGetMe, refreshGetMe }

export const selectMe = createSelector([selectGetMe], ({ data }) => data)

export const selectIsAdmin = createSelector(
  selectMe,
  (me) => (me?.roles.length ?? 0) > 0
)
