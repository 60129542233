import { client, gql } from '../graphql/client'
import { Languages } from '../graphql/types'

export interface FilenameWithDescription {
  filename: string
  description: string
}

export const fetchFilenamesWithDescriptions = async (filenames: string[], lang: Languages) => {
  const { filenamesWithDescriptions } = await client.request<{ filenamesWithDescriptions: FilenameWithDescription[] }>({
    query: gql`
      query GET_FILENAMES_WITH_DESCRIPTIONS($filenames:[citext!]!, $lang:String!){
        filenamesWithDescriptions: MapSourceDefFilename(where:{filename:{_in:$filenames}}) {
          filename
          description(path:$lang)
        }
      }`,
    variables: { filenames, lang }
  })

  return filenamesWithDescriptions
}