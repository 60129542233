import * as React from 'react'
import { PureComponent } from 'react'

import * as Sentry from '@sentry/browser'
import { Button, Paper, Typography } from '@mui/material'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { GQLAuthError } from '../graphql/GQLAuthError'
import i18n, { keys } from '../i18n'
import handleError from '../util/handleError'
import { InvalidAuthError } from '../util/request'
import { sendToLogin } from '../util/sendToLogin'
import Page from './Page'

interface State {
  hasError: boolean
}

class ErrorBoundary extends PureComponent<WithStyles<typeof styles>, State> {
  state: State = {
    hasError: false,
  }

  componentDidCatch(err: Error, info: React.ErrorInfo) {
    if (err instanceof InvalidAuthError || err instanceof GQLAuthError) {
      sendToLogin()
    } else {
      this.setState({ hasError: true })
      Sentry.withScope((scope) => {
        scope.setExtras({ stack: info.componentStack })
        handleError(err)
      })
      // tslint:disable-next-line:no-console
      console.error(info.componentStack)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Page>
          <Paper className={this.props.classes.root}>
            <Typography paragraph variant="h6" color="error">
              {i18n.t(keys.errors.somethingWentWrong)}
            </Typography>

            <Button
              onClick={this.handleReloadClick}
              color="primary"
              variant="contained"
              style={{ marginTop: '1em' }}
            >
              {i18n.t(keys.errors.tapToReload)}
            </Button>

            {process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'production' && (
              <Button
                onClick={() => {
                  Sentry.showReportDialog()
                }}
                style={{ marginTop: '1.6em' }}
              >
                {i18n.t(keys.errors.reportAnIssue)}
              </Button>
            )}
          </Paper>
        </Page>
      )
    }

    return this.props.children
  }

  handleReloadClick = async () => {
    window.location.reload()
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      maxWidth: 300,
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })

export default withStyles(styles)(ErrorBoundary)
