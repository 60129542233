import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore as _createStore,
  DeepPartial,
  Store,
} from 'redux'
import reduxAsyncPayload from 'redux-async-payload'

import * as Sentry from '@sentry/browser'

import handleError from '../util/handleError'
import persistenceMiddleware from './persistenceMiddleware'
import { setStore } from './store'
import { RootStore } from './types'
import { createListenerMiddleware } from '@reduxjs/toolkit'
import { constants } from './notifications/redux'
import { postJson } from '../vvapi/apiResource/createApiResource'

const logger: any = (_store: any) => (next: any) => (action: any) => {
  if (process.env.NODE_ENV === 'development') {
    // tslint:disable-next-line:no-console
    console.log(action.type, action.payload)
  }

  try {
    Sentry.addBreadcrumb({
      category: 'redux',
      data: action.type.startsWith('AsyncSelector/')
        ? { type: action.type }
        : { ...action },
    })

    return next(action)
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setExtra('state', store.getState())
      handleError(e)
    })
  }
}

const listenerMiddleware = createListenerMiddleware()

// Dismiss notification side effect.
listenerMiddleware.startListening({
  predicate: (action) => action.type === constants.dismissNotification,
  effect: async (action) => {
    if (!action?.meta?.appNotificationId) {
      return
    }
    await postJson(
      `/api/v3/notifications/${action.meta.appNotificationId}/viewed-notification`
    )
  },
})

const middleware = [
  reduxAsyncPayload(),
  logger,
  persistenceMiddleware(),
  listenerMiddleware.middleware,
]

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function getRootReducer() {
  // Importing this strange way is needed for hot loading.
  return require('./rootReducer').default
}

export default function createStore(initialState?: DeepPartial<RootStore>) {
  const _store = _createStore(
    getRootReducer(),
    initialState ?? {},
    composeEnhancers(applyMiddleware(...middleware))
  )

  if (module.hot) {
    module.hot.accept('./types', () => {
      _store.replaceReducer(getRootReducer())
    })
  }

  if (typeof window !== 'undefined') {
    // tslint:disable-next-line: semicolon
    ;(window as any).vvstore = _store
  }

  return _store as Store<RootStore, AnyAction>
}

export const store = createStore()

setStore(store)
