import * as React from 'react'
import { AdminPage } from '../AdminPage'
import {
  commitFeatureFlag as commit,
} from './queries'
import { FeatureFlagForm } from './FeatureFlagForm'
import { FeatureFlag } from '../../../graphql/types'
import { useHistory } from 'react-router-dom'
import { url, urls } from '../../../appNavigation/urls'

export const FeatureFlagCreate = () => {
    const history = useHistory()
  const commitFeatureFlag = async (ff: FeatureFlag) => {
    if (!ff) {
      return
    }
    const choice = await sweetAlert(
      `Are you sure you want to create this feature flag?`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: 'Create',
          },
        },
        dangerMode: true,
      }
    )

    // sweetalert returns null for "cancel"
    if (!choice) {
      return
    }
    await commit(ff)
    history.push(url(urls.admin_feature_flags))
  }

  const cancel = () => history.push(url(urls.admin_feature_flags))

  return (
    <AdminPage title="Create Feature Flag">
      <FeatureFlagForm commitFeatureFlag={commitFeatureFlag} cancel={cancel}/>
    </AdminPage>
  )
}
