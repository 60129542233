import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import * as api from '../../graphql/api'
import { Languages, Translated } from '../../graphql/types'
import { RootStore } from '../../redux/types'

export interface GetMapLayerDefGroupData {
  id: string
  name: Translated
  createdAt: string
  updatedAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }

  MapLayerDefs: {
    id: string
    name: string
  }[]
}

const get = ({ id, lang }: { id: string; lang: Languages }) =>
  api.mapLayerDefGroup.get<GetMapLayerDefGroupData>({
    pk: { id },
    returning: `{
      id
      name
      order

      createdAt
      updatedAt

      CreatedBy {
        firstName
      }
      UpdatedBy {
        firstName
      }
     
      MapLayerDefs {
        id
        name(path: "${lang}")
      }
    }`,
  })

const { selector: selectGetMapLayerDefGroup, refresh: refreshGetMapLayerDefGroup } =
  createAsyncSelector({
    resource: 'GetMapLayerDefGroupData',
    inputs: {
      lang: selectPreferredLanguage,
      mapLayerDefGroupId: (state: RootStore) => state.router.params.mapLayerDefGroupId,
    },
    fetcher: async ({ lang, mapLayerDefGroupId }) => {
      if (!mapLayerDefGroupId) {
        return
      }

      const { data } = await get({ lang, id: mapLayerDefGroupId })

      if (!data) {
        throw new Error('No Data')
      }

      return data
    },
  })

export { selectGetMapLayerDefGroup, refreshGetMapLayerDefGroup }
