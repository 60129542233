import { Reducer } from 'redux'
import { applyNamespace, createAction } from 'redux-ts-helpers'
import { buildInitialState } from '../vvapi/apiResource/createReduxResource'
import { NoteForm } from '../vvapi/models'
import { NoteFormsState, NoteTemplateSort } from './types'

export const initialState: NoteFormsState = {
  ...buildInitialState<NoteForm>(),
  isManageFormsVisible: false,
}

const constants = {
  ...applyNamespace('noteForms', {
    toggleIsManageFormsVisible: 0,
    setSortColumn: 0,
    reset: 0,
  }),
}

export const actions = {
  toggleIsManageFormsVisible: createAction(
    constants.toggleIsManageFormsVisible
  ),
  setSortColumn: createAction<NoteTemplateSort['column']>(
    constants.setSortColumn
  ),
  reset: createAction(constants.reset),
}

export const reducer: Reducer<NoteFormsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case constants.toggleIsManageFormsVisible:
      return {
        ...state,
        isManageFormsVisible: !state.isManageFormsVisible,
      }
    case `${constants.setSortColumn}`: {
      if (state.sort) {
        if (state.sort.column === action.payload) {
          if (state.sort.direction === 'asc') {
            return {
              ...state,
              sort: { column: action.payload, direction: 'desc' },
            }
          }
          if (state.sort.direction === 'desc') {
            return { ...state, sort: undefined }
          }
        }
      }
      return { ...state, sort: { column: action.payload, direction: 'asc' } }
    }
    case `${constants.reset}`: {
      return initialState
    }
  }

  return state
}
