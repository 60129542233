import './ReportSummary.scss'
import * as React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slide,
  Stack,
  LinearProgress,
} from '@mui/material'
import i18n, { keys } from '../../../i18n'
import {
  Parcel,
  Sample,
  SamplePlan,
  SamplePlanBlock,
} from '../../../graphql/types'
import ReportSection from '../../../reports/page/ReportSection'
import { TransitionProps } from '@mui/material/transitions'
import { useReactToPrint } from 'react-to-print'
import useAsync from '../../../hooks/useAsync'
import { fetchSamplePlanBlocks } from '../queries'
import SamplePlanCheckboxSVG from '../assets/sample_plan_checkbox.svg'
import SamplePlanCompleteSVG from '../assets/sample_plan_complete.svg'
import SamplePlanIncompleteSVG from '../assets/sample_plan_incomplete.svg'
import { SummaryPlanBlockReport } from './SamplePlanBlockReport'
import getMapImage from '../../../reports/page/map/getMapImage'
import { useInterval } from '../../../hooks/useInterval'
import { withStyles } from '@mui/styles'
import { SamplePlanMapImage } from './SamplePlanMapImage'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { useRedux } from '../../../hooks/useRedux'
import {
  buildParcelRasterFillSourcesAndLayers,
  buildParcelFillSourcesAndLayers,
  buildParcelLabelsSourcesAndLayers,
  buildParcelSourcesAndLayers,
} from '../hooks/util/parcelLayerHelper'
import {
  SAMPLE_PLAN_CHECKBOX_ICON,
  SAMPLE_PLAN_COMPLETE_ICON,
  SAMPLE_PLAN_INCOMPLETE_ICON,
  buildSampleLabelSourcesAndLayers,
  buildSampleSourcesAndLayers,
} from '../hooks/util/sampleLayerHelper'
import calculateParcelBounds from '../hooks/util/calculateParcelBounds'

const SAMPLE_PLAN_SUMMARY_MAP = 'SAMPLE-PLAN-SUMMARY-MAP'
const SAMPLES_PER_PAGE = 15

interface SamplePlanPDFProps {
  open: boolean
  orgName?: string
  samplePlan?: SamplePlan | null
  selectedParcels?: Parcel[]
  onClose: () => void
}

interface Page {
  samplePlanBlock: SamplePlanBlock
  samples: Sample[]
  currentPage: number
  totalPages: number
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ColorLinearProgress = withStyles({
  root: {
    height: '0.5em',
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
  barColorPrimary: {
    backgroundColor: 'rgba(255,255,255,0.6)',
  },
})(LinearProgress)

const SamplePlanReport = ({
  open,
  orgName,
  samplePlan,
  selectedParcels,
  onClose,
}: SamplePlanPDFProps) => {
  const [state] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)
  const printRef = React.useRef<HTMLDivElement>(null)
  const [loadingProgress, setLoadingProgress] = React.useState<number>(0)

  const selectedSamplePlanBlocksIds = React.useMemo(() => {
    return samplePlan?.SamplePlanBlocks?.filter((spb) =>
      selectedParcels?.map((sp) => sp.id).includes(spb.Parcel.id)
    ).map((spb) => spb.id)
  }, [selectedParcels, samplePlan])

  const [fetchedSelectedSamplePlanBlocks] = useAsync(fetchSamplePlanBlocks, [
    selectedSamplePlanBlocksIds,
  ])

  const selectedSampleBlocks = React.useMemo(() => {
    return fetchedSelectedSamplePlanBlocks.result?.data
  }, [fetchedSelectedSamplePlanBlocks?.result?.data])

  const pages = React.useMemo(() => {
    const blockPages = selectedSampleBlocks?.reduce((acc, block) => {
      const sortedSamples = block.Samples.sort((a, b) => {
        if (a.rowID < b.rowID) {
          return -1
        }
        if (a.rowID > b.rowID) {
          return 1
        }
        if (a.plantID < b.plantID) {
          return -1
        }
        if (a.plantID > b.plantID) {
          return 1
        }
        return 0
      })
      const numberOfPages = Math.ceil(sortedSamples.length / SAMPLES_PER_PAGE)
      for (let j = 0; j < numberOfPages; j++) {
        const start = j * SAMPLES_PER_PAGE
        const end = start + SAMPLES_PER_PAGE
        acc.push({
          samplePlanBlock: block,
          samples: sortedSamples.slice(start, end),
          currentPage: j,
          totalPages: numberOfPages,
        } as Page)
      }
      return acc
    }, [] as Page[])

    return blockPages
  }, [selectedSampleBlocks])

  const selectedSamples = React.useMemo(() => {
    return fetchedSelectedSamplePlanBlocks.result?.data.reduce((acc, spb) => {
      if (!spb) {
        return acc
      }
      return [...acc, ...spb.Samples]
    }, [] as Sample[])
  }, [fetchedSelectedSamplePlanBlocks?.result?.data])

  const samplePlanSummaryLayersAndSources = React.useMemo(() => {
    const layersAndSources: {
      sources: Record<string, any>
      layers: mapboxgl.Layer[]
    } = { sources: {}, layers: [] }

    if (!selectedParcels || !selectedSamples) {
      return layersAndSources
    }

    buildParcelRasterFillSourcesAndLayers(selectedParcels, layersAndSources)
    buildParcelSourcesAndLayers(selectedParcels, layersAndSources)
    buildParcelFillSourcesAndLayers(selectedParcels, layersAndSources)
    buildSampleSourcesAndLayers(selectedSamples, layersAndSources)
    buildParcelLabelsSourcesAndLayers(selectedParcels, layersAndSources)

    return layersAndSources
  }, [selectedParcels, selectedSamples])

  const updateLoadingProgress = () => {
    if (fetchedSelectedSamplePlanBlocks.status !== 'resolved') {
      setLoadingProgress(0)
      return
    }

    const mapsToLoad = selectedParcels?.length ?? 0

    const mapProgressPercent =
      mapsToLoad <= 0
        ? 100
        : ((mapsToLoad - getMapImage.getLength()) / mapsToLoad) * 100 || 0

    setLoadingProgress(mapProgressPercent)
  }

  useInterval(updateLoadingProgress, 700)

  const getBlockSummaryLayersAndSources = (
    samplePlanBlock: SamplePlanBlock
  ) => {
    const layersAndSources: {
      sources: Record<string, any>
      layers: mapboxgl.Layer[]
    } = { sources: {}, layers: [] }

    if (!selectedParcels || !samplePlanBlock) {
      return layersAndSources
    }

    buildParcelRasterFillSourcesAndLayers(
      [samplePlanBlock.Parcel],
      layersAndSources
    )
    buildParcelSourcesAndLayers(selectedParcels, layersAndSources)
    buildParcelFillSourcesAndLayers([samplePlanBlock.Parcel], layersAndSources)
    buildParcelLabelsSourcesAndLayers(
      [samplePlanBlock.Parcel],
      layersAndSources
    )
    buildSampleSourcesAndLayers(samplePlanBlock.Samples, layersAndSources, true)
    buildSampleLabelSourcesAndLayers(samplePlanBlock.Samples, layersAndSources)

    return layersAndSources
  }

  const calcBounds = (parcels: Parcel[]) => {
    const bounds = calculateParcelBounds(parcels)
    return [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ]
  }

  const handleLoadSampleIcons = (map: mapboxgl.Map) => {
    const samplePlanCompleteIcon = new Image(24, 24)
    const samplePlanIncompleteIcon = new Image(24, 24)
    const samplePlanCheckboxIcon = new Image(24, 24)

    const loading: Promise<void>[] = []

    loading.push(
      new Promise<void>((resolve) => {
        samplePlanCompleteIcon.onload = () => {
          if (!map.hasImage(SAMPLE_PLAN_COMPLETE_ICON)) {
            map.addImage(SAMPLE_PLAN_COMPLETE_ICON, samplePlanCompleteIcon)
          }
          resolve()
        }
        samplePlanCompleteIcon.src = SamplePlanCompleteSVG
      })
    )

    loading.push(
      new Promise<void>((resolve) => {
        samplePlanIncompleteIcon.onload = () => {
          if (!map.hasImage(SAMPLE_PLAN_INCOMPLETE_ICON)) {
            map.addImage(SAMPLE_PLAN_INCOMPLETE_ICON, samplePlanIncompleteIcon)
          }
          resolve()
        }
        samplePlanIncompleteIcon.src = SamplePlanIncompleteSVG
      })
    )

    loading.push(
      new Promise<void>((resolve) => {
        samplePlanCheckboxIcon.onload = () => {
          if (!map.hasImage(SAMPLE_PLAN_CHECKBOX_ICON)) {
            map.addImage(SAMPLE_PLAN_CHECKBOX_ICON, samplePlanCheckboxIcon)
          }
          resolve()
        }
        samplePlanCheckboxIcon.src = SamplePlanCheckboxSVG
      })
    )

    return Promise.all(loading)
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  return (
    <Dialog
      className="print"
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div id="ReportHome" className="light" ref={printRef}>
          <ReportSection>
            <section id="ReportCover">
              <div className="ReportHeader" />
              <div className="report_container">
                <Typography className="ReportCover__headline">
                  {orgName}
                </Typography>
                <Typography className="ReportCover__headline">
                  {samplePlan?.name}
                </Typography>
                <Typography className="ReportCover__subheader">
                  {selectedParcels?.[0]?.OrganizationGroup?.name}
                </Typography>
                <div className="ReportCover__prepared">
                  <Typography paragraph>
                    {`${i18n.t(keys.reports.preparedByOn)} ${i18n.toDateShort(
                      new Date(Date.now())
                    )}`}
                  </Typography>
                </div>
              </div>
            </section>
          </ReportSection>
          <ReportSection>
            <section id="ReportSummary">
              <Stack className="summary-container" direction="row">
                <Stack direction="column" spacing={2}>
                  <Typography className="title">
                    {i18n.t(keys.samplePlanReportTitle)}
                  </Typography>
                  <Stack direction="column" spacing={2}>
                    <Stack direction="column">
                      <Typography className="info-title">
                        {i18n.t(keys.user.organization)}
                      </Typography>
                      <Typography className="info">{orgName}</Typography>
                    </Stack>
                    <Stack direction="column">
                      <Typography className="info-title">
                        {i18n.t(keys.samplePlanBlockLocationColumnLabel)}
                      </Typography>
                      <Typography className="info">
                        {selectedParcels?.[0]?.OrganizationGroup?.name}
                      </Typography>
                    </Stack>
                    <Stack direction="column">
                      <Typography className="info-title">
                        {i18n.t(keys.samplePlanDateColumnLabel)}
                      </Typography>
                      <Typography className="info">
                        {i18n.toDateShort(samplePlan?.createdAt ?? '')}
                      </Typography>
                    </Stack>
                    <Stack direction="column">
                      <Typography className="info-title">
                        {i18n.t(keys.samplePlanNewStatisticalMethodLabel)}
                      </Typography>
                      <Typography className="info">
                        {
                          samplePlan?.SamplePlanCreateDataSet
                            ?.SamplingStatisticalMethod?.name[preferredLanguage]
                        }
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <SamplePlanMapImage
                  key={SAMPLE_PLAN_SUMMARY_MAP}
                  style="mapbox://styles/vineview/cjwhzylct0lhe1dqxys0x3i2v" // eslint-disable-line react/style-prop-object
                  bounds={calcBounds(selectedParcels ?? [])}
                  layers={samplePlanSummaryLayersAndSources}
                  onStyleLoaded={handleLoadSampleIcons}
                />
              </Stack>
            </section>
          </ReportSection>
          {pages
            ?.sort((a, b) =>
              a?.samplePlanBlock?.Parcel?.name?.localeCompare(
                b?.samplePlanBlock.Parcel?.name
              )
            )
            ?.map((b) => (
              <SummaryPlanBlockReport
                key={b.samplePlanBlock.id}
                orgName={orgName}
                samplePlan={samplePlan}
                samplePlanBlock={b.samplePlanBlock}
                bounds={calcBounds([b.samplePlanBlock.Parcel])}
                layersAndSources={getBlockSummaryLayersAndSources(
                  b.samplePlanBlock
                )}
                onStyleLoaded={handleLoadSampleIcons}
                pageSamples={b.samples}
                blockPageNumber={b.currentPage}
                blockPageTotal={b.totalPages}
                samplesPerPage={SAMPLES_PER_PAGE}
              />
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{i18n.t(keys.generic.close)}</Button>
        <Button disabled={loadingProgress !== 100} onClick={handlePrint}>
          {i18n.t(keys.reports.print)}
        </Button>
        {loadingProgress !== 100 && (
          <div
            className="Report__loadingProgress"
            style={{ paddingLeft: '1em' }}
          >
            <Typography variant="subtitle1">
              {i18n.t(keys.generic.loading)}
            </Typography>
            <div className="Report__loadingProgress" style={{ width: 100 }}>
              <ColorLinearProgress
                variant="determinate"
                value={loadingProgress}
              />
            </div>
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default SamplePlanReport
