import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

class ScrollToTop extends React.Component<RouteComponentProps> {
  componentDidUpdate(prevProps: this['props']) {
    const { location, history } = this.props

    if (location.pathname === prevProps.location.pathname) {
      return
    }

    // new route
    if (history.action === 'PUSH' || history.action === 'REPLACE') {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return null
  }
}

export default withRouter(ScrollToTop)
