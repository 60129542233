import { createSelector } from 'reselect'
import { selectPagingInfo } from '../../../appNavigation/urls'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { client, gql } from '../../../graphql/client'
import { RootStore } from '../../../redux/types'
import { ORDER } from '../../../UI/Table/orderRows'
import { TableOrder } from '../../../UI/Table/types'

export interface ListUserAndInvitesData {
  id: number
  userId?: number
  organizationId: string
  email: string
  firstName: string
  lastName: string
  status: string
  roles: string[]
  groupIds: string[]
}

const orderMap = ['firstName', 'lastName', 'email', 'roles']

const listOrganizationUsersAndInvites = async ({
  organizationId,
  limit,
  offset,
  order,
}: {
  organizationId: string
  limit?: number
  offset?: number
  order: TableOrder
}) => {
  const orderBy = order.reverse().map((orderClause) => ({
    [orderMap[orderClause.index]]: orderClause.direction,
  }))

  const {
    usersAndInvites,
    info: {
      aggregate: { count },
    },
  } = await client.request<{
    usersAndInvites: ListUserAndInvitesData[]
    info: { aggregate: { count: number } }
  }>({
    query: gql`
      query LIST_ORGANIZATION_USER_AND_INVITES(
        $organizationId: Int!
        $limit: Int
        $offset: Int
        $orderBy: [View_UsersAndInvites_order_by!]
      ) {
        usersAndInvites: View_UsersAndInvites(
          where: { organizationId: { _eq: $organizationId } }
          limit: $limit
          offset: $offset
          order_by: $orderBy
        ) {
          id
          email
          organizationId
          firstName
          lastName
          status
          roles
          groupIds
          userId
        }
        info: View_UsersAndInvites_aggregate(
          where: { organizationId: { _eq: $organizationId } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: {
      organizationId,
      limit,
      offset,
      orderBy,
    },
  })

  return { usersAndInvites, count, order }
}

const {
  selector: selectListOrganizationUsersAndInvites,
  refresh: refreshListOrganizationUsersAndInvites,
} = createAsyncSelector({
  resource: 'ListOrganizationUsersAndInvites',
  inputs: {
    pagingInfo: selectPagingInfo,
    organizationId: (state: RootStore) => state.router.params.organizationId,
    searchParams: (state: RootStore) => state.router.searchParams,
  },
  fetcher: async ({
    organizationId,
    searchParams,
    pagingInfo: { page, pageSize },
  }) => {
    if (!organizationId) {
      return
    }

    const order = ORDER.deserialize(searchParams.orderBy ?? '')

    return await listOrganizationUsersAndInvites({
      organizationId,
      limit: pageSize,
      offset: pageSize * page,
      order,
    })
  },
})

export {
  selectListOrganizationUsersAndInvites,
  refreshListOrganizationUsersAndInvites,
}

const EMPTY_USERS: ListUserAndInvitesData[] = []

export const selectOrganizationUsersAndInvites = createSelector(
  selectListOrganizationUsersAndInvites,
  (listUsersAndInvites) =>
    listUsersAndInvites.data?.usersAndInvites ?? EMPTY_USERS
)

export const selectOrganizationUsersAndInvitesCount = createSelector(
  selectListOrganizationUsersAndInvites,
  (listUsersAndInvites) => listUsersAndInvites.data?.count ?? 0
)

export const selectOrganizationUsersAndInvitesOrder = createSelector(
  selectListOrganizationUsersAndInvites,
  (listUsersAndInvites) => listUsersAndInvites.data?.order
)
