import * as React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

import Page from '../app/Page'
import { RouteParams, url, urls } from '../appNavigation/urls'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { AppDispatchProps } from '../redux/types'
import LinkChild from '../UI/LinkChild'
import { RFTextField } from '../UI/reduxForm/muiComponents'
import { reduxFormSubmissionFactory } from '../UI/reduxForm/reduxFormSubmissionFactory'
import { composeValidators } from '../UI/reduxForm/utils'
import { login } from '../vvapi/login'
import { passwordAdornment } from './adornments'
import { passwordLength, passwordsMatch, required } from './validators'

interface State {
  showPassword: boolean
}

const password = 'password'
const confirmPassword = 'confirmPassword'

const fieldLabelMap = {
  [password]: i18n.t(keys.user.password),
  [confirmPassword]: i18n.t(keys.user.confirmPassword),
}

const anyPropType: any = () => null
anyPropType.isRequired = () => null

class ResetPassword extends React.Component<
  AppDispatchProps & RouteComponentProps<RouteParams>,
  State
> {
  state: State = {
    showPassword: false,
  }

  render() {
    const title = i18n.t(keys.forms.resetPassword.title)

    return (
      <Page title={title}>
        <Dialog open fullWidth hideBackdrop>
          <Form
            initialValues={{
              token: this.props.match.params.token,
            }}
            onSubmit={reduxFormSubmissionFactory(
              login.actions.resetPassword,
              this.props.dispatch
            )}
          >
            {({ handleSubmit, error, submitting, invalid }) => (
              <form>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                  {error && <Typography color="error">{error}</Typography>}
                  <RFTextField name="token" type="hidden" />
                  <RFTextField
                    name="password"
                    label={fieldLabelMap.password}
                    type={this.state.showPassword ? 'text' : 'password'}
                    validate={composeValidators(required, passwordLength)}
                    fullWidth
                    margin="dense"
                    InputProps={{
                      endAdornment: passwordAdornment(
                        this.state.showPassword,
                        this.handleClickShowPassword
                      ),
                    }}
                  />
                  <RFTextField
                    type={this.state.showPassword ? 'text' : 'password'}
                    name={confirmPassword}
                    label={fieldLabelMap.confirmPassword}
                    validate={composeValidators(required, passwordsMatch)}
                    fullWidth
                    margin="dense"
                    InputProps={{
                      endAdornment: passwordAdornment(
                        this.state.showPassword,
                        this.handleClickShowPassword
                      ),
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    className="ChangePassword__submit"
                    type="submit"
                    disabled={submitting || (invalid && !error)}
                    onClick={(ev) => this.handleSubmit(ev, handleSubmit)}
                  >
                    {i18n.t(keys.forms.resetPassword.submit)}
                  </Button>
                  <LinkChild to={url(urls.mapView)}>
                    <Button size="small">{i18n.t(keys.generic.cancel)}</Button>
                  </LinkChild>
                </DialogActions>
              </form>
            )}
          </Form>
        </Dialog>
      </Page>
    )
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleSubmit = async (
    event: React.SyntheticEvent<any>,
    handleSubmit: FormRenderProps['handleSubmit']
  ) => {
    event.preventDefault()
    // reduxFormSubmissionFactory conditionally throws errors
    const err = await handleSubmit(undefined as any)

    if (!err) {
      // send user to home page
      this.props.history.push(url(urls.mapView))
    }
  }
}

export default connect<{}, {}, AppDispatchProps>()(withRouter(ResetPassword))
