import { applyNamespace, createAction, createReducer } from 'redux-ts-helpers'
import { AsyncSelector, AsyncSelectorState } from './types'

interface RefreshAsyncSelector {
  resource: string
}

interface UpdateAsyncSelector extends RefreshAsyncSelector {
  result: AsyncSelector
}

const constants = applyNamespace('AsyncSelector', {
  updateAsyncSelector: 0,
  refreshAsyncSelector: 0,
})

export const updateAsyncSelector = createAction<UpdateAsyncSelector>(
  constants.updateAsyncSelector
)
export const refreshAsyncSelector = createAction<RefreshAsyncSelector>(
  constants.refreshAsyncSelector
)

const initialState: AsyncSelectorState = {}

export const reducer = createReducer(initialState, {
  [constants.refreshAsyncSelector]: (
    state,
    action: ReturnType<typeof refreshAsyncSelector>
  ) => ({
    ...state,
    [action.payload.resource]: {
      ...state[action.payload.resource],
      cacheId:
        (((state[action.payload.resource] ?? {}).cacheId ?? 0) + 1) %
        Number.MAX_SAFE_INTEGER,
    },
  }),
  [constants.updateAsyncSelector]: (
    state,
    action: ReturnType<typeof updateAsyncSelector>
  ) => ({
    ...state,
    [action.payload.resource]: {
      ...state[action.payload.resource],
      ...action.payload.result,
    },
  }),
})
