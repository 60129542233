/** returns the first non-null or non-undefined argument passed in */
const coalesce = <T>(...args: T[]): T | undefined =>
  args.find((arg) => arg != null)
export default coalesce

export const mergeCoalesce = <T extends {}>(...args: T[]) => {
  const result = {}

  for (const layer of args) {
    for (const [key, value] of Object.entries(layer)) {
      result[key] = coalesce(result[key], value)
    }
  }

  return result
}
