import * as React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import DatePickerYear from '../../../admin/mapdata/utils/DatePickerYear'

interface Props {
  comment?: string
  open: boolean
  onSubmit: (comment: string, year: number) => void
  onCancel: () => void
}
const thisYear = new Date()

const minDate = new Date('2018-01-01T00:00:00.000')
const maxDate = new Date(thisYear)
maxDate.setFullYear(maxDate.getFullYear() + 1)

export const CopyOrderDialog = ({
  comment,
  onSubmit,
  onCancel,
  open,
}: Props) => {
  const [newComment, setNewComment] = React.useState(
    comment ? `${comment} Copy` : ''
  )
  const [year, setYear] = React.useState<Date | null>(thisYear)

  React.useEffect(() => {
    if (comment) {
      setNewComment(`${comment} Copy`)
    }
  }, [comment])

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Copy Order</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="Comment"
            value={newComment ?? ''}
            onChange={(ev) => setNewComment(ev.target.value)}
          />
          <DatePickerYear
            name="order-year"
            label="Year"
            value={year}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(newValue) => {
              setYear(newValue.value)
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!newComment || !year}
          onClick={() => onSubmit(newComment!, year!.getFullYear())}
        >
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  )
}
