import * as React from 'react'
import { Button, Icon } from '@mui/material'
import { DeliveryProcGroup } from '../../graphql/types'
import { downloadURL } from '../../util/download'
import { getJson } from '../../vvapi/apiResource/createApiResource'
interface Props {
  deliveryProcGroup: Pick<DeliveryProcGroup, 'deliveryId' | 'procGroup'>
}

export class KatoFileDownload extends React.PureComponent<Props> {
  render() {
    return (
      <Button
        variant="outlined"
        onClick={() => this.downloadKatoFile()}
        style={{ alignSelf: 'flex-start', marginBottom: 8 }}
      >
        Download Kato File<Icon>download</Icon>
      </Button>
    )
  }

  downloadKatoFile = async () => {
    const {
      deliveryProcGroup: { deliveryId, procGroup },
    } = this.props

    const { downloadUrl } = await getJson<{ downloadUrl: string }>(
      `/api/v3/delivery-procgroup/${deliveryId}/${procGroup}/kato-file-output`
    )

    downloadURL(downloadUrl)
  }
}
