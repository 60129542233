import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { gql, query } from '../../../graphql/client'

interface AggregateCount {
  aggregate: {
    count: number
  }
}

export interface GetDeliveryGroupFileStatus {
  pending: AggregateCount
  processing: AggregateCount
  unprocessed: AggregateCount
  complete: AggregateCount
  error: AggregateCount
}

const {
  selector: selectGetDeliveryGroupFileStatus,
  refresh: refreshGetDeliveryGroupFileStatus,
} = createAsyncSelector({
  resource: 'GetDeliveryGroupFileStatus',
  inputs: {},
  fetcher: async () => {
    const data = await query<GetDeliveryGroupFileStatus>({
      query: gql`
        query {
          pending: DeliveryGroupFile_aggregate(
            where: { status: { _eq: "pending" } }
          ) {
            aggregate {
              count
            }
          }
          processing: DeliveryGroupFile_aggregate(
            where: { status: { _eq: "processing" } }
          ) {
            aggregate {
              count
            }
          }
          unprocessed: DeliveryGroupFile_aggregate(
            where: { status: { _eq: "unprocessed" } }
          ) {
            aggregate {
              count
            }
          }
          complete: DeliveryGroupFile_aggregate(
            where: { status: { _eq: "complete" } }
          ) {
            aggregate {
              count
            }
          }
          error: DeliveryGroupFile_aggregate(
            where: { status: { _eq: "error" } }
          ) {
            aggregate {
              count
            }
          }
        }
      `,
    })

    if (!data) {
      throw new Error('No Data')
    }

    const {
      pending: {
        aggregate: { count: pending },
      },
      processing: {
        aggregate: { count: processing },
      },
      unprocessed: {
        aggregate: { count: unprocessed },
      },
      complete: {
        aggregate: { count: complete },
      },
      error: {
        aggregate: { count: error },
      },
    } = data

    return {
      pending,
      processing,
      unprocessed,
      complete,
      error,
    }
  },
})

export { selectGetDeliveryGroupFileStatus, refreshGetDeliveryGroupFileStatus }
