import * as React from 'react'
import { selectMapLayerDefsById } from '../../../data/selectListMapSourceDefs'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { selectSelectedLegendProductId } from '../../../data/selectSelectedLegendProductId'
import { useRedux } from '../../../hooks/useRedux'
import { selectActiveProductSettings } from '../../../ProductSettings/store/selectors/selectActiveProductSettings'
import { selectProductStats } from '../../../ProductStats/selectors/selectProductStats'
import {
  selectBestUnitsByProductId,
  selectCalculateStats,
  selectConvertedSelectedColorProfiles,
} from '../../../ProductStats/selectors/stats'
import { toStopData } from '../../../ProductStats/toStopData'
import { ZoneRow } from '../editor/sidebar-options/config/RateMapConfig'
import { useRateMapContext } from '../editor/RateMapContext'

export const useGenerateAmendmentZones = () => {
  const [state] = useRedux()
  const { rateMap } = useRateMapContext()

  const lang = selectPreferredLanguage(state)
  const productId = selectSelectedLegendProductId(state)
  const productStats = selectProductStats(state)[productId]
  const stats = selectCalculateStats(state)
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId]
  const productDef = selectMapLayerDefsById(state)[productId]
  const preferredUnitSystem = state.preferences.preferredUnitSystem
  const unit = selectBestUnitsByProductId(state)[productId]
  const settings = selectActiveProductSettings(state)

  const stops = React.useMemo(() => {
    const colorProfile =
      selectedColorProfiles &&
      (selectedColorProfiles[settings.visualization!] ||
        selectedColorProfiles.absolute ||
        selectedColorProfiles.relative ||
        selectedColorProfiles.threshold)

    const { stops } = toStopData({
      lang,
      unit,
      stats: productStats,
      preferredUnitSystem,
      colorProfile: colorProfile as any,
      layerDef: productDef,
    })

    return stops
  }, [
    productStats,
    lang,
    preferredUnitSystem,
    unit,
    selectedColorProfiles,
    productDef,
    settings,
  ])

  const zones = React.useMemo<ZoneRow[]>(() => {
    const ret = stops
      .map((stop, index) => {
        return {
          stopIndex: index,
          index,
          zoneId: `${stop.key}`,
          size: stop.size,
          color: stop.color as string,
          value: stop.value,
        }
      })
      .filter((s) => s.size > 0)
      .map((row, index) => ({
        ...row,
        amount: (rateMap?.amendmentZoneRates?.[index] ?? 0) * row.size,
        rateOfApplication: rateMap?.amendmentZoneRates?.[index] ?? 0,
      }))

    return ret
  }, [stops, rateMap])

  return [zones, stats.status === 'pending'] as [ZoneRow[], boolean]
}
