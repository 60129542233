import { Stack, Typography, styled } from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../i18n'

const DeleteMessageText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -0.035em;
  text-align: left;
`

export const DeleteProjectNotesForm = () => {
  return (
    <Stack direction="column" gap={2} width={300}>
      <DeleteMessageText>
        {i18n.t(keys.deleteSelectedNotesPromptMessage)}
      </DeleteMessageText>
    </Stack>
  )
}
