import * as Sentry from '@sentry/browser'
import handleError from './handleError'
import { throttle } from './throttle-debounce'

const throttleError = throttle({ delay: 200 }, handleError)

export const formatNum = (
  value: number | string,
  integer = false,
  minimumFractionDigits = 1,
  maximumFractionDigits = 2
) => {
  // tslint:disable-next-line: no-parameter-reassignment
  let num = Number(value)

  if (isNaN(num)) {
    // tslint:disable-next-line: no-parameter-reassignment
    num = 0
    Sentry.withScope((scope) => {
      scope.setTag('category', 'stats')
      scope.setTag('file', 'statData.ts')
      throttleError(new Error('NaN passed to formatNum'))
    })
  }

  if (integer) {
    return Math.round(num).toLocaleString()
  }

  return num.toLocaleString(navigator.language, {
    minimumFractionDigits,
    maximumFractionDigits,
  })
}
