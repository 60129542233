import * as React from 'react'
import { PureComponent } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import i18n, { keys } from '../i18n'

interface Props {
  open: boolean
  title?: string
  text: string
  onConfirm: () => void
  onCancel: () => void
}

class ConfirmDialog extends PureComponent<Props> {
  render() {
    const { open, title, text, onConfirm, onCancel } = this.props

    return (
      <Dialog open={open} onClose={onCancel} scroll="paper">
        {!!title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <Typography>{text}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{i18n.t(keys.generic.cancel)}</Button>
          <Button onClick={onConfirm}>{i18n.t(keys.generic.confirm)}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ConfirmDialog
