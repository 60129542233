import * as React from 'react'

import useAsync from '../../../hooks/useAsync'

import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table'
import { AdminPage } from '../AdminPage'

import { client, gql } from '../../../graphql/client'
import { Box, Button, Stack, TableContainer } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { FileDownload } from '@mui/icons-material'
import { downloadCSV } from '../../../util/table'
import dayjs from 'dayjs'

interface Login {
  Email: string
  First_Name: string
  Last_Name: string
  Last_Login: string
  Organizations: string
}

interface LoginRow extends Omit<Login, 'Last_Login'> {
  Last_Login: dayjs.Dayjs
}

export function LatestLogins() {
  const handleExportRows = (rows: MRT_Row<LoginRow>[]) => {
    const rowData = rows.map((row) => row.original)
    downloadCSV({ filename: 'latest-logins.csv', data: rowData })
  }

  // const history = useHistory()
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(
    dayjs(new Date()).startOf('year').add(3, 'month')
  )

  const [latestLogins] = useAsync(async () => {
    const { latestLogins } = await client.request<{
      latestLogins: Login[]
    }>({
      query: gql`
        query GET_LATEST_LOGINS($dateAfter: timestamptz!) {
          latestLogins: View_LatestLogins(
            where: { Last_Login: { _gt: $dateAfter } }
          ) {
            Email
            First_Name
            Last_Name
            Last_Login
            Organizations
          }
        }
      `,
      variables: { dateAfter: selectedDate ?? new Date('2018-01-01') },
    })

    return latestLogins
  }, [selectedDate])

  const data = React.useMemo(() => {
    return (
      latestLogins.result?.map(({ Last_Login, ...login }) => ({
        ...login,
        Last_Login: dayjs(Last_Login),
      })) ?? []
    )
  }, [latestLogins.result])

  const columns = React.useMemo(() => {
    if (!latestLogins.result) {
      return []
    }
    return [
      {
        header: 'Email',
        accessorKey: 'Email',
      },
      {
        header: 'First Name',
        accessorKey: 'First_Name',
      },
      {
        header: 'Last Name',
        accessorKey: 'Last_Name',
      },
      {
        header: 'Last Login',
        accessorKey: 'Last_Login',
        filterVariant: 'date-range',
        Cell: ({ cell }) => cell.getValue<Date>().toLocaleString(),
      },
      {
        header: 'Organizations',
        accessorKey: 'Organizations',
      },
    ] as MRT_ColumnDef<LoginRow>[]
  }, [latestLogins.result])

  const table = useMaterialReactTable({
    data,
    columns,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    initialState: { pagination: { pageSize: 100, pageIndex: 0 } },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          variant="contained"
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownload />}
        >
          Export All Rows
        </Button>
        <Button
          variant="contained"
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownload />}
        >
          Export Page Rows
        </Button>
        <Button
          variant="contained"
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownload />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  })

  return (
    <AdminPage title="Latest Logins">
      <div className="Paper">
        <Stack alignContent="start" spacing={1}>
          <h2>Latest Logins</h2>
          <DatePicker
            sx={{ width: 150 }}
            value={selectedDate}
            label="Date After"
            onChange={(value) => setSelectedDate(value?.startOf('day') ?? null)}
          />
          <TableContainer>
            <MaterialReactTable table={table} />
          </TableContainer>
        </Stack>
      </div>
    </AdminPage>
  )
}
