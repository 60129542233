/**
 * Compare two strings within sort functions, being mindful of numeric values
 */
const compareNumeric = (a: string, b: string) => {
  return a.localeCompare(b, undefined, {
    numeric: true,
  })
}

export default compareNumeric

export const compareDates =
  (direction: 'asc' | 'desc') => (a: string, b: string) =>
    direction === 'asc'
      ? Date.parse(a) - Date.parse(b)
      : Date.parse(b) - Date.parse(a)
