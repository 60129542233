// conversations from: https://www.unitjuggler.com
import UnitConverter from './UnitConverter'
import { UnitSystem } from './unitSystem'

const areaSource = {
  // metric units
  [UnitSystem.Metric]: {
    squareMillimeter: {
      value: 1e6,
      symbol: 'mm²',
    },
    squareCentimeter: {
      value: 1e4,
      symbol: 'cm²',
    },
    squareDecimeter: {
      value: 1e2,
      symbol: 'dm²',
    },
    squareMeter: {
      value: 1,
      symbol: 'm²',
    },
    squareDecameter: {
      value: 1e-2,
      symbol: 'dam²',
    },
    squareHectometer: {
      value: 1e-4,
      symbol: 'hm²',
    },
    squareKilometer: {
      value: 1e-6,
      symbol: 'km²',
    },

    // metric survey units
    centare: {
      value: 1,
      symbol: 'ca',
    },
    are: {
      value: 1e-2,
      symbol: 'a',
    },
    hectare: {
      value: 1e-4,
      symbol: 'ha',
      display: true,
    },
  },
  [UnitSystem.Imperial]: {
    // imperial units
    squareMile: {
      value: 3.8610215854245e-7,
      symbol: 'sq mi',
    },
    squareYard: {
      value: 1.1959900463011,
      symbol: 'sq yd',
    },
    squareFoot: {
      value: 10.76391041671,
      symbol: 'sq ft',
    },
    squareInch: {
      value: 1550.0031000062,
      symbol: 'sq in',
    },

    // imperial survey units
    acre: {
      value: 0.00024710538146717,
      symbol: 'ac',
      display: true,
    },
    rood: {
      value: 0.00098842152586866,
      symbol: 'ro',
    },
  },
}

const area = UnitConverter(areaSource)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...area,
}
export const convertArea = (
  value: number,
  inputUnit: AreaUnit,
  preferredUnitSystem: UnitSystem
) => {
  return area.bestUnit(
    value,
    area.units[inputUnit],
    inputUnit,
    area.unitsBySystem[preferredUnitSystem]
  )
}
export type AreaUnit =
  | keyof typeof areaSource.metric
  | keyof typeof areaSource.imperial
