import * as React from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Card, CardActions, CardContent, Checkbox, Collapse, FormControlLabel, Grid, IconButton, Skeleton, Typography
} from '@mui/material'

import { DeliveryGroupDate } from '../data/selectOrgMapData'
import i18n, { keys } from '../i18n'
import { Download, DownloadRequest, DownloadResult } from './types'

interface Props {
  selectedDownloads: Record<string, Set<string>>,
  renderStatus: ({ status, id, file, filename }: Pick<Download, "status" | "file" | "filename"> & {
    id: string;
  }) => JSX.Element | React.ReactNode[] | null,
  downloads: {
    status: "pending" | "resolved" | "rejected";
    result?: void | DownloadResult | undefined;
    error?: any;
  },
  hasDownloadsRefreshed: boolean,
  deliveryGroupsDatesById: Record<string, DeliveryGroupDate[]>,
  selectAllDownloads: () => void,
  selectDownloads: (requestId: string, downloadIds: string[], all?: boolean) => void
}

const downloadSkeleton = () => {
  const skeletonRows = []

  for (let i = 0; i < 5; i++) {
    skeletonRows.push(
      <Grid key={i} item xs={12}>
        <Card
          tabIndex={-1}
          variant="outlined">
          <CardContent>
            <Skeleton variant="text" sx={{ fontSize: 14 }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" sx={{ fontSize: 14 }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" sx={{ fontSize: 14 }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" sx={{ fontSize: 14 }} />
            <Skeleton variant="text" />
          </CardContent>
          <CardActions>
            <Skeleton variant="rectangular" width="100%" />
          </CardActions>
        </Card>
      </Grid>
    )
  }

  return skeletonRows
}

interface DownloadRowProps {
  key: string,
  selectedDownloads?: Set<string>,
  request: DownloadRequest
  deliveryGroupsDatesById: Record<string, DeliveryGroupDate[]>,
  selectDownloads: (requestId: string, downloadIds: string[], all?: boolean) => void
  renderStatus: ({ status, id, file, filename }: Pick<Download, "status" | "file" | "filename"> & {
    id: string;
  }) => JSX.Element | React.ReactNode[] | null,
}
const DownloadRow = ({ request, selectDownloads, renderStatus, selectedDownloads, deliveryGroupsDatesById }: DownloadRowProps) => {
  const [open, setOpen] = React.useState(false)

  const selectedDownloadSize = selectedDownloads?.size ?? 0

  return <>
    <Grid key={request.id} item xs={12}>
      <Card variant="outlined" onClick={() => setOpen(!open)}>
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Grid item>
              <FormControlLabel
                label={
                  <Typography variant="subtitle1"
                    onClick={(ev) => {
                      ev.preventDefault()
                      ev.stopPropagation()
                      setOpen(!open)
                    }}>
                    <strong>{i18n.t(keys.dateRequested)}:</strong> {i18n.toDateShort(request?.createdAt ?? '')}
                  </Typography>
                }
                control={
                  <Checkbox checked={selectedDownloadSize > 0}
                    onClick={(ev) => {
                      ev.stopPropagation()
                      selectDownloads(request.id, request?.Downloads.map(({ id }) => id), true)
                    }}
                    indeterminate={selectedDownloadSize > 0 && selectedDownloadSize < request?.Downloads?.length}
                    color="primary" />
                }
              />
            </Grid>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(ev) => {
                ev.stopPropagation()
                setOpen(!open)
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Grid>
        </CardContent>
      </Card>
    </Grid>


    <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
      <Grid item xs={12} sx={{ paddingLeft: 3 }}>
        {request?.Downloads?.map(({ id, filename, status, file, delivery, organizationGroup }) => {

          const deliveryGroupId = `${delivery?.id}_${organizationGroup?.id}`

          const deliveryGroups = deliveryGroupsDatesById[deliveryGroupId!] ?? []
          let dates: string
          const groupName = organizationGroup.name

          if (deliveryGroups.length === 1) {
            dates = deliveryGroups[0].flightDate
          }
          else {
            const maxDate = Math.max(...deliveryGroups.map(dg => Date.parse(dg.flightDate)))
            const minDate = Math.min(...deliveryGroups.map(dg => Date.parse(dg.flightDate)))
            dates = `${i18n.toDateShort(minDate)}-${i18n.toDateShort(maxDate)}`
          }

          return (
            <Card
              key={id}
              onClick={() => selectDownloads(request.id, [id])}
              tabIndex={-1}
              variant="outlined"
              sx={{ my: 1, background: selectedDownloads?.has(id) ? 'var(--background2)' : null }}>
              <CardContent sx={{ px: 1 }}>
                <Grid container direction="row">
                  <Grid>
                    <Checkbox
                      checked={selectedDownloads?.has(id) ?? false}
                      color="primary" />
                  </Grid>
                  <Grid>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {i18n.t(keys.groupName)}:
                    </Typography>
                    <Typography>
                      {groupName}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {i18n.t(keys.flightDate)}:
                    </Typography>
                    <Typography >
                      {dates}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {i18n.t(keys.filename)}:
                    </Typography>
                    <Typography >
                      {i18n.t(`filenames.${filename}`, { defaultValue: filename })}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {i18n.t(keys.status)}:
                    </Typography>
                    <Typography>
                      {i18n.t(status)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                {renderStatus({ status, id, file, filename })}
              </CardActions>
            </Card>
          )
        })}
      </Grid>
    </Collapse>
  </>
}

export const DownloadsCards = ({
  selectDownloads,
  selectedDownloads,
  downloads,
  hasDownloadsRefreshed,
  renderStatus,
  deliveryGroupsDatesById }: Props) => {

  return <Grid container spacing={2} columns={1} sx={{ my: 2 }}>
    {(downloads.status !== 'resolved' && !hasDownloadsRefreshed)
      ? downloadSkeleton()
      : downloads.result?.downloadRequests?.map((request: DownloadRequest) => {
        return <DownloadRow
          key={request.id}
          selectedDownloads={selectedDownloads[request.id]}
          selectDownloads={selectDownloads}
          renderStatus={renderStatus}
          deliveryGroupsDatesById={deliveryGroupsDatesById}
          request={request} />
      })}
  </Grid >
}
