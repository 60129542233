import * as React from 'react'
import useAsync from './useAsync'
import { useIsLoading } from './useIsLoading'

export default function useSavable<
  FetchFunc extends (args: any[], skip: () => void) => any,
  SaveFunc extends (
    value: UnwrapPromise<ReturnType<FetchFunc>>
  ) => ReturnType<FetchFunc>
>(
  fetchFunc: FetchFunc,
  args: any[],
  saveFunc: SaveFunc,
  dependencies: any[] = []
) {
  const [modified, setModified] = React.useState<
    UnwrapPromise<ReturnType<FetchFunc>> | undefined
  >(undefined)

  const [request, refresh] = useAsync(fetchFunc, args, dependencies)

  const lastSaved = React.useMemo(() => request.result, [request.result])

  React.useEffect(() => {
    if (lastSaved) {
      setModified(undefined)
    }
  }, [lastSaved])

  const { isLoading, load } = useIsLoading()

  const save = React.useCallback(() => {
    if (modified) {
      load(async () => {
        await saveFunc(modified)
        refresh()
      })
    }
  }, [saveFunc, refresh, modified, load])

  return [
    modified ?? lastSaved,
    setModified,
    refresh,
    modified !== undefined,
    save,
    request.status === 'pending' || isLoading,
  ] as const
}
