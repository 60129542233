import { Theme } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import * as React from 'react'
import { PureComponent } from 'react'
import ColorDrawer from '../colorScheme/ColorDrawer'
import i18n, { keys } from '../i18n'
import LayerDrawer from '../map/controls/LayerDrawer'
import SettingsDrawer from '../map/controls/SettingsDrawer'
import { connect } from '../redux/connect'
import { updatePreferences } from '../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../redux/types'

const tabContents = {
  layers: () => <LayerDrawer />,
  colors: () => <ColorDrawer />,
  settings: () => <SettingsDrawer />,
}
class SidebarTabs extends PureComponent<
  ReduxProps & AppDispatchProps & WithStyles<typeof styles>
> {
  render() {
    const { selectedTab, classes } = this.props

    const tabContent = tabContents[selectedTab]()

    return (
      <>
        <div className="ui-nav no-print" id="sidebar-right">
          <Tabs
            value={selectedTab}
            textColor="primary"
            indicatorColor="primary"
            className={classes.root}
            variant="fullWidth"
            centered
            onChange={this.handleTabChange}
          >
            <Tab
              style={{ minWidth: 0 }}
              value="layers"
              label={i18n.t(keys.layersTab)}
            />
            <Tab
              style={{ minWidth: 0 }}
              value="colors"
              label={i18n.t(keys.colorsTab)}
            />
            <Tab
              style={{ minWidth: 0 }}
              value="settings"
              label={i18n.t(keys.settingsTab)}
            />
          </Tabs>
        </div>
        <div className="ui-content no-print">{tabContent}</div>
      </>
    )
  }

  handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    this.props.dispatch(updatePreferences({ sidebarTabRight: value }))
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
  })

const mapState = (state: RootStore) => ({
  selectedTab: state.preferences.sidebarTabRight,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(SidebarTabs)
)
