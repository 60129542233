import { CircularProgress, Stack } from '@mui/material'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { client, gql } from '../../graphql/client'
import Page from '../../app/Page'

export const ViewComment = () => {
  const { commentId } = useParams<{ commentId: string }>()
  const history = useHistory()
  React.useEffect(() => {
    ;(async () => {
      // fetch comment by id
      const {
        comment: { deliveryProcGroup },
      } = await client.request<{
        comment: {
          deliveryProcGroup?: { procGroup: string; deliveryId: string }
        }
      }>({
        query: gql`
          query GET_COMMENT_BY_ID($commentId: uuid!) {
            comment: Comment_by_pk(id: $commentId) {
              deliveryProcGroup: Delivery_ProcGroup_Comment {
                deliveryId
                procGroup
              }
            }
          }
        `,
        variables: {
          commentId,
        },
      })

      if (deliveryProcGroup) {
        const { deliveryId, procGroup } = deliveryProcGroup
        // redirect to comment
        history.push(
          `/admin/delivery/${deliveryId}/proc-group/${procGroup}/comments?selectedCommentId=${commentId}`
        )
      } else {
        history.push('/')
      }
    })()
  }, [commentId])

  return (
    <Page>
      <Stack
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Stack>
    </Page>
  )
}

export default ViewComment
