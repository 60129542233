import './ReportCover.scss'
import * as React from 'react'

import { Typography } from '@mui/material'
import { selectOrganization } from '../../data/selectOrganization'
import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { selectReportGroup } from '../selectReportGroup'

class ReportCover extends React.PureComponent<ReduxProps> {
  render() {
    const {
      organization,
      group,
      date = i18n.toDateLong(new Date()),
    } = this.props

    const orgName = organization?.name ?? ''
    const groupName = group?.name ?? ''

    return (
      <section id="ReportCover">
        <div className="ReportHeader" />
        <div className="report_container">
          <Typography className="ReportCover__headline">
            {`${i18n.t(keys.reports.title, {
              orgName,
            })} :`}
          </Typography>
          <Typography className="ReportCover__subheader">
            {groupName}
          </Typography>
          <div className="ReportCover__prepared">
            <Typography paragraph>{i18n.t(keys.reports.preparedBy)}</Typography>
            <Typography paragraph>
              {i18n.t(keys.reports.flightDate)} {date}
            </Typography>
          </div>
        </div>
      </section>
    )
  }
}

const mapState = (state: RootStore) => ({
  organization: selectOrganization(state),
  date: getSelectedFlightDate(state),
  group: selectReportGroup(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(ReportCover)
