import './Tags.scss'

import * as React from 'react'

import { Chip, ChipProps, Tooltip } from '@mui/material'

interface Props extends ChipProps {
  tags?: string[]
}

export class Tags extends React.Component<Props> {
  render() {
    const { tags, ...restProps } = this.props

    return tags?.map((tag) => (
      <Tooltip title={tag} key={tag}>
        <Chip {...restProps} className="tag" label={tag} />
      </Tooltip>
    ))
  }
}
