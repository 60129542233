import { createSelector } from 'reselect'
import { selectRateMapFlightDate } from './selectRateMapFlightDate'
import { selectRateMapSelectedParcelIds } from './selectRateMapSelectedParcelIds'
import { RateMapContextState } from '../editor/RateMapContext'
import {
  ParcelData,
  selectParcelByIdWithFlightDate,
} from '../../../data/selectOrgMapData'
import { rateMapSelectedProductIds } from '../constants'
import { RootStore } from '../../../redux/types'
import { selectOrganizationId } from '../../../data/selectOrganizationId'

const getExternalState = (_: RateMapContextState, externalState: RootStore) =>
  externalState

const selectRateMapOrganizationId = createSelector(
  getExternalState,
  (externalstate) => selectOrganizationId(externalstate)
)

const EMPTY_PARCELS: Record<string, ParcelData> = {}

const selectRateMapParcelsById = createSelector(
  getExternalState,
  selectRateMapFlightDate,
  (externalstate, flightDate) =>
    flightDate
      ? selectParcelByIdWithFlightDate(externalstate, flightDate)
      : EMPTY_PARCELS
)

export const selectRateMapUserSelection = createSelector(
  selectRateMapFlightDate,
  selectRateMapSelectedParcelIds,
  selectRateMapOrganizationId,
  selectRateMapParcelsById,
  (flightDate, selectedParcelIds, organizationId, parcelsById) => {
    const selectedGroupId = selectedParcelIds?.[0]
      ? parcelsById[selectedParcelIds[0]]?.groupId
      : undefined

    return {
      selectedFlightDate: flightDate,
      selectedGroupId,
      selectedParcelIds,
      selectedProductIds: rateMapSelectedProductIds,
      selectedOrganizationId: organizationId,
    }
  }
)
