import * as React from 'react'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import * as api from '../../graphql/api'
import { VVRegion } from '../../graphql/types'
import { RootStore } from '../../redux/types'
import ShallowTable from '../../UI/ShallowTable'
import {
  DataSetEditMetaForm,
  DataSetEditMetaFormData,
} from './DataSetEditMetaForm'
import { refreshGetDataSet, selectGetDataSet } from './selectGetDataSet'

interface Props {
  dataSetId: string
}

interface State {
  open: boolean
}

class DataSetEditMetaButton extends PureComponent<
  Props & ReturnType<typeof mapState>,
  State
> {
  state: State = {
    open: false,
  }

  render() {
    return (
      <React.Fragment>
        <Button variant="outlined" onClick={this.handleOpen}>
          Edit Meta...
        </Button>
        {this.renderDialog()}
      </React.Fragment>
    )
  }

  renderDialog = () => {
    const { dataSet, dataSetId } = this.props
    if (!dataSet || !dataSet.data) {
      return null
    }
    const { meta = {}, region } = dataSet.data

    return (
      <Dialog open={this.state.open} onClose={this.handleClose}>
        <DialogTitle>Edit Meta for Data Set #{dataSetId}</DialogTitle>
        <DialogContent>
          <ShallowTable
            title="Attributes"
            data={{
              cameraDelay: meta.cameraDelay,
            }}
            style={{
              marginBottom: 22,
            }}
          />
          <DataSetEditMetaForm
            type="edit"
            saveMode="all"
            data={{
              ...meta.cameraGroup,
              region,
            }}
            onSave={this.handleSave}
            onCancel={this.handleClose}
          />
        </DialogContent>
      </Dialog>
    )
  }

  handleSave = async (formData: DataSetEditMetaFormData) => {
    const { dataSet } = this.props
    if (!dataSet) {
      throw new Error('dataSet is null')
    }
    if (!dataSet.data) {
      throw new Error('dataSet.data is null')
    }
    const { region, ...cameraGroup } = formData
    const { id, meta = {} } = dataSet.data

    try {
      await api.dataSet.update({
        pk: { id },
        input: {
          meta: {
            ...meta,
            cameraGroup,
          },
          region: region as VVRegion,
        },
      })
    } catch (e) {
      alert(`Problem updating data set meta: ${e.message}`)
    }

    refreshGetDataSet()
    this.handleClose()
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleOpen = () => {
    this.setState({
      open: true,
    })
  }
}

const mapState = (state: RootStore) => ({
  dataSet: selectGetDataSet(state),
})

export default connect(mapState)(DataSetEditMetaButton)
