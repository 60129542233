import { CircularProgress } from '@mui/material'
import * as React from 'react'
import { AuthImage } from './AuthImage'

interface LoadingImageProps {
  src: string
  alt?: string
  style?: React.CSSProperties
  onLoaded?: () => void
}
export const LoadingImage = ({
  src,
  alt,
  style,
  onLoaded,
}: LoadingImageProps) => {
  const imgRef = React.useRef<HTMLImageElement | null>(null)
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null)
  const [loaded, setLoaded] = React.useState(false)
  const [key, setKey] = React.useState(Math.random().toString())
  React.useEffect(() => {
    console.log('[LoadingImage] src', src)
  }, [src])
  React.useEffect(() => {
    if (!loaded) {
      console.log('[LoadingImage] src', src)
      intervalRef.current = setInterval(() => {
        setKey(Math.random().toString())
      }, 1000)
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [loaded])

  const handleLoaded = () => {
    setLoaded(true)
    onLoaded?.()
  }

  return (
    <>
      <AuthImage
        key={key}
        ref={imgRef}
        src={src}
        alt={alt}
        style={{
          ...(style ?? {}),
          display: loaded ? 'block' : 'none',
          objectFit: 'cover',
        }}
        onLoad={handleLoaded}
      />

      {!loaded && <CircularProgress />}
    </>
  )
}
