export function unionSet<T>(setA: Set<T>, setB: Set<T>) {
  const union = new Set(setA)
  for (const elem of Array.from(setB)) {
    union.add(elem)
  }

  return union
}

export const unionAllSets = <T>(sets: Set<T>[]) =>
  sets.reduce((_union, set) => {
    return unionSet(_union, set)
  }, new Set<T>())
