import * as React from 'react'
import { PureComponent } from 'react'
import { Typography } from '@mui/material'
import AsyncSelectorStatusOverlay from '../AsyncSelector/AsyncSelectorStatusOverlay'
import { MenuItem, Select } from '@mui/material'
import { selectDeliveryFilter } from '../data/selectDeliveryFilter'
import { selectFilteredDeliveries } from '../data/selectFilteredDeliveries'
import {
  selectDateMenuData,
  selectDeliveryDates,
  selectDeliveryIdsByGroupIdAndFlightDate,
  selectOrgMapData,
} from '../data/selectOrgMapData'
import * as userSelection from '../data/userSelectionRedux'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'

class DateMenu extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    const {
      selectedFlightDate,
      dates,
      deliveryFlightDatesSelector,
      deliveryFilter,
    } = this.props

    return (
      // this div fixes a layout issue caused by AsyncSelectorStatusOverlay
      <div>
        <AsyncSelectorStatusOverlay
          requests={[deliveryFlightDatesSelector, deliveryFilter]}
        >
          <Select
            disabled={
              dates.selectedDates.length === 0 && dates.otherDates.length === 0
            }
            value={selectedFlightDate ?? 'select-date'}
            onChange={this.handleChange}
          >
            {/* <MenuItem value={'select-date'} style={{ display: 'none' }}>
              {i18n.t(keys.selectDate)}
            </MenuItem> */}
            {dates.selectedDates.length > 0 && [
              <MenuItem disabled key="for-selected">
                <Typography variant="subtitle1" align="left">
                  {i18n.t(keys.forSelectedParcels)}
                </Typography>
              </MenuItem>,
              <hr key="for-selected-separator" />,
            ]}
            {dates.selectedDates.map((flightDate) => (
              <MenuItem key={flightDate} value={flightDate}>
                <Typography variant="subtitle1" align="left">
                  {flightDate}
                </Typography>
              </MenuItem>
            ))}
            {dates.otherDates.length > 0 && [
              <MenuItem disabled key="for-other-parcels">
                <Typography variant="subtitle1" align="left">
                  {i18n.t(keys.forOtherParcels)}
                </Typography>
              </MenuItem>,
              <hr key="for-other-separator" />,
            ]}
            {dates.otherDates.map((flightDate) => (
              <MenuItem key={flightDate} value={flightDate}>
                <Typography variant="subtitle1" align="left">
                  {flightDate}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </AsyncSelectorStatusOverlay>
      </div>
    )
  }

  handleChange = (event: any) => {
    this.props.dispatch(
      userSelection.update({
        selectedFlightDate: event.target.value,
      })
    )
  }
}

const mapState = (state: RootStore) => ({
  selectedFlightDate: state.userSelection.selectedFlightDate,
  dates: selectDateMenuData(state),
  deliveryFlightDates: selectDeliveryDates(state),
  filteredDeliveries: selectFilteredDeliveries(state),
  deliveryFlightDatesSelector: selectOrgMapData(state),
  deliveryFilter: selectDeliveryFilter(state),
  deliveryIds: selectDeliveryIdsByGroupIdAndFlightDate(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(DateMenu)
