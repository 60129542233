import * as React from 'react'
import useAsync from '../../../hooks/useAsync'
import { useParams } from '../../../hooks/useRouter'
import { AdminPage } from '../AdminPage'
import { fetchScheduledNotificationById } from './fetchScheduledNotificationById'
import { updateScheduledNotificationById } from './updateScheduledNotificationById'
import ScheduledNotificationForm from './ScheduledNotificationForm'
import { ScheduledNotification } from '../../../graphql/types'

export function ScheduledNotificationEdit() {
  const { id } = useParams()

  const [result, refresh] = useAsync(fetchScheduledNotificationById, [id])

  if (!id) {
    return null
  }

  const scheduledNotification = result.result ? result.result?.data : undefined

  const handleSave = async (scheduledNotification: ScheduledNotification) => {
    const updated = await updateScheduledNotificationById(id, scheduledNotification)
    refresh()
    return !!updated?.data?.id
  }

  return (
    <AdminPage title="Edit Scheduled Notification" subtitle={id}>
      <ScheduledNotificationForm
        type="edit"
        data={scheduledNotification}
        saveMode="all"
        onSave={handleSave}
      />
    </AdminPage>
  )
}
