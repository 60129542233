import * as React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import i18n, { keys } from '../../i18n'
import { Close } from '@mui/icons-material'
import styled from '@emotion/styled'
import { ConfirmationModal } from '../../app/ConfirmationModal/ConfirmationModal'
import { MoveProjectNotesForm } from './MoveProjectNotesForm'

interface Props {
  open: boolean
  onCancel: () => void
  onMoveProjectNotes?: () => Promise<void>
  currentProjectName: string
  targetProjectName: string
}

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
`

export const MoveProjectNotesModal = ({
  open,
  onCancel,
  onMoveProjectNotes,
  currentProjectName,
  targetProjectName,
}: Props) => {
  return (
    <ConfirmationModal
      open={open}
      onCancel={onCancel}
      onConfirm={() => onMoveProjectNotes?.()}
      title={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title>{i18n.t(keys.moveAllProjectNotesPromptLabel)}</Title>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Stack>
      }
      message={
        <MoveProjectNotesForm
          currentProjectName={currentProjectName}
          targetProjectName={targetProjectName}
        />
      }
    />
  )
}
