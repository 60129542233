import { createSelector } from 'reselect'

import * as Sentry from '@sentry/browser'

import { setCurrentOrg } from '../vvapi/apiResource/createApiResource'
import { selectOrganizationId } from './selectOrganizationId'
import { selectOrganizationsById } from './selectOrganizationsById'

export const selectOrganization = createSelector(
  [selectOrganizationId, selectOrganizationsById],
  (organizationId, organizationsById) => {
    const organization = organizationId ? organizationsById[organizationId] : undefined

    if (organization) {
      setCurrentOrg(organization.id)

      Sentry.configureScope((scope) => {
        scope.setTag('organization', organization.name)
      })
    }

    return organization
  }
)
