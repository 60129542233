import './Maestro.scss'

import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'

import { url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { Job, JobAttempt } from '../../graphql/types'
import i18n from '../../i18n'
import { connect } from '../../redux/connect'
import { RootStore } from '../../redux/types'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import { cancelJobAttempt } from '../../vvapi/maestro'
import errorAlert from '../errorAlert'
import { Column } from '../UI/Column'
import { JsonViewer } from '../UI/JsonViewer'
import { Row } from '../UI/Row'
import warnConfirm from '../warnConfirm'
import MaestroPage from './MaestroPage'
import {
  refreshGetJob,
  refreshGetJobAttemptLogs,
  selectGetJob,
  selectGetJobAttemptLogs,
} from './maestroSelectors'
import { RequeueJobDialog } from './RequeueJobDialog'
import { getStatusIcon } from './utils'
import { selectMe } from '../../data/selectMe'

interface State {
  requeueDialogOpen: boolean
}

class JobPage extends React.PureComponent<
  ReduxProps & RouteComponentProps,
  State
> {
  state: State = { requeueDialogOpen: false }

  render() {
    const { jobSelector } = this.props

    const job = jobSelector.data as Job | undefined
    const title = job ? `Maestro Job | ${job.taskName}` : 'Maestro Job'

    return (
      <MaestroPage
        backTo={this.getBackLocation()}
        backToTitle={this.getBackLocationTitle()}
        title={title}
      >
        <Paper
          className="Paper"
          style={{ flex: 1, maxHeight: '100vh', overflowY: 'auto' }}
        >
          <AsyncSelectorStatusOverlay
            style={{ minHeight: 300 }}
            requests={jobSelector}
          >
            {job && (
              <>
                {this.renderRequeueDialog()}
                <EnhancedTableToolbar
                  style={{ top: -16, paddingLeft: 0, paddingRight: 0 }}
                  title="Job"
                  numSelected={0}
                  onClickRefresh={this.refresh}
                />
                <Column>
                  <Row style={{ width: '100%', marginBottom: 15 }}>
                    {this.renderJobControls()}
                  </Row>
                  <Row style={{ width: '100%', marginBottom: 15 }}>
                    {this.renderJobInfo()}
                  </Row>
                  <Row style={{ width: '100%', marginBottom: 15 }}>
                    {this.renderJobInput()}
                  </Row>
                  <Column
                    style={{ width: '100%', height: '100%', overflowY: 'auto' }}
                  >
                    {this.renderAttempts()}
                  </Column>
                </Column>
              </>
            )}
          </AsyncSelectorStatusOverlay>
        </Paper>
      </MaestroPage>
    )
  }

  refresh = () => {
    refreshGetJob()
    refreshGetJobAttemptLogs()
  }

  cancelAttempt = async (jobAttempt: JobAttempt) => {
    const { jobId } = this.props
    if (jobAttempt.status !== 'running') {
      return
    }

    if (
      await warnConfirm({
        title: 'Cancel Job Attempt',
        message: 'Are you sure you would like to cancel this job attempt?',
      })
    ) {
      try {
        await cancelJobAttempt(jobId!, jobAttempt.attempt)
      } catch (e) {
        this.softError(e, 'Error Cancelling Job', e.message)
      }
      this.refresh()
    }
  }

  showRequeueDialog = () => {
    this.setState({ requeueDialogOpen: true })
  }

  hideRequeueDialog = () => {
    this.setState({ requeueDialogOpen: false })
  }

  handleOnJobRequeued = () => {
    this.refresh()
    this.setState({ requeueDialogOpen: false })
  }

  renderRequeueDialog = () => {
    const { requeueDialogOpen } = this.state
    const { jobSelector } = this.props
    if (jobSelector.data) {
      const { input = {}, id, priority } = jobSelector.data as Job

      return (
        <RequeueJobDialog
          open={requeueDialogOpen}
          input={JSON.stringify(input)}
          jobId={id}
          priority={priority}
          onCloseButtonClicked={this.hideRequeueDialog}
          onJobRequeued={this.handleOnJobRequeued}
        />
      )
    }

    return null
  }

  renderJobControls = () => {
    const { jobSelector, user } = this.props
    if (!user?.roles.includes('admin')) {
      return null
    }

    if (jobSelector.data) {
      const { Status } = jobSelector.data as Job

      return (
        <Button
          variant="contained"
          color="primary"
          disabled={Status?.status === 'queued'}
          onClick={() => this.showRequeueDialog()}
        >
          Re-Queue
        </Button>
      )
    }

    return null
  }

  renderJobInput = () => {
    const { jobSelector } = this.props
    if (jobSelector.data) {
      const { input = {} } = jobSelector.data as Job

      return (
        <Column style={{ flex: 1, width: '100%' }}>
          <Typography variant="caption">Input:</Typography>
          <JsonViewer json={input} />
        </Column>
      )
    }

    return null
  }

  renderAttemptWarnings = (jobAttempt: JobAttempt) => {
    const { warnings = [] } = jobAttempt

    if (warnings.length > 0) {
      return (
        <Column style={{ flex: 1 }}>
          <Typography variant="caption">Warnings:</Typography>
          <pre className="attempt-logs">
            <code>
              {warnings.map((w) => JSON.stringify(w, null, 2)).join('\n')}
            </code>
          </pre>
        </Column>
      )
    }

    return null
  }

  renderAttemptOutput = (jobAttempt: JobAttempt) => {
    const { output = {} } = jobAttempt

    return (
      <Column style={{ flex: 1, width: '100%' }}>
        <Typography variant="caption">Output:</Typography>
        <JsonViewer json={output} />
      </Column>
    )
  }

  renderJobInfo = () => {
    const { jobSelector } = this.props
    if (jobSelector.data) {
      const { taskName, pipeline, jobAttempts } = jobSelector.data as Job

      return (
        <Row
          className="job-info"
          style={{
            width: '100%',
            justifyContent: 'stretch',
            alignItems: 'stretch',
          }}
        >
          <Column className="job-info-column">
            <Typography>Task:</Typography>
            <Typography variant="caption">{taskName}</Typography>
          </Column>
          <Column className="job-info-column">
            <Typography>Pipeline:</Typography>
            <Typography variant="caption">
              {pipeline?.name ?? 'None'}
            </Typography>
          </Column>
          <Column className="job-info-column">
            <Typography>Attempts:</Typography>
            <Typography variant="caption">
              {jobAttempts?.length ?? 0}
            </Typography>
          </Column>
        </Row>
      )
    }

    return null
  }

  renderAttempts = () => {
    const { jobSelector, jobAttemptLogsSelector, selectedAttempt } = this.props
    if (jobSelector.data) {
      const job = jobSelector.data as Job
      if (job.jobAttempts?.length > 0) {
        return job.jobAttempts!.map((jobAttempt) => (
          <Accordion
            color="inherit"
            className={`job ${jobAttempt.status} ${
              selectedAttempt === jobAttempt.attempt ? ` expanded` : ''
            }`}
            expanded={selectedAttempt === jobAttempt.attempt}
            key={`${jobAttempt!.attempt}--${jobAttempt.status}`}
            title={`Attempt ${jobAttempt.attempt}`}
            onChange={(_e, expanded: boolean) =>
              this.handleExpandAttempt(jobAttempt, expanded)
            }
          >
            <AccordionSummary
              color="inherit"
              className="expander-summary"
              expandIcon={<Icon>expand_more</Icon>}
            >
              <Row
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Typography color="inherit">
                  Attempt {jobAttempt.attempt}
                </Typography>
                <Row>
                  {jobAttempt.warnings?.length > 0 && (
                    <Icon style={{ marginRight: 12 }}>warning-outlined</Icon>
                  )}
                  <Icon
                    color="inherit"
                    className={
                      jobAttempt.status === 'running' ? 'running-icon' : ''
                    }
                  >
                    {getStatusIcon(jobAttempt.status)}
                  </Icon>
                </Row>
              </Row>
            </AccordionSummary>
            <AccordionDetails className="expander-content">
              <Row
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Column>
                  {' '}
                  {this.renderStartedAt(jobAttempt)}
                  {this.renderStatusLabel(jobAttempt)}
                </Column>

                {this.renderCancel(jobAttempt)}
              </Row>

              {this.renderAttemptOutput(jobAttempt)}

              {this.renderAttemptWarnings(jobAttempt)}

              <Typography variant="caption">Logs:</Typography>

              <pre className="attempt-logs">
                <samp>
                  <AsyncSelectorStatusOverlay
                    style={{ width: '100%' }}
                    requests={jobAttemptLogsSelector}
                  >
                    {jobAttemptLogsSelector.data &&
                      this.formatLogs(
                        jobAttemptLogsSelector.data.logs ?? { type: 'none' }
                      )}
                  </AsyncSelectorStatusOverlay>
                </samp>
              </pre>
            </AccordionDetails>
          </Accordion>
        ))
      }

      return <span>No Attempts Yet</span>
    }

    return null
  }

  renderStartedAt = (jobAttempt: JobAttempt) => {
    return <span>Started At {i18n.toDateTimeShort(jobAttempt.startedAt)}</span>
  }

  renderStatusLabel = (jobAttempt: JobAttempt) => {
    switch (jobAttempt.status) {
      case 'running':
        return <span>Running for {this.getRunningTime(jobAttempt)}</span>
      case 'complete':
        return <span>Completed in {this.getDuration(jobAttempt)}</span>
      case 'error':
        return <span>Errored in {this.getDuration(jobAttempt)}</span>
      case 'timeout':
        return <span>Timed Out in {this.getDuration(jobAttempt)}</span>
      case 'cancelled':
        return <span>Cancelled after {this.getDuration(jobAttempt)}</span>
      default:
        return <span>Pending</span>
    }
  }

  renderCancel = (jobAttempt: JobAttempt) => {
    if (jobAttempt.status !== 'running') {
      return null
    }

    return (
      <Button
        color="secondary"
        variant="contained"
        onClick={() => this.cancelAttempt(jobAttempt)}
      >
        Cancel
      </Button>
    )
  }

  formatLogs = ({ data, type }: { data?: any; type?: string }) => {
    if (type === 'none') {
      return <span>No Logs</span>
    }

    if (type === 'cloudwatch') {
      const events = data as { timestamp: string; message: string }[]

      let lineNum = 1

      return events.map(({ timestamp, message }) => {
        const logRow = (
          <div className="log-line">
            <span className="log-number">{lineNum}</span>
            <span className="log-timestamp">{i18n.toLogFormat(timestamp)}</span>
            <span className="log-message">{message}</span>
          </div>
        )

        lineNum += 1

        return logRow
      })
    }

    return JSON.stringify(data, null, 2)
  }

  handleExpandAttempt = (jobAttempt: JobAttempt, expanded: boolean) => {
    const { jobId, selectedAttempt } = this.props

    if (expanded) {
      this.props.history.replace(
        url(urls.job, { jobId }, { 'selected-attempt': jobAttempt.attempt })
      )
    } else if (selectedAttempt === jobAttempt.attempt) {
      this.props.history.replace(url(urls.job, { jobId }, {}))
    }
  }

  getDuration = (jobAttempt: JobAttempt) => {
    return i18n.distanceInWords(jobAttempt.startedAt, jobAttempt.finishedAt)
  }
  getRunningTime = (jobAttempt: JobAttempt) => {
    return i18n.distanceInWords(jobAttempt.startedAt, new Date())
  }
  getBackLocation = () => {
    const { jobSelector } = this.props
    const job = jobSelector.data as Job
    if (job?.pipeline) {
      return url(urls.pipeline, { pipelineId: job.pipeline.id })
    }

    return url(urls.jobs)
  }

  getBackLocationTitle = () => {
    const { jobSelector } = this.props
    const job = jobSelector.data as Job
    if (job?.pipeline) {
      return 'Pipeline'
    }

    return 'Jobs'
  }

  softError = (
    error: Error,
    title: string,
    message: string,
    extras?: Record<string, any>
  ) =>
    errorAlert({
      error,
      title,
      message,
      extras,
      tags: {
        category: 'maestro',
      },
    })
}

const mapState = (state: RootStore) => {
  return {
    jobSelector: selectGetJob(state),
    jobId: state.router.params.jobId,
    selectedAttempt: Number(state.router.searchParams['selected-attempt']),
    jobAttemptLogsSelector: selectGetJobAttemptLogs(state),
    user: selectMe(state),
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(withRouter(JobPage))
