import { createSelector } from 'reselect'

import mask from '@turf/mask'

import { selectStatsZones } from './selectStatsZones'
import { featureCollection } from '@turf/helpers'

const EMPTY_SOURCES_AND_LAYERS = { sources: {}, layers: [] }

export const selectStatsMask = createSelector(
  selectStatsZones,
  (statsZones) => {
    if (!statsZones) {
      return EMPTY_SOURCES_AND_LAYERS
    }
    try {
      const poylgon = mask(statsZones)

      return {
        sources: {
          ['stats-mask-poly']: {
            data: featureCollection([poylgon]),
            type: 'geojson',
            attribution: '&copy; VineView',
          },
        },
        layers: [
          {
            paint: {
              'fill-color': 'black',
              'fill-opacity': 0.5,
            },
            type: 'fill' as 'fill',
            id: 'stats-mask-poly',
            source: 'stats-mask-poly',
          },
        ],
      }
    } catch (e) {
      return EMPTY_SOURCES_AND_LAYERS
    }
  }
)
