import { createSelector } from 'reselect'
import { RootStore } from '../redux/types'
import { selectParcels } from './selectOrgMapData'

const DEFAULT: string[] = []
const getSelectedParcelIds = (state: RootStore) =>
  state.userSelection.selectedParcelIds || DEFAULT

const getSelectedGroupId = (state: RootStore) =>
  state.userSelection.selectedGroupId

const selectSelectedGroupParcelIds = createSelector(
  [selectParcels, getSelectedGroupId],
  (parcels, groupId) =>
    new Set(
      parcels
        .filter(({ group }) => group.id === groupId)
        .map(({ id: parcelId }) => parcelId)
    )
)

export const selectSelectedParcelIds = createSelector(
  [getSelectedParcelIds, selectSelectedGroupParcelIds],
  (selectedParcelIds, selectedGroupParcelIds) =>
    new Set(selectedParcelIds.filter((id) => selectedGroupParcelIds.has(id)))
)
