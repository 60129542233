import { createSelector } from 'reselect'

import { RootStore } from '../redux/types'
import {
  selectDeliveryGroupsByGroupId,
  selectDeliveryParcelsByGroupId,
} from './selectDelivery'
import { selectGroupsById } from './selectOrgMapData'
import { GetDeliveryData } from './types'

const getSelectedGroupId = (state: RootStore) =>
  state.userSelection.selectedGroupId

/** Used to send extra data to Sentry */
export const selectSelectedGroup = createSelector(
  getSelectedGroupId,
  selectGroupsById,
  (groupId, groups) => (groupId ? groups[groupId] : undefined)
)

/** Used to send extra data to Sentry */
export const selectSelectedDeliveryGroup = createSelector(
  getSelectedGroupId,
  selectDeliveryGroupsByGroupId,
  (groupId, deliveryGroups) => (groupId ? deliveryGroups[groupId] : undefined)
)

const EMPTY_PARCELS: GetDeliveryData['parcels'] = []

export const selectSelectedDeliveryGroupParcels = createSelector(
  getSelectedGroupId,
  selectDeliveryParcelsByGroupId,
  (groupId, deiliveryParcels) =>
    groupId ? deiliveryParcels[groupId] : EMPTY_PARCELS
)
