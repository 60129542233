import { client, gql } from '../../../graphql/client'
import { FeatureFlag, User } from '../../../graphql/types'
import {
  createTableFetcher,
  Fetcher,
} from '../../../UI/Table/createTableFetcher'
import { tableFormatter } from './featureFlagTableFormatters'

const fetchFeatureFlagsQuery = (returning: string, order_by: string) => gql`
  query GET_FEATURE_FLAGS(
    $offset: Int!
    $limit: Int!
    $where: FeatureFlag_bool_exp
  ) {
    data: FeatureFlag(
      order_by: ${order_by}
      offset: $offset, 
      limit: $limit, 
      where: $where
    ) ${returning}

    aggregate: FeatureFlag_aggregate(where: $where) {
      info: aggregate {
        count
      }
    }
  }
`

const fetchFeatureFlags = (usersById: Record<string, User>) => {
  const list: Fetcher<FeatureFlag> = async (options) => {
    const where = {
      _and: {
        ...(options?.where ?? {}),
      },
    }

    const order_by = options.order_by?.replace(/\s/g, '') ?? '[{ id: desc }]'

    const { data, aggregate } = await client.request<{
      data?: FeatureFlag[]
      aggregate?: { info: { count: number } }
    }>({
      query: fetchFeatureFlagsQuery(options.returning ?? '', order_by),
      variables: {
        offset: options.offset,
        limit: options.limit,
        where: where,
      },
    })

    return {
      data: data?.map((ff) => {
        ff.FeatureFlagUserAccesses = ff.FeatureFlagUserAccesses?.map((ffua) => {
          ffua.User = usersById[ffua.userId]

          return ffua
        })

        return ff
      }),
      info: aggregate?.info,
    }
  }

  return createTableFetcher<FeatureFlag>(
    tableFormatter,
    `{
        id
        active
        name
        desc
        createdAt
        FeatureFlagOrganizationAccesses {
          Organization {
            id
            name
          }
        }
        FeatureFlagUserAccesses {
          userId
        }
      }`,
    list,
    '_or'
  )
}

const commitFeatureFlagQuery = gql`
  mutation COMMIT_FEATURE_FLAG($featureFlag: FeatureFlag_insert_input!) {
    data: insert_FeatureFlag_one(
      object: $featureFlag
      on_conflict: {
        constraint: FeatureFlag_pkey
        update_columns: [name, desc, active]
      }
    ) {
      id
    }
  }
`

const commitFeatureFlag = (featureFlag: FeatureFlag) => {
  return client.request<{
    data?: FeatureFlag
  }>({
    query: commitFeatureFlagQuery,
    variables: {
      featureFlag: {
        ...featureFlag,
        FeatureFlagOrganizationAccesses: {
          data:
            featureFlag?.FeatureFlagOrganizationAccesses?.map((ffo) => ({
              organizationId: ffo.organizationId,
            })) ?? [],
        },
        FeatureFlagUserAccesses: {
          data:
            featureFlag?.FeatureFlagUserAccesses?.map((ffu) => ({
              userId: ffu.userId,
            })) ?? [],
        },
      },
    },
  })
}

const deleteFeatureFlagQuery = gql`
  mutation DELETE_FEATURE_FLAG($featureFlagId: String!) {
    data: delete_FeatureFlag_by_pk(id: $featureFlagId) {
      id
    }
  }
`

const deleteFeatureFlag = (featureFlag: FeatureFlag) => {
  return client.request<{
    data?: FeatureFlag
  }>({
    query: deleteFeatureFlagQuery,
    variables: {
      featureFlagId: featureFlag.id,
    },
  })
}

const deleteFeatureFlagAccessQuery = gql`
  mutation DELETE_FEATURE_FLAG($featureFlagId: String!) {
    delete_FeatureFlagUserAccess(
      where: { featureFlagId: { _eq: $featureFlagId } }
    ) {
      returning {
        userId
        featureFlagId
      }
    }
    delete_FeatureFlagOrganizationAccess(
      where: { featureFlagId: { _eq: $featureFlagId } }
    ) {
      returning {
        organizationId
        featureFlagId
      }
    }
  }
`

const deleteFeatureFlagAccess = (featureFlag: FeatureFlag) => {
  return client.request<{
    data?: FeatureFlag
  }>({
    query: deleteFeatureFlagAccessQuery,
    variables: {
      featureFlagId: featureFlag.id,
    },
  })
}

const fetchFeatureFlagByPkQuery = gql`
  query FETCH_FEATURE_FLAG($featureFlagId: String!) {
    data: FeatureFlag_by_pk(id: $featureFlagId) {
      id
      active
      name
      desc
      createdAt
      FeatureFlagOrganizationAccesses {
        Organization {
          id
          name
        }
        featureFlagId
        organizationId
      }
      FeatureFlagUserAccesses {
        featureFlagId
        userId
      }
    }
  }
`

const fetchFeatureFlagByPk = (
  [featureFlagId]: [featureFlag: string],
  skip?: () => void
) => {
  if (!featureFlagId) {
    return skip?.()
  }
  return client.request<{
    data?: FeatureFlag
  }>({
    query: fetchFeatureFlagByPkQuery,
    variables: {
      featureFlagId: featureFlagId,
    },
  })
}

export {
  fetchFeatureFlags,
  fetchFeatureFlagByPk,
  commitFeatureFlag,
  deleteFeatureFlag,
  deleteFeatureFlagAccess,
}
