import { ScheduledNotification_update_input } from '../../../graphql/types'
import * as api from '../../../graphql/api'

export const updateScheduledNotificationById = async (id: string, update: ScheduledNotification_update_input) => {
  const choice = await sweetAlert(
    `Are you sure you want to update this scheduled notification?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Update',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (!choice) {
    return
  }

  
  return await api.scheduledNotifications.update<{ id: string }>(
    {
      pk: { id },
      input: update,
    }
  )
}
