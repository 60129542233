import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
  setFocusedSoilLayer,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { FocusedPolygon } from '../../../../postgis/types'
import { Feature } from '@turf/helpers'
import area from '@turf/area'
import { unionMany } from '../../../../util/unionMany'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class PolygonActionGenerator implements IMapActionGenerator {
  key = 'polygon'
  priority = Priority.Polygon

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
    mapLayers,
    map,
    state,
  }: IMapActionGeneratorParams): IMapAction[] {
    if (state.notes.editingNoteId !== undefined) {
      return []
    }
    const products = features.filter((f) => f.layer.id.endsWith('-layer'))

    // Find the qualifying features.
    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) =>
        mapLayers?.[f.layer.id.replace('-layer', '')]?.mapLayerDef.dataProperty
          .type === 'class' && isPolygonLayer(f)
    )

    // Return functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        let properties: FocusedPolygon['properties'] = {
          mapSourceId: q.sourceLayer,
        }

        const features = map.querySourceFeatures(q.source, {
          sourceLayer: q.sourceLayer,
        }) as Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>[]

        const filteredFeatures = features.filter((feat) => {
          return (
            feat.properties?.[
              mapLayers[q.layer.id.replace('-layer', '')].mapLayerDef
                .dataProperty.property
            ] ===
            q.properties?.[
              mapLayers[q.layer.id.replace('-layer', '')].mapLayerDef
                .dataProperty.property
            ]
          )
        })

        // get properties from other products
        products.forEach((product) => {
          if (!product) {
            return
          }

          properties = {
            ...properties,
            ...product.properties,
          }
        })

        const unionedFeature = unionMany(filteredFeatures)

        if (!!unionedFeature) {
          const focusedPolygon: Writable<FocusedPolygon> = {
            properties: { ...properties, Area_m2: area(unionedFeature) },
            type: 'Feature',
            geometry: unionedFeature.geometry,
          }

          dispatch(setFocusedPoint(null))
          dispatch(setFocusedPolygon(focusedPolygon))
          dispatch(setFocusedBlockId(undefined))
          dispatch(setFocusedSoilLayer(null))
        }
      },
    }))
  }
}

/** type guard for polygon geometries */
const isPolygonLayer = (
  product: GeoJSON.Feature<GeoJSON.Geometry>
): product is GeoJSON.Feature<GeoJSON.Polygon> =>
  product.geometry.type === 'Polygon'
