import * as React from 'react'

import { Grid } from '@mui/material'

import { selectMe } from '../../../data/selectMe'
import useAsync from '../../../hooks/useAsync'
import { useRedux } from '../../../hooks/useRedux'
import { getJson } from '../../../vvapi/apiResource/createApiResource'
import { OrganizationSubscriptions } from './OrganizationSubscriptions'
import { SubscriptionSkeleton } from './SubscriptionSkeleton'
import Typography from '@mui/material/Typography';
import i18n, { keys } from '../../../i18n'

export interface RefreshableRef {
  refresh: () => void
}

export const OrganizationSubscription = React.forwardRef((_props, ref) => {

  const [state] = useRedux()
  const me = selectMe(state)

  const [fetchSubscriptions, refreshSubscriptions] = useAsync(async ([email], skip: () => void) => {
    if (!email) {
      return skip()
    }
    const { subscriptions } = await getJson<{ subscriptions: any[] }>(`/api/v3/stripe/subscriptions`)
    return subscriptions
  }, [me?.email])

  React.useImperativeHandle(ref, () => ({
    refresh() {
      refreshSubscriptions()
    }
  }))

  if (fetchSubscriptions.status === 'pending') {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important' }}>
          <SubscriptionSkeleton />
        </Grid>
      </Grid>
    )
  }

  if ((fetchSubscriptions.result?.length ?? 0) > 0) {
    return <OrganizationSubscriptions subscriptions={fetchSubscriptions.result!} />
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems='center'>
        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important' }} justifyContent="center" alignItems='center'>
          <Typography align='center'>{i18n.t(keys.noSubscriptions)}</Typography>
        </Grid>
      </Grid>
    </>
  )
})