import * as Sentry from '@sentry/browser'
import { RootStore } from '../redux/types'
import handleError from '../util/handleError'
import { migrations } from './migrations'

const versions = Object.keys(migrations)
  .map(Number)
  .sort((a, b) => a - b)

export const latestKnownVersion = versions[versions.length - 1]

export default function migrateStore(state: any): RootStore | undefined {
  if (!state.app) {
    state.app = {}
  }

  let currentVersion =
    typeof state.app.storeVersion === 'number' ? state.app.storeVersion : 0

  // if the incoming version is newer then what the app know how to use reset the state.
  if (currentVersion > latestKnownVersion) {
    return
  }

  let err: Error | undefined

  versions.forEach((version) => {
    if (version <= currentVersion || err) {
      return
    }

    const migrateFunc = migrations[version]

    try {
      // tslint:disable-next-line:no-parameter-reassignment
      state = migrateFunc(state)
    } catch (e) {
      err = e

      Sentry.addBreadcrumb({
        category: 'app',
        message: 'Failed calling migrateFunc',
        data: {
          version,
          currentVersion,
          latestKnownVersion,
        },
        level: Sentry.Severity.Error,
      })
      handleError(err)
    }

    currentVersion = state.app.storeVersion = version
  })

  if (err) {
    return
  }

  return state
}
