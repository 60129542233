import * as React from 'react'
import { Stack, TextField, Typography } from '@mui/material'
import i18n, { keys } from '../../../../../i18n'
import { SamplePlanNewFormProps } from '../types/SamplePlanFormProps'

type Ref = HTMLDivElement

const SamplePlanNameForm = ({
  currentNewSamplingPlan,
  setCurrentNewSamplingPlan,
  forwardedRef,
  registerValidation,
  invalidateCompletedSamplingPlan,
  stage
}: SamplePlanNewFormProps<Ref>) => {
  const onNameChange = async (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCurrentNewSamplingPlan({
      ...currentNewSamplingPlan,
      samplePlanName: e.target.value,
    })
    await invalidateCompletedSamplingPlan()
  }

  React.useEffect(() => {
    registerValidation(() => {
      return !!currentNewSamplingPlan?.samplePlanName && currentNewSamplingPlan.samplePlanName.length > 0
    }, stage)
  },[registerValidation, stage, currentNewSamplingPlan])

  return (
    <Stack
      className="sample-plan-new-stage-form"
      direction="column"
      spacing={2}
      ref={forwardedRef}
    >
      <Typography variant="body1" fontWeight={600} fontSize={16}>
        {i18n.t(keys.samplePlanNewNameLabel)}
      </Typography>
      <TextField
        variant="outlined"
        size='small'
        placeholder={i18n.t(keys.samplePlanNewNameTipLabel)}
        value={currentNewSamplingPlan?.samplePlanName ?? ''}
        onChange={async (e) => onNameChange(e)}
      />
    </Stack>
  )
}

export default React.forwardRef<Ref, SamplePlanNewFormProps<Ref>>((props, ref) => (
  <SamplePlanNameForm {...props} forwardedRef={ref} />
))
