import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import * as React from 'react'
import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { MonitorEvent } from '../../graphql/types'
import { connect } from '../../redux/connect'
import { RootStore } from '../../redux/types'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import { Tags } from '../../UI/Tags'
import { Column } from '../UI/Column'
import { Row } from '../UI/Row'
import { refreshGetEvent, selectGetEvent } from './monitoringSelectors'

class MonitorEventPage extends React.PureComponent<ReduxProps> {
  render() {
    const { eventSelector } = this.props

    return (
      <Page
        title={`Event`}
        backTo={url(urls.monitorEventList)}
        backToTitle="Monitoring Dashboard"
      >
        <Paper>
          <EnhancedTableToolbar
            title={`Event`}
            numSelected={0}
            onClickRefresh={this.refreshEvent}
          />
          <AsyncSelectorStatusOverlay requests={eventSelector}>
            {eventSelector.data && this.renderEventDetail(eventSelector.data)}
          </AsyncSelectorStatusOverlay>
        </Paper>
      </Page>
    )
  }

  refreshEvent = () => {
    refreshGetEvent()
  }

  renderEventDetail({
    eventTime,
    eventData,
    tags,
    title,
    level,
  }: Partial<MonitorEvent>) {
    const dateString = this.convertDate(Date.parse(eventTime ?? ''))

    return (
      <Column style={{ padding: 24 }}>
        <Column>
          <Typography variant="subtitle1">Title:</Typography>
          <Typography variant="body1">{title}</Typography>
        </Column>
        <Column>
          <Typography variant="subtitle1">Level:</Typography>
          <Typography variant="body1">{level}</Typography>
        </Column>
        <Column>
          <Typography variant="subtitle1">Event Occurred At:</Typography>
          <Typography variant="body1">{dateString}</Typography>
        </Column>
        <Column>
          <Typography variant="subtitle1">Event Data:</Typography>
          <div
            style={{
              fontSize: 14,
              fontFamily: 'Consolas, monospace, serif',
              borderRadius: 3,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: 'white',
              padding: 12,
            }}
          >
            <pre>{JSON.stringify(eventData, null, 2)}</pre>
          </div>
        </Column>
        <Column>
          <Typography variant="subtitle1">Event Tags:</Typography>
          <Row>
            <Tags tags={tags} />
          </Row>
        </Column>
      </Column>
    )
  }

  convertDate = (dateNumber: number) => {
    const date = new Date(dateNumber)
    if (isNaN(date.getTime())) {
      return undefined
    }

    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }
}

const mapState = (state: RootStore) => ({
  eventSelector: selectGetEvent(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(MonitorEventPage)
