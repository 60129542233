import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import withStyles, { Styles, WithStyles } from '@mui/styles/withStyles'

import { url, urls } from '../../appNavigation/urls'
import ShallowTable from '../../UI/ShallowTable'
import SmallGridContainer from '../../UI/SmallGridContainer'
import { ListDataSetImageData } from './selectListDataSetImages'

interface Props {
  dataSetImage: ListDataSetImageData
}

interface State {
  maxLogsShown: number
}

class DataSetImageDetails extends React.PureComponent<
  Props & RouteComponentProps & WithStyles<Styles<Theme, Props, ClassKey>>,
  State
> {
  state: State = {
    maxLogsShown: 10,
  }

  render() {
    const { dataSetImage } = this.props

    if (!dataSetImage) {
      return null
    }

    let lat = 'unknown'
    let lng = 'unknown'

    if (dataSetImage.center) {
      // tslint:disable-next-line: semicolon
      ;[lng, lat] = dataSetImage.center.coordinates.map(String)
    }

    const { altitude, yaw, pitch, roll, elevation } = dataSetImage

    return (
      <SmallGridContainer>
        <Grid item md={6}>
          <ShallowTable
            title={`Attributes`}
            data={{
              lat,
              lng,
              altitude,
              yaw,
              pitch,
              roll,
              elevation,
            }}
          />
        </Grid>
        <Grid item md={6}>
          {/* from vineview-processing-py: atcor.py */}
          {this.renderMeta('atcor_instructions')}
        </Grid>
        <Grid item md={6}>
          {this.renderMeta('rawLogMatch')}
        </Grid>
      </SmallGridContainer>
    )
  }
  viewJob = (attempt?: number) => {
    const {
      dataSetImage: { job },
    } = this.props
    if (job) {
      if (attempt) {
        this.props.history.push(
          url(urls.job, { jobId: job.id }, { 'selected-attempt': attempt })
        )
      } else {
        this.props.history.push(url(urls.job, { jobId: job.id }, {}))
      }
    }
  }

  renderMeta(metaKey: string) {
    const { meta } = this.props.dataSetImage

    if (!meta || !meta[metaKey]) {
      return null
    }

    return <ShallowTable title={metaKey} data={meta[metaKey]} />
  }
}

type ClassKey = 'tableRow' | 'tableHeader'

const decorator = withStyles<Styles<Theme, Props, ClassKey>>((theme) => {
  return {
    tableHeader: {
      backgroundColor: theme.palette.background.default,
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      height: 'auto !important',
    },
  }
})

export default decorator(withRouter(DataSetImageDetails))
