import * as React from 'react'
import i18n, { keys } from '../../i18n'
import { Button, Stack, styled } from '@mui/material'
import { Add } from '@mui/icons-material'
import { CreateProjectModal } from './CreateProjectModal'
import { ProjectType } from '../../graphql/types'
import { selectOrganizationId } from '../../data/selectOrganizationId'
import { useRedux } from '../../hooks/useRedux'

interface Props {
  type: ProjectType
  style?: React.CSSProperties
}

const StyledButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
`
)

export const NewProjectButton = ({ type, style }: Props) => {
  const [state] = useRedux()
  const organizationId = selectOrganizationId(state)
  const [open, setOpen] = React.useState(false)

  return (
    <Stack style={style} flex={1}>
      <StyledButton
        variant="outlined"
        color="inherit"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        {i18n.t(keys.projectLabel)}
      </StyledButton>
      <CreateProjectModal
        open={open}
        onCancel={() => setOpen(false)}
        onProjectCreate={() => setOpen(false)}
        organizationId={organizationId}
        type={type}
      />
    </Stack>
  )
}
