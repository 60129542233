import { Button, Grid, Paper, Slide, Snackbar, Typography } from '@mui/material';
import * as React from "react";
import { NotificationProps } from "./NotificationProps";
import i18n, { keys } from "../../i18n";
import { Apple, Shop } from "@mui/icons-material";
import TooltipIconButton from '../../UI/TooltipIconButton';

const playStoreLink = 'https://play.google.com/store/apps/details?id=com.vineview'
const appStoreLink = 'https://apps.apple.com/us/app/vineview/id6445883728'

export const MobileAdvertiseNotification = ({dismissNotification, open}: NotificationProps) => {
    const linkAndDismiss = (link: string) => {
        window.open(link, '_blank', 'noreferrer')
        dismissNotification(0)
    }

    return (
        <>
            <Snackbar
              open={open}
              TransitionComponent={Slide}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
                <Paper>
                    <Grid container rowSpacing={2} style={ { maxWidth: '200px', padding: '10px 15px' } }>
                        <Grid item xs={12}>
                            <Typography variant='h6'>{i18n.t(keys.mobileAdvertisementTitle)}</Typography>
                            <Typography variant='subtitle1'>{i18n.t(keys.mobileAdvertisementText)}</Typography>
                        </Grid>
                        <Grid item xs={4} justifySelf='flex-end'>
                            <TooltipIconButton title='Apple App Store' onClick={() => linkAndDismiss(appStoreLink)}>
                                <Apple />
                            </TooltipIconButton>
                        </Grid>
                        <Grid item xs={4} justifySelf='flex-end'>
                            <TooltipIconButton title='Google Play Store' onClick={() => linkAndDismiss(playStoreLink)}>
                                <Shop />
                            </TooltipIconButton>
                        </Grid>
                        <Grid item xs={4} justifySelf='flex-end'>
                            <Button onClick={() => dismissNotification(0)}>{i18n.t(keys.generic.close)}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Snackbar> 
        </> 
    )
}