import { selectPagingInfo } from '../../appNavigation/urls'
import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import * as api from '../../graphql/api'
import { RootStore } from '../../redux/types'

const monitorEventReturning = `
{
    id
    sendNotification
    tags
    eventData
    eventTime
    title
    level
}`

const fetchEvent = async ({ eventId }: { eventId: string }) => {
  const { data } = await api.monitorEvent.get({
    pk: { id: eventId },
    returning: monitorEventReturning,
  })

  if (!data) {
    throw new Error('No Data')
  }

  return data
}

const fetchEvents = async ({
  pagingInfo,
}: {
  pagingInfo: ReturnType<typeof selectPagingInfo>
}) => {
  const { page, pageSize } = pagingInfo
  const offset = page * pageSize
  const limit = pageSize
  const { data, info: { count = 0 } = {} } = await api.monitorEvent.list({
    limit,
    offset,
    returning: monitorEventReturning,
    order_by: '{ eventTime: desc }',
  })

  if (!data) {
    throw new Error('No Data')
  }

  return {
    data,
    info: { count, page, pageSize },
  }
}

export const { selector: selectGetEvent, refresh: refreshGetEvent } =
  createAsyncSelector({
    resource: 'GetEvent',
    inputs: {
      eventId: (state: RootStore) => state.router.params.eventId,
    },
    fetcher: fetchEvent,
  })

export const { selector: selectListEvent, refresh: refreshListEvent } =
  createAsyncSelector({
    resource: 'ListEvent',
    inputs: {
      pagingInfo: selectPagingInfo,
    },
    fetcher: fetchEvents,
  })
