import { Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { refreshGetDataSet, selectGetDataSet } from "./selectGetDataSet";
import { getState } from "../../redux/store";
import * as React from "react";
import { DataSetPriority, DataSetPriorityList } from "../../graphql/types";
import * as api from '../../graphql/api'


export const DataSetPriorityDropdown = () => {
    const state = getState()
    const dataSet = state && selectGetDataSet(state)

    const onDropdownChanged = async (event: SelectChangeEvent<any>) => {
        if (!dataSet || !dataSet.data) {
          throw new Error('data set is not available')
        }

        const id = dataSet.data.id
        const selectedPriority = event.target.value as DataSetPriority
    
        try {
          await api.dataSet.update({
            pk: { id },
            input: {
              priority: selectedPriority
            },
          })
        } catch (e) {
          alert(`Problem updating data set meta: ${e.message}`)
        }
    
        refreshGetDataSet()
    }
    return (
        <Grid container direction='column' xs={1} style={{marginLeft: '8px'}}>
            <Typography variant="overline">Priority</Typography>
            <Select 
                value={dataSet?.data?.priority} 
                onChange={onDropdownChanged}
                >
                <MenuItem key='none' value={null as any}>None</MenuItem>
                {DataSetPriorityList.map((p => <MenuItem key={p} value={p}>{p}</MenuItem>))}
            </Select>
        </Grid>
    )
}