import i18n from '../../i18n'
import { TableFormatter } from '../../UI/Table/types'
import { ListMapLayerDefGroupsData } from './selectListMapLayerDefGroups'

export const tableFormatters: TableFormatter<ListMapLayerDefGroupsData>[] = [
  {
    header: () => 'Name',
    data: ({ name }) => name,
    orderBy: (direction) => `
      {
        name: ${direction}
      }
    `,
  },
  {
    header: () => 'Last Modified',
    data: ({ updatedAt }) => i18n.toDateTimeShort(updatedAt),
    orderBy: (direction) => `
      {
        updatedAt: ${direction}
      }
    `,
  },
]
