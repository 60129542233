import * as React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Icon,
  Stack,
} from '@mui/material'
import { setToggle } from '../../../../util/setToggle'
import { setWith } from '../../../../util/setWith'
import { setWithout } from '../../../../util/setWithout'
import { setHasAny } from '../../../../util/setHasAny'
import { setHasAll } from '../../../../util/setHasAll'
import { DeliveryParcel } from './fetchSourceAndDestinationParcels'

interface ChooseParcelsProps {
  groups: Record<
    number,
    {
      name: string
      id: number
    }
  >
  orderedGroupsIds: number[]
  orderedParcelIdsByGroup: Record<number, number[]>
  sourceParcelsByGroup: Record<number, Record<number, DeliveryParcel>>
  selectedParcels: Set<number>
  setSelectedParcels: (selectedParcels: Set<number>) => void
}
export const ChooseParcels = ({
  groups,
  sourceParcelsByGroup,
  setSelectedParcels,
  selectedParcels,
  orderedGroupsIds,
  orderedParcelIdsByGroup,
}: ChooseParcelsProps) => {
  const [expandedGroup, setExpandedGroup] = React.useState<number | undefined>()

  const handleExpandGroup = (groupId: number) => {
    if (expandedGroup === groupId) {
      setExpandedGroup(undefined)
    } else {
      setExpandedGroup(groupId)
    }
  }

  return (
    <Stack sx={{ my: 2 }}>
      {orderedGroupsIds.map((groupId) => {
        const groupParcels = orderedParcelIdsByGroup[groupId]
        if (!groupParcels || groupParcels.length <= 0) {
          return null
        }

        const group = groups[groupId]

        const anySelected = setHasAny(
          selectedParcels,
          ...(orderedParcelIdsByGroup[groupId] ?? [])
        )

        const allSelected = setHasAll(
          selectedParcels,
          ...(orderedParcelIdsByGroup[groupId] ?? [])
        )
        return (
          <Accordion
            TransitionProps={{
              unmountOnExit: true,
              appear: false,
              enter: false,
              exit: false,
            }}
            key={groupId}
            disableGutters
            color="primary"
            expanded={expandedGroup === groupId}
            onChange={() => handleExpandGroup(groupId)}
            sx={{ background: 'var(--background-light)' }}
          >
            <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
              <FormControlLabel
                label={group.name}
                onClick={(ev) => ev.stopPropagation()}
                control={
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation()
                      if (anySelected) {
                        setSelectedParcels(
                          setWithout(
                            selectedParcels,
                            ...(orderedParcelIdsByGroup[group.id] ?? [])
                          )
                        )
                      } else {
                        setSelectedParcels(
                          setWith(
                            selectedParcels,
                            ...(orderedParcelIdsByGroup[group.id] ?? [])
                          )
                        )
                      }
                    }}
                    checked={anySelected}
                    indeterminate={anySelected && !allSelected}
                  />
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ marginLeft: 3 }}>
              <FormGroup>
                {groupParcels.map((parcelId) => {
                  const sourceParcel = sourceParcelsByGroup[groupId][parcelId]

                  return (
                    <FormControlLabel
                      key={sourceParcel.parcel.id}
                      label={sourceParcel.parcel.name}
                      control={
                        <Checkbox
                          key={sourceParcel.parcel.id}
                          checked={selectedParcels.has(sourceParcel.parcel.id)}
                          onClick={() =>
                            setSelectedParcels(
                              setToggle(selectedParcels, sourceParcel.parcel.id)
                            )
                          }
                        />
                      }
                    />
                  )
                })}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Stack>
  )
}
