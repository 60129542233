import './StageStepper.scss'
import { Stepper, Step, StepLabel, StepIconProps } from '@mui/material'
import * as React from 'react'
import { classnames } from '../../util/classnames'
import { Check } from '@mui/icons-material'

interface StepData {
  content: string | React.ReactNode
}

interface Props {
  stages: StepData[]
  activeStages: number
}

const StepContent = ({active, completed}: StepIconProps) => {
  return <div className={classnames('stage-step', ['active', active], ['completed', completed])}>
      { active && <div className='stage-step-active-content'/> }
      { completed && <Check className='stage-step-complete-content'/> }
  </div>
}

const StageStepper = ({ stages: steps, activeStages: activeStep }: Props) => {
  return (
    <Stepper className="stage-stepper" activeStep={activeStep} alternativeLabel>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={StepContent}>{step.content}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export { StepData, StageStepper }
