import * as React from 'react'
import {
  MapSource,
  SamplePlanCreateDataSet,
  SamplingStatisticalMethodDataSource,
} from '../../../../../../graphql/types'
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useRedux } from '../../../../../../hooks/useRedux'
import { selectPreferredLanguage } from '../../../../../../data/selectPreferredLanguage'
import useAsync from '../../../../../../hooks/useAsync'
import { fetchDataSourceOptions } from '../../../../queries'
import { selectOrganizationId } from '../../../../../../data/selectOrganizationId'
import i18n, { keys } from '../../../../../../i18n'
import { Info } from '@mui/icons-material'

interface Props {
  statisticalMethodTypeId: number | string
  currentNewSamplingPlan: SamplePlanCreateDataSet
  dataSource: SamplingStatisticalMethodDataSource
  onDataSourceChanged: (
    name: string,
    value: any,
    dataSources: MapSource[]
  ) => void
  open?: boolean
  onToggle?: (open: boolean) => void
  setDataSourceWarningActive: (active: boolean) => void
}

export const SamplePlanSetUpFormDataSource = ({
  statisticalMethodTypeId,
  currentNewSamplingPlan,
  dataSource,
  onDataSourceChanged,
  setDataSourceWarningActive,
}: Props) => {
  const [state] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)
  const organizationId = selectOrganizationId(state)

  const parcelIds = React.useMemo(() => {
    return currentNewSamplingPlan?.selectedParcels?.map((p) => p.id)
  }, [currentNewSamplingPlan.selectedParcels])

  const [fetchedDataSourceOptions] = useAsync(fetchDataSourceOptions, [
    organizationId,
    parcelIds,
    dataSource.SamplingStatisticalMethodDataSourceFiles,
    dataSource.required,
  ])

  const dataSourceOptions = React.useMemo(() => {
    return fetchedDataSourceOptions?.result?.data ?? []
  }, [fetchedDataSourceOptions?.result?.data])

  const dataSources = React.useMemo(() => {
    return fetchedDataSourceOptions?.result?.dataSources ?? {}
  }, [fetchedDataSourceOptions?.result?.dataSources])

  const dataSourceWarning = React.useMemo(() => {
    return fetchedDataSourceOptions?.result?.inconsistentBlockData ?? false
  }, [fetchedDataSourceOptions?.result?.inconsistentBlockData])

  React.useEffect(() => {
    setDataSourceWarningActive(dataSourceWarning)
  }, [setDataSourceWarningActive, dataSourceWarning])

  React.useEffect(() => {
    if (
      currentNewSamplingPlan.flightDate &&
      currentNewSamplingPlan?.statisticalMethodTypeId ===
        statisticalMethodTypeId
    ) {
      onDataSourceChanged(
        dataSource.id,
        dataSourceOptions?.find(
          (dso) => dso.flightDate === currentNewSamplingPlan.flightDate
        ),
        dataSources?.[currentNewSamplingPlan.flightDate]
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNewSamplingPlan?.statisticalMethodTypeId])

  const setDataSource = (e: SelectChangeEvent<string>) => {
    if (!e.target.value || e.target.value === '') {
      return
    }

    onDataSourceChanged(
      e.target.name,
      dataSourceOptions?.find((dso) => dso.flightDate === e.target.value),
      dataSources?.[e.target.value]
    )
  }

  if (!currentNewSamplingPlan?.statisticalMethodTypeId) {
    return null
  }

  if (
    currentNewSamplingPlan.statisticalMethodTypeId !== statisticalMethodTypeId
  ) {
    return null
  }

  // if  there are no options and the data source is not required, return null
  if (
    (!dataSourceOptions || dataSourceOptions.length === 0) &&
    !dataSource.required
  ) {
    return null
  }

  switch (
    dataSource?.SamplingStatisticalMethodDataSourceFiles?.sort(
      (p) => p.priority
    )?.[0].fileType
  ) {
    case 'PresentPlant_VigorZones-Pro.shp.zip':
    case 'VigorZones-Pro.shp.zip':
    case 'VigorZones-Lite.shp.zip':
    case 'PureVine.shp.zip':
      return (
        <Stack direction="column" spacing={1} flexBasis="50%">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingRight={1}
            paddingLeft={1}
          >
            <Typography fontSize={12} fontWeight={400}>
              {dataSource.name[preferredLanguage]}
            </Typography>
            <Tooltip title={dataSource.description[preferredLanguage]}>
              <Info sx={{ width: 12, height: 12 }} />
            </Tooltip>
          </Stack>
          <FormControl size="small">
            <Select
              variant="outlined"
              name={dataSource.id}
              value={
                currentNewSamplingPlan.statisticalMethodDataSources?.[
                  dataSource.id
                ]?.flightDate ?? ''
              }
              required={dataSource.required}
              size="small"
              onChange={setDataSource}
              displayEmpty
              sx={{
                backgroundColor: 'var(--background2)',
              }}
            >
              <MenuItem key="none" disabled value="">
                <em style={{ color: 'grey' }}>
                  {i18n.t(keys.samplePlanNewSampleDataSourcePlaceholderLabel)}
                </em>
              </MenuItem>
              {dataSourceOptions?.map((st) => (
                <MenuItem key={st.flightDate} value={st.flightDate}>
                  {i18n.toDateShort(st.flightDate) ?? ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )
    default:
      return null
  }
}
