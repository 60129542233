export function setIntersect<T>(setA: Set<T>, setB: Set<T>) {
  const intersect = new Set<T>()
  for (const elem of Array.from(setA)) {
    if (setB.has(elem)) {
      intersect.add(elem)
    }
  }

  return intersect
}
