import gql from 'graphql-tag'
import { MaestroCronTaskType } from '../../../graphql/types'
import { client } from '../../../graphql/client'

const scheduleReprocessingJobQuery = gql`
  mutation INSERT_REPROCESSING_FOR_ORGS(
    $insert: [maestro_MaestroCronTask_insert_input!]!
    $update: maestro_MaestroCronTask_on_conflict
  ) {
    sent: insert_maestro_MaestroCronTask(
      objects: $insert
      on_conflict: $update
    ) {
      returning {
        organizationId
        taskName
        enabled
      }
    }
  }
`

export const scheduleReprocessingJob = async (
  organizationIds: number[],
  task: MaestroCronTaskType
) => {
  const result = await client.request<{
    sent: { organizationId: number; taskName: string }[]
  }>({
    query: scheduleReprocessingJobQuery,
    variables: {
      insert: organizationIds.map((oid) => ({
        organizationId: oid,
        taskName: task,
        enabled: true,
      })),
      update: {
        constraint: 'MaestroCronTasks_pkey',
        update_columns: ['enabled'],
      },
    },
  })

  return result.sent
}
