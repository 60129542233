import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Button } from '@mui/material'
import i18n, { keys } from '../../i18n'

interface Props {
  disabled?: boolean
}

class PrintButton extends React.PureComponent<Props> {
  render() {
    const { disabled } = this.props

    return (
      <React.Fragment>
        <Button disabled={disabled} onClick={window.print}>
          {i18n.t(keys.reports.print)}
        </Button>
        {disabled && <CircularProgress size={24} className="progress" />}
      </React.Fragment>
    )
  }
}

export default PrintButton
