import applyNamespace from 'redux-ts-helpers/lib/applyNamespace'
import createAction from 'redux-ts-helpers/lib/createAction'

export const constants = applyNamespace('app', {
  toggleSettingDialog: 0,
  setOrgSwitchDialogOpen: 0,
  setHelpDialogOpen: 0,
})

export const toggleSettingDialog = createAction<null>(
  constants.toggleSettingDialog
)

export const setOrgSwitchDialogOpen = createAction<boolean>(
  constants.setOrgSwitchDialogOpen
)
export const setHelpDialogOpen = createAction<boolean>(
  constants.setHelpDialogOpen
)
