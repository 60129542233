import * as React from 'react'
import useAsync, { AsyncResult } from '../hooks/useAsync'
import { useRedux } from '../hooks/useRedux'
import { client, gql } from '../graphql/client'
import { indexArray } from '../util/indexArray'
import { FeatureFlag } from '../graphql/types'
import { selectGetMe } from '../data/selectMe'
import { useDeferredValue } from '../hooks/useDeferredValue'
import { LoadingPage } from '../pages/LoadingPage'

const FEATURE_FLAG_FORMS: AsyncResult<Record<string, FeatureFlag>>[0] = {
  status: 'pending',
}
const NOOP = () => {}

export const FeatureFlagContext = React.createContext<
  AsyncResult<Record<string, FeatureFlag>>
>([FEATURE_FLAG_FORMS, NOOP, false])

export const useFeatureFlagsContext = () => React.useContext(FeatureFlagContext)

export const FeatureFlagProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state] = useRedux()

  const isLoggedIn = state?.login?.isLoggedIn

  const meSelector = selectGetMe(state)

  const templateAsyncResult = useAsync(
    async (_, skip) => {
      if (meSelector.status === 'pending') {
        skip()
        return undefined
      }

      if (!isLoggedIn || !meSelector.data) {
        return {}
      }

      const { featureFlags } = await client.request<{
        featureFlags: FeatureFlag[]
      }>({
        query: gql`
          query GET_FEATURE_FLAGS {
            featureFlags: FeatureFlag {
              id
              active
              name
              desc
              createdAt
              FeatureFlagUserAccesses {
                featureFlagId
                userId
              }
              FeatureFlagOrganizationAccesses {
                featureFlagId
                organizationId
                Organization {
                  id
                }
              }
            }
          }
        `,
      })

      return indexArray(featureFlags, 'id')
    },
    [],
    [meSelector.status, isLoggedIn]
  )

  const ready = useDeferredValue(templateAsyncResult[0].status === 'resolved')

  return (
    <FeatureFlagContext.Provider value={templateAsyncResult}>
      {ready ? children : <LoadingPage />}
    </FeatureFlagContext.Provider>
  )
}
