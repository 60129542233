import { createSelector } from 'reselect'
import { selectFilteredMapSourcesById } from './selectFilteredMapSourcesById'
import { RootStore } from '../redux/types'
import { MapSourcePropertyData } from './types'
import { keys } from '../i18n/keys'
import i18n from '../i18n'

const emptyProperties: MapSourcePropertyData[] = []

const getFocusedPolygon = (state: RootStore) => {
  return state.postgis.focusedPolygon
}

const getFocusedPoint = (state: RootStore) => {
  return state.postgis.focusedPoint
}

export const selectFocusedPolygonMapSourcesProperties = createSelector(
  selectFilteredMapSourcesById,
  getFocusedPolygon,
  (mapSourcesById, focusedPolygon) => {
    if (!focusedPolygon) {
      return emptyProperties
    }

    const { mapSourceId } = focusedPolygon.properties
    const mapSource = mapSourcesById[mapSourceId]

    if (mapSource?.mapSourceDef) {
      const mapSourceDefProperties = mapSource.mapSourceDef.properties.filter(
        (p) => p.showInPopup
      )
      // Special property range is added after the fact.
      // Does not relate to a data property and is instead produced on click.
      if (focusedPolygon.properties.range) {
        return [
          ...mapSourceDefProperties,
          {
            name: i18n.t(keys.range),
            valueType: 'text',
            type: 'value',
            property: 'range',
            showInPopup: true,
          },
        ]
      }
      return mapSourceDefProperties
    }

    return emptyProperties
  }
)

export const selectFocusedPointMapSourcesProperties = createSelector(
  selectFilteredMapSourcesById,
  getFocusedPoint,
  (mapSourcesById, getFocusedPoint) => {
    if (!getFocusedPoint) {
      return emptyProperties
    }

    const { mapSourceId } = getFocusedPoint.properties
    const mapSource = mapSourcesById[mapSourceId]

    if (mapSource?.mapSourceDef) {
      const mapSourceDefProperties = mapSource.mapSourceDef.properties.filter(
        (p) => p.showInPopup
      )

      return mapSourceDefProperties
    }

    return emptyProperties
  }
)
