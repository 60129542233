export const parseMetaArray = (arr: string) => {
  if (Array.isArray(arr)) {
    return arr
  }
  const match = arr.match(/^\(\d+:(.*)\)$/)

  if (match) {
    const [, values] = match

    return values.split(',').map((value) => value.trim())
  }

  return [arr.trim()]
}
