import { createSelector } from 'reselect'
import { selectOrganizationsById } from '../../data/selectOrganizations'
import { RootStore } from '../../redux/types'

const getOrganizationId = (state: RootStore) =>
  state.router.params.organizationId

export const selectOrganization = createSelector(
  selectOrganizationsById,
  getOrganizationId,
  (organizationsById, organizationId) => {
    if (!organizationsById || !organizationId) {
      return
    }

    return organizationsById[organizationId]
  }
)
