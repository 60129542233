import * as React from 'react'
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import i18n, { keys } from '../../../../../../i18n'
import { SamplePlanNewFormProps } from '../../types/SamplePlanFormProps'
import { Info } from '@mui/icons-material'
import { calc } from '../../../../../../util/calc'
import useAsync from '../../../../../../hooks/useAsync'
import { getSampleQualityByBlock } from '../../../../queries'
import { selectMe } from '../../../../../../data/selectMe'
import { useRedux } from '../../../../../../hooks/useRedux'
import { selectOrganizationId } from '../../../../../../data/selectOrganizationId'
import { BlockSampleData } from '../../../../../../graphql/types'
import { useDebounce } from '../../../../../../hooks/useDebounce'
import { SampleQuality } from '../../types/SampleQuality'

type Ref = HTMLDivElement

export const SAMPLE_PROPORTION = 0.5
export const Z_SCORE = 1.282 // 80% confidence level
const DEFAULT_SAMPLE_COUNT = 10
const MAX_SAMPLE_COUNT = 99

// Reintroduce this when we want to show the quality of the samples
// const sampleQualityColours = {
//   none: 'inherit',
//   low: '#FF0D0D',
//   fair: '#FF8E15',
//   good: '#FAB733',
//   excellent: '#69B34C',
// }

interface BlurredStackProps {
  isBlurred?: boolean
}

const BlurredStack = styled(Stack)<BlurredStackProps>(({ isBlurred }) => ({
  filter: isBlurred ? 'blur(2px)' : 'none',
}))

const SamplePlanProtocolForm = ({
  currentNewSamplingPlan,
  setCurrentNewSamplingPlan,
  forwardedRef,
  registerValidation,
  invalidateCompletedSamplingPlan,
  stage,
}: SamplePlanNewFormProps<Ref>) => {
  const [state] = useRedux()
  const [blockSampleData, setBlockSampleData] = React.useState<
    BlockSampleData[]
  >(currentNewSamplingPlan?.blockSamplesData ?? [])
  const [all, setAll] = React.useState<number>(DEFAULT_SAMPLE_COUNT)
  const [sameForAllMode, setSameForAllMode] = React.useState(false)
  const organizationId = selectOrganizationId(state)
  const me = selectMe(state)
  const [previousSampleCountsAndQuality, setPreviousSampleCountsAndQuality] =
    React.useState<Record<string, [number, SampleQuality | null]>>({})

  const currentlyLoadingSampleQualityByBlock = React.useMemo(() => {
    return {
      ...currentNewSamplingPlan,
      blockSamplesData: currentNewSamplingPlan?.blockSamplesData?.filter(
        (block) => {
          const previousCount =
            previousSampleCountsAndQuality?.[block.parcelId]?.[0]
          return (
            previousCount === undefined || previousCount !== block.sampleCount
          )
        }
      ),
    }
  }, [previousSampleCountsAndQuality, currentNewSamplingPlan])

  const [fetchedSampleQualityByBlock] = useAsync(getSampleQualityByBlock, [
    currentlyLoadingSampleQualityByBlock,
    organizationId,
    me?.id,
    previousSampleCountsAndQuality,
  ])

  const sampleQualityByBlock = React.useMemo(
    () =>
      fetchedSampleQualityByBlock.result?.data ??
      ({} as Record<string, SampleQuality>),
    [fetchedSampleQualityByBlock.result?.data]
  )

  React.useEffect(() => {
    if (!currentNewSamplingPlan?.blockSamplesData || !sampleQualityByBlock) {
      return
    }

    const sampleCounts = currentNewSamplingPlan.blockSamplesData.reduce(
      (acc, block) => {
        acc[block.parcelId] = [
          block.sampleCount,
          sampleQualityByBlock[block.parcelId] ?? null,
        ]
        return acc
      },
      {} as Record<string, [number, SampleQuality | null]>
    )

    setPreviousSampleCountsAndQuality(sampleCounts)
    // eslint-disable-next-line
  }, [sampleQualityByBlock])

  const averageSampleQuality = React.useMemo(() => {
    const values = Object.values(sampleQualityByBlock)
    if (values.length === 0) {
      return null
    }
    const marginOfErrorSum = values.reduce((acc, value) => {
      if (!value?.marginOfError) {
        return NaN
      }
      return acc + value.marginOfError
    }, 0)
    const jsDivergenceSum = values.reduce((acc, value) => {
      if (!value?.divergence) {
        return NaN
      }
      return acc + value.divergence
    }, 0)

    return {
      marginOfError: !isNaN(marginOfErrorSum) ? marginOfErrorSum / values.length : undefined,
      jsDivergence: !isNaN(jsDivergenceSum) ? jsDivergenceSum / values.length : undefined,
    }
  }, [sampleQualityByBlock])

  const debounceUpdateNewSamplingPlanBlockSampleData = useDebounce(
    async (blockSamplesData: BlockSampleData[]) => {
      setCurrentNewSamplingPlan({
        ...currentNewSamplingPlan,
        blockSamplesData,
      })
      await invalidateCompletedSamplingPlan()
    },
    700
  )

  const onSameForAllSampleCountChanged = async (value: number) => {
    if (isNaN(value) || value > MAX_SAMPLE_COUNT) {
      return
    }

    setAll(value)
    const updatedBlockSampleData = selectedParcels.map((p) => ({
      parcelId: p.id,
      sampleCount: Number(value),
    }))
    // update locally...
    setBlockSampleData(updatedBlockSampleData)

    // ...then update the sampling plan after deboucing.
    debounceUpdateNewSamplingPlanBlockSampleData(updatedBlockSampleData)
  }

  const onSampleCountChanged = async (value: number, parcelId: string) => {
    if (isNaN(value) || value > MAX_SAMPLE_COUNT) {
      return
    }

    const selectedBlockSample = blockSampleData.find(
      (cbs) => cbs.parcelId === parcelId
    )

    // update the sample count if it exists, otherwise add a new one
    const updatedBlockSampleData = !selectedBlockSample
      ? [...blockSampleData, { parcelId, sampleCount: value }]
      : blockSampleData.map((cbs) => {
          if (cbs.parcelId === parcelId) {
            cbs.sampleCount = value
          }
          return cbs
        })

    setBlockSampleData([...updatedBlockSampleData])

    debounceUpdateNewSamplingPlanBlockSampleData(updatedBlockSampleData)
  }

  const onChangeSelectAllMode = (checked: boolean) => {
    setSameForAllMode(checked)
    onSameForAllSampleCountChanged(all ?? 0)
  }

  const selectedParcels = React.useMemo(
    () => currentNewSamplingPlan?.selectedParcels ?? [],
    [currentNewSamplingPlan?.selectedParcels]
  )

  React.useEffect(() => {
    if (!!currentNewSamplingPlan?.blockSamplesData) {
      return
    }

    const defaultBlockSamplesData = selectedParcels.map((p) => ({
      parcelId: p.id,
      sampleCount: Number(DEFAULT_SAMPLE_COUNT),
    }))

    setBlockSampleData(defaultBlockSamplesData)

    setCurrentNewSamplingPlan({
      ...currentNewSamplingPlan,
      blockSamplesData: defaultBlockSamplesData,
    })
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    registerValidation(() => {
      return (
        !!currentNewSamplingPlan?.blockSamplesData &&
        currentNewSamplingPlan.blockSamplesData.some(
          (bs) => bs.sampleCount !== 0
        )
      )
    }, stage)
  }, [registerValidation, stage, currentNewSamplingPlan])

  React.useEffect(() => {
    setPreviousSampleCountsAndQuality({})
  }, [])

  return (
    <Stack direction="column" spacing={2} ref={forwardedRef}>
      <Stack direction="column" style={{ minHeight: '350px' }} spacing={2}>
        <Typography variant="h6">
          {i18n.t(keys.samplePlanNewSampleNumberPerBlock)}
        </Typography>

        <Stack
          direction="column"
          sx={{
            backgroundColor: 'var(--background2)',
            borderRadius: '4px',
            padding: '0px 10px',
          }}
        >
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={sameForAllMode}
                  onChange={(_, checked) => onChangeSelectAllMode(checked)}
                />
              }
              label={i18n.t(keys.samplePlanNewSameForAll)}
            />
            <TextField
              disabled={!sameForAllMode}
              variant="standard"
              sx={{ width: '20px' }}
              onChange={async (e) =>
                await onSameForAllSampleCountChanged(Number(e.target.value))
              }
              value={all ?? DEFAULT_SAMPLE_COUNT}
            />
          </Stack>
          <Collapse in={sameForAllMode}>
            {/* if loading for the first time, show the loading spinner */}
            {Object.keys(previousSampleCountsAndQuality).length === 0 ? (
              <Stack>
                <CircularProgress size={10} />
              </Stack>
            ) : null}
            <Stack direction="column" spacing={1}>
              {averageSampleQuality?.marginOfError && (
                <Stack direction="column" sx={{ padding: '5px 0px' }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography fontSize={12}>
                      {`${i18n.t(keys.generic.average)} ${i18n.t(
                        keys.marginOfError
                      )}`}
                    </Typography>
                    <Tooltip
                      arrow
                      placement="top-start"
                      title={
                        <Typography variant="body1" fontSize={12}>
                          {i18n.t(keys.samplePlanNewMarginOfErrorTooltip)}
                        </Typography>
                      }
                    >
                      <Info sx={{ width: '16px', height: '16px' }} />
                    </Tooltip>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <BlurredStack
                      isBlurred={
                        (currentlyLoadingSampleQualityByBlock?.blockSamplesData
                          ?.length ?? -1) > 0
                      }
                    >
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          sx={{ opacity: 0.7 }}
                        >{`${calc.roundToDecimalPlace(
                          averageSampleQuality?.marginOfError ?? 0
                        )}%`}</Typography>
                      </Stack>
                    </BlurredStack>
                    {(currentlyLoadingSampleQualityByBlock?.blockSamplesData
                      ?.length ?? -1) > 0 ? (
                      <Stack>
                        <CircularProgress size={10} />
                      </Stack>
                    ) : null}
                  </Stack>
                </Stack>
              )}
              {averageSampleQuality?.jsDivergence && (
                <Stack direction="column" sx={{ padding: '5px 0px' }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography fontSize={12}>
                      {`${i18n.t(keys.generic.average)} ${i18n.t(
                        keys.divergence
                      )}`}
                    </Typography>
                    <Tooltip
                      arrow
                      placement="top-start"
                      title={
                        <Typography variant="body1" fontSize={12}>
                          {i18n.t(keys.samplePlanNewDivergenceTooltip)}
                        </Typography>
                      }
                    >
                      <Info sx={{ width: '16px', height: '16px' }} />
                    </Tooltip>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <BlurredStack
                      isBlurred={
                        (currentlyLoadingSampleQualityByBlock?.blockSamplesData
                          ?.length ?? -1) > 0
                      }
                    >
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          sx={{ opacity: 0.7 }}
                        >{`${calc.roundToDecimalPlace(
                          averageSampleQuality?.jsDivergence ?? 0,
                          3
                        )}`}</Typography>
                      </Stack>
                    </BlurredStack>
                    {(currentlyLoadingSampleQualityByBlock?.blockSamplesData
                      ?.length ?? -1) > 0 ? (
                      <Stack>
                        <CircularProgress size={10} />
                      </Stack>
                    ) : null}
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Collapse>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            height: '255px',
            padding: '0px 10px',
          }}
        >
          {selectedParcels.map((p) => (
            <Stack key={p.id}>
              <Stack
                direction="row"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="overline" fontSize={14}>
                  {p.name}
                </Typography>
                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{ alignSelf: 'center', flexGrow: 1, margin: '0px 10px' }}
                />
                <TextField
                  disabled={sameForAllMode}
                  variant="standard"
                  sx={{ width: '20px' }}
                  onChange={async (e) =>
                    await onSampleCountChanged(Number(e.target.value), p.id)
                  }
                  value={
                    blockSampleData?.find((bs) => bs.parcelId === p.id)
                      ?.sampleCount ?? DEFAULT_SAMPLE_COUNT
                  }
                />
              </Stack>
              <Collapse in={!sameForAllMode}>
                {/* if loading for the first time, show the loading spinner */}
                {Object.keys(previousSampleCountsAndQuality).length === 0 ? (
                  <Stack>
                    <CircularProgress size={10} />
                  </Stack>
                ) : null}
                <Stack direction="column" spacing={1}>
                  {sampleQualityByBlock[p.id]?.marginOfError && (
                    <Stack direction="column">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography fontSize={12}>
                          {i18n.t(keys.marginOfError)}
                        </Typography>
                        <Tooltip
                          arrow
                          placement="top-start"
                          title={i18n.t(keys.samplePlanNewMarginOfErrorTooltip)}
                        >
                          <Info sx={{ width: '16px', height: '16px' }} />
                        </Tooltip>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <BlurredStack
                          isBlurred={
                            !!currentlyLoadingSampleQualityByBlock?.blockSamplesData?.find(
                              (bsd) => bsd.parcelId === p.id
                            )
                          }
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              fontSize={12}
                              fontWeight={400}
                              sx={{ opacity: 0.7 }}
                            >{`${calc.roundToDecimalPlace(
                              sampleQualityByBlock[p.id]?.marginOfError ?? 0
                            )}%`}</Typography>
                          </Stack>
                        </BlurredStack>
                        {currentlyLoadingSampleQualityByBlock?.blockSamplesData?.find(
                          (bsd) => bsd.parcelId === p.id
                        ) ? (
                          <Stack>
                            <CircularProgress size={10} />
                          </Stack>
                        ) : null}
                      </Stack>
                    </Stack>
                  )}
                  {sampleQualityByBlock[p.id]?.divergence && (
                    <Stack direction="column">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography fontSize={12}>
                          {i18n.t(keys.divergence)}
                        </Typography>
                        <Tooltip
                          arrow
                          placement="top-start"
                          title={i18n.t(keys.samplePlanNewDivergenceTooltip)}
                        >
                          <Info sx={{ width: '16px', height: '16px' }} />
                        </Tooltip>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <BlurredStack
                          isBlurred={
                            !!currentlyLoadingSampleQualityByBlock?.blockSamplesData?.find(
                              (bsd) => bsd.parcelId === p.id
                            )
                          }
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              fontSize={12}
                              fontWeight={400}
                              sx={{ opacity: 0.7 }}
                            >{`${calc.roundToDecimalPlace(
                              sampleQualityByBlock[p.id]?.divergence ?? 0,
                              3
                            )}`}</Typography>
                          </Stack>
                        </BlurredStack>
                        {currentlyLoadingSampleQualityByBlock?.blockSamplesData?.find(
                          (bsd) => bsd.parcelId === p.id
                        ) ? (
                          <Stack>
                            <CircularProgress size={10} />
                          </Stack>
                        ) : null}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Collapse>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default React.forwardRef<Ref, SamplePlanNewFormProps<Ref>>(
  (props, ref) => <SamplePlanProtocolForm {...props} forwardedRef={ref} />
)
