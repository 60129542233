import * as React from 'react'

import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { selectGroupDatesByGroupIdAndDeliveryId } from '../data/selectOrgMapData'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import { setIntersect } from '../util/setIntersect'
import {
  fetchFilenamesWithDescriptions,
  FilenameWithDescription,
} from './fetchFilenamesWithDescriptions'
import { SkipOrReplace } from './ManageDuplicateDownloadsDialog'
import { SettingsBase } from './types'

export interface DataDownloadSettings extends SettingsBase {
  type: 'rawData'
  selectedFilenames: Set<string>
  skipOrReplace?: SkipOrReplace
  mergeGroups?: boolean
  keepUnmergedFiles?: boolean
}

interface Props {
  selectedGroups: Set<string>
  selectedDeliveries: Set<string>
  settings: DataDownloadSettings
  updateSettings: (settings: DataDownloadSettings) => void
  updateName: (name: string) => void
  setIsLoading: (isLoading: boolean) => void
  name: string
}

const filenameContainsDenyList = ['Parcel_Stats', 'vv-parcel.geojson', 'aux']

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      fontSize: 12,
      fontWeight: 400,
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),

      '& .MuiChip-label': {
        padding: `0 ${theme.spacing(1)} 0 0`,
      },
      '& .MuiIcon-root': {
        marginRight: 0,
        fontSize: 18,
      },
    },
    inputLabel: {
      fontWeight: 500,
      fontSize: 16,
      left: -16,
    },
  })
)

export const DataDownloadSettingsForm = ({
  name,
  updateName,
  selectedGroups,
  selectedDeliveries,
  updateSettings,
  settings,
  setIsLoading,
}: Props) => {
  const theme = useTheme()
  const classes = useStyles()

  const { selectedFilenames } = settings
  const [state] = useRedux()

  const preferredLanguage = selectPreferredLanguage(state)

  const groupDatesByGroupIdAndDeliveryId =
    selectGroupDatesByGroupIdAndDeliveryId(state)

  const [availableFilenames, setAvailableFilenames] = React.useState<
    FilenameWithDescription[]
  >([])
  const [selectedFilenameString, setSelectedFilenameString] = React.useState<
    string | undefined
  >(undefined)

  const handleDelete = (ev: React.MouseEvent, value: string) => {
    const updatedSelectedFilenames = new Set(selectedFilenames)
    updatedSelectedFilenames.delete(value)
    updateSettings({ ...settings, selectedFilenames: updatedSelectedFilenames })
  }

  React.useEffect(() => {
    setIsLoading(true)

    const filenames = Array.from(selectedGroups).reduce(
      (curr, group, index) => {
        const groupFiles = new Set<string>()
        for (const deliveryId of Array.from(selectedDeliveries)) {
          const groupDates =
            groupDatesByGroupIdAndDeliveryId[`${group}/${deliveryId}`]
          if (groupDates !== undefined) {
            for (const groupDate of groupDates) {
              for (let filename of groupDate.enabledFilenames) {
                if (!filename.endsWith('.zip')) {
                  filename += '.zip'
                }
                if (
                  filenameContainsDenyList.some((denyContains) =>
                    filename.includes(denyContains)
                  )
                ) {
                  continue
                }

                groupFiles.add(filename)
              }
            }
          }
        }

        if (index === 0) {
          return groupFiles
        }

        return setIntersect(curr, groupFiles)
      },
      new Set<string>()
    )

    fetchFilenamesWithDescriptions(Array.from(filenames), preferredLanguage)
      .then((filenamesWithDescriptions) => {
        const filenameMap = new Map()

        for (const filenameWithDescription of filenamesWithDescriptions) {
          filenameMap.set(
            filenameWithDescription.filename,
            filenameWithDescription
          )
        }

        for (const filename of Array.from(filenames)) {
          if (!filenameMap.has(filename)) {
            filenameMap.set(filename, { filename })
          }
        }

        setAvailableFilenames(Array.from(filenameMap.values()))

        updateSettings({
          ...settings,
          selectedFilenames: setIntersect(
            selectedFilenames ?? new Set(),
            filenames
          ),
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    selectedGroups,
    selectedDeliveries,
    groupDatesByGroupIdAndDeliveryId,
    preferredLanguage,
    setIsLoading,
    updateSettings,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setSelectedFilenameString(
      Array.from(selectedFilenames ?? [])
        .map((filename) =>
          i18n.t(`filenames.${filename}`, { defaultValue: filename })
        )
        .join(', ')
    )
  }, [selectedFilenames])

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateName(e.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {i18n.t(keys.rawDataDownloadSettings)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            label={i18n.t(keys.name)}
            required={true}
            value={name}
            onChange={handleNameInputChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: theme.spacing(2) }}>
          <InputLabel color="primary" sx={{ left: `-${theme.spacing(2)}` }}>
            {i18n.t(keys.file)}:{' '}
          </InputLabel>
          <Select
            disabled={!availableFilenames.length}
            multiple={true}
            value={Array.from(selectedFilenames ?? [])}
            renderValue={() => (
              <div className={classes.chips}>
                {selectedFilenameString?.split(', ').map((filename: string) => (
                  <Chip
                    key={filename}
                    label={filename}
                    clickable
                    color="primary"
                    deleteIcon={
                      <Icon
                        onMouseDown={(
                          evt: React.MouseEvent<HTMLButtonElement>
                        ) => evt.stopPropagation()}
                      >
                        cancel
                      </Icon>
                    }
                    onDelete={(evt: React.MouseEvent<HTMLButtonElement>) =>
                      handleDelete(evt, filename)
                    }
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            onChange={(ev) =>
              updateSettings({
                ...settings,
                selectedFilenames: new Set(ev.target.value),
              })
            }
          >
            {availableFilenames?.map(({ filename, description }) => (
              <MenuItem key={filename} value={filename}>
                <Checkbox checked={selectedFilenames?.has(filename)} />
                <ListItemText
                  primary={i18n.t(`filenames.${filename}`, {
                    defaultValue: filename,
                  })}
                  secondary={description}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: theme.spacing(2) }}>
          <InputLabel color="primary" sx={{ left: `-${theme.spacing(2)}` }}>
            {i18n.t(keys.downloadOutputTypeLabel)}:{' '}
          </InputLabel>
          <Select
            defaultValue={0}
            onChange={(e) =>
              updateSettings({
                ...settings,
                mergeGroups: e.target.value === 1 || e.target.value === 2,
                keepUnmergedFiles: e.target.value === 2,
              })
            }
          >
            <MenuItem value={0}>
              {i18n.t(keys.downloadBlockByBlockLabel)}
            </MenuItem>
            <MenuItem value={1}>
              {i18n.t(keys.downloadMergeGroupsLabel)}
            </MenuItem>
            <MenuItem value={2}>{i18n.t(keys.downloadBothLabel)}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
