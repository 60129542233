import * as React from 'react'
import SelectionMap from './SelectionMap'

interface Props {
  variant: 'delivery' | 'order'
  selectedParcelIds: string[]
  zoomToSelection?: { groupId: string } | { parcelId: string }
}

class SplitSelectionMap extends React.PureComponent<Props> {
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          id="EditMapDelivery"
          style={{
            height: '90vh',
            overflowY: 'scroll',
          }}
        >
          {this.props.children}
        </div>
        <div
          style={{
            flex: 1,
            minWidth: 100,
            minHeight: 100,
            height: '90vh',
            position: 'relative',
          }}
        >
          <SelectionMap {...this.props} />
        </div>
      </div>
    )
  }
}

export default SplitSelectionMap
