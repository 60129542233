import { client, gql } from '../../../graphql/client'

export const updateGroupName = (id: number, name: string) =>
  client.request({
    query: gql`
      mutation UpdateGroupName($id: Int!, $name: String!) {
        update_OrganizationGroup_by_pk(
          pk_columns: { id: $id }
          _set: { name: $name }
        ) {
          id
          name
        }
      }
    `,
    variables: { id, name },
  })
