import * as React from 'react'

export class Row extends React.PureComponent<{
  style?: React.CSSProperties
  className?: string
  onClick?: (ev: React.SyntheticEvent) => void
}> {
  render() {
    return (
      <div
        onClick={this.props.onClick}
        className={this.props.className}
        style={{
          alignItems: 'center',
          justifyContent: 'start',
          ...this.props.style,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {this.props.children}
      </div>
    )
  }
}
