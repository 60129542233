import { LanguageSelection } from '../../app/types'
import { selectPagingInfo } from '../../appNavigation/urls'
import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import * as api from '../../graphql/api'
import { Translated } from '../../graphql/types'
import { RootStore } from '../../redux/types'

export interface ListPackageData {
  id: string
  name: Translated
  description?: Translated
  date: string
  code: string
  Package_MapLayerDefs: {
    MapLayerDef: {
      id: string
    }
  }[]
  CreatedBy: {
    firstName: string
  }
  createdAt: string
  UpdatedBy: {
    firstName: string
  }
  updatedAt: string
}

const list = ({
  limit,
  offset,
  lang,
}: {
  limit: number
  offset: number
  lang: LanguageSelection
}) =>
  api.productPackage.list<ListPackageData>({
    limit,
    offset,
    returning: `{
      id
      name(path: "${lang}")
      code
      CreatedBy {
        firstName
      }
      createdAt
      updatedAt
    }`,
  })

const { selector: selectListPackages, refresh: refreshListPackages } =
  createAsyncSelector({
    resource: 'ListPackageData',
    inputs: {
      lang: selectPreferredLanguage,
      searchParams: (state: RootStore) => state.router.searchParams,
      pagingInfo: selectPagingInfo,
    },
    fetcher: async ({ lang, searchParams, pagingInfo: { page, pageSize } }) => {
      const offset = page * pageSize
      const limit = pageSize

      const { data, info: { count = 0 } = {} } = await list({
        offset,
        limit,
        lang,
      })

      if (!data) {
        throw new Error('No Data')
      }

      return {
        data,
        info: { count, page, pageSize },
      }
    },
  })

export { selectListPackages, refreshListPackages }
