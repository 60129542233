import { GetMeData } from '../../data/selectMe'
import { client, gql } from '../../graphql/client'

interface DeliveryProcGroup {
  organization: {
    name: string
  }
  groupName: string
  procGroup: string
  deliveryId: string
  qaStatus: 'not-reviewed' | 'failed' | 'complete'
}

const EMPTY_PROC_GROUPS: DeliveryProcGroup[] = []

export const fetchMyQueue = async (
  [user, page, pageSize]: [GetMeData | undefined, number, number],
  skip: () => void
) => {
  if (!user) {
    return skip()
  }

  if (user.roles.length === 0) {
    return { procGroups: EMPTY_PROC_GROUPS, count: 0 }
  }

  const offset = page * pageSize
  const limit = pageSize

  const {
    procGroups,
    Delivery_ProcGroup_aggregate: {
      aggregate: { count },
    },
  } = await client.request<{
    procGroups: DeliveryProcGroup[]
    Delivery_ProcGroup_aggregate: { aggregate: { count: number } }
  }>({
    query: gql`
      query GET_MY_QUEUE($userId: Int!, $offset: Int!, $limit: Int!) {
        procGroups: Delivery_ProcGroup(
          where: {
            assignee: { _eq: $userId }
            _or: [
              { KatoLineQAJob: { Status: { status: { _eq: "queued" } } } }
              { qaStatus: { _eq: "failed" } }
            ]
          }
          order_by: { Delivery: { Order: { priority: desc } } }
          offset: $offset
          limit: $limit
        ) {
          organization: Organization {
            name
          }
          deliveryId
          procGroup
          groupName
          qaStatus
        }
        Delivery_ProcGroup_aggregate(
          where: {
            assignee: { _eq: $userId }
            _or: [
              { KatoLineQAJob: { Status: { status: { _eq: "queued" } } } }
              { qaStatus: { _eq: "failed" } }
            ]
          }
          order_by: { Delivery: { Order: { priority: desc } } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: { userId: user.id, offset, limit },
  })

  return { procGroups, count }
}
