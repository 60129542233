export const indexArrayMappedKey = <
  T extends Record<any, any>,
  K extends (x: T) => string = (x: T) => string,
  M extends (x: T) => any = (x: T) => T
>(
  results: T[],
  key: K,
  map?: M,
  include?: (m: ReturnType<M>, x: T) => boolean
) =>
  results.reduce((record, x) => {
    const id = key(x)
    const mapped = map ? map(x) : x

    if (!include || include(mapped, x)) {
      record[id] = mapped
    }

    return record
  }, {} as Record<ReturnType<K>, ReturnType<M>>)

export const indexMultiArrayMappedKey = <
  T extends Record<any, any>,
  K extends (x: T) => string = (x: T) => string,
  M extends (x: T) => any = (x: T) => T
>(
  results: T[],
  key: K,
  map?: M,
  include?: (m: ReturnType<M>, x: T) => boolean
) =>
  results.reduce((record, x) => {
    const id = key(x)
    const mapped = map ? map(x) : x

    if (!include || include(mapped, x)) {
      const arr = (record[id] = record[id] ?? [])
      arr.push(mapped)
    }

    return record
  }, {} as Record<ReturnType<K>, ReturnType<M>[]>)

export const indexMultiArrayMappedKeyAsSet = <
  T extends Record<any, any>,
  K extends (x: T) => string = (x: T) => string,
  M extends (x: T) => any = (x: T) => T
>(
  results: T[],
  key: K,
  map?: M,
  include?: (m: ReturnType<M>, x: T) => boolean
) =>
  results.reduce((record, x) => {
    const id = key(x)
    const mapped = map ? map(x) : x

    if (!include || include(mapped, x)) {
      const set = (record[id] = record[id] || new Set())
      set.add(mapped)
    }

    return record
  }, {} as Record<ReturnType<K>, Set<ReturnType<M>>>)

export const indexArray = <
  T extends Record<any, any>,
  K extends keyof T,
  M extends (x: T) => any = (x: T) => T
>(
  results: T[],
  key: K,
  map?: M,
  include?: (m: ReturnType<M>, x: T) => boolean
) => indexArrayMappedKey(results, (x) => x[key], map, include)

export const indexMultiArray = <
  T extends Record<any, any>,
  K extends keyof T,
  M extends (x: T) => any = (x: T) => T
>(
  results: T[],
  key: K,
  map?: M,
  include?: (m: ReturnType<M>, x: T) => boolean
) => indexMultiArrayMappedKey(results, (x) => x[key], map, include)

export const indexMultiArrayAsSet = <
  T extends Record<any, any>,
  K extends keyof T,
  M extends (x: T) => any = (x: T) => T
>(
  results: T[],
  key: K,
  map?: M,
  include?: (m: ReturnType<M>, x: T) => boolean
) => indexMultiArrayMappedKeyAsSet(results, (x) => x[key], map, include)
