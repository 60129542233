import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { updatePreferences } from '../../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../../redux/types'
import ListItemCheckbox from '../../UI/ListItemCheckbox'

class LayerDrawerGPSToggle extends React.PureComponent<
  ReduxProps & AppDispatchProps
> {
  render() {
    return (
      <ListItem button onClick={this.toggle}>
        <ListItemCheckbox checked={this.props.isEnabled} />
        <ListItemText primary={i18n.t(keys.showLatLon)} />
      </ListItem>
    )
  }

  toggle = () => {
    this.props.dispatch(updatePreferences({ showGPS: !this.props.isEnabled }))
  }
}

const mapState = (state: RootStore) => ({
  isEnabled: state.preferences.showGPS,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  LayerDrawerGPSToggle
)
