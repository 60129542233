import { AnyAction, DeepPartial } from 'redux'
import { RootStore } from './types'

export const APPLY_SETTINGS = 'APPLY_SETTINGS'
export const FORCE_LOGOUT = 'FORCE_LOGOUT'

/**
 * Applies stored redux state, saved as settings on the server.
 */
export const applySettings = (state: DeepPartial<RootStore>): AnyAction => ({
  state,
  type: APPLY_SETTINGS,
})

export const forceLogout = (url?: string): AnyAction => {
  if (url) {
    window.location.hash = url
  }

  return {
    type: FORCE_LOGOUT,
  }
}
