import * as gravatar from 'gravatar'
import MUIRichTextEditor, { TMUIRichTextEditorRef } from 'mui-rte'
import * as React from 'react'
import * as uuid from 'uuid'
import { ContentState, EditorState, Modifier, convertToRaw } from 'draft-js'
import { AddReaction } from '@mui/icons-material'
import {
  Avatar,
  Button,
  InputBaseComponentProps,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Popover,
  Stack,
} from '@mui/material'

import { useRedux } from '../../hooks/useRedux'
import { selectAssignableUsers } from '../../pages/Organization/Delivery/ProcessingGroups/selectAssignableUsers'
import { commentFieldDecorators } from './commentFieldDecorators'
import EmojiPicker from './EmojiPicker'
import { Emoji, emojis } from './emojis'

interface Props {
  onSubmit: (
    comment: string,
    mentionEmails?: string[],
    actionsSet?: Set<string>
  ) => void
  canCancel?: boolean
  onCancelComment?: () => void
  maxLength?: number
  autoFocus?: boolean
  comment?: string
  placeholder?: string
  actions?: string[]
}

type TStaff = {
  email: string
  name: string
}

const Staff: React.FunctionComponent<TStaff> = (props) => {
  return (
    <>
      <ListItemAvatar>
        <Avatar
          src={gravatar.url(props.email, {
            d: 'mm',
            r: 'g',
          })}
        />
      </ListItemAvatar>
      <ListItemText primary={props.name} secondary={props.email} />
    </>
  )
}

const MentionsInputWrapper = (
  inputComponentProps: InputBaseComponentProps & {
    editorRef: React.RefObject<TMUIRichTextEditorRef>
    onSave: (data: string) => void
    showEmojiPicker?: (state: EditorState, anchor: HTMLElement | null) => void
    onStateChange?: (data: ContentState) => void
    actions: string[]
  }
) => {
  const [key, setKey] = React.useState(uuid.v4())
  const [state] = useRedux()

  const users = selectAssignableUsers(state)

  React.useEffect(() => {
    if (inputComponentProps.autoFocus) {
      inputComponentProps.editorRef.current?.focus()
    }
  }, [])

  const handleSave = (data: string) => {
    inputComponentProps.onSave(data)
    setKey(uuid.v4())
  }

  return (
    <MUIRichTextEditor
      key={key}
      draftEditorProps={{
        spellCheck: true,
      }}
      controls={[
        'title',
        'bold',
        'underline',
        'italic',
        'add-emoji',
        'numberList',
        'bulletList',
      ]}
      keyCommands={[
        {
          key: 13,
          name: 'editor.save',
          callback: (state) => {
            inputComponentProps.editorRef.current?.save()

            return state
          },
        },
      ]}
      value={inputComponentProps.value}
      inlineToolbar={true}
      onChange={(val) =>
        inputComponentProps.onStateChange?.(val.getCurrentContent())
      }
      inlineToolbarControls={['title', 'bold', 'underline', 'italic']}
      ref={inputComponentProps.editorRef}
      onSave={handleSave}
      maxLength={inputComponentProps.maxLength}
      label={inputComponentProps.placeholder}
      decorators={commentFieldDecorators}
      toolbarButtonSize="small"
      customControls={[
        {
          name: 'emoji',
          type: 'atomic',
          atomicComponent: (props: any) => {
            const { blockProps } = props

            return blockProps.content
          },
        },
        {
          name: 'add-emoji',
          icon: <AddReaction />,
          type: 'callback',
          onClick: (state, __, anchor) => {
            inputComponentProps?.showEmojiPicker?.(state, anchor)
          },
        },
      ]}
      autocomplete={{
        strategies: [
          {
            insertSpaceAfter: true,
            items: inputComponentProps.actions.map((action) => ({
              keys: [action, action.toLowerCase(), action.toUpperCase()],
              value: `/__${action}`,
              content: `/${action}`,
            })),

            triggerChar: '/',
          },
          {
            insertSpaceAfter: true,
            items: users.map(({ email, firstName, lastName }) => ({
              keys: [email, firstName, lastName],
              value: `@__${email}`,
              content: (
                <Staff email={email} name={`${firstName} ${lastName}`} />
              ),
            })),
            triggerChar: '@',
          },
          {
            items: emojis,
            triggerChar: ':',
          },
        ],
      }}
    />
  )
}

export const CommentField = ({
  onSubmit,
  canCancel = false,
  onCancelComment,
  maxLength = 500,
  autoFocus = false,
  comment = '',
  placeholder,
  actions = [],
}: Props) => {
  const [state, setState] = React.useState<EditorState | null>(null)
  const [contentState, setContentState] = React.useState<ContentState>(
    ContentState.createFromText(comment)
  )
  const [commentText, setCommentText] = React.useState('')
  const [emojiAnchorEl, setEmojiAnchorEl] = React.useState<null | HTMLElement>(
    null
  )

  const editorRef = React.useRef<TMUIRichTextEditorRef | null>(null)

  const handleCancel = () => {
    onCancelComment?.()
    setContentState(ContentState.createFromText(''))
  }

  const handleSubmit = () => {
    editorRef.current?.save()
  }

  const handleSave = (data: string) => {
    const mentions = data.match(/@__\S+/g)
    const mentionsEmails = mentions?.map((mention) =>
      mention.replace('@__', '')
    )

    const parsedActions = data.match(/\/__\S+/g)
    const actionsSet = new Set(
      parsedActions?.map((action) => action.replace('/__', ''))
    )

    onSubmit(data, mentionsEmails, actionsSet)
    const newState = ContentState.createFromText('')

    setContentState(newState)
  }

  const handleKeyDown = (
    ev: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (ev.key === 'Enter' && !ev.shiftKey && commentText.trim()) {
      handleSubmit()
    }
  }

  const handleFocus = () => {
    editorRef.current?.focus()
  }

  const handleStateChanged = (state: ContentState) => {
    setCommentText(state.getPlainText())
  }

  const handleEmojiSelected = (emoji: Emoji) => {
    if (state) {
      let contentState = state.getCurrentContent()

      let targetRange = state.getSelection()

      let newContentState = Modifier.insertText(
        contentState,
        targetRange,
        emoji.value
      )

      let editorState = EditorState.push(
        state,
        newContentState,
        'insert-characters'
      )

      setState(editorState)
      setContentState(newContentState)
      setCommentText(newContentState.getPlainText())
    }
  }

  return (
    <>
      <Stack spacing={1} sx={{ width: '100%' }}>
        <OutlinedInput
          onFocusCapture={handleFocus}
          onKeyDown={handleKeyDown}
          autoFocus={autoFocus}
          onFocus={handleFocus}
          value={JSON.stringify(convertToRaw(contentState))}
          inputProps={{
            onStateChange: handleStateChanged,
            maxLength: maxLength,
            editorRef: editorRef,
            onSave: handleSave,
            placeholder,
            actions,
            showEmojiPicker: (state: EditorState, el: HTMLElement | null) => {
              setEmojiAnchorEl(el)
              setState(state)
            },
          }}
          defaultValue={comment}
          inputComponent={MentionsInputWrapper}
        ></OutlinedInput>

        {canCancel ? (
          <Stack direction="row" spacing={1}>
            <Button
              sx={{ alignSelf: 'flex-start' }}
              size="small"
              variant="contained"
              disabled={!commentText}
              onClick={handleSubmit}
            >
              Reply
            </Button>
            <Button
              sx={{ alignSelf: 'flex-start' }}
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Stack>
        ) : (
          <Button
            sx={{ alignSelf: 'flex-start' }}
            size="small"
            variant="contained"
            disabled={!commentText.trim()}
            onClick={handleSubmit}
          >
            Comment
          </Button>
        )}
      </Stack>
      <Popover
        onClose={() => setEmojiAnchorEl(null)}
        anchorEl={emojiAnchorEl}
        open={Boolean(emojiAnchorEl)}
      >
        <EmojiPicker emojiSelected={handleEmojiSelected} />
      </Popover>
    </>
  )
}
