import {
  Button,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { default as TextField } from '@mui/material/TextField'
import { Form } from '../../../admin/UI/Form'
import warnConfirm from '../../../admin/warnConfirm'
import * as React from 'react'
import { Order_create_input, Subscription } from '../../../graphql/types'
import { OrderPriority } from './constants'
import TooltipIconButton from '../../../UI/TooltipIconButton'

interface AdditionalProps {
  subscriptions?: Pick<
    Subscription,
    'id' | 'purchaseOrderId' | 'quoteId' | 'invoiceId'
  >[]
  onCopyOrder?: () => void
}

export default class OrderForm extends Form<
  Order_create_input,
  AdditionalProps
> {
  render() {
    const {
      quoteId = '',
      invoiceId = '',
      purchaseOrderId = '',
      comment = '',
      priority = 0,
      migratedSubscriptionId,
    } = this.getFormData()

    const { subscriptions = [] } = this.props

    return (
      <div className="grid Paper">
        <div
          className="grid-xs-12"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>{this.props.type === 'edit' ? 'Edit' : 'New'} Order</h2>
          {this.props.onCopyOrder && (
            <TooltipIconButton
              title="Copy Order"
              onClick={this.props.onCopyOrder}
            >
              <Icon>content_copy</Icon>
            </TooltipIconButton>
          )}
        </div>
        <div className="grid-xs-12">
          <TextField
            name="comment"
            label={`Comment`}
            fullWidth
            multiline
            value={comment || undefined}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!comment,
            }}
          />
        </div>
        <div className="grid-xs-12">
          <InputLabel htmlFor="order-priority">Priority</InputLabel>
          <Select
            name="priority"
            id="order-priority"
            fullWidth
            native={false}
            value={priority!}
            onChange={this.updateFormDataFromSelectChangeEvent}
            renderValue={() => this.displayPriority()}
          >
            <MenuItem value={OrderPriority.High}>
              High
              <Typography variant="caption">({OrderPriority.High})</Typography>
            </MenuItem>
            <MenuItem value={OrderPriority.Normal}>
              Normal
              <Typography variant="caption">
                ({OrderPriority.Normal})
              </Typography>
            </MenuItem>
            <MenuItem value={OrderPriority.Low}>
              Low
              <Typography variant="caption">({OrderPriority.Low})</Typography>
            </MenuItem>
          </Select>
        </div>
        <div className="grid-xs-4">
          <TextField
            name="quoteId"
            label={`Quote Id`}
            fullWidth
            multiline
            value={quoteId || undefined}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!quoteId,
            }}
          />
        </div>
        <div className="grid-xs-4">
          <TextField
            name="invoiceId"
            label={`Invoice Id`}
            fullWidth
            multiline
            value={invoiceId || undefined}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!invoiceId,
            }}
          />
        </div>
        <div className="grid-xs-4">
          <TextField
            name="purchaseOrderId"
            label={`Purchase Order Id`}
            fullWidth
            multiline
            value={purchaseOrderId || undefined}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!purchaseOrderId,
            }}
          />
        </div>
        {this.props.type === 'edit' &&
        migratedSubscriptionId &&
        migratedSubscriptionId !== -1 ? (
          <div className="grid-xs-12">
            <InputLabel htmlFor="order-migratedSubscriptionId">
              Linked subscription
            </InputLabel>
            <Typography>
              {this.getSubscriptionLabel(
                subscriptions.find((s) => s.id === migratedSubscriptionId)!
              )}
            </Typography>
            <Button onClick={() => this.unlinkSubscription()}>Unlink</Button>
          </div>
        ) : (
          <div className="grid-xs-12">
            <InputLabel htmlFor="order-migratedSubscriptionId">
              Linked subscription
            </InputLabel>
            <Select
              name="migratedSubscriptionId"
              id="order-subscriptionId"
              fullWidth
              native={false}
              value={migratedSubscriptionId || -1}
              onChange={this.updateFormDataFromSelectChangeEvent}
              renderValue={() => this.displaySubscription()}
            >
              <MenuItem value={-1}>None</MenuItem>
              {subscriptions.map((s) => (
                <MenuItem key={s.id} value={s.id} style={{ maxWidth: 618 }}>
                  {this.getSubscriptionLabel(s)}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }

  displaySubscription = () => {
    const { subscriptions } = this.props
    const { migratedSubscriptionId } = this.getFormData()
    if (
      migratedSubscriptionId === null ||
      migratedSubscriptionId === undefined ||
      migratedSubscriptionId === -1
    ) {
      return 'None'
    }
    const subscription = (subscriptions ?? []).find(
      (s) => s.id === migratedSubscriptionId
    )
    if (subscription) {
      return this.getSubscriptionLabel(subscription)
    }

    return migratedSubscriptionId
  }

  getSubscriptionLabel = (
    subscription?: Pick<
      Subscription,
      'id' | 'purchaseOrderId' | 'quoteId' | 'invoiceId'
    >
  ) =>
    subscription &&
    `${subscription.id} - quote: ${subscription.quoteId}, invoice: ${subscription.invoiceId}, PO: ${subscription.purchaseOrderId}`.trim()

  displayPriority = () => {
    const { priority = 0 } = this.getFormData()

    switch (priority) {
      case OrderPriority.High:
        return (
          <>
            High
            <Typography variant="caption">({OrderPriority.High})</Typography>
          </>
        )
      case OrderPriority.Normal:
        return (
          <>
            Normal
            <Typography variant="caption">({OrderPriority.Normal})</Typography>
          </>
        )
      case OrderPriority.Low:
        return (
          <>
            Low
            <Typography variant="caption">({OrderPriority.Low})</Typography>
          </>
        )
      default:
        return (
          <>
            Custom
            <Typography variant="caption">({priority})</Typography>
          </>
        )
    }
  }

  unlinkSubscription = async () => {
    if (
      await warnConfirm({
        title: 'Unlink Subscription',
        message: "Are you sure you'd like to un-link this subscription?",
      })
    ) {
      this.updateFormDataFromNameAndValue({
        name: 'migratedSubscriptionId',
        value: -1,
      })
    }
  }
}
