import * as React from 'react'
import i18n, { keys } from '../../i18n'
import { Button, Stack } from '@mui/material'
import { Delete } from '@mui/icons-material'

interface Props {
  onPress: () => void
  disabled?: boolean
}

export const DeleteButton = ({ onPress, disabled }: Props) => {
  return (
    <Stack flex={1}>
      <Button
        variant="outlined"
        color="error"
        startIcon={<Delete />}
        onClick={() => onPress()}
        disabled={disabled}
      >
        {i18n.t(keys.generic.delete)}
      </Button>
    </Stack>
  )
}
