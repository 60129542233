import { GroupData } from '../data/types'
import { FluxAction } from '../util/commonRedux'
import { GeoPdfDownloadsState } from './types'

// Actions
const OPEN_GEOPDF_DOWNLOADS_DIALOG = `downloads/OPEN_GEOPDF_DOWNLOADS_DIALOG`
const RESET_GEOPDF_DOWNLOADS_DIALOG = `downloads/RESET_GEOPDF_DOWNLOADS_DIALOG`

// Action Creators
export const openGeoPdfDownloadsDialog = (group: GroupData) => ({
  type: OPEN_GEOPDF_DOWNLOADS_DIALOG,
  payload: group,
})
export const resetGeoPdfDownloadsDialog = () => ({
  type: RESET_GEOPDF_DOWNLOADS_DIALOG,
})

export const initialState: GeoPdfDownloadsState = {
  isGeoPdfDownloadsDialogOpen: false,
}

export const reducer = (
  state = initialState,
  action: FluxAction
): GeoPdfDownloadsState => {
  switch (action.type) {
    case OPEN_GEOPDF_DOWNLOADS_DIALOG: {
      const group = action.payload as GroupData

      return {
        ...state,
        isGeoPdfDownloadsDialogOpen: true,
        selectedGroup: group,
      }
    }

    case RESET_GEOPDF_DOWNLOADS_DIALOG: {
      return { ...initialState }
    }

    default:
      return state
  }
}
