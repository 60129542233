import * as React from 'react'

import {
  Button,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import { url, urls } from '../../../appNavigation/urls'
import { client, gql } from '../../../graphql/client'
import useAsync from '../../../hooks/useAsync'
import { useParams } from '../../../hooks/useRouter'
import i18n from '../../../i18n'
import EnhancedTableToolbar from '../../../UI/EnhancedTable/EnhancedTableToolbar'
import LinkChild from '../../../UI/LinkChild'
import { CopyDeliveryDialog } from './Copy/CopyDeliveryDialog'

interface DeliveryCopy {
  id: string
  targetDeliveryDate: string
  comment: string
  organization: {
    name: string
  }
}

interface GetDeliveryCopiesResult {
  deliveryCopies: DeliveryCopy[]
}

const fetchDeliveryCopies = async (
  [deliveryId]: [string | undefined],
  skip: () => void
) => {
  if (deliveryId === undefined) {
    return skip()
  }

  const { jobTags } = await client.request<{
    jobTags: { job: { id: string; jobTags: { value: string }[] } }[]
  }>({
    query: gql`
      query GET_DELIVERY_COPIES($deliveryId: String!) {
        jobTags: maestro_JobTag(
          where: {
            key: { _eq: "sourceDeliveryId" }
            value: { _eq: $deliveryId }
          }
        ) {
          job: Job {
            id
            jobTags: JobTags(where: { key: { _eq: "destinationDeliveryId" } }) {
              value
            }
          }
        }
      }
    `,
    variables: { deliveryId },
  })

  const deliveryIds: string[] = []
  const jobIdsByDeliveryId: Record<string, string> = {}

  for (const jobTag of jobTags) {
    const jobTagDeliveryId = jobTag.job.jobTags[0].value
    deliveryIds.push(jobTagDeliveryId)
    jobIdsByDeliveryId[jobTagDeliveryId] = jobTag.job.id
  }

  const { deliveryCopies } = await client.request<GetDeliveryCopiesResult>({
    query: gql`
      query GET_DELIVERIES($deliveryIds: [uuid!]!) {
        deliveryCopies: Delivery(where: { id: { _in: $deliveryIds } }) {
          organization: Organization {
            name
          }
          id
          targetDeliveryDate
          comment
        }
      }
    `,
    variables: { deliveryIds },
  })

  return { deliveryCopies, jobIdsByDeliveryId }
}

export const CopyDeliveryPage = () => {
  const { deliveryId } = useParams()

  const [copiedDeliveries, refreshCopiedDeliveries] = useAsync(
    fetchDeliveryCopies,
    [deliveryId]
  )
  const [isCopyDeliveryDialogOpen, setIsCopyDeliveryDialogOpen] =
    React.useState(false)

  return (
    <Paper className="Paper ProcGroupsTable">
      <CopyDeliveryDialog
        open={isCopyDeliveryDialogOpen}
        onClose={() => {
          setIsCopyDeliveryDialogOpen(false)
          refreshCopiedDeliveries()
        }}
      />
      <EnhancedTableToolbar
        title="Copy Delivery"
        numSelected={0}
        onClickRefresh={() => refreshCopiedDeliveries()}
        onClickCreate={() => setIsCopyDeliveryDialogOpen(true)}
      />
      <Table>
        <colgroup>
          <col width="30%" />
          <col width="30%" />
          <col width="20%" />
          <col width="20%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>Organization</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell>Target Date</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        {copiedDeliveries.result?.deliveryCopies?.map((deliveryCopy) => {
          const jobId =
            copiedDeliveries.result?.jobIdsByDeliveryId?.[deliveryCopy.id]

          return (
            <TableRow>
              <TableCell>{deliveryCopy.organization.name}</TableCell>
              <TableCell>{deliveryCopy.comment}</TableCell>
              <TableCell>
                {i18n.toDateShort(deliveryCopy.targetDeliveryDate)}
              </TableCell>
              <TableCell align="right">
                <LinkChild
                  to={url(urls.editDelivery, { deliveryId: deliveryCopy.id })}
                >
                  <Button>View Delivery</Button>
                </LinkChild>
                {jobId ? (
                  <LinkChild to={url(urls.job, { jobId })}>
                    <Button>View Job</Button>
                  </LinkChild>
                ) : null}
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
    </Paper>
  )
}
