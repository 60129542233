import * as React from 'react'
import { default as Logo } from './assets/Reports_logo.png'

type dimensions = string | number | undefined

interface Props {
  height?: dimensions
  width?: dimensions
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ height = '30', width = 'auto' }: Props) => (
  <img src={Logo} height={height} width={width} alt="" />
)
