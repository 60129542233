import { createSelector } from 'reselect'
import { selectActiveColorProfiles } from '../ProductStats/selectors/stats'

import { selectSelectedProduct } from './selectSelectedProduct'

export const selectActiveColor = createSelector(
  selectActiveColorProfiles,
  selectSelectedProduct,
  (colorByProductId, selectedProduct) =>
    selectedProduct ? colorByProductId[selectedProduct.id] : undefined
)
