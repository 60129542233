import { createSelector } from 'reselect'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { client, gql } from '../../../graphql/client'
import { indexArray } from '../../../util/indexArray'
import { GetMeData, selectMe } from '../../../data/selectMe'

export interface ListRolesData {
  name: string
  description: string
  id: string
}

const listOrgRoles = async (
  lang: string,
  user: GetMeData | null | undefined
) => {
  const { roles } = await client.request<{ roles: ListRolesData[] }>({
    query: gql`
      query LIST_ORG_ROLES($lang: String!) {
        roles: AccessRole(where: { id: { _like: "org-%" } }) {
          id
          name(path: $lang)
          description(path: $lang)
        }
      }
    `,
    variables: { lang },
  })

  if (user?.roles?.includes('admin')) {
    return roles
  } else {
    return roles.filter((role) => role.id !== 'org-member-demo')
  }
}

const {
  selector: selectListOrganizationRoles,
  refresh: refreshListOrganizationRoles,
} = createAsyncSelector({
  resource: 'ListOrganizationRoles',
  inputs: { lang: selectPreferredLanguage, me: selectMe },
  fetcher: async ({
    lang,
    me,
  }: {
    lang: string
    me: GetMeData | null | undefined
  }) => {
    return await listOrgRoles(lang, me)
  },
})

export { selectListOrganizationRoles, refreshListOrganizationRoles }

const EMPTY_ROLES: ListRolesData[] = []

export const selectOrganizationRoles = createSelector(
  selectListOrganizationRoles,
  (listRoles) => listRoles.data || EMPTY_ROLES
)

export const selectOrganizationRolesById = createSelector(
  selectOrganizationRoles,
  (roles) => indexArray(roles, 'id')
)
