import warnConfirm from '../../../admin/warnConfirm'
import * as api from '../../../graphql/api'
import { Delivery, Organization, Parcel } from '../../../graphql/types'
import { asyncForEach } from '../../../util/syncArrayHelpers'
import { PreparedFile } from './prepareUpload'

export const VV_PARCEL_GEOJSON = 'vv-parcel.geojson'

interface ParcelProperties extends Record<string, any> {
  deliveryId?: Delivery['id']
  parcelId?: Parcel['id']
  orgId?: Organization['id']
  ImageId?: number
  DataSetID?: number
}

export type ParcelJSONData = GeoJSON.FeatureCollection<
  GeoJSON.Polygon,
  ParcelProperties
>

export interface UploadProgress {
  complete?: boolean

  fileUploadStatusByPath: Record<string, FileUploadStatus | undefined>
  filesToUpload: PreparedFile[]
  numFilesProcessed: number
  bytesToUpload: number
  bytesUploaded: number
  estimate: number | 'calculating'
}

export interface FileUploadStatus {
  status: 'started' | 'skipped' | 'finished' | 'error'
  info?: any
}

export const uploadFlightFiles = async (
  flightDate: string,
  deliveryId: string,
  filesToUpload: PreparedFile[],
  onProgressChange: (progress: UploadProgress) => void
) => {
  const progress: UploadProgress = {
    filesToUpload,
    fileUploadStatusByPath: {},
    numFilesProcessed: 0,
    estimate: 'calculating',
    bytesUploaded: 0,
    bytesToUpload: filesToUpload.reduce((sum, curr) => sum + curr.file.size, 0),
  }

  if (filesToUpload.some((file) => file.status === 'Updated')) {
    if (
      !(await warnConfirm({
        title: 'File Re-Upload',
        message:
          'Some files contained in this upload will overwrite previously uploaded files. Would you like to continue?',
        action: 'Continue',
      }))
    ) {
      return
    }
  }

  await doUploadFiles(
    flightDate,
    deliveryId,
    filesToUpload,
    progress,
    onProgressChange
  )

  progress.complete = true
  onProgressChange({ ...progress })
}

const doUploadFiles = async (
  flightDate: string,
  deliveryId: string,
  filesToUpload: PreparedFile[],
  progress: UploadProgress,
  onProgressChange: (progress: UploadProgress) => void
) => {
  const startTime = Date.now()

  await asyncForEach(
    filesToUpload,
    { limit: 10 },
    async ({ file, parcel, checksum, checksumAlgorithm }) => {
      const fileUploadStatus: FileUploadStatus = { status: 'started' }
      progress.fileUploadStatusByPath[
        (!!file.webkitRelativePath ? file.webkitRelativePath : file.fullPath)
      ] = fileUploadStatus
      onProgressChange({ ...progress })

      const skipFile = (info: FileUploadStatus['info']) => {
        progress.numFilesProcessed += 1

        fileUploadStatus.status = 'skipped'
        fileUploadStatus.info = info

        const timeElapsed = Date.now() - startTime

        progress.bytesUploaded += file.size
        const bytesRemaining = progress.bytesToUpload - progress.bytesUploaded

        progress.estimate =
          (timeElapsed / progress.bytesUploaded) * bytesRemaining
        onProgressChange({ ...progress })
      }

      try {
        const parcelId = parcel.parcelId
        const organizationId = parcel.orgId
        if (!parcelId) {
          return skipFile(
            `No parcelId property in ${VV_PARCEL_GEOJSON} feature`
          )
        }

        if (!organizationId) {
          return skipFile(
            `No organizationId property in ${VV_PARCEL_GEOJSON} feature`
          )
        }

        if (
          !(await api.deliveryParcelFile.checkNeedsUpload({
            filename: file.name,
            deliveryId,
            parcelId,
            checksum,
          }))
        ) {
          fileUploadStatus.status = 'skipped'

          return skipFile(`File unchanged`)
        }

        await api.deliveryParcelFile.upload({
          file,
          filename: file.name,
          deliveryId,
          parcelId,
          organizationId,
          checksum,
          checksumAlgorithm,
          flightDate: flightDate!,
        })
        progress.numFilesProcessed += 1

        const timeElapsed = Date.now() - startTime
        progress.bytesUploaded += file.size
        const bytesRemaining = progress.bytesToUpload - progress.bytesUploaded

        progress.estimate =
          (timeElapsed / progress.bytesUploaded) * bytesRemaining

        fileUploadStatus.status = 'finished'

        onProgressChange({ ...progress })
      } catch (error) {
        progress.numFilesProcessed += 1

        fileUploadStatus.info = error
        const timeElapsed = Date.now() - startTime
        progress.bytesUploaded += file.size
        const bytesRemaining = progress.bytesToUpload - progress.bytesUploaded

        progress.estimate =
          (timeElapsed / progress.bytesUploaded) * bytesRemaining

        fileUploadStatus.status = 'error'
        onProgressChange({ ...progress })
      }
    }
  )
}
