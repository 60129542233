import { selectPagingInfo } from '../../../appNavigation/urls'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import * as api from '../../../graphql/api'

export interface ListOrdersData {
  id: string
  comment: string
  createdAt: string
  updatedAt: string
  CreatedBy: {
    firstName: string
    lastName: string
    email: string
  }
  UpdatedBy: {
    firstName: string
    lastName: string
    email: string
  }
  TargetDeliveries_aggregate: {
    aggregate: {
      max: {
        date: string
      }
    }
  }
}

const list = ({
  limit,
  offset,
  orgId,
}: {
  limit?: number
  offset?: number
  orgId: string
}) =>
  api.order.list<ListOrdersData>({
    limit,
    offset,
    returning: `{
      id
      comment
      createdAt
      updatedAt
      CreatedBy {
        firstName
        lastName
        email
      }
      UpdatedBy {
        firstName
        lastName
        email
      }
      TargetDeliveries_aggregate {
        aggregate {
          max {
            date
          }
        }
      } 
  }`,
    where: {
      organizationId: {
        _eq: orgId,
      },
    },
  })

const { selector: selectListOrders, refresh: refreshListOrders } =
  createAsyncSelector({
    resource: 'ListOrdersData',
    inputs: {
      orgId: selectOrganizationId,
      pagingInfo: selectPagingInfo,
    },
    fetcher: async ({ orgId, pagingInfo: { page, pageSize } }) => {
      if (!orgId) {
        return
      }

      const offset = page * pageSize
      const limit = pageSize

      const { data, info } = await list({ orgId, offset, limit })

      if (!data) {
        throw new Error('No Data')
      }

      return {
        data,
        info: {
          page,
          pageSize,
          count: info?.count ?? 0,
        },
      }
    },
  })

export { selectListOrders, refreshListOrders }
