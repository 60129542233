import { Fetcher, createTableFetcher } from '../../UI/Table/createTableFetcher'
import { client, gql } from '../../graphql/client'
import { tableFormatterWithScope } from './yourQueueTableFormatter'

export interface DeliveryProcGroupSummary {
  organization: {
    name: string
  }
  groupName: string
  procGroup: string
  deliveryId: string
  KatoLineQAJob: {
    Status: {
      status: string
    }
  }
  qaStatus: 'not-reviewed' | 'complete' | 'failed'
  Delivery: {
    Order: {
      priority: number
    }
  }
}

const fetchMyQueueQuery = (returning: string, orderBy: string) => gql`
  query GET_MY_QUEUE($offset: Int!, $limit: Int!, $where: Delivery_ProcGroup_bool_exp!) {
    data: Delivery_ProcGroup(
      order_by: ${orderBy}
      offset: $offset
      limit: $limit
      where: $where
    ) ${returning}

    aggregate: Delivery_ProcGroup_aggregate(
      where: $where
    ) {
      info: aggregate { count }
    }
  }
`

export const fetchYourQueue = (userId: string | undefined) => {
  const list: Fetcher<DeliveryProcGroupSummary> = async (options) => {
    if (!userId) {
      return { data: [], info: { count: 0 } }
    }

    const where = {
      _and: {
        assignee: { _eq: userId },
        _or: [
          { qaStatus: { _eq: 'not-reviewed' } },
          { qaStatus: { _eq: 'failed' } },
        ],
        ...(options?.where ?? {}),
      },
    }

    const order_by =
      options.order_by?.replace(/\s/g, '') ??
      '[{ Delivery: { Order: { priority: desc } } }]'

    const { data, aggregate } = await client.request<{
      data?: DeliveryProcGroupSummary[]
      aggregate?: { info: { count: number } }
    }>({
      query: fetchMyQueueQuery(options.returning ?? '', order_by),
      variables: {
        offset: options.offset,
        limit: options.limit,
        where: where,
      },
    })

    return {
      data,
      info: aggregate?.info,
    }
  }

  return createTableFetcher<DeliveryProcGroupSummary>(
    tableFormatterWithScope(),
    `{
      organization: Organization {
        name
      }
      deliveryId
      procGroup
      groupName
      qaStatus
      KatoLineQAJob {
        Status {
          status
        }
      }
      Delivery {
        Order {
          priority
        }
      }
    }`,
    list,
    '_or'
  )
}
