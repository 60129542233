import {
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Column } from '../../../admin/UI/Column'
import { Row } from '../../../admin/UI/Row'
import { RouteParams, url } from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectOrganization } from '../../../data/selectOrganization'
import i18n, { keys } from '../../../i18n'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { ExpandButton } from '../../../UI/Expand/ExpandButton'
import { formatNum } from '../../../util/formatNumber'
import { convertLength } from '../../../util/units/length'
import { EditNameDialog } from './EditNameDialog'
import './OrganizationParcels.scss'
import {
  OrganizationGroup,
  OrganizationParcel,
  refreshListOrganizationParcels,
  selectListOrganizationParcels,
  selectOrganizationParcelsWithThumbnails,
} from './selectOrganizationParcels'
import { updateGroupName } from './updateGroupName'
import { updateParcelName } from './updateParcelName'

interface State {
  expandedGroups: Set<number>
  editedParcel?: OrganizationParcel
  editedGroup?: OrganizationGroup
}

class OrganizationParcels extends React.Component<
  ReduxProps & RouteComponentProps<RouteParams>,
  State
> {
  state: State = {
    expandedGroups: new Set(),
  }

  render() {
    const { parcelsSelector, parcelsByGroup, parcelFilter = '' } = this.props
    const { editedParcel, editedGroup } = this.state

    return (
      <AsyncSelectorStatusOverlay requests={parcelsSelector}>
        <EditNameDialog
          open={!!editedParcel || !!editedGroup}
          title={i18n.t(keys.editName)}
          initialName={editedGroup?.name || editedParcel?.name}
          onSave={(newName) => this.saveName(newName)}
          onCancel={() =>
            this.setState({ editedParcel: undefined, editedGroup: undefined })
          }
        />
        <Column style={{ width: '100%', alignItems: 'stretch' }}>
          <Column
            style={{
              alignItems: 'stretch',
              alignSelf: 'flex-end',
              marginBottom: 8,
            }}
            className="filter"
          >
            <TextField
              type="text"
              id="parcel-filter"
              value={parcelFilter}
              onChange={(ev) => this.handleChangeParcelFilter(ev)}
              InputProps={{
                autoComplete: 'off',
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Column>
          {parcelsByGroup.map((group) => {
            const expanded = this.state.expandedGroups.has(group.id)

            if (group.parcels.length < 1) {
              return null
            }

            return (
              <Column
                className="group"
                key={group.id}
                style={{ alignItems: 'stretch', width: '100%' }}
              >
                <Row
                  onClick={() => this.handleExpandGroup(group)}
                  style={{ cursor: 'pointer' }}
                >
                  <ExpandButton expanded={expanded} />
                  <Typography variant="body2">{group.name}</Typography>
                  <IconButton
                    style={{ marginLeft: 8 }}
                    onClick={(ev) => this.handleEditGroup(ev, group)}
                    size="large"
                  >
                    <Icon fontSize="small">edit</Icon>
                  </IconButton>
                </Row>

                {expanded &&
                  group.parcels.map((parcel) => {
                    return (
                      <Column
                        className="parcel"
                        key={parcel.id}
                        style={{
                          alignItems: 'stretch',
                          justifyContent: 'flex-start',
                          marginLeft: 34,
                        }}
                      >
                        <Row>
                          <Typography>{parcel.name}</Typography>
                          <IconButton
                            style={{ marginLeft: 8 }}
                            onClick={(ev) => this.handleEditParcel(ev, parcel)}
                            size="large"
                          >
                            <Icon fontSize="small">edit</Icon>
                          </IconButton>
                        </Row>

                        <Row
                          style={{
                            alignItems: 'flex-start',
                          }}
                        >
                          <img
                            className="parcel-map"
                            alt={parcel.name}
                            src={parcel.thumbnail}
                          />
                          <Column>
                            {this.renderMetaProperty({
                              parcelMeta: parcel.meta,
                              label: i18n.t(keys.focusedBlockDialog.varietals),
                              property: 'varietals',
                            })}
                            {this.renderMetaProperty({
                              parcelMeta: parcel.meta,
                              label: i18n.t(keys.focusedBlockDialog.rootstock),
                              property: 'rootstock',
                            })}
                            {this.renderMetaProperty({
                              parcelMeta: parcel.meta,
                              label: i18n.t(keys.focusedBlockDialog.rowSpacing),
                              property: 'rowSpacing',
                              backupProperty: 'rowSpace',
                              formatter: this.formatMeters,
                            })}
                            {this.renderMetaProperty({
                              parcelMeta: parcel.meta,
                              label: i18n.t(
                                keys.focusedBlockDialog.vineSpacing
                              ),
                              property: 'vineSpacing',
                              backupProperty: 'vineSpace',
                              formatter: this.formatMeters,
                            })}
                            {this.renderMetaProperty({
                              parcelMeta: parcel.meta,
                              label: i18n.t(
                                keys.focusedBlockDialog.trellisType
                              ),
                              property: 'trellis',
                              backupProperty: 'trellisType',
                            })}
                          </Column>
                        </Row>
                        <Divider className="divider" />
                      </Column>
                    )
                  })}
                {!expanded && <Divider />}
              </Column>
            )
          })}
        </Column>
      </AsyncSelectorStatusOverlay>
    )
  }

  renderMetaProperty = ({
    parcelMeta,
    label,
    property,
    backupProperty,
    formatter,
  }: {
    parcelMeta: OrganizationParcel['meta']
    label: string
    property: string
    backupProperty?: string
    formatter?: (val: number | string) => string
  }) => {
    const value =
      parcelMeta &&
      (parcelMeta[property] || (backupProperty && parcelMeta[backupProperty]))

    if (!value) {
      return
    }
    if (formatter) {
      return (
        <Row>
          <Typography style={{ fontWeight: 'bold' }}>{`${label}`}:</Typography>
          <Typography style={{ marginLeft: 4 }}>{formatter(value)}</Typography>
        </Row>
      )
    }

    return (
      <Row>
        <Typography style={{ fontWeight: 'bold' }}>{`${label}`}:</Typography>
        <Typography style={{ marginLeft: 4 }}>{value}</Typography>
      </Row>
    )
  }

  formatMeters = (input: number) => {
    const { preferredUnitSystem } = this.props

    const { value, symbol } = convertLength(input, 'meter', preferredUnitSystem)

    return `${formatNum(value, false, 2)} ${symbol}`
  }

  handleChangeParcelFilter = (
    ev: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    this.props.history.replace(
      url({ url: this.props.match.path }, this.props.match.params, {
        'parcel-filter': ev.target.value,
      })
    )
  }

  handleEditGroup = (ev: React.SyntheticEvent, group: OrganizationGroup) => {
    ev.stopPropagation()
    this.setState({ editedGroup: group, editedParcel: undefined })
  }

  handleEditParcel = (ev: React.SyntheticEvent, parcel: OrganizationParcel) => {
    ev.stopPropagation()
    this.setState({ editedGroup: undefined, editedParcel: parcel })
  }

  handleExpandGroup = (group: OrganizationGroup) => {
    const { expandedGroups } = this.state

    if (expandedGroups.has(group.id)) {
      expandedGroups.delete(group.id)
    } else {
      expandedGroups.add(group.id)
    }

    this.setState({ expandedGroups: new Set(expandedGroups) })
  }

  saveName = async (newName: string) => {
    const { editedGroup, editedParcel } = this.state
    if (editedGroup) {
      await updateGroupName(editedGroup.id, newName)
      refreshListOrganizationParcels()
    } else if (editedParcel) {
      await updateParcelName(editedParcel.id, newName)
      refreshListOrganizationParcels()
    }

    this.setState({ editedParcel: undefined, editedGroup: undefined })
  }
}

const mapState = (state: RootStore) => ({
  preferredUnitSystem: state.preferences.preferredUnitSystem,
  organization: selectOrganization(state),
  parcelsSelector: selectListOrganizationParcels(state),
  parcelsByGroup: selectOrganizationParcelsWithThumbnails(state),
  parcelFilter: state.router.searchParams['parcel-filter'],
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  OrganizationParcels
)
