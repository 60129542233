import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'

export type RequeueJobType =
  | 'Row Mask'
  | 'Reference Setup'
  | 'Final Products'
  | 'Reference Update'

interface Props {
  open: boolean
  jobType?: RequeueJobType
  procGroupCount: number
  onSubmit: (jobType: RequeueJobType, requeueDownstream?: boolean) => void
  onCancel: () => void
}

export const RequeueProcGroupsDialog = ({
  open,
  jobType,
  procGroupCount,
  onCancel,
  onSubmit,
}: Props) => {
  const [requeueDownstream, setRequeueDownstream] = React.useState(false)
  const theme = useTheme()

  React.useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setRequeueDownstream(false)
      }, theme.transitions.duration.leavingScreen)
    }
  }, [open, theme.transitions.duration.leavingScreen])

  const handleSubmit = () => {
    if (jobType) {
      onSubmit(jobType, requeueDownstream)
    }
  }

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>{`Requeue ${jobType}`}</DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>{`Would you like to requeue the ${jobType} jobs for the ${procGroupCount} selected proc groups?`}</Typography>
          {jobType !== 'Final Products' && (
            <FormControlLabel
              label="Requeue Downstream Jobs?"
              onChange={() => setRequeueDownstream(!requeueDownstream)}
              control={<Checkbox checked={requeueDownstream} />}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={1}
          sx={{ width: '100%' }}
          justifyContent="stretch"
        >
          <Button
            fullWidth
            color="secondary"
            onClick={() => onCancel()}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            fullWidth
            color="primary"
            onClick={handleSubmit}
            variant="contained"
          >
            Requeue
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
