import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import * as api from '../../graphql/api'
import { Model } from '../../graphql/types'
import errorAlert from '../errorAlert'
import assertDefinedFields from '../mapdata/utils/assertDefinedFields'
import PackageForm from './PackageForm'
import { EditPackageData } from './selectEditPackage'

class NewPackage extends React.PureComponent<RouteComponentProps> {
  render() {
    return (
      <Page
        title={`New Package`}
        backTo={url(urls.listPackages)}
        backToTitle="Packages"
      >
        <div id="NewPackage" className="MapDataContainerSmall">
          <PackageForm
            type="new"
            onCancel={this.goHome}
            onSave={this.handleSave}
          />
        </div>
      </Page>
    )
  }

  handleSave = async (formData: EditPackageData['package']) => {
    const { name, description, code } = formData
    const requiredFields = {
      name,
      code,
    }

    try {
      if (assertDefinedFields(requiredFields)) {
        const req = await api.productPackage.create<Model>({
          input: {
            description,
            ...requiredFields,
          },
        })

        this.props.history.replace(
          url(urls.editPackage, { packageId: req.data!.id })
        )
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Package', message, formData)
    }
  }

  goHome = () => {
    this.props.history.push(url(urls.listSourceDefs))
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
      file: 'NewPackage.tsx',
    },
  })

export default withRouter(NewPackage)
