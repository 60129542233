import * as api from '../../graphql/api'
import { gql, query } from '../../graphql/client'
import { Delivery, Flight, Order } from '../../graphql/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default async (
  dataSetId: string,
  targetDeliveryDate: string,
  order: Order,
  acquisitionDate: string,
  delivery?: Delivery | null
) => {
  if (delivery) {
    const { id: deliveryId, organizationId, Flights } = delivery
    const aqDate = new Date(acquisitionDate!)
    if (Flights?.length > 0) {
      const flight = Flights.find((f) => {
        const flightdate = new Date(f.date)

        return (
          aqDate.getUTCDate() === flightdate.getUTCDate() &&
          aqDate.getUTCFullYear() === flightdate.getUTCFullYear() &&
          aqDate.getUTCMonth() === flightdate.getUTCMonth()
        )
      })

      if (flight) {
        await createDataSetFlight({ dataSetId, flight })

        return
      }
    }

    await api.flight.upsert({
      input: {
        deliveryId,
        organizationId,
        DataSetFlights: {
          data: [
            {
              dataSetId,
            },
          ],
        } as any,
        date: acquisitionDate!,
      },
      onConflict: {
        constraint: 'Flight_pkey',
        update_columns: [],
      },
    })
  } else {
    const { organizationId, id: orderId } = order
    await query({
      query: gql`
        mutation ADD_DELIVERY(
          $organizationId: Int!
          $orderId: uuid!
          $targetDeliveryDate: date!
          $dataSetId: Int!
          $acquisitionDate: date!
        ) {
          insert_Delivery_one(
            object: {
              organizationId: $organizationId
              orderId: $orderId
              targetDeliveryDate: $targetDeliveryDate
              Flights: {
                data: [
                  {
                    date: $acquisitionDate
                    organizationId: $organizationId
                    DataSetFlights: { data: [{ dataSetId: $dataSetId }] }
                  }
                ]
              }
            }
          ) {
            id
          }
        }
      `,
      variables: {
        organizationId,
        orderId,
        targetDeliveryDate,
        dataSetId,
        acquisitionDate,
      },
    })
  }
}

const createDataSetFlight = async ({
  dataSetId,
  flight,
}: {
  dataSetId: string
  flight: Flight
}) => {
  await query({
    query: gql`
      mutation INSERT_DATASET_FLIGHT(
        $dataSetId: Int!
        $deliveryId: uuid!
        $flightDate: date!
      ) {
        insert_DataSet_Flight_one(
          object: {
            dataSetId: $dataSetId
            deliveryId: $deliveryId
            flightDate: $flightDate
          }
          on_conflict: { constraint: DataSet_Flight_pkey, update_columns: [] }
        ) {
          dataSetId
          deliveryId
          flightDate
        }
      }
    `,
    variables: {
      dataSetId,
      deliveryId: flight.deliveryId,
      flightDate: flight.date,
    },
  })
}
