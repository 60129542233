import * as React from "react";

export const useDelayedUnmount = (isRendering: boolean, duration: number) => {
    const [mounted, setMounted] = React.useState(true);

    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isRendering && !mounted) {
            setMounted(true);
            return;
        } else if (!isRendering && mounted){           
            timer = setTimeout(() => {
                setMounted(false);
            }, duration);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [isRendering, duration, mounted]);

    return mounted;
}