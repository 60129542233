import { createSelector } from 'reselect'
import { RootStore } from '../../redux/types'
import length, { LengthUnit } from '../../util/units/length'
import mapboxElevation from './mapbox-elevation'

const getPreferredUnits = (state: RootStore) =>
  length.elevationUnit[state.preferences.preferredUnitSystem] as LengthUnit

const getPreferredLength = createSelector(
  getPreferredUnits,
  (preferredUnits) => preferredUnits
)

const getElevationLayerEnabled = (state: RootStore) =>
  state.preferences.showElevationLayer

const DEFAULT_ELEVATION = { sources: {}, layers: [] }

export const getElevationLayer = createSelector(
  getElevationLayerEnabled,
  getPreferredLength,
  (elevationLayerEnabled, preferredLength) => {
    if (elevationLayerEnabled) {
      const elevationLayer = { ...mapboxElevation }
      const labelLayer = elevationLayer.layers[0]
      const newExpression = getElevationExpression(preferredLength)

      if (newExpression) {
        labelLayer.layout['text-field'] = newExpression
      }

      return mapboxElevation
    }

    return DEFAULT_ELEVATION
  }
)

/**
 * Returns a mapbox expression for elevation:
 * https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#math
 */
const getElevationExpression = (
  preferredLength: LengthUnit
): string[] | null => {
  if (preferredLength === 'meter') {
    return ['concat', ['get', 'ele'], 'm'] as string[]
  }
  if (preferredLength === 'foot') {
    return [
      'concat',
      ['round', ['*', ['get', 'ele'], 3.28084]],
      'ft',
    ] as string[]
  }

  // no conversion/invalid preferredLength
  return null
}
