import applyNamespace from 'redux-ts-helpers/lib/applyNamespace'
import createAction from 'redux-ts-helpers/lib/createAction'
import createReducer from 'redux-ts-helpers/lib/createReducer'

import * as noteForms from '../noteForms/redux'
import * as notes from '../notes/redux'
import { resetPostGISData } from '../postgis/resetPostGISData'
import { dispatch } from '../redux/store'
import { RootStore } from '../redux/types'
import { setCurrentOrg } from '../vvapi/apiResource/createApiResource'
import { UserSelectionState } from './types'

type Update = Partial<UserSelectionState>

// Actions
export const constants = applyNamespace('userSelection', {
  update: 0,
  reset: 0,
})

export const update = createAction<Update>(constants.update)

export const reset = createAction(constants.reset)

const me: any = window
me.resetUserSelection = reset

export const initialState: UserSelectionState = {}

export const reducer = createReducer(initialState, {
  [constants.update]: (state, { payload }: ReturnType<typeof update>) => {
    if (
      payload.selectedOrganizationId &&
      payload.selectedOrganizationId !== state.selectedOrganizationId
    ) {
      setCurrentOrg(payload.selectedOrganizationId)
      setTimeout(() => {
        dispatch(resetPostGISData(undefined))
        dispatch(notes.actions.reset(undefined))
        dispatch(noteForms.actions.reset(undefined))
      }, 0)

      return {
        ...initialState,
        ...payload,
      }
    }

    return {
      ...state,
      ...payload,
    }
  },
  [constants.reset]: () => initialState,
})

export const getSelectedFlightDate = (state: RootStore) => {
  return state.userSelection.selectedFlightDate
}
