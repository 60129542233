import * as React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'

import i18n, { keys } from '../../../i18n'
import { Sample } from '../../../graphql/types'
import {
  downloadCSV,
  downloadXLSX,
} from '../../../util/table'
import { downloadGeoJson } from '../../../util/download'
import { feature, featureCollection } from '@turf/helpers'
import { Close } from '@mui/icons-material'

interface Props {
  open: boolean
  onClose: (confirm: boolean) => void
  samplePlanName: string
  samples: Sample[],
}

type FileTypes = 'csv' | 'xlsx' | 'geojson'

export const SamplePlanDownload = ({
  open,
  onClose,
  samplePlanName,
  samples,
}: Props) => {
  const [fileType, setFileType] = React.useState<FileTypes>('csv')

  const onFileTypeChange = (fileTypes: FileTypes) => {
    setFileType(fileTypes)
  }

  const produceDownloadRows = (): Record<string, any>[] => {
    const getSampleContentData = (sample: Sample) => {
      return Object.entries(sample.content).reduce((acc, kv) => {
        const k = i18n.t(`noteForm.reservedNames.${kv[0]}`, { defaultValue: kv[0]})
        const v = Array.isArray(kv[1]?.value) 
          ? kv[1]?.value.map((item: string) => i18n.t(`noteForm.reservedNames.${item}`, {defaultValue: item})) 
          : kv[1]?.value
        acc[k] = v ?? ''
        return acc
      }, {} as Record<string, string>)
    }

    const sampleData =
      samples?.map(
        (s): Record<string, any> => ({
          [`${i18n.t(keys.sampleResultBlockLabel)}`]:
            s.SamplePlanBlock?.Parcel?.name ?? '',
          [`${i18n.t(keys.sampleResultSamplerLabel)}`]:
            !s.CompletedByUser?.firstName && !s.CompletedByUser?.lastName
              ? ''
              : `${s.CompletedByUser?.firstName} ${s.CompletedByUser?.lastName}`,
          [`${i18n.t(keys.sampleResultCompletionDateLabel)}`]: s.completedAt
            ? i18n.toDateShort(s.completedAt)
            : '',
          [`${i18n.t(keys.sampleResultCompletionHourLabel)}`]: s.completedAt
            ? i18n.toTimeHourMinute(s.completedAt)
            : '',
          ...getSampleContentData(s),
        })
      ) || []

    return sampleData
  }

  const confirmDownload = () => {
    switch (fileType) {
      case 'csv':
        downloadCSV({
          filename: samplePlanName,
          data: produceDownloadRows(),
        })
        break
      case 'xlsx':
        downloadXLSX({
          filename: samplePlanName,
          data: produceDownloadRows(),
        })
        break
      case 'geojson':
        const sampleFeatureCollection = featureCollection(samples.map((s) =>
        feature(s.geometry, {
          group: s.SamplePlanBlock?.Parcel?.OrganizationGroup?.name,
          block: s.SamplePlanBlock?.Parcel?.name,
          'completed-at': s.completedAt,
          content: Object.entries(s.content).reduce((acc, kv) => {
            acc[kv[0]] = kv[1].value
            return acc
          }, {} as Record<string, string>),
        }))
      )
        downloadGeoJson(JSON.stringify(sampleFeatureCollection), samplePlanName)
        break
      default:
        break
    }
    onClose(true)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>{i18n.t(keys.samplePlanDownloadTitleLabel)}</Typography>
          <IconButton onClick={() => onClose(false)}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column">
          <Typography>{i18n.t(keys.samplePlanDownloadDescLabel)}</Typography>
          <FormControl>
            <RadioGroup
              value={fileType}
              onChange={(e) => onFileTypeChange(e.target.value as FileTypes)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="csv"
                control={<Radio />}
                label={i18n.t(keys.samplePlanDownloadCsv)}
              />
              <FormControlLabel
                value="xlsx"
                control={<Radio />}
                label={i18n.t(keys.generic.xlsx)}
              />
              <FormControlLabel
                value="geojson"
                control={<Radio />}
                label={i18n.t(keys.samplePlanDownloadGeoJson)}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmDownload}>{i18n.t(keys.confirm)}</Button>
      </DialogActions>
    </Dialog>
  )
}
