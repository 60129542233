import { applyNamespace, createAction, createReducer } from 'redux-ts-helpers'
import { NotificationBody, NotificationsState } from './types'

export const constants = applyNamespace('Notifcations', {
  showNotification: 0,
  dismissNotification: 0,
  showFeedback: 0,
  dismissFeedback: 0,
})

export const showNotification = createAction<NotificationBody>(
  constants.showNotification
)

export const dismissNotification = createAction<number>(
  constants.dismissNotification
)

export const showFeedback = createAction<NotificationBody>(
  constants.showFeedback
)

export const dismissFeedback = createAction<number>(constants.dismissFeedback)

export const initialState: NotificationsState = {
  notifications: [],
  feedback: [],
}

export const reducer = createReducer(initialState, {
  [constants.showNotification]: (
    state,
    { payload }: ReturnType<typeof showNotification>
  ) =>
    // if app notification with same id already exists, ignore it.
    !state.notifications
      .map((n) => n.appNotificationId)
      .includes(payload.appNotificationId)
      ? {
          ...state,
          notifications: [...state.notifications, payload],
        }
      : state,
  [constants.dismissNotification]: (
    state,
    { payload }: ReturnType<typeof dismissNotification>
  ) => ({
    ...state,
    notifications: [
      ...state.notifications.slice(0, payload),
      ...state.notifications.slice(payload + 1),
    ],
  }),
  [constants.showFeedback]: (
    state,
    { payload }: ReturnType<typeof showFeedback>
  ) =>
    !state.feedback
      .map((n) => n.appNotificationId)
      .includes(payload.appNotificationId)
      ? {
          ...state,
          feedback: [...state.feedback, payload],
        }
      : state,
  [constants.dismissFeedback]: (
    state,
    { payload }: ReturnType<typeof dismissFeedback>
  ) => ({
    ...state,
    feedback: [
      ...state.feedback.slice(0, payload),
      ...state.feedback.slice(payload + 1),
    ],
  }),
})
