import createReducer from 'redux-ts-helpers/lib/createReducer'
import * as actions from './actions'
import { ColorSchemeState } from './types'

export const initialState: ColorSchemeState = {
  drawerProductType: null,
  showStats: false,
}

export const reducer = createReducer(initialState, {
  [actions.constants.showDrawerForProduct]: (
    state,
    action: ReturnType<typeof actions.showDrawerForProduct>
  ) => {
    return {
      ...state,
      drawerProductType: action.payload,
    }
  },

  [actions.constants.toggleStats]: (
    state,
    action: ReturnType<typeof actions.toggleStats>
  ) => ({
    ...state,
    showStats: !state.showStats,
  }),
})
