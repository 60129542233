import * as React from 'react'
import { PureComponent } from 'react'
import { Typography } from '@mui/material'
import i18n, { keys } from '../../i18n'
import { selectSelectedProduct } from '../../postgis/selectSelectedProduct'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { Slider } from '../../UI/Slider'
import { throttle } from '../../util/throttle-debounce'
import { updateProductSettings } from '../store/redux'
import { selectActiveProductSettings } from '../store/selectors/selectActiveProductSettings'

class Opacity extends PureComponent<AppDispatchProps & ReduxProps> {
  handleChangeOpacity = throttle({ delay: 100 }, (opacity: number) => {
    const { productId, dispatch } = this.props

    dispatch(updateProductSettings({ productId, settings: { opacity } }))
  })

  render() {
    const { opacity = 1 } = this.props

    return (
      <React.Fragment>
        <Typography variant="subtitle1">
          {i18n.t(keys.visualization.opacity)}: {Math.round(100 * opacity)}%
        </Typography>
        <Slider
          min={0.25}
          max={1}
          step={0.01}
          style={{ flex: 1 }}
          value={opacity}
          onChange={this.handleChangeOpacity}
        />
      </React.Fragment>
    )
  }
}

const mapState = (state: RootStore) => {
  const product = selectSelectedProduct(state)
  const settings = selectActiveProductSettings(state)

  return {
    opacity: settings.opacity,
    productId: product?.id,
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(Opacity)
