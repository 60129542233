import { createSelector } from 'reselect'
import { RootStore } from '../../redux/types'
import mapboxSoil from './mapbox-soil'

const getSoilLayersEnabled = (state: RootStore) =>
  state.preferences.showSoilLayer

const DEFAULT_SOIL = { sources: {}, layers: [] }

export const getSoilLayers = createSelector(
  getSoilLayersEnabled,
  (soilLayerEnabled) => {
    if (soilLayerEnabled) {
      return mapboxSoil
    }

    return DEFAULT_SOIL
  }
)
