import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { gql, query } from '../../../graphql/client'
import { RootStore } from '../../../redux/types'

export interface MigrationStatus {
  migrated: number
  unmigrated: number
  unmigratable: number
}

const GET_ORGANIZATION_MIGRATION_STATUS = gql`
  query GET_ORGANIZATION_MIGRATION_STATUS($organizationId: Int!) {
    Migrated: ParcelDataSet_aggregate(
      where: {
        _and: [
          { deletedAt: { _is_null: true } }
          { Parcel: { organizationId: { _eq: $organizationId } } }
          { DeliveryParcel: { migratedByVersion: { _is_null: false } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    Unmigrated: ParcelDataSet_aggregate(
      where: {
        _and: [
          { deletedAt: { _is_null: true } }
          { Parcel: { organizationId: { _eq: $organizationId } } }
          {
            _or: [
              { _not: { DeliveryParcel: {} } }
              { DeliveryParcel: { migratedByVersion: { _is_null: true } } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const {
  selector: selectOrganizationMigrationStatus,
  refresh: refreshOrganizationMigrationStatus,
} = createAsyncSelector({
  resource: 'GetOrganizationMigrationStatus',
  inputs: {
    organizationId: (state: RootStore) => state.router.params.organizationId,
  },
  fetcher: async ({ organizationId }) => {
    if (!Number(organizationId)) {
      return
    }
    const {
      Migrated: {
        aggregate: { count: migrated },
      },
      Unmigrated: {
        aggregate: { count: unmigrated },
      },
    } = await query<{
      Migrated: { aggregate: { count: number } }
      Unmigrated: { aggregate: { count: number } }
    }>({
      query: GET_ORGANIZATION_MIGRATION_STATUS,
      variables: { organizationId },
    })

    return {
      'Migrated ParcelDataSets': migrated,
      'Unmigrated ParcelDataSets': unmigrated,
    }
  },
})

export { selectOrganizationMigrationStatus, refreshOrganizationMigrationStatus }
