import * as React from 'react'
import { PureComponent } from 'react'
import {
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { selectAvailableProducts } from '../../data/selectAvailableProducts'
import * as userSelection from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'

class LayerDrawerBackgroundRaster extends PureComponent<
  ReduxProps & AppDispatchProps
> {
  render() {
    const { selectedBackgroundProductId, availableBackgroundProducts } =
      this.props

    if (
      !availableBackgroundProducts ||
      availableBackgroundProducts.length <= 0
    ) {
      return null
    }

    return (
      <ListItem>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.layerDrawer.backgroundRaster)}</InputLabel>
          <Select
            value={selectedBackgroundProductId ?? 'NONE'}
            onChange={this.handleChange}
            fullWidth
          >
            {availableBackgroundProducts.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
            <MenuItem value="NONE">{i18n.t(keys.generic.none)}</MenuItem>
          </Select>
        </FormControl>
      </ListItem>
    )
  }

  handleChange = (event: SelectChangeEvent) => {
    let value: string | undefined = event.target.value

    if (value === 'NONE') {
      value = undefined
    }

    this.props.dispatch(
      userSelection.update({ selectedBackgroundProductId: value })
    )
  }
}

const mapState = (state: RootStore) => ({
  selectedBackgroundProductId: state.userSelection.selectedBackgroundProductId,
  availableBackgroundProducts:
    selectAvailableProducts(state).backgroundProducts,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  LayerDrawerBackgroundRaster
)
