import { Button } from '@mui/material'
import * as React from 'react'
import { IconButton, Menu, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { RateMapActionMenuItems } from './RateMapActionMenuItems'
import { MRT_Row, MRT_TableInstance } from 'material-react-table'
import { RateMapRow } from '../types'
import i18n, { keys } from '../../../i18n'

interface ActionsCellProps {
  row: MRT_Row<RateMapRow>
  table: MRT_TableInstance<RateMapRow>
}

const ActionsCell: React.FC<ActionsCellProps> = ({ row, table }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        onClick={() => row.original.edit()}
        size="small"
        color="primary"
        variant="contained"
        sx={{ px: 2 }}
      >
        {i18n.t(keys.rateMapEditColumnButtonLabel)}
      </Button>
      <Tooltip title="More options">
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {RateMapActionMenuItems({ row, table, closeMenu: handleClose })}
      </Menu>
    </div>
  )
}

export default ActionsCell
