import { createSelector } from 'reselect'
import { selectAllReportMapLayers } from './selectAllReportMapLayers'
import { selectReportsPageParams } from './selectReportsPageParams'

const defaultReportMapLayers = {
  reportMapLayers: [],
  reportBackgroundRasterLayers: [],
}

export const selectReportMapLayers = createSelector(
  selectAllReportMapLayers,
  selectReportsPageParams,
  (mapLayers, { reportProducts }) => {
    if (!reportProducts) {
      return defaultReportMapLayers
    }

    const allMapLayers = mapLayers.filter(({ mapLayerDefId }) =>
      reportProducts.includes(mapLayerDefId)
    )

    const result = {
      reportMapLayers: allMapLayers,
      reportBackgroundRasterLayers: allMapLayers.filter(
        ({
          mapLayerDef: {
            mapSourceDef: { type },
          },
        }) => type === 'raster-background'
      ),
    }

    return result
  }
)
