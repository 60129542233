import * as React from 'react'
import { classnames } from '../../util/classnames'

interface Props {
  fluid?: boolean
  withLogo?: boolean
}

class ReportSection extends React.PureComponent<Props> {
  render() {
    const { children, fluid, withLogo } = this.props

    return (
      <section
        className={classnames(
          'ReportSection',
          ['ReportSection__fluid', !!fluid],
          ['ReportSection__withLogo', !!withLogo]
        )}
      >
        {children}
      </section>
    )
  }
}

export default ReportSection
