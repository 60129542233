import * as React from 'react'
import { Close, Upload } from '@mui/icons-material'
import {
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import { selectOrganizationId } from '../data/selectOrganizationId'
import { Media } from '../graphql/types'

import { useRedux } from '../hooks/useRedux'
import { getJson } from '../vvapi/apiResource/createApiResource'
import { doUpload } from '../upload/doUpload'
import { LoadingImage } from './LoadingImage'
import i18n, { keys } from '../i18n'

import { getMediaThumbnailUrl } from './util/getUrl'

interface Props {
  currentFiles: Record<string, MediaFile>
  setCurrentFiles: (files: Record<string, MediaFile>) => void
  onUploaded?: () => void
  onLoadImage?: (media: Media[]) => void
  onDeleteMedia?: (media: Media) => void
  onAddMedia?: (media: Media) => void
  fileLimit?: number
  onClickMedia: (mediaId: string | undefined) => void
}

export interface MediaFile {
  url?: string
  media?: Media
  file?: File
}

const FileButton = styled(Button)(() => ({
  justifyContent: 'start',
}))

const AttachmentTitle = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '14.4px',
  letterSpacing: '-0.035em',
  textAlign: 'left',
}))

const ChooseFileText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '14.4px',
  letterSpacing: '-3.5%',
  textAlign: 'center',
  color: '#FFFFFF',
  cursor: 'pointer',
}))

const FileLimitWarning = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '14.4px',
  letterSpacing: '-0.035em',
  textAlign: 'left',
  color: '#FF0000',
}))

const DeleteImageButton = styled(IconButton)((theme) => ({
  position: 'relative',
  top: '-2px',
  right: '0px',
  padding: '0px',
  color: 'black',
  border: '2px solid black',
  borderRadius: '50%',
  zIndex: 1,
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#DDDDDD',
  },
}))

const ImageInput = styled('input')(() => ({
  display: 'none',
}))

const OutlinedImageList = styled(ImageList)(({ theme }) => ({
  '& .MuiImageListItem-root': {
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  display: 'flex',
  overflowX: 'auto',
  position: 'relative',
  maxWidth: '320px',
}))

export const MediaSelector = ({
  currentFiles,
  setCurrentFiles,
  onUploaded,
  onLoadImage,
  onDeleteMedia,
  fileLimit = 10,
  onClickMedia,
  onAddMedia,
}: Props) => {
  const fileRef = React.useRef<HTMLInputElement>(null)
  const [state] = useRedux()
  const [fileLimitWarning, setFileLimitWarning] = React.useState(false)
  const [imageUrls, setImageUrls] = React.useState<Record<string, string>>({})
  const organizationId = selectOrganizationId(state)
  const theme = useTheme()
  const [loadedImages, setLoadedImages] = React.useState(new Set<string>())

  const deleteMedia = async (mediaFile: MediaFile | undefined) => {
    const name = mediaFile?.file?.name ?? mediaFile?.media?.id
    if (!name) {
      console.error('No media file provided')
      return
    }
    const newFiles = { ...currentFiles }
    const fileToDelete = newFiles[name]
    if (!fileToDelete?.media) {
      console.error('File to delete does not exist', name)
      return
    }

    onDeleteMedia && onDeleteMedia(fileToDelete.media)
    console.info('Media file deleted', name)
    delete newFiles[name]
    setCurrentFiles(newFiles)
  }

  const handleUploadClick = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const uploadMediaFiles = Array.from(event.target.files ?? []).map(
        (file) => {
          return {
            file,
            media: undefined,
          }
        }
      )
      const allMediaFiles = Object.values(currentFiles).concat(uploadMediaFiles)

      if (allMediaFiles.length > fileLimit) {
        setFileLimitWarning(true)
        return
      }

      if (uploadMediaFiles && uploadMediaFiles.length > 0) {
        setFileLimitWarning(false)

        const filesByNames: Record<string, MediaFile> = Object.fromEntries(
          allMediaFiles.map((mediaFile) => [
            mediaFile.media?.id ?? mediaFile?.file?.name,
            mediaFile,
          ])
        )
        setCurrentFiles(filesByNames)

        for (const mediaFile of uploadMediaFiles) {
          const { uploadUrl, ...media } = await getJson<
            Media & { uploadUrl: string }
          >(`/media-uploader/photo/upload-url?filename=${mediaFile.file.name}`)
          delete filesByNames[media.originalname]
          filesByNames[media.id] = {
            ...filesByNames[media.id],
            media,
          }

          onAddMedia?.(media)

          setCurrentFiles({ ...filesByNames })

          await doUpload(
            uploadUrl,
            mediaFile.file,
            (progress) => {
              console.log('Progress:', progress)
            },
            'image/jpeg'
          )
        }
        if (onUploaded) {
          onUploaded()
        }
      }
    },
    [
      setFileLimitWarning,
      setCurrentFiles,
      organizationId,
      onUploaded,
      onLoadImage,
      fileLimit,
    ]
  )

  const handleImageLoaded = (mediaId?: string) => {
    if (mediaId) {
      setLoadedImages((prev) => new Set(prev).add(mediaId))
    }
  }

  const handleClick = (mediaId?: string) => {
    if (mediaId) {
      onClickMedia(mediaId)
    }
  }

  React.useEffect(() => {
    for (const mediaFile of Object.values(currentFiles)) {
      if (!mediaFile.media?.id || !loadedImages.has(mediaFile.media.id)) {
        return
      }
    }
    onLoadImage?.(
      Object.values(currentFiles)
        .map((mediaFile) => mediaFile.media)
        .filter((media) => !!media) as Media[]
    )
  }, [loadedImages, currentFiles])

  React.useEffect(() => {
    const setUrls = async () => {
      const urls: Record<string, string> = {}
      for (const mediaFile of Object.values(currentFiles)) {
        if (!mediaFile?.media?.id) {
          continue
        }
        const url = getMediaThumbnailUrl({
          id: mediaFile.media.id,
        })
        if (url) {
          urls[mediaFile.media.id] = url
        }
      }
      console.log('urls', urls)
      setImageUrls(urls)
    }

    setUrls()
  }, [currentFiles, setImageUrls])

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <AttachmentTitle>
          {i18n.t(keys.attachments.attachments)}
        </AttachmentTitle>
        <FileButton
          onClick={() => {
            if (fileRef.current) {
              fileRef.current.click()
            }
          }}
          style={{ backgroundColor: 'transparent' }}
          disableRipple
        >
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Upload sx={{ color: theme.palette.text.primary }} />
              <ChooseFileText>
                {i18n.t(keys.attachments.chooseFile)}
              </ChooseFileText>
            </Stack>
          </Stack>

          <ImageInput
            accept="image/*"
            multiple
            type="file"
            ref={fileRef}
            onChange={handleUploadClick}
          />
        </FileButton>
      </Stack>
      {fileLimitWarning && (
        <FileLimitWarning>
          {i18n.t(keys.attachments.fileLimitWarning)}
        </FileLimitWarning>
      )}
      {Object.keys(currentFiles).length > 0 && (
        <OutlinedImageList cols={1} rowHeight={90} gap={10}>
          {Object.entries(currentFiles)
            .slice(0, 10)
            .map(([key, mediaFile], index) => {
              // console.log('mediaFile', mediaFile)
              return (
                <Tooltip
                  title={
                    mediaFile?.file?.name ?? mediaFile?.media?.originalname
                  }
                >
                  <ImageListItem
                    key={key}
                    onClick={() => handleClick(mediaFile.media?.id)}
                    sx={{
                      cursor: 'pointer',
                      maxWidth: 90,
                      maxHeight: 90,
                      flex: '0 0 auto',
                    }}
                  >
                    <LoadingImage
                      onLoaded={() => handleImageLoaded(mediaFile.media?.id)}
                      src={imageUrls[mediaFile.media?.id ?? '']}
                      alt={
                        mediaFile?.file?.name ?? mediaFile?.media?.originalname
                      }
                      style={{ width: '100%', height: '100%' }}
                    />
                    <ImageListItemBar
                      sx={{ backgroundColor: 'transparent' }}
                      position="top"
                      actionIcon={
                        <DeleteImageButton
                          onClick={async (event) => {
                            event.stopPropagation()
                            await deleteMedia(mediaFile)
                          }}
                        >
                          <Close />
                        </DeleteImageButton>
                      }
                      actionPosition="right"
                    />
                  </ImageListItem>
                </Tooltip>
              )
            })}
        </OutlinedImageList>
      )}
    </Stack>
  )
}
