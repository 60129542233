import * as React from 'react'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import i18n, { keys } from '../i18n'
import EditNotePoint from './EditNotePoint'
import EditNotePolygon from './EditNotePolygon'
import JumpToCurrentPositionButton from './JumpToCurrentPositionButton'
import { Note, NoteFormV2, OrganizationFeature } from '../vvapi/models'
import { useNoteTemplates } from './NotesContext'

interface Props {
  open: boolean
  note?: Pick<Note, 'feature' | 'templateId'> | null
  onNoteChange: (note: OrganizationFeature) => void
  onSave: (event: any) => void
  onCancel: () => void
  mode?: 'create' | 'edit'
}

export const NoteGeometryDialog = ({
  open,
  note,
  onNoteChange,
  onSave,
  onCancel,
  mode = 'create',
}: Props) => {
  const theme = useTheme()
  const [noteTemplatesAsync] = useNoteTemplates()

  const noteTemplates = React.useMemo(
    () => noteTemplatesAsync?.result ?? ({} as Record<string, NoteFormV2>),
    [noteTemplatesAsync.result]
  )

  if (!note || !open) {
    return null
  }

  return (
    <React.Fragment>
      <Card
        raised
        style={{
          overflow: 'initial',
          maxWidth: 450,
          position: 'relative',
          // move out of the way of the AppBar
          marginTop: '56px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <CardHeader
          title={i18n.t(keys.notes.notesPopup.selectLocation)}
        ></CardHeader>
        <CardContent>
          <Stack spacing={1}>
            <Typography variant="body1">
              {note.feature.geometry.type === 'Point' &&
                i18n.t(keys.notes.notesPopup.pointInstruction)}
              {note.feature.geometry.type === 'Polygon' &&
                i18n.t(keys.notes.notesPopup.polygonInstruction)}
            </Typography>
            <JumpToCurrentPositionButton />
          </Stack>
        </CardContent>
        <CardActions>
          <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
            <Button
              fullWidth
              onClick={onCancel}
              sx={{
                minWidth: 0,
                color: theme.palette.getContrastText(
                  theme.palette.background.default
                ),
                background: theme.palette.background.default,
                '&:hover': {
                  color: theme.palette.getContrastText(
                    theme.palette.secondary.main
                  ),
                },
              }}
            >
              {i18n.t(keys.generic.cancel)}
            </Button>
            <Button
              fullWidth
              color="primary"
              onClick={onSave}
              variant="contained"
            >
              {mode === 'create'
                ? i18n.t(keys.generic.create)
                : i18n.t(keys.notes.notesPopup.save)}
            </Button>
          </Stack>
        </CardActions>
      </Card>

      {note.feature.geometry.type === 'Point' && (
        <EditNotePoint
          color={noteTemplates[note.templateId]?.pinColor ?? '#2196F3'}
          noteFeature={note.feature}
          onNoteChange={onNoteChange}
        />
      )}
      {note.feature.geometry.type === 'Polygon' && (
        <EditNotePolygon
          noteFeature={note.feature}
          onNoteChange={onNoteChange}
        />
      )}
    </React.Fragment>
  )
}
