import * as React from 'react'
import { selectSelectedMapLayersById } from '../../data/selectSelectedMapLayers'
import { selectStats } from '../../ProductStats/selectors/stats'
import {
  defaultClickActionFactory,
  defaultMouseEnterActionFactory,
  defaultMouseLeaveActionFactory,
  IMapActionFactory,
} from './map-actions/MapActionFactory'
import { useRedux } from '../../hooks/useRedux'
import { useMap } from '../withMap'
import * as mapboxgl from 'mapbox-gl'
import { SAMPLE_PLAN_POINTS } from '../../pages/SamplePlan/hooks/util/sampleLayerHelper'
import { selectNoteSourceIds } from '../../postgis/selectors/NotesLayerSelector'

interface Props {
  overrideMouseEnterActionFactory?: IMapActionFactory
  overrideMouseLeaveActionFactory?: IMapActionFactory
  overrideClickActionFactory?: IMapActionFactory
}

export const MapEventHandler = ({
  overrideMouseEnterActionFactory,
  overrideMouseLeaveActionFactory,
  overrideClickActionFactory,
}: Props) => {
  const { map } = useMap()
  const [state, dispatch] = useRedux()
  const isMeasureToolActive = state.postgis.isMeasureToolActive
  const isStatsZonesToolActive = state.postgis.isStatsZonesToolActive
  const isCreatingNote = state.notes.isCreatingNote
  const editingNoteId = state.notes.editingNoteId
  const mapLayers = selectSelectedMapLayersById(state)

  const stats = selectStats(state)
  const projectNoteSourceIds = selectNoteSourceIds(state)
  const clickActionFactory =
    overrideClickActionFactory ?? defaultClickActionFactory
  const mouseEnterActionFactory =
    overrideMouseEnterActionFactory ?? defaultMouseEnterActionFactory
  const mouseLeaveActionFactory =
    overrideMouseLeaveActionFactory ?? defaultMouseLeaveActionFactory

  const handleMapMouseEnterNotesLayer = React.useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      if (map) {
        mouseEnterActionFactory.resolveFirst({
          event,
          map,
          dispatch,
          mapLayers,
          stats,
          state,
        })
      }
    },
    [map, dispatch, mapLayers, stats, state]
  )

  const handleMapMouseLeaveNotesLayer = React.useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      if (map) {
        mouseLeaveActionFactory.resolveFirst({
          event,
          map,
          dispatch,
          mapLayers,
          stats,
          state,
        })
      }
    },
    [map, dispatch, mapLayers, stats, state]
  )

  const handleMapClickEvent = React.useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      if (
        isCreatingNote ||
        editingNoteId !== undefined ||
        isMeasureToolActive ||
        isStatsZonesToolActive ||
        !map
      ) {
        return
      }

      event.originalEvent.stopPropagation()

      clickActionFactory.resolveFirst({
        event,
        map,
        dispatch,
        mapLayers,
        stats,
        state,
      })
    },
    [
      isCreatingNote,
      editingNoteId,
      isMeasureToolActive,
      isStatsZonesToolActive,
      map,
      mapLayers,
      stats,
      state,
    ]
  )

  const hoverLayers = React.useMemo(() => {
    return [
      SAMPLE_PLAN_POINTS,
      ...projectNoteSourceIds.map((id) => `${id}-layer`),
    ]
  }, [projectNoteSourceIds])

  React.useEffect(() => {
    if (!map) {
      return
    }

    map.on('click', handleMapClickEvent)
    map.on('mouseenter', hoverLayers, handleMapMouseEnterNotesLayer)
    map.on('mouseleave', hoverLayers, handleMapMouseLeaveNotesLayer)

    return () => {
      map.off('click', handleMapClickEvent)
      map.off('mouseenter', handleMapMouseEnterNotesLayer)
      map.off('mouseleave', handleMapMouseLeaveNotesLayer)
    }
  }, [
    hoverLayers,
    map,
    handleMapClickEvent,
    handleMapMouseEnterNotesLayer,
    handleMapMouseLeaveNotesLayer,
  ])

  return null
}
