import { PureComponent } from 'react'
// tslint:disable-next-line: import-name
import Draw from '@mapbox/mapbox-gl-draw'
import withMap, { WithMap } from '../map/withMap'
import { OrganizationFeature } from '../vvapi/models'

interface Props {
  noteFeature: OrganizationFeature
  onNoteChange: (noteFeature: OrganizationFeature) => void
}

class EditNotePolygon extends PureComponent<Props & WithMap> {
  draw?: Draw

  componentDidMount() {
    const { map } = this.props

    this.draw = new Draw({ displayControlsDefault: false })
    map.addControl(this.draw)

    if (
      this.props.noteFeature.geometry.type === 'Polygon' &&
      this.props.noteFeature.geometry.coordinates.length > 0
    ) {
      const featureIds = this.draw.add(this.props.noteFeature.geometry)
      this.draw.changeMode('simple_select', { featureIds })
    } else {
      this.draw.changeMode('draw_polygon')
    }

    map.on('draw.create', this.handleDrawUpdate)
    map.on('draw.update', this.handleDrawUpdate)
  }

  componentWillUnmount() {
    const { map } = this.props

    map.off('draw.create', this.handleDrawUpdate)
    map.off('draw.update', this.handleDrawUpdate)

    map.removeControl(this.draw!)
  }

  render() {
    return null
  }

  handleDrawUpdate = (event: any) => {
    this.props.onNoteChange({
      ...this.props.noteFeature,
      geometry: event.features[0].geometry,
    })
  }
}

export default withMap(EditNotePolygon)
