import './OrganizationUsers.scss'

import * as React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import { Edit } from '@mui/icons-material'
import {
  Fab,
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material'

import { Column } from '../../../admin/UI/Column'
import { selectPagingInfo, url } from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { refreshGetMe, selectMe } from '../../../data/selectMe'
import { selectOrganizationGroupsById } from '../../../data/selectOrgMapData'
import { useRedux } from '../../../hooks/useRedux'
import i18n, { keys } from '../../../i18n'
import { createTableActionButton } from '../../../UI/Table/createTableActionButton'
import { ORDER } from '../../../UI/Table/orderRows'
import { Table } from '../../../UI/Table/Table'
import { PaginationChange, TableOrder } from '../../../UI/Table/types'
import { AddNewUserDialog } from './AddNewUserDialog'
import { cancelInvitation } from './cancelInvitation'
import { deleteOrganizationUserRole } from './deleteOrganizationUser'
import { EditUserGroupAccessDialog } from './EditUserGroupAccessDialog'
import { resendInvite } from './resendInvite'
import { selectOrganizationRolesById } from './selectOrganizationRoles'
import {
  ListUserAndInvitesData,
  refreshListOrganizationUsersAndInvites,
  selectListOrganizationUsersAndInvites,
  selectOrganizationUsersAndInvites,
  selectOrganizationUsersAndInvitesCount,
  selectOrganizationUsersAndInvitesOrder,
} from './selectUsersAndInvites'
import {
  setToDemoMember,
  setToMember,
  setToOwner,
} from './updateOrganizationUserRole'
import { updateUserOrInviteGroups } from './updateUserOrInviteGroups'
import { useIsLoading } from '../../../hooks/useIsLoading'

export const OrganizationUsers = () => {
  const [state] = useRedux()
  const history = useHistory()
  const match = useRouteMatch()
  const { load, isLoading } = useIsLoading()

  const [editGroupAccessUser, setEditGroupAccessUser] = React.useState<
    ListUserAndInvitesData | undefined
  >(undefined)
  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = React.useState(false)

  const me = selectMe(state)
  const organizationId = state.router.params.organizationId
  const usersAndInvitesSelector = selectListOrganizationUsersAndInvites(state)
  const groupsById = selectOrganizationGroupsById(state)
  const pagination = selectPagingInfo(state)
  const roles = selectOrganizationRolesById(state)
  const usersAndInvites = selectOrganizationUsersAndInvites(state)
  const usersAndInvitesCount = selectOrganizationUsersAndInvitesCount(state)
  const usersAndInvitesOrder = selectOrganizationUsersAndInvitesOrder(state)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    if (event) {
      event.stopPropagation()
    }
    const { pageSize } = info
    handlePaginationChange({ page, pageSize })
  }

  const handleChangeRowsPerPage = (event: any) =>
    handlePaginationChange({
      page: 0,
      pageSize: event.target.value,
    })

  const handlePaginationChange = ({ page, pageSize }: PaginationChange) => {
    history.replace(
      url({ url: match.path }, match.params, {
        page,
        pageSize,
        orderBy: usersAndInvitesOrder && ORDER.serialize(usersAndInvitesOrder),
      })
    )
  }

  const handleChangeUserRole = async (
    userOrInvite: ListUserAndInvitesData,
    ev: SelectChangeEvent
  ) => {
    await load(async () => {
      if (!organizationId || !me) {
        return
      }

      if (userOrInvite.id) {
        const newValue = ev.target.value as string
        const newRole = roles[newValue]
        const currentRole = roles[getUserRole(userOrInvite.roles)]

        if (newRole === currentRole) {
          return
        }

        let response = await swal({
          title: i18n.t(keys.confirmUpdateUserTitle),
          text: i18n.t(keys.confirmUpdateUser, {
            oldRole: currentRole?.name || getUserRole(userOrInvite.roles),

            newRole: newRole?.name || newValue,
          }),

          buttons: {
            cancel: {
              visible: true,
              text: i18n.t(keys.generic.cancel),
            },
            confirm: {
              text: i18n.t(keys.generic.update),
            },
          },
        })

        if (response) {
          if (`${userOrInvite.id}` === me.id && newValue === 'org-member') {
            response = await swal({
              title: i18n.t(keys.confirmUpdateSelfTitle),
              text: i18n.t(keys.confirmUpdateSelf),

              buttons: {
                cancel: {
                  visible: true,
                  text: i18n.t(keys.generic.cancel),
                },
                confirm: {
                  text: i18n.t(keys.generic.continue),
                },
              },
            })
          }

          if (response) {
            if (newValue === 'org-owner') {
              await setToOwner({
                userId: userOrInvite.id,
                organizationId,
              })
            } else if (newValue === 'org-member') {
              await setToMember({
                userId: userOrInvite.id,
                organizationId,
              })
            } else if (
              newValue === 'org-member-demo' &&
              me.roles.some((r) => r === 'admin')
            ) {
              await setToDemoMember({
                userId: userOrInvite.id,
                organizationId,
              })
            } else {
              return
            }
            refresh()
            await swal({ title: i18n.t(keys.confirmUpdateUserSuccess) })
          }
        }
      }
    })
  }

  const resendInvitation = async (invitation: ListUserAndInvitesData) => {
    await load(async () => {
      const response = await swal({
        title: i18n.t(keys.confirmInviteResendTitle),
        text: i18n.t(keys.confirmInviteResend),
        buttons: {
          cancel: {
            visible: true,
            text: i18n.t(keys.generic.cancel),
          },
          confirm: {
            text: i18n.t(keys.resend),
          },
        },
      })

      if (response) {
        await resendInvite({
          organizationId: invitation.organizationId,
          email: invitation.email,
        })
        refresh()
        await swal({ title: i18n.t(keys.confirmInviteResendSuccess) })
      }
    })
  }

  const deleteUserInivitation = async ({ email }: ListUserAndInvitesData) => {
    await load(async () => {
      if (!organizationId || !me) {
        return
      }

      const response = await swal({
        title: i18n.t(keys.confirmUninviteUserTitle),
        text: i18n.t(keys.confirmUninviteUser),
        buttons: {
          cancel: {
            visible: true,
            text: i18n.t(keys.generic.cancel),
          },
          confirm: {
            text: i18n.t(keys.revoke),
          },
        },
      })

      if (response) {
        await cancelInvitation({ email, organizationId })
        refresh()
        await swal({ title: i18n.t(keys.confirmUninviteUserSuccess) })
      }
    })
  }

  const deleteUser = async (user: ListUserAndInvitesData) => {
    await load(async () => {
      if (!organizationId || !me) {
        return
      }

      let response = await swal({
        title: i18n.t(keys.confirmRemoveUserTitle),
        text: i18n.t(keys.confirmRemoveUser),
        buttons: {
          cancel: {
            visible: true,
            text: i18n.t(keys.generic.cancel),
          },
          confirm: {
            text: i18n.t(keys.generic.remove),
          },
        },
      })

      if (response) {
        if (`${user.id}` === me.id) {
          response = await swal({
            title: i18n.t(keys.confirmRemoveSelfTitle),
            text: i18n.t(keys.confirmRemoveSelf),

            buttons: {
              cancel: {
                visible: true,
                text: i18n.t(keys.generic.cancel),
              },
              confirm: {
                text: i18n.t(keys.generic.continue),
              },
            },
          })
        }
        if (response) {
          await deleteOrganizationUserRole(user.id, organizationId)
          refresh()
          await swal({ title: i18n.t(keys.confirmRemoveUserSuccess) })
        }
      }
    })
  }

  const addNewUser = () => {
    setAddNewUserDialogOpen(true)
  }

  const handleAddNewUserDialogClose = () => {
    setAddNewUserDialogOpen(false)
  }

  const handleEditUserOrInviteGroups = async (
    userOrInvite: ListUserAndInvitesData,
    groupIds: string[]
  ) => {
    await load(async () => {
      await updateUserOrInviteGroups(userOrInvite, groupIds)

      setEditGroupAccessUser(undefined)

      refresh()
    })
  }

  const handleOrderChange = (order: TableOrder) => {
    const { pageSize } = info
    history.replace(
      url({ url: match.path }, match.params, {
        page: 0,
        pageSize,
        orderBy: ORDER.serialize(order),
      })
    )
  }

  const getUserRole = (roles: ListUserAndInvitesData['roles']) => {
    if (roles.find((r) => r === 'org-owner')) {
      return 'org-owner'
    }

    if (roles.find((r) => r === 'org-member-demo')) {
      return 'org-member-demo'
    }

    return 'org-member'
  }

  const refresh = () => {
    refreshGetMe()
    refreshListOrganizationUsersAndInvites()
  }

  const info = React.useMemo(() => {
    return {
      ...pagination,
      count: usersAndInvitesCount,
      order: usersAndInvitesOrder,
    }
  }, [])

  const tablePagination = React.useMemo(() => {
    const { page, pageSize, count } = info

    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={count}
        page={page}
        rowsPerPage={pageSize}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    )
  }, [info])

  return (
    <>
      <EditUserGroupAccessDialog
        onSubmit={handleEditUserOrInviteGroups}
        userOrInvite={editGroupAccessUser}
        onCancel={() => setEditGroupAccessUser(undefined)}
      />
      <AsyncSelectorStatusOverlay
        requests={usersAndInvitesSelector}
        isLoading={isLoading}
      >
        <Column style={{ width: '100%', alignItems: 'stretch' }}>
          {organizationId !== undefined && (
            <AddNewUserDialog
              organizationId={organizationId}
              open={addNewUserDialogOpen}
              onClose={handleAddNewUserDialogClose}
            />
          )}
          {tablePagination}
          <Table
            order={usersAndInvitesOrder}
            onOrderChange={handleOrderChange}
            rows={usersAndInvites}
            formatter={[
              {
                header: () => i18n.t(keys.user.firstName),
                data: (userOrInvite) => {
                  switch (userOrInvite.status) {
                    case 'accepted':
                      return userOrInvite.firstName
                    case 'invited':
                      return <i>{i18n.t(keys.invitationPending)}</i>
                    case 'expired':
                      return <i>{i18n.t(keys.invitationExpired)}</i>
                  }

                  return null
                },

                span: (userOrInvite) => {
                  return userOrInvite.id !== null ? 1 : 2
                },
                orderBy: (dir) => `{ firstName: ${dir} }`,
              },
              {
                header: () => i18n.t(keys.user.lastName),
                data: (userOrInvite) =>
                  !!userOrInvite.lastName ? userOrInvite.lastName : '',
                span: (userOrInvite) => {
                  return userOrInvite.id !== null ? 1 : 0
                },
                orderBy: (dir) => `{ lastName: ${dir} }`,
              },
              {
                header: () => i18n.t(keys.user.email),
                data: ({ email }) => email,
                orderBy: (dir) => `{ email: ${dir} }`,
              },
              {
                header: () => i18n.t(keys.user.role),
                data: (userOrInvite) => {
                  const userRole = getUserRole(userOrInvite.roles)
                  const role = roles[userRole]

                  return (
                    <Select
                      disabled={
                        userOrInvite.status !== 'accepted' ||
                        (!me?.roles.some((ar) => ar === 'admin') &&
                          userRole === 'org-member-demo')
                      }
                      className={`role-select ${
                        userOrInvite.status !== 'accepted' && 'disabled'
                      }`}
                      style={{ width: '100%' }}
                      value={userRole}
                      onChange={(ev) => handleChangeUserRole(userOrInvite, ev)}
                      renderValue={() => role?.name}
                    >
                      {Object.values(roles)
                        // If an admin, the user can be changed to an demo member.
                        .filter(
                          (r) =>
                            me?.roles.some((ar) => ar === 'admin') ||
                            r.id !== 'org-member-demo'
                        )
                        .map(({ id, name, description }) => (
                          <MenuItem key={id} value={id}>
                            <div>
                              <Typography variant="body1">{name}</Typography>
                              <Typography variant="caption">
                                {description}
                              </Typography>
                            </div>
                          </MenuItem>
                        ))}
                    </Select>
                  )
                },
                orderBy: (dir) => `{ roles: ${dir} }`,
              },
              {
                header: () => i18n.t(keys.groups),
                data: (userOrInvite) => {
                  return (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <div>
                        {userOrInvite.groupIds.length === 0
                          ? i18n.t(keys.allGroups)
                          : userOrInvite.groupIds.length === 1
                          ? userOrInvite.groupIds.map(
                              (groupId) => groupsById?.[groupId]?.name
                            )
                          : `${userOrInvite.groupIds.length} ${i18n.t(
                              keys.groups
                            )}`}
                      </div>
                      <Tooltip title={i18n.t(keys.editGroupAccess)}>
                        <IconButton
                          size="small"
                          onClick={() => setEditGroupAccessUser(userOrInvite)}
                        >
                          <Edit fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )
                },
                orderBy: (dir) => `{ roles: ${dir} }`,
              },
            ]}
            actions={[
              {
                selectionRequired: true,
                button: createTableActionButton({
                  title: i18n.t(keys.removeUser),
                  children: <Icon>delete</Icon>,
                  variant: 'outlined',
                  color: 'secondary',
                  style: {
                    marginTop: 8,
                    marginBottom: 8,
                    padding: 8,
                    minWidth: 0,
                  },
                  key: 'delete-user',
                }),
                visible: ([userOrInvite]) => userOrInvite.id !== null,

                action: ([userOrInvite]) => deleteUser(userOrInvite),
              },
              {
                selectionRequired: true,
                button: createTableActionButton({
                  title: i18n.t(keys.uninviteUser),
                  children: <Icon>block</Icon>,
                  variant: 'outlined',
                  color: 'secondary',
                  style: { margin: 8, padding: 8, minWidth: 0 },
                  key: 'uninvite',
                }),
                visible: ([userOrInvite]) =>
                  userOrInvite.status === 'invited' ||
                  userOrInvite.status === 'expired',
                action: ([userOrInvite]) => deleteUserInivitation(userOrInvite),
              },
              {
                selectionRequired: true,
                button: createTableActionButton({
                  title: i18n.t(keys.resendInvite),
                  children: <Icon>send</Icon>,
                  variant: 'outlined',
                  color: 'primary',
                  style: {
                    padding: 8,
                    minWidth: 0,
                    marginTop: 8,
                    marginBottom: 8,
                  },
                  key: 'resend-invite',
                }),
                visible: ([userOrInvite]) =>
                  userOrInvite.status === 'invited' ||
                  userOrInvite.status === 'expired',
                action: ([userOrInvite]) => resendInvitation(userOrInvite),
              },
            ]}
          />
          {tablePagination}
          <Tooltip
            title={i18n.t(keys.addNewUser)}
            placement="right"
            style={{ marginTop: 8, marginBottom: 8, alignSelf: 'flex-end' }}
          >
            <Fab color="primary" onClick={addNewUser}>
              <Icon>add</Icon>
            </Fab>
          </Tooltip>
        </Column>
      </AsyncSelectorStatusOverlay>
    </>
  )
}

// class OrganizationUsersClass extends React.Component<
//   ReduxProps & RouteComponentProps<RouteParams>,
//   State
// > {
//   state: State = { addNewUserDialogOpen: false }

//   render() {
//     const {
//       usersAndInvitesSelector,
//       usersAndInvites,
//       roles,
//       me,
//       organizationId,
//       usersAndInvitesOrder,
//     } = this.props
//     const { addNewUserDialogOpen } = this.state

//     if (!me || !organizationId) {
//       return null
//     }

//     const pagination = this.renderPagination()

//     return (
//       <>
//         <EditUserGroupAccessDialog
//           onSubmit={this.handleEditUserOrInviteGroups}
//           userOrInvite={this.state.editGroupAccessUser}
//           onCancel={() => this.setState({ editGroupAccessUser: undefined })}
//         />
//         <AsyncSelectorStatusOverlay requests={usersAndInvitesSelector}>
//           <Column style={{ width: '100%', alignItems: 'stretch' }}>
//             <AddNewUserDialog
//               organizationId={organizationId}
//               open={addNewUserDialogOpen}
//               onClose={this.handleAddNewUserDialogClose}
//             />
//             {pagination}
//             <Table
//               order={usersAndInvitesOrder}
//               onOrderChange={this.handleOrderChange}
//               rows={usersAndInvites}
//               formatter={[
//                 {
//                   header: () => i18n.t(keys.user.firstName),
//                   data: (userOrInvite) => {
//                     switch (userOrInvite.status) {
//                       case 'accepted':
//                         return userOrInvite.firstName
//                       case 'invited':
//                         return <i>{i18n.t(keys.invitationPending)}</i>
//                       case 'expired':
//                         return <i>{i18n.t(keys.invitationExpired)}</i>
//                     }

//                     return null
//                   },

//                   span: (userOrInvite) => {
//                     return userOrInvite.id !== null ? 1 : 2
//                   },
//                   orderBy: (dir) => `{ firstName: ${dir} }`,
//                 },
//                 {
//                   header: () => i18n.t(keys.user.lastName),
//                   data: (userOrInvite) =>
//                     !!userOrInvite.lastName ? userOrInvite.lastName : '',
//                   span: (userOrInvite) => {
//                     return userOrInvite.id !== null ? 1 : 0
//                   },
//                   orderBy: (dir) => `{ lastName: ${dir} }`,
//                 },
//                 {
//                   header: () => i18n.t(keys.user.email),
//                   data: ({ email }) => email,
//                   orderBy: (dir) => `{ email: ${dir} }`,
//                 },
//                 {
//                   header: () => i18n.t(keys.user.role),
//                   data: (userOrInvite) => {
//                     const userRole = this.getUserRole(userOrInvite.roles)
//                     const role = roles[userRole]

//                     return (
//                       <Select
//                         disabled={
//                           userOrInvite.status !== 'accepted' ||
//                           (!me.roles.some((ar) => ar === 'admin') &&
//                             userRole === 'org-member-demo')
//                         }
//                         className={`role-select ${
//                           userOrInvite.status !== 'accepted' && 'disabled'
//                         }`}
//                         style={{ width: '100%' }}
//                         value={userRole}
//                         onChange={(ev) =>
//                           this.handleChangeUserRole(userOrInvite, ev)
//                         }
//                         renderValue={() => role?.name}
//                       >
//                         {Object.values(roles)
//                           // If an admin, the user can be changed to an demo member.
//                           .filter(
//                             (r) =>
//                               me.roles.some((ar) => ar === 'admin') ||
//                               r.id !== 'org-member-demo'
//                           )
//                           .map(({ id, name, description }) => (
//                             <MenuItem key={id} value={id}>
//                               <div>
//                                 <Typography variant="body1">{name}</Typography>
//                                 <Typography variant="caption">
//                                   {description}
//                                 </Typography>
//                               </div>
//                             </MenuItem>
//                           ))}
//                       </Select>
//                     )
//                   },
//                   orderBy: (dir) => `{ roles: ${dir} }`,
//                 },
//                 {
//                   header: () => i18n.t(keys.groups),
//                   data: (userOrInvite) => {
//                     return (
//                       <Stack direction="row" spacing={1} alignItems="center">
//                         <div>
//                           {userOrInvite.groupIds.length === 0
//                             ? i18n.t(keys.allGroups)
//                             : userOrInvite.groupIds.length === 1
//                             ? userOrInvite.groupIds.map(
//                                 (groupId) =>
//                                   this.props.groupsById[groupId]?.name
//                               )
//                             : `${userOrInvite.groupIds.length} ${i18n.t(
//                                 keys.groups
//                               )}`}
//                         </div>
//                         <Tooltip title={i18n.t(keys.editGroupAccess)}>
//                           <IconButton
//                             size="small"
//                             onClick={() =>
//                               this.setState({
//                                 editGroupAccessUser: userOrInvite,
//                               })
//                             }
//                           >
//                             <Edit fontSize="inherit" />
//                           </IconButton>
//                         </Tooltip>
//                       </Stack>
//                     )
//                   },
//                   orderBy: (dir) => `{ roles: ${dir} }`,
//                 },
//               ]}
//               actions={[
//                 {
//                   selectionRequired: true,
//                   button: createTableActionButton({
//                     title: i18n.t(keys.removeUser),
//                     children: <Icon>delete</Icon>,
//                     variant: 'outlined',
//                     color: 'secondary',
//                     style: {
//                       marginTop: 8,
//                       marginBottom: 8,
//                       padding: 8,
//                       minWidth: 0,
//                     },
//                     key: 'delete-user',
//                   }),
//                   visible: ([userOrInvite]) => userOrInvite.id !== null,

//                   action: ([userOrInvite]) => this.deleteUser(userOrInvite),
//                 },
//                 {
//                   selectionRequired: true,
//                   button: createTableActionButton({
//                     title: i18n.t(keys.uninviteUser),
//                     children: <Icon>block</Icon>,
//                     variant: 'outlined',
//                     color: 'secondary',
//                     style: { margin: 8, padding: 8, minWidth: 0 },
//                     key: 'uninvite',
//                   }),
//                   visible: ([userOrInvite]) =>
//                     userOrInvite.status === 'invited',
//                   action: ([userOrInvite]) =>
//                     this.deleteUserInivitation(userOrInvite),
//                 },
//                 {
//                   selectionRequired: true,
//                   button: createTableActionButton({
//                     title: i18n.t(keys.resendInvite),
//                     children: <Icon>send</Icon>,
//                     variant: 'outlined',
//                     color: 'primary',
//                     style: {
//                       padding: 8,
//                       minWidth: 0,
//                       marginTop: 8,
//                       marginBottom: 8,
//                     },
//                     key: 'resend-invite',
//                   }),
//                   visible: ([userOrInvite]) =>
//                     userOrInvite.status === 'invited' ||
//                     userOrInvite.status === 'expired',
//                   action: ([userOrInvite]) =>
//                     this.resendInvitation(userOrInvite),
//                 },
//               ]}
//             />
//             {pagination}
//             <Tooltip
//               title={i18n.t(keys.addNewUser)}
//               placement="right"
//               style={{ marginTop: 8, marginBottom: 8, alignSelf: 'flex-end' }}
//             >
//               <Fab color="primary" onClick={this.addNewUser}>
//                 <Icon>add</Icon>
//               </Fab>
//             </Tooltip>
//           </Column>
//         </AsyncSelectorStatusOverlay>
//       </>
//     )
//   }

//   handleChangeUserRole = async (
//     userOrInvite: ListUserAndInvitesData,
//     ev: SelectChangeEvent
//   ) => {
//     const { roles, organizationId, me } = this.props
//     if (!organizationId || !me) {
//       return
//     }

//     if (userOrInvite.id) {
//       const newValue = ev.target.value as string
//       const newRole = roles[newValue]
//       const currentRole = roles[this.getUserRole(userOrInvite.roles)]

//       if (newRole === currentRole) {
//         return
//       }

//       let response = await swal({
//         title: i18n.t(keys.confirmUpdateUserTitle),
//         text: i18n.t(keys.confirmUpdateUser, {
//           oldRole: currentRole?.name || this.getUserRole(userOrInvite.roles),

//           newRole: newRole?.name || newValue,
//         }),

//         buttons: {
//           cancel: {
//             visible: true,
//             text: i18n.t(keys.generic.cancel),
//           },
//           confirm: {
//             text: i18n.t(keys.generic.update),
//           },
//         },
//       })

//       if (response) {
//         if (`${userOrInvite.id}` === me.id && newValue === 'org-member') {
//           response = await swal({
//             title: i18n.t(keys.confirmUpdateSelfTitle),
//             text: i18n.t(keys.confirmUpdateSelf),

//             buttons: {
//               cancel: {
//                 visible: true,
//                 text: i18n.t(keys.generic.cancel),
//               },
//               confirm: {
//                 text: i18n.t(keys.generic.continue),
//               },
//             },
//           })
//         }

//         if (response) {
//           if (newValue === 'org-owner') {
//             await setToOwner({
//               userId: userOrInvite.id,
//               organizationId,
//             })
//           } else if (newValue === 'org-member') {
//             await setToMember({
//               userId: userOrInvite.id,
//               organizationId,
//             })
//           } else if (
//             newValue === 'org-member-demo' &&
//             me.roles.some((r) => r === 'admin')
//           ) {
//             await setToDemoMember({
//               userId: userOrInvite.id,
//               organizationId,
//             })
//           } else {
//             return
//           }
//           this.refresh()
//           await swal({ title: i18n.t(keys.confirmUpdateUserSuccess) })
//         }
//       }
//     }
//   }

//   resendInvitation = async (invitation: ListUserAndInvitesData) => {
//     const response = await swal({
//       title: i18n.t(keys.confirmInviteResendTitle),
//       text: i18n.t(keys.confirmInviteResend),
//       buttons: {
//         cancel: {
//           visible: true,
//           text: i18n.t(keys.generic.cancel),
//         },
//         confirm: {
//           text: i18n.t(keys.resend),
//         },
//       },
//     })

//     if (response) {
//       await resendInvite({
//         organizationId: invitation.organizationId,
//         email: invitation.email,
//       })
//       this.refresh()
//       await swal({ title: i18n.t(keys.confirmInviteResendSuccess) })
//     }
//   }

//   deleteUserInivitation = async ({ email }: ListUserAndInvitesData) => {
//     const { organizationId, me } = this.props
//     if (!organizationId || !me) {
//       return
//     }

//     const response = await swal({
//       title: i18n.t(keys.confirmUninviteUserTitle),
//       text: i18n.t(keys.confirmUninviteUser),
//       buttons: {
//         cancel: {
//           visible: true,
//           text: i18n.t(keys.generic.cancel),
//         },
//         confirm: {
//           text: i18n.t(keys.revoke),
//         },
//       },
//     })

//     if (response) {
//       await cancelInvitation({ email, organizationId })
//       this.refresh()
//       await swal({ title: i18n.t(keys.confirmUninviteUserSuccess) })
//     }
//   }

//   deleteUser = async (user: ListUserAndInvitesData) => {
//     const { organizationId, me } = this.props
//     if (!organizationId || !me) {
//       return
//     }

//     let response = await swal({
//       title: i18n.t(keys.confirmRemoveUserTitle),
//       text: i18n.t(keys.confirmRemoveUser),
//       buttons: {
//         cancel: {
//           visible: true,
//           text: i18n.t(keys.generic.cancel),
//         },
//         confirm: {
//           text: i18n.t(keys.generic.remove),
//         },
//       },
//     })

//     if (response) {
//       if (`${user.id}` === me.id) {
//         response = await swal({
//           title: i18n.t(keys.confirmRemoveSelfTitle),
//           text: i18n.t(keys.confirmRemoveSelf),

//           buttons: {
//             cancel: {
//               visible: true,
//               text: i18n.t(keys.generic.cancel),
//             },
//             confirm: {
//               text: i18n.t(keys.generic.continue),
//             },
//           },
//         })
//       }
//       if (response) {
//         await deleteOrganizationUserRole(user.id, organizationId)
//         this.refresh()
//         await swal({ title: i18n.t(keys.confirmRemoveUserSuccess) })
//       }
//     }
//   }

//   addNewUser = () => {
//     this.setState({ addNewUserDialogOpen: true })
//   }

//   handleAddNewUserDialogClose = () => {
//     this.setState({ addNewUserDialogOpen: false })
//   }

//   handleEditUserOrInviteGroups = async (
//     userOrInvite: ListUserAndInvitesData,
//     groupIds: string[]
//   ) => {
//     await updateUserOrInviteGroups(userOrInvite, groupIds)

//     this.setState({ editGroupAccessUser: undefined })

//     this.refresh()
//   }

//   handleOrderChange = (order: TableOrder) => {
//     const { pageSize } = this.getInfo()
//     this.props.history.replace(
//       url({ url: this.props.match.path }, this.props.match.params, {
//         page: 0,
//         pageSize,
//         orderBy: ORDER.serialize(order),
//       })
//     )
//   }

//   handleChangePage = (
//     event: React.MouseEvent<HTMLButtonElement> | null,
//     page: number
//   ) => {
//     if (event) {
//       event.stopPropagation()
//     }
//     const { pageSize } = this.getInfo()
//     this.handlePaginationChange({ page, pageSize })
//   }

//   handleChangeRowsPerPage = (event: any) =>
//     this.handlePaginationChange({
//       page: 0,
//       pageSize: event.target.value,
//     })

//   handlePaginationChange = ({ page, pageSize }: PaginationChange) => {
//     this.props.history.replace(
//       url({ url: this.props.match.path }, this.props.match.params, {
//         page,
//         pageSize,
//         orderBy:
//           this.props.usersAndInvitesOrder &&
//           ORDER.serialize(this.props.usersAndInvitesOrder),
//       })
//     )
//   }

//   renderPagination = () => {
//     const { page, pageSize, count } = this.getInfo()

//     return (
//       <TablePagination
//         rowsPerPageOptions={[5, 10, 25, 50]}
//         component="div"
//         count={count}
//         page={page}
//         rowsPerPage={pageSize}
//         backIconButtonProps={{
//           'aria-label': 'Previous Page',
//         }}
//         nextIconButtonProps={{
//           'aria-label': 'Next Page',
//         }}
//         onPageChange={this.handleChangePage}
//         onRowsPerPageChange={this.handleChangeRowsPerPage}
//       />
//     )
//   }

//   getInfo = () => {
//     return {
//       ...this.props.pagination,
//       count: this.props.usersAndInvitesCount,
//       order: this.props.usersAndInvitesOrder,
//     }
//   }

//   getUserRole = (roles: ListUserAndInvitesData['roles']) => {
//     if (roles.find((r) => r === 'org-owner')) {
//       return 'org-owner'
//     }

//     if (roles.find((r) => r === 'org-member-demo')) {
//       return 'org-member-demo'
//     }

//     return 'org-member'
//   }

//   refresh = () => {
//     refreshGetMe()
//     refreshListOrganizationUsersAndInvites()
//   }
// }

// const mapState = (state: RootStore) => ({
//   me: selectMe(state),
//   organizationId: state.router.params.organizationId,
//   usersAndInvitesSelector: selectListOrganizationUsersAndInvites(state),
//   groupsById: selectOrganizationGroupsById(state),
//   // users: selectOrganizationUsers(state),
//   pagination: selectPagingInfo(state),
//   roles: selectOrganizationRolesById(state),
//   usersAndInvites: selectOrganizationUsersAndInvites(state),
//   usersAndInvitesCount: selectOrganizationUsersAndInvitesCount(state),
//   usersAndInvitesOrder: selectOrganizationUsersAndInvitesOrder(state),
// })

// type ReduxProps = ReturnType<typeof mapState>

// export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
//   OrganizationUsers
// )
