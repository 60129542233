import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import {
  getDefaultPageInfo,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../graphql/api'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { createTableActionIconButton } from '../../UI/Table/createTableActionIconButton'
import { ORDER } from '../../UI/Table/orderRows'
import { Table } from '../../UI/Table/Table'
import { PaginationChange, TableAction, TableOrder } from '../../UI/Table/types'
import MapDataHome from '../mapdata/MapDataHome'
import HelperText from '../mapdata/utils/HelperText'
import {
  ListMapSourceDefsData,
  refreshListMapSourceDefs,
  selectListMapSourceDefs,
} from './selectListMapSourceDefs'
import { tableFormatters } from './tableFormatters'

interface State {
  selection: ListMapSourceDefsData[]
}

class ListMapSourceDefs extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps,
  State
> {
  state: State = {
    selection: [],
  }

  actions: TableAction<ListMapSourceDefsData>[] = [
    {
      button: createTableActionIconButton({
        title: 'Refresh',
        icon: 'autorenew',
        key: 'refresh-map-source-defs',
      }),
      action: () => {
        refreshListMapSourceDefs()
      },
    },
    {
      button: createTableActionIconButton({
        title: 'Create MapSourceDef',
        icon: 'add',
        key: 'create-map-source-def',
      }),
      action: () => {
        this.props.history.push(url(urls.newSourceDef))
      },
    },
    {
      selectionRequired: true,
      button: createTableActionIconButton({
        title: 'Edit MapSourceDef',
        icon: 'edit',
        key: 'edit-map-source-def',
      }),
      action: ([{ id: mapSourceDefId }]) => {
        this.props.history.push(url(urls.editSourceDef, { mapSourceDefId }))
      },
    },
    {
      multi: true,
      selectionRequired: true,
      button: createTableActionIconButton({
        title: 'Delete Selected MapSourceDefs',
        icon: 'delete',
        key: 'delete-map-source-defs',
      }),
      action: async (selection) => {
        const choice = await swal(
          `You are about to delete ${selection.length} source definition(s)`,
          {
            buttons: {
              cancel: true,
              confirm: {
                text: 'Delete',
              },
            },
            dangerMode: true,
          }
        )

        // swal returns null for "cancel"
        if (!choice) {
          return
        }

        for (const { id } of selection) {
          await api.mapSourceDef.delete({ pk: { id } })
        }

        this.setState({
          selection: [],
        })

        refreshListMapSourceDefs()
      },
    },
  ]

  render() {
    const { listMapSourceDefs } = this.props
    const { data = [] } = listMapSourceDefs.data ?? {}
    const { selection } = this.state

    const { order, filter, ...pagination } = getDefaultPageInfo(
      listMapSourceDefs.data
    )

    return (
      <MapDataHome>
        <div id="ListMapSourceDefs" className="Paper">
          <HelperText>
            These are file definitions; there should be one definition for each
            file that you plan to upload. You must define the properties that
            each file includes.
          </HelperText>
          <AsyncSelectorStatusOverlay requests={listMapSourceDefs}>
            <Table
              title="MapSourceDefs"
              rows={data}
              actions={this.actions}
              formatter={tableFormatters}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              pagination={pagination}
              onPaginationChange={this.handlePaginationChange}
              selection={selection}
              onSelectionChange={this.handleSelectionChange}
              order={order}
              onOrderChange={this.handleOrderChange}
            />
          </AsyncSelectorStatusOverlay>
        </div>
      </MapDataHome>
    )
  }

  getURLUpdate = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) => {
    const { listMapSourceDefs } = this.props
    const {
      pageSize: dPageSize,
      page: dPage,
      order: dOrder,
    } = getDefaultPageInfo(listMapSourceDefs.data)

    const {
      page = dPage,
      pageSize = dPageSize,
      orderBy = dOrder && ORDER.serialize(dOrder),
    } = options

    return { page, pageSize, orderBy }
  }

  updateURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) =>
    this.props.history.push(
      url(urls.listSourceDefs, {}, this.getURLUpdate(options))
    )

  replaceURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) =>
    this.props.history.replace(
      url(urls.listSourceDefs, {}, this.getURLUpdate(options))
    )

  handleSelectionChange = (selection: State['selection']) =>
    this.setState({ selection })

  handlePaginationChange = (pagination: PaginationChange) =>
    this.updateURL(pagination)

  handleOrderChange = (order: TableOrder) =>
    this.replaceURL({ page: 0, orderBy: ORDER.serialize(order) })
}

const mapState = (state: RootStore) => ({
  listMapSourceDefs: selectListMapSourceDefs(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(ListMapSourceDefs)
)
