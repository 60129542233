import { CheckboxProps } from '@mui/material/Checkbox'
import { SwitchProps } from '@mui/material/Switch'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { ControlLabelFieldProps } from '../createControlLabelField'
import SelectField, { SelectFieldProps } from '../SelectField'
import { CheckboxField } from './formFields'
import wrapComponentWithField, { MapRFProps } from './wrapComponentWithField'

const defaultMapRFProps: MapRFProps = (props) => {
  const { input, meta, ...rest } = props

  return {
    helperText: meta.error,
    error: Boolean(meta.touched && meta.error),
    ...input,
    ...rest,
  }
}

const mapCheckboxProps: MapRFProps<
  ControlLabelFieldProps<SwitchProps | CheckboxProps>
> = (props) => ({
  ...defaultMapRFProps(props),
  controlProps: {
    checked: !!props.input.value,
    onChange: (event, isChecked) => props.input.onChange(isChecked),

    onBlur: props.input.onBlur,
    onFocus: props.input.onFocus,
  },
})

// tslint:disable:variable-name
export const RFTextField = wrapComponentWithField<TextFieldProps>(
  TextField,
  defaultMapRFProps
)
export const RFCheckboxField = wrapComponentWithField<
  ControlLabelFieldProps<CheckboxProps> & JSX.IntrinsicAttributes
>(CheckboxField, mapCheckboxProps)
export const RFSelectField = wrapComponentWithField<
  SelectFieldProps & JSX.IntrinsicAttributes
>(SelectField, (props) => ({
  ...defaultMapRFProps(props),
  onChange: (event) => props.input.onChange(event.target.value),
  onBlur: (ev) => props.input.onBlur(ev),
}))
// tslint:enable:variable-name
