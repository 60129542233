import * as React from 'react'

import { connect } from '../../redux/connect'
import { dismissFeedback as dismiss } from '../../redux/notifications/redux'

import { AppDispatchProps, RootStore } from '../../redux/types'
import { DefaultNotification } from './DefaultNotification'

const Feedback = (props: ReduxProps & AppDispatchProps) => {
  const { feedback, dispatch } = props

  const [open, setOpen] = React.useState(true)

  // show most recent by grabbling end of the list. 
  const currentFeedback = feedback[feedback.length - 1]

  const dismissNotification = (index: number) => {
    setOpen(false)
    setTimeout(() => {
      dispatch(
        dismiss(index, { appNotificationId: feedback[index].appNotificationId })
      )
      setOpen(true)
    }, 300)
  }

  if (!currentFeedback) {
    return null
  }

  return (
    <DefaultNotification
      open={open}
      currentNotification={currentFeedback}
      dismissNotification={dismissNotification}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    />
  )
}

const mapState = (state: RootStore) => ({
  feedback: state.notifications.feedback,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
    Feedback
)
