import { createSelector } from 'reselect'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import i18n, { keys } from '../../i18n'
import { selectProductSettings } from '../../ProductSettings/store/selectors/selectProductSettings'

export const selectVisualizationName = createSelector(
  selectSelectedLegendProductId,
  selectProductSettings,
  (selectedLegendProductId, productSettings) => {
    const settings = productSettings[selectedLegendProductId]
    let visualizationName = ''

    if (!settings) {
      return {
        visualizationName,
        filtered: '',
      }
    }

    const { visualization } = settings

    visualizationName = i18n.t(keys.visualization.relative).toLocaleLowerCase()

    if (visualization === 'absolute') {
      visualizationName = i18n
        .t(keys.visualization.absolute)
        .toLocaleLowerCase()
    } else if (visualization === 'threshold') {
      visualizationName = i18n
        .t(keys.visualization.threshold)
        .toLocaleLowerCase()
    } else if (
      visualization === 'relative' &&
      settings.numberOfGroups &&
      settings.numberOfGroups > 1
    ) {
      visualizationName = `${i18n
        .t(keys.visualization.relative)
        .toLocaleLowerCase()}: ${
        settings.groupBy === 'equal-areas'
          ? i18n.t(keys.visualization.equalAreas).toLocaleLowerCase()
          : i18n.t(keys.visualization.valueRanges).toLocaleLowerCase()
      }`
    }

    return {
      visualizationName,
      filtered: `(${visualizationName}${
        settings.filterEnabled
          ? ` - ${i18n.t(keys.visualization.filter).toLocaleLowerCase()}`
          : ''
      })`,
    }
  }
)
