import { createSelector } from 'reselect'
import { indexArray } from '../util/indexArray'
import { selectFilteredMapSources } from './selectFilteredMapSources'

export const selectFilteredMapSourcesById = createSelector(
  [selectFilteredMapSources],
  (mapSources) => {
    const indexed = indexArray(mapSources, 'id')

    return indexed as Partial<typeof indexed>
  }
)
