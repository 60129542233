import { Notification } from '../graphql/types'
import { getJson } from '../vvapi/apiResource/createApiResource'

const fetchUserAppNotifications = async (
  [userId]: [userId: number],
  skip: () => void
) => {
  if (!userId) {
    return skip()
  }

  // get the notifications.
  const { notifications } = await getJson<{ notifications: Notification[] }>(
    `/api/v3/notifications`
  )

  return notifications
}

export { fetchUserAppNotifications }
