// tslint:disable-next-line: import-name
import pointInPolygon from '@turf/boolean-point-in-polygon'
import intersect from '@turf/intersect'
import { ParcelData } from '../data/types'
import { OrgFeatureGeometry } from '../vvapi/models'

const getContainingParcelId = (
  geometry: OrgFeatureGeometry,
  parcelsById?: Record<string, ParcelData>
) => {
  if (!geometry || !parcelsById) {
    return
  }

  for (const parcelId of Object.keys(parcelsById)) {
    const parcel = parcelsById[parcelId]
    if (geometry.type === 'Point') {
      if (pointInPolygon(geometry, parcel.geometry)) {
        return parcel.parcelId
      }
    } else if (geometry.type === 'Polygon') {
      if (intersect(geometry, parcel.geometry)) {
        return parcel.parcelId
      }
    }
  }

  return
}

export default getContainingParcelId
