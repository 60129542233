import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import './ShallowTable.scss'

type Meta = Record<string, string | number | undefined>

interface Props {
  data: Meta
  title?: string
  style?: React.CSSProperties
}

class ShallowTable extends React.PureComponent<Props> {
  render() {
    const { title, data } = this.props

    return (
      <Table className="ShallowTable" style={this.props.style}>
        <TableBody>
          {title && (
            <TableRow className="ShallowTable__header">
              <TableCell colSpan={2}>{title.toUpperCase()}</TableCell>
            </TableRow>
          )}
          {this.renderObjectToRows(data)}
        </TableBody>
      </Table>
    )
  }

  renderObjectToRows = (obj: Meta) => {
    return Object.keys(obj)
      .sort()
      .map((key) => {
        const value = obj[key]

        if (typeof value === 'object') {
          // ignores more nested-ness
          return null
        }

        return (
          <TableRow className="ShallowTable__row" key={key}>
            <TableCell>{key}</TableCell>
            <TableCell>{value}</TableCell>
          </TableRow>
        )
      })
  }
}

export default ShallowTable
