import './PipelineGraph.scss'
import * as React from 'react'
import { Graphviz } from 'graphviz-react'

import { Job, JobAttempt, Pipeline } from '../../graphql/types'
import { getStatusIcon } from './utils'

const defaultGraph = `digraph{}`

export interface JobDependency {
  jobId: string
  dependsOnJobId: string
}
export type Direction = 'horizontal' | 'vertical'

interface Props {
  pipelineName: string
  jobDependencies: JobDependency[]
  jobs: Job[]
  direction?: Direction
  selectedJobId?: string
  jobClicked?: (id: string) => void
  pipelineClicked?: (id: string) => void
}

const findJobOrPipeline = (
  node?: Element | null
): { jobId: string } | { pipelineId: string } | undefined => {
  if (!node) {
    return
  }

  const parent = node.parentNode as Element

  if (!parent) {
    return
  }

  const cn = ((parent.className ?? {}) as any).baseVal as string | undefined

  if (cn) {
    if (cn.includes('job')) {
      return { jobId: parent.children[0].innerHTML }
    }

    if (cn.includes('pipeline')) {
      return { pipelineId: parent.children[0].innerHTML }
    }
  }

  return findJobOrPipeline(parent)
}

class PipelineGraph extends React.Component<Props> {
  containerRef = React.createRef<HTMLDivElement>()

  handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    const { jobClicked, pipelineClicked } = this.props
    const node = findJobOrPipeline(ev.target as Element)

    if (node) {
      if ('jobId' in node && jobClicked) {
        jobClicked(node.jobId)
      } else if ('pipelineId' in node && pipelineClicked) {
        pipelineClicked(node.pipelineId)
      }
    }
  }

  render() {
    const { jobs, jobDependencies, direction, pipelineName, selectedJobId } =
      this.props

    const graph = jobDependenciesToDOT(
      pipelineName,
      jobs,
      jobDependencies,
      direction ?? 'horizontal',
      selectedJobId
    )

    return (
      <div ref={this.containerRef} onClick={this.handleClick}>
        <Graphviz
          className="pipeline-graph"
          options={{
            totalMemory: 1 * 10 ** 9,
            zoomScaleExtent: [1, 1],
            tweenShapes: false,
            tweenPaths: false,
            fit: false,
            zoom: false,
            width: this.containerRef.current
              ? this.containerRef.current.offsetWidth
              : undefined,
            height: this.containerRef.current
              ? this.containerRef.current.offsetHeight
              : undefined,
          }}
          dot={graph || defaultGraph}
        />
      </div>
    )
  }
}

interface SubPipeline {
  pipeline: Pick<Pipeline, 'id' | 'name'>
  end: Job
  start: Job
}

const getJobStatus = (job: Job): JobAttempt['status'] | 'pending' => {
  if (job.isPending) {
    return 'pending'
  }
  if (job.LatestJobAttempt) {
    return job.LatestJobAttempt.status
  }

  return 'pending'
}

const getJobTooltip = (job: Job) => {
  return `${job.taskName} - ${getJobStatus(job)}`
}

const getLabel = (name: string, status: ReturnType<typeof getJobStatus>) => {
  return `<

  <table border="0" cellpadding="1" cellspacing="1">
    <tr>
      <td cellspacing="2" colspan="3"><b>${name}</b></td>
      <td id="status" fixedsize="true" width="10" height="10" align="right" tooltip="${status}"><font point-size="2" face="Material Icons">${getStatusIcon(
    status
  )}</font></td>
    </tr>
  </table>

    >`
}

const getJobLabel = ({ job, node }: { job?: Job; node: string }) => {
  if (job) {
    return getLabel(job.taskName, getJobStatus(job))
  }

  return `"${node}"`
}
const getPipelineLabel = ({ pipeline: { name }, start, end }: SubPipeline) => {
  return getLabel(name, getPipelineStatus(start, end))
}

const getJobClass = (job: Job, selectedJobId?: string) => {
  return `job ${selectedJobId === job.id ? 'selected ' : ''}${job && getJobStatus(job)
    }`
}

const getPipelineStatus = (start: Job, end: Job) => {
  const endStatus = getJobStatus(end)
  const startStatus = getJobStatus(start)
  if (startStatus === 'pending') {
    return 'pending'
  }
  if (endStatus === 'complete') {
    return 'complete'
  }

  return 'running'
}

const getPipelineClass = ({ pipeline, start, end }: SubPipeline) => {
  return `pipeline ${pipeline && getPipelineStatus(start, end)}`
}

const jobDependenciesToDOT = (
  title: string,
  jobs: Job[],
  jobDependencies: JobDependency[],
  direction: Direction,
  selectedJobId?: string
) => {
  let dotString = `digraph "${title}"{
    concentrate=true;    
    node [shape=box, style=rounded];
    `

  if (direction === 'horizontal') {
    dotString += `
      rankdir=LR
      `
  }

  let nodeRelationString = ''
  for (const { dependsOnJobId, jobId } of jobDependencies) {
    let dependsOnId = dependsOnJobId
    let depdendantId = jobId

    nodeRelationString += `
          "${dependsOnId}" -> "${depdendantId}"
          `
  }
  const pipelines: Record<string, SubPipeline> = {}

  for (const job of jobs) {

    nodeRelationString += `
    "${job.id}"[class="${getJobClass(job, selectedJobId)}",
    label=${getJobLabel({
      job,
      node: job.id,
    })},
    tooltip="${getJobTooltip(job)}"]
    `
  }

  for (const [pipelineId, subPipeline] of Object.entries(pipelines)) {
    nodeRelationString += `
    "${pipelineId}"[
      shape=box,
      style=wedged,
      class="${getPipelineClass(subPipeline)}",
      label=${getPipelineLabel(subPipeline)},
      tooltip="${subPipeline.pipeline.name}"]
    `
  }

  dotString += nodeRelationString

  dotString += `
      }
    `

  return dotString
}

export default PipelineGraph
