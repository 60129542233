import * as React from 'react'
import { NavLink } from 'react-router-dom'
import Page, { PageProps } from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import i18n, { keys } from '../../i18n'
import './AdminPage.scss'

interface AdminPageProps extends PageProps {}

export function AdminPage({
  children,
  ...pageProps
}: React.PropsWithChildren<AdminPageProps>) {
  return (
    <Page
      {...pageProps}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <div className="ui-app-row">
        <div className="ui-sidebar admin-nav">
          <NavLink to={url(urls.admin_admins)}>Admins</NavLink>
          <NavLink to={url(urls.admin_organizations)}>Organizations</NavLink>
          <NavLink to={url(urls.admin_logins)}>Logins</NavLink>
          <NavLink to={url(urls.admin_variables)}>Variables</NavLink>
          <NavLink to={url(urls.admin_notifications)}>Notifications</NavLink>
          <NavLink to={url(urls.admin_feature_flags)}>Feature Flags</NavLink>
        </div>

        <div className="ui-content admin-content">{children}</div>
      </div>
    </Page>
  )
}
