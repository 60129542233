import * as gravatar from 'gravatar'
import * as React from 'react'

import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { renderAvatar } from '../../../../admin/Status/DeliveryStatus/ShortStatus'
import AsyncSelectorStatusOverlay from '../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectOrganizationId } from '../../../../data/selectOrganizationId'
import useAsync from '../../../../hooks/useAsync'
import { useIsLoading } from '../../../../hooks/useIsLoading'
import { useRedux } from '../../../../hooks/useRedux'
import { DeliveryGroupStatus } from '../selectGetDetailedDeliveryStatus'
import {
  fetchOrganizationUsers,
  OrganizationUser,
} from './fetchOrganizationUsers'

export interface SendDeliveryDataAvailableParams {
  userIds?: number[]
  groupIds?: number[]
  comments?: string
}

interface Props {
  open: boolean
  showWarning?: boolean
  onSubmit: (params: SendDeliveryDataAvailableParams) => Promise<void>
  onCancel: () => void
  deliveryGroupStatuses: DeliveryGroupStatus[]
}

export const SendDeliveryDataAvailableDialog = ({
  open,
  showWarning,
  onSubmit,
  onCancel,
  deliveryGroupStatuses,
}: Props) => {
  const [state] = useRedux()
  const { load, isLoading } = useIsLoading<void>()
  const [selectedUsers, setSelectedUsers] = React.useState<OrganizationUser[]>(
    []
  )
  const [selectedGroups, setSelectedGroups] = React.useState<
    DeliveryGroupStatus[]
  >([])

  const [comments, setComments] = React.useState<string>('')

  const organizationId = selectOrganizationId(state)
  const [orgUsersFetcher] = useAsync(
    async ([], skip) => {
      if (!organizationId) {
        return skip()
      }

      return await fetchOrganizationUsers(organizationId)
    },
    [organizationId]
  )

  const users = React.useMemo(() => {
    return orgUsersFetcher.result ?? []
  }, [orgUsersFetcher])

  React.useEffect(() => {
    setSelectedUsers(users)
  }, [users])

  React.useEffect(() => {
    setSelectedGroups(deliveryGroupStatuses)
  }, [deliveryGroupStatuses])

  const handleSubmit = () => {
    load(
      async () =>
        await onSubmit({
          userIds: selectedUsers.map(({ id }) => id),
          groupIds: selectedGroups.map(
            ({
              deliveryGroup: {
                group: { id },
              },
            }) => id
          ),
          comments,
        })
    )
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Send Delivery Data Available</DialogTitle>
      <DialogContent>
        <AsyncSelectorStatusOverlay requests={[]} isLoading={isLoading}>
          <Stack spacing={2}>
            <Typography
              variant="caption"
              width="100%"
              textAlign="center"
              fontSize={14}
              fontWeight={700}
            >
              You are about to deliver data to the client
            </Typography>
            {showWarning && (
              <Typography
                color="error"
                variant="caption"
                width="100%"
                textAlign="center"
                fontSize={14}
              >
                Not all required layers have been enabled. See Status summary
                for details.
              </Typography>
            )}
            <FormControl>
              <Autocomplete
                multiple
                options={users}
                value={selectedUsers}
                onChange={(_ev, newValue) => setSelectedUsers(newValue)}
                getOptionLabel={({ firstName, lastName, email }) =>
                  `${firstName} ${lastName} (${email})`
                }
                renderTags={(value, getTagProps) =>
                  value.map(({ firstName, lastName, email }, index) => (
                    <Chip
                      avatar={
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          key={email}
                          alt={`${firstName} ${lastName} (${email})`}
                          src={gravatar.url(email, { d: 'mm', r: 'g' })}
                        />
                      }
                      variant="outlined"
                      label={`${firstName} ${lastName} (${email})`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Recipients"
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                multiple
                options={deliveryGroupStatuses}
                value={selectedGroups}
                onChange={(_ev, newValue) => setSelectedGroups(newValue)}
                renderOption={(props, option) => (
                  <li {...props}>
                    <ListItemText primary={option.deliveryGroup.group.name} />
                    <ListItemIcon>
                      {renderAvatar(
                        option.enabled,
                        !option.enabled
                          ? 'Some expected layers are not yet enabled. See Status summary for details.'
                          : undefined
                      )}
                    </ListItemIcon>
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      avatar={renderAvatar(
                        option.enabled,
                        !option.enabled
                          ? 'Some expected layers are not yet enabled. See Status summary for details.'
                          : undefined
                      )}
                      variant="outlined"
                      label={option.deliveryGroup.group.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                getOptionLabel={({
                  deliveryGroup: {
                    group: { name },
                  },
                }) => name}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Groups" />
                )}
                // value={Array.from(selectedUsers.values())}
                // label="Recipients"
              />
            </FormControl>
            <TextField
              id="delivery-notifcation-comments"
              label="Comments"
              multiline
              value={comments}
              onChange={(ev) => setComments(ev.currentTarget.value)}
              rows={4}
            />
          </Stack>
        </AsyncSelectorStatusOverlay>
      </DialogContent>
      <DialogActions>
        {/* <Stack sx={{ width: '100%' }} spacing={1}> */}
        <Stack direction="row" width="100%" spacing={1}>
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Send
          </Button>
        </Stack>
        {/* </Stack> */}
      </DialogActions>
    </Dialog>
  )
}
