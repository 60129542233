import { utils, writeXLSX } from 'xlsx'

import { addExtension, cleanFilename, downloadBlob } from './download'

export interface TableHeader {
  key: string
  label: string
}

interface TableOptions {
  data: Record<string, any>[]
  headers?: TableHeader[]
}

export interface DownloadTableOptions extends TableOptions {
  filename: string
}

export const downloadCSV = (options: DownloadTableOptions) => {
  const table = toTable(options)

  downloadBlob(
    new Blob([`\ufeff${toCSVString(table)}`]),
    cleanFilename(addExtension(options.filename, '.csv'))
  )
}

export const downloadXLSX = async (options: DownloadTableOptions) => {
  const table = toTable(options)

  const sheet = utils.aoa_to_sheet(table)
  const book = utils.book_new()
  utils.book_append_sheet(book, sheet, truncateName(options.filename))
  const buffer = writeXLSX(book, { type: 'buffer' }) as Buffer

  const blob = new Blob([
    new Uint8Array(buffer, buffer.byteOffset, buffer.length),
  ])

  downloadBlob(blob, cleanFilename(addExtension(options.filename, '.xlsx')))
}

function truncateName(name: string) {
  if (name.length > 31) {
    return `${name.substring(0, 28)}...`
  }

  return name
}

export const toTable = ({ data, headers }: TableOptions) => {
  const actualHeaders = headers || makeHeaders(data)

  const cols = actualHeaders.map((header) => header.label)

  const rows = data.map((row) => {
    return actualHeaders.map((header) => {
      const item = row[header.key]

      return item ?? ''
    })
  })

  return [cols, ...rows]
}

const makeHeaders = (data: Record<string, any>[]): TableHeader[] => {
  const keySet = new Set<string>()
  data.forEach((row) => Object.keys(row).forEach((key) => keySet.add(key)))

  return Array.from(keySet).map((key) => ({
    key,
    label: key,
  }))
}

export const toCSVString = (data: any[][]) => {
  return data.map((line) => toCSVLine(line)).join('')
}

const toCSVLine = (arr: any[]) => {
  return `${arr.map((item) => toCSVItem(item)).join(',')}\n`
}

const toCSVItem = (item: any) => {
  let localeItem = item ?? ''

  if (typeof localeItem.toLocaleString === 'function') {
    localeItem = localeItem.toLocaleString()
  }

  return `"${escapeQuotes(`${localeItem}`)}"`
}

const escapeQuotes = (str: string) => {
  return str.replace(/"/g, '""')
}
