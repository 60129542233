import {
  default as RCSlider,
  Range as RCRange,
  RangeProps as RCRangeProps,
  SliderProps as RCSliderProps,
} from 'rc-slider'
import 'rc-slider/assets/index.css'
import * as React from 'react'
import { Omit } from 'react-redux'
import { classnames } from '../util/classnames'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { withTheme } from '@emotion/react'

type SliderProps = Omit<RCSliderProps, 'handle'>

// tslint:disable-next-line:class-name
class _Slider extends React.PureComponent<
  SliderProps & WithStyles<typeof styles> & { theme: Theme }
> {
  render() {
    const classNameRoot = classnames(
      'grabbable',
      this.props.className,
      this.props.classes.root
    )

    return (
      <RCSlider
        {...this.props}
        className={classNameRoot}
        trackStyle={{ background: this.props.theme.palette.primary.main }}
        handle={(props) => (
          <div
            className={this.props.classes.hitZone}
            style={{
              left: `${props.offset}%`,
            }}
            tabIndex={props.tabIndex}
          >
            <div className={this.props.classes.handle} />
          </div>
        )}
      />
    )
  }
}

type RangeProps = Omit<RCRangeProps, 'handle'>
// tslint:disable-next-line:max-classes-per-file class-name
class _Range extends React.PureComponent<
  RangeProps & WithStyles<typeof styles>
> {
  render() {
    const classNameRoot = classnames(
      'grabbable',
      this.props.className,
      this.props.classes.root
    )

    return (
      <RCRange
        {...this.props}
        className={classNameRoot}
        handle={(props) => (
          <div
            className={this.props.classes.hitZone}
            style={{
              left: `${props.offset}%`,
            }}
            tabIndex={props.tabIndex}
          >
            <div className={this.props.classes.handle} />
          </div>
        )}
      />
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      userSelect: 'none',
      WebkitUserSelect: 'none',
    },
    sliderTrack: {
      backgroundColor: theme.palette.primary.main,
    },
    hitZone: {
      width: 50,
      height: 50,
      position: 'absolute',
      transform: 'translate(-50%, calc(-50% + 2px))',
      outline: 'none',
    },
    handle: {
      width: 14,
      height: 14,
      backgroundColor: theme.palette.text.primary,
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      transformOrigin: '50% 50%',
    },
  })

// tslint:disable-next-line:variable-name
export const Slider = withStyles(styles)(withTheme(_Slider))
// tslint:disable-next-line:variable-name
export const Range = withStyles(styles)(_Range)
