import * as React from 'react'

import { Checkbox, Divider, Typography } from '@mui/material'

import { Row } from '../admin/UI/Row'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { GroupFileData } from '../data/types'
import { useRedux } from '../hooks/useRedux'
import { GroupFile } from './fetchDeliveryGroupFiles'
import { MapLayer } from './fetchMapLayersForGroup'
import { GeoPdfDownloadStatus } from './GeoPdfDownloadStatus'

interface Props {
  groupFile?: GroupFile
  mapLayer: MapLayer
  selected: boolean
  onDownloadRequested: (mapLayer: MapLayer) => void
  onReprocessRequested: (groupFile: GroupFileData, mapLayer: MapLayer) => void
  onSelected: (filename: string) => void
}

export const GeoPdfDownloadRow = ({
  mapLayer,
  groupFile,
  selected,
  onDownloadRequested,
  onReprocessRequested,
  onSelected,
}: Props) => {
  const [state] = useRedux()

  const preferredLanguage = selectPreferredLanguage(state)

  const { pdfFilenameTranslations } = mapLayer
  const isDownloadable = groupFile?.status === 'complete'

  return (
    <>
      <Row
        key={mapLayer.pdfFilename}
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Row style={{ width: '80%', opacity: isDownloadable ? 1 : 0.5 }}>
          <Checkbox
            disabled={!isDownloadable}
            checked={selected}
            onChange={
              isDownloadable ? () => onSelected(mapLayer.pdfFilename!) : undefined
            }
          />
          <Typography noWrap
            style={{
              cursor: isDownloadable ? 'pointer' : 'default',
              userSelect: 'none',
            }}
            onClick={
              isDownloadable ? () => onSelected(mapLayer.pdfFilename!) : undefined
            }
          >
            {!!pdfFilenameTranslations
              ? pdfFilenameTranslations[preferredLanguage]
              : mapLayer.pdfFilename}
          </Typography>
        </Row>
        <Row>
          <GeoPdfDownloadStatus
            mapLayer={mapLayer}
            groupFile={groupFile}
            onDownloadRequested={onDownloadRequested}
            onReprocessRequested={onReprocessRequested}
          />
        </Row>
      </Row>
      <Divider />
    </>
  )
}
