import bbox from '@turf/bbox'
import buffer from '@turf/buffer'
import { createSelector } from 'reselect'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { client, gql } from '../../../graphql/client'
import { Parcel } from '../../../graphql/types'
import { RootStore } from '../../../redux/types'
import filterTruthy from '../../../util/filterTruthy'

export type OrganizationParcel = Pick<
  Parcel,
  'name' | 'id' | 'meta' | 'geometry'
>

export interface OrganizationGroup {
  id: number
  name: string
  parcels: OrganizationParcel[]
}

const {
  selector: selectListOrganizationParcels,
  refresh: refreshListOrganizationParcels,
} = createAsyncSelector({
  resource: 'SelectOrganizationParcels',
  inputs: {
    organizationId: (state: RootStore) => state.router.params.organizationId,
  },
  fetcher: async ({ organizationId }) => {
    if (!organizationId) {
      return
    }

    const { groups } = await client.request<{ groups: OrganizationGroup[] }>({
      query: gql`
        query OrganizationParcels($organizationId: Int!) {
          groups: OrganizationGroup(
            where: {
              organizationId: { _eq: $organizationId }
              deletedAt: { _is_null: true }
            }
            order_by: { id: asc }
          ) {
            id
            name

            parcels: Parcels(
              where: { deletedAt: { _is_null: true } }
              order_by: { id: asc }
            ) {
              id
              name
              geometry
              meta
            }
          }
        }
      `,
      variables: { organizationId },
    })

    return groups
  },
})

const getParcelFilter = (state: RootStore) =>
  state.router.searchParams['parcel-filter'] ?? ''

const EMPTY_GROUPS: OrganizationGroup[] = []

export const selectOrganizationParcels = createSelector(
  selectListOrganizationParcels,

  (listParcels) => {
    return listParcels.data || EMPTY_GROUPS
  }
)

const FIELD_BOUNDARY_COLOR_SELECTED = '#FFCA28' // Material Color Amber 400
const FIELD_BOUNDARY_WIDTH = 3

export const selectOrganizationParcelsWithThumbnails = createSelector(
  selectOrganizationParcels,
  getParcelFilter,
  (parcelsByGroup, parcelFilter) =>
    parcelsByGroup.map((group) => {
      return {
        ...group,
        parcels: (group.name.toLowerCase().includes(parcelFilter.toLowerCase())
          ? group.parcels
          : group.parcels.filter((parcel) =>
              parcel.name.toLowerCase().includes(parcelFilter.toLowerCase())
            )
        )
          .map((parcel) => {
            const bounds = bbox(
              buffer(parcel.geometry, 25, { units: 'meters' })
            )
            if (!bounds) {
              return null
            }

            const parcelJson = JSON.stringify({
              type: 'Feature',
              geometry: parcel.geometry,

              properties: {
                stroke: FIELD_BOUNDARY_COLOR_SELECTED,
                'stroke-width': FIELD_BOUNDARY_WIDTH,
                fill: 'transparent',
              },
            })

            return {
              ...parcel,
              thumbnail: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/geojson(${encodeURIComponent(
                parcelJson
              )})/[${bounds[0]},${bounds[1]},${bounds[2]},${
                bounds[3]
              }]/300x300?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`,
            }
          })
          .filter(filterTruthy),
      }
    })
)

export { selectListOrganizationParcels, refreshListOrganizationParcels }
