import * as React from 'react'

import { Button, Icon, Tooltip } from '@mui/material'

import i18n, { keys } from '../../i18n'
import { toggleMeasureToolActive } from '../../postgis/actions'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'

class MeasureToolToggle extends React.PureComponent<
  ReduxProps & AppDispatchProps
> {
  handleClick = () => {
    const { dispatch } = this.props

    dispatch(toggleMeasureToolActive(null))
  }

  render() {
    const { isMeasureToolActive, isCreatingNote } = this.props

    return (
      <Button onClick={this.handleClick} disabled={isCreatingNote}>
        <Tooltip title={i18n.t(keys.measure)} placement="left">
          <Icon color={isMeasureToolActive ? 'primary' : undefined}>
            square_foot
          </Icon>
        </Tooltip>
      </Button>
    )
  }
}

const mapStateToProps = (state: RootStore) => ({
  isMeasureToolActive: state.postgis.isMeasureToolActive,
  isCreatingNote: state.notes.isCreatingNote,
})

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect<ReduxProps, {}, AppDispatchProps>(mapStateToProps)(
  MeasureToolToggle
)
