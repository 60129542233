import {
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import * as React from 'react'
import { Form } from '../../../admin/UI/Form'
import { AdminRole, api, gql } from '../../../hasura/api'
import useAsync from '../../../hooks/useAsync'
import { useRedux } from '../../../hooks/useRedux'

function UserSelect({
  userId = '-1',
  onChange,
}: {
  userId: string
  onChange: (event: SelectChangeEvent<string>) => void
}) {
  const [search, setSearch] = React.useState('')
  const [searchedUsers] = useAsync(
    async ([search, userId]) => {
      return api.users.list(
        {
          where: {
            _or: [
              { firstName: { _ilike: search } },
              { lastName: { _ilike: search } },
              { email: { _ilike: search } },
              { id: { _eq: userId } },
            ],
          },
        },
        gql`
          {
            id
            firstName
            lastName
            email
          }
        `
      )
    },
    [search, userId]
  )

  const users = searchedUsers.result?.data ?? []

  return (
    <FormControl fullWidth>
      <InputLabel required shrink={!!userId}>
        User
      </InputLabel>
      <Select name="userId" fullWidth onChange={onChange} value={userId ?? ''}>
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearch(`${e.target.value}%`)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation()
              }
            }}
          />
        </ListSubheader>
        {users.map(({ id, firstName, lastName, email }) => (
          <MenuItem key={id} value={id}>
            {firstName} {lastName} &lt;{email}&gt;
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function RoleSelect({
  role,
  onChange,
}: {
  role: string
  onChange: (event: SelectChangeEvent<string>) => void
}) {
  const [state] = useRedux()
  const [accessRoles] = useAsync(
    () =>
      api.accessRoles.list(
        {
          where: { scope: { _eq: 'global' } },
        },
        gql`
          {
            id
            name
            description
          }
        `
      ),
    []
  )

  const roles = accessRoles.result?.data ?? []

  return (
    <FormControl fullWidth>
      <InputLabel required shrink={!!role}>
        Role
      </InputLabel>
      <Select name="role" fullWidth onChange={onChange} value={role ?? ''}>
        {roles.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name?.[state.preferences.preferredLanguage]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default class AdminForm extends Form<AdminRole> {
  render() {
    const { userId = '-1', role = '' } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <h2>{this.props.type === 'edit' ? 'Edit' : 'New'} Admin Role</h2>
        </div>
        <div className="grid-xs-12">
          <UserSelect
            userId={userId}
            onChange={this.updateFormDataFromSelectChangeEvent}
          />
        </div>
        <div className="grid-xs-12">
          <RoleSelect
            role={role}
            onChange={this.updateFormDataFromSelectChangeEvent}
          />
        </div>
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }
}
