import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputAdornment,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { default as TablePagination } from '@mui/material/TablePagination'

import {
  DEFAULT_PAGE_INFO,
  PAGE_SIZE_OPTIONS,
  selectPagingInfo,
  url,
  urls,
} from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectOrganizationList } from '../../../data/selectOrganizationList'
import i18n, { keys } from '../../../i18n'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import EnhancedTableToolbar from '../../../UI/EnhancedTable/EnhancedTableToolbar'
import { Table } from '../../../UI/Table/Table'
import fuzzySearch from '../../../util/fuzzySearch'
import { startJob } from '../../../vvapi/maestro'
import { Column } from '../../UI/Column'
import { Row } from '../../UI/Row'
import warnConfirm from '../../warnConfirm'
import StatusHome from '../StatusHome'
import StatusSummary from '../StatusSummary'
import { MIGRATE_ALL_JOB_ID } from './constants'
import { MigrationRunState } from './MigrationRunState'
import { migrationStatusTableFormatter } from './migrationStatusTableFormatter'
import {
  refreshMigrationState,
  selectMigrationState,
} from './selectMigrationState'
import {
  refreshMigrationStatus,
  selectMigrationStatus,
} from './selectMigrationStatus'
import {
  refreshOrganizationMigrationStatus,
  selectOrganizationMigrationStatus,
} from './selectOrganizationMigrationStatus'
import {
  refreshParcelDataSetMigrations,
  selectParcelDataSetMigrations,
} from './selectParcelDataSetMigrations'

interface State {
  organizationModalOpen: boolean
  filter?: string
}

class MigrationStatusPage extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps,
  State
> {
  state: State = { organizationModalOpen: false }

  getInfo = () => {
    return this.props.parcelDataSetMigrations.data
      ? this.props.parcelDataSetMigrations.data.info
      : { ...DEFAULT_PAGE_INFO, order: [] }
  }
  render() {
    const pagination = this.renderPagination()
    const {
      migrationStatus,
      migrationState,
      parcelDataSetMigrations,
      organizationMigrationStatus,
    } = this.props

    const rows = parcelDataSetMigrations.data
      ? parcelDataSetMigrations.data.data
      : []
    const state = migrationState.data?.state
    const organizationSelect = this.renderOrganizationSelect()

    return (
      <StatusHome>
        <Paper id="MigrationStatus">
          {organizationSelect}
          <EnhancedTableToolbar
            title={`Migration Status`}
            numSelected={0}
            onClickRefresh={this.handleRefresh}
          />
          <AsyncSelectorStatusOverlay requests={migrationState}>
            <MigrationRunState state={state} />
          </AsyncSelectorStatusOverlay>
          <Column
            style={{
              flex: 1,
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 24,
            }}
          >
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'end',
                width: '100%',
              }}
            >
              <Column>
                <Row>
                  <Typography variant="caption">Organization:</Typography>
                  <Typography style={{ marginLeft: 4, marginRight: 4 }}>
                    {this.getSelectedOrganization()}
                  </Typography>
                </Row>
                <Button onClick={this.handleOrganizationModalOpen}>
                  Change Organization
                </Button>
              </Column>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleQueueUnmigrated}
              >
                Queue All Unmigrated
              </Button>
            </Row>
            <Row
              style={{
                paddingTop: 24,
                alignItems: 'flex-start',
              }}
            >
              <Column>
                <Typography style={{ marginLeft: 24 }} variant="subtitle1">
                  ParcelDataSet Stats
                </Typography>
                <StatusSummary summary={migrationStatus} />
              </Column>

              <Column>
                {this.props.selectedOrganization !== 'all' && (
                  <>
                    <Typography style={{ marginLeft: 24 }} variant="subtitle1">
                      Organization Stats
                    </Typography>
                    <StatusSummary summary={organizationMigrationStatus} />
                  </>
                )}
              </Column>
            </Row>
          </Column>
          {pagination}
          <AsyncSelectorStatusOverlay requests={parcelDataSetMigrations}>
            <Table
              stickyOffset={64}
              rows={rows}
              formatter={migrationStatusTableFormatter()}
            />
          </AsyncSelectorStatusOverlay>
          {pagination}
        </Paper>
      </StatusHome>
    )
  }

  getSelectedOrganization = () => {
    const { organizationsSelector, selectedOrganization } = this.props
    const organizations = organizationsSelector.data
      ? organizationsSelector.data
      : []

    const selectedOrg = organizations.find(
      ({ id }) => id.toString() === selectedOrganization
    )

    return selectedOrg?.name ?? 'All'
  }

  renderOrganizationSelect = () => {
    const { organizationsSelector } = this.props
    const organizations = organizationsSelector.data
      ? organizationsSelector.data
      : []
    const { filter } = this.state
    const filterAdornmentIcon = filter ? 'cancel' : 'search'

    const filteredOrganizations = (
      filter
        ? organizations.filter(
            ({ name, betaEnabled }) => fuzzySearch(filter, name) && betaEnabled
          )
        : organizations.filter(({ betaEnabled }) => betaEnabled)
    ).sort((orgA, orgB) => orgA.name.localeCompare(orgB.name))

    return (
      <Dialog
        open={this.state.organizationModalOpen}
        onClose={this.handleOrganizationModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Organizations</DialogTitle>
        <DialogContent>
          <>
            <TextField
              fullWidth
              placeholder="Filter!"
              value={filter}
              onChange={this.handleFilterChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon onClick={this.handleFilterClear}>
                      {filterAdornmentIcon}
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
            <MenuList>
              <MenuItem
                key={'all'}
                value={'all'}
                onClick={() => {
                  this.handleSelectOrganization('all')
                }}
              >
                All
              </MenuItem>
              {filteredOrganizations.map(({ name, id }) => (
                <MenuItem
                  key={id}
                  value={id}
                  onClick={() => {
                    this.handleSelectOrganization(id)
                  }}
                >
                  {name}
                </MenuItem>
              ))}
            </MenuList>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleOrganizationModalClose}>
            {i18n.t(keys.generic.close)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter: e.target.value,
    })
  }

  handleFilterClear = () => {
    this.setState({
      filter: '',
    })
  }

  handleOrganizationModalClose = async () => {
    this.setState({ organizationModalOpen: false })
  }

  handleOrganizationModalOpen = async () => {
    this.setState({ organizationModalOpen: true })
  }

  handleQueueUnmigrated = async () => {
    if (
      await warnConfirm({
        title: 'Confirm Migration',
        message: `Are you sure you would like to queue all unmigrated ParcelDataSets for Beta-Enabled organizations?`,
        action: 'Migrate',
      })
    ) {
      await startJob(MIGRATE_ALL_JOB_ID, -100)
    }
  }

  handleSelectOrganization = async (organizationId: string | 'all') => {
    this.setState({
      organizationModalOpen: false,
      filter: undefined,
    })
    this.props.history.push(
      url(urls.migrationStatus, {
        ...this.props.match.params,
        organizationId,

        page: 0,
      } as any)
    )
  }

  renderPagination = () => {
    const { page, pageSize, count } = this.getInfo()

    return (
      <TablePagination
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        component="div"
        count={count}
        page={page}
        rowsPerPage={pageSize}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    )
  }

  handleChangePage = (_event: any, page: number) =>
    this.props.history.push(
      url(urls.migrationStatus, this.props.match.params, {
        page,
        pageSize: this.getInfo().pageSize,
      })
    )

  handleChangeRowsPerPage = (event: any) =>
    this.props.history.push(
      url(urls.migrationStatus, this.props.match.params, {
        page: 0,
        pageSize: event.target.value,
      })
    )

  handleRefresh() {
    refreshMigrationStatus()
    refreshOrganizationMigrationStatus()
    refreshParcelDataSetMigrations()
    refreshMigrationState()
  }
}

const mapState = (state: RootStore) => ({
  ...selectPagingInfo(state),
  migrationStatus: selectMigrationStatus(state),
  parcelDataSetMigrations: selectParcelDataSetMigrations(state),
  migrationState: selectMigrationState(state),
  organizationsSelector: selectOrganizationList(state),
  selectedOrganization: state.router.params.organizationId,
  organizationMigrationStatus: selectOrganizationMigrationStatus(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(MigrationStatusPage)
)
