import { createSelector } from 'reselect'
import { selectSelectedMapLayersByLayerDefId } from '../../data/selectSelectedMapLayersByLayerDefId'
import { Converter } from '../../util/units/LayerConverter'
import { getConvertFunction } from '../calculateStats/getConvertFunction'
import { selectBestUnitsByProductId } from './stats'

export const selectConversionsByProductId = createSelector(
  [selectBestUnitsByProductId, selectSelectedMapLayersByLayerDefId],
  (bestUnits, mapLayers) => {
    const conversionsById: Record<string, Converter> = {}
    for (const [productId, layer] of Object.entries(mapLayers)) {
      const bestUnit = bestUnits[productId]
      if (bestUnit && layer) {
        const {
          mapLayerDef: { dataProperty },
        } = layer

        const converter = getConvertFunction(dataProperty, bestUnit)

        if (converter) {
          conversionsById[productId] = converter
        }
      }
    }

    return conversionsById
  }
)
