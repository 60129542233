import * as mapboxgl from 'mapbox-gl'
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'

import { MapSource, Parcel, Sample, SamplePlanBlock } from '../../../graphql/types'
import { useRedux } from '../../../hooks/useRedux'
import { MapboxGL } from '../../../map/MapboxGL'
import mapboxSatelliteJson from '../../../map/styles/mapbox-satellite.json'
import { MapContext } from '../../../map/withMap'
import {
  getUserPositionLayer,
  USER_POSITION_ACURRACY_MARKER,
  USER_POSITION_HEADING_LINE,
  USER_POSITION_MARKER,
} from '../../../postgis/selectors/UserPositionLayerSelector'
import SamplePlanCompleteSVG from '../assets/sample_plan_complete.svg'
import SamplePlanIncompleteSVG from '../assets/sample_plan_incomplete.svg'
import getPadding from './util/getPadding'
import {
  addSelectedParcelSourcesAndLayers,
  addUnselectedParcelSourcesAndLayers,
  SAMPLE_PLAN_BLOCK_UNSELECTED,
} from './util/parcelLayerHelper'
import removeSourceAndLayer from './util/removeSourceAndLayer'
import {
  addHighlightedSampleHaloSourcesAndLayers,
  addSamplesSourcesAndLayers,
  SAMPLE_PLAN_COMPLETE_ICON,
  SAMPLE_PLAN_INCOMPLETE_ICON,
  SAMPLE_PLAN_POINTS,
} from './util/sampleLayerHelper'
import waitForLoaded from './util/waitForLoaded'
import { addGeneratedSampleFeatureSourcesAndLayers } from './util/generatedSampleLayerHelper'

interface Inputs {
  parcels?: Parcel[]
  selectedParcels?: Parcel[]
  samples?: Sample[]
  selectedSamples?: Sample[]
  highlightedSample?: Sample | null
  paddingOffset?: MapboxGL.PaddingOptions // starting from top
  boundsPadding?: number | MapboxGL.PaddingOptions
  mapLoading?: boolean
  samplePlanBlocks?: SamplePlanBlock[]
  dataSources?: MapSource[]
  fields?: Record<string, any>
  showGeneratedFeatures?: boolean
}

interface Outputs {
  setBlockBounds: (bounds: mapboxgl.LngLatBoundsLike) => void
}

// Note: Must be used within a component wrapped in a MapContextProvider.
export const useSamplePlanMapSourceAndLayers = ({
  parcels,
  selectedParcels,
  samples,
  selectedSamples,
  highlightedSample,
  paddingOffset = { top: 0, right: 0, bottom: 0, left: 0 },
  boundsPadding = 20,
  mapLoading = false,
  samplePlanBlocks = [],
  dataSources = [],
  fields,
  showGeneratedFeatures = false,
}: Inputs): Outputs => {
  const [state] = useRedux()
  const { map } = React.useContext(MapContext)
  const userPosition = getUserPositionLayer(state)
  const [boundsInitialized, setBoundsInitialized] =
    React.useState<boolean>(false)
  const [style] = React.useState({
    ...mapboxSatelliteJson,
  } as MapboxGL.Style)

  const [blockBounds, setBlockBounds] =
    React.useState<mapboxgl.LngLatBoundsLike>()

  React.useEffect(() => {
    if (!map) {
      return
    }

    const samplePlanCompleteIcon = new Image(24, 24)
    const samplePlanIncompleteIcon = new Image(24, 24)

    const handleStyleLoaded = () => {
      if (!map.hasImage(SAMPLE_PLAN_COMPLETE_ICON)) {
        samplePlanCompleteIcon.onload = () => {
          map.addImage(SAMPLE_PLAN_COMPLETE_ICON, samplePlanCompleteIcon)
        }
        samplePlanCompleteIcon.src = SamplePlanCompleteSVG
      }

      if (!map.hasImage(SAMPLE_PLAN_INCOMPLETE_ICON)) {
        samplePlanIncompleteIcon.onload = () => {
          map.addImage(SAMPLE_PLAN_INCOMPLETE_ICON, samplePlanIncompleteIcon)
        }
        samplePlanIncompleteIcon.src = SamplePlanIncompleteSVG
      }
    }

    if (map.isStyleLoaded()) {
      handleStyleLoaded()
    } else {
      map.once('style.load', handleStyleLoaded)
    }

    return () => {
      map.off('style.load', handleStyleLoaded)
      samplePlanCompleteIcon.src = ''
      samplePlanCompleteIcon.onload = null
      samplePlanCompleteIcon.onerror = null
      samplePlanCompleteIcon.remove()

      samplePlanIncompleteIcon.src = ''
      samplePlanIncompleteIcon.onload = null
      samplePlanIncompleteIcon.onerror = null
      samplePlanIncompleteIcon.remove()
    }
  }, [map])

  React.useEffect(() => {
    return waitForLoaded(
      () => !!map && !!style,
      () => map?.setStyle(style)
    )
  }, [map, style])

  React.useEffect(() => {
    return waitForLoaded(
      () => !!map?.isStyleLoaded(),
      () =>
        addUnselectedParcelSourcesAndLayers({
          map,
          parcels,
          setBlockBounds: (b) => setBlockBounds(b),
          boundsInitialized,
        })
    )
  }, [map, parcels])

  React.useEffect(() => {
    return waitForLoaded(
      () =>
        !!map?.isStyleLoaded() &&
        !!map?.isSourceLoaded(SAMPLE_PLAN_BLOCK_UNSELECTED),
      () => addSelectedParcelSourcesAndLayers({ map, selectedParcels })
    )
  }, [map, selectedParcels])

  React.useEffect(() => {
    return waitForLoaded(
      () =>
        !!map?.isStyleLoaded() &&
        !!map?.isSourceLoaded(SAMPLE_PLAN_BLOCK_UNSELECTED),
      () => addSamplesSourcesAndLayers({ map, samples })
    )
  }, [map, samples])

  React.useEffect(() => {
    if (!showGeneratedFeatures) {
      return
    }
    return waitForLoaded(
      () =>
        !!map?.isStyleLoaded() &&
        !!map?.isSourceLoaded(SAMPLE_PLAN_POINTS) &&
        !!map?.isSourceLoaded(SAMPLE_PLAN_BLOCK_UNSELECTED),
      () =>
        addGeneratedSampleFeatureSourcesAndLayers({
          map,
          samplePlanBlocks,
          dataSources,
          fields,
        })
    )
  }, [map, showGeneratedFeatures, samplePlanBlocks])

  React.useEffect(() => {
    if (!map) {
      return
    }

    if (!blockBounds) {
      return
    }
    const boundsCenter = [
      (blockBounds[0] + blockBounds[1]) / 2,
      (blockBounds[2] + blockBounds[3]) / 2,
    ]
    // only animate after intial loading.
    map?.flyTo({
      center: boundsCenter as mapboxgl.LngLatLike,
      animate: boundsInitialized,
      padding: getPadding(paddingOffset, boundsPadding),
    })
    map?.fitBounds(blockBounds!, {
      // padding: getPadding(),
      animate: boundsInitialized,
    })

    // only set the first time.
    if (!boundsInitialized) {
      setBoundsInitialized(true)
    }
  }, [map, blockBounds, boundsInitialized])

  React.useEffect(() => {
    // offset so the sample pin can be seen overtop of the open map panel.
    map?.flyTo({
      center: highlightedSample?.geometry?.coordinates as mapboxgl.LngLatLike,
      padding: getPadding(paddingOffset, boundsPadding),
    })
    return waitForLoaded(
      () => !!map?.isStyleLoaded() && !!map?.isSourceLoaded(SAMPLE_PLAN_POINTS),
      () => addHighlightedSampleHaloSourcesAndLayers({ map, highlightedSample })
    )
  }, [map, highlightedSample])

  // User location updates.
  React.useEffect(() => {
    removeSourceAndLayer(USER_POSITION_MARKER, map)
    removeSourceAndLayer(USER_POSITION_ACURRACY_MARKER, map)
    removeSourceAndLayer(USER_POSITION_HEADING_LINE, map)

    if (!!userPosition) {
      Object.entries(userPosition.sources).forEach((sourceEntry) => {
        map?.addSource(sourceEntry[0], sourceEntry[1] as any)
      })
      userPosition.layers.forEach((layer) => {
        map?.addLayer(layer as mapboxgl.AnyLayer)
      })
    }
  }, [map, userPosition])

  return {
    setBlockBounds: (bounds: mapboxgl.LngLatBoundsLike) =>
      setBlockBounds(bounds),
  }
}
