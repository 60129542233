import * as Sentry from '@sentry/browser'

let token: string | null = null

if (typeof window !== 'undefined') {
  const url = new URL((window as any).location.toString())
  token = url.searchParams?.get('impersonationToken')
}

Sentry.setTag('impersonating', !token ? 'no' : 'yes')

export function getImpersonationToken() {
  return token
}
