import { ActionDecorator } from './ActionDecorator'
import { UserTagDecorator } from './UserTagDecorator'

export const commentFieldDecorators = [
  {
    component: UserTagDecorator,
    regex: /\@__[\w.@]+/g,
  },
  {
    component: ActionDecorator,
    regex: /\/__[\w.@]+/g,
  },
]
