import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { default as TextField } from '@mui/material/TextField'
import { Form } from '../../../admin/UI/Form'
import * as React from 'react'
import { Organization_create_input } from '../../../graphql/types'
import { countries } from '../../../util/countries'

// countries we service
const { FR, US, NZ, GB, AU } = countries
const COUNTRIES = Object.entries({ FR, US, NZ, GB, AU })

export default class OrganizationForm extends Form<Organization_create_input> {
  render() {
    const { name = '', billingCountry = '' } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <h2>{this.props.type === 'edit' ? 'Edit' : 'New'} Organization</h2>
        </div>
        <div className="grid-xs-12">
          <TextField
            name="name"
            label={`Name`}
            fullWidth
            multiline
            value={name || undefined}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!name,
            }}
          />
        </div>
        <div className="grid-xs-12">
          <FormControl fullWidth>
            <InputLabel shrink={!!billingCountry}>Billing Country</InputLabel>
            <Select
              name="billingCountry"
              fullWidth
              onChange={this.updateFormDataFromSelectChangeEvent}
              value={billingCountry}
            >
              {COUNTRIES.map(([code, country]) => (
                <MenuItem key={code} value={code}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }
}
