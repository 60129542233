import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import * as React from 'react'

import { default as MapboxDraw } from '@mapbox/mapbox-gl-draw'
import {
  Button,
  buttonClasses,
  Icon,
  Paper,
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Tooltip,
  Typography,
} from '@mui/material'
import area from '@turf/area'
import { featureCollection } from '@turf/helpers'
import length from '@turf/length'

import { Column } from '../admin/UI/Column'
import i18n, { keys } from '../i18n'
import { default as withMap, WithMap } from '../map/withMap'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import { formatNum } from '../util/formatNumber'
import { convertArea } from '../util/units/area'
import { convertLength } from '../util/units/length'
import { setStatsCustomZones, toggleStatsZonesToolActive } from './actions'
import { createStyles } from './drawStyles'
import { lineSVG, polygonSVG } from './drawSVGIcons'

export const MEASURE_CONTROL_ELEMENT_ID = 'VVMeasureControl'

interface State {
  measuredLength?: number
  measuredArea?: number
  measuredPerimiter?: number

  selectedMode?: string
}
const green = 'hsl(134, 43%, 50%)'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`&.${toggleButtonGroupClasses.root}`]: {
    marginTop: 2,
    [`& .${toggleButtonClasses.root}`]: {
      [`&:first-of-type`]: {
        border: 0,
      },
      borderBottom: 0,
      borderRight: 0,
      borderLeft: 0,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,
      minWidth: 32,
      minHeight: 32,
      width: 32,
      height: 32,
      [`&.${toggleButtonClasses.selected}`]: {
        backgroundColor: theme.palette.primary.main,
      },
      ['& .icon-wrapper']: {
        // eslint-disable-line no-useless-computed-key
        marginTop: 6,
        svg: {
          path: {
            stroke: theme.palette.text.primary,
            fill: 'none',
            strokeWidth: 2,
          },
          circle: {
            fill: theme.palette.text.primary,
          },
          polygon: {
            fill: 'none',
            stroke: theme.palette.text.primary,
            strokeWidth: 2,
          },
        },
      },
    },
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${buttonClasses.root}`]: {
    color: theme.palette.text.primary,
    minWidth: 32,
    minHeight: 32,
    width: 32,
    height: 32,
  },
}))

class MeasureTool extends React.Component<
  WithMap & ReduxProps & AppDispatchProps,
  State
> {
  state: State = {}
  private draw: MapboxDraw = new MapboxDraw({
    displayControlsDefault: false,
    styles: createStyles(green, green),
  })

  componentDidMount() {
    const { map } = this.props
    map.addControl(this.draw, 'top-left')

    const canvas = map.getCanvasContainer()
    canvas.addEventListener('keydown', this.handleKeyDown)

    map.on('draw.create', this.updateMeasurements)
    map.on('draw.delete', this.updateMeasurements)
    map.on('draw.update', this.updateMeasurements)
    map.on('draw.modechange', this.updateMode)

    map.on('draw.selectionchange', this.updateMeasurements)
  }

  componentWillUnmount() {
    const { map } = this.props

    const canvas = map.getCanvasContainer()
    canvas.removeEventListener('keydown', this.handleKeyDown)

    map.removeControl(this.draw)
    map.off('draw.create', this.updateMeasurements)
    map.off('draw.delete', this.updateMeasurements)
    map.off('draw.update', this.updateMeasurements)

    map.off('draw.modechange', this.updateMode)
    map.off('draw.selectionchange', this.updateMeasurements)
  }

  updateMode = ({ mode }: { mode: string }) => {
    this.setState({ selectedMode: mode })
  }

  componentDidUpdate(prevProps: WithMap & ReduxProps & AppDispatchProps) {
    if (
      !prevProps.isStatsZonesToolActive &&
      this.props.isStatsZonesToolActive
    ) {
      this.updateStatsZones()
      if (this.draw.getMode() !== 'draw_polygon') {
        this.draw.changeMode('draw_polygon')
        this.setState({ selectedMode: 'draw_polygon' })
      }
    }
  }

  updateMeasurements = () => {
    const selectedFeature = this.draw.getSelected().features[0]
    if (!selectedFeature) {
      this.setState({
        measuredArea: undefined,
        measuredPerimiter: undefined,
        measuredLength: undefined,
      })

      return
    }
    if (selectedFeature.geometry.type === 'Polygon') {
      const featureArea = area({
        type: 'FeatureCollection',
        features: [selectedFeature],
      })

      const featurePerimiter = length({
        type: 'FeatureCollection',
        features: [selectedFeature],
      })
      this.setState({
        measuredArea: featureArea,
        measuredPerimiter: featurePerimiter,
        measuredLength: undefined,
      })
    }

    if (selectedFeature.geometry.type === 'LineString') {
      const featureLength = length({
        type: 'FeatureCollection',
        features: [selectedFeature],
      })

      this.setState({
        measuredArea: undefined,
        measuredPerimiter: undefined,
        measuredLength: featureLength,
      })
    }

    if (this.props.isStatsZonesToolActive) {
      this.updateStatsZones()
    }
  }

  updateStatsZones = () => {
    if (this.draw.getMode() === 'draw_polygon') {
      this.draw.changeMode('simple_select')
      this.setState({ selectedMode: undefined })
    }

    const features = this.draw
      .getAll()
      .features.filter(
        (feature) =>
          feature.geometry.type === 'Polygon' ||
          feature.geometry.type === 'MultiPolygon'
      )
    if (features.length > 0) {
      this.props.dispatch(setStatsCustomZones(featureCollection(features)))
    } else {
      this.props.dispatch(setStatsCustomZones(undefined))
    }
  }

  toggleStatsZones = () => {
    const { dispatch } = this.props

    dispatch(toggleStatsZonesToolActive(null))
  }

  render() {
    const { map } = this.props
    const { selectedMode, measuredLength, measuredArea, measuredPerimiter } =
      this.state

    if (!map) {
      return null
    }

    const areaSelected = !!measuredArea && !!measuredPerimiter
    const lengthSelected = !!measuredLength

    return (
      <Column className="MeasureTool-map-control">
        <StyledToggleButtonGroup
          orientation="vertical"
          value={selectedMode}
          size="small"
        >
          <ToggleButton value="draw_polygon" onClick={this.activatePolygonMode}>
            <Tooltip title={i18n.t(keys.drawPolygon)} placement="right">
              <div className="icon-wrapper" color="inherit">
                {polygonSVG}
              </div>
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value="draw_line_string"
            onClick={this.activateLineMode}
          >
            <Tooltip title={i18n.t(keys.drawLine)} placement="right">
              <div className="icon-wrapper">{lineSVG}</div>
            </Tooltip>
          </ToggleButton>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup
          orientation="vertical"
          value={
            this.props.isStatsZonesToolActive ? 'stats_zones_active' : null
          }
          size="small"
        >
          <ToggleButton
            value="stats_zones_active"
            onClick={this.toggleStatsZones}
            sx={{ p: 1 }}
          >
            <Tooltip title={i18n.t(keys.statsZones)} placement="right">
              <Icon fontSize="small">highlight_alt</Icon>
            </Tooltip>
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Paper sx={{ my: 1 }}>
          <Column className="draw-controls">
            <Tooltip title={i18n.t(keys.clearAll)} placement="right">
              <StyledButton onClick={this.clear}>
                <Icon fontSize="small" className="icon-wrapper">
                  delete
                </Icon>
              </StyledButton>
            </Tooltip>
          </Column>
        </Paper>
        {(areaSelected || lengthSelected) && (
          <Paper
            className="paper"
            id={MEASURE_CONTROL_ELEMENT_ID}
            style={{
              padding: 8,
            }}
          >
            <Column className="Measurements">
              <Typography component="span" variant="subtitle1">
                Measurements ({areaSelected ? 'Polygon' : 'Line'}):
              </Typography>
              {measuredArea && this.renderArea()}
              {measuredPerimiter && this.renderPerimiter()}
              {measuredLength && this.renderLength()}
            </Column>
          </Paper>
        )}
      </Column>
    )
  }

  handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === 'delete' || ev.key === 'Backspace') {
      this.draw.delete(this.draw.getSelectedIds())
      this.updateMeasurements()
      this.draw.changeMode(this.state.selectedMode ?? 'draw_polygon')
    }
  }

  clear = () => {
    this.draw.deleteAll()
    this.updateMeasurements()
    this.draw.changeMode('simple_select')
    this.updateStatsZones()
  }

  activatePolygonMode = () => {
    const { selectedMode } = this.state

    if (selectedMode === 'draw_polygon') {
      this.draw.changeMode('simple_select')
      this.setState({ selectedMode: 'simple_select' })
    } else {
      this.draw.changeMode('draw_polygon')
      this.setState({ selectedMode: 'draw_polygon' })
    }
  }

  activateLineMode = () => {
    const { selectedMode } = this.state

    if (selectedMode === 'draw_line_string') {
      this.draw.changeMode('simple_select')
      this.setState({ selectedMode: 'simple_select' })
    } else {
      this.draw.changeMode('draw_line_string')
      this.setState({ selectedMode: 'draw_line_string' })
    }
  }

  renderArea = () => {
    const { measuredArea } = this.state
    const { preferredUnitSystem } = this.props

    const { unit: areaUnit, value: areaValue } = convertArea(
      measuredArea ?? 0,
      'squareMeter',
      preferredUnitSystem
    )

    return (
      <>
        <Typography variant="caption">{`${i18n.t(
          keys.units.areaLabel
        )}:`}</Typography>
        <Typography component="span" variant="body1">{`${formatNum(
          areaValue
        )} ${areaUnit}`}</Typography>
      </>
    )
  }

  renderPerimiter = () => {
    const { measuredPerimiter } = this.state
    const { preferredUnitSystem } = this.props

    const { unit: perimiterUnit, value: perimiterValue } = convertLength(
      measuredPerimiter ?? 0,
      'kilometer',
      preferredUnitSystem
    )

    return (
      <>
        <Typography variant="caption">{`${i18n.t(
          keys.units.perimiterLabel
        )}:`}</Typography>
        <Typography component="span" variant="body1">{`${formatNum(
          perimiterValue
        )} ${perimiterUnit}`}</Typography>
      </>
    )
  }

  renderLength = () => {
    const { measuredLength } = this.state
    const { preferredUnitSystem } = this.props

    const { unit: lengthUnit, value: lengthValue } = convertLength(
      measuredLength ?? 0,
      'kilometer',
      preferredUnitSystem
    )

    return (
      <>
        <Typography variant="caption">{`${i18n.t(
          keys.units.lengthLabel
        )}:`}</Typography>
        <Typography component="span" variant="body1">{`${formatNum(
          lengthValue
        )} ${lengthUnit}`}</Typography>
      </>
    )
  }
}

const mapStateToProps = (state: RootStore) => ({
  isMeasureToolActive: state.postgis.isMeasureToolActive,
  preferredUnitSystem: state.preferences.preferredUnitSystem,
  isStatsZonesToolActive: state.postgis.isStatsZonesToolActive,
})

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect<ReduxProps, {}, AppDispatchProps>(mapStateToProps)(
  withMap(MeasureTool)
)
