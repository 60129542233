import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import { CircularProgress, Stack } from '@mui/material'

import { resendInvite } from './resendInvite'
import i18n, { keys } from '../../../i18n'
import { url, urls } from '../../../appNavigation/urls'
import Page from '../../../app/Page'
import Container from '../../../UI/Container'

export const ResendInvitation = () => {
  const { inviteEmail, organizationId } = useParams<{
    inviteEmail: string
    organizationId: string
  }>()

  const history = useHistory()

  React.useEffect(() => {
    if (inviteEmail && organizationId !== undefined) {
      resendInvite({
        email: decodeURIComponent(inviteEmail),
        organizationId,
      }).then(() => {
        swal({ title: i18n.t(keys.confirmInviteResendSuccess) })
      })

      history.replace(
        url(urls.organizationSettings, { organizationId: organizationId })
      )
    }
  }, [inviteEmail, organizationId])

  return (
    <Page>
      <Container>
        <Stack
          sx={{ height: '100%', width: '100%' }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      </Container>
    </Page>
  )
}
