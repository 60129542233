import { gql, query } from '../../../../../graphql/client'
import { SelectedPackage } from '../PackageSelection'
import { refreshOrderPackageSelection } from '../selectOrderPackageSelection'

const UPDATE_ORDER_PACKAGE_SELECTION = gql`
  mutation UPDATE_ORDER_PACKAGE_SELECTION(
    $orderId: uuid!
    $targetDeliveryDate: date!
    $parcelPackages: [TargetDelivery_Parcel_Package_insert_input!]!
  ) {
    delete_TargetDelivery_Parcel_Package(
      where: {
        _and: [
          { orderId: { _eq: $orderId } }
          { targetDeliveryDate: { _eq: $targetDeliveryDate } }
        ]
      }
    ) {
      affected_rows
    }

    insert_TargetDelivery_Parcel_Package(objects: $parcelPackages) {
      affected_rows
    }
  }
`

export async function updateOrderPackageSelection(
  organizationId: string,
  orderId: string,
  targetDeliveryDate: string,
  parcelPackages: SelectedPackage[]
) {
  const result = await query({
    variables: {
      orderId,
      targetDeliveryDate,
      parcelPackages: parcelPackages.map((data) => ({
        ...data,
        organizationId,
        orderId,
        targetDeliveryDate,
      })),
    },
    query: UPDATE_ORDER_PACKAGE_SELECTION,
  })
  refreshOrderPackageSelection()

  return result
}
