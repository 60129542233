import * as React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'

export interface MapRFProps<P = any> {
  (props: FieldRenderProps<P>): P
}

export default function wrapComponentWithField<
  P extends JSX.IntrinsicAttributes = {}
>(
  C: React.ComponentType<P>,
  mapRFProps: MapRFProps<P>
): React.ComponentType<P & FieldProps<P, FieldRenderProps<P>>> {
  class Wrapper extends React.PureComponent<
    P & FieldProps<P, FieldRenderProps<P>>
  > {
    static displayName = `RFMUI(${C.displayName || C.name})`

    render() {
      return (
        <Field
          component={this.renderComponent as any}
          {...(this.props as any)}
        />
      )
    }

    renderComponent = (props: FieldRenderProps<P>) => {
      return <C {...mapRFProps(props)} />
    }
  }

  return Wrapper
}
