import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import Page from '../../app/Page'
import { RouteParams, url, urls } from '../../appNavigation/urls'
import * as api from '../../graphql/api'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import errorAlert from '../errorAlert'
import deletePackages from './deletePackages'
import PackageForm from './PackageForm'
import { EditPackageData, selectEditPackage } from './selectEditPackage'
import SelectMapLayerDefFrom from './SelectMapLayerDefFrom'
import updatePackageMapLayerDefs from './updatePackageMapLayerDefs'

class EditPackage extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps<RouteParams>
> {
  render() {
    const { editPackageData } = this.props
    const { package: packageData } =
      editPackageData.data ?? ({} as Partial<EditPackageData>)

    return (
      <Page
        title={`Edit Package`}
        backTo={url(urls.listPackages)}
        backToTitle="Packages"
      >
        <div id="EditPackage" className="MapDataContainerSmall">
          <PackageForm
            type="edit"
            saveMode="all"
            data={packageData}
            onDelete={this.handleDelete}
            onSave={this.handleSave}
          />
          <br />
          <br />

          <SelectMapLayerDefFrom
            type="edit"
            data={editPackageData.data}
            onSave={this.handleSaveMapLayerDefSelection}
          />
        </div>
      </Page>
    )
  }

  handleSave = async (formData: EditPackageData['package']) => {
    const { packageId } = this.props.match.params
    const { name, description, code } = formData

    try {
      await api.productPackage.update({
        pk: { id: packageId! },
        input: {
          name,
          description,
          code,
        },
      })

      this.goHome()
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Edit Package', message, this.state)
    }
  }

  handleSaveMapLayerDefSelection = async (formData: EditPackageData) => {
    const { packageId } = this.props.match.params
    const { selectedMapLayerDefs } = formData

    try {
      await updatePackageMapLayerDefs(
        packageId!,
        selectedMapLayerDefs.map(({ id }) => id)
      )
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Edit Package', message, this.state)
    }
  }

  goHome = () => {
    this.props.history.goBack()
  }

  handleDelete = async () => {
    const {
      editPackageData: { data: editPackageData },
    } = this.props

    if (!editPackageData) {
      return
    }

    await deletePackages([editPackageData.package.id!])
    this.goHome()
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
      file: 'EditPackage.tsx',
    },
  })

const mapState = (state: RootStore) => ({
  editPackageData: selectEditPackage(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(EditPackage)
)
