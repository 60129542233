import { PaperProps, Paper } from '@mui/material'
import * as React from 'react'
import Draggable from 'react-draggable'
import { updatePreferences } from '../redux/preferences/redux'
import { WindowPosition } from '../redux/preferences/types'
import { dispatch } from '../redux/store'

export const DraggablePaper = ({
  position,
  onPositionUpdate,
  ...props
}: PaperProps & {
  position?: WindowPosition
  onPositionUpdate: (pos: WindowPosition) => void
}) => {
  const handleStop = (
    _event: React.MouseEvent<HTMLElement>,
    dragElement: { x: number; y: number }
  ) => {
    dispatch(
      updatePreferences({
        notesWindowPosition: { x: dragElement.x, y: dragElement.y },
      })
    )
  }

  const draggableRef = React.useRef<HTMLDivElement>()
  const resizeObserverRef = React.useRef<ResizeObserver>()

  const handleDialiogPosition = () => {
    const newPosition = { ...(position ?? { x: 0, y: 0 }) }
    const windowHeight =
      window.innerHeight ?? document.documentElement.clientHeight
    const windowWidth =
      window.innerWidth ?? document.documentElement.clientWidth

    if (!draggableRef?.current) {
      return
    }

    const rect = draggableRef?.current?.getBoundingClientRect()
    const dialogHeight = rect?.height ?? 0
    const dialogWidth = rect?.width ?? 0

    if (rect.y < 0) {
      newPosition.y = -windowHeight / 2 + dialogHeight / 2
    }

    if (rect.x < 0) {
      newPosition.x = -windowWidth / 2 + dialogWidth / 2
    }

    if (
      (position?.x ?? 0) !== newPosition.x ||
      (position?.y ?? 0) !== newPosition.y
    ) {
      onPositionUpdate(newPosition)
    }
  }

  React.useEffect(() => {
    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect()
    }
    if (draggableRef.current) {
      const observer = new ResizeObserver(handleDialiogPosition)

      observer.observe(draggableRef.current)

      resizeObserverRef.current = observer
    }
  }, [draggableRef])

  React.useEffect(handleDialiogPosition, [position, draggableRef])

  return (
    <Draggable
      defaultClassName=""
      bounds="body"
      position={position}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      onStop={handleStop}
    >
      <Paper
        ref={draggableRef as React.MutableRefObject<HTMLInputElement>}
        {...props}
        sx={{
          width: 'initial !important',
          opacity: '1 !important',
          pointerEvents: 'all',
          m: '0 !important',
        }}
      />
    </Draggable>
  )
}
