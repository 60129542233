import * as gravatar from 'gravatar'
import * as React from 'react'

import {
  Avatar,
  AvatarGroup,
  Icon,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import { selectMe } from '../../../data/selectMe'
import { Watchable } from '../../../graphql/types'
import { useRedux } from '../../../hooks/useRedux'
import { isNumericIdEqual } from '../../../util/isNumericIdEqual'

interface Props {
  watched?: Watchable
  onToggleWatch: (userId: string, watch: boolean) => void
}

export const Watchers = ({ watched, onToggleWatch }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [iWatch, setIWatch] = React.useState(false)

  const [state] = useRedux()
  const me = selectMe(state)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (watched?.Watchers?.length === 0) {
      return
    }

    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  React.useEffect(() => {
    if (!watched || !me) {
      return
    }
    for (const watcher of watched.Watchers ?? []) {
      if (isNumericIdEqual(watcher.User.id, me.id)) {
        setIWatch(true)

        return
      }
    }

    setIWatch(false)
  }, [watched, me])

  const handleToggleWatchDelivery = async () => {
    if (!watched || !me) {
      return
    }

    await onToggleWatch(me.id, !iWatch)
  }

  const open = Boolean(anchorEl)

  if (!watched) {
    return null
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="caption">Watchers:</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <div>
          <AvatarGroup
            max={4}
            style={{ cursor: 'pointer' }}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {watched.Watchers?.map(
              ({ User: { id, firstName, lastName, email } }) => (
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  key={id}
                  alt={`${firstName} ${lastName} (${email})`}
                  src={gravatar.url(email, { d: 'mm', r: 'g' })}
                />
              )
            )}
          </AvatarGroup>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Stack sx={{ p: 1 }} spacing={1}>
              {watched.Watchers?.map(
                ({ User: { id, firstName, lastName, email } }) => (
                  <Stack direction="row" spacing={1}>
                    <Avatar
                      key={id}
                      alt={`${firstName} ${lastName} (${email})`}
                      src={gravatar.url(email, { d: 'mm', r: 'g' })}
                    />
                    <Typography key={id}>
                      {`${firstName} ${lastName} (${email})`}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>
          </Popover>
        </div>
        <Tooltip title={iWatch ? 'Unwatch Delivery' : 'Watch Delivery'}>
          <IconButton onClick={() => handleToggleWatchDelivery()}>
            <Icon color={iWatch ? 'primary' : undefined}>visibility</Icon>
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
