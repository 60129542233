import * as React from 'react'

export const polygonSVG = (
  <svg height="25" width="25">
    <g>
      <polygon points="7,7 7,18 18,18, 18,7" />
      <circle cx="7" cy="7" r="2" />
      <circle cx="7" cy="18" r="2" />
      <circle cx="18" cy="18" r="2" />
      <circle cx="18" cy="7" r="2" />
    </g>
  </svg>
)

export const lineSVG = (
  <svg height="25" width="25">
    <g>
      <path d="M7 18 L10 10 L15 15 L18 7" />
      <circle cx="7" cy="18" r="2" />
      <circle cx="10" cy="10" r="2" />
      <circle cx="15" cy="15" r="2" />
      <circle cx="18" cy="7" r="2" />
    </g>
  </svg>
)
