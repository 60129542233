import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import * as api from '../../graphql/api'
import { Model } from '../../graphql/types'
import errorAlert from '../errorAlert'
import assertDefinedFields from '../mapdata/utils/assertDefinedFields'
import MapSourceDefForm from './MapSourceDefForm'
import { GetMapSourceDefData } from './selectGetMapSourceDef'

class NewMapSourceDef extends React.PureComponent<RouteComponentProps> {
  render() {
    return (
      <Page
        title={`New Source Definition`}
        backTo={url(urls.listSourceDefs)}
        backToTitle="Source Defs"
      >
        <div id="NewMapSourceDef" className="MapDataContainerSmall">
          <MapSourceDefForm
            type="new"
            onSave={this.handleCreate}
            onCancel={this.goHome}
          />
        </div>
      </Page>
    )
  }

  handleCreate = async (formData: Partial<GetMapSourceDefData>) => {
    const { name, description, type } = formData
    const requiredFields = {
      name,
      description,
      type,
    }

    try {
      if (assertDefinedFields(requiredFields)) {
        const req = await api.mapSourceDef.create<Model>({
          input: requiredFields,
        })

        this.props.history.replace(
          url(urls.editSourceDef, { mapSourceDefId: req.data!.id })
        )
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Source Definition', message, this.state)
    }
  }

  goHome = () => {
    this.props.history.push(url(urls.listSourceDefs))
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default withRouter(NewMapSourceDef)
