import * as React from 'react'

import { Link } from 'react-router-dom'

import { url, urls } from '../../../appNavigation/urls'
import { Avatar, Icon } from '@mui/material'
import { TableFormatter } from '../../../UI/Table/types'
import { ParcelDataSetMigration } from './ParcelDataSetMigration'

const style: React.CSSProperties = {
  color: 'var(--color)',
  backgroundColor: 'var(--green)',
  width: 20,
  height: 20,
  verticalAlign: 'middle',
}

export const migrationStatusTableFormatter = () => {
  const formatter: TableFormatter<ParcelDataSetMigration>[] = [
    {
      header: () => 'ParcelDataSet Id',
      data: ({ id }) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {id}
        </div>
      ),
    },
    {
      header: () => 'Organization',
      data: ({ parcel: { organization } }) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Link
            to={url(urls.migrationStatus, {
              organizationId: organization.id.toString(),
            })}
          >
            {organization.name}
          </Link>
        </div>
      ),
    },
    {
      header: () => 'Date',
      data: ({ acquisitionDate, subscriptionProduct }) =>
        convertDate(
          subscriptionProduct?.scheduledAcquisitionDate || acquisitionDate
        ),
    },
    {
      header: () => 'Group/Parcel',
      data: ({
        parcel: {
          name: parcelName,
          group: { name: groupName },
        },
      }) => `${groupName}/${parcelName}`,
    },
    {
      header: () => 'Migrated',
      data: ({ deliveryParcel }) =>
        deliveryParcel?.migratedByVersion && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Avatar style={style}>
              <Icon fontSize="small">check_circle_outline</Icon>
            </Avatar>
            {deliveryParcel.migratedByVersion}
          </div>
        ),
    },
    {
      header: () => 'Migrated At',
      data: ({ deliveryParcel }) =>
        deliveryParcel && convertDate(deliveryParcel.migratedAt),
    },
  ]

  return formatter
}

const convertDate = (dateString?: string) => {
  if (!dateString) {
    return undefined
  }
  const date = new Date(dateString)

  return date.toLocaleDateString()
}
