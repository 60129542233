import './FocusedSamplePopup.scss'

import * as React from 'react'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import MapPopup from './MapPopup'
import { Divider, IconButton, Stack, Typography } from '@mui/material'
import { ChevronRight, Close } from '@mui/icons-material'
import { calc } from '../../util/calc'

interface Props {
  canEdit?: boolean
  onClose?: () => void
  onEdit?: (sampleId: string | number | undefined) => void
}

export const FocusedSamplePopup = ({ canEdit, onClose, onEdit }: Props) => {
  const [state] = useRedux()
  const focusedPoint = React.useMemo(
    () => state.postgis.focusedPoint,
    [state.postgis.focusedPoint]
  )

  const propertyMap = React.useMemo(
    () =>
      Object.entries(focusedPoint?.properties ?? {}).reduce(
        (acc, [propertyName, propertyValue]) => {
          switch (propertyName) {
            case 'RowID':
              return {
                ...acc,
                [i18n.t(keys.valueNames.RowID)]: propertyValue,
              }
            case 'PlantIndex':
              return {
                ...acc,
                [i18n.t(keys.valueNames.PlantID)]: propertyValue,
              }
            case 'EVI':
              return {
                ...acc,
                [i18n.t(keys.valueAcronyms.EVI)]: calc.roundToDecimalPlace(propertyValue),
              }
            default:
              return acc
          }
        },
        {} as Record<string, string>
      ),
    [focusedPoint?.properties]
  )

  if (
    !focusedPoint ||
    focusedPoint.properties.length === 0 ||
    !focusedPoint.geometry
  ) {
    return null
  }

  return (
    <MapPopup
      coordinates={focusedPoint.geometry?.coordinates}
      open={!!focusedPoint && Object.entries(propertyMap).length > 0}
      className="focused-sample-popup"
    >
      <Stack direction="column" style={{ width: '144px' }}>
        <Stack
          direction="row"
          padding={1}
          alignItems="center"
          justifyContent="space-between"
        >
          {canEdit && (
            <Stack
              alignItems="center"
              justifyContent="center"
              direction="row"
              onClick={() => onEdit?.(focusedPoint.properties?.id)}
              style={{ cursor: 'pointer' }}
            >
              <Typography
                variant="overline"
                lineHeight="15px"
                fontSize="12px"
                fontWeight={400}
              >
                {i18n.t(keys.sample)}
              </Typography>
              <ChevronRight
                style={{ color: 'white', height: '16px', padding: 0 }}
              />
            </Stack>
          )}
          <Stack justifySelf="end" alignSelf="end">
            <IconButton onClick={onClose} style={{ padding: 0 }}>
              <Close
                style={{
                  color: 'white',
                  height: '16px',
                  width: '16px',
                  padding: 0,
                }}
              />
            </IconButton>
          </Stack>
        </Stack>
        <Divider
          variant="fullWidth"
          flexItem
          style={{ backgroundColor: '#8C8A88' }}
        />
        <Stack direction="column" padding={1} gap={1}>
          {Object.entries(propertyMap).map(([key, value]) => (
            <Stack direction="column" key={key}>
              <Typography
                variant="overline"
                lineHeight="15px"
                fontSize="12px"
                fontWeight={400}
                color="#FFFFFFB2"
              >
                {key}
              </Typography>
              <Typography
                variant="h6"
                lineHeight="21px"
                fontSize="16px"
                fontWeight={500}
              >
                {value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </MapPopup>
  )
}
