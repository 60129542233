import { AdminRole } from '../../../graphql/types'
import { TableFormatter } from '../../../UI/Table/types'

const userIdColumn: TableFormatter<AdminRole> = {
  header: () => 'User ID',
  data: ({ userId }) => userId,
  orderBy: (direction) => `
    {
     userId: ${direction}
    }
  `,
  filterBy: (op, role) => ({
    userId: { [`_${op}`]: role },
  }),
}

const nameColumn: TableFormatter<AdminRole> = {
  header: () => 'Name',
  data: ({ User: { firstName, lastName } }) => `${firstName} ${lastName}`,
  orderBy: (direction) => `
    {
      User:{firstName: ${direction}}
    }
  `,
  filterBy: (op, role) => ({
    User: { firstName: { [`_${op}`]: role } },
  }),
}

const emailColumn: TableFormatter<AdminRole> = {
  header: () => 'Email',
  data: ({ User }) => User.email,
  orderBy: (direction) => `
    {
      User: {email:${direction}}
    }
  `,
  filterBy: (op, role) => ({
    User: { email: { [`_${op}`]: role } },
  }),
}
const roleColumn: TableFormatter<AdminRole> = {
  header: () => 'Role',
  data: ({ role }) =>
    typeof role === 'object' ? JSON.stringify(role) : role,
  orderBy: (direction) => `
    {
      role: ${direction}
    }
  `,
  filterBy: (op, role) => ({
    role: { [`_${op}`]: role },
  }),
}
export const tableFormatter = [
  userIdColumn,
  nameColumn,
  emailColumn,
  roleColumn,
]
