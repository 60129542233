import { Feature, MultiPolygon, Polygon, Properties } from '@turf/helpers'
import union from '@turf/union'

export const unionMany = (
  features: (Feature<Polygon | MultiPolygon, Properties> | null)[]
) => {
  let result = features.shift()

  if (result) {
    while (features.length > 0) {
      const nextFeature = features.shift()
      if (nextFeature && result) {
        const nextResult: Feature<Polygon | MultiPolygon, Properties> | null =
          union(result, nextFeature) as Feature<
            Polygon | MultiPolygon,
            Properties
          > | null
        if (nextResult) {
          result = nextResult
        }
      }
    }
  }

  return result
}
