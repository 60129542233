import {
  Button,
  Icon,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import sweetalert from 'sweetalert'
import i18n from '../i18n'

interface NotificationButtonProps {
  notificationName: string
  sentAt?: string
  update?: boolean
  error?: string
  beforeSend?: () => boolean
  send: (update?: boolean) => Promise<void> | void
  inhibitConfirm?: boolean
}
// tslint:disable-next-line: function-name
export function NotificationButton(props: NotificationButtonProps) {
  const {
    notificationName,
    sentAt,
    send,
    update = false,
    error,
    inhibitConfirm = false,
  } = props
  const theme = useTheme()
  const action = sentAt ? 'Resend' : 'Send'

  const handleSendNotification = async () => {
    if (inhibitConfirm) {
      return await send(update)
    }
    const choice = await sweetalert(
      `Are you sure you want to ${action} the ${notificationName} Notification?`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: action,
          },
        },
        dangerMode: true,
      }
    )

    // sweetalert returns null for "cancel"
    if (!choice) {
      return false
    }

    if (choice === true) {
      await send(update)
    }

    return true
  }

  return (
    <Stack alignItems="center">
      <Button
        color="primary"
        variant={action === 'Resend' && !update ? 'outlined' : 'contained'}
        onClick={handleSendNotification}
        sx={{
          fontWeight: update ? 'bold' : undefined,
          '&.Mui-disabled': {
            '& .MuiButton-endIcon': {
              pointerEvents: 'all',
              '& .MuiIcon-root': {
                color: `${theme.palette.error.main}`,
              },
            },
          },
        }}
        disabled={!!error}
        endIcon={
          error ? (
            <Tooltip title={error}>
              <Icon color="error">warning</Icon>
            </Tooltip>
          ) : null
        }
      >
        {action} {notificationName}
      </Button>
      {sentAt && (
        <Typography variant="caption">
          Sent: {i18n.toDateTime(sentAt)}
        </Typography>
      )}
    </Stack>
  )
}
