import './SamplePlanResult.scss'
import {
  Button,
  Divider,
  Hidden,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import Page from '../../../app/Page'
import { useRedux } from '../../../hooks/useRedux'
import { useHistory, useParams } from 'react-router-dom'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import useAsync from '../../../hooks/useAsync'
import {
  DEFAULT_PAGE_INFO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../../appNavigation/urls'
import i18n, { keys } from '../../../i18n'
import { Search } from '../../../UI/Table/Search'
import { LoadingControlBackdrop } from '../../../UI/Loading/LoadingControlBackdrop'
import { Table } from '../../../UI/Table/Table'
import {
  PaginationChange,
  TableFormatter,
  TableOrder,
} from '../../../UI/Table/types'
import { useSearchParams } from '../../../hooks/useRouter'
import { ORDER } from '../../../UI/Table/orderRows'
import { Download, PictureAsPdf } from '@mui/icons-material'
import { SamplePlanFormChip } from '../SamplePlanFormChip'
import { tableFormatter } from './samplePlanResultTableFormatter'
import { fetchSamplePlan, fetchSamplePlanResults } from '../queries'
import { selectMe } from '../../../data/selectMe'
import { Sample } from '../../../graphql/types'
import { SamplePlanDownload } from '../SamplePlanDownload/SamplePlanDownload'
import { DropdownTableFilter } from '../../../UI/Table/DropdownTableFilter/DropdownTableFilter'
import { SamplePlanReportSelector } from '../SamplePlanDetail/SamplePlanReportSelector'

const searchBarFilterId = 'sample-plan-seach-bar-filter'
const inlineFilterId = 'sample-plan-result-block-filter'

export const SamplePlanResult = () => {
  const [state] = useRedux()
  const me = selectMe(state)
  const {
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    filter,
    order,
  } = useSearchParams()
  const history = useHistory()
  const fromMap = (history.location?.state as any)?.fromMap
  const { samplePlanId } = useParams<{ samplePlanId: string }>()
  const organizationId = selectOrganizationId(state)
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false)

  const [downloadPlanOpen, setDownloadPlanOpen] = React.useState(false)

  const [samplePlanQueryResult] = useAsync(fetchSamplePlan, [
    me?.id,
    organizationId,
    samplePlanId,
  ])

  const samplePlan = React.useMemo(() => {
    return (
      {
        ...samplePlanQueryResult?.result?.data,
        NoteForm: {
          ...samplePlanQueryResult?.result?.data?.NoteForm,
        },
      } ?? null
    )
  }, [samplePlanQueryResult?.result?.data])

  const parcels = React.useMemo(() => {
    return (
      samplePlanQueryResult?.result?.data?.SamplePlanBlocks?.map(
        (sp) => sp.Parcel
      ) ?? []
    )
  }, [samplePlanQueryResult?.result?.data])

  const samplePlanResultFormatter = tableFormatter({
    inlineFilterId,
    searchBarFilterId,
  })

  const [samples] = useAsync(
    fetchSamplePlanResults(
      Number(organizationId),
      samplePlanId,
      samplePlanResultFormatter
    ),
    [Number(page), Number(pageSize), order, filter]
  )

  const updateSearchParams = (update: {
    page?: number
    pageSize?: number
    order?: string
    filter?: string
  }) => {
    history.push(
      url(
        urls.samplePlanResults,
        { samplePlanId },
        {
          page: Number(page),
          pageSize: Number(pageSize),
          order,
          filter,
          ...update,
        }
      )
    )
  }

  const rows = samples.result ? samples.result.data : []
  const info = samples.result
    ? samples.result.info
    : { ...DEFAULT_PAGE_INFO, order: [] }

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateSearchParams(pagination)

  const handleOrderChange = (order: TableOrder) => {
    updateSearchParams({ order: ORDER.serialize(order) })
  }

  const noteTemplateFormatter = (
    type: 'number' | 'dropdown' | 'text' | 'checkbox' | 'checkboxes',
    title: string,
    content: Record<string, any>,
  ) => {
    switch (type) {
      case 'dropdown':
      case 'text':
      case 'number':
        return content[title]?.value ?? '-'
      case 'checkbox':
        return content[title]?.value
          ? i18n.t(keys.samplePlanResultCheckBoxContentTrue)
          : i18n.t(keys.samplePlanResultCheckBoxContentFalse)
      case 'checkboxes':
        return content[title]?.value?.map((v: string) => {
          return i18n.t(
            `noteForm.reservedNames.${v}`,
            {defaultValue: v}
          )
        }).join(', ') ?? '-'
      default:
        return '-'
    }
  }

  const noteContentTableFormatter = React.useMemo(() => {
    if (!samples?.result?.data || samples.result.data.length === 0) {
      return []
    }
    const contentTemplate = Object.entries(samples?.result?.data?.[0].content)
    const tableFormatters = contentTemplate?.map(
      (ct): TableFormatter<Sample> => {
        const type = ct[1]['type'] ?? ''
        const title = i18n.t(`noteForm.reservedNames.${ct[1]['title'] }`, {defaultValue: ct[1]['title'] ?? ''}) 
        return {
          header: () => title ?? '',
          data: (row) =>
            !!row.completedAt
              ? noteTemplateFormatter(type, ct[1]['title'], row.content)
              : '-',
          // filter by empty as it's handled by other formatter.
          // filterBy: () => null,
        }
      }
    )
    return tableFormatters ?? []
  }, [samples?.result?.data])

  return (
    <Page
      title={i18n.t(keys.results)}
      backTo={
        fromMap ? url(urls.samplePlan, { samplePlanId }) : url(urls.samplePlans)
      }
      backToTitle={
        fromMap ? i18n.t(keys.samplePlanMap) : i18n.t(keys.samplePlanDashboard)
      }
    >
      <Stack className="sample-plan-result" spacing={2}>
        <Stack
          className="sample-plan-result-options"
          direction="column"
          spacing={2}
        >
          <LoadingControlBackdrop
            open={samplePlanQueryResult.status === 'pending'}
          >
            <Paper className="sample-plan-result-header" elevation={0}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <Stack direction="row" alignItems="center">
                  <Typography variant="h6">
                    {samplePlan?.name ?? '-'}
                  </Typography>
                  <Hidden mdDown>
                    <Divider
                      sx={{ mx: '5px' }}
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                    />
                    <Typography>
                      {!!samplePlan?.createdAt
                        ? i18n.toDateShort(samplePlan.createdAt)
                        : '-'}
                    </Typography>
                    <Divider
                      sx={{ mx: '5px' }}
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                    />
                    <SamplePlanFormChip
                      templateName={
                        i18n.t(
                          `noteForm.reservedNames.${samplePlan?.templateName}`,
                          {defaultValue: samplePlan?.templateName ?? ''}
                       )                       
                      }
                    />
                  </Hidden>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Search
                    tableFormatters={samplePlanResultFormatter}
                    filterId={searchBarFilterId}
                  />
                  <DropdownTableFilter
                    filterId={inlineFilterId}
                    options={samplePlan?.SamplePlanBlocks?.map(
                      (s) => s.Parcel.name
                    ).sort()}
                    tableFormatters={samplePlanResultFormatter}
                    allOptionTitle={i18n.t(keys.allBlocks)}
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Hidden lgDown>
                    <Button
                      title={i18n.t(keys.download)}
                      onClick={() => setDownloadDialogOpen(true)}
                      variant="outlined"
                      startIcon={<Download />}
                    >
                      {i18n.t(keys.download)}
                    </Button>
                  </Hidden>
                  <Hidden lgDown>
                    <Button
                      title={i18n.t(keys.samplePlanDownloadPlanLabel)}
                      variant="outlined"
                      onClick={() => setDownloadPlanOpen(true)}
                      startIcon={<PictureAsPdf />}
                      style={{
                        borderColor: '#FFF',
                        color: '#FFF',
                      }}
                    >
                      {i18n.t(keys.samplePlanDownloadPlanLabel)}
                    </Button>
                  </Hidden>
                </Stack>
              </Stack>
            </Paper>
          </LoadingControlBackdrop>
        </Stack>
        <LoadingControlBackdrop open={samples.status === 'pending'}>
          <Paper className="sample-plan-result-paper" elevation={0}>
            <Table
              rows={rows}
              formatter={[
                ...samplePlanResultFormatter,
                ...noteContentTableFormatter,
              ]}
              order={info.order}
              onOrderChange={handleOrderChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              pagination={info}
              onPaginationChange={handlePaginationChange}
            />
          </Paper>
        </LoadingControlBackdrop>
      </Stack>
      <SamplePlanDownload
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        samplePlanName={samplePlanQueryResult?.result?.data?.name ?? ''}
        samples={samples?.result?.data ?? []}
      />
      <SamplePlanReportSelector
        downloadPlanOpen={downloadPlanOpen}
        setDownloadPlanOpen={setDownloadPlanOpen}
        samplePlan={samplePlanQueryResult.result?.data ?? null}
        parcels={parcels}
      />
    </Page>
  )
}
