import * as React from 'react'
import { MapOption, HeaderOption, DrawerTabOption } from './types'
import {
  Box,
  Icon,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Map } from '../map/Map'
import { createStyles, makeStyles } from '@mui/styles'
import { MapEditorToolType } from '../redux/map-editor-pages/redux'
import { MapEditorDrawers } from './MapEditorDrawers'
import { selectHeaderOptions } from './redux/selectors/selectHeaderOptions'
import { selectMapOptions } from './redux/selectors/selectMapOptions'
import { useRedux } from '../hooks/useRedux'
import { selectDrawerOptions } from './redux/selectors/selectDrawerOptions'
import { MapOverlay } from './MapOverlay'
import { ToggleDrawerButton } from './redux/ToggleDrawerButton'
import { selectAllMapLayers } from '../postgis/selectors/selectAllMapLayers'
import i18n from '../i18n'
import { MapEditorActionTypes, MapSettings } from './redux/types'
import { selectDrawerStatus } from './redux/selectors/selectDrawerStatus'

interface MapEditorProps<HeaderConfig, MapConfig, DrawerConfig> {
  pageType: MapEditorToolType
  pageIcon?: string | React.ReactNode
  defaultDrawerStatus: {
    rightDrawerOpen: boolean
    leftDrawerOpen: boolean
    rightDrawerTabIndex: number
    leftDrawerTabIndex: number
  }
  defaultMapSettings: MapSettings
  defaultHeaderOptions: Record<string, HeaderOption<HeaderConfig>>
  defaultMapOptions: Record<string, MapOption<MapConfig>>
  defaultDrawerTabOptions: Record<string, DrawerTabOption<DrawerConfig>>
  headerComponents: Record<
    string,
    (
      option: HeaderOption<HeaderConfig>,
      page: MapEditorToolType
    ) => React.ReactNode
  >
  mapOverlayComponents: Record<
    string,
    (option: MapOption<MapConfig>, page: MapEditorToolType) => React.ReactNode
  >
  drawerTabComponents: Record<
    string,
    {
      icon: (
        option: DrawerTabOption<DrawerConfig>,
        page: MapEditorToolType
      ) => React.ReactNode
      component: (
        option: DrawerTabOption<DrawerConfig>,
        page: MapEditorToolType
      ) => React.ReactNode
    }
  >
}

const useMapPositionStyles = makeStyles(() =>
  createStyles({
    'top-left': {
      top: 0,
      left: 0,
    },
    'top-center': {
      top: 0,
    },
    'top-right': {
      top: 0,
      right: 0,
    },
    'bottom-left': {
      bottom: 0,
      left: 0,
    },
    'bottom-center': {
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
    'bottom-right': {
      bottom: 0,
      right: 0,
    },
    'center-right': {
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
    },
    'center-left': {
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
    },
    self: {},
  })
)

const MainContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
}))

interface HeaderProps {
  side: 'left' | 'right'
}

const Header = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: '#3E85C7',
  color: '#3E85C7',
  padding: theme.spacing(1),
  position: 'absolute',
  width: '100%',
  height: '54px',
  zIndex: theme.zIndex.drawer + 2,
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const HeaderOptionContainer = styled(Stack)<HeaderProps>(({ theme, side }) => ({
  flexDirection: side === 'left' ? 'row' : 'row-reverse',
  alignItems: 'center',
  gap: theme.spacing(1),
  maxHeight: '54px',
  width: '400px',
}))

const MapContainer = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
}))

const MapOptions = styled(Stack)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(2),
  position: 'absolute',
  zIndex: theme.zIndex.drawer - 2,
}))

const ShellContainer = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}))

const EditorTitleContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

const EditorTitleIcon = styled(Icon)(({ theme }) => ({
  color: '#fff',
  fontSize: 24,
}))

const EditorTitle = styled(Typography)`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: -0.035em;
  text-align: left;
`

export const MapEditor = <HeaderConfig, MapConfig, DrawerConfig>({
  pageType,
  pageIcon,
  defaultDrawerStatus,
  defaultHeaderOptions,
  defaultMapOptions,
  defaultDrawerTabOptions: defaultdrawerOptions,
  headerComponents,
  mapOverlayComponents,
  drawerTabComponents: drawerComponents,
}: MapEditorProps<HeaderConfig, MapConfig, DrawerConfig>) => {
  const [state, dispatch] = useRedux()
  const theme = useTheme()
  const mapOptions = selectMapOptions(state, pageType)

  const mapPositionClasses = useMapPositionStyles()
  const mbStyle = selectAllMapLayers(state)
  const themeMode = state.preferences.theme
  const headerOptions = selectHeaderOptions(state, pageType)
  const drawerOptions = selectDrawerOptions(state, pageType)
  const drawerStatus = selectDrawerStatus(state, pageType)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  React.useEffect(() => {
    if (drawerStatus.leftDrawerOpen === undefined) {
      dispatch({
        type: MapEditorActionTypes.SET_DRAWER,
        page: pageType,
        payload: {
          rightDrawerOpen: defaultDrawerStatus.rightDrawerOpen,
          leftDrawerOpen: defaultDrawerStatus.leftDrawerOpen,
        },
      })
    }
    if (!headerOptions || Object.keys(headerOptions).length === 0) {
      // Set default header options
      dispatch({
        type: MapEditorActionTypes.SET_HEADER_OPTIONS,
        page: pageType,
        payload: { headerOptions: defaultHeaderOptions },
      })
    }
    if (!mapOptions || Object.keys(mapOptions).length === 0) {
      // Set default map options
      dispatch({
        type: MapEditorActionTypes.SET_MAP_OPTIONS,
        page: pageType,
        payload: { mapOptions: defaultMapOptions },
      })
    }
    if (!drawerOptions || Object.keys(drawerOptions).length === 0) {
      // Set default side bar options
      dispatch({
        type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
        page: pageType,
        payload: { drawerOptions: defaultdrawerOptions },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultDrawerStatus,
    defaultHeaderOptions,
    defaultMapOptions,
    defaultdrawerOptions,
    pageType,
  ])

  const headerByPosition = React.useMemo(
    () =>
      Object.keys(headerComponents).reduce((acc, key) => {
        const options = headerOptions
        if (!acc[options[key]?.position]) {
          acc[options[key]?.position] = []
        }
        acc[options[key]?.position].push(key)
        return acc
      }, {} as Record<string, string[]>),
    [headerOptions, headerComponents]
  )

  const sortedLeftHeaderIds = React.useMemo(() => {
    return headerByPosition?.['left']?.sort((a, b) => {
      const aIndex = headerByPosition?.['left']?.indexOf(a)
      const bIndex = headerByPosition?.['left']?.indexOf(b)
      return (
        headerOptions?.[a]?.index ??
        aIndex - headerOptions?.[b]?.index ??
        bIndex
      )
    })
  }, [headerByPosition, headerOptions])

  const sortedRightHeaderIds = React.useMemo(() => {
    const rightHeaderIds = headerByPosition?.['right'] ?? []
    const undefinedHeaderIds = headerByPosition?.['undefined'] ?? []
    const headerIds = [...rightHeaderIds, ...undefinedHeaderIds]
    return headerIds?.sort((a, b) => {
      const aIndex = headerByPosition?.['right']?.indexOf(a)
      const bIndex = headerByPosition?.['right']?.indexOf(b)
      return (
        headerOptions?.[a]?.index ??
        aIndex - headerOptions?.[b]?.index ??
        bIndex
      )
    })
  }, [headerByPosition, headerOptions])

  const mapOverlayByPosition = React.useMemo(
    () =>
      Object.keys(mapOverlayComponents).reduce((acc, key) => {
        const options = mapOptions
        if (!acc[options[key]?.position]) {
          acc[options[key]?.position] = []
        }
        acc[options[key]?.position].push(key)
        return acc
      }, {} as Record<string, string[]>),
    [mapOptions, mapOverlayComponents]
  )

  const drawerByPosition = React.useMemo(
    () =>
      Object.keys(drawerComponents).reduce((acc, key) => {
        const options = drawerOptions
        if (!acc[options[key]?.position]) {
          acc[options[key]?.position] = []
        }
        acc[options[key]?.position].push(key)
        return acc
      }, {} as Record<string, string[]>),
    [drawerComponents, drawerOptions]
  )

  return (
    <MainContainer id="main">
      <ShellContainer id="shell">
        <Header id="header" position="fixed">
          <HeaderOptionContainer direction="row" side="left">
            {(drawerByPosition?.['left-side-bar']?.length ?? 0) +
              (drawerByPosition?.['undefined']?.length ?? 0) >
              0 && <ToggleDrawerButton pageType={pageType} side="left" />}
            {sortedLeftHeaderIds?.map((key) => (
              <Box key={key}>
                {headerComponents[key](
                  (headerOptions[key] as HeaderOption<HeaderConfig>) ??
                    defaultHeaderOptions[key],
                  pageType
                )}
              </Box>
            ))}
          </HeaderOptionContainer>
          <Stack direction="row">
            {!isMobile && (
              <EditorTitleContainer>
                {typeof pageIcon === 'string' ? (
                  <EditorTitleIcon>{pageIcon}</EditorTitleIcon>
                ) : (
                  pageIcon
                )}
                <EditorTitle noWrap>{i18n.t(pageType)}</EditorTitle>
              </EditorTitleContainer>
            )}
          </Stack>
          <HeaderOptionContainer direction="row" side="right">
            {(drawerByPosition?.['right-side-bar']?.length ?? 0) > 0 && (
              <ToggleDrawerButton pageType={pageType} side="right" />
            )}
            {sortedRightHeaderIds?.map((key) => (
              <Box key={key}>
                {headerComponents[key](
                  (headerOptions[key] as HeaderOption<HeaderConfig>) ??
                    defaultHeaderOptions[key],
                  pageType
                )}
              </Box>
            ))}
          </HeaderOptionContainer>
        </Header>
        <MapEditorDrawers
          id="side-bars"
          pageType={pageType}
          drawerByPosition={drawerByPosition}
          drawerOptions={
            (drawerOptions as Record<string, DrawerTabOption<DrawerConfig>>) ??
            defaultdrawerOptions
          }
          drawerComponents={drawerComponents}
        />
      </ShellContainer>
      <MapContainer id="rate-map">
        <Map classNames={[themeMode]} style={mbStyle}>
          {Object.entries(mapOverlayByPosition).map(
            ([position, overlayIds]) => (
              <MapOptions
                key={position}
                className={
                  mapPositionClasses[
                    position as
                      | 'top-left'
                      | 'top-center'
                      | 'top-right'
                      | 'bottom-left'
                      | 'bottom-center'
                      | 'bottom-right'
                      | 'center-right'
                      | 'center-left'
                      | 'self'
                  ]
                }
              >
                {overlayIds.map((overlayId) => (
                  <MapOverlay
                    id={overlayId}
                    key={overlayId}
                    pageType={pageType}
                  >
                    {mapOverlayComponents[overlayId](
                      (mapOptions[overlayId] as MapOption<MapConfig>) ??
                        defaultMapOptions[overlayId],
                      pageType
                    )}
                  </MapOverlay>
                ))}
              </MapOptions>
            )
          )}
        </Map>
      </MapContainer>
    </MainContainer>
  )
}
