import { Icon, Button, Tooltip, ButtonGroup, useTheme } from '@mui/material'
import * as React from 'react'
import { getColor } from '../../pages/Organization/Delivery/utils'
import { Row } from '../UI/Row'
import { getStatusIcon } from './utils'
import { useHistory, useLocation, useSearchParams } from '../../hooks/useRouter'
import { FILTER } from '../../UI/Table/filterRows'

interface JobStatusFilterProps {
  onFilterChanged?: () => void
}

export const JobStatusFilter = ({ onFilterChanged }: JobStatusFilterProps) => {
  const { filter = '' } = useSearchParams()
  const history = useHistory()
  const location = useLocation()
  const statusFilter = React.useRef(new Set<string>())
  const theme = useTheme()
  React.useEffect(() => {
    statusFilter.current = new Set(
      FILTER.deserialize(filter).map((f) => f.value)
    )
  }, [filter])

  const buttonStyle = {
    padding: 5,
    width: 30,
    minWidth: 0,
  }

  const handleFilterStatusClick = (
    ev: React.SyntheticEvent,
    status: string
  ) => {
    ev.stopPropagation()
    ev.preventDefault()
    const searchParams = new URLSearchParams(location.search)

    if (statusFilter.current.has(status)) {
      statusFilter.current.delete(status)
    } else {
      statusFilter.current.add(status)
    }

    if (statusFilter.current.size === 0) {
      searchParams.delete('filter')
    } else {
      searchParams.set(
        'filter',
        FILTER.serialize(
          Array.from(statusFilter.current).map((f) => ({
            op: 'eq',
            index: 2,
            value: f,
          }))
        )
      )
    }

    history.replace({ search: searchParams.toString() })

    onFilterChanged?.()
  }

  return (
    <Row>
      <Tooltip title="Filter by status">
        <Icon fontSize="small">filter_alt</Icon>
      </Tooltip>
      <span style={{ marginRight: 8 }}>:</span>

      <ButtonGroup variant="contained">
        {[
          'created',
          'complete',
          'running',
          'pending',
          'queued',
          'paused',
          'cancelled',
          'error',
          'timeout',
        ].map((status) => (
          <Tooltip key={status} title={status}>
            <Button
              className="button"
              onClick={(ev) => handleFilterStatusClick(ev, status)}
              style={{
                ...buttonStyle,
              }}
              variant={
                statusFilter.current.has(status) ? 'contained' : 'outlined'
              }
              size="small"
            >
              <Icon
                fontSize="small"
                style={{
                  color: statusFilter.current.has(status)
                    ? theme.palette.primary.contrastText
                    : getColor(status),
                  fontSize: 18,
                }}
              >
                {getStatusIcon(status)}
              </Icon>
            </Button>
          </Tooltip>
        ))}
      </ButtonGroup>
    </Row>
  )
}
