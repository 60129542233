import { client, gql } from '../../../graphql/client'

export const cancelInvitation = async (variables: {
  email: string
  organizationId: string
}) => {
  await client.request({
    query: gql`
      mutation CANCEL_INVITATION($email: String!, $organizationId: Int!) {
        delete_UserInvite_by_pk(
          email: $email
          organizationId: $organizationId
        ) {
          email
          organizationId
          role
        }
      }
    `,
    variables,
  })
}
