import Icon from '@mui/material/Icon'
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon'
import * as React from 'react'
import { Omit } from 'react-redux'

type Props = Omit<ListItemIconProps, 'children'> & {
  checked: boolean
}

export default class ListItemCheckbox extends React.PureComponent<Props> {
  render() {
    return (
      <ListItemIcon {...this.props}>
        <Icon>
          {this.props.checked ? 'check_box' : 'check_box_outline_blank'}
        </Icon>
      </ListItemIcon>
    )
  }
}
