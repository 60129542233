import * as React from 'react'
import { Map } from '../../map/Map'
import { MapboxGL } from '../../map/MapboxGL'
import mapboxSatelliteJson from '../../map/styles/mapbox-satellite.json'
import { MapContextProvider } from '../../map/withMap'
import { selectOrderPackageSelection } from '../../pages/Organization/Order/Inputs/selectOrderPackageSelection'
import getBoundsFromParcels from '../../postgis/getBoundsFromParcels'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import LoadingOverlay from '../../UI/LoadingOverlay'
import { isNumericIdEqual } from '../../util/isNumericIdEqual'

interface Props {
  variant: 'delivery' | 'order'
  selectedParcelIds: string[]
  zoomToSelection?: { groupId: string } | { parcelId: string }
}

interface State {
  parcels?: GeoJSON.FeatureCollection<GeoJSON.Polygon>
  bounds?: MapboxGL.LngLatBoundsLike
}

class SelectionMap extends React.PureComponent<ReduxProps & Props, State> {
  state: State = {}

  componentDidMount() {
    this.refreshData(true)
  }

  componentDidUpdate(oldProps: this['props']) {
    if (oldProps.initialSelection.data !== this.props.initialSelection.data) {
      this.refreshData(true)
    }
    if (oldProps.selectedParcelIds !== this.props.selectedParcelIds) {
      this.refreshData(false)
    }
    if (oldProps.zoomToSelection !== this.props.zoomToSelection) {
      this.refreshData(true)
    }
  }

  refreshData = (zoomChanged: boolean) => {
    const { selectedParcelIds, zoomToSelection, initialSelection } = this.props
    if (!initialSelection.data) {
      return this.setState({
        parcels: {
          type: 'FeatureCollection',
          features: [],
        },
      })
    }

    const { parcels } = initialSelection.data

    const parcelPolygons = parcels.map((parcel) => {
      return {
        type: 'Feature',
        geometry: parcel.geometry,
        properties: {
          groupName: parcel.group.name,
          parcelId: parcel.id,
          parcelName: parcel.name,
          packages: parcel.packages,
          hasPackages: selectedParcelIds.includes(parcel.id),
        },
      } as GeoJSON.Feature<GeoJSON.Polygon>
    })

    let bounds: MapboxGL.LngLatBoundsLike | undefined
    if (zoomChanged) {
      if (zoomToSelection) {
        const zoomedParcels = parcels.filter((parcel) => {
          if (
            'groupId' in zoomToSelection &&
            isNumericIdEqual(parcel.group.id, zoomToSelection.groupId)
          ) {
            return true
          }
          if (
            'parcelId' in zoomToSelection &&
            isNumericIdEqual(parcel.id, zoomToSelection.parcelId)
          ) {
            return true
          }

          return false
        })
        const bfp = getBoundsFromParcels(zoomedParcels)
        bounds = bfp && [bfp[0], bfp[1], bfp[2], bfp[3]]
      } else {
        const bfp = getBoundsFromParcels(parcels)
        bounds = bfp && [bfp[0], bfp[1], bfp[2], bfp[3]]
      }
    }

    this.setState({
      bounds,
      parcels: {
        type: 'FeatureCollection',
        features: parcelPolygons,
      },
    })
  }

  render() {
    const { initialSelection } = this.props
    const { bounds, parcels } = this.state

    return (
      <MapContextProvider>
        <Map
          style={{
            version: 8,
            glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
            sources: {
              ...mapboxSatelliteJson.sources,
              parcels: {
                type: 'geojson',
                data: parcels,
              },
            },
            layers: [
              ...mapboxSatelliteJson.layers,
              {
                id: 'parcelBounds',
                type: 'line' as 'line',
                source: 'parcels',
                paint: {
                  'line-width': ['case', ['get', 'hasPackages'], 3, 1],
                  'line-color': [
                    'case',
                    ['get', 'hasPackages'],
                    '#FFCA28',
                    '#000000',
                  ],
                  'line-opacity': 0.6,
                },
              },
              {
                id: 'parcelName',
                type: 'symbol' as 'symbol',
                source: 'parcels',
                paint: {
                  'text-color': 'white',
                  'text-halo-color': 'black',
                  'text-halo-width': 2,
                  'text-halo-blur': 2,
                  'text-opacity': ['case', ['get', 'hasPackages'], 1, 0.4],
                },
                layout: {
                  'text-field': '{parcelName}',
                },
              },
            ],
          }}
          fitBounds={bounds}
          fitBoundsOptions={{ animate: false }}
        />
        {initialSelection.status === 'pending' && <LoadingOverlay />}
      </MapContextProvider>
    )
  }
}

const mapState = (state: RootStore, props: Props) => ({
  initialSelection: selectOrderPackageSelection(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, Props, AppDispatchProps>(mapState)(
  SelectionMap
)
