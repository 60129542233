import * as React from 'react'
import { selectOrganizationList } from "../../../data/selectOrganizationList"
import { Box, Chip, Dialog, FormControl, FormHelperText, InputLabel, MenuItem, Select, DialogTitle, DialogContent, DialogActions, Button, SelectChangeEvent, DialogContentText } from '@mui/material';
import { MaestroCronTaskType, maestroCronTaskTypeArray } from '../../../graphql/types'
import { scheduleReprocessingJob } from './scheduleReprocessingJob';
import { useRedux } from '../../../hooks/useRedux';
import errorAlert from '../../errorAlert';
import successAlert from '../../successAlert';

interface Props {
    open: boolean
    onClose: () => void
}

export const ScheduleJobDialog = ({ open, onClose }: Props) => {
    const [state ] = useRedux()    
    const organizations = selectOrganizationList(state)
    const [organizationIds, setOrganizationIds] = React.useState<Array<number>>([-1])
    const [taskName, setTaskName] = React.useState<MaestroCronTaskType>()

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const onOrganizationIdsChange = (event: SelectChangeEvent<number[]>) => {
        const { value } = event.target
        let idArray = value as number[]
        const newValues = idArray.filter(x => !organizationIds.includes(x));
        if((newValues.includes(-1) && organizationIds.length !== 0) || idArray.length === 0 ) {
            idArray = [-1]
        } else {
            idArray = idArray.filter((id) => id !== -1)
        }
        setOrganizationIds(idArray)
    }

    const onTaskChange = (event: SelectChangeEvent<MaestroCronTaskType>) => {
        setTaskName(event.target.value as MaestroCronTaskType)
    }

    const onSchedule = async () => {
        try {
            if(!taskName || !organizationIds) {
                errorAlert({
                    error: new Error('Task or organizations have not been selected.'),
                    title: 'Options Unselected',
                    message: ''
                })
                return
            }
            if(organizationIds.includes(-1)) {
                const orgs = organizations?.data
                if(!orgs || orgs.length === 0) {
                    errorAlert({
                        error: new Error('The list of organizations is empty'),
                        title: 'No Organizations Exist',
                        message: 'The list of organizations is empty.'
                    })
                    return
                }
                scheduleReprocessingJob(orgs.map(o => Number.parseInt(o.id)), taskName)
            } else {
                await scheduleReprocessingJob(organizationIds, taskName)
            }
            successAlert({
                title: 'Success',
                message: `Reprocessing scheduled successfully. The ${taskName} task will begin tonight.`
            })
    
            onDialogClose()
        } catch (e) {
            errorAlert({
                error: e,
                title: 'Failed to Schedule',
                message: 'Failed to schedule reprocessing job.'
            })
        }
    }

    const onDialogClose = () => {
        setTaskName(undefined)
        setOrganizationIds([-1])
        onClose()
    }


    return (
        <Dialog open={open}>
            <DialogTitle>
                Schedule Reprocessing Job
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: '25px'}}>
                    Scheduled reprocessing jobs will be picked up and start running overnight. Consider the reprocessing job type and current pipeline load when selecting many organizations. Some jobs may require heavy processing.
                </DialogContentText>
                <div className="grid">
                    <FormControl className='grid-xs-12'>
                        <InputLabel>Reprocessing Task</InputLabel>
                        <Select
                            name="taskNames"
                            value={taskName}
                            required
                            onChange={onTaskChange}
                        >
                            {maestroCronTaskTypeArray.map((task) => (
                                <MenuItem
                                    key={task}
                                    value={task}
                                >
                                    {task}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{ marginLeft: 0 }}>{'The task that will do some reprocessing for the selected organizations.'}</FormHelperText>
                    </FormControl>
                    <FormControl className='grid-xs-12'>
                        <InputLabel>Reprocessing Organizations</InputLabel>
                        <Select
                            name="organizationIds"
                            multiple
                            value={organizationIds}
                            required
                            onChange={onOrganizationIdsChange}
                            renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {
                                selected.map((value) => {
                                    const organizationName = value === -1 ? 'All' : organizations?.data?.find((o) => o.id.toString() === value.toString())?.name 
                                    return(
                                    <Chip key={organizationName} label={organizationName} />
                                    )
                                })}
                            </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key="all"
                                value={-1}
                                >
                                All
                            </MenuItem>
                            {organizations?.data?.map((org) => (
                                <MenuItem
                                    key={org.name}
                                    value={org.id}
                                >
                                    {org.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{ marginLeft: 0}}>{'The organizations that require reprocessing via the selected task.'}</FormHelperText>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSchedule} disabled={!organizationIds || !taskName}>Schedule</Button>
                <Button onClick={onDialogClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}