import {
  MapColorProfileData,
  MapLayerData,
  MapLayerDefData,
} from '../data/types'
import { ProductSettings } from '../ProductSettings/store/types'
import { isolateStops } from './paint/isolateStops'

interface Props {
  product: MapLayerDefData
  settings: ProductSettings
  color?: MapColorProfileData
  layer: MapLayerData
}

export function makeFilters({ product, color, settings, layer }: Props) {
  const filters: any[] = [['all']]
  const displayProperty = product.dataProperty

  const coverageValue =
    settings.coverage?.[
      `${layer?.mapSource?.parcel?.groupId}_${layer?.mapSource?.flightDate}`
    ] ??
    layer.mapLayerDef.settings[0]?.coverage ??
    1

  if (!displayProperty) {
    return filters
  }

  const filterProperty = settings.filterProperty || displayProperty.property

  if (settings.filterEnabled && settings.filterRange && filterProperty) {
    filters.push(
      ['>=', ['get', filterProperty], settings.filterRange[0]],
      ['<=', ['get', filterProperty], settings.filterRange[1]]
    )
  }

  if (displayProperty && color && settings.isolatedStops?.length) {
    filters.push(
      isolateStops(
        {
          display: color.display,
          property: displayProperty.property,
          stops: color.dataStops,
          noData:
            color.noDataColor && product.noDataProperty
              ? {
                  property: product.noDataProperty.property,
                  value: product.noDataProperty.noDataValue,
                  color: color.noDataColor,
                }
              : undefined,
          coverage:
            color.coverageColor && product.coverageProperty
              ? {
                  property: product.coverageProperty.property,
                  min: coverageValue ?? 0,
                  max: 1,
                  color: color.coverageColor,
                }
              : undefined,
        },
        settings.isolatedStops
      )
    )
  }

  return filters
}
