import * as React from 'react'
import { useParams } from 'react-router-dom'
import useAsync from '../../../hooks/useAsync'

import { AdminPage } from '../AdminPage'
import {
  FetchedOrganization,
  fetchOrganizationById,
} from './fetchOrganizationById'
import OrganizationForm from './OrganizationForm'
import { updateOrganization } from './updateOrganization'

export function OrganizationEdit() {
  const { organizationId } = useParams<{ organizationId?: string }>()

  const [result, refresh] = useAsync(fetchOrganizationById, [organizationId])

  if (!organizationId) {
    return null
  }

  const organization = result.result?.organization

  const handleSave = async (org: FetchedOrganization) => {
    const saved = await updateOrganization(org)
    refresh()

    return saved
  }

  const subtitle = organization?.name

  return (
    <AdminPage title="Edit Organization" subtitle={subtitle}>
      <OrganizationForm
        type="edit"
        data={organization}
        saveMode="all"
        onSave={handleSave}
      />
    </AdminPage>
  )
}
