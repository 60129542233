import * as React from 'react'

type Timer = ReturnType<typeof setTimeout>

export const useDebounce = <T, U>(func: (args: U) => T, delay = 500) => {
  const timer = React.useRef<Timer>()
  React.useEffect(() => {
    return () => {
      if (!timer.current) return
      clearTimeout(timer.current)
    }
  }, [])

  const debouncedFunction = (args: U) => {
    const newTimer = setTimeout(() => {
      func(args)
    }, delay)
    clearTimeout(timer.current)
    timer.current = newTimer
  }

  return debouncedFunction
}
