import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import { Checkbox, Icon, Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import { default as TablePagination } from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import {
  getDefaultPageInfo,
  PAGE_SIZE_OPTIONS,
  selectPagingInfo,
  url,
  urls,
} from '../../appNavigation/urls'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import EnhancedTableHead from '../../UI/EnhancedTable/EnhancedTableHead'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import TooltipIconButton from '../../UI/TooltipIconButton'
import tableSort from '../../util/tableSort'
import errorAlert from '../errorAlert'
import MapDataHome from '../mapdata/MapDataHome'
import { Column } from '../mapdata/types'
import fieldToOrderValue from '../mapdata/utils/fieldToOrderValue'
import HelperText from '../mapdata/utils/HelperText'
import deletePackages from './deletePackages'
import {
  ListPackageData,
  refreshListPackages,
  selectListPackages,
} from './selectListPackages'

interface State {
  order?: 'asc' | 'desc'
  orderBy?: string
  selected: string[]
}

class MapDataPackageList extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps,
  State
> {
  state: State = {
    selected: [],
  }

  render() {
    const { selected, order, orderBy } = this.state
    const pagination = this.renderPagination()

    const {
      packages: { data: { data: listObjects = [] } = {} },
    } = this.props

    return (
      <MapDataHome>
        <div id="MapDataPackageList" className="Paper">
          <HelperText>
            These are product packages which are sold to clients. They define
            which products are included in packages such as "Pro" and "Lite".
          </HelperText>
          <EnhancedTableToolbar
            title={`Packages`}
            numSelected={selected.length}
            onClickCreate={this.handleClickCreate}
            onClickDelete={this.handleClickDelete}
            onClickRefresh={refreshListPackages}
          />
          {pagination}
          <Table>
            <EnhancedTableHead
              columns={COLUMNS}
              numSelected={selected.length}
              onRequestSort={this.handleRequestSort}
              onSelectAllClick={this.handleSelectAllClick}
              rowCount={listObjects.length}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {tableSort({
                order,
                orderBy,
                array: listObjects,
                valueMap: fieldToOrderValue,
              }).map((row) => {
                const isSelected = selected.includes(row.id)

                return (
                  <TableRow
                    key={row.id}
                    onClick={() => this.handleClickRow(row.id)}
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isSelected} />
                    </TableCell>
                    {COLUMNS.filter((column) => !!column.value).map(
                      ({ header, value, formatter }) => (
                        <TableCell key={header} padding="checkbox">
                          {formatter?.(row, value!) ?? row[value!]}
                        </TableCell>
                      )
                    )}
                    <TableCell padding="checkbox">
                      <TooltipIconButton
                        tooltipProps={{
                          placement: 'right',
                        }}
                        title={i18n.t(keys.generic.edit)}
                        onClick={(e: any) => {
                          e.stopPropagation()
                          this.handleClickEdit(row.id)
                        }}
                      >
                        <Icon>edit</Icon>
                      </TooltipIconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {pagination}
        </div>
      </MapDataHome>
    )
  }

  renderPagination() {
    const { count = 0 } = getDefaultPageInfo(this.props.packages.data)
    const {
      pagingInfo: { page, pageSize },
    } = this.props

    return (
      <TablePagination
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    )
  }

  handleChangePage = (_event: any, page: number) => {
    const {
      pagingInfo: { pageSize },
    } = this.props

    this.props.history.push(url(urls.listPackages, {}, { page, pageSize }))
  }

  handleChangeRowsPerPage = (event: any) => {
    this.props.history.replace(
      url(urls.listPackages, {}, { page: 0, pageSize: event.target.value })
    )
  }

  handleClickRow = (id: string) => {
    const selected = new Set(this.state.selected)

    if (selected.has(id)) {
      selected.delete(id)
    } else {
      selected.add(id)
    }

    this.setState({
      selected: Array.from(selected),
    })
  }

  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selected: string[] = []
    const {
      packages: { data: { data: listObjects = [] } = {} },
    } = this.props

    if (event.target.checked) {
      selected = (listObjects ?? []).map((obj) => obj.id)
    }

    this.setState({
      selected,
    })
  }

  handleClickEdit = (packageId: string) => {
    this.props.history.push(url(urls.editPackage, { packageId }))
  }

  handleRequestSort = (orderBy: string) => {
    let order = 'asc' as 'asc' | 'desc'

    if (this.state.orderBy === orderBy && this.state.order === 'asc') {
      order = 'desc'
    }

    this.setState({ order, orderBy })
  }

  handleClickCreate = () => {
    this.props.history.push(url(urls.newPackage))
  }

  handleClickDelete = async () => {
    const { selected } = this.state

    const choice = await swal(
      `You are about to delete ${selected.length} package(s)`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: 'Delete',
          },
        },
        dangerMode: true,
      }
    )

    // swal returns null for "cancel"
    if (!choice) {
      return
    }

    try {
      await deletePackages(selected)
      this.setState({
        selected: [],
      })

      refreshListPackages()
    } catch (e) {
      softError(e, 'Failed Deleting Packages', e.message)
    }
  }
}

const COLUMNS: Column<ListPackageData>[] = [
  {
    header: 'Name',
    value: 'name',
  },
  {
    header: 'Code',
    value: 'code',
  },
  {
    header: 'Created By',
    value: 'CreatedBy',
    formatter: ({ CreatedBy }) => CreatedBy?.firstName ?? '?',
  },
  {
    header: 'Date Created',
    value: 'createdAt',
    formatter: ({ createdAt }) => i18n.toDateShort(createdAt),
  },
  {
    header: 'Last Modified',
    value: 'updatedAt',
    formatter: ({ updatedAt }) => i18n.toDateTimeShort(updatedAt),
  },
  { header: 'Actions' },
]

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
      file: 'MapDataPackageList.tsx',
    },
  })

const mapState = (state: RootStore) => ({
  pagingInfo: selectPagingInfo(state),
  preferredLanguage: selectPreferredLanguage(state),
  packages: selectListPackages(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(MapDataPackageList)
)
