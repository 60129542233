import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class GlDrawActionGenerator implements IMapActionGenerator {
  key = 'draw-point'
  priority = Priority.DrawPoint

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
  }: IMapActionGeneratorParams): IMapAction[] {
    if (
      features.some(
        (feature) =>
          String(feature.layer.id).startsWith('gl-draw') &&
          feature.geometry.type === 'Point'
      )
    ) {
      return [
        {
          key: this.key,
          priority: this.priority,
          execute: () => {
            dispatch(setFocusedPoint(null))
            dispatch(setFocusedPolygon(null))
            dispatch(setFocusedBlockId(undefined))
          },
        },
      ]
    }

    return []
  }
}
