import * as React from 'react'

import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'

import { selectPreferredLanguage } from '../../../../data/selectPreferredLanguage'
import { AsyncResult } from '../../../../hooks/useAsync'
import { useRedux } from '../../../../hooks/useRedux'
import { setDifference } from '../../../../util/setDifference'
import { setToggle } from '../../../../util/setToggle'
import { ParcelsAndBounds } from '../fetchParcelsAndBounds'

interface Props {
  parcelsAndBoundsFetcher: AsyncResult<ParcelsAndBounds>[0]
  setLayersEnabled: (
    disabledLayers: string[],
    enabledLayers: string[]
  ) => Promise<void>
}

export const DeliveryProcGroupLayers = ({
  parcelsAndBoundsFetcher,
  setLayersEnabled,
}: Props) => {
  const [state] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)

  const [selectedLayers, setSelectedLayers] = React.useState<Set<string>>(
    new Set()
  )
  const [allLayers, setAllLayers] = React.useState<Set<string>>(new Set())
  const [currentLayers, setCurrentLayers] = React.useState<Set<string>>(
    new Set()
  )
  const [changesPending, setChangesPending] = React.useState(false)

  React.useEffect(() => {
    const newCurrentLayers = new Set<string>()
    const newAllLayers = new Set<string>()
    Object.keys({
      ...(parcelsAndBoundsFetcher.result?.backgroundMapLayerDefsById ?? {}),
      ...parcelsAndBoundsFetcher.result?.productMapLayerDefsById,
    }).forEach((id) => {
      newAllLayers.add(id)
      if (
        parcelsAndBoundsFetcher.result?.parcels?.every((p) => {
          return p.mapLayers
            ?.filter((ml) => ml.mapLayerDef.id === id)
            ?.every((ml) => ml.enabled)
        })
      ) {
        newCurrentLayers.add(id)
      }
    })

    setAllLayers(newAllLayers)
    setCurrentLayers(newCurrentLayers)
    setSelectedLayers(newCurrentLayers)
  }, [parcelsAndBoundsFetcher.result])

  React.useEffect(() => {
    setChangesPending(
      !!setDifference(currentLayers, selectedLayers).size ||
        currentLayers.size !== selectedLayers.size
    )
  }, [currentLayers, selectedLayers])

  const revert = () => {
    setSelectedLayers(currentLayers)
  }

  const handleSave = async () => {
    const disabledLayers = setDifference(allLayers, selectedLayers)
    await setLayersEnabled(
      Array.from(disabledLayers),
      Array.from(selectedLayers)
    )
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <Stack>
          <List sx={{ width: '100%' }}>
            <ListItem
              onClick={() =>
                setSelectedLayers(
                  selectedLayers.size ? new Set() : new Set(allLayers)
                )
              }
              secondaryAction={
                <Checkbox
                  checked={selectedLayers.size === allLayers.size}
                  indeterminate={
                    selectedLayers.size > 0 &&
                    selectedLayers.size < allLayers.size
                  }
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <Icon>layers</Icon>
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Product Layers" />
            </ListItem>
            {Object.values(
              parcelsAndBoundsFetcher?.result?.productMapLayerDefsById ?? {}
            )?.map((mapLayerDef) => (
              <ListItem
                key={mapLayerDef.id}
                onClick={() =>
                  setSelectedLayers(setToggle(selectedLayers, mapLayerDef.id))
                }
                secondaryAction={
                  <Checkbox checked={selectedLayers.has(mapLayerDef.id)} />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon>layers</Icon>
                  </ListItemIcon>
                  <ListItemText primary={mapLayerDef.name[preferredLanguage]} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider />
            <ListItem>
              <ListItemText primary="Background Layers" />
            </ListItem>
            {Object.values(
              parcelsAndBoundsFetcher?.result?.backgroundMapLayerDefsById ?? {}
            )?.map((mapLayerDef) => (
              <ListItem
                key={mapLayerDef.id}
                onClick={() =>
                  setSelectedLayers(setToggle(selectedLayers, mapLayerDef.id))
                }
                secondaryAction={
                  <Checkbox checked={selectedLayers.has(mapLayerDef.id)} />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon>layers</Icon>
                  </ListItemIcon>
                  <ListItemText primary={mapLayerDef.name[preferredLanguage]} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              disabled={!changesPending}
              variant="contained"
              color="secondary"
              onClick={() => revert()}
            >
              Revert
            </Button>
            <Button
              disabled={!changesPending}
              variant="contained"
              color="primary"
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}
