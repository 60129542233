import { client, gql } from '../../../graphql/client'

export interface Comment {
  id: string
  createdAt: string
  content: string
  author: {
    id: string
    firstName: string
    lastName: string
    email: string
  }
  replies?: Comment[]
}
interface WrappedComment {
  comment: Comment
}

export const fetchDeliveryProcGroupComments = async (
  deliveryId: string,
  procGroup: string
) => {
  const { procGroupWithComments } = await client.request<{
    procGroupWithComments: { wrappedComments: WrappedComment[] }
  }>({
    query: gql`
      query ProcGroupComments($deliveryId: uuid!, $procGroup: String!) {
        procGroupWithComments: Delivery_ProcGroup_by_pk(
          procGroup: $procGroup
          deliveryId: $deliveryId
        ) {
          wrappedComments: Delivery_ProcGroup_Comments(
            order_by: { Comment: { createdAt: asc } }
            where: { Comment: { replyToCommentId: { _is_null: true } } }
          ) {
            comment: Comment {
              id
              createdAt
              content
              author: Author {
                id
                firstName
                lastName
                email
              }
              replies: Replies {
                id
                createdAt
                content
                author: Author {
                  id
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        }
      }
    `,
    variables: { deliveryId, procGroup },
  })

  return procGroupWithComments.wrappedComments.map(({ comment }) => comment)
}

export type DeliveryProcGroupComments = Awaited<
  ReturnType<typeof fetchDeliveryProcGroupComments>
>
