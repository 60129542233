import * as React from 'react'
import { PureComponent } from 'react'
import { ListSubheader, ListSubheaderProps } from '@mui/material'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { classnames } from '../../util/classnames'

class LayerDrawerSubheader extends PureComponent<
  ListSubheaderProps & WithStyles<typeof styles>
> {
  render() {
    const { classes, className, ...rest } = this.props

    return (
      <ListSubheader
        {...rest}
        className={classnames(classes.listSubHeader, className)}
      />
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listSubHeader: {
      backgroundColor: theme.palette.background.default,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  })

export default withStyles(styles)(LayerDrawerSubheader)
