import applyNamespace from 'redux-ts-helpers/lib/applyNamespace'
import createAction from 'redux-ts-helpers/lib/createAction'
import { ReduxAsyncAction } from '../redux/types'

export const constants = applyNamespace('colorScheme', {
  showDrawerForProduct: 0,
  toggleStats: 0,
  toggleDrawerForProduct: 0,
})

export const showDrawerForProduct = createAction<'all' | 'active' | null>(
  constants.showDrawerForProduct
)

export const toggleStats = createAction(constants.toggleStats)

export const toggleDrawerForProduct = (): ReduxAsyncAction => ({
  type: constants.toggleDrawerForProduct,
  async payload(dispatch, getState) {
    const currentValue = getState().colorScheme.drawerProductType
    const nextValue = currentValue === 'active' ? null : 'active'

    dispatch(showDrawerForProduct(nextValue))
  },
})
