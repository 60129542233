import * as api from '../../../graphql/api'

export const deleteVariableByKey = async (key: string) => {
  const choice = await sweetAlert(
    `Are you sure you want to delete this Variable?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Delete',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (!choice) {
    return
  }

  return await api.variables.delete<{ key: string }>({
    pk: { key },
  })
}
