import {
  downloadApiUrl,
  fetchWithAuth,
  getDownloadUrl,
} from '../vvapi/apiResource/createApiResource'
import { gql, query } from './client'
import { createGQLResource } from './createGQLResource'
import {
  DeliveryGroupFile,
  DeliveryGroupFile_create_input,
  DeliveryGroupFile_update_input,
} from './types'

interface DownloadOptions {
  deliveryId: string
  groupId: string
  filename: string
  language?: 'en' | 'fr'
}

interface RequestDownloadOptions {
  deliveryId: string
  groupId: string
  filename: string
  organizationId: string
  language?: 'en' | 'fr'
}

interface UploadOptions extends DownloadOptions {
  file: File
  organizationId: string
  deliveryId: string
}

export const upload = async ({
  organizationId,
  deliveryId,
  groupId,
  filename,
  file,
}: UploadOptions) => {
  const uploadUrlRes = await fetchWithAuth(
    `/api/v3/delivery-group-file/${deliveryId}/${groupId}/${filename}/upload-url?contentType=${file.type}`
  )

  if (!uploadUrlRes.ok) {
    throw new Error(`${uploadUrlRes.status} - ${uploadUrlRes.statusText}`)
  }

  const { uploadUrl } = (await uploadUrlRes.json()) as { uploadUrl: string }

  const uploadResponse = await fetch(uploadUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'x-amz-storage-class': 'STANDARD_IA',
    },
  })

  if (!uploadResponse.ok) {
    throw new Error(`${uploadResponse.status} - ${uploadResponse.statusText}`)
  }

  const createRecordRes = await fetchWithAuth('/api/v3/delivery-group-file/', {
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organizationId,
        deliveryId,
        groupId,
        filename,
      }),
    },
  })

  if (!createRecordRes.ok) {
    throw new Error(`${createRecordRes.status} - ${createRecordRes.statusText}`)
  }
}

export const getUrl = ({
  deliveryId,
  groupId,
  filename,
  file: { downloadToken },
}: Pick<DeliveryGroupFile, 'deliveryId' | 'groupId' | 'filename' | 'file'>) =>
  getDownloadUrl(
    `/api/v3/delivery-group-file/${deliveryId}/${groupId}/${filename}?downloadToken=${downloadToken}`
  )

export const download = (
  {
    deliveryId,
    groupId,
    filename,
    file: { downloadToken },
  }: Pick<DeliveryGroupFile, 'deliveryId' | 'groupId' | 'filename' | 'file'>,
  name?: string
) =>
  downloadApiUrl(
    `/api/v3/delivery-group-file/${deliveryId}/${groupId}/${filename}?downloadToken=${downloadToken}`,
    name
  )

export const requestDownload = async (options: RequestDownloadOptions) => {
  await fetchWithAuth(`/api/v3/delivery-group-file/request`, {
    init: {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
      method: 'POST',
    },
  })
}

export const reprocess = ({
  deliveryId,
  groupId,
  filename,
  language,
}: DownloadOptions) =>
  query<{ deliveryGroupFile: { status: string } }>({
    variables: {
      deliveryId,
      groupId,
      filename,
      language,
    },
    query: gql`
      mutation reprocess_DeliveryGroupFile(
        $deliveryId: uuid!
        $groupId: Int!
        $filename: citext!
        $language: bpchar
      ) {
        deliveryGroupFile: update_DeliveryGroupFile_by_pk(
          pk_columns: {
            deliveryId: $deliveryId
            groupId: $groupId
            filename: $filename
          }
          _set: { status: "pending", language: $language }
        ) {
          status
        }
      }
    `,
  })

const { list, get } = createGQLResource<
  DeliveryGroupFile,
  DeliveryGroupFile_create_input,
  DeliveryGroupFile_update_input,
  'deliveryId' | 'groupId' | 'filename'
>('DeliveryGroupFile', {
  deliveryId: 'uuid',
  groupId: 'Int',
  filename: 'citext',
})

export const deliveryGroupFile = {
  list,
  get,
  reprocess,
  upload,
  download,
  getUrl,
  requestDownload,
}
