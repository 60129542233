import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
  setFocusedSoilLayer,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { FocusedPoint } from '../../../../postgis/types'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class PointActionGenerator implements IMapActionGenerator {
  key = 'point'
  priority = Priority.Point

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
    state,
  }: IMapActionGeneratorParams): IMapAction[] {
    if (state.notes.editingNoteId !== undefined) {
      return []
    }

    const products = features.filter((f) => f.layer.id.endsWith('-layer'))
    // Find the qualifying features.
    const qualifiers = features.filter((f: MapboxGL.MapboxGeoJSONFeature) =>
      isPointLayer(f)
    )

    // Return functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        let properties: FocusedPoint['properties'] = {
          mapSourceId: q.sourceLayer,
        }

        // get properties from other products
        products.forEach((product) => {
          if (!product) {
            return
          }

          properties = {
            ...properties,
            ...product.properties,
          }
        })

        const focusedPoint: Writable<FocusedPoint> = {
          properties,
          type: 'Feature',
          geometry: q.geometry as GeoJSON.Point,
        }

        dispatch(setFocusedPoint(focusedPoint))
        dispatch(setFocusedPolygon(null))
        dispatch(setFocusedBlockId(undefined))
        dispatch(setFocusedSoilLayer(null))
      },
    }))
  }
}

const isPointLayer = (
  product: GeoJSON.Feature<GeoJSON.Geometry>
): product is GeoJSON.Feature<GeoJSON.Point> =>
  product.geometry.type === 'Point'
