// conversations from: https://www.unitjuggler.com
import UnitConverter from './UnitConverter'
import { UnitSystem } from './unitSystem'

const lengthSource = {
  // metric units
  [UnitSystem.Metric]: {
    millimeter: {
      value: 1e3,
      symbol: 'mm',
    },
    centimeter: {
      value: 1e2,
      symbol: 'cm',
    },
    decimeter: {
      value: 1e1,
      symbol: 'dm',
    },
    meter: {
      value: 1,
      symbol: 'm',
      display: true,
    },
    decameter: {
      value: 1e-1,
      symbol: 'dam',
    },
    hectometer: {
      value: 1e-2,
      symbol: 'hm',
    },
    kilometer: {
      value: 1e-3,
      symbol: 'km',
      display: true,
    },
  },
  [UnitSystem.Imperial]: {
    // imperial units
    mile: {
      value: 0.00062137119223733,
      symbol: 'mi',
      display: true,
    },
    yard: {
      value: 1.0936132983377,
      symbol: 'yd',
    },
    foot: {
      value: 3.2808398950131,
      symbol: 'ft',
      display: true,
    },
    inch: {
      value: 39.370078740157,
      symbol: 'in',
    },
  },
}

const length = UnitConverter(lengthSource)

const exportableLength = {
  ...length,
  elevationUnit: {
    [UnitSystem.Imperial]: 'foot',
    [UnitSystem.Metric]: 'meter',
  },
}

export default exportableLength

export const convertLength = (
  value: number,
  inputUnit: LengthUnit,
  prefferedUnitSystem: UnitSystem,
  significate = 1
) =>
  length.bestUnit(
    value,
    length.units[inputUnit],
    inputUnit,
    length.unitsBySystem[prefferedUnitSystem]
  )

export type LengthUnit =
  | keyof typeof lengthSource.imperial
  | keyof typeof lengthSource.metric
