import './RequeueJobDialog.scss'

import * as React from 'react'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { startJob } from '../../vvapi/maestro'
import { Row } from '../UI/Row'

interface Props {
  jobId: string
  priority: number
  input: string
  open: boolean
  onCloseButtonClicked: () => void
  onJobRequeued: () => void
}
interface State {
  inputError?: string
  input?: string
  priority?: number
}
export class RequeueJobDialog extends React.Component<Props, State> {
  state: State = {}
  componentDidUpdate(prevProps: Props) {
    if (prevProps.input !== this.props.input) {
      this.setState({ input: this.props.input })
    }
    if (prevProps.priority !== this.props.priority) {
      this.setState({
        priority: this.props.priority ? Number(this.props.priority) : undefined,
      })
    }
  }

  componentDidMount() {
    this.setState({
      input: this.props.input,
      priority: this.props.priority ? Number(this.props.priority) : undefined,
    })
  }

  render() {
    const { open, onCloseButtonClicked } = this.props
    const { input, inputError, priority } = this.state

    return (
      <Dialog
        maxWidth="lg"
        open={open}
        aria-labelledby="requeue-job-dialog-title"
      >
        <DialogTitle id="requeue-job-dialog-title">Requeue Job</DialogTitle>
        <DialogContent className="requeue-job-dialog">
          <InputLabel htmlFor="job-input">Input</InputLabel>
          <TextField
            multiline
            id="job-input"
            className="job-input"
            value={input}
            error={!!inputError}
            onChange={this.handlInputChange}
            onBlur={this.handleInputBlur}
          />
          <Typography className="error" variant="caption">
            {inputError}
          </Typography>

          <InputLabel htmlFor="priority">Priority</InputLabel>
          <Input
            value={priority}
            id="priority"
            type="number"
            onChange={this.handlePriorityChange}
          />
          <Row style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button
              style={{ marginRight: 10 }}
              disabled={!!inputError}
              variant="contained"
              color="primary"
              onClick={() => this.requeueJob()}
            >
              Requeue Job
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onCloseButtonClicked()}
            >
              Cancel
            </Button>
          </Row>
        </DialogContent>
      </Dialog>
    )
  }

  requeueJob = async () => {
    const { jobId, onJobRequeued } = this.props
    const { input, inputError, priority } = this.state
    if (!!inputError) {
      return
    }

    await startJob(jobId, priority, input && JSON.parse(input))

    onJobRequeued()
  }

  handleInputBlur = () => {
    try {
      const { input } = this.state
      if (!input) {
        this.setState({ input: undefined, inputError: undefined })
      } else {
        const obj = JSON.parse(input)
        this.setState({
          input: JSON.stringify(obj, null, 2),
          inputError: undefined,
        })
      }
    } catch (err) {
      this.setState({ inputError: err.message })
    }
  }

  handlInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ inputError: undefined, input: event.target.value })
  }
  handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      priority: event.target.value ? Number(event.target.value) : undefined,
    })
  }
}
