interface ThrottleOptions {
  delay: number
  tail?: boolean
}

/**
 * Throttle -- only allow one call through for every `delay`ms.
 */
export function throttle<T extends (...args: any[]) => any>(
  { delay, tail = true }: ThrottleOptions,
  func: T
): T {
  let lastArgs: any[] | undefined
  let lastCalledArgs: any[] | undefined

  let waiting = false

  function start() {
    if (lastArgs === lastCalledArgs) {
      lastArgs = undefined
      lastCalledArgs = undefined

      waiting = false

      return
    }

    waiting = true

    if (!tail) {
      lastCalledArgs = lastArgs
      func(...lastArgs!)
    }

    setTimeout(finish, delay)
  }

  function finish() {
    if (tail) {
      lastCalledArgs = lastArgs
      func(...lastArgs!)
    }

    start()
  }

  return function call(...args: any[]) {
    lastArgs = args

    if (waiting) {
      return
    }

    start()
  } as T
}

interface DebounceOptions {
  delay: number
}

/**
 * Debounce -- only allow the last call through after `delay`ms of not being called.
 */
export function debounce<T extends (...args: any[]) => any>(
  { delay }: DebounceOptions,
  func: T
): T {
  let timeoutId: any

  return function call(...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(func, delay, ...args)
  } as T
}

/**
 * promiseDebounce -- only allow the last call through `delay`ms after
 * previous call resolves/rejects.
 */
export function promiseDebounce<T extends (...args: any[]) => Promise<void>>(
  { delay }: DebounceOptions,
  func: T
): T & { done: () => boolean } {
  let timeoutId: any
  let lastArgs: any[] | null = null
  let draining: any[] | null = null

  const drain = async () => {
    if (!lastArgs || draining) {
      return
    }

    draining = lastArgs
    lastArgs = null

    try {
      await func(...draining)
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn('promiseDebounce rejected:', e)
    }

    draining = null

    queueDrain()
  }

  const queueDrain = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(drain, delay)
  }

  const call: any = (...args: any[]) => {
    lastArgs = args

    queueDrain()
  }

  call.done = () => !draining && !lastArgs

  return call as any
}

export function createDedupQueue<T>(func: (data: T, next: () => void) => void) {
  const q: T[] = []
  let draining = false

  let last: T | undefined

  function drain() {
    if (draining) {
      return
    }
    draining = true

    let called = false
    function next() {
      if (called) {
        return
      }
      called = true

      draining = false

      if (q.length > 0) {
        drain()
      }
    }

    func(q.shift()!, next)
  }

  return {
    queue(data: T) {
      if (!q.includes(data) && data !== last) {
        last = data
        q.push(data)
        drain()
      }
    },
  }
}
