import { DEFAULT_PAGE_SIZE } from '../../appNavigation/urls'
import { FILTER, filterBy } from './filterRows'
import { ORDER, orderBy } from './orderRows'
import { TableFormatter } from './types'

export type Fetcher<T> = (options: {
  offset?: number
  limit?: number
  order_by?: string
  where?: any
  returning?: string
}) => Promise<{ data?: T[] | null; info?: { count: number } }>

export const createTableFetcher =
  <T>(
    tableFormatter: TableFormatter<T>[],
    returning: string,
    func: Fetcher<T>,
    multiFilterCondition?: '_and' | '_or',
    tableLevelFilter?: any,
  ) =>
  async ([
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    _order = '',
    _filter = '',
  ]) => {
    const order = ORDER.deserialize(_order)
    const filter = FILTER.deserialize(_filter)

    const offset = page * pageSize
    const limit = pageSize

    const { data, info } = await func({
      offset,
      limit,
      order_by: orderBy(tableFormatter, order),
      where: !!tableLevelFilter // if a table level filter exists, ensure condition is applied
        ? {
            _and: {
              ...tableLevelFilter,
              ...filterBy(  
                tableFormatter,
                filter,
                multiFilterCondition ?? '_and'
              ),
            },
          }
        : filterBy(tableFormatter, filter, multiFilterCondition ?? '_and'),
      returning,
    })

    if (!data) {
      throw new Error('No Data')
    }

    return {
      data,
      info: {
        order,
        filter,
        page,
        pageSize,
        count: info?.count ?? 0,
      },
    }
  }
