import * as React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  GeoPDFDownloadSettings,
  GeoPDFDownloadSettingsForm,
} from './GeoPDFDownloadSettingsForm'
import { ReviewDownload } from './ReviewDownload'
import {
  DataDownloadSettings,
  DataDownloadSettingsForm,
} from './DataDownloadSettingsForm'
import { Download, DownloadRequest, DownloadType, SettingsBase } from './types'
import { DownloadGroupsAndDates } from './DownloadGroupsAndDates'
import { client, gql } from '../graphql/client'
import {
  ManageDuplicateDownloadsDialog,
  SkipOrReplace,
} from './ManageDuplicateDownloadsDialog'
import { useRedux } from '../hooks/useRedux'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import AsyncSelectorStatusOverlay from '../AsyncSelector/AsyncSelectorStatusOverlay'
import i18n, { keys } from '../i18n'

interface Props {
  open: boolean

  onCancelled: () => void
  onSubmit: (
    name: string,
    selectedGroups: Set<string>,
    selectedDeliveries: Set<string>,
    settings: SettingsBase
  ) => void
}

type DownloadStep =
  | 'selectType'
  | 'groupsAndDates'
  | 'settings'
  | 'reviewAndRequest'

const steps: DownloadStep[] = [
  'selectType',
  'groupsAndDates',
  'settings',
  'reviewAndRequest',
]

export const RequestDownloadsDialog = ({
  open,
  onCancelled,
  onSubmit,
}: Props) => {
  const [state] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)

  const [activeStep, setActiveStep] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedGroups, setSelectedGroups] = React.useState<Set<string>>(
    new Set<string>()
  )
  const [selectedDeliveries, setSelectedDeliveries] = React.useState<
    Set<string>
  >(new Set<string>())
  const [settings, updateSettings] = React.useState<SettingsBase | undefined>(
    undefined
  )
  const [duplicateDownloads, updateDuplicateDownloads] = React.useState<
    Pick<Download, 'id' | 'groupId' | 'filename' | 'deliveryId'>[]
  >([])
  const [manageDuplicatesDialogOpen, setManageDuplicatesDialogOpen] =
    React.useState<boolean>(false)
  const [name, updateName] = React.useState<string>(
    `${i18n.t(keys.download)}-${i18n.toDateShort(new Date())}`
  )

  const theme = useTheme()

  const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    if (open === false) {
      setTimeout(handleClose, theme.transitions.duration.leavingScreen)
    }
  }, [open, theme.transitions.duration.leavingScreen])

  const close = () => {
    onCancelled()
  }

  const submit = () => {
    onSubmit(name, selectedGroups, selectedDeliveries, settings!)
  }

  const handleClose = () => {
    setActiveStep(0)
    updateSettings(undefined)
    setSelectedGroups(new Set())
    setSelectedDeliveries(new Set())
    updateName(`${i18n.t(keys.download)}-${i18n.toDateShort(new Date())}`)
  }

  const chooseType = (type: DownloadType) => {
    if (type === 'geoPdf') {
      updateSettings({
        type,
        selectedLanguage: preferredLanguage,
      } as GeoPDFDownloadSettings)
    } else {
      updateSettings({ type })
    }
  }

  const renderStep = (step: DownloadStep) => {
    switch (step) {
      case 'selectType':
        return (
          <Grid
            container
            sx={{ height: '100%', width: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl>
              <FormLabel id="type-label">{i18n.t(keys.downloadType)}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="type-label"
                name="download-type-group"
                value={settings?.type || ''}
                onChange={(ev) => chooseType(ev.target.value as DownloadType)}
              >
                <FormControlLabel
                  value="geoPdf"
                  control={<Radio />}
                  label={i18n.t(keys.geoPdf)}
                />
                <FormControlLabel
                  value="rawData"
                  control={<Radio />}
                  label={i18n.t(keys.rawData)}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )
      case 'groupsAndDates':
        return (
          <DownloadGroupsAndDates
            selectedDeliveries={selectedDeliveries}
            selectedGroups={selectedGroups}
            selectedDownloadType={settings?.type}
            setSelectedDeliveries={setSelectedDeliveries}
            setSelectedGroups={setSelectedGroups}
          />
        )
      case 'settings':
        switch (settings?.type) {
          case 'geoPdf':
            return (
              <GeoPDFDownloadSettingsForm
                updateSettings={updateSettings}
                settings={settings as GeoPDFDownloadSettings}
                selectedDeliveries={selectedDeliveries}
                selectedGroups={selectedGroups}
                updateName={updateName}
                name={name}
              />
            )
          case 'rawData':
            return (
              <DataDownloadSettingsForm
                updateSettings={updateSettings}
                settings={settings as DataDownloadSettings}
                selectedDeliveries={selectedDeliveries}
                selectedGroups={selectedGroups}
                updateName={updateName}
                name={name}
                setIsLoading={setIsLoading}
              />
            )
          default:
            return null
        }
      case 'reviewAndRequest':
        return (
          <ReviewDownload
            name={name}
            settings={settings!}
            selectedDeliveries={selectedDeliveries}
            selectedGroups={selectedGroups}
          />
        )
      default:
        return null
    }
  }

  const canProceed = () => {
    switch (steps[activeStep]) {
      case 'selectType':
        return !!settings?.type
      case 'groupsAndDates':
        return selectedDeliveries.size > 0 && selectedGroups.size > 0
      case 'settings':
        switch (settings?.type) {
          case 'geoPdf':
            const geoPdfSettings = settings as GeoPDFDownloadSettings
            return (
              geoPdfSettings?.selectedProduct &&
              geoPdfSettings?.selectedColorProfile &&
              geoPdfSettings?.selectedVisualizationMode &&
              geoPdfSettings?.selectedLanguage &&
              name
            )
          case 'rawData':
            const dataDownloadSettings = settings as DataDownloadSettings
            return dataDownloadSettings?.selectedFilenames?.size && name
        }
    }

    return true
  }

  const checkForDuplicateName = async () => {
    const { duplicateDownloadRequests } = await client.request<{
      duplicateDownloadRequests: Pick<DownloadRequest, 'name'>[]
    }>({
      query: gql`
        query FIND_DUPLICATE_DOWNLOAD_REQUEST_NAMES($name: String!) {
          duplicateDownloadRequests: DownloadRequest(
            where: { name: { _like: $name } }
          ) {
            name
          }
        }
      `,
      variables: {
        name: `${name}%`,
      },
    })

    if (duplicateDownloadRequests.length === 0) {
      return
    }

    const numberRegex = /\([^\d]*(\d+)[^\d]*\)$/
    let highest = 0
    for (const duplicateName of duplicateDownloadRequests) {
      const suffix = duplicateName.name.replace(name, '')
      const match = suffix.match(numberRegex)
      if (match) {
        highest = Math.max(parseInt(match[1]), highest)
      }
    }

    if (highest > 0) {
      updateName(`${name}(${highest + 1})`)
      return
    }

    if (
      !name.match(numberRegex) &&
      duplicateDownloadRequests.some(
        ({ name: downloadName }) => downloadName === name
      )
    ) {
      updateName(`${name}(1)`)
    }
  }

  const checkForDuplicates = async () => {
    const { duplicateDownloads } = await client.request<{
      duplicateDownloads: Pick<
        Download,
        'id' | 'groupId' | 'filename' | 'deliveryId'
      >[]
    }>({
      query: gql`
        query FIND_DUPLICATE_DOWNLOADS(
          $deliveryIds: [uuid!]
          $groupIds: [Int!]!
          $filenames: [String!]!
          $currentVersion: Int!
        ) {
          duplicateDownloads: Download(
            where: {
              deliveryId: { _in: $deliveryIds }
              groupId: { _in: $groupIds }
              filename: { _in: $filenames }
              settings: { _contains: { version: $currentVersion } }
            }
          ) {
            groupId
            filename
            deliveryId
            id
          }
        }
      `,
      variables: {
        deliveryIds: Array.from(selectedDeliveries),
        groupIds: Array.from(selectedGroups),
        filenames: Array.from(
          (settings as DataDownloadSettings)?.selectedFilenames ?? []
        ),
        currentVersion: 1,
      },
    })

    if (duplicateDownloads.length > 0) {
      updateDuplicateDownloads(duplicateDownloads)
      setManageDuplicatesDialogOpen(true)
    } else {
      setActiveStep(Math.min(activeStep + 1, steps.length))
      setIsLoading(false)
    }
  }

  const advanceStep = async () => {
    // if we're on Raw Data Settings
    if (activeStep === 1) {
      setIsLoading(true)
      await checkForDuplicateName()
      setIsLoading(false)
      setActiveStep(Math.min(activeStep + 1, steps.length))
    } else if (settings?.type === 'rawData' && activeStep === 2) {
      setIsLoading(true)
      await checkForDuplicates()
    } else {
      setIsLoading(true)
      setActiveStep(Math.min(activeStep + 1, steps.length))
      setIsLoading(false)
    }
  }

  const handleDuplicateDownloadsSubmit = (skipOrReplace: SkipOrReplace) => {
    updateSettings({
      ...(settings as DataDownloadSettings),
      skipOrReplace,
    } as DataDownloadSettings)
    setManageDuplicatesDialogOpen(false)
    setActiveStep(Math.min(activeStep + 1, steps.length))
    setIsLoading(false)
  }

  const handleCancelDuplicatesDialog = () => {
    setManageDuplicatesDialogOpen(false)
    setIsLoading(false)
  }

  return (
    <>
      <ManageDuplicateDownloadsDialog
        open={manageDuplicatesDialogOpen}
        duplicateDownloads={duplicateDownloads}
        onCancelled={handleCancelDuplicatesDialog}
        onSubmit={handleDuplicateDownloadsSubmit}
      />
      <Dialog open={open} maxWidth="md" fullWidth>
        <AsyncSelectorStatusOverlay requests={[]} isLoading={isLoading}>
          <DialogTitle>{i18n.t(keys.requestDownload)}</DialogTitle>
          <DialogContent>
            <Stepper
              activeStep={activeStep}
              orientation={matchesSmall ? 'vertical' : 'horizontal'}
            >
              {steps.map((step) => (
                <Step key={step}>
                  <StepLabel>{i18n.t(step)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid
              container
              sx={{ py: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              alignItems="flex-start"
              justifyContent="center"
            >
              {renderStep(steps[activeStep])}
            </Grid>
          </DialogContent>
        </AsyncSelectorStatusOverlay>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => close()}>
            {i18n.t(keys.generic.close)}
          </Button>
          <Button onClick={() => setActiveStep(Math.max(activeStep - 1, 0))}>
            {i18n.t(keys.generic.back)}
          </Button>
          <Button
            disabled={!canProceed() || isLoading}
            variant={activeStep < steps.length - 1 ? undefined : 'contained'}
            onClick={
              activeStep === steps.length - 1
                ? () => submit()
                : () => advanceStep()
            }
          >
            {activeStep < steps.length - 1
              ? i18n.t(keys.generic.next)
              : i18n.t(keys.request)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
