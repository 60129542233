import { createSelector } from 'reselect'
import { selectMapLayerDefsById } from '../../../data/selectListMapSourceDefs'
import { RootStore } from '../../../redux/types'
import coalesce from '../../../util/coalesce'
import { ProductSettings } from '../types'
import { getVisualization } from './helpers'
import { selectProductVisualizations } from './selectProductVisualizations'

const getUserProductSettings = (state: RootStore) =>
  state.ProductSettings.userProductSettings

export const DEFAULT_SETTINGS: ProductSettings = {
  opacity: 1,
  groupBy: 'value-ranges',
  threshold: 0.5,
  coverage: {},
  coverageMin: 0,
  coverageMax: 1,
  dotSize: 'medium',
  blockByBlockAverage: false,
}

export const selectProductSettings = createSelector(
  [selectMapLayerDefsById, getUserProductSettings, selectProductVisualizations],
  (productsById, userProductSettings, productVisualizations) => {
    const result: Record<string, ProductSettings | undefined> = {}

    for (const productId of Object.keys(productsById)) {
      const product = productsById[productId]
      const userSettings = userProductSettings[productId] ?? {}
      const defaultSettings = product.settings[0] ?? {}
      const visualizations = productVisualizations[productId] ?? []

      result[productId] = {
        opacity: coalesce(
          userSettings.opacity,
          defaultSettings.opacity,
          DEFAULT_SETTINGS.opacity
        ),
        groupBy: coalesce(
          userSettings.groupBy,
          defaultSettings.groupBy,
          DEFAULT_SETTINGS.groupBy
        ),
        numberOfGroups: coalesce(
          userSettings.numberOfGroups,
          defaultSettings.numberOfGroups,
          DEFAULT_SETTINGS.numberOfGroups
        ),
        isolatedStops: coalesce(
          userSettings.isolatedStops,
          DEFAULT_SETTINGS.isolatedStops
        ),
        filterProperty: coalesce(
          userSettings.filterProperty,
          DEFAULT_SETTINGS.filterProperty
        ),
        filterRange: coalesce(
          userSettings.filterRange,
          DEFAULT_SETTINGS.filterRange
        ),
        filterEnabled: coalesce(
          userSettings.filterEnabled,
          DEFAULT_SETTINGS.filterEnabled
        ),
        threshold: coalesce(
          userSettings.threshold,
          defaultSettings.threshold,
          DEFAULT_SETTINGS.threshold
        ),
        blockByBlockAverage: userSettings.blockByBlockAverage,
        coverage: coalesce(userSettings.coverage, DEFAULT_SETTINGS.coverage),
        coverageMin: coalesce(
          defaultSettings.coverageMin,
          DEFAULT_SETTINGS.coverageMin
        ),
        coverageMax: coalesce(
          defaultSettings.coverageMax,
          DEFAULT_SETTINGS.coverageMax
        ),
        dotSize: coalesce(
          userSettings.dotSize,
          defaultSettings.dotSize,
          DEFAULT_SETTINGS.dotSize
        ),
        visualization: getVisualization(
          coalesce(
            userSettings.visualization,
            defaultSettings.visualization,
            DEFAULT_SETTINGS.visualization
          ),
          visualizations
        ),

        colorIdByVisualization: coalesce(
          userSettings.colorIdByVisualization,
          DEFAULT_SETTINGS.colorIdByVisualization
        ),
      }
    }

    return result
  }
)
