import * as React from 'react'
import { connect } from 'react-redux'
import { GeoPdfDownloadsDialog } from '../geoPdfDownloads/GeoPdfDownloadsDialog'
import GroupDownloadsDialog from '../groupDownloads/GroupDownloadsDialog'
import { HelpDialog } from '../help/HelpDialog'
import PreferencesDialog from '../Preferences/PreferencesDialog'
import { RootStore } from '../redux/types'
import ReportsDialog from '../reports/builder/ReportsDialog'
import OrgSwitchDialog from '../users/OrgSwitchDialog'
import Notifications from './Notifications/Notifications'
import Feedback from './Notifications/Feedback'
import { MappedNoteTemplates } from '../noteForms/NoteTemplates'

class AppDialogs extends React.Component<ReduxProps> {
  render() {
    const {
      isPreferencesDialogOpen,
      isOrgSwitchDialogOpen,
      isHelpDialogOpen,
      isReportsDialogOpen,
      isGroupDownloadsDialogOpen,
      isGeoPdfDownloadsDialogOpen,
      notifications,
      feedback,
    } = this.props

    return (
      <>
        {isPreferencesDialogOpen && <PreferencesDialog />}
        <MappedNoteTemplates />
        {isOrgSwitchDialogOpen && <OrgSwitchDialog />}
        {isHelpDialogOpen && <HelpDialog />}
        {isReportsDialogOpen && <ReportsDialog />}
        {isGroupDownloadsDialogOpen && <GroupDownloadsDialog />}
        {isGeoPdfDownloadsDialogOpen && <GeoPdfDownloadsDialog />}
        {notifications && <Notifications />}
        {feedback && <Feedback />}
      </>
    )
  }
}

const mapState = (state: RootStore) => ({
  isPreferencesDialogOpen: state.app.isPreferencesDialogOpen,
  isOrgSwitchDialogOpen: state.app.isOrgSwitchDialogOpen,
  isHelpDialogOpen: state.app.isHelpDialogOpen,
  isReportsDialogOpen: state.reports.isReportsDialogOpen,
  isGroupDownloadsDialogOpen: state.groupDownloads.isGroupDownloadsDialogOpen,
  isGeoPdfDownloadsDialogOpen:
    state.geoPdfDownloads.isGeoPdfDownloadsDialogOpen,
  notifications: state.notifications.notifications,
  feedback: state.notifications.feedback,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps>(mapState)(AppDialogs)
