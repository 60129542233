import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import Icon from '@mui/material/Icon'
import * as React from 'react'

export interface ExpandProps extends CheckboxProps {
  expanded?: boolean
}

export class ExpandButton extends React.PureComponent<ExpandProps> {
  render() {
    const { expanded, style, ...rest } = this.props

    return (
      <Checkbox
        color="default"
        style={{ padding: 4, ...style }}
        disableRipple
        icon={<Icon>keyboard_arrow_right</Icon>}
        checkedIcon={<Icon>keyboard_arrow_down</Icon>}
        checked={expanded}
        {...rest}
      />
    )
  }
}
