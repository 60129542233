import { createTheme, ThemeOptions } from '@mui/material/styles'

const baseTheme: ThemeOptions = {
  components: {
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
      },
      styleOverrides: {
        tooltip: {
          fontSize: 12,
        },
      }
    },
    MuiTab: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small'
            ? {
                fontSize: '0.75rem',
              }
            : { fontSize: '0.9rem' }),
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        PaperProps: {
          elevation: 4,
          style: {
            width: 'var(--sidebar-width)',
            background: 'var(--background2)',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#4AB663',
      contrastText: '#fff',
    },
    secondary: {
      main: '#c05e40',
      contrastText: '#fff',
    },
    // text: {
    //   // Primary needs to be adjusted when type=dark.
    //   // primary: '#323232',
    // },
    common: {
      black: '#424242',
    },
  },

  transitions: {
    easing: {
      easeIn: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
      easeOut: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
      easeInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
      sharp: 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontSize: 12,
    button: {
      textTransform: 'capitalize',
    },
    caption: {
      fontSize: 12,
    },
    fontFamily: [
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"FontAwesome"',
    ].join(','),
  },
}

export const darkTheme = createTheme({
  ...baseTheme,
  components: {
    ...baseTheme.components,
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          style: {
            background: 'rgb(66, 66, 66)',
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        PaperProps: {
          style: {
            background: 'rgb(66, 66, 66)',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#FFF',
          },
          '&.MuiCheckbox-indeterminate': {
            color: 'rgba(255, 255, 255, 0.65)',
          },
        },
      },
    },
  },
  palette: {
    ...baseTheme.palette,
    background: {
      default: 'rgb(48, 48, 48)',
      paper: 'rgb(66, 66, 66)',
    },

    mode: 'dark',
  },
})
;(darkTheme as any).overrides = {
  MUIRichTextEditor: {
    placeHolder: {
      color: darkTheme.palette.text.disabled,
      padding: darkTheme.spacing(1),
    },
    editor: {
      padding: darkTheme.spacing(1),
    },
    editorReadOnly: {
      padding: darkTheme.spacing(1 / 3),
    },
  },
}

export const lightTheme = createTheme({
  ...baseTheme,
  components: {
    ...baseTheme.components,
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: baseTheme.palette!.common!.black,
          },
          '&.MuiCheckbox-indeterminate': {
            color: 'rgba(50, 50, 50, 0.65)',
          },
        },
      },
    },
  },
  palette: {
    ...baseTheme.palette,
    background: {
      default: 'rgb(245, 245, 245)',
      paper: 'rgb(250, 250, 250)',
    },
    text: {
      ...baseTheme.palette!.text,
      primary: baseTheme.palette!.common!.black,
    },
  },
})
;(lightTheme as any).overrides = {
  MUIRichTextEditor: {
    placeHolder: {
      color: lightTheme.palette.text.disabled,
      padding: lightTheme.spacing(1),
    },
    editor: {
      padding: lightTheme.spacing(1),
    },
    editorReadOnly: {
      padding: darkTheme.spacing(1 / 3),
    },
  },
}

export const themes = {
  dark: darkTheme,
  light: lightTheme,
}
