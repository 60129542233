import * as api from '../../../graphql/api'
import { ScheduledNotification } from '../../../graphql/types'

export const fetchScheduledNotificationById = async ([id]: [id: string], skip: () => void) => {
  if (!id) {
    return skip()
  }
  return api.scheduledNotifications.get<ScheduledNotification>({ pk: { id }, returning: 
   `{ 
      name 
      desc
      type
      organizationIds 
      userIds
      sendDate
      expireDate
      data
    }` 
  })
}
