import { createSelector } from 'reselect'

import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import * as api from '../graphql/api'
import { User } from '../graphql/types'
import { RootStore } from '../redux/types'
import { indexArray } from '../util/indexArray'

export type UserData = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'> & {
  userOrgs: { organizationId: number }[]
}

const fetchUsers = async (
  { isLoggedIn }: { isLoggedIn: boolean },
  skip: () => void
) => {
  if (!isLoggedIn) {
    return skip()
  }

  const { data = [] } = await api.user.list<UserData>({
    returning: `{
      id
      firstName
      lastName
      email
      userOrgs: OrganizationUsers {
        organizationId
      }
    }`,
  })

  if (!data) {
    throw new Error('No Data')
  }

  return data
}

const { selector: selectUserList, refresh: refreshUserList } =
  createAsyncSelector({
    resource: 'me.users',
    inputs: {
      isLoggedIn: (state: RootStore) => state.login.isLoggedIn,
    },
    fetcher: fetchUsers,
  })

const EMPTY_USERS: UserData[] = []

export const selectUsers = createSelector(
  selectUserList,
  (userList) => userList.data ?? EMPTY_USERS
)

export const selectUsersById = createSelector(selectUsers, (users) =>
  indexArray(users, 'id')
)

export { selectUserList, refreshUserList }
