import { createSelector } from 'reselect'
import { selectOrganizationSubscriptions } from '../../../data/selectOrganizationSubscriptions'
import { selectGetOrder } from './selectGetOrder'

export const selectOrderSubscriptions = createSelector(
  selectGetOrder,
  selectOrganizationSubscriptions,
  (getOrder, subscriptions) => {
    if (!getOrder.data || !getOrder.data.subscription) {
      return subscriptions
    }

    return [getOrder.data.subscription, ...subscriptions]
  }
)
