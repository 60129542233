import { oneAtATime } from './oneAtATime'

function dataURItoBlob(dataURI: any) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  // tslint:disable-next-line:no-increment-decrement
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

// some browsers throttle clicks that
// occur too close together.
// using oneAtAtTime with 1 second delays between each function run
// to avoid the throttle
export const downloadURL = oneAtATime(
  async (url: string, filename?: string) => {
    const a = document.createElement('a')
    a.href = url
    a.rel = 'download'
    if (filename) {
      a.download = filename
    }
    a.click()
  },
  { delayBetween: 1000 }
)

export function downloadText(text: string, filename: string) {
  downloadBlob(
    new Blob([text], {
      type: 'text/plain',
    }),
    filename
  )
}

export function downloadJson(json: string, filename: string) {
  downloadBlob(
    new Blob([json], {
      type: 'application/json',
    }),
    filename
  )
}

export function downloadShapefile(shp: Blob, filename: string) {
  downloadBlob(shp, cleanFilename(addExtension(filename, '.shp.zip')))
}

export function downloadGeoJson(json: string, filename: string) {
  downloadBlob(
    new Blob([json]),
    cleanFilename(addExtension(filename, '.geojson'))
  )
}

export function downloadBlob(blob: Blob, filename: string) {
  const blobURL = URL.createObjectURL(blob)
  downloadURL(blobURL, filename)
  URL.revokeObjectURL(blobURL)
}

export function downloadURI(uri: string, filename: string) {
  downloadBlob(dataURItoBlob(uri), filename)
}

export const addExtension = (filename: string, extension: string) => {
  if (filename.endsWith(extension)) {
    return filename
  }

  return `${filename}${extension}`
}

export function cleanFilename(name: string) {
  return name
    .split('.')
    .map((part) => part.replace(/(\W)+/gi, '-'))
    .join('.')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}
