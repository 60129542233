import * as React from 'react'
import { url, urls } from '../../../appNavigation/urls'
import { AdminRole_create_input } from '../../../graphql/types'
import { useHistory } from '../../../hooks/useRouter'
import { AdminPage } from '../AdminPage'
import AdminForm from './AdminForm'
import { createAdminRole } from './createAdminRole'

export function AdminNew() {
  const history = useHistory()

  const handleCancel = () => history.push(url(urls.admin_admins))

  const handleSave = async (input: AdminRole_create_input) => {
    const result = await createAdminRole(input)

    if (result && result.data) {
      const { userId, role } = result.data
      history.replace(url(urls.admin_admins_edit, { userId, role }))

      return true
    }

    return false
  }

  return (
    <AdminPage title="New Admin">
      <AdminForm type="new" onCancel={handleCancel} onSave={handleSave} />
    </AdminPage>
  )
}
