import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class EnterNoteGenerator implements IMapActionGenerator {
  key = 'point-note-enter'
  priority = Priority.PointNote

  generateActionsFromQualifyingFeatures({
    map,
    features,
  }: IMapActionGeneratorParams): IMapAction[] {
    // Find the qualifying features.
    const qualifiers = features.filter((f: MapboxGL.MapboxGeoJSONFeature) =>
      f.layer.id.startsWith('notes-project')
    )

    // Return array of functions that can execute the action for each of the qualifying features.
    return qualifiers.map(() => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        map.getCanvas().style.cursor = 'pointer'
      },
    }))
  }
}
