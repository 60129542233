import { getApiUrl, getAuthToken } from '../vvapi/apiResource/createApiResource'
import { createGQLClient, gql } from './createGQLClient'
export { gql }

export const client = createGQLClient(() => ({
  url: `${getApiUrl()}/hasura/v1/graphql`,
  headers: {
    authorization: `${getAuthToken()}`,
  },
}))

export const query = client.request
