import * as React from 'react'
import { useRedux } from '../../hooks/useRedux'
import { selectDrawerStatus } from './selectors/selectDrawerStatus'
import { MapEditorToolType } from '../../redux/map-editor-pages/redux'
import {
  Button,
  ButtonProps,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DrawerIcon } from '../../UI/icons/drawer-icon'
import { MapEditorActionTypes } from './types'
interface Props extends ButtonProps {
  pageType: MapEditorToolType
  side: 'left' | 'right'
}

const StyledButton = styled(Button)((props: Pick<Props, 'side'>) => ({
  color: 'white',
  fontSize: 24,
  minWidth: 0,
}))

const Icon = styled(DrawerIcon)({
  color: 'white',
})

export const ToggleDrawerButton = ({
  pageType,
  side,
  ...buttonProps
}: Props) => {
  const [state, dispatch] = useRedux()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { leftDrawerOpen, rightDrawerOpen } = selectDrawerStatus(
    state,
    pageType
  )

  const toggleDrawer = React.useCallback(
    (side: 'left' | 'right', isMobile: boolean) => {
      if (side === 'left') {
        dispatch({
          type: MapEditorActionTypes.SET_DRAWER,
          page: pageType,
          payload: {
            leftDrawerOpen: !leftDrawerOpen,
            rightDrawerOpen: isMobile ? false : undefined,
          },
        })
      }
      if (side === 'right') {
        dispatch({
          type: MapEditorActionTypes.SET_DRAWER,
          page: pageType,
          payload: {
            rightDrawerOpen: !rightDrawerOpen,
            leftDrawerOpen: isMobile ? false : undefined,
          },
        })
      }
    },
    [dispatch, leftDrawerOpen, rightDrawerOpen, pageType]
  )

  return (
    <StyledButton
      side={side}
      onClick={() => toggleDrawer(side, isMobile)}
      {...buttonProps}
    >
      <Icon variant={side} />
    </StyledButton>
  )
}
