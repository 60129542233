import * as api from '../../../graphql/api'
import { createTableActionIconButton } from '../../../UI/Table/createTableActionIconButton'
import { TableAction } from '../../../UI/Table/types'
import { asyncForEach } from '../../../util/syncArrayHelpers'
import { refreshGetDeliveryGroupFileStatus } from './selectGetDeliveryGroupFileStatus'
import { refreshListDeliveryGroupFileStatus } from './selectListDeliveryGroupFileStatus'
import { ListDeliveryGroupFileStatus } from './types'

const refresh: TableAction<ListDeliveryGroupFileStatus> = {
  button: createTableActionIconButton({
    title: 'Refresh',
    icon: 'autorenew',
    key: 'refresh-delivery-group-files',
  }),
  action: () => {
    refreshGetDeliveryGroupFileStatus()
    refreshListDeliveryGroupFileStatus()
  },
}

const reprocess: TableAction<ListDeliveryGroupFileStatus> = {
  selectionRequired: true,
  multi: true,
  button: createTableActionIconButton({
    title: 'Re-process DeliveryGroupFile(s)',
    icon: 'redo',
    key: 'reprocess-delivery-group-file',
  }),
  action: async (selection) => {
    await asyncForEach(selection, { limit: 2 }, async (deliveryGroupFile) => {
      await api.deliveryGroupFile.reprocess(deliveryGroupFile)
    })
    refreshGetDeliveryGroupFileStatus()
    refreshListDeliveryGroupFileStatus()
  },
}

const download: TableAction<ListDeliveryGroupFileStatus> = {
  selectionRequired: true,
  button: createTableActionIconButton({
    title: 'Download DeliveryGroupFile',
    icon: 'cloud_download',
    key: 'download-delivery-group-file',
  }),
  action: ([dgf]) => {
    api.deliveryGroupFile.download(dgf)
  },
}

export const actions = {
  refresh,
  reprocess,
  download,
}
