import * as React from 'react'
import { default as Icon } from '@mui/material/Icon'
import { default as Typography } from '@mui/material/Typography'
import CreateSourcePropertyDialog from '../../../admin/mapdata/source-properties/CreateSourcePropertyDialog'
import deleteSourceProperty from '../../../admin/mapdata/source-properties/deleteSourceProperty'
import EditSourcePropertyDialog from '../../../admin/mapdata/source-properties/EditSourcePropertyDialog'
import { MapSourceDef } from '../../../graphql/types'
import TooltipIconButton from '../../../UI/TooltipIconButton'
import { GetMapSourceDefData } from '../../MapSourceDef/selectGetMapSourceDef'

interface Props {
  mapSourceDefId: MapSourceDef['id']
  properties: GetMapSourceDefData['MapSourceProperties']
  onChange(): void
}

interface State {
  creatingProperty?: boolean
  editPropertyId?: string
}

class SourcePropertyList extends React.PureComponent<Props, State> {
  state: State = {}

  render() {
    return (
      <div className="grid-xs-12">
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1">Properties</Typography>
          <TooltipIconButton
            tooltipProps={{
              placement: 'right',
            }}
            title={`Create a property `}
            onClick={() => this.setState({ creatingProperty: true })}
          >
            <Icon fontSize="small">add_circle_outline</Icon>
          </TooltipIconButton>
        </div>
        <div>
          {(this.props.properties ?? []).map((property) => (
            <div key={property.id}>
              <Typography component="span">
                {property.name}
                <TooltipIconButton
                  title={`Edit property `}
                  onClick={() => this.setState({ editPropertyId: property.id })}
                >
                  <Icon fontSize="small">edit</Icon>
                </TooltipIconButton>
                <TooltipIconButton
                  title={`Delete property `}
                  onClick={() => this.handleDeleteProperty(property)}
                >
                  <Icon fontSize="small">delete</Icon>
                </TooltipIconButton>
              </Typography>
            </div>
          ))}
        </div>
        {this.renderCreatePropertyDialog()}
        {this.renderEditPropertyDialog()}
      </div>
    )
  }

  renderCreatePropertyDialog = () => {
    const { creatingProperty } = this.state
    const { mapSourceDefId } = this.props

    return (
      <CreateSourcePropertyDialog
        open={!!creatingProperty}
        key={String(creatingProperty)}
        onClose={this.handleDialogClose}
        mapSourceDefId={mapSourceDefId}
        onSave={this.handleSave}
      />
    )
  }

  renderEditPropertyDialog = () => {
    const { editPropertyId } = this.state

    if (!editPropertyId) {
      return null
    }

    return (
      <EditSourcePropertyDialog
        open={!!editPropertyId}
        onClose={this.handleDialogClose}
        id={editPropertyId}
        onSave={this.handleSave}
      />
    )
  }

  handleSave = () => {
    this.props.onChange()
    this.handleDialogClose()
  }

  handleDialogClose = () =>
    this.setState({ creatingProperty: false, editPropertyId: undefined })

  handleDeleteProperty = async (
    property: UnwrapArray<GetMapSourceDefData['MapSourceProperties']>
  ) => {
    await deleteSourceProperty(property)
    this.props.onChange()
  }
}

export default SourcePropertyList
