import * as React from 'react'
import { default as Checkbox } from '@mui/material/Checkbox'
import { default as FormControl } from '@mui/material/FormControl'
import { default as FormControlLabel } from '@mui/material/FormControlLabel'
import { default as InputLabel } from '@mui/material/InputLabel'
import { default as MenuItem } from '@mui/material/MenuItem'
import { default as Select, SelectChangeEvent } from '@mui/material/Select'
import { default as TextField } from '@mui/material/TextField'
import { default as Typography } from '@mui/material/Typography'
import { ChangeEvent } from 'react'
import { NameAndValue } from '../../../admin/mapdata/types'
import FormWrapper from '../../../admin/mapdata/utils/FormWrapper'
import {
  MapSourceProperty,
  MapSourcePropertyClass,
  SourcePropertyTypes,
} from '../../../graphql/types'
import TranslationTextField from '../../../UI/TranslationTextField/TranslationTextField'
import area, { AreaUnit } from '../../../util/units/area'
import length, { LengthUnit } from '../../../util/units/length'
import ClassInputList from './ClassInputList'
import RangeFormInputs from './RangeFormInputs'
import { isNumeric } from '../../../util/isNumeric'

export type MapSourcePropertyData = Removes<
  MapSourceProperty,
  string,
  'name' | 'description' | 'acronym'
> & {
  classes: Removes<MapSourcePropertyClass, string, 'translation'>[]
}

interface Props {
  instance: Partial<Writable<MapSourcePropertyData>>
  onChange(v: NameAndValue): void
  onSubmit(): void
}

const types = ['Class', 'Value']
const measurementTypes = ['Absolute', 'Relative']
const valueTypes: SourcePropertyTypes[] = ['text', 'index', 'area', 'length']
const areaUnits = Object.keys(area.units) as AreaUnit[]
const lengthUnits = Object.keys(length.units) as LengthUnit[]

class SourcePropertyForm extends React.PureComponent<Props> {
  render() {
    const { instance, onChange, onSubmit } = this.props

    if (!instance) {
      return null
    }

    let valueUnits: string[] = []

    if (instance.valueType === 'area') {
      valueUnits = areaUnits
    } else {
      valueUnits = lengthUnits
    }

    return (
      <FormWrapper onSubmit={onSubmit}>
        <div className="grid-xs-12">
          <TranslationTextField
            required
            name="name"
            label={`Name`}
            value={instance.name ?? {}}
            onChange={onChange}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            name="description"
            label={`Description`}
            value={instance.description ?? {}}
            onChange={onChange}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            required
            name="property"
            label={`Property`}
            value={instance.property ?? ''}
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="noDataValue"
            label={`No Data Value`}
            value={instance.noDataValue ?? ''}
            onChange={this.handleAlphaNumericChange}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            name="acronym"
            label={`Acronym`}
            value={instance.acronym ?? {}}
            onChange={onChange}
          />
        </div>
        <div className="grid-xs-12">
          <FormControl fullWidth>
            <InputLabel required shrink={!!instance.measurementType}>
              Measurement Type{' '}
            </InputLabel>
            <Select
              name="measurementType"
              fullWidth
              onChange={this.handleSelectChange}
              value={instance.measurementType ?? 'absolute'}
            >
              {measurementTypes.map((type) => (
                <MenuItem key={type} value={type.toLowerCase()}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="grid-xs-12">
          <FormControl fullWidth>
            <InputLabel required shrink={!!instance.type}>
              Type{' '}
            </InputLabel>
            <Select
              name="type"
              fullWidth
              onChange={this.handleSelectChange}
              value={instance.type ?? []}
            >
              {types.map((type) => (
                <MenuItem key={type} value={type.toLowerCase()}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {instance.type === 'class' && (
          <div className="grid-xs-12">
            <ClassInputList
              name="classes"
              label={`Classes`}
              value={instance.classes ?? []}
              onChange={onChange}
            />
          </div>
        )}
        {instance.type === 'value' && (
          <React.Fragment>
            <div className="grid-xs-12">
              <FormControl fullWidth>
                <InputLabel shrink={!!instance.valueType}>
                  valueType{' '}
                </InputLabel>
                <Select
                  name="valueType"
                  fullWidth
                  onChange={this.handleSelectChange}
                  value={instance.valueType ?? ''}
                >
                  <MenuItem value="" />
                  {valueTypes.map((valueType) => (
                    <MenuItem key={valueType} value={valueType.toLowerCase()}>
                      {valueType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {instance.valueType !== 'index' && instance.valueType !== 'text' && (
              <div className="grid-xs-12">
                <FormControl fullWidth>
                  <InputLabel shrink={!!instance.valueUnit}>
                    valueUnit
                  </InputLabel>
                  <Select
                    name="valueUnit"
                    fullWidth
                    onChange={this.handleSelectChange}
                    value={instance.valueUnit ?? ''}
                  >
                    <MenuItem value="" />
                    {valueUnits.map((valueUnit) => (
                      <MenuItem key={valueUnit} value={valueUnit}>
                        {valueUnit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {instance.valueType !== 'text' && (
              <div className="grid-xs-12">
                <Typography variant="subtitle1">Range</Typography>
                <RangeFormInputs
                  name="range"
                  value={instance.range ?? []}
                  onChange={onChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
        <div className="grid-xs-12">
          <FormControlLabel
            control={
              <Checkbox
                checked={!!instance.filterable}
                onChange={this.handleCheckbox}
                name="filterable"
              />
            }
            label="Filterable"
          />
        </div>
        <div className="grid-xs-12">
          <FormControlLabel
            control={
              <Checkbox
                checked={!!instance.showInPopup}
                onChange={this.handleCheckbox}
                name="showInPopup"
              />
            }
            label="Show In Popup"
          />
        </div>
      </FormWrapper>
    )
  }

  handleSelectChange = (e: SelectChangeEvent) => this.props.onChange(e.target)

  handleAlphaNumericChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isNumeric(e.target.value)) {
      this.props.onChange({
        name: e.target.name,
        value: parseFloat(e.target.value),
      })
    } else {
      this.handleChange(e)
    }
  }

  handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.props.onChange(e.target)

  handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChange({
      name: e.target.name,
      value: e.target.checked,
    })
}

export default SourcePropertyForm
