import { isEqual } from 'lodash'
import * as React from 'react'
import { RouteComponentProps, RouteProps, withRouter } from 'react-router-dom'
import { routerActions } from '../appNavigation/routerRedux'
import { connect } from '../redux/connect'
import { AppDispatchProps } from '../redux/types'

/** higher-order component that keeps the RouterState up to date */
// tslint:disable-next-line: variable-name
const withRouterParamsTracker = (Component: RouteProps['component']) => {
  if (!Component) {
    return null
  }

  return function WithRouterParamsTracker(props: any) {
    return (
      <RouterParamsMonitor>
        <Component {...props} />
      </RouterParamsMonitor>
    )
  }
}

class RouterParamsMonitorClass extends React.Component<
  RouteComponentProps & AppDispatchProps
> {
  componentDidMount() {
    this.componentDidUpdate()
  }

  componentDidUpdate(oldProps?: this['props']) {
    const oldMatch = oldProps?.match
    const oldLocation = oldProps?.location
    const { match, location, history, staticContext } = this.props

    if (!isEqual(oldMatch, match) || !isEqual(oldLocation, location)) {
      const searchParams = {}
      new URLSearchParams(location.search ?? '').forEach((value, key) => {
        searchParams[key] = value
      })

      this.props.dispatch(
        routerActions.setRouterState({
          ...match,
          searchParams,
          hash: location.hash,
          // router
          history,
          match,
          location,
          staticContext,
        })
      )
    }
  }

  render() {
    return this.props.children || null
  }
}

// tslint:disable-next-line: variable-name
const RouterParamsMonitor = connect<{}, {}, AppDispatchProps>()(
  withRouter(RouterParamsMonitorClass)
)

export default withRouterParamsTracker
