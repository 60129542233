import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { connect } from '../../../redux/connect'
import { AppDispatchProps } from '../../../redux/types'
import handleError from '../../../util/handleError'
import getMapImage from './getMapImage'
import MapLoader from './MapLoader'
import { MapImageProps } from './types'

interface State {
  src: string
}

class MapImage extends React.Component<
  MapImageProps & AppDispatchProps,
  State
> {
  state: State = {
    src: '',
  }

  _fetched = false

  componentDidMount() {
    this.update()
  }

  componentDidUpdate() {
    this.update()
  }

  componentWillUnmount() {
    getMapImage.empty()
  }

  update = async () => {
    if (this._fetched || !this.props.container) {
      return
    }
    this._fetched = true

    try {
      const src = await getMapImage(this.props)

      if (src) {
        this.setState({
          src,
        })
      }
    } catch (e) {
      const isAborted = /aborted/i.test((e as Error).message)
      if (!isAborted) {
        // ignore aborted errors
        Sentry.addBreadcrumb({
          category: 'map-data',
          message: 'Map Image failed to load',
          level: Sentry.Severity.Error,
        })
        handleError(e)
      }
    }
  }

  render() {
    if (!this.state.src) {
      return <MapLoader />
    }

    return <img src={this.state.src} alt="" />
  }
}

export default connect<{}, MapImageProps, AppDispatchProps>()(MapImage)
