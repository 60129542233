import * as React from 'react'

import {
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import ColorProfilePreview from '../admin/mapdata/color-profiles/ColorProfilePreview'
import { LanguageSelection } from '../app/types'
import {
  selectColorProfilesByProductId,
  selectPDFEnabledProductsForOrgById,
} from '../data/selectListMapSourceDefs'
import { selectGroupDatesByGroupIdAndDeliveryId } from '../data/selectOrgMapData'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { MapColorProfileData } from '../data/types'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import languageOptions from '../i18n/languageOptions'
import { VisualizationMode } from '../ProductSettings/store/types'
import { getSelectedColorProfiles } from '../ProductStats/calculateStats/getSelectedColorProfiles'
import { unionSet } from '../util/unionSet'
import { SettingsBase } from './types'
import { setIntersect } from '../util/setIntersect'

export interface GeoPDFDownloadSettings extends SettingsBase {
  type: 'geoPdf'
  selectedVisualizationMode?: string
  selectedProduct?: string
  selectedColorProfile?: string
  selectedLanguage?: LanguageSelection
}
interface Props {
  selectedGroups: Set<string>
  selectedDeliveries: Set<string>
  settings: GeoPDFDownloadSettings
  updateSettings: (settings: GeoPDFDownloadSettings) => void
  updateName: (name: string) => void
  name: string
}

export const GeoPDFDownloadSettingsForm = ({
  name,
  updateName,
  settings,
  updateSettings,
  selectedGroups,
  selectedDeliveries,
}: Props) => {
  const [state] = useRedux()

  const preferredLanguage = selectPreferredLanguage(state)

  const groupDatesByGroupIdAndDeliveryId =
    selectGroupDatesByGroupIdAndDeliveryId(state)

  const products = selectPDFEnabledProductsForOrgById(state)
  const colorProfiles = selectColorProfilesByProductId(state)
  const [availableProducts, setAvailableProducts] = React.useState<string[]>([])
  const [availableVisualizationModes, setAvailableVisualizationModes] =
    React.useState<string[]>([])
  const [colorProfilesByVisualization, setColorProfilesByVisualization] =
    React.useState<Record<string, MapColorProfileData[]> | undefined>(undefined)

  const {
    selectedProduct,
    selectedColorProfile,
    selectedLanguage,
    selectedVisualizationMode,
  } = settings

  React.useEffect(() => {
    updateSettings({ ...settings, selectedVisualizationMode: undefined })

    if (selectedProduct === undefined) {
      setColorProfilesByVisualization(undefined)
      setAvailableVisualizationModes([])
    } else {
      const productColorProfiles = colorProfiles[selectedProduct] ?? []
      const productColorProfilesByVisualization = productColorProfiles.reduce(
        (curr, colorProfile) => {
          for (const visualization of colorProfile.visualizations) {
            curr[visualization.visualization] = [
              ...(curr[visualization.visualization] ?? []),
              colorProfile,
            ]
          }
          return curr
        },
        {} as Record<VisualizationMode, MapColorProfileData[]>
      )

      const selectedColorProfiles = getSelectedColorProfiles(
        productColorProfilesByVisualization,
        {}
      )

      const visModes = Object.keys(productColorProfilesByVisualization)
      setAvailableVisualizationModes(visModes)
      setColorProfilesByVisualization(productColorProfilesByVisualization)

      updateSettings({
        ...settings,
        selectedVisualizationMode: visModes[0],
        selectedColorProfile: selectedColorProfiles[visModes[0]]?.id,
      })
    }
  }, [colorProfiles, selectedProduct, updateSettings]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!selectedVisualizationMode) {
      updateSettings({ ...settings, selectedColorProfile: undefined })
      return
    }
    if (
      selectedColorProfile &&
      colorProfilesByVisualization !== undefined &&
      !colorProfilesByVisualization[selectedVisualizationMode]?.some(
        (colorProfile) => colorProfile.id === selectedColorProfile
      )
    ) {
      const selectedColorProfiles = getSelectedColorProfiles(
        colorProfilesByVisualization,
        {}
      )
      const defaultColorProfileForVisualization =
        selectedColorProfiles[selectedVisualizationMode]

      updateSettings({
        ...settings,
        selectedColorProfile: defaultColorProfileForVisualization?.id,
      })
    }
  }, [
    selectedVisualizationMode,
    colorProfilesByVisualization,
    selectedColorProfile,
    updateSettings,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const availableProducts = Array.from(selectedGroups).reduce(
      (curr, group, index) => {
        let groupProducts = new Set<string>()
        for (const deliveryId of Array.from(selectedDeliveries)) {
          groupProducts = unionSet(
            groupProducts,
            new Set(
              groupDatesByGroupIdAndDeliveryId[
                `${group}/${deliveryId}`
              ]?.reduce(
                (ids, date) => [...ids, ...date.enabledMapLayerDefIds] ?? [],
                [] as string[]
              )
            )
          )
        }

        if (index === 0) {
          return groupProducts
        }

        return setIntersect(curr, groupProducts)
      },
      new Set<string>()
    )

    if (selectedProduct && !availableProducts.has(selectedProduct)) {
      updateSettings({ ...settings, selectedProduct: undefined })
    }

    setAvailableProducts(Array.from(availableProducts))
  }, [
    selectedGroups,
    selectedDeliveries,
    selectedProduct,
    groupDatesByGroupIdAndDeliveryId,
    updateSettings,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateName(e.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {i18n.t(keys.geoPdfSettings)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.product)}: </InputLabel>
          <Select
            disabled={!availableProducts.length}
            defaultValue=""
            value={selectedProduct ?? ''}
            onChange={(ev) =>
              updateSettings({ ...settings, selectedProduct: ev.target.value })
            }
          >
            {availableProducts.map((productId) => {
              return !!products[productId] ? (
                <MenuItem key={productId} value={productId}>
                  {products[productId].name}
                </MenuItem>
              ) : null
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.visualization.visualization)}: </InputLabel>
          <Select
            disabled={!availableVisualizationModes.length}
            defaultValue=""
            value={selectedVisualizationMode ?? ''}
            onChange={(ev) =>
              updateSettings({
                ...settings,
                selectedVisualizationMode: ev.target.value,
              })
            }
          >
            {!!selectedProduct
              ? availableVisualizationModes?.map((visualizationMode) => (
                  <MenuItem key={visualizationMode} value={visualizationMode}>
                    {i18n.t(keys.visualization[visualizationMode])}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.colorProfile)}: </InputLabel>
          <Select
            disabled={!selectedVisualizationMode}
            defaultValue=""
            value={selectedColorProfile ?? ''}
            onChange={(ev) =>
              updateSettings({
                ...settings,
                selectedColorProfile: ev.target.value,
              })
            }
          >
            {!!selectedVisualizationMode
              ? colorProfilesByVisualization?.[selectedVisualizationMode]?.map(
                  (colorProfile) => (
                    <MenuItem key={colorProfile.id} value={colorProfile.id}>
                      <ListItemText
                        disableTypography
                        primary={
                          <div>
                            <Typography variant="subtitle1">
                              {colorProfile.name}
                            </Typography>
                            <Typography variant="caption">
                              {colorProfile.description}
                            </Typography>
                          </div>
                        }
                        secondary={<ColorProfilePreview {...colorProfile} />}
                      />
                    </MenuItem>
                  )
                )
              : null}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.language.languagePicker)}: </InputLabel>
          <Select
            defaultValue={preferredLanguage}
            value={selectedLanguage}
            onChange={(ev) =>
              updateSettings({
                ...settings,
                selectedLanguage: ev.target.value as LanguageSelection,
              })
            }
          >
            {languageOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <TextField
            label={i18n.t(keys.name)}
            required={true}
            value={name}
            onChange={handleNameInputChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
