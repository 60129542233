import * as React from 'react'
import { TableFormatter } from '../../UI/Table/types'
import { ListJob } from './types'
import { JobStatusChip } from './JobStatusChip'

export const jobTableFormatter: TableFormatter<ListJob>[] = [
  {
    header: () => 'Task',
    data: ({ taskName }) => taskName,
  },
  {
    header: () => 'Pipeline',
    data: ({ pipeline }) => pipeline?.name ?? 'None',
  },

  {
    header: () => 'Status',
    data: (job) => (
      <JobStatusChip
        jobStatusAndId={{ id: job.id, status: job.status.status }}
      />
    ),

    filterBy: (op, value) => ({
      Status: {
        status: { [`_${op}`]: value },
      },
    }),
  },
]
