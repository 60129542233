type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

/**
 * better typing replacement for array method .filter(Boolean)
 *
 * .filter(Boolean) filters out falsy values, but TypeScript is unaware
 * .filter(filterTruthy) does the same but has a type guard.
 *
 * These examples have identical outputs with differing types
 *
 * ```
 * [1, 2, 0, null].filter(Boolean) // (number | null)[]
 * [1, 2, 0, null].filter(filterTruthy) // number[]
 * ```
 *
 */
const filterTruthy = <T>(value: T): value is Truthy<T> => !!value

export default filterTruthy
