import { default as swal } from 'sweetalert'
import * as api from '../../graphql/api'
import { MapLayerDef } from '../../graphql/types'

const deleteMapLayerDefinition = async (ids: MapLayerDef['id'][]) => {
  const choice = await swal(
    `You are about to delete ${ids.length} layer definition(s)`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Delete',
        },
      },
      dangerMode: true,
    }
  )

  // swal returns null for "cancel"
  if (!choice) {
    return
  }

  for (const id of ids) {
    await api.mapLayerDef.delete({ pk: { id } })
  }
}

export default deleteMapLayerDefinition
