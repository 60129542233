import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  DEFAULT_PAGE_INFO,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { ORDER } from '../../../UI/Table/orderRows'
import { Table } from '../../../UI/Table/Table'
import { PaginationChange, TableOrder } from '../../../UI/Table/types'
import StatusHome from '../StatusHome'
import StatusSummary from '../StatusSummary'
import { actions } from './actions'
import { tableFormatter } from './formatters'
import { selectGetDeliveryGroupFileStatus } from './selectGetDeliveryGroupFileStatus'
import { selectListDeliveryGroupFileStatus } from './selectListDeliveryGroupFileStatus'
import { ListDeliveryGroupFileStatus } from './types'

interface State {
  statusFilter: string[]
  selection: ListDeliveryGroupFileStatus[]
}

class DeliveryGroupFileStatus extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps,
  State
> {
  _fetched = false

  state: State = {
    statusFilter: ['error'],
    selection: [],
  }

  getInfo = () => {
    return this.props.listDeliveryGroupFileStatus.data
      ? this.props.listDeliveryGroupFileStatus.data.info
      : { ...DEFAULT_PAGE_INFO, order: [] }
  }

  handleOrderChange = (order: TableOrder) =>
    this.replaceURL({ page: 0, orderBy: ORDER.serialize(order) })

  handleSelectionChange = (selection: State['selection']) =>
    this.setState({ selection })

  render() {
    const { getDeliveryGroupFileStatus, listDeliveryGroupFileStatus } =
      this.props
    const info = this.getInfo()

    const rows = listDeliveryGroupFileStatus.data
      ? listDeliveryGroupFileStatus.data.data
      : []

    return (
      <StatusHome>
        <div id="DeliveryGroupFileStatus" className="Paper">
          <StatusSummary summary={getDeliveryGroupFileStatus} />
          <AsyncSelectorStatusOverlay requests={listDeliveryGroupFileStatus}>
            <Table
              title="DeliveryGroupFile Status"
              rows={rows}
              actions={[actions.refresh, actions.reprocess, actions.download]}
              formatter={tableFormatter}
              order={info.order}
              onOrderChange={this.handleOrderChange}
              selection={this.state.selection}
              onSelectionChange={this.handleSelectionChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              pagination={info}
              onPaginationChange={this.handlePaginationChange}
            />
          </AsyncSelectorStatusOverlay>
        </div>
      </StatusHome>
    )
  }

  updateURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) => {
    const { pageSize: dPageSize, page: dPage, order } = this.getInfo()

    const {
      page = dPage,
      pageSize = dPageSize,
      orderBy = ORDER.serialize(order),
    } = options

    this.props.history.push(
      url(urls.deliveryGroupFileStatus, {}, { page, pageSize, orderBy })
    )
  }

  replaceURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) => {
    const { pageSize: dPageSize, page: dPage, order } = this.getInfo()

    const {
      page = dPage,
      pageSize = dPageSize,
      orderBy = ORDER.serialize(order),
    } = options

    this.props.history.replace(
      url(urls.deliveryGroupFileStatus, {}, { page, pageSize, orderBy })
    )
  }

  handlePaginationChange = (pagination: PaginationChange) =>
    this.updateURL(pagination)
}

const mapState = (state: RootStore) => {
  return {
    getDeliveryGroupFileStatus: selectGetDeliveryGroupFileStatus(state),
    listDeliveryGroupFileStatus: selectListDeliveryGroupFileStatus(state),
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(DeliveryGroupFileStatus)
)
