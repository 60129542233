import * as React from 'react'
import { PureComponent } from 'react'

import { Icon, Paper, Button, ButtonGroup, Tooltip, buttonGroupClasses, buttonClasses } from '@mui/material'
import { classnames } from '../../util/classnames'
import withMap, { WithMap } from '../withMap'
import './ZoomControl.scss'
import { styled } from '@mui/styles'

interface Props {
  className?: string
}

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [`&.${buttonGroupClasses.root}`]: {
    [`& .${buttonClasses.root}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,
      minWidth: 30,
      minHeight: 30,
      width: 30,
      height: 30,
    },
  }
}))

class ZoomControl extends PureComponent<Props & WithMap> {
  render() {
    const className = classnames(
      'ZoomControl',
      'no-print',
      this.props.className
    )

    return (
      <Paper className={className}>
        <StyledButtonGroup
          variant="contained"
          size="small"
          orientation="vertical">
          <Button onClick={this.zoomIn} value="zoomIn">
            <Tooltip
              title="Zoom-in"
              placement="left"
            >
              <Icon>add</Icon>
            </Tooltip>
          </Button>
          <Button onClick={this.zoomOut} value="zoomOut">
            <Tooltip
              title="Zoom-out"
              placement="left"
            >
              <Icon>remove</Icon>
            </Tooltip>
          </Button>
        </StyledButtonGroup>
      </Paper>
    )
  }

  zoomIn = () => this.props.map.zoomIn()
  zoomOut = () => this.props.map.zoomOut()
}

export default withMap(ZoomControl)
