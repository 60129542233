import * as Sentry from '@sentry/browser'
import { default as swal } from 'sweetalert'

interface PublicAlert {
  error?: Error
  title: string
  message: string
  icon?: 'error' | 'success' | 'info' | 'warning'
  tags?: Record<string, string>
  extras?: Record<string, any>
}

/**
 * It displays an error message on the screen and sends an error to Sentry
 */
const publicAlert = ({
  error,
  title,
  message,
  tags,
  extras,
  icon,
}: PublicAlert) => {
  if (error) {
    // tslint:disable-next-line:no-parameter-reassignment
    icon = 'error'

    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Error)

      if (tags) {
        scope.setTags(tags)
      }

      if (extras) {
        scope.setExtras(extras)
      }

      Sentry.addBreadcrumb({
        message,
      })

      Sentry.captureException(error)
    })
  }

  return swal({
    title,
    icon,
    text: message,
  })
}

export default publicAlert
