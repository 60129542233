import { createRequest } from './request'
import { sendToLogin } from './sendToLogin'

const request = createRequest(globalThis.fetch)
export function fetch(input: RequestInfo, init?: RequestInit) {
  return request(input, init, { retryDelay: 5, throttleRetries: 5 }).then(
    checkResponseCode
  )
}

export function putJson<TJsonBody = any>(
  input: RequestInfo,
  init: RequestInit = {}
) {
  if (init.body) {
    init.body = JSON.stringify(init.body)
  }

  init.method = init.method ?? 'put'
  init.headers = prepareHeaders(new Headers(init.headers))

  return fetch(input, init).then((resp) => {
    // Don't parse JSON for 204-No content
    if (resp.status === 204) {
      return
    }

    return resp.json()
  }) as Promise<TJsonBody>
}

export function postJson<TJsonBody = any>(
  input: RequestInfo,
  init: RequestInit = {}
) {
  if (init.body) {
    init.body = JSON.stringify(init.body)
  }

  init.method = init.method ?? 'post'
  init.headers = prepareHeaders(new Headers(init.headers))

  return fetch(input, init).then((resp) => {
    try {
      return resp.json()
    } catch (err) {
      throw err
    }
  }) as Promise<TJsonBody>
}

export function deleteJson<TJsonBody = any>(
  input: RequestInfo,
  init: RequestInit = {}
) {
  if (init.body) {
    init.body = JSON.stringify(init.body)
  }

  init.method = init.method ?? 'delete'
  init.headers = prepareHeaders(new Headers(init.headers))

  return fetch(input, init).then((resp) => resp.json()) as Promise<TJsonBody>
}

export async function getBlob(
  input: RequestInfo,
  init: RequestInit = {},
  returnHeaders = false
): Promise<{ blob: Blob; headers?: Headers }> {
  const resp = await fetch(input, init)
  const blob = await resp.blob()

  if (returnHeaders) {
    return {
      blob,
      headers: resp.headers,
    }
  }

  return { blob }
}

export async function getText(
  input: RequestInfo,
  init: RequestInit = {},
  returnHeaders = false
): Promise<{ text: string; headers?: Headers }> {
  init.headers = prepareHeaders(new Headers(init.headers))

  const resp = await fetch(input, init)
  const text = await resp.text()

  if (returnHeaders) {
    return {
      text,
      headers: resp.headers,
    }
  }

  return { text }
}

export async function headApi(
  input: RequestInfo,
  init: Omit<RequestInit, 'method'> = {}
) {
  init.headers = prepareHeaders(new Headers(init.headers))

  const resp = await fetch(input, { method: 'head', ...init })
  return resp.headers
}
export async function getJson<TJsonBody = any>(
  input: RequestInfo,
  init: RequestInit = {},
  returnHeaders = false
) {
  init.headers = prepareHeaders(new Headers(init.headers))

  const resp = await fetch(input, init)
  const jsonPayload = await resp.json()

  if (returnHeaders) {
    return {
      ...jsonPayload,
      headers: resp.headers,
    } as TJsonBody
  }

  return jsonPayload as TJsonBody
}

export function postBlob(input: RequestInfo, init: RequestInit = {}) {
  if (init.body) {
    init.body = JSON.stringify(init.body)
  }

  init.method = init.method ?? 'post'
  init.headers = prepareHeaders(new Headers(init.headers))

  return fetch(input, init).then((resp) => resp.blob())
}

export function prepareHeaders(headers: Headers) {
  headers.set('Content-Type', 'application/json')

  return headers
}

export async function checkResponseCode(resp: Response) {
  if (resp.status >= 400) {
    if (resp.status === 401) {
      sendToLogin()
    }
    const err = new Error(
      `Error ${resp.status} from ${resp.url}\n${await resp.text()}`
    ) as any
    err.fetchHttpResponse = resp
    throw err
  }

  return resp
}
