import { createSelector } from 'reselect'

import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { indexArrayMappedKey } from '../util/indexArray'
import { getSelectedGroup } from './getSelectedGroup'

const EMPTY_FILENAMES_BY_MAPSOURCEDEFID: Record<
  string,
  DeliveryParcelFilename
> = {}

interface DeliveryParcelFilename {
  filename: string
  mapSource: { mapSourceDefId: string }
}

const {
  selector: selectListDeliveryParcelFilesForGroup,
  refresh: refreshListDeliveryParcelFilesForGroup,
} = createAsyncSelector({
  resource: 'GroupDeliveryParcelFiles',
  inputs: {
    selectedGroup: getSelectedGroup,
  },

  fetcher: async ({ selectedGroup }) => {
    if (selectedGroup) {
      const { filenames } = await query<{
        filenames: DeliveryParcelFilename[]
        deliveryGroupPureVineZonesCount: { aggregate: { count: number } }
      }>({
        query: gql`
          query DELIVERY_GROUP_FILENAMES($deliveryId: uuid!, $groupId: Int!) {
            filenames: DeliveryParcelFile(
              where: {
                deliveryId: { _eq: $deliveryId }
                DeliveryParcel: { Parcel: { groupId: { _eq: $groupId } } }
                MapSource: {}
              }
              distinct_on: filename
            ) {
              filename
              mapSource: MapSource {
                mapSourceDefId
              }
            }
          }
        `,
        variables: {
          deliveryId: selectedGroup.deliveryId,
          groupId: selectedGroup.groupId,
        },
      })

      return { filenames }
    }

    return null
  },
})

const filenamesByMapSourcDefId = createSelector(
  selectListDeliveryParcelFilesForGroup,
  (listDeliveryParcelFilesForGroup) => {
    if (listDeliveryParcelFilesForGroup?.data) {
      return indexArrayMappedKey(
        listDeliveryParcelFilesForGroup.data.filenames,
        (fn) => fn.mapSource.mapSourceDefId
      )
    }

    return EMPTY_FILENAMES_BY_MAPSOURCEDEFID
  }
)

const EMPTY_FILENAMES: string[] = []

export const selectAvailableGroupDownloads = createSelector(
  getSelectedGroup,
  filenamesByMapSourcDefId,

  (selectedGroup, mapSourceDefs) => {
    if (selectedGroup) {
      return [
        ...Array.from(
          selectedGroup.parcels.reduce((acc, curr) => {
            return new Set([
              ...Array.from(acc),
              ...curr.mapSources.reduce(
                (arr, { mapSourceDefId }) => {
                  const ms = mapSourceDefs[mapSourceDefId]
                  if (ms?.filename) {
                    arr.push(ms.filename)
                  }

                  return arr
                },

                [] as string[]
              ),
            ])
          }, new Set<string>())
        ),
        'group-downloads.zip',
      ]
    }

    return EMPTY_FILENAMES
  }
)

export {
  selectListDeliveryParcelFilesForGroup,
  refreshListDeliveryParcelFilesForGroup,
}
