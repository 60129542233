import { TableFormatter } from '../../UI/Table/types'
import { ListPipeline } from './types'

export const pipelineTableFormatter: TableFormatter<ListPipeline>[] = [
  {
    header: () => 'Name',
    data: ({ name }) => name,
    orderBy: (direction) => `{ name: ${direction} } `,
  },

  {
    header: () => 'Job Count',
    data: ({
      jobs: {
        aggregate: { count },
      },
    }) => count,
  },
]
