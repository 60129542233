import { delay } from './delay'

interface Func<Args extends any[], Return> {
  (...args: Args): Promise<Return>
}

interface Job<Args extends any[], Return> {
  args: Args
  resolve: (result: Return) => void
  reject: (error: any) => void
}

interface OneAtATimeSettings {
  delayBetween?: number
}

export const oneAtATime = <Args extends any[], Return>(
  func: Func<Args, Return>,
  settings?: OneAtATimeSettings
) => {
  const queue: Job<Args, Return>[] = []
  let isDraining = false
  async function drain() {
    if (isDraining) {
      return
    }

    isDraining = true

    const job = queue.shift()
    if (job) {
      try {
        const result = await func(...job.args)
        if (settings?.delayBetween) {
          await delay(settings.delayBetween)
        }
        job.resolve(result)
      } catch (error) {
        job.reject(error)
      }
    }

    isDraining = false

    if (queue.length !== 0) {
      drain()
    }
  }

  return ((...args) =>
    new Promise<Return>((resolve, reject) => {
      queue.push({ args, resolve, reject })
      drain()
    })) as Func<Args, Return>
}

export const oneAtATimeApi = <T extends Record<string, Func<any, any>>>(
  api: T
): T => {
  const caller = oneAtATime((func: Func<any, any>, args: any[]) =>
    func(...args)
  )
  const atomics = {}

  for (const [key, method] of Object.entries(api)) {
    atomics[key] = (...args: any[]) => caller(method, args)
  }

  return atomics as T
}
