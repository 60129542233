import { TextFieldProps } from '@mui/material/TextField'
import { FieldValidator } from 'final-form'
import i18n, { keys } from '../i18n'
import { isValidEmail } from '../util/isEmailValid'

export const required: FieldValidator<TextFieldProps['value']> = (
  value: TextFieldProps['value']
) => {
  return !value ? i18n.t(keys.forms.notNull) : undefined
}

export const validEmail: FieldValidator<TextFieldProps['value']> = (
  value: TextFieldProps['value']
) => (!isValidEmail(value as string) ? i18n.t(keys.emailInvalid) : undefined)

export const passwordsMatch: FieldValidator<TextFieldProps['value']> = (
  value: TextFieldProps['value'],
  allValues: any
) => {
  const passLabel = i18n.t(keys.user.password)
  const confirmPassLabel = i18n.t(keys.user.confirmPassword)

  if (value && value !== allValues.password) {
    return i18n.t(keys.forms.confirmation, {
      field: confirmPassLabel,
      otherField: passLabel,
    })
  }

  return undefined
}

export const passwordLength: FieldValidator<TextFieldProps['value']> = (
  value: TextFieldProps['value']
) =>
  !!value && (value as string).length < 6
    ? i18n.t(keys.forms.password.length)
    : undefined
