import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material'
import * as React from 'react'

interface Props {
  children: React.ReactNode
  open?: boolean
  containerStyle?: React.CSSProperties
  infoLabel?: string
  backdropStyle?: React.CSSProperties
}

export const LoadingControlBackdrop = ({
  open,
  children,
  containerStyle,
  infoLabel,
  backdropStyle,
}: Props) => {
  return (
    <div style={{ position: 'relative', ...containerStyle }}>
      {children}
      <Backdrop
        open={!!open}
        sx={{
          color: '#FFFFFF',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
          ...backdropStyle,
        }}
      >
        <Stack alignItems='center'>
          <CircularProgress color="inherit" />
          {infoLabel && <Typography variant="h6">{infoLabel}</Typography>}
        </Stack>
      </Backdrop>
    </div>
  )
}
