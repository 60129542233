import * as React from 'react'
import { url, urls } from '../../appNavigation/urls'
import i18n, { keys } from '../../i18n'
import OrganizationPage from '../../pages/Organization/OrganizationPage'
import MapDataHomeNavigation from './MapDataHomeNavigation'

class MapDataHome extends React.PureComponent {
  render() {
    return (
      <OrganizationPage
        title={`Data Library`}
        backTo={url(urls.mapView)}
        backToTitle={i18n.t(keys.map.map)}
      >
        <div id="MapDataHome">
          <div className="MapDataContainer">
            <MapDataHomeNavigation />
          </div>
          <div className="MapDataContainer">{this.props.children}</div>
        </div>
      </OrganizationPage>
    )
  }

  handleTabChange = (_: any, tabValue: number) => {
    this.setState({ tabValue })
  }
}

export default MapDataHome
