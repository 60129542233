import * as React from 'react'

import {
  Checkbox,
  CircularProgress,
  Grid,
  ListItem,
  Typography,
  styled,
} from '@mui/material'
import { Media } from '../graphql/types'
import { AuthImage } from '../media/AuthImage'
import { getMediaThumbnailUrl, getMediaUrl } from '../media/util/getUrl'
import { convertBytesToMegabytes } from './MediaAttachmentsModal'
import { headApi } from '../vvapi/apiResource/createApiResource'
import i18n from '../i18n'

interface Props {
  media: Media
  selected: boolean
  onClick: (media: Media) => void
  onClickMedia: (mediaId: string | undefined) => void
  style?: React.CSSProperties
}

const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  lineHeight: '15px',
  letterSpacing: '0.25px',
  color: '#fff',
}))

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  lineHeight: '10px',
  letterSpacing: '0.4px',
  color: theme.palette.text.primary,
  opacity: 0.7,
  marginTop: '0 !important',
}))

const Image = styled(AuthImage)({
  borderRadius: '4px',
})

const MediaListItem = ({ media, selected, onClick, onClickMedia }: Props) => {
  const handleOnChange = () => {
    onClick(media)
  }
  const [formattedSize, setFormattedSize] = React.useState<string | null>(null)

  React.useEffect(() => {
    const fetchSize = async () => {
      const headers = await headApi(getMediaUrl(media))
      if (headers.has('content-length')) {
        setFormattedSize(
          convertBytesToMegabytes(Number(headers.get('content-length')), 1)
        )
      }
    }

    fetchSize()
  }, [media])

  return (
    <ListItem
      disablePadding
      style={{ borderBottom: 'solid 1px #424242', padding: '10px 0' }}
    >
      <Grid
        container
        spacing={1}
        alignItems="start"
        sx={{ width: '100%' }}
        pl={2}
      >
        <Grid
          item
          xs={2}
          onClick={() => onClick(media)}
          style={{ cursor: 'pointer' }}
          alignItems="center"
        >
          <Image
            src={getMediaThumbnailUrl(media!)}
            width="32"
            height="32"
            alt={media?.originalname}
            onClick={(event) => {
              onClickMedia(media.id)
              event.stopPropagation()
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <Grid container rowSpacing={1} columnSpacing={0}>
            <Grid item xs={8}>
              <TitleText noWrap>{media?.originalname}</TitleText>
            </Grid>

            <Grid item xs={4}>
              {formattedSize === null ? (
                <CircularProgress size="small" />
              ) : (
                <DescriptionText noWrap>{formattedSize}</DescriptionText>
              )}
            </Grid>

            <Grid item xs={4.9}>
              <DescriptionText noWrap>
                {i18n.toDateShort(media?.createdAt)}
              </DescriptionText>
            </Grid>

            <Grid item xs={0.7}>
              <DescriptionText noWrap>|</DescriptionText>
            </Grid>

            <Grid item xs={5}>
              <DescriptionText noWrap>
                {media?.CreatedBy
                  ? `${media.CreatedBy.firstName} ${media.CreatedBy.lastName}`
                  : ''}
              </DescriptionText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sx={{ marginLeft: 2.7 }}>
          <Checkbox
            checked={selected}
            sx={{
              padding: 0,
              '&.Mui-checked': {
                color: '#13c95e',
              },
            }}
            onChange={handleOnChange}
            size={'small'}
          />
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default MediaListItem
