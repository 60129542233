import * as React from 'react'
import Page, { PageProps } from '../../app/Page'
import { selectOrganization } from '../../data/selectOrganization'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import './OrganizationPage.scss'

/**
 * Template for all Organization Pages
 */
class OrganizationPage extends React.PureComponent<
  PageProps & ReduxProps & AppDispatchProps
> {
  render() {
    const { organization, children, ...rest } = this.props
    const subtitle = organization?.name ?? 'VineView'

    return (
      <Page subtitle={subtitle} {...rest}>
        {children}
      </Page>
    )
  }
}

const mapState = (state: RootStore) => ({
  organization: selectOrganization(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  OrganizationPage
)
