import { FeatureFlag } from '../../../graphql/types'
import { TableFormatter } from '../../../UI/Table/types'

const featureFlagIdColumn: TableFormatter<FeatureFlag> = {
  header: () => 'Feature Flag ID',
  data: ({ id }) => id,
  orderBy: (direction) => `
    {
     id: ${direction}
    }
  `
}

const featureFlagNameColumn: TableFormatter<FeatureFlag> = {
  header: () => 'Feature Flag Name',
  data: ({ name }) => name,
  orderBy: (direction) => `
    {
      name: ${direction}
    }
  `,
}

const featureFlagDescColumn: TableFormatter<FeatureFlag> = {
  header: () => 'Feature Flag Description',
  data: ({ desc }) => desc,
  orderBy: (direction) => `
    {
      desc: ${direction}
    }
  `,
}
const activeColumn: TableFormatter<FeatureFlag> = {
  header: () => 'Active',
  data: ({ active }) => !!active ? 'Yes' : 'No',
  orderBy: (direction) => `
    {
      active: ${direction}
    }
  `,
}
export const tableFormatter = [
  featureFlagIdColumn,
  featureFlagNameColumn,
  featureFlagDescColumn,
  activeColumn,
]
