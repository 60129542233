import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import errorAlert from '../../../admin/errorAlert'
import * as React from 'react'
import * as api from '../../../graphql/api'
import { TargetDelivery } from '../../../graphql/types'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { refreshListTargetDeliveries } from './selectListTargetDeliveries'
import TargetDeliveryForm from './TargetDeliveryForm'

interface Props {
  open: boolean
  onClose: (date?: string) => void
}

class NewTargetDelivery extends React.PureComponent<Props & ReduxProps> {
  render() {
    return (
      <Dialog id="NewTargetDelivery" fullWidth open={!!this.props.open}>
        <DialogTitle>Create Target Delivery</DialogTitle>
        <DialogContent>
          <TargetDeliveryForm
            type="new"
            onSave={this.handleSave}
            onCancel={this.props.onClose}
          />
        </DialogContent>
      </Dialog>
    )
  }

  handleSave = async (formData: TargetDelivery) => {
    const { orderId, onClose } = this.props
    const { date, inhibitFlightNotice = false } = formData

    try {
      const data = await api.targetDelivery.create<{ date: string }>({
        input: {
          orderId,
          date: date!,
          inhibitFlightNotice,
        },
        returning: '{ date }',
      })

      refreshListTargetDeliveries()

      onClose(data.data ? data.data.date : undefined)
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Delivery', message, this.state)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'NewTargetDelivery',
    },
  })

const mapState = (state: RootStore) => ({
  orderId: state.router.params.orderId!,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  NewTargetDelivery
)
