import * as React from 'react'

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import { DeliveryParcel } from './fetchSourceAndDestinationParcels'

interface Props {
  sourceParcel?: DeliveryParcel
  destinationParcelsByName: Record<string, Record<string, DeliveryParcel>>
  onCancel: () => void
  onSubmit: (parcelId: number) => void
}

export const ChooseDestinationParcelDialog = ({
  sourceParcel,
  destinationParcelsByName,
  onCancel,
  onSubmit,
}: Props) => {
  const theme = useTheme()
  const [expandedGroup, setExpandedGroup] = React.useState<string | undefined>()
  const [selectedDestinationParcelId, setSelectedDestinationParcelId] =
    React.useState<number | undefined>()

  const handleExpandGroup = (groupName: string) => {
    if (expandedGroup === groupName) {
      setExpandedGroup(undefined)
    } else {
      setExpandedGroup(groupName)
    }
  }

  React.useEffect(() => {
    if (!sourceParcel) {
      setTimeout(() => {
        setExpandedGroup(undefined)
        setSelectedDestinationParcelId(undefined)
      }, theme.transitions.duration.leavingScreen)
    } else {
      if (sourceParcel.parcel.group.name in destinationParcelsByName) {
        setExpandedGroup(sourceParcel.parcel.group.name)
      }
    }
  }, [sourceParcel])

  const handleCancel = () => {
    onCancel()
  }

  const handleSubmit = () => {
    if (selectedDestinationParcelId !== undefined) {
      onSubmit(selectedDestinationParcelId)
    }
  }

  return (
    <Dialog open={sourceParcel !== undefined}>
      <DialogTitle>Choose Destination Parcel</DialogTitle>
      <DialogContent>
        {sourceParcel ? (
          <Stack spacing={1}>
            <Typography>
              {`Source Parcel: ${sourceParcel.parcel.group.name} | ${sourceParcel.parcel.name}`}
            </Typography>
            <List>
              <ListItemButton
                divider
                key={-1}
                onClick={() => setSelectedDestinationParcelId(-1)}
                selected={selectedDestinationParcelId === -1}
              >
                <ListItemText primary="Skip" />
              </ListItemButton>

              {Object.keys(destinationParcelsByName).map((groupName) => {
                const groupParcels = destinationParcelsByName[groupName] ?? {}
                const open = expandedGroup === groupName
                if (Object.keys(groupParcels).length === 0) {
                  return null
                }

                return (
                  <React.Fragment key={groupName}>
                    <ListItemButton
                      divider
                      onClick={() => handleExpandGroup(groupName)}
                    >
                      <ListItemText primary={groupName} />
                      {open ? (
                        <Icon>expand_less</Icon>
                      ) : (
                        <Icon>expand_more</Icon>
                      )}
                    </ListItemButton>

                    <Collapse in={open} timeout={0} unmountOnExit>
                      {Object.values(groupParcels).map((destinationParcel) => {
                        return (
                          <ListItemButton
                            divider
                            key={destinationParcel.parcel.id}
                            onClick={() =>
                              setSelectedDestinationParcelId(
                                destinationParcel.parcel.id
                              )
                            }
                            selected={
                              selectedDestinationParcelId ===
                              destinationParcel.parcel.id
                            }
                          >
                            <ListItemText
                              primary={destinationParcel.parcel.name}
                            />
                          </ListItemButton>
                        )
                      })}
                    </Collapse>
                  </React.Fragment>
                )
              })}
            </List>
          </Stack>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1} justifyContent="stretch">
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={selectedDestinationParcelId === undefined}
            color="primary"
            onClick={handleSubmit}
          >
            Choose
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
