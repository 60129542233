import * as api from '../../../graphql/api'
import { Variable } from '../../../graphql/types'

export const updateVariableByKey = async (key: string, update: Variable) => {
  const choice = await sweetAlert(
    `Are you sure you want to update this Variable?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Update',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (!choice) {
    return
  }

  return await api.variables.update<{ key: string }>({
    pk: { key },
    input: update,
  })
}
