import { createSelector } from 'reselect'

import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { JobStatus } from '../../../graphql/types'
import { RootStore } from '../../../redux/types'
import filterTruthy from '../../../util/filterTruthy'
import {
  indexArrayMappedKey,
  indexMultiArray,
  indexMultiArrayAsSet,
  indexMultiArrayMappedKeyAsSet,
} from '../../../util/indexArray'
import { ListDeliveryParcelFileStatus } from './DeliveryParcelFileStatus/types'

const list = ({
  limit,
  offset,
  order_by,
  where,
}: {
  limit?: number
  offset?: number
  order_by?: string
  where?: any
}) =>
  api.deliveryParcelFile.list<ListDeliveryParcelFileStatus>({
    limit,
    offset,
    order_by,
    where,
    returning: `{
      deliveryId
      parcelId
      flightDate
      filename      
      uploadedAt
      statusUpdatedAt
      jobId

      Organization {
        name
      }

      Delivery {
        id
      }

      DeliveryParcel {
        name
        procGroup:meta(path: "procGroup")
        DeliveryGroup {
          name
        }
      }

      Flight {
        date
      }

      Job {
        id
        isPending
        Status {
          status
        }        
      }

      CreatedBy {
        firstName
      }

      UpdatedBy {
        firstName
      }
    }`,
  })

const {
  selector: selectListDeliveryParcelFileStatusForDelivery,
  refresh: refreshListDeliveryParcelFileStatusForDelivery,
} = createAsyncSelector({
  resource: 'ListDeliveryParcelFileStatusForDelivery',
  inputs: {
    deliveryId: (state: RootStore) => state.router.params.deliveryId,
  },
  fetcher: async ({ deliveryId }) => {
    if (!deliveryId) {
      return
    }

    const { data } = await list({
      where: { deliveryId: { _eq: deliveryId } },
    })

    if (!data) {
      throw new Error('No Data')
    }

    return {
      data,
    }
  },
})

export {
  selectListDeliveryParcelFileStatusForDelivery,
  refreshListDeliveryParcelFileStatusForDelivery,
}

const EMPTY_DELIVERY_PARCEL_FILE_STATUSES: ListDeliveryParcelFileStatus[] = []

export interface WithStatus {
  status: JobStatus['status']
}

const addStatus = (
  dpf: ListDeliveryParcelFileStatus
): ListDeliveryParcelFileStatus & WithStatus => ({
  ...dpf,
  status: (dpf.Job
    ? dpf.Job.Status.status
    : 'complete') as WithStatus['status'],
})

const getStatusFilter = (state: RootStore) =>
  new Set(state.router.searchParams.status?.split(',') ?? [])

export const selectDeliveryParcelFileStatusesForDelivery = createSelector(
  selectListDeliveryParcelFileStatusForDelivery,
  getStatusFilter,
  (listDeliveryParcelFileStatus, statuses) => {
    const deliveryParcelFiles = (
      listDeliveryParcelFileStatus.data?.data ??
      EMPTY_DELIVERY_PARCEL_FILE_STATUSES
    )
      .filter(filterTruthy)
      .map(addStatus)

    if (statuses.size > 0) {
      return deliveryParcelFiles.filter((dpf) => statuses.has(dpf.status))
    }

    return deliveryParcelFiles
  }
)

export const selectDeliveryParcelFileStatusesByParcelForDelivery =
  createSelector(
    selectDeliveryParcelFileStatusesForDelivery,
    (deliveryParcelFileStatus) =>
      indexMultiArray(deliveryParcelFileStatus, 'parcelId')
  )

export const selectDeliveryParcelFileStatusesByKeyForDelivery = createSelector(
  selectDeliveryParcelFileStatusesForDelivery,
  (deliveryParcelFileStatus) =>
    indexArrayMappedKey(deliveryParcelFileStatus, getDeliveryParcelFileKey)
)

export const countStatuses = (dpfs: ListDeliveryParcelFileStatus[]) => ({
  ...dpfs.reduce((counts, { Job }) => {
    const status = Job?.Status?.status ?? 'complete'

    counts[status] = (counts[status] ?? 0) + 1

    return counts
  }, {} as Record<string, number>),
  total: dpfs.length,
})

export interface Parcel {
  files: ListDeliveryParcelFileStatus[]
  statusCounts: Record<string, number>
}
export interface ProcGroup {
  parcels: Record<string | number, Parcel>
  statusCounts: Record<string, number>
}

export interface Group {
  procGroups: Record<string, ProcGroup>
  statusCounts: Record<string, number>
}

export const selectDeliveryParcelFileStatusesByProcGroupForDelivery =
  createSelector(
    selectDeliveryParcelFileStatusesByParcelForDelivery,
    (deliveryParcelFileStatus) =>
      Object.entries(deliveryParcelFileStatus).reduce(
        (procGroups, [parcelId, files]) => {
          const procGroup = files[0].DeliveryParcel.procGroup
          const parcelStatuses = countStatuses(files)
          procGroups[procGroup] = {
            ...(procGroups[procGroup] ?? {}),
            parcels: {
              ...(procGroups[procGroup]?.parcels ?? {}),
              [parcelId]: {
                files,
                statusCounts: parcelStatuses,
              },
            },
            statusCounts: {
              ...(procGroups[procGroup]?.statusCounts
                ? addKeys(procGroups[procGroup].statusCounts, parcelStatuses)
                : parcelStatuses),
            },
          }

          return procGroups
        },
        {} as Record<string, ProcGroup>
      )
  )

const addKeys = (a: Record<string, number>, b: Record<string, number>) => {
  const allKeys = new Set([...Object.keys(a), ...Object.keys(b)])
  const res: Record<string, number> = {}
  for (const key of Array.from(allKeys)) {
    res[key] = (a[key] ?? 0) + (b[key] ?? 0)
  }

  return res
}

export const selectDeliveryParcelFileStatusesByGroupForDelivery =
  createSelector(
    selectDeliveryParcelFileStatusesByProcGroupForDelivery,
    (deliveryParcelFileStatus) =>
      Object.entries(deliveryParcelFileStatus).reduce(
        (groups, [procGroup, { parcels, statusCounts }]) => {
          const groupKey =
            Object.values(parcels)[0].files[0].DeliveryParcel.DeliveryGroup.name

          groups[groupKey] = {
            ...(groups[groupKey] ?? {}),
            procGroups: {
              ...(groups[groupKey]?.procGroups ?? {}),
              [procGroup]: {
                parcels,
                statusCounts,
              },
            },
            statusCounts: {
              ...(groups[groupKey]?.statusCounts
                ? addKeys(groups[groupKey].statusCounts, statusCounts)
                : statusCounts),
            },
          }

          return groups
        },
        {} as Record<string | number, Group>
      )
  )

export const getDeliveryParcelFileKey = (
  deliveryParcelFile: ListDeliveryParcelFileStatus
) => {
  return `${deliveryParcelFile.parcelId}_${deliveryParcelFile.filename}`
}

export const selectDeliveryParcelFilesByGroup = createSelector(
  selectDeliveryParcelFileStatusesForDelivery,
  (deliveryParcelFileStatuses) => {
    return indexMultiArrayMappedKeyAsSet(
      deliveryParcelFileStatuses,
      (dpfs) => dpfs.DeliveryParcel.DeliveryGroup.name,
      getDeliveryParcelFileKey
    )
  }
)

export const selectDeliveryParcelFilesByProcGroup = createSelector(
  selectDeliveryParcelFileStatusesForDelivery,
  (deliveryParcelFileStatuses) => {
    return indexMultiArrayMappedKeyAsSet(
      deliveryParcelFileStatuses,
      (dpfs) => dpfs.DeliveryParcel.procGroup,
      getDeliveryParcelFileKey
    )
  }
)

export const selectDeliveryParcelFilesByParcelId = createSelector(
  selectDeliveryParcelFileStatusesForDelivery,
  (deliveryParcelFileStatuses) => {
    const filesByParcelId = indexMultiArrayAsSet(
      deliveryParcelFileStatuses,
      'parcelId',
      getDeliveryParcelFileKey
    )

    return filesByParcelId
  }
)
