/**
 * @param obj Any Object.
 * @param keys The keys you wish to include in the returned object
 *
 * @returns A new object which contains the properties from `obj` specified by `keys.
 */
export const objectPick = <T extends {}, K extends keyof T>(
  obj: T,
  keys: K[]
) =>
  (Object.keys(obj) as K[])
    .filter((a) => keys.includes(a))
    .reduce((a, b) => {
      a[b] = obj[b]

      return a
    }, {} as Pick<T, K>)
