import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import * as React from "react"
import AsyncSelectorStatusOverlay from "../../AsyncSelector/AsyncSelectorStatusOverlay"
import { client, gql } from "../../graphql/client"
import { useRedux } from "../../hooks/useRedux"
import { selectListUngroupedMapLayerDefs, refreshListUngroupedMapLayerDefs } from "./selectListUngroupedMapLayerDefs"


interface Props {
  mapLayerDefGroupId: string
  open: boolean
  onClose: () => void
}

export const AddMapLayerDefToMapLayerDefGroupDialog = ({ mapLayerDefGroupId, open, onClose }: Props) => {

  const [state] = useRedux()
  const listUngroupedMapLayerDefs = selectListUngroupedMapLayerDefs(state)

  const [selectedMapLayerDefIds, setSelectedMapLayerDefIds] = React.useState(new Set<string>())

  const handleSubmit = async () => {
    await client.request({
      query: gql`
        mutation ADD_MAP_LAYER_DEF_TO_GROUP($mapLayerDefIds:[uuid!]!, $mapLayerDefGroupId:uuid!) {
          update_MapLayerDef(where:{id:{_in:$mapLayerDefIds}}, _set:{mapLayerDefGroupId:$mapLayerDefGroupId}){
            affected_rows
          }
        }
      `,
      variables: { mapLayerDefGroupId, mapLayerDefIds: Array.from(selectedMapLayerDefIds) }
    })

    setSelectedMapLayerDefIds(new Set())
    onClose()
    refreshListUngroupedMapLayerDefs()
  }

  const handleCancel = () => {
    setSelectedMapLayerDefIds(new Set())
    onClose()
  }

  const handleToggle = (mapLayerDefId: string) => {
    const newSelectedMapLayerDefIds = new Set(selectedMapLayerDefIds)

    if (newSelectedMapLayerDefIds.has(mapLayerDefId)) {
      newSelectedMapLayerDefIds.delete(mapLayerDefId)
    }
    else {
      newSelectedMapLayerDefIds.add(mapLayerDefId)
    }

    setSelectedMapLayerDefIds(newSelectedMapLayerDefIds)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Add MapLayerDef to MapLayerDefGroup</DialogTitle>
      <DialogContent>
        <AsyncSelectorStatusOverlay requests={listUngroupedMapLayerDefs}>
          <List>
            {listUngroupedMapLayerDefs.data?.data.map(mapLayerDef => (
              <ListItem key={mapLayerDef.id}>
                <ListItemButton role={undefined} onClick={() => handleToggle(mapLayerDef.id)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedMapLayerDefIds.has(mapLayerDef.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': `map-layer-${mapLayerDef.id}` }}
                    />
                  </ListItemIcon>
                  <ListItemText id={`map-layer-${mapLayerDef.id}`} primary={mapLayerDef.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </AsyncSelectorStatusOverlay>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleCancel}>Cancel</Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}