import * as api from '../../../graphql/api'
import { AdminRole } from '../../../graphql/types'
import { createTableFetcher } from '../../../UI/Table/createTableFetcher'
import { tableFormatter } from './adminsTableFormatters'

export const fetchAdminRoles = createTableFetcher<AdminRole>(
  tableFormatter,
  `{
    userId
    role

    User {
      firstName
      lastName
      email
    }
  }`,
  api.adminRoles.list
)
