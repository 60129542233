import * as React from 'react'
import {
  FormControl,
  FormHelperText,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import i18n, { keys } from '../../../../../i18n'
import { LoadingControlBackdrop } from '../../../../../UI/Loading/LoadingControlBackdrop'
import { fetchOrganizationNoteFormTemplates } from '../../../queries'
import { selectOrganizationId } from '../../../../../data/selectOrganizationId'
import useAsync from '../../../../../hooks/useAsync'
import { useRedux } from '../../../../../hooks/useRedux'
import { SamplePlanNewFormProps } from '../types/SamplePlanFormProps'
import * as noteForms from '../../../../../noteForms/redux'

type Ref = HTMLDivElement

const characterLimit = 500

const reservedTemplateExcludeIds = [
  '00000000-0000-0000-0000-000000000004',
]

const SamplePlanFormTemplateForm = ({
  currentNewSamplingPlan,
  setCurrentNewSamplingPlan,
  forwardedRef,
  registerValidation,
  invalidateCompletedSamplingPlan,
  stage,
}: SamplePlanNewFormProps<Ref>) => {
  const [state, dispatch] = useRedux()
  const organizationId = selectOrganizationId(state)
  const [noteTemplateForms, refreshNoteTemplateForms] = useAsync(
    fetchOrganizationNoteFormTemplates,
    [organizationId]
  )

  const filteredNoteTemplateForms = React.useMemo(() => {
    const filteredTemplates =
      noteTemplateForms.result?.data.map((ntf) => {
        return {
          ...ntf
        }
      }).filter(ntf => !reservedTemplateExcludeIds.includes(ntf.id)) ?? []

    return filteredTemplates
  }, [noteTemplateForms.result?.data])

  const onNoteFormChanged = async (e: SelectChangeEvent<string>) => {
    setCurrentNewSamplingPlan({
      ...currentNewSamplingPlan,
      NoteForm: noteTemplateForms.result?.data.find(
        (ntf) => ntf.id === e.target.value
      ),
      noteFormTemplateId: e.target.value,
    })
    await invalidateCompletedSamplingPlan()
  }

  const onClickAddNoteForm = () => {
    dispatch(noteForms.actions.toggleIsManageFormsVisible(null))
  }

  const onOpenNoteFormSelect = () => {
    refreshNoteTemplateForms()
  }

  const onInstructionsChanged = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCurrentNewSamplingPlan({
      ...currentNewSamplingPlan,
      projectInstructions: e.target.value,
    })
    await invalidateCompletedSamplingPlan()
  }

  React.useEffect(() => {
    registerValidation(() => {
      return (
        !!currentNewSamplingPlan?.noteFormTemplateId &&
        currentNewSamplingPlan.noteFormTemplateId.length > 0 &&
        (currentNewSamplingPlan?.projectInstructions?.length ?? 0) <=
          characterLimit
      )
    }, stage)
  }, [registerValidation, stage, currentNewSamplingPlan])

  return (
    <div className="sample-plan-new-stage-form" ref={forwardedRef}>
      <LoadingControlBackdrop open={noteTemplateForms.status !== 'resolved'}>
        <Stack
          className="sample-plan-new-stage-form"
          direction="column"
          spacing={2}
          ref={forwardedRef}
        >
          {/* Note Form Template */}
          <Typography variant="body1" fontWeight={600} fontSize={16}>
            {i18n.t(keys.samplePlanNewSelectFormTemplateLabel)}
          </Typography>
          <FormControl required size="small">
            <Select
              variant="outlined"
              name="noteFormTemplates"
              displayEmpty
              value={currentNewSamplingPlan?.noteFormTemplateId ?? ''}
              required
              size="small"
              onOpen={onOpenNoteFormSelect}
              onChange={onNoteFormChanged}
            >
              <MenuItem disabled value="">
                <em style={{ color: 'grey' }}>
                  {i18n.t(keys.samplePlanNewFormTemplatePlaceholderLabel)}
                </em>
              </MenuItem>
              {filteredNoteTemplateForms?.map((form, i) => (
                <MenuItem key={form.id} value={form.id}>
                  {i18n.t(`noteForm.reservedNames.${form.name}`, {defaultValue: form.name})}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* New Note Form */}
          <Link
            component="button"
            onClick={onClickAddNoteForm}
            underline="hover"
            style={{ textAlign: 'start' }}
          >
            {i18n.t(keys.samplePlanNewCreateFormTemplateLabel)}
          </Link>

          {/* Propject Instructions */}
          <Typography variant="body1" fontWeight={600} fontSize={16}>
            {i18n.t(keys.samplePlanNewProjectInstructionsLabel)}
          </Typography>
          <FormControl
            error={
              (currentNewSamplingPlan?.projectInstructions?.length ?? 0) >
              characterLimit
            }
          >
            <TextField
              variant="outlined"
              size="small"
              rows={5}
              placeholder={i18n.t(
                keys.samplePlanProjectInstructionsPlaceholder
              )}
              value={currentNewSamplingPlan?.projectInstructions ?? ''}
              multiline
              error={
                (currentNewSamplingPlan?.projectInstructions?.length ?? 0) >
                characterLimit
              }
              onChange={onInstructionsChanged}
            />
            <FormHelperText>{`${
              currentNewSamplingPlan?.projectInstructions?.length ?? '-'
            }/${characterLimit}`}</FormHelperText>
          </FormControl>
        </Stack>
      </LoadingControlBackdrop>
    </div>
  )
}

export default React.forwardRef<Ref, SamplePlanNewFormProps<Ref>>(
  (props, ref) => <SamplePlanFormTemplateForm {...props} forwardedRef={ref} />
)
