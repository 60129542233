import './ClassInputList.scss'

import * as React from 'react'

import { default as FormControl } from '@mui/material/FormControl'
import { default as Icon } from '@mui/material/Icon'
import { default as Input } from '@mui/material/Input'
import { default as InputLabel } from '@mui/material/InputLabel'
import { default as Typography } from '@mui/material/Typography'

import { MapSourcePropertyClass, Translated } from '../../../graphql/types'
import TooltipIconButton from '../../../UI/TooltipIconButton'
import TranslationTextField from '../../../UI/TranslationTextField/TranslationTextField'
import coalesce from '../../../util/coalesce'

type MapSourcePropertyClassData = Removes<
  MapSourcePropertyClass,
  string,
  'translation'
>

interface NameAndValue {
  name: string
  value: MapSourcePropertyClassData[]
}

interface Props extends NameAndValue {
  label: string
  onChange(e: NameAndValue): void
}

class ClassInputList extends React.PureComponent<Props> {
  render() {
    const { label, value } = this.props

    const values =
      value?.length > 0 ? value : [{} as MapSourcePropertyClassData]

    return (
      <div className="ClassInputList">
        <div className="ClassInputList__title">
          <Typography>{label}</Typography>
          <TooltipIconButton
            tooltipProps={{
              placement: 'right'
            }}
            title={`Add a class`}
            onClick={this.handleAdd}
          >
            <Icon fontSize="small">add_circle</Icon>
          </TooltipIconButton>
        </div>

        <div className="ClassInputList__content">
          {values.map(this.renderValueAndTranslation)}
        </div>
      </div>
    )
  }

  renderValueAndTranslation = (
    valueAndTranslation: MapSourcePropertyClassData,
    i: number
  ) => {
    const { length } = this.props.value!

    return (
      <div key={i} className="ClassInputList__value_section">
        <FormControl fullWidth>
          <InputLabel>Value</InputLabel>
          <Input
            onChange={(e) => this.onChangeValue(i, e.target.value)}
            value={coalesce(valueAndTranslation.value, '')}
            autoComplete="off"
            endAdornment={
              length > 1 && (
                <TooltipIconButton
                  tooltipProps={{
                    placement: 'right'
                  }}
                  title={`Remove `}
                  onClick={() => this.handleRemove(i)}
                >
                  <Icon fontSize="small">close</Icon>
                </TooltipIconButton>
              )
            }
          />
        </FormControl>
        <TranslationTextField
          label={`Name`}
          value={valueAndTranslation.translation ?? {}}
          onChange={(e) => this.onChangeTranslation(i, e.value)}
        />
      </div>
    )
  }

  handleAdd = () => {
    const { onChange, name, value } = this.props
    const newValue = [...(value ?? []), {}] as MapSourcePropertyClassData[]

    onChange({
      name,
      value: newValue,
    })
  }

  handleRemove = (i: number) => {
    const { onChange, name, value } = this.props
    const newValue = [...(value ?? [])]
    newValue.splice(i, 1)

    onChange({
      name,
      value: newValue,
    })
  }

  onChangeValue = (i: number, val: string | number) =>
    this.setValue('value', i, val)

  onChangeTranslation = (i: number, translation?: Translated | null) =>
    this.setValue('translation', i, translation)

  setValue = (
    key: keyof MapSourcePropertyClassData,
    i: number,
    val?: Translated | string | number | null
  ) => {
    const { onChange, name } = this.props
    let { value } = this.props

    if (value.length === 0) {
      value = [{}] as MapSourcePropertyClassData[]
    }

    const newValue = [...value]
    newValue[i][key] = val as any

    onChange({
      name,
      value: newValue,
    })
  }
}

export default ClassInputList
