import * as React from 'react'
import { Table, TableProps } from '../../../UI/Table/Table'
import { actions as tableActions } from './actions'
import { tableFormatter } from './formatters'
import { Job } from './types'

interface State {
  selection: Job[]
}

interface MaestroJobTableProps
  extends SelectivePartial<
    TableProps<Job>,
    'title' | 'actions' | 'formatter' | 'selection' | 'onSelectionChange'
  > {
  selectable?: boolean
  onRefresh?: () => void
}

export default class MaestroJobTable extends React.PureComponent<
  MaestroJobTableProps,
  State
> {
  state: State = {
    selection: [],
  }

  handleSelectionChange = (selection: State['selection']) =>
    this.setState({ selection })

  render() {
    const {
      onRefresh,
      title = 'Delivery Maestro Jobs',
      actions = [],
      ...tableProps
    } = this.props

    if (onRefresh) {
      actions.unshift(tableActions.refresh(onRefresh))
    }

    return (
      <Table
        stickyOffset={48}
        title={title}
        actions={actions}
        formatter={tableFormatter}
        {...tableProps}
      />
    )
  }
}
