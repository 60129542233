export default function fuzzySearch(needle: string, haystack: string) {
  // tslint:disable:no-parameter-reassignment
  needle = needle.toLowerCase()
  haystack = haystack.toLowerCase()
  // tslint:enable:no-parameter-reassignment

  const lenHaystack = haystack.length
  const lenNeedle = needle.length

  if (lenNeedle > lenHaystack) {
    return false
  }

  if (lenNeedle === lenHaystack) {
    return needle === haystack
  }

  outer: for (let i = 0, j = 0; i < lenNeedle; i += 1) {
    const nch = needle.codePointAt(i)
    while (j < lenHaystack) {
      // tslint:disable-next-line:no-increment-decrement
      if (haystack.codePointAt(j++) === nch) {
        continue outer
      }
    }

    return false
  }

  return true
}
