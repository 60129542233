import { createGQLResource } from './createGQLResource'
import { Order, Order_create_input, Order_update_input } from './types'

const resource = createGQLResource<
  Order,
  Order_create_input,
  Order_update_input,
  'id'
>('Order', { id: 'uuid' })

export const order = {
  ...resource,
}
