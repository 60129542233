import { client, gql } from '../graphql/client'
import i18n from '../i18n'
import { downloadApiUrl } from '../vvapi/apiResource/createApiResource'
import { Download } from './types'

const logDownload = async (variables: { downloadId: string, userId: string }) => {
  await client.request({
    query: gql`mutation ADD_DOWNLOAD_LOG($downloadId:uuid!, $userId:Int!) {
      insert_DownloadLog_one(object:{downloadId: $downloadId, userId: $userId, type: "downloaded"}) {
        id
      }
    }`,
    variables
  })
}

export const downloadFile = async (downloadId: string, file: Download['file'], filename: string, userId: string) => {
  await downloadApiUrl(`/api/v3/download/${downloadId}?downloadToken=${file!.downloadToken}`, i18n.t(`filenames.${filename}`, { defaultValue: filename }))

  await logDownload({ downloadId, userId })
} 
