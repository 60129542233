import * as React from 'react'

import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material'

interface Props {
  title: string
  children: IconButtonProps['children']
  onClick?: IconButtonProps['onClick']
  disabled?: IconButtonProps['disabled']
  size?: IconButtonProps['size']
  iconButtonProps?: Omit<IconButtonProps, 'children' | 'onClick' | 'disabled'>
  tooltipProps?: Omit<
    TooltipProps,
    'children' | 'title' | 'disabled' | 'style' | 'hide'
  >
  iconButtonStyle?: React.CSSProperties
  color?: IconButtonProps['color']
}

/**
 * Tooltips over IconButtons are glitchy; this class adds a default
 * PopperProps to offset the default tooltip position
 */
class TooltipIconButton extends React.PureComponent<Props> {
  render() {
    const {
      title,
      children,
      onClick,
      disabled,
      size,
      iconButtonProps = {},
      tooltipProps = { placement: 'top', disableInteractive: true },
      iconButtonStyle,
      color,
    } = this.props

    return (
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <Tooltip title={title} {...tooltipProps}>
          <span>
            <IconButton
              color={color}
              disabled={disabled}
              onClick={onClick}
              {...iconButtonProps}
              style={iconButtonStyle}
              size={size}
            >
              {children}
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }
}

export default TooltipIconButton
