import * as React from 'react'
import { PureComponent } from 'react'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import i18n, { keys } from '../../i18n'
import { selectSelectedProduct } from '../../postgis/selectSelectedProduct'
import { selectQuantileStops } from '../../ProductStats/selectors/selectProductStats'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { Slider } from '../../UI/Slider'
import { throttle } from '../../util/throttle-debounce'
import { updateProductSettings } from '../store/redux'
import { selectActiveProductSettings } from '../store/selectors/selectActiveProductSettings'
import { ProductSettings } from '../store/types'

class Grouping extends PureComponent<AppDispatchProps & ReduxProps> {
  handleChangeNumberOfGroups = throttle(
    { delay: 100 },
    (numberOfGroups: number) => {
      const { dispatch, productId } = this.props

      dispatch(
        updateProductSettings({ productId, settings: { numberOfGroups } })
      )
    }
  )

  handleChangeGroupBy = (event: SelectChangeEvent) => {
    const { dispatch, productId } = this.props

    const groupBy = event.target.value as ProductSettings['groupBy']

    dispatch(
      updateProductSettings({
        productId,
        settings: { groupBy },
      })
    )
  }
  render() {
    const { settings, quantileSupported } = this.props

    if (!settings) {
      return null
    }

    const { numberOfGroups, groupBy, visualization } = settings

    if (visualization !== 'relative') {
      return null
    }

    const none = numberOfGroups === undefined || numberOfGroups < 2

    return (
      <React.Fragment>
        <Typography variant="subtitle1">
          {i18n.t(keys.visualization.numberOfGroups)}:{' '}
          {none ? i18n.t(keys.generic.none) : numberOfGroups}
        </Typography>
        <Slider
          min={1}
          max={10}
          step={1}
          style={{ flex: 1 }}
          value={numberOfGroups ?? 1}
          onChange={this.handleChangeNumberOfGroups}
        />
        <br />
        <Typography variant="subtitle1">
          {i18n.t(keys.visualization.groupBy)}
        </Typography>
        <Select
          value={groupBy}
          onChange={this.handleChangeGroupBy}
          fullWidth
          disabled={none}
        >
          <MenuItem value="equal-areas" disabled={!quantileSupported}>
            {i18n.t(keys.visualization.equalAreas)}
          </MenuItem>
          <MenuItem value="value-ranges">
            {i18n.t(keys.visualization.valueRanges)}
          </MenuItem>
        </Select>
      </React.Fragment>
    )
  }
}

const mapState = (state: RootStore) => {
  const product = selectSelectedProduct(state)
  let quantileSupported = false
  let productId: string | undefined

  if (product) {
    const quantileStopsByProductId = selectQuantileStops(state)
    quantileSupported = !!quantileStopsByProductId[product.id]
    productId = product.id
  }

  return {
    productId,
    quantileSupported,
    settings: selectActiveProductSettings(state),
  }
}
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(Grouping)
