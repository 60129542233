import * as React from 'react'
import { default as FormControl } from '@mui/material/FormControl'
import { default as FormLabel } from '@mui/material/FormLabel'
import { default as MenuItem } from '@mui/material/MenuItem'
import { default as Select, SelectChangeEvent } from '@mui/material/Select'
import { Model } from '../../graphql/types'
import { NameAndValue } from './types'

interface Props<T extends Model> {
  name: string
  value?: T['id']
  label: string
  required?: boolean
  disabled?: boolean
  query(): Promise<T[]>
  display(value: T): string
  onChange(nameAndValue: NameAndValue): void
}

interface State<T extends Model> {
  objects?: T[]
}

/**
 * Renders a select drop-down for a related model, typically with an id value
 * and a display value
 */
class RelatedModelSelect<T extends Model> extends React.PureComponent<
  Props<T>,
  State<T>
> {
  state: State<T> = {}

  async componentDidMount() {
    const objects = await this.props.query()

    this.setState({
      objects,
    })
  }

  render() {
    const { objects = [] } = this.state
    const {
      display,
      value,
      label,
      required = false,
      disabled = false,
    } = this.props

    return (
      <FormControl>
        <FormLabel required={required}>{label}</FormLabel>
        <Select
          fullWidth
          value={value}
          onChange={this.onChange}
          disabled={disabled}
        >
          <MenuItem key={'none'} value={null as any}>
            None
          </MenuItem>
          {objects.map((obj) => (
            <MenuItem key={obj.id} value={obj.id}>
              {display(obj)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  onChange = (e: SelectChangeEvent) => {
    const { name, onChange } = this.props

    onChange({
      name,
      value: e.target.value || undefined,
    })
  }
}

export default RelatedModelSelect
