import * as React from 'react'
import { TextField, Typography } from '@mui/material'
import { Package } from '../../graphql/types'
import i18n from '../../i18n'
import TranslationTextField from '../../UI/TranslationTextField/TranslationTextField'
import { Form } from '../UI/Form'
import { EditPackageData } from './selectEditPackage'

export type PackageData = Removes<Package, string, 'name' | 'description'>

class PackageForm extends Form<EditPackageData['package']> {
  render() {
    const {
      CreatedBy,
      UpdatedBy,
      createdAt,
      updatedAt,
      name = {},
      description = {},
      code = '',
    } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <h2>{this.props.type === 'edit' ? 'Edit' : 'New'} Package</h2>
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            required
            name="name"
            label={`Name`}
            value={name}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            name="description"
            multiline
            label={`Description`}
            value={description}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            required
            name="code"
            label={`Code`}
            fullWidth
            value={code}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!code,
            }}
          />
        </div>
        {CreatedBy && UpdatedBy && (
          <div className="grid-xs-12">
            <Typography variant="subtitle1">
              Created By: {CreatedBy.firstName} - {i18n.toDateShort(createdAt!)}
            </Typography>
            <Typography variant="subtitle1">
              Updated By: {UpdatedBy.firstName} -{' '}
              {i18n.toDateTimeShort(updatedAt!)}
            </Typography>
          </div>
        )}
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }
}

export default PackageForm
