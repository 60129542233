import './FocusedPolygonPopup.scss'

import * as React from 'react'

import { Grid } from '@mui/material'
import centroid from '@turf/centroid'
import envelope from '@turf/envelope'
import { MapSourcePropertyData } from '../../data/types'
import i18n, { keys } from '../../i18n'
import * as actions from '../../postgis/actions'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { formatNum } from '../../util/formatNumber'
import area, { AreaUnit, convertArea } from '../../util/units/area'
import length, { convertLength, LengthUnit } from '../../util/units/length'
import MapPopup from './MapPopup'
import { selectFocusedPolygonMapSourcesProperties } from '../../data/selectFocusedMapSourcesProperties'

class FocusedPolygonPopup extends React.PureComponent<
  ReduxProps & AppDispatchProps
> {
  render() {
    const { focusedPolygon, properties } = this.props
    let geometry = focusedPolygon
      ? focusedPolygon.geometry || focusedPolygon._geometry
      : undefined
    const open = !!focusedPolygon && !!geometry && properties.length > 0
    // see if we can render any of the properties
    const renderedProperties = open ? properties.map(this.renderProperty) : []

    if (geometry?.type === 'MultiPolygon') {
      const envelopeFeature = envelope(geometry)
      if (!envelopeFeature || !envelopeFeature.geometry) {
        return
      }

      geometry = envelopeFeature.geometry
    }
    
    const clickPoint = focusedPolygon?.clickCoordinates ? focusedPolygon?.clickCoordinates : undefined
    const point =
      geometry &&
      centroid({ type: 'Feature', geometry, properties: {} }).geometry
        .coordinates    
    
    return (
      <MapPopup coordinates={clickPoint ? clickPoint : point} open={open} onClose={this.onClose}>
        <Grid
          className="FocusedPolygonPopup"
          container
          direction="column"
          justifyContent="center"
          style={{
            textAlign: 'center',
          }}
        >
          {renderedProperties}
          <Grid item>
            <span className="btn" onClick={this.handleClose}>
              {i18n.t(keys.generic.close)}
            </span>
          </Grid>
        </Grid>
      </MapPopup>
    )
  }

  /**
   * render key -> value for each property in popup
   */
  renderProperty = (property: MapSourcePropertyData) => {
    const { preferredUnitSystem } = this.props
    // must be defined if we're rendering
    const focusedPolygon = this.props.focusedPolygon!
    // geojson properties has the property values
    const { properties } = focusedPolygon
    const value: string | number = properties[property.property]
    const { valueType, valueUnit } = property
    if (value === undefined || !valueType) {
      // property not found in geojson
      return null
    }

    if ((valueType === 'area' || valueType === 'length') && !valueUnit) {
      return null
    }

    let output: typeof value | undefined
    let measurement:
      | ReturnType<typeof convertArea>
      | ReturnType<typeof convertLength>
      | undefined

    if (valueType === 'index') {
      output = formatNum(value)
    } else if (valueType === 'text') {
      output = value
    } else if (valueType === 'area' && isAreaUnit(valueUnit!)) {
      // for area and length values, convert to meter or feet
      const bestUnit = area.bestUnit(
        Number(value),
        area.units[valueUnit as AreaUnit],
        valueUnit,
        area.unitsBySystem[preferredUnitSystem]
      )

      measurement = area.convert(
        Number(value),
        area.units[valueUnit as AreaUnit],
        area.units[bestUnit.unit as AreaUnit],
        bestUnit.unit!
      )
    } else if (valueType === 'length' && isLengthUnit(valueUnit!)) {
      const bestUnit = length.bestUnit(
        Number(value),
        length.units[valueUnit as LengthUnit],
        valueUnit,
        length.unitsBySystem[preferredUnitSystem]
      )

      measurement = length.convert(
        Number(value),
        length.units[valueUnit as LengthUnit],
        length.units[bestUnit.unit as LengthUnit],
        bestUnit.unit!
      )
    }

    // format the output based on the conversion
    if (measurement) {
      output = `${formatNum(measurement.value, false, 1, 1)} ${
        measurement.symbol
      }`
    }

    if (!output) {
      return null
    }

    return (
      <Grid item key={property.name}>
        <h1>{output}</h1>
        <span>{property.acronym || property.name}</span>
      </Grid>
    )
  }

  handleClose = () => {
    this.props.dispatch(actions.setFocusedPolygon(null))
  }

  onClose = () => false
}

const isAreaUnit = (unit: string): unit is AreaUnit => !!area.units[unit]

const isLengthUnit = (unit: string): unit is LengthUnit => !!length.units[unit]

const mapState = (state: RootStore) => ({
  focusedPolygon: state.postgis.focusedPolygon,
  preferredUnitSystem: state.preferences.preferredUnitSystem,
  properties: selectFocusedPolygonMapSourcesProperties(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  FocusedPolygonPopup
)
