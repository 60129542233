import Typography from '@mui/material/Typography'
import * as React from 'react'
import { selectOrganization } from '../../data/selectOrganization'
import { ParcelData } from '../../data/types'
import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import getParcelArea from '../../postgis/getParcelArea'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { formatNum } from '../../util/formatNumber'
import { indexArray } from '../../util/indexArray'
import { convertArea } from '../../util/units/area'
import { ParcelMeta } from '../../vvapi/models'
import { selectReportGroup } from '../selectReportGroup'
import { selectReportStats } from '../selectReportStats'
import { ProductPage } from '../types'
import './FlightInformation.scss'

interface Props {
  product: ProductPage['product']
  title?: string
  parcel: ParcelData | null
}

class FlightInformation extends React.PureComponent<
  Props & ReduxProps & AppDispatchProps
> {
  render() {
    const { organization, group, date, parcel } = this.props

    if (!organization || !group || !date) {
      return null
    }

    const title = parcel
      ? i18n.t(keys.reports.blockInformation, {
        parcel: parcel.name,
      })
      : i18n.t(keys.reports.flightInformation)

    return (
      <section className="FlightInformation">
        <div>
          <Typography className="FlightInformation__title">{title}</Typography>
          <div className="FlightInformation__description">
            {parcel ? this.renderBlockDescription() : this.renderDescription()}
          </div>
        </div>
      </section>
    )
  }

  renderDescription = () => {
    const { title, organization, group, date } = this.props

    return (
      <React.Fragment>
        <Typography>{i18n.t(keys.reports.dataProduct)}</Typography>
        <Typography>{title}</Typography>

        <Typography>{i18n.t(keys.reports.client)}</Typography>
        <Typography>{organization!.name}</Typography>

        <Typography>{i18n.t(keys.reports.ranch)}</Typography>
        <Typography>{group!.name}</Typography>

        <Typography>{i18n.t(keys.reports.flightDate)}</Typography>
        <Typography>{date}</Typography>
      </React.Fragment>
    )
  }

  renderBlockDescription = () => {
    const { parcel, preferredUnitSystem, product } = this.props

    const meta = this.getMeta()

    if (!meta) {
      return null
    }

    // get properties from product
    const {
      mapSourceDef: { properties = [] },
    } = product

    const propertiesByProperty: Record<string, { name?: string }> = indexArray(
      properties.filter(
        (property) => property.type === 'value' && property.property in meta
      ),
      'property'
    )

    const stats = this.getStats()

    for (const statKey of Object.keys(stats)) {
      propertiesByProperty[statKey] = {}
    }

    const geometry = parcel?.geometry
    const m2 = geometry ? getParcelArea(geometry) : 0
    const measurement = convertArea(m2, 'squareMeter', preferredUnitSystem)

    return (
      <ul>
        <li>
          <Typography className="FlightInformation__blockArea">
            {i18n.t(keys.reports.blockArea)}
          </Typography>
          <Typography>{`${formatReportNumber(measurement.value)} ${measurement.symbol
            }`}</Typography>
        </li>
        {Object.keys(propertiesByProperty).map((key) => {
          const stat = stats[key]
          if (!stat) {
            return null
          }

          return (
            <li key={key}>
              <Typography>
                {!!keys.reports[key]
                  ? i18n.t(keys.reports[key])
                  : propertiesByProperty[key].name}
              </Typography>
              <Typography>{`${formatReportNumber(stat.value)}${stat.unit ?? ''
                }`}</Typography>
            </li>
          )
        })}
      </ul>
    )
  }

  getStats = () => {
    const { reportStatsByParcelProduct, product, parcel } = this.props
    if (!parcel || !product || !product.dataProperty) {
      return {}
    }
    const productStats = reportStatsByParcelProduct[product.id]
    if (productStats) {
      const parcelProductStats = productStats.parcels?.[`${parcel.parcelId}`]
      if (parcelProductStats) {
        if (product.dataProperty.property === 'EVImean') {
          return {
            EVImean: {
              value: parcelProductStats.data.stats.mean,
            },
            EVIstdev: { value: parcelProductStats.data.stats.stdev },
            EVIcov: { value: parcelProductStats.data.stats.cv },
          }
        }

        if (product.dataProperty.property === 'Presence') {
          return {
            PercentGaps: {
              value:
                (parcelProductStats.data.stops[0].size /
                  parcelProductStats.data.stats.size) *
                100,
              unit: '%',
            },
          }
        }
      }
    }

    return {}
  }

  getMeta = () => {
    const { parcel } = this.props

    if (!parcel) {
      return null
    }

    let meta: ParcelMeta = {}

    if (parcel?.meta) {
      meta = parcel.meta
    }

    const [source] = parcel.mapSources

    if (source?.meta) {
      meta = {
        ...meta,
        ...source.meta,
      }
    }

    return meta
  }
}

/**
 * All decimals are max 2 decimals
 */
const formatReportNumber = (num: number | string): string => {
  const isInteger = !String(num).includes('.')
  if (typeof num === 'number' && isNaN(num)) {
    return 'Not Available'
  }

  return formatNum(num, isInteger, undefined, 2)
}

const mapState = (state: RootStore) => ({
  date: getSelectedFlightDate(state),
  group: selectReportGroup(state),
  organization: selectOrganization(state),
  reportStatsByParcelProduct: selectReportStats(state),
  preferredUnitSystem: state.preferences.preferredUnitSystem,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  FlightInformation
)
