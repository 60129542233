import * as React from 'react'
import { default as swal } from 'sweetalert'

import { getDefaultPageInfo, PAGE_SIZE_OPTIONS, url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../graphql/api'
import { useRedux } from '../../hooks/useRedux'
import { useHistory } from '../../hooks/useRouter'
import { createTableActionIconButton } from '../../UI/Table/createTableActionIconButton'
import { ORDER } from '../../UI/Table/orderRows'
import { Table } from '../../UI/Table/Table'
import { PaginationChange, TableAction, TableOrder } from '../../UI/Table/types'
import MapDataHome from '../mapdata/MapDataHome'
import HelperText from '../mapdata/utils/HelperText'
import {
  ListMapLayerDefGroupsData, refreshListMapLayerDefGroups, selectListMapLayerDefGroups
} from './selectListMapLayerDefGroups'
import { tableFormatters } from './tableFormatters'

export const ListMapLayerDefsGroups = () => {
  const [state] = useRedux()
  const history = useHistory()
  const [selection, setSelection] = React.useState<ListMapLayerDefGroupsData[]>([])
  const listMapLayerDefGroups = selectListMapLayerDefGroups(state)

  const { data = [] } = listMapLayerDefGroups.data ?? {}

  const { order, filter, ...pagination } = getDefaultPageInfo(
    listMapLayerDefGroups.data
  )

  const actions: TableAction<ListMapLayerDefGroupsData
  >[] = [
      {
        button: createTableActionIconButton({
          title: 'Refresh',
          icon: 'autorenew',
          key: 'refresh-map-layer-defs',
        }),
        action: () => {
          refreshListMapLayerDefGroups()
        },
      },
      {
        button: createTableActionIconButton({
          title: 'Create MapLayerDefGroup',
          icon: 'add',
          key: 'create-map-layer-def-group',
        }),
        action: () => {
          history.push(url(urls.newLayerDefGroup))
        },
      },
      {
        selectionRequired: true,
        button: createTableActionIconButton({
          title: 'Edit MapLayerDefGroup',
          icon: 'edit',
          key: 'edit-map-layer-def-group',
        }),
        action: ([{ id: mapLayerDefGroupId }]) => {
          history.push(url(urls.editLayerDefGroup, { mapLayerDefGroupId }))
        },
      },
      {
        multi: true,
        selectionRequired: true,
        button: createTableActionIconButton({
          title: 'Delete Selected MapLayerDefGroups',
          icon: 'delete',
          key: 'delete-map-layer-def-groups',
        }),
        action: async (selection) => {
          const choice = await swal(
            `You are about to delete ${selection.length} source definition group(s). This will remove all layers from the selected groups.`,
            {
              buttons: {
                cancel: true,
                confirm: {
                  text: 'Delete',
                },
              },
              dangerMode: true,
            }
          )

          // swal returns null for "cancel"
          if (!choice) {
            return
          }

          for (const { id } of selection) {
            await api.mapLayerDefGroup.delete({ pk: { id } })
          }

          setSelection([])

          refreshListMapLayerDefGroups()
        },
      },
    ]

  const getURLUpdate = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) => {

    const {
      pageSize: dPageSize,
      page: dPage,
      order: dOrder,
    } = getDefaultPageInfo(listMapLayerDefGroups.data)

    const {
      page = dPage,
      pageSize = dPageSize,
      orderBy = dOrder && ORDER.serialize(dOrder),
    } = options

    return { page, pageSize, orderBy }
  }

  const updateURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) =>
    history.push(
      url(urls.listLayerDefGroups, {}, getURLUpdate(options))
    )

  const replaceURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) =>
    history.replace(
      url(urls.listLayerDefGroups, {}, getURLUpdate(options))
    )

  const handleSelectionChange = (selection: ListMapLayerDefGroupsData[]) =>
    setSelection(selection)

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateURL(pagination)

  const handleOrderChange = (order: TableOrder) =>
    replaceURL({ page: 0, orderBy: ORDER.serialize(order) })

  return (
    <MapDataHome>
      <div id="ListMapLayerDefGroups" className="Paper">
        <HelperText>
          These are layer groups. Grouping Layers allows users to choose how to display each layer group, by selecting a layer within the group.
        </HelperText>
        <AsyncSelectorStatusOverlay requests={listMapLayerDefGroups}>
          <Table
            title="MapLayerDefGroups"
            rows={data}
            actions={actions}
            formatter={tableFormatters}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            selection={selection}
            onSelectionChange={handleSelectionChange}
            order={order}
            onOrderChange={handleOrderChange}
          />
        </AsyncSelectorStatusOverlay>
      </div>
    </MapDataHome>
  )
}
