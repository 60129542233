import * as React from 'react'
interface DrawerIconProps {
  variant: keyof typeof variants
}

const variants = {
  left: (
    <svg viewBox="0 0 24 24" className="ui-font-icon">
      <rect
        x="3"
        y="5"
        width="18"
        height="14"
        rx="1"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect x="9" y="5" width="2" height="14" fill="currentColor" />
      <rect x="5" y="8" width="3" height="1" rx="1" fill="currentColor" />
      <rect x="5" y="10" width="3" height="1" rx="1" fill="currentColor" />
      <rect x="5" y="12" width="3" height="1" rx="1" fill="currentColor" />
    </svg>
  ),
  right: (
    <svg viewBox="0 0 24 24" className="ui-font-icon">
      <rect
        x="3"
        y="5"
        width="18"
        height="14"
        rx="1"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect x="13" y="5" width="2" height="14" fill="currentColor" />
      <rect x="16" y="8" width="3" height="1" rx="1" fill="currentColor" />
      <rect x="16" y="10" width="3" height="1" rx="1" fill="currentColor" />
      <rect x="16" y="12" width="3" height="1" rx="1" fill="currentColor" />
    </svg>
  ),
}

// tslint:disable-next-line:variable-name
export const DrawerIcon = ({ variant }: DrawerIconProps) => variants[variant]
