import { client, gql } from '../../../graphql/client'

export const setToDemoMember = async (variables: {
  userId: number
  organizationId: string
}) => {
  await client.request({
    query: gql`
      mutation SET_TO_DEMO_MEMBER($userId: Int!, $organizationId: Int!) {
        insert_OrganizationUser_one(
          object: {
            userId: $userId
            organizationId: $organizationId
            role: "org-member-demo"
          }
          on_conflict: {
            constraint: OrganizationUser_pkey
            update_columns: [role]
          }
        ) {
          role
          userId
          organizationId
        }
      }
    `,
    variables,
  })
}

export const setToMember = async (variables: {
  userId: number
  organizationId: string
}) => {
  await client.request({
    query: gql`
      mutation SET_TO_MEMBER($userId: Int!, $organizationId: Int!) {
        insert_OrganizationUser_one(
          object: {
            userId: $userId
            organizationId: $organizationId
            role: "org-member"
          }
          on_conflict: {
            constraint: OrganizationUser_pkey
            update_columns: [role]
          }
        ) {
          role
          userId
          organizationId
        }
      }
    `,
    variables,
  })
}

export const setToOwner = async (variables: {
  userId: number
  organizationId: string
}) => {
  await client.request({
    query: gql`
      mutation SET_TO_OWNER($userId: Int!, $organizationId: Int!) {
        insert_OrganizationUser_one(
          object: {
            userId: $userId
            organizationId: $organizationId
            role: "org-owner"
          }
          on_conflict: {
            constraint: OrganizationUser_pkey
            update_columns: [role]
          }
        ) {
          role
          userId
          organizationId
        }
      }
    `,
    variables,
  })
}
