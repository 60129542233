import * as React from 'react'
import './PercentageBar.scss'

interface PercentageBarProps {
  percentage: number
  colour?: string
}

const PercentageBar = ({ percentage, colour }: PercentageBarProps) => {
  const getStyle = () => {
    const style: React.CSSProperties | undefined = {
      width: `${percentage}%`,
    }
    if (!!colour) {
      return { ...style, backgroundColor: colour }
    }
    return style
  }

  return (
    <div className={`container`}>
      <div className={`bar`} style={getStyle()}></div>
    </div>
  )
}

export default PercentageBar
