import * as gravatar from 'gravatar'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  Avatar,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material'
import { createStyles } from '@mui/styles'
import withStyles, { WithStyles } from '@mui/styles/withStyles'

import * as appActions from '../app/actions'
import { url, urls } from '../appNavigation/urls'
import { selectMe } from '../data/selectMe'
import { selectOrganization } from '../data/selectOrganization'
import { selectOrganizations } from '../data/selectOrganizations'
import { selectSelectedGroup } from '../data/selectSelectedGroup'
import i18n, { keys } from '../i18n'
import * as noteForms from '../noteForms/redux'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import { openReportsDialog } from '../reports/redux'
import ConfirmDialog from '../UI/ConfirmDialog'
import { ReactComponent as SamplePlanIcon } from '../pages/SamplePlan/assets/sample_plan_icon.svg'
import Ellipsis from '../UI/Ellipsis'
import WrappingMenuItem from '../UI/WrappingMenuItem'
import { login } from '../vvapi/login'
import DisableOnPageMenuItem from './DisableOnPageMenuItem'
import { FeatureToggle } from '../feature-flags/FeatureToggle'

interface State {
  anchorEl?: HTMLElement | null
  showLogoutConfirmDialog: boolean
}

class UserMenuButton extends React.PureComponent<
  ReduxProps &
    AppDispatchProps &
    RouteComponentProps &
    WithStyles<typeof styles>,
  State
> {
  state: State = {
    showLogoutConfirmDialog: false,
  }

  render() {
    const { user, organizations, organization } = this.props

    if (!user) {
      return null
    }

    const { anchorEl, showLogoutConfirmDialog } = this.state

    // show current org or email
    let secondaryText: string
    if (organization) {
      secondaryText = organization.name
    } else {
      if (organizations.length > 0) {
        secondaryText = organizations[0].name
      } else {
        secondaryText = user.email
      }
    }

    return (
      <List className={this.props.classes.rootList}>
        <ConfirmDialog
          open={showLogoutConfirmDialog}
          title={i18n.t(keys.areYouSure)}
          text={i18n.t(keys.logoutConfirm)}
          onConfirm={() =>
            this.setState(
              {
                showLogoutConfirmDialog: false,
              },
              async () => {
                await this.props.dispatch(login.actions.logout())
                this.props.history.push(url(urls.mapView))
                window.location.reload()
              }
            )
          }
          onCancel={() =>
            this.setState({
              showLogoutConfirmDialog: false,
            })
          }
        />
        <ListItem
          button
          className={this.props.classes.button}
          color="inherit"
          onClick={this.showMenu}
        >
          <Avatar
            id="user-avatar"
            variant="rounded"
            style={{ marginRight: 12 }}
            className={this.props.classes.avatar}
            src={gravatar.url(user.email, { d: 'mm', r: 'g' })}
          />
          <ListItemText
            style={{ color: 'white' }}
            className={this.props.classes.text}
            primary={<Ellipsis>{user.firstName}</Ellipsis>}
            secondary={<Ellipsis>{secondaryText}</Ellipsis>}
          />
        </ListItem>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.hideMenu}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {this.renderUserMenu()}
          {this.renderHelpMenu()}
          {this.renderOrgMenu()}
          {this.renderSamplePlanMenu()}
          {this.renderAdminMenu()}
          {this.renderMonitorMenu()}
        </Menu>
      </List>
    )
  }

  renderUserMenu = () => {
    const { user, organizations } = this.props

    if (!user) {
      return null
    }

    return [
      ...(organizations.length > 1
        ? [
            <WrappingMenuItem
              key="switch_org"
              onClick={this.handleSwitchOrgClick}
            >
              <ListItemIcon>
                <Icon>group</Icon>
              </ListItemIcon>
              <ListItemText primary={i18n.t(keys.user.switchOrganization)} />
            </WrappingMenuItem>,
          ]
        : []),
      <WrappingMenuItem key="settings" onClick={this.handleSettingsClick}>
        <ListItemIcon>
          <Icon>settings</Icon>
        </ListItemIcon>
        <ListItemText primary={i18n.t(keys.preferences)} />
      </WrappingMenuItem>,
      <WrappingMenuItem key="logout" onClick={this.handleLogoutClick}>
        <ListItemIcon>
          <Icon>exit_to_app</Icon>
        </ListItemIcon>
        <ListItemText primary={i18n.t(keys.logout)} />
      </WrappingMenuItem>,
    ]
  }

  renderHelpMenu = () => [
    <hr key="separator_help" />,
    <WrappingMenuItem key="show_help" onClick={this.handleShowHelp}>
      <ListItemIcon>
        <Icon>help</Icon>
      </ListItemIcon>
      <ListItemText primary={i18n.t(keys.generic.help)} />
    </WrappingMenuItem>,
  ]

  renderOrgMenu = () => {
    const { user, organization, group } = this.props

    if (!organization) {
      return null
    }

    const isOrgMember = user?.orgRoles?.length ?? 0 > 0

    const isOrgOwner =
      (isOrgMember && user?.orgRoles?.includes('org-owner')) ||
      user?.roles.includes('admin')

    if (isOrgOwner) {
      return [
        <hr key="separator_org" />,
        <WrappingMenuItem key="notes" onClick={this.handleClickNoteType}>
          <ListItemIcon>
            <Icon>notes</Icon>
          </ListItemIcon>
          <ListItemText primary={i18n.t(keys.noteForm.toggleAdmin)} />
        </WrappingMenuItem>,
        <DisableOnPageMenuItem
          key="reports"
          title={i18n.t(keys.reports.singular)}
          icon="assignment"
          exact
          path={urls.report}
          onClick={this.handleClickReports}
          disabled={!group}
        />,
        <WrappingMenuItem key="downloads" onClick={this.handleClickDownload}>
          <ListItemIcon>
            <Icon>download</Icon>
          </ListItemIcon>
          <ListItemText primary={i18n.t(keys.dataDownload.buttonText)} />
        </WrappingMenuItem>,

        <DisableOnPageMenuItem
          key="manage-organization"
          title={i18n.t(keys.manageOrganization)}
          icon="manage-accounts"
          exact
          path={urls.organizationSettings}
          onClick={this.handleClickManageOrganization}
        />,
        <DisableOnPageMenuItem
          key="goto-map"
          title={i18n.t(keys.map.map)}
          icon="public"
          exact
          path={urls.mapView}
          onClick={this.handleClickMap}
        />,
      ]
    }

    if (isOrgMember) {
      return [
        <hr key="separator_org" />,
        <DisableOnPageMenuItem
          key="reports"
          title={i18n.t(keys.reports.singular)}
          icon="assignment"
          exact
          path={urls.report}
          onClick={this.handleClickReports}
          disabled={!group}
        />,
        <WrappingMenuItem key="downloads" onClick={this.handleClickDownload}>
          <ListItemIcon>
            <Icon>download</Icon>
          </ListItemIcon>
          <ListItemText primary={i18n.t(keys.dataDownload.buttonText)} />
        </WrappingMenuItem>,
        <DisableOnPageMenuItem
          key="goto-map"
          title={i18n.t(keys.map.map)}
          icon="public"
          exact
          path={urls.mapView}
          onClick={this.handleClickMap}
        />,
      ]
    }

    if (
      user?.roles.includes('data-processor-external') ||
      user?.roles.includes('data-processor')
    ) {
      return [
        <hr key="separator_org" />,
        <DisableOnPageMenuItem
          key="goto-map"
          title={i18n.t(keys.map.map)}
          icon="public"
          exact
          path={urls.mapView}
          onClick={this.handleClickMap}
        />,
      ]
    }

    return null
  }
  renderAdminMenu = () => {
    const { user } = this.props

    if (user?.roles.includes('admin')) {
      return [
        <hr key="separator_admin" />,
        <DisableOnPageMenuItem
          key="orders"
          title="Orders"
          icon="airplane_ticket"
          path={urls.listOrders}
          onClick={this.handleClickOrders}
        />,
        <DisableOnPageMenuItem
          key="processing-dashboard"
          title="Processing Dashboard"
          icon="assignment-turned-in"
          path={urls.processingQueue}
          onClick={this.handleClickProcessingDashboard}
        />,
        <DisableOnPageMenuItem
          key="my-queue"
          title="My Queue"
          icon="list"
          path={urls.myQueue}
          onClick={() => this.handleClickMyQueue()}
        />,
        <DisableOnPageMenuItem
          key="manage-datasets"
          title="Manage Data Sets"
          icon="local_airport"
          path={urls.listDataSets}
          onClick={this.handleManageDataSets}
        />,
        <DisableOnPageMenuItem
          key="manage-layers"
          title={i18n.t(keys.customLayers.editLayers)}
          icon="layers"
          path={urls.listSourceDefs}
          onClick={this.handleClickLayers}
        />,
        <DisableOnPageMenuItem
          key="admin"
          title="Admin"
          icon="lock"
          path={urls.admin}
          onClick={this.handleClickAdmin}
        />,
        <DisableOnPageMenuItem
          key="status-dashboard"
          title="Status Dashboard"
          icon="info"
          path={urls.deliveryStatus}
          onClick={this.handleStatusDashboard}
        />,
        <DisableOnPageMenuItem
          key="maestro-dashboard"
          title="Maestro Dashboard"
          icon="device_hub"
          path={urls.maestroQueue}
          onClick={this.handleMaestroDashboard}
        />,
      ]
    }

    if (
      user?.roles.includes('data-processor-external') ||
      user?.roles.includes('data-processor')
    ) {
      return [
        <hr key="separator_admin" />,
        <DisableOnPageMenuItem
          key="my-queue"
          title="My Queue"
          icon="list"
          path={urls.myQueue}
          onClick={() => this.handleClickMyQueue()}
        />,
        <DisableOnPageMenuItem
          key="orders"
          title="Orders"
          icon="airplane_ticket"
          path={urls.listOrders}
          onClick={this.handleClickOrders}
        />,
      ]
    }

    return null
  }

  renderSamplePlanMenu = () => {
    const { user } = this.props

    if (
      (user && user.roles.includes('admin')) ||
      (user?.orgRoles?.length ?? 0) > 0
    ) {
      return [
        <FeatureToggle off featureFlagId="tools-menu">
          <FeatureToggle featureFlagId="sample-planning">
            <hr key="separator_sample_plan" />
            <DisableOnPageMenuItem
              key="sample-plan"
              title={i18n.t(keys.samplePlanDashboard)}
              icon={<SamplePlanIcon />}
              path={urls.samplePlans}
              onClick={this.handleClickSamplePlan}
            />
          </FeatureToggle>
        </FeatureToggle>,
      ]
    }

    return null
  }

  renderMonitorMenu = () => {
    const { user } = this.props

    if (!user || !user.permissions.includes('MonitorEvent-read')) {
      return null
    }

    return [
      <hr key="separator_monitor" />,
      <DisableOnPageMenuItem
        key="monitoring"
        title="Monitoring"
        icon="track_changes"
        path={urls.monitorEventList}
        onClick={this.handleMonitor}
      />,
    ]
  }

  handleClickNoteType = () => {
    this.props.dispatch(noteForms.actions.toggleIsManageFormsVisible(null))
    this.hideMenu()
  }

  showMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  hideMenu = () => {
    this.setState({ anchorEl: null })
  }

  handleShowHelp = () => {
    this.props.dispatch(appActions.setHelpDialogOpen(true))
    this.hideMenu()
  }

  handleSwitchOrgClick = () => {
    this.props.dispatch(appActions.setOrgSwitchDialogOpen(true))
    this.hideMenu()
  }

  handleSettingsClick = async () => {
    this.props.dispatch(appActions.toggleSettingDialog(null))
    this.hideMenu()
  }

  handleLogoutClick = async () => {
    this.setState({ showLogoutConfirmDialog: true })
    this.hideMenu()
  }

  handleManageDataSets = () => {
    this.props.history.push(url(urls.listDataSets))
    this.hideMenu()
  }

  handleStatusDashboard = () => {
    this.props.history.push(url(urls.deliveryStatus))
    this.hideMenu()
  }

  handleMaestroDashboard = () => {
    this.props.history.push(url(urls.maestroQueue))
    this.hideMenu()
  }

  handleMonitor = () => {
    this.props.history.push(url(urls.monitorEventList))
    this.hideMenu()
  }

  handleClickOrders = () => {
    this.props.history.push(url(urls.listOrders))
    this.hideMenu()
  }

  handleClickAdmin = () => {
    this.props.history.push(url(urls.admin_organizations))
    this.hideMenu()
  }

  handleClickSamplePlan = () => {
    this.props.history.push(url(urls.samplePlans))
    this.hideMenu()
  }

  handleClickLayers = () => {
    this.props.history.push(url(urls.listSourceDefs))
    this.hideMenu()
  }

  handleClickMyQueue = () => {
    this.props.history.push(url(urls.myQueue))
    this.hideMenu()
  }

  handleClickProcessingDashboard = () => {
    this.props.history.push(url(urls.processingQueue))
    this.hideMenu()
  }

  handleClickManageOrganization = () => {
    const { organization } = this.props
    this.props.history.push(
      url(urls.organizationSettings, { organizationId: organization!.id })
    )
    this.hideMenu()
  }

  handleClickMap = () => {
    this.props.history.push(url(urls.mapView))
    this.hideMenu()
  }

  handleClickReports = () => {
    const { dispatch, group } = this.props

    if (!group) {
      return
    }

    dispatch(openReportsDialog(group))

    this.hideMenu()
  }

  handleClickDownload = async () => {
    this.props.history.push(url(urls.downloads))

    this.hideMenu()
  }
}

const styles = () =>
  createStyles({
    rootList: {
      padding: 0,
    },
    avatar: {
      width: 30,
      height: 30,
    },
    button: {
      height: '100%',
      padding: '0 1em',
    },
    text: {
      '& > *': {
        fontSize: 13,
      },
      [`@media (max-width:900px)`]: {
        display: 'none',
      },
    },
  })

const mapState = (state: RootStore) => ({
  user: selectMe(state),
  organization: selectOrganization(state),
  organizations: selectOrganizations(state),
  group: selectSelectedGroup(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(withRouter(UserMenuButton))
)
