import * as React from 'react'
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { useSamplePlanMapSourceAndLayers } from '../../../hooks/useSamplePlanMapSourceAndLayers'
import useAsync from '../../../../../hooks/useAsync'
import { fetchDataSourceFlightDateList, fetchOrganizationParcels } from '../../../queries'
import { useRedux } from '../../../../../hooks/useRedux'
import { selectOrganizationId } from '../../../../../data/selectOrganizationId'
import { Parcel } from '../../../../../graphql/types'
import { SamplePlanMap } from '../../../SamplePlanMap'
import {
  ExpandLess,
  ExpandMore,
  LocationSearching,
  Search,
} from '@mui/icons-material'
import { MapContext, MapContextProvider } from '../../../../../map/withMap'
import i18n, { keys } from '../../../../../i18n'
import { groupArray } from '../../../../../util/groupArray'
import bbox from '@turf/bbox'
import { BBox } from '@turf/helpers'
import { LoadingControlBackdrop } from '../../../../../UI/Loading/LoadingControlBackdrop'
import { useDebounce } from '../../../../../hooks/useDebounce'
import { classnames } from '../../../../../util/classnames'
import { SamplePlanNewFormProps } from '../types/SamplePlanFormProps'

type Ref = HTMLDivElement

const SamplePlanBlockSelectionWithMapContext = (
  props: SamplePlanNewFormProps<Ref>
) => {
  return (
    <div ref={props.forwardedRef}>
      <MapContextProvider key={`NEW-SP-MAP-collapsed`}>
        <SamplePlanBlockSelectionForm {...props} />
      </MapContextProvider>
    </div>
  )
}

const StyledSelect = styled(Select)({
  minWidth: '250px',
  height: '37px',
})

const SamplePlanBlockSelectionForm = ({
  currentNewSamplingPlan,
  setCurrentNewSamplingPlan,
  registerValidation,
  invalidateCompletedSamplingPlan,
  stage,
}: SamplePlanNewFormProps<Ref>) => {
  const [mapLoading, setMapLoading] = React.useState(true)
  const [search, setSearch] = React.useState<string>('')
  const [query, setQuery] = React.useState<string>('')
  const [openValues, setOpenValues] = React.useState<Record<string, boolean>>(
    {}
  )
  const { map } = React.useContext(MapContext)
  const [parcels, setParcels] = React.useState<Parcel[]>()
  const [state] = useRedux()
  const organizationId = selectOrganizationId(state)
  const [fetchDataSourceFlightDates] = useAsync(fetchDataSourceFlightDateList, [
    organizationId,
  ])

  const fetchedDataSourceFlightDates = React.useMemo(() => {
    return fetchDataSourceFlightDates?.result?.sort(
      (a: string, b: string) => new Date(b).getTime() - new Date(a).getTime()
    ) ?? []
  }, [fetchDataSourceFlightDates.result])

  const [selectedFlightDate, setSelectedFlightDate] = React.useState<string>()

  const [fetchParcels] = useAsync(fetchOrganizationParcels, [
    organizationId,
    query,
    selectedFlightDate,
  ])

  React.useEffect(() => {
    if (!map) {
      return
    }
    // wait until animation is completed so map is rendered correctly.

    setMapLoading(true)
    const timer = setTimeout(() => {
      map.resize()
      setMapLoading(false)
    }, 2000)
    return () => clearTimeout(timer)
  }, [map])

  React.useEffect(() => {
    setParcels(fetchParcels?.result?.data)
    setOpenValues(
      (fetchParcels?.result?.data ?? []).reduce((acc, d) => {
        if (d.OrganizationGroup?.name) {
          acc[d.OrganizationGroup?.name] =
            openValues[d.OrganizationGroup?.name] ?? false
        }
        return acc
      }, {} as Record<string, boolean>)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParcels?.result?.data])

  React.useEffect(() => {
    registerValidation(() => {
      return (
        !!currentNewSamplingPlan?.selectedParcels &&
        currentNewSamplingPlan.selectedParcels.length > 0
      )
    }, stage)
  }, [registerValidation, stage, currentNewSamplingPlan])

  const { setBlockBounds } = useSamplePlanMapSourceAndLayers({
    parcels,
    selectedParcels: currentNewSamplingPlan.selectedParcels,
    boundsPadding: 60,
  })

  const setSelectedParcel = async (parcel: Parcel, checked: boolean) => {
    if (checked) {
      const sameGroup = currentNewSamplingPlan?.selectedParcels?.every(
        (sp) => sp.OrganizationGroup?.id === parcel.OrganizationGroup?.id
      )
      // remove any parcels that might have been filtered out when selecting something new.
      const currentUnfilteredSelectedParcels = currentNewSamplingPlan?.selectedParcels?.filter(
        (sp) => parcels?.map((p) => p.id).includes(sp.id)
      )

      // if the parcel is of the same group, concat with others.
      // Otherwise overwrite with single parcel.
      const parcelSet = Array.from(
        new Set([
          ...(sameGroup ? currentUnfilteredSelectedParcels ?? [] : []),
          parcel,
        ])
      )
      setCurrentNewSamplingPlan({
        ...currentNewSamplingPlan,
        selectedParcels: parcelSet,
        selectedParcelIds: parcelSet.map((ps) => ps.id),
        blockSamplesData: undefined, // clear block sample data
        statisticalMethodDataSources: undefined, // clear the data sources
      })
      setParcelBounds(parcelSet)
    } else {
      const newSelectedParcels = currentNewSamplingPlan.selectedParcels?.filter(
        (sp) => sp.id !== parcel.id
      )
      setCurrentNewSamplingPlan({
        ...currentNewSamplingPlan,
        selectedParcels: newSelectedParcels,
        selectedParcelIds: newSelectedParcels?.map((ps) => ps.id),
        blockSamplesData: undefined, // clear block sample data
        statisticalMethodDataSources: undefined, // clear the data sources
      })
      setParcelBounds(newSelectedParcels)
    }
    await invalidateCompletedSamplingPlan()
  }

  const setSelectedLocation = async (parcels: Parcel[]) => {
    // only need to check the first parcels to determine whether the location is already selected.
    const locationAlreadySelected =
      parcels[0]?.OrganizationGroup?.id ===
      currentNewSamplingPlan?.selectedParcels?.[0]?.OrganizationGroup?.id
    if (!locationAlreadySelected) {
      setCurrentNewSamplingPlan({
        ...currentNewSamplingPlan,
        selectedParcels: parcels,
        selectedParcelIds: parcels.map((p) => p.id),
        blockSamplesData: undefined, // clear block sample data
        statisticalMethodDataSources: undefined, // clear the data sources
      })
    } else {
      setCurrentNewSamplingPlan({
        ...currentNewSamplingPlan,
        selectedParcels: [],
        selectedParcelIds: [],
        blockSamplesData: undefined, // clear block sample data
        statisticalMethodDataSources: undefined, // clear the data sources
      })
    }
    setParcelBounds(parcels)
    await invalidateCompletedSamplingPlan()
  }

  const setParcelBounds = (parcels?: Parcel[]) => {
    if (!parcels || parcels?.length === 0) {
      return
    }

    let newBounds: BBox = [
      Number.MAX_VALUE,
      Number.MAX_VALUE,
      -Number.MAX_VALUE,
      -Number.MAX_VALUE,
    ]

    for (const parcel of parcels) {
      const parcelBounds = bbox(parcel.geometry)
      newBounds = [
        Math.min(parcelBounds[0], newBounds[0]),
        Math.min(parcelBounds[1], newBounds[1]),
        Math.max(parcelBounds[2], newBounds[2]),
        Math.max(parcelBounds[3], newBounds[3]),
      ]
    }

    setBlockBounds(newBounds)
  }

  const onClickToggle = (id: string) => {
    if (typeof openValues?.[id] !== 'undefined') {
      setOpenValues((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }))
    }
  }

  const locationSelecteded = (locationParcels: Parcel[]) => {
    return locationParcels.some((lp) =>
      currentNewSamplingPlan?.selectedParcels
        ?.map((sp) => sp?.OrganizationGroup?.id)
        .includes(lp?.OrganizationGroup?.id)
    )
  }

  const debounceQuery = useDebounce(setQuery, 400)
  const searchBlocksAndLocations = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(e.target.value)
    debounceQuery(e.target.value)
  }

  const selectDataSource = (flightDate?: string) => {
    setSelectedFlightDate(flightDate)
    setCurrentNewSamplingPlan({
      ...currentNewSamplingPlan,
      flightDate: flightDate,
      selectedParcels: [],
      selectedParcelIds: [],
      blockSamplesData: undefined, // clear block sample data
      statisticalMethodDataSources: undefined, // clear the data sources
    })
  }

  return (
    <Stack
      className="sample-plan-new-stage-form"
      style={{ height: '100%', width: '100%' }}
      direction="row"
      spacing={2}
    >
      <Stack direction="column" spacing={2}>
        <TextField
          variant="outlined"
          size="small"
          style={{ width: '250px' }}
          placeholder={i18n.t(keys.search)}
          InputProps={{ endAdornment: <Search /> }}
          value={search}
          onChange={searchBlocksAndLocations}
        ></TextField>
        <StyledSelect
          value={selectedFlightDate}
          variant="outlined"
          displayEmpty
          size="small"
        >
          <MenuItem value={undefined} onClick={() => selectDataSource()}>
            <em style={{ color: 'grey' }}>{i18n.t(keys.samplePlanAllFlightDates)}</em>
          </MenuItem>
          {fetchedDataSourceFlightDates?.map((option, index) => (
            <MenuItem
              key={index}
              value={option}
              onClick={() => selectDataSource(option)}
            >
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
        <LoadingControlBackdrop
          open={fetchParcels.status !== 'resolved'}
          containerStyle={{ overflow: 'auto', height: '100%' }}
          backdropStyle={{ backgroundColor: 'rgba(0,0,0,0)' }}
        >
          <List style={{ maxHeight: '100%', overflow: 'auto' }}>
            {Object.entries(
              groupArray(parcels ?? [], (p) => p.OrganizationGroup?.name)
            ).map((g) => {
              return (
                <Stack direction="column" key={g?.[0]}>
                  <ListItem>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1}
                      style={{ width: '100%' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        style={{ width: '100%' }}
                      >
                        <IconButton onClick={() => onClickToggle(g?.[0])}>
                          {openValues[g?.[0]] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <Radio
                          checked={locationSelecteded(g?.[1] ?? [])}
                          onClick={async (e) =>
                            await setSelectedLocation(g?.[1])
                          }
                        />
                        <Typography>{g?.[0]}</Typography>
                      </Stack>
                      <IconButton onClick={() => setParcelBounds(g?.[1] ?? [])}>
                        <LocationSearching
                          className={classnames(
                            'sample-plan-block-location-icon',
                            ['selected', locationSelecteded(g?.[1] ?? [])]
                          )}
                        />
                      </IconButton>
                    </Stack>
                  </ListItem>
                  <Collapse in={openValues[g?.[0]]}>
                    {g?.[1].map((p) => {
                      return (
                        <ListItem key={p.id}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            style={{ width: '100%', paddingLeft: '60px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    currentNewSamplingPlan?.selectedParcels
                                      ?.map((sp) => sp.name)
                                      ?.includes(p.name) ?? false
                                  }
                                  onChange={async (_, checked) =>
                                    await setSelectedParcel(p, checked)
                                  }
                                />
                              }
                              label={p.name}
                            />
                          </Stack>
                        </ListItem>
                      )
                    })}
                  </Collapse>
                </Stack>
              )
            })}
          </List>
        </LoadingControlBackdrop>
      </Stack>
      <LoadingControlBackdrop
        open={mapLoading || fetchParcels.status !== 'resolved'}
        containerStyle={{ width: '100%', height: '100%' }}
      >
        <SamplePlanMap />
      </LoadingControlBackdrop>
    </Stack>
  )
}

export default React.forwardRef<Ref, SamplePlanNewFormProps<Ref>>(
  (props, ref) => (
    <SamplePlanBlockSelectionWithMapContext {...props} forwardedRef={ref} />
  )
)
