import * as React from 'react'
import ColorPicker from './ColorPicker'

interface NameAndValue {
  name: string
  value: string
}

interface Props extends NameAndValue {
  onChange(e: NameAndValue): void
}

export default class FormColorPicker extends React.PureComponent<Props> {
  render() {
    const { value } = this.props

    return <ColorPicker color={value} onChange={this.onChange} />
  }

  onChange = (value: string) => {
    const { name, onChange } = this.props

    onChange({
      name,
      value,
    })
  }
}
