import gql from 'graphql-tag'
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table'
import { client } from '../../graphql/client'
import {
  RateMap,
  RateMapAdditionalLayer,
  RateMapAmendmentModeType,
  RateMapAmendmentType,
  RateMapBackgroundLayer,
  RateMapBlockSelection,
  RateMapUnitType,
  Translation,
} from '../../graphql/types'

interface GetRateMapsParams {
  organizationId?: string
  columnFilters: MRT_ColumnFiltersState
  globalFilter: string
  sorting: MRT_SortingState
  pagination: MRT_PaginationState
}

interface GetRateMapsResponse {
  RateMaps: RateMap[]
  totalRowCount: {
    aggregate: {
      count: number
    }
  }
}

interface RateMapUpsert
  extends Omit<
    RateMap,
    | 'createdBy'
    | 'CreatedBy'
    | 'createdAt'
    | 'Mode'
    | 'DuplicateOf'
    | 'RateMapBlockSelections'
    | 'RateMapAdditionalLayers'
    | 'RateMapBackgroundLayers'
    | 'RateMapAmendmentType'
  > {
  RateMapBlockSelections: {
    data: Pick<RateMapBlockSelection, 'enabled' | 'parcelId'>[]
    on_conflict: {
      constraint: 'RateMapBlockSelection_pkey'
      update_columns: ['enabled']
    }
  }
}

const saveRateMapMutation = gql`
  mutation SAVE_RATE_MAP($rateMap: RateMap_insert_input!) {
    RateMap: insert_RateMap_one(
      object: $rateMap
      on_conflict: {
        constraint: RateMap_pkey
        update_columns: [
          name
          flightDate
          amendmentTypeId
          amendmentZoneRates
          unitTypeId
          unitCost
          mode
          modeConfiguration
        ]
      }
    ) {
      id
      name
      organizationId
      flightDate
      createdAt
      mode
      RateMapBlockSelections {
        rateMapId
        parcelId
        Parcel {
          id
          name
        }
        enabled
      }

      RateMapAmendmentType {
        id
        name
      }

      RateMapAdditionalLayers {
        layerId
        enabled
        opacity
      }
      RateMapBackgroundLayers {
        layerId
        enabled
        opacity
      }
    }
  }
`

const saveRateMap = async (rateMap: RateMap) => {
  const {
    CreatedBy,
    createdAt,
    createdBy,
    RateMapAdditionalLayers,
    RateMapBackgroundLayers,
    RateMapBlockSelections,
    RateMapAmendmentType,
    ...restRateMap
  } = rateMap

  const rateMapUpsert: RateMapUpsert = {
    ...restRateMap,
    RateMapBlockSelections: {
      data: RateMapBlockSelections.map((block) => ({
        parcelId: block.parcelId,
        enabled: block.enabled,
      })),
      on_conflict: {
        constraint: 'RateMapBlockSelection_pkey',
        update_columns: ['enabled'],
      },
    },
  }

  const { RateMap } = await client.request({
    query: saveRateMapMutation,
    variables: { rateMap: rateMapUpsert },
  })

  return RateMap
}

const getRateMapsQuery = gql`
  query GET_RATE_MAPS(
    $where: RateMap_bool_exp
    $order_by: [RateMap_order_by!]
    $offset: Int
    $limit: Int
  ) {
    RateMaps: RateMap(
      where: $where
      order_by: $order_by
      offset: $offset
      limit: $limit
    ) {
      id
      name
      flightDate
      organizationId
      createdAt
      RateMapBlockSelections(where: { enabled: { _eq: true } }) {
        parcelId
        Parcel {
          id
          name
        }
        enabled
      }

      unitTypeId
      unitCost
      amendmentTypeId
      amendmentZoneRates

      mode
      modeConfiguration

      RateMapAmendmentType {
        id
        name
      }
      RateMapCustomZones {
        customZoneTypeId
        parcelId
        geometry
      }

      RateMapAdditionalLayers {
        layerId
        enabled
        opacity
      }
      RateMapBackgroundLayers {
        layerId
        enabled
        opacity
      }
    }
    totalRowCount: RateMap_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

const getRateMaps = async ({
  organizationId,
  columnFilters,
  globalFilter,
  sorting,
  pagination,
}: GetRateMapsParams) => {
  if (!organizationId) {
    return { RateMaps: [], totalRowCount: 0 }
  }

  const where = {
    _and: [
      {
        organizationId: { _eq: organizationId },
        deletedAt: { _is_null: true },
      },
    ] as any[],
  }

  const tableFilters = {
    _or: [] as any[],
  }

  if (globalFilter) {
    tableFilters._or.push({
      _or: [
        { name: { _ilike: `%${globalFilter}%` } },
        {
          RateMapAmendmentType: {
            name: {
              _cast: {
                String: {
                  _ilike: `%${globalFilter}%`,
                },
              },
            },
          },
        },
        {
          RateMapBlockSelections: {
            _and: [
              { enabled: { _eq: true } },
              {
                Parcel: {
                  name: {
                    _ilike: `%${globalFilter}%`,
                  },
                },
              },
            ],
          },
        },
      ],
    })
  }

  if (columnFilters.length) {
    columnFilters.forEach((filter) => {
      if (filter.value) {
        switch (filter.id) {
          case 'createdAt':
            const [min, max] = filter.value as [string, string]
            if (min) {
              tableFilters._or.push({
                [filter.id]: {
                  _gte: min,
                },
              })
            }
            if (max) {
              tableFilters._or.push({
                [filter.id]: {
                  _lte: max,
                },
              })
            }
            break
          case 'block':
            const selectedParcelIds = filter.value as string[]
            tableFilters._or.push({
              RateMapBlockSelections: {
                _and: [
                  { enabled: { _eq: true } },
                  {
                    Parcel: {
                      id: {
                        _in: selectedParcelIds,
                      },
                    },
                  },
                ],
              },
            })
            break
          case 'amendmentType':
            const selectedAmendmentIds = filter.value as string[]
            tableFilters._or.push({
              RateMapAmendmentType: {
                id: {
                  _in: selectedAmendmentIds,
                },
              },
            })
            break
          default:
            tableFilters._or.push({
              [filter.id]: {
                _ilike: `%${filter.value}%`,
              },
            })
            break
        }
      }
    })
  }

  if (tableFilters._or.length) {
    where._and.push(tableFilters)
  }

  const order_by = sorting.map((sort) => {
    return { [sort.id]: sort.desc ? 'desc' : 'asc' }
  })

  const offset = pagination.pageIndex * pagination.pageSize
  const limit = pagination.pageSize

  const { RateMaps, totalRowCount } = await client.request<GetRateMapsResponse>(
    {
      query: getRateMapsQuery,
      variables: {
        where: where,
        order_by,
        offset,
        limit,
      },
    }
  )

  return { RateMaps, totalRowCount: totalRowCount.aggregate.count }
}

interface GetOrgFilterOptionsParams {
  organizationId?: string
}

interface GetOrgFilterResponse {
  amendmentOptions: {
    value: string
    label: Translation
  }[]
  parcelOptions: {
    value: string
    label: string
  }[]
}

const getOrgFilterOptionsQuery = gql`
  query GET_ORG_FILTER_OPTIONS($organizationId: Int!) {
    amendmentOptions: RateMapAmendmentType(
      where: { organizationId: { _eq: $organizationId } }
    ) {
      value: id
      label: name
    }
    parcelOptions: Parcel(
      where: { organizationId: { _eq: $organizationId } }
      order_by: { name: asc }
    ) {
      value: id
      label: name
    }
  }
`

const getOrgFilterOptions = async ({
  organizationId,
}: GetOrgFilterOptionsParams) => {
  if (!organizationId) {
    return { amendmentOptions: [], parcelOptions: [] }
  }
  return client.request<GetOrgFilterResponse>({
    query: getOrgFilterOptionsQuery,
    variables: {
      organizationId,
    },
  })
}

interface CreateRateMapParams {
  name: string
  organizationId: string
  flightDate: string | undefined
  blocks: { id: string }[] | undefined
  selectedBlockIds: Set<string> | undefined
}

interface CreateRateMapResponse {
  RateMap: RateMap
}

interface CreateRateMapBlockSelectionResponse {
  RateMapBlockSelection: {
    returning: {
      rateMapId: string
      parcelId: string
    }[]
  }
}

const createRateMapMutation = gql`
  mutation CREATE_RATE_MAP($rateMap: RateMap_insert_input!) {
    RateMap: insert_RateMap_one(object: $rateMap) {
      id
    }
  }
`

const createRateMapBlockSelectionMutation = gql`
  mutation CREATE_RATE_MAP_BLOCK_SELECTION(
    $rateMapBlockSelections: [RateMapBlockSelection_insert_input!]!
  ) {
    RateMapBlockSelection: insert_RateMapBlockSelection(
      objects: $rateMapBlockSelections
    ) {
      returning {
        rateMapId
        parcelId
      }
    }
  }
`

const createRateMap = async ({
  name,
  organizationId,
  flightDate,
  blocks = [],
  selectedBlockIds = new Set(),
}: CreateRateMapParams) => {
  const { RateMap } = await client.request<CreateRateMapResponse>({
    query: createRateMapMutation,
    variables: {
      rateMap: {
        name,
        organizationId,
        flightDate,
      },
    },
  })

  if (!RateMap) {
    throw new Error('Failed to create rate map')
  }

  if (blocks.length === 0) {
    return RateMap
  }

  const rateMapBlockSelections = blocks.map((block) => ({
    rateMapId: RateMap.id,
    parcelId: block.id,
    enabled: selectedBlockIds.has(block.id),
  }))

  const { RateMapBlockSelection } =
    await client.request<CreateRateMapBlockSelectionResponse>({
      query: createRateMapBlockSelectionMutation,
      variables: {
        rateMapBlockSelections,
      },
    })

  if (!RateMapBlockSelection) {
    throw new Error('Failed to create rate map block selections')
  }

  return { ...RateMap, RateMapBlockSelections: RateMapBlockSelection.returning }
}

interface DeleteRateMapParams {
  rateMapId: string
}

interface DeleteRateMapResponse {
  RateMap: {
    id: string
  }
}

const deleteRateMapMutation = gql`
  mutation SOFT_DELETE_RATE_MAP($rateMapId: uuid!, $now: timestamptz!) {
    RateMap: update_RateMap_by_pk(
      pk_columns: { id: $rateMapId }
      _set: { deletedAt: $now }
    ) {
      id
    }
  }
`

const deleteRateMap = async ({ rateMapId }: DeleteRateMapParams) => {
  const { RateMap } = await client.request<DeleteRateMapResponse>({
    query: deleteRateMapMutation,
    variables: {
      rateMapId: rateMapId,
      now: new Date().toISOString(),
    },
  })

  return RateMap
}

interface DuplicateRateMapParams {
  rateMap: RateMap
  newName: string
}

interface DuplicateRateMapResponse {
  RateMap: RateMap
}

interface DuplicateRateMapBlockSelectionResponse {
  RateMapBlockSelections: RateMapBlockSelection[]
}

interface DuplicateRateMapAdditionalLayersResponse {
  RateMapAdditionalLayers: RateMapAdditionalLayer[]
}

interface DuplicateRateMapBackgroundLayerResponse {
  RateMapBackgroundLayers: RateMapBackgroundLayer[]
}

const duplicateRateMapMutation = gql`
  mutation DUPLICATE_RATE_MAP($rateMap: RateMap_insert_input!) {
    RateMap: insert_RateMap_one(object: $rateMap) {
      id
    }
  }
`

const duplicateRateMapBlockSelectionMutation = gql`
  mutation DUPLICATE_RATE_MAP_BLOCK_SELECTION(
    $rateMapBlockSelections: [RateMapBlockSelection_insert_input!]!
  ) {
    RateMapBlockSelection: insert_RateMapBlockSelection(
      objects: $rateMapBlockSelections
    ) {
      returning {
        rateMapId
        parcelId
      }
    }
  }
`
const duplicateRateMapAdditionalLayerMutation = gql`
  mutation DUPLICATE_RATE_MAP_ADDITIONAL_LAYER(
    $rateMapAdditionalLayers: [RateMapAdditionalLayer_insert_input!]!
  ) {
    RateMapAdditionalLayer: insert_RateMapAdditionalLayer(
      objects: $rateMapAdditionalLayers
    ) {
      returning {
        rateMapId
        layerId
      }
    }
  }
`

const duplicateRateMapBackgroundLayerMutation = gql`
  mutation DUPLICATE_RATE_MAP_BACKGROUND_LAYER(
    $rateMapBackgroundLayers: [RateMapBackgroundLayer_insert_input!]!
  ) {
    RateMapBackgroundLayer: insert_RateMapBackgroundLayer(
      objects: $rateMapBackgroundLayers
    ) {
      returning {
        rateMapId
        layerId
      }
    }
  }
`

const duplicateRateMap = async ({
  rateMap,
  newName,
}: DuplicateRateMapParams) => {
  const { RateMap: newRateMap } =
    await client.request<DuplicateRateMapResponse>({
      query: duplicateRateMapMutation,
      variables: {
        rateMap: {
          name: newName,
          flightDate: rateMap.flightDate,
          organizationId: rateMap.organizationId,
          deliveryId: rateMap.deliveryId,
          mode: rateMap.mode,
          modeConfiguration: rateMap.modeConfiguration,
          duplicateOfId: rateMap.id,
          amendmentZoneRates: rateMap.amendmentZoneRates,
        },
      },
    })

  if (!newRateMap) {
    throw new Error('Failed to duplicate rate map')
  }

  if (rateMap.RateMapBlockSelections) {
    await client.request<DuplicateRateMapBlockSelectionResponse>({
      query: duplicateRateMapBlockSelectionMutation,
      variables: {
        rateMapBlockSelections: rateMap.RateMapBlockSelections.map((block) => ({
          rateMapId: newRateMap.id,
          parcelId: block.parcelId,
          enabled: block.enabled,
        })),
      },
    })
  }

  if (rateMap.RateMapAdditionalLayers) {
    await client.request<DuplicateRateMapAdditionalLayersResponse>({
      query: duplicateRateMapAdditionalLayerMutation,
      variables: {
        rateMapAdditionalLayers: rateMap.RateMapAdditionalLayers.map(
          (layer) => ({
            rateMapId: newRateMap.id,
            layerId: layer.layerId,
            enabled: layer.enabled,
          })
        ),
      },
    })
  }

  if (rateMap.RateMapBackgroundLayers) {
    await client.request<DuplicateRateMapBackgroundLayerResponse>({
      query: duplicateRateMapBackgroundLayerMutation,
      variables: {
        rateMapBackgroundLayers: rateMap.RateMapBackgroundLayers.map(
          (layer) => ({
            rateMapId: newRateMap.id,
            layerId: layer.layerId,
            enabled: layer.enabled,
          })
        ),
      },
    })
  }

  return {
    RateMap: {
      ...newRateMap,
    },
  }
}

interface GetRateMapParams {
  rateMapId: string
}

interface GetRateMapResponse {
  RateMap: RateMap
}

const getRateMapQuery = gql`
  query GET_RATE_MAP($rateMapId: uuid!) {
    RateMap: RateMap_by_pk(id: $rateMapId) {
      id
      name
      organizationId
      flightDate
      createdAt
      mode
      modeConfiguration
      amendmentZoneRates
      amendmentTypeId
      unitTypeId
      unitCost
      RateMapBlockSelections {
        rateMapId
        parcelId
        Parcel {
          id
          name
        }
        enabled
      }

      RateMapAmendmentType {
        id
        name
      }

      RateMapAdditionalLayers {
        layerId
        enabled
        opacity
      }
      RateMapBackgroundLayers {
        layerId
        enabled
        opacity
      }
    }
  }
`

const getRateMap = async ({ rateMapId }: GetRateMapParams) => {
  const { RateMap } = await client.request<GetRateMapResponse>({
    query: getRateMapQuery,
    variables: {
      rateMapId,
    },
  })

  return RateMap
}

const addAmendment = async (amendmentName: string, organizationId: string) => {
  const checkExisting = await client.request({
    query: gql`
      query CHECK_EXISTING_AMENDMENT(
        $amendmentName: String!
        $organizationId: Int!
      ) {
        RateMapAmendmentType(
          where: {
            name: { _cast: { String: { _ilike: $amendmentName } } }
            _or: [
              { organizationId: { _eq: $organizationId } }
              { organizationId: { _is_null: true } }
            ]
          }
        ) {
          id
        }
      }
    `,
    variables: {
      amendmentName: `{\"en\": \"${amendmentName}\"%`,
      organizationId: organizationId,
    },
  })

  if (checkExisting.RateMapAmendmentType.length > 0) {
    throw new Error(
      'An amendment with this name already exists for this organization.'
    )
  }

  const insertResult = await client.request({
    query: gql`
      mutation ADD_AMENDMENT($amendmentName: jsonb!, $organizationId: Int!) {
        insert_RateMapAmendmentType_one(
          object: { name: $amendmentName, organizationId: $organizationId }
        ) {
          id
        }
      }
    `,
    variables: {
      amendmentName: { en: amendmentName, fr: amendmentName },
      organizationId,
    },
  })
  return insertResult.insert_RateMapAmendmentType_one
}

const deleteAmendment = async (id: string) => {
  await client.request({
    query: gql`
      mutation SOFT_DELETE_AMENDMENT_TYPE($id: uuid!) {
        update_RateMapAmendmentType_by_pk(
          pk_columns: { id: $id }
          _set: { deletedAt: "now()" }
        ) {
          deletedAt
        }
        update_RateMap(
          where: { amendmentTypeId: { _eq: $id } }
          _set: { amendmentTypeId: "00000000-0000-0000-0000-000000000000" }
        ) {
          affected_rows
        }
      }
    `,
    variables: {
      id,
    },
  })
}

const getAmendmentTypes = async (organizationId: string) => {
  const { types } = await client.request<{ types: RateMapAmendmentType[] }>({
    query: gql`
      query GET_AMENDMENT_TYPES($organizationId: Int!) {
        types: RateMapAmendmentType(
          where: {
            deletedAt: { _is_null: true }
            _or: [
              { organizationId: { _is_null: true } }
              { organizationId: { _eq: $organizationId } }
            ]
          }
        ) {
          id
          name
          organizationId
        }
      }
    `,
    variables: { organizationId },
  })

  return types
}

const getUnitTypes = async (organizationId: string) => {
  const { types } = await client.request<{ types: RateMapUnitType[] }>({
    query: gql`
      query GET_UNIT_TYPES($organizationId: Int!) {
        types: RateMapUnitType(
          where: {
            _or: [
              { organizationId: { _is_null: true } }
              { organizationId: { _eq: $organizationId } }
            ]
          }
        ) {
          id
          name
          description
          weightUnit
          areaUnit
        }
      }
    `,
    variables: { organizationId },
  })

  return types
}

const getAmendmentModeTypes = async () => {
  const { types } = await client.request<{ types: RateMapAmendmentModeType[] }>(
    {
      query: gql`
        query GET_AMENDMENT_MODE_TYPES {
          types: RateMapAmendmentModeType {
            id
            defaultConfiguration
          }
        }
      `,
    }
  )

  return types
}

const getRatemapsByAmendment = async (
  amendmentTypeId: string
): Promise<number> => {
  const response = await client.request<{
    RateMap_aggregate: {
      aggregate: {
        count: number
      }
    }
  }>({
    query: gql`
      query CountRateMapsUsingAmendmentType($amendmentTypeId: uuid!) {
        RateMap_aggregate(
          where: { amendmentTypeId: { _eq: $amendmentTypeId } }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: {
      amendmentTypeId,
    },
  })
  return response.RateMap_aggregate.aggregate.count
}

export {
  saveRateMap,
  getOrgFilterOptions,
  getRateMaps,
  createRateMap,
  deleteRateMap,
  duplicateRateMap,
  getRateMap,
  addAmendment,
  deleteAmendment,
  getAmendmentTypes,
  getUnitTypes,
  getAmendmentModeTypes,
  getRatemapsByAmendment,
}
