import * as React from 'react'

import { Button, Grid, Typography, Tooltip } from '@mui/material';

import useAsync from '../../../hooks/useAsync'
import { getJson } from '../../../vvapi/apiResource/createApiResource'
import { SubscriptionCard } from './SubscriptionCard'
import { SubscriptionSkeleton } from './SubscriptionSkeleton'
import i18n, { keys } from '../../../i18n'

interface Subscription {
  id: string
}
interface Props {
  subscriptions: Subscription[]
}

const handleManageStripe = async () => {
  const { url } = await getJson(`/api/v3/stripe/billing-portal`)
  window.location.href = url
}

const Subscription = ({ id }: Subscription) => {
  const [fetchSubscription] = useAsync(async ([id], skip: () => void) => {
    const { subscription } = await getJson(`/api/v3/stripe/subscriptions/${id}`)
    if (!subscription) {
      return skip()
    }
    return subscription
  }, [id])

  const handlePayInvoice = async () => {
    if(fetchSubscription.result.latest_invoice.hosted_invoice_url){
      window.open(fetchSubscription.result.latest_invoice.hosted_invoice_url, '_blank')?.focus()
    }
  }

  if (fetchSubscription.status === 'pending') {
    return <SubscriptionSkeleton />
  }
  if (fetchSubscription.status === 'resolved' && fetchSubscription.result) {
    return <SubscriptionCard
      productName={fetchSubscription.result.items.data[0].price.product.name}
      productDescription={i18n.language === 'en' 
        ? fetchSubscription.result.items.data[0].price.product.metadata.productDescriptionEn
        : fetchSubscription.result.items.data[0].price.product.metadata.productDescriptionFr} 
      productPrice={fetchSubscription.result.items.data[0].price.unit_amount / 100} // Unit amount does not include decimals.
      renewalDate={fetchSubscription.result.current_period_end * 1000} // Unix time returned from Stripe API.
      actionButton={
        fetchSubscription.result.latest_invoice.status === 'paid'
          ? <Button variant="outlined" fullWidth color="primary" onClick={() => handleManageStripe()}>{i18n.t(keys['manage-subscription'])}</Button>
          : <Tooltip disableHoverListener={fetchSubscription.result.latest_invoice.hosted_invoice_url} title={i18n.t(keys.invoiceNotSent)}>
              <div style={{ flexGrow: 1 }}>
                <Button variant="outlined" fullWidth color="primary" onClick={() => handlePayInvoice()} disabled={!fetchSubscription.result.latest_invoice.hosted_invoice_url}>
                  {i18n.t(keys.paySubscription)}
                </Button>
              </div>
            </Tooltip>
      } />
  }

  return <Typography>Error</Typography>
}

export const OrganizationSubscriptions = ({ subscriptions }: Props) => (
  <Grid container justifyContent="center">
    {subscriptions.map(({ id }) =>
      <Grid item xs={12} sm={6} md={4} sx={{ margin: 1, paddingLeft: '0 !important' }}>
        <Subscription key={id} id={id} />
      </Grid>
    )}
  </Grid>
)
