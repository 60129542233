import { get } from 'lodash'
import * as React from 'react'
import { Typography } from '@mui/material'
import { connect } from '../../redux/connect'
import { RootStore } from '../../redux/types'
import DataSetImageCard from './DataSetImageCard'
import {
  ListDataSetImageData,
  selectListDataSetImages,
} from './selectListDataSetImages'

class DataSetImages extends React.Component<ReduxProps> {
  render() {
    const dataSetImages = get<ListDataSetImageData[]>(
      this.props.dataSetImages as any,
      'data.data',
      []
    )

    return (
      <React.Fragment>
        <Typography variant="h6" align="center" style={{ padding: 12 }}>
          {dataSetImages.length} image
          {dataSetImages.length !== 1 && 's'} (uploaded via the Processing App)
        </Typography>

        {dataSetImages.map((dataSetImage) => (
          <DataSetImageCard key={dataSetImage.id} dataSetImage={dataSetImage} />
        ))}
      </React.Fragment>
    )
  }
}

const mapState = (state: RootStore) => ({
  dataSetImages: selectListDataSetImages(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(DataSetImages)
