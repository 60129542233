import * as React from 'react'
import { Button, Checkbox } from '@mui/material'

import useAsync from '../../../hooks/useAsync'

import { AccountPage } from '../AccountPage'
import { fetchNotifications, notifications, Unsub } from './fetchNotifications'
import { useRedux } from '../../../hooks/useRedux'
import { selectMe } from '../../../data/selectMe'
import { query, gql } from '../../../graphql/client'
import i18n, { keys } from '../../../i18n'

import './Notifications.scss'

const isClean = (a: Unsub, b: Unsub) => {
  for (const [orgId, { emailNotifications }] of Object.entries(a)) {
    for (const key of notifications) {
      if (
        b[orgId]?.emailNotifications[key] != null &&
        !!emailNotifications[key] !== b[orgId]?.emailNotifications[key]
      ) {
        return false
      }
    }
  }

  return true
}

export function Notifications() {
  const [state] = useRedux()
  const me = selectMe(state)
  const [result, refresh] = useAsync(fetchNotifications, [me?.id])
  const orgs: Unsub = result.result ?? {}

  const [unsubs, setUnsubs] = React.useState<Unsub>({})

  const toggleEmailSubscription = (orgId: string, notificationType: string) =>
    setUnsubs({
      ...orgs,
      ...unsubs,
      [orgId]: {
        ...(orgs[orgId] ?? {}),
        ...(unsubs[orgId] ?? {}),
        emailNotifications: {
          ...(orgs[orgId]?.emailNotifications ?? {}),
          ...(unsubs[orgId]?.emailNotifications ?? {}),
          [notificationType]: !(
            unsubs[orgId]?.emailNotifications[notificationType] ??
            orgs[orgId]?.emailNotifications[notificationType]
          ),
        },
      },
    })

  const updateEmailSubscriptions = async () => {
    for (const [organizationId, { emailNotifications }] of Object.entries(
      unsubs
    )) {
      for (const [notificationType, subscribe] of Object.entries(
        emailNotifications
      )) {
        if (subscribe) {
          await query({
            variables: {
              userId: me?.id,
              organizationId,
              notificationType,
            },
            query: gql`
              mutation deleteUnsubscribeEmailNotification(
                $organizationId: Int!
                $userId: Int!
                $notificationType: String!
              ) {
                delete_UnsubscribeEmailNotification_by_pk(
                  userId: $userId
                  organizationId: $organizationId
                  notificationType: $notificationType
                ) {
                  userId
                  organizationId
                  notificationType
                }
              }
            `,
          })
        } else {
          await query({
            variables: {
              object: {
                userId: me?.id,
                organizationId,
                notificationType,
              },
            },
            query: gql`
              mutation insertUnsubscribeEmailNotification(
                $object: UnsubscribeEmailNotification_insert_input!
              ) {
                insert_UnsubscribeEmailNotification_one(
                  object: $object
                  on_conflict: {
                    constraint: UnsubscribeEmailNotification_pkey
                    update_columns: []
                  }
                ) {
                  userId
                  organizationId
                  notificationType
                }
              }
            `,
          })
        }
      }
    }

    refresh()
    setUnsubs({})
  }

  return (
    <AccountPage title="Notifications">
      <div className="Paper">
        <h2>{i18n.t(keys.notificationsManage)}</h2>
        {Object.entries(orgs).map(
          ([orgId, { name, emailNotifications }], index) => (
            <div key={index}>
              <h3>{name}</h3>
              {notifications.map((notificationType) => (
                <div
                  key={notificationType}
                  className="notification__container"
                  onClick={() =>
                    toggleEmailSubscription(orgId, notificationType)
                  }
                >
                  <Checkbox
                    checked={
                      unsubs[orgId]?.emailNotifications[notificationType] ??
                      emailNotifications[notificationType]
                    }
                  />
                  <div>
                    <h4>
                      {i18n.t(
                        keys.notifications[notificationType].subscribeText
                      )}
                    </h4>
                    <div>
                      {i18n.t(
                        keys.notifications[notificationType]
                          .subscriptionDescription
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        )}

        <div style={{ textAlign: 'end' }}>
          <Button
            disabled={isClean(orgs, unsubs)}
            onClick={updateEmailSubscriptions}
          >
            Update
          </Button>
        </div>
      </div>
    </AccountPage>
  )
}
