import { api, gql } from '../../../hasura/api'

export const fetchAdminRoleByPK = async (
  [userId, role]: [userId: string, role: string],
  skip: () => void
) => {
  if (userId == null || role == null) {
    return skip()
  }

  return api.adminRoles.getByPK(
    {
      pk: { userId, role },
    },
    gql`
      {
        userId
        role
      }
    `
  )
}
