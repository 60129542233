import { Typography } from '@mui/material'
import * as React from 'react'
import { PureComponent } from 'react'
import { createSelector } from 'reselect'
import Page from '../app/Page'
import { selectFilteredParcels } from '../data/selectFilteredParcels'
import { selectOrganization } from '../data/selectOrganization'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { selectSelectedGroup } from '../data/selectSelectedGroup'
import { selectSelectedParcelIds } from '../data/selectSelectedParcelIds'
import { getSelectedFlightDate } from '../data/userSelectionRedux'
import i18n, { keys } from '../i18n'
import { MapContextProvider } from '../map/withMap'
import { connect } from '../redux/connect'
import { RootStore } from '../redux/types'
import DateMenu from './DateMenu'
import RightSidebarTabs from './RightSidebarTabs'
import SidebarTabs from './SidebarTabs'
import { ReactComponent as SamplePlanIcon } from '../pages/SamplePlan/assets/sample_plan_icon.svg'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { url, urls } from '../appNavigation/urls'
import { ReactComponent as TractorIcon } from '../pages/RateMap/assets/tractor.svg'

/** Separated from MapView to isolate re-renders to save rebuilding the MapBox unnecessarily.  */
class MapViewPage extends PureComponent<ReduxProps & RouteComponentProps> {
  render() {
    const titleInfo = this.props.titleInfo

    const title = titleInfo?.mapViewTitle
    const subtitle = titleInfo?.mapViewSubtitle

    return (
      <MapContextProvider>
        <Page
          leftSidebar={<SidebarTabs />}
          rightSidebar={<RightSidebarTabs />}
          navChildren={<DateMenu />}
          alignTitle="left"
          title={title}
          subtitle={subtitle}
          tools={[
            {
              id: 'sample-planning',
              name: i18n.t(keys.samplePlanDashboard),
              description: i18n.t(keys.samplePlanDescription),
              icon: <SamplePlanIcon />,
              onClick: () => {
                this.props.history.push(url(urls.samplePlans))
              },
            },
            {
              id: 'rate-maps',
              name: i18n.t(keys.rateMap),
              description: i18n.t(keys.rateMapDescription),
              icon: <TractorIcon />,
              onClick: () => {
                this.props.history.push(url(urls.rateMaps))
              },
            },
          ]}
        >
          <div id="MAP_VIEW_CONTAINER">
            <div className="print-title print-only">
              <Typography
                style={{ fontWeight: 500 }}
                variant="subtitle1"
                color="inherit"
              >
                {title}
              </Typography>
              {subtitle && (
                <Typography variant="caption" color="inherit">
                  {subtitle}
                </Typography>
              )}
            </div>
            {this.props.children}
          </div>
        </Page>
      </MapContextProvider>
    )
  }
}

interface MapViewTitle {
  mapViewTitle: string
  mapViewSubtitle?: string
}

const getIsFullyLoggedIn = (state: RootStore) =>
  !state.login.isInProgress &&
  !state.login.isPostLoginInProgress &&
  state.login.isLoggedIn

const selectMapViewTitle = createSelector(
  selectFilteredParcels,
  selectSelectedParcelIds,
  selectSelectedGroup,
  selectOrganization,
  getSelectedFlightDate,
  getIsFullyLoggedIn,
  // invalidate selector when language changes
  selectPreferredLanguage,
  (
    filteredParcels,
    selectedParcelIds,
    selectedGroup,
    organization,
    selectedFlightDate,
    isFullyLoggedIn
  ) => {
    if (!isFullyLoggedIn) {
      return {
        mapViewTitle: '',
        mapViewSubtitle: '',
      }
    }
    const organizationName = organization?.name ?? ''
    const selectedDate = selectedFlightDate ?? ''

    if (!selectedGroup) {
      return {
        mapViewTitle: organizationName,
        mapViewSubtitle: i18n.t(keys.map.selectAField),
      }
    }

    let mapViewTitle = selectedGroup.name
    if (organization?.name) {
      mapViewTitle = `${organization.name} | ${selectedGroup.name}`
    }

    let mapViewSubtitle: React.ReactNode = selectedDate

    const selected = filteredParcels.filter(({ id: parcelId }) =>
      selectedParcelIds.has(parcelId)
    )

    if (selected.length === 0) {
      return {
        mapViewTitle: organizationName,
        mapViewSubtitle: i18n.t(keys.map.selectAField),
      }
    }

    if (selected.length === 1) {
      mapViewSubtitle = selected[0] ? selected[0].name : ''
    } else if (selected.length > 1) {
      const totalParcels = selectedGroup.parcels.length
      if (selected.length === totalParcels) {
        mapViewSubtitle = i18n.t(keys.map.allParcels, {
          totalParcels: totalParcels.toFixed(0),
        })
      } else {
        mapViewSubtitle = i18n.t(keys.map.someParcels, {
          selectedParcels: selected.length.toFixed(0),
          totalParcels: totalParcels.toFixed(0),
        })
      }
    }

    return { mapViewTitle, mapViewSubtitle } as MapViewTitle
  }
)

const mapState = (state: RootStore) => ({
  titleInfo: selectMapViewTitle(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps>(mapState)(withRouter(MapViewPage))
