import * as React from 'react'

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'

import { NameAndValue } from '../../../admin/mapdata/types'
import dayjs from 'dayjs'

interface Props
  extends Partial<Omit<DatePickerProps<Date>, 'format' | 'onChange'>> {
  placeholder?: string
  name: string
  required?: boolean
  onChange(v: NameAndValue): void
  begin?: 'start-of-day' | 'end-of-day'
}

class DatePickerYMD extends React.PureComponent<Props> {
  render() {
    const { name, value, onChange, required, placeholder, begin, ...props } =
      this.props

    return (
      <DatePicker
        format="YYYY-MM-DD"
        slotProps={{ field: { clearable: true }, textField: { placeholder } }}
        {...props}
        // dates from graphql are YMD
        value={value ? (dayjs(value) as any) : null}
        // dates must remain YMD to avoid timezone issues
        onChange={(date: Date) =>
          onChange({
            name,
            value:
              !!begin && begin === 'start-of-day'
                ? dayjs(date, { utc: true }).startOf('day').toDate()
                : dayjs(date, { utc: true }).endOf('day').toDate(),
          })
        }
      />
    )
  }
}

export default DatePickerYMD
