import './Table.scss'

import * as React from 'react'

import { TablePagination as MLTablePagination } from '@mui/material'

import { Pagination, PaginationChange } from './types'
import i18n, { keys } from '../../i18n'

export interface TablePaginationProps {
  pagination?: Pagination
  pageSizeOptions?: number[]
  onPaginationChange?: (pagination: PaginationChange) => void
}

const noop = () => undefined

export class TablePagination extends React.PureComponent<TablePaginationProps> {
  handlePaginationChange = (newPagination: Partial<PaginationChange>) => {
    const {
      pagination: { page: oldPage = 0, pageSize: oldPageSize = 10 } = {},
      onPaginationChange = noop,
    } = this.props

    const { page = oldPage, pageSize = oldPageSize } = newPagination

    onPaginationChange({ page, pageSize })
  }

  handleChangePage = (_event: any, page: number) =>
    this.handlePaginationChange({ page })

  handleChangeRowsPerPage = (event: any) =>
    this.handlePaginationChange({ page: 0, pageSize: event.target.value })

  render() {
    const { pagination, pageSizeOptions = [10, 25, 50, 100] } = this.props
    if (!pagination) {
      return null
    }

    const { count, page, pageSize } = pagination

    return (
      <MLTablePagination
        rowsPerPageOptions={pageSizeOptions}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        labelRowsPerPage={i18n.t(keys.tableRowsPerPage)}
        backIconButtonProps={{
          'aria-label': i18n.t(keys.previousPage),
        }}
        nextIconButtonProps={{
          'aria-label': i18n.t(keys.nextPage),
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    )
  }
}
