import { createSelector } from 'reselect'
import { RootStore } from '../redux/types'
import { selectProducts } from './selectProducts'

const selectReportFormValues = (state: RootStore) =>
  state.reports.reportFormValues

export const selectReportsPageParams = createSelector(
  selectReportFormValues,
  selectProducts,
  (reportFormValues, products) => {
    if (!reportFormValues) {
      // reset state
      return {
        reportProducts: [],
        reportProductOptions: {},
        includeRelative: false,
      }
    }

    // see which products are selected at all
    const reportProducts = products
      .filter(({ id }) => {
        const options = reportFormValues.products?.[id]

        return options && (options.hasGroupPage || options.hasBlockPage)
      })
      .map((product) => product.id)

    const includeRelative = !!reportFormValues.includeRelative

    return {
      reportProducts,
      includeRelative,
      reportProductOptions: reportFormValues.products,
    }
  }
)
