import { setToggle } from '../../../util/setToggle'
import { Field } from '../../../vvapi/models'

interface SampleFieldWithValue extends Field {
  value: string | number | string[] | boolean
}

interface SampleFormState {
  content: Record<string, SampleFieldWithValue>
}

type SampleFormAction =
  | {
      type: 'setForm'
      formContent: SampleFormState
    }
  | {
      type: 'setSampleContent'
      field: Omit<Field, 'title'>
      title: string
      value: any
    }
  | {
      type: 'setSampleCheckboxesContent'
      field: Omit<Field, 'title'>
      title: string
      value: any
    }
  | {
      type: 'reset'
    }

const initialState: SampleFormState = {
  content: {},
}

const reducer = (state: SampleFormState, action: SampleFormAction) => {
  if (action.type === 'reset') {
    return initialState
  }
  if (action.type === 'setForm') {
    return action.formContent
  }
  if (action.type === 'setSampleContent') {
    return {
      ...state,
      content: {
        ...state.content,
        [action.title]: { ...action.field, value: action.value },
      },
    }
  }
  if (action.type === 'setSampleCheckboxesContent') {
    const selectionSet = new Set(
      (state.content[action.title]?.value as string[]) ?? []
    )

    const value = Array.from(setToggle(selectionSet, action.value))

    return {
      ...state,
      content: {
        ...state.content,
        [action.title]: { ...action.field, value },
      },
    }
  }

  return state
}

export {
  SampleFieldWithValue,
  SampleFormState,
  SampleFormAction,
  reducer,
  initialState,
}
