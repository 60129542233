import './ReportProductPage.scss'

import * as React from 'react'

import { Typography } from '@mui/material'
import * as Sentry from '@sentry/browser'

import { applyColorProfileSettings } from '../../colorProfile/applyColorProfileSetting'
import {
  selectColorProfilesById, selectColorProfilesByProductId, selectMapLayerDefsById
} from '../../data/selectListMapSourceDefs'
import { selectOrganization } from '../../data/selectOrganization'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import { MapColorProfileData, MapSourceData } from '../../data/types'
import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import { addBoundLayers } from '../../postgis/selectors/FieldsLayerSelector'
import { makeProductSourcesAndLayers } from '../../postgis/selectors/SelectedProductLayerSelector'
import { selectProductSettings } from '../../ProductSettings/store/selectors/selectProductSettings'
import {
  selectConversionsByProductId
} from '../../ProductStats/selectors/selectConversionsByProductId'
import {
  selectActiveColorProfiles, selectBestUnitsByProductId, selectConvertedSelectedColorProfiles
} from '../../ProductStats/selectors/stats'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import * as bbox from '../../util/bbox'
import { classnames } from '../../util/classnames'
import coalesce from '../../util/coalesce'
import { formatNum } from '../../util/formatNumber'
import handleError from '../../util/handleError'
import { isNumericIdEqual } from '../../util/isNumericIdEqual'
import { convertLength } from '../../util/units/length'
import { selectReportGroup } from '../selectReportGroup'
import { selectReportMapLayers } from '../selectReportMapLayers'
import { selectGetReportStats, selectReportStats } from '../selectReportStats'
import { ProductPage } from '../types'
import FlightInformation from './FlightInformation'
import MapImage from './map/MapImage'
import { MapImageProps } from './map/types'
import ReportLegend from './ReportLegend'
import ReportLogo from './ReportLogo'
import MapLoader from './map/MapLoader'

interface Props {
  page: ProductPage
}

class ReportProductPage extends React.PureComponent<Props & ReduxProps> {
  /** cache for sourcesAndLayers */
  _sourcesAndLayers: MapImageProps['layers']

  container: HTMLDivElement | null

  /**
   * product layers are any layers that match product and parcel
   */
  getProductLayers = () => {
    const {
      page: {
        parcel,
        product: { id: productId },
      },
      r: { reportMapLayers },
    } = this.props

    let layers = reportMapLayers.filter(
      ({ mapLayerDefId }) => mapLayerDefId === productId
    )

    if (parcel) {
      const { deliveryId, parcelId } = parcel

      layers = layers.filter(
        ({ mapSource }) =>
          mapSource.parcel.deliveryId === deliveryId &&
          isNumericIdEqual(mapSource.parcel.parcelId, parcelId)
      )
    }

    return layers
  }

  /**
   * get rasters for the background of each product page
   */
  getBackgroundLayers = () => {
    const {
      page: { parcel },
      selectedBackgroundProductId,
      r: { reportBackgroundRasterLayers: backgroundLayers },
    } = this.props

    const result = backgroundLayers.filter((layer) => {
      if (
        parcel &&
        layer.mapSource.parcel.deliveryId !== parcel.deliveryId &&
        layer.mapSource.parcel.parcelId !== parcel.parcelId
      ) {
        return false
      }

      return layer.mapLayerDefId === selectedBackgroundProductId
    })

    return result
  }

  getProductTitle = () => {
    const {
      page: {
        mode,
        product: { name: productName },
      },
    } = this.props

    if (mode) {
      return `${productName} - ${i18n.t(keys.visualization[mode])}`
    }

    return productName
  }

  isRaster = () => {
    return this.props.page.product.mapSourceDef.type.startsWith('raster')
  }

  /**
   * combines bounds from all product layers
   */
  getBounds = () => {
    const layers = this.isRaster()
      ? this.getProductLayers()
      : [...this.getProductLayers(), ...this.getBackgroundLayers()]
    const boxes = layers.map((layer) => bbox.parse(layer.mapSource.bounds))
    const bounds = bbox.combine(boxes)

    // requires an alternate format: number[][]
    return [bounds.slice(0, 2), bounds.slice(2, 4)]
  }

  getParcels = () => {
    const layers = this.getProductLayers()

    return layers.map((l) => l.mapSource.parcel)
  }

  /** get mapbox-formatted sources and layers from map layers */
  getSourcesAndLayers = () => {
    if (this.props.reportStatsSelector.status !== 'resolved') {
      return
    }

    if (this._sourcesAndLayers) {
      return this._sourcesAndLayers
    }

    const {
      settingsByProductId,
      page: { product },
      productsById,
    } = this.props

    const productLayers = this.getProductLayers()
    const backgroundLayers = this.getBackgroundLayers()
    const [firstProductLayer] = productLayers
    const productId = firstProductLayer?.mapLayerDefId

    // we have several fetchers, so we need to make sure
    // that we have all the data to get sources and layers
    if (
      productLayers.length === 0 ||
      !productId ||
      !(productId in productsById)
    ) {
      return undefined
    }

    const allLayers = this.isRaster()
      ? [{ layers: productLayers, isBlockByBlockAverage: false }]
      : [{ layers: [...backgroundLayers, ...productLayers], isBlockByBlockAverage: false }]

    const allSources = allLayers.reduce((sources, mapLayers) => {
      for (const mapLayer of mapLayers.layers) {
        if (!!mapLayer.mapSource) {
          sources[mapLayer.id] = [...sources[mapLayer.id] ?? [], mapLayer.mapSource]
        }
      }
      return sources
    }, {} as Record<string, MapSourceData[]>)

    this._sourcesAndLayers = makeProductSourcesAndLayers(
      allSources,
      allLayers,
      productsById,
      settingsByProductId,
      { [product.id]: this.getColor() }
    )

    if (this.props.reportFormValues?.showBlockBoundaries) {
      addBoundLayers(
        this.getParcels(),
        'PARCEL_BOUNDARIES',
        this._sourcesAndLayers!
      )
    }

    return this._sourcesAndLayers
  }

  /**
   * reports have multiple modes displayed on the same page,
   * so we can't grab colors from "current" selectors
   */
  getModeColor = () => {
    const {
      settingsByProductId,
      selectedColorByProductId,
      colorByProductId,
      activeColorByProductId,
      colorById,
      page: { product, mode },
      reportStats
    } = this.props

    const productId = product.id
    const settings = settingsByProductId[productId] ?? {}

    const { colorIdByVisualization } = settings

    if (!mode) {
      return
    }

    if (colorIdByVisualization?.[mode]) {
      return colorById[colorIdByVisualization[mode]!]
    }

    if (selectedColorByProductId[mode]?.[productId]) {
      return selectedColorByProductId[mode][productId]
    }

    const profiles = [
      activeColorByProductId[productId],
      ...(colorByProductId[productId] ?? []),
    ]

    const color = this.chooseColorProfileForMode(profiles, mode)

    const productReportStats = reportStats?.[product.id]
    if (productReportStats) {
      return applyColorProfileSettings(product, color!, mode, settings,
        productReportStats.range!
      )
    }
    return color
  }

  chooseColorProfileForMode = (
    profiles: (MapColorProfileData | undefined)[],
    mode?: string
  ) => {
    return (
      profiles &&
      (profiles.find(
        (p) =>
          !!p &&
          (p.visualizations ?? []).some(
            (v) => v.visualization === mode && v.default
          )
      ) ||
        profiles.find(
          (p) =>
            !!p &&
            (p.visualizations ?? []).some((v) => v.visualization === mode)
        ) ||
        profiles.find((p) => !!p))
    )
  }

  chooseColorProfile = (profiles: (MapColorProfileData | undefined)[]) => {
    return (
      profiles &&
      (profiles.find(
        (p) => !!p && (p.visualizations ?? []).some((v) => v.default)
      ) ||
        profiles.find((p) => !!p))
    )
  }

  /** conditionally gets mode color or default color */
  getColor = () => {
    const {
      activeColorByProductId,
      colorByProductId,
      page: { product },
    } = this.props

    const color =
      this.getModeColor() ||
      activeColorByProductId[product.id] ||
      this.chooseColorProfile(colorByProductId[product.id])

    return color
  }

  render() {
    const {
      page: { parcel, product, mode, title },
      date,
      group,
      organization,
      reportStatsSelector
    } = this.props

    if (reportStatsSelector.status === 'pending' || reportStatsSelector.status === 'none') {
      return <MapLoader />
    }

    const sourcesAndLayers = this.getSourcesAndLayers()

    if (!organization || !sourcesAndLayers) {
      return null
    }

    const mapKey: string[] = [product.id]

    if (mode) {
      mapKey.push(mode)
    }

    if (parcel) {
      const { deliveryId, parcelId } = parcel
      mapKey.push(String(`${deliveryId}/${parcelId}`))
    }

    return (
      <article
        className={classnames('ReportProductPage', ['hasParcel', !!parcel])}
      >
        {!!parcel && !!date && !!group && (
          <header className="ReportProductPage__block">
            <Typography>
              {i18n.t(keys.stats.date)} | <b>{i18n.toDateLong(date)}</b>
            </Typography>
            <Typography>
              {i18n.t(keys.reports.client)} | <b>{organization!.name}</b>
            </Typography>
            <Typography>
              {i18n.t(keys.reports.ranch)} | <b>{group!.name}</b>
            </Typography>
            {this.renderVineSpace()}
            <Typography className="ReportProductPage__title">
              {this.getProductTitle()}
            </Typography>
          </header>
        )}
        <div className="ReportProductPage__container">
          <aside className="ReportProductPage__Information">
            <FlightInformation
              title={title}
              product={product}
              parcel={parcel}
            />
            {this.renderLegend()}
            <div className="ReportLogo">
              <ReportLogo width="160" height="auto" />
            </div>
          </aside>
          <div ref={this.setContainer} className="ReportProductPage__Map">
            <MapImage
              key={mapKey.join('-')}
              container={this.container}
              style="mapbox://styles/vineview/cjwhzylct0lhe1dqxys0x3i2v"  // eslint-disable-line react/style-prop-object
              bounds={this.getBounds()}
              layers={sourcesAndLayers}
            />
          </div>
        </div>
      </article>
    )
  }

  /** gets and formats vine space from parcel dataset */
  renderVineSpace() {
    const {
      page: { parcel },
      preferredUnitSystem,
    } = this.props

    if (!parcel) {
      return null
    }
    const parcelMeta = parcel.meta ?? {}

    const attribute = coalesce(
      parcelMeta.vineSpace || parcelMeta.vineSpacing,
      parcelMeta.VVSPACE
    )

    if (!attribute) {
      Sentry.addBreadcrumb({
        level: Sentry.Severity.Warning,
        category: 'reports',
        data: {
          parcelMeta,
        },
      })
      handleError('Failed getting vine spacing')

      return null
    }

    const measurement = convertLength(attribute, 'meter', preferredUnitSystem)

    return (
      <Typography>
        {i18n.t(keys.reports.vineSpacing)} |
        <b className="Report__vineSpace">
          {` ${formatNum(measurement.value, false, 1, 2)} ${measurement.symbol
            }`}
        </b>
      </Typography>
    )
  }

  renderLegend = () => {
    const {
      page: { product },
      preferredLanguage,
      reportStats,
      unitsByProduct,
      preferredUnitSystem,
    } = this.props

    return (
      <section className="ReportProductPage__LegendSection">
        <ReportLegend
          product={product}
          activeColor={this.getColor()}
          preferredLanguage={preferredLanguage}
          productStats={reportStats[product.id]}
          unit={unitsByProduct[product.id]}
          preferredUnitSystem={preferredUnitSystem}
        />
      </section>
    )
  }

  setContainer = (elem: HTMLDivElement | null) => {
    if (this.container !== elem) {
      this.container = elem
      this.forceUpdate()
    }
  }
}

const mapState = (state: RootStore) => ({
  organization: selectOrganization(state),
  date: getSelectedFlightDate(state),
  group: selectReportGroup(state),
  preferredUnitSystem: state.preferences.preferredUnitSystem,
  preferredLanguage: selectPreferredLanguage(state),
  settingsByProductId: selectProductSettings(state),
  colorByProductId: selectColorProfilesByProductId(state),
  colorById: selectColorProfilesById(state),
  activeColorByProductId: selectActiveColorProfiles(state),
  selectedColorByProductId: selectConvertedSelectedColorProfiles(state),
  conversionsByProduct: selectConversionsByProductId(state),
  unitsByProduct: selectBestUnitsByProductId(state),
  productsById: selectMapLayerDefsById(state),
  reportStatsSelector: selectGetReportStats(state),
  reportStats: selectReportStats(state),
  r: selectReportMapLayers(state),
  selectedBackgroundProductId: state.userSelection.selectedBackgroundProductId,
  reportFormValues: state.reports.reportFormValues,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, Props, AppDispatchProps>(mapState)(
  ReportProductPage
)
