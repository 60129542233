import { dirname } from 'path-browserify'
import { Delivery, Organization, Parcel } from '../../../graphql/types'
import { calculateMD5Hash } from '../../../util/calculateMD5Hash'
import { indexArray } from '../../../util/indexArray'
import { asyncReduce } from '../../../util/syncArrayHelpers'
import { ListDeliveryParcelFileStatus } from '../Delivery/DeliveryParcelFileStatus/types'
import readJSON from '../Delivery/readJSON'
import { FlightData } from './selectGetFlight'
const vvParcelgeojson = 'vv-parcel.geojson'

export interface ParcelProperties extends Record<string, any> {
  orgName: string
  groupName: string
  parcelName: string
  deliveryId?: Delivery['id']
  parcelId?: Parcel['id']
  orgId?: Organization['id']
  ImageId?: number
  DataSetID?: number
}

export type ParcelJSONData = GeoJSON.FeatureCollection<
  GeoJSON.Polygon,
  ParcelProperties
>

export interface PreparedFile {
  file: File
  parcel: ParcelProperties
  status: string
  checksum: string
  checksumAlgorithm: 'md5'
}

export const prepareUpload = async (
  filelist: File[],
  flightData: FlightData,
  deliveryParcelFilesByPK: Record<string, ListDeliveryParcelFileStatus>,
  reportProgress: (progress: number, estimate: number | 'calculating') => void
) => {
  reportProgress(0, 'calculating')

  let files = Array.from(filelist)

  const parcels = await findParcels(files)

  files = files.filter(
    ({ webkitRelativePath, fullPath }) =>
      dirname(!!webkitRelativePath ? webkitRelativePath : fullPath) in parcels
  )

  const startTime = Date.now()
  const totalBytes = files.reduce((sum, curr) => sum + curr.size, 0)
  let processedBytes = 0

  const filesToUpload = await asyncReduce(
    files,
    { limit: 1 },
    {} as Record<string, Record<string, PreparedFile[]>>,
    async (fileMap, file) => {
      const parcel =
        parcels[
          dirname(
            !!file.webkitRelativePath ? file.webkitRelativePath : file.fullPath
          )
        ]
      const { checksum, checksumAlgorithm } = await calculateMD5Hash(file)
      const dpfPK = `${flightData.deliveryId}_${parcel.parcelId}_${file.name}`
      const existingDpf = deliveryParcelFilesByPK[dpfPK]
      let status = 'New'

      if (existingDpf) {
        if (existingDpf.File.checksum === checksum) {
          status = 'Unchanged'
        } else {
          status = 'Updated'
        }
      }

      processedBytes += file.size
      const bytesLeftToProcess = totalBytes - processedBytes

      const timeElapsed = Date.now() - startTime
      reportProgress(
        (processedBytes / totalBytes) * 100,
        (timeElapsed / processedBytes) * bytesLeftToProcess
      )

      fileMap[parcel.groupName] = {
        ...(fileMap[parcel.groupName] ?? {}),
        [parcel.parcelId!]: [
          ...((fileMap[parcel.groupName] ?? {})[parcel.parcelId!] ?? []),
          {
            file,
            parcel,
            status,
            checksum,
            checksumAlgorithm,
          },
        ],
      }

      return fileMap
    }
  )

  const parcelsById = indexArray(Object.values(parcels), 'parcelId')

  return { filesToUpload, parcelsById }
}

const findParcels = async (files: File[]) => {
  const parcels = files.filter(({ name }) => name === vvParcelgeojson)

  const parcelsByDir: Record<string, ParcelProperties> = {}

  for (const parcel of parcels) {
    const properties = await getParcelProperties(parcel)
    if (properties) {
      parcelsByDir[
        dirname(
          !!parcel.webkitRelativePath
            ? parcel.webkitRelativePath
            : parcel.fullPath
        )
      ] = properties
    }
  }

  return parcelsByDir
}

const getParcelProperties = async (parcel: File) => {
  const vvParcelGeojson = await readJSON<ParcelJSONData>(parcel)

  return vvParcelGeojson?.features?.[0]?.properties
}
