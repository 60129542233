import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel'
import Select, { SelectProps } from '@mui/material/Select'
import * as React from 'react'
import { FormFieldBaseProps } from './createControlLabelField'

export type SelectFieldProps = FormFieldBaseProps &
  SelectProps & {
    label: React.ReactNode
    labelProps?: InputLabelProps
  }

export default class SelectField extends React.Component<SelectFieldProps> {
  render() {
    const {
      error,
      className,
      fullWidth,
      required,

      label,
      labelProps,

      helperText,
      helperTextProps,

      ...other
    } = this.props

    return (
      <FormControl
        className={className}
        error={error}
        fullWidth={fullWidth}
        required={required}
      >
        {label && <InputLabel {...labelProps}>{label}</InputLabel>}

        <Select {...other} />

        {helperText && (
          <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
        )}
      </FormControl>
    )
  }
}
