import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
  setFocusedSoilLayer,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import * as notes from '../../../../notes/redux'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class AreaNoteActionGenerator implements IMapActionGenerator {
  key = 'area-note'
  priority = Priority.AreaNote

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
  }: IMapActionGeneratorParams): IMapAction[] {
    // Find the qualifying features.
    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) =>
        f.layer.id === 'NOTE-MARKERS-POLYGONS'
    )

    // Return array of functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        dispatch(
          notes.actions.setEditingNoteId({
            noteId: q.properties?.id as string,
          })
        )
        dispatch(setFocusedPoint(null))
        dispatch(setFocusedPolygon(null))
        dispatch(setFocusedBlockId(undefined))
        dispatch(setFocusedSoilLayer(null))
      },
    }))
  }
}
