import * as React from 'react'
import { PureComponent } from 'react'
import {
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { updatePreferences } from '../redux/preferences/redux'
import { AppDispatchProps } from '../redux/types'

interface ReduxProps {
  selectedStatistics: string
}

const statisticsOptions = () => [
  {
    value: 'values',
    title: i18n.t(keys.statistics.values),
  },
  {
    value: 'percentages',
    title: i18n.t(keys.statistics.percentages),
  },
]

class PreferredStatistics extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    return (
      <React.Fragment>
        <ListItem>
          <FormControl fullWidth>
            <InputLabel>{i18n.t(keys.statistics.statisticsPicker)}</InputLabel>
            <Select
              value={this.props.selectedStatistics}
              onChange={this.handleChangeStatistics}
              fullWidth
            >
              {statisticsOptions().map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
      </React.Fragment>
    )
  }

  handleChangeStatistics = (event: SelectChangeEvent) => {
    this.props.dispatch(
      updatePreferences({ preferredStatistics: event.target.value as any })
    )
  }
}

export default connect<ReduxProps, {}, AppDispatchProps>((state) => ({
  selectedStatistics: state.preferences.preferredStatistics,
}))(PreferredStatistics)
