import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { default as Icon } from '@mui/material/Icon'
import { default as Typography } from '@mui/material/Typography'

import { url, urls } from '../../appNavigation/urls'
import { MapSourceDef } from '../../graphql/types'
import TooltipIconButton from '../../UI/TooltipIconButton'
import { GetMapSourceDefData } from '../MapSourceDef/selectGetMapSourceDef'
import deleteMapLayerDef from './deleteMapLayerDef'

interface Props {
  mapSourceDefId: MapSourceDef['id']
  layerDefs: GetMapSourceDefData['MapLayerDefs']
  onChange(): void
}

class MapSourceDefMapLayerDefList extends React.PureComponent<
  Props & RouteComponentProps
> {
  render() {
    return (
      <div className="grid-xs-12">
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1">Layer Definitions</Typography>
          <TooltipIconButton
            tooltipProps={{
              placement: 'right',
            }}
            title={`Create a layer definition `}
            onClick={this.handleClickCreate}
          >
            <Icon fontSize="small">add_circle_outline</Icon>
          </TooltipIconButton>
        </div>
        <div>
          {(this.props.layerDefs ?? []).map((layerDef) => (
            <div key={layerDef.id}>
              <Typography component="span">
                {layerDef.name}
                <TooltipIconButton
                  title={`Edit layer definition `}
                  onClick={() => this.handleClickEdit(layerDef.id)}
                >
                  <Icon fontSize="small">edit</Icon>
                </TooltipIconButton>
                <TooltipIconButton
                  title={`Delete layer definition `}
                  onClick={() => this.handleDelete(layerDef)}
                >
                  <Icon fontSize="small">delete</Icon>
                </TooltipIconButton>
              </Typography>
            </div>
          ))}
        </div>
      </div>
    )
  }

  handleDelete = async (
    layerDef: UnwrapArray<GetMapSourceDefData['MapLayerDefs']>
  ) => {
    await deleteMapLayerDef([layerDef.id])
    this.props.onChange()
  }

  handleClickEdit = (mapLayerDefId: string) => {
    this.props.history.push(url(urls.editLayerDef, { mapLayerDefId }))
  }

  handleClickCreate = () => {
    const { mapSourceDefId } = this.props
    this.props.history.push(
      url(urls.newLayerDefFromSourceDef, { mapSourceDefId })
    )
  }
}

export default withRouter(MapSourceDefMapLayerDefList)
