import { createSelector } from 'reselect'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { gql, query } from '../../../graphql/client'
import { RootStore } from '../../../redux/types'
import { indexArray, indexMultiArrayMappedKey } from '../../../util/indexArray'

export interface DeliveryGroupStatus {
  groupId: string
  fulfilled: boolean
  expected: boolean
  enabled: boolean
  ready: boolean
  totalCount: number
  enabledCount: number
  expectedCount: number
  readyCount: number
  fulfilledCount: number
  deliveryGroup: {
    group: {
      id: number
      name: string
    }
  }
  parcelFiles: {
    mapLayerDef: {
      name: string
    }
    parcel: {
      parcelId: string
      name: string
    }
    ready: boolean
    expected: boolean
    enabled: boolean
    MapLayer?: {
      id: string
      enabled: boolean
    }
  }[]
}

interface DeliveryStatus {
  fulfilled: boolean
  enabled: boolean
  expected: number
  totalCount: number
  expectedCount: number
  readyCount: number
  missingCount: number
  extraCount: number
  fulfilledCount: number
  enabledCount: number
}

export interface GetDetailedDeliveryStatus {
  status: [DeliveryStatus]
  groups: DeliveryGroupStatus[]
}

export type DetailedDeliveryStatus = keyof Omit<
  DeliveryStatus,
  'enabled' | 'fulfilled' | 'expected'
>

const DETAILED_DELIVERY_STATUS = gql`
  query DETAILED_DELIVERY_STATUS($deliveryId: uuid) {
    status: View_DeliveryStatus(where: { deliveryId: { _eq: $deliveryId } }) {
      totalCount
      fulfilledCount
      enabledCount
      expectedCount
      readyCount
      missingCount
      extraCount
      expected
      fulfilled
      enabled
    }
    groups: View_DeliveryGroupStatus(
      where: { deliveryId: { _eq: $deliveryId } }
      order_by: { DeliveryGroup: { OrganizationGroup: { name: asc } } }
    ) {
      groupId
      fulfilled
      enabled
      expected
      totalCount
      enabledCount
      expectedCount
      readyCount
      fulfilledCount
      deliveryGroup: DeliveryGroup {
        group: OrganizationGroup {
          name
          id
        }
      }
      parcelFiles: DeliveryParcelStatuses {
        mapLayerDef: MapLayerDef {
          name(path: "en")
        }
        MapLayer {
          id
          enabled
        }
        parcel: DeliveryParcel {
          parcelId
          name
        }
        ready
        enabled
        expected
      }
    }
  }
`

const {
  selector: selectGetDetailedDeliveryStatus,
  refresh: refreshGetDetailedDeliveryStatus,
} = createAsyncSelector({
  resource: 'GetDetailedDeliveryStatus',
  inputs: {
    deliveryId: (state: RootStore) => state.router.params.deliveryId,
  },
  fetcher: async ({ deliveryId }) => {
    if (!deliveryId) {
      return
    }

    const data = await query<GetDetailedDeliveryStatus>({
      variables: { deliveryId },
      query: DETAILED_DELIVERY_STATUS,
    })

    if (!data) {
      throw new Error('No Data')
    }

    const {
      status: [
        {
          fulfilled,
          expected,
          enabled,
          totalCount,
          enabledCount,
          expectedCount,
          fulfilledCount,
          readyCount,
          extraCount,
          missingCount,
        },
      ],

      groups,
    } = data

    return {
      fulfilled,
      enabled,
      expected,
      totalCount,
      expectedCount,
      fulfilledCount,
      enabledCount,
      readyCount,
      missingCount,
      extraCount,
      groups,
    }
  },
})

export { selectGetDetailedDeliveryStatus, refreshGetDetailedDeliveryStatus }

export interface GroupStatus {
  groupId: string
  parcelFiles: ParcelStatuses
}

export type GroupStatuses = Record<number, GroupStatus>

export interface ParcelStatus {
  files: DeliveryGroupStatus['parcelFiles']
  fulfilledCount: number
  expectedCount: number
  enabledCount: number
  totalCount: number
  readyCount: number
  expected: boolean
  enabled: boolean
  ready: boolean
  fulfilled: boolean
}

export type ParcelStatuses = Record<number, ParcelStatus>

const EMPTY_STATUSES: GroupStatuses = {}

export const selectParcelFileStatusesByGroupByParcel = createSelector(
  selectGetDetailedDeliveryStatus,
  (deliveryStatusSelector) => {
    if (!deliveryStatusSelector.data) {
      return EMPTY_STATUSES
    }

    const groupsWithIndexedParcels = deliveryStatusSelector.data.groups.map(
      ({ groupId, parcelFiles }) => ({
        groupId,
        parcelFiles: indexMultiArrayMappedKey(
          parcelFiles,
          (pf) => pf.parcel.name
        ),
      })
    )

    const groups = indexArray(groupsWithIndexedParcels, 'groupId')

    return Object.entries(groups).reduce((groupMap, [groupId, group]) => {
      groupMap[groupId] = {
        ...group,
        parcelFiles: Object.entries(group.parcelFiles).reduce(
          (parcelMap, [parcelName, files]) => {
            const totalCount = files.length
            const readyCount = files.filter((f) => f.ready).length
            const expectedCount = files.filter((f) => f.expected).length
            const fulfilledCount = 0
            const fulfilled = false
            const enabledCount = files.filter((f) => f.enabled).length

            parcelMap[parcelName] = {
              files,
              readyCount,
              expectedCount,
              enabledCount,
              totalCount,
              fulfilledCount,
              fulfilled,
              expected: expectedCount < 0,
              enabled: enabledCount === totalCount,
              ready: totalCount === readyCount,
            }

            return parcelMap
          },
          {} as ParcelStatuses
        ),
      }

      return groupMap
    }, {} as GroupStatuses)
  }
)
