import * as React from 'react'
import { Typography } from '@mui/material'
import i18n, { keys } from '../../i18n'
import { indexArray } from '../../util/indexArray'
import { ProductPage } from '../types'
import './ReportTableOfContents.scss'

interface Props {
  pages: ProductPage[]
}

class ReportTableOfContents extends React.PureComponent<Props> {
  render() {
    const { pages = [] } = this.props

    if (pages.length < 1) {
      return null
    }

    // maintain order, and strip duplicate products from pages
    const products = pages.map(({ product }) => product)
    const indexedProducts = indexArray(products, 'id')
    const productIds = Array.from(new Set(products.map(({ id }) => id)))

    return (
      <div id="ReportTOC">
        <div className="ReportHeader" />
        <section className="report_container">
          <Typography className="report_primary">
            {i18n.t(keys.reports.whatsInside)}
          </Typography>
          <div className="report_container">
            {productIds.map((productId) => {
              const product = indexedProducts[productId]!

              return (
                <div key={productId}>
                  <Typography className="ReportTOC__product_title">
                    {product.name}
                  </Typography>
                  <Typography className="ReportTOC__product_desc">
                    {product.description}
                  </Typography>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    )
  }
}

export default ReportTableOfContents
