import { useTheme } from '@mui/styles'
import * as React from 'react'

export const UserTagDecorator = (props: any) => {
  const theme = useTheme()

  return (
    <strong
      style={{
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.shape.borderRadius / 2,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      }}
    >
      {props.decoratedText.replace('@__', '')}
    </strong>
  )
}
