import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import * as api from '../../graphql/api'
import { MapLayerSettings, Translated } from '../../graphql/types'
import { RootStore } from '../../redux/types'
import { IHistoricalCalculationTypeProps } from './types/HistoricalChartCalculationType'

export interface GetMapLayerDefData {
  id: string
  name: Translated
  description: Translated
  pdfFilename?: string
  pdfFilenameTranslations: Translated
  dataProperty: string
  mapLayerDefGroupId?: string
  layerGroupLabel: Translated
  DataProperty: {
    id: string
    name: Translated
  }
  noDataProperty: string
  NoDataProperty: {
    id: string
    name: Translated
  }
  coverageProperty: string
  CoverageProperty: {
    id: string
    name: Translated
  }
  weightProperty: string
  WeightProperty: {
    id: string
    name: Translated
  }
  order: number
  enabled: boolean
  historicalChartCalculationType?: string
  historicalChartCalculationProperties?: IHistoricalCalculationTypeProps
  mapSourceDefId: string
  MapSourceDef: {
    id: string
    name: Translated
  }
  MapColorProfiles: {
    id: string
    name: Translated
  }[]
  MapLayerSettings: MapLayerSettings[]
  CreatedBy: {
    firstName: string
  }
  createdAt: string
  UpdatedBy: {
    firstName: string
  }
  updatedAt: string
}

const { selector: selectGetMapLayerDef, refresh: refreshGetMapLayerDef } =
  createAsyncSelector({
    resource: 'GetMapLayerDefData',
    inputs: {
      mapLayerDefId: (state: RootStore) => state.router.params.mapLayerDefId,
    },
    fetcher: async ({ mapLayerDefId }) => {
      if (!mapLayerDefId) {
        return
      }

      const { data } = await api.mapLayerDef.get<GetMapLayerDefData>({
        pk: { id: mapLayerDefId },
        returning: `{
      id
      name
      description
      pdfFilename
      pdfFilenameTranslations
      mapLayerDefGroupId
      layerGroupLabel
      dataProperty
      DataProperty {
        id
        name
      }
      noDataProperty
      NoDataProperty {
        id
        name
      }
      coverageProperty
      CoverageProperty {
        id
        name
      }
      weightProperty
      WeightProperty {
        id
        name
      }
      order
      enabled
      historicalChartCalculationType
      historicalChartCalculationProperties
      mapSourceDefId
      MapSourceDef {
        id
        name
      }
      MapColorProfiles {
        id
        name
      }
      MapLayerSettings {
        id
        visualization
        threshold
        organizationId
        opacity
        numberOfGroups
        groupBy
        coverageMin
        coverageMax
        coverage
      }
      CreatedBy {
        firstName
      }
      createdAt
      UpdatedBy {
        firstName
      }
      updatedAt
    }`,
      })

      if (!data) {
        throw new Error('No Data')
      }

      return data
    },
  })

export { selectGetMapLayerDef, refreshGetMapLayerDef }
