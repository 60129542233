import * as React from 'react'

import logoSvg from '../../../logo.svg'
import './MapLoader.scss'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <div className="MapLoader__container">
      <div className="MapLoader__icon_background">
        <img src={logoSvg} alt="" />
      </div>
    </div>
  )
}
