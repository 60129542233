import { default as TextField } from '@mui/material/TextField'
import * as React from 'react'
import { MapSourceDefFilename } from '../../../graphql/types'
import TranslationTextField from '../../../UI/TranslationTextField/TranslationTextField'
import { NameAndValue } from '../../mapdata/types'
import FormWrapper from '../../mapdata/utils/FormWrapper'

interface Props {
  instance: Partial<Writable<MapSourceDefFilename>>
  onChange(v: NameAndValue): void
  onSubmit(): void
}

class MapSourceFilenameForm extends React.PureComponent<Props> {
  render() {
    const { instance, onSubmit } = this.props

    if (!instance) {
      return null
    }

    return (
      <FormWrapper onSubmit={onSubmit}>
        <div className="grid-xs-12">
          <TextField
            required
            fullWidth
            name="filename"
            label={`Filename`}
            value={instance.filename ?? ''}
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            name="description"
            label={`Description`}
            value={instance.description ?? {}}
            onChange={this.props.onChange}
          />
        </div>
      </FormWrapper>
    )
  }

  handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => this.props.onChange(e.target)
}

export default MapSourceFilenameForm
