import { MapLayerDefData, MapSourcePropertyData } from '../data/types'
import { ColorProfileLabel, Languages, MapColorProfile } from '../graphql/types'
import i18n, { keys } from '../i18n'
import { formatNum } from '../util/formatNumber'
import area from '../util/units/area'
import GetUnitConverter from '../util/units/GetUnitConverter'
import { EitherUnit } from '../util/units/LayerUnits'
import length from '../util/units/length'
import { Unit } from '../util/units/Unit'
import { UnitSystem } from '../util/units/unitSystem'
import { ProductStat, ProductStats } from './calculateStats/productStats'
import { StopData } from './types'

interface ToStopDataOptions {
  colorProfile?: MapColorProfile
  layerDef?: MapLayerDefData
  stats?: ProductStats[string]
  lang: Languages
  unit: EitherUnit
  preferredUnitSystem: UnitSystem
}

const getSize = (index: number, stats?: ProductStat) => {
  if (!stats) {
    return 0
  }
  if (!stats.combined.data.stops[index]) {
    return 0
  }

  return stats.combined.data.stops[index].size
}

function getMinMaxLabel(lang: Languages, cpLabel?: ColorProfileLabel | null) {
  if (!cpLabel || !cpLabel.label) {
    return
  }

  return {
    label: cpLabel.label[lang],
    acronym: cpLabel.acronym?.[lang],
  }
}

function getStopLabel(
  layerDef: MapLayerDefData,
  value: any,
  index: number,
  acronym: string,
  next: [string | number, string] | undefined,
  lang: Languages,
  unit?: Unit
) {
  const unitSymbol = unit?.symbol ?? ''
  if (layerDef.dataProperty!.type !== 'value') {
    return value
  }
  if (index === 0) {
    return `${acronym}< ${
      next ? formatValue(lang, layerDef.dataProperty!, next[0]) : value
    } ${unitSymbol}`
  }
  if (next) {
    return `${acronym}${value} - ${formatValue(
      lang,
      layerDef.dataProperty!,
      next[0]
    )} ${unitSymbol}`
  }

  return `${acronym}≥ ${value} ${unitSymbol}`
}

const DEFAULT_LABEL = {
  label: '?',
  acronym: undefined,
}

function getLabelWithDefault(
  lang: Languages,
  cpLabel?: ColorProfileLabel | null
) {
  if (!cpLabel) {
    return DEFAULT_LABEL
  }

  return {
    label: cpLabel.label[lang] ?? '?',
    acronym: cpLabel.acronym?.[lang],
  }
}

function formatValue(
  lang: Languages,
  property: MapSourcePropertyData,
  value: any
) {
  if (property.type === 'class' && property.classes) {
    const result = property.classes.find((c) => c.value === value)

    return result?.translation[lang] ?? '?'
  }

  return formatNum(value, false)
}

const spatialTypes = ['area', 'length']

export const getWeightUnitAndConverter = (
  layerDef: MapLayerDefData,
  minWeight: number | undefined,
  unitSystem: UnitSystem
) => {
  if (minWeight !== undefined) {
    if (
      layerDef.weightProperty?.valueType &&
      spatialTypes.includes(layerDef.weightProperty.valueType)
    ) {
      const { weightProperty } = layerDef
      const inputUnitKey = weightProperty.valueUnit as EitherUnit

      const { units, unitsBySystem, convert, bestUnit } =
        GetUnitConverter(weightProperty)
      const inputUnit = units[inputUnitKey]
      const { unit: weightUnit } = bestUnit(
        minWeight,
        inputUnit,
        inputUnitKey,
        unitsBySystem[unitSystem]
      )

      if (weightUnit) {
        const outputWeightUnit = units[weightUnit]
        const weightConvert = (num: number) =>
          convert(num, inputUnit, outputWeightUnit, weightUnit).value

        return { weightConvert, weightUnit }
      }
    }
  }

  return {
    weightUnit: layerDef.weightProperty?.valueUnit,
    weightConvert: (num: number) => num,
  }
}

export function toStopData({
  colorProfile,
  layerDef,
  stats,
  lang,
  unit,
  preferredUnitSystem,
}: ToStopDataOptions) {
  let items: StopData[] = []

  if (!colorProfile || !layerDef) {
    return { stops: items }
  }

  if (layerDef.noDataProperty && colorProfile.noDataColor) {
    const { label, acronym } = getLabelWithDefault(
      lang,
      colorProfile.noDataLabel
    )

    items.push({
      label,
      key: 'noData',
      type: 'label',
      value: acronym,
      size: stats?.combined.noData.size ?? 0,
      color: colorProfile.noDataColor,
    })
  }

  if (layerDef.coverageProperty && colorProfile.coverageColor) {
    const { label, acronym } = getLabelWithDefault(
      lang,
      colorProfile.coverageLabel
    )

    items.push({
      label,
      key: 'coverage',
      type: 'label',
      value: acronym,
      size: stats?.combined.coverage.size ?? 0,
      color: colorProfile.coverageColor,
    })
  }

  const minWeight = stats?.combined.data.minStopSize ?? 0

  const { weightUnit, weightConvert } = getWeightUnitAndConverter(
    layerDef,
    minWeight,
    preferredUnitSystem
  )

  if (layerDef.dataProperty) {
    const type: StopData['type'] =
      layerDef.dataProperty.type === 'value' ? 'value' : 'label'

    const gradient =
      layerDef.dataProperty!.type === 'value' &&
      colorProfile.display === 'continuous'

    const lastIndex = colorProfile.dataStops.length - 1

    const acronym = layerDef.dataProperty.acronym
      ? `${layerDef.dataProperty.acronym}: `
      : ''
    let unitLabel: Unit

    if (layerDef.dataProperty.valueType === 'area') {
      unitLabel = area.units[unit]
    }
    if (layerDef.dataProperty.valueType === 'length') {
      unitLabel = length.units[unit]
    }

    items = items.concat(
      colorProfile.dataStops.map((stop, index, stops) => {
        const value = formatValue(lang, layerDef.dataProperty!, stop[0])

        const next = index < lastIndex ? stops[index + 1] : undefined

        const color: StopData['color'] =
          gradient && next ? [stop[1], next[1]] : stop[1]

        const label = getStopLabel(
          layerDef,
          value,
          index,
          acronym,
          next,
          lang,
          unitLabel
        )
        const size = weightConvert(getSize(index, stats))

        return {
          type,
          label,
          value,
          color,
          size,
          key: index,
        }
      })
    )
  }

  const minLabel = getMinMaxLabel(lang, colorProfile.minLabel)
  const maxLabel = getMinMaxLabel(lang, colorProfile.maxLabel)

  return {
    weightConvert,
    weightUnit,
    stops: items,
    minLabel: minLabel?.label,
    maxLabel: maxLabel?.label,
  }
}

export function toChartStopData(params: ToStopDataOptions) {
  const stopData = toStopData(params)

  const { layerDef, stats } = params

  const { weightUnit: bestWeightUnit } = stopData
  let unit = '?'
  let labelY = '?'
  let labelX = '?'
  let useIntegers = false

  if (layerDef) {
    if (stats) {
      const { weightType, weightUnit } = stats

      if (weightUnit) {
        if (weightType === 'area') {
          unit = area.units[bestWeightUnit ?? weightUnit].symbol
          labelY = `${i18n.t(keys.units.areaLabel)} (${unit})`
        } else if (weightType === 'length') {
          unit = length.units[bestWeightUnit ?? weightUnit].symbol
          labelY = `${i18n.t(keys.units.lengthLabel)} (${unit})`
        } else if (weightType === 'count') {
          unit = i18n.t(keys.units.countLabel)
          labelY = i18n.t(keys.units.plantCountLabel)
          useIntegers = true
        }
      }
    }

    if (layerDef.dataProperty) {
      const { name, acronym } = layerDef.dataProperty

      labelX = acronym ? `${name} (${acronym})` : name
    }
  }

  return {
    ...stopData,
    unit,
    labelY,
    labelX,
    useIntegers,
    stops: stopData.stops.map((stop) => ({
      ...stop,
      color: Array.isArray(stop.color) ? stop.color[0] : stop.color,
    })),
  }
}
