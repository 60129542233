import { useRedux } from './useRedux'

export const useRouter = () => {
  const [state] = useRedux()

  return state.router
}

export const useHistory = () => useRouter().history!
export const useLocation = () => useRouter().location!
export const useParams = () => useRouter().params
export const useMatch = () => useRouter().match!
export const useSearchParams = () => useRouter().searchParams
