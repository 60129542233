import './FocusedPointPopup.scss'

import * as React from 'react'

import { Grid } from '@mui/material'

import { MapSourcePropertyData } from '../../data/types'
import i18n, { keys } from '../../i18n'
import * as actions from '../../postgis/actions'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { formatNum } from '../../util/formatNumber'
import area, { AreaUnit, convertArea } from '../../util/units/area'
import length, { convertLength, LengthUnit } from '../../util/units/length'
import { UnitSystem } from '../../util/units/unitSystem'
import MapPopup from './MapPopup'
import { selectFocusedPointMapSourcesProperties } from '../../data/selectFocusedMapSourcesProperties'

class FocusedPointPopup extends React.PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    const { focusedPoint, properties } = this.props
    const geometry = focusedPoint
      ? focusedPoint.geometry || focusedPoint._geometry
      : undefined
    const open = !!focusedPoint && !!geometry && properties.length > 0
    // see if we can render any of the properties
    const renderedProperties = open ? properties.map(this.renderProperty) : []

    return (
      <MapPopup
        coordinates={geometry?.coordinates}
        open={open && renderedProperties.length > 0}
        onClose={this.onClose}
      >
        <Grid
          className="FocusedPointPopup"
          container
          direction="column"
          justifyContent="center"
          style={{
            textAlign: 'center',
          }}
        >
          {renderedProperties}
          <Grid item>
            <span className="btn" onClick={this.handleClose}>
              {i18n.t(keys.generic.close)}
            </span>
          </Grid>
        </Grid>
      </MapPopup>
    )
  }

  /**
   * render key -> value for each property in popup
   */
  renderProperty = (property: MapSourcePropertyData) => {
    const { preferredUnitSystem } = this.props
    // must be defined if we're rendering
    const focusedPoint = this.props.focusedPoint!
    // geojson properties has the property values
    const { properties } = focusedPoint
    const value: string | number = properties[property.property]

    const { valueType, valueUnit } = property
    if (value === undefined || !valueType) {
      // property not found in geojson
      return null
    }

    if ((valueType === 'area' || valueType === 'length') && !valueUnit) {
      return null
    }

    let output: typeof value | undefined
    let measurement:
      | ReturnType<typeof convertArea>
      | ReturnType<typeof convertLength>
      | undefined

    if (valueType === 'index') {
      output = formatNum(value)
    } else if (valueType === 'text') {
      output = value
    } else if (valueType === 'area' && isAreaUnit(valueUnit!)) {
      // for area and length values, convert to meter or feet
      if (preferredUnitSystem === UnitSystem.Metric) {
        measurement = area.convert(
          Number(value),
          area.units[valueUnit as AreaUnit],
          area.units.squareMeter,
          'squareMeter'
        )
      } else {
        measurement = area.convert(
          Number(value),
          area.units[valueUnit as AreaUnit],
          area.units.squareFoot,
          'squareFoot'
        )
      }
    } else if (valueType === 'length' && isLengthUnit(valueUnit!)) {
      if (preferredUnitSystem === UnitSystem.Metric) {
        measurement = length.convert(
          Number(value),
          length.units[valueUnit as LengthUnit],
          length.units.meter,
          'meter'
        )
      } else {
        measurement = length.convert(
          Number(value),
          length.units[valueUnit as LengthUnit],
          length.units.foot,
          'foot'
        )
      }
    }

    // format the output based on the conversion
    if (measurement) {
      output = `${formatNum(measurement.value, false, 1, 1)} ${measurement.symbol
        }`
    }

    if (!output) {
      return null
    }

    return (
      <Grid item>
        <h1>{output}</h1>
        <span>{property.acronym || property.name}</span>
      </Grid>
    )
  }

  handleClose = () => {
    this.props.dispatch(actions.setFocusedPoint(null))
  }

  onClose = () => false
}

const isAreaUnit = (unit: string): unit is AreaUnit => !!area.units[unit]

const isLengthUnit = (unit: string): unit is LengthUnit => !!length.units[unit]

const mapState = (state: RootStore) => ({
  focusedPoint: state.postgis.focusedPoint,
  preferredUnitSystem: state.preferences.preferredUnitSystem,
  properties: selectFocusedPointMapSourcesProperties(state)
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  FocusedPointPopup
)
