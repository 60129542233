import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import Page from '../../app/Page'
import { RouteParams, url, urls } from '../../appNavigation/urls'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import * as api from '../../graphql/api'
import { Model } from '../../graphql/types'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import errorAlert from '../errorAlert'
import MapLayerDefForm from './MapLayerDefForm'
import { GetMapLayerDefData } from './selectGetMapLayerDef'

class NewMapLayerDef extends React.PureComponent<
  RouteComponentProps<RouteParams> & ReduxProps & AppDispatchProps
> {
  render() {
    const { preferredLanguage } = this.props

    return (
      <Page
        title={`New Layer Definition`}
        backTo={url(urls.editSourceDef, {
          mapSourceDefId: this.props.match.params.mapSourceDefId,
        })}
        backToTitle="Source Def"
      >
        <div id="NewMapLayerDef">
          <div className="Paper MapDataContainerSmall">
            <MapLayerDefForm
              type="new"
              preferredLanguage={preferredLanguage}
              onCancel={this.goHome}
              onSave={this.handleSave}
            />
          </div>
        </div>
      </Page>
    )
  }

  handleSave = async (formData: GetMapLayerDefData) => {
    const {
      name,
      description,
      order,
      enabled = false,
      mapSourceDefId,
      dataProperty,
      noDataProperty,
      coverageProperty,
      weightProperty,
    } = formData

    try {
      const req = await api.mapLayerDef.create<Model>({
        input: {
          name,
          description,
          enabled,
          mapSourceDefId,
          dataProperty,
          noDataProperty,
          coverageProperty,
          weightProperty,
          order: order ?? 0,
        } as any,
      })

      this.props.history.replace(
        url(urls.editLayerDef, { mapLayerDefId: req.data!.id })
      )
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Layer Definition', message, this.state)
    }
  }

  goHome = () => {
    this.props.history.goBack()
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

const mapState = (state: RootStore) => ({
  preferredLanguage: selectPreferredLanguage(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(NewMapLayerDef)
)
