import * as React from 'react'
import { matchPath, RouteComponentProps } from 'react-router-dom'
import { Column } from '../admin/UI/Column'
import Page from '../app/Page'
import { RouteParams, url, UrlInfo, urls } from '../appNavigation/urls'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { RootStore } from '../redux/types'
import { isValidEmail } from '../util/isEmailValid'
import vvapi from '../vvapi'
import { login } from '../vvapi/login'

interface State {
  email: string
  isEmailValid: boolean
  password: string
  mode: 'login' | 'password-reset' | 'password-reset-requested'
}

const demoDenyList = [urls.invitationExisting.url]
const cancelRouteMap: Record<string, UrlInfo> = {
  [urls.invitationExisting.url]: urls.invitation,
}

class Login extends React.Component<
  ReduxProps & typeof login.actions & RouteComponentProps<RouteParams>,
  State
> {
  state: State = {
    email: '',
    isEmailValid: false,
    password: '',
    mode: 'login',
  }

  render() {
    const { mode } = this.state

    const title =
      mode === 'login'
        ? i18n.t(keys.forms.login.login)
        : i18n.t(keys.forms.forgotPassword.title)

    return (
      <Page title={title}>
        <Column
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Dialog open fullWidth hideBackdrop>
            {mode === 'login' && this.renderLoginForm()}
            {mode === 'password-reset' && this.renderPasswordResetForm()}
            {mode === 'password-reset-requested' &&
              this.renderPasswordResetRequestedForm()}
          </Dialog>
        </Column>
      </Page>
    )
  }

  renderLoginForm = () => {
    const {
      location: { pathname },
    } = this.props

    const redirectMapKey = Object.keys(cancelRouteMap).find(
      (key: string) => !!matchPath(pathname, { path: key, exact: true })
    )

    return (
      <form>
        <DialogTitle>{i18n.t(keys.forms.login.login)}</DialogTitle>
        <DialogContent>
          <TextField
            required
            label={i18n.t(keys.user.email)}
            name="email"
            type="email"
            error={this.props.error === keys.emailInvalid || this.props.error === keys.forms.login.emailRequired}
            onChange={this.handleEmailChange}
            value={this.state.email}
            fullWidth
            margin="normal"
            autoFocus
          />
          <TextField
            required
            label={i18n.t(keys.user.password)}
            name="password"
            type="password"
            error={this.props.error === keys.forms.login.passwordRequired}
            onChange={this.onPasswordChange}
            value={this.state.password}
            fullWidth
            margin="normal"
          />
          {this.props.error && (
            <Typography color="error">
              {i18n.t(this.props.error, { defaultValue: i18n.t(keys.forms.login.loginError) })}
            </Typography>
          )}

          {this.props.isInProgress && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div>
                <CircularProgress />
              </div>
            </div>
          )}

          {renderWrongBrowserWarning()}
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={this.handleForgotPasswordClick}>
            {`${i18n.t(keys.forms.forgotPassword.title)}?`}
          </Button>
          {!demoDenyList.some(
            (ddl) => !!matchPath(pathname, { path: ddl, exact: true })
          ) && (
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={this.handleDemoClick}
              >
                {i18n.t(keys.demo)}
              </Button>
            )}
          {redirectMapKey && (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={(ev) => this.handleCancelClick(redirectMapKey, ev)}
            >
              {i18n.t(keys.generic.cancel)}
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            color="primary"
            type="submit"
            onClick={this.handleLogin}
          >
            {i18n.t(keys.forms.login.login)}
          </Button>
        </DialogActions>
      </form>
    )
  }

  renderPasswordResetForm = () => {
    return (
      <form>
        <DialogTitle>{i18n.t(keys.forms.forgotPassword.title)}</DialogTitle>
        <DialogContent>
          <Typography>
            {i18n.t(keys.forms.forgotPassword.forgotMessage)}
          </Typography>

          <TextField
            label={i18n.t(keys.user.email)}
            name="email"
            type="email"
            onChange={this.handleEmailChange}
            value={this.state.email}
            fullWidth
            margin="normal"
            autoFocus
          />
          {this.props.error && (
            <Typography color="error">
              {i18n.t(this.props.error, { defaultValue: i18n.t(keys.forms.login.loginError) })}
            </Typography>
          )}

          {renderWrongBrowserWarning()}
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={this.handleResetPasswordCancelClick}>
            {i18n.t(keys.generic.cancel)}
          </Button>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            disabled={!this.state.isEmailValid}
            onClick={this.handleResetPasswordSubmitClick}
          >
            {i18n.t(keys.forms.forgotPassword.submit)}
          </Button>
        </DialogActions>
      </form>
    )
  }

  renderPasswordResetRequestedForm = () => {
    return (
      <form>
        <DialogTitle>{i18n.t(keys.forms.forgotPassword.title)}</DialogTitle>
        <DialogContent>
          <Typography>
            {i18n.t(keys.forms.forgotPassword.requestedMessage)}
          </Typography>

          <TextField
            label={i18n.t(keys.user.email)}
            name="email"
            type="email"
            onChange={this.handleEmailChange}
            value={this.state.email}
            fullWidth
            margin="normal"
            disabled
          />
          {this.props.error && (
            <Typography color="error">
              {i18n.t(this.props.error, { defaultValue: i18n.t(keys.forms.login.loginError) })}
            </Typography>
          )}

          {renderWrongBrowserWarning()}
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={this.handleResetPasswordCancelClick}>
            {i18n.t(keys.generic.ok)}
          </Button>
        </DialogActions>
      </form>
    )
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const email = event.target.value
    const isEmailValid = isValidEmail(email)

    this.setState({ email, isEmailValid })
  }

  handleEmailInput = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const email = event?.currentTarget?.value
    const isEmailValid = isValidEmail(email)

    this.setState({ email, isEmailValid })
  }

  handlePasswordInput = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ password: event.currentTarget.value })
  }

  onPasswordChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    this.setState({ password: event.target.value })

  handleForgotPasswordClick = () => this.setState({ mode: 'password-reset' })

  handleResetPasswordCancelClick = () => this.setState({ mode: 'login' })

  handleResetPasswordSubmitClick = async (
    event: React.SyntheticEvent<HTMLElement>
  ) => {
    event.preventDefault()

    try {
      await vvapi.auth.forgotPassword(this.state.email)

      this.setState({ mode: 'password-reset-requested' })
    } catch (exception) {
      // tslint:disable-next-line: no-console
      console.warn('Error logging in:', exception)
    }
  }

  handleDemoClick = (event?: React.SyntheticEvent<any>) => {
    if (event) {
      event.preventDefault()
    }

    this.setState(
      {
        email: 'demo@vineview.com',
        password: 'demo',
      },
      this.handleLogin
    )
  }

  handleCancelClick = async (
    redirectMapKey: string,
    event?: React.SyntheticEvent<HTMLElement>
  ) => {
    if (event) {
      event.preventDefault()
    }

    const cancelRoute = cancelRouteMap[redirectMapKey]

    this.props.history.replace(url(cancelRoute, this.props.match.params))
  }

  handleLogin = async (event?: React.SyntheticEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault()
    }

    try {
      await this.props.login(this.state.email, this.state.password)

      // clear data on successful login so we're not storing password in the DOM for entire session
      this.setState({ email: '', password: '' })
    } catch (exception) {
      // tslint:disable-next-line: no-console
      console.warn('Error logging in:', exception)
    }
  }
}

const isBrowserChrome = () => navigator.userAgent.indexOf('Chrome') >= 0
const renderWrongBrowserWarning = () =>
  !isBrowserChrome() && (
    <Typography>{i18n.t(keys.forms.login.notChromeWarning)}</Typography>
  )

const mapState = (state: RootStore) => ({
  error: state.login.error,
  isInProgress: state.login.isInProgress,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, typeof login.actions>(
  mapState,
  login.actions
)(Login)
