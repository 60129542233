import * as React from 'react'
// import { NavLink } from 'react-router-dom'
import Page, { PageProps } from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import i18n, { keys } from '../../i18n'
import './AccountPage.scss'

interface AccountPageProps extends PageProps { }

export function AccountPage({
  children,
  ...pageProps
}: React.PropsWithChildren<AccountPageProps>) {
  return (
    <Page
      {...pageProps}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <div className="ui-app-row">
        {/* <div className="ui-sidebar account-nav">
          <NavLink to={url(urls.admin_organizations)}>Organizations</NavLink>
          <NavLink to={url(urls.admin_variables)}>Variables</NavLink>
        </div> */}

        <div className="ui-content account-content">{children}</div>
      </div>
    </Page>
  )
}
