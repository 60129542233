import { PureComponent } from 'react'
import { MapboxGL } from '../map/MapboxGL'
import withMap, { WithMap } from '../map/withMap'
import { OrganizationFeature } from '../vvapi/models'

interface Props {
  noteFeature: OrganizationFeature
  color: string
  onNoteChange: (noteFeature: OrganizationFeature) => void
}

class EditNotePoint extends PureComponent<Props & WithMap> {
  marker?: MapboxGL.Marker
  marker2?: MapboxGL.Marker

  componentDidMount() {
    this.marker = new MapboxGL.Marker(undefined, {
      color: this.props.color,
    })

    // initialize marker's position to map location
    this.handleMapMove()
    this.marker.addTo(this.props.map)
    // this.marker2.addTo(this.props.map)

    this.props.map.on('move', this.handleMapMove)
  }

  componentWillUnmount() {
    this.props.map.off('move', this.handleMapMove)

    this.marker!.remove()
    // this.marker2!.remove()
  }

  render() {
    return null
  }

  handleMapMove = () => {
    const latLng = this.props.map.getCenter()

    if (this.marker) {
      this.marker.setLngLat(latLng)
    }

    // if (this.marker2) {
    //   this.marker2.setLngLat(latLng)
    // }

    this.props.onNoteChange({
      ...this.props.noteFeature,
      geometry: {
        type: 'Point',
        coordinates: [latLng.lng, latLng.lat],
      },
    })
  }
}

export default withMap(EditNotePoint)
