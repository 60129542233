import { default as swal } from 'sweetalert'
import { gql, query } from '../../../graphql/client'
import { MapSourceDefFilename } from '../../../graphql/types'
import errorAlert from '../../errorAlert'

export const createFilename = ({
  input,
  returning,
}: {
  input: MapSourceDefFilename
  returning?: string
}) =>
  query({
    variables: {
      input,
    },
    query: getCreateMutation(returning),
  })

const getCreateMutation = (returning = '{ filename }') => gql`
  mutation createMapSourceDefFilename($input: [MapSourceDefFilename_insert_input!]!) {
    result: insert_MapSourceDefFilename(
      objects: $input
    ) {
      returning ${returning}
    }
  }
`

export const updateFilename = (
  filename: string,
  {
    input,
    returning,
  }: {
    input: Pick<MapSourceDefFilename, 'filename'>
    returning?: string
  }
) =>
  query({
    variables: {
      input,
      pk: filename,
    },
    query: getUpdateMutation(returning),
  })

const getUpdateMutation = (returning = '{ filename }') => gql`
  mutation updateMapSourceDefFilename($input: MapSourceDefFilename_set_input!, $pk: citext!) {
    result: update_MapSourceDefFilename(
      where: {
        filename: {
          _eq: $pk
        }
      },
      _set: $input
    ) {
      returning ${returning}
    }
  }
`

export const deleteFilename = async (
  filename: MapSourceDefFilename['filename']
) => {
  const choice = await swal(`You are about to delete ${filename}`, {
    buttons: {
      cancel: true,
      confirm: {
        text: 'Delete',
      },
    },
    dangerMode: true,
  })

  // swal returns null for "cancel"
  if (!choice) {
    return
  }

  try {
    await query({
      variables: {
        filename,
      },
      query: DELETE_FILENAME_MUTATION,
    })
  } catch (e) {
    let message = 'Please try again or contact us'

    if (/GraphQL error/.test(e.message)) {
      message = e.message.replace(/GraphQL error:?\s?/, '')
    }

    softError(e, 'Failed Deleting Filename', message, { filename })
  }
}

const DELETE_FILENAME_MUTATION = gql`
  mutation DeleteFilename($filename: citext!) {
    delete_MapSourceDefFilename(where: { filename: { _eq: $filename } }) {
      affected_rows
    }
  }
`

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })
