import * as React from 'react'
import OrganizationPage from '../OrganizationPage'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { PageProps } from '../../../app/Page'
import { url, urls } from '../../../appNavigation/urls'
import { connect } from 'react-redux'

type Props = PageProps & ReduxProps & AppDispatchProps

class OrderPage extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedOrganizationId !== prevProps.selectedOrganizationId &&
      prevProps.selectedOrganizationId !== undefined
    ) {
      this?.props?.history?.push(url(urls.listOrders))
    }
  }

  render() {
    return <OrganizationPage {...this.props} />
  }
}

const mapState = (state: RootStore) => ({
  selectedOrganizationId: state.userSelection.selectedOrganizationId,
  history: state.router.history,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}>(mapState)(OrderPage)
