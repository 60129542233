import React from 'react'
import Page from '../app/Page'
import { Skeleton } from '@mui/material'

export const LoadingPage = () => {
  return (
    <Page loading>
      <Skeleton variant="rectangular" sx={{ height: '100%', width: '100%' }} />
    </Page>
  )
}
