import { MapSourcePropertyData } from '../../data/types'
import GetUnitConverter from '../../util/units/GetUnitConverter'
import { EitherUnit } from '../../util/units/LayerUnits'

export const getConvertFunction = (
  valueProperty: MapSourcePropertyData,
  outputUnit: EitherUnit
) => {
  if (valueProperty) {
    const { units, convert } = GetUnitConverter(valueProperty)

    return (value: number) =>
      convert(
        value,
        units[valueProperty.valueUnit as EitherUnit],
        units[outputUnit],
        outputUnit
      ).value
  }

  return undefined
}
