import './SamplePlanDetail.scss'
import * as React from 'react'
import Page from '../../../app/Page'
import i18n, { keys } from '../../../i18n'
import { url, urls } from '../../../appNavigation/urls'
import { Stack, useMediaQuery } from '@mui/material'
import { useParams } from 'react-router-dom'
import { SamplePlanDetailSidePanel } from './SamplePlanDetailSidePanel'
import { SamplePlanMap } from '../SamplePlanMap'
import { MapContextProvider } from '../../../map/withMap'
import { classnames } from '../../../util/classnames'
import { useTheme } from '@mui/styles'
import { SamplePlanMapContextProvider } from '../Context/SamplePlanMapContext'
import { SamplePlanReportMapSelector } from './SamplePlanReportMapSelector'

export const SamplePlanDetail = () => {
  return (
    <MapContextProvider key={`SP-MAP-collapsed`}>
      <SamplePlanDetailWithContext />
    </MapContextProvider>
  )
}

const SamplePlanDetailWithContext = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [sidePanelOpen, setSidePanelOpen] = React.useState(true)
  const [downloadPlanOpen, setDownloadPlanOpen] = React.useState(false)

  const { samplePlanId } = useParams<{ samplePlanId: string }>()
  return (
    <SamplePlanMapContextProvider
      paddingOffset={
        sidePanelOpen
          ? { top: 0, right: 0, bottom: 0, left: !isMobile ? 375 : 0 }
          : undefined
      }
      boundsPadding={60}
      samplePlanId={samplePlanId}
    >
      <Page
        backTo={url(urls.samplePlans)}
        backToTitle={i18n.t(keys.samplePlanDashboard)}
      >
        <Stack
          className={classnames('sample-plan-details', [
            'side-panel-open',
            sidePanelOpen,
          ])}
          direction={'row'}
          sx={{ width: '100%', height: '100%' }}
        >
          <SamplePlanMap sidePanelOpen={sidePanelOpen} canEditSamples>
            <SamplePlanDetailSidePanel
              sidePanelOpen={sidePanelOpen}
              setSidePanelOpen={setSidePanelOpen}
              toggleDownloadPlan={() => setDownloadPlanOpen(!downloadPlanOpen)}
            />
          </SamplePlanMap>
        </Stack>
        <SamplePlanReportMapSelector 
          downloadPlanOpen={downloadPlanOpen} 
          setDownloadPlanOpen={setDownloadPlanOpen} 
        />
      </Page>
    </SamplePlanMapContextProvider>
  )
}
