import { gql, query } from '../../graphql/client'

export const deletedDataSetFlight = async ({
  dataSetId,
  flightDate,
  deliveryId
}: {
  dataSetId: string
  flightDate: string
  deliveryId: string
}) => {
  await query({
    query: gql`
      mutation DELETE_DATASET_FLIGHT(
        $dataSetId: Int!
        $deliveryId: uuid!
        $flightDate: date!
      ) {
        delete_DataSet_Flight(
          where: {
            dataSetId: {_eq:$dataSetId}
            deliveryId: {_eq:$deliveryId}
            flightDate: {_eq:$flightDate}
          }
        ) {
          affected_rows
        }
      }
    `,
    variables: {
      dataSetId,
      deliveryId,
      flightDate,
    },
  })
}
