import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import envelope from '@turf/envelope'
import { Feature, featureCollection, Polygon, Properties } from '@turf/helpers'
import { BBox } from 'geojson'
import handleError from '../util/handleError'

export const parse = (value: string | undefined) => {
  if (!value) {
    return []
  }

  if (!/^BOX\(.* .*,.* .*\)$/.test(value)) {
    throw new TypeError(`BBox must be if the format: BOX(minX minY,maxX maxY)`)
  }

  return value
    .replace(/^BOX\(/i, '')
    .replace(/\)$/, '')
    .split(/\s|,/)
    .map(Number)
}

/** gets full extent of all passed boxes */
export const combine = (boxes: number[][]) => {
  // convert boxes to polygons
  const polygons: Feature<Polygon, Properties>[] = []

  for (const singleBox of boxes) {
    try {
      const polygon = bboxPolygon(singleBox as BBox)

      if (polygon && !!polygon.geometry) {
        // polygon geometry is not null
        polygons.push(polygon as Feature<Polygon>)
      }
    } catch (e) {
      handleError(e)
    }
  }

  try {
    const features = featureCollection(polygons)
    const enveloped = envelope(features)

    return bbox(enveloped)
  } catch (e) {
    return []
  }
}
