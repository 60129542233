import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Button, Container, Icon, IconButton } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import Page from '../../app/Page'
import { RouteParams, url, urls } from '../../appNavigation/urls'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import LinkChild from '../../UI/LinkChild'
import DataSetCard from './DataSetCard'
import DataSetImages from './DataSetImages'
import DataSetOrganizations from './DataSetOrganizations'
import { refreshGetDataSet, selectGetDataSet } from './selectGetDataSet'
import { refreshListDataSetFlights } from './selectListDataSetFlights'
import { refreshListDataSetImages, selectListDataSetImages } from './selectListDataSetImages'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'

type TabValue = 'organizations' | 'images'

class DataSetDetailsPage extends React.Component<
  ReduxProps & RouteComponentProps<RouteParams> & AppDispatchProps
> {
  render() {
    const { dataSet, dataSetImages } = this.props
    const { dataSetId } = this.props.match.params
    const tab = this.props.match.params.tab as TabValue

    return (
      <Page
        title={`Data Set ${dataSetId} - ${tab}`}
        backTo={url(urls.listDataSets)}
        backToTitle="Data Sets"
        navChildren={
          <>
            <LinkChild
              to={url(urls.editDataSet, {
                tab,
                dataSetId: `${Number(dataSetId) + 1}`,
              })}
            >
              <IconButton
                color="inherit"
                aria-label="Next Data Set"
                size="large"
              >
                <Icon>arrow_upward</Icon>
              </IconButton>
            </LinkChild>
            <LinkChild
              to={url(urls.editDataSet, {
                tab,
                dataSetId: `${Number(dataSetId) - 1}`,
              })}
            >
              <IconButton
                color="inherit"
                aria-label="Previous Data Set"
                size="large"
              >
                <Icon>arrow_downward</Icon>
              </IconButton>
            </LinkChild>
            <IconButton
              onClick={this.refreshData}
              aria-label="Refresh"
              size="large"
            >
              <Icon>refresh</Icon>
            </IconButton>
          </>
        }
      >
        <Container maxWidth="lg" sx={{ my: 2 }}>
          <AsyncSelectorStatusOverlay requests={[dataSet, dataSetImages]}>
            <DataSetCard dataSet={dataSet.data} />
            <Tabs
              value={tab}
              onChange={this.handleTabChange}
              centered
              textColor="primary"
              indicatorColor="primary"
              style={{ flex: 1 }}
            >
              {this.renderTab('organizations')}
              {this.renderTab('images')}
            </Tabs>

            {tab === 'organizations' && <DataSetOrganizations />}
            {tab === 'images' && <DataSetImages />}
          </AsyncSelectorStatusOverlay>
        </Container>
      </Page>
    )
  }

  renderTab = (targetTab: TabValue) => (
    <Tab
      style={{ padding: 8, minWidth: 50, minHeight: 20 }}
      label={targetTab}
      value={targetTab}
      disabled={targetTab === this.props.match.params.tab}
      onClick={() => this.handleTabChange(null, targetTab)}
      component={Button}
    />
  )

  handleTabChange = (event: React.ChangeEvent<{}> | null, tab: TabValue) => {
    const { dataSetId } = this.props.match.params
    this.props.history.push(url(urls.editDataSet, { dataSetId, tab }))
  }

  refreshData = () => {
    refreshGetDataSet()
    refreshListDataSetImages()
    refreshListDataSetFlights()
  }
}

const mapState = (state: RootStore) => ({
  dataSet: selectGetDataSet(state),
  dataSetImages: selectListDataSetImages(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<
  ReduxProps,
  RouteComponentProps<RouteParams>,
  AppDispatchProps
>(mapState)(DataSetDetailsPage)
