import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
} from '@mui/material'
import * as React from 'react'
import { Column } from '../../../admin/UI/Column'
import { Dialog } from '@mui/material'
import i18n, { keys } from '../../../i18n'

interface Props {
  open: boolean
  title: string
  initialName?: string
  onSave: (newName: string) => Promise<void>
  onCancel: () => void
}

interface State {
  newName: string
}

export class EditNameDialog extends React.Component<Props, State> {
  state: State = { newName: '' }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialName !== this.props.initialName) {
      this.setState({ newName: this.props.initialName ?? '' })
    }
  }

  render() {
    const { open, title, initialName } = this.props
    const { newName } = this.state

    return (
      <Dialog open={open} onKeyDown={this.handleKeyDown}>
        <DialogTitle title={title}>{title}</DialogTitle>
        <DialogContent>
          <Column
            style={{
              alignItems: 'stretch',
              justifyContent: 'space-evenly',
              width: 250,
              height: 100,
            }}
          >
            <Column style={{ alignItems: 'stretch' }}>
              <InputLabel className="label" htmlFor="new-name">
                {i18n.t(keys.focusedBlockDialog.name)}
              </InputLabel>
              <TextField
                autoFocus
                id="new-name"
                value={newName}
                onChange={(ev) => this.handleChangeName(ev)}
              />
            </Column>
          </Column>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={newName === initialName || !newName}
            variant="contained"
            color="primary"
            onClick={this.handleSave}
          >
            {i18n.t(keys.generic.save)}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={this.handleCancel}
          >
            {i18n.t(keys.generic.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter' && !!this.state.newName) {
      this.handleSave(ev)
    }
  }

  handleChangeName = (
    ev: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    ev.stopPropagation()

    this.setState({ newName: ev.target.value })
  }
  handleSave = (ev: React.SyntheticEvent) => {
    ev.stopPropagation()

    this.props.onSave(this.state.newName)
    this.setState({ newName: '' })
  }

  handleCancel = (ev: React.SyntheticEvent) => {
    ev.stopPropagation()

    this.props.onCancel()
    this.setState({ newName: '' })
  }
}
