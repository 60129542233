import * as React from 'react'

import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material'

import { Emoji, emojis } from './emojis'
import {
  CardTravel,
  EmojiEmotions,
  EmojiEvents,
  EmojiPeople,
  EmojiSymbols,
  Fastfood,
  Flag,
  Inventory,
  Park,
  Search,
} from '@mui/icons-material'
import { useDeferredValue } from '../../hooks/useDeferredValue'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function EmojiTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      sx={{
        flex: 1,
        overflowY: 'auto',
        height: '100%',
        overflowX: 'hidden',
        paddingRight: 1.5,
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container spacing={1}>
          {children}
        </Grid>
      )}
    </Box>
  )
}

interface Props {
  emojiSelected: (emoji: Emoji) => void
}

const getIcon = (category: string) => {
  switch (category) {
    case 'Smileys & Emotion':
      return <EmojiEmotions fontSize="small" />
    case 'People & Body':
      return <EmojiPeople fontSize="small" />
    case 'Animals & Nature':
      return <Park fontSize="small" />
    case 'Food & Drink':
      return <Fastfood fontSize="small" />
    case 'Travel & Places':
      return <CardTravel fontSize="small" />
    case 'Activities':
      return <EmojiEvents fontSize="small" />
    case 'Objects':
      return <Inventory fontSize="small" />
    case 'Symbols':
      return <EmojiSymbols fontSize="small" />
    case 'Flags':
      return <Flag fontSize="small" />
    default:
      return <EmojiEvents fontSize="small" />
  }
}

const EmojiPicker = ({ emojiSelected }: Props) => {
  const [filterValue, setFilterValue] = React.useState('')
  const [selectedTab, setSelectedTab] = React.useState(0)

  const deferredFilterValue = useDeferredValue(filterValue)

  const filteredEmojis = React.useMemo(() => {
    if (!deferredFilterValue) {
      return []
    }

    return emojis.filter((emoji) => {
      return (
        emoji.aliases.some((keyword) =>
          keyword.includes(deferredFilterValue.toLowerCase())
        ) ||
        emoji.description.includes(deferredFilterValue.toLowerCase()) ||
        emoji.category.includes(deferredFilterValue.toLowerCase())
      )
    })
  }, [deferredFilterValue])

  const memoizedEmojisByCategory = React.useMemo(() => {
    return emojis.reduce((acc, emoji) => {
      if (!acc[emoji.category]) {
        acc[emoji.category] = []
      }
      acc[emoji.category].push(emoji)

      return acc
    }, {} as Record<string, typeof emojis>)
  }, [emojis])

  const memoizedEmojisByCategoryKeys = Object.keys(memoizedEmojisByCategory)

  return (
    <Stack sx={{ height: 300, width: 331, p: 2 }}>
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        placeholder="Search"
        InputProps={{ startAdornment: <Search sx={{ marginRight: 1 }} /> }}
      />

      {filterValue ? (
        <>
          <Divider sx={{ marginTop: 1 }} />
          <EmojiTabPanel value={0} index={0}>
            {filteredEmojis.map((emoji: Emoji) => (
              <Grid item xs={1} key={emoji.value}>
                <IconButton
                  sx={{ height: 35, width: 35, fontSize: 18, p: 0 }}
                  onClick={() => emojiSelected(emoji)}
                >
                  {emoji.value}
                </IconButton>
              </Grid>
            ))}
          </EmojiTabPanel>
        </>
      ) : (
        <>
          {' '}
          <Tabs
            value={selectedTab}
            sx={{
              '& .MuiTabs-scrollButtons.Mui-disabled': {
                opacity: 0.3,
              },
            }}
          >
            {memoizedEmojisByCategoryKeys.map((category, index) => (
              <Tab
                title={category}
                sx={{ p: 1, minWidth: 0 }}
                icon={<Tooltip title={category}>{getIcon(category)}</Tooltip>}
                key={category}
                onClick={() => setSelectedTab(index)}
              />
            ))}
          </Tabs>
          <Divider />
          {memoizedEmojisByCategoryKeys.map((category, index) => (
            <EmojiTabPanel key={category} value={selectedTab} index={index}>
              {memoizedEmojisByCategory[category].map((emoji: Emoji) => (
                <Grid item xs={1} key={emoji.value}>
                  <IconButton
                    sx={{ height: 35, width: 35, fontSize: 18, p: 0 }}
                    onClick={() => emojiSelected(emoji)}
                  >
                    {emoji.value}
                  </IconButton>
                </Grid>
              ))}
            </EmojiTabPanel>
          ))}
        </>
      )}
    </Stack>
  )
}

export default EmojiPicker
