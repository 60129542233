import { Reducer } from 'redux'
import {
  MapEditorActionTypes,
  MapEditorAction,
  MapEditorState,
  Payload,
} from './types'

const mapEditorReducerBase = <HeaderConfig, MapConfig, DrawerConfig>(
  state: MapEditorState<HeaderConfig, MapConfig, DrawerConfig> | undefined,
  action: MapEditorAction<Payload<HeaderConfig, MapConfig, DrawerConfig>>,
  initialState?: Partial<MapEditorState<HeaderConfig, MapConfig, DrawerConfig>>
) => {
  if (state === undefined) {
    return {
      headerOptions: {},
      mapOptions: {},
      drawerOptions: {},
      leftDrawerOpen: undefined,
      rightDrawerOpen: undefined,
      leftDrawerTabIndex: 0,
      rightDrawerTabIndex: 0,
      leftDrawerWidth: 300,
      rightDrawerWidth: 300,
      ...(initialState ?? {}),
    }
  }

  if (action.type === MapEditorActionTypes.SET_HEADER_OPTIONS) {
    return {
      ...state,
      headerOptions: {
        ...state.headerOptions,
        ...action.payload.headerOptions,
      },
    }
  }
  if (action.type === MapEditorActionTypes.SET_MAP_OPTIONS) {
    return {
      ...state,
      mapOptions: {
        ...state.mapOptions,
        ...action.payload.mapOptions,
      },
    }
  }
  if (action.type === MapEditorActionTypes.SET_DRAWER_OPTIONS) {
    return {
      ...state,
      drawerOptions: {
        ...state.drawerOptions,
        ...action.payload.drawerOptions,
      },
    }
  }
  if (action.type === MapEditorActionTypes.SET_DRAWER) {
    return {
      ...state,
      rightDrawerOpen: action.payload.rightDrawerOpen ?? state.rightDrawerOpen,
      leftDrawerOpen: action.payload.leftDrawerOpen ?? state.leftDrawerOpen,
    }
  }
  if (action.type === MapEditorActionTypes.SET_DRAWER_TAB_INDEX) {
    return {
      ...state,
      rightDrawerTabIndex:
        action.payload.rightDrawerTabIndex ?? state.rightDrawerTabIndex,
      leftDrawerTabIndex:
        action.payload.leftDrawerTabIndex ?? state.leftDrawerTabIndex,
    }
  }
  if (action.type === MapEditorActionTypes.SET_DRAWER_WIDTH) {
    return {
      ...state,
      rightDrawerWidth:
        action.payload.rightDrawerWidth ?? state.rightDrawerWidth,
      leftDrawerWidth: action.payload.leftDrawerWidth ?? state.leftDrawerWidth,
    }
  }
  return state
}

const createMapEditorReducer = <HeaderConfig, MapConfig, DrawerConfig>(
  pageName: string,
  reducerFunctionOverride?: typeof mapEditorReducerBase,
  initialState?: Partial<MapEditorState<HeaderConfig, MapConfig, DrawerConfig>>
): Reducer<
  MapEditorState<HeaderConfig, MapConfig, DrawerConfig>,
  MapEditorAction<Payload<HeaderConfig, MapConfig, DrawerConfig>>
> => {
  return (state, action) => {
    const { page } = action
    const isInitializationCall = state === undefined
    if (page !== pageName && !isInitializationCall) return state

    if (reducerFunctionOverride) {
      return reducerFunctionOverride(state, action)
    }

    return mapEditorReducerBase(state, action, initialState)
  }
}

const mapEditorReducerFactory = {
  createMapEditorReducer,
  mapEditorReducerBase,
}

export default mapEditorReducerFactory
