import { Box, Stack, styled, Tab, Tabs } from '@mui/material'
import AsyncSelectorStatusOverlay from '../../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { useRedux } from '../../../../../hooks/useRedux'
import * as React from 'react'
import i18n, { keys } from '../../../../../i18n'
import { selectOrgMapData } from '../../../../../data/selectOrgMapData'
import { AmendmentConfig } from './AmendmentConfig'
import { VigorZoneConfig } from './VigorZoneConfig'
import { FinalOutput } from './FinalOutput'
import { useRateMapContext } from '../../RateMapContext'
import { useGenerateAmendmentZones } from '../../../hooks/useGenerateAmendmentZones'

export interface ZoneRow {
  amount: number
  rateOfApplication: number
  stopIndex: number
  index: number
  zoneId: string
  size: number
  color: string
  value: string | null | undefined
}

const MainContainer = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
}))

const AmendmentContentContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(1),
  overflow: 'auto',
}))

const ConfigSectionContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#00000033',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}))

const RateMapConfigTab = styled(Tab)(({ theme }) => ({
  minWidth: 0,
  textTransform: 'none',
}))

export const RateMapConfig = () => {
  const [state] = useRedux()

  const [zones, zonesLoading] = useGenerateAmendmentZones()

  const OrgMapDataRequest = selectOrgMapData(state)

  const { loading } = useRateMapContext()

  return (
    <MainContainer direction="column">
      <Tabs
        value={'RateMapConfig'}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
        centered
      >
        <RateMapConfigTab
          style={{ minWidth: 0 }}
          value="RateMapConfig"
          label={i18n.t(keys.rateMapTab)}
        />
      </Tabs>
      <AmendmentContentContainer>
        <AsyncSelectorStatusOverlay
          requests={[OrgMapDataRequest]}
          isLoading={loading}
          style={{
            width: '100%',
          }}
        >
          <Stack sx={{ width: '100%', overflow: 'hidden' }} spacing={1}>
            <ConfigSectionContainer>
              <VigorZoneConfig />
            </ConfigSectionContainer>
            <ConfigSectionContainer>
              <AmendmentConfig zones={zones} zonesLoading={zonesLoading} />
            </ConfigSectionContainer>
            <ConfigSectionContainer>
              <FinalOutput zones={zones} zonesLoading={zonesLoading} />
            </ConfigSectionContainer>
          </Stack>
        </AsyncSelectorStatusOverlay>
      </AmendmentContentContainer>
    </MainContainer>
  )
}
