import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { client, gql } from '../../../graphql/client'
import { Watchable } from '../../../graphql/types'
import { RootStore } from '../../../redux/types'

export interface GetDeliveryData extends Watchable {
  id: string
  organizationId: string
  comment?: string
  createdAt: string
  updatedAt: string
  pipelineId: string
  flightCompleteSentAt: string
  dataAvailableSentAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }

  TargetDelivery: {
    orderId: string
  }
  Organization: {
    OrganizationUsers_aggregate: {
      aggregate: {
        count: number
      }
    }
  }
}

const get = async ({ id }: { id: string }) => {
  const { delivery } = await client.request<{ delivery: GetDeliveryData }>({
    query: gql`
      query GET_DELIVERY_DATA($id: uuid!) {
        delivery: Delivery_by_pk(id: $id) {
          id
          organizationId
          comment
          createdAt
          updatedAt
          pipelineId
          flightCompleteSentAt
          dataAvailableSentAt

          CreatedBy {
            firstName
          }
          UpdatedBy {
            firstName
          }

          Organization {
            OrganizationUsers_aggregate {
              aggregate {
                count
              }
            }
          }

          TargetDelivery {
            orderId
          }
          Watchers {
            User {
              id
              email
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables: { id },
  })

  return delivery
}

const { selector: selectGetDelivery, refresh: refreshGetDelivery } =
  createAsyncSelector({
    resource: 'GetDeliveryData',
    inputs: {
      deliveryId: (state: RootStore) => state.router.params.deliveryId,
    },
    fetcher: async ({ deliveryId }) => {
      if (!deliveryId) {
        return
      }

      const data = await get({ id: deliveryId })

      if (!data) {
        throw new Error('No Data')
      }

      return data
    },
  })

export { selectGetDelivery, refreshGetDelivery }
