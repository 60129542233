import { IMapActionGeneratorParams } from './MapActionGeneratorParams'

export enum Priority {
  NoAction,
  DrawPoint,
  BlockByBlock,
  Block,
  Sample,
  PointNote,
  AreaNote,
  Product,
  Point,
  Polygon,
  MultiPolygon,
  Soil,
  PolygonByColour,
}

export interface IMapAction {
  key: string
  priority: Priority
  execute: () => void
}

export interface IMapActionGenerator {
  key: string
  priority: Priority
  generateActionsFromQualifyingFeatures: (
    params: IMapActionGeneratorParams
  ) => IMapAction[]
}
