import * as React from 'react'
import { default as Typography } from '@mui/material/Typography'
import { ColorProfileLabel, Translated } from '../../../graphql/types'
import TranslationTextField from '../../../UI/TranslationTextField/TranslationTextField'

interface NameAndValue {
  name: string
  value: Partial<ColorProfileLabel>
}

interface Props extends NameAndValue {
  label: string
  required?: boolean
  onChange(e: NameAndValue): void
}

interface State {
  anchorEl: HTMLElement | null
}

class LabelInputs extends React.PureComponent<Props, State> {
  state: State = {
    anchorEl: null,
  }

  render() {
    const { label, value, required = false } = this.props

    return (
      <div className="LabelInputs grid">
        <div className="grid-xs-12">
          <Typography variant="subtitle1">{label}</Typography>
          <TranslationTextField
            required={required}
            name="label"
            label="Label"
            value={value.label as Translated}
            onChange={this.onChange}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            required={required}
            name="acronym"
            label="Acronym"
            value={value.acronym as Translated}
            onChange={this.onChange}
          />
        </div>
      </div>
    )
  }

  onChange = (e: any) => {
    const { name, value, onChange } = this.props

    onChange({
      name,
      value: {
        ...value,
        [e.name]: e.value,
      },
    })
  }
}

export default LabelInputs
