import { default as swal } from 'sweetalert'

interface WarnConfirmOptions {
  title?: string
  message: string
  action?: string
  cancel?: string
}

const warnConfirm = async ({
  title,
  message,
  action = 'OK',
  cancel = 'Cancel',
}: WarnConfirmOptions) =>
  swal({
    title,
    text: message,
    buttons: {
      cancel: {
        visible: true,
        text: cancel,
      },
      confirm: {
        text: action,
      },
    },
    dangerMode: true,
  })

export default warnConfirm
