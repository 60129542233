import * as React from 'react'

import Typography from '@mui/material/Typography'
import Page from '../app/Page'
import { Button, Grid, Stack } from '@mui/material'
import { url, urls } from './urls'
import LinkChild from '../UI/LinkChild'

// tslint:disable-next-line: variable-name
const NotFound = () => (
  <Page title="404">
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100%' }}
    >
      <Grid item xs={3}>
        <Stack spacing={2} alignItems="center">
          <Typography>Page not found</Typography>
          <LinkChild to={url(urls.mapView)}>
            <Button variant="outlined">Go to the Map</Button>
          </LinkChild>
        </Stack>
      </Grid>
    </Grid>
  </Page>
)

export default NotFound
