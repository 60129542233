export const calc = {
  percentage: (nom: number, denom: number) => {
    return ~~((nom / (denom + Number.EPSILON)) * 100)
  },
  marginOfError: (
    sampleSize: number,
    sampleProportion: number,
    populationSize: number,
    zScore: number
  ) => {
    return (
      zScore *
      Math.sqrt(
        (sampleProportion * (1 - sampleProportion)) /
          ((populationSize - 1) * (sampleSize / (populationSize - sampleSize)))
      ) *
      100
    )
  },
  roundToDecimalPlace: (num: number, decimalPlace = 2) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, decimalPlace)) /
      Math.pow(10, decimalPlace)
    )
  },
  avg: (values: number[]) => {
    if (values.length === 0) {
      return 0
    }
    return values.reduce((total, value) => total + value, 0) / values.length
  },
  /**
   * Calculates the euclidean distance between two points.
   * @param a The first point. Must have the same number of dimensions as `b`.
   * @param b The second point. Must have the same number of dimensions as `a`.
   * @returns The euclidean distance between the two points.
   */
  euclideanDistance: (a: number[], b: number[]) => {
    return Math.sqrt(
      a.reduce((acc, val, i) => acc + Math.pow(val - b[i], 2), 0)
    )
  }
}
