import { createDeepEqualitySelector } from './createDeepEqualitySelector'
import { selectRateMapWithoutZoneRates } from './selectRateMap'

const EMPTY_PARCEL_IDS: string[] = []

export const selectRateMapSelectedParcelIds = createDeepEqualitySelector(
  selectRateMapWithoutZoneRates,
  (rateMap) => {
    const parcelIds = rateMap?.RateMapBlockSelections?.filter(
      (block) => block.enabled
    ).map((block) => block.parcelId)

    if (!parcelIds || parcelIds.length === 0) {
      return EMPTY_PARCEL_IDS
    }
    return parcelIds
  }
)
