import { Button, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { CancelKatoJobDialog } from '../../../../admin/Kato/CancelKatoJobDialog'
import { CompleteKatoJobDialog } from '../../../../admin/Kato/CompleteKatoJobDialog'
import { KatoFileDownload } from '../../../../admin/Kato/KatoFileDownload'
import { KatoFileUpload } from '../../../../admin/Kato/KatoFileUpload'
import { UploadKatoFileDialog } from '../../../../admin/Kato/UploadKatoFileDialog'
import { Row } from '../../../../admin/UI/Row'
import { url, urls } from '../../../../appNavigation/urls'
import { selectMe } from '../../../../data/selectMe'
import { useRedux } from '../../../../hooks/useRedux'
import { createTableActionIconButton } from '../../../../UI/Table/createTableActionIconButton'
import { Table } from '../../../../UI/Table/Table'
import {
  finishJob,
  queueJob,
  reserveJobById,
  startJob,
} from '../../../../vvapi/maestro'
import {
  refreshGetDeliveryProcGroup,
  selectDeliveryProcGroup,
} from './deliveryProcGroupSelectors'
import { default as swal } from 'sweetalert'
import { uploadKatoFile } from '../../../../admin/Kato/uploadKatoFile'
import warnConfirm from '../../../../admin/warnConfirm'
import { ProcGroupJobs } from './ProcGroupJobs'

interface BorrowedParams {
  notes: string
  eviThreshold: number
  bufferWidth: number
  rpThreshold?: number
  rliThreshold?: number
}

interface Props {
  setIsLoading: (isLoading: boolean) => void
}

export const DeliveryProcGroupKato = ({ setIsLoading }: Props) => {
  const [state] = useRedux()

  const procGroup = selectDeliveryProcGroup(state)
  const me = selectMe(state)
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false)
  const [completeDialogOpen, setCompleteDialogOpen] = React.useState(false)
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false)
  const [uploadProgress, setUploadProgress] = React.useState(0)
  const [katoFile, setKatoFile] = React.useState<File | undefined>(undefined)
  const history = useHistory()

  const [completeDialogMode, setCompleteDialogMode] = React.useState<
    'complete' | 'update'
  >('complete')
  const [borrowedParams, setBorrowedParams] = React.useState<
    BorrowedParams | undefined
  >(undefined)

  const katoJob = procGroup?.KatoLineQAJob
  const historicalProcGroups = procGroup?.HistoricalProcGroups

  const renderHistoricalProcGroup = () => {
    return (
      <Stack alignItems="stretch">
        <Typography style={{ marginBottom: 4 }}>
          Historical Proc Groups:
        </Typography>
        {historicalProcGroups?.length ?? 0 > 0 ? ( // eslint-disable-line no-self-compare
          <Table
            fixed
            stickyOffset={48}
            rows={historicalProcGroups ?? []}
            formatter={[
              {
                header: () => 'Flight Date',
                data: ({ flightDate }) => flightDate,
              },
              {
                header: () => 'Notes',
                data: ({ KatoLineQAJob }) =>
                  KatoLineQAJob?.LatestJobAttempt?.output?.notes,
              },
              {
                header: () => 'Buffer Width',
                data: ({ KatoLineQAJob }) =>
                  KatoLineQAJob?.LatestJobAttempt?.output?.bufferWidth,
              },
              {
                header: () => 'EVI Threshold',
                data: ({ KatoLineQAJob }) =>
                  KatoLineQAJob?.LatestJobAttempt?.output?.eviThreshold,
              },
            ]}
            actions={[
              {
                selectionRequired: true,
                multi: false,
                button: createTableActionIconButton({
                  title: 'View',
                  icon: 'arrow_forward',
                  key: 'view-delivery-procgroup',
                }),
                action: ([{ deliveryId, procGroup }]) => {
                  history.push(
                    url(urls.deliveryProcGroup, { deliveryId, procGroup }, {})
                  )
                },
              },
              {
                selectionRequired: true,
                multi: false,
                button: createTableActionIconButton({
                  title: 'Use Params',
                  icon: 'launch',
                  key: 'use-params',
                }),
                disabled: ([{ KatoLineQAJob }]) => {
                  return (
                    !KatoLineQAJob ||
                    !KatoLineQAJob.LatestJobAttempt ||
                    !KatoLineQAJob.LatestJobAttempt.output ||
                    !katoJob
                  )
                },
                action: ([{ KatoLineQAJob }]) => {
                  if (KatoLineQAJob?.LatestJobAttempt?.output) {
                    setBorrowedParams(
                      KatoLineQAJob?.LatestJobAttempt?.output as BorrowedParams
                    )
                    setCompleteDialogMode('update')
                  }
                },
              },
            ]}
          />
        ) : (
          <Typography>
            There are not yet any historical entries matching this processing
            group
          </Typography>
        )}
      </Stack>
    )
  }

  const renderKatoJob = () => {
    if (!katoJob || !me || !me.permissions.includes('KatoJob-execute')) {
      return null
    }

    if (
      katoJob?.Status?.status === 'queued' ||
      katoJob?.Status?.status === 'pending'
    ) {
      return renderPendingKatoJob()
    }

    if (katoJob.LatestJobAttempt) {
      switch (katoJob.LatestJobAttempt.status) {
        case 'running':
          return renderRunningKatoJob()
        case 'cancelled':
        case 'complete':
        case 'error':
        case 'timeout':
          return renderDoneJob()
      }
    }

    return null
  }

  const getCompleteButtonText = () => {
    switch (completeDialogMode) {
      case 'complete':
        return getCompleteLabel()
      case 'update':
        return 'Update'
    }
  }

  const getCompleteLabel = () => {
    if (katoFile != null) {
      return 'Upload and Queue Final Products'
    }

    return 'Queue Final Products'
  }

  const renderDoneJob = () => {
    return (
      katoJob != null && (
        <Stack alignItems="stretch">
          <Typography>
            Kato job completed by:{' '}
            <strong>{katoJob.LatestJobAttempt?.worker}</strong> with status:{' '}
            <strong>{katoJob.LatestJobAttempt?.status}</strong>
          </Typography>

          <Typography>Output:</Typography>
          <div className="output">
            {JSON.stringify(katoJob.LatestJobAttempt?.output ?? {}, null, 2)}
          </div>

          <Stack
            direction="row"
            spacing={1}
            style={{ marginTop: 8, justifyContent: 'flex-end', width: '100%' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => reRunKato()}
            >
              Re-run Kato
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => updateParams()}
            >
              Update Final Products Params
            </Button>
          </Stack>
        </Stack>
      )
    )
  }

  const renderRunningKatoJob = () => {
    return (
      katoJob != null && (
        <Stack alignItems="stretch">
          <Stack direction="row" justifyContent="space-between">
            <Typography>{`Started By: ${katoJob.LatestJobAttempt?.worker}`}</Typography>
            {procGroup && <KatoFileDownload deliveryProcGroup={procGroup} />}
          </Stack>
          <KatoFileUpload onFileChosen={(file: File) => setKatoFile(file)} />
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              style={{ marginRight: 8 }}
              variant="contained"
              color="secondary"
              onClick={() => cancelJob()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => completeJob()}
            >
              {getCompleteLabel()}
            </Button>
          </Row>
        </Stack>
      )
    )
  }

  const renderPendingKatoJob = () => {
    return (
      <Stack alignItems="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => startKatoJob()}
        >
          Begin Kato QA
        </Button>
      </Stack>
    )
  }

  const handleCompleteDialogClose = async () => {
    setCompleteDialogOpen(false)
  }

  const handleCancelDialogClose = async () => {
    setCancelDialogOpen(false)
  }

  const handleCompleteKatoJob = async (params: Record<string, any>) => {
    if (!!katoJob && !!katoJob.LatestJobAttempt) {
      if (completeDialogMode === 'update') {
        setCompleteDialogOpen(false)
        setIsLoading(true)

        await startJob(katoJob.id)
        await reserveJobById(katoJob.id, me!.email)
        await finishJob(
          katoJob.id,
          katoJob.LatestJobAttempt!.attempt + 1,
          'complete',
          {
            ...params,
          }
        )

        refreshGetDeliveryProcGroup()
        setIsLoading(false)
      } else if (katoFile != null) {
        setUploadDialogOpen(true)
        const katoUrl = await uploadKatoFile(
          procGroup.deliveryId,
          procGroup.procGroup,
          katoFile,
          (progress) => {
            setUploadProgress(progress)
          }
        )
        setUploadDialogOpen(false)
        setCompleteDialogOpen(false)
        setIsLoading(true)

        await finishJob(
          katoJob.id,
          katoJob.LatestJobAttempt?.attempt ?? 1,
          'complete',
          {
            ...params,
            katoUrl,
          }
        )
        refreshGetDeliveryProcGroup()
        setIsLoading(false)
      } else {
        setCompleteDialogOpen(false)
        setIsLoading(true)
        await finishJob(
          katoJob.id,
          katoJob.LatestJobAttempt!.attempt,
          'complete',
          {
            ...params,
          }
        )

        refreshGetDeliveryProcGroup()
        setIsLoading(false)
      }
    }
  }

  const handleCancelDialogResult = async (
    confirm: boolean,
    requeue: boolean
  ) => {
    if (!!katoJob && !!katoJob.LatestJobAttempt) {
      if (confirm) {
        setCancelDialogOpen(false)
        setIsLoading(true)

        await finishJob(
          katoJob.id,
          katoJob.LatestJobAttempt!.attempt,
          'cancelled',
          {}
        )

        if (requeue) {
          await startJob(katoJob.id)
        }
        refreshGetDeliveryProcGroup()

        setIsLoading(false)
      } else {
        setCancelDialogOpen(false)
      }
    }
  }

  const cancelJob = () => {
    setCancelDialogOpen(true)
  }

  const completeJob = () => {
    setCompleteDialogOpen(true)
  }

  const reRunKato = async () => {
    if (!katoJob) {
      return
    }

    if (
      await warnConfirm({
        title: 'Re-Run Kato',
        message:
          'This will restart the Kato Job. Are you sure you would like to continue?',
        action: 'Continue',
        cancel: 'Cancel',
      })
    ) {
      setIsLoading(true)
      await queueJob(katoJob.id)
      await startKatoJob()
    }
  }

  const updateParams = () => {
    setCompleteDialogMode('update')
    setCompleteDialogOpen(true)
  }

  // const requeue = async () => {
  //   if (!katoJob) {
  //     return
  //   }
  //   if (
  //     await warnConfirm({
  //       title: 'Restart Kato Job',
  //       message: 'Are you sure you want to restart this Kato Job?',
  //       action: 'Yes',
  //     })
  //   ) {
  //     setIsLoading(true)
  //     await startJob(katoJob.id)

  //     refreshGetDeliveryProcGroup()
  //     setIsLoading(true)
  //   }
  // }

  const startKatoJob = async () => {
    if (!katoJob) {
      return
    }

    setIsLoading(true)
    try {
      if (katoJob.Status?.status === 'pending') {
        await queueJob(katoJob.id)
      }
      await reserveJobById(katoJob.id, me!.email)
    } catch (error) {
      await swal({
        title: 'Unable to start job',
        text: 'Unable to start kato job. Ensure all dependent jobs have been completed first.',
      })
    } finally {
      refreshGetDeliveryProcGroup()
      setIsLoading(false)
    }
  }

  return (
    <Stack
      spacing={1}
      alignItems="stretch"
      justifyContent="flex-start"
      sx={{ padding: 1 }}
    >
      {katoJob && (
        <>
          <CancelKatoJobDialog
            open={cancelDialogOpen}
            katoJob={katoJob}
            onClose={handleCancelDialogClose}
            onResponse={handleCancelDialogResult}
          />
          <CompleteKatoJobDialog
            open={completeDialogOpen}
            katoJob={katoJob}
            title={
              completeDialogMode === 'complete'
                ? getCompleteLabel()
                : 'Update Final Products Params'
            }
            completeButtonText={getCompleteButtonText()}
            onCancel={handleCompleteDialogClose}
            onClose={handleCompleteDialogClose}
            onComplete={handleCompleteKatoJob}
            initialNotes={borrowedParams?.notes}
            initialBufferWidth={
              borrowedParams?.bufferWidth ??
              katoJob?.LatestJobAttempt?.output?.bufferWidth
            }
            initialEviThreshold={
              borrowedParams?.eviThreshold ??
              katoJob?.LatestJobAttempt?.output?.eviThreshold
            }
            initialRpThreshold={
              borrowedParams?.rpThreshold ??
              katoJob?.LatestJobAttempt?.output?.rpThreshold
            }
            initialRliThreshold={
              borrowedParams?.rliThreshold ??
              katoJob?.LatestJobAttempt?.output?.rliThreshold
            }
          />
          <UploadKatoFileDialog
            open={uploadDialogOpen}
            progress={uploadProgress}
          />
        </>
      )}
      {procGroup && (
        <Stack sx={{ px: 10 }}>
          <ProcGroupJobs deliveryProcGroup={procGroup} />
        </Stack>
      )}

      {katoJob && <>{renderKatoJob()}</>}

      {historicalProcGroups && <>{renderHistoricalProcGroup()}</>}
    </Stack>
  )
}
