import { Alert, AlertTitle, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"
import * as React from "react"
import ColorProfilePreview from "../admin/mapdata/color-profiles/ColorProfilePreview"
import { selectColorProfilesById, selectMapLayerDefsById } from "../data/selectListMapSourceDefs"
import { DeliveryGroupDate, selectGroupDatesByDeliveryGroupId, selectGroupsById } from "../data/selectOrgMapData"
import { useRedux } from "../hooks/useRedux"
import i18n, { keys } from "../i18n"
import { DataDownloadSettings } from "./DataDownloadSettingsForm"
import { SettingsBase } from "./types"
import { GeoPDFDownloadSettings } from "./GeoPDFDownloadSettingsForm"

interface Props {
  selectedDeliveries: Set<string>
  selectedGroups: Set<string>
  settings: SettingsBase
  name: string
}

const RawDataReview = ({ name, selectedDeliveries, selectedGroups, settings }: Props) => {
  const [state] = useRedux()
  const deliveryDatesByDeliveryGroupId = selectGroupDatesByDeliveryGroupId(state)
  const groups = selectGroupsById(state)


  const [datesByGroupsAndFilename, setDatesByGroupsAndFilename] = React.useState<Record<string, Record<string, Date[]>>>({})
  const [fileCount, setFileCount] = React.useState<number>(-1)

  React.useEffect(() => {
    let newFileCount = 0
    const newDatesByGroupsAndFilename: Record<string, Record<string, Date[]>> = {}

    const handleAddGroupDateFilenamne = (date: DeliveryGroupDate, filename: string) => {
      newFileCount += 1
      const currentRange = newDatesByGroupsAndFilename[date.groupId]?.[filename]
      const currDate = new Date(date.flightDate)
      if (!currentRange) {
        newDatesByGroupsAndFilename[date.groupId] = { ...newDatesByGroupsAndFilename[date.groupId] ?? {}, [filename]: [currDate] }
      }
      else if (currentRange.length === 1) {
        if (currDate < newDatesByGroupsAndFilename[date.groupId][filename][0]) {
          newDatesByGroupsAndFilename[date.groupId][filename].unshift(currDate)
        }
        else if (currDate > newDatesByGroupsAndFilename[date.groupId][filename][0]) {
          newDatesByGroupsAndFilename[date.groupId][filename].push(currDate)
        }
      }
      else {
        if (currDate < newDatesByGroupsAndFilename[date.groupId][filename][0]) {
          newDatesByGroupsAndFilename[date.groupId][filename][0] = currDate
        }
        else if (currDate > newDatesByGroupsAndFilename[date.groupId][filename][1]) {
          newDatesByGroupsAndFilename[date.groupId][filename][1] = currDate
        }
      }
    }

    for (const deliveryId of Array.from(selectedDeliveries)) {
      for (const groupId of Array.from(selectedGroups)) {
        const groupDates = deliveryDatesByDeliveryGroupId[`${deliveryId}_${groupId}`]
        if (groupDates !== undefined) {
          for (const date of groupDates) {
            const foundFilenames = new Set<string>()
            for (let filename of date.filenames) {

              if (filename && !filename.endsWith('.zip')) {
                filename += '.zip'
              }

              if (filename && !foundFilenames.has(filename) && (settings as DataDownloadSettings)?.selectedFilenames.has(filename)) {
                foundFilenames.add(filename)
                const groupDateKey = `${date.deliveryId}_${date.groupId}_${filename}`
                if ((settings as DataDownloadSettings)?.skipOrReplace?.[groupDateKey]?.action === 'skip') {
                  continue
                }
                else {
                  handleAddGroupDateFilenamne(date, filename)
                }
              }
            }
          }
        }
      }
    }

    setFileCount(newFileCount)
    setDatesByGroupsAndFilename(newDatesByGroupsAndFilename)
  }, [selectedDeliveries, selectedGroups, settings, deliveryDatesByDeliveryGroupId])

  if (fileCount === -1) {
    return null
  }

  if (fileCount === 0) {
    return <Alert severity="error">
      <AlertTitle>{i18n.t(keys.noFilesAffectedTitle)}</AlertTitle>
      {i18n.t(keys.noFilesAffectedDetail)}
    </Alert>
  }
  else {
    return (
      <Grid container justifyContent="center" sx={{ width: '100%' }}>
        <Grid container flexDirection="column">
          <Grid item>
            <Typography sx={{ color: "var(--color-light)" }}>{i18n.t(keys.name)}:&nbsp;</Typography>
            <Typography>{name}</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: "var(--color-light)" }}>{i18n.t(keys.downloadType)}:&nbsp;</Typography>
            <Typography>{i18n.t(settings.type)}</Typography>
          </Grid>
          <Grid item display="flex" alignItems="flex-end">
            <Typography variant="subtitle1">{i18n.t(keys.filesCount, { fileCount })}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around" sx={{ width: '100%', m: 0 }}>
          {Object.keys(datesByGroupsAndFilename).map(groupId =>
            <Grid key={groupId} item>
              <List subheader={groups[groupId]?.name} dense disablePadding>
                {Object.entries(datesByGroupsAndFilename[groupId])?.map(([filename, datesByFilename]) =>
                  datesByFilename?.map(date => {
                    const dateString = i18n.toDateShort(date)

                    return <ListItem sx={{ display: 'list-item', py: 0 }} key={`${filename}_${dateString}`}>
                      <ListItemText primary={filename} secondary={dateString} />
                    </ListItem>
                  }

                  )
                )}
              </List>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
}

const GeoPDFReview = ({ name, selectedDeliveries, selectedGroups, settings }: Props) => {

  const [state] = useRedux()

  const groups = selectGroupsById(state)
  const productsById = selectMapLayerDefsById(state)
  const colorProfiles = selectColorProfilesById(state)
  const deliveryDatesByDeliveryGroupId = selectGroupDatesByDeliveryGroupId(state)

  const selectedDates = Array.from(Array.from(selectedDeliveries).reduce((dates, deliveryId) => {
    for (const groupId of Array.from(selectedGroups)) {
      const groupDates = deliveryDatesByDeliveryGroupId[`${deliveryId}_${groupId}`] ?? []
      for (const date of groupDates) {
        dates.add(date.flightDate)
      }
    }
    return dates
  }, new Set<string>()))

  const colorProfile = settings.type === 'geoPdf' ? colorProfiles[(settings as GeoPDFDownloadSettings).selectedColorProfile!] : undefined

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="caption">{i18n.t(keys.name)}</Typography>
        <Typography>{name}</Typography>
      </Grid >
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="caption">{i18n.t(keys.downloadType)}</Typography>
        <Typography>{i18n.t(settings.type)}</Typography>
      </Grid >
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="caption">{i18n.t(keys.groups)}:</Typography>
        <Typography>{Array.from(selectedGroups).map(groupId => groups[groupId]?.name).join(', ')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="caption">{i18n.t(keys.dates)}:</Typography>
        <Typography>{Array.from(selectedDates).join(', ')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="caption">{i18n.t(keys.product)}:</Typography>
        <Typography>{productsById[(settings as GeoPDFDownloadSettings).selectedProduct!].name}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="caption">{i18n.t(keys.visualization.visualization)}:</Typography>
        <Typography>{(settings as GeoPDFDownloadSettings).selectedVisualizationMode ? i18n.t(keys.visualization[(settings as GeoPDFDownloadSettings)!.selectedVisualizationMode!]) : 'N/A'}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="caption">{i18n.t(keys.colorProfile)}:</Typography>
        <Typography>{colorProfile?.name ?? 'N/A'}</Typography>
        {colorProfile ? <ColorProfilePreview  {...colorProfile} /> : null}
      </Grid>
    </Grid>
  )
}

export const ReviewDownload = (props: Props) => {

  if (props.settings.type === 'rawData') {
    return <RawDataReview {...props} />
  }
  else if (props.settings.type === 'geoPdf') {
    return <GeoPDFReview {...props} />
  }

  return null
}
