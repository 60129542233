import { ListDeliveryParcelFileStatus } from '../pages/Organization/Delivery/DeliveryParcelFileStatus/types'
import { downloadBlob } from '../util/download'
import { oneAtATimeApi } from '../util/oneAtATime'
import { fetchWithAuth, getBlob } from '../vvapi/apiResource/createApiResource'
import { gql, query } from './client'
import { createGQLResource } from './createGQLResource'
import {
  DeliveryParcelFile,
  DeliveryParcelFile_create_input,
  DeliveryParcelFile_update_input,
} from './types'

import JSZip from 'jszip'

interface DownloadOptions {
  deliveryId: string
  parcelId: string
  filename: string
}

interface UploadOptions extends DownloadOptions {
  flightDate: string
  file: File
  organizationId: string
  deliveryId: string
  checksum: string
  checksumAlgorithm: 'md5' // can add more algorithms in the future if we need
}

const checkNeedsUpload = async ({
  deliveryId,
  parcelId,
  filename,
  checksum,
}: Pick<
  UploadOptions,
  'deliveryId' | 'parcelId' | 'filename' | 'checksum'
>) => {
  const needsUploadRes = await fetchWithAuth(
    `/api/v3/delivery-parcel-file/${deliveryId}/${parcelId}/${filename}/needs-upload?checksum=${encodeURIComponent(
      checksum
    )}`
  )

  if (!needsUploadRes.ok) {
    throw new Error(`${needsUploadRes.status} - ${needsUploadRes.statusText}`)
  }

  const { needsUpload } = (await needsUploadRes.json()) as {
    needsUpload: boolean
  }

  return needsUpload
}

const uploadApi = oneAtATimeApi({
  getUploadUrl: async ({
    deliveryId,
    parcelId,
    filename,
    file,
  }: Pick<UploadOptions, 'deliveryId' | 'parcelId' | 'filename' | 'file'>) => {
    const uploadUrlRes = await fetchWithAuth(
      `/api/v3/delivery-parcel-file/${deliveryId}/${parcelId}/${filename}/upload-url?contentType=${file.type}`
    )

    if (!uploadUrlRes.ok) {
      throw new Error(`${uploadUrlRes.status} - ${uploadUrlRes.statusText}`)
    }

    const { uploadUrl } = (await uploadUrlRes.json()) as { uploadUrl: string }

    return uploadUrl
  },
  markUploadComplete: async ({
    organizationId,
    deliveryId,
    parcelId,
    flightDate,
    filename,
    checksum,
    checksumAlgorithm,
  }: UploadOptions) => {
    const createRecordRes = await fetchWithAuth(
      '/api/v3/delivery-parcel-file/',
      {
        init: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            parcelId,
            filename,
            flightDate,
            organizationId,
            deliveryId,
            checksum,
            checksumAlgorithm,
          }),
        },
      }
    )

    if (!createRecordRes.ok) {
      throw new Error(
        `${createRecordRes.status} - ${createRecordRes.statusText}`
      )
    }
  },
})

export const upload = async (options: UploadOptions) => {
  const uploadUrl = await uploadApi.getUploadUrl(options)

  const uploadResponse = await fetch(uploadUrl, {
    method: 'PUT',
    body: options.file,
    headers: {
      'Content-Type': options.file.type,
      'Content-Length': `${options.file.size}`,
      'x-amz-storage-class': 'STANDARD_IA',
    },
  })

  if (!uploadResponse.ok) {
    throw new Error(`${uploadResponse.status} - ${uploadResponse.statusText}`)
  }

  await uploadApi.markUploadComplete(options)
}

export const download = async ({
  deliveryId,
  parcelId,
  filename,
}: DownloadOptions) => {
  const { blob } = await getBlob(
    `/api/v3/delivery-parcel-file/${deliveryId}/${parcelId}/${filename}`
  )
  downloadBlob(blob, filename)
}

export const downloadMany = async (
  downloadOptions: ListDeliveryParcelFileStatus[],
  filename = 'download'
) => {
  // reduce to create structure to be able to build directories
  const dpfsByGroupProcGroupAndParcelName = downloadOptions.reduce((acc, d) => {
    acc[d.DeliveryParcel.DeliveryGroup.name] =
      acc[d.DeliveryParcel.DeliveryGroup.name] ?? {}

    acc[d.DeliveryParcel.DeliveryGroup.name][d.DeliveryParcel.procGroup] =
      acc[d.DeliveryParcel.DeliveryGroup.name][d.DeliveryParcel.procGroup] ?? {}

    acc[d.DeliveryParcel.DeliveryGroup.name][d.DeliveryParcel.procGroup][
      d.DeliveryParcel.name
    ] =
      acc[d.DeliveryParcel.DeliveryGroup.name][d.DeliveryParcel.procGroup][
        d.DeliveryParcel.name
      ] ?? []

    acc[d.DeliveryParcel.DeliveryGroup.name][d.DeliveryParcel.procGroup][
      d.DeliveryParcel.name
    ].push(d)

    return acc
  }, {} as Record<string, Record<string, Record<string, ListDeliveryParcelFileStatus[]>>>)

  const zip = new JSZip()

  // build nested directories and add files
  for (const group in dpfsByGroupProcGroupAndParcelName) {
    const groupFolder = zip.folder(group)
    for (const procGroup in dpfsByGroupProcGroupAndParcelName[group]) {
      const procGroupFolder = groupFolder?.folder(procGroup)
      for (const parcelId in dpfsByGroupProcGroupAndParcelName[group][
        procGroup
      ]) {
        const parcelFolder = procGroupFolder?.folder(parcelId)
        const parcelFiles =
          dpfsByGroupProcGroupAndParcelName[group][procGroup][parcelId]
        for (const { deliveryId, parcelId, filename } of parcelFiles) {
          const { blob } = await getBlob(
            `/api/v3/delivery-parcel-file/${deliveryId}/${parcelId}/${filename}`
          )
          parcelFolder?.file(filename, blob)
        }
      }
    }
  }

  const content = await zip.generateAsync({ type: 'blob' })

  // download the zip file
  downloadBlob(content, `${filename}.zip`)
}

export const reprocess = ({
  deliveryId,
  parcelId,
  filename,
}: DownloadOptions) =>
  query<{ deliveryParcelFile: { status: string } }>({
    variables: {
      deliveryId,
      parcelId,
      filename,
    },
    query: gql`
      mutation reprocess_DeliveryParcelFile(
        $deliveryId: uuid!
        $parcelId: Int!
        $filename: citext!
      ) {
        deliveryParcelFile: update_DeliveryParcelFile_by_pk(
          pk_columns: {
            deliveryId: $deliveryId
            parcelId: $parcelId
            filename: $filename
          }
          _set: { status: "pending" }
        ) {
          status
        }
      }
    `,
  })

const {
  list,
  get,
  delete: _delete,
} = createGQLResource<
  DeliveryParcelFile,
  DeliveryParcelFile_create_input,
  DeliveryParcelFile_update_input,
  'deliveryId' | 'parcelId' | 'filename'
>('DeliveryParcelFile', {
  deliveryId: 'uuid',
  parcelId: 'Int',
  filename: 'citext',
})

export const deliveryParcelFile = {
  list,
  get,
  reprocess,
  checkNeedsUpload,
  upload,
  download,
  downloadMany,
  delete: _delete,
}
