import { default as Toolbar } from '@mui/material/Toolbar'
import { ReactNode } from 'react'
import { Row } from '../../admin/UI/Row'
import * as React from 'react'
import { Grid, Icon, Typography, useTheme } from '@mui/material'
import TooltipIconButton from '../TooltipIconButton'
import './EnhancedTableToolbar.scss'

interface Props {
  title: string
  subtitle?: string
  numSelected: number
  style?: React.CSSProperties
  onClickCreate?: () => void
  onClickDelete?: () => void
  onClickRefresh?: () => void
  actions?: () => ReactNode,
  instruction?: string,
  hideSelected?: boolean,
}

/**
 * Based on material UI Docs: https://v3.material-ui.com/demos/tables/
 */
const EnhancedTableToolbar = (props: Props) => {
  const theme = useTheme()

  const {
    title,
    subtitle,
    style,
    instruction,
    numSelected,
    onClickCreate,
    onClickDelete,
    onClickRefresh,
    actions,
    hideSelected,
  } = props

  return (
    <Toolbar style={style} className="EnhancedTableToolbar" sx={{ my: { xs: 2 }, borderRadius: `${theme.shape.borderRadius}px` }}>
      <Grid container className="flex">
        <Grid item xs={12} sm={instruction ? 3 : 6}>
          <Typography variant="h6">{title}</Typography>
        </Grid>

        {instruction && (
          <Grid item xs={12} sm={5} display="flex" alignItems="center">
            <Icon sx={{ color: theme.palette.primary.main, fontSize: '30px !important', mr: "4px" }}>info</Icon>
            <Typography variant="caption" sx={{ color: theme.palette.primary.main }}>
              {instruction}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={instruction ? 4 : 6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <React.Fragment>
              {!hideSelected && (
                <div>
                  {numSelected > 0 && (
                    <Typography variant="caption">
                      {`${numSelected} selected `}
                    </Typography>
                  )}
                </div>
              )}
              <Row style={{ marginLeft: 8 }}>
                {onClickCreate && (
                  <TooltipIconButton title={`Create`} onClick={onClickCreate}>
                    <Icon>add_circle</Icon>
                  </TooltipIconButton>
                )}
                {onClickRefresh && (
                  <TooltipIconButton title={`Refresh`} onClick={onClickRefresh}>
                    <Icon>autorenew</Icon>
                  </TooltipIconButton>
                )}
                {onClickDelete && (
                  <TooltipIconButton
                    title={`Delete`}
                    disabled={numSelected === 0}
                    onClick={onClickDelete}
                  >
                    <Icon>delete</Icon>
                  </TooltipIconButton>
                )}
                {actions?.()}
              </Row>
            </React.Fragment>
          </Grid>
        </Grid>
        {subtitle && <Grid item xs={12}>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Grid>}
      </Grid>
    </Toolbar>
  )
}

export default EnhancedTableToolbar
