import sweetalert from 'sweetalert'
import * as api from '../../graphql/api'
import errorAlert from '../errorAlert'
import { PackageData } from './PackageForm'
import { refreshListPackages } from './selectListPackages'

const deletePackages = async (packageIds: PackageData['id'][]) => {
  const choice = await sweetalert(
    `You are about to delete ${packageIds.length} package(s)`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Delete',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (!choice) {
    return
  }

  try {
    for (const id of packageIds) {
      await api.productPackage.delete({ pk: { id } })
    }

    refreshListPackages()
  } catch (e) {
    softError(e, 'Failed Deleting Packages', e.message)
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
      file: 'deletePackages.ts',
    },
  })

export default deletePackages
