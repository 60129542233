import { AlertColor, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import * as React from 'react'
import { NameAndValue } from '../../../../admin/mapdata/types';
import TranslationTextField from '../../../../UI/TranslationTextField/TranslationTextField';
import { Languages } from '../../../../graphql/types';

interface GenericNotificationFieldsProps {
    message: Partial<Record<Languages, string>>
    severity?: AlertColor
    onChange: (NameAndValue: NameAndValue) => void
}

export const genericNotificationFields = ({message, severity, onChange}: GenericNotificationFieldsProps) => {
    const severityOptions = ['info', 'warning', '']
    return [
        <div className="grid-xs-12">
          <TranslationTextField
            required
            name="message"
            label={`Notification Message`}
            value={message}
            onChange={(e) => e.name && e.value && onChange({ name: e.name, value: e.value})}
          />
        </div>,
        <FormControl className='grid-xs-12'>
          <InputLabel>Notification Severity</InputLabel>
          <Select
            name="severity"
            value={severity}
            required
            onChange={({ target }) => onChange({ name: target.name, value: target.value})}
          >
            {severityOptions.map((severity) => (
              <MenuItem
                key={severity}
                value={severity}
              >
                {severity}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ marginLeft: 0}}>{'The severity of the message. Determines colour and icon displayed to user.'}</FormHelperText>
        </FormControl>
    ]
}