import './AddNewUserDialog.scss'

import * as React from 'react'
import { default as swal } from 'sweetalert'

import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  useTheme,
} from '@mui/material'

import { selectOrganizationGroupsById } from '../../../data/selectOrgMapData'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { useRedux } from '../../../hooks/useRedux'
import i18n, { keys } from '../../../i18n'
import { isValidEmail } from '../../../util/isEmailValid'
import { RequestError } from '../../../util/request'
import { inviteUser } from './inviteUser'
import { selectOrganizationRoles } from './selectOrganizationRoles'
import { refreshListOrganizationUsersAndInvites } from './selectUsersAndInvites'

interface Props {
  organizationId: string
  open: boolean
  onClose: () => void
}

export const AddNewUserDialog = ({ onClose, open, organizationId }: Props) => {
  const theme = useTheme()
  const [state] = useRedux()
  const [userEmail, setUserEmail] = React.useState('')
  const [selectedRole, setSelectedRole] = React.useState('org-member')
  const [isEmailValid, setIsEmailValid] = React.useState(true)
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([])

  const groupsById = selectOrganizationGroupsById(state)

  const language = selectPreferredLanguage(state)
  const roles = selectOrganizationRoles(state)

  React.useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setUserEmail('')
        setSelectedRole('org-member')
        setIsEmailValid(true)
        setSelectedGroups([])
      }, theme.transitions.duration.leavingScreen)
    }
  }, [open])

  const handleChangeEmail = (
    ev: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const userEmail = ev.target.value.trim()
    const isEmailValid = isValidEmail(userEmail)
    setUserEmail(userEmail)
    setIsEmailValid(isEmailValid)
  }

  const handleChangeUserRole = (ev: SelectChangeEvent) => {
    const selectedRole = ev.target.value as string

    setSelectedRole(selectedRole)
  }

  const handleAddUser = async () => {
    try {
      await inviteUser({
        organizationId,
        email: userEmail,
        role: selectedRole,
        language,
        groupIds: selectedGroups,
      })

      onClose()

      refreshListOrganizationUsersAndInvites()
    } catch (error) {
      if (error instanceof RequestError) {
        if (error.response.status === 409) {
          return await swal({
            title: i18n.t(keys.inviteConflict),
            text: i18n.t(keys.inviteForEmailAlreadyExists, { userEmail }),
          })
        }
      }

      throw error
    }
  }

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter' && isEmailValid) {
      handleAddUser()
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onKeyDown={handleKeyDown}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{i18n.t(keys.addNewUser)}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <FormControl fullWidth>
            <TextField
              label={i18n.t(keys.user.email)}
              id="new-user-email"
              value={userEmail}
              onChange={(ev) => handleChangeEmail(ev)}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel html-for="new-user-role">
              {i18n.t(keys.user.role)}
            </InputLabel>
            <Select
              label={i18n.t(keys.user.role)}
              id="new-user-role"
              value={selectedRole}
              onChange={(ev) => handleChangeUserRole(ev)}
            >
              {roles.map((r) => (
                <MenuItem key={r.id} value={r.id}>
                  <ListItemText primary={r.name} secondary={r.description} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              options={Object.keys(groupsById)}
              value={selectedGroups}
              onChange={(_ev, newValue) => setSelectedGroups(newValue)}
              renderOption={(props, option) => (
                <li {...props}>
                  <ListItemText primary={groupsById[option]?.name} />
                </li>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={groupsById[option]?.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => {
                if (selectedGroups.length === 0) {
                  return (
                    <TextField
                      {...{
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          value: i18n.t(keys.allGroups),
                        },
                      }}
                      variant="standard"
                      label={i18n.t(keys.groups)}
                    />
                  )
                }
                return (
                  <TextField
                    {...params}
                    variant="standard"
                    label={i18n.t(keys.groups)}
                  />
                )
              }}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isEmailValid || !selectedRole}
          size="small"
          variant="contained"
          color="primary"
          onClick={handleAddUser}
        >
          {i18n.t(keys.add)}
        </Button>
        <Button size="small" onClick={handleClose}>
          {i18n.t(keys.generic.close)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
