import { createSelector } from 'reselect'
import { selectSelectedMapLayersArray } from '../../data/selectSelectedMapLayers'

export const selectSpeedMapSourcesAndSourceLayers = createSelector(
  selectSelectedMapLayersArray,
  (mapLayers) =>
    mapLayers
      .filter((layer) => layer?.mapLayerDef?.dataProperty?.property === 'speed')
      .map((layer) => ({
        source: `${layer.mapSourceId}-source`,
        sourceLayer: layer.mapSourceId,
      }))
)
