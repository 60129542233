import './CompleteKatoJobDialog.scss'
import * as React from 'react'
import {
  Button,
  Input,
  InputLabel,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  Collapse,
  styled,
  Icon,
  ButtonProps,
} from '@mui/material'
import { Job } from '../../graphql/types'

interface Props {
  katoJob: Job
  open: boolean
  completeButtonText?: string
  title?: string
  initialNotes?: string
  initialBufferWidth?: number
  initialEviThreshold?: number
  initialRpThreshold?: number
  initialRliThreshold?: number
  onClose: () => {}
  onCancel: () => {}
  onComplete: (params: State['params']) => {}
}

interface State {
  advancedExpanded: boolean

  params: {
    notes?: string
    bufferWidth?: number
    eviThreshold?: number
    rpThreshold?: number
    rliThreshold?: number
  }
}

interface ExpandMoreProps extends ButtonProps {
  expanded: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expanded, ...other } = props
  return <Button {...other} endIcon={<Icon>arrow_drop_down</Icon>} />
})(({ theme, expanded }) => ({
  '& .MuiIcon-root': {
    transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}))

export class CompleteKatoJobDialog extends React.Component<Props, State> {
  state: State = {
    params: { notes: '' },
    advancedExpanded: false,
  }

  componentDidMount() {
    this.setState({
      params: {
        ...this.state.params,
        eviThreshold: this.props.initialEviThreshold,
        bufferWidth: this.props.initialBufferWidth,
      },
    })
  }

  componentDidUpdate(prevProps: Props) {
    let update = false
    const newState = { ...this.state }
    if (prevProps.initialBufferWidth !== this.props.initialBufferWidth) {
      newState.params.bufferWidth = this.props.initialBufferWidth
      update = true
    }

    if (prevProps.initialEviThreshold !== this.props.initialEviThreshold) {
      newState.params.eviThreshold = this.props.initialEviThreshold
      update = true
    }

    if (prevProps.initialRpThreshold !== this.props.initialRpThreshold) {
      newState.params.rpThreshold = this.props.initialRpThreshold
      update = true
    }

    if (prevProps.initialRliThreshold !== this.props.initialRliThreshold) {
      newState.params.rliThreshold = this.props.initialRliThreshold
      update = true
    }

    if (prevProps.initialNotes !== this.props.initialNotes) {
      newState.params.notes = this.props.initialNotes
      update = true
    }

    if (update) {
      this.setState(newState)
    }
  }

  render() {
    const {
      open,
      onClose,
      completeButtonText = 'Complete Kato Job',
      title = 'Complete Kato Job',
    } = this.props
    const {
      params: { bufferWidth, eviThreshold, rliThreshold, rpThreshold, notes },
      advancedExpanded,
    } = this.state

    return (
      <Dialog
        style={{ minWidth: 450 }}
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="complete-kato-job-title"
      >
        <DialogTitle id="complete-kato-job-title">{title}</DialogTitle>
        <DialogContent className="complete-kato-job-dialog">
          <Stack className="fields" alignItems="stretch" spacing={1}>
            <InputLabel className="label" htmlFor="job-output-notes">
              Notes
            </InputLabel>
            <TextField
              multiline
              id="job-notes"
              className="job-notes"
              value={notes}
              onChange={(ev) => this.handleChange(ev, 'notes')}
            />
            <InputLabel className="label" htmlFor="job-output-buffer-width">
              Pro Buffer Width
            </InputLabel>
            <Input
              type="number"
              id="pro-buffer-width"
              value={bufferWidth}
              onChange={(ev) => this.handleChange(ev, 'bufferWidth')}
            />
            <InputLabel className="label" htmlFor="job-output-evi-threshold">
              Pro EVI Threshold
            </InputLabel>
            <Input
              type="number"
              id="pro-evi-threshold"
              value={eviThreshold}
              onChange={(ev) => this.handleChange(ev, 'eviThreshold')}
            />

            <ExpandMore
              sx={{ alignSelf: 'flex-start' }}
              onClick={() =>
                this.setState({ advancedExpanded: !advancedExpanded })
              }
              expanded={advancedExpanded}
            >
              Advanced Options
            </ExpandMore>
            <Collapse in={advancedExpanded}>
              <Stack spacing={1}>
                <InputLabel className="label" htmlFor="job-output-rp-threshold">
                  Red Peak Threshold
                </InputLabel>
                <Input
                  type="number"
                  id="pro-rp-threshold"
                  value={rpThreshold}
                  onChange={(ev) => this.handleChange(ev, 'rpThreshold')}
                />
                <InputLabel
                  className="label"
                  htmlFor="job-output-rli-threshold"
                >
                  Red Leaf Index Threshold
                </InputLabel>
                <Input
                  type="number"
                  id="pro-rli-threshold"
                  value={rliThreshold}
                  onChange={(ev) => this.handleChange(ev, 'rliThreshold')}
                />
              </Stack>
            </Collapse>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            sx={{ width: '100%' }}
            justifyContent="flex-end"
          >
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              color="secondary"
              onClick={() => this.props.onCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.onComplete(this.state.params)}
            >
              {completeButtonText}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    )
  }

  handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    key: keyof State['params']
  ) => {
    this.setState({
      params: { ...this.state.params, [key]: event.target.value },
    })
  }
}
