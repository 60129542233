import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
  setFocusedSoilLayer,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { FocusedPolygon } from '../../../../postgis/types'
import { Feature } from '@turf/helpers'
import { unionMany } from '../../../../util/unionMany'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'
import { getStopIndex } from '../../../../ProductStats/calculateStats/calcStats'
import area from '@turf/area'

const getRangeLabel = (
  floor: number,
  ceiling: number | undefined,
  stopIndex: number
) => {
  const floorFormatted = Math.round((floor + Number.EPSILON) * 100) / 100

  const ceilingFormatted =
    ceiling !== undefined
      ? Math.round((ceiling + Number.EPSILON) * 100) / 100
      : undefined

  if (stopIndex === 0) {
    return `< ${ceilingFormatted}`
  } else {
    if (ceilingFormatted === undefined) {
      return `≥ ${floorFormatted}`
    }
    return `${floorFormatted} - ${ceilingFormatted}`
  }
}
export class PolygonByColourActionGenerator implements IMapActionGenerator {
  key = 'polygon-by-colour'
  priority = Priority.PolygonByColour

  generateActionsFromQualifyingFeatures({
    dispatch,
    event,
    features,
    mapLayers,
    map,
    stats,
    state,
  }: IMapActionGeneratorParams): IMapAction[] {
    if (state.notes.editingNoteId !== undefined) {
      return []
    }
    if (!stats) {
      return []
    }
    const products = features.filter((f) => f.layer.id.endsWith('-layer'))
    // Find the qualifying features.
    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) =>
        (mapLayers?.[f.layer.id.replace('-layer', '')]?.mapLayerDef.dataProperty
          .type === 'value' &&
          isPolygonLayer(f)) ||
        isMultiPolygonLayer(f)
    )

    // Return functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        let properties: FocusedPolygon['properties'] = {
          mapSourceId: q.sourceLayer,
        }

        const features = map.querySourceFeatures(q.source, {
          sourceLayer: q.sourceLayer,
        }) as Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>[]

        const mapLayerDefId =
          mapLayers[q.layer.id.replace('-layer', '')].mapLayerDefId
        const propertyValue =
          q.properties?.[
            mapLayers[q.layer.id.replace('-layer', '')].mapLayerDef.dataProperty
              .property
          ]

        const colorProfiles = Object.values(
          stats?.activeColorProfiles ?? {}
        ) as any[]

        const selectedColourProfile = colorProfiles.find(
          (acp) => acp.mapLayerDefId === mapLayerDefId
        )

        const stops = selectedColourProfile?.dataStops?.map(
          ([v]: [number]) => v
        )
        const stopIndex = getStopIndex(
          { value: propertyValue },
          stops,
          selectedColourProfile.type
        )

        const floor = selectedColourProfile?.dataStops[stopIndex]?.[0]

        const ceiling = selectedColourProfile?.dataStops[stopIndex + 1]?.[0]

        // Filter out the features that don't have the same color profile value.
        const filteredFeatures = features.filter((feat) => {
          const propertyValue =
            feat.properties?.[
              mapLayers[q.layer.id.replace('-layer', '')].mapLayerDef
                .dataProperty.property
            ]

          const valueStopIndex = getStopIndex(
            { value: propertyValue },
            stops,
            selectedColourProfile.type
          )

          return stopIndex === valueStopIndex
        })

        // get properties from other products
        products.forEach((product) => {
          if (!product) {
            return
          }

          properties = {
            ...properties,
            ...product.properties,
          }
        })

        const unionedFeature = unionMany(filteredFeatures)

        const range = getRangeLabel(floor, ceiling, stopIndex)

        if (!!unionedFeature) {
          const focusedPolygon: Writable<FocusedPolygon> = {
            properties: {
              ...properties,
              Area_m2: area(unionedFeature),
              range,
            },
            type: 'Feature',
            geometry: unionedFeature.geometry,
            clickCoordinates: [event.lngLat.lng, event.lngLat.lat],
          }

          dispatch(setFocusedPoint(null))
          dispatch(setFocusedPolygon(focusedPolygon))
          dispatch(setFocusedBlockId(undefined))
          dispatch(setFocusedSoilLayer(null))
        }
      },
    }))
  }
}

/** type guard for polygon geometries */
const isPolygonLayer = (
  product: GeoJSON.Feature<GeoJSON.Geometry>
): product is GeoJSON.Feature<GeoJSON.Polygon> =>
  product.geometry.type === 'Polygon'

const isMultiPolygonLayer = (
  product: GeoJSON.Feature<GeoJSON.Geometry>
): product is GeoJSON.Feature<GeoJSON.MultiPolygon> =>
  product.geometry.type === 'MultiPolygon'
