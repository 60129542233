import * as React from 'react'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useTheme,
} from '@mui/material'

import { selectOrganizationList } from '../../../../data/selectOrganizationList'
import useAsync from '../../../../hooks/useAsync'
import { useRedux } from '../../../../hooks/useRedux'
import { useParams } from '../../../../hooks/useRouter'
import { showFeedback } from '../../../../redux/notifications/redux'
import { ChooseParcels } from './ChooseParcels'
import { MapParcels } from './MapParcels'
import { ReviewCopy } from './ReviewCopy'
import { fetchSourceAndDestinationParcels } from './fetchSourceAndDestinationParcels'
import { ChooseDelivery } from './ChooseDelivery'
import { fetchOranizationDeliveries } from './fetchOranizationDeliveries'
import { copyDelivery } from './copyDelivery'
import { ChooseOrganization } from './ChooseOrganization'

interface Props {
  open: boolean
  onClose: () => void
}

export const CopyDeliveryDialog = ({ open, onClose }: Props) => {
  const [state, dispatch] = useRedux()

  const { deliveryId } = useParams()

  const theme = useTheme()

  const organizationsList = selectOrganizationList(state)

  const [step, setStep] = React.useState(0)
  const [selectedOrganization, setSelectedOrganization] = React.useState<
    string | undefined
  >(undefined)
  const [selectedDelivery, setSelectedDelivery] = React.useState<
    string | undefined
  >(undefined)
  const [parcelMap, setParcelMap] = React.useState<Record<number, number>>({})
  const [selectedSourceParcels, setSelectedSourceParcels] = React.useState(
    new Set<number>()
  )

  const [sourceAndDetinationParcelsFetch] = useAsync(
    fetchSourceAndDestinationParcels,
    [deliveryId, selectedDelivery]
  )

  const sourceParcelsByGroup =
    sourceAndDetinationParcelsFetch.result?.sourceParcelsByGroup

  const destinationParcelsByName =
    sourceAndDetinationParcelsFetch.result?.destinationParcelsByName

  const groups = sourceAndDetinationParcelsFetch.result?.groups ?? {}

  const orderedGroupsIds =
    sourceAndDetinationParcelsFetch.result?.orderedGroupsIds ?? []

  const orderedParcelIdsByGroup =
    sourceAndDetinationParcelsFetch.result?.orderedParcelIdsByGroup ?? {}

  const [deliveriesFetch] = useAsync(fetchOranizationDeliveries, [
    selectedOrganization,
    deliveryId,
  ])

  const handleClose = () => {
    setSelectedDelivery(undefined)
    setSelectedOrganization(undefined)
    setSelectedSourceParcels(new Set())
    setParcelMap({})
    setStep(0)
  }

  React.useEffect(() => {
    if (open === false) {
      setTimeout(handleClose, theme.transitions.duration.leavingScreen)
    }
  }, [open, theme.transitions.duration.leavingScreen])

  React.useEffect(() => {
    if (
      sourceParcelsByGroup === undefined ||
      destinationParcelsByName === undefined
    ) {
      return
    }

    const newParcelMap: Record<number, number> = {}

    for (const sourceParcels of Object.values(sourceParcelsByGroup)) {
      for (const { parcel } of Object.values(sourceParcels)) {
        if (!selectedSourceParcels.has(parcel.id)) {
          continue
        }

        if (!newParcelMap[parcel.id]) {
          if (
            destinationParcelsByName[parcel.group.name] &&
            destinationParcelsByName[parcel.group.name][parcel.name]
          ) {
            newParcelMap[parcel.id] =
              destinationParcelsByName[parcel.group.name][parcel.name].parcel.id
          }
        }
      }
    }

    setParcelMap(newParcelMap)
  }, [sourceParcelsByGroup, destinationParcelsByName, selectedSourceParcels])

  const renderLoading = () => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: 100, width: 100 }}
    >
      <CircularProgress />
    </Grid>
  )

  const renderStep = () => {
    switch (step) {
      case 0:
        return organizationsList.status === 'resolved' ? (
          <ChooseOrganization
            organizations={organizationsList.data ?? []}
            selectedOrganization={selectedOrganization}
            onSelectOrganization={(organization) =>
              setSelectedOrganization(organization)
            }
          />
        ) : (
          renderLoading()
        )
      case 1:
        return deliveriesFetch.status === 'resolved' ? (
          <ChooseDelivery
            deliveries={deliveriesFetch.result ?? []}
            selectedDelivery={selectedDelivery}
            onSelectDelivery={(delivery) => setSelectedDelivery(delivery)}
          />
        ) : (
          renderLoading()
        )
      case 2:
        return (
          <ChooseParcels
            groups={groups ?? {}}
            sourceParcelsByGroup={sourceParcelsByGroup ?? {}}
            selectedParcels={selectedSourceParcels}
            setSelectedParcels={setSelectedSourceParcels}
            orderedGroupsIds={orderedGroupsIds}
            orderedParcelIdsByGroup={orderedParcelIdsByGroup}
          />
        )
      case 3:
        return sourceAndDetinationParcelsFetch.status === 'resolved' ? (
          <MapParcels
            groups={groups ?? {}}
            sourceParcelsByGroup={sourceParcelsByGroup ?? {}}
            destinationParcelsByName={destinationParcelsByName ?? {}}
            parcelMap={parcelMap}
            onUpdateParcelMap={(parcelMap) => setParcelMap(parcelMap)}
            selectedSourceParcels={selectedSourceParcels}
            orderedGroupsIds={orderedGroupsIds}
            orderedParcelIdsByGroup={orderedParcelIdsByGroup}
          />
        ) : (
          renderLoading()
        )
      case 4:
        return (
          <ReviewCopy
            organizations={organizationsList.data ?? []}
            selectedOrganization={selectedOrganization!}
            selectedDelivery={selectedDelivery!}
            deliveries={deliveriesFetch.result ?? []}
            sourceParcelsByGroup={sourceParcelsByGroup ?? {}}
            destinationParcelsByName={destinationParcelsByName ?? {}}
            groups={groups}
            parcelMap={parcelMap}
            orderedGroupsIds={orderedGroupsIds}
            orderedParcelIdsByGroup={orderedParcelIdsByGroup}
          />
        )
      default:
        return null
    }
  }

  const canProceed = () => {
    switch (step) {
      case 0:
        return selectedOrganization !== undefined
      case 1:
        return selectedDelivery !== undefined
      case 2:
        return selectedSourceParcels.size > 0
      case 3:
        const parcelMapValues = new Set(Object.values(parcelMap))
        if (parcelMapValues.size === 0) {
          return 0
        }

        if (parcelMapValues.size === 1) {
          return !parcelMapValues.has(-1)
        }

        return true

      default:
        return true
    }
  }

  const advanceStep = async () => {
    if (step < 4) {
      setStep(step + 1)
      return
    }

    const filteredMap: Record<number, number> = {}

    for (const [key, val] of Object.entries(parcelMap)) {
      if (val === -1) {
        continue
      }

      filteredMap[key] = val
    }

    await copyDelivery(deliveryId!, selectedDelivery!, filteredMap)

    dispatch(
      showFeedback({ severity: 'success', message: 'Delivery Copy Initiated' })
    )

    onClose()
  }

  const cancel = () => {
    onClose()
  }

  const canGoBack = () => step > 0

  const goBack = () => {
    switch (step) {
      case 1:
        setSelectedDelivery(undefined)
      case 2:
        setSelectedSourceParcels(new Set())
      case 3:
        setParcelMap({})
      default:
        setStep(step - 1)
    }
  }

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>Copy Delivery</DialogTitle>
      <DialogContent>
        <Stepper activeStep={step}>
          <Step>
            <StepLabel>Choose organization</StepLabel>
          </Step>
          <Step>
            <StepLabel>Choose delivery</StepLabel>
          </Step>
          <Step>
            <StepLabel>Choose Source Parcels</StepLabel>
          </Step>
          <Step>
            <StepLabel>Map Destination Parcels</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirm</StepLabel>
          </Step>
        </Stepper>
        {renderStep()}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => cancel()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!canGoBack()}
          onClick={() => goBack()}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!canProceed()}
          onClick={() => advanceStep()}
        >
          {step === 4 ? 'Copy' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
