import { default as TextField } from '@mui/material/TextField'
import { Form } from '../../../admin/UI/Form'
import * as React from 'react'
import {
  ScheduledNotification_create_input,
  appNotificationTypesArray,
} from '../../../graphql/types'
import {
  AlertColor,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { RootStore } from '../../../redux/types'
import { connect } from 'react-redux'
import { NameAndValue } from '../../../admin/mapdata/types'
import { selectUsersById, UserData } from '../../../data/selectUserList'
import DateTimePickerYMDT from '../../../admin/mapdata/utils/DateTimePickerYMDT'
import { genericNotificationFields } from './NotificationTypeFields/GenericNotificationFields'
import { OrganizationsAutoComplete } from './OrganizationsAutoComplete'
import { UsersAutoComplete } from './UsersAutoComplete'
import { OrganizationData } from '../../../data/selectOrganizationList'

interface State {
  audience: 'global' | 'filtered'
}

class ScheduledNotificationForm extends Form<
  ScheduledNotification_create_input & State,
  ReduxProps
> {
  handleSetOrganizations = (organizations: OrganizationData[]) => {
    this.updateFormDataFromNameAndValue({
      name: 'organizationIds',
      value: organizations.map((o) => Number(o.id)),
    })

    const { usersById } = this.props
    if (organizations[0]?.id === '-1') {
      return
    } else {
      const users: UserData[] = []
      const { userIds } = this.getFormData()
      if (userIds && userIds[0] !== -1) {
        for (const userId of userIds) {
          if (
            usersById[userId]?.userOrgs.some((o) =>
              organizations.some((org) => Number(org.id) === o.organizationId)
            )
          ) {
            users.push(usersById[userId])
          }
        }

        this.updateFormDataFromNameAndValue({
          name: 'userIds',
          value: users.length === 0 ? [-1] : users.map((u) => Number(u.id)),
        })
      }
    }
  }

  render() {
    const {
      name = '',
      desc = '',
      type = '',
      organizationIds = [-1],
      userIds = [-1],
      sendDate = '',
      expireDate = '',
      data = {},
    } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <h2>
            {this.props.type === 'edit' ? 'Edit' : 'New'} Scheduled Notification
          </h2>
        </div>
        <div className="grid-xs-4">
          <DateTimePickerYMDT
            helperText="The day and time that the notification will start being sent to users."
            label={'Sends On'}
            name="sendDate"
            value={sendDate}
            required
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-4">
          <DateTimePickerYMDT
            helperText="The day and time that the notification will stop being sent to users."
            label={'Expires On'}
            name="expireDate"
            value={expireDate}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            helperText="Helps admins to identify a scheduled notification. Try to use different names for new notifications."
            name="name"
            label={`Scheduled Notification Name`}
            fullWidth
            required
            value={name}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!name,
            }}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            helperText="The reason the notification might have been sent or any other relevant details."
            name="desc"
            label={`Scheduled Notification Description`}
            fullWidth
            multiline
            required
            value={desc}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!desc,
            }}
          />
        </div>
        <FormControl className="grid-xs-6">
          <OrganizationsAutoComplete
            selectedOrganizations={organizationIds}
            onOrganizationsSelected={this.handleSetOrganizations}
          />
          <FormHelperText sx={{ marginLeft: 0 }}>
            {
              'The organizations you wish to send to. Also helps filter down users.'
            }
          </FormHelperText>
        </FormControl>
        <FormControl className="grid-xs-6">
          <UsersAutoComplete
            selectedOrganizations={organizationIds}
            selectedUsers={userIds}
            onUsersSelected={(users) =>
              this.updateFormDataFromNameAndValue({
                name: 'userIds',
                value: users.map((u) => Number(u.id)),
              })
            }
          />
          <FormHelperText sx={{ marginLeft: 0 }}>
            {
              'The users you wish to send to. Selecting a user overrides the selected organizations.'
            }
          </FormHelperText>
        </FormControl>
        <FormControl className="grid-xs-12">
          <InputLabel>Notification Type</InputLabel>
          <Select
            name="type"
            value={type}
            required
            onChange={this.updateFormDataFromSelectChangeEvent}
          >
            {appNotificationTypesArray.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ marginLeft: 0 }}>
            {
              'The type of notification. It determines which fields will be necessary to display the notification.'
            }
          </FormHelperText>
        </FormControl>
        {type === 'app|generic-text'
          ? genericNotificationFields({
              severity: data?.severity as AlertColor,
              message: data?.message,
              onChange: this.updateDataPropertyFromChange,
            })
          : null}

        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }

  updateDataPropertyFromChange = ({ name, value }: NameAndValue) => {
    const { data } = this.getFormData()

    this.updateFormDataFromNameAndValue({
      name: 'data',
      value: { ...data, [name]: value },
    } as NameAndValue)
  }
}

const mapState = (state: RootStore) => ({
  usersById: selectUsersById(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(ScheduledNotificationForm)
