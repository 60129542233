import * as React from 'react'
import { default as Dialog } from '@mui/material/Dialog'
import { default as DialogActions } from '@mui/material/DialogActions'
import { default as DialogContent } from '@mui/material/DialogContent'
import { default as DialogTitle } from '@mui/material/DialogTitle'
import { Button } from '@mui/material'
import { MapSourceDefFilename } from '../../../graphql/types'
import i18n, { keys } from '../../../i18n'
import errorAlert from '../../errorAlert'
import { NameAndValue } from '../../mapdata/types'
import assertDefinedFields from '../../mapdata/utils/assertDefinedFields'
import { updateFilename } from './filenameGQL'
import MapSourceFilenameForm from './MapSourceFilenameForm'

interface Props {
  filename: Pick<Partial<MapSourceDefFilename>, 'filename' | 'description'>
  open: boolean
  onSave(): void
  onClose(): void
}

type State = Partial<MapSourceDefFilename>

class EditMapSourceFilenameDialog extends React.PureComponent<Props, State> {
  state: State = {}

  async componentDidMount() {

    this.setState({
      filename: this.props.filename?.filename,
      description: this.props.filename?.description,
    })
  }

  render() {
    const { filename, description } = this.state

    return (
      <Dialog
        id="EditMapSourceFilenameDialog"
        fullWidth
        open={!!this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Edit Filename</DialogTitle>
        <DialogContent>
          <div className="grid">
            <MapSourceFilenameForm
              instance={{ filename, description }}
              onChange={this.handleChange}
              onSubmit={this.handleSave}
            />
          </div>
        </DialogContent>
        <DialogActions className="align-right">
          <Button onClick={this.props.onClose}>
            {i18n.t(keys.generic.cancel)}
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave}>
            {i18n.t(keys.generic.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleChange = ({ name, value }: NameAndValue) =>
    this.setState({
      [name]: value,
    })

  handleSave = async () => {
    const { filename, description } = this.state
    const { filename: { filename: pk } } = this.props
    const requiredFields = {
      filename,
      description
    }

    try {
      if (assertDefinedFields(requiredFields)) {
        const req = await updateFilename(pk!, {
          input: requiredFields,
        })

        if (req.errors) {
          softError(
            req.errors[0],
            'Failed to Update filename',
            req.errors[0].message,
            this.state
          )
        } else {
          this.props.onSave()
        }
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Update filename', message, this.state)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default EditMapSourceFilenameDialog
