import {
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import * as React from 'react'
import Page from '../../app/Page'
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, url, urls } from '../../appNavigation/urls'
import useAsync from '../../hooks/useAsync'
import { useHistory, useSearchParams } from '../../hooks/useRouter'
import { fetchYourQueue } from './fetchYourQueue'
import NotFound from '../../appNavigation/NotFound'
import { tableFormatterWithScope } from './yourQueueTableFormatter';
import { Table } from '../../UI/Table/Table'
import { FilterBy, PaginationChange, TableOrder } from '../../UI/Table/types'
import { ORDER } from '../../UI/Table/orderRows'
import { Refresh } from '@mui/icons-material'
import { FILTER } from '../../UI/Table/filterRows'
import { useRedux } from '../../hooks/useRedux'
import { selectMe } from '../../data/selectMe'
import { useParams } from 'react-router-dom'

export const YourQueue = () => {
  const [state] = useRedux()
  const user = selectMe(state)
  const { userId } = useParams<{ userId: string }>()
  const [filteringProcessingState, setFilteringProcessingState] = React.useState<string>('');

  const { page = 0, pageSize = DEFAULT_PAGE_SIZE, filter, order } = useSearchParams()
  const history = useHistory()

  const [yourQueue, refreshYourQueue] = useAsync(fetchYourQueue(userId), [
    Number(page),
    Number(pageSize),
    order, 
    filter
  ])

  const updateSearchParams = (update: {
    page?: number
    pageSize?: number
    order?: string
    filter?: string
  }) => {
    history.push(
      url(
        urls.yourQueue,
        { userId },
        {
          page: Number(page),
          pageSize: Number(pageSize),
          order,
          filter,
          ...update,
        }
      )
    )
  }

  const rows = yourQueue.result ? yourQueue.result.data : []
  const info = yourQueue.result
    ? yourQueue.result.info
    : { ...DEFAULT_PAGE_INFO, order: [] }

  const tableFormatter = tableFormatterWithScope()

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateSearchParams(pagination)

  const handleOrderChange = (order: TableOrder) => {
    updateSearchParams({ order: ORDER.serialize(order) })
  }

  const handleProcessingStateFilterChange = (e: SelectChangeEvent<string>) => {
    if(e.target.value.length === 0) {
      updateSearchParams({ filter: '' })
      setFilteringProcessingState('')
      return 
    }

    const stateValue = e.target.value
    
    const filter = FILTER.serialize(
      tableFormatter
        .flatMap((_, index) => ({ index, op: 'eq', value: stateValue } as FilterBy))
        .filter(f => !!tableFormatter[f.index].filterBy)
    )

    updateSearchParams({ filter })
    setFilteringProcessingState(e.target.value)
  }

  React.useEffect(() => {
    // if the search value has a value on load...
    if (!!filter) {
      // only need to use the first.
      const param = FILTER.deserialize(filter).map(p => p.value).join('|')
      // if there was no search value but the search param exists...
      setFilteringProcessingState(param)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If not the current user and not an admin, return not found.
  if(userId !== user?.id && !user?.roles.includes('admin')) {
    return <NotFound/>
  }

  return (
    <Page title="My Queue" backTo={url(urls.mapView)} backToTitle="Map">
      <Container maxWidth="lg">
        <Paper sx={{ padding: '10px', my: 1 }} elevation={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction='row' spacing={1}>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel>Processing State Filter</InputLabel>
                  <Select 
                    title='Processing State Filter' 
                    label='Processing State Filter'
                    value={filteringProcessingState}
                    onChange={handleProcessingStateFilterChange}>
                    <MenuItem value={''}>None</MenuItem>
                    <MenuItem value={'pending'}>Kato Job Pending</MenuItem>
                    <MenuItem value={'queued'}>Kato Job Queued</MenuItem>
                    <MenuItem value={'running'}>Kato Job Running</MenuItem>
                    <MenuItem value={'complete'}>Kato Job Complete</MenuItem>
                    <MenuItem value={'cancelled'}>Kato Job Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              
              <IconButton onClick={() => refreshYourQueue()}>
                <Refresh/>
              </IconButton>
            </Stack>
        </Paper>
        <Paper sx={{ padding: '10px' }} elevation={0}>
            <Table
                rows={rows}
                formatter={tableFormatter}
                order={info.order}
                onOrderChange={handleOrderChange}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                pagination={info}
                onPaginationChange={handlePaginationChange}
                rowClass={() => ["clickable"]}
              />
        </Paper>
      </Container>
    </Page>
  )
}
