import * as React from 'react'
import { Button, SelectChangeEvent, Stack } from '@mui/material'
import { NameAndValue } from '../mapdata/types'

const DEFAULT_EQUALS = (a: any, b: any) => a === b

export interface FormProps<T extends object> {
  type: 'new' | 'edit' | 'clone'
  saveMode?: 'all' | 'diff'
  data?: Partial<T> | null
  onCancel?: () => Promise<void> | void
  onDelete?: () => Promise<void> | void
  onSave?: (formData: Partial<T>) => Promise<boolean | void> | boolean | void
  equals?: (a: any, b: any) => boolean
}

export interface FormState<T extends object> {
  formData?: Partial<T>
}

export class Form<
  T extends object,
  P extends object = {}
> extends React.PureComponent<FormProps<T> & P, FormState<T>> {
  state: FormState<T> = {}

  renderFormButtons() {
    const { type, onCancel, onDelete, onSave } = this.props
    const cancelBtn = onCancel && (
      <Button
        style={{ marginLeft: 8 }}
        variant="contained"
        color="secondary"
        onClick={this.handleCancel}
      >
        Cancel
      </Button>
    )
    const deleteBtn = type === 'edit' && onDelete && (
      <Button
        style={{ marginLeft: 8 }}
        variant="contained"
        color="error"
        onClick={this.handleDelete}
      >
        Delete
      </Button>
    )
    const saveBtn = onSave && (
      <Button
        disabled={this.isClean()}
        style={{ marginLeft: 8 }}
        variant="contained"
        color="primary"
        onClick={this.handleSave}
      >
        {this.getSaveButtonText()}
      </Button>
    )

    return (
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        width="100%"
      >
        {deleteBtn}
        <Stack direction="row" spacing={1}>
          {cancelBtn}
          {saveBtn}
        </Stack>
      </Stack>
    )
  }

  getSaveButtonText = () => {
    const { type } = this.props
    switch (type) {
      case 'new':
        return 'Create'
      case 'edit':
        return 'Update'
      case 'clone':
        return 'Clone'
      default:
        return 'Save'
    }
  }

  getFormData = (): Partial<T> => {
    const { data } = this.props
    const { formData } = this.state

    return {
      ...((data ?? {}) as any),
      ...(formData as any),
    }
  }

  updateFormData = (data: Partial<T>) =>
    this.setState(({ formData }) => ({
      formData: {
        ...(formData as any),
        ...(data as any),
      },
    }))

  updateFormDataFromNameAndValue = (data: NameAndValue) =>
    this.updateFormData({ [data.name]: data.value } as Partial<T>)

  updateFormDataFromChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.type === 'checkbox'
    ) {
      this.updateFormDataFromNameAndValue({
        name: event.target.name,
        value: event.target.checked,
      } as NameAndValue)
    } else {
      this.updateFormDataFromNameAndValue(
        event.target as unknown as NameAndValue
      )
    }
  }

  updateFormDataFromSelectChangeEvent = <SelectType,>(
    event: SelectChangeEvent<SelectType>
  ) => {
    this.updateFormDataFromNameAndValue({
      name: event.target.name,
      value: event.target.value,
    } as NameAndValue)
  }

  reset = () => this.setState({ formData: undefined })

  private isClean = () => {
    const { data, equals = DEFAULT_EQUALS } = this.props
    const { formData } = this.state

    if (!formData) {
      return true
    }

    if (data) {
      for (const [key, value] of Object.entries(formData)) {
        if (!equals(data[key], value)) {
          return false
        }
      }

      return true
    }

    return false
  }

  private handleCancel = async () => {
    if (this.props.onCancel) {
      await this.props.onCancel()
      this.reset()
    }
  }
  private handleDelete = async () => {
    if (this.props.onDelete) {
      await this.props.onDelete()
      this.reset()
    }
  }
  private handleSave = async () => {
    const { saveMode = 'diff' } = this.props

    if (this.state.formData && this.props.onSave) {
      const saved = await this.props.onSave(
        saveMode === 'diff' ? this.state.formData : this.getFormData()
      )

      if (!saved || saved) {
        this.reset()
      }
    }
  }
}
