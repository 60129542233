import * as api from '../../graphql/api'
import { MapLayerSettings } from '../../graphql/types'
import { objectPick } from '../../util/objectPick'

const createOrUpdateMapLayerDefSettings = (layerDefaults: MapLayerSettings) => {
  if (layerDefaults.id) {
    return api.mapLayerSettings.update({
      pk: { id: layerDefaults.id },
      input: objectPick(layerDefaults, [
        'visualization',
        'threshold',
        'opacity',
        'numberOfGroups',
        'groupBy',
        'coverageMin',
        'coverageMax',
        'coverage',
      ]),
    })
  }

  return api.mapLayerSettings.create({
    input: objectPick(layerDefaults, [
      'organizationId',
      'mapLayerDefId',
      'visualization',
      'threshold',
      'opacity',
      'numberOfGroups',
      'groupBy',
      'coverageMin',
      'coverageMax',
      'coverage',
    ]),
  })
}

export default createOrUpdateMapLayerDefSettings
