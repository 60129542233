import './ReportHome.scss'

import * as React from 'react'

import { LinearProgress, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import { selectOrganization } from '../../data/selectOrganization'
import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import didPropChange from '../../util/didPropChange'
import { selectReportGroup } from '../selectReportGroup'
import { selectReportPages } from '../selectReportPages'
import getMapImage from './map/getMapImage'
import PrintButton from './PrintButton'
import ReportCover from './ReportCover'
import ReportProductPage from './ReportProductPage'
import ReportSection from './ReportSection'
import ReportTableOfContents from './ReportTableOfContents'

// tslint:disable-next-line:variable-name
const ColorLinearProgress = withStyles({
  root: {
    height: '0.5em',
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
  barColorPrimary: {
    backgroundColor: 'rgba(255,255,255,0.6)',
  },
})(LinearProgress)

interface State {
  mapProgressPercent: number
}

class ReportHome extends React.PureComponent<ReduxProps & AppDispatchProps> {
  // using a timer to poll the mapImage whose state is not managed by redux
  t: NodeJS.Timer

  state: State = {
    mapProgressPercent: 0,
  }

  componentDidMount() {
    this.t = setInterval(this.updatePercent, 700)
  }

  componentWillUnmount() {
    clearInterval(this.t)
  }

  componentDidUpdate(oldProps: this['props']) {
    if (didPropChange('reportPages', oldProps, this.props)) {
      this.updatePercent()
    }
  }

  updatePercent = () => {
    const { reportPages } = this.props

    const mapsToLoad = reportPages.length

    const mapProgressPercent =
      mapsToLoad <= 0
        ? 100
        : ((mapsToLoad - getMapImage.getLength()) / mapsToLoad) * 100 || 0

    this.setState({ mapProgressPercent })
  }

  render() {
    const { organization, reportPages, reportGroup, reportDate } = this.props

    const { mapProgressPercent } = this.state

    const readyToPrint = mapProgressPercent === 100

    if (!reportPages) {
      return null
    }

    const title =
      reportGroup && organization
        ? `${organization.name} | ${reportGroup.name}`
        : ''

    const subtitle = reportDate ? i18n.toDateShort(new Date(reportDate)) : ''

    return (
      <Page
        title={title}
        subtitle={subtitle}
        backTo={url(urls.mapView)}
        backToTitle={i18n.t(keys.map.map)}
        navChildren={
          <>
            {!readyToPrint && (
              <div
                className="Report__loadingProgress"
                style={{ paddingLeft: '1em' }}
              >
                <Typography variant="subtitle1">
                  {i18n.t(keys.generic.loading)}
                </Typography>
                <div className="Report__loadingProgress" style={{ width: 100 }}>
                  <ColorLinearProgress
                    variant="determinate"
                    value={mapProgressPercent}
                  />
                </div>
              </div>
            )}
            <div className="Report__PrintWrapper">
              <PrintButton disabled={!readyToPrint} />
            </div>
          </>
        }
      >
        <div id="ReportHome" className="light">
          <ReportSection withLogo>
            <ReportCover />
          </ReportSection>

          <ReportSection fluid withLogo>
            <ReportTableOfContents pages={reportPages} />
          </ReportSection>

          {reportPages.map(
            // tslint:disable-next-line:no-shadowed-variable
            ({ title, parcel, product, mode }) => (
              <ReportSection key={title}>
                <ReportProductPage page={{ product, title, mode, parcel }} />
              </ReportSection>
            )
          )}
        </div>
      </Page>
    )
  }
}

const mapState = (state: RootStore) => ({
  organization: selectOrganization(state),
  reportPages: selectReportPages(state),
  reportGroup: selectReportGroup(state),
  reportDate: getSelectedFlightDate(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(ReportHome)
