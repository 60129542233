import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import * as api from '../../graphql/api'
import { Languages, SourceTypes, Translated } from '../../graphql/types'
import { RootStore } from '../../redux/types'

export interface GetMapSourceDefData {
  id: string
  name: Translated
  description?: Translated
  type: SourceTypes
  createdAt: string
  updatedAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }

  MapSourceDefFilenames: {
    filename: string
    description?: Translated
  }[]
  MapSourceProperties: {
    id: string
    name: string
  }[]
  MapLayerDefs: {
    id: string
    name: string
  }[]
}

const get = ({ id, lang }: { id: string; lang: Languages }) =>
  api.mapSourceDef.get<GetMapSourceDefData>({
    pk: { id },
    returning: `{
      id
      name
      description
      type

      createdAt
      updatedAt

      CreatedBy {
        firstName
      }
      UpdatedBy {
        firstName
      }

      MapSourceDefFilenames {
        filename
        description
      }
      MapSourceProperties {
        id
        name(path: "${lang}")
      }
      MapLayerDefs {
        id
        name(path: "${lang}")
      }
    }`,
  })

const { selector: selectGetMapSourceDef, refresh: refreshGetMapSourceDef } =
  createAsyncSelector({
    resource: 'GetMapSourceDefData',
    inputs: {
      lang: selectPreferredLanguage,
      mapSourceDefId: (state: RootStore) => state.router.params.mapSourceDefId,
    },
    fetcher: async ({ lang, mapSourceDefId }) => {
      if (!mapSourceDefId) {
        return
      }

      const { data } = await get({ lang, id: mapSourceDefId })

      if (!data) {
        throw new Error('No Data')
      }

      return data
    },
  })

export { selectGetMapSourceDef, refreshGetMapSourceDef }
