import * as React from 'react'

import { Icon, IconButton, InputAdornment } from '@mui/material'

export const passwordAdornment = (
  visible: boolean,
  showPassword: () => void
) => {
  const icon = visible ? 'visibility_off' : 'visibility'

  return (
    <InputAdornment position="end">
      <IconButton tabIndex={-1} onClick={showPassword} size="large">
        <Icon>{icon}</Icon>
      </IconButton>
    </InputAdornment>
  )
}
