export function groupArray<T>(
  toGroup: T[],
  by: ((item: T) => any) | keyof T
): GroupedObject<T> {
  return toGroup.reduce((prev, cur) => {
    const v = by instanceof Function ? by(cur) : cur[by]
    if (v instanceof Array) {
      v.forEach((key) => {
        // tslint:disable-next-line: semicolon
        ;(prev[key] = prev[key] ?? []).push(cur)
      })
    } else {
      // tslint:disable-next-line: semicolon
      ;(prev[v] = prev[v] ?? []).push(cur)
    }

    return prev
  }, {} as GroupedObject<T>)
}

export interface GroupedObject<T> {
  [groupKey: string]: T[]
}
