import * as React from 'react'
import { url, urls } from '../../../appNavigation/urls'
import { Organization_create_input } from '../../../graphql/types'
import { useHistory } from '../../../hooks/useRouter'
import { AdminPage } from '../AdminPage'
import { createOrganization } from './createOrganization'
import OrganizationForm from './OrganizationForm'

export function OrganizationNew() {
  const history = useHistory()

  const handleCancel = () => history.push(url(urls.admin_organizations))

  const handleSave = async (input: Organization_create_input) => {
    const result = await createOrganization(input)
    const organizationId = result?.data?.id

    if (organizationId) {
      history.replace(url(urls.admin_organizations_edit, { organizationId }))

      return true
    }

    return false
  }

  return (
    <AdminPage title="New Organization">
      <OrganizationForm
        type="new"
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </AdminPage>
  )
}
