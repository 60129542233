import * as React from 'react'
import { default as swal } from 'sweetalert'

import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../graphql/api'
import { useRedux } from '../../hooks/useRedux'
import { useHistory, useParams } from '../../hooks/useRouter'
import assertDefinedFields from '../mapdata/utils/assertDefinedFields'
import MapLayerDefGroupForm from './MapLayerDefGroupForm'
import { MapLayerDefGroupMapLayerDefList } from './MapLayerDefGroupMapLayerDefList'
import {
  GetMapLayerDefGroupData,
  refreshGetMapLayerDefGroup,
  selectGetMapLayerDefGroup,
} from './selectGetMapLayerDefGroup'
import { refreshListMapLayerDefGroups } from './selectListMapLayerDefGroups'
import { refreshListUngroupedMapLayerDefs } from './selectListUngroupedMapLayerDefs'

export const EditMapLayerDefGroup = () => {

  const [state] = useRedux()
  const params = useParams()
  const history = useHistory()

  const selectMapLayerDefGroup = selectGetMapLayerDefGroup(state)
  const { mapLayerDefGroupId } = params

  const {
    MapLayerDefs = [],
  } = selectMapLayerDefGroup.data ?? {}



  const handleUpdate = async (formData: Partial<GetMapLayerDefGroupData>) => {
    const { data: mapLayerDefGroup } = selectMapLayerDefGroup

    if (!mapLayerDefGroup) {
      return
    }

    const { name, } = formData
    const requiredFields = {
      name,
    }

    if (assertDefinedFields(requiredFields)) {
      await api.mapLayerDefGroup.update({
        pk: { id: mapLayerDefGroup.id },
        input: requiredFields,
      })
      handleRefresh()
    }

    goHome()
  }

  const goHome = () => {
    history.push(url(urls.listLayerDefGroups))
  }

  const handleDelete = async () => {
    const { data: mapLayerDefGroup } = selectMapLayerDefGroup

    if (!mapLayerDefGroup) {
      return
    }

    const choice = await swal(
      `You are about to delete ${mapLayerDefGroup.name.en}. This will remove all layers from the group.`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: 'Delete',
          },
        },
        dangerMode: true,
      }
    )

    // swal returns null for "cancel"
    if (!choice) {
      return
    }

    await api.mapLayerDefGroup.delete({ pk: { id: mapLayerDefGroup.id } })
    handleRefresh()
    goHome()
  }

  const handleRefresh = () => {
    refreshGetMapLayerDefGroup()
    refreshListMapLayerDefGroups()
    refreshListUngroupedMapLayerDefs()
  }

  return (
    <Page
      title={`Edit Group`}
      backTo={url(urls.listLayerDefGroups)}
      backToTitle="Groups"
    >
      <div id="EditMapLayerDefGroup" className="MapDataContainerSmall">
        <AsyncSelectorStatusOverlay requests={selectMapLayerDefGroup}>
          <MapLayerDefGroupForm
            type="edit"
            data={selectMapLayerDefGroup.data}
            onSave={handleUpdate}
            onDelete={handleDelete}
          />
          <div className="Paper">
            <MapLayerDefGroupMapLayerDefList
              mapLayerDefGroupId={mapLayerDefGroupId!}
              layerDefs={MapLayerDefs}
              onChange={handleRefresh}
            />
          </div>
        </AsyncSelectorStatusOverlay>
      </div>
    </Page>
  )
}