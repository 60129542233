import { createSelector } from 'reselect'
import { RootStore } from '../redux/types'
import { selectAvailableProducts } from './selectAvailableProducts'
import { selectSelectedProductIds } from './selectSelectedProductIds'

const getSelectedLegendProductId = (state: RootStore) =>
  state.userSelection.selectedLegendProductId

export const selectSelectedLegendProductId = createSelector(
  [
    getSelectedLegendProductId,
    selectSelectedProductIds,
    selectAvailableProducts,
  ],
  (selectedLegendProductId, selectedProductIds, { products }) => {
    const selectedProducts = products
      .filter(({ id }) => selectedProductIds.some(productId => productId.replace('-block-by-block', '') === id))
      .map(({ id }) => id)
      .reverse()

    const adjustedLegendProductId = selectedLegendProductId?.replace('-block-by-block', '')
    return adjustedLegendProductId &&
      selectedProducts.includes(adjustedLegendProductId)
      ? adjustedLegendProductId
      : selectedProducts[0]
  }
)
