import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import HelperText from '../../../../admin/mapdata/utils/HelperText'
import {
  DEFAULT_PAGE_INFO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../../../appNavigation/urls'
import * as api from '../../../../graphql/api'

import { ListOrdersData } from '../selectListOrders'
import { CopyOrderDialog } from '../CopyOrderDialog'
import { postJson } from '../../../../vvapi/apiResource/createApiResource'
import useAsync from '../../../../hooks/useAsync'
import { fetchOrderList as fetch } from './queries'
import { useSearchParams } from '../../../../hooks/useRouter'
import { useRedux } from '../../../../hooks/useRedux'
import { Table } from '../../../../UI/Table/Table'
import {
  PaginationChange,
  TableAction,
  TableOrder,
} from '../../../../UI/Table/types'
import { createTableActionIconButton } from '../../../../UI/Table/createTableActionIconButton'
import { ORDER } from '../../../../UI/Table/orderRows'
import { ORDER_LIST_SEARCH, tableFormatter } from './orderListTableFormatter'
import { selectOrganizationId } from '../../../../data/selectOrganizationId'
import { Stack } from '@mui/material'
import { Search } from '../../../../UI/Table/Search'
import OrganizationPage from '../../OrganizationPage'
import i18n, { keys } from '../../../../i18n'

const ListOrders = () => {
  const [state] = useRedux()
  const organizationId = selectOrganizationId(state)
  const {
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    order = '',
    filter = '',
  } = useSearchParams()

  const updateSearchParams = (update: {
    page?: number
    pageSize?: number
    order?: string
    filter?: string
  }) => {
    history.push(
      url(
        urls.listOrders,
        {},
        {
          page: Number(page),
          pageSize: Number(pageSize),
          order,
          filter,
          ...update,
        }
      )
    )
  }

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateSearchParams(pagination)

  // tslint:disable-next-line: no-shadowed-variable
  const handleOrderChange = (order: TableOrder) => {
    updateSearchParams({ order: ORDER.serialize(order) })
  }

  const history = useHistory()
  const [fetchedOrderList, refresh] = useAsync(
    fetch(organizationId),
    [Number(page), Number(pageSize), order, filter],
    [organizationId]
  )

  const orderListInfo = React.useMemo(
    () => fetchedOrderList.result?.info ?? { ...DEFAULT_PAGE_INFO, order: [] },
    [fetchedOrderList.result?.info]
  )
  const orderListRows = React.useMemo(
    () => fetchedOrderList.result?.data ?? [],
    [fetchedOrderList.result?.data]
  )

  const [selected, setSelected] = React.useState<ListOrdersData[]>([])
  const [copyOrderDialogOpen, setCopyOrderDialogOpen] = React.useState(false)
  const [copyId, setCopyId] = React.useState<string | undefined>()
  const [copyComment, setCopyComment] = React.useState<string | undefined>()

  const handleCopyOrderSubmit = async (comment: string, year: number) => {
    if (!copyId) {
      return
    }

    const { orderId } = await postJson(`/api/v3/orders/${copyId}/copy`, {
      body: { year, comment },
    })

    setCopyId(undefined)
    setCopyComment(undefined)
    setCopyOrderDialogOpen(false)

    history.push(url(urls.editOrder, { orderId }))
  }

  const handleCopyOrderCancel = () => {
    setCopyId(undefined)
    setCopyComment(undefined)
    setCopyOrderDialogOpen(false)
  }

  const addOrderAction = React.useMemo(
    (): TableAction<ListOrdersData> => ({
      button: createTableActionIconButton({
        title: 'New Order',
        icon: 'add',
        key: 'new-order',
      }),
      action: () => history.push(url(urls.newOrder)),
    }),
    [history]
  )

  const refreshOrdersAction = React.useMemo(
    (): TableAction<ListOrdersData> => ({
      button: createTableActionIconButton({
        title: 'Refresh Orders',
        icon: 'autorenew',
        key: 'refresh-order',
      }),
      action: () => refresh(),
    }),
    [refresh]
  )

  const deleteOrderAction = React.useMemo(
    (): TableAction<ListOrdersData> => ({
      button: createTableActionIconButton({
        title: 'Delete Order(s)',
        icon: 'delete',
        key: 'delete-order',
      }),
      action: async () => {
        const { length } = selected
        const pluralized = length === 1 ? '1 order' : `${length} orders`

        const choice = await swal(`You are about to delete ${pluralized}`, {
          buttons: {
            cancel: true,
            confirm: {
              text: 'Delete',
            },
          },
          dangerMode: true,
        })

        if (!choice) {
          return
        }

        for (const order of selected) {
          await api.order.delete({ pk: { id: order.id } })
        }

        setSelected([])
        refresh()
      },
    }),
    [selected, refresh]
  )

  const editOrderAction = React.useMemo(
    (): TableAction<ListOrdersData> => ({
      selectionRequired: true,
      button: createTableActionIconButton({
        title: 'Edit Order',
        icon: 'edit',
        key: 'edit-order',
      }),
      action: ([{ id }]) => history.push(url(urls.editOrder, { orderId: id })),
    }),
    [history]
  )

  const copyOrderAction = React.useMemo(
    (): TableAction<ListOrdersData> => ({
      selectionRequired: true,
      button: createTableActionIconButton({
        title: 'Copy Order',
        icon: 'content_copy',
        key: 'copy-order',
      }),
      action: ([{ id, comment }]) => {
        setCopyId(id)
        setCopyComment(comment)
        setCopyOrderDialogOpen(true)
      },
    }),
    []
  )

  return (
    <OrganizationPage
      title={`Orders`}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <Stack py={8} px={16}>
        <div id="ListOrders" className="Paper">
          <HelperText>
            Orders are where we define a completed package for a customer. You
            must create a Order, and then add TargetDelivery.
          </HelperText>
          <Stack direction="row" spacing={2} justifyContent="left">
            <Search
              tableFormatters={tableFormatter}
              filterId={ORDER_LIST_SEARCH}
            />
          </Stack>
          <Table
            title="Orders"
            rows={orderListRows}
            actions={[
              addOrderAction,
              refreshOrdersAction,
              deleteOrderAction,
              editOrderAction,
              copyOrderAction,
            ]}
            formatter={tableFormatter}
            order={orderListInfo.order}
            onOrderChange={handleOrderChange}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            pagination={orderListInfo}
            onPaginationChange={handlePaginationChange}
            onSelectionChange={(rows) => setSelected(rows)}
            selection={selected}
          />
          <CopyOrderDialog
            open={copyOrderDialogOpen}
            comment={copyComment}
            onSubmit={handleCopyOrderSubmit}
            onCancel={handleCopyOrderCancel}
          />
        </div>
      </Stack>
    </OrganizationPage>
  )
}

export default ListOrders
