import {
  downloadGeoJson,
  downloadJson,
  downloadShapefile,
} from '../../../util/download'
import { downloadCSV, downloadXLSX } from '../../../util/table'
import { ExportTemplate } from '../types'
import { geojsonToShapefile } from '../../../util/geojsonToShapefile'

class Exporter {
  static exportCsv<T>(
    format: (data: T, ...args: any[]) => any,
    id?: string
  ): ExportTemplate<T> {
    return {
      exportId: id ?? 'csv',
      export: (data: T, downloadName: string, ...args: any[]) => {
        const table = format(data, ...args)
        downloadCSV({ data: table, filename: downloadName })
      },
    }
  }

  static exportXLSX<T>(
    format: (data: T, ...args: any[]) => any,
    id?: string
  ): ExportTemplate<T> {
    return {
      exportId: id ?? 'xlsx',
      export: (data: T, downloadName: string, ...args: any[]) => {
        const table = format(data, ...args)
        downloadXLSX({ data: table, filename: downloadName })
      },
    }
  }

  static exportGeoJson<T>(
    format: (data: T, ...args: any[]) => any,
    id?: string
  ): ExportTemplate<T> {
    return {
      exportId: id ?? 'geojson',
      export: (data: T, downloadName: string, ...args: any[]) => {
        const geojson = format(data, ...args)
        const geojsonString = JSON.stringify(geojson)
        downloadGeoJson(geojsonString, downloadName)
      },
    }
  }

  static exportShapefile<T>(
    format: (data: T, ...args: any[]) => any,
    id?: string
  ): ExportTemplate<T> {
    return {
      exportId: id ?? 'shapefile',
      export: async (data: T, downloadName: string, ...args: any[]) => {
        const geojson = format(data, ...args)
        const shp = await geojsonToShapefile(geojson, downloadName)
        downloadShapefile(shp, downloadName)
      },
    }
  }

  static exportJson<T>(
    format: (data: T, ...args: any[]) => any,
    id?: string
  ): ExportTemplate<T> {
    return {
      exportId: id ?? 'json',
      export: (data: T, downloadName: string, ...args: any[]) => {
        const json = format(data, ...args)
        const jsonString = JSON.stringify(json)
        downloadJson(jsonString, downloadName)
      },
    }
  }
}

export default Exporter
