import * as gravatar from 'gravatar'
import * as React from 'react'

import {
  Autocomplete,
  Avatar,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material'

import { isNumericIdEqual } from '../../../../util/isNumericIdEqual'

import { DeliveryProcGroupWithNeighborsAndJobStatuses } from './deliveryProcGroupSelectors'
import {
  selectAssignableUsers,
  selectListAssignableUsers,
  UserWithRoles,
} from './selectAssignableUsers'
import { useRedux } from '../../../../hooks/useRedux'

interface Props {
  open: boolean
  onAssign: (userId?: number) => void
  onCancel: () => void
  procGroup?: DeliveryProcGroupWithNeighborsAndJobStatuses
}

export const DeliveryProcGroupAssignDialog = ({
  open,
  procGroup,
  onCancel,
  onAssign,
}: Props) => {
  const [state] = useRedux()
  const users = selectAssignableUsers(state)
  const usersList = selectListAssignableUsers(state)

  const [newAssignee, setNewAssignee] = React.useState<UserWithRoles | null>(
    null
  )
  const [assigneeInput, setAssigneeInput] = React.useState<string>('')

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: ({ firstName, lastName, email }: UserWithRoles) =>
      `${firstName} ${lastName} (${email})`,
  })

  React.useEffect(() => {
    if (!procGroup?.Assignee) {
      return
    }

    for (const user of users) {
      if (isNumericIdEqual(user.id, procGroup?.Assignee.id)) {
        setNewAssignee(user)

        return
      }
    }
  }, [users, procGroup])

  const handleAssign = () => {
    onAssign(newAssignee?.id)
  }

  const handleCancelAssignDialog = () => {
    setNewAssignee(null)
    onCancel()
  }

  const getHighestRole = (userRoles: UserWithRoles['UserRoles']) =>
    userRoles.sort((a, b) => {
      if (a.AccessRole.id === 'admin') {
        return -1
      }
      if (a.AccessRole.id === 'data-processor') {
        return -1
      }

      return 1
    })[0]?.AccessRole?.id

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Proc Group Assignee</DialogTitle>
      <DialogContent>
        <Autocomplete
          blurOnSelect
          autoSelect
          loading={usersList.status === 'pending'}
          value={newAssignee}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(_event: any, user: UserWithRoles | null) => {
            setNewAssignee(user)
          }}
          inputValue={assigneeInput}
          onInputChange={(_event, value) => setAssigneeInput(value)}
          getOptionLabel={({ firstName, lastName, email }) =>
            `${firstName} ${lastName} (${email})`
          }
          filterOptions={filterOptions}
          options={users}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Assignee"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: newAssignee ? (
                    <InputAdornment position="start">
                      <Avatar
                        sx={{ height: 24, width: 24 }}
                        alt={`${newAssignee.firstName} ${newAssignee.lastName} (${newAssignee.email})`}
                        src={gravatar.url(newAssignee.email!, {
                          d: 'mm',
                          r: 'g',
                        })}
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
            )
          }}
          renderOption={(_props, user) => {
            const { id, email, firstName, lastName, UserRoles } = user

            return (
              <ListItemButton
                key={id}
                selected={newAssignee?.id === id}
                onClick={() => setNewAssignee(user)}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={`${firstName} ${lastName} (${email})`}
                    src={gravatar.url(email!, {
                      d: 'mm',
                      r: 'g',
                    })}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${firstName} ${lastName} (${email})`}
                  secondary={getHighestRole(UserRoles)}
                />
              </ListItemButton>
            )
          }}
        ></Autocomplete>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={handleCancelAssignDialog}
          color="secondary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleAssign}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  )
}
