import { MapColorProfileData } from '../../data/types'
import {
  ProductSettings,
  VisualizationMode,
} from '../../ProductSettings/store/types'

export const getSelectedColorProfiles = (
  colorProfiles: Partial<Record<VisualizationMode, MapColorProfileData[]>>,
  { colorIdByVisualization = {} }: ProductSettings = {}
) => {
  const result: Partial<Record<VisualizationMode, MapColorProfileData>> = {}

  for (const [visualization, profiles] of Object.entries(colorProfiles)) {
    if (profiles) {
      const userSelected = profiles.find(
        (profile) => profile.id === colorIdByVisualization[visualization]
      )

      if (userSelected) {
        result[visualization] = userSelected
      } else {
        // look for defaults in visualizations
        const defaultVisualization = profiles.find((profile) =>
          profile.visualizations.some(
            (visual) => visual.visualization === visualization && visual.default
          )
        )

        if (defaultVisualization) {
          result[visualization] = defaultVisualization
        } else {
          // default to first color profile (already ordered in db)
          result[visualization] = profiles[0]
        }
      }
    }
  }

  return result
}
