import { client, gql } from '../../../graphql/client'
import { ListUserAndInvitesData } from './selectUsersAndInvites'

export const updateUserOrInviteGroups = async (
  userOrInvite: ListUserAndInvitesData,
  groupIds: string[]
) => {
  if (userOrInvite.userId) {
    await updateOrganizationUserGroups(
      userOrInvite.userId,
      userOrInvite.organizationId,
      groupIds
    )
  } else {
    await updateInviteGroupIds(
      userOrInvite.organizationId,
      userOrInvite.email,
      groupIds
    )
  }
}

const updateInviteGroupIds = async (
  organizationId: string,
  email: string,
  groupIds?: string[]
) => {
  await client.request({
    query: gql`
      mutation UPDATE_INVITATION_GROUPS(
        $organizationId: Int!
        $email: String!
        $groupIds: _int4
      ) {
        update_UserInvite_by_pk(
          pk_columns: { organizationId: $organizationId, email: $email }
          _set: { groupIds: $groupIds }
        ) {
          organizationId
          email
          groupIds
        }
      }
    `,
    variables: {
      organizationId,
      email,
      groupIds: !groupIds ? '{}' : `{${groupIds?.join(',')}}`,
    },
  })
}

const updateOrganizationUserGroups = async (
  userId: number,
  organizationId: string,
  groupIds: string[]
) => {
  await client.request({
    query: gql`
      mutation UPDATE_ORGANIZATION_USER_GROUPS(
        $userId: Int!
        $organizationId: Int!
        $groupIds: [OrganizationUser_Group_insert_input!]!
      ) {
        delete_OrganizationUser_Group(
          where: {
            userId: { _eq: $userId }
            organizationId: { _eq: $organizationId }
          }
        ) {
          affected_rows
        }
        insert_OrganizationUser(
          objects: {
            organizationId: $organizationId
            userId: $userId
            OrganizationGroups: { data: $groupIds }
          }
          on_conflict: {
            constraint: OrganizationUser_pkey
            update_columns: [organizationId, userId]
          }
        ) {
          returning {
            OrganizationGroups {
              groupId
            }
          }
        }
      }
    `,
    variables: {
      userId,
      organizationId,
      groupIds:
        groupIds?.map((groupId) => ({
          groupId,
        })) ?? [],
    },
  })
}
