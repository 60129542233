import { createSelector } from 'reselect'
import { createAsyncSelector } from '../../../../AsyncSelector/createAsyncSelector'
import { client, gql } from '../../../../graphql/client'
import { User } from '../../../../vvapi/models'

export interface UserWithRoles extends User {
  UserRoles: {
    AccessRole: {
      id: string
    }
  }[]
}

const { selector: selectListAssignableUsers, refresh: refreshAssignableUsers } =
  createAsyncSelector({
    resource: 'ASSIGNABLE_USERS',
    fetcher: async () => {
      const { users } = await client.request<{ users: UserWithRoles[] }>({
        query: gql`
          query FETCH_ASSIGNABLE_USERS {
            users: User(
              where: {
                _or: [
                  { UserRoles: { AccessRole: { id: { _eq: "admin" } } } }
                  {
                    UserRoles: { AccessRole: { id: { _eq: "data-processor" } } }
                  }
                  {
                    UserRoles: {
                      AccessRole: { id: { _eq: "data-processor-external" } }
                    }
                  }
                ]
              }
            ) {
              id
              email
              firstName
              lastName
              UserRoles {
                AccessRole {
                  id
                }
              }
            }
          }
        `,
      })

      return users
    },
  })

const EMPTY_USERS: UserWithRoles[] = []

export const selectAssignableUsers = createSelector(
  selectListAssignableUsers,
  (listAssignableUsers) => {
    return listAssignableUsers.data ?? EMPTY_USERS
  }
)

export { selectListAssignableUsers, refreshAssignableUsers }
