import * as api from '../../../graphql/api'
import { createTableFetcher } from '../../../UI/Table/createTableFetcher'
import { tableFormatter } from './organizationTableFormatters'

export interface FetchedOrganizations {
  id: string
  name: string
  betaEnabled: boolean
  billingCountry?: string
  createdAt: string
  updatedAt: string
}
export const fetchOrganizations = createTableFetcher<FetchedOrganizations>(
  tableFormatter,
  `{
    id
    name
    betaEnabled
    billingCountry
    createdAt
    updatedAt
  }`,
  api.organization.list
)
