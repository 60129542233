import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import * as api from '../../graphql/api'

export interface ListMapLayerDefData {
  id: string
  name: string
}

const { selector: selectListUngroupedMapLayerDefs, refresh: refreshListUngroupedMapLayerDefs } =
  createAsyncSelector({
    resource: 'ListUngroupMapLayerDefsData',
    inputs: {
      lang: selectPreferredLanguage,
    },
    fetcher: async ({ lang }) => {

      const { data } = await api.mapLayerDef.list<ListMapLayerDefData>(
        {
          where: { mapLayerDefGroupId: { _is_null: true } },
          returning: `{
        id
        name(path: "${lang}")
      }`,
        }
      )

      if (!data) {
        throw new Error('No Data')
      }

      return {
        data,
      }
    },
  })

export { selectListUngroupedMapLayerDefs, refreshListUngroupedMapLayerDefs }
