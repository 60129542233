import * as React from 'react'
import Icon from '@mui/material/Icon'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { Column } from '../../admin/mapdata/types'
import { Checkbox, Tooltip } from '@mui/material'
import './EnhancedTableHead.scss'

interface Props {
  columns: Column<any>[]
  numSelected: number
  onRequestSort: (...args: any[]) => void
  onSelectAllClick: (...args: any[]) => void
  rowCount: number
  order?: 'asc' | 'desc'
  orderBy?: string
}

/**
 * Based on material UI Docs: https://v3.material-ui.com/demos/tables/
 */
class EnhancedTableHead extends React.Component<Props> {
  createSortHandler = (property: string) => () => {
    this.props.onRequestSort(property)
  }

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, columns } =
      this.props

    return (
      <TableHead className="EnhancedTableHead">
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              indeterminateIcon={<Icon>add_box</Icon>}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columns.map(
            ({ header, value }) => (
              <TableCell
                padding="checkbox"
                key={header}
                sortDirection={orderBy === value ? order : false}
              >
                {!!value ? (
                  <Tooltip title="Sort">
                    <TableSortLabel
                      active={orderBy === value}
                      direction={order}
                      onClick={this.createSortHandler(String(value!))}
                    >
                      {header}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  header
                )}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    )
  }
}

export default EnhancedTableHead
