import { gql, query } from '../../graphql/client'
import { MapLayerDef } from '../../graphql/types'
import { PackageData } from './PackageForm'
import { refreshEditPackage } from './selectEditPackage'

/** adds associated records for adding layer defs to a package */
const updatePackageMapLayerDefs = async (
  packageId: PackageData['id'],
  mapLayerDefIds: MapLayerDef['id'][]
) => {
  const result = await query({
    variables: {
      packageId,
    },
    query: gql`
      mutation updatePackageMapLayerDefs(
        $packageId: uuid!
      ) {

        delete_Package_MapLayerDef(where: { packageId: { _eq: $packageId } }) {
          affected_rows
        }

        insert_Package_MapLayerDef(objects: [
          ${mapLayerDefIds
            .map(
              (mapLayerDefId) =>
                `{ packageId: "${packageId}", mapLayerDefId: "${mapLayerDefId}" }`
            )
            .join(',\n')}
        ]) {
          affected_rows
        }
      }
    `,
  })

  refreshEditPackage()

  return result
}
export default updatePackageMapLayerDefs
