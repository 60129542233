import { OrganizationData } from '../../../data/selectOrganizationList'
import { selectOrganizations } from '../../../data/selectOrganizations'
import { useRedux } from '../../../hooks/useRedux'
import * as React from 'react'
import { Autocomplete, TextField } from '@mui/material'

interface Props {
  onOrganizationsSelected: (organization: OrganizationData[]) => void
  selectedOrganizations?: number[]
}

const ALL = { name: 'All', id: '-1', betaEnabled: true }

export const OrganizationsAutoComplete = ({
  onOrganizationsSelected,
  selectedOrganizations = [-1],
}: Props) => {
  const [state] = useRedux()

  const organizations = selectOrganizations(state)

  const adjustedOrganizations = React.useMemo(() => {
    return [ALL, ...organizations]
  }, [organizations])

  const memoizedSelectedOrganizations = React.useMemo(() => {
    const organizationsSet = new Set(selectedOrganizations)
    return adjustedOrganizations.filter((o) =>
      organizationsSet.has(Number(o.id))
    )
  }, [adjustedOrganizations, selectedOrganizations])

  const handleOrganizationsSelected = (_: any, value: OrganizationData[]) => {
    if (
      (value.some((o) => o.id === '-1') &&
        !selectedOrganizations.some((o) => o === -1)) ||
      value.length === 0
    ) {
      onOrganizationsSelected([ALL])
    } else {
      onOrganizationsSelected(value.filter((o) => o.id !== '-1'))
    }
  }

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      value={memoizedSelectedOrganizations}
      options={adjustedOrganizations}
      getOptionLabel={(org) => org?.name ?? ''}
      defaultValue={[ALL]}
      onChange={handleOrganizationsSelected}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Recieving Organizations"
        />
      )}
    />
  )
}
