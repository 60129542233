import * as React from 'react'
import Page from '../../../app/Page'
import i18n, { keys } from '../../../i18n'
import { url, urls } from '../../../appNavigation/urls'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table'
import useAsync from '../../../hooks/useAsync'
import {
  deleteRateMap,
  duplicateRateMap,
  getOrgFilterOptions,
  getRateMaps,
} from '../queries'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { useRedux } from '../../../hooks/useRedux'
import { darken, Stack, styled, useTheme } from '@mui/material'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { useHistory } from 'react-router-dom'
import { RateMapToolBarOptions } from './RateMapToolBarOptions'
import { RateMapEmptyView } from './RateMapEmptyView'
import { RateMapActionMenuItems } from './RateMapActionMenuItems'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import { RateMapRow } from '../types'
import ActionsCell from './ActionsCell'
import { RateMap } from '../../../graphql/types'
import { ConfirmationModal } from '../../../app/ConfirmationModal/ConfirmationModal'
import { DuplicateModal } from '../../../app/DuplicateModal/DuplicateModal'

const TableContainer = styled(Stack)({
  margin: '30px 128px',
})

export const RateMapDashboard = () => {
  const [state] = useRedux()
  const history = useHistory()
  const theme = useTheme()

  const preferredLanguage = selectPreferredLanguage(state)
  const organizationId = selectOrganizationId(state)
  const [rowCount, setRowCount] = React.useState(0)

  //table state
  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [sorting, setSorting] = React.useState<MRT_SortingState>([])
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const [filterOptions] = useAsync(async () => {
    return await getOrgFilterOptions({ organizationId })
  }, [organizationId])

  const amendmentFilterOptions = React.useMemo(() => {
    if (!filterOptions.result) return {}
    return filterOptions.result.amendmentOptions.map((a) => ({
      value: a.value,
      label: a.label[preferredLanguage],
    }))
  }, [filterOptions.result, preferredLanguage])

  const parcelFilterOptions = React.useMemo(() => {
    if (!filterOptions.result) return {}
    return filterOptions.result.parcelOptions
  }, [filterOptions.result])

  const [rateMapRequest, refreshRateMapRequest] = useAsync(async () => {
    const { RateMaps, totalRowCount } = await getRateMaps({
      organizationId,
      columnFilters,
      globalFilter,
      sorting,
      pagination,
    })
    setRowCount(totalRowCount)
    return RateMaps
  }, [
    organizationId,
    columnFilters,
    globalFilter,
    sorting,
    pagination.pageIndex,
    pagination.pageSize,
  ])

  const [deleteCandidate, setDeleteCandidate] = React.useState<RateMap>()

  const [duplicateCandidate, setDuplicateCandidate] = React.useState<RateMap>()

  const handleDelete = async () => {
    if (!deleteCandidate) {
      return
    }
    await deleteRateMap({ rateMapId: deleteCandidate.id })
    refreshRateMapRequest()
    setDeleteCandidate(undefined)
  }

  const handleDuplicate = async (value: string) => {
    if (!duplicateCandidate) {
      return
    }

    const { RateMap: duplicate } = await duplicateRateMap({
      rateMap: duplicateCandidate,
      newName: value,
    })
    history.push(url(urls.rateMap, { rateMapId: duplicate.id }))
  }

  const rateMaps = React.useMemo(() => {
    if (!rateMapRequest.result) return []
    return rateMapRequest.result.map((rm) => ({
      id: rm.id,
      name: rm.name,
      createdAt: i18n.toDateShort(rm.createdAt),
      block:
        rm?.RateMapBlockSelections || rm?.RateMapBlockSelections.length > 0
          ? rm?.RateMapBlockSelections?.map((b) => b?.Parcel?.name)?.join(', ')
          : '-',
      amendmentType: rm?.RateMapAmendmentType?.name[preferredLanguage] ?? '',
      edit: () => {
        history.push(url(urls.rateMap, { rateMapId: rm.id }))
      },
      duplicate: async () => {
        setDuplicateCandidate(rm)
      },
      delete: async () => {
        setDeleteCandidate(rm)
      },
    }))
  }, [rateMapRequest.result, preferredLanguage, history, refreshRateMapRequest])

  const columns = React.useMemo<MRT_ColumnDef<RateMapRow>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: i18n.t(keys.rateMapNameColumnLabel),
        enableSorting: false,
      },
      {
        accessorKey: 'createdAt',
        header: i18n.t(keys.rateMapDateColumnLabel),
        filterVariant: 'date-range',
        size: 80,
        enableSorting: false,
      },
      {
        accessorKey: 'block',
        header: i18n.t(keys.rateMapBlockColumnLabel),
        filterVariant: 'multi-select',
        filterSelectOptions: parcelFilterOptions,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'amendmentType',
        header: i18n.t(keys.rateMapAmendmentTypeColumnLabel),
        filterVariant: 'multi-select',
        filterSelectOptions: amendmentFilterOptions,
        enableSorting: false,
      },
      {
        id: 'actions',
        header: 'Actions',
        Cell: ({ row, table }) => <ActionsCell row={row} table={table} />,
      },
    ] as MRT_ColumnDef<RateMapRow>[]
  }, [amendmentFilterOptions, parcelFilterOptions])

  const table = useMaterialReactTable({
    columns,
    data: rateMaps,
    enableRowActions: false,
    enableColumnActions: false,
    getRowId: (row) => row.id,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps:
      rateMapRequest.status === 'rejected'
        ? {
            color: 'error',
            children: i18n.t(keys.errors.somethingWentWrong),
          }
        : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: rateMapRequest.status === 'pending',
      pagination,
      showAlertBanner: rateMapRequest.status === 'rejected',
      showProgressBars: rateMapRequest.status === 'pending',
      sorting,
    },
    localization:
      preferredLanguage === 'fr' ? MRT_Localization_FR : MRT_Localization_EN,
    positionActionsColumn: 'last',
    muiTableHeadCellProps: {
      sx: {
        fontSize: 14,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: 14,
      },
    },
    muiTableBodyProps: {
      sx: {
        '& tr:nth-of-type(odd) > td': {
          backgroundColor: darken(theme.palette.background.paper, 0.2),
        },
      },
    },
    renderTopToolbarCustomActions: RateMapToolBarOptions,
    renderRowActionMenuItems: RateMapActionMenuItems,
    renderEmptyRowsFallback: RateMapEmptyView,
  })

  return (
    <Page
      title={i18n.t(keys.rateMap)}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <TableContainer>
        <MaterialReactTable table={table} />
      </TableContainer>
      <ConfirmationModal
        open={!!deleteCandidate}
        onConfirm={handleDelete}
        onCancel={() => setDeleteCandidate(undefined)}
        title={i18n.t(keys.rateMapDelete)}
        message={i18n.t(keys.rateMapDeleteConfirmation)}
        dangerMode
      />
      <DuplicateModal
        open={!!duplicateCandidate}
        onConfirm={handleDuplicate}
        onCancel={() => setDuplicateCandidate(undefined)}
        title={i18n.t(keys.rateMapDuplicate)}
        message={i18n.t(keys.rateMapDuplicateConfirmation)}
        defaultValue={`${duplicateCandidate?.name} - Copy`}
      />
    </Page>
  )
}
