import { default as Icon } from '@mui/material/Icon'
import { default as Typography } from '@mui/material/Typography'
import * as React from 'react'
import {
  MapSourceDef,
  MapSourceDefFilename_update_input,
} from '../../../graphql/types'
import TooltipIconButton from '../../../UI/TooltipIconButton'
import CreateMapSourceFilenameDialog from './CreateMapSourceFilenameDialog'
import EditMapSourceFilenameDialog from './EditMapSourceFilenameDialog'
import { deleteFilename } from './filenameGQL'

interface Props {
  mapSourceDefId: MapSourceDef['id']
  filenames: MapSourceDefFilename_update_input[] | null
  onChange(): void
}

interface State {
  isCreating?: boolean
  editFilename?: Pick<MapSourceDefFilename_update_input, 'filename' | 'description'>
}

class MapSourceFilenameList extends React.PureComponent<Props, State> {
  state: State = {}

  render() {
    return (
      <div className="grid-xs-12">
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1">Filenames</Typography>
          <TooltipIconButton
            tooltipProps={{
              placement: 'right',
            }}
            title={`Create a filename `}
            onClick={() => this.setState({ isCreating: true })}
          >
            <Icon fontSize="small">add_circle_outline</Icon>
          </TooltipIconButton>
        </div>
        <div>
          {(this.props.filenames ?? []).map(({ filename, description }) => (
            <div key={filename}>
              <Typography component="span">
                {filename}
                <TooltipIconButton
                  title={`Edit filename `}
                  onClick={() => this.setState({ editFilename: { filename, description } })}
                >
                  <Icon fontSize="small">edit</Icon>
                </TooltipIconButton>
                <TooltipIconButton
                  title={`Delete filename `}
                  onClick={() => this.handleDelete(filename)}
                >
                  <Icon fontSize="small">delete</Icon>
                </TooltipIconButton>
              </Typography>
            </div>
          ))}
        </div>
        {this.renderCreateFilenameDialog()}
        {this.renderEditFilenameDialog()}
      </div>
    )
  }

  renderCreateFilenameDialog = () => {
    const { isCreating } = this.state
    const { mapSourceDefId } = this.props

    return (
      <CreateMapSourceFilenameDialog
        open={!!isCreating}
        key={String(isCreating)}
        onClose={this.handleDialogClose}
        mapSourceDefId={mapSourceDefId}
        onSave={this.handleSave}
      />
    )
  }

  renderEditFilenameDialog = () => {
    const { editFilename } = this.state

    if (!editFilename) {
      return null
    }

    return (
      <EditMapSourceFilenameDialog
        open={!!editFilename}
        onClose={this.handleDialogClose}
        filename={editFilename}
        onSave={this.handleSave}
      />
    )
  }

  handleSave = () => {
    this.props.onChange()
    this.handleDialogClose()
  }

  handleDialogClose = () =>
    this.setState({ isCreating: false, editFilename: undefined })

  handleDelete = async (
    filename: MapSourceDefFilename_update_input['filename']
  ) => {
    await deleteFilename(filename!)
    this.props.onChange()
  }
}

export default MapSourceFilenameList
