import * as React from 'react'

import { connect } from '../../redux/connect'
import { dismissNotification as dismiss } from '../../redux/notifications/redux'

import { AppDispatchProps, RootStore } from '../../redux/types'
import { DefaultNotification } from './DefaultNotification'
import { MobileAdvertiseNotification } from './MobileAdvertiseNotification'

const Notifications = (props: ReduxProps & AppDispatchProps) => {
    const { notifications, dispatch } = props

    const [open, setOpen] = React.useState(true)

    const currentNotification = notifications[0]

    const dismissNotification = (index: number) => {
      setOpen(false)
      setTimeout(() => {
        dispatch(dismiss(index, { appNotificationId: notifications[index].appNotificationId }))
        setOpen(true)
      }, 300)
    }

    if(!currentNotification) {
      return null
    }

    switch(currentNotification.type){
      case 'advertise|mobile-app':
          return <MobileAdvertiseNotification 
            open={open}
            currentNotification={currentNotification} 
            dismissNotification={dismissNotification}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}/>
      default: 
          return <DefaultNotification 
            open={open} 
            currentNotification={currentNotification} 
            dismissNotification={dismissNotification}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}/>
  }
  }

const mapState = (state: RootStore) => ({
  notifications: state.notifications.notifications,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  Notifications
)
