export const generateFill = (
  resolution = 64,
  lines = 4,
  lineThickness = 2,
  dash = true,
  opacity = 0.5,
  lineType: 'horizontal' | 'vertical' = 'vertical'
) => {
  const bytesPerPixel = 4 // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
  const data = new Uint8Array(resolution * resolution * bytesPerPixel)

  if (lineType === 'horizontal') {
    for (let line = 0; line < lines; line++) {
      const lineSpacing =
        resolution * (resolution / lines) * bytesPerPixel * line
      for (let x = 0; x < resolution * lineThickness; x++) {
        const offset = x * bytesPerPixel + lineSpacing
        data[offset + 0] = 136 // red
        data[offset + 1] = 147 // green
        data[offset + 2] = 133 // blue
        data[offset + 3] = !dash
          ? 255 * opacity
          : 255 * opacity * Math.min(1, x % 8) // alpha
      }
    }
  }

  // Since the array is flat, drawing lines along y-axis is a bit harder.
  if (lineType === 'vertical') {
    for (let line = 0; line < lines; line++) {
      const lineSpacing = (resolution / lines) * bytesPerPixel * line
      for (let x = 0; x < resolution * lineThickness; x++) {
        const y = Math.floor(x / resolution)
        // Essentially rotating the x position into a y coordinate.
        const position = y + (x % resolution) * resolution
        // Since we are working with a flat array, we offset of position to consider the bytes.
        // We also offset by space required for equidistant lines given the line count.
        const offset = position * bytesPerPixel + lineSpacing
        data[offset + 0] = 136 // red
        data[offset + 1] = 147 // green
        data[offset + 2] = 133 // blue
        // Set to transparent in sections if dash is enabled.
        data[offset + 3] = !dash
          ? 255 * opacity
          : 255 * opacity * Math.min(1, (position - y) % (resolution * 8)) // alpha
      }
    }
  }

  return {
    width: resolution,
    height: resolution,
    data: data,
  }
}
