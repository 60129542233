import * as React from 'react'
import { url, urls } from '../../../appNavigation/urls'
import { ScheduledNotification_create_input } from '../../../graphql/types'
import { useHistory } from '../../../hooks/useRouter'
import { AdminPage } from '../AdminPage'
import { createScheduledNotification } from './createScheduledNotification'
import ScheduledNotificationForm from './ScheduledNotificationForm'

export function ScheduledNotificationNew() {
  const history = useHistory()

  const handleCancel = () => history.push(url(urls.admin_notifications))

  const handleSave = async (input: ScheduledNotification_create_input) => {
    const result = await createScheduledNotification(input)
    const id = result?.data?.id

    if (id) {
      history.replace(url(urls.admin_notifications_edit, { id }))

      return true
    }

    return false
  }

  return (
    <AdminPage title="New Scheduled Notification">
      <ScheduledNotificationForm type="new" onCancel={handleCancel} onSave={handleSave} />
    </AdminPage>
  )
}
