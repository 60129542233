import { client, gql } from '../../../graphql/client'
import { sendCommentNotifications } from './sendCommentNotification'

interface Variables {
  deliveryId: string
  procGroup: string
  content: string
  replyToCommentId?: string
}
export const createDeliveryProcGroupComment = async (
  variables: Variables,
  mentionEmails?: string[]
) => {
  if (mentionEmails?.length) {
    const { users } = await client.request<{ users: { id: string }[] }>({
      query: gql`
        query GET_USERS_BY_EMAILS($emails: [String!]!) {
          users: User(where: { email: { _in: $emails } }) {
            id
          }
        }
      `,
      variables: {
        emails: mentionEmails,
      },
    })

    const {
      procGroupComment: { comment },
    } = await client.request<{
      procGroupComment: { comment: { id: string } }
    }>({
      query: gql`
        mutation CREATE_DELIVERY_PROC_GROUP_COMMENT(
          $deliveryId: uuid!
          $procGroup: String!
          $content: String!
          $replyToCommentId: uuid
          $userIds: [CommentMention_insert_input!]!
        ) {
          procGroupComment: insert_Delivery_ProcGroup_Comment_one(
            object: {
              deliveryId: $deliveryId
              procGroup: $procGroup
              Comment: {
                data: {
                  content: $content
                  replyToCommentId: $replyToCommentId
                  CommentMentions: { data: $userIds }
                }
              }
            }
          ) {
            comment: Comment {
              id
            }
          }
        }
      `,
      variables: {
        ...variables,
        userIds: users.map((user) => ({ userId: user.id })),
      },
    })

    await sendCommentNotifications(comment.id)
  } else {
    return await client.request({
      query: gql`
        mutation CREATE_DELIVERY_PROC_GROUP_COMMENT(
          $deliveryId: uuid!
          $procGroup: String!
          $content: String!
          $replyToCommentId: uuid
        ) {
          insert_Delivery_ProcGroup_Comment_one(
            object: {
              deliveryId: $deliveryId
              procGroup: $procGroup
              Comment: {
                data: { content: $content, replyToCommentId: $replyToCommentId }
              }
            }
          ) {
            comment: Comment {
              id
            }
          }
        }
      `,
      variables,
    })
  }
}
