export interface ClassStats {
  size: number
}

export interface ValueStats extends ClassStats {
  mean: number
  stdev: number
  cv: number
  min: number
  max: number
}

export function createValueStatsCalculator() {
  let entries: [number, number][] = []
  let _sum = 0
  let _size = 0
  let _min = NaN
  let _max = NaN

  return {
    reset() {
      entries = []
      _sum = 0
      _size = 0
      _min = NaN
      _max = NaN
    },
    update(
      data: { value: number; weight?: number; min?: number; max?: number }[]
    ) {
      for (const datum of data) {
        const { value, weight = 1 } = datum
        const { min = value, max = value } = datum

        _sum += value * weight
        _size += weight

        _min = isNaN(_min) ? min : Math.min(_min, min)
        _max = isNaN(_max) ? max : Math.max(_max, max)

        entries.push([value, weight])
      }
    },
    digest(): ValueStats {
      const mean = _sum / _size
      const stdev = Math.sqrt(
        entries.reduce(
          (acc, [value, weight]) => acc + weight * (value - mean) ** 2,
          0
        ) / _size
      )

      const cv = stdev / mean

      return {
        size: _size,
        mean: isNaN(mean) ? 0 : mean,
        stdev: isNaN(stdev) ? 0 : stdev,
        cv: isNaN(cv) ? 0 : cv,
        min: isNaN(_min) ? 0 : _min,
        max: isNaN(_max) ? 0 : _max,
      }
    },
  }
}

export function createClassStatsCalculator() {
  let size = 0

  return {
    reset() {
      size = 0
    },
    update(data: { weight?: number }[]) {
      for (const { weight = 1 } of data) {
        size += weight
      }
    },
    digest(): ClassStats {
      return {
        size,
      }
    },
  }
}
