import * as api from '../../../graphql/api'
import { Organization_create_input } from '../../../graphql/types'

export const createOrganization = async (input: Organization_create_input) => {
  const choice = await sweetAlert(
    `Are you sure you want to create this Organization?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Create',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (!choice) {
    return
  }

  return await api.organization.create<{ id: string }>({ input })
}
