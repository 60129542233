import { createSelector } from 'reselect'

import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectSelectedMapLayers } from '../../data/selectSelectedMapLayers'
import { selectSelectedMapSources } from '../../data/selectSelectedMapSources'
import {
  makeBlockByBlockSource,
  makeBorderLayer,
  makeTileLayers,
  makeTileSource,
  TileLayer,
} from '../../map/layers'
import { selectProductSettings } from '../../ProductSettings/store/selectors/selectProductSettings'
import {
  selectActiveColorProfiles,
  selectStats,
} from '../../ProductStats/selectors/stats'
import filterTruthy from '../../util/filterTruthy'

export const makeProductSourcesAndLayers = (
  sources: ReturnType<typeof selectSelectedMapSources>,
  layers: ReturnType<typeof selectSelectedMapLayers>,
  mapLayerDefsById: ReturnType<typeof selectMapLayerDefsById>,
  productSettings: ReturnType<typeof selectProductSettings>,
  activeColorProfiles: ReturnType<typeof selectActiveColorProfiles>,
  stats?: ReturnType<typeof selectStats>
) => ({
  sources: Object.entries(sources).reduce(
    (sources, [productId, mapSources]) => {
      if (productId.endsWith('-block-by-block')) {
        for (const source of mapSources) {
          const layerDef =
            mapLayerDefsById[productId.replace('-block-by-block', '')]
          sources[`${source.id}-block-by-block`] = makeBlockByBlockSource(
            source,
            layerDef,
            stats?.productStats?.[layerDef.id]?.parcels?.[source.parcelId]?.data
              ?.stats.mean
          )
        }
      } else {
        for (const source of mapSources) {
          const tileSource = makeTileSource(source)
          sources[tileSource.id] = tileSource
        }
      }

      return sources
    },
    {} as Record<string, any>
  ),
  layers: layers
    .reduce(
      (layersReduced, { layers: currentLayers, isBlockByBlockAverage }) => {
        for (const layer of currentLayers) {
          const product = mapLayerDefsById[layer.mapLayerDefId]
          const settings = productSettings[layer.mapLayerDefId] ?? {}
          const color = activeColorProfiles[layer.mapLayerDefId]

          settings.blockByBlockAverage = isBlockByBlockAverage

          if (
            !product ||
            (product.mapSourceDef.type !== 'raster' &&
              product.mapSourceDef.type !== 'raster-background' &&
              !color)
          ) {
            return layersReduced
          }

          if (layer.mapLayerDef.coverageProperty?.property) {
            const borderLayer = makeBorderLayer({
              layer,
              product,
              settings,
              color,
            })
            layersReduced.push(borderLayer)
          }

          const tileLayer = makeTileLayers({
            layer,
            product,
            settings,
            color,
          })

          if (tileLayer) {
            layersReduced.push(tileLayer)
          }
        }
        return layersReduced
      },
      [] as TileLayer[]
    )
    .filter(filterTruthy),
})

export const selectedProductLayers = createSelector(
  [
    selectSelectedMapSources,
    selectSelectedMapLayers,
    selectMapLayerDefsById,
    selectProductSettings,
    selectActiveColorProfiles,
    selectStats,
  ],
  makeProductSourcesAndLayers
)
