import * as React from 'react'
import { PureComponent } from 'react'
import { List, ListItem, Typography } from '@mui/material'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectFilteredGroups } from '../../data/selectFilteredGroups'
import {
  selectAvailableGroupIdsForFlightDate,
  selectOrgMapData,
} from '../../data/selectOrgMapData'
import { selectParcelFilter } from '../../data/selectParcelFilter'
import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import AdvancedFilters from './AdvancedFilters/AdvancedFilters'
import GroupListItem from './GroupListItem'
import ParcelFilter from './ParcelFilter'

class ParcelDrawer extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    const {
      groups,
      OrgMapDataRequest,
      parcelFilterRequest,
      availableGroupIds,
      selectedFlightDate,
    } = this.props

    const availableGroups = groups
      .filter(({ id }) => availableGroupIds.includes(id))
      .map((group) => <GroupListItem key={group.id} group={group} />)

    const unavailableGroups = groups
      .filter(({ id }) => !availableGroupIds.includes(id))
      .map((group) => <GroupListItem key={group.id} group={group} />)

    return (
      <AsyncSelectorStatusOverlay
        requests={OrgMapDataRequest}
        hideChildrenWhenLoading
      >
        <List dense>
          <ParcelFilter />
          <AdvancedFilters />
          <ListItem disabled>
            <Typography variant="subtitle1">
              {i18n.t(keys.parcelDrawer.selectParcels)}
            </Typography>
          </ListItem>
          <AsyncSelectorStatusOverlay
            hideChildrenWhenLoading
            requests={[OrgMapDataRequest, parcelFilterRequest]}
          >
            {availableGroups}
            {unavailableGroups.length > 0 && selectedFlightDate && (
              <ListItem disabled>
                <Typography variant="subtitle1">
                  {i18n.t(keys.parcelDrawer.unavailableForSelectedDate)}
                </Typography>
              </ListItem>
            )}
            {unavailableGroups}
            {groups.length === 0 && (
              <ListItem disabled>
                <Typography variant="caption">
                  {i18n.t(keys.parcelDrawer.noResults)}
                </Typography>
              </ListItem>
            )}
          </AsyncSelectorStatusOverlay>
        </List>
      </AsyncSelectorStatusOverlay>
    )
  }
}

const mapState = (state: RootStore) => ({
  groups: selectFilteredGroups(state),
  OrgMapDataRequest: selectOrgMapData(state),
  parcelFilterRequest: selectParcelFilter(state),
  availableGroupIds: selectAvailableGroupIdsForFlightDate(state),
  selectedFlightDate: getSelectedFlightDate(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(ParcelDrawer)
