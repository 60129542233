import { Icon, IconButtonProps } from '@mui/material'
import * as React from 'react'
import TooltipIconButton from '../TooltipIconButton'
import { TableAction } from './types'

export const createTableActionIconButton =
  ({
    icon,
    ...props
  }: {
    title: string
    icon: string
    style?: React.CSSProperties
    color?: IconButtonProps['color']
    key: string
  }): TableAction<any>['button'] =>
    ({ disabled, onClick }) => {
      return (
        <TooltipIconButton {...props} disabled={disabled} onClick={onClick} size="small" tooltipProps={{ placement: 'top' }}>
          <Icon fontSize='small'>{icon}</Icon>
        </TooltipIconButton>
      )
    }
