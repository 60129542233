import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import * as React from 'react'
import i18n, { keys } from '../../../../../i18n'

const characterLimit = 50

export const AddAmendmentDialog = ({
  open,
  onSave,
  onCancel,
}: {
  open: boolean
  onSave: (amendmentName: string) => Promise<void>
  onCancel: () => void
}) => {
  const [amendmentName, setAmendmentName] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  React.useEffect(() => {
    if (open) {
      setAmendmentName('')
      setErrorMessage('')
    }
  }, [open])

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{i18n.t(keys.rateMapAddAmendmentModalTitle)}</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="subtitle1" fontWeight="700">
            Name
          </Typography>
          <FormControl error={(amendmentName?.length ?? 0) > characterLimit}>
            <TextField
              sx={{
                [`& .MuiInputBase-input`]: {
                  px: 1,
                  py: 1.5,
                },
                [`& .MuiOutlinedInput-notchedOutline`]: {
                  backgroundColor: '#00000033',
                },
                [`&:not(.Mui-focused,:hover)`]: {
                  [`& .MuiOutlinedInput-notchedOutline`]: {
                    border: 'none',
                  },
                },
              }}
              autoFocus
              placeholder="Amendment Name"
              variant="outlined"
              fullWidth
              value={amendmentName}
              error={(amendmentName?.length ?? 0) > characterLimit}
              onChange={(ev) => setAmendmentName(ev.target.value)}
            />
            <FormHelperText>{`${
              amendmentName?.length ?? '-'
            }/${characterLimit}`}</FormHelperText>
          </FormControl>
          {errorMessage && (
            <Typography color="error" variant="subtitle2" fontWeight="700">
              {i18n.t(keys.rateMapAmendmentDuplicateError)}
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={'row'}
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            alignContent: 'center',
          }}
          spacing={1}
        >
          <Button onClick={onCancel} variant="text" color="inherit">
            Back
          </Button>
          <Button
            onClick={async () => {
              try {
                await onSave(amendmentName)
              } catch (error) {
                setErrorMessage(error.message)
              }
            }}
            variant="text"
            disabled={!amendmentName || amendmentName.length > characterLimit}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
