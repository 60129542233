import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import { default as Icon } from '@mui/material/Icon'
import { default as Typography } from '@mui/material/Typography'

import Page from '../../app/Page'
import { RouteParams, url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import * as api from '../../graphql/api'
import { MapLayerSettings } from '../../graphql/types'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import TooltipIconButton from '../../UI/TooltipIconButton'
import errorAlert from '../errorAlert'
import CreateColorProfileDialog from '../mapdata/color-profiles/CreateColorProfileDialog'
import UpdateColorProfileDialog from '../mapdata/color-profiles/UpdateColorProfileDialog'
import createOrUpdateMapLayerDefSettings from './createOrUpdateMapLayerDefSettings'
import MapLayerDefForm from './MapLayerDefForm'
import MapLayerDefSettingsForm from './MapLayerDefSettingsForm'
import {
  GetMapLayerDefData,
  refreshGetMapLayerDef,
  selectGetMapLayerDef,
} from './selectGetMapLayerDef'

interface State {
  creatingColorProfile?: boolean
  editColorProfileId?: string
  isLoading: boolean
}

class EditMapLayerDef extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps<RouteParams>,
  State
> {
  state: State = {
    isLoading: false,
  }

  render() {
    const { isLoading } = this.state
    const { preferredLanguage, mapLayerDef } = this.props
    const {
      MapLayerSettings: settings = [],
      MapColorProfiles = [],
      mapSourceDefId,
    } = mapLayerDef.data ?? ({} as Partial<GetMapLayerDefData>)

    // this is actually probably a one-to-one; not many-to-one
    const layerDefaults = settings[0] ?? {}

    return (
      <Page
        title={`Edit Layer Definition`}
        backTo={mapSourceDefId ? url(urls.editSourceDef, { mapSourceDefId }) : undefined}
        backToTitle="Source Def"
      >
        <AsyncSelectorStatusOverlay
          requests={mapLayerDef}
          isLoading={isLoading}
        >
          <div id="EditMapLayerDef" className="MapDataContainerSmall">
            <MapLayerDefForm
              type="edit"
              data={mapLayerDef.data}
              preferredLanguage={preferredLanguage}
              onDelete={this.handleDelete}
              onSave={this.handleSave}
            />
            <MapLayerDefSettingsForm
              type="edit"
              data={layerDefaults}
              onSave={this.handleSaveSettings}
            />

            <div className="grid Paper">
              <div className="grid-xs-12">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="subtitle1">Color Profiles</Typography>
                  <TooltipIconButton
                    tooltipProps={{
                      placement: 'right',
                    }}
                    title={`Create a Color Profile `}
                    onClick={() =>
                      this.setState({ creatingColorProfile: true })
                    }
                  >
                    <Icon fontSize="small">add_circle_outline</Icon>
                  </TooltipIconButton>
                </div>
                <div>
                  {MapColorProfiles.map((colorProfile) => (
                    <div key={colorProfile.id}>
                      <Typography component="div">
                        {colorProfile.name[preferredLanguage]}
                        <TooltipIconButton
                          tooltipProps={{
                            placement: 'right',
                          }}
                          title={`Edit color profile `}
                          onClick={() =>
                            this.setState({
                              editColorProfileId: colorProfile.id,
                            })
                          }
                        >
                          <Icon fontSize="small">edit</Icon>
                        </TooltipIconButton>
                        <TooltipIconButton
                          tooltipProps={{
                            placement: 'right',
                          }}
                          title={`Delete color profile `}
                          onClick={() =>
                            this.handleDeleteColorProfile(colorProfile)
                          }
                        >
                          <Icon fontSize="small">delete</Icon>
                        </TooltipIconButton>
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {this.renderCreateColorProfileDialog()}
          {this.renderUpdateColorProfileDialog()}
        </AsyncSelectorStatusOverlay>
      </Page>
    )
  }

  renderCreateColorProfileDialog = () => {
    const { creatingColorProfile } = this.state
    const { mapLayerDefId } = this.props.match.params

    return (
      <CreateColorProfileDialog
        key={`${creatingColorProfile}`}
        open={!!creatingColorProfile}
        onClose={this.handleDialogClose}
        mapLayerDefId={mapLayerDefId!}
        onSave={this.handleColorProfileSave}
      />
    )
  }

  renderUpdateColorProfileDialog = () => {
    const { editColorProfileId = '' } = this.state

    if (!(editColorProfileId)) {
      return null
    }

    return (
      <UpdateColorProfileDialog
        key={editColorProfileId}
        id={editColorProfileId}
        open={!!editColorProfileId}
        onClose={this.handleDialogClose}
        onSave={this.handleColorProfileSave}
      />
    )
  }

  handleColorProfileSave = () => {
    this.handleDialogClose()
  }

  handleDialogClose = () =>
    this.setState({
      creatingColorProfile: false,
      editColorProfileId: undefined,
    })

  handleSave = async (formData: Partial<GetMapLayerDefData>) => {
    const { mapLayerDefId } = this.props.match.params

    try {
      this.setState({ isLoading: true }, async () => {
        await api.mapLayerDef.update({
          pk: { id: mapLayerDefId! },
          input: formData,
        })
        refreshGetMapLayerDef()
        this.setState({ isLoading: false })
      })
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }
      this.setState({ isLoading: false })

      softError(e, 'Failed to Edit Layer Definition', message, this.state)
    }
  }

  handleSaveSettings = async (formData: MapLayerSettings) => {
    const { mapLayerDefId } = this.props.match.params
    const { MapLayerSettings: settings = [] } =
      this.props.mapLayerDef.data ?? ({} as Partial<GetMapLayerDefData>)

    // this is actually probably a one-to-one; not many-to-one
    const layerDefaults = settings[0] ?? {}
    try {
      this.setState({ isLoading: true }, async () => {
        await createOrUpdateMapLayerDefSettings({
          ...formData,
          id: layerDefaults.id,
          mapLayerDefId: mapLayerDefId!,
        })
        refreshGetMapLayerDef()
        this.setState({ isLoading: false })
      })
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      this.setState({ isLoading: false })

      softError(e, 'Failed to Edit Layer Definition', message, this.state)
    }
  }

  handleDeleteColorProfile = async (
    colorProfile: UnwrapArray<GetMapLayerDefData['MapColorProfiles']>
  ) => {
    const choice = await swal(
      `You are about to delete the "${colorProfile.name.en}" color profile. Are you sure?`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: 'Delete',
          },
        },
        dangerMode: true,
      }
    )
    if (!choice) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      await api.mapColorProfile.delete({ pk: { id: colorProfile.id } })
      refreshGetMapLayerDef()
      this.setState({ isLoading: false })
    })
  }

  goHome = () => {
    this.props.history.goBack()
  }

  handleDelete = async () => {
    const { data: mapLayerDef } = this.props.mapLayerDef

    if (!mapLayerDef) {
      return
    }

    const choice = await swal(
      `You are about to delete ${mapLayerDef.name.en}`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: 'Delete',
          },
        },
        dangerMode: true,
      }
    )

    // swal returns null for "cancel"
    if (!choice) {
      return
    }
    this.setState({ isLoading: true }, async () => {
      await api.mapLayerDef.delete({ pk: { id: mapLayerDef.id } })
      refreshGetMapLayerDef()
      this.setState({ isLoading: false }, () => {
        this.goHome()
      })
    })
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

const mapState = (state: RootStore) => ({
  preferredLanguage: selectPreferredLanguage(state),
  mapLayerDef: selectGetMapLayerDef(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(EditMapLayerDef)
)
