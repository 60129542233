import * as React from 'react'

import { Menu } from '@mui/material'

export interface PopupProps {
  open?: boolean | 'hover' | 'click'
  onClose?: () => void
  children: React.ReactNode
  popup: React.ReactElement<any>
  disabled?: boolean
}

export const Popup = ({
  onClose,
  children,
  popup,
  disabled
}: PopupProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.()
  };

  return <><span onClick={handleClick}>
    {children}
  </span>
    <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
      {popup}
    </Menu>
  </>
}
