import { default as CircularProgress } from '@mui/material/CircularProgress'
import * as React from 'react'
import './FileDropTarget.scss'
import { getAllFilesFromDataTransferItems } from './getAllFilesFromDataTransferItems'

interface Props {
  type?: 'directory' | 'file'
  children: React.ReactNode
  extensions?: string[]
  onFilesDropped?: () => void
  onFilesSelected(files: File[]): void
}

interface State {
  drag: boolean
  /** might take awhile for the browser to read all dragged/dropped files */
  pending: boolean
}

class FileDropTarget extends React.PureComponent<Props, State> {
  state: State = {
    drag: false,
    pending: false,
  }

  eventsAdded = false
  dropRef = React.createRef<HTMLDivElement>()
  filesRef: HTMLInputElement | null = null

  componentDidMount() {
    this.events('add')
  }

  componentDidUpdate() {
    this.events('add')
  }

  componentWillUnmount() {
    this.events('remove')
  }

  render() {
    const { type = 'directory', extensions = [] } = this.props

    return (
      <React.Fragment>
        {type === 'directory' ? (
          <input
            type="file"
            onChange={this.handleClickChange}
            ref={this.addCustomAttributes}
            multiple
          />
        ) : (
          <input
            type="file"
            onChange={this.handleClickChange}
            ref={this.addCustomAttributes}
            accept={extensions.join(',')}
          />
        )}
        <div className="FileDropTarget__container" ref={this.dropRef}>
          <div className="FileDropTarget__fileList">{this.props.children}</div>
          <div className="FileDropTarget__dropArea">
            {this.state.pending && (
              <div className="FileDropTarget__progress">
                <CircularProgress />
              </div>
            )}
            {this.state.drag && (
              <div className="FileDropTarget__dragText">Drop Here</div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  addCustomAttributes = (input: HTMLInputElement | null) => {
    const { type = 'directory' } = this.props
    this.filesRef = input
    if (input && type === 'directory') {
      input.setAttribute('webkitdirectory', '')
    }
  }

  events = (addOrRemove: 'add' | 'remove') => {
    const div = this.dropRef.current

    if (!div) {
      return
    }

    if (
      (addOrRemove === 'add' && this.eventsAdded) ||
      (addOrRemove === 'remove' && !this.eventsAdded)
    ) {
      return
    }

    div[`${addOrRemove}EventListener`]('dragleave', this.handleDragOut as any)
    div[`${addOrRemove}EventListener`]('dragover', this.handleDrag as any)
    div[`${addOrRemove}EventListener`]('drop', this.onFilesSelected as any)

    this.eventsAdded = addOrRemove === 'add'
  }

  handleDrag = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.items?.length > 0) {
      this.setState({ drag: true })
    }
  }

  handleDragOut = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
  }

  onFilesSelected = async (e: React.DragEvent) => {
    if (this.props.onFilesDropped) {
      this.props.onFilesDropped()
    }
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })

    // let the file input know that files have been loaded
    if (this.filesRef) {
      this.filesRef.files = e.dataTransfer.files
    }

    // iterate dragged items to find all files
    if (e.dataTransfer.items?.length > 0) {
      const { extensions } = this.props

      this.setState({
        pending: true,
      })

      const allFiles = await getAllFilesFromDataTransferItems(
        e.dataTransfer.items,
        extensions
      )

      this.props.onFilesSelected(Object.values(allFiles))

      this.setState({
        pending: false,
      })

      e.dataTransfer.clearData()
    }
  }

  /** handle input clicking/selecting files/folders */
  handleClickChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pending: false,
    })

    if (e.target.files) {
      const { extensions = [] } = this.props
      let filtered = Array.from(e.target.files)

      if (extensions.length > 0) {
        filtered = filtered.filter((file) =>
          extensions.some((ext) => file.name.endsWith(ext))
        )
      }

      this.props.onFilesSelected(filtered)
    }
  }
}

export default FileDropTarget
