import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import { Checkbox, Icon, Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import { default as TablePagination } from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import Page from '../../app/Page'
import {
  DEFAULT_PAGE_INFO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../graphql/api'
import { GQLQueryResult } from '../../graphql/createGQLResource'
import { MonitorEvent } from '../../graphql/types'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import EnhancedTableHead from '../../UI/EnhancedTable/EnhancedTableHead'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import { Tags } from '../../UI/Tags'
import TooltipIconButton from '../../UI/TooltipIconButton'
import tableSort from '../../util/tableSort'
import { Column } from '../mapdata/types'
import { refreshListEvent, selectListEvent } from './monitoringSelectors'

interface State {
  order?: 'asc' | 'desc'
  orderBy?: string
  selected: string[]
}

class ListMonitorEvents extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps,
  State
> {
  state: State = {
    selected: [],
  }

  handleProps() {
    const {
      eventListSelector: { data, error, status, ...rest },
    } = this.props

    return {
      status,
      error,
      data: data?.data ?? ([] as GQLQueryResult<MonitorEvent>[]),
      info: data?.info ?? DEFAULT_PAGE_INFO,
      ...rest,
    }
  }

  render() {
    const { eventListSelector } = this.props
    const { data } = this.handleProps()
    const { selected, order, orderBy } = this.state
    const pagination = this.renderPagination()

    return (
      <Page
        title={`Monitoring Dashboard`}
        backTo={url(urls.mapView)}
        backToTitle={i18n.t(keys.map.map)}
      >
        <AsyncSelectorStatusOverlay requests={eventListSelector}>
          <div id="ListMonitorEvents" className="Paper">
            <EnhancedTableToolbar
              title={`Events`}
              numSelected={selected.length}
              onClickDelete={this.handleClickDelete}
              onClickRefresh={this.handleRefresh}
            />
            {pagination}
            <Table>
              <EnhancedTableHead
                columns={COLUMNS}
                numSelected={selected.length}
                onRequestSort={this.handleRequestSort}
                onSelectAllClick={this.handleSelectAllClick}
                rowCount={data.length}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {tableSort<GQLQueryResult<MonitorEvent>>({
                  order,
                  orderBy,
                  array: data,
                }).map((row) => {
                  const isSelected = selected.includes(row.id!)

                  return (
                    <TableRow
                      key={row.id!}
                      onClick={() => this.handleClickRow(row.id!)}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      {COLUMNS.filter((column) => !!column.value).map(
                        ({ header, value, formatter }) => (
                          <TableCell key={header} padding="checkbox">
                            {formatter?.(row, value!) ?? row[value!]}
                          </TableCell>
                        )
                      )}
                      <TableCell padding="checkbox">
                        <TooltipIconButton
                          title={i18n.t(keys.generic.view)}
                          onClick={(e: any) => {
                            e.stopPropagation()
                            this.handleClickViewRow(row.id!)
                          }}
                        >
                          <Icon>arrow_forward</Icon>
                        </TooltipIconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {pagination}
          </div>
        </AsyncSelectorStatusOverlay>
      </Page>
    )
  }

  renderPagination() {
    const { info } = this.handleProps()

    return (
      <TablePagination
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        component="div"
        count={info.count ?? 0}
        rowsPerPage={info.pageSize || DEFAULT_PAGE_SIZE}
        page={info.page ?? 0}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    )
  }

  handleClickViewRow = (eventId: string) => {
    this.props.history.push(url(urls.monitorEvent, { eventId }))
  }

  handleClickRow = (id: string) => {
    const selected = new Set(this.state.selected)

    if (selected.has(id)) {
      selected.delete(id)
    } else {
      selected.add(id)
    }

    this.setState({
      selected: Array.from(selected),
    })
  }

  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selected: string[] = []
    const { data } = this.handleProps() as {
      data: GQLQueryResult<MonitorEvent>[]
    }

    if (event.target.checked) {
      selected = data.map((obj) => obj.id!)
    }

    this.setState({
      selected,
    })
  }

  handleChangePage = (_event: any, page: number) => {
    const {
      info: { pageSize },
    } = this.handleProps()
    this.props.history.push(url(urls.monitorEventList, {}, { page, pageSize }))
  }

  handleChangeRowsPerPage = (event: any) => {
    this.props.history.push(
      url(urls.monitorEventList, {}, { page: 0, pageSize: event.target.value })
    )
  }

  handleRequestSort = (orderBy: string) => {
    let order = 'asc' as 'asc' | 'desc'

    if (this.state.orderBy === orderBy && this.state.order === 'asc') {
      order = 'desc'
    }

    this.setState({ order, orderBy })
  }

  handleRefresh = () => refreshListEvent()

  handleClickDelete = async () => {
    const { selected } = this.state
    const { length } = selected
    const pluralized = length === 1 ? '1 event' : `${length} events`

    const choice = await swal(`You are about to delete ${pluralized}`, {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Delete',
        },
      },
      dangerMode: true,
    })

    // swal returns null for "cancel"
    if (!choice) {
      return
    }

    for (const id of selected) {
      await api.monitorEvent.delete({ pk: { id } })
    }

    this.setState({
      selected: [],
    })

    refreshListEvent()
  }
}

const convertDate = (dateString?: string) => {
  if (!dateString) {
    return undefined
  }
  const date = new Date(dateString)

  return `${date.toLocaleTimeString()} ${date.toLocaleDateString()}`
}

const COLUMNS: Column<Partial<MonitorEvent>>[] = [
  {
    header: 'Title',
    value: 'title',
  },
  {
    header: 'Level',
    value: 'level',
  },
  {
    header: 'Time',
    value: 'eventTime',
    formatter: ({ eventTime }) => convertDate(eventTime),
  },
  {
    header: 'Tags',
    value: 'tags',
    formatter: ({ tags }) => <Tags tags={tags} />,
  },
  { header: 'Actions' },
]

const mapState = (state: RootStore) => ({
  eventListSelector: selectListEvent(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(ListMonitorEvents)
)
