import { LngLatLike } from 'mapbox-gl'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as SampleCompleteIcon } from '../assets/sample_plan_complete.svg'
import { ReactComponent as SampleIncompleteIcon } from '../assets/sample_plan_incomplete.svg'

import {
  ChevronLeft,
  ChevronRight,
  Edit,
  LocationSearching,
} from '@mui/icons-material'
import {
  Button,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled, useTheme } from '@mui/styles'

import { url, urls } from '../../../appNavigation/urls'
import { Sample } from '../../../graphql/types'
import { useRedux } from '../../../hooks/useRedux'
import i18n, { keys } from '../../../i18n'
import { MapContext } from '../../../map/withMap'
import PercentageBar from '../../../UI/PercentageBar/PercentageBar'
import { FILTER } from '../../../UI/Table/filterRows'
import { tableFormatter } from '../SamplePlanResult/samplePlanResultTableFormatter'
import { SamplePlanStatusText } from '../SamplePlanStatusText'
import { useSamplePlanMapContext } from '../Context/SamplePlanMapContext'

interface Props {
  onClose: () => void
}

export const SamplePlanBlockDetailContent = ({ onClose }: Props) => {
  const theme = useTheme()
  const [_, dispatch] = useRedux()
  const { map } = React.useContext(MapContext)
  const {
    state: { selectedSamplePlanSamples },
  } = useSamplePlanMapContext()
  const history = useHistory()
  const {
    state: { samplePlan, selectedSamplePlanBlocks, highlightedSample },
    dispatch: samplePlanMapDispatch,
  } = useSamplePlanMapContext()

  React.useEffect(() => {
    // offset so the sample pin can be seen overtop of the open map panel.
    map?.flyTo({
      center: highlightedSample?.geometry?.coordinates as LngLatLike,
      zoom: 20,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedSample])

  const getPercentage = (nom: number, denom: number) => {
    return Math.round((nom / (denom + Number.EPSILON)) * 100)
  }

  const navigateToBlockResult = () => {
    history.push(
      url(
        urls.samplePlanResults,
        { samplePlanId: samplePlan?.id },
        {
          filter: FILTER.serialize([
            {
              index: tableFormatter({
                inlineFilterId: 'sample-plan-inline-filter',
                searchBarFilterId: '',
              }).findIndex(
                (tf) => tf?.['filterId'] === 'sample-plan-inline-filter'
              ),
              op: 'eq',
              value: selectedSamplePlanBlocks?.[0]?.Parcel.name,
            },
          ]),
        }
      )
    )
  }

  const noteTemplateFormatter = (content: Record<string, any>) => {
    switch (content.type) {
      case 'dropdown':
      case 'text':
      case 'number':
        return content?.value ?? '-'
      case 'checkbox':
        return content?.value
          ? i18n.t(keys.samplePlanResultCheckBoxContentTrue)
          : i18n.t(keys.samplePlanResultCheckBoxContentFalse)
      case 'checkboxes':
        return content?.value?.map((v: string) => {
          return i18n.t(`noteForm.reservedNames.${v}`, { defaultValue: v })
        }).join(', ') ?? '-'
      default:
        return '-'
    }
  }

  const SampleFormData = ({ sample }: { sample: Sample }) => {
    // Only show the first two.
    if (!sample.completedAt) {
      return <Typography>{'-'}</Typography>
    }
    const contents = Object.entries(sample?.content ?? {}).slice(0, 2)
    return (
      <Stack direction="row" alignItems="center">
        {contents.map((c, i) => {
          const content = `${i18n.t(`noteForm.reservedNames.${c[0]}`, { defaultValue: c[0]})}: ${noteTemplateFormatter(c[1])}`
          return (
            <Tooltip title={content} placement="right" followCursor key={c[0]}>
              <Typography
                sx={{
                  pr: '16px',
                  fontSize: theme.typography.fontSize,
                  color: '#FFFFFFB2',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '92px',
                }}
                key={i}
              >
                {content}
              </Typography>
            </Tooltip>
          )
        })}
      </Stack>
    )
  }

  const SampleFormDataRemaining = ({ sample }: { sample: Sample }) => {
    // Only represent the ones counted past the second form field.
    const formData = Object.entries(sample?.content ?? {}).slice(2)
    return formData.length > 0 && sample.completedAt ? (
      <Typography
        fontSize="13px"
        color="#FFFFFFB2"
      >{`(+${formData.length})`}</Typography>
    ) : null
  }

  const getSampleTitle = (sample: Sample) => {
    return sample.id.substring(0, 6).toUpperCase()
  }

  const getSampleRow = (sample: Sample) => {
    const sampleRow = sample.rowID
      ? `${i18n.t(keys.valueNames.RowID)}: ${sample.rowID}`
      : '-'
    return sampleRow
  } 

  const getSamplePlantIndex = (sample: Sample) => {
    const samplePlantIndex = sample.plantIndex
      ? `${i18n.t(keys.valueNames.PlantID)}: ${sample.plantIndex}`
      : '-'
    return samplePlantIndex
  }

  const getSampleCompletingUser = (sample: Sample) => {
    const completingUser = sample.CompletedByUser
      ? `${sample.CompletedByUser.firstName} ${sample.CompletedByUser.lastName}`
      : '-'
    return completingUser
  }

  const sortSamples = (a: Sample, b: Sample) => {
    if (a.rowID !== b.rowID) {
      return Number(a.rowID) - Number(b.rowID)
    }
    if (a.plantID && b.plantID && a.plantID !== b.plantID) {
      return Number(a.plantID) - Number(b.plantID)
    }
    return a.id.localeCompare(b.id)
  }

  const SamplePaper = styled(({ sample }: { sample: Sample }) => {
    return (
      <ListItem sx={{ width: '100%' }}>
        <Paper elevation={0} sx={{ width: '100%' }}>
          <Stack direction="row" spacing={2} sx={{ padding: theme.spacing(1) }}>
            <Stack
              direction="column"
              justifyContent={!sample?.completedAt ? 'center' : undefined}
            >
              <Icon style={{ fontSize: 20 }}>
                {!!sample?.completedAt ? (
                  <SampleCompleteIcon height={18} width={18} />
                ) : (
                  <SampleIncompleteIcon height={18} width={18} />
                )}
              </Icon>
            </Stack>
            <Stack
              direction="column"
              flexGrow={1}
              spacing={1}
              justifyContent={!sample?.completedAt ? 'center' : undefined}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {!!sample.rowID && !!sample.plantIndex ? (
                  <Stack direction="column">
                    <Typography fontWeight={600}>
                      {`${getSampleRow(sample)}`}
                    </Typography>
                    <Typography fontWeight={600}>
                      {getSamplePlantIndex(sample)}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography fontWeight={600}>
                    {getSampleTitle(sample)}
                  </Typography>
                )}
              </Stack>
              {!!sample?.completedAt ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <SampleFormData sample={sample} />
                  <SampleFormDataRemaining sample={sample} />
                </Stack>
              ) : null}
              {!!sample?.completedAt ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>{getSampleCompletingUser(sample)}</Typography>
                </Stack>
              ) : null}
              {!!sample?.completedAt ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  {!sample.completedAt ? (
                    <Typography>{'-'}</Typography>
                  ) : (
                    <Typography>{`${i18n.toDateShort(
                      sample.completedAt
                    )}`}</Typography>
                  )}
                  {!sample.completedAt ? (
                    <Typography>{'-'}</Typography>
                  ) : (
                    <Typography>{`${i18n.toTimeHourMinute(
                      sample.completedAt
                    )}`}</Typography>
                  )}
                </Stack>
              ) : null}
            </Stack>
            <Stack direction={!!sample?.completedAt ? 'column' : 'row'}>
              <IconButton
                onClick={() =>
                  dispatch({
                    type: 'setEditingSampleId',
                    sampleId: sample.id,
                  })
                }
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() =>
                  samplePlanMapDispatch({
                    type: 'SET_HIGHLIGHTED_SAMPLE',
                    sampleId: sample.id,
                  })
                }
              >
                <LocationSearching />
              </IconButton>
            </Stack>
          </Stack>
        </Paper>
      </ListItem>
    )
  })(({ theme, sample }) => ({}))

  const sortedSamples = React.useMemo(() => {
    return selectedSamplePlanSamples?.sort(sortSamples)
  }, [selectedSamplePlanSamples])

  const completedSamples = React.useMemo(() => {
    return selectedSamplePlanSamples?.filter((s) => !!s.completedAt)
  }, [selectedSamplePlanSamples])

  return (
    <Stack direction="column">
      <Stack
        className="sample-plan-detail-info-panel"
        direction="column"
        spacing={1}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={onClose} sx={{ padding: '0px' }}>
            <ChevronLeft />
          </IconButton>
          <Typography sx={{ fontWeight: 700 }} variant="h6">
            {selectedSamplePlanBlocks?.[0]?.Parcel?.name ?? '-'}
          </Typography>
        </Stack>
        <Stack>
          <Typography>{`${
            selectedSamplePlanBlocks?.[0]?.Parcel?.Organization?.name ?? '-'
          }, ${
            selectedSamplePlanBlocks?.[0]?.Parcel?.OrganizationGroup?.name ??
            '-'
          }`}</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column" spacing={1}>
            <SamplePlanStatusText
              variant="overline"
              isPending={samplePlan?.isPending}
              completedSamples={
                completedSamples.length
              }
              totalSamples={selectedSamplePlanSamples?.length}
            />
            <Stack direction="row" alignItems="center">
              <div className="sample-plan-percentage-bar-container">
                <PercentageBar
                  percentage={getPercentage(
                    completedSamples
                      ?.length ?? 0,
                    selectedSamplePlanSamples?.length ?? 0
                  )}
                />
              </div>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontWeight: 700 }}>
                {`${getPercentage(
                  completedSamples
                    ?.length ?? 0,
                  selectedSamplePlanSamples?.length ?? 0
                )}%`}
              </Typography>
              <Divider
                sx={{ mx: '5px' }}
                orientation="vertical"
                variant="fullWidth"
                flexItem
              />
              <Typography>{`${
                completedSamples
                  ?.length ?? '-'
              }/${selectedSamplePlanSamples?.length ?? '-'} ${i18n.t(
                keys.samplePlanBlockSampleCountColumnLabel
              )}`}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyItems="start">
          <Button endIcon={<ChevronRight />} onClick={navigateToBlockResult}>
            {i18n.t(keys.seeResults)}
          </Button>
        </Stack>
      </Stack>
      <List sx={{ maxHeight: 'calc(100vh - 290px)', overflowY: 'scroll' }}>
        {sortedSamples.map((sample) => (
          <SamplePaper key={sample.id} sample={sample} />
        ))}
      </List>
    </Stack>
  )
}
