import { Reducer } from 'redux'

interface SamplingState {
  editingSampleId?: string
}

type SamplingActions = {
  type: 'setEditingSampleId'
  sampleId: string
}

const initialState: SamplingState = {
    editingSampleId: undefined,
}

const reducer: Reducer<SamplingState> = (state: SamplingState = initialState, action: SamplingActions) => {
  switch (action.type) {
    case `setEditingSampleId`: {
      if (!action.sampleId) {
        return {
          ...state,
          editingSampleId: undefined,
        }
      }

      return {
        ...state,
        editingSampleId: action.sampleId,
      }
    }
    default: 
      return state
  }
}

export { SamplingState, SamplingActions, initialState, reducer}
