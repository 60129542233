import { PayloadAction } from '@reduxjs/toolkit'
import { HeaderOption, MapOption, DrawerTabOption } from '../types'
import { MapEditorToolType } from '../../redux/map-editor-pages/redux'
import { RootStore } from '../../redux/types'

const MapEditorActionTypes = {
  SET_HEADER_OPTIONS: 'SET_HEADER_OPTIONS',
  SET_MAP_OPTIONS: 'SET_MAP_OPTIONS',
  SET_DRAWER_OPTIONS: 'SET_DRAWER_OPTIONS',
  SET_DRAWER: 'SET_DRAWER',
  SET_DRAWER_TAB_INDEX: 'SET_DRAWER_TAB_INDEX',
  SET_DRAWER_WIDTH: 'SET_DRAWER_WIDTH',
}

export interface MapSettings {
  userSelection?: Partial<RootStore['userSelection']>
  productSettings?: Partial<RootStore['ProductSettings']>
}

interface MapEditorState<HeaderConfig, MapConfig, DrawerConfig> {
  headerOptions: Record<string, HeaderOption<HeaderConfig>>
  mapOptions: Record<string, MapOption<MapConfig>>
  drawerOptions: Record<string, DrawerTabOption<DrawerConfig>>
  rightDrawerOpen: boolean | undefined
  leftDrawerOpen: boolean | undefined
  rightDrawerTabIndex: number
  leftDrawerTabIndex: number
  leftDrawerWidth: number
  rightDrawerWidth: number
  mapSettings?: MapSettings
}

interface MapEditorAction<Payload> extends PayloadAction<Payload> {
  page: MapEditorToolType
}

interface Payload<HeaderConfig, MapConfig, DrawerConfig> {
  id: string
  page: string
  headerOptions: Record<string, HeaderOption<HeaderConfig>>
  mapOptions: Record<string, MapOption<MapConfig>>
  drawerOptions: Record<string, DrawerTabOption<DrawerConfig>>
  rightDrawerOpen?: boolean
  leftDrawerOpen?: boolean
  rightDrawerTabIndex?: number
  leftDrawerTabIndex?: number
  leftDrawerWidth?: number
  rightDrawerWidth?: number
  mapSettings?: MapSettings
}

export { MapEditorState, MapEditorActionTypes, MapEditorAction, Payload }
