const sources: Record<string, mapboxgl.VectorSource> = {
  elevation: {
    url: 'mapbox://mapbox.mapbox-terrain-v2',
    type: 'vector',
  },
}

const COLOR = 'white'

const layers = [
  {
    id: 'contour-labels',
    type: 'symbol',
    source: 'elevation',
    'source-layer': 'contour',
    layout: {
      /** default: elevation in meters */
      'text-field': ['concat', ['get', 'ele']],
      /** 0 - 16 */
      'text-size': 12,
      'symbol-placement': 'line',
      /** in ems */
      'text-offset': [0, 0.6],
    },
    paint: {
      'text-color': COLOR,
      'text-halo-color': 'black',
      'text-halo-blur': 2,
      'text-halo-width': 2,
    },
  },
  {
    id: 'contour',
    type: 'line',
    source: 'elevation',
    'source-layer': 'contour',
    layout: {},
    paint: {
      'line-color': COLOR,
    },
  },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sources,
  layers,
}
