import * as React from 'react'

import {
  Button,
  buttonClasses,
  ButtonGroup,
  buttonGroupClasses,
  Icon,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, styled, withStyles, WithStyles } from '@mui/styles'

import { selectAvailableProductIds } from '../data/selectAvailableProductIds'
import i18n, { keys } from '../i18n'
import Legend from '../legend/Legend'
import CursorLocation from '../map/controls/CursorLocation'
import FitSelectedNote from '../map/controls/FitSelectedNote'
import FocusedPointPopup from '../map/controls/FocusedPointPopup'
import FocusedPolygonPopup from '../map/controls/FocusedPolygonPopup'
import GPSControl from '../map/controls/GPSControl'
import GPSOffsetMarkerControl from '../map/controls/GPSOffsetMarkerControl'
import MeasureToolToggle from '../map/controls/MeasureToolToggle'
import ScaleControl from '../map/controls/ScaleControl'
import { SettingsDrawerToggle } from '../map/controls/SettingsDrawer'
import SpeedTracker from '../map/controls/SpeedTracker'
import { Toolbar } from '../map/controls/Toolbar'
import UserPositionToggle from '../map/controls/UserPositionToggle'
import ZoomControl from '../map/controls/ZoomControl'
import { MapEventHandler } from '../map/events/MapEventHandler'
import MapMoveHandler from '../map/events/MapMoveHandler'
import { Map } from '../map/Map'
import CreateNoteButton from '../notes/CreateNoteButton'
import { NoteMapComponents } from '../notes/NoteMapComponents'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import * as actions from './actions'
import FitSelectedParcels from './FitSelectedParcels'
import FocusedBlockPopup from './FocusedBlockPopup'
import MapViewPage from './MapViewPage'
import MeasureTool from './MeasureTool'
import RestoreMapPosition from './RestoreMapPosition'
import { selectAllMapLayers } from './selectors/selectAllMapLayers'
import FocusedSoilPopup from './SoilLayer/FocusedSoilPopup'

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [`&.${buttonGroupClasses.root}`]: {
    [`& .${buttonClasses.root}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,
      width: 45,
      height: 45,
    },
  },
}))

class MapView extends React.Component<
  ReduxProps & AppDispatchProps & WithStyles<typeof styles>
> {
  render() {
    const {
      classes,
      isEditingNote,
      isMeasureToolActive,
      isGPSOffsetMarkerVisible,
    } = this.props

    return (
      <MapViewPage>
        <Map classNames={[this.props.theme]} style={this.props.mbStyle}>
          {/* controls */}
          <div className="MapControl-top-left">
            <ScaleControl />
            <CursorLocation />

            {isMeasureToolActive && <MeasureTool />}
            {!isGPSOffsetMarkerVisible && <SpeedTracker />}
            <GPSControl />
          </div>
          <div className="MapControl-right">
            <ZoomControl />
            {!isGPSOffsetMarkerVisible && (
              <Toolbar>
                <StyledButtonGroup
                  variant="contained"
                  size="large"
                  orientation="vertical"
                >
                  <MeasureToolToggle />
                  <CreateNoteButton />
                  <UserPositionToggle />
                  <Button onClick={this.zoomToSelection}>
                    <Tooltip
                      title={i18n.t(keys.zoomToSelection)}
                      placement="left"
                    >
                      <Icon>border_outer</Icon>
                    </Tooltip>
                  </Button>
                  <SettingsDrawerToggle />
                </StyledButtonGroup>
              </Toolbar>
            )}
          </div>

          {/* END controls */}

          {/* non-rendering */}
          <RestoreMapPosition />
          <FitSelectedParcels />
          <FitSelectedNote />
          <MapMoveHandler />
          <MapEventHandler />
          {/* END non-rendering */}

          <NoteMapComponents />
          <FocusedPointPopup />
          <FocusedPolygonPopup />
          <FocusedBlockPopup />
          <FocusedSoilPopup />

          {!isEditingNote && !isGPSOffsetMarkerVisible && (
            <Legend classes={{ root: classes.legend }} />
          )}

          {isGPSOffsetMarkerVisible && <GPSOffsetMarkerControl />}
        </Map>
      </MapViewPage>
    )
  }

  zoomToSelection = () => {
    this.props.dispatch(actions.setFitSelectedParcels(true))
  }
}

const styles = (theme: Theme) =>
  createStyles({
    legend: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
  })

const mapState = (state: RootStore) => {
  const legendWillRender = selectAvailableProductIds(state).length > 0
  const isEditingNote = state.notes.editingNoteId || state.notes.isCreatingNote

  return {
    legendWillRender,
    isEditingNote,
    mbStyle: selectAllMapLayers(state),
    theme: state.preferences.theme,
    isMeasureToolActive: state.postgis.isMeasureToolActive,
    isStatsZonesToolActive: state.postgis.isStatsZonesToolActive,
    isGPSOffsetMarkerVisible: state.postgis.isGPSOffsetMarkerVisible,
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(MapView)
)
