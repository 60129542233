import * as React from 'react'

import {
  Button, Checkbox, Icon, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography
} from '@mui/material'

import errorAlert from '../../../../admin/errorAlert'
import { getStatusIcon } from '../../../../admin/Maestro/utils'
import { Row } from '../../../../admin/UI/Row'
import warnConfirm from '../../../../admin/warnConfirm'
import { url, urls } from '../../../../appNavigation/urls'
import * as api from '../../../../graphql/api'
import { ExpandButton } from '../../../../UI/Expand/ExpandButton'
import LinkChild from '../../../../UI/LinkChild'
import { SelectBox, Selection } from '../../../../UI/SelectBox/SelectBox'
import TooltipIconButton from '../../../../UI/TooltipIconButton'
import { setDifference } from '../../../../util/setDifference'
import { asyncForEach } from '../../../../util/syncArrayHelpers'
import { unionAllSets, unionSet } from '../../../../util/unionSet'
import { startJob } from '../../../../vvapi/maestro'
import {
  getDeliveryParcelFileKey, Group, WithStatus
} from '../selectListDeliveryParcelFileStatusForDelivery'
import { getColor } from '../utils'
import { ListDeliveryParcelFileStatus } from './types'

interface State {
  expandedGroups: Set<string>
  expandedProcGroups: Set<string>
  expandedParcels: Set<string>
  selection: Record<string, Record<string, Record<string, Set<string>>>>
}

interface DeliveryParcelFileStatusTableProps {
  rows: Record<string | number, Group>
  filesByGroup: Record<string, Set<string>>
  filesByProcGroup: Record<string, Set<string>>
  filesByParcel: Record<string, Set<string>>
  filesByKey: Record<string, ListDeliveryParcelFileStatus>
  selectable?: boolean
  onRefresh: () => void
  onSelectionChanged?: (selection: ListDeliveryParcelFileStatus[]) => void
}

export default class DeliveryParcelFileStatusTable extends React.PureComponent<
  DeliveryParcelFileStatusTableProps,
  State
> {
  state: State = {
    selection: {},
    expandedGroups: new Set(),
    expandedProcGroups: new Set(),
    expandedParcels: new Set(),
  }

  handleSelectionChange = (selection: State['selection']) =>
    this.setState({ selection })

  render() {
    const { selectable, onRefresh, rows, ...tableProps } = this.props

    return (
      <>
        <Table
          title="Delivery Parcel Files"
          {...tableProps}
          style={{ tableLayout: 'fixed' }}
        >
          <colgroup>
            <col span={1} style={{ width: 175 }} />
            <col span={1} style={{ width: 175 }} />
            <col span={1} style={{ width: 175 }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell>Proc Group</TableCell>
              <TableCell>Parcel</TableCell>
              <TableCell>Filename</TableCell>
              <TableCell>Flight</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{Object.keys(rows).map(this.renderGroup)}</TableBody>
        </Table>
      </>
    )
  }

  renderGroup = (groupName: string) => {
    const groupExpanded = this.state.expandedGroups.has(groupName)
    const { rows, selectable, filesByGroup } = this.props

    const selection = selectable ? this.state.selection : undefined
    const groupSelection = selection?.[groupName]

    const selectionSet =
      (groupSelection &&
        Object.values(groupSelection).reduce((set, procGroupSets) => {
          return unionAllSets([...Object.values(procGroupSets), set])
        }, new Set<string>())) ||
      new Set<string>()

    let groupSelectionState: Selection = 'partially-selected'
    if (selectionSet.size === filesByGroup[groupName].size) {
      groupSelectionState = 'selected'
    } else if (selectionSet.size === 0) {
      groupSelectionState = 'unselected'
    }

    return (
      <>
        <TableRow
          style={{ cursor: 'pointer' }}
          key={groupName}
          onClick={(ev) => this.expandGroup(ev, groupName)}
        >
          <TableCell style={{ paddingLeft: 4 }}>
            <Row>
              {selectable && (
                <SelectBox
                  selection={groupSelectionState}
                  onClick={(ev: React.SyntheticEvent) =>
                    this.handleSelectGroup(ev, groupName)
                  }
                />
              )}
              <ExpandButton expanded={groupExpanded} />
              <Typography>{groupName}</Typography>
            </Row>
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell>
            {this.renderStatusRollup(rows[groupName].statusCounts)}
          </TableCell>
          <TableCell />
        </TableRow>

        {groupExpanded &&
          Object.keys(rows[groupName].procGroups).map((procGroup) =>
            this.renderProcGroup(groupName, procGroup)
          )}
      </>
    )
  }

  renderProcGroup = (groupName: string, procGroup: string) => {
    const procGroupExpanded = this.state.expandedProcGroups.has(procGroup)
    const { rows, selectable, filesByProcGroup } = this.props

    const selection = selectable ? this.state.selection : undefined
    const procGroupSelection = selection?.[groupName]?.[procGroup]

    const selectionSet =
      (procGroupSelection &&
        unionAllSets([...Object.values(procGroupSelection)])) ||
      new Set<ListDeliveryParcelFileStatus>()

    let procGroupSelectionState: Selection = 'partially-selected'
    if (selectionSet.size === filesByProcGroup[procGroup].size) {
      procGroupSelectionState = 'selected'
    } else if (selectionSet.size === 0) {
      procGroupSelectionState = 'unselected'
    }

    return (
      <>
        <TableRow
          style={{ cursor: 'pointer' }}
          key={procGroup}
          onClick={(ev) => this.expandProcGroup(ev, procGroup)}
        >
          <TableCell />
          <TableCell style={{ paddingLeft: 4 }}>
            <Row>
              {selectable && (
                <SelectBox
                  selection={procGroupSelectionState}
                  onClick={(ev: React.SyntheticEvent) =>
                    this.handleSelectProcGroup(ev, groupName, procGroup)
                  }
                />
              )}
              <ExpandButton expanded={procGroupExpanded} />
              <Typography>{procGroup}</Typography>
            </Row>
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell>
            {this.renderStatusRollup(
              rows[groupName].procGroups[procGroup].statusCounts
            )}
          </TableCell>
          <TableCell />
        </TableRow>
        {procGroupExpanded &&
          Object.keys(rows[groupName].procGroups[procGroup].parcels).map(
            (parcelId) => this.renderParcel(groupName, procGroup, parcelId)
          )}
      </>
    )
  }

  renderParcel = (groupName: string, procGroup: string, parcelId: string) => {
    const parcelExpanded = this.state.expandedParcels.has(parcelId)

    const { rows, selectable, filesByParcel } = this.props

    const selection = selectable ? this.state.selection : undefined
    const parcelSelection = selection?.[groupName]?.[procGroup]?.[parcelId]

    const selectionSet =
      parcelSelection || new Set<ListDeliveryParcelFileStatus>()

    let parcelSelectionState: Selection = 'partially-selected'
    if (selectionSet.size === filesByParcel[parcelId].size) {
      parcelSelectionState = 'selected'
    } else if (selectionSet.size === 0) {
      parcelSelectionState = 'unselected'
    }

    return (
      <>
        <TableRow
          style={{ cursor: 'pointer' }}
          key={parcelId}
          onClick={(ev) => this.expandParcel(ev, parcelId)}
        >
          <TableCell />
          <TableCell />
          <TableCell style={{ paddingLeft: 4 }}>
            <Row>
              {selectable && (
                <SelectBox
                  selection={parcelSelectionState}
                  onClick={(ev: React.SyntheticEvent) =>
                    this.handleSelectParcel(ev, groupName, procGroup, parcelId)
                  }
                />
              )}

              <ExpandButton expanded={parcelExpanded} />
              <Typography>
                {
                  rows[groupName].procGroups[procGroup].parcels[parcelId]
                    .files[0].DeliveryParcel.name
                }
              </Typography>
            </Row>
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell>
            {this.renderStatusRollup(
              rows[groupName].procGroups[procGroup].parcels[parcelId]
                .statusCounts
            )}
          </TableCell>
          <TableCell />
        </TableRow>
        {parcelExpanded &&
          rows[groupName].procGroups[procGroup].parcels[parcelId].files.map(
            this.renderFile
          )}
      </>
    )
  }

  renderFile = (file: ListDeliveryParcelFileStatus & WithStatus) => {
    const { selectable } = this.props
    const selection = selectable ? this.state.selection : undefined
    const groupName = file.DeliveryParcel.DeliveryGroup.name
    const procGroup = file.DeliveryParcel.procGroup
    const parcelId = file.parcelId
    const key = getDeliveryParcelFileKey(file)

    const parcelSelection =
      (selection?.[groupName] &&
        selection[file.DeliveryParcel.DeliveryGroup.name][procGroup] &&
        selection[groupName][procGroup][parcelId]) ||
      new Set<string>()

    return (
      <TableRow key={`${file.parcelId}_${file.filename}`}>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>
          {selectable ? (
            <Row
              style={{ cursor: 'pointer' }}
              onClick={(ev: React.SyntheticEvent) =>
                this.handleSelectParcelFile(
                  ev,
                  groupName,
                  procGroup,
                  parcelId,
                  file
                )
              }
            >
              <Checkbox checked={parcelSelection.has(key)} />
              {file.filename}
            </Row>
          ) : (
            file.filename
          )}
        </TableCell>
        <TableCell>{file.flightDate}</TableCell>
        <TableCell>
          {file.Job ? (
            <LinkChild
              forceNewTab
              to={url(urls.job, {
                jobId: file.Job.id,
              })}
            >
              <Button>{file.status}</Button>
            </LinkChild>
          ) : (
            <Button disabled>{file.status}</Button>
          )}
        </TableCell>
        <TableCell>
          <Row>
            <TooltipIconButton
              title="Re-process DeliveryParcelFile"
              onClick={() => this.handleReprocessDeliveryParcelFiles([file])}
            >
              <Icon>redo</Icon>
            </TooltipIconButton>
            <TooltipIconButton
              title="Download DeliveryParcelFile"
              onClick={() => this.handleDownloadDeliveryParcelFiles([file])}
            >
              <Icon>cloud_download</Icon>
            </TooltipIconButton>
            <TooltipIconButton
              title="Delete DeliveryParcelFile"
              onClick={() => this.handleDeleteDeliveryParcelFiles([file])}
            >
              <Icon>delete</Icon>
            </TooltipIconButton>
          </Row>
        </TableCell>
      </TableRow>
    )
  }

  handleSelectGroup = (ev: React.SyntheticEvent, groupName: string) => {
    ev.stopPropagation()
    ev.preventDefault()

    const { selection } = this.state
    const { filesByGroup } = this.props

    const groupSelection =
      (selection[groupName] &&
        Object.values(selection[groupName]).reduce((set, procGroupSets) => {
          return unionAllSets([...Object.values(procGroupSets), set])
        }, new Set<string>())) ||
      new Set<string>()

    if (groupSelection.size > 0) {
      this.setState(
        { selection: { ...selection, [groupName]: {} } },
        this.updateSelection
      )
    } else {
      const newGroupSelection = Array.from(filesByGroup[groupName]).reduce(
        (sets, parcelFileKey) => {
          const parcelFile = this.props.filesByKey[parcelFileKey]
          const parcelId = parcelFile.parcelId
          const procGroup = parcelFile.DeliveryParcel.procGroup

          return {
            ...sets,
            [procGroup]: {
              ...(sets[procGroup] ?? {}),
              [parcelId]: unionSet(
                sets[procGroup]?.[parcelId] || new Set(),
                new Set([parcelFileKey])
              ),
            },
          }
        },
        {} as Record<string, Record<string, Set<string>>>
      )
      this.setState(
        {
          selection: { ...selection, [groupName]: newGroupSelection },
        },
        this.updateSelection
      )
    }
  }

  handleSelectProcGroup = (
    ev: React.SyntheticEvent,
    groupName: string,
    procGroup: string
  ) => {
    ev.stopPropagation()
    ev.preventDefault()

    const { selection } = this.state
    const { filesByProcGroup } = this.props

    const procGroupSelections = selection[groupName]?.[procGroup] ?? {}

    const procGroupSelection = unionAllSets(Object.values(procGroupSelections))

    if (procGroupSelection.size > 0) {
      this.setState(
        {
          selection: {
            ...(selection ?? {}),
            [groupName]: {
              ...(selection[groupName] ?? {}),
              [procGroup]: {},
            },
          },
        },
        this.updateSelection
      )
    } else {
      const newProcGroupSelection = Array.from(
        filesByProcGroup[procGroup]
      ).reduce((sets, parcelFileKey) => {
        const parcelFile = this.props.filesByKey[parcelFileKey]
        const parcelId = parcelFile.parcelId

        return {
          ...sets,
          [parcelId]: unionSet(
            sets[parcelId] || new Set(),
            new Set([parcelFileKey])
          ),
        }
      }, {} as Record<string, Set<string>>)
      this.setState(
        {
          selection: {
            ...(selection ?? {}),
            [groupName]: {
              ...(selection[groupName] ?? {}),
              [procGroup]: newProcGroupSelection,
            },
          },
        },
        this.updateSelection
      )
    }
  }

  handleSelectParcel = (
    ev: React.SyntheticEvent,
    groupName: string,
    procGroup: string,
    parcelId: string
  ) => {
    ev.stopPropagation()
    ev.preventDefault()

    const { selection } = this.state
    const { filesByParcel } = this.props

    const parcelSet = selection[groupName]?.[procGroup]?.[parcelId] || new Set()

    if (parcelSet.size > 0) {
      this.setState(
        {
          selection: {
            ...(selection ?? {}),
            [groupName]: {
              ...(selection[groupName] ?? {}),
              [procGroup]: {
                ...(selection[groupName]?.[procGroup] ?? {}),
                [parcelId]: new Set(),
              },
            },
          },
        },
        this.updateSelection
      )
    } else {
      this.setState(
        {
          selection: {
            ...(selection ?? {}),
            [groupName]: {
              ...(selection[groupName] ?? {}),
              [procGroup]: {
                ...(selection[groupName]?.[procGroup] ?? {}),
                [parcelId]: new Set(filesByParcel[parcelId]),
              },
            },
          },
        },
        this.updateSelection
      )
    }
  }

  handleSelectParcelFile = (
    ev: React.SyntheticEvent,
    groupName: string,
    procGroup: string,
    parcelId: string,
    file: ListDeliveryParcelFileStatus
  ) => {
    ev.stopPropagation()

    const { selection } = this.state

    const parcelSet = selection[groupName]?.[procGroup]?.[parcelId] || new Set()

    const parcelFileKey = getDeliveryParcelFileKey(file)

    if (parcelSet.has(parcelFileKey)) {
      this.setState(
        {
          selection: {
            ...(selection ?? {}),
            [groupName]: {
              ...(selection[groupName] ?? {}),
              [procGroup]: {
                ...(selection[groupName]?.[procGroup] ?? {}),
                [parcelId]: setDifference(
                  selection[groupName]?.[procGroup]?.[parcelId],
                  new Set([parcelFileKey])
                ),
              },
            },
          },
        },
        this.updateSelection
      )
    } else {
      this.setState(
        {
          selection: {
            ...(selection ?? {}),
            [groupName]: {
              ...(selection[groupName] ?? {}),
              [procGroup]: {
                ...(selection[groupName]?.[procGroup] ?? {}),
                [parcelId]: unionSet(
                  selection[groupName]?.[procGroup]?.[parcelId],
                  new Set([parcelFileKey])
                ),
              },
            },
          },
        },
        this.updateSelection
      )
    }
  }

  updateSelection = () => {
    const { selection } = this.state
    const { onSelectionChanged } = this.props
    if (!onSelectionChanged) {
      return
    }

    const selectionArray = Array.from(
      Object.values(selection).reduce((set, procGroup) => {
        return Object.values(procGroup).reduce((innerSet, parcel) => {
          return unionAllSets([...Object.values(parcel), innerSet])
        }, set)
      }, new Set<string>())
    ).map((key) => this.props.filesByKey[key])

    onSelectionChanged(selectionArray)
  }

  expandAll = (ev: React.SyntheticEvent) => {
    ev.stopPropagation()

    const { rows } = this.props

    const { procGroups, parcels } = Object.values(rows).reduce(
      ({ procGroups: pgs, parcels: ps }, group) => {
        const newProcGroups = [...pgs, ...Object.keys(group)]

        const newParcels = Object.values(group).reduce(
          (groupParcels, procGroup) => {
            const newGroupParcels = [...groupParcels, ...Object.keys(procGroup)]

            return newGroupParcels
          },
          ps
        )

        return { procGroups: newProcGroups, parcels: newParcels }
      },
      { procGroups: [] as string[], parcels: [] as string[] }
    )

    this.setState({
      expandedGroups: new Set(Object.keys(rows)),
      expandedProcGroups: new Set(procGroups),
      expandedParcels: new Set(parcels),
    })
  }

  renderStatusRollup = (statusRollup: Record<string, number>) => (
    <Row style={{ width: '100%', justifyContent: 'space-between' }}>
      <Row>
        {['complete'].map(
          (status) =>
            !!statusRollup[status] && (
              <Tooltip title={`${statusRollup[status]} ${status}`} key={status}>
                <span>
                  <Icon fontSize="small" style={{ color: getColor(status) }}>
                    {getStatusIcon(status)}
                  </Icon>
                  <Typography variant="caption" style={{ textAlign: 'center' }}>
                    {statusRollup[status]}
                  </Typography>
                </span>
              </Tooltip>
            )
        )}
      </Row>
      <Row>
        {[
          'created',
          'complete',
          'running',
          'pending',
          'queued',
          'cancelled',
          'error',
          'timeout',
        ].map(
          (status) =>
            !!statusRollup[status] && (
              <Tooltip title={`${statusRollup[status]} ${status}`} key={status}>
                <span>
                  <Icon fontSize="small" style={{ color: getColor(status) }}>
                    {getStatusIcon(status)}
                  </Icon>
                  <Typography variant="caption" style={{ textAlign: 'center' }}>
                    {statusRollup[status]}
                  </Typography>
                </span>
              </Tooltip>
            )
        )}
      </Row>
    </Row>
  )

  expandGroup = (ev: React.SyntheticEvent, group: string) => {
    ev.stopPropagation()

    const { expandedGroups } = this.state

    const newExpandedGroups = new Set(expandedGroups)

    if (newExpandedGroups.has(group)) {
      newExpandedGroups.delete(group)
    } else {
      newExpandedGroups.add(group)
    }

    this.setState({ expandedGroups: newExpandedGroups })
  }

  expandProcGroup = (ev: React.SyntheticEvent, procGroup: string) => {
    ev.stopPropagation()

    const { expandedProcGroups } = this.state

    const newExpandedProcGroups = new Set(expandedProcGroups)

    if (newExpandedProcGroups.has(procGroup)) {
      newExpandedProcGroups.delete(procGroup)
    } else {
      newExpandedProcGroups.add(procGroup)
    }

    this.setState({ expandedProcGroups: newExpandedProcGroups })
  }

  expandParcel = (ev: React.SyntheticEvent, parcelId: string) => {
    ev.stopPropagation()

    const { expandedParcels } = this.state

    const newExpandedParcels = new Set(expandedParcels)

    if (newExpandedParcels.has(parcelId)) {
      newExpandedParcels.delete(parcelId)
    } else {
      newExpandedParcels.add(parcelId)
    }

    this.setState({ expandedParcels: newExpandedParcels })
  }

  handleDeleteDeliveryParcelFiles = async (
    selection: ListDeliveryParcelFileStatus[]
  ) => {
    const confirmed = await warnConfirm({
      title: `Are you sure you want to delete ${selection.length} selected DeliveryParcelFile(s)?`,
      message:
        'Deleting a DeliveryParcelFile will delete all associated sources and layers. This is not reversible.',
      action: 'Delete',
    })

    if (!confirmed) {
      return
    }

    try {
      await asyncForEach(
        selection,
        { limit: 2 },
        async ({ deliveryId, parcelId, filename }) => {
          await api.deliveryParcelFile.delete({
            pk: { deliveryId, parcelId, filename },
          })
        }
      )
      this.refresh()
    } catch (e) {
      this.softError(e, 'Failed to Delete Flight', e.message)
    }
  }

  handleReprocessDeliveryParcelFiles = async (
    selection: ListDeliveryParcelFileStatus[]
  ) => {
    await asyncForEach(selection, { limit: 2 }, async ({ Job }) => {
      if (Job?.id) {
        await startJob(Job.id)
      }
    })

    this.refresh()
  }

  handleDownloadDeliveryParcelFiles = async ([
    deliveryParcelFile,
  ]: ListDeliveryParcelFileStatus[]) => {
    api.deliveryParcelFile.download(deliveryParcelFile)
  }

  refresh() {
    if (this.props.onRefresh) {
      this.props.onRefresh()
    }
  }

  softError = (
    error: Error,
    title: string,
    message: string,
    extras?: Record<string, any>
  ) =>
    errorAlert({
      error,
      title,
      message,
      extras,
      tags: {
        category: 'EditDelivery',
      },
    })
}
