import { createSelector } from 'reselect'
import { selectDeliveryParcelsByParcelId } from '../data/selectDelivery'
import { selectMapLayerDefsById } from '../data/selectListMapSourceDefs'
import { selectOrganization } from '../data/selectOrganization'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { selectSelectedLegendProductId } from '../data/selectSelectedLegendProductId'
import { MapLayerDefData } from '../data/types'
import { getSelectedFlightDate } from '../data/userSelectionRedux'
import i18n, { keys } from '../i18n'
import { selectActiveProductSettings } from '../ProductSettings/store/selectors/selectActiveProductSettings'
import { RootStore } from '../redux/types'
import { selectVisualizationName } from '../stats/selectors/selectVisualizationName'
import { DownloadTableOptions } from '../util/table'
import area, { AreaUnit } from '../util/units/area'
import { EitherUnit } from '../util/units/LayerUnits'
import length, { LengthUnit } from '../util/units/length'
import { ProductStats } from './calculateStats/productStats'
import { selectConversionsByProductId } from './selectors/selectConversionsByProductId'
import { selectProductStats } from './selectors/selectProductStats'
import {
  selectBestUnitsByProductId,
  selectConvertedSelectedColorProfiles,
} from './selectors/stats'
import { getWeightUnitAndConverter, toStopData } from './toStopData'
import { StopData } from './types'

function stopsToHeaders(
  stops: StopData[],
  weightUnitLabel: string,
  unit?: AreaUnit | LengthUnit
): any[] {
  return stops.map(({ key, label }) => ({
    key,
    label: `${unit ? `${label} (${unit})` : label}${weightUnitLabel}`,
  }))
}

function valueStats(
  product: MapLayerDefData,
  allStats: ProductStats[string],
  preferredUnit: EitherUnit
) {
  if (!product.dataProperty || product.dataProperty.type !== 'value') {
    return []
  }

  const dataName = product.dataProperty.acronym || product.dataProperty.name

  const name = dataName ? `${dataName} ` : ''

  const dataUnit =
    allStats.valueType === 'area'
      ? area.units[preferredUnit || allStats.valueUnit!] &&
        area.units[preferredUnit || allStats.valueUnit!].symbol
      : allStats.valueType === 'length'
      ? length.units[preferredUnit || allStats.valueUnit!] &&
        length.units[preferredUnit || allStats.valueUnit!].symbol
      : undefined

  const unit = dataUnit ? ` (${dataUnit})` : ''

  return [
    {
      key: 'mean',
      label: `${name}${i18n.t(keys.stats.mean)}${unit}`,
    },
    {
      key: 'stdev',
      label: `${name}${i18n.t(keys.stats.stDev)}${unit}`,
    },
    {
      key: 'cv',
      label: `${name}${i18n.t(keys.stats.coefficientOfVariation)} %`,
    },
  ]
}

const selectPreferredUnitSystem = (state: RootStore) =>
  state.preferences.preferredUnitSystem

const selectProductData = createSelector(
  [
    selectSelectedLegendProductId,
    selectMapLayerDefsById,
    selectConvertedSelectedColorProfiles,
    selectBestUnitsByProductId,
    selectConversionsByProductId,
    selectProductStats,
  ],
  (
    productId,
    mapLayerDefsById,
    convertedSelectedColorProfiles,
    bestUnitsByProductId,
    conversionsByProductId,
    productStats
  ) => {
    const product = mapLayerDefsById[productId]
    const selectedColorProfiles = convertedSelectedColorProfiles[productId]
    const unit = bestUnitsByProductId[productId]
    const convert = conversionsByProductId[productId] || ((n: number) => n)
    const allStats = productStats[productId]

    return {
      product,
      selectedColorProfiles,
      unit,
      convert,
      allStats,
    }
  }
)

export const selectStatsData = createSelector(
  [
    selectOrganization,
    selectProductData,
    selectActiveProductSettings,
    selectDeliveryParcelsByParcelId,
    selectVisualizationName,
    selectPreferredLanguage,
    getSelectedFlightDate,
    selectPreferredUnitSystem,
  ],
  (
    organization,
    productData,
    settings,
    deliveryParcelByParcelId,
    visualizationNameObject,
    lang,
    date,
    preferredUnitSystem
  ) => {
    const { product, selectedColorProfiles, unit, convert, allStats } =
      productData

    const visualizationName = visualizationNameObject.visualizationName
    const colorProfile = selectedColorProfiles?.[settings.visualization!]

    if (!organization || !product || !colorProfile || !allStats) {
      return
    }

    const isInteger = allStats.weightType === 'count'

    const data: any[] = []

    const { stops } = toStopData({
      lang,
      unit,
      preferredUnitSystem,
      colorProfile: colorProfile as any,
      layerDef: product,
    })
    const minWeight = allStats.minParcelStopSize

    const { weightConvert, weightUnit } = getWeightUnitAndConverter(
      product,
      minWeight,
      preferredUnitSystem
    )

    const weightUnitLabel = weightUnit
      ? ` (${(area.units[weightUnit] || length.units[weightUnit]).symbol})`
      : ` (${i18n.t(keys.units.plantCountLabel)})`

    for (const [parcelId, stats] of Object.entries(allStats.parcels)) {
      const parcel = deliveryParcelByParcelId[parcelId]
      if (parcel) {
        data.push({
          date,
          organization: organization.name,
          group: parcel.group.name,
          parcel: parcel.name,
          visualization: visualizationName,
          noData: roundNum(convert(stats.noData.size), isInteger),
          coverage: roundNum(stats.coverage.size, isInteger),
          ...Object.entries(stats.data.stops).reduce((acc, [key, { size }]) => {
            acc[key] = roundNum(
              weightConvert ? weightConvert(size) : size,
              isInteger
            )

            return acc
          }, {}),
          total: roundNum(
            weightConvert
              ? weightConvert(stats.data.stats.size)
              : stats.data.stats.size,
            isInteger
          ),
          mean: roundNum(convert(stats.data.stats.mean!) ?? 0),
          stdev: roundNum(convert(stats.data.stats.stdev!) ?? 0),
          cv: roundNum((stats.data.stats.cv ?? 0) * 100, false, 1),
        })
      }
    }

    // blank line before totals
    data.push({})

    data.push({
      date,
      organization: i18n.t(keys.stats.total),
      group: i18n.t(keys.stats.total),
      parcel: i18n.t(keys.stats.total),
      visualization: visualizationName,
      noData: roundNum(allStats.combined.noData.size, isInteger),
      coverage: roundNum(allStats.combined.coverage.size, isInteger),
      ...Object.entries(allStats.combined.data.stops).reduce(
        (acc, [key, { size }]) => {
          acc[key] = roundNum(
            weightConvert ? weightConvert(size) : size,
            isInteger
          )

          return acc
        },
        {}
      ),
      total: roundNum(
        weightConvert
          ? weightConvert(allStats.combined.data.stats.size)
          : allStats.combined.data.stats.size,
        isInteger
      ),
      mean: roundNum(convert(allStats.combined.data.stats.mean ?? 0)),
      stdev: roundNum(convert(allStats.combined.data.stats.stdev ?? 0)),
      cv: roundNum((allStats.combined.data.stats.cv ?? 0) * 100, false, 1),
    })

    stops.map((s) => ({
      ...s,
    }))

    const result: DownloadTableOptions = {
      data,
      filename: `${organization.name}_${product.name}_stats`,
      headers: [
        {
          key: 'organization',
          label: i18n.t(keys.stats.organization),
        },
        {
          key: 'group',
          label: i18n.t(keys.stats.group),
        },
        {
          key: 'parcel',
          label: i18n.t(keys.stats.block),
        },
        {
          key: 'date',
          label: i18n.t(keys.stats.date),
        },
        {
          key: 'visualization',
          label: i18n.t(keys.visualization.visualization),
        },
        ...stopsToHeaders(stops, weightUnitLabel),
        {
          key: 'total',
          label: `${i18n.t(keys.stats.total)}${weightUnitLabel}`,
        },
        ...valueStats(product, allStats, unit),
      ],
    }

    return result
  }
)

function roundNum(num: number, isInteger?: boolean, maximumFractionDigits = 3) {
  if (isInteger) {
    return Math.round(num)
  }

  const multiplier = Math.pow(10, maximumFractionDigits)
  let result = num * multiplier
  result = Math.round(result)
  result = result / multiplier

  return Number(result)
}
