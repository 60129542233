import { IconButton, Menu, Stack, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as React from 'react'
import { Item, Level, LevelInfo } from './types'
import { ListChildComponentProps } from 'react-window'
import { MoreHoriz, NavigateNextSharp } from '@mui/icons-material'

interface Props {
  level: Level
  levelIndex: number
  levels: Level[]
  setCurrentLevelInfo: (levelInfo: LevelInfo) => void
  currentLevelInfo: LevelInfo
  width: number
}

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    minWidth: '250px',
    overflow: 'hidden',
    borderBottom: '1px solid #424242',
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#5CC46C33',
      '& #next-button': {
        backgroundColor: '#5CC46C',
      },
    },
  },
  icon: {
    width: '24px',
    height: '24px',
    padding: theme.spacing(1),
  },
  menu: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

export const DrillDownRow = ({
  level,
  levels,
  levelIndex,
  setCurrentLevelInfo,
  currentLevelInfo,
  index,
  data: items,
  style,
  width,
}: Props & ListChildComponentProps<Item[]>) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openContext = Boolean(anchorEl)
  const handleOpenContext = (
    event: React.MouseEvent<HTMLButtonElement>,
    ref: HTMLDivElement | null
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(ref)
  }
  const handleCloseContext = () => {
    setAnchorEl(null)
  }
  const innerRef = React.createRef<HTMLDivElement>()
  return (
    <>
      <Stack
        className={classes.mainContainer}
        direction="row"
        id={items[index].id.toString()}
        key={items[index].id}
        sx={{
          ...style,
        }}
        alignItems="center"
        justifyContent="space-between"
        style={{
          backgroundColor:
            anchorEl && anchorEl.id === items[index].id.toString()
              ? '#5CC46C33'
              : undefined,
        }}
        ref={innerRef}
        onClick={() => {
          levels[currentLevelInfo.index]?.onSelectItem?.(items[index])
        }}
      >
        {<level.ItemComponent {...items[index]} />}
        {(level.Options || levelIndex !== levels.length - 1) && (
          <Stack direction="row" spacing={2} pr={2}>
            {level.Options && (
              <IconButton
                id="options-button"
                className={classes.icon}
                onClick={(e) => handleOpenContext(e, innerRef.current)}
                style={{
                  backgroundColor:
                    anchorEl && anchorEl.id === items[index].id.toString()
                      ? '#5CC46C'
                      : undefined,
                }}
              >
                <MoreHoriz />
              </IconButton>
            )}
            {levelIndex !== levels.length - 1 && (
              <IconButton
                id="next-button"
                className={classes.icon}
                onClick={(e) => {
                  e.stopPropagation()
                  levels[currentLevelInfo.index].onDescend?.()
                  setCurrentLevelInfo({
                    index: currentLevelInfo.index + 1,
                    parentHistory: [
                      ...currentLevelInfo.parentHistory.map((item, i) =>
                        i === levelIndex + 1 ? items[index] : item
                      ),
                    ],
                  })
                }}
              >
                <NavigateNextSharp />
              </IconButton>
            )}
          </Stack>
        )}
      </Stack>
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openContext}
        onClose={handleCloseContext}
        sx={{ marginLeft: 1 }}
      >
        {level.Options && <level.Options {...items[index]} />}
      </Menu>
    </>
  )
}
