import { Checkbox, Typography } from '@mui/material'
import { default as Table } from '@mui/material/Table'
import { default as TableBody } from '@mui/material/TableBody'
import { default as TableCell } from '@mui/material/TableCell'
import { default as TableHead } from '@mui/material/TableHead'
import { default as TableRow } from '@mui/material/TableRow'
import * as React from 'react'
import { Column } from '../../../admin/UI/Column'
import { Row } from '../../../admin/UI/Row'
import { ExpandButton } from '../../../UI/Expand/ExpandButton'
import { ParcelProperties, PreparedFile } from './prepareUpload'

interface Props {
  filesByGroups: Record<string, Record<string, PreparedFile[]>>
  parcels: Record<string, ParcelProperties>
  selectedFilesChanged: (files: PreparedFile[]) => void
}

interface State {
  expandedParcelIds: Set<string>
  expandedGroupNames: Set<string>
  selectedFiles: Record<string, Record<string, Set<PreparedFile>>>
}

export class FlightUploadFiles extends React.Component<Props, State> {
  state: State = {
    expandedParcelIds: new Set<string>(),
    expandedGroupNames: new Set<string>(),
    selectedFiles: {},
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { filesByGroups, selectedFilesChanged } = this.props
    const { selectedFiles } = this.state
    if (filesByGroups !== prevProps.filesByGroups) {
      this.selectAll(true)
    }
    if (prevState.selectedFiles !== selectedFiles) {
      selectedFilesChanged(Array.from(this.flattenAllSelectedFiles()))
    }
  }

  render() {
    const { filesByGroups, parcels } = this.props
    const { expandedParcelIds, expandedGroupNames, selectedFiles } = this.state

    const totalSelectionState = this.totalSelectionState()

    const allFiles = this.flattenAllFiles()

    const newFiles = allFiles.filter((f) => f.status === 'New')

    return (
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableCell>
            <Row
              style={{
                justifyContent: 'flex-start',
                cursor: 'pointer',
                width: '100%',
              }}
              onClick={() => this.selectAll()}
            >
              <Checkbox
                indeterminate={totalSelectionState === 'partial'}
                checked={totalSelectionState === 'all'}
                onClick={(ev) => {
                  ev.stopPropagation()
                  this.selectAll()
                }}
              />
              <Column>
                <Typography>Select All</Typography>
                <Typography>{`(${allFiles.length} Files ${newFiles.length} New)`}</Typography>
              </Column>
            </Row>
          </TableCell>
          <TableCell>Filename</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Checksum</TableCell>
          <TableCell>Checksum Algorithm</TableCell>
        </TableHead>
        <TableBody>
          {Object.entries(filesByGroups).map(([groupName, groupParcels]) => {
            const groupExpanded = expandedGroupNames.has(groupName)
            const selectedGroupFiles = selectedFiles[groupName] as Record<
              string,
              Set<PreparedFile>
            >

            const groupSelectionState = this.groupSelectionState(groupName)
            const groupFiles = this.flattenGroupFiles(groupParcels)
            const newGroupFiles = groupFiles.filter((gf) => gf.status === 'New')

            return (
              [
                <TableRow key={groupName}>
                  <TableCell colSpan={5}>
                    <Row
                      style={{
                        justifyContent: 'flex-start',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      onClick={() => this.expandGroup(groupName)}
                    >
                      <Checkbox
                        indeterminate={groupSelectionState === 'partial'}
                        checked={groupSelectionState === 'all'}
                        onClick={(ev) => {
                          ev.stopPropagation()
                          this.selectGroupFiles(groupName)
                        }}
                      />

                      <Typography>{`${groupName} (${groupFiles.length} Files ${newGroupFiles.length} New)`}</Typography>

                      <ExpandButton expanded={!!groupExpanded} />
                    </Row>
                  </TableCell>
                </TableRow>
                , ...(groupExpanded &&
                  Object.entries(groupParcels).map(
                    ([parcelId, parcelFiles]) => {
                      const parcelExpanded = expandedParcelIds.has(parcelId)
                      const selectedParcelFiles = selectedGroupFiles?.[parcelId]
                      const parcel = parcels[parcelId]
                      const newParcelFiles = parcelFiles.filter(
                        (pf) => pf.status === 'New'
                      )

                      return ([
                        <TableRow key={parcelId}>
                          <TableCell colSpan={5}>
                            <Row
                              style={{
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                                width: '100%',
                              }}
                              onClick={() => this.expandParcel(parcelId)}
                            >
                              <Checkbox
                                indeterminate={
                                  selectedParcelFiles?.size > 0 &&
                                  selectedParcelFiles.size <
                                  parcelFiles.length
                                }
                                checked={
                                  selectedParcelFiles?.size ===
                                  parcelFiles.length
                                }
                                onClick={(ev) => {
                                  ev.stopPropagation()
                                  this.selectParcelFiles(groupName, parcelId)
                                }}
                              />

                              <Typography>{`${parcel.parcelName} (${parcelFiles.length} Files ${newParcelFiles.length} New)`}</Typography>

                              <ExpandButton expanded={!!parcelExpanded} />
                            </Row>
                          </TableCell>
                        </TableRow>

                        , {...parcelExpanded &&
                        parcelFiles.map((pf) => (
                          <TableRow
                            style={{
                              width: '100%',
                              cursor: 'pointer',
                              justifyContent: 'space-between',
                            }}
                            key={`${parcelId}_${pf.file.name}`}
                            onClick={() =>
                              this.selectFile(groupName, parcelId, pf)
                            }
                          >
                            <TableCell>
                              <Checkbox
                                checked={!!selectedParcelFiles?.has(pf)}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography>{pf.file.name}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{pf.status}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{pf.checksum}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {pf.checksumAlgorithm}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))} || []
                      ]
                      )
                    }
                  )) || []
              ]
            )
          })}
        </TableBody>
      </Table>
    )
  }

  flattenAllSelectedFiles = () => {
    const { selectedFiles } = this.state

    return Object.values(selectedFiles).reduce((acc, group) => {
      return new Set<PreparedFile>([
        ...Array.from(acc),
        ...Array.from(this.flattenSelectedGroupFiles(group)),
      ])
    }, new Set<PreparedFile>())
  }

  flattenAllFiles = () => {
    const { filesByGroups } = this.props

    return Object.values(filesByGroups).reduce(
      (acc, groupFiles) => [...acc, ...this.flattenGroupFiles(groupFiles)],
      [] as PreparedFile[]
    )
  }

  flattenGroupFiles = (groupFiles: Record<string, PreparedFile[]>) => {
    return Object.values(groupFiles).reduce(
      (groupAcc, files) => [...groupAcc, ...files],
      [] as PreparedFile[]
    )
  }

  flattenSelectedGroupFiles = (
    selectedGroupFiles: Record<string, Set<PreparedFile>>
  ) => {
    return Object.values(selectedGroupFiles).reduce((acc, set) => {
      return new Set<PreparedFile>([...Array.from(acc), ...Array.from(set)])
    }, new Set<PreparedFile>())
  }

  totalSelectionState = () => {
    const allSelectedFiles = this.flattenAllSelectedFiles()

    const allFiles = this.flattenAllFiles()

    if (allSelectedFiles.size === 0) {
      return 'none'
    }
    if (allSelectedFiles.size === allFiles.length) {
      return 'all'
    }

    return 'partial'
  }

  groupSelectionState = (groupName: string) => {
    const { selectedFiles } = this.state
    const { filesByGroups } = this.props

    const groupFiles = filesByGroups[groupName]

    const selectedGroupFiles: Record<string, Set<PreparedFile>> = selectedFiles[
      groupName
    ] ?? {}

    if (!groupFiles) {
      return 'all'
    }

    if (!selectedGroupFiles) {
      return 'none'
    }

    const flattenedSelectedGroupFiles =
      this.flattenSelectedGroupFiles(selectedGroupFiles)

    const flattenedGroupFiles = this.flattenGroupFiles(groupFiles)

    if (flattenedSelectedGroupFiles.size === 0) {
      return 'none'
    }
    if (flattenedSelectedGroupFiles.size === flattenedGroupFiles.length) {
      return 'all'
    }

    return 'partial'
  }

  expandParcel = (parcelId: string) => {
    const { expandedParcelIds } = this.state
    const newExpandedParcelIds = new Set(expandedParcelIds)
    if (newExpandedParcelIds.has(parcelId)) {
      newExpandedParcelIds.delete(parcelId)
    } else {
      newExpandedParcelIds.add(parcelId)
    }

    this.setState({ expandedParcelIds: newExpandedParcelIds })
  }

  expandGroup = (groupName: string) => {
    const { expandedGroupNames } = this.state
    const newExpandedGroupNames = new Set(expandedGroupNames)
    if (newExpandedGroupNames.has(groupName)) {
      newExpandedGroupNames.delete(groupName)
    } else {
      newExpandedGroupNames.add(groupName)
    }

    this.setState({ expandedGroupNames: newExpandedGroupNames })
  }

  selectAll = (all = false) => {
    const { selectedFiles } = this.state
    const { filesByGroups } = this.props

    const allSelectedFiles = Object.values(selectedFiles).reduce(
      (acc, group) => {
        return new Set<PreparedFile>([
          ...Array.from(acc),
          ...Array.from(
            Object.values(group).reduce((groupAcc, set) => {
              return new Set<PreparedFile>([
                ...Array.from(groupAcc),
                ...Array.from(set),
              ])
            }, new Set<PreparedFile>())
          ),
        ])
      },
      new Set<PreparedFile>()
    )

    if (allSelectedFiles.size === 0 || all) {
      this.setState({
        selectedFiles: Object.entries(filesByGroups).reduce(
          (groupFileMap, [groupName, groupFiles]) => {
            return {
              ...groupFileMap,
              [groupName]: Object.entries(groupFiles).reduce(
                (fileMap, [parcelId, parcelFiles]) => {
                  return {
                    ...fileMap,
                    [parcelId]: new Set<PreparedFile>(
                      parcelFiles.map((pf) => pf)
                    ),
                  }
                },
                {} as Record<string, Set<PreparedFile>>
              ),
            }
          },
          {} as Record<string, Record<string, Set<PreparedFile>>>
        ),
      })
    } else {
      this.setState({
        selectedFiles: {},
      })
    }
  }

  selectGroupFiles = (groupName: string) => {
    const { selectedFiles } = this.state
    const { filesByGroups } = this.props

    const newSelectedGroupFiles: Record<string, Set<PreparedFile>> | undefined =
      selectedFiles[groupName]

    if (
      newSelectedGroupFiles &&
      Object.values(newSelectedGroupFiles).some(
        (groupParcelFiles) => groupParcelFiles.size > 0
      )
    ) {
      this.setState({
        selectedFiles: {
          ...selectedFiles,
          [groupName]: {},
        },
      })
    } else {
      this.setState({
        selectedFiles: {
          ...selectedFiles,
          [groupName]: Object.entries(filesByGroups[groupName]).reduce(
            (fileMap, [parcelId, parcelFiles]) => {
              return {
                ...fileMap,
                [parcelId]: new Set<PreparedFile>(parcelFiles.map((pf) => pf)),
              }
            },
            {} as Record<string, Set<PreparedFile>>
          ),
        },
      })
    }
  }

  selectParcelFiles = (groupName: string, parcelId: string) => {
    const { selectedFiles } = this.state
    const { filesByGroups } = this.props

    const newSelectedParcelFiles = selectedFiles[groupName]?.[parcelId]
      ? new Set(selectedFiles[groupName][parcelId])
      : new Set<string>()

    if (newSelectedParcelFiles.size > 0) {
      this.setState({
        selectedFiles: {
          ...selectedFiles,
          [groupName]: {
            ...(selectedFiles[groupName] ?? {}),
            [parcelId]: new Set<PreparedFile>(),
          },
        },
      })
    } else {
      this.setState({
        selectedFiles: {
          ...selectedFiles,
          [groupName]: {
            ...(selectedFiles[groupName] ?? {}),
            [parcelId]: new Set(
              filesByGroups[groupName]?.[parcelId].map((pf) => pf) ?? []
            ),
          },
        },
      })
    }
  }

  selectFile = (groupName: string, parcelId: string, file: PreparedFile) => {
    const { selectedFiles } = this.state
    const newSelectedParcelFiles = selectedFiles[groupName]?.[parcelId]
      ? new Set(selectedFiles[groupName][parcelId])
      : new Set<PreparedFile>()

    if (newSelectedParcelFiles.has(file)) {
      newSelectedParcelFiles.delete(file)
    } else {
      newSelectedParcelFiles.add(file)
    }

    this.setState({
      selectedFiles: {
        ...selectedFiles,
        [groupName]: {
          ...(selectedFiles[groupName] ?? {}),
          [parcelId]: newSelectedParcelFiles,
        },
      },
    })
  }
}
