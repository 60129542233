import * as React from 'react'
import { Input, ListItem, FormControl, InputLabel } from '@mui/material'
import { ParcelFilterInfo } from '../../../../../../postgis/types'
import { useRedux } from '../../../../../../hooks/useRedux'
import { MapEditorToolType } from '../../../../../../redux/map-editor-pages/redux'
import { MapEditorActionTypes } from '../../../../../../map-editor/redux/types'
import { selectDrawerOptions } from '../../../../../../map-editor/redux/selectors/selectDrawerOptions'
import i18n, { keys } from '../../../../../../i18n'
import { Search } from '@mui/icons-material'

interface Props {
  id: string
  parcelFilterInfo?: ParcelFilterInfo
  page: MapEditorToolType
}

const BlockFilter = ({ id, parcelFilterInfo, page }: Props) => {
  const [state, dispatch] = useRedux()
  const drawerOptions = selectDrawerOptions(state, page)
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
      page: page,
      payload: {
        drawerOptions: {
          ...drawerOptions,
          [id]: {
            config: {
              ...drawerOptions[id].config,
              parcelFilter: {
                ...drawerOptions[id].config?.parcelFilter,
                searchTerm: e.target.value,
              },
            },
          },
        },
      },
    })
  }
  return (
    <ListItem dense>
      <FormControl fullWidth>
        <InputLabel>{i18n.t(keys.forms.parcelFilter.search)}</InputLabel>
        <Input
          value={parcelFilterInfo?.searchTerm ?? ''}
          onChange={handleChange}
          fullWidth
          endAdornment={<Search />}
        />
      </FormControl>
    </ListItem>
  )
}

export default BlockFilter
