import * as React from 'react'
import { default as Dialog } from '@mui/material/Dialog'
import { default as DialogActions } from '@mui/material/DialogActions'
import { default as DialogContent } from '@mui/material/DialogContent'
import { default as DialogTitle } from '@mui/material/DialogTitle'
import { default as Typography } from '@mui/material/Typography'
import errorAlert from '../../../admin/errorAlert'
import { NameAndValue } from '../../../admin/mapdata/types'
import assertDefinedFields from '../../../admin/mapdata/utils/assertDefinedFields'
import { Button } from '@mui/material'
import * as api from '../../../graphql/api'
import i18n, { keys } from '../../../i18n'
import SourcePropertyForm, { MapSourcePropertyData } from './SourcePropertyForm'

const getData = (id: string) => {
  /** ! These fields must be listed in the gql query below */
  type fields = Exclude<
    keyof MapSourcePropertyData,
    'MapSourceDef' | 'mapSourceDefId'
  >

  return api.mapSourceProperty.get<Pick<MapSourcePropertyData, fields>>({
    pk: { id },
    returning: `{
      id
      name
      description
      property
      type
      acronym
      valueType
      valueUnit
      measurementType
      range
      classes
      filterable
      showInPopup
      noDataValue
      CreatedBy {
        firstName
      }
      createdAt
      UpdatedBy {
        firstName
      }
      updatedAt
    }`,
  })
}

interface Props {
  id: string
  open: boolean
  onSave(): void
  onClose(): void
}

type GQResponse = UnwrapPromise<ReturnType<typeof getData>>
type Results = NonNullable<GQResponse['data']>

type State = Partial<Results>

class EditSourcePropertyDialog extends React.PureComponent<Props, State> {
  state: State = {}

  componentDidMount() {
    this.fetchData()
  }

  render() {
    const { CreatedBy, createdAt, updatedAt, UpdatedBy } = this.state

    return (
      <Dialog
        id="EditSourcePropertyDialog"
        fullWidth
        open={!!this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Edit Source Property</DialogTitle>
        <DialogContent>
          <div className="grid">
            {CreatedBy && UpdatedBy && (
              <div className="grid-xs-12">
                <Typography variant="subtitle1">
                  Created By: {CreatedBy.firstName} - {createdAt}
                </Typography>
                <Typography variant="subtitle1">
                  Updated By: {UpdatedBy.firstName} - {updatedAt}
                </Typography>
              </div>
            )}
            <SourcePropertyForm
              instance={this.state}
              onChange={this.handleChange}
              onSubmit={this.handleSave}
            />
          </div>
        </DialogContent>
        <DialogActions className="align-right">
          <Button onClick={this.props.onClose}>
            {i18n.t(keys.generic.cancel)}
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave}>
            {i18n.t(keys.generic.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  async fetchData() {
    const { id } = this.props

    try {
      const req = await getData(id)

      this.setState({
        ...req.data,
      })
    } catch (e) {
      softError(e, 'Fetching Error', 'Cannot find Source Property', this.state)
    }
  }

  handleChange = ({ name, value }: NameAndValue) =>
    this.setState({
      [name]: value,
    })

  handleSave = async () => {
    const {
      name,
      description,
      type,
      property,
      noDataValue,
      acronym,
      filterable,
      classes,
      valueType,
      valueUnit,
      measurementType,
      range,
      showInPopup,
    } = this.state
    const { id } = this.props
    const requiredFields = {
      name,
      type,
      property,
    }

    try {
      if (assertDefinedFields(requiredFields)) {
        await api.mapSourceProperty.update({
          pk: { id },
          input: {
            ...requiredFields,
            description,
            acronym,
            classes,
            valueType,
            valueUnit,
            measurementType,
            range,
            noDataValue,
            filterable: filterable || false,
            showInPopup: !!showInPopup,
          },
        })

        this.props.onSave()
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Update Source Property', message, this.state)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default EditSourcePropertyDialog
