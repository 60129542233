import * as React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { OrganizationData } from '../../../../data/selectOrganizationList'
import { GetOrganizationDeliveriesResult } from './fetchOranizationDeliveries'
import { DeliveryParcel } from './fetchSourceAndDestinationParcels'

interface ReviewCopyProps {
  selectedDelivery: string
  selectedOrganization: string
  organizations: OrganizationData[]
  deliveries: GetOrganizationDeliveriesResult['deliveries']
  groups: Record<
    number,
    {
      name: string
      id: number
    }
  >
  orderedGroupsIds: number[]
  orderedParcelIdsByGroup: Record<number, number[]>
  sourceParcelsByGroup: Record<number, Record<number, DeliveryParcel>>
  destinationParcelsByName: Record<string, Record<string, DeliveryParcel>>
  parcelMap: Record<number, number>
}
export const ReviewCopy = ({
  groups,
  selectedOrganization,
  selectedDelivery,
  organizations,
  deliveries,
  parcelMap,
  sourceParcelsByGroup,
  destinationParcelsByName,
  orderedGroupsIds,
  orderedParcelIdsByGroup,
}: ReviewCopyProps) => {
  const [expandedGroup, setExpandedGroup] = React.useState<number | undefined>()

  const handleExpandGroup = (groupId: number) => {
    if (expandedGroup === groupId) {
      setExpandedGroup(undefined)
    } else {
      setExpandedGroup(groupId)
    }
  }

  const organization = organizations.find(
    (organization) => organization.id === selectedOrganization
  )
  const delivery = deliveries.find(
    (delivery) => delivery.id === selectedDelivery
  )
  const destinationParcelsById = React.useMemo(() => {
    return Object.values(destinationParcelsByName).reduce(
      (mapped, deliveryParcels) => {
        for (const deliveryParcel of Object.values(deliveryParcels)) {
          mapped[deliveryParcel.parcel.id] = deliveryParcel
        }
        return mapped
      },

      {} as Record<string, DeliveryParcel>
    )
  }, [destinationParcelsByName])

  const [mappedParcelsByGroup, mappedGroups] = React.useMemo(() => {
    const parcelsByGroup: Record<
      string,
      { source: DeliveryParcel; destination: DeliveryParcel }[]
    > = {}

    const groupIds: number[] = []

    for (const groupId of orderedGroupsIds) {
      const mappedGroupParcels: {
        source: DeliveryParcel
        destination: DeliveryParcel
      }[] = []

      for (const parcelId of orderedParcelIdsByGroup[groupId] ?? []) {
        if (parcelId in parcelMap) {
          if (parcelMap[parcelId] === -1) {
            continue
          }
          const destination = destinationParcelsById[parcelMap[parcelId]]
          const source = sourceParcelsByGroup[groupId][parcelId]

          if (destination) {
            mappedGroupParcels.push({ source, destination })
          }
        }
      }
      if (mappedGroupParcels.length > 0) {
        parcelsByGroup[groupId] = mappedGroupParcels
        groupIds.push(groupId)
      }
    }

    return [parcelsByGroup, groupIds]
  }, [
    destinationParcelsById,
    parcelMap,
    orderedGroupsIds,
    orderedParcelIdsByGroup,
  ])

  return (
    <Stack justifyContent="center" sx={{ py: 2 }}>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={5}
        alignItems="flex-start"
      >
        <Stack justifyContent="center">
          <Typography variant="caption">Selected Organization:</Typography>
          <Typography variant="subtitle1">{organization?.name}</Typography>
        </Stack>
        <Stack justifyContent="center">
          <Typography variant="caption">Selected Delivery:</Typography>
          <Typography variant="subtitle1">
            {delivery?.targetDeliveryDate}
          </Typography>
          <Typography variant="subtitle2">{delivery?.comment}</Typography>
        </Stack>
      </Stack>
      <Stack justifyContent="center">
        <Typography variant="caption">Parcel Mapping:</Typography>
        {mappedGroups.map((groupId) => {
          const group = groups[groupId]

          return (
            <Accordion
              key={groupId}
              disableGutters
              sx={{ background: 'var(--background-light)' }}
              expanded={expandedGroup === groupId}
              onChange={() => handleExpandGroup(groupId)}
              TransitionProps={{
                unmountOnExit: true,
                appear: false,
                enter: false,
                exit: false,
              }}
            >
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}
                sx={{ p: 1 }}
              >
                <Stack
                  sx={{ width: '100%', px: 1 }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>{group.name}</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Source Parcel</TableCell>
                        <TableCell>Destination Parcel</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mappedParcelsByGroup[groupId].map(
                        ({ source, destination }) => (
                          <TableRow key={source.parcel.id}>
                            <TableCell>{`${source.parcel.group.name} | ${source.parcel.name}`}</TableCell>
                            <TableCell>
                              {' '}
                              {`${destination.parcel.group.name} | ${destination.parcel.name}`}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Stack>
    </Stack>
  )
}
