import './PasswordStrength.scss'

import * as React from 'react'

import { Typography } from '@mui/material'

import { passwordStrength } from '../util/passwordStrength'

interface Props {
  password?: string
}

const passwordStrengthMap = {
  1: 'weak',
  2: 'moderate',
  3: 'ok',
  4: 'good',
  5: 'strong',
}

export class PasswordStrength extends React.Component<Props> {
  render() {
    const { password } = this.props

    if (!password) {
      return null
    }

    const strength = passwordStrengthMap[passwordStrength(password)]

    return (
      <Typography
        className={`password-strength ${strength}`}
      >{`Password Strength: ${strength}`}</Typography>
    )
  }
}
