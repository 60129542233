import * as React from "react";
import { Stack, styled, useTheme } from "@mui/material";
import { useDelayedUnmount } from "../../hooks/useDelayedUnmount";

interface Props {
  children: React.ReactNode;
  open: boolean;
  orientation: "vertical" | "horizontal";
  unmountOnExit?: boolean;
}

const CollapsibleStack = styled(Stack)<Props>(
  ({ theme, open, orientation }) => ({
    overflow: "hidden",
    transition: theme.transitions.create(["height", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: orientation === "vertical" && !open ? 0 : "100%",
    width: orientation === "horizontal" && !open ? 0 : "100%",
  })
);

const Collapsible = ({ children, open, orientation, unmountOnExit = false }: Props) => {
  const theme = useTheme();
  const mounted = useDelayedUnmount(open, theme.transitions.duration.leavingScreen);

  if(!mounted && unmountOnExit) {
    return null;
  }

  return (
    <CollapsibleStack open={open} orientation={orientation}>
      {children}
    </CollapsibleStack>
  );
};

export default Collapsible;
