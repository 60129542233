import './ProcessingDashboard.scss'
import * as React from 'react'
import { ORDER } from '../../UI/Table/orderRows'
import { PaginationChange, TableOrder } from '../../UI/Table/types'
import Page from '../../app/Page'
import {
  DEFAULT_PAGE_INFO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../appNavigation/urls'
import useAsync from '../../hooks/useAsync'
import { useInterval } from '../../hooks/useInterval'
import { useHistory, useSearchParams } from '../../hooks/useRouter'
import i18n, { keys } from '../../i18n'
import { ProcessingUserStatus, fetchProcessingQueue } from './queries'
import { tableFormatterWithScope } from './processingQueueTableFormatter'
import { MenuItem, Paper, Select, Stack, useTheme } from '@mui/material'
import { Search } from '../../UI/Table/Search'
import { Table } from '../../UI/Table/Table'

import { ProportionBar } from '../UI/ProportionBar'
import { formatNum } from '../../util/formatNumber'
import area from '../../util/units/area'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'

type StatusTypes = keyof Omit<ProcessingUserStatus, 'Assignee'>
type StatusTypePrefix = keyof Omit<
  ProcessingUserStatus,
  | 'Assignee'
  | 'areaQaComplete'
  | 'areaComplete'
  | 'areaInProgress'
  | 'areaPending'
>

export type ProcessingDashboardMode = 'procGroups' | 'acres'
const searchBarFilterId = 'processing-dashboard-search-filter'

export const ProcessingDashboard = () => {
  const {
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    filter,
    order,
  } = useSearchParams()
  const history = useHistory()
  const theme = useTheme()

  const [mode, setMode] = React.useState<ProcessingDashboardMode>('acres')

  const [processingQueue, refreshProcessingQueue] = useAsync(
    fetchProcessingQueue(),
    [Number(page), Number(pageSize), order, filter]
  )

  useInterval(refreshProcessingQueue, 60000)

  const updateSearchParams = (update: {
    page?: number
    pageSize?: number
    order?: string
    filter?: string
  }) => {
    history.push(
      url(
        urls.processingQueue,
        {},
        {
          page: Number(page),
          pageSize: Number(pageSize),
          order,
          filter,
          ...update,
        }
      )
    )
  }

  const rows = processingQueue.result ? processingQueue.result.data : []
  const info = processingQueue.result
    ? processingQueue.result.info
    : { ...DEFAULT_PAGE_INFO, order: [] }

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateSearchParams(pagination)

  const handleOrderChange = (order: TableOrder) => {
    updateSearchParams({ order: ORDER.serialize(order) })
  }

  const tableFormatter = tableFormatterWithScope({
    onViewClicked: (id: string) =>
      history.replace(url(urls.yourQueue, { userId: id })),
    searchBarFilterId,
    mode,
  })

  const getTotalCount = (states: StatusTypes[]) => {
    return states.reduce(
      (acc, state) =>
        acc +
        (processingQueue?.result?.data?.reduce((iacc, v) => {
          return iacc + (v?.[state] ?? 0)
        }, 0) ?? 0),
      0
    )
  }

  const getPercentage = (nom: number, denom: number) =>
    nom === 0 ? 0 : (nom / denom) * 100

  const capitalizeFirstLetter = (val: string) => {
    return val.charAt(0).toUpperCase() + val.slice(1)
  }

  const buildStatusInput = (
    label: string,
    mode: string,
    color: string,
    statusTypes: StatusTypePrefix[]
  ) => {
    let adjustedStatusTypes: StatusTypes[] = statusTypes
    if (mode === 'acres') {
      adjustedStatusTypes = statusTypes.map(
        (status) => `area${capitalizeFirstLetter(status)}` as StatusTypes
      )
    }
    const totalCount = getTotalCount(
      mode === 'acres'
        ? [
            'areaPending',
            'areaInProgress',
            'areaComplete',
            'areaQaComplete',
            'areaQaFailed',
          ]
        : ['pending', 'inProgress', 'complete', 'qaComplete', 'qaFailed']
    )
    const statusCount = getTotalCount(adjustedStatusTypes)
    const percentage = getPercentage(statusCount, totalCount)

    return {
      label: `${label}: ${
        mode === 'acres'
          ? formatNum(
              area.convert(
                statusCount,
                area.units.squareMeter,
                area.units.acre,
                'acre'
              ).value
            )
          : statusCount
      } ${mode === 'acres' ? 'acres' : 'proc groups'}`,
      value: percentage,
      color,
    }
  }

  return (
    <Page
      title="Processing Dashboard"
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <AsyncSelectorStatusOverlay requests={processingQueue}>
        <Stack className="processing-dashboard" spacing={2}>
          <Paper className="processing-paper" elevation={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Search
                tableFormatters={tableFormatter}
                filterId={searchBarFilterId}
              />
              <Select
                value={mode}
                onChange={(ev) =>
                  setMode(ev.target.value as ProcessingDashboardMode)
                }
              >
                <MenuItem value="acres">By Acres</MenuItem>
                <MenuItem value="procGroups">By Proc Groups</MenuItem>
              </Select>
            </Stack>
          </Paper>

          <Paper className="processing-paper">
            <ProportionBar
              percentages={[
                buildStatusInput('Pending', mode, '#7CB1FF', ['pending']),
                buildStatusInput('In Progress', mode, '#FFC107', [
                  'inProgress',
                ]),
                buildStatusInput('Complete', mode, '#9C12CC', ['complete']),
                buildStatusInput('QA Complete', mode, '#5CC46C', [
                  'qaComplete',
                ]),
                buildStatusInput('QA Failed', mode, theme.palette.error.main, [
                  'qaFailed',
                ]),
              ]}
            />
          </Paper>

          <Paper className="processing-paper" elevation={0}>
            <Table
              rows={rows}
              formatter={tableFormatter}
              order={info.order}
              onOrderChange={handleOrderChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              pagination={info}
              onPaginationChange={handlePaginationChange}
              rowClass={() => ['clickable']}
            />
          </Paper>
        </Stack>
      </AsyncSelectorStatusOverlay>
    </Page>
  )
}
