import gql from 'graphql-tag'
import { Fetcher, createTableFetcher } from '../../UI/Table/createTableFetcher'
import { client } from '../../graphql/client'
import { tableFormatterWithScope } from './processingQueueTableFormatter'

export interface ProcessingUserStatus {
  Assignee: {
    id: string
    firstName: string
    lastName: string
    email: string
  }
  pending: number
  inProgress: number
  complete: number
  qaComplete: number
  qaFailed: number
  areaPending: number
  areaInProgress: number
  areaComplete: number
  areaQaComplete: number
  areaQaFailed: number
}

const fetchProcessingQueueQuery = (returning: string, order_by: string) => gql`
  query GET_PROCESSING_USER_STATUS($offset: Int!, $limit: Int!, $where: View_ProcessingUserStatus_bool_exp) {
    data: View_ProcessingUserStatus(
      order_by: ${order_by}
      offset: $offset
      limit: $limit
      where: $where
    ) ${returning}

    aggregate: View_ProcessingUserStatus_aggregate(
      where: $where, 
    ) {
      info: aggregate { count }
    }
  }
`

export const fetchProcessingQueue = () => {
  const list: Fetcher<ProcessingUserStatus> = async (options) => {
    const where = {
      _and: {
        ...(options?.where ?? {}),
      },
    }

    const order_by =
      options.order_by?.replace(/\s/g, '') ?? '[{ assignee: desc }]'

    const { data, aggregate } = await client.request<{
      data?: ProcessingUserStatus[]
      aggregate?: { info: { count: number } }
    }>({
      query: fetchProcessingQueueQuery(options.returning ?? '', order_by),
      variables: {
        offset: options.offset,
        limit: options.limit,
        where: where,
      },
    })

    return {
      data,
      info: aggregate?.info,
    }
  }

  return createTableFetcher<ProcessingUserStatus>(
    tableFormatterWithScope({}),
    `{
      Assignee {
        id
        firstName
        lastName
        email
      }
      pending
      inProgress
      complete
      qaComplete
      qaFailed
      areaPending
      areaInProgress
      areaComplete
      areaQaComplete
      areaQaFailed
    }`,
    list,
    '_and'
  )
}
