export function setToggle<T>(setA: Set<T>, value: T) {
  const toggle = new Set(setA)

  if (toggle.has(value)) {
    toggle.delete(value)
  }
  else {
    toggle.add(value)
  }

  return toggle
}