export const makeSearchTermFilter = (
  searchTerm: string,
  fieldExtractor: (id: string) => string
) => {
  const lcSearchTerms = searchTerm.toLocaleLowerCase().split(' ')

  return (id: string) => {
    return lcSearchTerms.every(
      (lcSearchTerm) =>
        fieldExtractor(id).toLocaleLowerCase().indexOf(lcSearchTerm) >= 0
    )
  }
}

export const makeDateFilter = (
  dateExtractor: (id: string) => Date | undefined,
  fromDate?: Date,
  toDate?: Date
) => {
  return (id: string) => {
    const noteDate = dateExtractor(id)

    if (!noteDate) {
      return false
    }

    let nextDay = toDate
    if (toDate) {
      nextDay = new Date(toDate)
      nextDay.setDate(nextDay.getDate() + 1)
    }

    return (
      (!fromDate || noteDate >= fromDate) && (!nextDay || noteDate < nextDay)
    )
  }
}

export const makeFormTypeFilter = (
  searchFormTypeId: string | undefined,
  extractor: (id: string) => string | undefined
) => {
  return (id: string) => {
    if (!searchFormTypeId) {
      return true
    }

    return searchFormTypeId === extractor(id)
  }
}

export const makeProjectTypeFilter = (
  searchProjectTypeId: string | undefined,
  extractor: (id: string) => string | undefined
) => {
  return (id: string) => {
    if (!searchProjectTypeId) {
      return true
    }

    return searchProjectTypeId === extractor(id)
  }
}
