import * as React from 'react'
import { PureComponent } from 'react'
import { MapboxGL } from '../../map/MapboxGL'

import { Button, CircularProgress, Icon, Tooltip } from '@mui/material'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import i18n, { keys } from '../../i18n'
import * as actions from '../../postgis/actions'
import { selectAdjustedUserPosition } from '../../postgis/selectors/UserPositionLayerSelector'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import withMap, { WithMap } from '../withMap'
import getPositionErrorMessage from './getPositionErrorMessage'

interface ButtonData {
  tooltipText: string | undefined
  icon: string
  iconColorOverride: 'error' | undefined
}

class UserPositionToggle extends PureComponent<
  WithMap & ReduxProps & AppDispatchProps & WithStyles<typeof styles>
> {
  state = {
    // isSearching true when we're looking for our first location event
    // so we can show the user some UI that we're working on their request
    isSearching: false,

    // isFollowing is when location is found, and we are currently in following
    // mode
    isFollowing: false,

    // set when an error occurs when trying to invoke the current user position
    // the icon switches to an error state, and the tooltip changes to an error
    // message until you tap it again
    errorMessage: undefined,
  }

  componentDidUpdate(prevProps: this['props']) {
    if (prevProps.positionError !== this.props.positionError) {
      if (this.props.positionError) {
        this.handleWatchPositionError(this.props.positionError)
      }
    }
    if (prevProps.position !== this.props.position) {
      if (this.props.position) {
        this.setState({ isSearching: false })
        if (this.state.isFollowing && !this.props.isGPSOffsetMarkerVisible) {
          const { position } = this.props
          const center: MapboxGL.LngLatLike = [
            position.coords.longitude,
            position.coords.latitude,
          ]
          this.props.map.easeTo({ center })
        }
      }
    }
  }

  handleWatchPositionError = (error: GeolocationPositionError) => {
    this.setState({
      isFollowing: false,
      isSearching: false,
      errorMessage: getPositionErrorMessage(error),
    })
  }

  clearError = () => {
    this.setState({ errorMessage: undefined })
  }

  toggleFollowingState = () => {
    this.props.dispatch(
      actions.setIsPollingUserPosition(!this.state.isFollowing)
    )
    this.setState({
      // we need to toggle isFollowing from its previous state
      // the new value of isSearching must match the new value of isFollowing
      // regardless of its previous value
      isSearching: !this.state.isFollowing,
      isFollowing: !this.state.isFollowing,
    })
  }

  handleClick = () => {
    if (this.state.errorMessage) {
      this.clearError()
    } else {
      this.toggleFollowingState()
    }
  }

  render() {
    const { classes } = this.props

    const buttonData: ButtonData = this.state.errorMessage
      ? {
        tooltipText: this.state.errorMessage,
        icon: 'error',
        iconColorOverride: 'error',
      }
      : {
        tooltipText: i18n.t(keys.userPosition.mapToolbarTooltip),
        icon: 'gps_fixed',
        iconColorOverride: undefined,
      }

    return (
      <Button
        onClick={this.handleClick}
      >
        <Tooltip
          title={buttonData.tooltipText ?? ''}
          placement="left"
        >
          <>
            <Icon color={this.state.isFollowing ? 'secondary' : undefined}>{buttonData.icon}</Icon>
            {this.state.isSearching && !this.state.errorMessage && (
              <div className={classes.spinner}>
                <CircularProgress
                  variant="indeterminate"
                  size={30}
                  color="secondary"
                />
              </div>
            )}
          </>
        </Tooltip>
      </Button>
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    spinner: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })

const mapState = (state: RootStore) => ({
  position: selectAdjustedUserPosition(state),
  positionError: state.postgis.userPositionError,
  isGPSOffsetMarkerVisible: state.postgis.isGPSOffsetMarkerVisible,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(withMap(UserPositionToggle))
)
