import * as React from 'react'
import { AdminPage } from '../AdminPage'
import { useHistory, useParams } from 'react-router-dom'
import useAsync from '../../../hooks/useAsync'
import {
  deleteFeatureFlagAccess as removeAccess,
  deleteFeatureFlag as remove,
  commitFeatureFlag as commit,
  fetchFeatureFlagByPk as fetch,
} from './queries'
import { FeatureFlagForm } from './FeatureFlagForm'
import { FeatureFlag } from '../../../graphql/types'
import { url, urls } from '../../../appNavigation/urls'

export const FeatureFlagEdit = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [fetchedFeatureFlag] = useAsync(fetch, [id])
  const featureFlag = React.useMemo(
    () => fetchedFeatureFlag?.result?.data,
    [fetchedFeatureFlag?.result?.data]
  )

  const cancel = () => history.push(url(urls.admin_feature_flags))

  const deleteFeatureFlag = async (ff: FeatureFlag) => {
    if (!ff) {
      return
    }
    const choice = await sweetAlert(
      `Are you sure you want to delete this feature flag?`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: 'Delete',
          },
        },
        dangerMode: true,
      }
    )

    // sweetalert returns null for "cancel"
    if (!choice) {
      return
    }
    await remove(ff)
    history.push(url(urls.admin_feature_flags))
  }

  const commitFeatureFlag = async (ff: FeatureFlag) => {
    if (!ff) {
      return
    }
    const choice = await sweetAlert(
      `Are you sure you want to edit this feature flag?`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: 'Update',
          },
        },
        dangerMode: true,
      }
    )

    // sweetalert returns null for "cancel"
    if (!choice) {
      return
    }

    await removeAccess(ff)
    await commit(ff)
    history.push(url(urls.admin_feature_flags))
  }

  return (
    <AdminPage title="Edit Feature Flag" subtitle={id}>
      <FeatureFlagForm
        ff={featureFlag}
        cancel={cancel}
        deleteFeatureFlag={deleteFeatureFlag}
        commitFeatureFlag={commitFeatureFlag}
      />
    </AdminPage>
  )
}
