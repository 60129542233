const languageOptions = [
  {
    value: 'en',
    title: 'English',
  },
  {
    value: 'fr',
    title: 'Française',
  },
]

export default languageOptions
