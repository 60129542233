import * as React from 'react'

import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { alpha, Theme } from '@mui/material/styles'
import { createStyles, withStyles, WithStyles } from '@mui/styles'

import { ColorDrawerToggle } from '../colorScheme/ColorDrawer'
import { selectAvailableProducts } from '../data/selectAvailableProducts'
import { selectOrganization } from '../data/selectOrganization'
import i18n, { keys } from '../i18n'
import { LayerDrawerToggle } from '../map/controls/LayerDrawer'
import { selectSelectedProduct } from '../postgis/selectSelectedProduct'
import { updateProductSettings } from '../ProductSettings/store/redux'
import { selectActiveProductSettings } from '../ProductSettings/store/selectors/selectActiveProductSettings'
import { Filter } from '../ProductSettings/UI/Filter'
import Visualization from '../ProductSettings/UI/Visualization'
import Stats from '../ProductStats/UI/Stats'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import { selectVisualizationName } from '../stats/selectors/selectVisualizationName'
import ColorLegend from './ColorLegend'
import { selectProductGroupsById } from '../data/selectListMapLayerDefGroups'

export const LEGEND_ELEMENT_ID = 'VVMapLegend'

class Legend extends React.PureComponent<
  AppDispatchProps & ReduxProps & WithStyles<typeof styles>
> {
  state = {
    dataLayerMenuButton: null,
  }

  render() {
    const { product, classes, visualizationInfo } = this.props

    //     if (!!layerGroups[product?.mapLayerDefGroupId]) {
    // x
    //     }

    return (
      <div className={classes.root}>
        <Paper className={classes.mainColumn} id={LEGEND_ELEMENT_ID}>
          <div>{product ? <Stats /> : null}</div>
          <div className={classes.info}>
            <div className={classes.infoLeft}>
              <LayerDrawerToggle />
              {product ? <Stats toggle /> : null}
            </div>
            <div className={classes.title}>
              <Typography variant="subtitle1">
                {product?.name ?? i18n.t(keys.legend.noDataSelected)}
              </Typography>
              <Typography variant="caption">{visualizationInfo}</Typography>
            </div>
            <div className={classes.infoRight}>
              {product ? (
                <React.Fragment>
                  <Filter />
                  <Visualization simple />
                </React.Fragment>
              ) : null}

              <ColorDrawerToggle />
            </div>
          </div>
          <div
            className={classes.legendContainer}
            id="colorSchemeRendererContainer"
          >
            <ColorLegend productType={this.props.product} />
          </div>
        </Paper>
      </div>
    )
  }

  handleAverageSelected = (event: React.MouseEvent) => {
    const { dispatch, product, settings } = this.props

    event.stopPropagation()
    event.preventDefault()

    if (product) {
      dispatch(
        updateProductSettings({
          productId: product.id,
          settings: { blockByBlockAverage: !settings?.blockByBlockAverage },
        })
      )
    }
  }
}

export const MAX_WIDTH_LEGEND_PX = 538

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      pointerEvents: 'none!important' as any,
    },
    mainColumn: {
      pointerEvents: 'all',
      maxWidth: MAX_WIDTH_LEGEND_PX,
      margin: 'auto',
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      overflow: 'hidden',
    },
    info: {
      position: 'relative',
      height: 48,
    },
    infoLeft: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
    },
    infoRight: {
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'flex',
    },
    legendContainer: {
      cursor: 'pointer',
    },
    title: {
      paddingTop: theme.spacing(1 / 2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    legend: {
      maxWidth: MAX_WIDTH_LEGEND_PX,
      width: '100%',
    },
  })

const mapState = (state: RootStore) => ({
  product: selectSelectedProduct(state),
  layerGroups: selectProductGroupsById(state),
  availableProducts: selectAvailableProducts(state).products,
  organization: selectOrganization(state),
  settings: selectActiveProductSettings(state),
  visualizationInfo: selectVisualizationName(state).filtered,
  preferences: state.preferences,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(Legend)
)
