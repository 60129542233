import './TranslationTextField.scss'

import * as React from 'react'

import { Icon, Input, InputAdornment, InputLabel, Menu, MenuItem } from '@mui/material'

import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import { Translated } from '../../graphql/types'
import languageOptions from '../../i18n/languageOptions'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import TooltipIconButton from '../TooltipIconButton'

interface NameAndValue {
  name?: string
  value?: Translated | null
}

interface Props extends NameAndValue {
  label: string
  required?: boolean
  multiline?: boolean
  onChange(e: NameAndValue): void
}

interface State {
  anchorEl: HTMLElement | null
}

class TranslationTextField extends React.PureComponent<
  Props & ReduxProps,
  State
> {
  state: State = {
    anchorEl: null,
  }

  render() {
    const {
      label,
      value,
      selectedLanguage,
      required = false,
      multiline = false,
    } = this.props

    const translation = value ?? {}

    const languages = Object.keys(translation)

    if (languages.length === 0) {
      languages.push(selectedLanguage)
    }

    const len = languages.length

    return (
      <div className="TranslationTextField">
        <div className="TranslationTextField__title">
          <InputLabel required={required}>{label}</InputLabel>
          <TooltipIconButton
            tooltipProps={{
              placement: 'right',
            }}
            title={`Add a language!`}
            onClick={this.handleMenuClick}
          >
            <Icon fontSize="small">add_circle</Icon>
          </TooltipIconButton>
        </div>

        <div className="TranslationTextField__content">
          {languages.map((lang, i) => (
            <Input
              key={lang}
              fullWidth
              name={lang}
              onChange={this.onChange}
              value={translation[lang] ?? ''}
              autoComplete="off"
              multiline={multiline}
              startAdornment={
                <InputAdornment position="start">{lang}</InputAdornment>
              }
              endAdornment={
                len > 1 && (
                  <TooltipIconButton
                    tooltipProps={{
                      placement: 'right',
                    }}
                    title={`Remove !`}
                    onClick={() => this.handleRemoveLanguage(lang)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </TooltipIconButton>
                )
              }
            />
          ))}
        </div>

        <Menu
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={this.handleMenuClose}
        >
          {languageOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => this.handleAddLanguage(option.value)}
              disabled={languages.includes(option.value)}
            >
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }

  onChange = (e: any) => {
    const { name, value, onChange } = this.props

    onChange({
      name,
      value: {
        ...value,
        [e.target.name]: e.target.value,
      },
    })
  }

  handleAddLanguage = (lang: string) => {
    this.onChange({
      target: {
        name: lang,
        value: '',
      },
    })
  }

  handleRemoveLanguage = (lang: string) => {
    const { name, value, onChange } = this.props
    const newValue = { ...value }

    delete newValue[lang]

    onChange({
      name,
      value: newValue,
    })
  }

  handleMenuClick = (event: any) => {
    this.setState({ anchorEl: event.target })
  }

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    })
  }
}

const mapState = (state: RootStore) => ({
  selectedLanguage: selectPreferredLanguage(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, Props, AppDispatchProps>(mapState)(
  TranslationTextField
)
