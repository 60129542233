import './Toolbar.scss'

import * as React from 'react'

import { Paper } from '@mui/material'

interface Props {
  className?: string
}

export class Toolbar extends React.PureComponent<Props> {
  render() {
    return (
      <Paper className="map_controls_Toolbar no-print">
        {this.props.children}
      </Paper>
    )
  }
}
