
import * as api from '../../../graphql/api'
import { ScheduledNotification_create_input } from '../../../graphql/types';


export const createScheduledNotification = async (input: ScheduledNotification_create_input) => {
  const choice = await sweetAlert(
    `Are you sure you want to create this scheduled notification?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Create',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (!choice) {
    return
  }

  // TODO: Create notification set and notifications.
  return await api.scheduledNotifications.create<{id: string}>({ input })
}
