import * as React from 'react'
import i18n, { keys } from '../../../../../i18n'
import { ConfirmationModal } from '../../../../../app/ConfirmationModal/ConfirmationModal'
import { Box, Typography } from '@mui/material'

interface Props {
  open: boolean
  amendmentId: string
  amendmentLabel: string
  affectedRateMaps?: number
  onDelete: (amendmentName: string) => void
  onCancel: () => void
}

export const DeleteAmendmentDialog = ({
  open,
  amendmentId,
  amendmentLabel,
  affectedRateMaps,
  onDelete,
  onCancel,
}: Props) => {
  const baseMessage = `${i18n.t(
    keys.rateMapDeleteAmendmentModalDescription
  )} ${amendmentLabel}?`

  let additionalMessage = ''
  if (affectedRateMaps && affectedRateMaps > 0) {
    additionalMessage =
      i18n.t(keys.rateMapDeleteAmendmentModalAffectedMaps, {
        count: affectedRateMaps,
      }) + ' '
    additionalMessage +=
      affectedRateMaps === 1
        ? i18n.t(keys.rateMapDeleteAmendmentModalAffectedMapsSingular)
        : i18n.t(keys.rateMapDeleteAmendmentModalAffectedMapsPlural)
  }

  const message = (
    <>
      <Typography>{baseMessage}</Typography>
      {additionalMessage && (
        <Box mt={2}>
          <Typography>{additionalMessage}</Typography>
        </Box>
      )}
    </>
  )
  return (
    <ConfirmationModal
      open={open}
      title={i18n.t(keys.rateMapDeleteAmendmentModalTitle)}
      message={message}
      onConfirm={() => onDelete(amendmentId)}
      onCancel={onCancel}
      dangerMode
    />
  )
}
