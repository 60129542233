import dateFnsEn from 'date-fns/locale/en-US'
import dateFnsFr from 'date-fns/locale/fr'
import { formatDistance } from 'date-fns'
import I18n, { i18n as i18nType, TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { en } from './en'
import { fr } from './fr'
import dayjs from 'dayjs'

export { keys } from './keys'

/** when fetched from the server, dates become strings */
export type DateLike = Date | number | string | null

type I18nWithBetterNumberLocale = Omit<i18nType, 't'> & {
  toDateShort: (date: DateLike) => string
  toDateShortName: (date: DateLike) => string
  toDateLong: (date: DateLike) => string
  toTimeShort: (date: DateLike) => string
  toTimeHourMinute: (date: DateLike) => string
  toDateTimeShort: (date: DateLike) => string
  toDateTime: (date: DateLike) => string
  toLogFormat: (date: DateLike) => string
  distanceInWords: (
    dateToCompare: DateLike,
    date: DateLike,
    options?: any
  ) => string
  msToTime: (ms: number) => string
  use: any
  init: any
  t: (key: string, options?: TOptions) => string
}

// tslint:disable-next-line: no-var-requires
const i18n: I18nWithBetterNumberLocale =
  I18n as unknown as I18nWithBetterNumberLocale

i18n.toDateShort = (date) => (date ? dayjs(date).format('YYYY-MM-DD') : '')

// i18n.t = (param) => { return i18n.t(param)! }

i18n.toDateShortName = (date) =>
  date
    ? new Date(date).toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    : ''

i18n.toDateLong = (date) =>
  date
    ? new Date(date).toLocaleDateString(i18n.language, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : ''

i18n.toTimeShort = (date) =>
  date ? new Date(date).toLocaleTimeString(i18n.language) : ''

i18n.toTimeHourMinute = (date) =>
  date
    ? new Date(date).toLocaleTimeString(i18n.language, {
        hour: 'numeric',
        minute: 'numeric',
      })
    : ''

i18n.toDateTimeShort = (date) =>
  `${i18n.toDateShort(date)} ${i18n.toTimeShort(date)}`

i18n.toDateTime = (date) =>
  date
    ? new Date(date).toLocaleTimeString(i18n.language, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : ''

i18n.toLogFormat = (datelike) => {
  if (datelike) {
    const date = new Date(datelike)

    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${padZero(
      date.getHours()
    )}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}:${padZero(
      date.getMilliseconds(),
      2
    )}`
  }

  return ''
}

const padZero = (num: number, leadingZeros = 1) =>
  num >= 10 ** leadingZeros
    ? num
    : `${'0'.repeat(leadingZeros - `${num}`.length + 1)}${num}`

const translations = {
  en,
  fr,
}

type DateFnsLocale = {
  [key in keyof typeof translations]: Locale
}

const datefnsLocale: DateFnsLocale = {
  en: dateFnsEn,
  fr: dateFnsFr,
}

i18n.distanceInWords = (dateToCompare, date, options) => {
  const shortCode = i18n.language.split('-')[0]
  const locale = datefnsLocale[shortCode]

  return dateToCompare && date
    ? formatDistance(new Date(dateToCompare), new Date(date), {
        ...options,
        locale,
      })
    : ''
}

i18n.msToTime = (ms: number) => {
  const seconds = ms / 1000
  const minutes = ms / (1000 * 60)
  const hours = ms / (1000 * 60 * 60)
  const days = ms / (1000 * 60 * 60 * 24)

  if (seconds < 60) {
    return `${seconds.toFixed(1)} Sec`
  }
  if (minutes < 60) {
    return `${minutes.toFixed(1)} Min`
  }
  if (hours < 24) {
    return `${hours.toFixed(1)} Hrs`
  }

  return `${days.toFixed(1)} Days`
}

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: navigator.language ?? 'en',
    namespace: '',
    fallbackLng: 'en',
    resources: {
      en: { translation: translations.en },
      fr: { translation: translations.fr },
    },
  })

export default i18n as I18nWithBetterNumberLocale
