import * as React from 'react'

import {
  ArrowBack,
  Download,
  InsertPhoto,
  LocationOn,
} from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import i18n from '../i18n'
import { NoteWithTemplate, OrgFeatureGeometry } from '../vvapi/models'
import { Media } from '../graphql/types'
import { AuthImage } from './AuthImage'
import { downloadBlob } from '../util/download'
import { getBlob } from '../vvapi/apiResource/createApiResource'
import { getMediaUrl } from './util/getUrl'
import { LngLatLike } from 'mapbox-gl'
import { useMap } from '../map/withMap'
import { dispatch } from '../redux/store'
import { actions } from '../notes/redux'
import centroid from '@turf/centroid'

interface Props {
  selectedMediaId?: string
  setSelectedMediaId: (mediaId?: string) => void
  mediaFiles: Record<string, { media?: Media }>
  note?: Pick<NoteWithTemplate, 'pinColor' | 'templateName' | 'id'>
  geometry?: OrgFeatureGeometry
}

const CaptionText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.text.primary,
  lineHeight: '17.07px',
  letterSpacing: '-3.5%',
}))

const LargeCaptionText = styled(CaptionText)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '19.2px',
}))

const SmallCaptionText = styled(CaptionText)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '15px',
}))

export const convertBytesToMegabytes = (bytes: number, precision = 2) => {
  return `${(bytes / 1e6).toFixed(precision)} MB`
}

export const MediaAttachmentsModal = ({
  mediaFiles,
  selectedMediaId,
  setSelectedMediaId,
  note,
  geometry,
}: Props) => {
  const theme = useTheme()
  const currentIndex = React.useMemo(() => {
    if (selectedMediaId === undefined) {
      return -1
    }

    return Object.keys(mediaFiles).indexOf(selectedMediaId)
  }, [mediaFiles, selectedMediaId])

  const [imageSize, setImageSize] = React.useState<number | undefined>()

  const selectedMedia = selectedMediaId
    ? mediaFiles[selectedMediaId]
    : undefined

  const handleDownload = async () => {
    if (selectedMediaId && selectedMedia?.media?.originalname) {
      const { blob } = await getBlob(getMediaUrl({ id: selectedMediaId }))
      downloadBlob(blob, selectedMedia?.media?.originalname)
    }
  }

  const { map } = useMap()

  const handleLocationSnap = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      if (geometry) {
        if (geometry.type === 'Point') {
          map?.panTo(geometry.coordinates as LngLatLike)
        } else {
          const center = centroid({
            type: 'Feature',
            geometry: geometry,
            properties: {},
          })
          map?.panTo(center.geometry.coordinates as LngLatLike)
        }
        setImageSize(undefined)
        setSelectedMediaId(undefined)
        dispatch(actions.setEditingNoteId(undefined))
        dispatch(actions.setFocusedNoteId({ noteId: note?.id }))
      }
    },
    [geometry, map, note?.id, setSelectedMediaId]
  )

  return (
    <Dialog
      maxWidth="xl"
      style={{
        height: '100%',
        width: '100%',
      }}
      open={selectedMediaId !== undefined}
      onClose={() => {
        setSelectedMediaId(undefined)
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap={2} alignItems="start">
            <IconButton
              onClick={() => {
                setImageSize(undefined)
                setSelectedMediaId(undefined)
              }}
            >
              <ArrowBack sx={{ color: theme.palette.text.primary }} />
            </IconButton>
            {selectedMedia && (
              <>
                <Stack sx={{ mt: 1 }} spacing={0.8}>
                  <Stack
                    direction="row"
                    gap={'8px'}
                    alignItems="center"
                    justifyContent="start"
                  >
                    <InsertPhoto sx={{ color: theme.palette.text.primary }} />
                    <LargeCaptionText>
                      {`${selectedMedia.media?.CreatedBy?.firstName} ${selectedMedia.media?.CreatedBy?.lastName}`}
                    </LargeCaptionText>
                    <SmallCaptionText>|</SmallCaptionText>
                    <CaptionText>
                      {i18n.toDateShort(selectedMedia.media!.createdAt!)}
                    </CaptionText>
                  </Stack>
                  {note && (
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      gap={'8px'}
                    >
                      <Box
                        sx={{
                          height: 10,
                          width: 10,
                          borderRadius: 10,
                          background: `${note.pinColor}`,
                        }}
                      />
                      <CaptionText>
                        {i18n.t(`noteForm.reservedNames.${note.templateName}`, {
                          defaultValue: note.templateName,
                        })}
                      </CaptionText>
                      <SmallCaptionText variant="caption">|</SmallCaptionText>
                      <CaptionText variant="caption">
                        {imageSize === undefined
                          ? ''
                          : convertBytesToMegabytes(imageSize)}
                      </CaptionText>
                    </Stack>
                  )}
                </Stack>
              </>
            )}
          </Stack>
          <Stack gap={1} direction="row" alignContent="center">
            {geometry && (
              <IconButton onClick={handleLocationSnap}>
                <LocationOn sx={{ color: theme.palette.text.primary }} />
              </IconButton>
            )}
            <IconButton onClick={handleDownload}>
              {/* <a href={mediaUrl} download={true}> */}
              <Download sx={{ color: theme.palette.text.primary }} />
              {/* </a> */}
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent
        style={{
          paddingTop: 5,
          height: '100%',
          width: '100%',
          minWidth: '60vw',
          objectFit: 'contain',
        }}
      >
        <Carousel
          height={'65vh'}
          sx={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
          }}
          autoPlay={false}
          navButtonsAlwaysVisible={true}
          onChange={(index?: number) => {
            setImageSize(undefined)
            setSelectedMediaId(
              index !== undefined ? Object.keys(mediaFiles)[index] : undefined
            )
          }}
          index={currentIndex}
          indicators={false}
        >
          {Object.entries(mediaFiles).map(([id, mediaFile]) => {
            if (id !== selectedMediaId)
              return (
                <Stack
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Stack>
              )

            return (
              <AuthImage
                onSizeFound={setImageSize}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                src={getMediaUrl(mediaFile?.media!)}
                alt={mediaFile?.media?.originalname}
              />
            )
          })}
        </Carousel>
      </DialogContent>
    </Dialog>
  )
}

export default MediaAttachmentsModal
