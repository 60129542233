import { MapSourcePropertyData } from '../../data/types'
import area from './area'
import length from './length'
import UnitConverter from './UnitConverter'
import { UnitSystem } from './unitSystem'

const defaultUnitConverter = {
  convert: (value: number) => ({
    value,
  }),
  bestUnit: () => ({
    value: 1,
  }),
  unitsBySystem: {
    [UnitSystem.Metric]: {},
    [UnitSystem.Imperial]: {},
  },
  units: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  valueType,
}: MapSourcePropertyData): ReturnType<typeof UnitConverter> =>
  getUnitConverter(valueType)

export const getUnitConverter = (
  valueType: MapSourcePropertyData['valueType']
): ReturnType<typeof UnitConverter> => {
  switch (valueType) {
    case 'area':
      return area
    case 'length':
      return length
    default:
      return defaultUnitConverter
  }
}
