import { createSelector } from 'reselect'

import { selectMapLayerDefsById } from '../data/selectListMapSourceDefs'
import { selectSelectedLegendProductId } from '../data/selectSelectedLegendProductId'

export const selectSelectedProduct = createSelector(
  selectMapLayerDefsById,
  selectSelectedLegendProductId,
  (productsById, selectedLegendProductId) =>
    selectedLegendProductId ? productsById[selectedLegendProductId] : null
)
