import { createSelector } from 'reselect'
import { selectMapLayerDefsById } from '../../../data/selectListMapSourceDefs'
import { getVisualizationsForProduct } from './helpers'

export const selectProductVisualizations = createSelector(
  [selectMapLayerDefsById],
  (productsById) => {
    const result: Record<
      string,
      ReturnType<typeof getVisualizationsForProduct>
    > = {}

    for (const [productId, product] of Object.entries(productsById)) {
      if (product) {
        result[productId] = getVisualizationsForProduct(product)
      }
    }

    return result
  }
)
