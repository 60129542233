import { createSelector } from 'reselect'
import { SearchParams, selectPagingInfo } from '../../appNavigation/urls'
import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import * as api from '../../graphql/api'
import { gql, query } from '../../graphql/client'
import { Job } from '../../graphql/types'
import { RootStore } from '../../redux/types'
import { ORDER, orderBy } from '../../UI/Table/orderRows'
import { getLogs } from '../../util/logHelper'
import { pipelineTableFormatter } from './pipelineTableFormatter'
import { ListJob, ListPipeline } from './types'
import { FILTER, filterBy } from '../../UI/Table/filterRows'
import { jobTableFormatter } from './jobTableFormatter'
import { getPauseAllStatus } from '../../vvapi/maestro'

export interface GetPipeline {
  pipeline: {
    name: string
    jobs: Job[]
    jobDependencies: { jobId: string; dependsOnJobId: string }[]
  }
}

const { selector: selectGetJob, refresh: refreshGetJob } = createAsyncSelector({
  resource: 'GetJob',
  inputs: {
    jobId: (state: RootStore) => state.router.params.jobId,
  },
  fetcher: async ({ jobId }) => {
    if (!jobId) {
      return
    }

    const { data } = await api.job.get({
      pk: { id: jobId },
      returning: `
    {
      id
      taskName
      input
      isPending
      priority
      pipeline: Pipeline {
        id
        name
      }
      Status {
        status
      }
      jobAttempts: JobAttempts(order_by:{attempt:desc}, limit: 5)  {
        attempt
        status
        output
        startedAt
        finishedAt
        hasWarnings
        logLocation
        warnings: JobAttemptWarnings {
          data
        }
      }
    }
    `,
    })
    if (!data) {
      throw new Error('No Data')
    }

    return data
  },
})

const selectJob = createSelector(
  selectGetJob,
  (jobSelector) => jobSelector.data
)

const fetchLogs = async ({
  job,
  searchParams,
}: {
  job: Job
  searchParams: SearchParams
}) => {
  if (!job || !job.jobAttempts || !searchParams['selected-attempt']) {
    return
  }
  const jobAttempt = job.jobAttempts.find(
    (ja) => ja.attempt === Number(searchParams['selected-attempt'])
  )

  if (!jobAttempt || !jobAttempt.logLocation) {
    return
  }

  try {
    const logs = await getLogs(jobAttempt.logLocation)

    return {
      logs,
    }
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error)

    return {}
  }
}

const { selector: selectGetJobAttemptLogs, refresh: refreshGetJobAttemptLogs } =
  createAsyncSelector({
    resource: 'GetJobAttemptLogs',
    inputs: {
      job: selectJob,
      searchParams: (state: RootStore) => state.router.searchParams,
    },
    fetcher: fetchLogs,
  })

const { selector: selectGetPipeline, refresh: refreshGetPipeline } =
  createAsyncSelector({
    resource: 'GetPipeline',
    inputs: {
      pipelineId: (state: RootStore) => state.router.params.pipelineId,
      deliveryId: (state: RootStore) => state.router.params.deliveryId,
    },
    fetcher: async ({ pipelineId, deliveryId }) => {
      if (!pipelineId && !deliveryId) {
        return
      }

      let data: GetPipeline | undefined
      if (pipelineId) {
        data = await query<GetPipeline>({
          query: gql`
            query GetPipeline($pipelineId: uuid!) {
              pipeline: maestro_Pipeline_by_pk(id: $pipelineId) {
                name
                jobs: Jobs {
                  id
                  isPending
                  taskName
                  LatestJobAttempt {
                    attempt
                    status
                  }
                }
                jobDependencies: JobDependencies {
                  jobId
                  dependsOnJobId
                }
              }
            }
          `,
          variables: { pipelineId },
        })
      } else {
        const { delivery } = await query<{ delivery: GetPipeline }>({
          query: gql`
            query GetDeliveryPipeline($deliveryId: uuid!) {
              delivery: Delivery_by_pk(id: $deliveryId) {
                pipeline: Pipeline {
                  name
                  jobs: Jobs {
                    id
                    isPending
                    taskName
                    LatestJobAttempt {
                      attempt
                      status
                    }
                  }
                  jobDependencies: JobDependencies {
                    jobId
                    dependsOnJobId
                  }
                }
              }
            }
          `,
          variables: { deliveryId },
        })
        data = delivery
      }

      if (!data) {
        throw new Error('No Data')
      }

      return data.pipeline
    },
  })

const listPipelines = ({
  limit,
  offset,
  order_by,
}: {
  limit?: number
  offset?: number
  order_by?: string
}) =>
  api.pipeline.list<ListPipeline>({
    limit,
    offset,
    order_by:
      order_by ||
      `{
      createdAt:asc
    }`,
    returning: `{
      id
      name
      isPaused
      jobs:Jobs_aggregate {
        aggregate{
          count
        }
      }
    }`,
  })

const { selector: selectListPipeline, refresh: refreshListPipeline } =
  createAsyncSelector({
    resource: 'ListPipeline',
    inputs: {
      searchParams: (state: RootStore) => state.router.searchParams,
      pagingInfo: selectPagingInfo,
    },
    fetcher: async ({ searchParams, pagingInfo: { page, pageSize } }) => {
      const order = ORDER.deserialize(searchParams.sort ?? '')

      const offset = page * pageSize
      const limit = pageSize

      const { data, info } = await listPipelines({
        offset,
        limit,
        order_by: orderBy(pipelineTableFormatter, order),
      })

      if (!data) {
        throw new Error('No Data')
      }

      return {
        data,
        info: {
          order,
          page,
          pageSize,
          count: info?.count ?? 0,
        },
      }
    },
  })

const listJobs = ({
  limit,
  offset,
  order_by,
  where,
}: {
  limit?: number
  offset?: number
  order_by?: string
  where?: any
}) =>
  api.job.list<ListJob>({
    where: {
      taskName: { _nin: ['_pipeline_start', '_pipeline_end'] },
      ...where,
    },
    limit,
    offset,
    order_by:
      order_by ||
      `{
      createdAt:asc
    }`,
    returning: `{
      id
      isPending
      taskName
      pipeline: Pipeline {
        id
        name
      }
      status:Status{
        status
      }
      attempts:JobAttempts_aggregate{
        aggregate{
          count
        }
      }
    }`,
  })

const { selector: selectListJob, refresh: refreshListJob } =
  createAsyncSelector({
    resource: 'ListJob',
    inputs: {
      searchParams: (state: RootStore) => state.router.searchParams,
      pagingInfo: selectPagingInfo,
    },
    fetcher: async ({ searchParams, pagingInfo: { page, pageSize } }) => {
      const order = ORDER.deserialize(searchParams.sort ?? '')
      const filter = FILTER.deserialize(searchParams.filter ?? '')

      const offset = page * pageSize
      const limit = pageSize

      const { data, info } = await listJobs({
        offset,
        limit,
        order_by: orderBy(jobTableFormatter, order),
        where: filterBy(jobTableFormatter, filter, '_or'),
      })

      if (!data) {
        throw new Error('No Data')
      }

      return {
        data,
        info: {
          order,
          page,
          pageSize,
          count: info?.count ?? 0,
        },
      }
    },
  })

const { selector: selectPauseAllStatus, refresh: refreshPauseAllStatus } =
  createAsyncSelector({
    resource: 'PauseAllStatus',
    inputs: {},
    fetcher: async () => {
      const pauseStatus = await getPauseAllStatus()
      return (pauseStatus as boolean) ?? false
    },
  })

export {
  selectGetJob,
  refreshGetJob,
  selectGetPipeline,
  refreshGetPipeline,
  selectListPipeline,
  refreshListPipeline,
  selectListJob,
  refreshListJob,
  selectGetJobAttemptLogs,
  refreshGetJobAttemptLogs,
  selectPauseAllStatus,
  refreshPauseAllStatus,
}
