import { createSelector } from 'reselect'
import { selectSelectedLegendProductId } from '../../../data/selectSelectedLegendProductId'
import {
  DEFAULT_SETTINGS,
  selectProductSettings,
} from './selectProductSettings'

export const selectActiveProductSettings = createSelector(
  [selectSelectedLegendProductId, selectProductSettings],
  (productId, productSettings) => {
    const settings = productId && productSettings[productId]

    return settings || DEFAULT_SETTINGS
  }
)
