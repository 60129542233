import * as React from 'react'

import { Button, Icon, List, ListItem, Tooltip } from '@mui/material'

import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import LayerDrawerCursorLocationToggle from '../../postgis/LayerDrawer/LayerDrawerCursorLocationToggle'
import LayerDrawerGPSOffsetToggle from '../../postgis/LayerDrawer/LayerDrawerGPSOffsetToggle'
import LayerDrawerGPSToggle from '../../postgis/LayerDrawer/LayerDrawerGPSToggle'
import LayerDrawerScaleToggle from '../../postgis/LayerDrawer/LayerDrawerScaleToggle'
import LayerDrawerSpeedTrackerToggle from '../../postgis/LayerDrawer/LayerDrawerSpeedTrackerToggle'
import LayerDrawerSubheader from '../../postgis/LayerDrawer/LayerDrawerSubheader'
import { selectIsSpeedTrackerAvailable } from '../../postgis/selectors/selectIsSpeedTrackerAvailable'
import { updatePreferences } from '../../redux/preferences/redux'
import { downloadMapAsPNG, printMap } from '../../util/downloadMapAsPNG'
import { MapContext } from '../withMap'
import DrawerFieldBoundariesToggle from '../../postgis/LayerDrawer/DrawerFieldBoundariesToggle'
import LayerDrawerFieldLabelsToggle from '../../postgis/LayerDrawer/LayerDrawerFieldLabelsToggle'

export function SettingsDrawerToggle() {
  const [state, dispatch] = useRedux()
  const { preferences } = state

  const toggleSettingsDrawer = (event: any) => {
    event.preventDefault()

    if (
      preferences.showSidebarRight &&
      preferences.sidebarTabRight === 'settings'
    ) {
      dispatch(updatePreferences({ showSidebarRight: false }))
    } else {
      dispatch(
        updatePreferences({
          showSidebarRight: true,
          sidebarTabRight: 'settings',
        })
      )
    }
  }

  return (
    <Button value="settings" onClick={toggleSettingsDrawer}>
      <Tooltip title={i18n.t(keys.map.mapSettings)} placement="left">
        <Icon>settings</Icon>
      </Tooltip>
    </Button>
  )
}

export default function SettingsDrawer() {
  const { map } = React.useContext(MapContext)
  const [state] = useRedux()
  const isSpeedTrackerAvailable = selectIsSpeedTrackerAvailable(state)

  const handleDownloadPNG = async () => {
    if (map)
      downloadMapAsPNG(
        map,
        state.preferences.showSidebarRight,
        state.preferences.showSidebarLeft
      )
  }

  const handlePrintMapView = async () => {
    if (map)
      printMap(
        map,
        state.preferences.showSidebarRight,
        state.preferences.showSidebarLeft
      )
  }
  return (
    <List dense>
      {/* wrap each section in a div to push previous stickies out of the way. */}
      <div>
        <LayerDrawerSubheader>
          {i18n.t(keys.map.mapSettings)}
        </LayerDrawerSubheader>
        <DrawerFieldBoundariesToggle />
        <LayerDrawerFieldLabelsToggle />

        <LayerDrawerScaleToggle />
        <LayerDrawerCursorLocationToggle />
        <LayerDrawerGPSToggle />
      </div>
      <div>
        <LayerDrawerSubheader>
          {i18n.t(keys.layerDrawer.downloadDialog.exportMapHeading)}
        </LayerDrawerSubheader>
        <ListItem style={{ justifyContent: 'flex-start' }}>
          <Button fullWidth disabled={map == null} onClick={handlePrintMapView}>
            <Icon>print</Icon>
            {i18n.t(keys.reports.print)}
          </Button>
        </ListItem>
        <ListItem style={{ justifyContent: 'flex-start' }}>
          <Button fullWidth disabled={map == null} onClick={handleDownloadPNG}>
            <Icon>photo_camera</Icon>
            {i18n.t(keys.layerDrawer.downloadDialog.downloadPNG)}
          </Button>
        </ListItem>
      </div>

      {isSpeedTrackerAvailable && (
        <div>
          <LayerDrawerSubheader>
            {i18n.t(keys.speedTracker)}
          </LayerDrawerSubheader>

          <LayerDrawerSpeedTrackerToggle />
        </div>
      )}

      <div>
        <LayerDrawerSubheader>{i18n.t(keys.GPSLocation)}</LayerDrawerSubheader>

        <LayerDrawerGPSOffsetToggle />
      </div>
    </List>
  )
}
