import * as React from 'react'

import { Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import { Tags } from '../../../../UI/Tags'
import { copyToClipboard } from '../../../../util/copyToClipboard'
import {
  DeliveryProcGroupWithNeighborsAndJobStatuses,
  getStatusAndId,
} from './deliveryProcGroupSelectors'
import { getColor } from '../utils'

interface Props {
  procGroup: DeliveryProcGroupWithNeighborsAndJobStatuses
}

export class ProcGroupDetails extends React.PureComponent<Props> {
  render() {
    const { procGroup } = this.props

    if (!procGroup) {
      return null
    }

    const adjustedNasPath =
      procGroup &&
      (procGroup.location ?? '')
        .replace('VVQNAPHalifax/final_products', 'L:')
        .replaceAll('/', '\\')

    const { status } = getStatusAndId(procGroup.KatoLineQAJob)

    return (
      <Stack spacing={1}>
        <Stack direction="row">
          <Typography variant="body1">Status:</Typography>&nbsp;
          <Typography
            variant="body1"
            style={{
              fontWeight: 'bold',
              color: getColor(status),
            }}
          >
            {status}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
        >
          <Tags
            variant="outlined"
            color="primary"
            tags={[
              `Organization: ${procGroup?.Delivery?.Organization?.name}`,
              `Flight Date: ${procGroup.flightDate}`,
              `Proc Group: ${procGroup.procGroup}`,
            ]}
          />
        </Stack>
        {adjustedNasPath && (
          <Stack direction="row">
            <Typography variant="caption">NAS Location:</Typography>
            <Stack direction="row">
              <Tooltip
                title={
                  <>
                    <Typography variant="caption">{adjustedNasPath}</Typography>
                    <Typography variant="caption">
                      &nbsp;{'(Click to copy)'}
                    </Typography>
                  </>
                }
              >
                <IconButton
                  onClick={() => copyToClipboard(adjustedNasPath)}
                  size="large"
                >
                  <Icon fontSize="small">assignment</Icon>
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        )}
      </Stack>
    )
  }
}
