import * as React from 'react'
import { PureComponent } from 'react'
import { default as turfArea } from '@turf/area'
import centerOfMass from '@turf/center-of-mass'
import { Grid } from '@mui/material'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { selectDeliveryParcelsByParcelId } from '../data/selectDelivery'
import i18n, { keys } from '../i18n'
import MapPopup from '../map/controls/MapPopup'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import { formatNum } from '../util/formatNumber'
import { parseMetaArray } from '../util/parseMetaArray'
import { convertArea } from '../util/units/area'
import { setFocusedBlockId } from './actions'

class FocusedBlockPopup extends PureComponent<
  ReduxProps & AppDispatchProps & WithStyles<typeof styles>
> {
  render() {
    const { focusedBlockId, parcels } = this.props

    const parcel = parcels[focusedBlockId!]

    let coordinates
    if (parcel) {
      coordinates = centerOfMass(parcel.geometry).geometry!.coordinates
    }

    return (
      <MapPopup
        coordinates={coordinates}
        open={parcel !== undefined}
        onClose={this.onClose}
      >
        {this.renderBody()}
      </MapPopup>
    )
  }

  renderBody() {
    const generatedDataList = this.getBlockDataList()

    if (!generatedDataList) {
      return null
    }

    return (
      <Grid
        className="FocusedPointPopup"
        container
        direction="column"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          className={this.props.classes.listItem}
          style={{ display: 'grid', height: '49px' }}
        >
          <span style={{ fontSize: '1rem', textAlign: 'center' }}>
            {i18n.t(keys.focusedBlockDialog.fieldInformation)}
          </span>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            maxHeight: 220,
            overflowY: 'auto',
            width: 200,
            overflowX: 'hidden',
          }}
        >
          {generatedDataList.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                className={this.props.classes.listItem}
              >
                <Grid item xs={8}>
                  <span style={{ textAlign: 'left' }}>{item.title}</span>
                </Grid>
                <Grid item xs={4}>
                  <span style={{ textAlign: 'left' }}>{item.value}</span>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
        <Grid
          item
          style={{
            textAlign: 'center',
            paddingTop: 16,
          }}
        >
          <span className="btn" onClick={this.handleClose}>
            {i18n.t(keys.generic.close)}
          </span>
        </Grid>
      </Grid>
    )
  }

  getBlockDataList() {
    const { focusedBlockId, parcels, preferredUnitSystem } = this.props
    if (focusedBlockId) {
      const selectedParcelData = parcels[focusedBlockId]
      const allValues = []

      if (!selectedParcelData) {
        return
      }

      if (selectedParcelData.name) {
        allValues.push({
          title: i18n.t(keys.focusedBlockDialog.name),
          value: selectedParcelData.name,
        })
      }

      if (selectedParcelData.geometry) {
        const totalAreaInMeterSquare = turfArea(selectedParcelData.geometry)
        const measurement = convertArea(
          totalAreaInMeterSquare,
          'squareMeter',
          preferredUnitSystem
        )
        const formatedArea = `${formatNum(measurement.value)} ${
          measurement.symbol
        }`
        allValues.push({
          title: i18n.t(keys.focusedBlockDialog.area),
          value: formatedArea,
        })
      }

      if (selectedParcelData.meta) {
        if (selectedParcelData.meta.varietals) {
          const parsedVarietals = parseMetaArray(
            selectedParcelData.meta.varietals
          )
          if (parsedVarietals.length > 0) {
            allValues.push({
              title: i18n.t(keys.focusedBlockDialog.varietals),
              value: parsedVarietals.join(', '),
            })
          }
        }
        if (
          selectedParcelData.meta.rootstock &&
          selectedParcelData.meta.rootstock !== 'Unknown'
        ) {
          const parsedRootstock = parseMetaArray(
            selectedParcelData.meta.rootstock
          )
          if (parsedRootstock.length > 0) {
            allValues.push({
              title: i18n.t(keys.focusedBlockDialog.rootstock),
              value: parsedRootstock.join(', '),
            })
          }
        }
        if (
          selectedParcelData.meta.trellis &&
          selectedParcelData.meta.trellis !== 'Unknown'
        ) {
          const parsedTrellis = parseMetaArray(selectedParcelData.meta.trellis)
          if (parsedTrellis.length > 0) {
            allValues.push({
              title: i18n.t(keys.focusedBlockDialog.trellisType),
              value: parsedTrellis.join(', '),
            })
          }
        }
      }

      return allValues
    }

    return undefined
  }

  handleClose = () => {
    this.props.dispatch(setFocusedBlockId(undefined))
  }

  onClose = () => {
    return false
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      borderBottom: `1px solid ${theme.palette.background.paper}`,
      padding: 10,
      display: 'flex',
    },
  })

const mapState = (state: RootStore) => ({
  focusedBlockId: state.postgis.focusedBlockId,
  parcels: selectDeliveryParcelsByParcelId(state),
  preferredUnitSystem: state.preferences.preferredUnitSystem,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(FocusedBlockPopup)
)
