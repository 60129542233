type ClassName = string | [string | undefined, boolean | undefined] | undefined

export function classnames(...classes: ClassName[]) {
  return classes
    .map((className) => {
      if (typeof className === 'string') {
        return className
      }

      if (Array.isArray(className)) {
        const [name, enabled] = className

        return enabled ? name : undefined
      }

      return null
    })
    .filter((className) => typeof className === 'string')
    .join(' ')
}
