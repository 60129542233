import * as Sentry from '@sentry/browser'
import { default as swal } from 'sweetalert'
import { GQLError } from '../graphql/GQLError'
import handleError from '../util/handleError'

interface ErrorAlert {
  error: any
  title: string
  message?: string
  tags?: Record<string, string>
  extras?: Record<string, any>
}

/**
 * It displays an error message on the screen and sends an error to Sentry.
 * For internal admin screens only.
 */
const errorAlert = async ({
  error,
  title,
  message,
  tags,
  extras,
}: ErrorAlert) => {
  Sentry.withScope((scope) => {
    scope.setLevel(Sentry.Severity.Error)

    if (tags) {
      scope.setTags(tags)
    }

    if (extras) {
      scope.setExtras(extras)
    }

    Sentry.addBreadcrumb({
      message,
    })

    handleError(error)
  })

  // tslint:disable-next-line: no-console
  console.error(`${title}:`, error)

  const errors: string[] = []

  if (error instanceof GQLError) {
    error.errors.forEach((e) => errors.push(`${e}`))
  }

  if (error?.fetchHttpResponse instanceof Response) {
    const response = error.fetchHttpResponse as Response
    const text = await response.text()
    try {
      const json = JSON.parse(text)
      // tslint:disable-next-line: no-console
      console.error('response body:', json)

      // look for standard formatted Sequelize errors
      if (json.errors) {
        for (const key of Object.keys(json.errors)) {
          const keyErrors = json.errors[key] as { message: string }[]
          errors.push(`${key}: ${keyErrors.map(({ message: m }) => m)}`)
        }
      }
    } catch (parseException) {
      // tslint:disable-next-line: no-console
      console.error('response body:', text)
    }
  }

  return swal({
    title,
    text: `${message ?? ''}
      ${
        errors.join('\n') ||
        `Please send any console logs to the dev team along with any supporting materials might help them
        track down the issue.
        Thanks`
      }

      See console for details: ${error}
    `,
    icon: 'error',
  })
}

export default errorAlert
