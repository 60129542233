import * as React from 'react'
import { SelectBox, Selection } from '../../../../../UI/SelectBox/SelectBox'
import { useRedux } from '../../../../../hooks/useRedux'
import {
  GroupData,
  ParcelData,
  selectGroupDatesByGroupId,
} from '../../../../../data/selectOrgMapData'
import { isNumericIdEqual } from '../../../../../util/isNumericIdEqual'
import { toggleArrayValue } from '../../../../../util/toggleArrayValue'
import { dateRangeString } from '../../../../../postgis/ParcelDrawer/getParcelDateString'
import {
  Collapse,
  Icon,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material'
import i18n, { keys } from '../../../../../i18n'
import { ExpandButton } from '../../../../../UI/Expand/ExpandButton'
import { createStyles, makeStyles } from '@mui/styles'
import { MapEditorToolType } from '../../../../../redux/map-editor-pages/redux'
import { selectDrawerOptions } from '../../../../../map-editor/redux/selectors/selectDrawerOptions'
import { MapEditorActionTypes } from '../../../../../map-editor/redux/types'
import BlockListItem from './BlockListItem'

interface Props {
  id: string
  page: MapEditorToolType
  group: GroupData
  selectedFlightDate: string
  filteredParcels: ParcelData[]
  selectedParcelIds: Set<ParcelData['id']>
  onSelect: (parcelIds: string[], flightDate?: string) => void
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ListItem: {
      backgroundColor: theme.palette.background.default,
    },
    stickyDiv: {
      // we need to use the same backgroundColor as the <ParcelDrawer>
      // so that when you hover the <ListItem button> you don't see the
      // elements behind it and so it has the same hover backgroundColor
      // as the <ParcelListItem>
      backgroundColor: theme.palette.background.paper,
      position: 'sticky',
      zIndex: 1,
      top: 0,
    },
    parcelContainer: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
  })
)

const getGroupSelection = (
  groupParcels: ParcelData[],
  selectedParcelIds: Set<ParcelData['id']>
): Selection => {
  const selectedParcels = groupParcels.filter(({ id: parcelId }) =>
    selectedParcelIds.has(parcelId)
  )

  return selectedParcels.length === 0
    ? 'unselected'
    : selectedParcels.length === groupParcels.length
    ? 'selected'
    : 'partially-selected'
}

const BlockGroupListItem = ({
  id,
  page,
  group,
  selectedFlightDate,
  filteredParcels,
  selectedParcelIds,
  onSelect,
  disabled = false,
}: Props) => {
  const classes = useStyles()
  const [state, dispatch] = useRedux()
  const options = selectDrawerOptions(state, page)
  const expandedGroupIds = React.useMemo(
    () => options[id]?.config?.expandedGroupIds ?? [],
    [options, id]
  )
  const flightDatesByGroupId = selectGroupDatesByGroupId(state)
  const [groupParcels, setGroupParcels] = React.useState<ParcelData[]>([])
  const [groupSelection, setGroupSelection] =
    React.useState<Selection>('unselected')

  React.useEffect(() => {
    onUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    onUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParcelIds, filteredParcels])

  const onUpdate = () => {
    const groupParcels = filteredParcels.filter(({ groupId }) =>
      isNumericIdEqual(groupId, group.id)
    )

    setGroupParcels(groupParcels)
    setGroupSelection(getGroupSelection(groupParcels, selectedParcelIds))
  }

  const handleCheckboxClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // the checkbox is within a button; stop propagation to that button
      event.stopPropagation()

      const groupParcelIds = groupParcels.map(({ id: parcelId }) => parcelId)

      const newFlightDate = selectedFlightDate
        ? selectedFlightDate
        : flightDatesByGroupId[group.id]?.[0]?.flightDate

      if (groupSelection === 'selected') {
        onSelect([], selectedFlightDate)
      } else {
        onSelect(groupParcelIds, newFlightDate)
      }
    },
    [
      flightDatesByGroupId,
      group.id,
      groupParcels,
      groupSelection,
      onSelect,
      selectedFlightDate,
    ]
  )

  const handleCollapseClick = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault()
      event.stopPropagation()

      if (!group) {
        return
      }

      dispatch({
        type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
        page: page,
        payload: {
          drawerOptions: {
            ...options,
            [id]: {
              ...options[id],
              config: {
                ...options[id].config,
                expandedGroupIds: toggleArrayValue(expandedGroupIds, group.id),
              },
            },
          },
        },
      })
    },
    [dispatch, expandedGroupIds, group, id, options, page]
  )

  const isExpanded = expandedGroupIds.includes(group.id)
  const dates = dateRangeString(group.flightDates.slice(-1))
  const isDisabled = !dates || disabled

  const datesString = dates || i18n.t(keys.generic.unavailable)

  return (
    <div>
      <div className={classes.stickyDiv}>
        <ListItem
          disabled={isDisabled}
          button
          disableGutters
          dense
          className={classes.ListItem}
          onClick={handleCollapseClick}
          disableRipple
        >
          <ExpandButton
            style={{ padding: 0 }}
            expanded={isExpanded}
            className="GroupListItem__isCollapsed"
          />
          <SelectBox
            disabled={isDisabled}
            style={{ padding: 0 }}
            selection={groupSelection}
            indeterminateIcon={<Icon>add_box</Icon>}
            color="primary"
            onClick={handleCheckboxClick}
            disableRipple
            className="GroupListItem__isSelected"
          />

          <ListItemText
            style={{ marginLeft: 20 }}
            primary={
              <Typography
                variant="body2"
                style={{ textTransform: 'uppercase' }}
              >
                {group.name}
              </Typography>
            }
            secondary={
              <Typography
                variant="caption"
                style={{ textTransform: 'uppercase' }}
              >
                {datesString}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
      </div>
      <Collapse
        classes={{
          wrapperInner: classes.parcelContainer,
        }}
        in={isExpanded}
      >
        {groupParcels.map((parcel) => (
          <BlockListItem
            key={parcel.id}
            parcel={parcel}
            selectedFlightDate={selectedFlightDate}
            selectedParcelIds={selectedParcelIds}
            filteredParcels={filteredParcels}
            onSelect={onSelect}
          />
        ))}
      </Collapse>
    </div>
  )
}

export default BlockGroupListItem
