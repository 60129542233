import { selectPagingInfo } from '../../../appNavigation/urls'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { RootStore } from '../../../redux/types'
import { ORDER, orderBy } from '../../../UI/Table/orderRows'
import { tableFormatter } from './tableFormatter'
import { ListDeliveryStatus } from './types'

const list = ({
  limit,
  offset,
  order_by,
}: {
  limit?: number
  offset?: number
  order_by?: string
}) =>
  api.delivery.list<ListDeliveryStatus>({
    limit,
    offset,
    order_by,
    returning: `{
      id
      comment
      Organization {
        id
        name
      }
      View_DeliveryStatus {
        totalCount
        expectedCount
        enabledCount
        fulfilledCount
        expected
        enabled
        fulfilled
      }
      TargetDelivery {
        date
      }
      CreatedBy {
        firstName
      }
      UpdatedBy {
        firstName
      }
      createdAt
      updatedAt
    }`,
  })

const {
  selector: selectListDeliveryStatus,
  refresh: refreshListDeliveryStatus,
} = createAsyncSelector({
  resource: 'ListDeliveryStatus',
  inputs: {
    searchParams: (state: RootStore) => state.router.searchParams,
    pagingInfo: selectPagingInfo,
  },
  fetcher: async ({ searchParams, pagingInfo: { page, pageSize } }) => {
    const order = ORDER.deserialize(searchParams.sort ?? '')

    const offset = page * pageSize
    const limit = pageSize

    const { data, info } = await list({
      offset,
      limit,
      order_by: orderBy(tableFormatter, order),
    })

    if (!data) {
      throw new Error('No Data')
    }

    return {
      data,
      info: {
        order,
        page,
        pageSize,
        count: info?.count ?? 0,
      },
    }
  },
})

export { selectListDeliveryStatus, refreshListDeliveryStatus }
