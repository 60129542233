import FormControl from '@mui/material/FormControl'
import FormControlLabel, {
  FormControlLabelProps as _FormControlLabelProps,
} from '@mui/material/FormControlLabel'
import FormHelperText, {
  FormHelperTextProps as _FormHelperTextProps,
} from '@mui/material/FormHelperText'
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import { TextFieldProps } from '@mui/material/TextField'
import * as React from 'react'
import { Omit } from 'react-redux'

type CopiedTextFieldProps = Pick<
  TextFieldProps,
  'children' | 'className' | 'error' | 'fullWidth' | 'helperText' | 'required'
>

export type FormFieldBaseProps = CopiedTextFieldProps & {
  helperTextProps?: _FormHelperTextProps
}

type Omitted = Omit<_FormControlLabelProps, 'control' | 'label'>

export type ControlLabelFieldProps<P extends JSX.IntrinsicAttributes> =
  FormFieldBaseProps & {
    controlLabelProps: Omitted
    controlProps: P
    label: React.ReactNode
    topLabel?: React.ReactNode
    labelProps?: FormLabelProps
  }

export default function createControlLabelField<
  P extends JSX.IntrinsicAttributes = {}
>(C: React.ComponentType<P>) {
  class Wrapper extends React.Component<ControlLabelFieldProps<P>> {
    render() {
      const {
        children,
        className,
        error,
        controlProps,
        helperTextProps,
        fullWidth,
        helperText,
        controlLabelProps,
        label,
        labelProps,
        topLabel,
        required,
        ...other
      } = this.props

      return (
        <FormControl
          className={className}
          error={error}
          fullWidth={fullWidth}
          required={required}
          {...other}
        >
          {topLabel && <FormLabel {...labelProps}>{topLabel}</FormLabel>}

          <FormControlLabel
            {...controlLabelProps}
            label={label}
            control={<C {...controlProps} />}
          />

          {helperText && (
            <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
          )}
        </FormControl>
      )
    }
  }

  return Wrapper
}
