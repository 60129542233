import * as React from 'react'
import AsyncSelectorStatusOverlay from '../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { connect } from '../../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../../redux/types'
import PackageSelection from './PackageSelection'
import {
  refreshOrderPackageSelection,
  selectOrderPackageSelection,
} from './selectOrderPackageSelection'

interface Props {
  onSelectionChange?: (selection: string[]) => void
  onZoomToSelection?: (
    selection: { groupId: string } | { parcelId: string }
  ) => void
}

class OrderPackageSelection extends React.PureComponent<ReduxProps & Props> {
  render() {
    const { orderPackageSelection, onZoomToSelection, onSelectionChange } =
      this.props

    if (!orderPackageSelection || !orderPackageSelection.data) {
      return null
    }

    return (
      <div className="Paper">
        <AsyncSelectorStatusOverlay requests={orderPackageSelection}>
          <PackageSelection
            data={orderPackageSelection.data}
            onZoomToSelection={onZoomToSelection}
            onSelectionChange={(selection) =>
              onSelectionChange &&
              onSelectionChange(selection.map((p) => p.parcelId))
            }
            onRefresh={refreshOrderPackageSelection}
          />
        </AsyncSelectorStatusOverlay>
      </div>
    )
  }
}

const mapState = (state: RootStore) => ({
  orderId: state.router.params.orderId,
  orderPackageSelection: selectOrderPackageSelection(state),
})

type ReduxProps = Partial<ReturnType<typeof mapState>>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  OrderPackageSelection
)
