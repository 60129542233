import { MapLayerDefData } from '../data/types'
import { gql, query } from '../graphql/client'
import { indexArray } from '../util/indexArray'

export type MapLayer = Pick<
  MapLayerDefData,
  'name' | 'id' | 'pdfFilename' | 'pdfFilenameTranslations'
>

export const fetchMapLayersForGroup = async ([deliveryId, groupId]: [
  deliveryId: string | undefined,
  groupId: string | undefined
]) => {
  if (!deliveryId || !groupId) {
    return
  }

  const { mapLayers } = await query<{
    mapLayers: MapLayer[]
  }>({
    query: gql`
      query DELIVERY_GROUP_MAPLAYERS($deliveryId: uuid!, $groupId: Int!) {
        mapLayers: MapLayerDef(
          where: {
            MapLayers: {
              MapSource: {
                DeliveryParcel: {
                  DeliveryGroup: {
                    deliveryId: { _eq: $deliveryId }
                    groupId: { _eq: $groupId }
                  }
                }
              }
            }
            pdfFilename: { _is_null: false }
          }
        ) {
          name
          pdfFilename
          pdfFilenameTranslations
          id
        }
      }
    `,
    variables: {
      deliveryId,
      groupId,
    },
  })

  return indexArray(mapLayers, 'pdfFilename')
}
