import { parsePath } from 'history'
import * as React from 'react'
import {
  LinkProps as RRLinkProps,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'
import { classnames } from '../util/classnames'

const fakePropType: any = () => null
fakePropType.isRequired = () => null

// const isModifiedEvent = (event: React.MouseEvent<any>) =>
//   !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

export interface LinkChildProps {
  disabled?: boolean
  /**
   * The url to visit.
   */
  to: RRLinkProps['to']
  /**
   * Replaces the current location without adding it to the history.
   */
  replace?: boolean
  /**
   * Use exact matching instead of starts with when checking current location.
   */
  exact?: boolean
  /**
   * Class name given to the child when current location matches `to`.
   */
  activeClassName?: string
  /**
   * Style given to the child when current location matches `to`.
   */
  activeStyle?: React.CSSProperties
  /**
   * Disable handling of middle mouse / ctrl+click.
   */
  disableNewTab?: boolean
  /**
   * Disable passing an `href` prop.
   */
  disableHref?: boolean
  /**
   * Disable passing an `onClick` prop.
   */
  disableOnclick?: boolean
  /**
   * Accepting a `className` fixes a bug with @mui/material.
   * CardActions -> LinkChild -> Button.
   */
  className?: string
  /**
   * Custom onclick event handler.
   */
  onClick?: (event: React.MouseEvent<any>) => any
  /**
   * LinkChild requires a single child element.
   */
  children: React.ReactElement<any>
  /**
   * Forces opening the link in a new tab/window
   */
  forceNewTab?: boolean
}

class LinkChild extends React.Component<LinkChildProps & RouteComponentProps> {
  render() {
    const {
      location: currLocation,
      history,
      match: { path },
    } = this.props

    let childProps = null
    const { to, disabled } = this.props
    if (disabled) {
      childProps = {
        style: {},

        disabled,
      }

      // Return the child element with extra props.
      return React.cloneElement(
        React.Children.only(this.props.children),
        childProps
      )
    }

    let location: any

    if (typeof to === 'function') {
      location = to(currLocation)
    } else {
      location = typeof to === 'string' ? parsePath(to) : to
    }

    const href = history.createHref(location)
    let style: React.CSSProperties | undefined
    let doesMatch = false

    // If `activeClassName` or `activeStyle` are set then check the current
    // location against our `to` prop.
    if (this.props.activeClassName || this.props.activeStyle) {
      doesMatch = this.props.exact
        ? path === href.replace(/^#/, '')
        : path.startsWith(href.replace(/^#/, ''))

      if (doesMatch) {
        style = this.props.activeStyle
      }
    }

    if (style) {
      childProps = {
        style,
        className: classnames(this.props.className, [
          this.props.activeClassName,
          doesMatch,
        ]),
        href: this.props.disableHref ? undefined : href,
        onClick: this.props.disableOnclick ? undefined : this.handleClick,
      }
    } else {
      childProps = {
        className: classnames(this.props.className, [
          this.props.activeClassName,
          doesMatch,
        ]),
        href: this.props.disableHref ? undefined : href,
        onClick: this.props.disableOnclick ? undefined : this.handleClick,
      }
    }

    // Return the child element with extra props.
    return React.cloneElement(
      React.Children.only(this.props.children),
      childProps
    )
  }

  handleClick = (event: React.MouseEvent<any>) => {
    // Return early if need be.
    if (
      event.isPropagationStopped() ||
      (this.props.disableNewTab && event.button !== 0) ||
      (!this.props.disableNewTab && event.button > 1)
      // || isModifiedEvent(event)
    ) {
      return
    }

    if (this.props.onClick) {
      this.props.onClick(event)
    }

    event.preventDefault()

    const {
      history,
      location: currLocation,
      replace,
      to,
      forceNewTab,
    } = this.props

    let location: any

    if (typeof to === 'function') {
      location = to(currLocation)
    } else {
      location = typeof to === 'string' ? parsePath(to) : to
    }

    // Check if we can and should open a new tab.
    if (
      (!this.props.disableNewTab &&
        (event.metaKey || event.ctrlKey || event.button === 1)) ||
      forceNewTab
    ) {
      event.stopPropagation()

      window.open(history.createHref(location))

      return
    }

    if (replace) {
      history.replace(location)
    } else {
      history.push(location)
    }
  }
}

export default withRouter(LinkChild)
