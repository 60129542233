import * as React from 'react'
import { Link } from 'react-router-dom'

import { Chip } from '@mui/material'

import { url, urls } from '../../../appNavigation/urls'
import i18n from '../../../i18n'
import { TableFormatter } from '../../../UI/Table/types'
import { Job } from './types'

const taskNameColumn: TableFormatter<Job> = {
  header: () => 'Task Name',
  data: ({ id, taskName }) => (
    <Link to={url(urls.job, { jobId: id })}>{taskName}</Link>
  ),
  orderBy: (direction) => `
    {
      taskName: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    taskName: { [`_${op}`]: value },
  }),
}

const statusColumn: TableFormatter<Job> = {
  header: () => 'Status',
  data: ({ id, isPending, LatestJobAttempt }) => {
    const status = isPending ? 'pending' : LatestJobAttempt?.status ?? 'created'

    const statusColor = /error|timeout/i.test(status)
      ? 'var(--error-color)'
      : /running/i.test(status)
      ? 'var(--info-color)'
      : /complete/i.test(status)
      ? 'var(--success-color)'
      : undefined

    return <Chip style={{ background: statusColor }} label={status} />
  },
  orderBy: (direction) => `
    {
      LatestJobAttempt: {
        status: ${direction}
      }
    }
  `,
  filterBy: (op, value) => ({
    LatestJobAttempt: {
      status: { [`_${op}`]: value },
    },
  }),
}

const attemptsColumn: TableFormatter<Job> = {
  header: () => 'Attempts',
  data: ({ LatestJobAttempt }) => LatestJobAttempt?.attempt ?? 0,
  orderBy: (direction) => `
    {
      LatestJobAttempt: {
        attempt: ${direction}
      }
    }
  `,
  filterBy: (op, value) => ({
    LatestJobAttempt: {
      attempt: { [`_${op}`]: value },
    },
  }),
}

const priorityColumn: TableFormatter<Job> = {
  header: () => 'Priority',
  data: ({ priority }) => priority,
  orderBy: (direction) => `
    {
      priority: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    priority: { [`_${op}`]: value },
  }),
}

const timeoutColumn: TableFormatter<Job> = {
  header: () => 'Timeout',
  data: ({ timeout }) => `${timeout}s`,
  orderBy: (direction) => `
    {
      timeout: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    timeout: { [`_${op}`]: value },
  }),
}

const createdAtColumn: TableFormatter<Job> = {
  header: () => 'Created At',
  data: ({ createdAt }) => i18n.toDateTimeShort(createdAt),
  orderBy: (direction) => `
    {
      createdAt: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    createdAt: { [`_${op}`]: value },
  }),
}

export const formatters = {
  statusColumn,
  taskNameColumn,
  attemptsColumn,
  priorityColumn,
  timeoutColumn,
  createdAtColumn,
}

export const tableFormatter = Object.values(formatters)
