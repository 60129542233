import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import errorAlert from '../../../admin/errorAlert'
import assertDefinedFields from '../../../admin/mapdata/utils/assertDefinedFields'
import { RouteParams, url, urls } from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../../graphql/api'
import { Model } from '../../../graphql/types'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { selectGetTargetDelivery } from '../TargetDelivery/selectGetTargetDelivery'
import DeliveryForm from './DeliveryForm'
import { GetDeliveryData } from './selectGetDelivery'
import OrderPage from '../Order/OrderPage'

class NewDelivery extends React.PureComponent<
  RouteComponentProps<RouteParams> & ReduxProps
> {
  render() {
    return (
      <OrderPage
        title={`New Delivery`}
        backTo={this.getBackUrl}
        backToTitle="Order"
      >
        <div id="NewDelivery" className="MapDataContainerSmall">
          <AsyncSelectorStatusOverlay requests={this.props.targetDelivery}>
            <DeliveryForm
              type="new"
              onCancel={this.handleCancel}
              onSave={this.handleCreate}
            />
          </AsyncSelectorStatusOverlay>
        </div>
      </OrderPage>
    )
  }

  handleCreate = async ({ comment }: GetDeliveryData) => {
    try {
      const targetDelivery = this.props.targetDelivery.data
      if (!targetDelivery) {
        throw new Error('Something went wrong')
      }
      const {
        order: { organizationId },
        orderId,
        date: targetDeliveryDate,
      } = targetDelivery

      const required = {
        organizationId,
        orderId,
        targetDeliveryDate,
      }

      if (assertDefinedFields(required)) {
        const req = await api.delivery.create<Model>({
          input: {
            ...required,
            comment,
          },
        })

        this.props.history.replace(
          url(urls.editDelivery, {
            deliveryId: req.data!.id,
          })
        )
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Delivery', message, this.state)
    }
  }

  getBackUrl = () => {
    const targetDelivery = this.props.targetDelivery.data

    if (!targetDelivery) {
      return
    }

    const { orderId } = targetDelivery

    return url(urls.editOrder, { orderId })
  }

  handleCancel = () => {
    const backUrl = this.getBackUrl()
    if (backUrl) {
      this.props.history.push(backUrl)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'NewDelivery',
    },
  })

const mapState = (state: RootStore) => ({
  orderId: state.router.params.orderId!,
  targetDeliveryDate: state.router.params.targetDeliveryDate!,
  targetDelivery: selectGetTargetDelivery(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(NewDelivery)
)
