import * as React from 'react'
import { SamplePlanReportSelector } from "./SamplePlanReportSelector"
import { useSamplePlanMapContext } from '../Context/SamplePlanMapContext'

interface Props {
    downloadPlanOpen: boolean
    setDownloadPlanOpen: (open: boolean) => void
}
export const SamplePlanReportMapSelector = ({downloadPlanOpen, setDownloadPlanOpen}: Props) => {
    const { state: samplePlanMapState } = useSamplePlanMapContext()
    const samplePlan = React.useMemo(() => {
      return samplePlanMapState.samplePlan
    }, [samplePlanMapState.samplePlan])
  
    const parcels = React.useMemo(() => {
      return samplePlan?.SamplePlanBlocks?.map((spb) => spb.Parcel) ?? []
    }, [samplePlan?.SamplePlanBlocks])

    return (
        <SamplePlanReportSelector
            downloadPlanOpen={downloadPlanOpen}
            setDownloadPlanOpen={setDownloadPlanOpen}
            samplePlan={samplePlan}
            parcels={parcels}
        />
    )
}