import * as mapboxgl from 'mapbox-gl'
import { Feature, Sample } from '../../../../graphql/types'
import { SAMPLE_PLAN_BLOCK_LABEL } from './parcelLayerHelper'

export const SAMPLE_PLAN_POINT_LABELS = 'SAMPLE_PLAN_POINT_LABELS'
export const SAMPLE_PLAN_POINTS = 'SAMPLE-PLAN-POINTS'
export const SAMPLE_PLAN_SELECTED_POINT = 'SAMPLE-PLAN-SELECTED-POINT'
export const SAMPLE_PLAN_COMPLETE_ICON = 'sample-plan-complete'
export const SAMPLE_PLAN_INCOMPLETE_ICON = 'sample-plan-incomplete'
export const SAMPLE_PLAN_CHECKBOX_ICON = 'sample-plan-checkbox'
export const SAMPLE_PLAN_HALO_COLOUR = '#FFFFFF'

interface SamplesInput {
  map?: mapboxgl.Map
  samples?: Sample[]
}

interface SelectedSampleInput {
  map?: mapboxgl.Map
  highlightedSample?: Sample | null
}

const addSamplesSourcesAndLayers = ({ map, samples }: SamplesInput) => {
  if (!map) {
    return
  }

  if (!samples) {
    return
  }

  if (!map?.isSourceLoaded(SAMPLE_PLAN_POINTS)) {
    const selectedSampleSourcesAndLayers: {
      sources: Record<string, any>
      layers: mapboxgl.Layer[]
    } = { sources: {}, layers: [] }

    buildSampleSourcesAndLayers(samples, selectedSampleSourcesAndLayers)

    Object.entries(selectedSampleSourcesAndLayers.sources).forEach(
      (sourceEntry) => {
        map?.addSource(sourceEntry[0], sourceEntry[1])
      }
    )
    selectedSampleSourcesAndLayers.layers.forEach((layer) => {
      map?.addLayer(layer as mapboxgl.AnyLayer, SAMPLE_PLAN_BLOCK_LABEL)
    })
  } else {
    const updatedSource = buildSampleSource(samples)
    const currentSource = map.getSource(SAMPLE_PLAN_POINTS) as mapboxgl.GeoJSONSource;
    currentSource.setData(updatedSource.data)
  }
}

const addHighlightedSampleHaloSourcesAndLayers = ({
  map,
  highlightedSample,
}: SelectedSampleInput) => {
  if (!map) {
    return
  }

  if (!highlightedSample) {
    return
  }

  if (!map?.isSourceLoaded(SAMPLE_PLAN_SELECTED_POINT)) {
    const selectedSampleHaloSourcesAndLayers: {
      sources: Record<string, any>
      layers: mapboxgl.Layer[]
    } = { sources: {}, layers: [] }

    buildSampleHaloSourcesAndLayers(
      highlightedSample,
      selectedSampleHaloSourcesAndLayers
    )

    Object.entries(selectedSampleHaloSourcesAndLayers.sources).forEach(
      (sourceEntry) => {
        map?.addSource(sourceEntry[0], sourceEntry[1])
      }
    )
    selectedSampleHaloSourcesAndLayers.layers.forEach((layer) => {
      map?.addLayer(layer as mapboxgl.AnyLayer, SAMPLE_PLAN_POINTS)
    })

    // move labels back to the front.
    map?.moveLayer(SAMPLE_PLAN_BLOCK_LABEL)
  } else {
    const updatedSource = buildHighlightedSampleSource(highlightedSample)
    const currentSource = map.getSource(SAMPLE_PLAN_SELECTED_POINT) as mapboxgl.GeoJSONSource;
    currentSource.setData(updatedSource.data)
  }
}

const buildSampleSource = (samples: Sample[]) => {
  // order by row then plant
  const sortedSamples = [...samples].sort((a, b) => {
    if (a.rowID < b.rowID) {
      return -1
    }
    if (a.rowID > b.rowID) {
      return 1
    }
    if (a.plantID < b.plantID) {
      return -1
    }
    if (a.plantID > b.plantID) {
      return 1
    }
    return 0
  })

  const sampleGeoJson = sortedSamples.map((sample, index) => {
    return {
      id: sample!.id,
      type: 'Feature',
      geometry: sample!.geometry,
      properties: {
        isCompleted: `${!!sample.completedAt}`,
        id: sample?.id,
        meta: sample?.content,
        index: index + 1,
        ...sample.properties,
      },
    } as GeoJSON.Feature<Feature['geometry']>
  })

  const sampleFeatureCollection = {
    features: sampleGeoJson,
    type: 'FeatureCollection',
  } as GeoJSON.FeatureCollection<Feature['geometry']>

  return {
    data: sampleFeatureCollection,
    type: 'geojson',
    attribution: '&copy; VineView',
  }
}

const buildSampleSourcesAndLayers = (
  samples: Sample[],
  sampleSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  },
  checkboxMode: boolean = false
) => {
  sampleSourcesAndLayers.sources[SAMPLE_PLAN_POINTS] = buildSampleSource(samples)

  sampleSourcesAndLayers.layers.push({
    type: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': [
        'match',
        ['get', 'isCompleted'],
        'true',
        SAMPLE_PLAN_COMPLETE_ICON,
        checkboxMode ? SAMPLE_PLAN_CHECKBOX_ICON : SAMPLE_PLAN_INCOMPLETE_ICON,
      ],
    },
    id: SAMPLE_PLAN_POINTS,
    source: SAMPLE_PLAN_POINTS,
  })
}

const buildSampleLabelSourcesAndLayers = (
  samples: Sample[],
  sampleSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  }
) => {
  sampleSourcesAndLayers.sources[SAMPLE_PLAN_POINT_LABELS] = buildSampleSource(samples)

  sampleSourcesAndLayers.layers.push({
    type: 'symbol',
    layout: {
      'text-field': ['get', 'index'],
      'text-size': 8,
      'text-offset': [0, -0.1],
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      "text-allow-overlap": true,
      'text-ignore-placement': true,
    },
    paint: {
      'text-color': '#000000'
    },
    id: SAMPLE_PLAN_POINT_LABELS,
    source: SAMPLE_PLAN_POINT_LABELS,
  })
}

const buildHighlightedSampleSource = (sample: Sample) => {
  const sampleGeoJson = [
    {
      id: sample!.id,
      type: 'Feature',
      geometry: sample!.geometry,
      properties: {
        isCompleted: `${!!sample.completedAt}`,
        id: sample?.id,
        meta: sample?.content,
      },
    } as GeoJSON.Feature<Feature['geometry']>,
  ]

  const sampleHaloFeatureCollection = {
    features: sampleGeoJson,
    type: 'FeatureCollection',
  } as GeoJSON.FeatureCollection<Feature['geometry']>

  return {
    data: sampleHaloFeatureCollection,
    type: 'geojson',
    attribution: '&copy; VineView',
  }
}


const buildSampleHaloSourcesAndLayers = (
  sample: Sample,
  sampleHaloSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  }
) => {

  sampleHaloSourcesAndLayers.sources[SAMPLE_PLAN_SELECTED_POINT] = buildHighlightedSampleSource(sample)

  sampleHaloSourcesAndLayers.layers.push({
    type: 'circle',
    paint: {
      'circle-color': SAMPLE_PLAN_HALO_COLOUR,
      'circle-blur': 1,
      'circle-radius': 30,
    },
    id: SAMPLE_PLAN_SELECTED_POINT,
    source: SAMPLE_PLAN_SELECTED_POINT,
  })
}

export {
  addSamplesSourcesAndLayers,
  addHighlightedSampleHaloSourcesAndLayers,
  buildSampleSourcesAndLayers,
  buildSampleHaloSourcesAndLayers,
  buildSampleLabelSourcesAndLayers
}
