import * as React from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import { Job } from '../../graphql/types'
import { Column } from '../UI/Column'
import { Row } from '../UI/Row'
interface Props {
  katoJob: Job
  open: boolean
  onClose: () => {}
  onResponse: (confirm: boolean, requeue: boolean) => {}
}

interface State {
  requeue: boolean
}

export class CancelKatoJobDialog extends React.Component<Props, State> {
  state: State = { requeue: true }
  render() {
    const { open, onClose } = this.props
    const { requeue } = this.state

    return (
      <Dialog
        style={{ minWidth: 450 }}
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="cancel-kato-job-title"
      >
        <DialogTitle id="cancel-kato-job-title">Cancel Kato Job</DialogTitle>
        <DialogContent>
          <Column>
            <Typography>Are you sure you'd like to cancel this job?</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={requeue}
                  onChange={this.handleRequeueChanged}
                  name="requeue"
                />
              }
              label="Requeue Job for QA"
            />
            <Row style={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}>
              <Button
                style={{ marginRight: 8 }}
                variant="contained"
                color="secondary"
                onClick={() => this.handleResponse(false)}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleResponse(true)}
              >
                Yes
              </Button>
            </Row>
          </Column>
        </DialogContent>
      </Dialog>
    )
  }

  handleResponse = async (confirm: boolean) => {
    const { onResponse } = this.props
    const { requeue } = this.state

    onResponse(confirm, requeue)
  }

  handleRequeueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ requeue: !!event.target.checked })
  }
}
