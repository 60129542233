import { fetchJobs } from '../../../admin/Maestro/JobTable/fetchJobs'
// import { FILTER } from '../../UI/Table/filterRows'
import { tableFormatter } from '../../../admin/Maestro/JobTable/formatters'
import { selectPagingInfo } from '../../../appNavigation/urls'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { RootStore } from '../../../redux/types'
import { ORDER, orderBy } from '../../../UI/Table/orderRows'
import { selectGetDelivery } from './selectGetDelivery'

const { selector: selectListDeliveryJobs, refresh: refreshListDeliveryJobs } =
  createAsyncSelector({
    resource: 'ListDeliveryJobs',
    inputs: {
      delivery: selectGetDelivery,
      searchParams: (state: RootStore) => state.router.searchParams,
      pagingInfo: selectPagingInfo,
    },
    fetcher: async ({ delivery, searchParams, pagingInfo }) => {
      if (!delivery.data) {
        return
      }

      const order = ORDER.deserialize(searchParams.orderBy ?? '')
      // const filter = FILTER.deserialize(searchParams.filter ?? '')

      const { data, info } = await fetchJobs({
        where: { pipelineId: { _eq: delivery.data.pipelineId } },
        papination: pagingInfo,
        order_by: orderBy(tableFormatter, order),
      })

      if (!data) {
        throw new Error('No Data')
      }

      return {
        data,
        info: {
          order,
          ...pagingInfo,
          count: info?.count ?? 0,
        },
      }
    },
  })

export { selectListDeliveryJobs, refreshListDeliveryJobs }
