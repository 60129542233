import { PureComponent } from 'react'
import { MapboxGL } from '../map/MapboxGL'
import withMap, { WithMap } from '../map/withMap'
import { connect } from '../redux/connect'
import { RootStore } from '../redux/types'
import didPropChange from '../util/didPropChange'

class RestoreMapPosition extends PureComponent<WithMap & ReduxProps> {
  componentDidMount() {
    this.restoreBounds()
  }

  componentDidUpdate(prevProps: this['props']) {
    if (didPropChange('settingsFetchedAt', prevProps, this.props)) {
      this.restoreBounds()
    }
  }

  restoreBounds = () => {
    try {
      this.props.map.fitBounds(this.props.bounds as MapboxGL.LngLatBoundsLike, {
        duration: 0,
      })
    } catch (e) {
      console.error('Error restoring map position', e)
    }
  }

  render() {
    return null
  }
}

const mapState = (state: RootStore) => ({
  settingsFetchedAt: state.settingsFetchedAt,
  bounds: state.postgis.bounds,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(
  withMap(RestoreMapPosition)
)
