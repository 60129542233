import { createSelector } from '@reduxjs/toolkit'
import {
  MapEditorToolType,
  MapEditorToolsState,
} from '../../../redux/map-editor-pages/redux'
import { RootStore } from '../../../redux/types'

const getPageLeftDrawerWidth = (
  state: MapEditorToolsState,
  pageType: MapEditorToolType
) => ({
  leftDrawerWidth: state?.[pageType]?.leftDrawerWidth,
})

const getPageRightDrawerWidth = (
  state: MapEditorToolsState,
  pageType: MapEditorToolType
) => ({
  rightDrawerWidth: state?.[pageType]?.rightDrawerWidth,
})

export const selectDrawerWidth = createSelector(
  [
    (state: RootStore) => state.mapEditorTools,
    (_: RootStore, pageType: MapEditorToolType) => pageType,
  ],
  (toolPages, pageType) => {
    return {
      ...getPageLeftDrawerWidth(toolPages, pageType),
      ...getPageRightDrawerWidth(toolPages, pageType),
    }
  }
)
