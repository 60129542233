import * as React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  List,
  ListItem,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'

import { setHelpDialogOpen } from '../app/actions'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />
}

const help_links = {
  productGuide: {
    en: 'https://vineview.com/product-guide/',
    fr: 'https://vineview.fr/catalogue-produits/',
  },
  appGuide: {
    en: 'https://vineview.com/app-user-guide/',
    fr: 'https://vineview.fr/guide-dutilisation-de-lapplication/',
  },
  faq: {
    en: 'https://vineview.com/faq/',
    fr: 'https://vineview.fr/faq-4/',
  },
  contactUs: {
    en: 'https://vineview.com/connect/',
    fr: 'https://vineview.fr/reseau/',
  },
}

export const HelpDialog = () => {
  const [state, dispatch] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)
  const isHelpDialogOpen = state.app.isHelpDialogOpen

  return (
    <Dialog
      open={isHelpDialogOpen}
      onClose={() => dispatch(setHelpDialogOpen(false))}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{i18n.t(keys.generic.help)}</DialogTitle>
      <DialogContent>
        <List>
          {Object.keys(help_links).map((key) => (
            <ListItemLink
              key={key}
              href={help_links[key][preferredLanguage]}
              target="_blank"
            >
              <ListItemText>{i18n.t(key)}</ListItemText>
              <ListItemSecondaryAction>
                <Icon>open_in_new</Icon>
              </ListItemSecondaryAction>
            </ListItemLink>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(setHelpDialogOpen(false))}>
          {i18n.t(keys.generic.close)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
