import { parseCSSColor } from 'csscolorparser'

export default function getColorFromGradient(
  percentage: number,
  // TODO
  stops: any[]
): string {
  const index = lastIndexLETInput(
    stops.map(([value]) => value),
    percentage
  )

  if (index === -1) {
    return stops[0][1]
  }

  if (index === stops.length - 1) {
    return stops[stops.length - 1][1]
  }

  const [value1, color1] = stops[index]
  const [value2, color2] = stops[index + 1]

  const localPercentage = (percentage - value1) / (value2 - value1)

  return blend(color1, color2, localPercentage)
}

function blendRGBA(
  [r1 = 0, g1 = 0, b1 = 0, a1 = 1]: number[],
  [r2 = 0, g2 = 0, b2 = 0, a2 = 1]: number[],
  p: number = 0.5
) {
  // tslint:disable-next-line:no-parameter-reassignment
  p = isNaN(p) ? 1 : p

  return [
    Math.round(r1 + (r2 - r1) * p),
    Math.round(g1 + (g2 - g1) * p),
    Math.round(b1 + (b2 - b1) * p),
    a1 + (a2 - a1) * p,
  ]
}

function blend(color1: string, color2: string, by?: number) {
  return `rgba(${blendRGBA(
    parseCSSColor(color1),
    parseCSSColor(color2),
    by
  ).join(', ')})`
}

function lastIndexLETInput(
  stops: number[],
  input: number,
  lowerIndex = 0,
  upperIndex = stops.length - 1
): number {
  const lastIndex = stops.length - 1
  const currentIndex = Math.floor((lowerIndex + upperIndex) / 2)
  const currentValue = stops[currentIndex]
  const nextValue = stops[currentIndex + 1]

  if (input < stops[0]) {
    return -1
  }

  if (input > stops[lastIndex]) {
    return lastIndex
  }

  if (currentValue <= input) {
    if (currentIndex === lastIndex || input < nextValue) {
      return currentIndex
    }

    return lastIndexLETInput(
      stops,
      input,
      currentIndex + 1, // lowerIndex
      upperIndex
    )
  }

  return lastIndexLETInput(
    stops,
    input,
    lowerIndex,
    currentIndex - 1 // upperIndex
  )
}
