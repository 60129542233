import { gql, query } from '../../../graphql/client'
import { DetailedDeliveryStatus } from './selectGetDetailedDeliveryStatus'

export const STATUS_COUNTS: DetailedDeliveryStatus[] = [
  'expectedCount',
  'readyCount',
  'extraCount',
  'fulfilledCount',
  'missingCount',
  'enabledCount',
  'totalCount',
]

/** set layers enabled fields to true or false */
export const setLayersEnabled = async ({
  value,
  enabled,
}: {
  value: string
  enabled: boolean
}) => {
  await query({
    variables: {
      enabled,
      deliveryId: value,
    },
    query: gql`
      mutation SetLayersEnabled($deliveryId: uuid!, $enabled: Boolean!) {
        update_MapLayer(
          where: { MapSource: { deliveryId: { _eq: $deliveryId } } }
          _set: { enabled: $enabled }
        ) {
          affected_rows
        }
      }
    `,
  })
}

export const setSelectedProcGroupLayersEnabled = async ({
  deliveryId,
  procGroup,
  selectedLayerDefs,
  enabled,
}: {
  deliveryId: string
  procGroup: string
  selectedLayerDefs: string[]
  enabled: boolean
}) => {
  await query({
    query: gql`
      mutation SetProcGroupLayersEnabled(
        $deliveryId: uuid!
        $procGroup: String!
        $selectedLayerDefs: [uuid!]!
        $enabled: Boolean!
      ) {
        update_MapLayer(
          where: {
            MapSource: {
              deliveryId: { _eq: $deliveryId }
              DeliveryParcel: { meta: { _contains: { procGroup: $procGroup } } }
            }
            mapLayerDefId: { _in: $selectedLayerDefs }
          }
          _set: { enabled: $enabled }
        ) {
          affected_rows
        }
      }
    `,
    variables: {
      enabled,
      deliveryId,
      procGroup,
      selectedLayerDefs,
    },
  })
}

export const enableSelectedLayers = async (
  deliveryId: string,
  selectedLayers: string[]
) => {
  await query({
    variables: {
      selectedLayers,
      deliveryId,
    },
    query: gql`
      mutation SetLayersEnabled($deliveryId: uuid!, $selectedLayers: [uuid!]!) {
        disable: update_MapLayer(
          where: { MapSource: { deliveryId: { _eq: $deliveryId } } }
          _set: { enabled: false }
        ) {
          affected_rows
        }
        enable: update_MapLayer(
          where: { id: { _in: $selectedLayers } }
          _set: { enabled: true }
        ) {
          affected_rows
        }
      }
    `,
  })
}

export const enableFulfilledLayers = async (deliveryId: string) => {
  await query({
    variables: {
      deliveryId,
    },
    query: gql`
      mutation SetFulfilledLayersEnabled($deliveryId: uuid!) {
        disable: update_MapLayer(
          where: { MapSource: { deliveryId: { _eq: $deliveryId } } }
          _set: { enabled: false }
        ) {
          affected_rows
        }
        enable: update_MapLayer(
          where: {
            MapSource: { deliveryId: { _eq: $deliveryId } }
            DeliveryParcelStatus: { fulfilled: { _eq: true } }
          }
          _set: { enabled: true }
        ) {
          affected_rows
        }
      }
    `,
  })
}
