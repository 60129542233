import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import errorAlert from '../../../admin/errorAlert'
import { RouteParams, url, urls } from '../../../appNavigation/urls'
import { selectOrganization } from '../../../data/selectOrganization'
import { selectOrganizationSubscriptions } from '../../../data/selectOrganizationSubscriptions'
import * as api from '../../../graphql/api'
import { Model, Order_create_input } from '../../../graphql/types'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { OrderPriority } from './constants'
import OrderForm from './OrderForm'
import { refreshListOrders } from './selectListOrders'
import OrderPage from './OrderPage'

class NewOrder extends React.PureComponent<
  RouteComponentProps<RouteParams> & ReduxProps
> {
  render() {
    const { subscriptions } = this.props

    return (
      <OrderPage
        title={`New Order`}
        backTo={url(urls.listOrders)}
        backToTitle="Orders"
      >
        <div id="NewOrder" className="MapDataContainerSmall">
          <OrderForm
            type="new"
            subscriptions={subscriptions}
            onSave={this.handleCreate}
            onCancel={this.goHome}
          />
        </div>
      </OrderPage>
    )
  }

  handleCreate = async (formData: Order_create_input) => {
    const {
      comment,
      priority = OrderPriority.Normal,
      quoteId,
      invoiceId,
      purchaseOrderId,
    } = formData
    try {
      const req = await api.order.create<Model>({
        input: {
          comment,
          priority,
          organizationId: this.props.organization!.id,
          quoteId,
          invoiceId,
          purchaseOrderId,
        },
      })

      refreshListOrders()
      this.props.history.replace(url(urls.editOrder, { orderId: req.data!.id }))
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Delivery', message, this.state)
    }
  }

  goHome = () => {
    this.props.history.push(url(urls.listOrders))
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'NewOrder',
    },
  })

const mapState = (state: RootStore) => ({
  organization: selectOrganization(state),
  subscriptions: selectOrganizationSubscriptions(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(NewOrder)
)
