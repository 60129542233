import * as React from 'react'
import { useFeatureFlagsContext } from '../feature-flags/FeatureFlagContext'
import { useRedux } from './useRedux'
import { selectMe } from '../data/selectMe'
import { selectOrganizationId } from '../data/selectOrganizationId'

interface Inputs {
  featureFlagId: string
}

interface Outputs {
  featureEnabled: boolean
}

export const useFeatureFlag = ({ featureFlagId }: Inputs): Outputs => {
  const [state] = useRedux()
  const me = selectMe(state)
  const organizationId = selectOrganizationId(state)
  const [context] = useFeatureFlagsContext()

  const featureFlag = React.useMemo(() => {
    return context?.result?.[featureFlagId]
  }, [featureFlagId, context?.result])

  if (!featureFlag || !me?.id || !organizationId) {
    return { featureEnabled: false }
  }

  // if the flag is unactive, feature is disabled.
  if (!featureFlag.active) {
    return { featureEnabled: false }
  }

  const userFlags = featureFlag.FeatureFlagUserAccesses
  const organizationFlags = featureFlag.FeatureFlagOrganizationAccesses

  const flagsForMe = userFlags?.find(
    (ua) => ua.userId.toString() === me?.id.toString()
  )
  const flagsForOrg = organizationFlags?.find(
    (oa) => oa.organizationId.toString() === organizationId.toString()
  )

  // if the flag is active, check if the user has the correct level of access.
  return {
    featureEnabled:
      (!!flagsForMe && !!flagsForOrg) || // has access for both user and org.
      (!!flagsForMe && organizationFlags?.length === 0) || // ...or has access for user and no access needed for org.
      (!!flagsForOrg && userFlags?.length === 0) || // ...or has access for org and no access needed for user.
      (organizationFlags?.length === 0 && userFlags?.length === 0), // ...or flag requires no specific user or org access.
  }
}