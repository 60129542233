import { default as TextField } from '@mui/material/TextField'
import { default as Typography } from '@mui/material/Typography'
import DatePickerYMD from '../../../admin/mapdata/utils/DatePickerYMD'
import { Form } from '../../../admin/UI/Form'
import * as React from 'react'
import i18n from '../../../i18n'
import { FlightData } from './selectGetFlight'

export default class FlightForm extends Form<FlightData> {
  render() {
    const {
      CreatedBy,
      UpdatedBy,
      createdAt,
      updatedAt,
      date,
      comment = '',
    } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <Typography variant="subtitle1">Flight Details</Typography>
          {CreatedBy && UpdatedBy && (
            <div className="grid-xs-12">
              <Typography variant="subtitle1">
                Created By: {CreatedBy.firstName} -{' '}
                {i18n.toDateShort(createdAt!)}
              </Typography>
              <Typography variant="subtitle1">
                Updated By: {UpdatedBy.firstName} -{' '}
                {i18n.toDateTimeShort(updatedAt!)}
              </Typography>
            </div>
          )}
        </div>
        <div className="grid-xs-12">
          <DatePickerYMD
            required
            disableFuture
            label={`Flight Date`}
            name="date"
            value={date}
            onChange={(nameAndValue) =>
              this.updateFormDataFromNameAndValue({
                ...nameAndValue,
                value: nameAndValue.value,
              })
            }
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="comment"
            label={`Comment`}
            multiline
            fullWidth
            value={comment ?? ''}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!comment,
            }}
          />
        </div>
        <div className="actions grid-xs-12">{this.renderFormButtons()}</div>
      </div>
    )
  }
}
