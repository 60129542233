import { createDeepEqualitySelector } from './createDeepEqualitySelector'
import { RateMapContextState } from '../editor/RateMapContext'

const getRateMap = (state: RateMapContextState) => state.rateMap

export const selectRateMapWithoutZoneRates = createDeepEqualitySelector(
  getRateMap,
  (rateMap) => {
    if (!rateMap) {
      return
    }

    const {
      amendmentZoneRates,
      amendmentTypeId,
      unitCost,
      unitTypeId,
      ...restRateMap
    } = rateMap

    return restRateMap
  }
)
