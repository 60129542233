import * as React from 'react'
import { PureComponent } from 'react'
import AsyncSelectorStatusOverlay from '../AsyncSelector/AsyncSelectorStatusOverlay'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { selectMapLayerDefsById } from '../data/selectListMapSourceDefs'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { selectSelectedLegendProductId } from '../data/selectSelectedLegendProductId'
import { MapLayerDefData } from '../data/types'
import { updateProductSettings } from '../ProductSettings/store/redux'
import { selectActiveProductSettings } from '../ProductSettings/store/selectors/selectActiveProductSettings'
import { selectProductStats } from '../ProductStats/selectors/selectProductStats'
import {
  selectBestUnitsByProductId,
  selectCalculateStats,
  selectConvertedSelectedColorProfiles,
} from '../ProductStats/selectors/stats'
import { toStopData } from '../ProductStats/toStopData'
import { SelectedStopData, StopData } from '../ProductStats/types'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import { Legend } from '../stats/UI/Legend'

interface Props {
  productType?: MapLayerDefData | null
}

/**
 * The color palette in the map legend
 */
class ColorLegend extends PureComponent<
  Props & AppDispatchProps & ReduxProps & WithStyles<typeof styles>
> {
  render() {
    const {
      classes,
      colorProfile,
      productDef,
      settings,
      preferredLanguage: lang,
      unit,
      preferredUnitSystem,
      calculateStats,
    } = this.props
    const empty = (
      <AsyncSelectorStatusOverlay requests={calculateStats}>
        <div className={classes.root} />
      </AsyncSelectorStatusOverlay>
    )

    if (!colorProfile || !productDef) {
      return empty
    }

    const { stops, minLabel, maxLabel } = toStopData({
      lang,
      unit,
      preferredUnitSystem,
      colorProfile: colorProfile as any,
      layerDef: productDef,
    })

    const isRaster = false

    return (
      <AsyncSelectorStatusOverlay requests={calculateStats}>
        <div className={classes.root}>
          <Legend
            layerDef={productDef}
            stops={stops}
            minLabel={minLabel}
            maxLabel={maxLabel}
            selected={settings.isolatedStops}
            orientation="horizontal"
            interactive={!isRaster}
            onClickItem={isRaster ? undefined : this.handleIsolatedStops}
          />
        </div>
      </AsyncSelectorStatusOverlay>
    )
  }

  handleIsolatedStops = (item: StopData, event: React.MouseEvent) => {
    const { dispatch, settings, productId } = this.props
    const isMultiSelect = event.metaKey || event.ctrlKey

    const isolatedStops = updateIsolatedStops(
      settings.isolatedStops,
      item.key,
      isMultiSelect
    )

    dispatch(updateProductSettings({ productId, settings: { isolatedStops } }))
  }
}

const updateIsolatedStops = (
  isolatedIndexArray: SelectedStopData[] = [],
  selectedIndex: SelectedStopData,
  isMultiSelect = false
) => {
  const clonedIsolatedStops = new Set(isolatedIndexArray)

  if (isMultiSelect) {
    if (clonedIsolatedStops.has(selectedIndex)) {
      clonedIsolatedStops.delete(selectedIndex)
    } else {
      clonedIsolatedStops.add(selectedIndex)
    }

    return Array.from(clonedIsolatedStops)
  }

  if (clonedIsolatedStops.has(selectedIndex)) {
    return []
  }

  return [selectedIndex]
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 60,
      width: '100%',
      display: 'flex',
      position: 'relative',
      color: theme.palette.text.primary,
      userSelect: 'none',
    },
  })

const mapState = (state: RootStore) => {
  const settings = selectActiveProductSettings(state)
  const productId = selectSelectedLegendProductId(state)!
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId]

  return {
    settings,
    productId,
    colorProfile:
      selectedColorProfiles &&
      (selectedColorProfiles[settings.visualization!] ||
        selectedColorProfiles.absolute ||
        selectedColorProfiles.relative ||
        selectedColorProfiles.threshold),
    productStats: selectProductStats(state)[productId],
    productDef: selectMapLayerDefsById(state)[productId],
    preferredLanguage: selectPreferredLanguage(state),
    unit: selectBestUnitsByProductId(state)[productId],
    preferredUnitSystem: state.preferences.preferredUnitSystem,
    calculateStats: selectCalculateStats(state),
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, Props, AppDispatchProps>(mapState)(
  withStyles(styles)(ColorLegend)
)
