import { selectPagingInfo } from '../../../appNavigation/urls'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { Delivery } from '../../../graphql/types'
import { RootStore } from '../../../redux/types'

export interface ListTargetDeliveriesData {
  orderId: string
  date: string
  inhibitFlightNotice: boolean
  delivery?: {
    id: Delivery['id']
    // deliveryStatus: {
    //   totalCount: number
    //   enabledCount: number
    //   fulfilledCount: number
    //   enabled: boolean
    //   fulfilled: boolean
    // }
  }

  createdAt: string
  updatedAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }
}

const list = ({
  limit,
  offset,
  orderId,
}: {
  limit?: number
  offset?: number
  orderId: string
}) =>
  api.targetDelivery.list<ListTargetDeliveriesData>({
    limit,
    offset,
    returning: `{
      orderId
      date
      createdAt
      updatedAt

      delivery: Delivery {
        id
        # deliveryStatus: View_DeliveryStatus {
        #   totalCount
        #   enabledCount
        #   fulfilledCount
        #   enabled
        #   fulfilled
        # }
      }

      CreatedBy {
        firstName
      }
      UpdatedBy {
        firstName
      }
    }`,
    where: {
      orderId: {
        _eq: orderId,
      },
    },
  })

const {
  selector: selectListTargetDeliveries,
  refresh: refreshListTargetDeliveries,
} = createAsyncSelector({
  resource: 'ListTargetDeliveriesData',
  inputs: {
    orderId: (state: RootStore) => state.router.params.orderId,
    pagingInfo: selectPagingInfo,
  },
  fetcher: async ({ orderId, pagingInfo: { page, pageSize } }) => {
    if (!orderId) {
      return
    }

    const offset = page * pageSize
    const limit = pageSize

    const { data, info } = await list({ orderId, offset, limit })

    if (!data) {
      throw new Error('No Data')
    }

    return {
      data,
      info: {
        page,
        pageSize,
        count: info?.count ?? 0,
      },
    }
  },
})

export { selectListTargetDeliveries, refreshListTargetDeliveries }
