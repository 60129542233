import { createSelector } from 'reselect'
import { indexArray } from '../util/indexArray'
import {
  OrganizationData,
  selectOrganizationList,
} from './selectOrganizationList'

const DEFAULT_ARR: OrganizationData[] = []

export const selectOrganizations = createSelector(
  [selectOrganizationList],
  ({ data }) => data ?? DEFAULT_ARR
)

export const selectOrganizationsById = createSelector(
  selectOrganizations,
  (organizations) => indexArray(organizations, 'id')
)
