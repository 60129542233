import { createSelector } from 'reselect'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { RootStore } from '../../../redux/types'
import { indexArrayMappedKey } from '../../../util/indexArray'
import { ListDeliveryParcelFileStatus } from '../Delivery/DeliveryParcelFileStatus/types'

const list = ({
  limit,
  offset,
  order_by,
  where,
}: {
  limit?: number
  offset?: number
  order_by?: string
  where?: any
}) =>
  api.deliveryParcelFile.list<ListDeliveryParcelFileStatus>({
    limit,
    offset,
    order_by,
    where,
    returning: `{
      deliveryId
      parcelId
      filename
      flightDate
      status
      uploadedAt
      statusUpdatedAt

      Organization {
        name
      }

      Delivery {
        id
      }

      DeliveryParcel {
        name

        DeliveryGroup {
          name
        }
      }

      Flight {
        date
      }

      File {
        checksum
      }
      
      Job {
        id
        isPending
        LatestJobAttempt {
          status
        }
      }

      CreatedBy {
        firstName
      }

      UpdatedBy {
        firstName
      }
    }`,
  })

const {
  selector: selectListDeliveryParcelFileStatusForFlight,
  refresh: refreshListDeliveryParcelFileStatusForFlight,
} = createAsyncSelector({
  resource: 'ListDeliveryParcelFileStatusForFlight',
  inputs: {
    deliveryId: (state: RootStore) => state.router.params.deliveryId,
    flightDate: (state: RootStore) => state.router.params.flightDate,
  },
  fetcher: async ({ deliveryId, flightDate }) => {
    if (!deliveryId || !flightDate) {
      return { data: [] }
    }
    const { data } = await list({
      where: {
        deliveryId: { _eq: deliveryId },
        flightDate: { _eq: flightDate },
      },
    })

    if (!data) {
      throw new Error('No Data')
    }

    return {
      data,
    }
  },
})

export {
  selectListDeliveryParcelFileStatusForFlight,
  refreshListDeliveryParcelFileStatusForFlight,
}

export const selectFlightDeliveryParcelFilesByKey = createSelector(
  selectListDeliveryParcelFileStatusForFlight,
  (deliveryParcelFileStatusForFlight) => {
    if (!deliveryParcelFileStatusForFlight.data) {
      return {}
    }

    return indexArrayMappedKey(
      deliveryParcelFileStatusForFlight.data.data,
      (dpf) => `${dpf.deliveryId}_${dpf.parcelId}_${dpf.filename}`
    )
  }
)
