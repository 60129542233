import { Typography } from '@mui/material'
import * as React from 'react'
import i18n from '../../i18n'
import TranslationTextField from '../../UI/TranslationTextField/TranslationTextField'
import { Form } from '../UI/Form'
import { GetMapLayerDefGroupData } from './selectGetMapLayerDefGroup'

class MapLayerDefGroupsForm extends Form<GetMapLayerDefGroupData> {
  render() {
    const {
      CreatedBy,
      UpdatedBy,
      createdAt,
      updatedAt,
      name = {},
    } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <h2>{this.props.type === 'edit' ? 'Edit' : 'New'} MapLayerDefGroup</h2>
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            required
            name="name"
            label={`Name`}
            value={name}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        {CreatedBy && UpdatedBy && (
          <div className="grid-xs-12">
            <Typography variant="subtitle1">
              Created By: {CreatedBy.firstName} - {i18n.toDateShort(createdAt!)}
            </Typography>
            <Typography variant="subtitle1">
              Updated By: {UpdatedBy.firstName} -{' '}
              {i18n.toDateTimeShort(updatedAt!)}
            </Typography>
          </div>
        )}
        <div className="actions grid-xs-12">{this.renderFormButtons()}</div>
      </div>
    )
  }
}

export default MapLayerDefGroupsForm
