import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { Order, Organization } from '../../../graphql/types'
import { RootStore } from '../../../redux/types'

export interface GetTargetDeliveryData {
  orderId: Order['id']
  date: string

  order: {
    organizationId: Organization['id']
  }

  createdAt: string
  updatedAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }
}

const get = ({
  orderId,
  targetDeliveryDate,
}: {
  orderId: string
  targetDeliveryDate: string
}) =>
  api.targetDelivery.get<GetTargetDeliveryData>({
    pk: { orderId, date: targetDeliveryDate },
    returning: `{
      orderId
      date
      createdAt
      updatedAt

      order: Order {
        organizationId
      }

      CreatedBy {
        firstName
      }
      UpdatedBy {
        firstName
      }
    }`,
  })

const { selector: selectGetTargetDelivery, refresh: refreshGetTargetDelivery } =
  createAsyncSelector({
    resource: 'GetTargetDeliveryData',
    inputs: {
      orderId: (state: RootStore) => state.router.params.orderId,
      targetDeliveryDate: (state: RootStore) =>
        state.router.params.targetDeliveryDate,
    },
    fetcher: async ({ orderId, targetDeliveryDate }) => {
      if (!orderId || !targetDeliveryDate) {
        return
      }

      const { data } = await get({ orderId, targetDeliveryDate })

      if (!data) {
        throw new Error('No Data')
      }

      return data
    },
  })

export { selectGetTargetDelivery, refreshGetTargetDelivery }
