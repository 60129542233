import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Form, FormRenderProps } from 'react-final-form'

import { default as swal } from 'sweetalert'
import { Column } from '../admin/UI/Column'
import { Row } from '../admin/UI/Row'
import Page from '../app/Page'
import { RouteParams, url, urls } from '../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../AsyncSelector/AsyncSelectorStatusOverlay'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import { passwordAdornment } from '../register/adornments'
import { PasswordStrength } from '../register/PasswordStrength'
import {
  passwordLength,
  passwordsMatch,
  required,
  validEmail,
} from '../register/validators'
import { RFTextField } from '../UI/reduxForm/muiComponents'
import { reduxFormSubmissionFactory } from '../UI/reduxForm/reduxFormSubmissionFactory'
import { RequestError } from '../util/request'
import { login } from '../vvapi/login'
import { selectGetUserInvite, selectUserInvite } from './selectInvitation'
import { composeValidators } from '../UI/reduxForm/utils'

// const FORM_NAME = 'Register'

interface State {
  showPassword: boolean
  showConfirmPasseord: boolean
}

type Props = RouteComponentProps<RouteParams> & ReduxProps & AppDispatchProps

class Register extends React.Component<Props, State> {
  state: State = { showConfirmPasseord: false, showPassword: false }

  render() {
    const { userInviteSelector } = this.props

    return (
      <Page title={i18n.t(keys.signup)}>
        <Column
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Dialog open fullWidth hideBackdrop>
            <AsyncSelectorStatusOverlay
              requests={userInviteSelector}
              hideChildrenWhenLoading
            >
              {this.renderContent()}
            </AsyncSelectorStatusOverlay>
          </Dialog>
        </Column>
      </Page>
    )
  }

  renderContent = () => {
    const { isLoggedIn, isLoginInProgress, userInvite } = this.props

    if (isLoginInProgress) {
      return
    }

    if (isLoggedIn) {
      return
    }

    if (!userInvite) {
      return
    }

    if (typeof userInvite === 'string') {
      return this.renderMissingOrAccepted()
    }

    return this.renderRegistrationForm()
  }

  renderMissingOrAccepted = () => (
    <Row
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" style={{ textAlign: 'center', marginTop: 8 }}>
        {i18n.t(keys.inviteInvalid)}
      </Typography>
    </Row>
  )

  renderRegistrationForm = () => {
    return (
      <Form
        onSubmit={reduxFormSubmissionFactory(
          login.actions.register,
          this.props.dispatch
        )}
        initialValues={{
          token: this.props.match.params.token,
          email:
            typeof this.props.userInvite === 'object'
              ? this.props.userInvite.email
              : undefined,
        }}
      >
        {({ handleSubmit, submitting, error, invalid, values }) => (
          <form>
            <DialogTitle>{i18n.t(keys.signup)}</DialogTitle>
            <DialogContent>
              <RFTextField
                disabled
                required
                label={i18n.t(keys.user.email)}
                name="email"
                type="email"
                fullWidth
                validate={composeValidators(required, validEmail)}
                margin="normal"
              />
              <RFTextField
                required
                name="firstName"
                label={i18n.t(keys.user.firstName)}
                fullWidth
                margin="normal"
                validate={required}
                autoFocus
              />
              <RFTextField
                required
                name="lastName"
                label={i18n.t(keys.user.lastName)}
                fullWidth
                validate={required}
                margin="normal"
              />
              <RFTextField
                required
                label={i18n.t(keys.user.password)}
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                fullWidth
                validate={composeValidators(required, passwordLength)}
                margin="normal"
                InputProps={{
                  endAdornment: passwordAdornment(
                    this.state.showPassword,
                    this.handleClickShowPassword
                  ),
                }}
              />
              <RFTextField
                required
                label={i18n.t(keys.user.confirmPassword)}
                name="confirmPassword"
                type={this.state.showConfirmPasseord ? 'text' : 'password'}
                fullWidth
                validate={composeValidators(required, passwordsMatch)}
                InputProps={{
                  endAdornment: passwordAdornment(
                    this.state.showConfirmPasseord,
                    this.handleClickShowConfirmPassword
                  ),
                }}
              />
              <PasswordStrength password={values.password} />
              {!!this.props.error && (
                <Typography color="error">
                  {i18n.t(this.props.error, { defaultValue: i18n.t(keys.signupError) })}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={submitting}
                onClick={this.handleCancel}
              >
                {i18n.t(keys.generic.cancel)}
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || (invalid && !error)}
                onClick={(ev) => this.handleRegister(ev, handleSubmit)}
              >
                {i18n.t(keys.signup)}
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    )
  }

  handleClickShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword })
  handleClickShowConfirmPassword = () =>
    this.setState({ showConfirmPasseord: !this.state.showConfirmPasseord })

  handleCancel = async (event?: React.SyntheticEvent<HTMLElement>) => {
    const { token } = this.props
    if (!token) {
      return
    }

    if (event) {
      event.preventDefault()
    }

    this.props.history.replace(url(urls.invitation, { token }))
  }

  handleRegister = async (
    event: React.SyntheticEvent<HTMLElement>,
    handleSubmit: FormRenderProps['handleSubmit']
  ) => {
    if (event) {
      event.preventDefault()
    }

    const { token, userInvite } = this.props
    if (!token || !userInvite) {
      return
    }
    try {
      // reduxFormSubmissionFactory conditionally throws errors
      const err = await handleSubmit(undefined as any)
      if (!err) {
        // clear data on successful login so we're not storing password in the DOM for entire session
        this.props.history.replace(url(urls.mapView))
      }
    } catch (error) {
      if (error instanceof RequestError) {
        if (error.response.status === 409) {
          return await swal({ text: i18n.t(keys.emailInUse) })
        }
        if (error.response.status === 403) {
          return await swal({
            text: i18n.t(keys.inviteInvalid),
          })
        }
      }
      // tslint:disable-next-line: no-console
      console.warn('Error registering account:', error)

      throw error
    }
  }
}

// const selectFormValues = formValueSelector(FORM_NAME)

const mapState = (state: RootStore) => ({
  error: state.login.error,
  isLoginInProgress: state.login.isInProgress,
  isLoggedIn: state.login.isLoggedIn,
  token: state.router.params.token,
  userInviteSelector: selectGetUserInvite(state),
  userInvite: selectUserInvite(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(Register)
