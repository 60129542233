// tslint:disable: no-console
import * as Sentry from '@sentry/browser'

/** simulate Sentry experience in the console */
const fakeSentryCapture = (e?: Error | string) => {
  Sentry.withScope((scope) => {
    // need to access private variables
    const {
      _tags: tags = {},
      _breadcrumbs: breadcrumbs = [],
      _extra: extra = {},
    } = scope as any

    if (Object.keys(tags).length > 0) {
      console.warn('tags', tags)
    }
    if (breadcrumbs.length > 0) {
      console.groupCollapsed('breadcrumbs')
      breadcrumbs.forEach((breadcrumb: Record<string, any>) =>
        console.warn(breadcrumb)
      )
      console.groupEnd()
    }
    if (Object.keys(extra).length > 0) {
      console.warn('extra', extra)
    }
  })

  if (e) {
    console.error(e)
  }
}

/** automatically handle string messages and errors, with Sentry */
const sentryCapture = (e?: Error | string) => {
  console.error('sentryCapture:', e)
  if (typeof e === 'string') {
    Sentry.captureMessage(e, Sentry.Severity.Error)
  } else {
    Sentry.captureException(e)
  }
}

/** conditionally show errors in sentry (production) or console */
let handleError: (e?: Error | string | undefined) => void

if (process.env.NODE_ENV === 'test') {
  // tslint:disable-next-line:no-empty
  handleError = (e?: string | Error | undefined) => {
    console.error('handleError:', e)
  }
} else {
  handleError = !!process.env.REACT_APP_SENTRY_DSN
    ? sentryCapture
    : fakeSentryCapture
}

export default handleError
