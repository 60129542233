import * as React from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  buttonClasses,
  ButtonGroup,
  buttonGroupClasses,
  Checkbox,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  styled,
  Tooltip,
} from '@mui/material'

import { Column } from '../../../../admin/UI/Column'
import warnConfirm from '../../../../admin/warnConfirm'
import {
  selectColorProfilesByProductId,
  selectMapLayerDefsById,
} from '../../../../data/selectListMapSourceDefs'
import { selectPreferredLanguage } from '../../../../data/selectPreferredLanguage'
import { GetDeliveryData } from '../../../../data/types'
import useAsync, { AsyncResult } from '../../../../hooks/useAsync'
import { useRedux } from '../../../../hooks/useRedux'
import CursorLocation from '../../../../map/controls/CursorLocation'
import ScaleControl from '../../../../map/controls/ScaleControl'
import ZoomControl from '../../../../map/controls/ZoomControl'
import { makeTileLayers, makeTileSource } from '../../../../map/layers'
import { Map } from '../../../../map/Map'
import { MapboxGL } from '../../../../map/MapboxGL'
//  '../../../map/styles/mapbox-satellite.json'
import mapboxSatelliteJson from '../../../../map/styles/mapbox-satellite.json'
import { selectProductSettings } from '../../../../ProductSettings/store/selectors/selectProductSettings'
import { setSelectedProcGroupLayersEnabled } from '../enableLayers'
import { MapContextProvider } from '../../../../map/withMap'
import { LayerVisibilityToggle } from '../../../../map/controls/LayerVisibilityToggle'
import { ParcelsAndBounds } from '../fetchParcelsAndBounds'
import {
  addFieldBounds,
  addFieldLabels,
  FIELD_BOUNDS_COLOR_SELECTED,
} from '../../../../postgis/selectors/FieldsLayerSelector'
import { client, gql } from '../../../../graphql/client'

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [`&.${buttonGroupClasses.root}`]: {
    [`& .${buttonClasses.root}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,
      minWidth: 30,
      minHeight: 30,
      width: 30,
      height: 30,
    },
  },
}))

const fitBoundsOptions = {
  padding: 30,
  duration: 0,
}

interface SourcesAndLayers {
  sources: Record<string, MapboxGL.Source>
  layers: MapboxGL.Layer[]
}

interface Props {
  mapViewExpanded: boolean
  parcelsAndBoundsFetcher: AsyncResult<ParcelsAndBounds>[0]
  setMapViewExpanded: (mapViewExpanded: boolean) => void
  onRefresh: () => void
}

export const DeliveryProcGroupMapQA = ({
  mapViewExpanded,
  setMapViewExpanded,
  parcelsAndBoundsFetcher,
  onRefresh,
}: Props) => {
  const { deliveryId, procGroup } = useParams<{
    deliveryId: string
    procGroup: string
  }>()
  const [state] = useRedux()
  const [defaultQALayers] = useAsync(async () => {
    const result = await client.request<{
      defaultQALayerId: { value: string }
      defaultQaBackgroundLayerId: { value: string }
    }>({
      query: gql`
        query {
          defaultQALayerId: vv_Variables_by_pk(key: "DEFAULT_QA_LAYER_ID") {
            value
          }
          defaultQaBackgroundLayerId: vv_Variables_by_pk(
            key: "DEFAULT_QA_BACKGROUND_LAYER_ID"
          ) {
            value
          }
        }
      `,
    })

    return result
  }, [])
  const [selectedLayer, setSelectedLayer] = React.useState('')
  const [selectedBackgroundLayer, setSelectedBackgroundLayer] =
    React.useState('')
  const [productLayerVisible, setProductLayerVisible] = React.useState(true)
  const [activeProductLayers, setActiveProductLayers] = React.useState<
    string[]
  >([])
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<
    undefined | HTMLElement
  >(undefined)
  const menuOpen = Boolean(menuAnchorEl)
  const preferredLanguage = selectPreferredLanguage(state)

  const maplayersDefs = selectMapLayerDefsById(state)
  const colorProfiles = selectColorProfilesByProductId(state)
  const productSettings = selectProductSettings(state)
  const [productSourcesAndLayers, setProductSourcesAndLayers] =
    React.useState<SourcesAndLayers>({ sources: {}, layers: [] })
  const [backgroundSourcesAndLayers, setBackgroundSourcesAndLayers] =
    React.useState<SourcesAndLayers>({ sources: {}, layers: [] })
  const [fieldBoundsSourcesAndLayers, setFieldBoundsSourcesAndLayers] =
    React.useState<SourcesAndLayers>({ sources: {}, layers: [] })

  React.useEffect(() => {
    const newSourcesAndLayers: SourcesAndLayers = { sources: {}, layers: [] }

    const parcelsAndBounds = parcelsAndBoundsFetcher.result
    if (!parcelsAndBounds || !selectedLayer || !maplayersDefs[selectedLayer]) {
      setProductSourcesAndLayers(newSourcesAndLayers)

      return
    }

    parcelsAndBounds.parcels?.forEach((parcel) => {
      parcel.mapSources.forEach((mapSource) => {
        const tileSource = makeTileSource(mapSource)
        mapSource.mapLayers.forEach((mapLayer) => {
          if (mapLayer.mapLayerDef.id === selectedLayer) {
            newSourcesAndLayers.sources[tileSource.id] = tileSource

            const product = maplayersDefs[selectedLayer]
            const settings = productSettings[product.id] ?? {}
            const colorId =
              productSettings?.[product.id]?.colorIdByVisualization?.[
                settings.visualization!
              ]
            const color = colorProfiles[product.id]?.find((p) => {
              if (colorId) {
                return p.id === colorId
              }

              return p.visualizations.some(
                (v) => v.visualization === settings.visualization && v.default
              )
            })

            const tileLayer = makeTileLayers({
              layer: mapLayer,
              product,
              settings: productLayerVisible
                ? settings
                : { ...settings, opacity: 0 },
              color,
            })

            if (tileLayer) {
              newSourcesAndLayers.layers.push(tileLayer)
            }
          }
        })
      })
    })

    setProductSourcesAndLayers(newSourcesAndLayers)
  }, [
    parcelsAndBoundsFetcher.result,
    selectedLayer,
    maplayersDefs,
    colorProfiles,
    productSettings,
  ])

  React.useEffect(() => {
    const newBackgroundSourcesAndLayers: {
      sources: Record<string, MapboxGL.Source>
      layers: MapboxGL.Layer[]
    } = { sources: {}, layers: [] }
    const parcelsAndBounds = parcelsAndBoundsFetcher.result
    // const mapLayerDefsById = styleAndBounds?.result?.mapLayerDefsById ?? {}
    if (
      !parcelsAndBounds ||
      !selectedBackgroundLayer ||
      !maplayersDefs[selectedBackgroundLayer]
    ) {
      setBackgroundSourcesAndLayers(newBackgroundSourcesAndLayers)

      return
    }

    parcelsAndBounds?.parcels?.forEach((parcel) => {
      parcel.mapSources.forEach((mapSource) => {
        const tileSource = makeTileSource(mapSource)
        mapSource.mapLayers.forEach((mapLayer) => {
          if (mapLayer.mapLayerDef.id === selectedBackgroundLayer) {
            newBackgroundSourcesAndLayers.sources[tileSource.id] = tileSource

            const product = maplayersDefs[selectedBackgroundLayer]
            const settings = productSettings[product.id] ?? {}
            const colorId =
              productSettings?.[product.id]?.colorIdByVisualization?.[
                settings.visualization!
              ]
            const color = colorProfiles[product.id]?.find((p) => {
              if (colorId) {
                return p.id === colorId
              }

              return p.visualizations.some(
                (v) => v.visualization === settings.visualization && v.default
              )
            })

            const tileLayer = makeTileLayers({
              layer: mapLayer,
              product,
              settings,
              color,
            })
            if (tileLayer) {
              newBackgroundSourcesAndLayers.layers.push(tileLayer)
            }
          }
        })
      })
    })

    setBackgroundSourcesAndLayers(newBackgroundSourcesAndLayers)
  }, [parcelsAndBoundsFetcher?.result, selectedBackgroundLayer, maplayersDefs])

  React.useEffect(() => {
    const newFieldBoundsSourcesAndLayers: {
      sources: Record<string, MapboxGL.Source>
      layers: MapboxGL.Layer[]
    } = { sources: {}, layers: [] }

    const parcelsAndBounds = parcelsAndBoundsFetcher.result
    if (!parcelsAndBounds?.parcels) {
      setFieldBoundsSourcesAndLayers(newFieldBoundsSourcesAndLayers)

      return
    }

    addFieldBounds(
      parcelsAndBounds?.parcels,
      {},
      'FIELD-BOUNDS-POLYGONS-SELECTED',
      FIELD_BOUNDS_COLOR_SELECTED,
      newFieldBoundsSourcesAndLayers
    )
    addFieldLabels(
      parcelsAndBounds?.parcels,
      {},
      newFieldBoundsSourcesAndLayers
    )

    setFieldBoundsSourcesAndLayers(newFieldBoundsSourcesAndLayers)
  }, [parcelsAndBoundsFetcher.result])

  React.useEffect(() => {
    setActiveProductLayers(
      productSourcesAndLayers?.layers?.map(({ id }) => id) ?? []
    )
  }, [productSourcesAndLayers])

  React.useEffect(() => {
    if (selectedLayer || selectedBackgroundLayer) {
      return
    }
    if (defaultQALayers.result) {
      setSelectedLayer(defaultQALayers.result?.defaultQALayerId?.value)
      setSelectedBackgroundLayer(
        defaultQALayers.result?.defaultQaBackgroundLayerId?.value
      )
    }
  }, [defaultQALayers.result])

  const [style, setStyle] = React.useState(mapboxSatelliteJson)

  React.useEffect(() => {
    const newStyle = { ...mapboxSatelliteJson } as MapboxGL.Style
    const settings = productSettings[selectedLayer] ?? {}
    productSourcesAndLayers.layers.forEach((layer) => {
      const opacity = productLayerVisible ? settings?.opacity ?? 1 : 0
      if (layer.paint) {
        switch (layer.type) {
          case 'circle':
            layer.paint['circle-opacity'] = opacity
            layer.paint['circle-stroke-opacity'] = opacity
            break
          case 'raster':
            layer.paint['raster-opacity'] = opacity
            break
          case 'fill':
            layer.paint['fill-opacity'] = opacity
            break
          case 'line':
            layer.paint['line-opacity'] = opacity
            break
        }
      }
    })

    newStyle.sources = {
      ...newStyle.sources,
      ...backgroundSourcesAndLayers.sources,
      ...productSourcesAndLayers.sources,
      ...fieldBoundsSourcesAndLayers.sources,
    } as MapboxGL.Style['sources']
    newStyle.layers = [
      ...newStyle.layers,
      ...backgroundSourcesAndLayers.layers,
      ...productSourcesAndLayers.layers,
      ...fieldBoundsSourcesAndLayers.layers,
    ] as MapboxGL.Style['layers']

    setStyle(newStyle)
  }, [
    backgroundSourcesAndLayers,
    productSourcesAndLayers,
    fieldBoundsSourcesAndLayers,
  ])

  const handleEnableLayers = async () => {
    const mapLayerDefIds = Object.keys({
      ...(parcelsAndBoundsFetcher.result?.productMapLayerDefsById ?? {}),
      ...(parcelsAndBoundsFetcher.result?.backgroundMapLayerDefsById ?? {}),
    })

    if (mapLayerDefIds.length === 0) {
      return
    }

    if (
      await warnConfirm({
        title: 'Enable Layers',
        message:
          'Are you sure you would like to enable the layers in this processing group?',
      })
    ) {
      await setSelectedProcGroupLayersEnabled({
        deliveryId,
        procGroup,
        selectedLayerDefs: mapLayerDefIds,
        enabled: true,
      })

      onRefresh()
    }
  }

  const handleDisableSelectedLayers = async () => {
    if (!selectedLayer) {
      return
    }
    if (
      await warnConfirm({
        title: 'Disable Layers',
        message:
          'Are you sure you would like to disable the selected layer in this processing group?',
      })
    ) {
      await setSelectedProcGroupLayersEnabled({
        deliveryId,
        procGroup,
        selectedLayerDefs: [selectedLayer],
        enabled: false,
      })

      onRefresh()
    }
  }
  const handleEnableSelectedLayers = async () => {
    if (!selectedLayer) {
      return
    }

    if (
      await warnConfirm({
        title: 'Enable Layers',
        message:
          'Are you sure you would like to enable the selected layer in this processing group?',
      })
    ) {
      await setSelectedProcGroupLayersEnabled({
        deliveryId,
        procGroup,
        selectedLayerDefs: [selectedLayer],
        enabled: true,
      })

      onRefresh()
    }
  }

  const handleDisableLayers = async () => {
    const mapLayerDefIds = Object.keys({
      ...(parcelsAndBoundsFetcher.result?.productMapLayerDefsById ?? {}),
      ...(parcelsAndBoundsFetcher.result?.backgroundMapLayerDefsById ?? {}),
    })

    if (mapLayerDefIds.length === 0) {
      return
    }

    if (
      await warnConfirm({
        title: 'Disable Layers',
        message:
          'Are you sure you would like to disable the layers in this processing group?',
      })
    ) {
      await setSelectedProcGroupLayersEnabled({
        deliveryId,
        procGroup,
        selectedLayerDefs: mapLayerDefIds,
        enabled: false,
      })

      onRefresh()
    }
  }

  return (
    <Column
      style={{
        height: '100%',
      }}
    >
      <Grid container sx={{ width: '100%', my: 1 }} columnSpacing={1}>
        <Grid item md={2} container alignItems="flex-end">
          <FormControl fullWidth>
            <InputLabel id="layer-select-label">Product Layer</InputLabel>
            <Select
              id="layer-select"
              value={selectedLayer}
              onChange={(ev) => setSelectedLayer(ev.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              {(parcelsAndBoundsFetcher.result?.productMapLayerDefsById
                ? Object.values(
                    parcelsAndBoundsFetcher.result?.productMapLayerDefsById
                  )
                : ([] as Pick<
                    GetDeliveryData,
                    'parcels'
                  >['parcels'][0]['mapLayers'][0]['mapLayerDef'][])
              ).map((mapLayerDef) => (
                <MenuItem key={mapLayerDef.id} value={mapLayerDef.id}>
                  {mapLayerDef.name[preferredLanguage]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={1} container alignItems="flex-end">
          <Tooltip title="Show/Hide Product Layer">
            <Checkbox
              sx={{ p: 1 / 2 }}
              size="small"
              disabled={!selectedLayer}
              onChange={() => setProductLayerVisible(!productLayerVisible)}
              value={productLayerVisible}
              checkedIcon={<Icon fontSize="small">visibility_off</Icon>}
              icon={<Icon fontSize="small">visibility_on</Icon>}
            />
          </Tooltip>
        </Grid>
        <Grid item md={3} container alignItems="flex-end">
          <FormControl fullWidth>
            <InputLabel id="layer-select-label">Background Layer</InputLabel>
            <Select
              id="layer-select"
              value={selectedBackgroundLayer}
              onChange={(ev) => setSelectedBackgroundLayer(ev.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              {(parcelsAndBoundsFetcher.result?.backgroundMapLayerDefsById
                ? Object.values(
                    parcelsAndBoundsFetcher.result?.backgroundMapLayerDefsById
                  )
                : ([] as Pick<
                    GetDeliveryData,
                    'parcels'
                  >['parcels'][0]['mapLayers'][0]['mapLayerDef'][])
              ).map((mapLayerDef) => (
                <MenuItem key={mapLayerDef.id} value={mapLayerDef.id}>
                  {mapLayerDef.name[preferredLanguage]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <IconButton
              aria-label="more"
              id="action-menu"
              aria-controls={menuOpen ? 'action-menu' : undefined}
              aria-expanded={menuOpen ? 'true' : undefined}
              aria-haspopup="true"
              onClick={(e) => {
                setMenuAnchorEl(e.currentTarget)
              }}
            >
              <Icon>more_vert</Icon>
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={() => setMenuAnchorEl(undefined)}
            >
              <MenuItem
                disabled={!selectedLayer}
                onClick={() => handleDisableSelectedLayers()}
              >
                Disable Selected Layer
              </MenuItem>
              <MenuItem onClick={() => handleDisableLayers()}>
                Disable All Layers
              </MenuItem>
              <MenuItem
                disabled={!selectedLayer}
                onClick={() => handleEnableSelectedLayers()}
              >
                Enable Selected Layer
              </MenuItem>
              <MenuItem onClick={() => handleEnableLayers()}>
                Enable All Layers
              </MenuItem>
            </Menu>
          </Stack>
        </Grid>
      </Grid>
      <MapContextProvider
        key={`QA-Map-${mapViewExpanded ? 'expanded' : 'collapsed'}`}
      >
        <Map
          classNames={[state.preferences.theme]}
          style={style}
          fitBounds={parcelsAndBoundsFetcher.result?.bounds}
          fitBoundsOptions={fitBoundsOptions}
        >
          <LayerVisibilityToggle
            layers={activeProductLayers}
            hidden={!productLayerVisible}
          />
          <div className="MapControl-top-left">
            <ScaleControl />
            <CursorLocation />
          </div>
          <div className="MapControl-top-right">
            <Stack spacing={1}>
              <ZoomControl />
              <StyledButtonGroup
                variant="contained"
                size="small"
                orientation="vertical"
              >
                <Button onClick={() => setMapViewExpanded(!mapViewExpanded)}>
                  <Tooltip title={mapViewExpanded ? 'Collapse' : 'Expand'}>
                    {mapViewExpanded ? (
                      <Icon>fullscreen_exit</Icon>
                    ) : (
                      <Icon>fullscreen</Icon>
                    )}
                  </Tooltip>
                </Button>
              </StyledButtonGroup>
            </Stack>
          </div>
        </Map>
      </MapContextProvider>
    </Column>
  )
}
