import { Media } from '../../graphql/types'

const getMediaThumbnailUrl = (media: Pick<Media, 'id'>) => {
  return `/media-uploader/photo/thumbnail/${media?.id}`
}

const getMediaUrl = (media: Pick<Media, 'id'>) => {
  return `/media-uploader/photo/${media?.id}`
}

export { getMediaThumbnailUrl, getMediaUrl }
