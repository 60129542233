import * as React from 'react'
import samplePlanMapReducer, {
  SamplePlanMapAction,
  SamplePlanMapState,
  initialState,
} from './SamplePlanMapReducer'
import { useSamplePlanMapSourceAndLayers } from '../hooks/useSamplePlanMapSourceAndLayers'
import useAsync from '../../../hooks/useAsync'
import { fetchSamplePlan } from '../queries'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import { useRedux } from '../../../hooks/useRedux'
import { selectMe } from '../../../data/selectMe'

interface SamplePlanMapContextTypes {
  state: SamplePlanMapState
  dispatch: React.Dispatch<SamplePlanMapAction>
  setBlockBounds: (bounds: mapboxgl.LngLatBoundsLike) => void
  refreshSamplePlan: (invalidateHasRefreshed?: boolean | undefined) => void
  isLoading: boolean
}

const SamplePlanMapContext = React.createContext<SamplePlanMapContextTypes>({
  state: initialState,
  dispatch: () => null,
  setBlockBounds: () => null,
  refreshSamplePlan: () => null,
  isLoading: true,
})

export const useSamplePlanMapContext = () =>
  React.useContext(SamplePlanMapContext)

export const SamplePlanMapContextProvider = ({
  children,
  paddingOffset,
  boundsPadding,
  samplePlanId,
}: {
  children: React.ReactNode
  paddingOffset: mapboxgl.PaddingOptions | undefined
  boundsPadding: number
  samplePlanId: string
}) => {
  const [reduxState] = useRedux()
  const me = selectMe(reduxState)
  const organizationId = selectOrganizationId(reduxState)
  const [samplePlan, refreshSamplePlan] = useAsync(fetchSamplePlan, [
    me?.id,
    organizationId,
    samplePlanId,
  ])
  const [state, dispatch] = React.useReducer(samplePlanMapReducer, initialState)
  const { setBlockBounds } = useSamplePlanMapSourceAndLayers({
    parcels: state.samplePlan?.SamplePlanBlocks?.map((spb) => spb.Parcel) ?? [],
    selectedParcels:
      state.selectedSamplePlanBlocks?.map((spb) => spb.Parcel) ?? [],
    samples: state.samplePlan?.Samples ?? [],
    selectedSamples: state.selectedSamplePlanSamples ?? [],
    highlightedSample: state.highlightedSample,
    paddingOffset: paddingOffset,
    boundsPadding: boundsPadding,
  })

  React.useEffect(() => {
    if (samplePlan?.result?.data) {
      dispatch({
        type: 'SET_SAMPLE_PLAN',
        samplePlan: samplePlan.result.data,
      })
    }
  }, [samplePlan?.result?.data])

  return (
    <SamplePlanMapContext.Provider
      value={{
        state,
        dispatch,
        setBlockBounds,
        refreshSamplePlan,
        isLoading: samplePlan.status === 'pending',
      }}
    >
      {children}
    </SamplePlanMapContext.Provider>
  )
}
