import * as React from 'react'
import { uniqWith, isEqual } from 'lodash'

import {
  Chip, Button, FormControl, Grid, Icon, InputLabel, MenuItem,
  Select, SelectChangeEvent,Typography, Theme, useTheme, useMediaQuery
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles';

import { selectPDFEnabledProductsForOrg } from '../data/selectListMapSourceDefs'
import {
  selectAvailableFilenames, selectDeliveryDates, selectGroups
} from '../data/selectOrgMapData'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { useRedux } from '../hooks/useRedux'
import { useHistory, useLocation, useSearchParams } from '../hooks/useRouter'
import i18n, { keys } from '../i18n'
import { FILTER } from '../UI/Table/filterRows'
import { FilterBy } from '../UI/Table/types'

interface Props {
  withTitle: boolean
}

const EMPTY_FILTER = ""
export const FILTER_INDEXES = ['status', 'groupId', 'deliveryId', 'filename']

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      fontSize: 12,
      fontWeight: 400,
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),

      "& .MuiChip-label": {
        padding: `0 ${theme.spacing(1)} 0 0`
      },
      "& .MuiIcon-root": {
        marginRight: 0,
        fontSize: 18
      }
    },
    inputLabel: {
      fontWeight: 500,
      fontSize: 16,
      left: -16
    }
  })
);

export const DownloadFilters = ({ withTitle }: Props) => {
  const theme = useTheme()
  const classes = useStyles();

  const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [state] = useRedux()
  const organizationGroups = selectGroups(state)
  const dates = selectDeliveryDates(state)
  const filenames = selectAvailableFilenames(state)
  const pdfEnabledProducts = selectPDFEnabledProductsForOrg(state)
  const preferredLanguage = selectPreferredLanguage(state)

  const { filter = EMPTY_FILTER } = useSearchParams()
  const history = useHistory()
  const location = useLocation()

  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState<string | undefined>(undefined)
  const [selectedGroupFilter, setSelectedGroupFilter] = React.useState<string[]>([])
  const [selectedDateFilter, setSelectedDateFilter] = React.useState<string[]>([])
  const [selectedFilenameFilter, setSelectedFilenameFilters] = React.useState<string[]>([])

  React.useEffect(() => {
    const tableFilters = FILTER.deserialize(filter)
    let statusFilter: string | undefined = undefined
    let groupFilter: string[] = []
    let dateFilter: string[] = []
    let filenameFilter: string[] = []

    for (const filter of tableFilters) {
      if (filter.op === 'eq') {
        const column = FILTER_INDEXES[filter.index]
        if (column === 'status') {
          statusFilter = filter.value
        }
        if (column === 'groupId') {
          groupFilter.push(filter.value)
        }
        if (column === 'deliveryId') {
          dateFilter.push(filter.value)
        }
        if (column === 'filename') {
          filenameFilter.push(filter.value)
        }
      }
      if (filter.op === 'neq') {
        const column = FILTER_INDEXES[filter.index]
        if (column === 'status') {
          statusFilter = 'pending'
        }
      }
    }

    setSelectedStatusFilter(statusFilter)
    setSelectedGroupFilter(groupFilter)
    setSelectedDateFilter(dateFilter)
    setSelectedFilenameFilters(filenameFilter)
  }, [filter])


  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    const filterValue = event.target.value
    const tableFilters = FILTER.deserialize(filter ?? '')
    const searchParams = new URLSearchParams(location.search);

    if (filterValue === 'all') {
      const filter = FILTER.serialize(tableFilters.filter(filter => filter.index !== 0))
      if (filter.length > 0) {
        searchParams.set('filter', filter)
      }
      else {
        searchParams.delete('filter')
      }

      history.replace({ ...location, search: searchParams.toString() })
    }
    else if (filterValue === 'complete') {
      const filter = FILTER.serialize([...tableFilters.filter(filter => filter.index !== 0), { op: 'eq', index: 0, value: 'complete' }])
      if (filter.length > 0) {
        searchParams.set('filter', filter)
      }
      else {
        searchParams.delete('filter')
      }

      history.replace({ ...location, search: searchParams.toString() })
    }
    else {
      const filter = FILTER.serialize([...tableFilters.filter(filter => filter.index !== 0), { op: 'neq', index: 0, value: 'complete' }])
      if (filter.length > 0) {
        searchParams.set('filter', filter)
      }
      else {
        searchParams.delete('filter')
      }

      history.replace({ ...location, search: searchParams.toString() })
    }
  }

  const handleDelete = (e: React.MouseEvent, value: string) => {
    const searchParams = new URLSearchParams(location.search);
    const tableFilters = FILTER.deserialize(filter).filter(item => item.value !== value)
    const updatedFilter = FILTER.serialize(tableFilters);

    if (updatedFilter.length > 0) {
      searchParams.set('filter', updatedFilter)
    }
    else {
      searchParams.delete('filter')
    }

    history.replace({ ...location, search: searchParams.toString() })
  };

  const handleFilterChange = (event: SelectChangeEvent<string[] | string>, index: number) => {
    const filterValue = event.target.value
    const tableFilters = FILTER.deserialize(filter ?? '')
    const searchParams = new URLSearchParams(location.search);

    if (filterValue[filterValue.length - 1] === 'all') {
      const filter = FILTER.serialize(tableFilters.filter(filter => filter.index !== index))

      if (filter.length > 0) {
        searchParams.set('filter', filter)
      }
      else {
        searchParams.delete('filter')
      }

      history.replace({ ...location, search: searchParams.toString() })
    }
    else if (!filterValue.length) {
      const filter = FILTER.serialize(tableFilters.filter(filter => filter.index !== index))
      if (filter.length > 0) {
        searchParams.set('filter', filter)
      }
      else {
        searchParams.delete('filter')
      }

      history.replace({ ...location, search: searchParams.toString() })
    }
    else {
      const filterValues = (filterValue as string[]).map(value => ({ op: 'eq', index: index, value: value }))
      const filter = FILTER.serialize(uniqWith([...tableFilters.filter(filter => filter.index !== index), ...filterValues as FilterBy[]], isEqual))

      if (filter.length > 0) {
        searchParams.set('filter', filter)
      }
      else {
        searchParams.delete('filter')
      }

      history.replace({ ...location, search: searchParams.toString() })
    }
  }

  const handleClearAllFilters = () => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete('filter');
    history.replace({ ...location, search: searchParams.toString() })
  }

  return <Grid container alignItems="center" sx={{ p: 0 }}>
    {
      withTitle && (
        <Grid item xs={12} sx={{ pt: theme.spacing(4) }}>
          <Typography align="center" variant="h6">{i18n.t(keys.filterBy)}:</Typography>
        </Grid>
      )
    }
    <Grid item xs={12} sx={{ pt: theme.spacing(4) }}>
      <FormControl fullWidth>
        <InputLabel color="primary" className={classes.inputLabel} shrink={true} id="download-group-filter-select-label">{i18n.t(keys.groupName)}</InputLabel>
        <Select
          multiple={true}
          displayEmpty={true}
          labelId="download-group-filter-select-label"
          id="download-group-filter-select"
          value={selectedGroupFilter}
          label="Group"
          renderValue={(value: string[]) => (
              <div className={classes.chips}>
                {
                  value.length ? (
                    organizationGroups.map(group =>
                      value.includes(group.id) && (
                        <Chip
                          key={group.id}
                          label={group.name}
                          clickable
                          color="primary"
                          deleteIcon={
                            <Icon onMouseDown={(evt: React.MouseEvent<HTMLButtonElement>) => evt.stopPropagation()}>cancel</Icon>
                          }
                          onDelete={(evt: React.MouseEvent<HTMLButtonElement>) => handleDelete(evt, group.id)}
                          className={classes.chip}
                        />
                      )
                    )
                  ) : <em>{i18n.t(keys.all)}</em>
                }
              </div>
            )
          }
          onChange={(ev) => handleFilterChange(ev, 1)}>
            <MenuItem selected={true} value="all">
              <em>{i18n.t(keys.all)}</em>
            </MenuItem>
            {organizationGroups.map(group =>
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            )}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} sx={{ paddingTop: theme.spacing(4) }}>
      <FormControl fullWidth>
        <InputLabel color="primary" className={classes.inputLabel} shrink={true} id="download-date-filter-select-label">{i18n.t(keys.flightDate)}</InputLabel>
        <Select
          multiple={true}
          displayEmpty={true}
          labelId="download-date-filter-select-label"
          id="download-date-filter-select"
          value={selectedDateFilter}
          label="Age"
          renderValue={(value: string[]) => (
            <div className={classes.chips}>
              {
                value.length ? (
                  dates.map(date =>
                    value.includes(date.deliveryId) && (
                      <Chip
                        key={date.deliveryId}
                        label={date.flightDates.join(' - ')}
                        clickable
                        color="primary"
                        deleteIcon={
                          <Icon onMouseDown={(evt: React.MouseEvent<HTMLButtonElement>) => evt.stopPropagation()}>cancel</Icon>
                        }
                        onDelete={(evt: React.MouseEvent<HTMLButtonElement>) => handleDelete(evt, date.deliveryId)}
                        className={classes.chip}
                      />
                    )
                  )
                ) : <em>{i18n.t(keys.all)}</em>
              }
            </div>
            )
          }
          onChange={(ev) => handleFilterChange(ev, 2)}>
            <MenuItem value='all'>
              <em>{i18n.t(keys.all)}</em>
            </MenuItem>
            {dates.map(date =>
              <MenuItem key={date.deliveryId} value={date.deliveryId}>
                {date.flightDates.join(' - ')}
              </MenuItem>
            )}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} sx={{ paddingTop: theme.spacing(4) }}>
      <FormControl fullWidth>
        <InputLabel className={classes.inputLabel} id="download-status-filter-select-label">{i18n.t(keys.status)}</InputLabel>
        <Select
          labelId="download-status-filter-select-label"
          id="download-status-filter-select"
          value={selectedStatusFilter ?? 'all'}
          label="Status"
          onChange={handleStatusFilterChange}>
          <MenuItem value='all'>
            <em>{i18n.t(keys.all)}</em>
          </MenuItem>
          <MenuItem value='complete'>{i18n.t(keys.readyForDownload)}</MenuItem>
          <MenuItem value='pending'>{i18n.t(keys.inProgress)}</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} sx={{ paddingTop: theme.spacing(4) }}>
      <FormControl fullWidth>
        <InputLabel color="primary" className={classes.inputLabel} shrink={true} id="download-filename-filter-select-label">{i18n.t(keys.filename)}</InputLabel>
        <Select
          multiple={true}
          displayEmpty={true}
          labelId="download-filename-filter-select-label"
          id="download-filename-filter-select"
          value={selectedFilenameFilter}
          label="Filename"
          renderValue={(value: string[]) => (
            <div className={classes.chips}>
              {
                value.length ? ([
                  filenames.map(filename => {
                      if (!filename.endsWith('.zip')) {
                        filename += '.zip'
                      }
                      return (
                        value.includes(filename) && (
                          <Chip
                            key={filename}
                            label={i18n.t(`filenames.${filename}`, { defaultValue: filename })}
                            clickable
                            color="primary"
                            deleteIcon={
                              <Icon onMouseDown={(evt: React.MouseEvent<HTMLButtonElement>) => evt.stopPropagation()}>cancel</Icon>
                            }
                            onDelete={(evt: React.MouseEvent<HTMLButtonElement>) => handleDelete(evt, filename)}
                            className={classes.chip}
                          />
                        )
                      )
                    }
                  ),
                  pdfEnabledProducts.map((product) => {
                    const filename = product?.pdfFilenameTranslations?.[preferredLanguage]
                      ?? product?.pdfFilename
                        ?? `${product?.name}.pdf`
                    return (
                      value.includes(filename) && (
                        <Chip
                          key={product.pdfFilename!}
                          label={filename}
                          clickable
                          color="primary"
                          deleteIcon={
                            <Icon onMouseDown={(evt: React.MouseEvent<HTMLButtonElement>) => evt.stopPropagation()}>cancel</Icon>
                          }
                          onDelete={(evt: React.MouseEvent<HTMLButtonElement>) => handleDelete(evt, product.pdfFilename!)}
                          className={classes.chip}
                        />
                      )
                    )
                  })
                ]) : <em>{i18n.t(keys.all)}</em>
              }
            </div>
          )
          }
          onChange={(ev) => handleFilterChange(ev, 3)}>
            <MenuItem value="all">
              <em>{i18n.t(keys.all)}</em>
            </MenuItem>
            {
              filenames.map((filename) => {
                if (!filename.endsWith('.zip')) {
                  filename += '.zip'
                }
                return (
                  <MenuItem key={filename} value={filename}>
                    {i18n.t(`filenames.${filename}`, { defaultValue: filename })}
                  </MenuItem>
                )
              })
            }
            {
              pdfEnabledProducts.map((product) => {
                const filename = product?.pdfFilenameTranslations?.[preferredLanguage]
                  ?? product?.pdfFilename
                  ?? `${product?.name}.pdf`
                return (
                  <MenuItem key={product.pdfFilename!} value={product.pdfFilename!}>
                    {filename}
                  </MenuItem>
                )
              })
            }
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} sx={{ pt: theme.spacing(4) }} display="flex" justifyContent={matchesSmall ? "flex-end" : "center"}>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleClearAllFilters}
      >
        {`${i18n.t(keys.clearAll)} ${i18n.t(keys.filters)}`}
      </Button>
    </Grid>
  </Grid>
}
