import * as React from 'react'
import {
  LinearProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'

interface Props {
  open: boolean
  progress: number
}

export class UploadKatoFileDialog extends React.Component<Props> {
  render() {
    const { progress, open } = this.props

    return (
      <Dialog
        style={{ minWidth: 500, minHeight: 300 }}
        maxWidth="lg"
        open={open}
        aria-labelledby="upload-kato-file-title"
      >
        <DialogTitle id="upload-kato-file-title">
          Uploading Kato File
        </DialogTitle>
        <DialogContent>
          <LinearProgress
            variant="determinate"
            color="primary"
            value={progress}
          />
        </DialogContent>
      </Dialog>
    )
  }
}
