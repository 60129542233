import * as Sentry from '@sentry/browser'
import { createSelector } from 'reselect'
import { selectFilteredDeliveryParcels } from './selectFilteredParcels'
import { selectSelectedParcelIds } from './selectSelectedParcelIds'

export const selectSelectedDeliveryParcels = createSelector(
  [selectFilteredDeliveryParcels, selectSelectedParcelIds],
  (parcels, selectedParcelIds) => {
    const selectedParcels = parcels.filter(({ parcelId }) =>
      selectedParcelIds.has(parcelId)
    )
    // save selected parcels to Sentry
    Sentry.configureScope((scope) => {
      scope.setExtra(
        'parcels',
        selectedParcels.map(({ name }) => name)
      )
    })

    return selectedParcels
  }
)
