import { query, gql } from '../../../graphql/client'

const defaultEmailNotifications = {
  'order|confirmation': true,
  'target-delivery|flight-notice': true,
  'delivery|flight-complete': true,
  'delivery|data-available': true,
}

export const notifications = Object.keys(
  defaultEmailNotifications
) as (keyof typeof defaultEmailNotifications)[]

export interface Unsub {
  [key: string]: {
    name: string
    emailNotifications: {
      [key: string]: boolean
    }
  }
}

export const fetchNotifications = async (
  [userId]: [userId: string | undefined],
  skip: () => void
): Promise<Unsub | void> => {
  if (!userId) {
    return skip()
  }

  const { organizations, unsubscribeEmailNotifications } = await query<{
    organizations: {
      id: string
      name: string
    }[]
    unsubscribeEmailNotifications: {
      userId: string
      organizationId: string
      notificationType: string
    }[]
  }>({
    variables: {
      userId,
    },
    query: gql`
      query fetchNotifications($userId: Int!) {
        organizations: Organization(
          where: { OrganizationUsers: { userId: { _eq: $userId } } }
        ) {
          id
          name
        }
        unsubscribeEmailNotifications: UnsubscribeEmailNotification(
          where: { userId: { _eq: $userId } }
        ) {
          userId
          organizationId
          notificationType
        }
      }
    `,
  })

  const result: Unsub = {}

  for (const { id, name } of organizations) {
    result[id] = result[id] ?? {
      name,
      emailNotifications: { ...defaultEmailNotifications },
    }
  }

  for (const {
    organizationId,
    notificationType,
  } of unsubscribeEmailNotifications) {
    const emailNotifications = result[organizationId]?.emailNotifications
    if (emailNotifications) {
      emailNotifications[notificationType] = false
    }
  }

  return result
}
