import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import errorAlert from '../../../admin/errorAlert'
import warnConfirm from '../../../admin/warnConfirm'
import { RouteParams, url, urls } from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../../graphql/api'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { createTableActionIconButton } from '../../../UI/Table/createTableActionIconButton'
import { Table } from '../../../UI/Table/Table'
import { TableAction, TableFormatter } from '../../../UI/Table/types'
import {
  ListFlightsData,
  refreshListFlights,
  selectListFlights,
} from './selectListFlight'

interface State {
  selection: ListFlightsData[]
}

class ListFlight extends React.PureComponent<
  RouteComponentProps<RouteParams> & ReduxProps,
  State
> {
  state: State = {
    selection: [],
  }
  actions: TableAction<ListFlightsData>[] = [
    {
      button: createTableActionIconButton({
        title: 'Create New Flight',
        icon: 'add',
        key: 'create-flight',
      }),
      action: () => {
        this.props.history.push(
          url(urls.newFlightFromDelivery, this.props.match.params)
        )
      },
    },
    {
      selectionRequired: true,
      button: createTableActionIconButton({
        title: 'Edit Flight',
        icon: 'edit',
        key: 'edit-flight',
      }),
      action: ([{ deliveryId, date: flightDate }]) => {
        this.props.history.push(
          url(urls.editFlight, { deliveryId, flightDate })
        )
      },
    },
    {
      selectionRequired: true,
      multi: true,
      button: createTableActionIconButton({
        title: 'Delete Flight(s)',
        icon: 'delete',
        key: 'delete-flight',
      }),
      action: async (selection) => {
        const confirmed = await warnConfirm({
          title: `Are you sure you want to delete ${selection.length} selected flight(s)?`,
          message:
            'Deleting a flight will delete all associated files and layers. This is not reversible.',
          action: 'Delete',
        })

        if (!confirmed) {
          return
        }

        try {
          for (const { deliveryId, date } of selection) {
            await api.flight.delete({
              pk: { deliveryId, date },
            })
          }
          refreshListFlights()
        } catch (e) {
          softError(e, 'Failed to Delete Flight', e.message)
        }
      },
    },
  ]
  tableFormatter: TableFormatter<ListFlightsData>[] = [
    {
      header: () => 'Date',
      data: ({ date }) => date,
    },
    {
      header: () => 'Comment',
      data: ({ comment }) => comment,
    },
  ]

  handleSelectionChange = (selection: State['selection']) =>
    this.setState({ selection })

  render() {
    const { flights } = this.props
    const { selection } = this.state

    return (
      <AsyncSelectorStatusOverlay requests={flights}>
        <Table
          title="Flights"
          rows={flights.data ?? []}
          formatter={this.tableFormatter}
          selection={selection}
          actions={this.actions}
          onSelectionChange={this.handleSelectionChange}
        />
      </AsyncSelectorStatusOverlay>
    )
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
      file: 'MapDataFlightDialog.tsx',
    },
  })

const mapState = (state: RootStore) => ({
  flights: selectListFlights(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(ListFlight)
)
