import * as React from 'react'
import { useHistory } from 'react-router-dom'

import {
  AccessTime,
  CheckCircleOutline,
  DoDisturb,
  ErrorOutline,
  ExpandCircleDownOutlined,
  HelpOutline,
  HistoryToggleOff,
} from '@mui/icons-material'
import {
  CircularProgress,
  IconButton,
  keyframes,
  Menu,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import { url, urls } from '../../../../appNavigation/urls'
import {
  DeliveryProcGroupWithStatuses,
  JobStatusAndId,
} from './deliveryProcGroupSelectors'
import { getColor, getTextColor } from '../utils'

interface CompactProps {
  compact?: boolean
}

const pulse = keyframes`
  0% {
    background-color: #068bf8;
  }
  100% {
    background-color: #42a5f5;
  }
`

const JobOutputSummary = ({
  output,
  title,
}: JobStatusAndId & { title: string }) => {
  return (
    <Stack>
      <Typography variant="caption">{title}</Typography>
      <div
        style={{
          maxHeight: 150,
          width: 200,
          overflow: 'auto',
          textAlign: 'justify',
        }}
      >
        <pre>{output?.join('\n') ?? ''}</pre>
      </div>
    </Stack>
  )
}

const CustomStep = styled(Step)<CompactProps>(({ compact, theme }) => ({
  padding: compact ? theme.spacing(1 / 2) : undefined,
}))

const Connector = styled(StepConnector)(({ theme }) => ({
  left: 'calc(-50%)',
  right: 'calc(50%)',
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.divider,
    borderRadius: 1,
  },
}))

interface JobStatusProps extends CompactProps {
  status: 'complete' | 'cancelled' | 'running' | 'error' | 'expired'
}

const JobStepIconRoot = styled('div')<JobStatusProps>(
  ({ theme, compact, status }) => ({
    backgroundColor: getColor(status, theme),
    zIndex: 1,
    color: getTextColor(status, theme),
    width: compact ? 20 : 25,
    height: compact ? 20 : 25,
    display: 'flex',
    padding: 0,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(status === 'running' && {
      animation: `${pulse} 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite`,
      animationDirection: 'alternate',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
  })
)

const FinalProductJobStepIcon = (
  props: JobStatusAndId & StepIconProps & CompactProps
) => {
  const history = useHistory()
  const { className, status, taskName, id } = props

  const Icon = getIcon(status)

  const navigateToJob = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const loc = url(urls.job, { jobId: id })
    if (event.metaKey || event.ctrlKey || event.button === 1) {
      event.stopPropagation()

      window.open(loc)
    } else {
      history.push(loc)
    }
  }

  return (
    <Tooltip
      disableInteractive={false}
      title={
        <JobOutputSummary {...props} title={`${taskName ?? ''}: ${status}`} />
      }
    >
      <JobStepIconRoot
        onClick={navigateToJob}
        compact={props.compact}
        sx={{ cursor: 'pointer' }}
        status={status as JobStatusProps['status']}
        className={className}
      >
        {status === 'running' ? (
          <CircularProgress
            sx={{
              ['& .MuiCircularProgress-svg']: {
                height: '100%',
                padding: '4px',
                width: '100%',
              },
            }}
          />
        ) : (
          <Icon sx={{ width: 'auto', height: 'auto' }} />
        )}
      </JobStepIconRoot>
    </Tooltip>
  )
}

interface FinalProductsJobsAndStatuses {
  finalProductsStatuses: JobStatusAndId[]
}

const FinalProductsJobsStepIcon = (
  props: FinalProductsJobsAndStatuses & StepIconProps & CompactProps
) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<
    null | HTMLElement | SVGSVGElement
  >(null)
  const open = Boolean(anchorEl)
  const { finalProductsStatuses, ...restProps } = props

  if (finalProductsStatuses.length === 1) {
    return <JobStepIcon {...{ ...restProps, ...finalProductsStatuses[0] }} />
  }

  return (
    <>
      <IconButton
        disableRipple
        sx={{
          m: 0,
          p: 0,
          backgroundColor: theme.palette.grey[700],
          height: props.compact ? 20 : 25,
          width: props.compact ? 20 : 25,
        }}
        onClick={(ev) => setAnchorEl(ev.currentTarget)}
      >
        <ExpandCircleDownOutlined
          onClick={(ev) => setAnchorEl(ev.currentTarget)}
          sx={{
            cursor: 'pointer',
            color: theme.palette.getContrastText(theme.palette.grey[700]),
            width: 'auto',
            height: 'auto',
          }}
        />
      </IconButton>
      <Menu
        disableAutoFocusItem
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Stack spacing={1} sx={{ p: 1 }}>
          {finalProductsStatuses.map((jas) => {
            return <FinalProductJobStepIcon {...{ ...jas, ...restProps }} />
          })}
        </Stack>
      </Menu>
    </>
  )
}

const UploadJobStepIcon = (
  props: JobStatusAndId &
    StepIconProps &
    CompactProps &
    FinalProductsJobsAndStatuses
) => {
  const history = useHistory()
  const { className, status, id, finalProductsStatuses, finishedAt } = props

  const adjustedStatus =
    status !== 'queued' &&
    status !== 'pending' &&
    status !== 'running' &&
    finalProductsStatuses.some(
      (fpj) =>
        (fpj.finishedAt?.valueOf() ?? Number.MIN_VALUE) >
        (finishedAt?.valueOf() ?? Number.MAX_VALUE)
    )
      ? 'expired'
      : status

  let Icon = getIcon(adjustedStatus)

  const navigateToJob = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const loc = url(urls.job, { jobId: id })
    if (event.metaKey || event.ctrlKey || event.button === 1) {
      event.stopPropagation()

      window.open(loc)
    } else {
      history.push(loc)
    }
  }

  return (
    <Tooltip
      disableInteractive={false}
      title={
        <JobOutputSummary {...props} title={`Upload: ${adjustedStatus}`} />
      }
    >
      <JobStepIconRoot
        onClick={navigateToJob}
        compact={props.compact}
        sx={{ cursor: 'pointer' }}
        status={adjustedStatus as JobStatusProps['status']}
        className={className}
      >
        {status === 'running' ? (
          <CircularProgress
            sx={{
              ['& .MuiCircularProgress-svg']: {
                height: '100%',
                padding: '4px',
                width: '100%',
              },
            }}
          />
        ) : (
          <Icon sx={{ width: 'auto', height: 'auto' }} />
        )}
      </JobStepIconRoot>
    </Tooltip>
  )
}
const JobStepIcon = (props: JobStatusAndId & StepIconProps & CompactProps) => {
  const history = useHistory()
  const { className, status, icon, id } = props

  const titleMap: { [index: string]: string } = {
    1: 'Row Mask',
    2: 'Reference Setup',
    3: 'Kato',
    4: 'Reference Update',
    5: 'Final Products',
    6: 'Upload',
  }

  const Icon = getIcon(status)

  const navigateToJob = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const loc = url(urls.job, { jobId: id })
    if (event.metaKey || event.ctrlKey || event.button === 1) {
      event.stopPropagation()

      window.open(loc)
    } else {
      history.push(loc)
    }
  }

  return (
    <Tooltip
      disableInteractive={false}
      title={
        <JobOutputSummary
          {...props}
          title={`${titleMap[String(icon)]}: ${status}`}
        />
      }
    >
      <JobStepIconRoot
        onClick={navigateToJob}
        compact={props.compact}
        sx={{ cursor: 'pointer' }}
        status={status as JobStatusProps['status']}
        className={className}
      >
        {status === 'running' ? (
          <CircularProgress
            sx={{
              ['& .MuiCircularProgress-svg']: {
                height: '100%',
                padding: '4px',
                width: '100%',
              },
            }}
          />
        ) : (
          <Icon sx={{ width: 'auto', height: 'auto' }} />
        )}
      </JobStepIconRoot>
    </Tooltip>
  )
}

interface Props {
  deliveryProcGroup: DeliveryProcGroupWithStatuses
  compact?: boolean
}

export const ProcGroupJobs = ({
  deliveryProcGroup: {
    rowMaskStatus,
    referenceSetupStatus,
    katoStatus,
    referenceUpdateStatus,
    finalProductsStatuses,
    uploadStatus,
  },
  compact = false,
}: Props) => {
  const showJobTitles = !compact
  return (
    <Stepper connector={<Connector />} alternativeLabel>
      <CustomStep compact={compact}>
        <StepLabel
          StepIconComponent={JobStepIcon}
          StepIconProps={
            { ...rowMaskStatus, compact } as Partial<StepIconProps>
          }
        >
          {showJobTitles ? 'Row Mask' : null}
        </StepLabel>
      </CustomStep>
      <CustomStep compact={compact}>
        <StepLabel
          StepIconComponent={JobStepIcon}
          StepIconProps={
            { ...referenceSetupStatus, compact } as Partial<StepIconProps>
          }
        >
          {showJobTitles ? 'Reference Setup' : null}
        </StepLabel>
      </CustomStep>
      <CustomStep compact={compact}>
        <StepLabel
          StepIconComponent={JobStepIcon}
          StepIconProps={{ ...katoStatus, compact } as Partial<StepIconProps>}
        >
          {showJobTitles ? 'Kato' : null}
        </StepLabel>
      </CustomStep>
      <CustomStep compact={compact}>
        <StepLabel
          StepIconComponent={JobStepIcon}
          StepIconProps={
            { ...referenceUpdateStatus, compact } as Partial<StepIconProps>
          }
        >
          {showJobTitles ? 'Reference Update' : null}
        </StepLabel>
      </CustomStep>
      <CustomStep compact={compact}>
        <StepLabel
          StepIconComponent={FinalProductsJobsStepIcon}
          StepIconProps={
            {
              finalProductsStatuses,
              compact,
            } as Partial<StepIconProps>
          }
        >
          {showJobTitles ? 'Final Products' : null}
        </StepLabel>
      </CustomStep>
      <CustomStep compact={compact}>
        <StepLabel
          StepIconComponent={UploadJobStepIcon}
          StepIconProps={
            {
              ...uploadStatus,
              finalProductsStatuses,
              compact,
            } as Partial<StepIconProps>
          }
        >
          {showJobTitles ? 'Upload' : null}
        </StepLabel>
      </CustomStep>
    </Stepper>
  )
}
const getIcon = (status: string) => {
  switch (status) {
    case 'complete':
      return CheckCircleOutline
    case 'pending':
    case 'queued':
      return AccessTime
    case 'cancelled':
      return DoDisturb
    case 'error':
      return ErrorOutline
    case 'expired':
      return HistoryToggleOff
  }
  return HelpOutline
}
