import { client, gql } from '../../../graphql/client'

export interface FetchedOrganization {
  id: string
  name: string
  billingCountry?: string
}
export const fetchOrganizationById = (
  [organizationId]: [organizationId: string],
  skip: () => void
) => {
  if (!organizationId) {
    return skip()
  }

  return client.request<{ organization: FetchedOrganization }>({
    variables: { organizationId },
    query: gql`
      query GET_ORGANIZATION_BY_ID($organizationId: Int!) {
        organization: Organization_by_pk(id: $organizationId) {
          id
          name
          billingCountry
        }
      }
    `,
  })
}
