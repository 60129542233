import * as React from 'react'

import {
  Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
  List, ListItem, ListItemText, Radio, RadioGroup
} from '@mui/material'

import { selectGroupDatesByDeliveryGroupId, selectGroupsById } from '../data/selectOrgMapData'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import { DataDownloadSettings } from './DataDownloadSettingsForm'
import { Download } from './types'

export type SkipOrReplace = Record<string, { action: 'skip' | 'replace', id: string }>

interface Props {
  open: boolean
  duplicateDownloads: Pick<Download, 'id' | 'groupId' | 'deliveryId' | 'filename'>[]
  onCancelled: () => void
  onSubmit: (skipOrReplace: SkipOrReplace) => void
}

export const ManageDuplicateDownloadsDialog = ({ open, onCancelled, duplicateDownloads, onSubmit }: Props) => {

  const [, updateSettings] = React.useState<DataDownloadSettings | undefined>(undefined)

  const [state] = useRedux()

  const [skipOrReplace, setSkipOrReplace] = React.useState<SkipOrReplace>({})

  React.useEffect(() => {
    setSkipOrReplace(
      duplicateDownloads.reduce((curr, { id, deliveryId, groupId, filename }) => {
        const deliveryGroupFileKey = `${deliveryId}_${groupId}_${filename}`;
        curr[deliveryGroupFileKey] = {
          action: 'replace', id
        }
        return curr
      }, {} as SkipOrReplace)
    )
  }, [duplicateDownloads])

  const deliveryGroupsDatesById = selectGroupDatesByDeliveryGroupId(state)
  const organizationGroupsById = selectGroupsById(state)


  const close = () => {
    onCancelled()
  }

  const submit = () => {
    onSubmit(skipOrReplace)
  }

  const handleClose = () => {
    updateSettings(undefined)
  }

  const handleSkipOrReplaceAllChange = (action: 'skip' | 'replace') => {
    setSkipOrReplace(
      duplicateDownloads.reduce((curr, { id, deliveryId, groupId, filename }) => {
        const deliveryGroupFileKey = `${deliveryId}_${groupId}_${filename}`;
        curr[deliveryGroupFileKey] = {
          action, id
        }
        return curr
      }, {} as SkipOrReplace)
    )
  }

  const handleSkipOrReplaceChange = (key: string, id: string, action: 'skip' | 'replace') => {
    setSkipOrReplace({ ...skipOrReplace, [key]: { action, id } })
  }

  return <Dialog open={open} maxWidth="sm" fullWidth onClose={() => handleClose()}>
    <DialogTitle>
      <Alert severity="info">
        <AlertTitle>{i18n.t(keys.duplicatesFoundTitle)}</AlertTitle>
        <span dangerouslySetInnerHTML={{ __html: i18n.t(keys.duplicatesFoundDetail) }} />
      </Alert>
    </DialogTitle>
    <DialogContent>

      <List>
        <ListItem key="all">
          <ListItemText primary="" />
          <Button onClick={() => handleSkipOrReplaceAllChange('skip')}>{i18n.t(keys.skipAll)}</Button>
          <Button onClick={() => handleSkipOrReplaceAllChange('replace')}>{i18n.t(keys.replaceAll)}</Button>
        </ListItem>
        {duplicateDownloads.map(({ id, deliveryId, groupId, filename }) => {
          const deliveryGroups = deliveryGroupsDatesById[`${deliveryId}_${groupId}`]
          const deliveryGroupFileKey = `${deliveryId}_${groupId}_${filename}`;
          let dates: string
          const groupName = organizationGroupsById[deliveryGroups[0].groupId]?.name
          if (deliveryGroups.length === 1) {
            dates = deliveryGroups[0].flightDate
          }
          else {
            const maxDate = Math.max(...deliveryGroups.map(dg => Date.parse(dg.flightDate)))
            const minDate = Math.min(...deliveryGroups.map(dg => Date.parse(dg.flightDate)))
            dates = `${i18n.toDateShort(minDate)}-${i18n.toDateShort(maxDate)}`
          }

          return <ListItem key={deliveryGroupFileKey}>
            <ListItemText primary={`${groupName} - ${dates}`} secondary={filename} />
            <RadioGroup row value={skipOrReplace[deliveryGroupFileKey]?.action} onChange={(_ev, value) => handleSkipOrReplaceChange(deliveryGroupFileKey, id, value as 'skip' | 'replace')}>
              <FormControlLabel value="skip" control={<Radio />} label={i18n.t(keys.skip)} />
              <FormControlLabel value="replace" control={<Radio />} label={i18n.t(keys.replace)} />
            </RadioGroup>
          </ListItem>
        })}
      </List>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="secondary" onClick={() => close()}>Close</Button>
      <Button
        onClick={() => submit()}
        variant="contained">
        {i18n.t(keys.generic.ok)}
      </Button>
    </DialogActions>
  </Dialog >
}
