import * as mapboxgl from "mapbox-gl"

const removeSourceAndLayer = (id: string, map: mapboxgl.Map | undefined) => {
    // remove the old selected layers/sources if they exist.
    if (map?.isSourceLoaded(id)) {
      map?.removeLayer(id)
      map?.removeSource(id)
    }
}

export default removeSourceAndLayer