import { createSelector } from 'reselect'

import { RootStore } from '../redux/types'
import { isNumericIdEqual } from '../util/isNumericIdEqual'
import { selectOrganizationList } from './selectOrganizationList'

const getOrganizationId = (state: RootStore) =>
  state.userSelection.selectedOrganizationId

export const selectOrganizationId = createSelector(
  [getOrganizationId, selectOrganizationList],
  (organizationId, { data }) => {
    if (data && data.length > 0) {
      if (data.find((org) => isNumericIdEqual(org.id, organizationId))) {
        return organizationId
      }

      return data[0].id
    }

    return
  }
)
