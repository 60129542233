import './GPSOffsetMarkerControl.scss'

import * as React from 'react'

import { Button, Paper, Typography } from '@mui/material'

import i18n, { keys } from '../../i18n'
import {
  setIsPollingUserPosition,
  toggleGPSOffsetMarkerVisible,
  toggleLayerDrawer,
} from '../../postgis/actions'
import { connect } from '../../redux/connect'
import { updatePreferences } from '../../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { MapboxGL } from '../MapboxGL'
import withMap, { WithMap } from '../withMap'

interface State {
  xOffset: number
  yOffset: number
  stopPollingUserPositionOnUnmount: boolean
}

class GPSOffsetMarkerControl extends React.PureComponent<
  ReduxProps & AppDispatchProps & WithMap
> {
  marker?: MapboxGL.Marker

  state: State = {
    xOffset: 0,
    yOffset: 0,
    stopPollingUserPositionOnUnmount: false,
  }

  componentDidMount() {
    const { isPollingUserPosition, position, dispatch } = this.props
    dispatch(toggleLayerDrawer(null))

    this.marker = new MapboxGL.Marker()
    // initialize marker's position to map location
    this.handleMapMove()
    this.marker.addTo(this.props.map)

    this.props.map.on('move', this.handleMapMove)

    if (position) {
      const { longitude, latitude } = position.coords
      const center: MapboxGL.LngLatLike = [longitude, latitude]
      this.props.map.easeTo({ center })
    } else if (!isPollingUserPosition) {
      dispatch(setIsPollingUserPosition(true))
      this.setState({ stopPollingUserPositionOnUnmount: true })
    }
  }

  componentDidUpdate(prevProps: this['props']) {
    const { position } = this.props
    if (position && !prevProps.position) {
      // tslint:disable-next-line:no-console
      console.log('GPSOffsetMarkerControl  componentDidUpdate')
      const { longitude, latitude } = position.coords
      const center: MapboxGL.LngLatLike = [longitude, latitude]
      this.props.map.easeTo({ center })
    }
  }

  componentWillUnmount() {
    this.props.map.off('move', this.handleMapMove)

    this.marker!.remove()

    if (this.state.stopPollingUserPositionOnUnmount) {
      this.props.dispatch(setIsPollingUserPosition(false))
    }
  }

  handleConfirm = () => {
    this.props.dispatch(
      updatePreferences({
        gpsOffset: { lng: this.state.xOffset, lat: this.state.yOffset },
      })
    )
    this.props.dispatch(toggleGPSOffsetMarkerVisible(null))
  }

  handleCancel = () => {
    this.props.dispatch(toggleGPSOffsetMarkerVisible(null))
  }

  render() {
    return (
      <Paper className="adjust_position_dialog">
        <Typography variant="subtitle1">
          {i18n.t(keys.adjustCurrentGPSMapPosition)}
        </Typography>
        <Typography variant="caption">
          {i18n.t(keys.moveTheMapToSetCorrectLocation)}
        </Typography>
        <div className="actions">
          <Button className="submit_offset_btn" onClick={this.handleConfirm}>
            {i18n.t(keys.generic.confirm)}
          </Button>
          <Button onClick={this.handleCancel}>
            {i18n.t(keys.generic.cancel)}
          </Button>
        </div>
      </Paper>
    )
  }

  handleMapMove = () => {
    const { position } = this.props
    const latLng = this.props.map.getCenter()

    if (this.marker) {
      this.marker.setLngLat(latLng)
    }

    if (position) {
      // Math.round does toFixed(7) keeping the number type
      const xOffset =
        Math.round((latLng.lng - position.coords.longitude) * 1e7) / 1e7
      const yOffset =
        Math.round((latLng.lat - position.coords.latitude) * 1e7) / 1e7

      this.setState({ xOffset, yOffset })
    }
  }
}

const mapState = (state: RootStore) => ({
  position: state.postgis.userPosition,
  isPollingUserPosition: state.postgis.isPollingUserPosition,
  preferredUnitSystem: state.preferences.preferredUnitSystem,
  isGPSOffsetMarkerVisible: state.postgis.isGPSOffsetMarkerVisible,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withMap(GPSOffsetMarkerControl)
)
