import * as React from 'react'
import { StopData } from '../../../../../ProductStats/types'
import { useRedux } from '../../../../../hooks/useRedux'
import { selectActiveProductSettings } from '../../../../../ProductSettings/store/selectors/selectActiveProductSettings'
import { selectSelectedLegendProductId } from '../../../../../data/selectSelectedLegendProductId'
import {
  selectBestUnitsByProductId,
  selectCalculateStats,
  selectConvertedSelectedColorProfiles,
} from '../../../../../ProductStats/selectors/stats'
import { selectMapLayerDefsById } from '../../../../../data/selectListMapSourceDefs'
import { selectPreferredLanguage } from '../../../../../data/selectPreferredLanguage'
import { toStopData } from '../../../../../ProductStats/toStopData'
import AsyncSelectorStatusOverlay from '../../../../../AsyncSelector/AsyncSelectorStatusOverlay'

import { useRateMapContext } from '../../RateMapContext'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { Legend } from '../../../../../stats/UI/Legend'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 60,
      width: '400px',
      display: 'flex',
      position: 'relative',
      color: theme.palette.text.primary,
      userSelect: 'none',
    },
  })
)

const RateMapColorLegend = () => {
  const [state] = useRedux()
  const { setSelectedZoneIndex } = useRateMapContext()
  const settings = selectActiveProductSettings(state)
  const productId = selectSelectedLegendProductId(state)!
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId]
  const colorProfile =
    selectedColorProfiles &&
    (selectedColorProfiles[settings.visualization!] ||
      selectedColorProfiles.absolute ||
      selectedColorProfiles.relative ||
      selectedColorProfiles.threshold)
  const productDef = selectMapLayerDefsById(state)[productId]
  const lang = selectPreferredLanguage(state)
  const unit = selectBestUnitsByProductId(state)[productId]
  const preferredUnitSystem = state.preferences.preferredUnitSystem
  const calculateStats = selectCalculateStats(state)

  const classes = useStyles()

  const { stops, minLabel, maxLabel } = React.useMemo(
    () =>
      toStopData({
        lang,
        unit,
        preferredUnitSystem,
        colorProfile: colorProfile as any,
        layerDef: productDef,
      }),
    [lang, unit, preferredUnitSystem, colorProfile, productDef]
  )

  const handleIsolatedStops = React.useCallback(
    (item: StopData, event: React.MouseEvent) => {
      const prevSelectedZoneIndex = settings.isolatedStops
        ? settings.isolatedStops[0]
        : undefined
      const selectedZoneIndex = stops.findIndex((stop) => stop.key === item.key)
      if (prevSelectedZoneIndex === selectedZoneIndex) {
        setSelectedZoneIndex?.(undefined)
      } else {
        setSelectedZoneIndex?.(selectedZoneIndex)
      }
    },
    [setSelectedZoneIndex, settings.isolatedStops, stops]
  )

  if (!colorProfile || !productDef) {
    return (
      <AsyncSelectorStatusOverlay requests={calculateStats}>
        <div className={classes.root} />
      </AsyncSelectorStatusOverlay>
    )
  }

  return (
    <AsyncSelectorStatusOverlay requests={calculateStats}>
      <div className={classes.root}>
        <Legend
          layerDef={productDef}
          stops={stops}
          minLabel={minLabel}
          maxLabel={maxLabel}
          selected={settings.isolatedStops}
          orientation="horizontal"
          interactive
          onClickItem={handleIsolatedStops}
        />
      </div>
    </AsyncSelectorStatusOverlay>
  )
}

export default RateMapColorLegend
