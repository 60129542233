import * as React from 'react'
import { PureComponent } from 'react'
import { Input, ListItem, FormControl, InputLabel } from '@mui/material'
import { selectParcelFilterInfo } from '../../data/selectParcelFilterInfo'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import * as actions from '../actions'

interface State {
  open: boolean
}

class ParcelFilter extends PureComponent<ReduxProps & AppDispatchProps, State> {
  state: State = {
    open: false,
  }

  render() {
    return (
      <ListItem dense>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.forms.parcelFilter.search)}</InputLabel>
          <Input
            value={this.props.parcelFilterInfo.searchTerm ?? ''}
            onChange={this.handleSearchTermChange}
            fullWidth
          />
        </FormControl>
      </ListItem>
    )
  }

  handleSearchTermChange = (event: any) => {
    this.props.dispatch(
      actions.setParcelFilterInfo({
        ...this.props.parcelFilterInfo,
        searchTerm: event.target.value,
      })
    )
  }
}

const mapState = (state: RootStore) => ({
  parcelFilterInfo: selectParcelFilterInfo(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(ParcelFilter)
