import {
  Chart,
  ChartData,
  ChartOptions,
  ChartType,
  registerables,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import * as React from 'react'
import './ChartJS.scss'

let initialized = false
const init = () => {
  if (initialized) {
    return
  }

  Chart.register(...registerables, ChartDataLabels)

  initialized = true
}

export interface ChartJSProps {
  type: ChartType
  options: ChartOptions
  data: ChartData
}

export default function ChartJS({ type, options, data }: ChartJSProps) {
  const ref = React.useRef<HTMLCanvasElement>(null)
  const [chart, setChart] = React.useState<Chart>()

  React.useEffect(() => {
    if (chart) {
      chart.config.options = options
      chart.config.data = data
      chart.update()
    }
  }, [options, data]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    init()
    setChart(new Chart(ref.current!, { type, options, data }))
    return () => {
      chart?.destroy()
    }
  }, [type])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chartjs-container">
      <canvas ref={ref}></canvas>
    </div>
  )
}
