import * as React from 'react'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import { selectGetPipeline } from './maestroSelectors'
import PipelineGraph from './PipelineGraph'

export interface PipelineUIProps {
  pipelineSelector: ReturnType<typeof selectGetPipeline>
  selectedJobId: string | undefined
  pipelineId: string | undefined
  onRefresh?: () => void
  onJobClick?: (jobId: string) => void
  onPipelineClick?: (pipelineId: string) => void
}

export class PipelineUI extends React.PureComponent<PipelineUIProps> {
  render() {
    const {
      pipelineSelector,
      selectedJobId,
      onRefresh,
      onJobClick,
      onPipelineClick,
    } = this.props

    const jobs = pipelineSelector.data ? pipelineSelector.data.jobs : []

    const jobDependencies = pipelineSelector.data
      ? pipelineSelector.data.jobDependencies
      : []

    return (
      <AsyncSelectorStatusOverlay
        style={{ minHeight: 300 }}
        requests={pipelineSelector}
      >
        {pipelineSelector.data && (
          <>
            <EnhancedTableToolbar
              style={{ paddingLeft: 0, paddingRight: 0 }}
              title={`${pipelineSelector.data?.name} pipeline`}
              numSelected={0}
              onClickRefresh={onRefresh}
            />
            <PipelineGraph
              jobClicked={onJobClick}
              pipelineClicked={onPipelineClick}
              selectedJobId={selectedJobId}
              pipelineName={pipelineSelector.data.name}
              jobDependencies={jobDependencies}
              jobs={jobs}
            />
          </>
        )}
      </AsyncSelectorStatusOverlay>
    )
  }
}
