import { useState } from 'react'

interface ILoader<T> {
  load: (action: () => Promise<T>) => Promise<T>
  isLoading: boolean
}

/**
 * A hook that returns a function that can be called to start some kind of loading. The function takes in the action that requires loading.
 * Before the action starts, isLoading is set to true and once the action is complete, it is set to false.
 * @returns Returns a function that is called when loading is needed and current state of isLoading.
 */
export const useIsLoading = <T>(): ILoader<T> => {
  const [isLoading, setIsLoading] = useState(false)

  return {
    load: async (action: () => Promise<T>) => {
      setIsLoading(true)
      const loadedActionContent = await action()
      setIsLoading(false)
      return loadedActionContent
    },
    isLoading,
  }
}
