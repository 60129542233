import { createSelector } from 'reselect'
import filterTruthy from '../util/filterTruthy'
import { indexArray, indexMultiArrayMappedKey } from '../util/indexArray'
import { selectAvailableProductIds } from './selectAvailableProductIds'
import { selectMapLayerDefsById } from './selectListMapSourceDefs'

export const selectAvailableProducts = createSelector(
  [selectAvailableProductIds, selectMapLayerDefsById],
  (availableProductIds, productDefsById) => {
    const allProducts = availableProductIds
      .map((id) => productDefsById[id])
      .filter(filterTruthy)

    return {
      products: allProducts.sort((a, b) => a.order - b.order).filter(
        ({ mapSourceDef: { type } }) => type !== 'raster-background'
      ),
      backgroundProducts: allProducts.filter(
        ({ mapSourceDef: { type } }) => type === 'raster-background'
      ),
    }
  }
)

export const selectAvailableProductsById = createSelector(selectAvailableProducts,
  ({ products }) => indexArray(products, 'id'))

export const selectAvailableProductsByGroup = createSelector(selectAvailableProducts,
  ({ products }) => indexMultiArrayMappedKey(products, (product) => {
    return product.mapLayerDefGroupId || product.id
  }))