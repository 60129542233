import { gql, query } from '../graphql/client'
import { DeliveryGroupFile } from '../graphql/types'
import { indexArray } from '../util/indexArray'

export type GroupFile = Pick<
  DeliveryGroupFile,
  'status' | 'filename' | 'groupId' | 'deliveryId' | 'file'
>

export const fetchDeliveryGroupFiles = async ([deliveryId, groupId]: [
  deliveryId: string | undefined,
  groupId: string | undefined
]) => {
  if (!deliveryId || !groupId) {
    return
  }

  const { files } = await query<{
    files: GroupFile[]
  }>({
    query: gql`
      query DELIVERY_GROUP_FILES($deliveryId: uuid!, $groupId: Int!) {
        files: DeliveryGroupFile(
          where: {
            deliveryId: { _eq: $deliveryId }
            groupId: { _eq: $groupId }
          }
          distinct_on: filename
        ) {
          deliveryId
          groupId
          filename
          status
          url
          file: File {
            downloadToken
          }
        }
      }
    `,
    variables: {
      deliveryId,
      groupId,
    },
  })

  return indexArray(files, 'filename')
}
