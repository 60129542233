import * as React from 'react'
import {
  SamplePlanCreateDataSet,
  SamplingStatisticalMethodField,
} from '../../../../../../graphql/types'
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useRedux } from '../../../../../../hooks/useRedux'
import { selectPreferredLanguage } from '../../../../../../data/selectPreferredLanguage'
import i18n, { keys } from '../../../../../../i18n'
import { Info } from '@mui/icons-material'

interface Props {
  statisticalMethodTypeId: number | string
  currentNewSamplingPlan: SamplePlanCreateDataSet
  field: SamplingStatisticalMethodField
  onFieldChanged: (name: string, value: string) => void
  open?: boolean
  onToggle?: (open: boolean) => void
}

const zonesPerBlockRange = [2, 3, 4, 5]
const rowsPerBlockRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const excludeEdgeVinesRange = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const clusterValues = ['true', 'false']

export const SamplePlanSetUpFormField = ({
  statisticalMethodTypeId,
  currentNewSamplingPlan,
  field,
  onFieldChanged,
}: Props) => {
  const [state] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)

  const setFieldDropdown = (e: SelectChangeEvent<any>) => {
    onFieldChanged(e.target.name, e.target.value)
  }

  React.useEffect(() => {
    if (
      currentNewSamplingPlan.statisticalMethodTypeId ===
        statisticalMethodTypeId &&
      (currentNewSamplingPlan.statisticalMethodFields[field.type] ===
        undefined ||
        currentNewSamplingPlan.statisticalMethodFields[field.type] === null) &&
      field.defaultValue !== null
    ) {
      onFieldChanged(field.type, field.defaultValue)
    }
  }, [
    currentNewSamplingPlan.statisticalMethodTypeId,
    onFieldChanged,
    field.type,
    field.defaultValue,
    statisticalMethodTypeId,
    currentNewSamplingPlan.statisticalMethodFields,
  ])

  if (!currentNewSamplingPlan?.statisticalMethodTypeId) {
    return null
  }

  if (
    currentNewSamplingPlan.statisticalMethodTypeId !== statisticalMethodTypeId
  ) {
    return null
  }

  const range =
    field.type === 'zones-per-block'
      ? zonesPerBlockRange
      : field.type === 'rows-per-block'
      ? rowsPerBlockRange
      : field.type === 'exclude-edge-vines'
      ? excludeEdgeVinesRange
      : clusterValues

  switch (field.type) {
    case 'zones-per-block':
    case 'rows-per-block':
    case 'exclude-edge-vines':
      return (
        <Stack direction="column" spacing={1} flexBasis="50%">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingRight={1}
            paddingLeft={1}
          >
            <Typography fontSize={12} fontWeight={400}>
              {field.name[preferredLanguage]}
            </Typography>
            <Tooltip title={field.description[preferredLanguage]}>
              <Info sx={{ width: 12, height: 12 }} />
            </Tooltip>
          </Stack>
          <FormControl size="small">
            <Select
              variant="outlined"
              name={field.type}
              value={
                currentNewSamplingPlan.statisticalMethodFields?.[field.type] ??
                ''
              }
              required={field.required}
              size="small"
              onChange={setFieldDropdown}
              displayEmpty
              sx={{
                backgroundColor: 'var(--background2)',
              }}
            >
              <MenuItem key="none" disabled value="">
                <em style={{ color: 'grey' }}>
                  {i18n.t(keys.samplePlanNewSampleFieldPlaceholderLabel)}
                </em>
              </MenuItem>
              {range.map((i) => (
                <MenuItem key={i} value={i}>
                  {i.toString() === field.defaultValue ? (
                    <em style={{ color: 'grey' }}>{i}</em>
                  ) : (
                    i
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )
    case 'cluster':
      return (
        <Stack direction="column" spacing={1} flexBasis="50%">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingRight={1}
            paddingLeft={1}
          >
            <Typography fontSize={12} fontWeight={400}>
              {field.name[preferredLanguage]}
            </Typography>
            <Tooltip title={field.description[preferredLanguage]}>
              <Info sx={{ width: 12, height: 12 }} />
            </Tooltip>
          </Stack>
          <FormControl size="small">
            <Select
              variant="outlined"
              name={field.type}
              value={
                currentNewSamplingPlan.statisticalMethodFields?.[field.type] ??
                ''
              }
              required={field.required}
              size="small"
              onChange={setFieldDropdown}
              displayEmpty
              sx={{
                backgroundColor: 'var(--background2)',
              }}
            >
              {range.map((i) => (
                <MenuItem key={i} value={i}>
                  {i.toString() === field.defaultValue ? (
                    <em style={{ color: 'grey' }}>
                      {i === 'true'
                        ? i18n.t(keys.generic.on)
                        : i18n.t(keys.generic.off)}
                    </em>
                  ) : i === 'true' ? (
                    i18n.t(keys.generic.on)
                  ) : (
                    i18n.t(keys.generic.off)
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )
    default:
      return null
  }
}
