import * as Sentry from '@sentry/browser'
import { PaintOptions } from './colorProfileToPaint'

export function isolateStops(
  { display, noData, coverage, property, stops }: PaintOptions,
  isolateIndex: (string | number)[]
) {
  const filters: any[] = ['any']

  if (noData && isolateIndex.includes('noData')) {
    filters.push([
      '==',
      ['to-string', ['get', noData.property]],
      `${noData.value}`,
    ])
  }

  if (coverage && isolateIndex.includes('coverage')) {
    filters.push(['<=', ['get', coverage.property], coverage.min])
  }

  Sentry.addBreadcrumb({
    category: 'maps',
    data: {
      stops,
      property,
      isolateIndex,
    },
  })

  filters.push(
    ...isolateIndex
      .filter((index): index is number => typeof index === 'number')
      .map((index) =>
        display === 'class'
          ? isolateClassStop(stops, index, property, noData, coverage)
          : isolateValueStop(stops, index, property, noData, coverage)
      )
  )

  return filters
}

function isolateValueStop(
  stops: PaintOptions['stops'],
  index: number,
  property: string,
  noData: PaintOptions['noData'],
  coverage: PaintOptions['coverage']
) {
  let filter: any

  if (index === 0) {
    filter = ['<', ['get', property], stops[1][0]]
  } else if (index === stops.length - 1) {
    filter = ['>=', ['get', property], stops[stops.length - 1][0]]
  } else if (index < stops.length - 1) {
    filter = [
      'all',
      ['>=', ['get', property], stops[index][0]],
      ['<', ['get', property], stops[index + 1][0]],
    ]
  }

  return noDataAndCoverage(filter, noData, coverage)
}

function isolateClassStop(
  stops: PaintOptions['stops'],
  index: number,
  property: string,
  noData: PaintOptions['noData'],
  coverage: PaintOptions['coverage']
) {
  const filter = ['==', ['to-string', ['get', property]], `${stops[index][0]}`]

  return noDataAndCoverage(filter, noData, coverage)
}

function noDataAndCoverage(
  filter: any[],
  noData: PaintOptions['noData'],
  coverage: PaintOptions['coverage']
) {
  if (noData) {
    // tslint:disable-next-line: no-parameter-reassignment
    filter = ['all', filter]

    if (noData) {
      filter.push([
        '!=',
        ['to-string', ['get', noData.property]],
        `${noData.value}`,
      ])
    }
    if (coverage) {
      filter.push(['>', ['get', coverage.property], coverage.min])
    }
  }

  return filter
}
