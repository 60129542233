import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import errorAlert from '../../../admin/errorAlert'
import SplitSelectionMap from '../../../admin/UI/SplitSelectionMap'
import { RouteParams, url, urls } from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectMe } from '../../../data/selectMe'
import { refreshOrganizationSubscriptions } from '../../../data/selectOrganizationSubscriptions'
import * as api from '../../../graphql/api'
import { Model, Order_update_input } from '../../../graphql/types'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { NotificationButton } from '../../../UI/NotificationButton'
import coalesce from '../../../util/coalesce'
import vvapi from '../../../vvapi'
import { postJson } from '../../../vvapi/apiResource/createApiResource'
import { CopyOrderDialog } from './CopyOrderDialog'
import OrderPackageSelection from './Inputs/OrderPackageSelection'
import { selectMostRecentTargetDeliveryUpdatedAtOrCreatedAt } from './Inputs/selectMostRecentTargetDeliveryUpdatedAtOrCreatedAt'
import OrderForm from './OrderForm'
import { refreshGetOrder, selectGetOrder } from './selectGetOrder'
import { refreshListOrders } from './selectListOrders'
import { selectOrderSubscriptions } from './selectOrderSubscriptions'
import OrderPage from './OrderPage'

interface EditOrderState {
  selectedParcelIds: string[]
  zoomToSelection?: { groupId: string } | { parcelId: string }
  copyOrderDialogOpen: boolean
}

class EditOrder extends React.PureComponent<
  RouteComponentProps<RouteParams> & ReduxProps,
  EditOrderState
> {
  state: EditOrderState = {
    selectedParcelIds: [],
    copyOrderDialogOpen: false,
  }

  handleCopyClick = () => {
    this.setState({ copyOrderDialogOpen: true })
  }

  handleCopyOrderSubmit = async (comment: string, year: number) => {
    const { order } = this.props

    if (!order?.data) {
      return
    }

    const { orderId } = await postJson(
      `/api/v3/orders/${order.data!.id}/copy`,
      { body: { year, comment } }
    )

    this.setState({ copyOrderDialogOpen: false })

    this.props.history.push(url(urls.editOrder, { orderId }))
  }
  handleCopyOrderCancel = () => {
    this.setState({ copyOrderDialogOpen: false })
  }

  render() {
    const { order, subscriptions, user } = this.props
    const { selectedParcelIds, zoomToSelection, copyOrderDialogOpen } =
      this.state

    return (
      <OrderPage
        title={`Edit Order`}
        backTo={url(urls.listOrders)}
        backToTitle="Orders"
      >
        <CopyOrderDialog
          open={copyOrderDialogOpen}
          comment={order.data?.comment}
          onSubmit={this.handleCopyOrderSubmit}
          onCancel={this.handleCopyOrderCancel}
        />

        <SplitSelectionMap
          variant="order"
          selectedParcelIds={selectedParcelIds}
          zoomToSelection={zoomToSelection}
        >
          <div
            className="MapDataContainerSmall"
            style={{ margin: 0, padding: 16, width: '100%', minWidth: 500 }}
          >
            <AsyncSelectorStatusOverlay requests={order}>
              <OrderForm
                type="edit"
                data={order.data}
                subscriptions={subscriptions}
                onSave={this.handleCreate}
                onCopyOrder={
                  user?.roles.includes('admin')
                    ? this.handleCopyClick
                    : undefined
                }
                // todo onDelete={this.handleDelete}
              />
              <OrderPackageSelection
                onSelectionChange={this.handleSelectionChanged}
                onZoomToSelection={this.handleZoomToSelection}
              />
              {this.renderNotificationButton()}
            </AsyncSelectorStatusOverlay>
          </div>
        </SplitSelectionMap>
      </OrderPage>
    )
  }

  sendNotification = async (updated = false) => {
    const { order } = this.props
    if (!order.data) {
      return
    }

    await vvapi.notification.sendOrderConfirmation(order.data.id, updated)
    refreshGetOrder()
  }

  renderNotificationButton = () => {
    const { order, mostRecentUpdatedTargetDeliveryDate, user } = this.props
    if (!order.data || !user?.roles.includes('admin')) {
      return
    }

    const isUpdated =
      !!mostRecentUpdatedTargetDeliveryDate &&
      !!order.data.orderConfirmationSentAt &&
      mostRecentUpdatedTargetDeliveryDate >
        Date.parse(`${order.data.orderConfirmationSentAt}+00:00`)

    return (
      <div className="Paper">
        <NotificationButton
          notificationName="Order Confirmation"
          sentAt={order.data.orderConfirmationSentAt}
          send={this.sendNotification}
          update={isUpdated}
        />
      </div>
    )
  }

  handleSelectionChanged = (selectedParcelIds: string[]) => {
    this.setState({ selectedParcelIds })
  }

  handleZoomToSelection = (
    zoomToSelection: { groupId: string } | { parcelId: string }
  ) => {
    this.setState({ zoomToSelection })
  }

  handleCreate = async (formData: Order_update_input) => {
    const {
      comment,
      priority,
      quoteId,
      invoiceId,
      purchaseOrderId,
      migratedSubscriptionId,
    } = formData
    const {
      order: { data: order },
    } = this.props

    if (!order) {
      return
    }

    try {
      await api.order.update<Model>({
        pk: { id: order.id },
        input: {
          priority: coalesce(priority, order.priority),
          comment: coalesce(comment, order.comment),
          quoteId: coalesce(quoteId, order.quoteId),
          invoiceId: coalesce(invoiceId, order.invoiceId),
          purchaseOrderId: coalesce(purchaseOrderId, order.purchaseOrderId),
          migratedSubscriptionId:
            migratedSubscriptionId === -1
              ? null
              : coalesce(migratedSubscriptionId, order.migratedSubscriptionId),
        },
      })

      refreshListOrders()
      refreshGetOrder()
      refreshOrganizationSubscriptions()
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Delivery', message, this.state)
    }
  }

  goHome = () => {
    this.props.history.push(url(urls.listOrders))
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'EditOrder',
    },
  })

const mapState = (state: RootStore) => ({
  order: selectGetOrder(state),
  subscriptions: selectOrderSubscriptions(state),
  mostRecentUpdatedTargetDeliveryDate:
    selectMostRecentTargetDeliveryUpdatedAtOrCreatedAt(state),
  user: selectMe(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(EditOrder)
)
