import { client, gql } from '../../../graphql/client'

export const toggleWatchDelivery = async (
  deliveryId: string,
  userId: string,
  watch: boolean
) => {
  if (watch) {
    await client.request({
      query: gql`
        mutation WATCH_DELIVERY($deliveryId: uuid!, $userId: Int!) {
          insert_Delivery_Watcher_one(
            object: { deliveryId: $deliveryId, userId: $userId }
          ) {
            deliveryId
            userId
          }
        }
      `,
      variables: { deliveryId, userId },
    })
  } else {
    await client.request({
      query: gql`
        mutation UNWATCH_DELIVERY($deliveryId: uuid!, $userId: Int!) {
          delete_Delivery_Watcher_by_pk(
            deliveryId: $deliveryId
            userId: $userId
          ) {
            userId
            deliveryId
          }
        }
      `,
      variables: { deliveryId, userId },
    })
  }
}
