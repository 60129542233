import Page, { PageProps } from '../../app/Page'
import * as React from 'react'
import Container from '../../UI/Container'
import { Column } from '../UI/Column'

class MaestroPage extends React.PureComponent<PageProps> {
  render() {
    const { children, ...rest } = this.props

    return (
      <Page {...rest}>
        <Column
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Container>{this.props.children}</Container>
        </Column>
      </Page>
    )
  }
}

export default MaestroPage
