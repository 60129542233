import { Button, ButtonProps } from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../../i18n'
import { useHistory } from 'react-router-dom'
import { createRateMap } from '../queries'
import { useRedux } from '../../../hooks/useRedux'
import { Add } from '@mui/icons-material'
import { selectOrganization } from '../../../data/selectOrganization'
import { getSelectedFlightDate } from '../../../data/userSelectionRedux'
import { selectGroupDates, selectParcels } from '../../../data/selectOrgMapData'
import { selectSelectedParcelIds } from '../../../data/selectSelectedParcelIds'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../constants'

export const NewRateMapButton = (props: ButtonProps) => {
  const [state] = useRedux()
  const history = useHistory()
  const organization = selectOrganization(state)
  const mainMapBlockOptions = selectParcels(state)
  const mainMapFlightDate = getSelectedFlightDate(state)
  const mainMapBlockSelections = selectSelectedParcelIds(state)
  const groupDates = selectGroupDates(state)
  const groupDatesWithRateMapZoneData = React.useMemo(() => {
    return groupDates.filter((gd) => {
      return gd.mapLayerDefIds.some(
        (id) => id === pureVineZonesLayerID || id === vigorZonesLayerID
      )
    })
  }, [groupDates])

  const selectedGroupDate = React.useMemo(() => {
    return groupDatesWithRateMapZoneData.find(
      (gd) => gd.flightDate === mainMapFlightDate
    )
  }, [groupDatesWithRateMapZoneData, mainMapFlightDate])

  const rateMapFlightDateAvailability = React.useMemo(() => {
    return selectedGroupDate !== undefined
  }, [selectedGroupDate])

  const rateMapBlockOptions = React.useMemo(() => {
    return mainMapBlockOptions.filter((parcel) => {
      return parcel.flightDates.includes(mainMapFlightDate ?? '')
    })
  }, [mainMapBlockOptions, mainMapFlightDate])

  const handleClick = React.useCallback(async () => {
    const newRateMap = await createRateMap({
      name: `Rate Map - ${organization?.name} - ${i18n.toDateShort(
        new Date()
      )}`,
      organizationId: organization?.id ?? '',
      flightDate: rateMapFlightDateAvailability ? mainMapFlightDate : undefined,
      blocks: rateMapFlightDateAvailability ? rateMapBlockOptions : undefined,
      selectedBlockIds: rateMapFlightDateAvailability
        ? mainMapBlockSelections
        : undefined,
    })
    history.push(`/rate-map/${newRateMap.id}`)
  }, [
    organization?.name,
    organization?.id,
    rateMapFlightDateAvailability,
    mainMapFlightDate,
    rateMapBlockOptions,
    mainMapBlockSelections,
    history,
  ])

  return (
    <Button startIcon={<Add />} onClick={handleClick} {...props}>
      {i18n.t(keys.newRateMap)}
    </Button>
  )
}
