import { client, gql } from '../../../graphql/client'

export const updateParcelName = (id: number | string, name: string) =>
  client.request({
    query: gql`
      mutation UpdateParcelName($id: Int!, $name: String!) {
        update_Parcel_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
          id
          name
        }
      }
    `,
    variables: { id, name },
  })
