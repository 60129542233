import * as React from 'react'
import { appendOpacity } from '../../util/stringToColour'
import { Chip } from '@mui/material'

interface Props {
  templateName: string
  noteFormColor?: string
}

export const SamplePlanFormChip = ({ templateName, noteFormColor: noteFormColour }: Props) => {
  return (
    <Chip
      sx={{
        height: '25px',
        backgroundColor: appendOpacity(noteFormColour ?? '#2196F3', 0.25),
        transition: 'background-color 500ms'
      }}
      label={templateName}
    />
  )
}
