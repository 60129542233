import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import Page from '../../app/Page'
import { RouteParams, url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../graphql/api'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import SourcePropertyList from '../mapdata/source-properties/SourcePropertyList'
import assertDefinedFields from '../mapdata/utils/assertDefinedFields'
import MapSourceDefMapLayerDefList from '../MapLayerDef/MapSourceDefMapLayerDefList'
import MapSourceFilenameList from './filenames/MapSourceFilenameList'
import MapSourceDefForm from './MapSourceDefForm'
import {
  GetMapSourceDefData,
  refreshGetMapSourceDef,
  selectGetMapSourceDef,
} from './selectGetMapSourceDef'
import { refreshListMapSourceDefs } from './selectListMapSourceDefs'

class EditMapSourceDef extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps<RouteParams>
> {
  render() {
    const { mapSourceDefId } = this.props.match.params
    const { mapSourceDef } = this.props
    const {
      MapSourceProperties = [],
      MapLayerDefs = [],
      MapSourceDefFilenames = [],
    } = mapSourceDef.data ?? {}

    return (
      <Page
        title={`Edit Source`}
        backTo={url(urls.listSourceDefs)}
        backToTitle="Source Defs"
      >
        <div id="EditMapSourceDef" className="MapDataContainerSmall">
          <AsyncSelectorStatusOverlay requests={mapSourceDef}>
            <MapSourceDefForm
              type="edit"
              data={mapSourceDef.data}
              onSave={this.handleUpdate}
              onDelete={this.handleDelete}
            />
            <div className="Paper">
              <MapSourceFilenameList
                mapSourceDefId={mapSourceDefId!}
                filenames={MapSourceDefFilenames}
                onChange={this.handleRefresh}
              />
              <SourcePropertyList
                mapSourceDefId={mapSourceDefId!}
                properties={MapSourceProperties}
                onChange={this.handleRefresh}
              />
              <MapSourceDefMapLayerDefList
                mapSourceDefId={mapSourceDefId!}
                layerDefs={MapLayerDefs}
                onChange={this.handleRefresh}
              />
            </div>
          </AsyncSelectorStatusOverlay>
        </div>
      </Page>
    )
  }

  handleUpdate = async (formData: Partial<GetMapSourceDefData>) => {
    const { data: mapSourceDef } = this.props.mapSourceDef

    if (!mapSourceDef) {
      return
    }

    const { name, description, type } = formData
    const requiredFields = {
      name,
      description,
      type,
    }

    if (assertDefinedFields(requiredFields)) {
      await api.mapSourceDef.update({
        pk: { id: mapSourceDef.id },
        input: requiredFields,
      })
      this.handleRefresh()
    }

    this.goHome()
  }

  goHome = () => {
    this.props.history.push(url(urls.listSourceDefs))
  }

  handleDelete = async () => {
    const { data: mapSourceDef } = this.props.mapSourceDef

    if (!mapSourceDef) {
      return
    }

    const choice = await swal(
      `You are about to delete ${mapSourceDef.name.en}`,
      {
        buttons: {
          cancel: true,
          confirm: {
            text: 'Delete',
          },
        },
        dangerMode: true,
      }
    )

    // swal returns null for "cancel"
    if (!choice) {
      return
    }

    await api.mapSourceDef.delete({ pk: { id: mapSourceDef.id } })
    this.handleRefresh()
    this.goHome()
  }

  handleRefresh = () => {
    refreshGetMapSourceDef()
    refreshListMapSourceDefs()
  }
}

const mapState = (state: RootStore) => ({
  mapSourceDef: selectGetMapSourceDef(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(EditMapSourceDef)
)
