import { default as swal } from 'sweetalert'
import errorAlert from '../../../admin/errorAlert'
import * as api from '../../../graphql/api'
import { GetMapSourceDefData } from '../../MapSourceDef/selectGetMapSourceDef'

const deleteSourceProperty = async (
  property: UnwrapArray<GetMapSourceDefData['MapSourceProperties']>
) => {
  const choice = await swal(`You are about to delete ${property.name}`, {
    buttons: {
      cancel: true,
      confirm: {
        text: 'Delete',
      },
    },
    dangerMode: true,
  })

  // swal returns null for "cancel"
  if (!choice) {
    return
  }

  try {
    await api.mapSourceProperty.delete({ pk: { id: property.id } })
  } catch (e) {
    let message = 'Please try again or contact us'

    if (/GraphQL error/.test(e.message)) {
      message = e.message.replace(/GraphQL error:?\s?/, '')
    }

    softError(e, 'Failed Deleting Source Property', message, property)
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default deleteSourceProperty
