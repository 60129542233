import * as React from 'react'
import { PureComponent } from 'react'
import { TextField } from '@mui/material'

function randomHexByte() {
  return Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, '0')
}

export function randomColor() {
  return `#${randomHexByte()}${randomHexByte()}${randomHexByte()}`
}

interface Props {
  color: string
  onChange: (color: string) => void
}

class ColorPicker extends PureComponent<Props> {
  render() {
    const { color } = this.props

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <input type="color" value={color} onChange={this.handleColorChange} />
        <TextField value={color} onChange={this.handleColorChange} />
      </div>
    )
  }

  handleColorChange = (evt: any) => {
    this.props.onChange(evt.target.value)
  }
}

export default ColorPicker
