import * as React from 'react'
import { Checkbox, Stack, Tooltip, Typography, styled } from '@mui/material'
import { Project } from '../graphql/types'

interface Props {
  project: Project
  selected: boolean
  onSelect: (projectId: string) => void
  style?: React.CSSProperties
}

const ProjectTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-align: left;
`
const NoteCountText = styled(Typography)(
  ({ theme }) => `
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-align: left;
  color: ${theme.palette.text.primary};
  opacity: 70%;
`
)

export const ProjectItem = ({ project, selected, onSelect, style }: Props) => {
  return (
    <Stack justifyContent="space-between" flex={1} pl={1}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ ...style }}
      >
        <Stack direction="row" alignItems="center">
          <Checkbox
            hidden={project.id === 'general'}
            checked={selected}
            onClick={(e) => {
              e.stopPropagation()
              onSelect(project.id)
            }}
          />
          <Stack direction="row" spacing={0.5}>
            <Tooltip title={project.name}>
              <ProjectTitle maxWidth="125px" noWrap>
                {project.name}
              </ProjectTitle>
            </Tooltip>
            <NoteCountText>{`(${project.Notes_aggregate.aggregate.count})`}</NoteCountText>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
