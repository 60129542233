import * as React from 'react'
import { backgroundCheckerboard } from '../../../colorScheme/backgroundCheckerboard'
import { MapColorProfile } from '../../../graphql/types'

interface Props {
  display?: MapColorProfile['display'] | 'class' | null
  dataStops?: MapColorProfile['dataStops'] | [any, string][]
  noDataColor?: MapColorProfile['noDataColor'] | null
  coverageColor?: MapColorProfile['coverageColor'] | null
}

export default class ColorProfilePreview extends React.PureComponent<Props> {
  render() {
    const {
      display = 'step',
      dataStops = [],
      noDataColor,
      coverageColor,
    } = this.props

    return (
      <div
        style={{
          ...backgroundCheckerboard,
          border: '1px solid black',
          height: 30,
          width: '100%',
        }}
      >
        <div
          style={{
            height: 30,
            width: '100%',
            background: makeGradient({
              display,
              dataStops,
              noDataColor,
              coverageColor,
            }),
          }}
        />
      </div>
    )
  }
}

function makeGradient({
  display,
  dataStops,
  noDataColor,
  coverageColor,
}: Props) {
  let numOfStops = (dataStops ?? []).length

  if (noDataColor) {
    numOfStops += 1
  }

  if (coverageColor) {
    numOfStops += 1
  }

  if (numOfStops === 0) {
    return 'transparent'
  }

  const stops: Stop[] = []

  let percent = 0

  if (noDataColor) {
    // tslint:disable-next-line: no-increment-decrement
    stops.push([percent++ / numOfStops, noDataColor, 'step'])
  }

  if (coverageColor) {
    // tslint:disable-next-line: no-increment-decrement
    stops.push([percent++ / numOfStops, coverageColor, 'step'])
  }

  if (display) {
    for (const [, color] of dataStops ?? []) {
      // tslint:disable-next-line: no-increment-decrement
      stops.push([percent++ / numOfStops, color, display])
    }
  }

  return makeCSSGradient(stops)
}

type Stop = [number, string, 'step' | 'continuous' | 'class']

function makeCSSStop(
  [value, color, display]: Stop,
  index: number,
  stops: Stop[]
) {
  if (display === 'step' || display === 'class') {
    const [nextValue] = stops[index + 1] ?? [1]

    return `${color} ${100 * value}% ${100 * nextValue}%`
  }

  return `${color} ${100 * value}%`
}

function makeCSSGradient(stops: Stop[]) {
  return `linear-gradient(to right, ${stops.map(makeCSSStop).join(', ')})`
}
