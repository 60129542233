import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import * as React from 'react'
import i18n, { keys } from '../../i18n'
import StatusHomeNavigation from './StatusHomeNavigation'

class StatusHome extends React.PureComponent {
  render() {
    return (
      <Page
        title={`Status Dashboard`}
        backTo={url(urls.mapView)}
        backToTitle={i18n.t(keys.map.map)}
      >
        <div id="Status" style={{ padding: '1rem 2rem' }}>
          <div>
            <StatusHomeNavigation />
          </div>
          <div>{this.props.children}</div>
        </div>
      </Page>
    )
  }
}

export default StatusHome
