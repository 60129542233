import {
  CSSObject,
  Drawer,
  listItemButtonClasses,
  listItemTextClasses,
  styled,
  Theme,
} from '@mui/material'
import { useIsLive } from '../hooks/useIsLive'

const drawerWidth = 240

const openedMixin = (theme: Theme, isLive = false): CSSObject => ({
  width: `${drawerWidth}px !important`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  zIndex: theme.zIndex.appBar - 1,
  paddingTop: isLive ? 0 : '28px',
  [`& .${listItemTextClasses.root}`]: {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${listItemButtonClasses.root}`]: {
    height: 30,
    paddingTop: theme.spacing(1 / 4),
    paddingBottom: theme.spacing(1 / 4),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${listItemButtonClasses.selected}`]: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
})

const closedMixin = (theme: Theme, isLive = false): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'visible',
  width: `32px !important`,
  [theme.breakpoints.up('sm')]: {
    width: `32px !important`,
  },
  zIndex: theme.zIndex.appBar - 1,
  [`& .${listItemTextClasses.root}`]: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${listItemButtonClasses.root}`]: {
    height: 30,
    paddingTop: theme.spacing(1 / 4),
    paddingBottom: theme.spacing(1 / 4),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingLeft: theme.spacing(1 / 2),
  },
  [`& .${listItemButtonClasses.selected}`]: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  paddingTop: isLive ? 0 : '28px',
})

export const CollapseDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => {
  const isLive = useIsLive()

  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflow: 'visible',
    ...(open && {
      ...openedMixin(theme, isLive),
      '& .MuiDrawer-paper': openedMixin(theme, isLive),
    }),
    ...(!open && {
      ...closedMixin(theme, isLive),
      '& .MuiDrawer-paper': closedMixin(theme, isLive),
    }),
  }
})
