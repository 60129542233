import { FeatureCollection, Polygon, Point } from '@turf/helpers'
import pointsWithinPolygon from '@turf/points-within-polygon'
import { indexMultiArrayMappedKey } from '../../util/indexArray'
import { asyncForEach } from '../../util/syncArrayHelpers'
import { getAuthToken, getApiUrl } from '../../vvapi/apiResource/createApiResource'
import { fetchLayerInfo, Layer, LayerDef } from './fetchLayerInfo'

export interface StatsData {
  [productId: string]: {
    layerDef: LayerDef
    parcels: {
      [parcelId: string]: Layer
    }
  }
}

export async function statsData(ids: string[], statsZones?: FeatureCollection<Polygon>) {
  const data = await fetchLayerInfo(ids)
  const dataByMapSourceId = indexMultiArrayMappedKey(data.MapLayer, ({ MapSource: { id } }) => id)
  const layers: Layer[] = []

  await asyncForEach(Object.keys(dataByMapSourceId), { limit: 4 }, async (mapSourceId) => {
    const mapLayers = dataByMapSourceId[mapSourceId]

    if (mapLayers[0].MapSource.type === 'vector') {
      const res = await fetch(`${getApiUrl()}/api/v3/map-source/${mapSourceId}/product.geojson?token=${getAuthToken()}`)

      const mapSourceGeojson = (await res.json()) as FeatureCollection

      let properties: Record<string, string>[]
      if (statsZones) {
        properties = pointsWithinPolygon(mapSourceGeojson as FeatureCollection<Point>, statsZones).features
          .map(feature => feature.properties as Record<string, string>)
      }
      else {
        properties = mapSourceGeojson.features.map(feature => feature.properties as Record<string, string>)
      }

      for (const layer of mapLayers) {
        layers.push({
          ...layer,
          Properties: properties
        })
      }
    }
  })

  const layerDefs: Record<string, LayerDef> = {}
  for (const layerDef of data.MapLayerDef) {
    layerDefs[layerDef.id] = layerDef
  }

  const result: StatsData = {}
  for (const layer of layers) {
    const product = (result[layer.mapLayerDefId] = result[
      layer.mapLayerDefId
    ] ?? {
      layerDef: layerDefs[layer.mapLayerDefId],
      parcels: {},
    })

    if (layer.MapSource.parcelId) {
      product.parcels[layer.MapSource.parcelId] = layer
    }
  }

  return result
}
