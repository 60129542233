import getColorFromGradient from '../getColorFromGradient'

export default function resample<T extends any[]>(
  stops: T[],
  numOfStops: number,
  mode: 'linear' | 'spacing' = 'linear'
): T[] {
  if (mode === 'spacing') {
    // tslint:disable-next-line:no-parameter-reassignment
    stops = stops.map(
      ([value, color], index) => [index / (stops.length - 1), color] as T
    )
  }

  return Array.from(
    { length: numOfStops },
    (_, index) =>
      [
        index / numOfStops,
        getColorFromGradient(index / (numOfStops - 1), stops),
      ] as T
  )
}
