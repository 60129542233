import * as React from 'react'
import { PureComponent } from 'react'
import { LanguageSelection } from '../app/types'
import {
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import i18n, { keys } from '../i18n'
import languageOptions from '../i18n/languageOptions'
import { connect } from '../redux/connect'
import { updatePreferences } from '../redux/preferences/redux'
import { AppDispatchProps } from '../redux/types'

interface ReduxProps {
  selectedLanguage: string
}

class PreferredLanguage extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    return (
      <React.Fragment>
        <ListItem>
          <FormControl fullWidth>
            <InputLabel>{i18n.t(keys.language.languagePicker)}</InputLabel>
            <Select
              value={this.props.selectedLanguage}
              onChange={this.handleChangeLanguage}
              fullWidth
            >
              {languageOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
      </React.Fragment>
    )
  }

  handleChangeLanguage = (event: SelectChangeEvent) => {
    this.props.dispatch(
      updatePreferences({
        preferredLanguage: event.target.value as LanguageSelection,
      })
    )
    i18n.changeLanguage(event.target.value)
  }
}

export default connect<ReduxProps, {}, AppDispatchProps>((state) => ({
  selectedLanguage: selectPreferredLanguage(state),
}))(PreferredLanguage)
