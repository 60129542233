import * as React from 'react'

import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Skeleton } from '@mui/material'

export const SubscriptionSkeleton = () => <Card>
  <CardHeader
    avatar={
      <Skeleton>
        <Avatar variant="rounded" sx={{ width: 56, height: 56 }} />
      </Skeleton>
    }
    title={
      <Skeleton variant='text' sx={{ width: 100 }} />
    }
  />
  <CardContent>
    <Skeleton variant='text' />
    <Skeleton variant='text' />
    <Skeleton variant='text' />
  </CardContent>
  <CardActions>
    <Skeleton><Button /></Skeleton>
  </CardActions>
</Card>