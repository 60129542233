import { createSelector } from 'reselect'

import { selectAvailableProductsByGroup } from '../../../data/selectAvailableProducts'
import { MapLayerDefData } from '../../../data/types'
import i18n, { keys } from '../../../i18n'
import { selectSelectedProduct } from '../../../postgis/selectSelectedProduct'
import { RootStore } from '../../../redux/types'

const getSelectedLayersByGroup = (state: RootStore) => state.userSelection.selectedLayerByGroup

export const selectSelectedGroupProduct = createSelector(selectSelectedProduct, getSelectedLayersByGroup, (selectedProduct, selectedLayersByGroup) => {
  if (!selectedProduct || !selectedProduct.mapLayerDefGroupId) {
    return undefined
  }

  return selectedLayersByGroup?.[selectedProduct.mapLayerDefGroupId]
})

const EMPTY_GROUP_PRODUCTS: MapLayerDefData[] = []

export const selectSelectedGroupProducts = createSelector(selectSelectedProduct, selectAvailableProductsByGroup, (selectedProduct, productsGroupByGroups) =>
  selectedProduct?.mapLayerDefGroupId ? productsGroupByGroups[selectedProduct?.mapLayerDefGroupId] : EMPTY_GROUP_PRODUCTS
)

export const selectSelectedGroupPointLayer = createSelector(selectSelectedGroupProducts, (selectedGroupProducts) =>
  selectedGroupProducts.find(product => product.mapSourceDef.type === 'point' &&
    (product.dataProperty.type === 'value')
  )
)

export const selectOrderedGroupProducts = createSelector(selectSelectedGroupProducts, (products) =>
  [...products].sort((a, b) => a.order - b.order)
)

export const selectOrderedGroupProductsWithExtras = createSelector(selectSelectedGroupProducts, selectSelectedGroupPointLayer, (products, pointLayer) => {
  if (pointLayer) {
    return [...products, {
      id: `${pointLayer.id}-block-by-block`,
      layerGroupLabel: i18n.t(keys.blockByBlockAverage),
      name: ''
    }] as Pick<MapLayerDefData, 'id' | 'layerGroupLabel' | 'name'>[]
  }

  return products as Pick<MapLayerDefData, 'id' | 'layerGroupLabel' | 'name'>[]
})

export const selectOrderedGroupProductsWithExtrasIds = createSelector(selectOrderedGroupProductsWithExtras, (orderedProducts) => orderedProducts.map(({ id }) => id))