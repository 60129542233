import { TableCell, TableRow } from '@mui/material'
import * as React from 'react'
import {
  enabledStatus,
  expectedStatus,
  readyStatus,
  renderAvatar,
} from '../../../admin/Status/DeliveryStatus/ShortStatus'
import { Row } from '../../../admin/UI/Row'
import { FormControlLabel } from '@mui/material'
import { ExpandButton } from '../../../UI/Expand/ExpandButton'
import { SelectBox, selectionOf } from '../../../UI/SelectBox/SelectBox'
import { ParcelStatus } from './selectGetDetailedDeliveryStatus'

interface Props {
  parcelStatus: ParcelStatus
  parcelName: string
  parcelSelection: Record<string, boolean>
  selectionChanged: (
    selected: boolean,
    parcelName: string,
    layerName?: string
  ) => void
}

interface State {
  showDetails: boolean
}

export class DeliveryGroupParcelFiles extends React.Component<Props, State> {
  state: State = {
    showDetails: false,
  }

  render() {
    const { parcelName, parcelStatus, parcelSelection } = this.props
    const { showDetails } = this.state
    const selection = selectionOf(
      Object.values(parcelSelection ?? {}).filter((pf) => !!pf).length,
      parcelStatus.files.length
    )

    const isDirty = parcelStatus.files.some(
      (pf) =>
        (pf.MapLayer?.enabled || false) !==
        ((pf.MapLayer && parcelSelection?.[pf.MapLayer.id]) || false)
    )

    return (
      <>
        <TableRow style={{ width: '100%', alignItems: 'stretch' }}>
          <TableCell />
          <TableCell colSpan={7}>
            <Row style={{ justifySelf: 'flex-start' }}>
              <ExpandButton
                expanded={showDetails}
                onClick={() => this.setState({ showDetails: !showDetails })}
              />
              <FormControlLabel
                style={{ marginLeft: -8 }}
                label={isDirty ? `${parcelName}*` : parcelName}
                control={
                  <SelectBox
                    disabled={parcelStatus.readyCount <= 0}
                    selection={selection}
                    onChange={() => this.handleParcelSelectedChange()}
                  />
                }
              />
            </Row>
          </TableCell>
          <TableCell>
            <Row>{expectedStatus(parcelStatus)}</Row>
          </TableCell>
          <TableCell>
            <Row>{readyStatus(parcelStatus)}</Row>
          </TableCell>
          <TableCell>
            <Row>{enabledStatus(parcelStatus)}</Row>
          </TableCell>
        </TableRow>
        {showDetails &&
          parcelStatus.files.map((pf) => (
            <TableRow key={pf.mapLayerDef.name}>
              <TableCell colSpan={2} />
              <TableCell colSpan={6}>
                <FormControlLabel
                  label={
                    pf.MapLayer &&
                    !!parcelSelection[pf.MapLayer.id] !== pf.MapLayer.enabled
                      ? `${pf.mapLayerDef.name}*`
                      : pf.mapLayerDef.name
                  }
                  control={
                    <SelectBox
                      disabled={!pf.ready}
                      checked={pf.MapLayer && !!parcelSelection[pf.MapLayer.id]}
                      onChange={() => this.handleLayerSelectedChange(pf)}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <Row>{renderAvatar(pf.expected)}</Row>
              </TableCell>
              <TableCell>
                <Row>{renderAvatar(pf.ready)}</Row>
              </TableCell>
              <TableCell>
                <Row>{renderAvatar(pf.enabled)}</Row>
              </TableCell>
            </TableRow>
          ))}
      </>
    )
  }

  handleLayerSelectedChange = (
    parcelFile: UnwrapArray<ParcelStatus['files']>
  ) => {
    const { parcelName, parcelSelection, selectionChanged } = this.props

    if (!parcelFile.MapLayer) {
      return
    }

    selectionChanged(
      !parcelSelection[parcelFile.MapLayer.id],
      parcelName,
      parcelFile.MapLayer?.id
    )
  }

  handleParcelSelectedChange = () => {
    const { parcelName, parcelStatus, parcelSelection, selectionChanged } =
      this.props

    const selection = selectionOf(
      Object.values(parcelSelection).filter((pf) => !!pf).length,
      parcelStatus.files.length
    )

    selectionChanged(selection === 'unselected', parcelName)
  }
}
