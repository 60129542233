import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class LeaveGenerator implements IMapActionGenerator {
  key = 'leave'
  priority = Priority.NoAction

  generateActionsFromQualifyingFeatures({
    map,
  }: IMapActionGeneratorParams): IMapAction[] {

    // Return array of functions that can execute the action for each of the qualifying features.
    return [{
      key: this.key,
      priority: this.priority,
      execute: () => {
        map.getCanvas().style.cursor = ''
      },
    }]
  }
}
