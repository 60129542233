import * as React from 'react'
import { Project } from '../../graphql/types'
import { Menu, MenuItem, Stack, Typography } from '@mui/material'
import i18n, { keys } from '../../i18n'
import { NavigateNext } from '@mui/icons-material'

interface Props {
  currentProject: Project
  projectOptions: Record<string, Project>
  moveNotesToProject: (newProjectId: string) => void
  className: string
}

export const MoveMenuItem = ({
  currentProject,
  projectOptions,
  moveNotesToProject,
  className
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const projectList = React.useMemo(() => {
    return Object.values(projectOptions).filter(
      (p) => p.id !== currentProject.id
    )
  }, [projectOptions, currentProject])

  return (
    <>
      <MenuItem onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Stack
          className={className}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{i18n.t(keys.moveAllProjectNotesLabel)}</Typography>
          <NavigateNext />
        </Stack>
      </MenuItem>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!projectOptions['general'] && (
          <MenuItem
            onClick={() => {
              moveNotesToProject('general')
            }}
          >
            <Stack
              className={className}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                {i18n.t(keys.noteForm.reservedNames.__VV__GENERAL__TEMPLATE__)}
              </Typography>
            </Stack>
          </MenuItem>
        )}
        {projectList.map((project) => (
          <MenuItem
            key={project.id}
            onClick={() => {
              // Move all notes to project
              moveNotesToProject(project.id)
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography>{project.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
