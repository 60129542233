import { CircularProgress } from '@mui/material'
import * as React from 'react'
import { Fetched } from './createAsyncSelector'

type PickedFetched<T> = Pick<Fetched<T>, 'status'>

interface Props {
  requests: PickedFetched<any> | PickedFetched<any>[]
  retry?: () => void
  style?: React.CSSProperties
  overlayStyle?: React.CSSProperties
  hideChildrenWhenLoading?: boolean
  isLoading?: boolean
}

class AsyncSelectorStatusOverlay extends React.PureComponent<Props> {
  get status() {
    const { requests } = this.props
    const selectors = Array.isArray(requests) ? requests : [requests]

    return selectors.some((selector) => selector.status === 'none')
      ? 'none'
      : selectors.some((selector) => selector.status === 'rejected')
      ? 'rejected'
      : selectors.some((selector) => selector.status === 'pending')
      ? 'pending'
      : 'resolved'
  }

  get errors() {
    const { requests } = this.props
    const selectors = Array.isArray(requests) ? requests : [requests]

    return selectors.filter((selector) => selector.status === 'rejected')
  }

  render() {
    const { style, hideChildrenWhenLoading, children, isLoading } = this.props

    const loading = this.status === 'pending' || isLoading

    return (
      <div style={{ position: 'relative', height: '100%', ...style }}>
        {loading && hideChildrenWhenLoading ? null : children}
        {this.renderOverlay()}
      </div>
    )
  }
  renderOverlay() {
    const { isLoading, overlayStyle = {} } = this.props

    if (this.status === 'resolved' && !isLoading) {
      return null
    }

    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...overlayStyle,
        }}
      >
        {(this.status === 'pending' || isLoading) && <CircularProgress />}
        {this.status === 'rejected' && `Error: ${this.errors}`}
      </div>
    )
  }
}

export default AsyncSelectorStatusOverlay
