import * as gravatar from 'gravatar'
import * as React from 'react'

import { Avatar, Button, Stack, Tooltip, Typography } from '@mui/material'

import { DeliveryProcGroupWithNeighborsAndJobStatuses } from './deliveryProcGroupSelectors'
import { DeliveryProcGroupAssignDialog } from './DeliveryProcGroupAssignDialog'

interface Props {
  procGroup: DeliveryProcGroupWithNeighborsAndJobStatuses
  onAssign: (userId?: number) => void
  compact?: boolean
}

export const DeliveryProcGroupAssignee = ({
  procGroup,
  onAssign,
  compact = false,
}: Props) => {
  const [assignDialogOpen, setAssignDialogOpen] = React.useState(false)

  const handleOpenAssignDialog = () => {
    setAssignDialogOpen(true)
  }

  const handleCancelAssignDialog = () => {
    setAssignDialogOpen(false)
  }

  const handleAssign = (userId: number) => {
    onAssign(userId)
    setAssignDialogOpen(false)
  }

  return (
    <>
      <DeliveryProcGroupAssignDialog
        open={assignDialogOpen}
        procGroup={procGroup}
        onCancel={handleCancelAssignDialog}
        onAssign={handleAssign}
      />
      {compact ? (
        procGroup?.Assignee ? (
          <Tooltip
            title={`${procGroup.Assignee.firstName} ${procGroup.Assignee.lastName}(${procGroup.Assignee.email})`}
          >
            <Avatar
              onClick={() => handleOpenAssignDialog()}
              sx={{ height: 24, width: 24, cursor: 'pointer' }}
              alt={`${procGroup.Assignee.firstName} ${procGroup.Assignee.lastName} (${procGroup.Assignee.email})`}
              src={gravatar.url(procGroup.Assignee.email, {
                d: 'mm',
                r: 'g',
              })}
            />
          </Tooltip>
        ) : null
      ) : (
        <Stack direction="column" spacing={1} alignItems="center">
          <Typography variant="caption">Assignee:</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            {procGroup?.Assignee ? (
              <>
                <Avatar
                  sx={{ height: 24, width: 24 }}
                  alt={`${procGroup.Assignee.firstName} ${procGroup.Assignee.lastName} (${procGroup.Assignee.email})`}
                  src={gravatar.url(procGroup.Assignee.email, {
                    d: 'mm',
                    r: 'g',
                  })}
                />
                <Stack>
                  <Typography variant="caption">{`${procGroup.Assignee.firstName} ${procGroup.Assignee.lastName}`}</Typography>
                  <Typography variant="caption">{`(${procGroup.Assignee.email})`}</Typography>
                </Stack>
              </>
            ) : (
              <Typography variant="caption">Unassigned</Typography>
            )}
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleOpenAssignDialog()}
            >
              {procGroup?.Assignee ? 'Change' : 'Assign'}
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  )
}
