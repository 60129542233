export function createCancellable<CB extends (...args: any[]) => void>(cb: CB) {
  let cancelled = false

  const cancel = () => {
    cancelled = true
  }

  const callback = ((...args: any[]) => {
    if (!cancelled) {
      cb(...args)
    }
  }) as CB

  return {
    cancel,
    callback,
  }
}
