import * as React from 'react'
import { PureComponent } from 'react'
import errorAlert from '../../admin/errorAlert'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material'
import { DataSetImageState } from '../../graphql/types'
import vvapi, { DataSetImagePipelineJobParams } from '../../vvapi'
import { refreshListDataSetImages } from './selectListDataSetImages'
import { ListDataSetData, refreshListDataSets } from './selectListDataSets'

interface Props {
  // must specify one or the other
  dataSetId?: ListDataSetData['id']
  dataSetImageId?: ListDataSetData['id']
  disabled?: boolean
}

interface State {
  keepIntermediateFiles?: boolean
  skipJob?: boolean
  skipTemporal?: boolean
  open: boolean
}

interface StateTrigger {
  triggerBehavior: string
  triggerState: DataSetImageState
}

// tslint:disable-next-line: variable-name
export const stateTriggers: StateTrigger[] = [
  {
    triggerBehavior: 'TIF conversion',
    triggerState: DataSetImageState.RawUploadFinished,
  },
  {
    triggerBehavior: 'Band Alignment',
    triggerState: DataSetImageState.TifConversionFinished,
  },
  {
    triggerBehavior: 'Ortho',
    triggerState: DataSetImageState.BandAlignmentFinished,
  },
  {
    triggerBehavior: 'Atcor',
    triggerState: DataSetImageState.OrthoFinished,
  },
  {
    triggerBehavior: 'Apply Corrections',
    triggerState: DataSetImageState.AtcorFinished,
  },
  {
    triggerBehavior: 'Georeference',
    triggerState: DataSetImageState.ApplyCorrectionsFinished,
  },
  {
    triggerBehavior: 'Generate Preview',
    triggerState: DataSetImageState.GeoreferenceFinished,
  },
]

/**
 * It renders UI to change state on all datasetimages in a dataset or
 * an individual datasetimage
 */
class DataSetImageTriggerProcessing extends PureComponent<Props, State> {
  state: State = {
    open: false,
  }

  render() {
    if (!(this.props.dataSetId || this.props.dataSetImageId)) {
      return null
    }

    let title = ''
    let buttonText = ''
    if (this.props.dataSetId) {
      title = `Data Set: ${this.props.dataSetId}`
      buttonText = `Retrigger All Images`
    } else if (this.props.dataSetImageId) {
      title = `Data Set Image: ${this.props.dataSetImageId}`
      buttonText = `Retrigger ${this.props.dataSetImageId}`
    }

    return (
      <React.Fragment>
        <Button
          disabled={this.props.disabled}
          variant="outlined"
          onClick={() =>
            this.setState({
              open: true,
            })
          }
        >
          {buttonText}
        </Button>

        <Dialog open={this.state.open} fullWidth onClose={this.handleClose}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <FormControlLabel
              label="Skip Temporal Alignment"
              control={
                <Checkbox
                  checked={Boolean(this.state.skipTemporal)}
                  color="primary"
                  onClick={(evt) =>
                    this.setState({
                      skipTemporal: !this.state.skipTemporal,
                    })
                  }
                />
              }
            />
            <FormControlLabel
              label="Keep intermediate files on the windows worker (for troubleshooting)"
              control={
                <Checkbox
                  checked={Boolean(this.state.keepIntermediateFiles)}
                  color="primary"
                  onClick={(evt) =>
                    this.setState({
                      keepIntermediateFiles: !this.state.keepIntermediateFiles,
                    })
                  }
                />
              }
            />
            <FormControlLabel
              label="Skip (automatically succeeds job)"
              control={
                <Checkbox
                  checked={Boolean(this.state.skipJob)}
                  color="primary"
                  onClick={(evt) =>
                    this.setState({
                      skipJob: !this.state.skipJob,
                    })
                  }
                />
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Close</Button>
            <Button
              style={{ marginLeft: 12 }}
              size="small"
              variant="contained"
              color="primary"
              onClick={this.handleConfirm}
            >
              Trigger
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }

  handleConfirm = async () => {

    const { dataSetId } = this.props

    try {
      const jobParams: DataSetImagePipelineJobParams = {
        state: DataSetImageState.RawUploadFinished,
        keepIntermediateFiles: this.state.keepIntermediateFiles,
        skip: this.state.skipJob,
        skipTemporal: this.state.skipTemporal,
      }

      if (this.props.dataSetImageId) {
        jobParams.dataSetImageId = this.props.dataSetImageId
      } else if (dataSetId) {
        jobParams.dataSetId = dataSetId
      }

      await vvapi.admin.worker.queueDataSetImagePipelineJob(jobParams)

      refreshListDataSetImages()
    } catch (error) {
      errorAlert({
        error,
        title: `Error changing data set image state`,
        message: error.message,
      })
    }

    refreshListDataSets()

    this.setState({
      keepIntermediateFiles: undefined,
      open: false,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }
}

export default DataSetImageTriggerProcessing
