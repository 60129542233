import filterTruthy from '../../util/filterTruthy'
import { FilterBy, TableFilter, TableFormatter } from './types'

export type MultipleFilterConditional = '_and' | '_or'

export function filterRows<T>(
  rows: T[],
  tableFormatter: TableFormatter<T>[],
  filters: TableFilter
) {
  const filterFuncs = filters
    .map(({ index, op, value }) => {
      const formatter = tableFormatter[index]

      const filter = formatter?.filter

      if (filter) {
        return (a: T) => filter(a, op, value)
      }

      return null
    })
    .filter(filterTruthy)

  return rows.filter((item) => {
    for (const filter of filterFuncs) {
      if (!filter(item)) {
        return false
      }
    }

    return true
  })
}

export const FILTER = {
  serialize(filter: TableFilter) {
    return filter
      .map(({ index, op, value }) => `${index}:${op}:${JSON.stringify(value)}`)
      .join(',')
  },

  deserialize(str: string) {
    return str
      ? str.split(',').map((o) => {
          const [index, op, value] = o.split(':')

          return {
            op,
            index: Number(index),
            value: JSON.parse(value),
          } as FilterBy
        })
      : ([] as TableFilter)
  },
}

export function filterBy<T>(
  tableFormatter: TableFormatter<T>[],
  filter: TableFilter,
  multiFilterCondition?: MultipleFilterConditional
) {
  const result = filter
    .map(({ index, op, value }) => {
      const formatter = tableFormatter[index]

      if (formatter?.filterBy) {
        return formatter.filterBy(op, value)
      }

      return null
    })
    .filter(filterTruthy)

  return result.length > 0
    ? { [multiFilterCondition ?? '_and']: result }
    : undefined
}
