import { Typography } from '@mui/material'
import { default as FormControl } from '@mui/material/FormControl'
import { default as InputLabel } from '@mui/material/InputLabel'
import { default as MenuItem } from '@mui/material/MenuItem'
import { default as Select } from '@mui/material/Select'
import * as React from 'react'
import { SourceTypes } from '../../graphql/types'
import i18n from '../../i18n'
import TranslationTextField from '../../UI/TranslationTextField/TranslationTextField'
import { Form } from '../UI/Form'
import { GetMapSourceDefData } from './selectGetMapSourceDef'

// TODO: translate displays
const sourceTypes: {
  [x in SourceTypes]: string
} = {
  point: 'Point',
  line: 'Line',
  zone: 'Zone',
  'field-bounds': 'Field-Bounds',
  raster: 'Raster',
  'raster-background': 'Background-Raster',
}

const sourceTypeKeys = Object.keys(sourceTypes)

class MapSourceDefForm extends Form<GetMapSourceDefData> {
  render() {
    const {
      CreatedBy,
      UpdatedBy,
      createdAt,
      updatedAt,
      name = {},
      description = {},
      type,
    } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <h2>{this.props.type === 'edit' ? 'Edit' : 'New'} MapSourceDef</h2>
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            required
            name="name"
            label={`Name`}
            value={name}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            required
            multiline
            name="description"
            label={`Description`}
            value={description}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <FormControl fullWidth>
            <InputLabel required shrink={!!type}>
              Type{' '}
            </InputLabel>
            <Select
              name="type"
              fullWidth
              onChange={this.updateFormDataFromSelectChangeEvent}
              value={type}
            >
              {sourceTypeKeys.map((key) => (
                <MenuItem key={key} value={key}>
                  {sourceTypes[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {CreatedBy && UpdatedBy && (
          <div className="grid-xs-12">
            <Typography variant="subtitle1">
              Created By: {CreatedBy.firstName} - {i18n.toDateShort(createdAt!)}
            </Typography>
            <Typography variant="subtitle1">
              Updated By: {UpdatedBy.firstName} -{' '}
              {i18n.toDateTimeShort(updatedAt!)}
            </Typography>
          </div>
        )}
        <div className="actions grid-xs-12">{this.renderFormButtons()}</div>
      </div>
    )
  }
}

export default MapSourceDefForm
