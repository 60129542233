import { JobAttempt } from '../graphql/types'
import { postJson, putJson, getJson } from './apiResource/createApiResource'

const ROOT_PIPELINE_ID = '00000000-0000-0000-0000-000000000000'

interface BaseNode {
  id?: string
  tempId?: string
}

interface JobNode extends BaseNode {
  type: 'job'
  taskName?: string
  input?: Record<string, object | string | number>
  tags?: string[]
  isPending?: true
  priority?: number
}

interface RootNode {
  create?: Node[]
  delete?: Node[]
  update?: Node[]
}

interface PipelineNode extends RootNode, BaseNode {
  type: 'pipeline'
  id?: string
  name?: string
  tempId?: string
  isPaused?: true
  nodes: RootNode
}
type Node = JobNode | PipelineNode

const baseMaestroUrl = '/api/v3/maestro'

export const cancelJobAttempt = (jobId: string, attempt: number) =>
  putJson<JobAttempt | undefined>(
    `${baseMaestroUrl}/job-attempts/${jobId}/${attempt}/finish`,
    {
      body: { status: 'cancelled' },
    }
  )

export const reserveJob = (tasks: string[], userEmail: string) =>
  postJson<JobAttempt | undefined>(`${baseMaestroUrl}/reserve-next-job`, {
    body: { tasks, worker: userEmail },
  })

export const reserveJobById = (jobId: string, userEmail: string) =>
  postJson<JobAttempt | undefined>(`${baseMaestroUrl}/jobs/${jobId}/reserve`, {
    body: { worker: userEmail },
  })

export const queueJob = (jobId: string) =>
  postJson<JobAttempt | undefined>(`${baseMaestroUrl}/jobs/${jobId}/queue`)

export const finishJob = (
  jobId: string,
  attempt: number,
  status: JobAttempt['status'],
  output: any
) =>
  putJson(`${baseMaestroUrl}/job-attempts/${jobId}/${attempt}/finish`, {
    body: { status, output },
  })

export const sendWarning = (jobId: string, attempt: number, warnings: any[]) =>
  postJson(`${baseMaestroUrl}/job-attempts/${jobId}/${attempt}/warnings`, {
    body: { warnings },
  })

export const startJob = async (
  jobId: string,
  priority: number = 0,
  input?: any
) =>
  updateMaestroGraph({
    nodes: {
      update: [{ input, priority, id: jobId, type: 'job', isPending: true }],
    },
  })

interface AddJobParams {
  taskName: string
  input: any
  tags?: string[]
  pipelineId?: string
  pipelineName?: string
}

export const addJob = async ({
  taskName,
  input,
  tags = [],
  pipelineId,
  pipelineName,
}: AddJobParams) => {
  if (pipelineName) {
    return updateMaestroGraph({
      nodes: {
        update: [
          {
            type: 'pipeline',
            id: pipelineId ?? ROOT_PIPELINE_ID,
            nodes: {
              create: [
                {
                  type: 'pipeline',
                  tempId: 'p-1',
                  name: pipelineName,
                  nodes: {
                    create: [
                      {
                        taskName,
                        input,
                        tags,
                        tempId: 'j-1',
                        type: 'job',
                        isPending: true,
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    })
  }

  return updateMaestroGraph({
    nodes: {
      update: [
        {
          type: 'pipeline',
          id: pipelineId ?? ROOT_PIPELINE_ID,
          nodes: {
            create: [
              {
                taskName,
                input,
                tags,
                tempId: 'j-1',
                type: 'job',
                isPending: true,
              },
            ],
          },
        },
      ],
    },
  })
}

export const getPauseAllStatus = () =>
  getJson(`${baseMaestroUrl}/jobs/pause-all/status`)
export const pauseAllJobs = () =>
  postJson(`${baseMaestroUrl}/jobs/pause-all/pause`)
export const unpauseAllJobs = () =>
  postJson(`${baseMaestroUrl}/jobs/pause-all/unpause`)

const updateMaestroGraph = async (graph: { nodes: RootNode }) => {
  // tslint:disable-next-line:no-console
  console.log(JSON.stringify(graph, null, 2))

  const response = await postJson('/api/v3/maestro/jobs/graph', {
    body: graph,
  })

  // tslint:disable-next-line:no-console
  console.log('RESPONSE:', JSON.stringify(response, null, 2))

  return response
}
