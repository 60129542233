import { job } from '../../../graphql/api'
import { PaginationChange } from '../../../UI/Table/types'
import { Job } from './types'

const fetchJobQuery = `
{
  id
  taskName
  input
  timeout
  priority
  createdAt
  updatedAt
  LatestJobAttempt {
    attempt
    status
    worker
  }
}
`

export const fetchJobs = ({
  papination,
  where,
  order_by,
}: {
  papination?: PaginationChange
  where?: any
  order_by?: string
}) => {
  let offset: number | undefined
  let limit: number | undefined

  if (papination) {
    offset = papination.page * papination.pageSize
    limit = papination.pageSize
  }

  return job.list<Job>({
    returning: fetchJobQuery,
    where,
    order_by,
    offset,
    limit,
  })
}
