import { DocumentNode } from 'graphql'
import { client, gql } from '../graphql/client'
import {
  DeleteModelsQueryParams,
  GetModelQueryParams,
  InsertModelQueryParams,
  InsertModelsQueryParams,
  ListModelQueryParams,
  Model,
  ModelTable,
  PrimaryKey,
  UpdateModelQueryParams,
  UpdateModelsQueryParams,
} from './Model'

export { gql }

type DeepPartial<T> = { [K in keyof T]?: DeepPartial<T[K]> }

interface QueryResponse<T> {
  data: T
}

interface QueryListResponseInertial<T> {
  data: T[]
  info: {
    aggregate: {
      count: number
    }
  }
}

interface QueryListResponse<T> {
  data: T[]
  info: {
    limit?: number
    offset?: number
    count: number
  }
}

interface AggregateResponse<T> {
  aggregate: {
    count: number
  }
  nodes: T[]
}

interface MutationResponse<T> {
  affected_rows: number
  returning: T
}

export function createHasuraResource<M extends Model<any>>(
  table: ModelTable<M>
) {
  const tableString =
    table.schema === 'public' ? table.name : `${table.schema}_${table.name}`

  const pkVars = Object.entries(table.pkTypes)
    .map(([key, type]) => `$pk_${key}: ${type}!`)
    .join(', ')

  const pkValues = Object.keys(table.pkTypes)
    .map((key) => `${key}: $pk_${key}`)
    .join(', ')

  const queryList = (returning: DocumentNode) => gql`
    query list_${tableString}(
      $where: ${tableString}_bool_exp,
      $order_by: [${tableString}_order_by!],
      $distinct_on:  [${tableString}_select_column!],
      $limit: Int,
      $offset: Int
    ) {
      data: ${tableString}(
        where: $where,
        order_by: $order_by,
        distinct_on: $distinct_on,
        limit: $limit,
        offset: $offset
      ) ${returning}
      info: ${tableString}_aggregate(
        where: $where
      ) {
        aggregate {
          count
        }
      }
    }
  `

  const queryAggregate = (returning: DocumentNode) => gql`
    query aggregate_${tableString}(
      $where: ${tableString}_bool_exp,
      $order_by: [${tableString}_order_by!],
      $distinct_on:  [${tableString}_select_column!],
      $limit: Int,
      $offset: Int
    ) {
      data: ${tableString}_aggregate(
        where: $where,
        order_by: $order_by,
        distinct_on: $distinct_on,
        limit: $limit,
        offset: $offset
      ) ${returning}
    }
  `

  const queryGetByPK = (returning: DocumentNode) => gql`
    query get_${tableString}_by_pk(
      ${pkVars}
    ) {
      data: ${tableString}_by_pk(
        ${pkValues}
      ) ${returning}
    }
  `

  // tslint:disable-next-line: variable-name
  const queryInsert = (returning: DocumentNode, on_conflict: boolean) => gql`
    mutation insert_${tableString}(
      $objects: [${tableString}_insert_input!]!
      ${on_conflict ? `, $on_conflict:${tableString}_on_conflict` : ''} 
    ) {
      data: insert_${tableString}(
        objects: $objects
        ${on_conflict ? `, on_conflict:$on_conflict` : ''} 
      ) ${returning}
    }
  `

  // tslint:disable-next-line: variable-name
  const queryInsertOne = (returning: DocumentNode, on_conflict: boolean) => gql`
    mutation insert_${tableString}_one(
      $object: ${tableString}_insert_input!
      ${on_conflict ? `, $on_conflict:${tableString}_on_conflict` : ''} 
    ) {
      data: insert_${tableString}_one(
        object: $object, 
        ${on_conflict ? `, on_conflict:$on_conflict` : ''} 
      ) ${returning}
    }
  `

  const queryUpdate = (returning: DocumentNode, _inc: boolean) => gql`
    mutation update_${tableString}(
      $where: ${tableString}_bool_exp!, 
      ${_inc ? `$_inc: ${tableString}_inc_input,` : ``} 
      $_set: ${tableString}_set_input
    ) {
      data: update_${tableString}_by_pk(
        pk_columns: {${pkValues}}, 
        ${_inc ? `_inc:$_inc,` : ``}
        _set: $_set
      ) ${returning}
    }
  `

  const queryUpdateByPK = (returning: DocumentNode, _inc: boolean) => gql`
    mutation update_${tableString}_by_pk(
      ${pkVars},
      ${_inc ? `$_inc: ${tableString}_inc_input,` : ``} 
      $_set: ${tableString}_set_input
    ) {
      data: update_${tableString}_by_pk(
        pk_columns: {${pkValues}}, 
        ${_inc ? `_inc:$_inc,` : ``}
        _set: $_set
      ) ${returning}
    }
  `

  const queryDelete = (returning: DocumentNode) => gql`
    mutation delete_${tableString}(
      $where: ${tableString}_bool_exp!, 
    ) {
      data: delete_${tableString}(
        where: $where
      ) ${returning}
    }
  `

  const queryDeleteByPK = (returning: DocumentNode) => gql`
    mutation delete_${tableString}_by_pk(
      ${pkVars}
    ) {
      data: delete_${tableString}_by_pk(
        ${pkValues}
      ) ${returning}
    }
  `

  const pkVariables = (pk: PrimaryKey<M>) => {
    const result = {}

    for (const [key, val] of Object.entries(pk)) {
      result[`pk_${key}`] = val
    }

    return result
  }

  type Aggregate = DeepPartial<AggregateResponse<M['__model']>>
  type One = DeepPartial<M['__model']>
  type Mutation = DeepPartial<MutationResponse<M['__model']>>

  return {
    async list<T extends One>(
      options: ListModelQueryParams<M>,
      returning: DocumentNode
    ) {
      const { data, info } = await client.request<QueryListResponseInertial<T>>(
        {
          variables: options,
          query: queryList(returning),
        }
      )

      return {
        data,
        info: { count: info.aggregate.count },
      } as QueryListResponse<T>
    },
    aggregate<T extends Aggregate>(
      options: ListModelQueryParams<M>,
      returning: DocumentNode
    ) {
      return client.request<QueryResponse<T>>({
        variables: options,
        query: queryAggregate(returning),
      })
    },
    getByPK<T extends One>(
      options: GetModelQueryParams<M>,
      returning: DocumentNode
    ) {
      const { pk } = options

      return client.request<QueryResponse<T>>({
        variables: {
          ...pkVariables(pk),
        },
        query: queryGetByPK(returning),
      })
    },
    insert<T extends Mutation>(
      options: InsertModelsQueryParams<M>,
      returning: DocumentNode
    ) {
      return client.request<QueryResponse<T>>({
        variables: options,
        query: queryInsert(returning, !!options.on_conflict),
      })
    },
    insertOne<T extends One>(
      options: InsertModelQueryParams<M>,
      returning: DocumentNode
    ) {
      return client.request<QueryResponse<T>>({
        variables: options,
        query: queryInsertOne(returning, !!options.on_conflict),
      })
    },
    update<T extends Mutation>(
      options: UpdateModelsQueryParams<M>,
      returning: DocumentNode
    ) {
      return client.request<QueryResponse<T>>({
        variables: options,
        query: queryUpdate(returning, !!options._inc),
      })
    },
    updateByPK<T extends One>(
      options: UpdateModelQueryParams<M>,
      returning: DocumentNode
    ) {
      const { pk, ...rest } = options

      return client.request<QueryResponse<T>>({
        variables: {
          ...pkVariables(pk),
          ...rest,
        },
        query: queryUpdateByPK(returning, !!options._inc),
      })
    },
    delete<T extends Mutation>(
      options: DeleteModelsQueryParams<M>,
      returning: DocumentNode
    ) {
      return client.request<QueryResponse<T>>({
        variables: options,
        query: queryDelete(returning),
      })
    },
    deleteByPK<T extends One>(
      options: GetModelQueryParams<M>,
      returning: DocumentNode
    ) {
      const { pk } = options

      return client.request<QueryResponse<T>>({
        variables: {
          ...pkVariables(pk),
        },
        query: queryDeleteByPK(returning),
      })
    },
  }
}

export type HasuraResource = ReturnType<typeof createHasuraResource>
;(window as any).createHasuraResource = createHasuraResource
