import resample from './resample'

export default function group<T extends any[]>(
  stops: T[],
  numOfStops: number,
  groups?: number[]
): T[] {
  // tslint:disable:no-parameter-reassignment
  stops = resample(stops, numOfStops, 'spacing')

  if (groups) {
    stops = stops.map(([, color], i) => [groups[i], color] as T)
  }

  return stops
}
