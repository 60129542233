import { downloadJson, downloadText } from '../util/download'
import { unescapeQuotes } from '../util/unescapeQuotes'
import {
  fetchWithAuth,
  getJson,
  getText,
} from '../vvapi/apiResource/createApiResource'
import { createGQLResource } from './createGQLResource'
import { Delivery, Delivery_create_input, Delivery_update_input } from './types'

async function downloadMissingGeoJSON({
  deliveryId,
}: {
  deliveryId: string
}) {
  const { headers, ...targetDeliveryJson } = await getJson(
    `/api/v3/delivery/${deliveryId}/download-geojson/missing`,
    undefined,
    true
  )

  let filename = 'delivery-missing.geojson'
  const contentDisposition = headers.get('Content-Disposition')

  if (contentDisposition) {
    const [, remainingFilename] = contentDisposition.split('; filename="')

    if (remainingFilename) {
      filename = remainingFilename.split('"')[0]
    }
  }

  downloadJson(JSON.stringify(targetDeliveryJson), filename)
}
async function downloadGeoJSON({
  orderId,
  targetDeliveryDate,
}: {
  orderId: string
  targetDeliveryDate: string
}) {
  const { headers, ...targetDeliveryJson } = await getJson(
    `/api/v3/target-delivery/${orderId}/${targetDeliveryDate}/download-geojson`,
    undefined,
    true
  )

  let filename = 'target-delivery.geojson'
  const contentDisposition = headers.get('Content-Disposition')

  if (contentDisposition) {
    const [, remainingFilename] = contentDisposition.split('; filename="')

    if (remainingFilename) {
      filename = remainingFilename.split('"')[0]
    }
  }

  downloadJson(JSON.stringify(targetDeliveryJson), filename)
}

async function downloadKML({
  orderId,
  targetDeliveryDate,
}: {
  orderId: string
  targetDeliveryDate: string
}) {
  const { headers, text: targetDeliveryKml } = await getText(
    `/api/v3/target-delivery/${orderId}/${targetDeliveryDate}/download-kml`,
    undefined,
    true
  )

  let filename = 'target-delivery.kml'
  const contentDisposition = headers?.get('Content-Disposition')

  if (contentDisposition) {
    const [, remainingFilename] = contentDisposition.split('; filename="')

    if (remainingFilename) {
      filename = remainingFilename.split('"')[0]
    }
  }

  downloadText(
    unescapeQuotes(
      targetDeliveryKml.substring(1).substring(0, targetDeliveryKml.length - 2)
    ),
    filename
  )
}

async function updateDeliveryParcels({ id }: { id: string }) {
  const res = await fetchWithAuth(`/api/v3/delivery/${id}/update-snapshot`, {
    init: {
      method: 'POST',
    },
  })

  if (!res.ok) {
    throw new Error('Bad Request')
  }

  return res
}

const resource = createGQLResource<
  Delivery,
  Delivery_create_input,
  Delivery_update_input,
  'id'
>('Delivery', { id: 'uuid' })

export const delivery = {
  ...resource,
  downloadMissingGeoJSON,
  downloadGeoJSON,
  downloadKML,
  updateDeliveryParcels,
}
