import './AdvancedFilters.scss'

import * as React from 'react'

import { Button, ListItem, Typography } from '@mui/material'

import { NameAndValue } from '../../../admin/mapdata/types'
import DatePickerYMD from '../../../admin/mapdata/utils/DatePickerYMD'
import {
  parcelFilterStatsRootstock,
  parcelFilterStatsTrellis,
  parcelFilterStatsVarietals,
} from '../../../data/selectOrgMapData'
import { selectParcelFilter } from '../../../data/selectParcelFilter'
import { selectParcelFilterInfo } from '../../../data/selectParcelFilterInfo'
import i18n, { keys } from '../../../i18n'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { ExpandButton } from '../../../UI/Expand/ExpandButton'
import * as actions from '../../actions'
import MetaFilterSelect from '../MetaFilterSelect'
import ProductFilterSelect from '../ProductFilterSelect'

class AdvancedFilters extends React.PureComponent<
  ReduxProps & AppDispatchProps
> {
  toggleFilters = () => {
    this.props.dispatch(
      actions.setParcelFilterInfo({
        ...this.props.parcelFilterInfo,
        advancedFilterOpen: !this.props.parcelFilterInfo.advancedFilterOpen,
      })
    )
  }

  handleDateFilters = ({ name, value }: NameAndValue) => {
    const { parcelFilterInfo } = this.props
    const minDate = parcelFilterInfo?.minDate
    const maxDate = parcelFilterInfo?.maxDate
    const newObj = {
      ...parcelFilterInfo,
      minDate: minDate && minDate > value ? value : minDate,
      maxDate: maxDate && maxDate < value ? value : maxDate,
      [name]: value,
    }

    this.props.dispatch(actions.setParcelFilterInfo(newObj))
  }

  handleReset = () => {
    const { parcelFilterInfo } = this.props
    this.props.dispatch(
      actions.setParcelFilterInfo({
        advancedFilterOpen: parcelFilterInfo.advancedFilterOpen,
        searchTerm: parcelFilterInfo.searchTerm,
        metaFilters: { rootstock: [], trellises: [], varietals: [] },
      })
    )
  }

  render() {
    const { advancedFilterOpen, minDate, maxDate } = this.props.parcelFilterInfo

    return (
      <div className="AdvancedFilters">
        <ListItem
          button
          disableGutters
          className="AdvancedFilters__toggle"
          dense
          onClick={this.toggleFilters}
          disableRipple
          selected={advancedFilterOpen}
        >
          <ExpandButton expanded={advancedFilterOpen} style={{ padding: 0 }} />
          <Typography variant="caption" style={{ fontSize: 14 }}>
            {i18n.t(keys.forms.parcelFilter.advancedFilters)}
          </Typography>
        </ListItem>
        {advancedFilterOpen && (
          <div className="AdvancedFilters__body">
            <ListItem>
              <DatePickerYMD
                sx={{ width: '100%' }}
                label={i18n.t(keys.forms.parcelFilter.fromDate)}
                name="minDate"
                value={minDate}
                onChange={(nameAndValue) =>
                  this.handleDateFilters({
                    ...nameAndValue,
                    value: nameAndValue.value,
                  })
                }
              />
            </ListItem>
            <ListItem>
              <DatePickerYMD
                sx={{ width: '100%' }}
                label={i18n.t(keys.forms.parcelFilter.toDate)}
                name="maxDate"
                value={maxDate}
                onChange={(nameAndValue) =>
                  this.handleDateFilters({
                    ...nameAndValue,
                    value: nameAndValue.value,
                  })
                }
              />
            </ListItem>
            <ProductFilterSelect />
            <MetaFilterSelect
              filterProperty="varietals"
              availableValuesSelector={parcelFilterStatsVarietals}
              inputLabel={i18n.t(keys.forms.parcelFilter.varietal)}
              noAvailableValuesLabel={i18n.t(
                keys.forms.parcelFilter.noVarietals
              )}
            />
            <MetaFilterSelect
              filterProperty="rootstock"
              availableValuesSelector={parcelFilterStatsRootstock}
              inputLabel={i18n.t(keys.forms.parcelFilter.rootstock)}
              noAvailableValuesLabel={i18n.t(
                keys.forms.parcelFilter.noRootstocks
              )}
            />
            <MetaFilterSelect
              filterProperty="trellises"
              availableValuesSelector={parcelFilterStatsTrellis}
              inputLabel={i18n.t(keys.forms.parcelFilter.trellisType)}
              noAvailableValuesLabel={i18n.t(
                keys.forms.parcelFilter.noTrellises
              )}
            />
            <ListItem>
              <Button fullWidth onClick={this.handleReset}>
                {i18n.t(keys.generic.reset)}
              </Button>
            </ListItem>
          </div>
        )}
        <ListItem disabled divider />
      </div>
    )
  }
}

const mapState = (state: RootStore) => ({
  parcelFilterInfo: selectParcelFilterInfo(state),
  selectParcelFilter: selectParcelFilter(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  AdvancedFilters
)
