import { blue } from '@mui/material/colors'
import { darken } from '@mui/material/styles'
import { CSSProperties, StyleRules } from '@mui/styles'
import { Job, JobAttempt } from '../../graphql/types'

export const extractStatus = (
  job: Pick<Job, 'isPending'> & {
    LatestJobAttempt?: Pick<JobAttempt, 'status'>
  }
) => {
  if (job.isPending) {
    return 'pending'
  }
  if (job?.LatestJobAttempt) {
    return job.LatestJobAttempt.status
  }

  return 'none'
}

export const getStatusIcon = (
  status: JobAttempt['status'] | 'pending' | 'none' | 'paused' | string
) => {
  switch (status.toLowerCase()) {
    case 'complete':
      return 'check_circle'
    case 'error':
      return 'error'
    case 'running':
      return 'donut_large'
    case 'timeout':
      return 'hourglass_empty'
    case 'cancelled':
      return 'remove_circle'
    case 'pending':
      return 'access_time_filled'
    case 'total':
      return 'functions'
    case 'n/a':
      return 'check_circle_outline'
    case 'queued':
      return 'playlist_add_check_circle_rounded'
    case 'paused':
      return 'pause_circle'
    default:
      return 'help'
  }
}

const getStatusClass = (
  background: string,
  stroke: string,
  theme: any,
  styles: CSSProperties = {}
) => ({
  ...styles,

  stroke,
  // For DOM elements
  background,
  transition: 'background 0.25s, color 0.25s, fill 0.25s, stroke 0.25s',
  // for SVG
  fill: background,
  color: theme.palette.getContrastText(background),

  '&:hover': {
    stroke,
    background: darken(background, 0.2),
    fill: darken(background, 0.2),
  },
})

export const statusClasses = (theme: any): StyleRules => ({
  '&.error': getStatusClass(
    theme.palette.secondary.main,
    theme.palette.secondary.dark,
    theme
  ),
  '&.cancelled': getStatusClass(blue[400], blue[600], theme),
  '&.timeout': getStatusClass(
    theme.palette.grey[500],
    theme.palette.grey[600],
    theme
  ),
  '&.running': getStatusClass(
    theme.palette.primary.main,
    theme.palette.text.primary,
    theme
  ),
  '&.complete': getStatusClass(
    theme.palette.primary.main,
    theme.palette.text.primary,
    theme
  ),
})
