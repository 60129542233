import { AdminRole, api, gql } from '../../../hasura/api'

export const updateAdminRoleByPK = async (
  userId: string,
  role: string,
  update: AdminRole
) => {
  const choice = await sweetAlert(
    `Are you sure you want to update this AdminRole?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Update',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (choice == null || choice === false) {
    return
  }

  return api.adminRoles.updateByPK<{ userId: string; role: string }>(
    {
      pk: { userId, role },
      _set: update,
    },
    gql`
      {
        userId
        role
      }
    `
  )
}
