import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { gql, query } from '../../../graphql/client'

interface AggregateCount {
  aggregate: {
    count: number
  }
}

export interface GetDeliveryStatus {
  total: AggregateCount
  fulfilled: AggregateCount
  enabled: AggregateCount
}

const { selector: selectGetDeliveryStatus, refresh: refreshGetDeliveryStatus } =
  createAsyncSelector({
    resource: 'GetDeliveryStatus',
    inputs: {},
    fetcher: async () => {
      const data = await query<GetDeliveryStatus>({
        query: gql`
          query {
            total: Delivery_aggregate {
              aggregate {
                count
              }
            }
            fulfilled: View_DeliveryStatus_aggregate(
              where: { fulfilled: { _eq: true } }
            ) {
              aggregate {
                count
              }
            }
            enabled: View_DeliveryStatus_aggregate(
              where: { enabled: { _eq: true } }
            ) {
              aggregate {
                count
              }
            }
          }
        `,
      })

      if (!data) {
        throw new Error('No Data')
      }

      const {
        total: {
          aggregate: { count: total },
        },
        fulfilled: {
          aggregate: { count: fulfilled },
        },
        enabled: {
          aggregate: { count: enabled },
        },
      } = data

      return {
        total,
        fulfilled,
        enabled,
      }
    },
  })

export { selectGetDeliveryStatus, refreshGetDeliveryStatus }
