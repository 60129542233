import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

import { FeatureCollection, Polygon } from '@turf/helpers'

import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectSelectedMapLayersArray } from '../../data/selectSelectedMapLayers'
import { selectSelectedMapLayersByLayerDefId } from '../../data/selectSelectedMapLayersByLayerDefId'
import {
  MapColorProfileData,
  MapLayerData,
  MapLayerDefData,
} from '../../data/types'
import { selectStatsZones } from '../../postgis/selectors/selectStatsZones'
import { selectProductSettings } from '../../ProductSettings/store/selectors/selectProductSettings'
import {
  ProductSettings,
  VisualizationMode,
} from '../../ProductSettings/store/types'
import { RootStore } from '../../redux/types'
import { UnitSystem } from '../../util/units/unitSystem'
import { calculateStatsOnWorker } from '../calculateStats/statsWorker'
import { StatsOutputs } from '../calculateStats/types'
import { isEqual } from 'lodash'

type relevantSettingsKey = keyof ProductSettings

const relevantSettingsKeys: relevantSettingsKey[] = [
  'numberOfGroups',
  'groupBy',
  'visualization',
  'threshold',
  'coverage',
  'coverageMax',
  'coverageMin',
  'blockByBlockAverage',
  'numberOfGroups',
  'groupBy',
  'colorIdByVisualization',
]

const relevantSettingsSelector = createSelectorCreator(
  defaultMemoize,
  (prev, next) => {
    const prevSettings = prev as Record<string, ProductSettings>
    const nextSettings = next as Record<string, ProductSettings>
    if (prev === undefined || Object.keys(prevSettings).length === 0) {
      return false
    }
    if (next === undefined || Object.keys(nextSettings).length === 0) {
      return false
    }
    for (const [productId, prevProductSetting] of Object.entries(
      prevSettings
    )) {
      const nextProductSetting = nextSettings[productId]
      if (!prevProductSetting) {
        return false
      }
      if (!nextProductSetting) {
        return false
      }
      for (const relevantKey of relevantSettingsKeys) {
        if (typeof prevProductSetting[relevantKey] === 'boolean') {
          if (
            !!prevProductSetting[relevantKey] !==
            !!nextProductSetting[relevantKey]
          ) {
            return false
          }
        } else if (typeof prevProductSetting[relevantKey] === 'object') {
          if (
            !isEqual(
              prevProductSetting[relevantKey],
              nextProductSetting[relevantKey]
            )
          ) {
            return false
          }
        } else if (
          // eslint-disable-next-line eqeqeq
          prevProductSetting[relevantKey] != nextProductSetting[relevantKey]
        ) {
          return false
        }
      }
    }

    return true
  }
)

const selectRelevantProductSettings = relevantSettingsSelector(
  selectProductSettings,
  (settings) => settings
)

export const selectProductNumberOfGroups = createSelector(
  [selectRelevantProductSettings],
  (productSettings) => {
    const productNumberOfGroups = {}
    for (const [productId, settings] of Object.entries(productSettings)) {
      if (!settings) {
        continue
      }

      productNumberOfGroups[productId] = settings.numberOfGroups
    }

    return productNumberOfGroups
  }
)

const EMPTY_PRODUCT_COVERAGE: Record<string, number> = {}

export const selectProductCoverage = createSelector(
  [selectRelevantProductSettings],
  (productSettings) => {
    const productCoverage = {}
    for (const [productId, settings] of Object.entries(productSettings)) {
      if (!settings) {
        continue
      }

      productCoverage[productId] = settings.coverage ?? EMPTY_PRODUCT_COVERAGE
    }

    return productCoverage
  }
)

export const selectLayerIds = createSelector(
  [selectSelectedMapLayersArray],
  (layers) => {
    return layers.map((layer) => layer.id)
  }
)

const calcStats = async ({
  layerIds,
  mapLayerDefsById,
  productSettings,
  preferredUnitSystem,
  selectedMapLayersByLayerDefId,
  statsZones,
}: {
  mapLayerDefsById: Record<any, MapLayerDefData>
  selectedMapLayersByLayerDefId: Record<any, MapLayerData>
  productSettings: Record<string, ProductSettings | undefined>
  preferredUnitSystem: UnitSystem
  layerIds: string[]
  statsZones?: FeatureCollection<Polygon>
}) => {
  try {
    const data = await calculateStatsOnWorker({
      mapLayerDefsById,
      selectedMapLayersByLayerDefId,
      productSettings,
      preferredUnitSystem,
      layerIds,
      statsZones,
    })

    return data
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.warn(`Error generating stats: ${error}`)

    return
  }
}

export const {
  selector: selectCalculateStats,
  refresh: refreshCalculateStats,
} = createAsyncSelector({
  resource: 'me.organization.delivery.generateStats',
  inputs: {
    mapLayerDefsById: (state: RootStore) => selectMapLayerDefsById(state),
    selectedMapLayersByLayerDefId: (state: RootStore) =>
      selectSelectedMapLayersByLayerDefId(state),
    productSettings: selectRelevantProductSettings,
    preferredUnitSystem: (state: RootStore) =>
      state.preferences.preferredUnitSystem,
    layerIds: (state: RootStore) => selectLayerIds(state),
    statsZones: (state: RootStore) => selectStatsZones(state),
  },
  fetcher: calcStats,
})

const EMPTY_STATS: Partial<StatsOutputs> = {}

export const selectStats = createSelector(
  selectCalculateStats,
  ({ data }) => data ?? EMPTY_STATS
)

const EMPTY_COLOR_PROFILES: Record<
  string,
  Partial<Record<VisualizationMode, MapColorProfileData>>
> = {}

export const selectConvertedSelectedColorProfiles = createSelector(
  selectStats,
  (statsResults) => {
    return statsResults.convertedColorProfiles ?? EMPTY_COLOR_PROFILES
  }
)

export const selectConvertedProductColorProfilesByVisualizations =
  createSelector(selectStats, (statsResults) => {
    return statsResults.convertedColorProfilesByVisualization ?? {}
  })

export const selectActiveColorProfiles = createSelector(
  selectStats,
  (statsResults) => {
    return statsResults.activeColorProfiles ?? {}
  }
)

export const selectBestUnitsByProductId = createSelector(
  selectStats,
  (statsResults) => {
    return statsResults.bestUnitsByProductId ?? {}
  }
)
