import { Sample, SamplePlan, SamplePlanBlock } from "../../../graphql/types";
import { indexArray } from "../../../util/indexArray";

export interface SamplePlanMapState {
    samplePlan: SamplePlan | null;
    selectedSamplePlanBlocks: SamplePlanBlock[];
    selectedSamplePlanSamples: Sample[];
    highlightedSample: Sample | null;
}

export const initialState: SamplePlanMapState = {
    samplePlan: null,
    selectedSamplePlanBlocks: [],
    selectedSamplePlanSamples: [],
    highlightedSample: null,
};

export type SamplePlanMapAction = {
    type: 'SET_SAMPLE_PLAN';
    samplePlan: SamplePlan;
    selectedSamplePlanBlockIds?: string[];
    selectedSamplePlanSampleIds?: string[];
    highlightedSampleId?: string;
} | {
    type: 'SET_SELECTED_SAMPLE_PLAN_BLOCKS';
    selectedSamplePlanBlockIds: string[];
} | {
    type: 'SET_SELECTED_SAMPLE_PLAN_SAMPLES';
    selectedSamplePlanSampleIds: string[];
} | {
    type: 'SET_HIGHLIGHTED_SAMPLE';
    sampleId: string | null;
} | {
    type: 'UPDATE_SAMPLE';
    sample: Sample;
};

// Define the reducer function
const samplePlanMapReducer = (state: SamplePlanMapState, action: SamplePlanMapAction) => {
    switch (action.type) {
        case 'SET_SAMPLE_PLAN':
            const samplePlanBlockMap = indexArray(action.samplePlan?.SamplePlanBlocks ?? [], 'id');
            const samplePlanSampleMap = indexArray(action.samplePlan?.Samples ?? [], 'id');
            return {
                samplePlan: action.samplePlan,
                selectedSamplePlanBlocks: action.selectedSamplePlanBlockIds?.map(id => samplePlanBlockMap[id]) || [],
                selectedSamplePlanSamples: action.selectedSamplePlanSampleIds?.map(id => samplePlanSampleMap[id]) || [],
                highlightedSample: action.highlightedSampleId ? samplePlanSampleMap[action.highlightedSampleId] : null,
            };
        case 'SET_SELECTED_SAMPLE_PLAN_BLOCKS':
            if (!state?.samplePlan?.SamplePlanBlocks) { 
                console.error('SET_SELECTED_SAMPLE_PLAN_BLOCKS: SamplePlanBlocks not found in state');
                return state; 
            }
            if (!state?.samplePlan?.Samples) {
                console.error('SET_SELECTED_SAMPLE_PLAN_BLOCKS: Samples not found in state');
                return state;
            }
            const blockMap = indexArray(state.samplePlan.SamplePlanBlocks ?? [], 'id');
            return {
                ...state,
                selectedSamplePlanBlocks: action.selectedSamplePlanBlockIds.map(id => blockMap[id]) || [],
                selectedSamplePlanSamples: state?.samplePlan?.Samples?.filter(sps => action.selectedSamplePlanBlockIds?.includes(sps.samplePlanBlockId)) || [],
            };
        case 'SET_SELECTED_SAMPLE_PLAN_SAMPLES':
            if (!state?.samplePlan?.Samples) {
                console.error('SET_SELECTED_SAMPLE_PLAN_SAMPLES: Samples not found in state');
                return state;
            }
            const sampleMap = indexArray(state.samplePlan.Samples ?? [], 'id');
            return {
                ...state,
                selectedSamplePlanSamples: action.selectedSamplePlanSampleIds.map(id => sampleMap[id]) || [],
            };
        case 'SET_HIGHLIGHTED_SAMPLE':
            return {
                ...state,
                highlightedSample: state?.samplePlan?.Samples?.find(sps => sps.id === action.sampleId) || null,
            };
        case 'UPDATE_SAMPLE':
            if(!state.samplePlan){
                console.error('UPDATE_SAMPLE: SamplePlan not found in state');
                return state;
            }
            const updatedSamples = [ ...(state.samplePlan?.Samples?.map(s => s.id === action.sample.id ? action.sample : s) ?? [])]
            const updatedSelectedSamplePlanSamples = [ ...(state.selectedSamplePlanSamples?.map(s => s.id === action.sample.id ? action.sample : s) ?? []) ]
            return {
                ...state,
                samplePlan: {
                    ...state.samplePlan,
                    Samples: updatedSamples,
                },
                selectedSamplePlanSamples: updatedSelectedSamplePlanSamples,
            };
        default:
            return state;
    }
};

export default samplePlanMapReducer;
