import { GroupData } from '../data/selectOrgMapData'
import { FluxAction } from '../util/commonRedux'
import { ReportFormValues, ReportsState } from './types'

// Actions
const OPEN_REPORTS_DIALOG = `reports/OPEN_REPORTS_DIALOG`
const CLOSE_REPORTS_DIALOG = `reports/CLOSE_REPORTS_DIALOG`
const SET_REPORT_FORM_VALUES = `reports/SET_REPORT_FORM_VALUES`

// Action Creators
export const openReportsDialog = (group: GroupData) => ({
  type: OPEN_REPORTS_DIALOG,
  payload: group,
})
export const closeReportsDialog = () => ({
  type: CLOSE_REPORTS_DIALOG,
})
export const setReportFormValues = (reportFormValues?: ReportFormValues) => ({
  type: SET_REPORT_FORM_VALUES,
  payload: reportFormValues,
})

export const initialState: ReportsState = {
  isReportsDialogOpen: false,
}

export const reducer = (
  state = initialState,
  action: FluxAction
): ReportsState => {
  switch (action.type) {
    case OPEN_REPORTS_DIALOG: {
      const group = action.payload as GroupData

      return {
        ...state,
        isReportsDialogOpen: true,
        selectedGroupId: group.id,
      }
    }

    case CLOSE_REPORTS_DIALOG:
      return {
        ...state,
        isReportsDialogOpen: false,
      }

    case SET_REPORT_FORM_VALUES:
      return {
        ...state,
        reportFormValues: action.payload as ReportFormValues,
      }

    default:
      return state
  }
}
