import * as React from 'react'
import { TableFormatter } from '../../../UI/Table/types'
import { Sample } from '../../../graphql/types'
import i18n, { keys } from '../../../i18n'
import { Tooltip, Typography } from '@mui/material'

interface Context {
  inlineFilterId?: string
  searchBarFilterId?: string
}

export const tableFormatter = ({
  inlineFilterId,
  searchBarFilterId,
}: Context) => {
  const blockColumn: TableFormatter<Sample> = {
    header: () => i18n.t(keys.sampleResultBlockLabel),
    data: ({ SamplePlanBlock }) => (
      <Tooltip
        title={SamplePlanBlock?.Parcel?.name ?? '-'}
        placement="right"
        followCursor
      >
        <Typography noWrap sx={{ maxWidth: '100px' }}>
          {SamplePlanBlock?.Parcel?.name ?? '-'}
        </Typography>
      </Tooltip>
    ),
    filterBy: (op, value) => ({
      SamplePlanBlock: {
        Parcel: {
          name: { [`_${op}`]: value },
        },
      },
    }),
  }

  const samplerColumn: TableFormatter<Sample> = {
    header: () => i18n.t(keys.sampleResultSamplerLabel),
    data: ({ CompletedByUser }) => {
      if (!CompletedByUser?.firstName && !CompletedByUser?.lastName) {
        return '-'
      }
      return (
        <Tooltip
          title={`${CompletedByUser?.firstName} ${CompletedByUser?.lastName}`}
          placement="right"
          followCursor
        >
          <Typography noWrap sx={{ maxWidth: '200px' }}>
            {`${CompletedByUser?.firstName} ${CompletedByUser?.lastName}`}
          </Typography>
        </Tooltip>
      )
    },
    filterBy: (op, value) => ({
      CompletedByUser: {
        _or: [
          { firstName: { [`_${op}`]: value } },
          { lastName: { [`_${op}`]: value } },
        ],
      },
    }),
  }

  const completedDayColumn: TableFormatter<Sample> = {
    header: () => i18n.t(keys.sampleResultCompletionDateLabel),
    data: ({ completedAt }) => {
      if (!completedAt) {
        return '-'
      }
      return <Typography noWrap>{i18n.toDateShort(completedAt)}</Typography>
    },
    orderBy: (dir) => `{ completedAt: ${dir === 'desc' ? 'desc_nulls_last' : dir}}`,
  }

  const completedTimeColumn: TableFormatter<Sample> = {
    header: () => i18n.t(keys.sampleResultCompletionHourLabel),
    data: ({ completedAt }) => {
      if (!completedAt) {
        return '-'
      }
      return <Typography noWrap>{i18n.toTimeHourMinute(completedAt)}</Typography>
    },
  }

  // TODO: After change to content format, can't search.
  // Need to reconsider approach. 
  // const contentFilter: TableFormatter<Sample> = {
  //   filterBy: (op, value) => ({
  //     content: {
  //       _cast: {
  //         String: {
  //           [`_${op}`]: value, // only values
  //         },
  //       },
  //     },
  //   }),
  // }

  // for more complex filters, its easier to roll them together.
  const searchFilter: TableFormatter<Sample> = {
    filterId: searchBarFilterId,
    filterBy: (op, value) => ({
      _or: [
        !!samplerColumn.filterBy && samplerColumn.filterBy(op, value),
        //!!contentFilter.filterBy && contentFilter.filterBy(op, value),
      ],
    }),
  }

  const inlineButtonFilter: TableFormatter<Sample> = {
    filterId: inlineFilterId,
    filterBy: (op, value) => ({
      _and: [!!blockColumn.filterBy && blockColumn.filterBy(op, value)],
    }),
  }

  return [
    blockColumn,
    samplerColumn,
    completedDayColumn,
    completedTimeColumn,
    searchFilter,
    inlineButtonFilter,
  ]
}
