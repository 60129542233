import * as React from 'react'
import { PureComponent } from 'react'
import { Button } from '@mui/material'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { throttle } from '../../util/throttle-debounce'
import { resetProductSettings } from '../store/redux'

class ResetSettings extends PureComponent<AppDispatchProps & ReduxProps> {
  handleButtonClick = throttle({ delay: 100 }, () => {
    const { productId, dispatch } = this.props

    dispatch(resetProductSettings({ productId }))
  })

  render() {
    return (
      <Button size="small" variant="contained" onClick={this.handleButtonClick}>
        {i18n.t(keys.colorScheme.revertToDefaults)}
      </Button>
    )
  }
}

const mapState = (state: RootStore) => {
  return {
    productId: selectSelectedLegendProductId(state),
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  ResetSettings
)
