import * as React from 'react'
import { getBlob } from '../vvapi/apiResource/createApiResource'

export const AuthImage = ({
  onSizeFound,
  ...props
}: React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & { onSizeFound?: (size: number) => void }) => {
  const imageRef = React.useRef<HTMLImageElement>(null)
  const [isFetched, setIsFetched] = React.useState(false)

  React.useEffect(() => {
    const fetchImage = async () => {
      if (imageRef.current && props.src) {
        const { blob } = await getBlob(props.src, undefined, false, true)
        onSizeFound?.(blob.size)
        const objectUrl = URL.createObjectURL(blob)
        imageRef.current.onload = () => {
          URL.revokeObjectURL(objectUrl)
          setIsFetched(true)
        }
        imageRef.current.src = objectUrl
      }
    }
    if (props.src) {
      fetchImage()
    }
  }, [onSizeFound, props.src])

  return (
    <img
      {...props}
      alt={props.alt}
      ref={imageRef}
      style={{ ...props.style, display: isFetched ? undefined : 'none' }}
    />
  )
}
