import createCompositeAsyncSelector from '../AsyncSelector/createCompositeAsyncSelector'
import {
  refreshListDeliveryParcelFilesForGroup,
  selectListDeliveryParcelFilesForGroup,
} from './selectAvailableGroupDownloads'
import {
  refreshListDeliveryGroupFiles,
  selectListDeliveryGroupFiles,
} from './selectDeliveryGroupFiles'

export const {
  selector: selectGroupAndAvailableFiles,
  refresh: refreshGroupAndAvailableFiles,
} = createCompositeAsyncSelector({
  listDeliveryParcelFilesForGroup: {
    selector: selectListDeliveryParcelFilesForGroup,
    refresh: refreshListDeliveryParcelFilesForGroup,
  },
  listDeliveryGroupFiles: {
    selector: selectListDeliveryGroupFiles,
    refresh: refreshListDeliveryGroupFiles,
  },
})
