import { Tooltip, Typography } from '@mui/material'
import { TableFormatter } from '../../UI/Table/types'
import * as React from 'react'
import { DeliveryProcGroupSummary } from './fetchYourQueue'
import LinkChild from '../../UI/LinkChild'
import { url, urls } from '../../appNavigation/urls'
import { ArrowForward } from '@mui/icons-material'

export const tableFormatterWithScope = () => {
  const organizationNameColumn: TableFormatter<DeliveryProcGroupSummary> = {
    header: () => 'Organization',
    data: ({ organization }) => organization.name,
  }

  const groupNameColumn: TableFormatter<DeliveryProcGroupSummary> = {
    header: () => 'Group Name',
    data: ({ groupName }) => groupName,
  }

  const procGroupColumn: TableFormatter<DeliveryProcGroupSummary> = {
    header: () => 'Processing Group Name',
    data: ({ procGroup }) => procGroup,
  }

  const statusColumn: TableFormatter<DeliveryProcGroupSummary> = {
    header: () => 'Processing Status',
    data: ({ KatoLineQAJob, qaStatus }) => {
      const status = KatoLineQAJob?.Status?.status
      let statusColour = ''
      let statusLabel = ''

      if (qaStatus == 'complete') {
        return (
          <Typography sx={{ color: '#5CC46C' }}>Proc Group Reviewed</Typography>
        )
      }

      if (qaStatus === 'failed') {
        return <Typography color="error">QA Failed</Typography>
      }

      switch (status) {
        case 'created':
          statusColour = '#FFFFFF'
          statusLabel = 'Kato Job Created'
          break
        case 'pending':
          statusColour = '#7CB1FF'
          statusLabel = 'Kato Job Pending'
          break
        case 'queued':
          statusColour = '#7CB1FF'
          statusLabel = 'Kato Job Queued'
          break
        case 'running':
          statusColour = '#FFC107'
          statusLabel = 'Kato In Progress'
          break
        case 'complete':
          statusColour = '#9C12CC'
          statusLabel = 'Kato Complete'
          break
        case 'cancelled':
          statusColour = '#C70039'
          statusLabel = 'Kato Job Cancelled'
          break
        default:
          break
      }

      return (
        <Typography sx={{ color: `${statusColour}` }}>{statusLabel}</Typography>
      )
    },
    filterBy: (op, value) => ({
      KatoLineQAJob: { Status: { status: { [`_${op}`]: value } } },
    }),
  }

  const priorityColumn: TableFormatter<DeliveryProcGroupSummary> = {
    header: () => 'Priority',
    data: ({ Delivery }) => Delivery.Order.priority,
    orderBy: (direction) => `
        { 
            Delivery: { 
                Order: { 
                    priority: ${direction} 
                } 
            } 
        }
    `,
  }

  const viewProcGroupDetailsColumn: TableFormatter<DeliveryProcGroupSummary> = {
    header: () => '',
    data: ({ deliveryId, procGroup }) => {
      return (
        <Tooltip title="View Proc Group Details">
          <LinkChild
            to={url(
              urls.deliveryProcGroup,
              {
                deliveryId,
                procGroup,
              },
              {}
            )}
          >
            <ArrowForward />
          </LinkChild>
        </Tooltip>
      )
    },
  }

  return [
    organizationNameColumn,
    groupNameColumn,
    procGroupColumn,
    statusColumn,
    priorityColumn,
    viewProcGroupDetailsColumn,
  ]
}
