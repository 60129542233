import {
  Container,
  Icon,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectMe } from '../../data/selectMe'
import useAsync from '../../hooks/useAsync'
import { useRedux } from '../../hooks/useRedux'
import { useHistory, useLocation, useSearchParams } from '../../hooks/useRouter'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import LinkChild from '../../UI/LinkChild'
import { fetchMyQueue } from './fetchMyQueue'

export const MyQueue = () => {
  const [state] = useRedux()
  const theme = useTheme()

  const user = selectMe(state)

  const { page = 0, pageSize = 5 } = useSearchParams()
  const history = useHistory()
  const location = useLocation()

  const [myQueue, refreshMyQueue] = useAsync(fetchMyQueue, [
    user,
    Number(page),
    Number(pageSize),
  ])

  const handleChangePage = (
    _event: React.ChangeEvent<HTMLButtonElement>,
    page: number
  ) => {
    history.replace(
      url({ url: location.pathname }, {}, { page: page - 1, pageSize })
    )
  }

  const renderPagination = () => {
    return (
      <Pagination
        shape="rounded"
        color="primary"
        page={Number(page) + 1}
        count={Math.ceil((myQueue.result?.count ?? 0) / Number(pageSize))}
        onChange={handleChangePage}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          pt: theme.spacing(4),
          '.MuiPagination-ul > li > .Mui-selected': { color: '#fff' },
        }}
      />
    )
  }

  return (
    <Page title="My Queue" backTo={url(urls.mapView)} backToTitle="Map">
      <Container maxWidth="lg">
        <EnhancedTableToolbar
          numSelected={0}
          onClickRefresh={refreshMyQueue}
          title="My Queue"
        ></EnhancedTableToolbar>
        <Paper sx={{ py: 2 }}>
          <AsyncSelectorStatusOverlay requests={myQueue}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Organization</TableCell>
                  <TableCell>Group Name</TableCell>
                  <TableCell>Processing Group</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {myQueue?.result?.procGroups.map(
                  ({
                    deliveryId,
                    procGroup,
                    groupName,
                    qaStatus,
                    organization: { name: organizationName },
                  }) => (
                    <TableRow>
                      <TableCell>{organizationName}</TableCell>
                      <TableCell>{groupName}</TableCell>
                      <TableCell>{procGroup}</TableCell>
                      <TableCell>
                        {qaStatus === 'failed' ? (
                          <Typography color="error">QA Failed</Typography>
                        ) : (
                          'Pending KATO'
                        )}
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Tooltip title="View Proc Group Details">
                          <LinkChild
                            to={url(
                              urls.deliveryProcGroup,
                              {
                                deliveryId,
                                procGroup,
                              },
                              {}
                            )}
                          >
                            <IconButton size="large">
                              <Icon>arrow_forward</Icon>
                            </IconButton>
                          </LinkChild>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </AsyncSelectorStatusOverlay>
        </Paper>
        {renderPagination()}
      </Container>
    </Page>
  )
}
