import * as React from 'react'
import { default as swal } from 'sweetalert'

import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, List,
  Tooltip, Typography
} from '@mui/material'

import HelperText from '../admin/mapdata/utils/HelperText'
import { Row } from '../admin/UI/Row'
import warnConfirm from '../admin/warnConfirm'
import { selectMe } from '../data/selectMe'
import { selectOrganizationId } from '../data/selectOrganizationId'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { getSelectedFlightDate } from '../data/userSelectionRedux'
import * as api from '../graphql/api'
import useAsync from '../hooks/useAsync'
import { useInterval } from '../hooks/useInterval'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import LoadingOverlay from '../UI/LoadingOverlay'
import { fetchDeliveryGroupFiles, GroupFile } from './fetchDeliveryGroupFiles'
import { fetchMapLayersForGroup, MapLayer } from './fetchMapLayersForGroup'
import { GeoPdfDownloadRow } from './GeoPdfDownloadRow'
import { resetGeoPdfDownloadsDialog } from './redux'

export const GeoPdfDownloadsDialog = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedFiles, setSelectedFiles] = React.useState(new Set<string>())

  const [state, dispatch] = useRedux()
  const selectedGroup = state.geoPdfDownloads.selectedGroup
  const open = state.geoPdfDownloads.isGeoPdfDownloadsDialogOpen

  const [deliveryGroupFilesResult, refreshDeliveryGroupFiles] = useAsync(
    fetchDeliveryGroupFiles,
    [selectedGroup?.deliveryId, selectedGroup?.groupId]
  )
  const [groupMapLayersResult] = useAsync(fetchMapLayersForGroup, [
    selectedGroup?.deliveryId,
    selectedGroup?.groupId,
  ])

  const me = selectMe(state)
  const preferredLanguage = selectPreferredLanguage(state)

  const organizationId = selectOrganizationId(state)
  const selectedDate = getSelectedFlightDate(state)

  const groupFilesByFilename = deliveryGroupFilesResult.result
  const avalableGeoPDFLayers = groupMapLayersResult.result

  useInterval(refreshDeliveryGroupFiles, 2000)

  React.useEffect(() => {
    setIsLoading(!avalableGeoPDFLayers)
  }, [avalableGeoPDFLayers])

  if (!selectedGroup || !selectedDate) {
    return null
  }

  const groupDownloadCount = !!groupFilesByFilename
    ? Object.keys(groupFilesByFilename).length
    : 0
  const availableDownloadCount = !!avalableGeoPDFLayers
    ? Object.keys(avalableGeoPDFLayers).length
    : 0

  const handleClose = () => {
    dispatch(resetGeoPdfDownloadsDialog())
  }

  const handleDownloadClick = async () => {
    if (!groupFilesByFilename || !avalableGeoPDFLayers) {
      return
    }

    dispatch(resetGeoPdfDownloadsDialog())

    if (selectedGroup && selectedGroup.deliveryGroupFiles) {
      for (const filename of Array.from(selectedFiles)) {
        const dgf = groupFilesByFilename[filename]
        api.deliveryGroupFile.download(
          dgf,
          avalableGeoPDFLayers[filename]?.pdfFilenameTranslations?.[
          preferredLanguage
          ] || filename
        )
      }
    }
  }

  const reprocessAll = async () => {
    if (!groupFilesByFilename) {
      return
    }

    const groupFiles = Object.values(groupFilesByFilename)
    if (
      await warnConfirm({
        title: i18n.t(keys.confirmReprocessDownload),
        message: i18n.t(keys.confirmReprocessDownloadMessage, {
          filename: i18n.t(keys.allFiles),
        }),
        action: i18n.t(keys.requestDownload),
        cancel: i18n.t(keys.generic.cancel),
      })
    ) {
      setIsLoading(true)

      try {
        for (const { deliveryId, groupId, filename } of groupFiles) {
          await api.deliveryGroupFile.reprocess({
            deliveryId,
            groupId,
            filename,
            language: preferredLanguage,
          })
        }
      } catch (error) {
        await swal({
          title: i18n.t(keys.downloadRequestErrorTitle),
          text: i18n.t(keys.downloadRequestError),
          dangerMode: true,
        })
      } finally {
        setIsLoading(false)
        refreshDeliveryGroupFiles()
      }
    }
  }

  const requestDownloadAll = async () => {
    if (!selectedGroup || !organizationId || !avalableGeoPDFLayers) {
      return
    }

    const { deliveryId, groupId } = selectedGroup
    if (
      await warnConfirm({
        title: i18n.t(keys.confirmRequestDownload),
        message: i18n.t(keys.confirmRequestDownloadMessage, {
          filename: i18n.t(keys.allFiles),
        }),
        action: i18n.t(keys.requestDownload),
        cancel: i18n.t(keys.generic.cancel),
      })
    ) {
      setIsLoading(true)

      try {
        for (const mapLayer of Object.values(avalableGeoPDFLayers)) {
          await api.deliveryGroupFile.requestDownload({
            deliveryId,
            groupId,
            organizationId,
            filename: mapLayer.pdfFilename!,
            language: preferredLanguage,
          })
        }

        await swal({
          title: i18n.t(keys.downloadRequested),
          text: i18n.t(keys.downloadRequestedCheckBack, {
            filename: i18n.t(keys.allFiles),
          }),
        })
      } catch (error) {
        await swal({
          title: i18n.t(keys.downloadRequestErrorTitle),
          text: i18n.t(keys.downloadRequestError),
          dangerMode: true,
        })
      } finally {
        setIsLoading(false)
        refreshDeliveryGroupFiles()
      }
    }
  }

  const handleGroupDownloadSelectAll = () => {
    if (!groupFilesByFilename) {
      return
    }

    const groupFiles = Object.entries(groupFilesByFilename)
    if (selectedFiles.size === 0) {
      setSelectedFiles(
        new Set(
          groupFiles
            .filter(([, dgf]) => dgf.status === 'complete')
            .map(([filename]) => filename)
        )
      )
    } else {
      setSelectedFiles(new Set<string>())
    }
  }

  const handleGroupDownloadSelected = (filename: string) => {
    const newSelectedFiles = new Set(selectedFiles)

    if (newSelectedFiles.has(filename)) {
      newSelectedFiles.delete(filename)
    } else {
      newSelectedFiles.add(filename)
    }

    setSelectedFiles(newSelectedFiles)
  }

  const reprocessPdf = async (groupFile: GroupFile, mapLayer: MapLayer) => {
    if (
      await warnConfirm({
        title: i18n.t(keys.confirmReprocessDownload),
        message: i18n.t(keys.confirmReprocessDownloadMessage, {
          filename:
            mapLayer?.pdfFilenameTranslations?.[preferredLanguage] ||
            mapLayer.pdfFilename,
        }),
        action: i18n.t(keys.requestDownload),
        cancel: i18n.t(keys.generic.cancel),
      })
    ) {
      setIsLoading(true)

      try {
        await api.deliveryGroupFile.reprocess({
          deliveryId: groupFile!.deliveryId,
          groupId: groupFile!.groupId,
          filename: mapLayer.pdfFilename!,
          language: preferredLanguage,
        })
      } catch (error) {
        await swal({
          title: i18n.t(keys.downloadRequestErrorTitle),
          text: i18n.t(keys.downloadRequestError),
          dangerMode: true,
        })
      } finally {
        setIsLoading(false)
        refreshDeliveryGroupFiles()
      }
    }
  }

  const requestDownloadPdf = async (mapLayer: MapLayer) => {
    if (!organizationId || !selectedGroup) {
      return
    }

    setIsLoading(true)
    try {
      await api.deliveryGroupFile.requestDownload({
        deliveryId: selectedGroup.deliveryId,
        groupId: selectedGroup.groupId,
        filename: mapLayer.pdfFilename!,
        organizationId,
        language: preferredLanguage,
      })

      await swal({
        title: i18n.t(keys.downloadRequested),
        text: i18n.t(keys.downloadRequestedCheckBack, {
          filename:
            mapLayer?.pdfFilenameTranslations?.[preferredLanguage] ||
            mapLayer.pdfFilename,
        }),
      })
    } catch (error) {
      await swal({
        title: i18n.t(keys.downloadRequestErrorTitle),
        text: i18n.t(keys.downloadRequestError),
        dangerMode: true,
      })
    } finally {
      setIsLoading(false)
      refreshDeliveryGroupFiles()
    }
  }

  return (
    <Dialog open={open} disableEnforceFocus>
      <DialogTitle>{i18n.t(keys.geoPdfDownload)}</DialogTitle>
      <DialogContent>
        <HelperText>
          <Typography variant="body1" paragraph>
            <span>{i18n.t(keys.geoPdfDownloadByRequest)}</span>
            &nbsp;
            <span>{i18n.t(keys.groupDownloadToRequest)}</span>
            <Icon style={{ transform: 'translate(-4px, 7px)' }}>add</Icon>
          </Typography>
          <Typography variant="body1">
            {i18n.t(keys.groupDownloadMayTakeAMinute)}
          </Typography>
          <Typography variant="body1">
            {i18n.t(keys.groupDownloadOnceGenerated)}
            <Icon style={{ transform: 'translate(0px, 7px)' }}>
              cloud_download
            </Icon>
            {i18n.t(keys.groupDownloadOnceGeneratedPost)}
          </Typography>
        </HelperText>
        <Row
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            paragraph
            variant="subtitle1"
            style={{ textAlign: 'left' }}
          >
            {`${selectedGroup.name} - ${i18n.toDateLong(selectedDate)}`}
          </Typography>
        </Row>

        {isLoading && <LoadingOverlay />}
        <Row
          style={{
            justifyContent: 'space-between',
          }}
        >
          <Row style={{ opacity: groupDownloadCount === 0 ? 0.5 : 1 }}>
            <Checkbox
              indeterminate={
                0 < selectedFiles.size &&
                selectedFiles.size < groupDownloadCount
              }
              disabled={groupDownloadCount === 0}
              checked={
                groupDownloadCount > 0 &&
                selectedFiles.size === groupDownloadCount
              }
              onChange={() => handleGroupDownloadSelectAll()}
            />
            <div
              style={{ cursor: 'pointer', userSelect: 'none' }}
              onClick={() => handleGroupDownloadSelectAll()}
            >
              {i18n.t(keys.generic.selectAll)}
            </div>
          </Row>
          <Row>
            {me && me.roles.length > 0 && groupDownloadCount > 0 && (
              <Tooltip
                title={i18n.t(keys.reprocessDownloadAll)}
                placement="top"
              >
                <IconButton onClick={() => reprocessAll()} size="large">
                  <Icon>sync</Icon>
                </IconButton>
              </Tooltip>
            )}
            {groupDownloadCount < availableDownloadCount && (
              <Tooltip
                title={i18n.t(keys.requestDownloadAll)}
                placement="top"
              >
                <IconButton onClick={() => requestDownloadAll()} size="large">
                  <Icon>add</Icon>
                </IconButton>
              </Tooltip>
            )}
          </Row>
        </Row>

        <List sx={{ width: '100%' }}>
          {avalableGeoPDFLayers &&
            Object.keys(avalableGeoPDFLayers).map((filename) => (
              <GeoPdfDownloadRow
                key={filename}
                mapLayer={avalableGeoPDFLayers?.[filename]}
                groupFile={groupFilesByFilename?.[filename]}
                selected={selectedFiles.has(filename)}
                onReprocessRequested={reprocessPdf}
                onDownloadRequested={requestDownloadPdf}
                onSelected={handleGroupDownloadSelected}
              />
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={selectedFiles.size <= 0}
          size="small"
          variant="contained"
          color="primary"
          onClick={handleDownloadClick}
        >
          {i18n.t(keys.generic.download)}
        </Button>
        <Button size="small" onClick={handleClose}>
          {i18n.t(keys.generic.close)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
