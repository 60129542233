import * as api from '../../../graphql/api'
import { ScheduledNotification } from '../../../graphql/types'
import { createTableFetcher } from '../../../UI/Table/createTableFetcher'
import { tableFormatter } from './notificationsTableFormatters'

export const fetchScheduledNotifications =
  createTableFetcher<ScheduledNotification>(
    tableFormatter,
    `{
      id
      name
      type
      sendDate
      expireDate
      sent
    }`,
    api.scheduledNotifications.list
  )
