import './Legend.scss'

import * as React from 'react'

import { Tooltip } from '@mui/material'

import { MapLayerDefData } from '../../data/types'
import { SelectedStopData, StopData } from '../../ProductStats/types'
import { classnames } from '../../util/classnames'

interface Props {
  stops: StopData[]
  minLabel?: string
  maxLabel?: string
  selected?: SelectedStopData[]
  orientation: 'vertical' | 'horizontal'
  interactive?: boolean
  layerDef: MapLayerDefData
  onClickItem?: (item: StopData, event: React.MouseEvent) => void
}

const NAMED_STOPS: SelectedStopData[] = ['noData', 'coverage']

export class Legend extends React.PureComponent<Props> {
  render() {
    const { orientation, selected = [], minLabel, maxLabel } = this.props
    let { stops } = this.props

    const className = classnames(
      'data_Legend',
      ['vertical', orientation === 'vertical'],
      ['horizontal', orientation === 'horizontal'],
      ['selected', selected.length > 0]
    )

    if (orientation === 'vertical' && (minLabel || maxLabel)) {
      stops = stops.map((item, index) => {
        if (NAMED_STOPS.includes(item.key)) {
          return item
        }

        const label =
          item.key === 0
            ? minLabel!
            : index === stops.length - 1
              ? maxLabel!
              : ''

        return {
          ...item,
          label,
        }
      })
    }

    const len = stops.length
    const namedStopsLen = stops.filter((item) =>
      NAMED_STOPS.includes(item.key)
    ).length

    return (
      <div className={className}>
        {this.renderMinMaxLabels(len, namedStopsLen)}
        {stops.map(this.renderItem)}
      </div>
    )
  }

  renderMinMaxLabels = (len: number, namedStopsLen: number) => {
    const { minLabel, maxLabel, orientation } = this.props
    const percentOffset = (len - namedStopsLen) / 2

    if (len <= 0 || orientation === 'vertical') {
      return null
    }

    return (
      <>
        <div
          className="minLabel"
          style={{
            left: `${(namedStopsLen / len) * 100}%`,
            width: `${(percentOffset / len) * 100}%`,
          }}
        >
          {minLabel}
        </div>
        <div
          className="maxLabel"
          style={{
            left: `${((percentOffset + namedStopsLen) / len) * 100}%`,
            width: `${(percentOffset / len) * 100}%`,
          }}
        >
          {maxLabel}
        </div>
      </>
    )
  }

  renderItem = (item: StopData) => {
    const {
      orientation,
      interactive,
      onClickItem,
      selected = [],
      stops,
    } = this.props

    const className = classnames(
      'item',
      ['selectable', !!(interactive && onClickItem)],
      ['selected', selected.includes(item.key)]
    )

    const onClick =
      interactive && onClickItem
        ? (event: React.MouseEvent) => onClickItem(item, event)
        : undefined

    let itemBlock =
      orientation === 'horizontal'
        ? this.renderHorizontalItem(item)
        : this.renderVerticalItem(item)

    if (interactive && orientation === 'horizontal' && item.label) {
      itemBlock = (
        <Tooltip key={item.key} title={item.label}>
          {itemBlock}
        </Tooltip>
      )
    }

    const style =
      orientation === 'horizontal'
        ? { width: `${(1 / stops.length) * 100}%` }
        : undefined

    return (
      <div key={item.key} className={className} onClick={onClick} style={style}>
        {itemBlock}
      </div>
    )
  }

  renderHorizontalItem = (item: StopData) => {
    let text: React.ReactChild | null = null

    const { minLabel, maxLabel, stops, layerDef } = this.props

    const minMaxLabels = minLabel || maxLabel

    const background = Array.isArray(item.color)
      ? `linear-gradient(to right, ${item.color[0]} 0%, ${item.color[1]} 100%)`
      : item.color

    if (!minMaxLabels) {
      if (item.type === 'label') {
        if (layerDef.dataProperty.type === 'class' && stops.length >= 5) {
          return (
            <div className="itemContainer">
              <div
                className="itemColor tall"
                style={{
                  background,
                }}
              />
              {text}
            </div>
          )
        }
        const tryUsingAcronym = stops.length > 5

        text = (
          <div className="itemText">
            <div className="itemLabel">
              {tryUsingAcronym && item.value ? item.value : item.label}
            </div>
          </div>
        )
      } else if (item.key !== 0) {
        text = (
          <div
            className={classnames('itemValue', [
              'itemValueRight',
              typeof item.key === 'string',
            ])}
          >
            <div className="itemValueText">{item.value}</div>
          </div>
        )
      }
    }

    return (
      <div className="itemContainer">
        <div
          className="itemColor"
          style={{
            background,
          }}
        />
        {text}
      </div>
    )
  }

  renderVerticalItem = (item: StopData) => (
    <>
      <div
        className="itemColor"
        style={{
          background: Array.isArray(item.color)
            ? `linear-gradient(to top, ${item.color[0]} 0%, ${item.color[1]} 100%)`
            : item.color,
        }}
      />
      <div className="itemText">
        <div className="itemLabel">{item.label}</div>
      </div>
    </>
  )
}
