interface NoData {
  property: string
  value: any
  color: string
}

interface CanopyCoverage {
  property: string
  min: number
  max: number
  color: string
}

export interface PaintOptions {
  display: 'class' | 'step' | 'continuous'
  property: string
  stops: [string | number, string][]
  classDefaultColor?: string
  noData?: NoData
  coverage?: CanopyCoverage
  returnClasses?: boolean
}

export function colorProfileToPaint({
  display,
  property,
  stops,
  classDefaultColor = 'white',
  noData,
  returnClasses = false,
}: PaintOptions): any[] {
  // tslint:disable-next-line: no-parameter-reassignment
  stops = makeValuesSameType(stops)

  if (returnClasses) {
    // tslint:disable-next-line: no-parameter-reassignment
    stops = stops.map(([value], index) => [value, `${index}`] as [any, string])
  }

  const flattenedStops =
    display === 'class'
      ? stops.reduce((acc, [value, color]) => [...acc, `${value}`, color], [])
      : stops.reduce((acc, stop) => [...acc, ...stop], [])

  if (display === 'class') {
    return [
      'match',
      ['to-string', ['get', property]],
      ...flattenedStops,
      classDefaultColor,
    ]
  }

  let paint: any[]

  if (display === 'step' || returnClasses) {
    paint = ['step', ['get', property], ...flattenedStops.slice(1)]
  } else {
    paint = ['interpolate', ['linear'], ['get', property], ...flattenedStops]
  }

  if (noData) {
    const casePaint: any[] = ['case']

    if (noData) {
      casePaint.push(
        ['==', ['to-string', ['get', noData.property]], `${noData.value}`],
        returnClasses ? 'noData' : noData.color
      )
    }

    paint = [...casePaint, paint]
  }

  return paint
}

/**
 * Mapbox needs values to be of same type for interpolation, etc.
 */
const makeValuesSameType = (stops: PaintOptions['stops']) => {
  const types = new Set(stops.map(([value]) => typeof value))

  if (types.size === 1) {
    return stops
  }

  // try numbers
  const numberValueStops: typeof stops = []
  for (const [value, stop] of stops) {
    const asNumber = Number(value)
    const isNumber = !isNaN(asNumber)
    if (isNumber) {
      numberValueStops.push([asNumber, stop])
    } else {
      // need to convert all values to string
      break
    }
  }

  if (numberValueStops.length === stops.length) {
    return numberValueStops
  }

  // convert all values to string
  return stops.map(
    ([value, color]) => [String(value), color] as [string, string]
  )
}
