import { Dialog, DialogContent, IconButton } from '@mui/material'
import DeliveryForm from './DeliveryForm'
import { GetDeliveryData } from './selectGetDelivery'
import * as React from 'react'
import { Close } from '@mui/icons-material'

interface Props {
  open: boolean
  delivery: GetDeliveryData
  onSave: (formData: Partial<GetDeliveryData>) => void
  onDelete: () => void
  onClose: () => void
}

export const DeliveryFormDialog = ({
  open,
  delivery,
  onSave,
  onDelete,
  onClose,
}: Props) => {
  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <DeliveryForm
          type="edit"
          data={delivery}
          onSave={onSave}
          onDelete={onDelete}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  )
}
