import * as React from 'react'
import { useFeatureFlag } from '../hooks/useFeatureFlag'

interface Props {
  featureFlagId: string
  children: React.ReactNode | React.ReactNode[]
  off?: boolean
}

export const FeatureToggle = ({ featureFlagId, children, off }: Props) => {
  const { featureEnabled } = useFeatureFlag({ featureFlagId })
  if (!featureEnabled) {
    if (off) {
      return <React.Fragment>{children}</React.Fragment>
    }
    return null
  }

  if (off) {
    return null
  }

  return <React.Fragment>{children}</React.Fragment>
}
