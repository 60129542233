import * as React from 'react'
import { TableFormatter } from '../../../UI/Table/types'
import { SamplePlan } from '../../../graphql/types'
import i18n, { keys } from '../../../i18n'
import { Button, Tooltip, Typography } from '@mui/material'
import { SamplePlanMoreMenu } from '../SamplePlanMoreMenu'
import { SamplePlanStatusText } from '../SamplePlanStatusText'
import { SamplePlanFormChip } from '../SamplePlanFormChip'
import { url, urls } from '../../../appNavigation/urls'
import { History } from 'history'

interface Scope {
  onResultClick?: (id: string) => void
  refresh?: () => void
  searchBarFilterId?: string
  inlineFilterId?: string
  history?: History<any>
}

export const tableFormatterWithScope = ({
  onResultClick,
  refresh,
  searchBarFilterId,
  inlineFilterId,
  history
}: Scope) => {
  const nameColumn: TableFormatter<SamplePlan> = {
    header: () => i18n.t(keys.samplePlanNameColumnLabel),
    data: ({ name }) => (
      <Tooltip title={name} placement="right" followCursor>
        <Typography noWrap sx={{ maxWidth: '125px' }}>
          {name}
        </Typography>
      </Tooltip>
    ),
    filterBy: (op, value) => ({
      name: { [`_${op}`]: value },
    }),
  }

  const locationColumn: TableFormatter<SamplePlan> = {
    header: () => i18n.t(keys.samplePlanBlockLocationColumnLabel),
    data: ({ SamplePlanBlocks }) => (
      <Tooltip
        title={
          SamplePlanBlocks?.map((spb) => spb.Parcel.OrganizationGroup?.name)
            .filter(
              (ogn, i, organizationGroups) =>
                organizationGroups.indexOf(ogn) === i
            )
            .join(', ') ?? '-'
        }
        placement="right"
        followCursor
      >
        <Typography noWrap sx={{ maxWidth: '125px' }}>
          {SamplePlanBlocks?.map((spb) => spb.Parcel.OrganizationGroup?.name)
            .filter(
              (ogn, i, organizationGroups) =>
                organizationGroups.indexOf(ogn) === i
            )
            .join(', ') ?? '-'}
        </Typography>
      </Tooltip>
    ),
    filterBy: (op, value) => ({
      SamplePlanBlocks: {
        Parcel: {
          Group: {
            name: { [`_${op}`]: value },
          },
        },
      },
    }),
  }

  const dateColumn: TableFormatter<SamplePlan> = {
    header: () => i18n.t(keys.samplePlanDateColumnLabel),
    data: ({ createdAt }) => i18n.toDateShort(createdAt),
    orderBy: (direction) => `
        {
          createdAt: ${direction}
        }
      `,
  }

  const formColumn: TableFormatter<SamplePlan> = {
    header: () => i18n.t(keys.samplePlanFormColumnLabel),
    data: ({ NoteForm, templateName }) => {
      return (
        <SamplePlanFormChip
          templateName={
            i18n.t(`noteForm.reservedNames.${templateName}`, {defaultValue: templateName ?? ''})
          }
          noteFormColor={NoteForm?.pinColor}
        />
      )
    },
    filterBy: (op, value) => ({
      templateName: { name: { [`_${op}`]: value } },
    }),
  }

  const blocksColumn: TableFormatter<SamplePlan> = {
    header: () => i18n.t(keys.samplePlanBlocksColumnLabel),
    data: ({ SamplePlanBlocks }) => {
      const samplePlanBlocks =
        SamplePlanBlocks?.map((spb) => spb?.Parcel?.name ?? '').join(' | ') ??
        ''
      return (
        <Tooltip title={samplePlanBlocks} placement="right" followCursor>
          <Typography noWrap sx={{ maxWidth: '100px' }}>
            {samplePlanBlocks}
          </Typography>
        </Tooltip>
      )
    },
  }

  const samplesColumn: TableFormatter<SamplePlan> = {
    header: () => i18n.t(keys.samplePlanSamplesColumnLabel),
    data: ({ totalSamples, completedSamples }) => {
      const total = totalSamples?.aggregate?.count
        ? totalSamples?.aggregate?.count
        : '-'
      const completed = completedSamples?.aggregate?.count
        ? completedSamples?.aggregate?.count
        : 0
      return `${completed}/${total}`
    },
    orderBy: (direction) => `
        {
          Samples_aggregate: { count: ${direction} }
        }
      `,
  }

  const lastUpdateColumn: TableFormatter<SamplePlan> = {
    header: () => i18n.t(keys.samplePlanLastUpdateColumnLabel),
    data: ({ updatedAt }) => i18n.toDateShort(updatedAt),
    orderBy: (direction) => `
        {
          updatedAt: ${direction}
        }
      `,
  }

  const statusColumn: TableFormatter<SamplePlan> = {
    header: () => i18n.t(keys.samplePlanStatusColumnLabel),
    data: ({ totalSamples, completedSamples, isPending }) => (
      <SamplePlanStatusText
        isPending={isPending}
        totalSamples={totalSamples?.aggregate.count}
        completedSamples={completedSamples?.aggregate.count}
      />
    ),
  }

  const openResultColumn: TableFormatter<SamplePlan> = {
    header: () => '',
    data: ({ id, isPending }) => (
      <Button
        title={i18n.t(keys.results)}
        disabled={isPending}
        variant="contained"
        onClick={async (e) => {
          e.stopPropagation()
          onResultClick?.(id)
        }}
      >
        {i18n.t(keys.results)}
      </Button>
    ),
  }

  const moreMenuColumn: TableFormatter<SamplePlan> = {
    header: () => '',
    data: ({ id, name }) => (
      <SamplePlanMoreMenu 
        samplePlanName={name} 
        samplePlanId={id} 
        refresh={refresh}
        onDuplicate={(duplicateSamplePlanId) => {
          if (!history){
            return
          }
          history.push(
            url(urls.samplePlan, {
              samplePlanId: duplicateSamplePlanId,
            })
          )
        }
        }/>
    ),
  }

  // for more complex filters, its easier to roll them together.
  const searchFilter: TableFormatter<SamplePlan> = {
    filterId: searchBarFilterId,
    filterBy: (op, value) => ({
      _or: [
        !!nameColumn.filterBy && nameColumn.filterBy(op, value),
        !!formColumn.filterBy && formColumn.filterBy(op, value),
      ],
    }),
  }

  const inlineButtonFilter: TableFormatter<SamplePlan> = {
    filterId: inlineFilterId,
    filterBy: (op, value) => ({
      _and: [!!locationColumn.filterBy && locationColumn.filterBy(op, value)],
    }),
  }

  return [
    nameColumn,
    locationColumn,
    dateColumn,
    formColumn,
    blocksColumn,
    samplesColumn,
    lastUpdateColumn,
    statusColumn,
    openResultColumn,
    moreMenuColumn,
    searchFilter,
    inlineButtonFilter,
  ]
}
