import { isEmpty } from 'lodash'

import * as React from 'react'
import { PureComponent } from 'react'
import InputLabel from '@mui/material/InputLabel'

import {
  Checkbox,
  FormControl,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { selectProductsForOrg } from '../../data/selectListMapSourceDefs'
import { selectParcelFilterInfo } from '../../data/selectParcelFilterInfo'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import * as actions from '../actions'

const CLEAR_FILTER = 'CLEAR_FILTER'

class ProductFilterSelect extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    return (
      <ListItem>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.productsText)}</InputLabel>

          {this.renderAvailableValues()}
        </FormControl>
      </ListItem>
    )
  }

  renderAvailableValues = () => {
    const { products, parcelFilterInfo } = this.props

    const selectedValues = (parcelFilterInfo.products ?? []) as string[]

    return (
      <>
        <Select
          disabled={products.length === 0}
          multiple
          value={selectedValues}
          onChange={this.handleSelectionChange}
          renderValue={(selected: any[]) =>
            products
              .filter(({ id }) => selected.includes(id))
              .map(({ name }) => name)
              .join(', ')
          }
        >
          <MenuItem value={CLEAR_FILTER} disabled={isEmpty(selectedValues)}>
            <ListItemText
              primary={i18n.t(keys.forms.parcelFilter.resetSelection)}
              onClick={this.resetSelection}
            />
          </MenuItem>
          {products.map(({ id: value, name }) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={selectedValues.includes(value)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </>
    )
  }

  handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
    let selection = event.target.value
    if (typeof selection === 'string') {
      selection = selection.split(', ')
    }
    if (selection.includes(CLEAR_FILTER)) {
      return
    }

    this.props.dispatch(
      actions.setParcelFilterInfo({
        ...this.props.parcelFilterInfo,
        products: selection,
      })
    )
  }

  resetSelection = () => {
    this.props.dispatch(
      actions.setParcelFilterInfo({
        ...this.props.parcelFilterInfo,
        products: [],
      })
    )
  }
}

const mapState = (state: RootStore) => ({
  parcelFilterInfo: selectParcelFilterInfo(state),
  products: selectProductsForOrg(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  ProductFilterSelect
)
