import { createSelector } from 'reselect'
import { getSelectedBackgroundProductId } from './getSelectedBackgroundProductId'
import { selectFilteredMapSources } from './selectFilteredMapSources'
import { selectSelectedProductIds } from './selectSelectedProductIds'
import { MapSourceData } from './types'

export const selectSelectedMapSources = createSelector(
  selectFilteredMapSources,
  selectSelectedProductIds,
  getSelectedBackgroundProductId,
  (filteredMapSources, selectedProductIds, selectedBackgroundProductId) => {

    const allProductIds = [
      ...(selectedProductIds ?? []),
      ...(!!selectedBackgroundProductId ? [selectedBackgroundProductId] : []),
    ]


    const sources: Record<string, MapSourceData[]> = {}

    for (const id of allProductIds) {
      const mapSources = filteredMapSources.filter(ms => ms.mapLayers.some((ml) => id === ml.mapLayerDefId || id === `${ml.mapLayerDefId}-block-by-block`))
      if (mapSources.length > 0) {
        sources[id] = mapSources
      }
    }

    return sources
  }
)
