import * as React from 'react'
import {
  DEFAULT_PAGE_INFO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../../appNavigation/urls'
import { ScheduledNotification } from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import { useHistory, useSearchParams } from '../../../hooks/useRouter'
import { createTableActionIconButton } from '../../../UI/Table/createTableActionIconButton'
import { ORDER } from '../../../UI/Table/orderRows'
import { Table } from '../../../UI/Table/Table'
import {
  PaginationChange,
  TableAction,
  TableOrder,
} from '../../../UI/Table/types'
import { AdminPage } from '../AdminPage'
import { deleteScheduledNotificationById } from './deleteScheduledNotificationById'
import { fetchScheduledNotifications } from './fetchScheduledNotifications'
import { tableFormatter } from './notificationsTableFormatters'

export function ScheduledNotifications() {
  const {
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    order = '',
    filter = '',
  } = useSearchParams()
  const history = useHistory()

  const [result, refresh] = useAsync(fetchScheduledNotifications, [
    Number(page),
    Number(pageSize),
    order,
    filter,
  ])

  const updateSearchParams = (update: {
    page?: number
    pageSize?: number
    order?: string
    filter?: string
  }) => {
    history.push(
      url(
        urls.admin_notifications,
        {},
        {
          page: Number(page),
          pageSize: Number(pageSize),
          order,
          filter,
          ...update,
        }
      )
    )
  }

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateSearchParams(pagination)

  // tslint:disable-next-line: no-shadowed-variable
  const handleOrderChange = (order: TableOrder) => {
    updateSearchParams({ order: ORDER.serialize(order) })
  }

  const rows = result.result ? result.result.data : []
  const info = result.result
    ? result.result.info
    : { ...DEFAULT_PAGE_INFO, order: [] }

  const { newBtn, editBtn, deleteBtn, refreshBtn } = tableActions(
    history,
    refresh
  )

  return (
    <AdminPage title="Notifications">
      <div className="Paper">
        <Table
          title="Notifications"
          rows={rows}
          actions={[newBtn, editBtn, deleteBtn, refreshBtn]}
          formatter={tableFormatter}
          order={info.order}
          onOrderChange={handleOrderChange}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pagination={info}
          onPaginationChange={handlePaginationChange}
        />
      </div>
    </AdminPage>
  )
}

function tableActions(
  history: ReturnType<typeof useHistory>,
  refresh: () => void
) {
  const refreshBtn: TableAction<ScheduledNotification> = {
    button: createTableActionIconButton({
      title: 'Refresh',
      icon: 'autorenew',
      key: 'refresh-notification',
    }),
    action: refresh,
  }

  const newBtn: TableAction<ScheduledNotification> = {
    button: createTableActionIconButton({
      title: 'Schedule New Notification',
      icon: 'add',
      key: 'new-notification',
    }),
    action: () => history.push(url(urls.admin_notifications_new)),
  }

  const editBtn: TableAction<ScheduledNotification> = {
    selectionRequired: true,
    button: createTableActionIconButton({
      title: 'Edit Scheduled Notification',
      icon: 'edit',
      key: 'edit-notification',
    }),
    action: ([{ id }]) =>
      history.push(url(urls.admin_notifications_edit, { id })),
  }

  const deleteBtn: TableAction<ScheduledNotification> = {
    selectionRequired: true,
    button: createTableActionIconButton({
      title: 'Delete Scheduled Notification',
      icon: 'delete',
      key: 'delete-notification',
    }),
    action: async (rows) => {
      for (const { id } of rows) {
        await deleteScheduledNotificationById(id)
      }
      refresh()
    },
  }

  return { newBtn, editBtn, deleteBtn, refreshBtn }
}
