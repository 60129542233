import * as React from 'react'
import { Button, CircularProgress, Paper, Typography } from '@mui/material'
import { RouteComponentProps } from 'react-router-dom'
import { default as swal } from 'sweetalert'
import { Column } from '../admin/UI/Column'
import { Row } from '../admin/UI/Row'
import Page from '../app/Page'
import { RouteParams, url, urls } from '../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../AsyncSelector/AsyncSelectorStatusOverlay'
import createCompositeAsyncSelector from '../AsyncSelector/createCompositeAsyncSelector'

import { refreshGetMe, selectGetMe, selectMe } from '../data/selectMe'
import { refreshOrganizationList } from '../data/selectOrganizationList'
import * as userSelection from '../data/userSelectionRedux'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import { RequestError } from '../util/request'
import { login } from '../vvapi/login'
import { acceptInvitationExisting } from './acceptInvitation'
import {
  GetUserInviteData,
  refreshGetUserInvite,
  selectGetUserInvite,
  selectUserInvite,
} from './selectInvitation'
import LinkChild from '../UI/LinkChild'

const { selector: compositeSelector } = createCompositeAsyncSelector({
  me: { selector: selectGetMe, refresh: refreshGetMe },
  userInvites: { selector: selectGetUserInvite, refresh: refreshGetUserInvite },
})

class Inivitation extends React.Component<
  RouteComponentProps<RouteParams> & ReduxProps & AppDispatchProps
> {
  render() {
    const { asyncSelectors } = this.props

    return (
      <Page title={i18n.t(keys.invitation)}>
        <Column
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <AsyncSelectorStatusOverlay
            requests={asyncSelectors}
            hideChildrenWhenLoading
            style={{ width: '100%' }}
            overlayStyle={{ width: '100%' }}
          >
            <Column
              style={{
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {this.renderContent()}
            </Column>
          </AsyncSelectorStatusOverlay>
        </Column>
      </Page>
    )
  }

  renderContent = () => {
    const { isLoggedIn, isLoginInProgress, userInvite } = this.props

    if (isLoginInProgress) {
      return <CircularProgress />
    }

    if (!userInvite) {
      return <CircularProgress />
    }

    if (typeof userInvite === 'string') {
      return this.renderMissingOrAccepted()
    }

    if (isLoggedIn) {
      return this.renderLoggedIn(userInvite)
    }

    return this.renderLoggedOut(userInvite)
  }

  renderMissingOrAccepted = () => (
    <Column style={{ width: '100%', alignItems: 'center' }}>
      <Typography variant="h6" style={{ textAlign: 'center', marginTop: 8}}>
        {i18n.t(keys.inviteInvalid)}
      </Typography>
      <Column
        style={{
          backgroundColor: 'rgb(66, 66, 66)',
          alignItems: 'center',
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 16,
          paddingBottom: 16,
          marginTop: 16,
          borderRadius: 8,
      }}>
        <Typography variant="h6" style={{ textAlign: 'center'}}>
          {i18n.t(keys.continueWith)}
        </Typography>
        <Button
          style={{ marginTop: 8, width: '100%' }}
          color="primary"
          variant="contained"
          onClick={this.onMapPress}
        >
          {this.props.me ? i18n.t(keys.map.map) : i18n.t(keys.forms.login.login)}
        </Button>
      </Column>
    </Column>
  )

  renderLoggedIn = (userInvite: GetUserInviteData) => {
    const { me } = this.props
    if (!me || !userInvite) {
      return
    }

    if (me.email !== userInvite.email) {
      return (
        <Paper className="Paper">
          <Column style={{ width: '100%', alignItems: 'center' }}>
            <span>
              <Typography>{i18n.t(keys.wrongInviteEmail)}</Typography>
              <Typography>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
                <a href="javascript:void(0);"
                  onClick={this.logout}
                  style={{ marginTop: 8 }}
                >
                  {i18n.t(keys.switchAcount)}
                </a>{' '}
                {i18n.t(keys.toAcceptInvite)}
              </Typography>
            </span>
          </Column>
        </Paper>
      )
    }

    return (
      <Paper className="Paper">
        <Column style={{ width: '100%', alignItems: 'center' }}>
          {this.renderInvitationMessage(userInvite)}
          <Row
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Column
              style={{
                alignItems: 'center',
                height: 100,
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                style={{ marginTop: 8 }}
                color="primary"
                variant="contained"
                onClick={this.join}
              >
                {i18n.t(keys.join, {
                  user: `${me.firstName} ${me.lastName} (${me.email})`,
                })}
              </Button>
            </Column>
          </Row>
        </Column>
      </Paper>
    )
  }

  renderLoggedOut = (userInvite: GetUserInviteData) => {
    if (!!userInvite.user) {
      return (
        <Paper className="Paper">
          <Column style={{ width: '100%', alignItems: 'center' }}>
            <span>
              <Typography>{i18n.t(keys.mustBeSignedIn)}</Typography>

              <Typography>
                <LinkChild
                  to={url(urls.invitationExisting, { token: this.props.token })}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
                  <a style={{ marginTop: 8 }} href="javascript:void(0);">
                    {i18n.t(keys.forms.login.login)}
                  </a>
                </LinkChild>{' '}
                {i18n.t(keys.toAcceptInvite)}
              </Typography>
            </span>
          </Column>
        </Paper>
      )
    }

    return (
      <Paper className="Paper">
        <Column
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 125,
          }}
        >
          {this.renderInvitationMessage(userInvite)}
          <Row
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'space-evenly',
              alignItems: 'flex-end',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={this.useNewAccount}
            >
              {i18n.t(keys.signUpToAccept)}
            </Button>
          </Row>
        </Column>
      </Paper>
    )
  }

  renderInvitationMessage = (userInvite: GetUserInviteData) => {
    if (!userInvite) {
      return null
    }

    return (
      <Typography variant="h6" style={{ textAlign: 'center', marginTop: 8 }}>
        {i18n.t(keys.inviteMessage, {
          organizationName: userInvite.organization.name,
          invitee: `${userInvite.invitedBy.firstName} ${userInvite.invitedBy.lastName}`,
        })}
      </Typography>
    )
  }

  logout = async () => {
    const { token } = this.props

    await this.props.dispatch(login.actions.logout())
    this.props.history.replace(url(urls.invitation, { token }))
  }

  onMapPress = async () => {
    this.props.history.push(url(urls.mapView))
  }

  useExistingAccount = () => {
    const { token } = this.props
    this.props.history.replace(url(urls.invitationExisting, { token }))
  }

  useNewAccount = () => {
    const { token } = this.props
    this.props.history.replace(url(urls.invitationNew, { token }))
  }

  join = async () => {
    const { token, userInvite } = this.props
    if (!token || !userInvite || typeof userInvite === 'string') {
      return
    }

    try {
      await acceptInvitationExisting(token)
      refreshOrganizationList()

      if (
        await swal({
          title: i18n.t(keys.joinedOrgSuccessTitle),
          text: i18n.t(keys.joinedOrgSuccess, {
            organizationName: userInvite.organization.name,
          }),
          buttons: {
            cancel: {
              visible: true,
              text: i18n.t(keys.generic.noThanks),
            },
            confirm: {
              text: i18n.t(keys.generic.yes),
            },
          },
        })
      ) {
        this.props.dispatch(
          userSelection.update({
            selectedOrganizationId: userInvite.organizationId,
          })
        )
      }

      this.props.history.replace(url(urls.mapView))
    } catch (error) {
      if (error instanceof RequestError) {
        if (error.response.status === 409) {
          return await swal({
            text: i18n.t(keys.userAlreadyBelongsToOrg, {
              organizationName: userInvite.organization.name,
            }),
          })
        }
        if (error.response.status === 403) {
          return await swal({
            text: i18n.t(keys.inviteInvalid),
          })
        }
        if (error.response.status === 400) {
          return await swal({
            text: i18n.t(keys.wrongInviteEmail),
          })
        }

        // tslint:disable-next-line: no-console
        console.warn('Error joining organization:', error)
        throw error
      }
    }
  }
}

const mapState = (state: RootStore) => ({
  token: state.router.params.token,
  isLoginInProgress: state.login.isInProgress,
  isLoggedIn: state.login.isLoggedIn,
  me: selectMe(state),
  userInvite: selectUserInvite(state),
  asyncSelectors: compositeSelector(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(Inivitation)
