import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { selectOrganizationId } from './selectOrganizationId'
import { selectParcelFilterWhere } from './selectParcelFilterWhere'
import { selectSelectedParcelIds } from './selectSelectedParcelIds'

export interface DeliveryFilter {
  deliveries: {
    deliveryId: string
  }[]
}

const deliveryFilterFetcher = async ({
  organizationId,
  parcelFiltersWhere,
  selectedParcelIds,
}: {
  organizationId: ReturnType<typeof selectOrganizationId>
  parcelFiltersWhere: ReturnType<typeof selectParcelFilterWhere>
  selectedParcelIds: ReturnType<typeof selectSelectedParcelIds>
}) => {
  const deliveryFilters = parcelFiltersWhere.slice()

  // if no parcels are selected and there are no other filters return null
  if (
    !organizationId ||
    (selectedParcelIds.size === 0 && deliveryFilters.length === 0)
  ) {
    return null
  }

  deliveryFilters.push({ parcelId: { _in: Array.from(selectedParcelIds) } })
  // Filter the filters to groups that the user has access to
  deliveryFilters.push({ OrganizationGroup: {} })

  const {
    organization: {
      orgParcels: { deliveries },
    },
  } = await query<{
    organization: { orgParcels: { deliveries: { deliveryId: string }[] } }
  }>({
    query: gql`
      query PARCEL_FILTER(
        $deliveryFilters: View_ParcelFilter_bool_exp!
        $organizationId: Int!
      ) {
        organization: Organization_by_pk(id: $organizationId) {
          orgParcels: ParcelFilters {
            deliveries: ParcelFilters(
              distinct_on: deliveryId
              where: $deliveryFilters
            ) {
              deliveryId
            }
          }
        }
      }
    `,
    variables: {
      organizationId,
      deliveryFilters: {
        _and: deliveryFilters,
      },
    },
  })

  if (!deliveries) {
    throw new Error('No Data')
  }

  return { deliveries }
}

const { selector: selectDeliveryFilter, refresh: refreshDeliveryFilter } =
  createAsyncSelector({
    resource: 'me.organization.deliveryFilter',
    inputs: {
      organizationId: selectOrganizationId,
      parcelFiltersWhere: selectParcelFilterWhere,
      selectedParcelIds: selectSelectedParcelIds,
    },
    fetcher: deliveryFilterFetcher,
  })

export { selectDeliveryFilter, refreshDeliveryFilter, deliveryFilterFetcher }
