import { createSelector } from '@reduxjs/toolkit'
import {
  MapEditorToolType,
  MapEditorToolsState,
} from '../../../redux/map-editor-pages/redux'
import { RootStore } from '../../../redux/types'

const getPageLeftDrawerStatus = (
  state: MapEditorToolsState,
  pageType: MapEditorToolType
) => ({
  leftDrawerOpen: state?.[pageType]?.leftDrawerOpen,
  leftDrawerTabIndex: state?.[pageType]?.leftDrawerTabIndex,
})

const getPageRightDrawerStatus = (
  state: MapEditorToolsState,
  pageType: MapEditorToolType
) => ({
  rightDrawerOpen: state?.[pageType]?.rightDrawerOpen,
  rightDrawerTabIndex: state?.[pageType]?.rightDrawerTabIndex,
})

export const selectDrawerStatus = createSelector(
  [
    (state: RootStore) => state.mapEditorTools,
    (_: RootStore, pageType: MapEditorToolType) => pageType,
  ],
  (toolPages, pageType) => {
    return {
      ...getPageLeftDrawerStatus(toolPages, pageType),
      ...getPageRightDrawerStatus(toolPages, pageType),
    }
  }
)
