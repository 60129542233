import * as api from '../../../graphql/api'
import { Variable } from '../../../graphql/types'
import { createTableFetcher } from '../../../UI/Table/createTableFetcher'
import { tableFormatter } from './variablesTableFormatters'

export const fetchVariables = createTableFetcher<Variable>(
  tableFormatter,
  `{
    key
    value
  }`,
  api.variables.list
)
