import * as React from 'react'
import { MapContext } from '../withMap'

interface Props {
  layers: string[]
  hidden: boolean
}

export const LayerVisibilityToggle = ({
  layers,
  hidden: layersHidden,
}: Props) => {
  const { map } = React.useContext(MapContext)

  const showOrHideLayers = () => {
    if (map && map.isStyleLoaded()) {
      const opacity = layersHidden ? 0 : 1

      layers.forEach((layer) => {
        const mapLayer = map.getLayer(layer)
        if (!mapLayer) {
          return
        }
        switch (mapLayer.type) {
          case 'circle':
            map.setPaintProperty(layer, 'circle-opacity', opacity)
            map.setPaintProperty(layer, 'circle-stroke-opacity', opacity)
            break
          case 'raster':
            map.setPaintProperty(layer, 'raster-opacity', opacity)
            break
          case 'fill':
            map.setPaintProperty(layer, 'fill-opacity', opacity)
            break
          case 'line':
            map.setPaintProperty(layer, 'line-opacity', opacity)
            break
        }
      })
    }
  }

  React.useEffect(() => {
    if (map) {
      if (map.loaded()) {
        showOrHideLayers()
      } else {
        map.once('idle', showOrHideLayers)
      }
    }
  }, [map, layers, layersHidden])

  return null
}
