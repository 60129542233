import { Checkbox, ListItem, ListItemText, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import * as React from 'react'
import {
  ParcelData,
  selectGroupDatesByGroupId,
} from '../../../../../data/selectOrgMapData'
import { useRedux } from '../../../../../hooks/useRedux'
import { dateRangeString } from '../../../../../postgis/ParcelDrawer/getParcelDateString'
import i18n, { keys } from '../../../../../i18n'
import { toggleArrayValue } from '../../../../../util/toggleArrayValue'

interface Props {
  parcel: ParcelData
  selectedParcelIds: Set<string>
  selectedFlightDate: string
  filteredParcels: ParcelData[]
  onSelect: (parcelIds: string[], flightDate?: string) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      height: 'auto',
      padding: 0,
    },
  })
)

const BlockListItem = ({
  parcel,
  selectedParcelIds,
  selectedFlightDate,
  filteredParcels,
  onSelect,
}: Props) => {
  const [state] = useRedux()
  const classes = useStyles()

  const flightDatesByGroupId = selectGroupDatesByGroupId(state)

  const handleListItemClick = React.useCallback(
    (event: any) => {
      const parcelId = parcel.id
      const group = parcel.group

      const newFlightDate =
        selectedFlightDate && group
          ? selectedFlightDate
          : flightDatesByGroupId[group.id]?.[0]?.flightDate

      if (typeof event.target.checked === 'boolean') {
        // when clicking the checkbox toggle this parcel's visibility
        const newSelection = toggleArrayValue(
          Array.from(selectedParcelIds),
          parcelId
        )

        // remove any parcels in other groups
        const newSelectionFiltered = newSelection.filter((id) => {
          const parcel = filteredParcels.find((p) => p.id === id)
          return parcel && parcel.groupId === group.id
        })

        onSelect(newSelectionFiltered, newFlightDate)
      } else {
        // clicking anywhere else on the row sets only this parcel as visible
        onSelect([parcelId], newFlightDate)
      }
    },
    [
      parcel.id,
      parcel.group,
      selectedFlightDate,
      flightDatesByGroupId,
      filteredParcels,
      selectedParcelIds,
      onSelect,
    ]
  )

  const dates = dateRangeString([
    parcel.flightDates[parcel.flightDates.length - 1],
  ])
  const disabled = !dates

  const datesString = dates || i18n.t(keys.generic.unavailable)
  return (
    <ListItem disabled={disabled} button onClick={handleListItemClick}>
      <Checkbox
        checked={selectedParcelIds.has(parcel.id)}
        disableRipple
        color="primary"
        className={classes.checkbox}
        style={{ marginLeft: 24 }}
      />

      <ListItemText
        style={{ marginLeft: 20 }}
        primary={
          <Typography variant="body2" style={{ textTransform: 'uppercase' }}>
            {parcel.name}
          </Typography>
        }
        disableTypography
        secondary={
          <Typography variant="caption" style={{ textTransform: 'uppercase' }}>
            {datesString}
          </Typography>
        }
      />
    </ListItem>
  )
}

export default BlockListItem
