import { TableCell, TableRow } from '@mui/material'
import * as React from 'react'
import {
  enabledStatus,
  expectedStatus,
  fulfilledStatus,
} from '../../../admin/Status/DeliveryStatus/ShortStatus'
import { Row } from '../../../admin/UI/Row'
import { FormControlLabel } from '@mui/material'
import { ExpandButton } from '../../../UI/Expand/ExpandButton'
import {
  SelectBox,
  SelectBoxProps,
  selectionOf,
} from '../../../UI/SelectBox/SelectBox'
import { DeliveryGroupParcelFiles } from './DeliveryGroupParcelFiles'
import {
  DeliveryGroupStatus,
  ParcelStatuses,
} from './selectGetDetailedDeliveryStatus'

interface Props {
  group: DeliveryGroupStatus
  parcelFiles: ParcelStatuses
  selection: Record<string, Record<string, boolean>>

  selectionChanged: (
    selected: boolean,
    groupId: string,
    parcelName?: string,
    layerName?: string
  ) => void
}

interface State {
  showDetails: boolean
  selectionValue: SelectBoxProps['selection']
}

export class DeliveryGroupStatusView extends React.Component<Props, State> {
  state: State = {
    showDetails: false,
    selectionValue: 'unselected',
  }

  componentDidMount() {
    this.updateSelectedState()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.selection !== this.props.selection) {
      this.updateSelectedState()
    }

    if (prevProps.parcelFiles !== this.props.parcelFiles) {
      this.updateSelectedState()
    }
  }

  render() {
    const { group, parcelFiles, selection } = this.props
    const { deliveryGroup, groupId, ...status } = group
    const { showDetails, selectionValue } = this.state

    const isDirty = group.parcelFiles.some(
      (pf) =>
        (pf.MapLayer?.enabled || false) !==
        ((pf.MapLayer && selection?.[pf.parcel.name][pf.MapLayer.id]) || false)
    )

    return (
      <>
        <TableRow>
          <TableCell colSpan={8}>
            <Row>
              <ExpandButton
                expanded={showDetails}
                onClick={() => this.setState({ showDetails: !showDetails })}
              />
              <FormControlLabel
                style={{ marginLeft: -8 }}
                label={
                  isDirty
                    ? `${deliveryGroup.group.name}*`
                    : deliveryGroup.group.name
                }
                control={
                  <SelectBox
                    disabled={group.readyCount <= 0}
                    selection={selectionValue}
                    onChange={() => this.handleGroupSelectedChange()}
                  />
                }
              />
            </Row>
          </TableCell>
          <TableCell>
            <Row>{expectedStatus(status)}</Row>
          </TableCell>
          <TableCell>
            <Row>{fulfilledStatus(status)}</Row>
          </TableCell>
          <TableCell>
            <Row>{enabledStatus(status)}</Row>
          </TableCell>
        </TableRow>
        {showDetails &&
          Object.keys(parcelFiles).map((parcelName) => {
            const parcelStatus = parcelFiles[parcelName]
            const parcelSelection =
              selection?.[parcelName] ?? ({} as Record<string, boolean>)

            return (
              <DeliveryGroupParcelFiles
                key={parcelName}
                parcelStatus={parcelStatus}
                parcelName={parcelName}
                parcelSelection={parcelSelection}
                selectionChanged={this.handleSelectionChanged}
              />
            )
          })}
      </>
    )
  }

  updateSelectedState() {
    const { selection, parcelFiles } = this.props
    let selectedCount = 0
    let totalCount = 0
    for (const [key, value] of Object.entries(parcelFiles)) {
      selectedCount += Object.values((selection ?? {})[key] ?? {}).filter(
        (pf) => !!pf
      ).length
      totalCount += value.files.length
    }

    this.setState({ selectionValue: selectionOf(selectedCount, totalCount) })
  }

  handleGroupSelectedChange = () => {
    const {
      group: { groupId },
      selectionChanged,
    } = this.props
    const { selectionValue } = this.state
    selectionChanged(selectionValue === 'unselected', groupId)
  }

  handleSelectionChanged = (
    selected: boolean,
    parcelName: string,
    layerName?: string
  ) => {
    const {
      group: { groupId },
      selectionChanged,
    } = this.props
    selectionChanged(selected, groupId, parcelName, layerName)
  }
}
