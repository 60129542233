import * as React from 'react'

import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

import {
  selectGroupDatesByGroupId,
  selectGroupDatesByGroupIdAndDeliveryId,
  selectGroupsById,
} from '../data/selectOrgMapData'
import { useRedux } from '../hooks/useRedux'
import { setIntersect } from '../util/setIntersect'
import i18n, { keys } from '../i18n'

interface Props {
  selectedDeliveries: Set<string>
  selectedGroups: Set<string>
  selectedDownloadType: string | undefined
  setSelectedDeliveries: (selectedDeliveries: Set<string>) => void
  setSelectedGroups: (selectedGroups: Set<string>) => void
}

export const DownloadGroupsAndDates = ({
  setSelectedDeliveries,
  setSelectedGroups,
  selectedDeliveries,
  selectedGroups,
  selectedDownloadType,
}: Props) => {
  const [state] = useRedux()

  const groupsById = selectGroupsById(state)
  const dates = selectGroupDatesByGroupId(state)
  const groupDatesByGroupIdAndDeliveryId =
    selectGroupDatesByGroupIdAndDeliveryId(state)

  const [availableDates, setAvailableDates] = React.useState<
    Record<string, string>
  >({})
  const [selectedDateString, setSelectedDateString] = React.useState<string>('')
  const [selectedGroupString, setSelectedGroupString] =
    React.useState<string>('')

  React.useEffect(() => {
    const availableDates = Array.from(selectedGroups).reduce(
      (currDates, group, index) => {
        const filteredDates: Record<string, string> = {}

        for (let date of dates[group] || []) {
          const isDataEnabled =
            (selectedDownloadType === 'geoPdf' &&
              date.enabledMapLayerDefIds.length > 0) ||
            (selectedDownloadType === 'rawData' &&
              date.enabledFilenames.length > 0)
          if (isDataEnabled) {
            filteredDates[date.flightDate] = date.deliveryId
          }
        }

        if (index === 0) {
          return filteredDates
        }

        const updatedCurrDates: Record<string, string> = {}

        for (let flightDate in currDates) {
          //Only include enabled data if enabled for other existing groups
          if (filteredDates[flightDate]) {
            updatedCurrDates[flightDate] = currDates[flightDate]
          }
        }

        return updatedCurrDates
      },

      {} as Record<string, string>
    )

    setSelectedDeliveries(
      setIntersect(
        selectedDeliveries,
        new Set<string>(Object.values(availableDates))
      )
    )

    setAvailableDates(availableDates)
  }, [selectedGroups, setSelectedDeliveries, setAvailableDates]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const groups = []
    for (const groupId of Array.from(selectedGroups)) {
      groups.push(groupsById[groupId]?.name)
    }

    setSelectedGroupString(groups.join(', '))
  }, [selectedGroups, groupsById, setSelectedGroupString])

  React.useEffect(() => {
    const selectedDates = Array.from(
      Array.from(selectedDeliveries).reduce((dates, deliveryId) => {
        for (const groupId of Array.from(selectedGroups)) {
          const groupDates =
            groupDatesByGroupIdAndDeliveryId[`${groupId}/${deliveryId}`]
          if (groupDates !== undefined) {
            for (const date of groupDates) {
              dates.add(date.flightDate)
            }
          }
        }

        return dates
      }, new Set<string>())
    )

    setSelectedDateString(selectedDates.join(', '))
  }, [selectedGroups, groupDatesByGroupIdAndDeliveryId, selectedDeliveries])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {i18n.t(keys.selectGroupsAndDates)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.groups)}: </InputLabel>
          <Select
            value={Array.from(selectedGroups)}
            multiple
            onChange={(ev) => setSelectedGroups(new Set(ev.target.value))}
            renderValue={() => selectedGroupString}
          >
            {Object.values(groupsById).map((group) => (
              <MenuItem key={group.id} value={group.id}>
                <Checkbox checked={selectedGroups.has(group.id)} />
                <ListItemText primary={group.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>{i18n.t(keys.dates)}: </InputLabel>
          <Select
            disabled={!selectedGroups.size}
            value={Array.from(selectedDeliveries)}
            multiple
            onChange={(ev) => setSelectedDeliveries(new Set(ev.target.value))}
            renderValue={() => selectedDateString}
          >
            {Object.keys(availableDates).length === 0 ? (
              <MenuItem disabled>
                <ListItemText primary={i18n.t(keys.noAvailableDates)} />
              </MenuItem>
            ) : (
              Object.entries(availableDates).map(([date, deliveryId]) => (
                <MenuItem key={deliveryId} value={deliveryId}>
                  <Checkbox checked={selectedDeliveries.has(deliveryId)} />
                  <ListItemText primary={date} />
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
