import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class NoActionGenerator implements IMapActionGenerator {
  key = 'no-action'
  priority = Priority.NoAction

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
  }: IMapActionGeneratorParams): IMapAction[] {
    // If there are no features
    if (!features || features.length === 0) {
      return [
        {
          key: this.key,
          priority: this.priority,
          execute: () => {
            dispatch(setFocusedPoint(null))
            dispatch(setFocusedPolygon(null))
            dispatch(setFocusedBlockId(undefined))
          },
        },
      ]
    }
    return []
  }
}
