import { client, gql } from '../../../../graphql/client'
import { Heap } from '../../../../util/heap'
import { indexArray } from '../../../../util/indexArray'

export interface GetSourceAndDestinationParcelsResult {
  sourceParcels: DeliveryParcel[]
  destinationParcels: DeliveryParcel[]
  delivery: {
    organization: {
      groups: {
        name: string
        id: number
      }[]
    }
  }
}

export interface DeliveryParcel {
  parcel: {
    id: number
    name: string
    group: {
      id: number
      name: string
    }
  }
}

export const fetchSourceAndDestinationParcels = async (
  [sourceDeliveryId, destinationDeliveryId]: [string?, string?],
  skip: () => void
) => {
  if (sourceDeliveryId === undefined || destinationDeliveryId === undefined) {
    return skip()
  }

  const {
    sourceParcels,
    destinationParcels,
    delivery: {
      organization: { groups },
    },
  } = await client.request<GetSourceAndDestinationParcelsResult>({
    query: gql`
      query GET_SOURCE_AND_DESTINATION_DELIVERY_PARCELS(
        $sourceDeliveryId: uuid!
        $destinationDeliveryId: uuid!
      ) {
        delivery: Delivery_by_pk(id: $sourceDeliveryId) {
          organization: Organization {
            groups: OrganizationGroups {
              id
              name
            }
          }
        }
        sourceParcels: DeliveryParcel(
          where: { deliveryId: { _eq: $sourceDeliveryId } }
          order_by: [
            { Parcel: { Group: { name: asc } } }
            { Parcel: { name: asc } }
          ]
        ) {
          parcel: Parcel {
            id
            name
            group: Group {
              id
              name
            }
          }
        }
        destinationParcels: DeliveryParcel(
          where: { deliveryId: { _eq: $destinationDeliveryId } }
          order_by: [
            { Parcel: { Group: { name: asc } } }
            { Parcel: { name: asc } }
          ]
        ) {
          parcel: Parcel {
            id
            name
            group: Group {
              id
              name
            }
          }
        }
      }
    `,
    variables: { sourceDeliveryId, destinationDeliveryId },
  })

  const sourceParcelsByGroup = sourceParcels.reduce((mapped, sourceParcel) => {
    mapped[sourceParcel.parcel.group.id] = {
      ...(mapped[sourceParcel.parcel.group.id] ?? {}),
      [sourceParcel.parcel.id]: sourceParcel,
    }

    return mapped
  }, {} as Record<number, Record<number, DeliveryParcel>>)

  return {
    groups: indexArray(groups, 'id'),
    orderedGroupsIds: groups.map(({ id }) => id),
    orderedParcelIdsByGroup: Object.entries(sourceParcelsByGroup).reduce(
      (mapped, [groupId, groupParcels]) => {
        const heap = new Heap<DeliveryParcel>(
          (a, b) => a.parcel.name.localeCompare(b.parcel.name) < 0
        )

        for (const parcel of Object.values(groupParcels)) {
          heap.push(parcel)
        }

        mapped[groupId] = heap.getArray().map(({ parcel }) => parcel.id)

        return mapped
      },
      {} as Record<number, number[]>
    ),
    sourceParcelsByGroup,
    destinationParcelsByName: destinationParcels.reduce(
      (mapped, deliveryParcel) => {
        mapped[deliveryParcel.parcel.group.name] = {
          ...(mapped[deliveryParcel.parcel.group.name] ?? {}),
          [deliveryParcel.parcel.name]: deliveryParcel,
        }

        return mapped
      },
      {} as Record<string, Record<string, DeliveryParcel>>
    ),
  }
}
