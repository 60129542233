import * as api from '../../graphql/api'
import { createTableActionIconButton } from '../../UI/Table/createTableActionIconButton'
import { TableAction } from '../../UI/Table/types'
import { asyncForEach } from '../../util/syncArrayHelpers'
import { ListPipeline } from './types'

const pause = (refresh: () => void): TableAction<ListPipeline> => ({
  selectionRequired: true,
  button: createTableActionIconButton({
    title: 'Pause',
    icon: 'pause',
    key: 'pause-pipeline',
  }),
  visible: (selection) => selection.every((p) => !p.isPaused),
  action: async (selection) => {
    await asyncForEach(selection, { limit: 2 }, async ({ id }) => {
      await api.pipeline.update({
        pk: { id },
        input: { isPaused: true },
        returning: `{ isPaused }`,
      })
    })
    refresh()
  },
})

const resume = (refresh: () => void): TableAction<ListPipeline> => ({
  selectionRequired: true,
  button: createTableActionIconButton({
    title: 'Resume',
    icon: 'play_arrow',
    key: 'resume-pipeline',
  }),
  visible: (selection) => selection.every((p) => p.isPaused),
  action: async (selection) => {
    await asyncForEach(selection, { limit: 2 }, async ({ id }) => {
      await api.pipeline.update({
        pk: { id },
        input: { isPaused: false },
        returning: `{ isPaused }`,
      })
    })
    refresh()
  },
})

const view = (
  viewHandler: (pipeline: ListPipeline) => void
): TableAction<ListPipeline> => ({
  selectionRequired: true,
  multi: false,
  button: createTableActionIconButton({
    title: 'View',
    icon: 'arrow_forward',
    key: 'view-pipeline',
  }),
  action: ([pipeline]) => {
    viewHandler(pipeline)
  },
})

export const actions = {
  pause,
  resume,
  view,
}
