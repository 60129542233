import * as Sentry from '@sentry/browser'
import * as React from 'react'
import getMapImage from '../../../reports/page/map/getMapImage'
import MapLoader from '../../../reports/page/map/MapLoader'
import handleError from '../../../util/handleError'
import { makeProductSourcesAndLayers } from '../../../postgis/selectors/SelectedProductLayerSelector'

export interface Props {
  style: string
  bounds?: number[][]
  center?: [number, number]
  layers?: ReturnType<typeof makeProductSourcesAndLayers>
  constainerStyle?: React.CSSProperties
  onStyleLoaded?: (map: mapboxgl.Map) => Promise<void | void[]>
}

export const SamplePlanMapImage = (props: Props) => {
  const mapContainerRef = React.useRef<HTMLDivElement>(null)
  const [src, setSrc] = React.useState<string>('')
  let [fetched, setFetched] = React.useState<boolean>(false)

  React.useEffect(() => {
    update()
  }, [mapContainerRef])

  React.useEffect(() => {
    update()
    return () => {
      getMapImage.empty()
    }
  }, [])

  const update = async () => {
    if (fetched || !mapContainerRef.current) {
      return
    }
    setFetched(true)

    try {
      const src = await getMapImage({
        ...props,
        container: mapContainerRef.current,
      })

      if (src) {
        setSrc(src)
      }
    } catch (e) {
      const isAborted = /aborted/i.test((e as Error).message)
      if (!isAborted) {
        // ignore aborted errors
        Sentry.addBreadcrumb({
          category: 'map-data',
          message: 'Map Image failed to load',
          level: Sentry.Severity.Error,
        })
        handleError(e)
      }
    }
  }

  return (
    <div
      ref={mapContainerRef}
      className="ReportProductPage__Map"
      style={props.constainerStyle}
    >
      {!src ? <MapLoader /> : <img src={src} alt="" />}
    </div>
  )
}
