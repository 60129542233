import { client, gql } from '../../../../graphql/client'

export interface OrganizationUser {
  id: number
  email: string
  firstName: string
  lastName: string
}

interface OrganizationUsers {
  organizationUsers: {
    user: OrganizationUser
  }[]
}

export const fetchOrganizationUsers = async (organizationId: string) => {
  const { organizationUsers } = await client.request<OrganizationUsers>({
    query: gql`
      query FETCH_ORGANIZATION_USERS($organizationId: Int!) {
        organizationUsers: OrganizationUser(
          where: { organizationId: { _eq: $organizationId } }
        ) {
          user: User {
            id
            email
            firstName
            lastName
          }
        }
      }
    `,
    variables: { organizationId },
  })

  return organizationUsers?.map(({ user }) => user)
}
