import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import sweetalert from 'sweetalert'

import { Button, LinearProgress } from '@mui/material'
import { default as Typography } from '@mui/material/Typography'

import errorAlert from '../../../admin/errorAlert'
import { Column } from '../../../admin/UI/Column'
import { Row } from '../../../admin/UI/Row'
import { RouteParams, url, urls } from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../../graphql/api'
import i18n from '../../../i18n'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import FileDropTarget from '../../../UI/FileDropTarget/FileDropTarget'
import { selectGetDelivery } from '../Delivery/selectGetDelivery'
import { selectListDeliveryGroupForDelivery } from '../Delivery/selectListDeliveryGroup'
import { selectListDeliveryParcelForDelivery } from '../Delivery/selectListDeliveryParcel'
import FlightForm from './FlightForm'
import { FlightUploadFiles } from './FlightUploadFiles'
import { ParcelProperties, PreparedFile, prepareUpload } from './prepareUpload'
import {
  selectGetFlight,
  refreshGetFlight,
  FlightData,
} from './selectGetFlight'
import { selectFlightDeliveryParcelFilesByKey } from './selectListDeliveryParcelFileStatusFlight'
import {
  ParcelJSONData,
  uploadFlightFiles,
  UploadProgress,
  VV_PARCEL_GEOJSON,
} from './uploadFlightFiles'
import UploadFlightFilesProgressDialog from './UploadFlightFilesProgressDialog'
import dateToYMD from '../../../admin/mapdata/dateToYMD'
import OrderPage from '../Order/OrderPage'

interface State {
  vvParcelGeojsonByDirectory: Record<string, ParcelJSONData | undefined>
  filesToUpload: Record<string, Record<string, PreparedFile[]>> // map of group ids to map of parcel ids to prepared files
  parcelsById: Record<string, ParcelProperties> // map of parcel ids to ParcelProperties
  filesToUploadSelection: PreparedFile[]
  uploadFlightFilesProgress?: UploadProgress
  filePrepProgress: number | undefined
  filePrepState: 'unstarted' | 'discovering' | 'preparing' | 'done'
  filePrepEstimate: number | 'calculating'
}

class EditMapDataFlight extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps<RouteParams>,
  State
> {
  state: State = {
    vvParcelGeojsonByDirectory: {},
    filesToUpload: {},
    parcelsById: {},
    filesToUploadSelection: [],
    filePrepProgress: undefined,
    filePrepState: 'unstarted',
    filePrepEstimate: 'calculating',
  }

  render() {
    const { filePrepProgress, filePrepState } = this.state
    const { deliveryId, flightDate } = this.props.match.params
    const { deliveryParcels, deliveryGroups } = this.props
    if (!deliveryId || !flightDate) {
      return null
    }

    return (
      <OrderPage
        title={`Edit Flight`}
        backTo={this.getBackUrl}
        backToTitle="Delivery"
      >
        <div className="MapDataContainerSmall">
          <AsyncSelectorStatusOverlay requests={this.props.flight}>
            <FlightForm
              type="edit"
              saveMode="all"
              data={this.props.flight.data}
              onSave={this.handleUpdate}
              onDelete={this.handleDelete}
            />
            <div className="Paper">
              <Row
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <Typography variant="subtitle1" paragraph>
                  Upload Files to this Flight
                </Typography>
                <Column style={{ alignItems: 'flex-end' }}>
                  {deliveryParcels.data?.data && (
                    <Typography>{`Total/upload parcels: ${
                      deliveryParcels.data.data.length
                    }/${
                      Object.keys(this.state.parcelsById).length
                    }`}</Typography>
                  )}
                  {deliveryGroups.data?.data && (
                    <Typography>{`Total/upload groups: ${
                      deliveryGroups.data.data.length
                    }/${
                      Object.keys(this.state.filesToUpload).length
                    }`}</Typography>
                  )}

                  {this.state.filesToUploadSelection && (
                    <Typography style={{ textAlign: 'end' }}>
                      Total upload size:{' '}
                      {this.getFilesToUploadSelectionSize().toLocaleString()}{' '}
                      bytes
                    </Typography>
                  )}
                  <Button
                    style={{ marginTop: 4, float: 'right' }}
                    disabled={
                      Object.keys(this.state.filesToUploadSelection).length ===
                      0
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (!deliveryId) {
                        throw new Error('No deliveryId')
                      }
                      if (!flightDate) {
                        throw new Error('No flightDate')
                      }

                      uploadFlightFiles(
                        flightDate,
                        deliveryId,
                        this.state.filesToUploadSelection,
                        (uploadFlightFilesProgress) =>
                          this.setState({ uploadFlightFilesProgress })
                      )
                    }}
                  >
                    Upload Files
                  </Button>
                </Column>
              </Row>

              {filePrepState !== 'unstarted' && (
                <>
                  <Typography variant="caption">
                    {this.getFilePrepLabel()}
                  </Typography>
                  <LinearProgress
                    value={filePrepProgress ?? 0}
                    variant={
                      filePrepState === 'discovering'
                        ? 'indeterminate'
                        : 'determinate'
                    }
                  />
                </>
              )}
              <FileDropTarget
                onFilesDropped={this.handleFilesDropped}
                onFilesSelected={this.handleFilesSelected}
                extensions={[VV_PARCEL_GEOJSON, '.shp.zip', '.tif']}
              >
                <FlightUploadFiles
                  filesByGroups={this.state.filesToUpload}
                  parcels={this.state.parcelsById}
                  selectedFilesChanged={this.handleUploadFileSelectionChanged}
                />
              </FileDropTarget>
            </div>
          </AsyncSelectorStatusOverlay>
        </div>
        <UploadFlightFilesProgressDialog
          flightFilesProgress={this.state.uploadFlightFilesProgress}
          onDone={() => this.reset()}
        />
      </OrderPage>
    )
  }

  handleUploadFileSelectionChanged = (files: PreparedFile[]) => {
    this.setState({
      filesToUploadSelection: files,
    })
  }

  handleUpdate = async ({ date, comment }: FlightData) => {
    try {
      await api.flight.update({
        pk: {
          deliveryId: this.props.match.params.deliveryId!,
          date: this.props.match.params.flightDate!,
        },
        input: {
          date: date ? dateToYMD(date) : undefined,
          comment,
        },
      })

      refreshGetFlight()
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      errorAlert({
        message,
        error: e,
        title: 'Failed to Edit Flight',
        extras: this.state,
      })
    }
  }

  handleDelete = async () => {
    const choice = await sweetalert(`Delete this flight?`, {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Delete',
        },
      },
      dangerMode: true,
    })

    // sweetalert returns null for "cancel"
    if (!choice) {
      return
    }

    await api.flight.delete({
      pk: {
        deliveryId: this.props.match.params.deliveryId!,
        date: this.props.match.params.flightDate!,
      },
    })

    const backUrl = this.getBackUrl()
    if (backUrl) {
      this.props.history.push(backUrl)
    }
  }

  getFilePrepLabel = () => {
    const { filePrepState, filePrepEstimate } = this.state
    if (filePrepState === 'unstarted') {
      return ''
    }
    if (filePrepState === 'done') {
      return 'File preparation complete'
    }

    const estimateMessage =
      filePrepEstimate === 'calculating'
        ? 'Calculating'
        : i18n.msToTime(filePrepEstimate)

    return `${
      filePrepState.charAt(0).toUpperCase() + filePrepState.slice(1)
    } files. Estimated time remaining: ${estimateMessage}`
  }

  getFilesToUploadSelectionSize = () =>
    this.state.filesToUploadSelection.reduce((acc, curr) => {
      return acc + curr.file.size
    }, 0)

  getBackUrl = () => {
    const deliveryId = this.props.flight.data?.deliveryId

    if (deliveryId) {
      return url(urls.editDelivery, { deliveryId })
    }

    return undefined
  }

  reset = () => {
    this.setState({
      vvParcelGeojsonByDirectory: {},
      uploadFlightFilesProgress: undefined,
    })
  }

  handleFilesDropped = () => {
    this.setState({ filePrepState: 'discovering' })
  }

  handleFilesSelected = async (files: File[]) => {
    this.setState({
      filePrepProgress: 0,
      filePrepState: 'preparing',
    })
    const { deliveryParcelFilesByKey } = this.props
    const flightData = this.props.flight.data
    if (!flightData) {
      sweetalert(
        `Error: cannot determine organization from QueriedFlight - reload the page and try again`
      )

      return
    }
    const flightOrgId = flightData.organizationId
    const flightDeliveryId = flightData.deliveryId
    if (!flightOrgId || !flightDeliveryId) {
      sweetalert(
        `Error: cannot determine organization from QueriedFlight - reload the page and try again`
      )

      return
    }

    const { filesToUpload, parcelsById } = await prepareUpload(
      files,
      flightData,
      deliveryParcelFilesByKey,
      (progress, estimate) => {
        this.setState({
          filePrepProgress: progress,
          filePrepEstimate: estimate,
        })
      }
    )

    this.setState({
      filesToUpload,
      parcelsById,
      filesToUploadSelection: [],
      filePrepProgress: 100,
      filePrepState: 'done',
    })
  }
}

const mapState = (state: RootStore) => ({
  flight: selectGetFlight(state),
  delivery: selectGetDelivery(state),
  deliveryParcels: selectListDeliveryParcelForDelivery(state),
  deliveryGroups: selectListDeliveryGroupForDelivery(state),
  deliveryParcelFilesByKey: selectFlightDeliveryParcelFilesByKey(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(EditMapDataFlight)
)
