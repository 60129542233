import { client, gql } from '../../../graphql/client';

export const deleteComment = async (commentId: string) => {
  await client.request({
    query: gql`
      mutation DELETE_COMMENT($commentId:uuid!) {
        delete_Comment_by_pk(id:$commentId) {
          id
        }
      }`,
    variables: { commentId }
  });
}

