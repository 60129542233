import './ReportLegend.scss'

import * as React from 'react'

import { MapColorProfileData, MapLayerDefData } from '../../data/types'
import { Languages } from '../../graphql/types'
import { ProductStats } from '../../ProductStats/calculateStats/productStats'
import { toStopData } from '../../ProductStats/toStopData'
import { Legend } from '../../stats/UI/Legend'
import { EitherUnit } from '../../util/units/LayerUnits'
import { UnitSystem } from '../../util/units/unitSystem'

interface Props {
  product: MapLayerDefData
  activeColor: MapColorProfileData | undefined
  preferredLanguage: Languages
  productStats?: ProductStats[string]
  unit: EitherUnit
  preferredUnitSystem: UnitSystem
}

class ReportLegend extends React.PureComponent<Props> {
  render() {
    const {
      product,
      activeColor,
      preferredLanguage,
      productStats,
      unit,
      preferredUnitSystem,
    } = this.props
    const displayProperty = product.dataProperty

    if (!activeColor || !displayProperty) {
      return <div />
    }

    const { stops, minLabel, maxLabel } = toStopData({
      unit,
      preferredUnitSystem,
      colorProfile: activeColor as any,
      layerDef: product,
      lang: preferredLanguage,
      stats: productStats,
    })

    return (
      <React.Fragment>
        <hr />
        <div className="ReportLegend">
          <Legend
            layerDef={product}
            stops={stops}
            minLabel={minLabel}
            maxLabel={maxLabel}
            orientation="vertical"
          />
        </div>
        <hr />
      </React.Fragment>
    )
  }
}

export default ReportLegend
