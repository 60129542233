import { createSelector } from 'reselect'
import { RootStore } from '../../redux/types'

const getSelectedPolygon = (state: RootStore) => state.postgis.focusedPolygon

const FOCUSED_POLYGON_COLOR = '#889385'

export const selectSelectedPolygonLayer = createSelector(
  getSelectedPolygon,
  (selectedPolygon) => {
    if (!selectedPolygon) {
      return {
        sources: {},
        layers: [],
      }
    }

    return {
      sources: {
        FOCUSED_POLYGON: {
          data: selectedPolygon.geometry,
          type: 'geojson',
          attribution: '&copy; VineView',
        },
      },
      layers: [
        {
          paint: {
            'line-width': 2.5,
            'line-color': FOCUSED_POLYGON_COLOR,
            'line-opacity': 0.8,
          },
          type: 'line',
          id: 'FOCUSED_POLYGON_OUTLINE',
          source: 'FOCUSED_POLYGON',
        },
        {
          paint: {
            'fill-pattern': 'pattern',
            'fill-antialias': false,
          },
          type: 'fill',
          id: 'FOCUSED_POLYGON_FILL',
          source: 'FOCUSED_POLYGON',
        },
      ],
    }
  }
)
