import * as React from 'react'
import { url, urls } from '../../../appNavigation/urls'
import { AdminRole } from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import { useHistory, useParams } from '../../../hooks/useRouter'
import { AdminPage } from '../AdminPage'
import AdminForm from './AdminForm'
import { fetchAdminRoleByPK } from './fetchAdminRoleByPK'
import { updateAdminRoleByPK } from './updateAdminRoleByPK'

export function AdminEdit() {
  const history = useHistory()
  const { userId, role } = useParams()

  const [result, refresh] = useAsync(fetchAdminRoleByPK, [userId, role])

  const variable = result.result ? result.result.data : undefined

  if (userId == null || role == null) {
    return null
  }

  const handleSave = async (org: AdminRole) => {
    const updated = await updateAdminRoleByPK(userId, role, org)
    const newRole = updated && updated.data && updated.data.role

    if (newRole != null) {
      if (role !== newRole) {
        history.replace(url(urls.admin_admins_edit, { userId, role: newRole }))

        return true
      }

      refresh()

      return true
    }

    return false
  }

  return (
    <AdminPage title="Edit Admin" subtitle={role}>
      <AdminForm
        type="edit"
        data={variable}
        saveMode="all"
        onSave={handleSave}
      />
    </AdminPage>
  )
}
