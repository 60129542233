import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { default as swal } from 'sweetalert'

import errorAlert from '../../../admin/errorAlert'
import { RouteParams, url, urls } from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../../graphql/api'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { selectGetDelivery } from '../Delivery/selectGetDelivery'
import FlightForm from './FlightForm'
import { FlightData } from './selectGetFlight'
import dateToYMD from '../../../admin/mapdata/dateToYMD'
import OrderPage from '../Order/OrderPage'

class NewFlight extends React.PureComponent<
  RouteComponentProps<RouteParams> & ReduxProps
> {
  render() {
    return (
      <OrderPage
        title={`New Flight`}
        backTo={this.getBackUrl}
        backToTitle="Delivery"
      >
        <AsyncSelectorStatusOverlay requests={this.props.delivery}>
          <div id="NewFlight" className="MapDataContainerSmall">
            <AsyncSelectorStatusOverlay requests={this.props.delivery}>
              <FlightForm
                type="new"
                onCancel={this.handleCancel}
                onSave={this.handleCreate}
              />
            </AsyncSelectorStatusOverlay>
          </div>
        </AsyncSelectorStatusOverlay>
      </OrderPage>
    )
  }

  handleCreate = async ({ date, comment }: FlightData) => {
    if (!date || !this.props.delivery.data) {
      swal({
        text: 'Please fill out all required fields',
        icon: 'info',
      })

      return
    }

    const { id: deliveryId, organizationId } = this.props.delivery.data

    try {
      const req = await api.flight.create<{ deliveryId: string; date: string }>(
        {
          input: {
            date: dateToYMD(date),
            comment,
            deliveryId,
            organizationId,
          },
          returning: `{
            deliveryId
            date
          }`,
        }
      )

      this.props.history.replace(
        url(urls.editFlight, {
          deliveryId: req.data!.deliveryId,
          flightDate: req.data!.date,
        })
      )
    } catch (e) {
      softError(e, 'Failed to Create Flight', e.message, this.state)
    }
  }

  getBackUrl = () => url(urls.editDelivery, this.props.match.params)

  handleCancel = () => {
    this.props.history.push(this.getBackUrl())
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
      file: 'NewFlight.tsx',
    },
  })

const mapState = (state: RootStore) => ({
  delivery: selectGetDelivery(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(NewFlight)
)
