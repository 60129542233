import { client, gql } from '../../../../graphql/client'
import { DeliveryProcGroupPackages } from './AddPackageToProcGroupDialog'

export const addPackagesToProcGroup = async (
  deliveryProcGroupPackage: DeliveryProcGroupPackages
) => {
  const packages = deliveryProcGroupPackage.packages.map((p) => ({
    deliveryId: deliveryProcGroupPackage.deliveryId,
    organizationId: deliveryProcGroupPackage.organizationId,
    flightDate: deliveryProcGroupPackage.flightDate,
    procGroup: deliveryProcGroupPackage.procGroup,
    package: p,
  }))

  const nasLocation = deliveryProcGroupPackage.nasLocation
  const nasAddress = 'VVQNAPHalifax/final_products'
  const nasUrl = `${nasAddress}/${nasLocation}`

  await client.request({
    query: gql`
      mutation ADD_DELIVERY_PROCGROUP_PACKAGES(
        $deliveryId: uuid!
        $procGroup: String!
        $flightDate: date!
        $organizationId: Int!
        $nasAddress: String!
        $nasLocation: String!
        $nasUrl: String!
        $packages: [Delivery_ProcGroup_Package_insert_input!]!
      ) {
        insert_Flight_one(
          object: {
            deliveryId: $deliveryId
            date: $flightDate
            organizationId: $organizationId
          }
          on_conflict: { constraint: Flight_pkey, update_columns: [date] }
        ) {
          deliveryId
          date
        }
        insert_File_one(
          object: {
            path: $nasLocation
            url: $nasUrl
            StorageAdapter: {
              data: { type: "nas", address: $nasAddress }
              on_conflict: {
                constraint: StorageAdapter_pkey
                update_columns: [type, address]
              }
            }
          }
          on_conflict: { constraint: File_pkey, update_columns: [url] }
        ) {
          url
        }
        update_Delivery_ProcGroup_by_pk(
          pk_columns: { deliveryId: $deliveryId, procGroup: $procGroup }
          _set: { location: $nasUrl, flightDate: $flightDate }
        ) {
          deliveryId
          procGroup
          organizationId
        }
        insert_Delivery_ProcGroup_Package(objects: $packages) {
          affected_rows
        }
      }
    `,
    variables: {
      flightDate: deliveryProcGroupPackage.flightDate,
      deliveryId: deliveryProcGroupPackage.deliveryId,
      procGroup: deliveryProcGroupPackage.procGroup,
      organizationId: deliveryProcGroupPackage.organizationId,
      nasUrl,
      nasLocation,
      nasAddress,
      packages,
    },
  })
}
