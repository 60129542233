import { getImpersonationToken } from '../util/getImpersonationToken'
import { setAuthToken } from './apiResource/createApiResource'
import { Token } from './models'

const TOKEN_KEY = 'D4A8A930-CFD6-48D9-83ED-B0256889B24B'

export function persistToken(token: Token) {
  const tokenString = token.token

  // Don't save the token if the user is impersonating someone.
  if (getImpersonationToken() === null) {
    localStorage.setItem(TOKEN_KEY, tokenString)
  }

  setAuthToken(tokenString)
}

export function persistedToken() {
  const token = getImpersonationToken() ?? localStorage.getItem(TOKEN_KEY)

  if (token === null) {
    return
  }

  setAuthToken(token)

  return token
}

export function deletePersistedToken() {
  const exists = localStorage.getItem(TOKEN_KEY) ?? false

  localStorage.removeItem(TOKEN_KEY)

  return exists
}
