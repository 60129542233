import { client, gql } from '../../../graphql/client'

export const toggleWatchDeliveryProcGroup = async (
  deliveryId: string,
  procGroup: string,
  userId: string,
  watch: boolean
) => {
  if (watch) {
    await client.request({
      query: gql`
        mutation WATCH_DELIVERY_PROCGROUP(
          $deliveryId: uuid!
          $procGroup: String!
          $userId: Int!
        ) {
          insert_Delivery_ProcGroup_Watcher_one(
            object: {
              deliveryId: $deliveryId
              procGroup: $procGroup
              userId: $userId
            }
          ) {
            deliveryId
            procGroup
            userId
          }
        }
      `,
      variables: { deliveryId, userId, procGroup },
    })
  } else {
    await client.request({
      query: gql`
        mutation UNWATCH_WATCH_DELIVERY_PROCGROUP(
          $deliveryId: uuid!
          $procGroup: String!
          $userId: Int!
        ) {
          delete_Delivery_ProcGroup_Watcher_by_pk(
            deliveryId: $deliveryId
            procGroup: $procGroup
            userId: $userId
          ) {
            userId
            deliveryId
            procGroup
          }
        }
      `,
      variables: { deliveryId, userId, procGroup },
    })
  }
}
