import * as React from 'react'
import { AnyAction, Dispatch } from 'redux'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'

// tslint:disable-next-line: variable-name
export const ReduxContext = React.createContext<
  [RootStore, Dispatch<AnyAction>]
>([] as any)

export const useRedux = () => React.useContext(ReduxContext)

const mapState = (state: RootStore) => {
  return {
    state,
  }
}

type ReduxProps = ReturnType<typeof mapState>

const Provider = ({
  state,
  dispatch,
  children,
  stateOverrides,
}: React.PropsWithChildren<
  ReduxProps & AppDispatchProps & { stateOverrides?: Partial<RootStore> }
>) => {
  const blendedState = React.useMemo(() => {
    if (stateOverrides) {
      return { ...state, ...stateOverrides }
    }

    return state
  }, [state, stateOverrides])

  return (
    <ReduxContext.Provider
      value={[blendedState, dispatch]}
      children={children}
    />
  )
}

// tslint:disable-next-line: variable-name
export const ReduxContextProvider = connect<
  ReduxProps,
  { stateOverrides?: Partial<RootStore> },
  AppDispatchProps
>(mapState)(Provider)
