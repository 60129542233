import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { gql, query } from '../../../graphql/client'

export interface MigrationStatus {
  migrated: number
  unmigrated: number
  unmigratable: number
}

const GET_MIGRATION_STATUS = gql`
  query GET_MIGRATION_STATUS {
    Migrated: ParcelDataSet_aggregate(
      where: {
        DeliveryParcel: { migratedByVersion: { _is_null: false } }
        Parcel: { Organization: { betaEnabled: { _eq: true } } }
        deletedAt: { _is_null: true }
      }
    ) {
      aggregate {
        count
      }
    }
    Unmigrated: ParcelDataSet_aggregate(
      where: {
        deletedAt: { _is_null: true }
        Parcel: { Organization: { betaEnabled: { _eq: true } } }
        _or: [
          { _not: { DeliveryParcel: {} } }
          { DeliveryParcel: { migratedByVersion: { _is_null: true } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const { selector: selectMigrationStatus, refresh: refreshMigrationStatus } =
  createAsyncSelector({
    resource: 'GetMigrationStatus',
    inputs: {},
    fetcher: async () => {
      const {
        Migrated: {
          aggregate: { count: migrated },
        },
        Unmigrated: {
          aggregate: { count: unmigrated },
        },
      } = await query<{
        Migrated: { aggregate: { count: number } }
        Unmigrated: { aggregate: { count: number } }
      }>({ query: GET_MIGRATION_STATUS })

      return {
        'Migrated ParcelDataSets': migrated,
        'Unmigrated ParcelDataSets': unmigrated,
      }
    },
  })

export { selectMigrationStatus, refreshMigrationStatus }
