import * as React from 'react'
import {
  DEFAULT_PAGE_INFO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../../appNavigation/urls'
import useAsync from '../../../hooks/useAsync'
import { useHistory, useSearchParams } from '../../../hooks/useRouter'
import { createTableActionIconButton } from '../../../UI/Table/createTableActionIconButton'
import { ORDER } from '../../../UI/Table/orderRows'
import { Table } from '../../../UI/Table/Table'
import {
  PaginationChange,
  TableAction,
  TableOrder,
} from '../../../UI/Table/types'
import { AdminPage } from '../AdminPage'
import { FetchedOrganizations, fetchOrganizations } from './fetchOrganizations'
import { tableFormatter } from './organizationTableFormatters'

export function Organizations() {
  const {
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    order = '',
    filter = '',
  } = useSearchParams()
  const history = useHistory()

  const [result, refresh] = useAsync(fetchOrganizations, [
    Number(page),
    Number(pageSize),
    order,
    filter,
  ])

  const updateSearchParams = (update: {
    page?: number
    pageSize?: number
    order?: string
    filter?: string
  }) => {
    history.push(
      url(
        urls.admin_organizations,
        {},
        {
          page: Number(page),
          pageSize: Number(pageSize),
          order,
          filter,
          ...update,
        }
      )
    )
  }

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateSearchParams(pagination)

  // tslint:disable-next-line: no-shadowed-variable
  const handleOrderChange = (order: TableOrder) => {
    updateSearchParams({ order: ORDER.serialize(order) })
  }

  const rows = result.result ? result.result.data : []
  const info = result.result
    ? result.result.info
    : { ...DEFAULT_PAGE_INFO, order: [] }

  const { newBtn, editBtn, refreshBtn } = tableActions(history, refresh)

  return (
    <AdminPage title="Organizations">
      <div className="Paper">
        <Table
          title="Organizations"
          rows={rows}
          actions={[newBtn, editBtn, refreshBtn]}
          formatter={tableFormatter}
          order={info.order}
          onOrderChange={handleOrderChange}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pagination={info}
          onPaginationChange={handlePaginationChange}
        />
      </div>
    </AdminPage>
  )
}

function tableActions(
  history: ReturnType<typeof useHistory>,
  refresh: () => void
) {
  const refreshBtn: TableAction<FetchedOrganizations> = {
    button: createTableActionIconButton({
      title: 'Refresh',
      icon: 'autorenew',
      key: 'refresh-organizations',
    }),
    action: refresh,
  }

  const newBtn: TableAction<FetchedOrganizations> = {
    button: createTableActionIconButton({
      title: 'New Organization',
      icon: 'add',
      key: 'new-organization',
    }),
    action: () => history.push(url(urls.admin_organizations_new)),
  }

  const editBtn: TableAction<FetchedOrganizations> = {
    selectionRequired: true,
    button: createTableActionIconButton({
      title: 'Edit Organization',
      icon: 'edit',
      key: 'edit-organization',
    }),
    action: ([{ id }]) =>
      history.push(url(urls.admin_organizations_edit, { organizationId: id })),
  }

  return { newBtn, editBtn, refreshBtn }
}
