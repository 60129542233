import * as api from '../../../graphql/api'

export const deleteScheduledNotificationById = async (id: string) => {
  const choice = await sweetAlert(
    `Are you sure you want to delete this notification?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Delete',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (!choice) {
    return
  }

  return await api.scheduledNotifications.delete<{ id: string }>({
    pk: { id }
  })
}
