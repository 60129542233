export const doUpload = (
  url: string,
  file: File,
  reportProgress: (progress: number) => void,
  contentType = 'application/octet-stream'
) =>
  new Promise<void>((resolve, reject) => {
    const req = new XMLHttpRequest()

    console.log(`Uploading with content type: ${contentType}`)

    req.open('PUT', url)
    req.setRequestHeader('Content-Type', contentType)
    req.upload.onprogress = (ev: ProgressEvent) => {
      reportProgress((ev.loaded / file.size) * 100)
    }
    req.upload.onerror = () => {
      const { status, statusText } = req

      reject(new Error(`Upload failed with status: ${status} -- ${statusText}`))
    }

    req.upload.onload = () => {
      setTimeout(() => {
        resolve()
      }, 500)
    }

    req.send(file)
  })
