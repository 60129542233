import { ScheduledNotification } from '../../../graphql/types'
import { TableFormatter } from '../../../UI/Table/types'

const idColumn: TableFormatter<ScheduledNotification> = {
  header: () => 'Id',
  data: ({ id }) => id,
  orderBy: (direction) => `
    {
      id: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    id: { [`_${op}`]: value },
  }),
}
const nameColumn: TableFormatter<ScheduledNotification> = {
  header: () => 'Name',
  data: ({ name }) => name,
  orderBy: (direction) => `
    {
      name: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    name: { [`_${op}`]: value },
  }),
}
const startDateColumn: TableFormatter<ScheduledNotification> = {
  header: () => 'Send Date',
  data: ({ sendDate }) => sendDate,
  orderBy: (direction) => `
    {
      sendDate: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    sendDate: { [`_${op}`]: value },
  }),
}
const expireDateColumn: TableFormatter<ScheduledNotification> = {
  header: () => 'Expire Date',
  data: ({ expireDate }) => expireDate,
  orderBy: (direction) => `
  {
    expireDate: ${direction}
  }
`,
filterBy: (op, value) => ({
  expireDate: { [`_${op}`]: value },
}),
}
const typeColumn: TableFormatter<ScheduledNotification> = {
  header: () => 'Type',
  data: ({ type }) => type,
  orderBy: (direction) => `
    {
      type: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    type: { [`_${op}`]: value },
  }),
}
const sentColumn: TableFormatter<ScheduledNotification> = {
  header: () => 'Sent',
  data: ({ sent }) => sent ? 'Yes' : 'No',
  orderBy: (direction) => `
    {
      sent: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    sent: { [`_${op}`]: value },
  }),
}
export const tableFormatter = [idColumn, nameColumn, typeColumn, startDateColumn, expireDateColumn, sentColumn]
