import { GroupData } from '../data/types'
import { FluxAction } from '../util/commonRedux'
import { GroupDownloadsState } from './types'

// Actions
const OPEN_DOWNLOADS_DIALOG = `reports/OPEN_DOWNLOADS_DIALOG`
const RESET_DOWNLOADS_DIALOG = `reports/RESET_DOWNLOADS_DIALOG`

// Action Creators
export const openDownloadsDialog = (group: GroupData) => ({
  type: OPEN_DOWNLOADS_DIALOG,
  payload: group,
})
export const resetDownloadsDialog = () => ({
  type: RESET_DOWNLOADS_DIALOG,
})

export const initialState: GroupDownloadsState = {
  isGroupDownloadsDialogOpen: false,
}

export const reducer = (
  state = initialState,
  action: FluxAction
): GroupDownloadsState => {
  switch (action.type) {
    case OPEN_DOWNLOADS_DIALOG: {
      const group = action.payload as GroupData

      return {
        ...state,
        isGroupDownloadsDialogOpen: true,
        selectedGroup: group,
      }
    }

    case RESET_DOWNLOADS_DIALOG: {
      return { ...initialState }
    }

    default:
      return state
  }
}
