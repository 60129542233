import * as mapboxgl from 'mapbox-gl'
import { MapSource, SamplePlanBlock } from '../../../../graphql/types'
import { colorProfileToPaint } from '../../../../map/paint/colorProfileToPaint'
import { SAMPLE_PLAN_BLOCK_UNSELECTED } from './parcelLayerHelper'
import resample from '../../../../colorProfile/transformations/resample'
import stretch from '../../../../colorProfile/transformations/stretch'
import { addTileUrl } from '../../../../map/layers'
import * as bbox from '../../../../util/bbox'
import { indexArrayMappedKey } from '../../../../util/indexArray'
import { Feature, LineString } from '@turf/helpers'
import { calc } from '../../../../util/calc'

export const SAMPLE_PLAN_GENERATED_FEATURE = 'SAMPLE_PLAN_GENERATED_FEATURE'
const MIN_MAX = 'minMax'
const ZONES_PER_BLOCK = 'zones-per-block'
const PROPERTY = 'EVImean'
const AREA_WEIGHTED = 'c5e3288b-32d2-46b4-9f1c-f38018354044'
const ZONAL_VARIATION = 'f526ee41-c14f-4188-a693-bd3106fd6e04'
const REPRESENTATIVE_ROW = 'e918b8e5-6dd7-474f-8014-668b8e6a8957'
const ZONE_FILES = [
  'PresentPlant_VigorZones-Pro.shp.zip',
  'VigorZones-Pro.shp.zip',
  'VigorZones-Lite.shp.zip',
]

interface ZoneLayerInputs {
  map?: mapboxgl.Map
  samplePlanBlocks?: SamplePlanBlock[]
  dataSources?: MapSource[]
  fields?: Record<string, any>
}

const addGeneratedSampleFeatureSourcesAndLayers = ({
  map,
  samplePlanBlocks,
  dataSources,
  fields,
}: ZoneLayerInputs) => {
  const generatedSampleFeatureSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  } = { sources: {}, layers: [] }

  if (!map) {
    return
  }

  if (!samplePlanBlocks || samplePlanBlocks.length === 0) {
    return
  }

  if (samplePlanBlocks.some(isCurvedRepresentativeRow)) {
    return
  }

  if (
    samplePlanBlocks.some(
      (spb) =>
        !map?.isSourceLoaded(`${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.parcelId}`)
    )
  ) {

    buildGeneratedSampleFeaturesSourcesAndLayers(
      samplePlanBlocks,
      generatedSampleFeatureSourcesAndLayers,
      dataSources,
      fields
    )

    Object.entries(generatedSampleFeatureSourcesAndLayers.sources).forEach(
      (sourceEntry) => {
        map?.addSource(sourceEntry[0], sourceEntry[1])
      }
    )
    generatedSampleFeatureSourcesAndLayers.layers.forEach((layer) => {
      map?.addLayer(layer as mapboxgl.AnyLayer, SAMPLE_PLAN_BLOCK_UNSELECTED)
    })
  }
}

const buildGeneratedSampleFeaturesSourcesAndLayers = (
  samplePlanBlocks: SamplePlanBlock[],
  generatedSampleFeatureSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  },
  dataSources?: MapSource[],
  fields?: Record<string, any>
) => {

  if (!dataSources) {
    return
  }

  const sourceByFileAndBlock = indexArrayMappedKey(
    dataSources,
    (ds) => `${ds.parcelId}-${ds.filename}`
  )

  samplePlanBlocks.forEach((spb) => {
    switch (spb.samplingStatisticalMethodId) {
      case AREA_WEIGHTED:
      case ZONAL_VARIATION:
        const source = sourceByFileAndBlock?.[`${spb.parcelId}-${ZONE_FILES[0]}`] 
          ?? sourceByFileAndBlock?.[`${spb.parcelId}-${ZONE_FILES[1]}`] 
          ?? sourceByFileAndBlock?.[`${spb.parcelId}-${ZONE_FILES[2]}`]

        const zonesPerBlock = fields?.[ZONES_PER_BLOCK]

        const minMax = spb.metadata?.[MIN_MAX] as { min: number; max: number }

        if (!source || !zonesPerBlock || !minMax) {
          return
        }

        generatedSampleFeatureSourcesAndLayers.sources[
          `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}`
        ] = {
          id: `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}`,
          bounds: bbox.parse(source.bounds),
          minzoom: Number(source.minzoom ?? 0),
          maxzoom: Number(source.maxzoom ?? 16),
          scheme: source.scheme ?? 'xyz',
          tileSize: source.tileSize ?? 512,
          attribution: source.attribution ?? '&copy; VineView',
          tiles: addTileUrl(source.tiles),
          type: (source.type ?? 'vector') as mapboxgl.Source['type'],
        }

        const paint = colorProfileToPaint({
          display: 'step',
          property: PROPERTY,
          stops: generateStops(
            ['#FF0000', '#FF8000', '#FFFF00', '#80C000', '#008000'],
            minMax.min,
            minMax.max,
            zonesPerBlock
          ),
        })

        generatedSampleFeatureSourcesAndLayers.layers.push({
          type: 'fill',
          paint: {
            'fill-outline-color': 'transparent',
            'fill-opacity': 0.6,
            'fill-color': paint,
          } as mapboxgl.FillPaint,
          layout: {},
          id: `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}`,
          source: `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}`,
          'source-layer': source.id,
        })
        break
      case REPRESENTATIVE_ROW:
        const generatedSampleFeatures = spb?.generatedSampleFeatures

        if (
          !generatedSampleFeatures ||
          generatedSampleFeatures.features.length === 0
        ) {
          return
        }

        generatedSampleFeatureSourcesAndLayers.sources[
          `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}`
        ] = {
          type: 'geojson',
          data: generatedSampleFeatures,
        }

        generatedSampleFeatureSourcesAndLayers.layers.push({
          type: 'line',
          paint: {
            'line-width': 1,
            'line-color': '#ffffff',
            'line-gap-width': 7,
          },
          layout: {
            'line-cap': 'butt',
          },
          id: `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}-outline`,
          source: `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}`,
        })

        generatedSampleFeatureSourcesAndLayers.layers.push({
          type: 'line',
          paint: {
            'line-opacity': 0.6,
            'line-width': 8,
            'line-color': '#49b662',
          },
          layout: {
            'line-cap': 'butt',
          },
          id: `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}-line`,
          source: `${SAMPLE_PLAN_GENERATED_FEATURE}-${spb.id}`,
        })
        break
      default:
        break
    }
  })
}

const generateStops = (
  colours: string[],
  minValue: number = 0,
  maxValue: number = 1,
  numberOfGroups: number = 5
) => {
  const stops: [number | string, string][] = []
  const step = 1 / colours.length
  for (let i = 0; i < 1; i += step) {
    stops.push([
      minValue + (maxValue - minValue) * i,
      colours[Math.floor(i * colours.length)],
    ])
  }
  const dataStops = stretch(resample(stops, numberOfGroups, 'spacing'), [
    minValue,
    maxValue,
  ])
  return dataStops
}

const isCurvedRepresentativeRow = (samplePlanBlock: SamplePlanBlock) => {
  if (samplePlanBlock.samplingStatisticalMethodId !== REPRESENTATIVE_ROW) {
    return false
  }

  const generatedSampleFeatures = samplePlanBlock?.generatedSampleFeatures
  if (generatedSampleFeatures.features.length === 0) {
    return false
  }

  return generatedSampleFeatures.features.some((f) => {
    const line = f as Feature<LineString>
    const coordinates = line.geometry.coordinates
    const lineLength = coordinates.reduce((acc, curr, index) =>  index < coordinates.length - 1 ? acc += calc.euclideanDistance(curr, coordinates[index + 1]) : acc, 0)
    const startToEndDistance = calc.euclideanDistance(coordinates[0], coordinates[coordinates.length - 1])
    const curvature = startToEndDistance / lineLength
    return curvature < 0.95
  })
}

export { addGeneratedSampleFeatureSourcesAndLayers }
