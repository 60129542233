import { createAsyncSelector } from '../../../../AsyncSelector/createAsyncSelector'
import { selectPreferredLanguage } from '../../../../data/selectPreferredLanguage'
import { RootStore } from '../../../../redux/types'
import { fetchOrderPackageSelection } from './fetchers/fetchOrderPackageSelection'

const {
  selector: selectOrderPackageSelection,
  refresh: refreshOrderPackageSelection,
} = createAsyncSelector({
  resource: 'OrderPackageSelectionData',
  inputs: {
    orderId: (state: RootStore) => state.router.params.orderId,
    lang: selectPreferredLanguage,
  },
  fetcher: async ({ orderId, lang }) => {
    if (!orderId) {
      return
    }

    return fetchOrderPackageSelection(orderId, lang)
  },
})

export { selectOrderPackageSelection, refreshOrderPackageSelection }
