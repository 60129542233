import { getJson } from '../../vvapi/apiResource/createApiResource'

export const uploadKatoFile = async (
  deliveryId: string,
  procGroup: string,
  file: File,
  reportProgress: (progress: number) => void
) => {
  const { url } = await getJson<{ url: string }>(
    `/api/v3/delivery-procgroup/${deliveryId}/${procGroup}/kato-file-output/upload`
  )

  try {
    await doUpload(url, file, reportProgress)
  } catch (e) {
    console.error(e)
    throw e
  }

  return toS3Url(url)
}

const toS3Url = (httpUrl: string) => {
  // strip query param and protocol
  const bareUrl = httpUrl.split('?')[0].split('https://')[1]
  // extract bucket and key
  const [bucket, key] = bareUrl.split('.s3.amazonaws.com/')

  return `s3://${bucket}/${decodeURIComponent(key)}`
}

const doUpload = (
  url: string,
  file: File,
  reportProgress: (progress: number) => void
) =>
  new Promise<void>((resolve, reject) => {
    const req = new XMLHttpRequest()
    req.open('PUT', url)
    req.setRequestHeader('x-amz-storage-class', 'STANDARD_IA')
    req.setRequestHeader('Content-Type', 'application/zip')
    req.upload.onprogress = (ev: ProgressEvent) => {
      reportProgress((ev.loaded / file.size) * 100)
    }
    req.upload.onerror = () => {
      const { status, statusText } = req

      reject(new Error(`Upload failed with status: ${status} -- ${statusText}`))
    }

    req.upload.onload = () => {
      setTimeout(() => {
        resolve()
      }, 500)
    }

    req.send(file)
  })
