import * as React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'

import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../graphql/api'
import { useIsLoading } from '../../hooks/useIsLoading'
import { useRedux } from '../../hooks/useRedux'
import { refreshGetDataSet, selectGetDataSet } from './selectGetDataSet'

export const DatasetEditNotesButton = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [newNotes, setNewNotes] = React.useState('')
  const { load, isLoading } = useIsLoading<void>()

  const [state] = useRedux()
  const dataset = selectGetDataSet(state)

  React.useEffect(() => {
    setNewNotes(dataset.data?.notes ?? '')
  }, [dataset])

  const handleOpen = () => {
    setDialogOpen(true)
  }

  const handleCancel = () => {
    setDialogOpen(false)
  }

  const handleSave = async () => {
    await load(async () => {
      if (!dataset) {
        throw new Error('dataSet is null')
      }
      if (!dataset.data) {
        throw new Error('dataSet.data is null')
      }

      const { id } = dataset.data

      try {
        await api.dataSet.update({
          pk: { id },
          input: {
            notes: newNotes,
          },
        })

        setDialogOpen(false)
        refreshGetDataSet()
      } catch (e) {
        alert(`Problem updating dataset notes: ${e.message}`)
      }
    })
  }

  return (
    <>
      <Dialog open={dialogOpen} maxWidth="sm">
        <DialogTitle>Edit Dataset Notes</DialogTitle>
        <DialogContent sx={{ width: 300 }}>
          <AsyncSelectorStatusOverlay requests={[]} isLoading={isLoading}>
            <TextField
              fullWidth
              label="Notes"
              multiline
              value={newNotes}
              onChange={(ev) => setNewNotes(ev.currentTarget.value)}
            />
          </AsyncSelectorStatusOverlay>
        </DialogContent>

        <DialogActions>
          <Stack direction="row" width="100%" spacing={1}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isLoading}
            >
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Button variant="outlined" onClick={handleOpen}>
        Edit Notes...
      </Button>
    </>
  )
}
