import * as React from 'react'
import { PureComponent } from 'react'
import { Typography } from '@mui/material'
import { MapSourcePropertyData } from '../../data/types'
import i18n, { keys } from '../../i18n'
import { selectSelectedProduct } from '../../postgis/selectSelectedProduct'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { Slider } from '../../UI/Slider'
import { formatNum } from '../../util/formatNumber'
import { throttle } from '../../util/throttle-debounce'
import { updateProductSettings } from '../store/redux'
import { selectActiveProductSettings } from '../store/selectors/selectActiveProductSettings'

class Threshold extends PureComponent<AppDispatchProps & ReduxProps> {
  handleChangeThreshold = throttle({ delay: 100 }, (threshold: number) => {
    const { product, dispatch } = this.props
    dispatch(
      updateProductSettings({
        productId: product?.id,
        settings: { threshold },
      })
    )
  })

  // TODO: redleaf and leafroll are supposed to have conditional prefixes
  getThresholdLabel = () => {
    return i18n.t(keys.visualization.threshold)
  }

  render() {
    const { settings } = this.props
    if (!settings) {
      return null
    }

    const { threshold, visualization } = settings

    if (visualization !== 'threshold') {
      return null
    }

    return (
      <React.Fragment>
        <Typography variant="subtitle1">
          {this.getThresholdLabel()}: {this.formatValue(threshold)}
        </Typography>
        <Slider
          min={0.01}
          max={0.99}
          step={0.01}
          style={{ flex: 1 }}
          value={threshold ?? 0}
          onChange={this.handleChangeThreshold}
        />
      </React.Fragment>
    )
  }

  formatValue(value?: number) {
    const { product } = this.props

    return value === undefined || !product || !product.dataProperty
      ? i18n.t(keys.generic.none)
      : formatNum(percentToProductValue(product.dataProperty, value))
  }
}

function toReal(value: number, [min, max]: [number, number]) {
  return min + value * (max - min)
}

function percentToProductValue(property: MapSourcePropertyData, value: number) {
  return toReal(value, property.range ?? [0, 1])
}

const mapState = (state: RootStore) => ({
  settings: selectActiveProductSettings(state),
  product: selectSelectedProduct(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(Threshold)
