import * as React from 'react'
import { MapboxGL } from './MapboxGL'

export type MapContextType = {
  map?: MapboxGL.Map
  setMap: (map: MapboxGL.Map) => void
}

// tslint:disable-next-line: variable-name
export const MapContext = React.createContext({
  map: undefined,
  setMap: () => undefined,
} as MapContextType)

export function MapContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [map, setMap] = React.useState<MapboxGL.Map>()

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  )
}

export interface WithMap {
  map: mapboxgl.Map
}

export const useMap = () => React.useContext(MapContext)

// eslint-disable-next-line import/no-anonymous-default-export
export default function <Props>(
  // tslint:disable-next-line: variable-name
  Wrapped: React.ComponentType<Props & WithMap>
) {
  // tslint:disable-next-line:no-shadowed-variable
  return function WithMap(props: Props) {
    return (
      <MapContext.Consumer>
        {({ map }) => (map ? <Wrapped map={map} {...props} /> : null)}
      </MapContext.Consumer>
    )
  }
}
