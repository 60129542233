import { Chip, Tooltip, TooltipProps } from '@mui/material'
import * as React from 'react'
import { url, urls } from '../../appNavigation/urls'
import { JobStatusAndId } from '../../pages/Organization/Delivery/ProcessingGroups/deliveryProcGroupSelectors'
import { getColor, getTextColor } from '../../pages/Organization/Delivery/utils'
import LinkChild from '../../UI/LinkChild'

interface Props extends Pick<TooltipProps, 'placement'> {
  jobStatusAndId: JobStatusAndId
}

export const JobStatusChip = (
  { jobStatusAndId: { status, id, output }, placement }: Props,
  key?: string | number
) => {
  return !!id ? (
    !!output ? (
      <Tooltip
        placement={placement}
        key={key}
        title={
          <div
            style={{
              maxHeight: 150,
              width: 200,
              overflow: 'auto',
              textAlign: 'justify',
            }}
          >
            <pre>{output?.join('\n') ?? ''}</pre>
          </div>
        }
      >
        <LinkChild to={url(urls.job, { jobId: id })}>
          <Chip
            sx={{ m: 1 }}
            label={status}
            style={{
              cursor: 'pointer',
              backgroundColor: getColor(status),
              color: getTextColor(status),
            }}
          />
        </LinkChild>
      </Tooltip>
    ) : (
      <LinkChild to={url(urls.job, { jobId: id })}>
        <Chip
          sx={{ m: 1 }}
          label={status}
          style={{
            cursor: 'pointer',
            backgroundColor: getColor(status),
            color: getTextColor(status),
          }}
        />
      </LinkChild>
    )
  ) : (
    <Chip
      sx={{ m: 1 }}
      key={key}
      label={status}
      style={{
        backgroundColor: getColor(status),
        color: getTextColor(status),
      }}
    />
  )
}
