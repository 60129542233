import '../../UI/FileDropTarget/FileDropTarget.scss'
import * as React from 'react'
import { Button, Stack, Typography, useTheme } from '@mui/material'
import { useDropzone } from 'react-dropzone'

interface Props {
  onFileChosen: (file?: File) => void
}

export const KatoFileUpload = ({ onFileChosen }: Props) => {
  const theme = useTheme()

  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } =
    useDropzone({
      maxFiles: 1,
      accept: {
        '': ['.okato'],
      },
    })

  const getFileLabel = () => {
    if (acceptedFiles.length === 0) {
      return 'Drop Kato file here.'
    }
    if (acceptedFiles.length > 1) {
      return 'Only one file can be uploaded to this Kato job.'
    }

    return acceptedFiles[0].name
  }

  React.useEffect(() => {
    onFileChosen(acceptedFiles[0])
  }, [acceptedFiles])

  return (
    <Stack direction="column" alignItems="center">
      <div
        {...getRootProps({ className: 'FileDropTarget__container' })}
        style={{ height: 100, width: '100%' }}
      >
        <div className="FileDropTarget__dropArea">
          <input {...getInputProps()} />
          <Typography>{getFileLabel()}</Typography>
        </div>
      </div>
      {fileRejections.length > 0 ? (
        <Stack>
          <Typography color={theme.palette.error.main}>
            Only a single .okato file can be uploaded. Cannot upload the
            following files:
          </Typography>
          <Typography>
            {fileRejections.map((file) => file.file.name).join(', ')}
          </Typography>
        </Stack>
      ) : null}
      <Button onClick={() => open()}>Choose File</Button>
    </Stack>
  )
}
