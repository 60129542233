import * as React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { RouteParams } from '../../../../appNavigation/urls'
import { ProcGroupsTable } from './ProcGroupsTable'

export default class ProcGroupsNavigation extends React.Component<
  RouteComponentProps<RouteParams>
> {
  render() {
    const { match } = this.props
    const tableRoute = `${match.url}/flight/:flight/status/:status`

    return (
      <Switch>
        <Route exact path={tableRoute} component={ProcGroupsTable} />
        <Route exact path={match.url}>
          <Redirect to={`${match.url}/flight/all/status/all`} />
        </Route>
      </Switch>
    )
  }
}
