import { GroupData, ParcelData } from '../../data/types'
import i18n from '../../i18n'

export function getParcelDateString(parcel: ParcelData) {
  const dates = Array.from(
    new Set((parcel.mapSources ?? []).map(({ flightDate }) => flightDate))
  ).sort()

  return dateRangeString(dates)
}

export function getGroupDateString(group: GroupData) {
  const dates = Array.from(
    new Set(
      group.parcels
        .map(({ mapSources }) => mapSources.map(({ flightDate }) => flightDate))
        .reduce((acc, next) => [...acc, ...next])
    )
  ).sort()

  return dateRangeString(dates)
}

export function getDateObjectListDateString(
  objects: { date: string }[],
  long?: boolean
) {
  const dates = Array.from(new Set(objects.map(({ date }) => date))).sort()

  return dateRangeString(dates, long)
}

export function dateRangeString(dates: string[], long?: boolean) {
  if (dates.length === 0) {
    return ''
  }

  if (dates.length === 1) {
    return long ? i18n.toDateShortName(dates[0]) : dates[0]
  }

  const start = dates[0]
  const end = dates[dates.length - 1]

  return long
    ? `${i18n.toDateShortName(start)} - ${i18n.toDateShortName(end)}`
    : `${start} - ${end}`
}
