import * as React from 'react'
import { default as Dialog } from '@mui/material/Dialog'
import { default as DialogActions } from '@mui/material/DialogActions'
import { default as DialogContent } from '@mui/material/DialogContent'
import { default as DialogTitle } from '@mui/material/DialogTitle'
import errorAlert from '../../../admin/errorAlert'
import { NameAndValue } from '../../../admin/mapdata/types'
import assertDefinedFields from '../../../admin/mapdata/utils/assertDefinedFields'
import { Button } from '@mui/material'
import * as api from '../../../graphql/api'
import { Model } from '../../../graphql/types'
import i18n, { keys } from '../../../i18n'
import SourcePropertyForm, { MapSourcePropertyData } from './SourcePropertyForm'

interface Props {
  mapSourceDefId: string
  open: boolean
  onSave(): void
  onClose(): void
}

type State = Partial<Writable<MapSourcePropertyData>>

class CreateSourcePropertyDialog extends React.PureComponent<Props, State> {
  state: State = {}

  render() {
    return (
      <Dialog
        id="CreateSourcePropertyDialog"
        fullWidth
        open={!!this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Create Source Property</DialogTitle>
        <DialogContent>
          <SourcePropertyForm
            instance={this.state}
            onChange={this.handleChange}
            onSubmit={this.handleSave}
          />
        </DialogContent>
        <DialogActions className="align-right">
          <Button onClick={this.props.onClose}>
            {i18n.t(keys.generic.cancel)}
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave}>
            {i18n.t(keys.generic.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleChange = ({ name, value }: NameAndValue) =>
    this.setState({
      [name]: value,
    })

  handleSave = async () => {
    const {
      name,
      description,
      type,
      property,
      acronym,
      filterable,
      classes,
      valueType,
      valueUnit,
      measurementType,
      range,
      showInPopup,
    } = this.state
    const { mapSourceDefId } = this.props
    const requiredFields = {
      name,
      type,
      property,
    }

    try {
      if (assertDefinedFields(requiredFields)) {
        await api.mapSourceProperty.create<Model>({
          input: {
            ...requiredFields,
            description,
            acronym,
            mapSourceDefId,
            classes,
            valueType,
            measurementType: measurementType ?? 'absolute',
            valueUnit,
            range,
            filterable: filterable || false,
            showInPopup,
          },
        })

        this.props.onSave()
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Source Property', message, this.state)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default CreateSourcePropertyDialog
