import { combineReducers } from 'redux'
import {
  reducer as rateMapReducer,
  pageType as rateMapPageType,
} from '../../pages/RateMap/redux/redux'

type MapEditorToolType = typeof rateMapPageType

const reducer = combineReducers({
  rateMap: rateMapReducer,
})

type MapEditorToolsState = ReturnType<typeof reducer>

export { reducer, MapEditorToolsState, MapEditorToolType }
