import { LanguageSelection } from '../app/types'
import { DeliveryGroupDate } from '../data/selectOrgMapData'
import { MapLayerDefData } from '../data/types'
import { client, gql } from '../graphql/client'
import { DataDownloadSettings } from './DataDownloadSettingsForm'
import { GeoPDFDownloadSettings } from './GeoPDFDownloadSettingsForm'

export const requestDownload = async ({
  name,
  selectedGroups,
  selectedDeliveries,
  organizationId,
  settings,
  preferredLanguage,
  deliveryGroupsDatesById,
  productsById,
}: {
  name: string
  selectedGroups: Set<string>
  selectedDeliveries: Set<string>
  organizationId: string
  settings: GeoPDFDownloadSettings | DataDownloadSettings
  preferredLanguage: LanguageSelection
  deliveryGroupsDatesById: Record<string, DeliveryGroupDate[]>
  productsById: Record<string, MapLayerDefData>
}) => {
  const isPdf = settings.type === 'geoPdf'
  const inputs = []

  for (const groupId of Array.from(selectedGroups)) {
    for (const deliveryId of Array.from(selectedDeliveries)) {
      const deliveryGroups = deliveryGroupsDatesById[`${deliveryId}_${groupId}`]
      if (!deliveryGroups || deliveryGroups.length === 0) {
        continue
      }

      if (isPdf) {
        const filename =
          productsById?.[settings.selectedProduct!]?.pdfFilenameTranslations?.[
            preferredLanguage
          ] ??
          productsById?.[settings.selectedProduct!]?.pdfFilename ??
          `${productsById?.[settings.selectedProduct!]?.name}.pdf`
        inputs.push({
          filename,
          type: 'geoPdf',
          organizationId,
          groupId,
          deliveryId,
          settings: { version: 1, parameters: { ...settings } },
        })
      } else {
        const {
          selectedFilenames,
          skipOrReplace = {},
          ...restSettings
        } = settings
        for (const filename of Array.from(selectedFilenames!)) {
          const skipOrReplaceAction =
            skipOrReplace[`${deliveryId}_${groupId}_${filename}`]
          if (!!skipOrReplaceAction) {
            if (skipOrReplaceAction.action === 'replace') {
              inputs.push({
                id: skipOrReplaceAction.id,
                filename,
                type: 'rawData',
                status: 'pending',
                organizationId,
                groupId,
                deliveryId,
                settings: { version: 1, parameters: { ...restSettings } },
              })
            }
          } else {
            inputs.push({
              filename,
              type: 'rawData',
              organizationId,
              groupId,
              deliveryId,
              settings: { version: 1, parameters: { ...restSettings } },
            })
          }
        }
      }
    }
  }

  await client.request({
    query: gql`
      mutation REQUEST_DOWNLOADS(
        $inputs: [Download_insert_input!]!
        $organizationId: Int!
        $name: String!
        $type: downloadType!
      ) {
        insert_DownloadRequest_one(
          object: {
            organizationId: $organizationId
            name: $name
            type: $type
            Downloads: {
              data: $inputs
              on_conflict: {
                constraint: Download_pkey
                update_columns: [status, requestId, settings]
              }
            }
          }
        ) {
          id
        }
      }
    `,
    variables: { inputs, organizationId, name, type: 'rawData' },
  })
}
