import { MapSourceProperty } from '../../graphql/types'
import { calcStats, CategoryStats } from './calcStats'
import { CategoryReduce } from './categoryReduce'

export interface ProductStat {
  type: MapSourceProperty['valueType']
  range?: MapSourceProperty['range']
  valueType: MapSourceProperty['valueType']
  valueUnit: MapSourceProperty['valueUnit']
  weightType: MapSourceProperty['valueType']
  weightUnit: MapSourceProperty['valueUnit']
  combined: CategoryStats
  parcels: {
    [parcelKey: string]: CategoryStats
  }
  minParcelStopSize: number
}

export interface ProductStats {
  [productId: string]: ProductStat
}

export interface ProductStops {
  [productId: string]: (string | number)[]
}

export function productStats(
  reduced: CategoryReduce,
  productStops: ProductStops
) {
  const result: ProductStats = {}

  for (const [productId, forProduct] of Object.entries(reduced)) {
    const stops = productStops[productId] ?? []

    const combined = calcStats(forProduct.combined, stops)

    const parcels = {}
    let minParcelStopSize = Infinity
    for (const [parcelId, forParcel] of Object.entries(forProduct.parcels)) {
      parcels[parcelId] = calcStats(forParcel, stops)
      const parcelSize = parcels[parcelId].data.minStopSize
      if (parcelSize < minParcelStopSize) {
        minParcelStopSize = parcelSize
      }
    }

    const { type, range, valueType, valueUnit, weightType, weightUnit } =
      forProduct

    result[productId] = {
      type,
      range,
      valueType,
      valueUnit,
      weightType,
      weightUnit,
      combined,
      parcels,
      minParcelStopSize,
    }
  }

  return result
}
