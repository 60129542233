export default function abbreviateNumber(n: number, decimal: number) {
  if (n >= 1e3 && n < 1e6) {
    return `${+(n / 1e3).toFixed(decimal)}k`
  }
  if (n >= 1e6 && n < 1e9) {
    return `${+(n / 1e6).toFixed(decimal)}m`
  }
  if (n >= 1e9 && n < 1e12) {
    return `${+(n / 1e9).toFixed(decimal)}b`
  }
  if (n >= 1e12) {
    return `${+(n / 1e12).toFixed(decimal)}t`
  }

  return `${n}`
}
