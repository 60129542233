import { createSelector } from 'reselect'

import { ParcelFilterInfo } from '../postgis/types'
import { RootStore } from '../redux/types'

export interface ParcelFilter {
  deliveries: {
    deliveryId: string
  }[]
  parcels: {
    parcelId: string
  }[]
}

const parcelMetaProperties = {
  varietals: ['varietals'],
  rootstock: ['rootstock'],
  trellises: ['trellis'],
}

type ParcelMetaPropertiesKeys = keyof typeof parcelMetaProperties

export const getFiltersInfo = (
  metaFilters: ParcelFilterInfo['metaFilters'],
  maxDate: ParcelFilterInfo['maxDate'],
  minDate: ParcelFilterInfo['minDate'],
  products: ParcelFilterInfo['products']
) => {
  const parcelFilters: any[] = []

  // meta filters
  for (const [key, properties] of Object.entries(
    parcelMetaProperties as Pick<
      ParcelFilterInfo['metaFilters'],
      ParcelMetaPropertiesKeys
    >
  ) as [ParcelMetaPropertiesKeys, string[]][]) {
    if (metaFilters[key]?.length) {
      for (const property of properties) {
        parcelFilters.push({
          _or: metaFilters[key].map((value) => ({
            meta: { _contains: { [property]: [value] } },
          })),
        })
      }
    }
  }

  // date filters
  if (maxDate) {
    parcelFilters.push({ flightDate: { _lte: maxDate } })
  }
  if (minDate) {
    parcelFilters.push({ flightDate: { _gte: minDate } })
  }

  // products filter
  if (products?.length) {
    parcelFilters.push({ mapLayerDefId: { _in: products } })
  }

  return parcelFilters
}

export const selectParcelFilterWhere = createSelector(
  [
    (state: RootStore) => state.postgis.parcelFilterInfo.metaFilters,
    (state: RootStore) => state.postgis.parcelFilterInfo.maxDate,
    (state: RootStore) => state.postgis.parcelFilterInfo.minDate,
    (state: RootStore) => state.postgis.parcelFilterInfo.products,
  ],
  getFiltersInfo
)
