// Magic number is the resolution of Mapbox at the equator at the max zoom
// level of our map (25). To calculate:
// - Draw a line from [0, 0] to something like [0.00001, 0]
// - Use turf to calculate the length of the line in meters
// - Un-project the coordinates to pixels
// - Final value is: meters / (x2 - x1)
// See https://stackoverflow.com/a/37794326/93003

const baseMetersToPixelsAtEquator = 78271.484

export default function metersToPixelsAtMaxZoom(
  meters: number,
  latitude: number
) {
  return metersToPixelsAtZoom(25, meters, latitude)
}

export const metersToPixelsAtZoom = (
  zoom: number,
  meters: number,
  latitude: number
) =>
  meters /
  (baseMetersToPixelsAtEquator / 2 ** zoom) /
  Math.cos((latitude * Math.PI) / 180)
