import { createSelector } from 'reselect'
import { RootStore } from '../../redux/types'
// import { groupArray } from '../../util/groupArray'
import {
  getNotesFilter,
  getSelectedProjects,
  selectFilteredNotes,
  selectNotesById,
  selectProjects,
  selectProjectsById,
} from '../../notes/selectors'
// import { indexArray } from '../../util/indexArray'
import { getApiUrl } from '../../vvapi/apiResource/createApiResource'
import { selectOrganization } from '../../data/selectOrganization'
import * as bbox from '../../util/bbox'
import notesToGeoJSON from '../../notes/notesToGeoJSON'
import { NotesFilterInfo } from '../../notes/types'
import i18n, { keys } from '../../i18n'

export const NOTE_MARKERS_POINTS = 'NOTE-MARKERS-POINTS'
export const NOTE_HALO_COLOUR = '#FFF'
export const FOCUSED_NOTE_MARKERS_POINTS = 'FOCUSED_NOTE_MARKERS_POINTS'
export const NOTE_MARKERS_POLYGONS = 'NOTE-MARKERS-POLYGONS'

const getEditingNoteId = (state: RootStore) => state.notes.editingNoteId
const getFocusedNoteId = (state: RootStore) => state.notes.focusedNoteId

export const selectNoteSourceIds = createSelector(selectProjects, getSelectedProjects, (projects, selectedProjectIds) =>
  projects
  .filter((p) => selectedProjectIds.includes(p.id))
  .map((p) => getProjectSourceId(p.id, true))
)

export const getNotesLayer = createSelector(
  selectNotesById,
  getEditingNoteId,
  getFocusedNoteId,
  getNotesFilter,
  selectFilteredNotes,
  selectOrganization,
  selectProjectsById,
  selectNoteSourceIds,
  (
    notes = {},
    editingNoteId,
    focusedNoteId,
    notesFilters,
    _filteredNotes,
    organization,
    projectsById = {},
    noteSourceIds
  ) => {
    const sources = {}
    const layers: any[] = []
    if (!organization) {
      return { sources, layers }
    }

    const highlightedNoteId = editingNoteId ?? focusedNoteId

    if (highlightedNoteId) {
      const focusedNote = notes[highlightedNoteId]

      const focusedNoteGeoJson = notesToGeoJSON([focusedNote])

      sources[FOCUSED_NOTE_MARKERS_POINTS] = {
        data: focusedNoteGeoJson,
        type: 'geojson',
        attribution: '&copy; VineView',
      }

      layers.push({
        type: 'circle',
        paint: {
          'circle-color': NOTE_HALO_COLOUR,
          'circle-blur': 1,
          'circle-radius': 30,
          'circle-translate': [0, -12],
        },
        id: FOCUSED_NOTE_MARKERS_POINTS,
        source: FOCUSED_NOTE_MARKERS_POINTS,
      })
    }

    for (const noteSourceId of noteSourceIds) {
      if (noteSourceId.startsWith('notes-project-general')) {
        let generalTileUrl = `${getApiUrl()}/api/v3/notes/tiles-no-project/${
          organization.id
        }/{z}/{x}/{y}.mvt${getNoteLayerQueryParameters(notesFilters)}`

        // check for general project
        if (!projectsById['general']) {
          continue
        }

        // no bounds means no notes
        if (projectsById['general'].bounds === null) {
          continue
        }

        const generalNoteMarkersSource = {
          type: 'vector',
          tiles: [generalTileUrl],
          attribution: '&copy; VineView',
          id: noteSourceId,
          bounds: bbox.parse(projectsById['general'].bounds),
          volatile: true,
          tileSize: 512,
        }

        sources[generalNoteMarkersSource.id] = generalNoteMarkersSource

        layers.push(makeNoteLayer(generalNoteMarkersSource.id, 'general'))
      } else {
        const projectId = noteSourceId.substring(14, 14 + 36)
        const project = projectsById[projectId]
        // no bounds means no notes
        if (project.bounds === null) {
          continue
        }
        if (project) {
          let tileUrl: string

          tileUrl = `${getApiUrl()}/api/v3/notes/tiles-by-project/${
            organization.id
          }/${project.id}/{z}/{x}/{y}.mvt${getNoteLayerQueryParameters(
            notesFilters
          )}`

          const noteMarkersSource = {
            type: 'vector',
            tiles: [tileUrl],
            attribution: '&copy; VineView',
            id: noteSourceId,
            bounds: bbox.parse(project.bounds),
            volatile: true,
            tileSize: 512,
          }

          if (!sources[noteMarkersSource.id]) {
            sources[noteMarkersSource.id] = noteMarkersSource

            layers.push(makeNoteLayer(noteMarkersSource.id, project.id))
          }
        }
      }
    }

    return { sources, layers }
  }
)

const getNoteLayerQueryParameters = (notesFilters: NotesFilterInfo) => {
  const { fromDate, toDate, ...noteFilterWithoutDate } = notesFilters
  let noteFilter: Omit<NotesFilterInfo, 'fromDate' | 'toDate'> & {
    fromDate?: string
    toDate?: string
  } = noteFilterWithoutDate

  if (fromDate) {
    noteFilter.fromDate = fromDate.toUTCString()
  }
  if (toDate) {
    noteFilter.toDate = toDate.toUTCString()
  }

  const filters = Object.entries(noteFilter).filter(([_, value]) => {
    if (value === undefined) {
      return false
    }
    if (Array.isArray(value) || typeof value === 'string') {
      return value.length > 0
    }
    return true
  })

  if (filters.length === 0) {
    return ''
  }

  if (notesFilters.searchTerm !== undefined && notesFilters.searchTerm !== '') {
    const reservedTerms = Object.entries(keys.noteForm.reservedNames)
      .filter(([key]) => {
        return (
          i18n
            .t(`noteForm.reservedNames.${key}`)
            .toLocaleLowerCase()
            .indexOf(notesFilters.searchTerm.toLocaleLowerCase()) >= 0
        )
      })
      .map(([key]) => key)
    filters.push(['reservedTerms', JSON.stringify(reservedTerms)])
  }

  return `?${new URLSearchParams(filters).toString()}`
}

const makeNoteLayer = (sourceId: string, projectId: string) => ({
  type: 'symbol',

  layout: {
    'text-field': String.fromCharCode(0xe568),
    'text-font': ['Material Icons Regular'],
    'text-size': ['interpolate', ['exponential', 2], ['zoom'], 5, 22, 25, 300],
    'text-line-height': 1,
    'text-offset': [0, 0.25],
    'text-padding': 0,
    'text-allow-overlap': true,
    'icon-optional': true,
    'icon-text-fit': 'both',
    'text-anchor': 'bottom',
    'symbol-sort-key': ['to-number', ['get', 'priority']],
  },
  paint: {
    'text-translate-anchor': 'viewport',
    'text-color': ['get', 'pinColor'],
    'text-halo-color': '#FFF',
    'text-halo-blur': 0,
    'text-halo-width': 2.5,
  },
  'source-layer': projectId,
  id: `${sourceId}-layer`,
  source: sourceId,
})

export const getProjectSourceId = (projectId?: string, cacheProof?: boolean) =>
  `notes-project-${projectId ?? 'general'}${cacheProof ? `-${Date.now()}` : ''}`
