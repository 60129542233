import { Typography } from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../i18n'

interface Props {
  totalSamples?: number
  completedSamples?: number
  isPending?: boolean
  variant?: 'overline' | 'body1'
}

export const SamplePlanStatusText = ({
  isPending,
  totalSamples = -1,
  completedSamples = -1,
  variant = 'body1'
}: Props) => {
  const [labelText, setLabelText] = React.useState('-')
  const [labelColour, setLabelColour] = React.useState('')

  React.useEffect(() => {
    // missing data
    if(totalSamples === -1) {
        setLabelText('-')
        setLabelColour('')
        return
    }

    // pending
    if (isPending) {
      setLabelText(i18n.t(keys.samplePlanStatusPendingLabel))
      setLabelColour('#7CB1FF')
      return
    }

    // completed
    if (completedSamples === totalSamples) {
      setLabelText(i18n.t(keys.samplePlanStatusCompleteLabel))
      setLabelColour('#5CC46C')
      return
    }

    // in progress
    setLabelText(i18n.t(keys.samplePlanStatusInProgressLabel))
    setLabelColour('#FFC107')
  }, [isPending, totalSamples, completedSamples])

  return <Typography variant={variant} sx={{ color: labelColour }}>{labelText}</Typography>
}
