import {
  FormControl,
  Link,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../i18n'
import useAsync from '../hooks/useAsync'
import { selectOrganizationId } from '../data/selectOrganizationId'
import { useRedux } from '../hooks/useRedux'
import { getProjects } from './queries'
import { CreateProjectModal } from './create/CreateProjectModal'
import { ProjectType } from '../graphql/types'
import { OutlinedSelect } from '../notes/NoteForm'

interface Props {
  selectedProjectId: string | undefined
  type: ProjectType
  onProjectChange: (projectId: string | undefined) => void
}

const SelectProjectTitle = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '14.4px',
  letterSpacing: '-0.035em',
  textAlign: 'left',
  marginBottom: -5,
}))

export const SelectProject = ({
  selectedProjectId,
  type,
  onProjectChange,
}: Props) => {
  const [state] = useRedux()

  const organizationId = selectOrganizationId(state)
  const [projectOptionsRequest, refreshProjectOptions] = useAsync(getProjects, [
    organizationId,
  ])

  const [newProjectOpen, setNewProjectOpen] = React.useState(false)

  const projectOptions = React.useMemo(() => {
    return projectOptionsRequest?.result?.data ?? []
  }, [projectOptionsRequest.result?.data])

  return (
    <Stack direction="column" gap={1}>
      <SelectProjectTitle>{i18n.t(keys.projectLabel)}</SelectProjectTitle>
      <FormControl required size="small">
        <OutlinedSelect
          variant="outlined"
          name="projects"
          displayEmpty
          value={selectedProjectId ?? ''}
          required
          size="small"
          onChange={(e: SelectChangeEvent<string>) =>
            onProjectChange(
              !!e.target.value && e.target.value.length > 0
                ? e.target.value
                : undefined
            )
          }
        >
          <MenuItem value="">
            {i18n.t(keys.noteForm.reservedNames.__VV__GENERAL__TEMPLATE__)}
          </MenuItem>
          {projectOptions?.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {project.name}
            </MenuItem>
          ))}
        </OutlinedSelect>
      </FormControl>
      <Link
        component="button"
        onClick={() => setNewProjectOpen(true)}
        underline="hover"
        style={{ textAlign: 'start' }}
      >
        {i18n.t(keys.projectCreateNewLabel)}
      </Link>
      <CreateProjectModal
        open={newProjectOpen}
        onCancel={() => setNewProjectOpen(false)}
        onProjectCreate={(id) => {
          setNewProjectOpen(false)
          refreshProjectOptions()
          onProjectChange(id)
        }}
        organizationId={organizationId}
        type={type}
      />
    </Stack>
  )
}
