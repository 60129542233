import { default as gql } from 'graphql-tag'
import { query } from '../../graphql/client'
import {
  MapLayer,
  MapLayerDef,
  MapSource,
  MapSourceDef,
  MapSourceProperty,
} from '../../graphql/types'

const FETCH_LAYER_INFO = gql`
  query LayerInfo($ids: [uuid!]!, $types: [String!]!) {
    MapLayer(
      where: {
        id: { _in: $ids }
        MapLayerDef: { MapSourceDef: { type: { _in: $types } } }
      }
    ) {
      MapSource {
        id
        deliveryId
        parcelId
        type
        statsData
        properties
        flightDate
        DeliveryParcel {
          Parcel {
            groupId
          }
        }
      }
      mapLayerDefId
    }
    MapLayerDef(where: { MapSourceDef: { type: { _in: $types } } }) {
      id
      DataProperty {
        type
        property
        classes
        noDataValue
        valueType
        valueUnit
        range
      }
      NoDataProperty {
        type
        property
        classes
        noDataValue
        valueType
        valueUnit
        range
      }
      CoverageProperty {
        type
        property
        classes
        noDataValue
        valueType
        valueUnit
        range
      }
      WeightProperty {
        type
        property
        classes
        noDataValue
        valueType
        valueUnit
        range
      }
      MapLayerSettings {
        coverage
      }
      MapSourceDef {
        id
        type
      }
    }
  }
`

type Property = null | Pick<
  MapSourceProperty,
  | 'type'
  | 'property'
  | 'classes'
  | 'noDataValue'
  | 'valueType'
  | 'valueUnit'
  | 'range'
>

export type Layer = Pick<MapLayer, 'mapLayerDefId'> & {
  MapSource: Pick<
    MapSource,
    | 'id'
    | 'deliveryId'
    | 'parcelId'
    | 'type'
    | 'statsData'
    | 'properties'
    | 'DeliveryParcel'
    | 'flightDate'
  >
  Properties?: Record<string, any>[]
}

export type SourceDef = Pick<MapSourceDef, 'id' | 'type'>

export type LayerDef = Pick<MapLayerDef, 'id'> & {
  DataProperty: Property
  NoDataProperty: Property
  CoverageProperty: Property
  WeightProperty: Property
  MapLayerSettings: { coverage?: number }[]
  MapSourceDef: SourceDef
}

interface Data {
  MapLayer: Layer[]
  MapLayerDef: LayerDef[]
}

const STATS_TYPES = ['point', 'line', 'zone']

export function fetchLayerInfo(ids: string[]) {
  return query<Data>({
    variables: {
      ids,
      types: STATS_TYPES,
    },
    query: FETCH_LAYER_INFO,
  })
}
