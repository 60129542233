import * as React from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import * as api from '../../graphql/api'
import { Model } from '../../graphql/types'
import errorAlert from '../errorAlert'
import assertDefinedFields from '../mapdata/utils/assertDefinedFields'
import MapLayerDefGroupsForm from './MapLayerDefGroupForm'
import { GetMapLayerDefGroupData } from './selectGetMapLayerDefGroup'

export const NewMapLayerDefGroup = () => {

  const history = useHistory()

  const handleCreate = async (formData: Partial<GetMapLayerDefGroupData>) => {
    const { name } = formData
    const requiredFields = {
      name
    }

    try {
      if (assertDefinedFields(requiredFields)) {
        const req = await api.mapLayerDefGroup.create<Model>({
          input: requiredFields,
        })

        history.replace(
          url(urls.editLayerDefGroup, { mapLayerDefGroupId: req.data!.id })
        )
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Source Definition', message)
    }
  }

  const goHome = () => {
    history.push(url(urls.listLayerDefGroups))
  }

  return (
    <Page
      title={`New Layer Definition Group`}
      backTo={url(urls.listLayerDefGroups)}
      backToTitle="Source Defs"
    >
      <div id="NewMapSourceDef" className="MapDataContainerSmall">
        <MapLayerDefGroupsForm
          type="new"
          onSave={handleCreate}
          onCancel={goHome}
        />
      </div>
    </Page>
  )

}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default withRouter(NewMapLayerDefGroup)
