import * as React from 'react'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import i18n from '../../../../i18n'
import { GetOrganizationDeliveriesResult } from './fetchOranizationDeliveries'

interface ChooseDeliveryProps {
  deliveries: GetOrganizationDeliveriesResult['deliveries']
  selectedDelivery?: string
  onSelectDelivery: (id: string) => void
}
export const ChooseDelivery = ({
  deliveries,
  selectedDelivery,
  onSelectDelivery,
}: ChooseDeliveryProps) => {
  return (
    <List>
      {deliveries.map((delivery) => (
        <ListItem
          key={delivery.id}
          onClick={() => onSelectDelivery(delivery.id)}
        >
          <ListItemButton selected={delivery.id === selectedDelivery}>
            <ListItemText
              primary={
                delivery.order.comment !== null
                  ? `${delivery.order.comment}-${i18n.toDateShort(
                      delivery.targetDeliveryDate
                    )}`
                  : i18n.toDateShort(delivery.targetDeliveryDate)
              }
              secondary={delivery.comment}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
