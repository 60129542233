import { Avatar, Icon, Tooltip, Typography } from '@mui/material'
import * as React from 'react'
import { ListDeliveryStatus } from './types'

export const renderAvatar = (complete: boolean, tooltip?: string) => {
  const style: React.CSSProperties = {
    color: 'var(--color)',
    backgroundColor: 'var(--background)',
    width: 23,
    height: 23,
    verticalAlign: 'middle',
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  }
  let icon = 'error_outline'

  if (complete) {
    style.backgroundColor = 'var(--green)'
    icon = 'check_circle_outline'
  } else {
    style.backgroundColor = 'var(--red)'
  }

  return tooltip ? (
    <Tooltip title={tooltip}>
      <Avatar style={style}>
        <Icon fontSize="small">{icon}</Icon>
      </Avatar>
    </Tooltip>
  ) : (
    <Avatar style={style}>
      <Icon fontSize="small">{icon}</Icon>
    </Avatar>
  )
}

export const readyStatus = (
  deliveryStatus?: ListDeliveryStatus['View_DeliveryStatus'] | null
) => {
  if (deliveryStatus) {
    const { totalCount, readyCount, ready } = deliveryStatus

    return (
      <>
        {renderAvatar(ready)}
        <Typography>{`${readyCount}/${totalCount}`}</Typography>
      </>
    )
  }

  return null
}

export const expectedStatus = (
  deliveryStatus?: ListDeliveryStatus['View_DeliveryStatus'] | null
) => {
  if (deliveryStatus) {
    const { totalCount, expectedCount, expected } = deliveryStatus

    return (
      <>
        {renderAvatar(expected)}
        <Typography>{`${expectedCount}/${totalCount}`}</Typography>
      </>
    )
  }

  return null
}

export const fulfilledStatus = (
  deliveryStatus?: ListDeliveryStatus['View_DeliveryStatus'] | null
) => {
  if (deliveryStatus) {
    const { totalCount, fulfilledCount, fulfilled } = deliveryStatus

    return (
      <>
        {renderAvatar(fulfilled)}
        <Typography>{`${fulfilledCount}/${totalCount}`}</Typography>
      </>
    )
  }

  return null
}

export const enabledStatus = (
  deliveryStatus?: ListDeliveryStatus['View_DeliveryStatus'] | null
) => {
  if (deliveryStatus) {
    const { totalCount, enabledCount, enabled } = deliveryStatus

    return (
      <>
        {renderAvatar(enabled)}
        <Typography>{`${enabledCount}/${totalCount}`}</Typography>
      </>
    )
  }

  return null
}
