import * as React from 'react'
import { url, urls } from '../../../appNavigation/urls'
import { Variable } from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import { useHistory, useParams } from '../../../hooks/useRouter'
import { AdminPage } from '../AdminPage'
import { fetchVariableByKey } from './fetchVariableByKey'
import { updateVariableByKey } from './updateVariableByKey'
import VariableForm from './VariableForm'

export function VariableEdit() {
  const history = useHistory()
  const { key } = useParams()

  const [result, refresh] = useAsync(fetchVariableByKey, [key])

  if (!key) {
    return null
  }

  const variable = result.result ? result.result.data : undefined

  const handleSave = async (org: Variable) => {
    const updated = await updateVariableByKey(key, org)
    const newKey = updated?.data?.key

    if (newKey) {
      if (key !== newKey) {
        history.replace(url(urls.admin_variables_edit, { key: newKey }))

        return true
      }

      refresh()

      return true
    }

    return false
  }

  return (
    <AdminPage title="Edit Variable" subtitle={key}>
      <VariableForm
        type="edit"
        data={variable}
        saveMode="all"
        onSave={handleSave}
      />
    </AdminPage>
  )
}
