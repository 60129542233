import { default as gql } from 'graphql-tag'

import { DocumentNode, print } from 'graphql'
import { GQLAuthError } from './GQLAuthError'
import { GQLError } from './GQLError'

interface GQLRequest<V extends Record<string, any>> {
  query: DocumentNode
  variables?: V
}

interface GQLResponse<D> {
  data?: D
  errors?: any[]
}

interface GQLClientOptions {
  url: string
  headers?: Record<string, string>
}

function createGQLClient(options: GQLClientOptions | (() => GQLClientOptions)) {
  const getOptions = typeof options === 'function' ? options : () => options

  async function request<R = any, V extends Record<string, any> = any>({
    query,
    variables,
  }: GQLRequest<V>): Promise<R> {
    const { url, headers = {} } = getOptions()

    const printQuery = print(query)

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...headers,
      },
      body: JSON.stringify({ variables, query: printQuery }),
    })

    const { data, errors } = (await res.json()) as GQLResponse<R>

    if (!data || errors) {
      if (errors?.[0]?.extensions?.code === 'access-denied') {
        const authError = new GQLAuthError(printQuery, data, errors, variables)
        // tslint:disable-next-line: no-console
        console.error(authError)
        throw authError
      }

      const error = new GQLError(printQuery, data, errors, variables)
      // tslint:disable-next-line: no-console
      console.error(error)
      throw error
    }

    return data
  }

  return {
    request,
  }
}

type GQLClient = ReturnType<typeof createGQLClient>

export { createGQLClient, GQLClient, gql }
