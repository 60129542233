import { MapLayerDefData } from '../../../data/types'
import { VisualizationMode } from '../types'

/**
 * VisualizationMode for product
 */
export function getVisualization(
  visualization: VisualizationMode | undefined,
  visualizations: VisualizationMode[]
): VisualizationMode | undefined {
  if (visualization && visualizations.includes(visualization)) {
    return visualization
  }

  // pop from the top;
  // visualizations are already ordered from GraphQL
  return visualizations[0]
}

export function getVisualizationsForProduct(product: MapLayerDefData) {
  const result: VisualizationMode[] = []

  for (const colorProfile of product.colorProfiles) {
    for (const vis of colorProfile.visualizations) {
      if (vis.default) {
        // default should take precedence; add it to the top of the array
        result.unshift(vis.visualization)
      } else {
        result.push(vis.visualization)
      }
    }
  }

  return Array.from(new Set(result))
}
