import * as React from 'react'

import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { VVRegion } from '../../graphql/types'
import { Form } from '../UI/Form'
import SmallGridContainer from '../../UI/SmallGridContainer'

export interface DataSetEditMetaFormData {
  heading?: number
  pitch?: number
  roll?: number
  region?: VVRegion
}
export class DataSetEditMetaForm extends Form<DataSetEditMetaFormData> {
  render() {
    const { heading, pitch, roll, region } = this.getFormData()

    return (
      <>
        <SmallGridContainer>
          <Grid item>
            <TextField
              type="number"
              name="heading"
              label={`Heading`}
              value={heading}
              onChange={this.updateFormDataFromChangeEvent}
            />
          </Grid>
          <Grid item>
            <TextField
              type="number"
              name="pitch"
              label={`Pitch`}
              value={pitch}
              onChange={this.updateFormDataFromChangeEvent}
            />
          </Grid>
          <Grid item>
            <TextField
              type="number"
              name="roll"
              label={`Roll`}
              value={roll}
              onChange={this.updateFormDataFromChangeEvent}
            />
          </Grid>
        </SmallGridContainer>

        <FormControl fullWidth>
          <InputLabel required shrink={!!region}>
            Region
          </InputLabel>
          <Select
            name="region"
            fullWidth
            onChange={this.updateFormDataFromSelectChangeEvent}
            value={region}
          >
            <MenuItem value={'California'}>California</MenuItem>
            <MenuItem value={'France'}>France</MenuItem>
          </Select>
        </FormControl>

        <div style={{ marginTop: 16, textAlign: 'right' }}>
          {this.renderFormButtons()}
        </div>
      </>
    )
  }
}
