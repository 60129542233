export enum FixType {
  NOT_VALID,
  GPS_FIX,
  DGNSS,
  NA,
  RTK_FIX,
  RTK_FLOAT,
  DEAD,
}

export const displayFixType = (fixType: FixType) => {
  return FixType[fixType]
}
