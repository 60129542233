import { selectPagingInfo } from '../../../appNavigation/urls'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { RootStore } from '../../../redux/types'
import { FILTER, filterBy } from '../../../UI/Table/filterRows'
import { ORDER, orderBy } from '../../../UI/Table/orderRows'
import { tableFormatter } from './formatters'
import { ListDeliveryGroupFileStatus } from './types'

const list = ({
  limit,
  offset,
  order_by,
  where,
}: {
  limit?: number
  offset?: number
  order_by?: string
  where?: any
}) =>
  api.deliveryGroupFile.list<ListDeliveryGroupFileStatus>({
    limit,
    offset,
    order_by,
    where,
    returning: `{
      deliveryId 
      groupId
      filename
      status
      uploadedAt
      statusUpdatedAt
      jobId

      file: File {
        downloadToken
      }

      Organization {
        name
      }

      Delivery {
        id
      }

      DeliveryGroup {
        name
      }

      CreatedBy {
        firstName
      }

      UpdatedBy {
        firstName
      }
    }`,
  })

const {
  selector: selectListDeliveryGroupFileStatus,
  refresh: refreshListDeliveryGroupFileStatus,
} = createAsyncSelector({
  resource: 'ListDeliveryGroupFileStatus',
  inputs: {
    searchParams: (state: RootStore) => state.router.searchParams,
    pagingInfo: selectPagingInfo,
  },
  fetcher: async ({ searchParams, pagingInfo: { page, pageSize } }) => {
    const order = ORDER.deserialize(searchParams.orderBy ?? '')
    const filter = FILTER.deserialize((searchParams.filter as string) ?? '')

    const offset = page * pageSize
    const limit = pageSize

    const { data, info } = await list({
      offset,
      limit,
      order_by: orderBy(tableFormatter, order),
      where: filterBy(tableFormatter, filter),
    })

    if (!data) {
      throw new Error('No Data')
    }

    return {
      data,
      info: {
        order,
        filter,
        page,
        pageSize,
        count: info?.count ?? 0,
      },
    }
  },
})

export { selectListDeliveryGroupFileStatus, refreshListDeliveryGroupFileStatus }
