import * as React from 'react'
import { default as FormControl } from '@mui/material/FormControl'
import { default as InputLabel } from '@mui/material/InputLabel'
import { default as MenuItem } from '@mui/material/MenuItem'
import { default as Select } from '@mui/material/Select'
import { default as TextField } from '@mui/material/TextField'
import { default as Typography } from '@mui/material/Typography'
import { MapLayerSettings } from '../../graphql/types'
import { Form } from '../UI/Form'

const visualizations: MapLayerSettings['visualization'][] = [
  'absolute',
  'relative',
  'threshold',
]

export default class LayerSettingsForm extends Form<MapLayerSettings> {
  render() {
    const {
      visualization,
      threshold,
      opacity,
      numberOfGroups,
      groupBy,
      coverageMin,
      coverageMax,
      coverage,
    } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
            Default Settings
          </Typography>
          <FormControl fullWidth>
            <InputLabel required shrink={!!visualization}>
              visualization!
            </InputLabel>
            <Select
              name="visualization"
              fullWidth
              onChange={this.updateFormDataFromSelectChangeEvent}
              value={visualization ?? ''}
            >
              {visualizations.map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="grid-xs-12">
          <TextField
            name="threshold"
            type="number"
            label={`Threshold!`}
            InputLabelProps={{
              shrink: threshold !== undefined,
            }}
            value={threshold}
            autoComplete="off"
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="opacity"
            type="number"
            label={`opacity!`}
            InputLabelProps={{
              shrink: opacity !== undefined,
            }}
            value={opacity}
            autoComplete="off"
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="numberOfGroups"
            type="number"
            label={`Number of Groups!`}
            InputLabelProps={{
              shrink: numberOfGroups !== undefined,
            }}
            value={numberOfGroups}
            autoComplete="off"
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="groupBy"
            label={`Group By!`}
            InputLabelProps={{
              shrink: groupBy !== undefined,
            }}
            value={groupBy}
            autoComplete="off"
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="coverageMin"
            type="number"
            label={`Coverage Min!`}
            InputLabelProps={{
              shrink: coverageMin !== undefined,
            }}
            value={coverageMin}
            autoComplete="off"
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="coverageMax"
            type="number"
            label={`Coverage Max!`}
            InputLabelProps={{
              shrink: coverageMin !== undefined,
            }}
            value={coverageMax}
            autoComplete="off"
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="coverage"
            type="number"
            label={`Coverage!`}
            InputLabelProps={{
              shrink: coverage !== undefined,
            }}
            value={coverage}
            autoComplete="off"
            onChange={this.handleChange}
          />
        </div>
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }

  handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.type === 'number' && 'valueAsNumber' in e.target) {
      this.updateFormDataFromNameAndValue({
        name: e.target.name,
        value: e.target.valueAsNumber,
      })
    } else {
      this.updateFormDataFromNameAndValue(e.target)
    }
  }
}
