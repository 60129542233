export class GQLAuthError extends Error {
  errors: any[]
  name = 'GQLError'

  constructor(query: string, data: any, errors: any[] = [], variables: any) {
    super(`${query}\n${JSON.stringify({ data, errors, variables }, null, 2)}`)
    this.errors = errors

    // set the prototype so JavaScript knows it's an error
    Object.setPrototypeOf(this, Error.prototype)
  }
}
