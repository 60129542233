const waitForLoaded = (condition: () => boolean, effect: () => void) => {
  const retryInterval = setInterval(() => {
    if (condition()) {
      clearInterval(retryInterval)
      effect()
    }
  }, 100)

  return () => clearInterval(retryInterval)
}

export default waitForLoaded