import { Box, Stack, styled, useMediaQuery, useTheme } from '@mui/material'
import * as React from 'react'
import { ContainedDrawer } from './ContainedDrawer'
import { DrawerTabOption } from './types'
import { selectDrawerStatus } from './redux/selectors/selectDrawerStatus'
import { MapEditorToolType } from '../redux/map-editor-pages/redux'
import { pageType } from '../pages/RateMap/redux/redux'
import { useRedux } from '../hooks/useRedux'
import { MapEditorActionTypes } from './redux/types'
import { selectDrawerWidth } from './redux/selectors/selectDrawerWidth'

interface Props<DrawerConfig> {
  id: string
  pageType: MapEditorToolType
  // DrawerWidths?: Partial<Record<'left' | 'right', number>>
  drawerByPosition: Record<string, string[]>
  drawerComponents: Record<
    string,
    {
      icon: (
        option: DrawerTabOption<DrawerConfig>,
        page: MapEditorToolType
      ) => React.ReactNode
      component: (
        option: DrawerTabOption<DrawerConfig>,
        page: MapEditorToolType
      ) => React.ReactNode
    }
  >
  drawerOptions: Record<string, DrawerTabOption<DrawerConfig>>
}

const DrawerContainer = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'row',
  height: '100%',
  width: '100%',
  paddingTop: '54px',
}))

interface InnerWindowProps {
  isMobile: boolean
  DrawerOpen: boolean
}

const InnerWindow = styled(Box)<InnerWindowProps>(
  ({ theme, isMobile, DrawerOpen }) => ({
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    pointerEvents: isMobile && DrawerOpen ? 'auto' : 'none',
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: isMobile && DrawerOpen ? '#0000007D' : undefined,
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  })
)

const Drawer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  width: '54px',
  maxWidth: '54px',
  zIndex: theme.zIndex.drawer + 1,
}))

interface DrawerTabProps {
  side: 'left' | 'right'
  selected: boolean
}

const DrawerTab = styled(Box)<DrawerTabProps>(({ theme, selected, side }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '54px',
  height: '54px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.action.hover,
  },
  borderRight:
    side === 'left'
      ? `2px solid ${
          selected ? theme.palette.primary.dark : theme.palette.divider
        }`
      : 'none',
  borderLeft:
    side === 'right'
      ? `2px solid ${
          selected ? theme.palette.primary.dark : theme.palette.divider
        }`
      : 'none',
  backgroundColor: selected ? theme.palette.background.default : 'transparent',
}))

const DrawerTabContent = styled(Box)<DrawerTabProps>(({ selected }) => ({
  display: selected ? 'flex' : 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
}))

export const MapEditorDrawers = <DrawerConfig,>({
  drawerOptions,
  drawerByPosition,
  drawerComponents,
}: // DrawerWidths,
Props<DrawerConfig>) => {
  const [state, dispatch] = useRedux()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { rightDrawerWidth, leftDrawerWidth } = selectDrawerWidth(
    state,
    pageType
  )

  const {
    leftDrawerOpen,
    rightDrawerOpen,
    leftDrawerTabIndex,
    rightDrawerTabIndex,
  } = selectDrawerStatus(state, pageType)

  // note that the undefined positions are in the left side bar
  const sortedLeftDrawerIds = React.useMemo(() => {
    const leftDrawerIds = drawerByPosition?.['left-side-bar'] ?? []
    const undefinedDrawerIds = drawerByPosition?.['undefined'] ?? []
    const DrawerIds = [...leftDrawerIds, ...undefinedDrawerIds]
    return DrawerIds?.sort((a, b) => {
      const aIndex = drawerByPosition?.['left-side-bar']?.indexOf(a)
      const bIndex = drawerByPosition?.['left-side-bar']?.indexOf(b)
      return (
        drawerOptions?.[a]?.index ??
        aIndex - drawerOptions?.[b]?.index ??
        bIndex
      )
    })
  }, [drawerByPosition, drawerOptions])

  const sortedRightDrawerIds = React.useMemo(
    () =>
      drawerByPosition?.['right-side-bar']?.sort((a, b) => {
        const aIndex = drawerByPosition?.['right-side-bar']?.indexOf(a)
        const bIndex = drawerByPosition?.['right-side-bar']?.indexOf(b)
        return (
          drawerOptions?.[a]?.index ??
          aIndex - drawerOptions?.[b]?.index ??
          bIndex
        )
      }),
    [drawerByPosition, drawerOptions]
  )

  const setDrawerTab = (
    DrawerTabIndex: number,
    DrawerPosition: 'right' | 'left' | 'none',
    isMobile: boolean
  ) => {
    if (DrawerPosition === 'right') {
      dispatch({
        type: MapEditorActionTypes.SET_DRAWER_TAB_INDEX,
        page: pageType,
        payload: { rightDrawerTabIndex: DrawerTabIndex },
      })
      dispatch({
        type: MapEditorActionTypes.SET_DRAWER,
        page: pageType,
        payload: {
          rightDrawerOpen: true,
          leftDrawerOpen: isMobile ? false : undefined,
        },
      })
    } else if (DrawerPosition === 'left') {
      dispatch({
        type: MapEditorActionTypes.SET_DRAWER_TAB_INDEX,
        page: pageType,
        payload: { leftDrawerTabIndex: DrawerTabIndex },
      })
      dispatch({
        type: MapEditorActionTypes.SET_DRAWER,
        page: pageType,
        payload: {
          leftDrawerOpen: true,
          rightDrawerOpen: isMobile ? false : undefined,
        },
      })
    }
  }

  const closeAllDrawers = () => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER,
      page: pageType,
      payload: {
        leftDrawerOpen: false,
        rightDrawerOpen: false,
      },
    })
  }

  React.useEffect(() => {
    if (isMobile && leftDrawerOpen && rightDrawerOpen) {
      dispatch({
        type: MapEditorActionTypes.SET_DRAWER,
        page: pageType,
        payload: {
          leftDrawerOpen: true,
          rightDrawerOpen: false,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  return (
    <DrawerContainer id="side-bar-shell">
      {(drawerByPosition?.['left-side-bar']?.length ??
        0 + drawerByPosition?.['undefined']?.length ??
        0) > 1 && (
        <Drawer id="left-side-bar" onClick={(e) => e.stopPropagation()}>
          {sortedLeftDrawerIds?.map((DrawerOptionId, i) => (
            <DrawerTab
              side="left"
              selected={i === leftDrawerTabIndex}
              key={DrawerOptionId}
              onClick={() => setDrawerTab(i, 'left', isMobile)}
            >
              {drawerComponents[DrawerOptionId].icon(
                drawerOptions[DrawerOptionId],
                pageType
              )}
            </DrawerTab>
          ))}
        </Drawer>
      )}
      <InnerWindow
        isMobile={isMobile}
        onClick={() => {
          isMobile && closeAllDrawers()
        }}
        DrawerOpen={!!leftDrawerOpen || !!rightDrawerOpen}
      >
        <ContainedDrawer
          id="left-drawer"
          open={leftDrawerOpen}
          drawerWidth={leftDrawerWidth}
          // DrawerWidth={DrawerWidths?.left}
          anchor="left"
          onClick={(e) => e.stopPropagation()}
        >
          {sortedLeftDrawerIds?.map((DrawerOptionId, i) => (
            <DrawerTabContent
              side="left"
              selected={i === leftDrawerTabIndex}
              key={DrawerOptionId}
            >
              {drawerComponents[DrawerOptionId].component(
                drawerOptions[DrawerOptionId],
                pageType
              )}
            </DrawerTabContent>
          ))}
        </ContainedDrawer>
        <ContainedDrawer
          id="right-drawer"
          open={rightDrawerOpen}
          drawerWidth={rightDrawerWidth}
          anchor="right"
          onClick={(e) => e.stopPropagation()}
        >
          {sortedRightDrawerIds?.map((DrawerOptionId, i) => (
            <DrawerTabContent
              side="right"
              selected={i === rightDrawerTabIndex}
              key={DrawerOptionId}
            >
              {drawerComponents[DrawerOptionId].component(
                drawerOptions[DrawerOptionId],
                pageType
              )}
            </DrawerTabContent>
          ))}
        </ContainedDrawer>
      </InnerWindow>
      {sortedRightDrawerIds?.length > 1 && (
        <Drawer id="right-side-bar" onClick={(e) => e.stopPropagation()}>
          {sortedRightDrawerIds?.map((DrawerOptionId, i) => (
            <DrawerTab
              side="right"
              selected={i === rightDrawerTabIndex}
              key={DrawerOptionId}
              onClick={() => setDrawerTab(i, 'right', isMobile)}
            >
              {drawerComponents[DrawerOptionId].icon(
                drawerOptions[DrawerOptionId],
                pageType
              )}
            </DrawerTab>
          ))}
        </Drawer>
      )}
    </DrawerContainer>
  )
}
