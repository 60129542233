import centroid from '@turf/centroid'
import { MapboxGL } from '../../../map/MapboxGL'
import queue from '../../../util/queue'
import { ActualMapboxOptions, MapImageProps } from './types'
import * as mapboxgl from 'mapbox-gl'
import { getApiUrl, getAuthToken } from '../../../vvapi/apiResource/createApiResource'

const getSrc = async (props: MapImageProps) => {
  const { container, layers: sourcesAndLayers, onStyleLoaded } = props

  if (!container || !sourcesAndLayers) {
    return null
  }

  const map = createMap(props)

  await whenStyleLoaded(map, onStyleLoaded)

  const { sources, layers } = sourcesAndLayers

  const layersLoaded = whenLayersLoaded(map)

  Object.keys(sources).forEach((key) => {
    // as any because source could be any mapbox source, but the
    // type field of each are incompatible
    return map.addSource(key, sources[key] as any)
  })

  layers.forEach((layer) => map.addLayer(layer as MapboxGL.AnyLayer))

  await layersLoaded

  const src = map.getCanvas().toDataURL()

  map.remove()

  return src
}

const whenStyleLoaded = (map: MapboxGL.Map, onStyleLoaded?: (map: mapboxgl.Map) => Promise<void | void[]>) => {
  return new Promise<void>((resolve) => {
    const onStyleData = async (event: MapboxGL.MapDataEvent) => {
      if (event.dataType === 'style') {
        await onStyleLoaded?.(map)
        resolve()
        map.off('styledata', onStyleData)
      }
    }

    map.on('styledata', onStyleData)
  })
}

/**
 * huge function to make up for the fact that mapbox does not properly handle
 * onload events or idle events
 */
const whenLayersLoaded = (map: MapboxGL.Map) => {
  return new Promise<void>((resolve) => {
    const finished = () => {
      removeEvents()
      resolve()
    }

    const removeEvents = () => {
      map.off('data', debounce)
      map.off('render', debounce)
      map.off('error', noop)
      clearTimeout(t)
    }

    let t: NodeJS.Timer

    /**
     * debounce function to check if map is loaded after requests/events stop
     */
    const debounce = () => {
      clearTimeout(t)
      // debounce timeout onload check
      t = setTimeout(() => {
        // RAF is an optimization to pause onload checks when tab is inactive
        requestAnimationFrame(() => {
          if (map.loaded()) {
            finished()
          } else {
            debounce()
          }
        })
      }, 100)
    }

    // add events
    map.on('data', debounce)
    map.on('render', debounce)

    // tslint:disable-next-line: no-console
    const noop = console.warn

    // ignore errors
    map.on('error', noop)
  })
}

const createMap = ({ center, bounds, container, style }: MapImageProps) => {
  if (!center) {
    // tslint:disable-next-line:no-parameter-reassignment
    center = getCenter(bounds)
  }

  const fitBoundsOptions: MapboxGL.FitBoundsOptions = {
    padding: 60,
    duration: 0,
  }

  const map = new MapboxGL.Map({
    container,
    style,
    center,
    fitBoundsOptions,
    attributionControl: false,
    interactive: false,
    preserveDrawingBuffer: true,
    fadeDuration: 0,
    transformRequest: (url: string) => {
      if (url.startsWith(getApiUrl() ?? '')) {
        return {
          url: url,
          headers: {
            authorization: `${getAuthToken()}`,
          },
        }
      }
      return {
        url
      }
    }
  } as ActualMapboxOptions)

  if (bounds) {
    map.fitBounds(bounds as MapboxGL.LngLatBoundsLike, fitBoundsOptions)
  }

  return map
}

const getCenter = (bounds?: number[][]): [number, number] => {
  if (!bounds) {
    return [0, 0]
  }

  return centroid({
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [[...bounds, bounds[0]]],
    },
  }).geometry.coordinates as [number, number]
}

export default queue(getSrc, 6)
