import { createSelector } from "reselect"
import { selectOrderPackageSelection } from "./selectOrderPackageSelection"

export const selectMostRecentTargetDeliveryUpdatedAtOrCreatedAt = createSelector(selectOrderPackageSelection, (orderPackageSelection) =>
  orderPackageSelection?.data?.order?.targetDeliveries?.reduce((mostRecent, next) => {
    const updatedAt = next.updatedAt ? Date.parse(next.updatedAt) : undefined
    const createdAt = next.createdAt ? Date.parse(next.createdAt) : undefined

    const operativeDate = updatedAt ?? createdAt
    if (mostRecent === undefined) {
      return operativeDate
    }
    if (operativeDate) {
      return Math.max(mostRecent, operativeDate)
    }

    return mostRecent

  }, undefined)
)