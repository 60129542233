// import './OrganizationSettings.scss'

import * as React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import {
  Container,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import Page from '../../app/Page'
import { url, urls } from '../../appNavigation/urls'
import { refreshGetMe, selectMe } from '../../data/selectMe'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import { CollapseDrawer } from '../../UI/CollapseDrawer'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import OrganizationParcels from './Fields/OrganizationParcels'
import { refreshListOrganizationParcels } from './Fields/selectOrganizationParcels'
import { OrganizationUsers } from './Members/OrganizationUsers'
import { refreshListOrganizationRoles } from './Members/selectOrganizationRoles'
import { refreshListOrganizationUsersAndInvites } from './Members/selectUsersAndInvites'
import { selectOrganization } from './selectOrganization'
import {
  OrganizationSubscription,
  RefreshableRef,
} from './Subscription/OrganizationSubscription'
import { Row } from '../../admin/UI/Row'
import { Column } from '../../admin/UI/Column'

type OrganizationSettingsTab = 'members' | 'fields' | 'manage-subscription'
const TABS: OrganizationSettingsTab[] = [
  'members',
  'fields',
  'manage-subscription',
]
const tabToIcon: Record<OrganizationSettingsTab, string> = {
  members: 'group',
  fields: 'architecture',
  'manage-subscription': 'receipt',
}

export const OrganizationSettings = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [state] = useRedux()
  const subsRef = React.useRef<RefreshableRef | null>(null)

  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  const theme = useTheme()

  const me = selectMe(state)
  const organization = selectOrganization(state)

  const getCurrentTab = (): OrganizationSettingsTab => {
    return location.pathname
      .slice(match.url.length + 1)
      .split('/')[0] as OrganizationSettingsTab
  }
  const selectedTab = getCurrentTab()

  const handleDrawerClose = () => {
    setDrawerOpen(!drawerOpen)
  }

  const handleRefresh = () => {
    if (selectedTab === 'members') {
      refreshGetMe()
      refreshListOrganizationUsersAndInvites()
      refreshListOrganizationRoles()
    }
    if (selectedTab === 'fields') {
      refreshListOrganizationParcels()
    }
    if (selectedTab === 'manage-subscription') {
      if (subsRef.current) {
        subsRef.current.refresh()
      }
    }
  }

  const setTab = (tab: string) => {
    history.replace(`${match.url}/${tab.replace(' ', '-')}`)
  }

  if (!me) {
    return <></>
  }

  return (
    <Page
      title={i18n.t(selectedTab)}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <Row
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'stretch',
          justifyContent: 'stretch',
          marginTop: 10,
        }}
      >
        {me.orgRoles?.includes('org-owner') || me.roles.length > 0 ? (
          <>
            <CollapseDrawer
              variant="permanent"
              open={drawerOpen}
              className={`drawer ${drawerOpen ? 'open' : 'closed'}`}
            >
              <Toolbar variant="dense" />
              <List disablePadding>
                <ListItem key="collapse" disablePadding>
                  <ListItemButton onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon
                        sx={{
                          transition: (theme) =>
                            `transform ${theme.transitions.duration.standard}ms`,
                          transform: drawerOpen ? undefined : 'rotate(180deg)',
                        }}
                      >
                        chevron_left
                      </Icon>
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                <Divider className="divider" />

                {TABS.map((tab) => (
                  <ListItem disablePadding key={tab}>
                    <Tooltip title={i18n.t(tab)} placement="right">
                      <ListItemButton
                        selected={selectedTab === tab}
                        onClick={() => setTab(tab)}
                      >
                        <ListItemIcon className="icon">
                          <Icon>{tabToIcon[tab]}</Icon>
                        </ListItemIcon>
                        <ListItemText
                          className="icon-text"
                          primary={<Typography>{i18n.t(tab)}</Typography>}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </CollapseDrawer>
            <Container sx={{ height: 'fill-available' }}>
              <EnhancedTableToolbar
                style={{
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}
                title={`${organization?.name ?? ''} ›
            ${i18n.t(selectedTab)}`}
                numSelected={0}
                onClickRefresh={handleRefresh}
              />
              <Paper sx={{ padding: theme.spacing(2) }}>
                <Column style={{ height: '100%', alignItems: 'stretch' }}>
                  <Switch>
                    <Route
                      exact
                      path={`${match.path}/members`}
                      component={OrganizationUsers}
                    />
                    <Route
                      exact
                      path={`${match.path}/fields`}
                      component={OrganizationParcels}
                    />
                    <Route exact path={`${match.path}/manage-subscription`}>
                      <OrganizationSubscription ref={subsRef} />
                    </Route>
                    <Route exact path={`${match.path}`}>
                      <Redirect to={`${match.url}/members`} />
                    </Route>
                  </Switch>
                </Column>
              </Paper>
            </Container>
          </>
        ) : (
          me.orgRoles !== undefined && (
            <Typography>{i18n.t(keys.manageOrgNotPermitted)}</Typography>
          )
        )}
        {/* </Paper> */}
      </Row>
    </Page>
  )
}
