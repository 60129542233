import { createTableFetcher } from '../../../../UI/Table/createTableFetcher'
import { ListOrdersData } from '../selectListOrders'
import { tableFormatter } from './orderListTableFormatter'
import * as api from '../../../../graphql/api'

const fetchOrderList = (orgId?: string) => {
  return createTableFetcher<ListOrdersData>(
      tableFormatter,
      `{
      id
      comment
      createdAt
      updatedAt
      CreatedBy {
          firstName
          lastName
          email
      }
      UpdatedBy {
          firstName
          lastName
          email
      }
      TargetDeliveries_aggregate {
          aggregate {
          max {
              date
          }
        }
      } 
    }`,
      api.order.list,
      '_or',
      {
        organizationId: {
          _eq: orgId,
        },
      }
    )
}

export { fetchOrderList }
