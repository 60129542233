import { createSelector } from 'reselect'
import { selectReportGroup } from './selectReportGroup'
import { selectDeliveryParcelsByGroupId } from '../data/selectDelivery'

export const selectAllReportMapLayers = createSelector(
  selectReportGroup,
  selectDeliveryParcelsByGroupId,
  (group, parcels) => {
    if (!group) {
      return []
    }

    return parcels[group.id]
      ?.map(({ mapLayers }) => mapLayers)
      .reduce((acc, next) => [...acc, ...next], [])
  }
)
