import { default as areaInSquareMeter } from '@turf/area'

import { formatNum } from '../util/formatNumber'
import { convertArea } from '../util/units/area'
import { UnitSystem } from '../util/units/unitSystem'
import { OrgFeatureGeometry } from '../vvapi/models'

const areaUserString = (
  geometry: OrgFeatureGeometry | undefined,
  preferredUnitSystem: UnitSystem,
  fractionDigits = 2
) => {
  if (!preferredUnitSystem || !geometry || geometry.type !== 'Polygon') {
    return ''
  }

  const measurement = convertArea(
    areaInSquareMeter(geometry),
    'squareMeter',
    preferredUnitSystem
  )

  return `${formatNum(measurement.value, false, 1, fractionDigits)} ${
    measurement.symbol
  }`
}

export default areaUserString
