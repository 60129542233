import { createSelector } from 'reselect'

import { selectMapLayerDefsById } from '../data/selectListMapSourceDefs'
import { MapLayerData } from '../data/types'
import i18n, { keys } from '../i18n'
import { selectProductVisualizations } from '../ProductSettings/store/selectors/selectProductVisualizations'
import { VisualizationMode } from '../ProductSettings/store/types'
import filterTruthy from '../util/filterTruthy'
import { indexArrayMappedKey } from '../util/indexArray'
import { selectReportMapLayers } from './selectReportMapLayers'
import { selectReportsPageParams } from './selectReportsPageParams'
import { ProductPage } from './types'
import { selectSelectedDeliveryGroupParcels } from '../data/selectSelectedGroup'

type PageVisualizationModes = Exclude<VisualizationMode, 'threshold'>

const EMPTY_PAGES: ProductPage[] = []

export const selectReportPages = createSelector(
  selectReportsPageParams,
  selectMapLayerDefsById,
  selectReportMapLayers,
  selectProductVisualizations,
  selectSelectedDeliveryGroupParcels,
  (
    { reportProducts, reportProductOptions, includeRelative },
    productsById,
    { reportMapLayers },
    productVisualizations,
    reportParcels
  ) => {
    if (reportMapLayers.length === 0) {
      return EMPTY_PAGES
    }

    const modeFilter = (
      mode: PageVisualizationModes
    ): mode is PageVisualizationModes =>
      includeRelative
        ? ['absolute', 'relative'].includes(mode)
        : mode === 'absolute'

    const products = reportProducts
      .map((id) => productsById[id])
      .filter(filterTruthy)

    // 1. get full-group product pages
    const productPages: ProductPage[] = products
      .filter((product) => {
        const options = reportProductOptions?.[product.id]! ?? {}

        return options.hasGroupPage
      })
      .reduce((prev, product) => {
        // background products do not have a mode
        if (
          product.mapSourceDef.type === 'raster' ||
          product.mapSourceDef.type === 'raster-background'
        ) {
          return [
            ...prev,
            {
              product,
              title: product.name,
              parcel: null,
            },
          ]
        }

        const modes = (productVisualizations[product.id] ?? []).filter(
          modeFilter
        )

        // non-background products MUST have a mode that is
        // either absolute or relative
        return [
          ...prev,
          ...modes.map((mode) => ({
            product,
            mode,
            title: `${product.name} - ${i18n.t(keys.visualization[mode])}`,
            parcel: null,
          })),
        ]
      }, [])

    // 2. get block-by-block pages
    const blockProducts = reportProducts.filter((productId) => {
      const options = reportProductOptions?.[productId]! ?? {}

      return options.hasBlockPage
    })

    // const reportParcels = reportGroup?.parcels ?? []

    if (blockProducts.length > 0 && reportParcels) {
      const indexedReportParcels = indexArrayMappedKey(
        reportParcels,
        ({ deliveryId, parcelId }) => `${deliveryId}/${parcelId}`
      )

      const getBlockPage = ({
        mode,
        productId,
        layer,
      }: {
        mode?: PageVisualizationModes
        productId: string
        layer: MapLayerData
      }): ProductPage | null => {
        if (!layer.mapSource.parcel) {
          return null
        }

        const { deliveryId, parcelId } = layer.mapSource.parcel
        const parcel = indexedReportParcels[`${deliveryId}/${parcelId}`]
        const product = productsById[productId]

        if (!product || !parcel) {
          return null
        }

        const title = mode
          ? `${parcel.name} ${product.name} - ${i18n.t(
              keys.visualization[mode]
            )}`
          : `${parcel.name} ${product.name}`

        return {
          product,
          mode,
          parcel,
          title,
        }
      }

      // for every mode there's a page
      const iterateModes = (
        productId: string,
        layer: MapLayerData
      ): ProductPage[] => {
        const modes = (productVisualizations[productId] ?? []).filter(
          modeFilter
        )

        return modes
          .map((mode) => getBlockPage({ mode, productId, layer }))
          .filter(filterTruthy)
      }

      const iterateMapLayers = (
        layers: typeof reportMapLayers,
        productId: string
      ) =>
        layers.reduce((prev, layer) => {
          const product = productsById[productId]

          if (!product) {
            return prev
          }

          if (
            product.mapSourceDef.type === 'raster' ||
            product.mapSourceDef.type === 'raster-background'
          ) {
            // rasters do not have modes
            const page = getBlockPage({
              productId,
              layer,
            })

            return page ? [...prev, page] : prev
          }

          return [...prev, ...iterateModes(productId, layer)]
        }, [])

      const blockPages = blockProducts.reduce((prev, productId) => {
        const productLayers = reportMapLayers.filter(
          (layer) => layer.mapLayerDefId === productId
        )

        return [...prev, ...iterateMapLayers(productLayers, productId)]
      }, [])

      productPages.push(...blockPages)
    }

    return productPages
  }
)
