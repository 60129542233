import { Theme } from '@mui/material/styles'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import * as React from 'react'
import { PureComponent } from 'react'
import LayerDrawerSubheader from '../postgis/LayerDrawer/LayerDrawerSubheader'
import { selectSelectedProduct } from '../postgis/selectSelectedProduct'
import ColorProfileSelect from '../ProductSettings/UI/ColorProfileSelect'
import Opacity from '../ProductSettings/UI/Opacity'
import Visualization from '../ProductSettings/UI/Visualization'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'

class ColorSchemeList extends PureComponent<
  ReduxProps & AppDispatchProps & WithStyles<typeof styles>
> {
  render() {
    const { classes, selectedProduct } = this.props

    if (!selectedProduct) {
      return null
    }

    return (
      <React.Fragment>
        <div>
          <LayerDrawerSubheader>{selectedProduct.name}</LayerDrawerSubheader>
          <div className={classes.sliderContainer}>
            <Opacity />
          </div>
        </div>

        <ColorProfileSelect />

        <div className={classes.sliderContainer}>
          <Visualization />
        </div>
      </React.Fragment>
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    sliderContainer: {
      margin: 0,
      padding: 30,
    },
  })

const mapState = (state: RootStore) => ({
  selectedProduct: selectSelectedProduct(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(ColorSchemeList)
)
