import * as React from 'react'
import i18n, { keys } from '../../i18n'
import { Project } from '../../graphql/types'
import { ConfirmationModal } from '../../app/ConfirmationModal/ConfirmationModal'
import { DeleteProjectForm } from './DeleteProjectForm'

interface Props {
  open: boolean
  onCancel: () => void
  onProjectDelete: (project: Project) => void
  project: Project
}

export const DeleteProjectModal = ({
  open,
  onCancel,
  onProjectDelete,
  project,
}: Props) => {
  return (
    <ConfirmationModal
      open={open}
      title={i18n.t(keys.deleteProjectLabel)}
      message={
        <DeleteProjectForm
          projectNoteCount={project?.Notes_aggregate?.aggregate?.count ?? 0}
          projectName={project?.name ?? ''}
        />
      }
      onConfirm={() => onProjectDelete(project)}
      onCancel={onCancel}
      dangerMode
    />
  )
}
