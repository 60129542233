import { Typography } from '@mui/material'

import * as React from 'react'

interface Props {
  state?: 'running' | 'pending' | 'not-running'
}

export class MigrationRunState extends React.PureComponent<Props> {
  getStatusLabel(state: Props['state']) {
    switch (state) {
      case 'running':
        return 'Running'
      case 'not-running':
        return 'Not Running'
      case 'pending':
        return 'Pending'
      default:
        return 'Unknown'
    }
  }
  render() {
    const { state } = this.props
    const statusLabel = this.getStatusLabel(state)

    return (
      <div className="flex" style={{ paddingLeft: 24, paddingRight: 12 }}>
        <Typography>
          Migration State:
          <span style={{ fontWeight: 'bold' }}>{` ${statusLabel}`}</span>
        </Typography>
      </div>
    )
  }
}
