import { setFocusedPolygon } from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { FocusedPolygon } from '../../../../postgis/types'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class BlockByBlockActionGenerator implements IMapActionGenerator {
  key = 'block-by-block'
  priority = Priority.BlockByBlock

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
    mapLayers,
    state,
  }: IMapActionGeneratorParams): IMapAction[] {
    if (state.notes.editingNoteId !== undefined) {
      return []
    }
    // Find the qualifying features.
    const qualifiers = features.filter((f: MapboxGL.MapboxGeoJSONFeature) =>
      f.layer.id.endsWith('-block-by-block-layer')
    )

    // Return functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        const layerId = q?.layer.id?.replace('-block-by-block-layer', '')
        const layer = mapLayers[layerId]

        const focusedPolygon: Writable<FocusedPolygon> = {
          type: 'Feature',
          geometry: q.geometry as GeoJSON.Polygon,
          properties: {
            ...q.properties,
            mapSourceId: layer.mapSourceId,
          } as FocusedPolygon['properties'],
        }

        dispatch(setFocusedPolygon(focusedPolygon))
      },
    }))
  }
}
