import './DeliveryProcGroup.scss'

import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { faMask } from '@fortawesome/free-solid-svg-icons/faMask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import { Row } from '../../../../admin/UI/Row'
import { url, urls } from '../../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import useAsync from '../../../../hooks/useAsync'
import { useRedux } from '../../../../hooks/useRedux'
import { CollapseDrawer } from '../../../../UI/CollapseDrawer'
import LinkChild from '../../../../UI/LinkChild'
import { postJson } from '../../../../vvapi/apiResource/createApiResource'
import { DeliveryProcGroupAssignee } from './DeliveryProcGroupAssignee'
import { DeliveryProcGroupComments } from './DeliveryProcGroupComments'
import { DeliveryProcGroupKato } from './DeliveryProcGroupKato'
import { DeliveryProcGroupLayers } from './DeliveryProcGroupLayers'
import { DeliveryProcGroupMapQA } from './DeliveryProcGroupMapQA'
import {
  DeliveryProcGroupWithNeighborsAndJobStatuses,
  refreshGetDeliveryProcGroup,
  selectDeliveryProcGroup,
  selectGetDeliveryProcGroup,
} from './deliveryProcGroupSelectors'
import { setSelectedProcGroupLayersEnabled } from '../enableLayers'
import { fetchDeliveryProcGroupComments } from '../fetchDeliveryProcGroupComments'
import {
  fetchParcelsAndBounds,
  ParcelsAndBounds,
} from '../fetchParcelsAndBounds'
import { toggleWatchDeliveryProcGroup } from '../toggleWatchDeliveryProcGroup'
import { Watchers } from '../Watchers'
import TooltipIconButton from '../../../../UI/TooltipIconButton'
import { ProcGroupDetails } from './ProcGroupDetails'
import { setDeliveryProcGroupQACompleteStatus } from './setDeliveryProcGroupQACompleteStatus'
import OrderPage from '../../Order/OrderPage'
import { MenuButton } from '../../../../UI/MenuButton'
import { selectMe } from '../../../../data/selectMe'

type ProcGroupTab = 'kato' | 'map-qa' | 'layers' | 'comments'

const getDeliveryProcGroupTitle = (
  procGroup?: DeliveryProcGroupWithNeighborsAndJobStatuses
) => {
  if (procGroup?.Delivery?.comment) {
    return `${procGroup?.Delivery?.Organization?.name ?? ''}
  - ${procGroup?.Delivery?.Order?.comment ?? ''} 
  - ${procGroup?.Delivery?.comment ?? ''} 
  - ${procGroup?.Delivery?.TargetDelivery?.date ?? ''}`
  } else {
    return `${procGroup?.Delivery?.Organization?.name ?? ''}
  - ${procGroup?.Delivery?.Order?.comment ?? ''}
  - ${procGroup?.Delivery?.TargetDelivery?.date ?? ''}`
  }
}

export const DeliveryProcGroup = () => {
  const [state] = useRedux()
  const params = useParams<{
    tab: ProcGroupTab
    deliveryId: string
    procGroup: string
  }>()
  const [sidebarOpen, setSidebarOpen] = React.useState(false)
  const history = useHistory()
  const [isLoading, setIsLoading] = React.useState(false)
  const [mapViewExpanded, setMapViewExpanded] = React.useState(false)

  const [parcelsAndBoundsFetcher, refreshParcelsAndBounds] = useAsync<
    (deps: [string, string]) => Promise<ParcelsAndBounds>
  >(
    ([deliveryId, procGroup]) => fetchParcelsAndBounds(deliveryId, procGroup),
    [params.deliveryId, params.procGroup]
  )

  const deliveryProcGroupCommentsFetch = useAsync(
    ([deliveryId, procGroup]) =>
      fetchDeliveryProcGroupComments(deliveryId, procGroup),
    [params.deliveryId, params.procGroup]
  )

  const [deliveryProcGroupCommentsFetcher, refreshComments] =
    deliveryProcGroupCommentsFetch

  const theme = useTheme()
  const procGroup = selectDeliveryProcGroup(state)
  const procGroupSelector = selectGetDeliveryProcGroup(state)
  const me = selectMe(state)

  const handleSetLayersEnabled = async (
    disabledLayers: string[],
    enabledLayers: string[]
  ) => {
    setIsLoading(true)
    await setSelectedProcGroupLayersEnabled({
      deliveryId: params.deliveryId,
      procGroup: params.procGroup,
      selectedLayerDefs: disabledLayers,
      enabled: false,
    })
    await setSelectedProcGroupLayersEnabled({
      deliveryId: params.deliveryId,
      procGroup: params.procGroup,
      selectedLayerDefs: enabledLayers,
      enabled: true,
    })
    setIsLoading(false)

    handleRefresh()
  }

  const renderContent = () => {
    switch (params.tab) {
      case 'kato':
        return <DeliveryProcGroupKato setIsLoading={setIsLoading} />
      case 'map-qa':
        return (
          <DeliveryProcGroupMapQA
            mapViewExpanded={mapViewExpanded}
            setMapViewExpanded={setMapViewExpanded}
            parcelsAndBoundsFetcher={parcelsAndBoundsFetcher}
            onRefresh={handleRefresh}
          />
        )
      case 'layers':
        return (
          <DeliveryProcGroupLayers
            setLayersEnabled={handleSetLayersEnabled}
            parcelsAndBoundsFetcher={parcelsAndBoundsFetcher}
          />
        )
      case 'comments':
        return (
          <DeliveryProcGroupComments
            setIsLoading={setIsLoading}
            deliveryProcGroupCommentsFetch={deliveryProcGroupCommentsFetch}
          />
        )
      default:
        return null
    }
  }

  const handleRefresh = () => {
    refreshGetDeliveryProcGroup()
    refreshParcelsAndBounds()
    refreshComments()
  }

  const handleToggleWatch = async (userId: string, watch: boolean) => {
    if (!procGroup) {
      return
    }

    await toggleWatchDeliveryProcGroup(
      procGroup.deliveryId,
      procGroup.procGroup,
      userId,
      watch
    )

    handleRefresh()
  }

  const handleAssignProcGroup = async (assignee?: number) => {
    if (!procGroup) {
      return
    }

    if (!assignee) {
      await postJson(
        `/api/v3/delivery-procgroup/${procGroup.deliveryId}/${procGroup.procGroup}/unassign`
      )
    } else {
      await postJson(
        `/api/v3/delivery-procgroup/${procGroup.deliveryId}/${procGroup.procGroup}/assign/${assignee}`
      )
    }

    handleRefresh()
  }

  const showExpanded = mapViewExpanded && params.tab === 'map-qa'

  return (
    <OrderPage
      backTo={
        procGroup &&
        `${url(urls.editDelivery, {
          deliveryId: procGroup.deliveryId,
        })}/proc-groups`
      }
      backToTitle="Delivery"
    >
      <Row
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'stretch',
          justifyContent: 'stretch',
          paddingTop: 10,
        }}
      >
        <CollapseDrawer open={sidebarOpen} variant="permanent">
          <Toolbar variant="dense" />
          <List disablePadding>
            <ListItem key="collapse" disablePadding>
              <ListItemButton onClick={() => setSidebarOpen(!sidebarOpen)}>
                <ListItemIcon>
                  <Icon
                    sx={{
                      transition: (theme) =>
                        `transform ${theme.transitions.duration.standard}ms`,
                      transform: sidebarOpen ? undefined : 'rotate(180deg)',
                    }}
                  >
                    chevron_left
                  </Icon>
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <Divider className="divider" />
            <ListItem key="Kato" disablePadding>
              <Tooltip title={sidebarOpen ? '' : 'Kato'} placement="right">
                <ListItemButton
                  onClick={() =>
                    history.replace(
                      url(urls.deliveryProcGroup, { ...params, tab: 'kato' })
                    )
                  }
                  selected={params.tab === 'kato'}
                >
                  <ListItemIcon sx={{ paddingLeft: 1 / 2 }}>
                    <FontAwesomeIcon icon={faMask} />
                  </ListItemIcon>
                  <ListItemText primary="Kato" />
                </ListItemButton>
              </Tooltip>
            </ListItem>
            <ListItem key="Map QA" disablePadding>
              <Tooltip title={sidebarOpen ? '' : 'Map QA'} placement="right">
                <ListItemButton
                  onClick={() =>
                    history.replace(
                      url(urls.deliveryProcGroup, {
                        ...params,
                        tab: 'map-qa',
                      })
                    )
                  }
                  selected={params.tab === 'map-qa'}
                >
                  <ListItemIcon sx={{ paddingLeft: 1 / 4 }}>
                    <Icon sx={{ fontSize: '20px !important' }}>map</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Map QA" />
                </ListItemButton>
              </Tooltip>
            </ListItem>
            <ListItem key="Layers" disablePadding>
              <Tooltip title={sidebarOpen ? '' : 'Layers'} placement="right">
                <ListItemButton
                  onClick={() =>
                    history.replace(
                      url(urls.deliveryProcGroup, { ...params, tab: 'layers' })
                    )
                  }
                  selected={params.tab === 'layers'}
                >
                  <ListItemIcon sx={{ paddingLeft: 1 / 4 }}>
                    <Icon sx={{ fontSize: '20px !important' }}>layers</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Layers" />
                </ListItemButton>
              </Tooltip>
            </ListItem>
            <ListItem key="Comments" disablePadding>
              <Tooltip title={sidebarOpen ? '' : 'Comments'} placement="right">
                <ListItemButton
                  onClick={() =>
                    history.replace(
                      url(urls.deliveryProcGroup, {
                        ...params,
                        tab: 'comments',
                      })
                    )
                  }
                  selected={params.tab === 'comments'}
                >
                  <ListItemIcon sx={{ paddingLeft: 1 / 4 }}>
                    <Badge
                      color="warning"
                      badgeContent={
                        deliveryProcGroupCommentsFetch[0]?.result?.length
                      }
                    >
                      <Icon sx={{ fontSize: '20px !important' }}>chat</Icon>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="Comments" />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </CollapseDrawer>
        <Container
          sx={{ height: 'fill-available' }}
          maxWidth={showExpanded ? 'xl' : undefined}
        >
          <Stack
            sx={{ height: '100%', paddingBottom: 1, overflowY: 'auto' }}
            spacing={1}
          >
            {!showExpanded && (
              <Paper sx={{ padding: theme.spacing(2) }}>
                <Stack spacing={1}>
                  <Row
                    style={{
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {procGroup?.prevProcGroup ? (
                      <LinkChild
                        disabled={!procGroup || !procGroup.prevProcGroup}
                        to={url(urls.deliveryProcGroup, {
                          ...params,
                          deliveryId: procGroup.deliveryId,
                          procGroup: procGroup.prevProcGroup.procGroup,
                        })}
                      >
                        <Button color="primary" variant="outlined" size="small">
                          <Icon
                            fontSize="small"
                            style={{ marginRight: 3, fontSize: 12 }}
                          >
                            arrow_back
                          </Icon>
                          Previous
                        </Button>
                      </LinkChild>
                    ) : (
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled
                        size="small"
                      >
                        <Icon
                          fontSize="small"
                          style={{ marginRight: 3, fontSize: 12 }}
                        >
                          arrow_back
                        </Icon>
                        Previous
                      </Button>
                    )}
                    {procGroup?.nextProcGroup ? (
                      <LinkChild
                        disabled={!procGroup || !procGroup.nextProcGroup}
                        to={url(urls.deliveryProcGroup, {
                          ...params,
                          deliveryId: procGroup.deliveryId,
                          procGroup: procGroup.nextProcGroup.procGroup,
                        })}
                      >
                        <Button color="primary" variant="outlined" size="small">
                          Next{' '}
                          <Icon
                            fontSize="small"
                            style={{ marginLeft: 3, fontSize: 12 }}
                          >
                            arrow_forward
                          </Icon>
                        </Button>
                      </LinkChild>
                    ) : (
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled
                        size="small"
                      >
                        Next{' '}
                        <Icon
                          fontSize="small"
                          style={{ marginLeft: 3, fontSize: 12 }}
                        >
                          arrow_forward
                        </Icon>
                      </Button>
                    )}
                  </Row>
                  <Grid container className="flex">
                    <Grid item xs={6} sm={3}>
                      <Stack>
                        <Typography variant="h6">{`${
                          procGroup?.groupName ?? ''
                        } - ${procGroup?.procGroup ?? ''}`}</Typography>
                        <Typography variant="subtitle1">
                          {getDeliveryProcGroupTitle(procGroup)}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={5} sm={8}>
                      {procGroup && <ProcGroupDetails procGroup={procGroup} />}
                    </Grid>
                    <Grid item xs={1}>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <React.Fragment>
                          <Row>
                            <TooltipIconButton
                              title={`Refresh`}
                              onClick={() => handleRefresh()}
                            >
                              <Icon>autorenew</Icon>
                            </TooltipIconButton>
                          </Row>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Stack direction="row" justifyContent="space-between">
                    <Watchers
                      watched={procGroup}
                      onToggleWatch={handleToggleWatch}
                    />
                    {procGroup && (
                      <DeliveryProcGroupAssignee
                        procGroup={procGroup}
                        onAssign={handleAssignProcGroup}
                      />
                    )}
                    <Stack alignItems="center">
                      <Typography variant="caption">QA Status:</Typography>
                      <MenuButton
                        variant="outlined"
                        disabled={
                          procGroup?.UploadJob?.Status?.status !== 'complete' ||
                          !me?.roles.includes('admin')
                        }
                        color={
                          procGroup?.qaStatus === 'failed' ? 'error' : undefined
                        }
                        size="small"
                        title={
                          procGroup?.qaStatus === 'complete'
                            ? 'Reviewed'
                            : procGroup?.UploadJob?.Status?.status !==
                              'complete'
                            ? 'Pending Upload'
                            : procGroup?.qaStatus === 'not-reviewed'
                            ? 'Requires Review'
                            : procGroup?.qaStatus === 'failed'
                            ? 'QA Failed'
                            : ''
                        }
                      >
                        <MenuItem
                          onClick={async () => {
                            if (!procGroup) {
                              return
                            }

                            await setDeliveryProcGroupQACompleteStatus(
                              procGroup,
                              'complete'
                            )
                            handleRefresh()
                          }}
                        >
                          Approve QA
                        </MenuItem>
                        <MenuItem
                          onClick={async () => {
                            if (!procGroup) {
                              return
                            }

                            await setDeliveryProcGroupQACompleteStatus(
                              procGroup,
                              'failed'
                            )
                            handleRefresh()
                          }}
                        >
                          Fail QA
                        </MenuItem>
                      </MenuButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            )}
            <Paper
              className="kato-job"
              sx={{ height: '100%', overflowY: 'auto' }}
            >
              <AsyncSelectorStatusOverlay
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'stretch',
                  height: '100%',
                  padding: showExpanded
                    ? theme.spacing(1 / 2)
                    : theme.spacing(2),
                }}
                isLoading={isLoading}
                requests={[
                  procGroupSelector,
                  parcelsAndBoundsFetcher,
                  deliveryProcGroupCommentsFetcher,
                ]}
              >
                {renderContent()}
              </AsyncSelectorStatusOverlay>
            </Paper>
          </Stack>
        </Container>
      </Row>
    </OrderPage>
  )
}
