import * as React from 'react'
import {
  Select as BaseSelect,
  SelectProps,
  SelectRootSlotProps,
} from '@mui/base/Select'
import { Option as BaseOption, optionClasses } from '@mui/base/Option'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'

import { KeyboardArrowDown } from '@mui/icons-material'
import { styled } from '@mui/styles'

const sortOptions = (a: any, b: any) => {
  // Put deafult options first, then sort alphabetically
  if (a.organizationId === null && b.organizationId !== null) return -1
  if (a.organizationId !== null && b.organizationId === null) return 1

  return a.label.localeCompare(b.label)
}

const Select = function Select<TValue extends {}, Multiple extends boolean>(
  props: SelectProps<TValue, Multiple>
) {
  const ref = React.useRef<HTMLButtonElement>(null)
  const slots: SelectProps<TValue, Multiple>['slots'] = {
    root: CustomButton,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  }

  return (
    <BaseSelect
      {...props}
      ref={ref}
      slots={slots}
      slotProps={{
        popup: {
          disablePortal: true,
          style: { width: ref.current?.clientWidth },
        },
      }}
    />
  )
} as <TValue extends {}, Multiple extends boolean>(
  props: SelectProps<TValue, Multiple> & React.RefAttributes<HTMLButtonElement>
) => JSX.Element

export default function RateMapSelect<TValue extends string | number = string>({
  id,
  label,
  selectedValue,
  options,
  onChange,
  onAddOption,
  onDeleteOption,
}: {
  id?: string
  label?: string
  selectedValue?: TValue | null
  options: {
    value: TValue
    label: string
    description?: string
    organizationId?: string | null
  }[]
  onChange: SelectProps<TValue, false>['onChange']
  onAddOption?: () => void
  onDeleteOption?: (value: TValue, label: string) => void
}) {
  const sortedOptions = React.useMemo(
    () => [...options].sort(sortOptions),
    [options]
  )

  return (
    <Stack gap={1} sx={{ width: '100%', overflow: 'hidden' }}>
      {label && (
        <InputLabel
          htmlFor={id}
          sx={{
            textTransform: 'uppercase',
            fontWeight: 400,
            fontSize: '12px',
            letterSpacing: '0.4px',
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        id={id}
        value={selectedValue}
        style={{ width: '100%' }}
        onChange={onChange}
      >
        {sortedOptions.map(({ value, label, description, organizationId }) => (
          <Option
            key={value}
            value={value}
            title={description}
            onDelete={
              onDeleteOption ? () => onDeleteOption(value, label) : undefined
            }
            organizationId={organizationId}
          >
            {label}{' '}
          </Option>
        ))}
        {onAddOption && (
          <Option
            value="add"
            onClick={(ev: any) => {
              ev.stopPropagation()
              ev.preventDefault()
              onAddOption()
            }}
          >
            + Add
          </Option>
        )}
      </Select>
    </Stack>
  )
}

const CustomButton = React.forwardRef(function CustomButton<
  TValue extends {},
  Multiple extends boolean
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { ownerState, ...other } = props
  return (
    <Button
      type="button"
      variant="outlined"
      {...other}
      ref={ref}
      sx={{
        width: '100%',
        minHeight: 10,
        height: 24,
        borderRadius: '8px',
        borderColor: '#8C8A88',
        color: 'white',
        fontSize: '12px',
        paddingRight: 1,
        paddingLeft: 1,
        paddingTop: 1,
        paddingBottom: 1,
        fontWeight: '400',
        lineHeight: '15px',
        letterSpacing: '0.4px',
        textAlign: 'left',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: '100%', overflow: 'hidden' }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            flexGrow: 0,
            width: '100%',
            textTransform: 'none',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {other.children ?? '-'}
        </Typography>
        <KeyboardArrowDown style={{ width: '15px', flexShrink: 0 }} />
      </Stack>
    </Button>
  )
})

const Listbox = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  borderRadius: '8px',
  overflow: 'auto',
  maxHeight: '50vh',
  outline: '0px',
  width: '100%',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  gap: '8px',
  overflowX: 'hidden',
}))

const Option = styled(
  ({ children, onDelete, organizationId, ...props }: any) => (
    <BaseOption {...props}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            wordBreak: 'break-word',
            paddingRight: 1,
            flexGrow: 1,
          }}
        >
          {children}
        </div>
        {organizationId !== null && onDelete && (
          <IconButton
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              width: '15px',
              height: '15px',
              flexShrink: 0,
            }}
            onClick={(e) => {
              e.stopPropagation()
              onDelete()
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    </BaseOption>
  )
)(({ theme }) => ({
  padding: '8px',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '1.2',
  letterSpacing: '0.4px',
  textAlign: 'left',
  cursor: 'default',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  '&:not(:first-of-type)': {
    // marginTop: '4px',
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },

  [`&.${optionClasses.selected}`]: {
    backgroundColor: `${theme.palette.background.paper}`,
    color: theme.palette.text.primary,
  },

  [`&.${optionClasses.highlighted}`]: {
    backgroundColor: '#5CC46C33',
    color: theme.palette.text.primary,
  },

  '&:focus-visible': {
    outline: `3px solid ${theme.palette.grey}`,
  },

  [`&.${optionClasses.highlighted}.${optionClasses.selected}`]: {
    // backgroundColor: `${theme.palette.mode === 'dark' ? blue[900] : blue[100]}`,
    color: theme.palette.text.primary,
  },

  [`&.${optionClasses.disabled}`]: {
    color: theme.palette.text.primary,
  },

  [`&:hover:not(.${optionClasses.disabled})`]: {
    backgroundColor: `${theme.palette.grey[700]}`,
    color: theme.palette.text.primary,
  },
}))

const Popup = styled('div')({
  zIndex: 10009,
})
