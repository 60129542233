import { getState } from "../redux/store"
import { delay } from "../util/delay"
import { Fetched } from "./createAsyncSelector"

export const waitForResource = async (resource: string) => {

  const DEFAULT: Fetched<ReturnType<any>> = {
    status: 'none',
    inputs: {},
  }

  while (true) {

    const fetchedStatus = (getState()?.AsyncSelector[resource] || DEFAULT) as Fetched<any>

    if (fetchedStatus.status === 'rejected') {
      throw new Error(fetchedStatus.error)
    }

    if (fetchedStatus.status === 'resolved') {
      return
    }

    await delay(100)
  }
}
