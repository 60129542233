import { createGQLResource } from './createGQLResource'
import { deliveryParcelFile } from './DeliveryParcelFile'
import {
  MapSource,
  MapSource_create_input,
  MapSource_update_input,
} from './types'

export async function download({ id }: { id: string }) {
  const { data } = await resource.get<{
    deliveryId: string
    parcelId: string
    filename: string
  }>({
    pk: { id },
    returning: `
    {
      deliveryId
      parcelId
      filename
    }
  `,
  })
  deliveryParcelFile.download(data!)
}

const resource = createGQLResource<
  MapSource,
  MapSource_create_input,
  MapSource_update_input,
  'id'
>('MapSource', { id: 'uuid' })

export const mapSource = {
  ...resource,
  download,
}
