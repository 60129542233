import area from '@turf/area'
import { createSelector } from 'reselect'
import getParcelArea from '../postgis/getParcelArea'
import { RootStore } from '../redux/types'
import { convertArea } from '../util/units/area'
import { selectSelectedDeliveryParcels } from './selectSelectedDeliveryParcels'

const getPreferredUnitSystem = (state: RootStore) =>
  state.preferences.preferredUnitSystem

const getCustomStatsZones = (state: RootStore) => state.postgis.statsCustomZones

export const selectSelectedDeliveryParcelsArea = createSelector(
  [selectSelectedDeliveryParcels, getPreferredUnitSystem, getCustomStatsZones],
  (parcels, system, customStatsZones) => {
    if (customStatsZones) {
      let totalArea = 0
      for (const feature of customStatsZones.features) {
        totalArea += area(feature)
      }

      return convertArea(totalArea, 'squareMeter', system)
    }

    return convertArea(
      parcels
        .filter((parcel) => parcel.mapSources.length > 0)
        .map(({ geometry }) => getParcelArea(geometry))
        .reduce((acc, next) => acc + next, 0),
      // turf.js getParcelArea API returns meters squared
      'squareMeter',
      system
    )
  }
)
