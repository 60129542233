import * as React from 'react'

interface Output {
  registerStage: (validation: () => boolean, stage: number) => void
  resolveStatusForStage: ((stage: number) => boolean)
}

export const useStageValidation = (): Output => {
  const [validations, setValidations] = React.useState<(() => boolean)[]>([])

  const registerStage = React.useCallback((validation: () => boolean, stage: number) => {
    setValidations(prevValidations => {
      const newValidations = [...prevValidations]
      newValidations[stage] = validation
      return newValidations
    })
  }, [])

  const resolveStatusForStage = React.useCallback((stage: number) => {
    if (validations && validations[stage]) {
      return validations[stage]()
    }
    return false
  }, [validations])

  return {
    registerStage,
    resolveStatusForStage
  }
}
