import * as React from 'react'
import Paper from '@mui/material/Paper'
import { default as TablePagination } from '@mui/material/TablePagination'
import {
  DEFAULT_PAGE_INFO,
  PAGE_SIZE_OPTIONS,
  selectPagingInfo,
  url,
  urls,
} from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import { Table } from '../../UI/Table/Table'
import { jobTableFormatter } from './jobTableFormatter'
import MaestroHome from './MaestroHome'
import {
  refreshListJob,
  refreshPauseAllStatus,
  selectListJob,
  selectPauseAllStatus,
} from './maestroSelectors'
import { ListJob } from './types'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { JobStatusFilter } from './JobStatusFilter'
import { pauseAllJobs, unpauseAllJobs } from '../../vvapi/maestro'
import TooltipIconButton from '../../UI/TooltipIconButton'
import i18n, { keys } from '../../i18n'
import { Pause, PlayArrow, ScheduleSend } from '@mui/icons-material'
import { ScheduleJobDialog } from './Reprocessing/ScheduleJobDialog'

interface State {
  reprocessingDialogOpen: boolean
}

class JobListPage extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps, State
> {
  state: State = {
    reprocessingDialogOpen: false
  }
  getInfo = () => {
    return this.props.jobListSelector.data
      ? this.props.jobListSelector.data.info
      : { ...DEFAULT_PAGE_INFO, order: [] }
  }

  renderActions = () => {
    const { pauseAllStatusSelector } = this.props
    const pauseAllStatus = pauseAllStatusSelector?.data ?? false

    return (
      <>
        <TooltipIconButton title='Schedule Reprocessing Job'
          onClick={() => this.setState({ ...this.state, reprocessingDialogOpen: true})}>
            <ScheduleSend/>
        </TooltipIconButton>
        <TooltipIconButton
          title={
            pauseAllStatus
              ? i18n.t(keys.unpauseAllJobs)
              : i18n.t(keys.pauseAllJobs)
          }
          onClick={this.pauseOrUnpauseAllJobs}
        >
          {pauseAllStatus ? <PlayArrow /> : <Pause />}
        </TooltipIconButton>
        <JobStatusFilter />
      </>
    )
  }

  pauseOrUnpauseAllJobs = async () => {
    const { pauseAllStatusSelector } = this.props
    const pauseAllStatus = pauseAllStatusSelector?.data ?? false

    if (pauseAllStatus) {
      await unpauseAllJobs()
    } else {
      await pauseAllJobs()
    }

    refreshPauseAllStatus()
  }

  onCloseReprocessDialog = () => {
    this.setState({...this.state, reprocessingDialogOpen: false })
  }

  render() {
    const pagination = this.renderPagination()
    const { jobListSelector } = this.props

    const rows = jobListSelector.data ? jobListSelector.data.data : []

    return (
      <MaestroHome>
        <Paper id="MigrationStatus" className="Paper">
          <EnhancedTableToolbar
            style={{ paddingLeft: 0, paddingRight: 0 }}
            title={`Maestro Jobs`}
            numSelected={0}
            onClickRefresh={this.handleRefresh}
            actions={this.renderActions}
          />
          {pagination}
          <AsyncSelectorStatusOverlay
            style={{ minHeight: 300 }}
            requests={jobListSelector}
          >
            <Table
              stickyOffset={64}
              rows={rows}
              formatter={jobTableFormatter}
            />
          </AsyncSelectorStatusOverlay>
          {pagination}
        </Paper>
        <ScheduleJobDialog open={this.state.reprocessingDialogOpen} onClose={this.onCloseReprocessDialog} />
      </MaestroHome>
    )
  }

  handleViewJob = (job: ListJob) =>
    this.props.history.push(url(urls.job, { jobId: job.id }, {}))

  renderPagination = () => {
    const { page, pageSize, count } = this.getInfo()

    return (
      <TablePagination
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        component="div"
        count={count}
        page={page}
        rowsPerPage={pageSize}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    )
  }

  handleChangePage = (_event: any, page: number) => {
    const searchParams = new URLSearchParams(this.props.location.search)
    searchParams.set('page', `${page}`)
    this.props.history.replace({ search: searchParams.toString() })
  }

  handleChangeRowsPerPage = (event: any) => {
    const searchParams = new URLSearchParams(this.props.location.search)
    searchParams.set('page', '0')
    searchParams.set('pageSize', `${event.target.value}`)
    this.props.history.replace({ search: searchParams.toString() })
  }

  handleRefresh() {
    refreshListJob()
    refreshPauseAllStatus()
  }
}

const mapState = (state: RootStore) => ({
  ...selectPagingInfo(state),
  jobListSelector: selectListJob(state),
  pauseAllStatusSelector: selectPauseAllStatus(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(JobListPage)
)
