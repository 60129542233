import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { formatNum } from '../../util/formatNumber'

interface ProportionItem {
  color: string
  label: string
  value: number
}

interface Props {
  percentages: ProportionItem[]
}
export const ProportionBar = ({ percentages }: Props) => {
  const theme = useTheme()

  return (
    <Stack width="100%">
      <Box
        sx={{
          padding: '3px',
          width: '100%',
          height: 15,
          backgroundColor: 'var(--background2)',
          borderRadius: theme.shape.borderRadius,
          overflow: 'clip',
          ['& .MuiStack-root']: {
            ['& .MuiBox-root:first-child']: {
              borderTopLeftRadius: theme.shape.borderRadius,
              borderBottomLeftRadius: theme.shape.borderRadius,
            },
            ['& .MuiBox-root:last-child']: {
              borderTopRightRadius: theme.shape.borderRadius,
              borderBottomRightRadius: theme.shape.borderRadius,
            },
          },
        }}
      >
        <Stack
          direction="row"
          width="100%"
          justifyContent="stretch"
          alignItems="stretch"
          height="100%"
          spacing={1 / 4}
        >
          {percentages
            .filter(({ value }) => value > 0)
            .map(({ color, value, label }) => {
              return (
                <Tooltip
                  key={label}
                  title={`${label} (${formatNum(value, true)}%)`}
                >
                  <Box
                    sx={{
                      width: `${value}%`,
                      backgroundColor: color,
                      height: '100%',
                      transition: `${theme.transitions.create(['width'], {
                        duration: theme.transitions.duration.standard,
                      })}`,
                      // animation: `${grow(value)} 1s ${
                      //   theme.transitions.easing.easeIn
                      // } forwards`,
                    }}
                  />
                </Tooltip>
              )
            })}
        </Stack>
      </Box>
      <Stack direction="row" spacing={1}>
        {percentages.map(({ color, label }) => (
          <Stack direction="row" spacing={1} alignItems="center" key={label}>
            <Box
              sx={{
                borderColor: 'var(--background2)',
                borderWidth: 3,
                borderStyle: 'solid',
                backgroundColor: color,
                height: 16,
                width: 16,
                borderRadius: 16,
              }}
            />
            <Typography variant="overline">{label}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
