import { Parcel } from '../../../../graphql/types'
import {
  addFieldBounds,
  addFieldBoundsWithFill,
  addFieldLabels,
  parcelsToGeoJSON,
} from '../../../../postgis/selectors/FieldsLayerSelector'
import calculateParcelBounds from './calculateParcelBounds'
import { addTileUrl } from '../../../../map/layers'
import { parse as bboxParse } from '../../../../util/bbox'
import { SAMPLE_PLAN_POINTS } from './sampleLayerHelper'
import * as mapboxgl from 'mapbox-gl'

export const SAMPLE_PLAN_BLOCK_UNSELECTED = 'SAMPLE-PLAN-BLOCK-UNSELECTED'
export const SAMPLE_PLAN_BLOCK_LABEL = 'SAMPLE-PLAN-BLOCK-LABEL'
export const SAMPLE_PLAN_BLOCK_SELECTED = 'SAMPLE-PLAN-BLOCK-SELECTED'
export const SAMPLE_PLAN_BLOCK_SELECTED_FILL = 'SAMPLE-PLAN-BLOCK-SELECTED-FILL'
export const SAMPLE_PLAN_RASTER = 'SAMPLE-PLAN-RASTER'
export const SAMPLE_PLAN_PARCEL_LINE_COLOUR = '#FFC107'
export const SAMPLE_PLAN_LINE_WIDTH = 6
export const SAMPLE_PLAN_LINE_OPACITY_STOPS = [[1, 0.6]]
export const SAMPLE_PLAN_LINE_SELECTED_OPACITY_STOPS = [[1, 1]]
export const SAMPLE_PLAN_PARCEL_SELECTED_LINE_COLOUR = '#FFFFFF'
export const SAMPLE_PLAN_PARCEL_SELECTED_FILL_COLOUR = '#FFFFFF'

interface UnselectedLayerInputs {
  map?: mapboxgl.Map
  parcels?: Parcel[]
  setBlockBounds: (box: mapboxgl.LngLatBoundsLike) => void
  boundsInitialized: boolean
}

interface SelectedLayerInputs {
  map?: mapboxgl.Map
  selectedParcels?: Parcel[]
}

const addUnselectedParcelSourcesAndLayers = ({
  map,
  parcels,
  setBlockBounds,
  boundsInitialized,
}: UnselectedLayerInputs) => {
  const rasterParcelSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  } = { sources: {}, layers: [] }

  if (!map) {
    return
  }

  if (!parcels || parcels.length === 0) {
    return
  }

  if (!map?.isSourceLoaded(SAMPLE_PLAN_BLOCK_UNSELECTED)) {
    const unselectedParcelsSourcesAndLayers: {
      sources: Record<string, any>
      layers: mapboxgl.Layer[]
    } = { sources: {}, layers: [] }

    const unselectedParcelLabelsSourcesAndLayers: {
      sources: Record<string, any>
      layers: mapboxgl.Layer[]
    } = { sources: {}, layers: [] }

    buildParcelSourcesAndLayers(parcels, unselectedParcelsSourcesAndLayers)
    buildParcelLabelsSourcesAndLayers(
      parcels,
      unselectedParcelLabelsSourcesAndLayers
    )

    Object.entries(unselectedParcelsSourcesAndLayers.sources).forEach(
      (sourceEntry) => {
        map?.addSource(sourceEntry[0], sourceEntry[1])
      }
    )
    unselectedParcelsSourcesAndLayers.layers.forEach((layer) => {
      map?.addLayer(
        layer as mapboxgl.AnyLayer,
        map?.isSourceLoaded(SAMPLE_PLAN_BLOCK_SELECTED)
          ? SAMPLE_PLAN_BLOCK_SELECTED
          : undefined
      )
    })

    Object.entries(unselectedParcelLabelsSourcesAndLayers.sources).forEach(
      (sourceEntry) => {
        map?.addSource(sourceEntry[0], sourceEntry[1])
      }
    )
    unselectedParcelLabelsSourcesAndLayers.layers.forEach((layer) => {
      map?.addLayer(layer as mapboxgl.AnyLayer)
    })
    if (!boundsInitialized) {
      setBlockBounds(calculateParcelBounds(parcels))
    }
  }

  if (
    parcels.some((p) => !map?.isSourceLoaded(`${SAMPLE_PLAN_RASTER}-${p.id}`))
  ) {
    buildParcelRasterFillSourcesAndLayers(
      parcels,
      rasterParcelSourcesAndLayers
    )

    Object.entries(rasterParcelSourcesAndLayers.sources).forEach(
      (sourceEntry) => {
        map?.addSource(sourceEntry[0], sourceEntry[1])
      }
    )
    rasterParcelSourcesAndLayers.layers.forEach((layer) => {
      map?.addLayer(layer as mapboxgl.AnyLayer, SAMPLE_PLAN_BLOCK_UNSELECTED)
    })
  }
}

const addSelectedParcelSourcesAndLayers = ({
  map,
  selectedParcels,
}: SelectedLayerInputs) => {
  // create empty source and layer object for the selected blocks.
  const selectedParcelSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  } = { sources: {}, layers: [] }

  if (!map) {
    return
  }

  if (!selectedParcels) {
    return
  }

  if (!map?.isSourceLoaded(SAMPLE_PLAN_BLOCK_SELECTED)) {
    buildParcelFillSourcesAndLayers(
      selectedParcels,
      selectedParcelSourcesAndLayers
    )

    Object.entries(selectedParcelSourcesAndLayers.sources).forEach(
      (sourceEntry) => {
        map?.addSource(sourceEntry[0], sourceEntry[1])
      }
    )
    selectedParcelSourcesAndLayers.layers.forEach((layer) => {
      map?.addLayer(
        layer as mapboxgl.AnyLayer,
        map?.isSourceLoaded(SAMPLE_PLAN_POINTS)
          ? SAMPLE_PLAN_POINTS
          : SAMPLE_PLAN_BLOCK_LABEL
      )
    })
  } else {
    const fieldBoundsCollection = parcelsToGeoJSON(selectedParcels as any, {})
    const updatedSource = {
      data: fieldBoundsCollection,
      type: 'geojson',
      attribution: '&copy; VineView',
    }
    const currentSource = map.getSource(
      SAMPLE_PLAN_BLOCK_SELECTED
    ) as mapboxgl.GeoJSONSource
    currentSource.setData(updatedSource.data)
  }
  map?.moveLayer(SAMPLE_PLAN_BLOCK_LABEL)
}

const buildParcelRasterFillSourcesAndLayers = (
  parcels: Parcel[],
  rasterSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  }
) => {
  parcels.forEach((p) => {
    // Only displaying the latest raster right now, but we may want to expand later.
    const mapSource = p.DeliveryParcels?.find(dp => !!dp.DeliveryParcelFiles && dp.DeliveryParcelFiles.length !== 0)
    ?.DeliveryParcelFiles?.sort((a, b) => {
      const f1 = a.flightDate.split('/').reverse().join('')
      const f2 = b.flightDate.split('/').reverse().join('')
      return f1 > f2 ? 1 : f1 < f2 ? -1 : 0
    })?.find(dpf => dpf.filename === 'RGB.tif')
    ?.MapSource

    // only try to add if it exists.
    if (!mapSource) {
      return
    }

    rasterSourcesAndLayers.sources[`${SAMPLE_PLAN_RASTER}-${p.id}`] = {
      id: `${SAMPLE_PLAN_RASTER}-${p.id}`,
      bounds: bboxParse(mapSource.bounds),
      minzoom: Number(mapSource.minzoom ?? 0),
      maxzoom: Number(mapSource.maxzoom ?? 16),
      scheme: (mapSource?.scheme ?? 'xyz') as any,
      tileSize: mapSource.tileSize ?? 512,
      attribution: mapSource.attribution ?? '&copy; VineView',
      tiles: addTileUrl(mapSource.tiles),
      type: (mapSource.type ?? 'vector') as any,
    }

    rasterSourcesAndLayers.layers.push({
      paint: { 'raster-resampling': 'nearest' } as any,
      id: `${SAMPLE_PLAN_RASTER}-${p.id}`,
      type: 'raster',
      source: `${SAMPLE_PLAN_RASTER}-${p.id}`,
      'source-layer': `${SAMPLE_PLAN_RASTER}-${p.id}`,
      minzoom: 0,
      maxzoom: 24,
    })
  })
}

export const buildParcelFillSourcesAndLayers = (
  selectedParcels: Parcel[],
  selectedParcelSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  }
) => {
  addFieldBoundsWithFill(
    selectedParcels as any,
    {},
    SAMPLE_PLAN_BLOCK_SELECTED,
    SAMPLE_PLAN_PARCEL_SELECTED_LINE_COLOUR,
    selectedParcelSourcesAndLayers,
    undefined,
    SAMPLE_PLAN_LINE_WIDTH,
    SAMPLE_PLAN_LINE_SELECTED_OPACITY_STOPS,
  )
}

const buildParcelSourcesAndLayers = (
  parcels: Parcel[],
  parcelSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  }
) => {
  // Add the bounds of the parcels as sources/layers.
  addFieldBounds(
    parcels as any,
    {},
    SAMPLE_PLAN_BLOCK_UNSELECTED,
    SAMPLE_PLAN_PARCEL_LINE_COLOUR,
    parcelSourcesAndLayers,
    undefined,
    SAMPLE_PLAN_LINE_WIDTH,
    SAMPLE_PLAN_LINE_OPACITY_STOPS
  )
}

const buildParcelLabelsSourcesAndLayers = (
  parcels: Parcel[],
  parcelSourcesAndLayers: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  }
) => {
  // Add the parcel labels as sources/layers.
  addFieldLabels(
    parcels as any,
    {},
    parcelSourcesAndLayers,
    SAMPLE_PLAN_BLOCK_LABEL
  )
}

export {
  addUnselectedParcelSourcesAndLayers,
  addSelectedParcelSourcesAndLayers,
  buildParcelSourcesAndLayers,
  buildParcelLabelsSourcesAndLayers,
  buildParcelRasterFillSourcesAndLayers,
}
