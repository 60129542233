type FileCallBack = (file: File) => void
type ReaderCallBack = (entries: WebkitEntry[]) => void

interface EntryReader {
  readEntries(cb: ReaderCallBack, error?: () => void): void
}

interface WebkitEntry {
  isFile: boolean
  isDirectory: boolean
  name: string
  fullPath: string
  file: (cb: FileCallBack, error?: () => void) => void
  createReader(): EntryReader
}

/**
 * This is necessary for handling drag-and-drop files/directories
 */
export const getAllFilesFromDataTransferItems = async (
  items: DataTransferItemList,
  extensions: string[] = []
) => {
  // ! all entries must be retrieved synchronously or it breaks
  // i.e. can't use `webkitGetAsEntry` in the loop below
  const entries = Array.from(items).map(
    (item) => item.webkitGetAsEntry() as unknown as WebkitEntry
  )
  let files: Record<string, File> = {}

  for (const entry of entries) {
    files = {
      ...files,
      ...(await traverseEntry(entry, extensions)),
    }
  }

  return files
}

/**
 * copied from:
 * https://stackoverflow.com/questions/3590058/does-html5-allow-drag-drop-upload-of-folders-or-a-folder-tree
 */
const traverseEntry = async (entry: WebkitEntry, extensions: string[]) => {
  let output: Record<string, File> = {}

  if (entry.isFile) {
    if (
      extensions.length > 0 &&
      !extensions.some((ext) => entry.name.endsWith(ext))
    ) {
      return
    }
    const file = await new Promise<File>((resolve, reject) =>
      entry.file(resolve, reject)
    )
    // make sure fullPath is populated
    file.fullPath = entry.fullPath
    output[`${entry.fullPath}`] = file
  } else if (entry.isDirectory) {
    const entries = await getAllEntriesFromDirectory(entry)
    for (const anotherEntry of entries) {
      output = {
        ...output,
        ...(await traverseEntry(anotherEntry, extensions)),
      }
    }
  }

  return output
}

/** a while loop to ensure that all files in a directory are returned */
const getAllEntriesFromDirectory = async (entry: WebkitEntry) => {
  const allEntries: WebkitEntry[] = []
  const reader = entry.createReader()

  let entries = await getEntriesFromReader(reader)

  while (entries.length > 0) {
    allEntries.push(...entries)
    entries = await getEntriesFromReader(reader)
  }

  return allEntries
}

/** entry reader only returns max 100 files at a time */
const getEntriesFromReader = (reader: EntryReader) =>
  new Promise<WebkitEntry[]>((resolve, reject) =>
    reader.readEntries(resolve, reject)
  )
