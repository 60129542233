import { createSelector } from '@reduxjs/toolkit'
import {
  MapEditorToolType,
  MapEditorToolsState,
} from '../../../redux/map-editor-pages/redux'
import { RootStore } from '../../../redux/types'

const getHeaderOptions = (
  state: MapEditorToolsState,
  pageType: MapEditorToolType
) => state?.[pageType]?.headerOptions

export const selectHeaderOptions = createSelector(
  [
    (state: RootStore) => state.mapEditorTools,
    (_: RootStore, pageType: MapEditorToolType) => pageType,
  ],
  (toolPages, pageType) => getHeaderOptions(toolPages, pageType)
)

export const selectHeaderOption = createSelector(
  [
    selectHeaderOptions,
    (_state: RootStore, _pageType: MapEditorToolType, id: string) => id,
  ],
  (headerOptions, id) => headerOptions[id]
)
