import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import { default as TablePagination } from '@mui/material/TablePagination'

import {
  DEFAULT_PAGE_INFO,
  PAGE_SIZE_OPTIONS,
  selectPagingInfo,
  url,
  urls,
} from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'
import { Table } from '../../UI/Table/Table'
import MaestroHome from './MaestroHome'
import { refreshListPipeline, selectListPipeline } from './maestroSelectors'
import { actions } from './pipelineTableActions'
import { pipelineTableFormatter } from './pipelineTableFormatter'
import { ListPipeline } from './types'

class PipelineListPage extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps
> {
  getInfo = () => {
    return this.props.pipelineListSelector.data
      ? this.props.pipelineListSelector.data.info
      : { ...DEFAULT_PAGE_INFO, order: [] }
  }

  render() {
    const pagination = this.renderPagination()
    const { pipelineListSelector } = this.props

    const rows = pipelineListSelector.data ? pipelineListSelector.data.data : []

    return (
      <MaestroHome>
        <Paper id="MigrationStatus" className="Paper">
          <EnhancedTableToolbar
            style={{ paddingLeft: 0, paddingRight: 0 }}
            title={`Maestro Pipelines`}
            numSelected={0}
            onClickRefresh={this.handleRefresh}
          />
          {pagination}
          <AsyncSelectorStatusOverlay
            style={{ minHeight: 300 }}
            requests={pipelineListSelector}
          >
            <Table
              stickyOffset={64}
              rows={rows}
              actions={[
                actions.pause(refreshListPipeline),
                actions.resume(refreshListPipeline),
                actions.view(this.handleViewPipeline),
              ]}
              formatter={pipelineTableFormatter}
            />
          </AsyncSelectorStatusOverlay>
          {pagination}
        </Paper>
      </MaestroHome>
    )
  }

  handleViewPipeline = (pipeline: ListPipeline) =>
    this.props.history.push(url(urls.pipeline, { pipelineId: pipeline.id }, {}))

  renderPagination = () => {
    const { page, pageSize, count } = this.getInfo()

    return (
      <TablePagination
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        component="div"
        count={count}
        page={page}
        rowsPerPage={pageSize}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    )
  }

  handleChangePage = (_event: any, page: number) =>
    this.props.history.push(
      url(urls.pipelines, this.props.match.params, {
        page,
        pageSize: this.getInfo().pageSize,
      })
    )

  handleChangeRowsPerPage = (event: any) =>
    this.props.history.push(
      url(urls.pipelines, this.props.match.params, {
        page: 0,
        pageSize: event.target.value,
      })
    )

  handleRefresh() {
    refreshListPipeline()
  }
}

const mapState = (state: RootStore) => ({
  ...selectPagingInfo(state),
  pipelineListSelector: selectListPipeline(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(PipelineListPage)
)
