export function toggleArrayValue<T>(array: T[], value: T) {
  const set = new Set<T>(array)

  if (set.has(value)) {
    set.delete(value)
  } else {
    set.add(value)
  }

  return Array.from(set)
}

export function addArrayValues<T>(array: T[], values: T[]) {
  return Array.from(new Set<T>([...array, ...values]))
}

export function removeArrayValues<T>(array: T[], values: T[]) {
  const set = new Set<T>(array)

  for (const value of values) {
    set.delete(value)
  }

  return Array.from(set)
}
