import { default as TextField } from '@mui/material/TextField'
import { Form } from '../../../admin/UI/Form'
import * as React from 'react'
import { Variable_create_input } from '../../../graphql/types'

export default class VariableForm extends Form<Variable_create_input> {
  render() {
    const { key = '', value = '' } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <h2>{this.props.type === 'edit' ? 'Edit' : 'New'} Variable</h2>
        </div>
        <div className="grid-xs-12">
          <TextField
            name="key"
            label={`Key`}
            fullWidth
            multiline
            value={key}
            onChange={this.updateFormDataFromChangeEvent}
            InputLabelProps={{
              shrink: !!key,
            }}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="value"
            label={`Value (${typeof value})`}
            fullWidth
            multiline
            value={typeof value === 'object' ? JSON.stringify(value) : value}
            onChange={this.updateFormDataFromChangeEventJSON}
            InputLabelProps={{
              shrink: !!value,
            }}
          />
        </div>
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }

  updateFormDataFromChangeEventJSON = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const name = event.target.name
    let value = event.target.value

    try {
      value = JSON.parse(value)
    } catch (e) {}

    this.updateFormDataFromNameAndValue({ name, value })
  }
}
