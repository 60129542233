import { createSelector } from 'reselect'
import { selectRateMapClasses } from './selectRateMapClasses'
import { selectRateMapGroupBy } from './selectRateMapGroupBy'
import { getSelectedZoneIndex } from './getSelectedZoneIndex'
import { selectRateMapMode } from './selectRateMapMode'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../constants'
import { VisualizationMode } from '../../../ProductSettings/store/types'

export const selectRateMapProductSettings = createSelector(
  selectRateMapClasses,
  selectRateMapGroupBy,
  selectRateMapMode,
  getSelectedZoneIndex,
  (rateMapClasses, rateMapGroupBy, rateMapMode, selectedZoneIndex) => {
    return {
      [pureVineZonesLayerID]: {
        visualization: rateMapMode as VisualizationMode | undefined,
        groupBy: rateMapGroupBy as 'value-ranges' | 'equal-areas' | undefined,
        numberOfGroups: rateMapClasses,
        isolatedStops:
          selectedZoneIndex !== undefined ? [selectedZoneIndex] : [],
      },
      [vigorZonesLayerID]: {
        visualization: rateMapMode as VisualizationMode | undefined,
        groupBy: rateMapGroupBy as 'value-ranges' | 'equal-areas' | undefined,
        numberOfGroups: rateMapClasses,
        isolatedStops:
          selectedZoneIndex !== undefined ? [selectedZoneIndex] : [],
      },
    }
  }
)
