import * as React from 'react'
import { Collapse, Stack, Typography, useTheme } from '@mui/material'
import { Translation } from '../../../../../../graphql/types'
import { selectPreferredLanguage } from '../../../../../../data/selectPreferredLanguage'
import { useRedux } from '../../../../../../hooks/useRedux'

interface SectionData {
  title: Translation
  description: Translation
}

interface Props {
  open: boolean
  sections: SectionData[]
  image: string
}

const InfoSection = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  return (
    <Stack direction="column" spacing={1} padding={0.5}>
      <Stack direction="row">
        <Typography variant="body1" fontWeight={600}>
          {title}
        </Typography>
      </Stack>
      <Stack direction="row" flexBasis="50%">
        <Typography variant="body1" fontWeight={400}>
          {description}
        </Typography>
      </Stack>
    </Stack>
  )
}

const InfoImage = ({ image }: { image: string }) => {
  return (
    <Stack direction="row" alignItems="center" borderRadius={1}>
      <img src={image} height="350px" />
    </Stack>
  )
}

export const SamplePlanMethodInfo = ({ open, sections, image }: Props) => {
  const [state] = useRedux()
  const theme = useTheme()
  const preferredLanguage = selectPreferredLanguage(state)
  return (
    <Collapse in={open}>
      <Stack direction="row" spacing={4} paddingBottom={theme.spacing(2)}>
        <Stack
          direction="column"
          spacing={2}
          flexBasis={image && image.length > 0 ? '50%' : '100%'}
        >
          {sections.map((s, i) => (
            <InfoSection
              key={i}
              title={s.title[preferredLanguage]}
              description={s.description[preferredLanguage]}
            />
          ))}
        </Stack>
        {image && image.length > 0 ? (
          <Stack direction="column" flexBasis="50%" alignItems="center">
            <InfoImage image={image} />
          </Stack>
        ) : null}
      </Stack>
    </Collapse>
  )
}
