import { gql, query } from './client'

/** Recursively Partial */
export type GQLQueryResult<T> = {
  [P in keyof T]?: T[P] extends (infer K)[]
    ? GQLQueryResult<K>[]
    : GQLQueryResult<T[P]>
}

export interface GQLResult<T> {
  data?: T | null
}

interface ListInfo {
  limit?: number | null
  offset?: number | null
  count: number
}

export interface GQLListResult<T> extends GQLResult<T> {
  info?: ListInfo
}

function undefinedAndEmptyStringToNull<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map(undefinedAndEmptyStringToNull) as any
  }

  if (obj && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = undefinedAndEmptyStringToNull(value)

      return acc
    }, {} as any)
  }

  if (obj === undefined || (obj as any) === '') {
    return null as any
  }

  return obj
}

type PKTypes = Record<string, string>

const pkVariables = (pkTypes: PKTypes) =>
  Object.entries(pkTypes)
    .map(([key, type]) => `$pk_${key}: ${type}!`)
    .join(', ')
const pkLookup = (pkTypes: PKTypes) =>
  Object.keys(pkTypes)
    .map((key) => `${key}: $pk_${key}`)
    .join(', ')
const pkReturning = (pkTypes: PKTypes) => `{${Object.keys(pkTypes).join(' ')}}`
const pkValues = (pk: any) => {
  const result = {}

  for (const [key, val] of Object.entries(pk)) {
    result[`pk_${key}`] = val
  }

  return result
}

export const getModelQuery =
  (model: string, pkTypes: PKTypes) =>
  (returning = pkReturning(pkTypes)) =>
    gql`
  query get${model}(${pkVariables(pkTypes)}) {
    data: ${model}_by_pk(${pkLookup(pkTypes)}) ${returning}
  }
`

const getModel = <M extends object, PK extends object>(
  model: string,
  pkTypes: PKTypes
) =>
  async function get<R extends GQLQueryResult<M>>({
    pk,
    returning,
  }: {
    pk: PK
    returning?: string
  }): Promise<GQLResult<R>> {
    return query<{ data: R }>({
      variables: {
        ...pkValues(pk),
      },
      query: getModelQuery(model, pkTypes)(returning),
    })
  }

export const listModelQuery =
  (model: string, pkTypes: PKTypes) =>
  ({
    returning = pkReturning(pkTypes),
    order_by = '',
  }: {
    returning?: string
    order_by?: string
  }) =>
    gql`
  query list${model}(
    $where: ${model}_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    data: ${model}(
      where: $where
      limit: $limit
      offset: $offset
      ${order_by}
    ) ${returning}
    info: ${model}_aggregate(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`

type Where<T> = PartialRecord<keyof T, any>

const listModel = <M extends object>(model: string, pkTypes: PKTypes) =>
  async function list<R extends GQLQueryResult<M>>({
    returning,
    where = {},
    limit = null,
    offset = null,
    order_by,
  }: {
    returning?: string
    where?: Where<M>
    limit?: number | null
    offset?: number | null
    order_by?: string
  }): Promise<GQLListResult<R[]>> {
    if (order_by) {
      // tslint:disable-next-line:no-parameter-reassignment
      order_by = `order_by: ${order_by}`
    }
    const { data = [], info = { aggregate: { count: 0 } } } = await query<{
      data: R[]
      info: { aggregate: { count: number } }
    }>({
      variables: {
        where,
        limit,
        offset,
      },
      query: listModelQuery(
        model,
        pkTypes
      )({
        returning,
        order_by,
      }),
    })

    return {
      data,
      info: {
        limit,
        offset,
        count: info.aggregate.count,
      },
    }
  }

export const createModelQuery =
  (model: string, pkTypes: PKTypes) =>
  (returning = pkReturning(pkTypes)) =>
    gql`
  mutation create${model}($input: [${model}_insert_input!]!) {
    data: insert_${model}(
      objects: $input
    ) {
      returning ${returning}
    }
  }
`

export const upsertModelQuery =
  (model: string, pkTypes: PKTypes) =>
  (returning = pkReturning(pkTypes)) =>
    gql`
  mutation create${model}($input: [${model}_insert_input!]!, $onConflict:${model}_on_conflict!) {
    data: insert_${model}(
      objects: $input,
      on_conflict: $onConflict
    ) {
      returning ${returning}
    }
  }
`

const upsertModel = <M extends object, C extends Partial<M>>(
  model: string,
  pkTypes: PKTypes
) =>
  async function create<R extends GQLQueryResult<M>>({
    input,
    onConflict,
    returning,
  }: {
    input: C
    onConflict: { constraint: string; update_columns: string[] }
    returning?: string
  }): Promise<GQLResult<R>> {
    const { data } = await query<{
      data: { returning: R[] }
    }>({
      variables: {
        onConflict,
        input: [undefinedAndEmptyStringToNull(input)],
      },
      query: upsertModelQuery(model, pkTypes)(returning),
    })

    return {
      data: data?.returning[0],
    }
  }

const createModel = <M extends object, C extends Partial<M>>(
  model: string,
  pkTypes: PKTypes
) =>
  async function create<R extends GQLQueryResult<M>>({
    input,
    returning,
  }: {
    input: C
    returning?: string
  }): Promise<GQLResult<R>> {
    const { data } = await query<{
      data: { returning: R[] }
    }>({
      variables: {
        input: [undefinedAndEmptyStringToNull(input)],
      },
      query: createModelQuery(model, pkTypes)(returning),
    })

    return {
      data: data?.returning[0],
    }
  }

export const updateModelQuery =
  (model: string, pkTypes: PKTypes) =>
  (returning = pkReturning(pkTypes)) =>
    gql`
  mutation update${model}(${pkVariables(
      pkTypes
    )}, $input: ${model}_set_input!) {
    data: update_${model}_by_pk(
      pk_columns: {${pkLookup(pkTypes)}},
      _set: $input
    ) ${returning}
  }
`

const updateModel = <M extends object, U extends Partial<M>, PK extends object>(
  model: string,
  pkTypes: PKTypes
) =>
  async function update<R extends GQLQueryResult<M>>({
    pk,
    input,
    returning,
  }: {
    pk: PK
    input: U
    returning?: string
  }): Promise<GQLResult<R>> {
    const { data } = await query<{
      data: R
    }>({
      variables: {
        ...pkValues(pk),
        input: undefinedAndEmptyStringToNull(input),
      },
      query: updateModelQuery(model, pkTypes)(returning),
    })

    return {
      data,
    }
  }

export const deleteModelQuery =
  (model: string, pkTypes: PKTypes) =>
  (returning = pkReturning(pkTypes)) =>
    gql`
  mutation delete${model}(${pkVariables(pkTypes)}) {
    data: delete_${model}_by_pk(${pkLookup(pkTypes)}) ${returning}
  }
`

const deleteModel = <M extends object, PK extends object>(
  model: string,
  pkTypes: PKTypes
) =>
  async function delete_<R extends GQLQueryResult<M>>({
    pk,
    returning,
  }: {
    pk: PK
    returning?: string
  }): Promise<GQLResult<R>> {
    const { data } = await query<{
      data: R
    }>({
      variables: {
        ...pkValues(pk),
      },
      query: deleteModelQuery(model, pkTypes)(returning),
    })

    return {
      data,
    }
  }

export function createGQLResource<
  M extends object,
  C extends Partial<M>,
  U extends Partial<M>,
  PK extends keyof M
>(model: string, pkTypes: Record<PK, string>) {
  return {
    get: getModel<M, Pick<M, PK>>(model, pkTypes),
    list: listModel<M>(model, pkTypes),
    create: createModel<M, C>(model, pkTypes),
    update: updateModel<M, U, Pick<M, PK>>(model, pkTypes),
    upsert: upsertModel<M, C>(model, pkTypes),
    delete: deleteModel<M, Pick<M, PK>>(model, pkTypes),
  }
}
