import { createSelector } from 'reselect'

import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { MapColorProfileData, MapLayerData } from '../data/types'
import { categoryReduce } from '../ProductStats/calculateStats/categoryReduce'
import {
  ProductStats,
  productStats,
  ProductStops,
} from '../ProductStats/calculateStats/productStats'
import { statsData } from '../ProductStats/calculateStats/statsData'
import { selectActiveColorProfiles } from '../ProductStats/selectors/stats'
import { selectReportMapLayers } from './selectReportMapLayers'

const fetchReportStats = async ({
  reportMapLayers: { reportMapLayers },
  activeColorProfilesForProduct,
}: {
  activeColorProfilesForProduct: Record<string, MapColorProfileData | undefined>
  reportMapLayers: { reportMapLayers: MapLayerData[] }
}) => {
  // Only calculating the stats that we need
  const reportStatMapLayers = reportMapLayers.filter((ml) => {
    return (
      ml.mapLayerDef.dataProperty &&
      (ml.mapLayerDef.dataProperty.property === 'EVImean' ||
        ml.mapLayerDef.dataProperty.property === 'EVI' ||
        ml.mapLayerDef.dataProperty.property === 'Presence')
    )
  })

  const canopyGapsLayer = reportStatMapLayers.find(
    (ml) => ml.mapLayerDef.dataProperty.property === 'Presence'
  )

  const productStops: ProductStops = {}

  // only supply productstops for canopy gaps,
  // pulled from either the active or default color profile
  // So we have the present and not present totals
  if (canopyGapsLayer) {
    const colorProfile =
      activeColorProfilesForProduct[canopyGapsLayer.mapLayerDefId]

    if (colorProfile) {
      productStops[canopyGapsLayer.mapLayerDefId] = colorProfile.dataStops.map(
        ([s]) => s
      )
    } else {
      const defaultProfile = canopyGapsLayer.mapLayerDef.colorProfiles.find(
        (cp) => cp.visualizations.some((v) => v.default)
      )
      if (defaultProfile) {
        productStops[canopyGapsLayer.mapLayerDefId] =
          defaultProfile.dataStops.map(([s]) => s)
      }
    }
  }
  const data = await statsData(reportStatMapLayers.map((ml) => ml.id))

  const reduced = categoryReduce(data)

  const stats = productStats(reduced, productStops)

  return stats
}

export const {
  selector: selectGetReportStats,
  refresh: refreshGetReportStats,
} = createAsyncSelector({
  resource: 'ReportStats',
  inputs: {
    reportMapLayers: selectReportMapLayers,
    activeColorProfilesForProduct: selectActiveColorProfiles,
  },
  fetcher: fetchReportStats,
})

const EMPTY_REPORT_STATS: ProductStats = {}

export const selectReportStats = createSelector(
  selectGetReportStats,
  (getReportStats) =>
    getReportStats.data ? getReportStats.data : EMPTY_REPORT_STATS
)
