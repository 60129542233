import { createSelector, createStructuredSelector } from 'reselect'

import { AsyncSelector, Fetched } from './createAsyncSelector'

type CompositeFetched = Pick<Fetched<Promise<any>>, 'status' | 'data'> & {
  composedSelectors: Record<string, Fetched<Promise<any>>>
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (asyncSelectors: Record<string, AsyncSelector>) => {
  const selectors = extractSelectors(asyncSelectors)
  const compositeSelector = createStructuredSelector(selectors)

  const mapSelector = createSelector(
    compositeSelector,
    (result: Record<string, Fetched<Promise<any>>>) => mapSelectors(result)
  )

  return {
    selector: mapSelector,
    refresh: () => {
      Object.values(asyncSelectors).forEach((asyncSelector) => {
        asyncSelector.refresh()
      })
    },
  }
}

const extractSelectors = (asyncSelectors: Record<string, AsyncSelector>) => {
  return Object.entries(asyncSelectors).reduce((acc, [key, asyncSelector]) => {
    acc[key] = asyncSelector.selector

    return acc
  }, {})
}

const mapSelectors = (
  composedSelectors: Record<string, Fetched<Promise<any>>>
): CompositeFetched => {
  const selectorArray = Object.entries(composedSelectors)
  if (selectorArray.some(([, s]) => s.status === 'rejected')) {
    return {
      composedSelectors,
      status: 'rejected',
    }
  }

  if (selectorArray.some(([, s]) => s.status === 'pending')) {
    return {
      composedSelectors,
      status: 'pending',
    }
  }

  if (selectorArray.every(([, s]) => s.status === 'resolved')) {
    return {
      composedSelectors,
      status: 'resolved',
      data: selectorArray.reduce((acc, [name, selector]) => {
        acc[name] = selector.data

        return acc
      }, {}),
    }
  }

  return {
    composedSelectors,
    status: 'none',
  }
}
