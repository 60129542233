import * as React from 'react'
import i18n, { keys } from '../../../../../../i18n'
import useAsync from '../../../../../../hooks/useAsync'
import { fetchStatisticalMethods } from '../../../../queries'
import { Stack, Typography, useTheme } from '@mui/material'
import { SamplePlanNewFormProps } from '../../types/SamplePlanFormProps'
import {
  DataSourceData,
  MapSource,
  SamplePlanCreateDataSet,
  SamplingStatisticalMethod,
} from '../../../../../../graphql/types'
import { SamplePlanMethodOption } from './SamplePlanMethodOption'
import { Warning } from '@mui/icons-material'

interface Props {
  setDataSources: (dataSources: MapSource[]) => void
}

type Ref = HTMLDivElement

const SamplePlanSetUpForm = ({
  currentNewSamplingPlan,
  setCurrentNewSamplingPlan,
  forwardedRef,
  registerValidation,
  invalidateCompletedSamplingPlan,
  stage,
  setDataSources,
}: SamplePlanNewFormProps<Ref> & Props) => {
  const [fetchedStatisticalMethods] = useAsync(fetchStatisticalMethods, [])
  const theme = useTheme()
  const [dataSourceWarningActive, setDataSourceWarningActive] = React.useState(false);

  const statisticalMethods = React.useMemo(() => {
    return fetchedStatisticalMethods?.result?.data ?? []
  }, [fetchedStatisticalMethods?.result?.data])

  const onStatisticalMethodChanged = async (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setCurrentNewSamplingPlan({
        ...currentNewSamplingPlan,
        statisticalMethodTypeId: e.target.value,
        SamplingStatisticalMethod: statisticalMethods.find(
          (sm) => sm.id === e.target.value
        ),
        statisticalMethodDataSources: {},
        statisticalMethodFields: {},
      })
      await invalidateCompletedSamplingPlan()
    }
  }

  const onStatisticalMethodFieldChanged = async <T,>(name: string, data: T) => {
    setCurrentNewSamplingPlan({
      ...currentNewSamplingPlan,
      statisticalMethodFields: {
        ...currentNewSamplingPlan.statisticalMethodFields,
        [name]: data,
      },
    })
    await invalidateCompletedSamplingPlan()
  }
  const onStatisticalMethodDataSourceChanged = async (
    name: string,
    data: DataSourceData,
    dataSources: MapSource[]
  ) => {
    setCurrentNewSamplingPlan({
      ...currentNewSamplingPlan,
      statisticalMethodDataSources: {
        ...currentNewSamplingPlan.statisticalMethodDataSources,
        [name]: data,
      },
    })
    setDataSources(dataSources)
    await invalidateCompletedSamplingPlan()
  }

  const requiredFieldsAndDataSourcesExist = (
    sp: SamplePlanCreateDataSet,
    sms: SamplingStatisticalMethod[]
  ) => {
    const selectedStatisticalMethod = sms?.find(
      (sm) => sm.id === sp?.statisticalMethodTypeId
    )

    const requiredDataSources =
      selectedStatisticalMethod?.SamplingStatisticalMethodDataSources?.filter(
        (smds) => smds.required
      )
    const requiredFields =
      selectedStatisticalMethod?.SamplingStatisticalMethodFields?.filter(
        (smf) => smf.required
      )

    const requiredDataSourcesExist = requiredDataSources
      ?.map((ds) => ds.id)
      ?.every((id) =>
        Object.entries(sp?.statisticalMethodDataSources ?? {}).some(
          (smm) => smm[0] === id && !!smm?.[1] && smm[1] !== undefined
        )
      )

    const requiredFieldsExist = requiredFields
      ?.map((f) => f.type)
      ?.every((type) =>
        Object.entries(sp?.statisticalMethodFields ?? {}).some(
          (smm) => smm[0] === type && smm?.[1] !== undefined && smm[1] !== ''
        )
      )

    return !!requiredFieldsExist && !!requiredDataSourcesExist
  }

  React.useEffect(() => {
    registerValidation(() => {
      return (
        !!currentNewSamplingPlan?.statisticalMethodTypeId &&
        requiredFieldsAndDataSourcesExist(
          currentNewSamplingPlan,
          statisticalMethods
        )
      )
    }, stage)
  }, [registerValidation, stage, currentNewSamplingPlan, statisticalMethods])

  return (
    <Stack
      className="sample-plan-new-stage-form"
      direction="column"
      spacing={2}
      ref={forwardedRef}
      style={{ position: 'relative' }}
    >
      {dataSourceWarningActive && <Stack 
        direction='row' 
        p={2}
        alignItems='center'
        justifyContent='space-evenly'
        spacing={2}
        sx={{ backgroundColor: '#00000033', borderRadius: 8}}>
          <Warning color='warning' />
          <Typography color={theme.palette.warning.dark} >
            {i18n.t(keys.samplePlanMissingDatasourcesWarning)}
          </Typography>
      </Stack>}
      <Typography variant="body1" fontWeight={600} fontSize={16}>
        {i18n.t(keys.samplePlanNewChooseMethod)}
      </Typography>
      <Stack
        direction="column"
        style={{ overflow: 'auto', height: '100%', width: '100%' }}
        spacing={1}
      >
        <Stack>
          {statisticalMethods?.map(
            ({
              id,
              name,
              info,
              SamplingStatisticalMethodDataSources: dataSources,
              SamplingStatisticalMethodFields: fields,
            }) => (
              <SamplePlanMethodOption
                key={id}
                id={id}
                name={name}
                info={info}
                dataSources={dataSources}
                fields={fields}
                currentNewSamplingPlan={currentNewSamplingPlan}
                onStatisticalMethodChanged={onStatisticalMethodChanged}
                onStatisticalMethodFieldChanged={
                  onStatisticalMethodFieldChanged
                }
                onStatisticalMethodDataSourceChanged={
                  onStatisticalMethodDataSourceChanged
                }
                setDataSourceWarningActive={setDataSourceWarningActive}
              />
            )
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default React.forwardRef<Ref, SamplePlanNewFormProps<Ref> & Props>(
  (props, ref) => <SamplePlanSetUpForm {...props} forwardedRef={ref} />
)
