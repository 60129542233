import { createSelector } from 'reselect'

import { RootStore } from '../redux/types'
import { indexArray } from '../util/indexArray'
import { getSelectedBackgroundProductId } from './getSelectedBackgroundProductId'
import { selectFilteredMapLayers } from './selectFilteredMapSources'
import { selectSelectedProductIds } from './selectSelectedProductIds'
import { MapLayerData } from './types'

const getIsGPSOffsetMarkerVisibled = (state: RootStore) =>
  state.postgis.isGPSOffsetMarkerVisible

export const selectSelectedMapLayers = createSelector(
  [
    selectFilteredMapLayers,
    selectSelectedProductIds,
    getSelectedBackgroundProductId,
    getIsGPSOffsetMarkerVisibled,
  ],
  (
    mapLayers,
    selectedProductIds,
    selectedBackgroundProductId,
    isGPSOffsetMarkerVisible
  ) => {
    const layerArray = [selectedBackgroundProductId, ...(!isGPSOffsetMarkerVisible ? selectedProductIds : [])] as string[]
    return layerArray.reduce((allLayers, id) => {
      if (id !== undefined) {
        let layers: MapLayerData[]
        const isBlockByBlockAverage = id.endsWith('-block-by-block')
        if (isBlockByBlockAverage) {
          layers = mapLayers.filter(ml => `${ml.mapLayerDefId}-block-by-block` === id)
        }
        else {
          layers = mapLayers.filter(ml => ml.mapLayerDefId === id)
        }

        if (layers.length > 0) {
          allLayers.push({
            layers,
            isBlockByBlockAverage
          })
        }
      }
      return allLayers
    }, [] as { layers: MapLayerData[], isBlockByBlockAverage: boolean }[])
  }
)

export const selectSelectedMapLayersArray = createSelector(selectSelectedMapLayers, (mapLayers) =>
  Array.from(
    new Set(
      Object.values(mapLayers).reduce(
        (mls, ml) => [...mls, ...ml.layers], [] as MapLayerData[]
      )
    )
  )
)

export const selectSelectedMapLayersById = createSelector(
  selectSelectedMapLayersArray, mapLayers => indexArray(mapLayers, 'id')
)
