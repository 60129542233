export interface Emoji {
  value: string
  description: string
  category: string
  aliases: string[]
  keys: string[]
  unicode_version: string
  ios_version: string
  skin_tones?: boolean
}

const rawEmojis: Emoji[] = [
  {
    value: '😀',
    description: 'grinning face',
    category: 'Smileys & Emotion',
    aliases: ['grinning'],
    keys: ['smile', 'happy'],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😃',
    description: 'grinning face with big eyes',
    category: 'Smileys & Emotion',
    aliases: ['smiley'],
    keys: ['happy', 'joy', 'haha'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😄',
    description: 'grinning face with smiling eyes',
    category: 'Smileys & Emotion',
    aliases: ['smile'],
    keys: ['happy', 'joy', 'laugh', 'pleased'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😁',
    description: 'beaming face with smiling eyes',
    category: 'Smileys & Emotion',
    aliases: ['grin'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😆',
    description: 'grinning squinting face',
    category: 'Smileys & Emotion',
    aliases: ['laughing', 'satisfied'],
    keys: ['happy', 'haha'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😅',
    description: 'grinning face with sweat',
    category: 'Smileys & Emotion',
    aliases: ['sweat_smile'],
    keys: ['hot'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤣',
    description: 'rolling on the floor laughing',
    category: 'Smileys & Emotion',
    aliases: ['rofl'],
    keys: ['lol', 'laughing'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '😂',
    description: 'face with tears of joy',
    category: 'Smileys & Emotion',
    aliases: ['joy'],
    keys: ['tears'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🙂',
    description: 'slightly smiling face',
    category: 'Smileys & Emotion',
    aliases: ['slightly_smiling_face'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🙃',
    description: 'upside-down face',
    category: 'Smileys & Emotion',
    aliases: ['upside_down_face'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🫠',
    description: 'melting face',
    category: 'Smileys & Emotion',
    aliases: ['melting_face'],
    keys: ['sarcasm', 'dread'],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '😉',
    description: 'winking face',
    category: 'Smileys & Emotion',
    aliases: ['wink'],
    keys: ['flirt'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😊',
    description: 'smiling face with smiling eyes',
    category: 'Smileys & Emotion',
    aliases: ['blush'],
    keys: ['proud'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😇',
    description: 'smiling face with halo',
    category: 'Smileys & Emotion',
    aliases: ['innocent'],
    keys: ['angel'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥰',
    description: 'smiling face with hearts',
    category: 'Smileys & Emotion',
    aliases: ['smiling_face_with_three_hearts'],
    keys: ['love'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '😍',
    description: 'smiling face with heart-eyes',
    category: 'Smileys & Emotion',
    aliases: ['heart_eyes'],
    keys: ['love', 'crush'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤩',
    description: 'star-struck',
    category: 'Smileys & Emotion',
    aliases: ['star_struck'],
    keys: ['eyes'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '😘',
    description: 'face blowing a kiss',
    category: 'Smileys & Emotion',
    aliases: ['kissing_heart'],
    keys: ['flirt'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😗',
    description: 'kissing face',
    category: 'Smileys & Emotion',
    aliases: ['kissing'],
    keys: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '☺️',
    description: 'smiling face',
    category: 'Smileys & Emotion',
    aliases: ['relaxed'],
    keys: ['blush', 'pleased'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '😚',
    description: 'kissing face with closed eyes',
    category: 'Smileys & Emotion',
    aliases: ['kissing_closed_eyes'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😙',
    description: 'kissing face with smiling eyes',
    category: 'Smileys & Emotion',
    aliases: ['kissing_smiling_eyes'],
    keys: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '🥲',
    description: 'smiling face with tear',
    category: 'Smileys & Emotion',
    aliases: ['smiling_face_with_tear'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '😋',
    description: 'face savoring food',
    category: 'Smileys & Emotion',
    aliases: ['yum'],
    keys: ['tongue', 'lick'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😛',
    description: 'face with tongue',
    category: 'Smileys & Emotion',
    aliases: ['stuck_out_tongue'],
    keys: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😜',
    description: 'winking face with tongue',
    category: 'Smileys & Emotion',
    aliases: ['stuck_out_tongue_winking_eye'],
    keys: ['prank', 'silly'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤪',
    description: 'zany face',
    category: 'Smileys & Emotion',
    aliases: ['zany_face'],
    keys: ['goofy', 'wacky'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '😝',
    description: 'squinting face with tongue',
    category: 'Smileys & Emotion',
    aliases: ['stuck_out_tongue_closed_eyes'],
    keys: ['prank'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤑',
    description: 'money-mouth face',
    category: 'Smileys & Emotion',
    aliases: ['money_mouth_face'],
    keys: ['rich'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🤗',
    description: 'smiling face with open hands',
    category: 'Smileys & Emotion',
    aliases: ['hugs'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🤭',
    description: 'face with hand over mouth',
    category: 'Smileys & Emotion',
    aliases: ['hand_over_mouth'],
    keys: ['quiet', 'whoops'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🫢',
    description: 'face with open eyes and hand over mouth',
    category: 'Smileys & Emotion',
    aliases: ['face_with_open_eyes_and_hand_over_mouth'],
    keys: ['gasp', 'shock'],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🫣',
    description: 'face with peeking eye',
    category: 'Smileys & Emotion',
    aliases: ['face_with_peeking_eye'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🤫',
    description: 'shushing face',
    category: 'Smileys & Emotion',
    aliases: ['shushing_face'],
    keys: ['silence', 'quiet'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🤔',
    description: 'thinking face',
    category: 'Smileys & Emotion',
    aliases: ['thinking'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🫡',
    description: 'saluting face',
    category: 'Smileys & Emotion',
    aliases: ['saluting_face'],
    keys: ['respect'],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🤐',
    description: 'zipper-mouth face',
    category: 'Smileys & Emotion',
    aliases: ['zipper_mouth_face'],
    keys: ['silence', 'hush'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🤨',
    description: 'face with raised eyebrow',
    category: 'Smileys & Emotion',
    aliases: ['raised_eyebrow'],
    keys: ['suspicious'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '😐',
    description: 'neutral face',
    category: 'Smileys & Emotion',
    aliases: ['neutral_face'],
    keys: ['meh'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😑',
    description: 'expressionless face',
    category: 'Smileys & Emotion',
    aliases: ['expressionless'],
    keys: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😶',
    description: 'face without mouth',
    category: 'Smileys & Emotion',
    aliases: ['no_mouth'],
    keys: ['mute', 'silence'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫥',
    description: 'dotted line face',
    category: 'Smileys & Emotion',
    aliases: ['dotted_line_face'],
    keys: ['invisible'],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '😶‍🌫️',
    description: 'face in clouds',
    category: 'Smileys & Emotion',
    aliases: ['face_in_clouds'],
    keys: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    value: '😏',
    description: 'smirking face',
    category: 'Smileys & Emotion',
    aliases: ['smirk'],
    keys: ['smug'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😒',
    description: 'unamused face',
    category: 'Smileys & Emotion',
    aliases: ['unamused'],
    keys: ['meh'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🙄',
    description: 'face with rolling eyes',
    category: 'Smileys & Emotion',
    aliases: ['roll_eyes'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '😬',
    description: 'grimacing face',
    category: 'Smileys & Emotion',
    aliases: ['grimacing'],
    keys: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😮‍💨',
    description: 'face exhaling',
    category: 'Smileys & Emotion',
    aliases: ['face_exhaling'],
    keys: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    value: '🤥',
    description: 'lying face',
    category: 'Smileys & Emotion',
    aliases: ['lying_face'],
    keys: ['liar'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🫨',
    description: 'shaking face',
    category: 'Smileys & Emotion',
    aliases: ['shaking_face'],
    keys: ['shock'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '😌',
    description: 'relieved face',
    category: 'Smileys & Emotion',
    aliases: ['relieved'],
    keys: ['whew'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😔',
    description: 'pensive face',
    category: 'Smileys & Emotion',
    aliases: ['pensive'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😪',
    description: 'sleepy face',
    category: 'Smileys & Emotion',
    aliases: ['sleepy'],
    keys: ['tired'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤤',
    description: 'drooling face',
    category: 'Smileys & Emotion',
    aliases: ['drooling_face'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '😴',
    description: 'sleeping face',
    category: 'Smileys & Emotion',
    aliases: ['sleeping'],
    keys: ['zzz'],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😷',
    description: 'face with medical mask',
    category: 'Smileys & Emotion',
    aliases: ['mask'],
    keys: ['sick', 'ill'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤒',
    description: 'face with thermometer',
    category: 'Smileys & Emotion',
    aliases: ['face_with_thermometer'],
    keys: ['sick'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🤕',
    description: 'face with head-bandage',
    category: 'Smileys & Emotion',
    aliases: ['face_with_head_bandage'],
    keys: ['hurt'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🤢',
    description: 'nauseated face',
    category: 'Smileys & Emotion',
    aliases: ['nauseated_face'],
    keys: ['sick', 'barf', 'disgusted'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🤮',
    description: 'face vomiting',
    category: 'Smileys & Emotion',
    aliases: ['vomiting_face'],
    keys: ['barf', 'sick'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🤧',
    description: 'sneezing face',
    category: 'Smileys & Emotion',
    aliases: ['sneezing_face'],
    keys: ['achoo', 'sick'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥵',
    description: 'hot face',
    category: 'Smileys & Emotion',
    aliases: ['hot_face'],
    keys: ['heat', 'sweating'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥶',
    description: 'cold face',
    category: 'Smileys & Emotion',
    aliases: ['cold_face'],
    keys: ['freezing', 'ice'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥴',
    description: 'woozy face',
    category: 'Smileys & Emotion',
    aliases: ['woozy_face'],
    keys: ['groggy'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '😵',
    description: 'face with crossed-out eyes',
    category: 'Smileys & Emotion',
    aliases: ['dizzy_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😵‍💫',
    description: 'face with spiral eyes',
    category: 'Smileys & Emotion',
    aliases: ['face_with_spiral_eyes'],
    keys: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    value: '🤯',
    description: 'exploding head',
    category: 'Smileys & Emotion',
    aliases: ['exploding_head'],
    keys: ['mind', 'blown'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🤠',
    description: 'cowboy hat face',
    category: 'Smileys & Emotion',
    aliases: ['cowboy_hat_face'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥳',
    description: 'partying face',
    category: 'Smileys & Emotion',
    aliases: ['partying_face'],
    keys: ['celebration', 'birthday'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥸',
    description: 'disguised face',
    category: 'Smileys & Emotion',
    aliases: ['disguised_face'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '😎',
    description: 'smiling face with sunglasses',
    category: 'Smileys & Emotion',
    aliases: ['sunglasses'],
    keys: ['cool'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤓',
    description: 'nerd face',
    category: 'Smileys & Emotion',
    aliases: ['nerd_face'],
    keys: ['geek', 'glasses'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🧐',
    description: 'face with monocle',
    category: 'Smileys & Emotion',
    aliases: ['monocle_face'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '😕',
    description: 'confused face',
    category: 'Smileys & Emotion',
    aliases: ['confused'],
    keys: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '🫤',
    description: 'face with diagonal mouth',
    category: 'Smileys & Emotion',
    aliases: ['face_with_diagonal_mouth'],
    keys: ['confused'],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '😟',
    description: 'worried face',
    category: 'Smileys & Emotion',
    aliases: ['worried'],
    keys: ['nervous'],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '🙁',
    description: 'slightly frowning face',
    category: 'Smileys & Emotion',
    aliases: ['slightly_frowning_face'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '☹️',
    description: 'frowning face',
    category: 'Smileys & Emotion',
    aliases: ['frowning_face'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '😮',
    description: 'face with open mouth',
    category: 'Smileys & Emotion',
    aliases: ['open_mouth'],
    keys: ['surprise', 'impressed', 'wow'],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😯',
    description: 'hushed face',
    category: 'Smileys & Emotion',
    aliases: ['hushed'],
    keys: ['silence', 'speechless'],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😲',
    description: 'astonished face',
    category: 'Smileys & Emotion',
    aliases: ['astonished'],
    keys: ['amazed', 'gasp'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😳',
    description: 'flushed face',
    category: 'Smileys & Emotion',
    aliases: ['flushed'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥺',
    description: 'pleading face',
    category: 'Smileys & Emotion',
    aliases: ['pleading_face'],
    keys: ['puppy', 'eyes'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥹',
    description: 'face holding back tears',
    category: 'Smileys & Emotion',
    aliases: ['face_holding_back_tears'],
    keys: ['tears', 'gratitude'],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '😦',
    description: 'frowning face with open mouth',
    category: 'Smileys & Emotion',
    aliases: ['frowning'],
    keys: [],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😧',
    description: 'anguished face',
    category: 'Smileys & Emotion',
    aliases: ['anguished'],
    keys: ['stunned'],
    unicode_version: '6.1',
    ios_version: '6.0',
  },
  {
    value: '😨',
    description: 'fearful face',
    category: 'Smileys & Emotion',
    aliases: ['fearful'],
    keys: ['scared', 'shocked', 'oops'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😰',
    description: 'anxious face with sweat',
    category: 'Smileys & Emotion',
    aliases: ['cold_sweat'],
    keys: ['nervous'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😥',
    description: 'sad but relieved face',
    category: 'Smileys & Emotion',
    aliases: ['disappointed_relieved'],
    keys: ['phew', 'sweat', 'nervous'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😢',
    description: 'crying face',
    category: 'Smileys & Emotion',
    aliases: ['cry'],
    keys: ['sad', 'tear'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😭',
    description: 'loudly crying face',
    category: 'Smileys & Emotion',
    aliases: ['sob'],
    keys: ['sad', 'cry', 'bawling'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😱',
    description: 'face screaming in fear',
    category: 'Smileys & Emotion',
    aliases: ['scream'],
    keys: ['horror', 'shocked'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😖',
    description: 'confounded face',
    category: 'Smileys & Emotion',
    aliases: ['confounded'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😣',
    description: 'persevering face',
    category: 'Smileys & Emotion',
    aliases: ['persevere'],
    keys: ['struggling'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😞',
    description: 'disappointed face',
    category: 'Smileys & Emotion',
    aliases: ['disappointed'],
    keys: ['sad'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😓',
    description: 'downcast face with sweat',
    category: 'Smileys & Emotion',
    aliases: ['sweat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😩',
    description: 'weary face',
    category: 'Smileys & Emotion',
    aliases: ['weary'],
    keys: ['tired'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😫',
    description: 'tired face',
    category: 'Smileys & Emotion',
    aliases: ['tired_face'],
    keys: ['upset', 'whine'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥱',
    description: 'yawning face',
    category: 'Smileys & Emotion',
    aliases: ['yawning_face'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '😤',
    description: 'face with steam from nose',
    category: 'Smileys & Emotion',
    aliases: ['triumph'],
    keys: ['smug'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😡',
    description: 'enraged face',
    category: 'Smileys & Emotion',
    aliases: ['rage', 'pout'],
    keys: ['angry'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😠',
    description: 'angry face',
    category: 'Smileys & Emotion',
    aliases: ['angry'],
    keys: ['mad', 'annoyed'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤬',
    description: 'face with symbols on mouth',
    category: 'Smileys & Emotion',
    aliases: ['cursing_face'],
    keys: ['foul'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '😈',
    description: 'smiling face with horns',
    category: 'Smileys & Emotion',
    aliases: ['smiling_imp'],
    keys: ['devil', 'evil', 'horns'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👿',
    description: 'angry face with horns',
    category: 'Smileys & Emotion',
    aliases: ['imp'],
    keys: ['angry', 'devil', 'evil', 'horns'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💀',
    description: 'skull',
    category: 'Smileys & Emotion',
    aliases: ['skull'],
    keys: ['dead', 'danger', 'poison'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '☠️',
    description: 'skull and crossbones',
    category: 'Smileys & Emotion',
    aliases: ['skull_and_crossbones'],
    keys: ['danger', 'pirate'],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '💩',
    description: 'pile of poo',
    category: 'Smileys & Emotion',
    aliases: ['hankey', 'poop', 'shit'],
    keys: ['crap'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤡',
    description: 'clown face',
    category: 'Smileys & Emotion',
    aliases: ['clown_face'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '👹',
    description: 'ogre',
    category: 'Smileys & Emotion',
    aliases: ['japanese_ogre'],
    keys: ['monster'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👺',
    description: 'goblin',
    category: 'Smileys & Emotion',
    aliases: ['japanese_goblin'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👻',
    description: 'ghost',
    category: 'Smileys & Emotion',
    aliases: ['ghost'],
    keys: ['halloween'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👽',
    description: 'alien',
    category: 'Smileys & Emotion',
    aliases: ['alien'],
    keys: ['ufo'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👾',
    description: 'alien monster',
    category: 'Smileys & Emotion',
    aliases: ['space_invader'],
    keys: ['game', 'retro'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤖',
    description: 'robot',
    category: 'Smileys & Emotion',
    aliases: ['robot'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '😺',
    description: 'grinning cat',
    category: 'Smileys & Emotion',
    aliases: ['smiley_cat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😸',
    description: 'grinning cat with smiling eyes',
    category: 'Smileys & Emotion',
    aliases: ['smile_cat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😹',
    description: 'cat with tears of joy',
    category: 'Smileys & Emotion',
    aliases: ['joy_cat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😻',
    description: 'smiling cat with heart-eyes',
    category: 'Smileys & Emotion',
    aliases: ['heart_eyes_cat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😼',
    description: 'cat with wry smile',
    category: 'Smileys & Emotion',
    aliases: ['smirk_cat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😽',
    description: 'kissing cat',
    category: 'Smileys & Emotion',
    aliases: ['kissing_cat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🙀',
    description: 'weary cat',
    category: 'Smileys & Emotion',
    aliases: ['scream_cat'],
    keys: ['horror'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😿',
    description: 'crying cat',
    category: 'Smileys & Emotion',
    aliases: ['crying_cat_face'],
    keys: ['sad', 'tear'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '😾',
    description: 'pouting cat',
    category: 'Smileys & Emotion',
    aliases: ['pouting_cat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🙈',
    description: 'see-no-evil monkey',
    category: 'Smileys & Emotion',
    aliases: ['see_no_evil'],
    keys: ['monkey', 'blind', 'ignore'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🙉',
    description: 'hear-no-evil monkey',
    category: 'Smileys & Emotion',
    aliases: ['hear_no_evil'],
    keys: ['monkey', 'deaf'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🙊',
    description: 'speak-no-evil monkey',
    category: 'Smileys & Emotion',
    aliases: ['speak_no_evil'],
    keys: ['monkey', 'mute', 'hush'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💌',
    description: 'love letter',
    category: 'Smileys & Emotion',
    aliases: ['love_letter'],
    keys: ['email', 'envelope'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💘',
    description: 'heart with arrow',
    category: 'Smileys & Emotion',
    aliases: ['cupid'],
    keys: ['love', 'heart'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💝',
    description: 'heart with ribbon',
    category: 'Smileys & Emotion',
    aliases: ['gift_heart'],
    keys: ['chocolates'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💖',
    description: 'sparkling heart',
    category: 'Smileys & Emotion',
    aliases: ['sparkling_heart'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💗',
    description: 'growing heart',
    category: 'Smileys & Emotion',
    aliases: ['heartpulse'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💓',
    description: 'beating heart',
    category: 'Smileys & Emotion',
    aliases: ['heartbeat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💞',
    description: 'revolving hearts',
    category: 'Smileys & Emotion',
    aliases: ['revolving_hearts'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💕',
    description: 'two hearts',
    category: 'Smileys & Emotion',
    aliases: ['two_hearts'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💟',
    description: 'heart decoration',
    category: 'Smileys & Emotion',
    aliases: ['heart_decoration'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '❣️',
    description: 'heart exclamation',
    category: 'Smileys & Emotion',
    aliases: ['heavy_heart_exclamation'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '💔',
    description: 'broken heart',
    category: 'Smileys & Emotion',
    aliases: ['broken_heart'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '❤️‍🔥',
    description: 'heart on fire',
    category: 'Smileys & Emotion',
    aliases: ['heart_on_fire'],
    keys: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    value: '❤️‍🩹',
    description: 'mending heart',
    category: 'Smileys & Emotion',
    aliases: ['mending_heart'],
    keys: [],
    unicode_version: '13.1',
    ios_version: '14.0',
  },
  {
    value: '❤️',
    description: 'red heart',
    category: 'Smileys & Emotion',
    aliases: ['heart'],
    keys: ['love'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🩷',
    description: 'pink heart',
    category: 'Smileys & Emotion',
    aliases: ['pink_heart'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🧡',
    description: 'orange heart',
    category: 'Smileys & Emotion',
    aliases: ['orange_heart'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '💛',
    description: 'yellow heart',
    category: 'Smileys & Emotion',
    aliases: ['yellow_heart'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💚',
    description: 'green heart',
    category: 'Smileys & Emotion',
    aliases: ['green_heart'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💙',
    description: 'blue heart',
    category: 'Smileys & Emotion',
    aliases: ['blue_heart'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🩵',
    description: 'light blue heart',
    category: 'Smileys & Emotion',
    aliases: ['light_blue_heart'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '💜',
    description: 'purple heart',
    category: 'Smileys & Emotion',
    aliases: ['purple_heart'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤎',
    description: 'brown heart',
    category: 'Smileys & Emotion',
    aliases: ['brown_heart'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🖤',
    description: 'black heart',
    category: 'Smileys & Emotion',
    aliases: ['black_heart'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🩶',
    description: 'grey heart',
    category: 'Smileys & Emotion',
    aliases: ['grey_heart'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🤍',
    description: 'white heart',
    category: 'Smileys & Emotion',
    aliases: ['white_heart'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '💋',
    description: 'kiss mark',
    category: 'Smileys & Emotion',
    aliases: ['kiss'],
    keys: ['lipstick'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💯',
    description: 'hundred points',
    category: 'Smileys & Emotion',
    aliases: ['100'],
    keys: ['score', 'perfect'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💢',
    description: 'anger symbol',
    category: 'Smileys & Emotion',
    aliases: ['anger'],
    keys: ['angry'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💥',
    description: 'collision',
    category: 'Smileys & Emotion',
    aliases: ['boom', 'collision'],
    keys: ['explode'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💫',
    description: 'dizzy',
    category: 'Smileys & Emotion',
    aliases: ['dizzy'],
    keys: ['star'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💦',
    description: 'sweat droplets',
    category: 'Smileys & Emotion',
    aliases: ['sweat_drops'],
    keys: ['water', 'workout'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💨',
    description: 'dashing away',
    category: 'Smileys & Emotion',
    aliases: ['dash'],
    keys: ['wind', 'blow', 'fast'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕳️',
    description: 'hole',
    category: 'Smileys & Emotion',
    aliases: ['hole'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '💬',
    description: 'speech balloon',
    category: 'Smileys & Emotion',
    aliases: ['speech_balloon'],
    keys: ['comment'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👁️‍🗨️',
    description: 'eye in speech bubble',
    category: 'Smileys & Emotion',
    aliases: ['eye_speech_bubble'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🗨️',
    description: 'left speech bubble',
    category: 'Smileys & Emotion',
    aliases: ['left_speech_bubble'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🗯️',
    description: 'right anger bubble',
    category: 'Smileys & Emotion',
    aliases: ['right_anger_bubble'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '💭',
    description: 'thought balloon',
    category: 'Smileys & Emotion',
    aliases: ['thought_balloon'],
    keys: ['thinking'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💤',
    description: 'ZZZ',
    category: 'Smileys & Emotion',
    aliases: ['zzz'],
    keys: ['sleeping'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👋',
    description: 'waving hand',
    category: 'People & Body',
    aliases: ['wave'],
    keys: ['goodbye'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🤚',
    description: 'raised back of hand',
    category: 'People & Body',
    aliases: ['raised_back_of_hand'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🖐️',
    description: 'hand with fingers splayed',
    category: 'People & Body',
    aliases: ['raised_hand_with_fingers_splayed'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '✋',
    description: 'raised hand',
    category: 'People & Body',
    aliases: ['hand', 'raised_hand'],
    keys: ['highfive', 'stop'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🖖',
    description: 'vulcan salute',
    category: 'People & Body',
    aliases: ['vulcan_salute'],
    keys: ['prosper', 'spock'],
    unicode_version: '7.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    value: '🫱',
    description: 'rightwards hand',
    category: 'People & Body',
    aliases: ['rightwards_hand'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '🫲',
    description: 'leftwards hand',
    category: 'People & Body',
    aliases: ['leftwards_hand'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '🫳',
    description: 'palm down hand',
    category: 'People & Body',
    aliases: ['palm_down_hand'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '🫴',
    description: 'palm up hand',
    category: 'People & Body',
    aliases: ['palm_up_hand'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '🫷',
    description: 'leftwards pushing hand',
    category: 'People & Body',
    aliases: ['leftwards_pushing_hand'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
    skin_tones: true,
  },
  {
    value: '🫸',
    description: 'rightwards pushing hand',
    category: 'People & Body',
    aliases: ['rightwards_pushing_hand'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
    skin_tones: true,
  },
  {
    value: '👌',
    description: 'OK hand',
    category: 'People & Body',
    aliases: ['ok_hand'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🤌',
    description: 'pinched fingers',
    category: 'People & Body',
    aliases: ['pinched_fingers'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '🤏',
    description: 'pinching hand',
    category: 'People & Body',
    aliases: ['pinching_hand'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '✌️',
    description: 'victory hand',
    category: 'People & Body',
    aliases: ['v'],
    keys: ['victory', 'peace'],
    unicode_version: '',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🤞',
    description: 'crossed fingers',
    category: 'People & Body',
    aliases: ['crossed_fingers'],
    keys: ['luck', 'hopeful'],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🫰',
    description: 'hand with index finger and thumb crossed',
    category: 'People & Body',
    aliases: ['hand_with_index_finger_and_thumb_crossed'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '🤟',
    description: 'love-you gesture',
    category: 'People & Body',
    aliases: ['love_you_gesture'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤘',
    description: 'sign of the horns',
    category: 'People & Body',
    aliases: ['metal'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '🤙',
    description: 'call me hand',
    category: 'People & Body',
    aliases: ['call_me_hand'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👈',
    description: 'backhand index pointing left',
    category: 'People & Body',
    aliases: ['point_left'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👉',
    description: 'backhand index pointing right',
    category: 'People & Body',
    aliases: ['point_right'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👆',
    description: 'backhand index pointing up',
    category: 'People & Body',
    aliases: ['point_up_2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🖕',
    description: 'middle finger',
    category: 'People & Body',
    aliases: ['middle_finger', 'fu'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '👇',
    description: 'backhand index pointing down',
    category: 'People & Body',
    aliases: ['point_down'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '☝️',
    description: 'index pointing up',
    category: 'People & Body',
    aliases: ['point_up'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🫵',
    description: 'index pointing at the viewer',
    category: 'People & Body',
    aliases: ['index_pointing_at_the_viewer'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '👍',
    description: 'thumbs up',
    category: 'People & Body',
    aliases: ['+1', 'thumbsup'],
    keys: ['approve', 'ok'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👎',
    description: 'thumbs down',
    category: 'People & Body',
    aliases: ['-1', 'thumbsdown'],
    keys: ['disapprove', 'bury'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '✊',
    description: 'raised fist',
    category: 'People & Body',
    aliases: ['fist_raised', 'fist'],
    keys: ['power'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👊',
    description: 'oncoming fist',
    category: 'People & Body',
    aliases: ['fist_oncoming', 'facepunch', 'punch'],
    keys: ['attack'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🤛',
    description: 'left-facing fist',
    category: 'People & Body',
    aliases: ['fist_left'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤜',
    description: 'right-facing fist',
    category: 'People & Body',
    aliases: ['fist_right'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👏',
    description: 'clapping hands',
    category: 'People & Body',
    aliases: ['clap'],
    keys: ['praise', 'applause'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🙌',
    description: 'raising hands',
    category: 'People & Body',
    aliases: ['raised_hands'],
    keys: ['hooray'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🫶',
    description: 'heart hands',
    category: 'People & Body',
    aliases: ['heart_hands'],
    keys: ['love'],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '👐',
    description: 'open hands',
    category: 'People & Body',
    aliases: ['open_hands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🤲',
    description: 'palms up together',
    category: 'People & Body',
    aliases: ['palms_up_together'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤝',
    description: 'handshake',
    category: 'People & Body',
    aliases: ['handshake'],
    keys: ['deal'],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🙏',
    description: 'folded hands',
    category: 'People & Body',
    aliases: ['pray'],
    keys: ['please', 'hope', 'wish'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '✍️',
    description: 'writing hand',
    category: 'People & Body',
    aliases: ['writing_hand'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '💅',
    description: 'nail polish',
    category: 'People & Body',
    aliases: ['nail_care'],
    keys: ['beauty', 'manicure'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🤳',
    description: 'selfie',
    category: 'People & Body',
    aliases: ['selfie'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '💪',
    description: 'flexed biceps',
    category: 'People & Body',
    aliases: ['muscle'],
    keys: ['flex', 'bicep', 'strong', 'workout'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🦾',
    description: 'mechanical arm',
    category: 'People & Body',
    aliases: ['mechanical_arm'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🦿',
    description: 'mechanical leg',
    category: 'People & Body',
    aliases: ['mechanical_leg'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🦵',
    description: 'leg',
    category: 'People & Body',
    aliases: ['leg'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🦶',
    description: 'foot',
    category: 'People & Body',
    aliases: ['foot'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👂',
    description: 'ear',
    category: 'People & Body',
    aliases: ['ear'],
    keys: ['hear', 'sound', 'listen'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🦻',
    description: 'ear with hearing aid',
    category: 'People & Body',
    aliases: ['ear_with_hearing_aid'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '👃',
    description: 'nose',
    category: 'People & Body',
    aliases: ['nose'],
    keys: ['smell'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🧠',
    description: 'brain',
    category: 'People & Body',
    aliases: ['brain'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🫀',
    description: 'anatomical heart',
    category: 'People & Body',
    aliases: ['anatomical_heart'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🫁',
    description: 'lungs',
    category: 'People & Body',
    aliases: ['lungs'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🦷',
    description: 'tooth',
    category: 'People & Body',
    aliases: ['tooth'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦴',
    description: 'bone',
    category: 'People & Body',
    aliases: ['bone'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '👀',
    description: 'eyes',
    category: 'People & Body',
    aliases: ['eyes'],
    keys: ['look', 'see', 'watch'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👁️',
    description: 'eye',
    category: 'People & Body',
    aliases: ['eye'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '👅',
    description: 'tongue',
    category: 'People & Body',
    aliases: ['tongue'],
    keys: ['taste'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👄',
    description: 'mouth',
    category: 'People & Body',
    aliases: ['lips'],
    keys: ['kiss'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫦',
    description: 'biting lip',
    category: 'People & Body',
    aliases: ['biting_lip'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '👶',
    description: 'baby',
    category: 'People & Body',
    aliases: ['baby'],
    keys: ['child', 'newborn'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🧒',
    description: 'child',
    category: 'People & Body',
    aliases: ['child'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👦',
    description: 'boy',
    category: 'People & Body',
    aliases: ['boy'],
    keys: ['child'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👧',
    description: 'girl',
    category: 'People & Body',
    aliases: ['girl'],
    keys: ['child'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🧑',
    description: 'person',
    category: 'People & Body',
    aliases: ['adult'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👱',
    description: 'person: blond hair',
    category: 'People & Body',
    aliases: ['blond_haired_person'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👨',
    description: 'man',
    category: 'People & Body',
    aliases: ['man'],
    keys: ['mustache', 'father', 'dad'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🧔',
    description: 'person: beard',
    category: 'People & Body',
    aliases: ['bearded_person'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧔‍♂️',
    description: 'man: beard',
    category: 'People & Body',
    aliases: ['man_beard'],
    keys: [],
    unicode_version: '13.1',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '🧔‍♀️',
    description: 'woman: beard',
    category: 'People & Body',
    aliases: ['woman_beard'],
    keys: [],
    unicode_version: '13.1',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '👨‍🦰',
    description: 'man: red hair',
    category: 'People & Body',
    aliases: ['red_haired_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👨‍🦱',
    description: 'man: curly hair',
    category: 'People & Body',
    aliases: ['curly_haired_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👨‍🦳',
    description: 'man: white hair',
    category: 'People & Body',
    aliases: ['white_haired_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👨‍🦲',
    description: 'man: bald',
    category: 'People & Body',
    aliases: ['bald_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👩',
    description: 'woman',
    category: 'People & Body',
    aliases: ['woman'],
    keys: ['girls'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👩‍🦰',
    description: 'woman: red hair',
    category: 'People & Body',
    aliases: ['red_haired_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧑‍🦰',
    description: 'person: red hair',
    category: 'People & Body',
    aliases: ['person_red_hair'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👩‍🦱',
    description: 'woman: curly hair',
    category: 'People & Body',
    aliases: ['curly_haired_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧑‍🦱',
    description: 'person: curly hair',
    category: 'People & Body',
    aliases: ['person_curly_hair'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👩‍🦳',
    description: 'woman: white hair',
    category: 'People & Body',
    aliases: ['white_haired_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧑‍🦳',
    description: 'person: white hair',
    category: 'People & Body',
    aliases: ['person_white_hair'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👩‍🦲',
    description: 'woman: bald',
    category: 'People & Body',
    aliases: ['bald_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧑‍🦲',
    description: 'person: bald',
    category: 'People & Body',
    aliases: ['person_bald'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👱‍♀️',
    description: 'woman: blond hair',
    category: 'People & Body',
    aliases: ['blond_haired_woman', 'blonde_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '👱‍♂️',
    description: 'man: blond hair',
    category: 'People & Body',
    aliases: ['blond_haired_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧓',
    description: 'older person',
    category: 'People & Body',
    aliases: ['older_adult'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👴',
    description: 'old man',
    category: 'People & Body',
    aliases: ['older_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👵',
    description: 'old woman',
    category: 'People & Body',
    aliases: ['older_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🙍',
    description: 'person frowning',
    category: 'People & Body',
    aliases: ['frowning_person'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🙍‍♂️',
    description: 'man frowning',
    category: 'People & Body',
    aliases: ['frowning_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🙍‍♀️',
    description: 'woman frowning',
    category: 'People & Body',
    aliases: ['frowning_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🙎',
    description: 'person pouting',
    category: 'People & Body',
    aliases: ['pouting_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🙎‍♂️',
    description: 'man pouting',
    category: 'People & Body',
    aliases: ['pouting_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🙎‍♀️',
    description: 'woman pouting',
    category: 'People & Body',
    aliases: ['pouting_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🙅',
    description: 'person gesturing NO',
    category: 'People & Body',
    aliases: ['no_good'],
    keys: ['stop', 'halt', 'denied'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🙅‍♂️',
    description: 'man gesturing NO',
    category: 'People & Body',
    aliases: ['no_good_man', 'ng_man'],
    keys: ['stop', 'halt', 'denied'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🙅‍♀️',
    description: 'woman gesturing NO',
    category: 'People & Body',
    aliases: ['no_good_woman', 'ng_woman'],
    keys: ['stop', 'halt', 'denied'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🙆',
    description: 'person gesturing OK',
    category: 'People & Body',
    aliases: ['ok_person'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🙆‍♂️',
    description: 'man gesturing OK',
    category: 'People & Body',
    aliases: ['ok_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🙆‍♀️',
    description: 'woman gesturing OK',
    category: 'People & Body',
    aliases: ['ok_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '💁',
    description: 'person tipping hand',
    category: 'People & Body',
    aliases: ['tipping_hand_person', 'information_desk_person'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '💁‍♂️',
    description: 'man tipping hand',
    category: 'People & Body',
    aliases: ['tipping_hand_man', 'sassy_man'],
    keys: ['information'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '💁‍♀️',
    description: 'woman tipping hand',
    category: 'People & Body',
    aliases: ['tipping_hand_woman', 'sassy_woman'],
    keys: ['information'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🙋',
    description: 'person raising hand',
    category: 'People & Body',
    aliases: ['raising_hand'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🙋‍♂️',
    description: 'man raising hand',
    category: 'People & Body',
    aliases: ['raising_hand_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🙋‍♀️',
    description: 'woman raising hand',
    category: 'People & Body',
    aliases: ['raising_hand_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧏',
    description: 'deaf person',
    category: 'People & Body',
    aliases: ['deaf_person'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧏‍♂️',
    description: 'deaf man',
    category: 'People & Body',
    aliases: ['deaf_man'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧏‍♀️',
    description: 'deaf woman',
    category: 'People & Body',
    aliases: ['deaf_woman'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🙇',
    description: 'person bowing',
    category: 'People & Body',
    aliases: ['bow'],
    keys: ['respect', 'thanks'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🙇‍♂️',
    description: 'man bowing',
    category: 'People & Body',
    aliases: ['bowing_man'],
    keys: ['respect', 'thanks'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🙇‍♀️',
    description: 'woman bowing',
    category: 'People & Body',
    aliases: ['bowing_woman'],
    keys: ['respect', 'thanks'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🤦',
    description: 'person facepalming',
    category: 'People & Body',
    aliases: ['facepalm'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤦‍♂️',
    description: 'man facepalming',
    category: 'People & Body',
    aliases: ['man_facepalming'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤦‍♀️',
    description: 'woman facepalming',
    category: 'People & Body',
    aliases: ['woman_facepalming'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤷',
    description: 'person shrugging',
    category: 'People & Body',
    aliases: ['shrug'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤷‍♂️',
    description: 'man shrugging',
    category: 'People & Body',
    aliases: ['man_shrugging'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤷‍♀️',
    description: 'woman shrugging',
    category: 'People & Body',
    aliases: ['woman_shrugging'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍⚕️',
    description: 'health worker',
    category: 'People & Body',
    aliases: ['health_worker'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍⚕️',
    description: 'man health worker',
    category: 'People & Body',
    aliases: ['man_health_worker'],
    keys: ['doctor', 'nurse'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍⚕️',
    description: 'woman health worker',
    category: 'People & Body',
    aliases: ['woman_health_worker'],
    keys: ['doctor', 'nurse'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🎓',
    description: 'student',
    category: 'People & Body',
    aliases: ['student'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🎓',
    description: 'man student',
    category: 'People & Body',
    aliases: ['man_student'],
    keys: ['graduation'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🎓',
    description: 'woman student',
    category: 'People & Body',
    aliases: ['woman_student'],
    keys: ['graduation'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🏫',
    description: 'teacher',
    category: 'People & Body',
    aliases: ['teacher'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🏫',
    description: 'man teacher',
    category: 'People & Body',
    aliases: ['man_teacher'],
    keys: ['school', 'professor'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🏫',
    description: 'woman teacher',
    category: 'People & Body',
    aliases: ['woman_teacher'],
    keys: ['school', 'professor'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍⚖️',
    description: 'judge',
    category: 'People & Body',
    aliases: ['judge'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍⚖️',
    description: 'man judge',
    category: 'People & Body',
    aliases: ['man_judge'],
    keys: ['justice'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍⚖️',
    description: 'woman judge',
    category: 'People & Body',
    aliases: ['woman_judge'],
    keys: ['justice'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🌾',
    description: 'farmer',
    category: 'People & Body',
    aliases: ['farmer'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🌾',
    description: 'man farmer',
    category: 'People & Body',
    aliases: ['man_farmer'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🌾',
    description: 'woman farmer',
    category: 'People & Body',
    aliases: ['woman_farmer'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🍳',
    description: 'cook',
    category: 'People & Body',
    aliases: ['cook'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🍳',
    description: 'man cook',
    category: 'People & Body',
    aliases: ['man_cook'],
    keys: ['chef'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🍳',
    description: 'woman cook',
    category: 'People & Body',
    aliases: ['woman_cook'],
    keys: ['chef'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🔧',
    description: 'mechanic',
    category: 'People & Body',
    aliases: ['mechanic'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🔧',
    description: 'man mechanic',
    category: 'People & Body',
    aliases: ['man_mechanic'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🔧',
    description: 'woman mechanic',
    category: 'People & Body',
    aliases: ['woman_mechanic'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🏭',
    description: 'factory worker',
    category: 'People & Body',
    aliases: ['factory_worker'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🏭',
    description: 'man factory worker',
    category: 'People & Body',
    aliases: ['man_factory_worker'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🏭',
    description: 'woman factory worker',
    category: 'People & Body',
    aliases: ['woman_factory_worker'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍💼',
    description: 'office worker',
    category: 'People & Body',
    aliases: ['office_worker'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍💼',
    description: 'man office worker',
    category: 'People & Body',
    aliases: ['man_office_worker'],
    keys: ['business'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍💼',
    description: 'woman office worker',
    category: 'People & Body',
    aliases: ['woman_office_worker'],
    keys: ['business'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🔬',
    description: 'scientist',
    category: 'People & Body',
    aliases: ['scientist'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🔬',
    description: 'man scientist',
    category: 'People & Body',
    aliases: ['man_scientist'],
    keys: ['research'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🔬',
    description: 'woman scientist',
    category: 'People & Body',
    aliases: ['woman_scientist'],
    keys: ['research'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍💻',
    description: 'technologist',
    category: 'People & Body',
    aliases: ['technologist'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍💻',
    description: 'man technologist',
    category: 'People & Body',
    aliases: ['man_technologist'],
    keys: ['coder'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍💻',
    description: 'woman technologist',
    category: 'People & Body',
    aliases: ['woman_technologist'],
    keys: ['coder'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🎤',
    description: 'singer',
    category: 'People & Body',
    aliases: ['singer'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🎤',
    description: 'man singer',
    category: 'People & Body',
    aliases: ['man_singer'],
    keys: ['rockstar'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🎤',
    description: 'woman singer',
    category: 'People & Body',
    aliases: ['woman_singer'],
    keys: ['rockstar'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🎨',
    description: 'artist',
    category: 'People & Body',
    aliases: ['artist'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🎨',
    description: 'man artist',
    category: 'People & Body',
    aliases: ['man_artist'],
    keys: ['painter'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🎨',
    description: 'woman artist',
    category: 'People & Body',
    aliases: ['woman_artist'],
    keys: ['painter'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍✈️',
    description: 'pilot',
    category: 'People & Body',
    aliases: ['pilot'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍✈️',
    description: 'man pilot',
    category: 'People & Body',
    aliases: ['man_pilot'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍✈️',
    description: 'woman pilot',
    category: 'People & Body',
    aliases: ['woman_pilot'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🚀',
    description: 'astronaut',
    category: 'People & Body',
    aliases: ['astronaut'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🚀',
    description: 'man astronaut',
    category: 'People & Body',
    aliases: ['man_astronaut'],
    keys: ['space'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🚀',
    description: 'woman astronaut',
    category: 'People & Body',
    aliases: ['woman_astronaut'],
    keys: ['space'],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🚒',
    description: 'firefighter',
    category: 'People & Body',
    aliases: ['firefighter'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🚒',
    description: 'man firefighter',
    category: 'People & Body',
    aliases: ['man_firefighter'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👩‍🚒',
    description: 'woman firefighter',
    category: 'People & Body',
    aliases: ['woman_firefighter'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👮',
    description: 'police officer',
    category: 'People & Body',
    aliases: ['police_officer', 'cop'],
    keys: ['law'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👮‍♂️',
    description: 'man police officer',
    category: 'People & Body',
    aliases: ['policeman'],
    keys: ['law', 'cop'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👮‍♀️',
    description: 'woman police officer',
    category: 'People & Body',
    aliases: ['policewoman'],
    keys: ['law', 'cop'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🕵️',
    description: 'detective',
    category: 'People & Body',
    aliases: ['detective'],
    keys: ['sleuth'],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '🕵️‍♂️',
    description: 'man detective',
    category: 'People & Body',
    aliases: ['male_detective'],
    keys: ['sleuth'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🕵️‍♀️',
    description: 'woman detective',
    category: 'People & Body',
    aliases: ['female_detective'],
    keys: ['sleuth'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '💂',
    description: 'guard',
    category: 'People & Body',
    aliases: ['guard'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '💂‍♂️',
    description: 'man guard',
    category: 'People & Body',
    aliases: ['guardsman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '💂‍♀️',
    description: 'woman guard',
    category: 'People & Body',
    aliases: ['guardswoman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🥷',
    description: 'ninja',
    category: 'People & Body',
    aliases: ['ninja'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '👷',
    description: 'construction worker',
    category: 'People & Body',
    aliases: ['construction_worker'],
    keys: ['helmet'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👷‍♂️',
    description: 'man construction worker',
    category: 'People & Body',
    aliases: ['construction_worker_man'],
    keys: ['helmet'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👷‍♀️',
    description: 'woman construction worker',
    category: 'People & Body',
    aliases: ['construction_worker_woman'],
    keys: ['helmet'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🫅',
    description: 'person with crown',
    category: 'People & Body',
    aliases: ['person_with_crown'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '🤴',
    description: 'prince',
    category: 'People & Body',
    aliases: ['prince'],
    keys: ['crown', 'royal'],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '👸',
    description: 'princess',
    category: 'People & Body',
    aliases: ['princess'],
    keys: ['crown', 'royal'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👳',
    description: 'person wearing turban',
    category: 'People & Body',
    aliases: ['person_with_turban'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👳‍♂️',
    description: 'man wearing turban',
    category: 'People & Body',
    aliases: ['man_with_turban'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👳‍♀️',
    description: 'woman wearing turban',
    category: 'People & Body',
    aliases: ['woman_with_turban'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '👲',
    description: 'person with skullcap',
    category: 'People & Body',
    aliases: ['man_with_gua_pi_mao'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🧕',
    description: 'woman with headscarf',
    category: 'People & Body',
    aliases: ['woman_with_headscarf'],
    keys: ['hijab'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤵',
    description: 'person in tuxedo',
    category: 'People & Body',
    aliases: ['person_in_tuxedo'],
    keys: ['groom', 'marriage', 'wedding'],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤵‍♂️',
    description: 'man in tuxedo',
    category: 'People & Body',
    aliases: ['man_in_tuxedo'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '🤵‍♀️',
    description: 'woman in tuxedo',
    category: 'People & Body',
    aliases: ['woman_in_tuxedo'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '👰',
    description: 'person with veil',
    category: 'People & Body',
    aliases: ['person_with_veil'],
    keys: ['marriage', 'wedding'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👰‍♂️',
    description: 'man with veil',
    category: 'People & Body',
    aliases: ['man_with_veil'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '👰‍♀️',
    description: 'woman with veil',
    category: 'People & Body',
    aliases: ['woman_with_veil', 'bride_with_veil'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '🤰',
    description: 'pregnant woman',
    category: 'People & Body',
    aliases: ['pregnant_woman'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🫃',
    description: 'pregnant man',
    category: 'People & Body',
    aliases: ['pregnant_man'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '🫄',
    description: 'pregnant person',
    category: 'People & Body',
    aliases: ['pregnant_person'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
    skin_tones: true,
  },
  {
    value: '🤱',
    description: 'breast-feeding',
    category: 'People & Body',
    aliases: ['breast_feeding'],
    keys: ['nursing'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👩‍🍼',
    description: 'woman feeding baby',
    category: 'People & Body',
    aliases: ['woman_feeding_baby'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '👨‍🍼',
    description: 'man feeding baby',
    category: 'People & Body',
    aliases: ['man_feeding_baby'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '🧑‍🍼',
    description: 'person feeding baby',
    category: 'People & Body',
    aliases: ['person_feeding_baby'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '👼',
    description: 'baby angel',
    category: 'People & Body',
    aliases: ['angel'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🎅',
    description: 'Santa Claus',
    category: 'People & Body',
    aliases: ['santa'],
    keys: ['christmas'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🤶',
    description: 'Mrs. Claus',
    category: 'People & Body',
    aliases: ['mrs_claus'],
    keys: ['santa'],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧑‍🎄',
    description: 'mx claus',
    category: 'People & Body',
    aliases: ['mx_claus'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
    skin_tones: true,
  },
  {
    value: '🦸',
    description: 'superhero',
    category: 'People & Body',
    aliases: ['superhero'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🦸‍♂️',
    description: 'man superhero',
    category: 'People & Body',
    aliases: ['superhero_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🦸‍♀️',
    description: 'woman superhero',
    category: 'People & Body',
    aliases: ['superhero_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🦹',
    description: 'supervillain',
    category: 'People & Body',
    aliases: ['supervillain'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🦹‍♂️',
    description: 'man supervillain',
    category: 'People & Body',
    aliases: ['supervillain_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🦹‍♀️',
    description: 'woman supervillain',
    category: 'People & Body',
    aliases: ['supervillain_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧙',
    description: 'mage',
    category: 'People & Body',
    aliases: ['mage'],
    keys: ['wizard'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧙‍♂️',
    description: 'man mage',
    category: 'People & Body',
    aliases: ['mage_man'],
    keys: ['wizard'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧙‍♀️',
    description: 'woman mage',
    category: 'People & Body',
    aliases: ['mage_woman'],
    keys: ['wizard'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧚',
    description: 'fairy',
    category: 'People & Body',
    aliases: ['fairy'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧚‍♂️',
    description: 'man fairy',
    category: 'People & Body',
    aliases: ['fairy_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧚‍♀️',
    description: 'woman fairy',
    category: 'People & Body',
    aliases: ['fairy_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧛',
    description: 'vampire',
    category: 'People & Body',
    aliases: ['vampire'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧛‍♂️',
    description: 'man vampire',
    category: 'People & Body',
    aliases: ['vampire_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧛‍♀️',
    description: 'woman vampire',
    category: 'People & Body',
    aliases: ['vampire_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧜',
    description: 'merperson',
    category: 'People & Body',
    aliases: ['merperson'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧜‍♂️',
    description: 'merman',
    category: 'People & Body',
    aliases: ['merman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧜‍♀️',
    description: 'mermaid',
    category: 'People & Body',
    aliases: ['mermaid'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧝',
    description: 'elf',
    category: 'People & Body',
    aliases: ['elf'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧝‍♂️',
    description: 'man elf',
    category: 'People & Body',
    aliases: ['elf_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧝‍♀️',
    description: 'woman elf',
    category: 'People & Body',
    aliases: ['elf_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧞',
    description: 'genie',
    category: 'People & Body',
    aliases: ['genie'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧞‍♂️',
    description: 'man genie',
    category: 'People & Body',
    aliases: ['genie_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧞‍♀️',
    description: 'woman genie',
    category: 'People & Body',
    aliases: ['genie_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧟',
    description: 'zombie',
    category: 'People & Body',
    aliases: ['zombie'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧟‍♂️',
    description: 'man zombie',
    category: 'People & Body',
    aliases: ['zombie_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧟‍♀️',
    description: 'woman zombie',
    category: 'People & Body',
    aliases: ['zombie_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧌',
    description: 'troll',
    category: 'People & Body',
    aliases: ['troll'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '💆',
    description: 'person getting massage',
    category: 'People & Body',
    aliases: ['massage'],
    keys: ['spa'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '💆‍♂️',
    description: 'man getting massage',
    category: 'People & Body',
    aliases: ['massage_man'],
    keys: ['spa'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '💆‍♀️',
    description: 'woman getting massage',
    category: 'People & Body',
    aliases: ['massage_woman'],
    keys: ['spa'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '💇',
    description: 'person getting haircut',
    category: 'People & Body',
    aliases: ['haircut'],
    keys: ['beauty'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '💇‍♂️',
    description: 'man getting haircut',
    category: 'People & Body',
    aliases: ['haircut_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '💇‍♀️',
    description: 'woman getting haircut',
    category: 'People & Body',
    aliases: ['haircut_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🚶',
    description: 'person walking',
    category: 'People & Body',
    aliases: ['walking'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🚶‍♂️',
    description: 'man walking',
    category: 'People & Body',
    aliases: ['walking_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🚶‍♀️',
    description: 'woman walking',
    category: 'People & Body',
    aliases: ['walking_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🧍',
    description: 'person standing',
    category: 'People & Body',
    aliases: ['standing_person'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧍‍♂️',
    description: 'man standing',
    category: 'People & Body',
    aliases: ['standing_man'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧍‍♀️',
    description: 'woman standing',
    category: 'People & Body',
    aliases: ['standing_woman'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧎',
    description: 'person kneeling',
    category: 'People & Body',
    aliases: ['kneeling_person'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧎‍♂️',
    description: 'man kneeling',
    category: 'People & Body',
    aliases: ['kneeling_man'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧎‍♀️',
    description: 'woman kneeling',
    category: 'People & Body',
    aliases: ['kneeling_woman'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧑‍🦯',
    description: 'person with white cane',
    category: 'People & Body',
    aliases: ['person_with_probing_cane'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🦯',
    description: 'man with white cane',
    category: 'People & Body',
    aliases: ['man_with_probing_cane'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '👩‍🦯',
    description: 'woman with white cane',
    category: 'People & Body',
    aliases: ['woman_with_probing_cane'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧑‍🦼',
    description: 'person in motorized wheelchair',
    category: 'People & Body',
    aliases: ['person_in_motorized_wheelchair'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🦼',
    description: 'man in motorized wheelchair',
    category: 'People & Body',
    aliases: ['man_in_motorized_wheelchair'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '👩‍🦼',
    description: 'woman in motorized wheelchair',
    category: 'People & Body',
    aliases: ['woman_in_motorized_wheelchair'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🧑‍🦽',
    description: 'person in manual wheelchair',
    category: 'People & Body',
    aliases: ['person_in_manual_wheelchair'],
    keys: [],
    unicode_version: '12.1',
    ios_version: '13.2',
    skin_tones: true,
  },
  {
    value: '👨‍🦽',
    description: 'man in manual wheelchair',
    category: 'People & Body',
    aliases: ['man_in_manual_wheelchair'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '👩‍🦽',
    description: 'woman in manual wheelchair',
    category: 'People & Body',
    aliases: ['woman_in_manual_wheelchair'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '🏃',
    description: 'person running',
    category: 'People & Body',
    aliases: ['runner', 'running'],
    keys: ['exercise', 'workout', 'marathon'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🏃‍♂️',
    description: 'man running',
    category: 'People & Body',
    aliases: ['running_man'],
    keys: ['exercise', 'workout', 'marathon'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🏃‍♀️',
    description: 'woman running',
    category: 'People & Body',
    aliases: ['running_woman'],
    keys: ['exercise', 'workout', 'marathon'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '💃',
    description: 'woman dancing',
    category: 'People & Body',
    aliases: ['woman_dancing', 'dancer'],
    keys: ['dress'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🕺',
    description: 'man dancing',
    category: 'People & Body',
    aliases: ['man_dancing'],
    keys: ['dancer'],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🕴️',
    description: 'person in suit levitating',
    category: 'People & Body',
    aliases: ['business_suit_levitating'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '👯',
    description: 'people with bunny ears',
    category: 'People & Body',
    aliases: ['dancers'],
    keys: ['bunny'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👯‍♂️',
    description: 'men with bunny ears',
    category: 'People & Body',
    aliases: ['dancing_men'],
    keys: ['bunny'],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👯‍♀️',
    description: 'women with bunny ears',
    category: 'People & Body',
    aliases: ['dancing_women'],
    keys: ['bunny'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧖',
    description: 'person in steamy room',
    category: 'People & Body',
    aliases: ['sauna_person'],
    keys: ['steamy'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧖‍♂️',
    description: 'man in steamy room',
    category: 'People & Body',
    aliases: ['sauna_man'],
    keys: ['steamy'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧖‍♀️',
    description: 'woman in steamy room',
    category: 'People & Body',
    aliases: ['sauna_woman'],
    keys: ['steamy'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧗',
    description: 'person climbing',
    category: 'People & Body',
    aliases: ['climbing'],
    keys: ['bouldering'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧗‍♂️',
    description: 'man climbing',
    category: 'People & Body',
    aliases: ['climbing_man'],
    keys: ['bouldering'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧗‍♀️',
    description: 'woman climbing',
    category: 'People & Body',
    aliases: ['climbing_woman'],
    keys: ['bouldering'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤺',
    description: 'person fencing',
    category: 'People & Body',
    aliases: ['person_fencing'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🏇',
    description: 'horse racing',
    category: 'People & Body',
    aliases: ['horse_racing'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '⛷️',
    description: 'skier',
    category: 'People & Body',
    aliases: ['skier'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '🏂',
    description: 'snowboarder',
    category: 'People & Body',
    aliases: ['snowboarder'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🏌️',
    description: 'person golfing',
    category: 'People & Body',
    aliases: ['golfing'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '🏌️‍♂️',
    description: 'man golfing',
    category: 'People & Body',
    aliases: ['golfing_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🏌️‍♀️',
    description: 'woman golfing',
    category: 'People & Body',
    aliases: ['golfing_woman'],
    keys: [],
    unicode_version: '',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🏄',
    description: 'person surfing',
    category: 'People & Body',
    aliases: ['surfer'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🏄‍♂️',
    description: 'man surfing',
    category: 'People & Body',
    aliases: ['surfing_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🏄‍♀️',
    description: 'woman surfing',
    category: 'People & Body',
    aliases: ['surfing_woman'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🚣',
    description: 'person rowing boat',
    category: 'People & Body',
    aliases: ['rowboat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🚣‍♂️',
    description: 'man rowing boat',
    category: 'People & Body',
    aliases: ['rowing_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🚣‍♀️',
    description: 'woman rowing boat',
    category: 'People & Body',
    aliases: ['rowing_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🏊',
    description: 'person swimming',
    category: 'People & Body',
    aliases: ['swimmer'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🏊‍♂️',
    description: 'man swimming',
    category: 'People & Body',
    aliases: ['swimming_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🏊‍♀️',
    description: 'woman swimming',
    category: 'People & Body',
    aliases: ['swimming_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '⛹️',
    description: 'person bouncing ball',
    category: 'People & Body',
    aliases: ['bouncing_ball_person'],
    keys: ['basketball'],
    unicode_version: '5.2',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '⛹️‍♂️',
    description: 'man bouncing ball',
    category: 'People & Body',
    aliases: ['bouncing_ball_man', 'basketball_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '⛹️‍♀️',
    description: 'woman bouncing ball',
    category: 'People & Body',
    aliases: ['bouncing_ball_woman', 'basketball_woman'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🏋️',
    description: 'person lifting weights',
    category: 'People & Body',
    aliases: ['weight_lifting'],
    keys: ['gym', 'workout'],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '🏋️‍♂️',
    description: 'man lifting weights',
    category: 'People & Body',
    aliases: ['weight_lifting_man'],
    keys: ['gym', 'workout'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🏋️‍♀️',
    description: 'woman lifting weights',
    category: 'People & Body',
    aliases: ['weight_lifting_woman'],
    keys: ['gym', 'workout'],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🚴',
    description: 'person biking',
    category: 'People & Body',
    aliases: ['bicyclist'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🚴‍♂️',
    description: 'man biking',
    category: 'People & Body',
    aliases: ['biking_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🚴‍♀️',
    description: 'woman biking',
    category: 'People & Body',
    aliases: ['biking_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🚵',
    description: 'person mountain biking',
    category: 'People & Body',
    aliases: ['mountain_bicyclist'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🚵‍♂️',
    description: 'man mountain biking',
    category: 'People & Body',
    aliases: ['mountain_biking_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🚵‍♀️',
    description: 'woman mountain biking',
    category: 'People & Body',
    aliases: ['mountain_biking_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
    skin_tones: true,
  },
  {
    value: '🤸',
    description: 'person cartwheeling',
    category: 'People & Body',
    aliases: ['cartwheeling'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤸‍♂️',
    description: 'man cartwheeling',
    category: 'People & Body',
    aliases: ['man_cartwheeling'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤸‍♀️',
    description: 'woman cartwheeling',
    category: 'People & Body',
    aliases: ['woman_cartwheeling'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤼',
    description: 'people wrestling',
    category: 'People & Body',
    aliases: ['wrestling'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🤼‍♂️',
    description: 'men wrestling',
    category: 'People & Body',
    aliases: ['men_wrestling'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🤼‍♀️',
    description: 'women wrestling',
    category: 'People & Body',
    aliases: ['women_wrestling'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🤽',
    description: 'person playing water polo',
    category: 'People & Body',
    aliases: ['water_polo'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤽‍♂️',
    description: 'man playing water polo',
    category: 'People & Body',
    aliases: ['man_playing_water_polo'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤽‍♀️',
    description: 'woman playing water polo',
    category: 'People & Body',
    aliases: ['woman_playing_water_polo'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤾',
    description: 'person playing handball',
    category: 'People & Body',
    aliases: ['handball_person'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤾‍♂️',
    description: 'man playing handball',
    category: 'People & Body',
    aliases: ['man_playing_handball'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤾‍♀️',
    description: 'woman playing handball',
    category: 'People & Body',
    aliases: ['woman_playing_handball'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤹',
    description: 'person juggling',
    category: 'People & Body',
    aliases: ['juggling_person'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🤹‍♂️',
    description: 'man juggling',
    category: 'People & Body',
    aliases: ['man_juggling'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🤹‍♀️',
    description: 'woman juggling',
    category: 'People & Body',
    aliases: ['woman_juggling'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
    skin_tones: true,
  },
  {
    value: '🧘',
    description: 'person in lotus position',
    category: 'People & Body',
    aliases: ['lotus_position'],
    keys: ['meditation'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧘‍♂️',
    description: 'man in lotus position',
    category: 'People & Body',
    aliases: ['lotus_position_man'],
    keys: ['meditation'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🧘‍♀️',
    description: 'woman in lotus position',
    category: 'People & Body',
    aliases: ['lotus_position_woman'],
    keys: ['meditation'],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '🛀',
    description: 'person taking bath',
    category: 'People & Body',
    aliases: ['bath'],
    keys: ['shower'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '🛌',
    description: 'person in bed',
    category: 'People & Body',
    aliases: ['sleeping_bed'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
    skin_tones: true,
  },
  {
    value: '🧑‍🤝‍🧑',
    description: 'people holding hands',
    category: 'People & Body',
    aliases: ['people_holding_hands'],
    keys: ['couple', 'date'],
    unicode_version: '12.0',
    ios_version: '13.0',
    skin_tones: true,
  },
  {
    value: '👭',
    description: 'women holding hands',
    category: 'People & Body',
    aliases: ['two_women_holding_hands'],
    keys: ['couple', 'date'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👫',
    description: 'woman and man holding hands',
    category: 'People & Body',
    aliases: ['couple'],
    keys: ['date'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👬',
    description: 'men holding hands',
    category: 'People & Body',
    aliases: ['two_men_holding_hands'],
    keys: ['couple', 'date'],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '💏',
    description: 'kiss',
    category: 'People & Body',
    aliases: ['couplekiss'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👩‍❤️‍💋‍👨',
    description: 'kiss: woman, man',
    category: 'People & Body',
    aliases: ['couplekiss_man_woman'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👨‍❤️‍💋‍👨',
    description: 'kiss: man, man',
    category: 'People & Body',
    aliases: ['couplekiss_man_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    value: '👩‍❤️‍💋‍👩',
    description: 'kiss: woman, woman',
    category: 'People & Body',
    aliases: ['couplekiss_woman_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    value: '💑',
    description: 'couple with heart',
    category: 'People & Body',
    aliases: ['couple_with_heart'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
    skin_tones: true,
  },
  {
    value: '👩‍❤️‍👨',
    description: 'couple with heart: woman, man',
    category: 'People & Body',
    aliases: ['couple_with_heart_woman_man'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
    skin_tones: true,
  },
  {
    value: '👨‍❤️‍👨',
    description: 'couple with heart: man, man',
    category: 'People & Body',
    aliases: ['couple_with_heart_man_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    value: '👩‍❤️‍👩',
    description: 'couple with heart: woman, woman',
    category: 'People & Body',
    aliases: ['couple_with_heart_woman_woman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
    skin_tones: true,
  },
  {
    value: '👪',
    description: 'family',
    category: 'People & Body',
    aliases: ['family'],
    keys: ['home', 'parents', 'child'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👨‍👩‍👦',
    description: 'family: man, woman, boy',
    category: 'People & Body',
    aliases: ['family_man_woman_boy'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '👨‍👩‍👧',
    description: 'family: man, woman, girl',
    category: 'People & Body',
    aliases: ['family_man_woman_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👩‍👧‍👦',
    description: 'family: man, woman, girl, boy',
    category: 'People & Body',
    aliases: ['family_man_woman_girl_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👩‍👦‍👦',
    description: 'family: man, woman, boy, boy',
    category: 'People & Body',
    aliases: ['family_man_woman_boy_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👩‍👧‍👧',
    description: 'family: man, woman, girl, girl',
    category: 'People & Body',
    aliases: ['family_man_woman_girl_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👨‍👦',
    description: 'family: man, man, boy',
    category: 'People & Body',
    aliases: ['family_man_man_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👨‍👧',
    description: 'family: man, man, girl',
    category: 'People & Body',
    aliases: ['family_man_man_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👨‍👧‍👦',
    description: 'family: man, man, girl, boy',
    category: 'People & Body',
    aliases: ['family_man_man_girl_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👨‍👦‍👦',
    description: 'family: man, man, boy, boy',
    category: 'People & Body',
    aliases: ['family_man_man_boy_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👨‍👧‍👧',
    description: 'family: man, man, girl, girl',
    category: 'People & Body',
    aliases: ['family_man_man_girl_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👩‍👩‍👦',
    description: 'family: woman, woman, boy',
    category: 'People & Body',
    aliases: ['family_woman_woman_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👩‍👩‍👧',
    description: 'family: woman, woman, girl',
    category: 'People & Body',
    aliases: ['family_woman_woman_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👩‍👩‍👧‍👦',
    description: 'family: woman, woman, girl, boy',
    category: 'People & Body',
    aliases: ['family_woman_woman_girl_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👩‍👩‍👦‍👦',
    description: 'family: woman, woman, boy, boy',
    category: 'People & Body',
    aliases: ['family_woman_woman_boy_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👩‍👩‍👧‍👧',
    description: 'family: woman, woman, girl, girl',
    category: 'People & Body',
    aliases: ['family_woman_woman_girl_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '👨‍👦',
    description: 'family: man, boy',
    category: 'People & Body',
    aliases: ['family_man_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👨‍👦‍👦',
    description: 'family: man, boy, boy',
    category: 'People & Body',
    aliases: ['family_man_boy_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👨‍👧',
    description: 'family: man, girl',
    category: 'People & Body',
    aliases: ['family_man_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👨‍👧‍👦',
    description: 'family: man, girl, boy',
    category: 'People & Body',
    aliases: ['family_man_girl_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👨‍👧‍👧',
    description: 'family: man, girl, girl',
    category: 'People & Body',
    aliases: ['family_man_girl_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👩‍👦',
    description: 'family: woman, boy',
    category: 'People & Body',
    aliases: ['family_woman_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👩‍👦‍👦',
    description: 'family: woman, boy, boy',
    category: 'People & Body',
    aliases: ['family_woman_boy_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👩‍👧',
    description: 'family: woman, girl',
    category: 'People & Body',
    aliases: ['family_woman_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👩‍👧‍👦',
    description: 'family: woman, girl, boy',
    category: 'People & Body',
    aliases: ['family_woman_girl_boy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '👩‍👧‍👧',
    description: 'family: woman, girl, girl',
    category: 'People & Body',
    aliases: ['family_woman_girl_girl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '🗣️',
    description: 'speaking head',
    category: 'People & Body',
    aliases: ['speaking_head'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '👤',
    description: 'bust in silhouette',
    category: 'People & Body',
    aliases: ['bust_in_silhouette'],
    keys: ['user'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👥',
    description: 'busts in silhouette',
    category: 'People & Body',
    aliases: ['busts_in_silhouette'],
    keys: ['users', 'group', 'team'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫂',
    description: 'people hugging',
    category: 'People & Body',
    aliases: ['people_hugging'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '👣',
    description: 'footprints',
    category: 'People & Body',
    aliases: ['footprints'],
    keys: ['feet', 'tracks'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐵',
    description: 'monkey face',
    category: 'Animals & Nature',
    aliases: ['monkey_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐒',
    description: 'monkey',
    category: 'Animals & Nature',
    aliases: ['monkey'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦍',
    description: 'gorilla',
    category: 'Animals & Nature',
    aliases: ['gorilla'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦧',
    description: 'orangutan',
    category: 'Animals & Nature',
    aliases: ['orangutan'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🐶',
    description: 'dog face',
    category: 'Animals & Nature',
    aliases: ['dog'],
    keys: ['pet'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐕',
    description: 'dog',
    category: 'Animals & Nature',
    aliases: ['dog2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦮',
    description: 'guide dog',
    category: 'Animals & Nature',
    aliases: ['guide_dog'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🐕‍🦺',
    description: 'service dog',
    category: 'Animals & Nature',
    aliases: ['service_dog'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🐩',
    description: 'poodle',
    category: 'Animals & Nature',
    aliases: ['poodle'],
    keys: ['dog'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐺',
    description: 'wolf',
    category: 'Animals & Nature',
    aliases: ['wolf'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦊',
    description: 'fox',
    category: 'Animals & Nature',
    aliases: ['fox_face'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦝',
    description: 'raccoon',
    category: 'Animals & Nature',
    aliases: ['raccoon'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🐱',
    description: 'cat face',
    category: 'Animals & Nature',
    aliases: ['cat'],
    keys: ['pet'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐈',
    description: 'cat',
    category: 'Animals & Nature',
    aliases: ['cat2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐈‍⬛',
    description: 'black cat',
    category: 'Animals & Nature',
    aliases: ['black_cat'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🦁',
    description: 'lion',
    category: 'Animals & Nature',
    aliases: ['lion'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🐯',
    description: 'tiger face',
    category: 'Animals & Nature',
    aliases: ['tiger'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐅',
    description: 'tiger',
    category: 'Animals & Nature',
    aliases: ['tiger2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐆',
    description: 'leopard',
    category: 'Animals & Nature',
    aliases: ['leopard'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐴',
    description: 'horse face',
    category: 'Animals & Nature',
    aliases: ['horse'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫎',
    description: 'moose',
    category: 'Animals & Nature',
    aliases: ['moose'],
    keys: ['canada'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🫏',
    description: 'donkey',
    category: 'Animals & Nature',
    aliases: ['donkey'],
    keys: ['mule'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🐎',
    description: 'horse',
    category: 'Animals & Nature',
    aliases: ['racehorse'],
    keys: ['speed'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦄',
    description: 'unicorn',
    category: 'Animals & Nature',
    aliases: ['unicorn'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🦓',
    description: 'zebra',
    category: 'Animals & Nature',
    aliases: ['zebra'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦌',
    description: 'deer',
    category: 'Animals & Nature',
    aliases: ['deer'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦬',
    description: 'bison',
    category: 'Animals & Nature',
    aliases: ['bison'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🐮',
    description: 'cow face',
    category: 'Animals & Nature',
    aliases: ['cow'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐂',
    description: 'ox',
    category: 'Animals & Nature',
    aliases: ['ox'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐃',
    description: 'water buffalo',
    category: 'Animals & Nature',
    aliases: ['water_buffalo'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐄',
    description: 'cow',
    category: 'Animals & Nature',
    aliases: ['cow2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐷',
    description: 'pig face',
    category: 'Animals & Nature',
    aliases: ['pig'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐖',
    description: 'pig',
    category: 'Animals & Nature',
    aliases: ['pig2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐗',
    description: 'boar',
    category: 'Animals & Nature',
    aliases: ['boar'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐽',
    description: 'pig nose',
    category: 'Animals & Nature',
    aliases: ['pig_nose'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐏',
    description: 'ram',
    category: 'Animals & Nature',
    aliases: ['ram'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐑',
    description: 'ewe',
    category: 'Animals & Nature',
    aliases: ['sheep'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐐',
    description: 'goat',
    category: 'Animals & Nature',
    aliases: ['goat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐪',
    description: 'camel',
    category: 'Animals & Nature',
    aliases: ['dromedary_camel'],
    keys: ['desert'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐫',
    description: 'two-hump camel',
    category: 'Animals & Nature',
    aliases: ['camel'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦙',
    description: 'llama',
    category: 'Animals & Nature',
    aliases: ['llama'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦒',
    description: 'giraffe',
    category: 'Animals & Nature',
    aliases: ['giraffe'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🐘',
    description: 'elephant',
    category: 'Animals & Nature',
    aliases: ['elephant'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦣',
    description: 'mammoth',
    category: 'Animals & Nature',
    aliases: ['mammoth'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🦏',
    description: 'rhinoceros',
    category: 'Animals & Nature',
    aliases: ['rhinoceros'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦛',
    description: 'hippopotamus',
    category: 'Animals & Nature',
    aliases: ['hippopotamus'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🐭',
    description: 'mouse face',
    category: 'Animals & Nature',
    aliases: ['mouse'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐁',
    description: 'mouse',
    category: 'Animals & Nature',
    aliases: ['mouse2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐀',
    description: 'rat',
    category: 'Animals & Nature',
    aliases: ['rat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐹',
    description: 'hamster',
    category: 'Animals & Nature',
    aliases: ['hamster'],
    keys: ['pet'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐰',
    description: 'rabbit face',
    category: 'Animals & Nature',
    aliases: ['rabbit'],
    keys: ['bunny'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐇',
    description: 'rabbit',
    category: 'Animals & Nature',
    aliases: ['rabbit2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐿️',
    description: 'chipmunk',
    category: 'Animals & Nature',
    aliases: ['chipmunk'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🦫',
    description: 'beaver',
    category: 'Animals & Nature',
    aliases: ['beaver'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🦔',
    description: 'hedgehog',
    category: 'Animals & Nature',
    aliases: ['hedgehog'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦇',
    description: 'bat',
    category: 'Animals & Nature',
    aliases: ['bat'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🐻',
    description: 'bear',
    category: 'Animals & Nature',
    aliases: ['bear'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐻‍❄️',
    description: 'polar bear',
    category: 'Animals & Nature',
    aliases: ['polar_bear'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🐨',
    description: 'koala',
    category: 'Animals & Nature',
    aliases: ['koala'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐼',
    description: 'panda',
    category: 'Animals & Nature',
    aliases: ['panda_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦥',
    description: 'sloth',
    category: 'Animals & Nature',
    aliases: ['sloth'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🦦',
    description: 'otter',
    category: 'Animals & Nature',
    aliases: ['otter'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🦨',
    description: 'skunk',
    category: 'Animals & Nature',
    aliases: ['skunk'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🦘',
    description: 'kangaroo',
    category: 'Animals & Nature',
    aliases: ['kangaroo'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦡',
    description: 'badger',
    category: 'Animals & Nature',
    aliases: ['badger'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🐾',
    description: 'paw prints',
    category: 'Animals & Nature',
    aliases: ['feet', 'paw_prints'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦃',
    description: 'turkey',
    category: 'Animals & Nature',
    aliases: ['turkey'],
    keys: ['thanksgiving'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🐔',
    description: 'chicken',
    category: 'Animals & Nature',
    aliases: ['chicken'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐓',
    description: 'rooster',
    category: 'Animals & Nature',
    aliases: ['rooster'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐣',
    description: 'hatching chick',
    category: 'Animals & Nature',
    aliases: ['hatching_chick'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐤',
    description: 'baby chick',
    category: 'Animals & Nature',
    aliases: ['baby_chick'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐥',
    description: 'front-facing baby chick',
    category: 'Animals & Nature',
    aliases: ['hatched_chick'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐦',
    description: 'bird',
    category: 'Animals & Nature',
    aliases: ['bird'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐧',
    description: 'penguin',
    category: 'Animals & Nature',
    aliases: ['penguin'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕊️',
    description: 'dove',
    category: 'Animals & Nature',
    aliases: ['dove'],
    keys: ['peace'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🦅',
    description: 'eagle',
    category: 'Animals & Nature',
    aliases: ['eagle'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦆',
    description: 'duck',
    category: 'Animals & Nature',
    aliases: ['duck'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦢',
    description: 'swan',
    category: 'Animals & Nature',
    aliases: ['swan'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦉',
    description: 'owl',
    category: 'Animals & Nature',
    aliases: ['owl'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦤',
    description: 'dodo',
    category: 'Animals & Nature',
    aliases: ['dodo'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪶',
    description: 'feather',
    category: 'Animals & Nature',
    aliases: ['feather'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🦩',
    description: 'flamingo',
    category: 'Animals & Nature',
    aliases: ['flamingo'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🦚',
    description: 'peacock',
    category: 'Animals & Nature',
    aliases: ['peacock'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦜',
    description: 'parrot',
    category: 'Animals & Nature',
    aliases: ['parrot'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪽',
    description: 'wing',
    category: 'Animals & Nature',
    aliases: ['wing'],
    keys: ['fly'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🐦‍⬛',
    description: 'black bird',
    category: 'Animals & Nature',
    aliases: ['black_bird'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🪿',
    description: 'goose',
    category: 'Animals & Nature',
    aliases: ['goose'],
    keys: ['honk'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🐸',
    description: 'frog',
    category: 'Animals & Nature',
    aliases: ['frog'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐊',
    description: 'crocodile',
    category: 'Animals & Nature',
    aliases: ['crocodile'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐢',
    description: 'turtle',
    category: 'Animals & Nature',
    aliases: ['turtle'],
    keys: ['slow'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦎',
    description: 'lizard',
    category: 'Animals & Nature',
    aliases: ['lizard'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🐍',
    description: 'snake',
    category: 'Animals & Nature',
    aliases: ['snake'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐲',
    description: 'dragon face',
    category: 'Animals & Nature',
    aliases: ['dragon_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐉',
    description: 'dragon',
    category: 'Animals & Nature',
    aliases: ['dragon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦕',
    description: 'sauropod',
    category: 'Animals & Nature',
    aliases: ['sauropod'],
    keys: ['dinosaur'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦖',
    description: 'T-Rex',
    category: 'Animals & Nature',
    aliases: ['t-rex'],
    keys: ['dinosaur'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🐳',
    description: 'spouting whale',
    category: 'Animals & Nature',
    aliases: ['whale'],
    keys: ['sea'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐋',
    description: 'whale',
    category: 'Animals & Nature',
    aliases: ['whale2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐬',
    description: 'dolphin',
    category: 'Animals & Nature',
    aliases: ['dolphin', 'flipper'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦭',
    description: 'seal',
    category: 'Animals & Nature',
    aliases: ['seal'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🐟',
    description: 'fish',
    category: 'Animals & Nature',
    aliases: ['fish'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐠',
    description: 'tropical fish',
    category: 'Animals & Nature',
    aliases: ['tropical_fish'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐡',
    description: 'blowfish',
    category: 'Animals & Nature',
    aliases: ['blowfish'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦈',
    description: 'shark',
    category: 'Animals & Nature',
    aliases: ['shark'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🐙',
    description: 'octopus',
    category: 'Animals & Nature',
    aliases: ['octopus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐚',
    description: 'spiral shell',
    category: 'Animals & Nature',
    aliases: ['shell'],
    keys: ['sea', 'beach'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪸',
    description: 'coral',
    category: 'Animals & Nature',
    aliases: ['coral'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🪼',
    description: 'jellyfish',
    category: 'Animals & Nature',
    aliases: ['jellyfish'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🐌',
    description: 'snail',
    category: 'Animals & Nature',
    aliases: ['snail'],
    keys: ['slow'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦋',
    description: 'butterfly',
    category: 'Animals & Nature',
    aliases: ['butterfly'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🐛',
    description: 'bug',
    category: 'Animals & Nature',
    aliases: ['bug'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐜',
    description: 'ant',
    category: 'Animals & Nature',
    aliases: ['ant'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🐝',
    description: 'honeybee',
    category: 'Animals & Nature',
    aliases: ['bee', 'honeybee'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪲',
    description: 'beetle',
    category: 'Animals & Nature',
    aliases: ['beetle'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🐞',
    description: 'lady beetle',
    category: 'Animals & Nature',
    aliases: ['lady_beetle'],
    keys: ['bug'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🦗',
    description: 'cricket',
    category: 'Animals & Nature',
    aliases: ['cricket'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪳',
    description: 'cockroach',
    category: 'Animals & Nature',
    aliases: ['cockroach'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🕷️',
    description: 'spider',
    category: 'Animals & Nature',
    aliases: ['spider'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🕸️',
    description: 'spider web',
    category: 'Animals & Nature',
    aliases: ['spider_web'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🦂',
    description: 'scorpion',
    category: 'Animals & Nature',
    aliases: ['scorpion'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🦟',
    description: 'mosquito',
    category: 'Animals & Nature',
    aliases: ['mosquito'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪰',
    description: 'fly',
    category: 'Animals & Nature',
    aliases: ['fly'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪱',
    description: 'worm',
    category: 'Animals & Nature',
    aliases: ['worm'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🦠',
    description: 'microbe',
    category: 'Animals & Nature',
    aliases: ['microbe'],
    keys: ['germ'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '💐',
    description: 'bouquet',
    category: 'Animals & Nature',
    aliases: ['bouquet'],
    keys: ['flowers'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌸',
    description: 'cherry blossom',
    category: 'Animals & Nature',
    aliases: ['cherry_blossom'],
    keys: ['flower', 'spring'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💮',
    description: 'white flower',
    category: 'Animals & Nature',
    aliases: ['white_flower'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪷',
    description: 'lotus',
    category: 'Animals & Nature',
    aliases: ['lotus'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🏵️',
    description: 'rosette',
    category: 'Animals & Nature',
    aliases: ['rosette'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌹',
    description: 'rose',
    category: 'Animals & Nature',
    aliases: ['rose'],
    keys: ['flower'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥀',
    description: 'wilted flower',
    category: 'Animals & Nature',
    aliases: ['wilted_flower'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🌺',
    description: 'hibiscus',
    category: 'Animals & Nature',
    aliases: ['hibiscus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌻',
    description: 'sunflower',
    category: 'Animals & Nature',
    aliases: ['sunflower'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌼',
    description: 'blossom',
    category: 'Animals & Nature',
    aliases: ['blossom'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌷',
    description: 'tulip',
    category: 'Animals & Nature',
    aliases: ['tulip'],
    keys: ['flower'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪻',
    description: 'hyacinth',
    category: 'Animals & Nature',
    aliases: ['hyacinth'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🌱',
    description: 'seedling',
    category: 'Animals & Nature',
    aliases: ['seedling'],
    keys: ['plant'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪴',
    description: 'potted plant',
    category: 'Animals & Nature',
    aliases: ['potted_plant'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🌲',
    description: 'evergreen tree',
    category: 'Animals & Nature',
    aliases: ['evergreen_tree'],
    keys: ['wood'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌳',
    description: 'deciduous tree',
    category: 'Animals & Nature',
    aliases: ['deciduous_tree'],
    keys: ['wood'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌴',
    description: 'palm tree',
    category: 'Animals & Nature',
    aliases: ['palm_tree'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌵',
    description: 'cactus',
    category: 'Animals & Nature',
    aliases: ['cactus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌾',
    description: 'sheaf of rice',
    category: 'Animals & Nature',
    aliases: ['ear_of_rice'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌿',
    description: 'herb',
    category: 'Animals & Nature',
    aliases: ['herb'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '☘️',
    description: 'shamrock',
    category: 'Animals & Nature',
    aliases: ['shamrock'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🍀',
    description: 'four leaf clover',
    category: 'Animals & Nature',
    aliases: ['four_leaf_clover'],
    keys: ['luck'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍁',
    description: 'maple leaf',
    category: 'Animals & Nature',
    aliases: ['maple_leaf'],
    keys: ['canada'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍂',
    description: 'fallen leaf',
    category: 'Animals & Nature',
    aliases: ['fallen_leaf'],
    keys: ['autumn'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍃',
    description: 'leaf fluttering in wind',
    category: 'Animals & Nature',
    aliases: ['leaves'],
    keys: ['leaf'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪹',
    description: 'empty nest',
    category: 'Animals & Nature',
    aliases: ['empty_nest'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🪺',
    description: 'nest with eggs',
    category: 'Animals & Nature',
    aliases: ['nest_with_eggs'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🍄',
    description: 'mushroom',
    category: 'Animals & Nature',
    aliases: ['mushroom'],
    keys: ['fungus'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍇',
    description: 'grapes',
    category: 'Food & Drink',
    aliases: ['grapes'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍈',
    description: 'melon',
    category: 'Food & Drink',
    aliases: ['melon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍉',
    description: 'watermelon',
    category: 'Food & Drink',
    aliases: ['watermelon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍊',
    description: 'tangerine',
    category: 'Food & Drink',
    aliases: ['tangerine', 'orange', 'mandarin'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍋',
    description: 'lemon',
    category: 'Food & Drink',
    aliases: ['lemon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍌',
    description: 'banana',
    category: 'Food & Drink',
    aliases: ['banana'],
    keys: ['fruit'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍍',
    description: 'pineapple',
    category: 'Food & Drink',
    aliases: ['pineapple'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥭',
    description: 'mango',
    category: 'Food & Drink',
    aliases: ['mango'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🍎',
    description: 'red apple',
    category: 'Food & Drink',
    aliases: ['apple'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍏',
    description: 'green apple',
    category: 'Food & Drink',
    aliases: ['green_apple'],
    keys: ['fruit'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍐',
    description: 'pear',
    category: 'Food & Drink',
    aliases: ['pear'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍑',
    description: 'peach',
    category: 'Food & Drink',
    aliases: ['peach'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍒',
    description: 'cherries',
    category: 'Food & Drink',
    aliases: ['cherries'],
    keys: ['fruit'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍓',
    description: 'strawberry',
    category: 'Food & Drink',
    aliases: ['strawberry'],
    keys: ['fruit'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫐',
    description: 'blueberries',
    category: 'Food & Drink',
    aliases: ['blueberries'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🥝',
    description: 'kiwi fruit',
    category: 'Food & Drink',
    aliases: ['kiwi_fruit'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🍅',
    description: 'tomato',
    category: 'Food & Drink',
    aliases: ['tomato'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫒',
    description: 'olive',
    category: 'Food & Drink',
    aliases: ['olive'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🥥',
    description: 'coconut',
    category: 'Food & Drink',
    aliases: ['coconut'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥑',
    description: 'avocado',
    category: 'Food & Drink',
    aliases: ['avocado'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🍆',
    description: 'eggplant',
    category: 'Food & Drink',
    aliases: ['eggplant'],
    keys: ['aubergine'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥔',
    description: 'potato',
    category: 'Food & Drink',
    aliases: ['potato'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥕',
    description: 'carrot',
    category: 'Food & Drink',
    aliases: ['carrot'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🌽',
    description: 'ear of corn',
    category: 'Food & Drink',
    aliases: ['corn'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌶️',
    description: 'hot pepper',
    category: 'Food & Drink',
    aliases: ['hot_pepper'],
    keys: ['spicy'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🫑',
    description: 'bell pepper',
    category: 'Food & Drink',
    aliases: ['bell_pepper'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🥒',
    description: 'cucumber',
    category: 'Food & Drink',
    aliases: ['cucumber'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥬',
    description: 'leafy green',
    category: 'Food & Drink',
    aliases: ['leafy_green'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥦',
    description: 'broccoli',
    category: 'Food & Drink',
    aliases: ['broccoli'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧄',
    description: 'garlic',
    category: 'Food & Drink',
    aliases: ['garlic'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🧅',
    description: 'onion',
    category: 'Food & Drink',
    aliases: ['onion'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🥜',
    description: 'peanuts',
    category: 'Food & Drink',
    aliases: ['peanuts'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🫘',
    description: 'beans',
    category: 'Food & Drink',
    aliases: ['beans'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🌰',
    description: 'chestnut',
    category: 'Food & Drink',
    aliases: ['chestnut'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫚',
    description: 'ginger root',
    category: 'Food & Drink',
    aliases: ['ginger_root'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🫛',
    description: 'pea pod',
    category: 'Food & Drink',
    aliases: ['pea_pod'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🍞',
    description: 'bread',
    category: 'Food & Drink',
    aliases: ['bread'],
    keys: ['toast'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥐',
    description: 'croissant',
    category: 'Food & Drink',
    aliases: ['croissant'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥖',
    description: 'baguette bread',
    category: 'Food & Drink',
    aliases: ['baguette_bread'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🫓',
    description: 'flatbread',
    category: 'Food & Drink',
    aliases: ['flatbread'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🥨',
    description: 'pretzel',
    category: 'Food & Drink',
    aliases: ['pretzel'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥯',
    description: 'bagel',
    category: 'Food & Drink',
    aliases: ['bagel'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥞',
    description: 'pancakes',
    category: 'Food & Drink',
    aliases: ['pancakes'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🧇',
    description: 'waffle',
    category: 'Food & Drink',
    aliases: ['waffle'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🧀',
    description: 'cheese wedge',
    category: 'Food & Drink',
    aliases: ['cheese'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🍖',
    description: 'meat on bone',
    category: 'Food & Drink',
    aliases: ['meat_on_bone'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍗',
    description: 'poultry leg',
    category: 'Food & Drink',
    aliases: ['poultry_leg'],
    keys: ['meat', 'chicken'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥩',
    description: 'cut of meat',
    category: 'Food & Drink',
    aliases: ['cut_of_meat'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥓',
    description: 'bacon',
    category: 'Food & Drink',
    aliases: ['bacon'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🍔',
    description: 'hamburger',
    category: 'Food & Drink',
    aliases: ['hamburger'],
    keys: ['burger'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍟',
    description: 'french fries',
    category: 'Food & Drink',
    aliases: ['fries'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍕',
    description: 'pizza',
    category: 'Food & Drink',
    aliases: ['pizza'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌭',
    description: 'hot dog',
    category: 'Food & Drink',
    aliases: ['hotdog'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🥪',
    description: 'sandwich',
    category: 'Food & Drink',
    aliases: ['sandwich'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🌮',
    description: 'taco',
    category: 'Food & Drink',
    aliases: ['taco'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🌯',
    description: 'burrito',
    category: 'Food & Drink',
    aliases: ['burrito'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🫔',
    description: 'tamale',
    category: 'Food & Drink',
    aliases: ['tamale'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🥙',
    description: 'stuffed flatbread',
    category: 'Food & Drink',
    aliases: ['stuffed_flatbread'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🧆',
    description: 'falafel',
    category: 'Food & Drink',
    aliases: ['falafel'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🥚',
    description: 'egg',
    category: 'Food & Drink',
    aliases: ['egg'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🍳',
    description: 'cooking',
    category: 'Food & Drink',
    aliases: ['fried_egg'],
    keys: ['breakfast'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥘',
    description: 'shallow pan of food',
    category: 'Food & Drink',
    aliases: ['shallow_pan_of_food'],
    keys: ['paella', 'curry'],
    unicode_version: '',
    ios_version: '10.2',
  },
  {
    value: '🍲',
    description: 'pot of food',
    category: 'Food & Drink',
    aliases: ['stew'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫕',
    description: 'fondue',
    category: 'Food & Drink',
    aliases: ['fondue'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🥣',
    description: 'bowl with spoon',
    category: 'Food & Drink',
    aliases: ['bowl_with_spoon'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥗',
    description: 'green salad',
    category: 'Food & Drink',
    aliases: ['green_salad'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🍿',
    description: 'popcorn',
    category: 'Food & Drink',
    aliases: ['popcorn'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🧈',
    description: 'butter',
    category: 'Food & Drink',
    aliases: ['butter'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🧂',
    description: 'salt',
    category: 'Food & Drink',
    aliases: ['salt'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥫',
    description: 'canned food',
    category: 'Food & Drink',
    aliases: ['canned_food'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🍱',
    description: 'bento box',
    category: 'Food & Drink',
    aliases: ['bento'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍘',
    description: 'rice cracker',
    category: 'Food & Drink',
    aliases: ['rice_cracker'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍙',
    description: 'rice ball',
    category: 'Food & Drink',
    aliases: ['rice_ball'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍚',
    description: 'cooked rice',
    category: 'Food & Drink',
    aliases: ['rice'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍛',
    description: 'curry rice',
    category: 'Food & Drink',
    aliases: ['curry'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍜',
    description: 'steaming bowl',
    category: 'Food & Drink',
    aliases: ['ramen'],
    keys: ['noodle'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍝',
    description: 'spaghetti',
    category: 'Food & Drink',
    aliases: ['spaghetti'],
    keys: ['pasta'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍠',
    description: 'roasted sweet potato',
    category: 'Food & Drink',
    aliases: ['sweet_potato'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍢',
    description: 'oden',
    category: 'Food & Drink',
    aliases: ['oden'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍣',
    description: 'sushi',
    category: 'Food & Drink',
    aliases: ['sushi'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍤',
    description: 'fried shrimp',
    category: 'Food & Drink',
    aliases: ['fried_shrimp'],
    keys: ['tempura'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍥',
    description: 'fish cake with swirl',
    category: 'Food & Drink',
    aliases: ['fish_cake'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥮',
    description: 'moon cake',
    category: 'Food & Drink',
    aliases: ['moon_cake'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🍡',
    description: 'dango',
    category: 'Food & Drink',
    aliases: ['dango'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥟',
    description: 'dumpling',
    category: 'Food & Drink',
    aliases: ['dumpling'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥠',
    description: 'fortune cookie',
    category: 'Food & Drink',
    aliases: ['fortune_cookie'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥡',
    description: 'takeout box',
    category: 'Food & Drink',
    aliases: ['takeout_box'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦀',
    description: 'crab',
    category: 'Food & Drink',
    aliases: ['crab'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🦞',
    description: 'lobster',
    category: 'Food & Drink',
    aliases: ['lobster'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦐',
    description: 'shrimp',
    category: 'Food & Drink',
    aliases: ['shrimp'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦑',
    description: 'squid',
    category: 'Food & Drink',
    aliases: ['squid'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦪',
    description: 'oyster',
    category: 'Food & Drink',
    aliases: ['oyster'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🍦',
    description: 'soft ice cream',
    category: 'Food & Drink',
    aliases: ['icecream'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍧',
    description: 'shaved ice',
    category: 'Food & Drink',
    aliases: ['shaved_ice'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍨',
    description: 'ice cream',
    category: 'Food & Drink',
    aliases: ['ice_cream'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍩',
    description: 'doughnut',
    category: 'Food & Drink',
    aliases: ['doughnut'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍪',
    description: 'cookie',
    category: 'Food & Drink',
    aliases: ['cookie'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎂',
    description: 'birthday cake',
    category: 'Food & Drink',
    aliases: ['birthday'],
    keys: ['party'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍰',
    description: 'shortcake',
    category: 'Food & Drink',
    aliases: ['cake'],
    keys: ['dessert'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧁',
    description: 'cupcake',
    category: 'Food & Drink',
    aliases: ['cupcake'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥧',
    description: 'pie',
    category: 'Food & Drink',
    aliases: ['pie'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🍫',
    description: 'chocolate bar',
    category: 'Food & Drink',
    aliases: ['chocolate_bar'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍬',
    description: 'candy',
    category: 'Food & Drink',
    aliases: ['candy'],
    keys: ['sweet'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍭',
    description: 'lollipop',
    category: 'Food & Drink',
    aliases: ['lollipop'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍮',
    description: 'custard',
    category: 'Food & Drink',
    aliases: ['custard'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍯',
    description: 'honey pot',
    category: 'Food & Drink',
    aliases: ['honey_pot'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍼',
    description: 'baby bottle',
    category: 'Food & Drink',
    aliases: ['baby_bottle'],
    keys: ['milk'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥛',
    description: 'glass of milk',
    category: 'Food & Drink',
    aliases: ['milk_glass'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '☕',
    description: 'hot beverage',
    category: 'Food & Drink',
    aliases: ['coffee'],
    keys: ['cafe', 'espresso'],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    value: '🫖',
    description: 'teapot',
    category: 'Food & Drink',
    aliases: ['teapot'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🍵',
    description: 'teacup without handle',
    category: 'Food & Drink',
    aliases: ['tea'],
    keys: ['green', 'breakfast'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍶',
    description: 'sake',
    category: 'Food & Drink',
    aliases: ['sake'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍾',
    description: 'bottle with popping cork',
    category: 'Food & Drink',
    aliases: ['champagne'],
    keys: ['bottle', 'bubbly', 'celebration'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🍷',
    description: 'wine glass',
    category: 'Food & Drink',
    aliases: ['wine_glass'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍸',
    description: 'cocktail glass',
    category: 'Food & Drink',
    aliases: ['cocktail'],
    keys: ['drink'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍹',
    description: 'tropical drink',
    category: 'Food & Drink',
    aliases: ['tropical_drink'],
    keys: ['summer', 'vacation'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍺',
    description: 'beer mug',
    category: 'Food & Drink',
    aliases: ['beer'],
    keys: ['drink'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🍻',
    description: 'clinking beer mugs',
    category: 'Food & Drink',
    aliases: ['beers'],
    keys: ['drinks'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥂',
    description: 'clinking glasses',
    category: 'Food & Drink',
    aliases: ['clinking_glasses'],
    keys: ['cheers', 'toast'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥃',
    description: 'tumbler glass',
    category: 'Food & Drink',
    aliases: ['tumbler_glass'],
    keys: ['whisky'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🫗',
    description: 'pouring liquid',
    category: 'Food & Drink',
    aliases: ['pouring_liquid'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🥤',
    description: 'cup with straw',
    category: 'Food & Drink',
    aliases: ['cup_with_straw'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧋',
    description: 'bubble tea',
    category: 'Food & Drink',
    aliases: ['bubble_tea'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🧃',
    description: 'beverage box',
    category: 'Food & Drink',
    aliases: ['beverage_box'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🧉',
    description: 'mate',
    category: 'Food & Drink',
    aliases: ['mate'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🧊',
    description: 'ice',
    category: 'Food & Drink',
    aliases: ['ice_cube'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🥢',
    description: 'chopsticks',
    category: 'Food & Drink',
    aliases: ['chopsticks'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🍽️',
    description: 'fork and knife with plate',
    category: 'Food & Drink',
    aliases: ['plate_with_cutlery'],
    keys: ['dining', 'dinner'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🍴',
    description: 'fork and knife',
    category: 'Food & Drink',
    aliases: ['fork_and_knife'],
    keys: ['cutlery'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥄',
    description: 'spoon',
    category: 'Food & Drink',
    aliases: ['spoon'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🔪',
    description: 'kitchen knife',
    category: 'Food & Drink',
    aliases: ['hocho', 'knife'],
    keys: ['cut', 'chop'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🫙',
    description: 'jar',
    category: 'Food & Drink',
    aliases: ['jar'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🏺',
    description: 'amphora',
    category: 'Food & Drink',
    aliases: ['amphora'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🌍',
    description: 'globe showing Europe-Africa',
    category: 'Travel & Places',
    aliases: ['earth_africa'],
    keys: ['globe', 'world', 'international'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌎',
    description: 'globe showing Americas',
    category: 'Travel & Places',
    aliases: ['earth_americas'],
    keys: ['globe', 'world', 'international'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌏',
    description: 'globe showing Asia-Australia',
    category: 'Travel & Places',
    aliases: ['earth_asia'],
    keys: ['globe', 'world', 'international'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌐',
    description: 'globe with meridians',
    category: 'Travel & Places',
    aliases: ['globe_with_meridians'],
    keys: ['world', 'global', 'international'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗺️',
    description: 'world map',
    category: 'Travel & Places',
    aliases: ['world_map'],
    keys: ['travel'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🗾',
    description: 'map of Japan',
    category: 'Travel & Places',
    aliases: ['japan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧭',
    description: 'compass',
    category: 'Travel & Places',
    aliases: ['compass'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🏔️',
    description: 'snow-capped mountain',
    category: 'Travel & Places',
    aliases: ['mountain_snow'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⛰️',
    description: 'mountain',
    category: 'Travel & Places',
    aliases: ['mountain'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '🌋',
    description: 'volcano',
    category: 'Travel & Places',
    aliases: ['volcano'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗻',
    description: 'mount fuji',
    category: 'Travel & Places',
    aliases: ['mount_fuji'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏕️',
    description: 'camping',
    category: 'Travel & Places',
    aliases: ['camping'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏖️',
    description: 'beach with umbrella',
    category: 'Travel & Places',
    aliases: ['beach_umbrella'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏜️',
    description: 'desert',
    category: 'Travel & Places',
    aliases: ['desert'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏝️',
    description: 'desert island',
    category: 'Travel & Places',
    aliases: ['desert_island'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏞️',
    description: 'national park',
    category: 'Travel & Places',
    aliases: ['national_park'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏟️',
    description: 'stadium',
    category: 'Travel & Places',
    aliases: ['stadium'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏛️',
    description: 'classical building',
    category: 'Travel & Places',
    aliases: ['classical_building'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏗️',
    description: 'building construction',
    category: 'Travel & Places',
    aliases: ['building_construction'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🧱',
    description: 'brick',
    category: 'Travel & Places',
    aliases: ['bricks'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪨',
    description: 'rock',
    category: 'Travel & Places',
    aliases: ['rock'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪵',
    description: 'wood',
    category: 'Travel & Places',
    aliases: ['wood'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🛖',
    description: 'hut',
    category: 'Travel & Places',
    aliases: ['hut'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🏘️',
    description: 'houses',
    category: 'Travel & Places',
    aliases: ['houses'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏚️',
    description: 'derelict house',
    category: 'Travel & Places',
    aliases: ['derelict_house'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏠',
    description: 'house',
    category: 'Travel & Places',
    aliases: ['house'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏡',
    description: 'house with garden',
    category: 'Travel & Places',
    aliases: ['house_with_garden'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏢',
    description: 'office building',
    category: 'Travel & Places',
    aliases: ['office'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏣',
    description: 'Japanese post office',
    category: 'Travel & Places',
    aliases: ['post_office'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏤',
    description: 'post office',
    category: 'Travel & Places',
    aliases: ['european_post_office'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏥',
    description: 'hospital',
    category: 'Travel & Places',
    aliases: ['hospital'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏦',
    description: 'bank',
    category: 'Travel & Places',
    aliases: ['bank'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏨',
    description: 'hotel',
    category: 'Travel & Places',
    aliases: ['hotel'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏩',
    description: 'love hotel',
    category: 'Travel & Places',
    aliases: ['love_hotel'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏪',
    description: 'convenience store',
    category: 'Travel & Places',
    aliases: ['convenience_store'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏫',
    description: 'school',
    category: 'Travel & Places',
    aliases: ['school'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏬',
    description: 'department store',
    category: 'Travel & Places',
    aliases: ['department_store'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏭',
    description: 'factory',
    category: 'Travel & Places',
    aliases: ['factory'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏯',
    description: 'Japanese castle',
    category: 'Travel & Places',
    aliases: ['japanese_castle'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏰',
    description: 'castle',
    category: 'Travel & Places',
    aliases: ['european_castle'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💒',
    description: 'wedding',
    category: 'Travel & Places',
    aliases: ['wedding'],
    keys: ['marriage'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗼',
    description: 'Tokyo tower',
    category: 'Travel & Places',
    aliases: ['tokyo_tower'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗽',
    description: 'Statue of Liberty',
    category: 'Travel & Places',
    aliases: ['statue_of_liberty'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⛪',
    description: 'church',
    category: 'Travel & Places',
    aliases: ['church'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '🕌',
    description: 'mosque',
    category: 'Travel & Places',
    aliases: ['mosque'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🛕',
    description: 'hindu temple',
    category: 'Travel & Places',
    aliases: ['hindu_temple'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🕍',
    description: 'synagogue',
    category: 'Travel & Places',
    aliases: ['synagogue'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '⛩️',
    description: 'shinto shrine',
    category: 'Travel & Places',
    aliases: ['shinto_shrine'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '🕋',
    description: 'kaaba',
    category: 'Travel & Places',
    aliases: ['kaaba'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '⛲',
    description: 'fountain',
    category: 'Travel & Places',
    aliases: ['fountain'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '⛺',
    description: 'tent',
    category: 'Travel & Places',
    aliases: ['tent'],
    keys: ['camping'],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '🌁',
    description: 'foggy',
    category: 'Travel & Places',
    aliases: ['foggy'],
    keys: ['karl'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌃',
    description: 'night with stars',
    category: 'Travel & Places',
    aliases: ['night_with_stars'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏙️',
    description: 'cityscape',
    category: 'Travel & Places',
    aliases: ['cityscape'],
    keys: ['skyline'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌄',
    description: 'sunrise over mountains',
    category: 'Travel & Places',
    aliases: ['sunrise_over_mountains'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌅',
    description: 'sunrise',
    category: 'Travel & Places',
    aliases: ['sunrise'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌆',
    description: 'cityscape at dusk',
    category: 'Travel & Places',
    aliases: ['city_sunset'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌇',
    description: 'sunset',
    category: 'Travel & Places',
    aliases: ['city_sunrise'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌉',
    description: 'bridge at night',
    category: 'Travel & Places',
    aliases: ['bridge_at_night'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '♨️',
    description: 'hot springs',
    category: 'Travel & Places',
    aliases: ['hotsprings'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🎠',
    description: 'carousel horse',
    category: 'Travel & Places',
    aliases: ['carousel_horse'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛝',
    description: 'playground slide',
    category: 'Travel & Places',
    aliases: ['playground_slide'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🎡',
    description: 'ferris wheel',
    category: 'Travel & Places',
    aliases: ['ferris_wheel'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎢',
    description: 'roller coaster',
    category: 'Travel & Places',
    aliases: ['roller_coaster'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💈',
    description: 'barber pole',
    category: 'Travel & Places',
    aliases: ['barber'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎪',
    description: 'circus tent',
    category: 'Travel & Places',
    aliases: ['circus_tent'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚂',
    description: 'locomotive',
    category: 'Travel & Places',
    aliases: ['steam_locomotive'],
    keys: ['train'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚃',
    description: 'railway car',
    category: 'Travel & Places',
    aliases: ['railway_car'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚄',
    description: 'high-speed train',
    category: 'Travel & Places',
    aliases: ['bullettrain_side'],
    keys: ['train'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚅',
    description: 'bullet train',
    category: 'Travel & Places',
    aliases: ['bullettrain_front'],
    keys: ['train'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚆',
    description: 'train',
    category: 'Travel & Places',
    aliases: ['train2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚇',
    description: 'metro',
    category: 'Travel & Places',
    aliases: ['metro'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚈',
    description: 'light rail',
    category: 'Travel & Places',
    aliases: ['light_rail'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚉',
    description: 'station',
    category: 'Travel & Places',
    aliases: ['station'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚊',
    description: 'tram',
    category: 'Travel & Places',
    aliases: ['tram'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚝',
    description: 'monorail',
    category: 'Travel & Places',
    aliases: ['monorail'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚞',
    description: 'mountain railway',
    category: 'Travel & Places',
    aliases: ['mountain_railway'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚋',
    description: 'tram car',
    category: 'Travel & Places',
    aliases: ['train'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚌',
    description: 'bus',
    category: 'Travel & Places',
    aliases: ['bus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚍',
    description: 'oncoming bus',
    category: 'Travel & Places',
    aliases: ['oncoming_bus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚎',
    description: 'trolleybus',
    category: 'Travel & Places',
    aliases: ['trolleybus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚐',
    description: 'minibus',
    category: 'Travel & Places',
    aliases: ['minibus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚑',
    description: 'ambulance',
    category: 'Travel & Places',
    aliases: ['ambulance'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚒',
    description: 'fire engine',
    category: 'Travel & Places',
    aliases: ['fire_engine'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚓',
    description: 'police car',
    category: 'Travel & Places',
    aliases: ['police_car'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚔',
    description: 'oncoming police car',
    category: 'Travel & Places',
    aliases: ['oncoming_police_car'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚕',
    description: 'taxi',
    category: 'Travel & Places',
    aliases: ['taxi'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚖',
    description: 'oncoming taxi',
    category: 'Travel & Places',
    aliases: ['oncoming_taxi'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚗',
    description: 'automobile',
    category: 'Travel & Places',
    aliases: ['car', 'red_car'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚘',
    description: 'oncoming automobile',
    category: 'Travel & Places',
    aliases: ['oncoming_automobile'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚙',
    description: 'sport utility vehicle',
    category: 'Travel & Places',
    aliases: ['blue_car'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛻',
    description: 'pickup truck',
    category: 'Travel & Places',
    aliases: ['pickup_truck'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🚚',
    description: 'delivery truck',
    category: 'Travel & Places',
    aliases: ['truck'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚛',
    description: 'articulated lorry',
    category: 'Travel & Places',
    aliases: ['articulated_lorry'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚜',
    description: 'tractor',
    category: 'Travel & Places',
    aliases: ['tractor'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏎️',
    description: 'racing car',
    category: 'Travel & Places',
    aliases: ['racing_car'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏍️',
    description: 'motorcycle',
    category: 'Travel & Places',
    aliases: ['motorcycle'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🛵',
    description: 'motor scooter',
    category: 'Travel & Places',
    aliases: ['motor_scooter'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🦽',
    description: 'manual wheelchair',
    category: 'Travel & Places',
    aliases: ['manual_wheelchair'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🦼',
    description: 'motorized wheelchair',
    category: 'Travel & Places',
    aliases: ['motorized_wheelchair'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🛺',
    description: 'auto rickshaw',
    category: 'Travel & Places',
    aliases: ['auto_rickshaw'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🚲',
    description: 'bicycle',
    category: 'Travel & Places',
    aliases: ['bike'],
    keys: ['bicycle'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛴',
    description: 'kick scooter',
    category: 'Travel & Places',
    aliases: ['kick_scooter'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🛹',
    description: 'skateboard',
    category: 'Travel & Places',
    aliases: ['skateboard'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🛼',
    description: 'roller skate',
    category: 'Travel & Places',
    aliases: ['roller_skate'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🚏',
    description: 'bus stop',
    category: 'Travel & Places',
    aliases: ['busstop'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛣️',
    description: 'motorway',
    category: 'Travel & Places',
    aliases: ['motorway'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🛤️',
    description: 'railway track',
    category: 'Travel & Places',
    aliases: ['railway_track'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🛢️',
    description: 'oil drum',
    category: 'Travel & Places',
    aliases: ['oil_drum'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⛽',
    description: 'fuel pump',
    category: 'Travel & Places',
    aliases: ['fuelpump'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '🛞',
    description: 'wheel',
    category: 'Travel & Places',
    aliases: ['wheel'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🚨',
    description: 'police car light',
    category: 'Travel & Places',
    aliases: ['rotating_light'],
    keys: ['911', 'emergency'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚥',
    description: 'horizontal traffic light',
    category: 'Travel & Places',
    aliases: ['traffic_light'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚦',
    description: 'vertical traffic light',
    category: 'Travel & Places',
    aliases: ['vertical_traffic_light'],
    keys: ['semaphore'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛑',
    description: 'stop sign',
    category: 'Travel & Places',
    aliases: ['stop_sign'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🚧',
    description: 'construction',
    category: 'Travel & Places',
    aliases: ['construction'],
    keys: ['wip'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⚓',
    description: 'anchor',
    category: 'Travel & Places',
    aliases: ['anchor'],
    keys: ['ship'],
    unicode_version: '4.1',
    ios_version: '6.0',
  },
  {
    value: '🛟',
    description: 'ring buoy',
    category: 'Travel & Places',
    aliases: ['ring_buoy'],
    keys: ['life preserver'],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '⛵',
    description: 'sailboat',
    category: 'Travel & Places',
    aliases: ['boat', 'sailboat'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '🛶',
    description: 'canoe',
    category: 'Travel & Places',
    aliases: ['canoe'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🚤',
    description: 'speedboat',
    category: 'Travel & Places',
    aliases: ['speedboat'],
    keys: ['ship'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛳️',
    description: 'passenger ship',
    category: 'Travel & Places',
    aliases: ['passenger_ship'],
    keys: ['cruise'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⛴️',
    description: 'ferry',
    category: 'Travel & Places',
    aliases: ['ferry'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '🛥️',
    description: 'motor boat',
    category: 'Travel & Places',
    aliases: ['motor_boat'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🚢',
    description: 'ship',
    category: 'Travel & Places',
    aliases: ['ship'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '✈️',
    description: 'airplane',
    category: 'Travel & Places',
    aliases: ['airplane'],
    keys: ['flight'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🛩️',
    description: 'small airplane',
    category: 'Travel & Places',
    aliases: ['small_airplane'],
    keys: ['flight'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🛫',
    description: 'airplane departure',
    category: 'Travel & Places',
    aliases: ['flight_departure'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🛬',
    description: 'airplane arrival',
    category: 'Travel & Places',
    aliases: ['flight_arrival'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🪂',
    description: 'parachute',
    category: 'Travel & Places',
    aliases: ['parachute'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '💺',
    description: 'seat',
    category: 'Travel & Places',
    aliases: ['seat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚁',
    description: 'helicopter',
    category: 'Travel & Places',
    aliases: ['helicopter'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚟',
    description: 'suspension railway',
    category: 'Travel & Places',
    aliases: ['suspension_railway'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚠',
    description: 'mountain cableway',
    category: 'Travel & Places',
    aliases: ['mountain_cableway'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚡',
    description: 'aerial tramway',
    category: 'Travel & Places',
    aliases: ['aerial_tramway'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛰️',
    description: 'satellite',
    category: 'Travel & Places',
    aliases: ['artificial_satellite'],
    keys: ['orbit', 'space'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🚀',
    description: 'rocket',
    category: 'Travel & Places',
    aliases: ['rocket'],
    keys: ['ship', 'launch'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛸',
    description: 'flying saucer',
    category: 'Travel & Places',
    aliases: ['flying_saucer'],
    keys: ['ufo'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🛎️',
    description: 'bellhop bell',
    category: 'Travel & Places',
    aliases: ['bellhop_bell'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🧳',
    description: 'luggage',
    category: 'Travel & Places',
    aliases: ['luggage'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '⌛',
    description: 'hourglass done',
    category: 'Travel & Places',
    aliases: ['hourglass'],
    keys: ['time'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⏳',
    description: 'hourglass not done',
    category: 'Travel & Places',
    aliases: ['hourglass_flowing_sand'],
    keys: ['time'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⌚',
    description: 'watch',
    category: 'Travel & Places',
    aliases: ['watch'],
    keys: ['time'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⏰',
    description: 'alarm clock',
    category: 'Travel & Places',
    aliases: ['alarm_clock'],
    keys: ['morning'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⏱️',
    description: 'stopwatch',
    category: 'Travel & Places',
    aliases: ['stopwatch'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    value: '⏲️',
    description: 'timer clock',
    category: 'Travel & Places',
    aliases: ['timer_clock'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    value: '🕰️',
    description: 'mantelpiece clock',
    category: 'Travel & Places',
    aliases: ['mantelpiece_clock'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🕛',
    description: 'twelve o’clock',
    category: 'Travel & Places',
    aliases: ['clock12'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕧',
    description: 'twelve-thirty',
    category: 'Travel & Places',
    aliases: ['clock1230'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕐',
    description: 'one o’clock',
    category: 'Travel & Places',
    aliases: ['clock1'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕜',
    description: 'one-thirty',
    category: 'Travel & Places',
    aliases: ['clock130'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕑',
    description: 'two o’clock',
    category: 'Travel & Places',
    aliases: ['clock2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕝',
    description: 'two-thirty',
    category: 'Travel & Places',
    aliases: ['clock230'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕒',
    description: 'three o’clock',
    category: 'Travel & Places',
    aliases: ['clock3'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕞',
    description: 'three-thirty',
    category: 'Travel & Places',
    aliases: ['clock330'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕓',
    description: 'four o’clock',
    category: 'Travel & Places',
    aliases: ['clock4'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕟',
    description: 'four-thirty',
    category: 'Travel & Places',
    aliases: ['clock430'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕔',
    description: 'five o’clock',
    category: 'Travel & Places',
    aliases: ['clock5'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕠',
    description: 'five-thirty',
    category: 'Travel & Places',
    aliases: ['clock530'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕕',
    description: 'six o’clock',
    category: 'Travel & Places',
    aliases: ['clock6'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕡',
    description: 'six-thirty',
    category: 'Travel & Places',
    aliases: ['clock630'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕖',
    description: 'seven o’clock',
    category: 'Travel & Places',
    aliases: ['clock7'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕢',
    description: 'seven-thirty',
    category: 'Travel & Places',
    aliases: ['clock730'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕗',
    description: 'eight o’clock',
    category: 'Travel & Places',
    aliases: ['clock8'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕣',
    description: 'eight-thirty',
    category: 'Travel & Places',
    aliases: ['clock830'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕘',
    description: 'nine o’clock',
    category: 'Travel & Places',
    aliases: ['clock9'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕤',
    description: 'nine-thirty',
    category: 'Travel & Places',
    aliases: ['clock930'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕙',
    description: 'ten o’clock',
    category: 'Travel & Places',
    aliases: ['clock10'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕥',
    description: 'ten-thirty',
    category: 'Travel & Places',
    aliases: ['clock1030'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕚',
    description: 'eleven o’clock',
    category: 'Travel & Places',
    aliases: ['clock11'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕦',
    description: 'eleven-thirty',
    category: 'Travel & Places',
    aliases: ['clock1130'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌑',
    description: 'new moon',
    category: 'Travel & Places',
    aliases: ['new_moon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌒',
    description: 'waxing crescent moon',
    category: 'Travel & Places',
    aliases: ['waxing_crescent_moon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌓',
    description: 'first quarter moon',
    category: 'Travel & Places',
    aliases: ['first_quarter_moon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌔',
    description: 'waxing gibbous moon',
    category: 'Travel & Places',
    aliases: ['moon', 'waxing_gibbous_moon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌕',
    description: 'full moon',
    category: 'Travel & Places',
    aliases: ['full_moon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌖',
    description: 'waning gibbous moon',
    category: 'Travel & Places',
    aliases: ['waning_gibbous_moon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌗',
    description: 'last quarter moon',
    category: 'Travel & Places',
    aliases: ['last_quarter_moon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌘',
    description: 'waning crescent moon',
    category: 'Travel & Places',
    aliases: ['waning_crescent_moon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌙',
    description: 'crescent moon',
    category: 'Travel & Places',
    aliases: ['crescent_moon'],
    keys: ['night'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌚',
    description: 'new moon face',
    category: 'Travel & Places',
    aliases: ['new_moon_with_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌛',
    description: 'first quarter moon face',
    category: 'Travel & Places',
    aliases: ['first_quarter_moon_with_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌜',
    description: 'last quarter moon face',
    category: 'Travel & Places',
    aliases: ['last_quarter_moon_with_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌡️',
    description: 'thermometer',
    category: 'Travel & Places',
    aliases: ['thermometer'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '☀️',
    description: 'sun',
    category: 'Travel & Places',
    aliases: ['sunny'],
    keys: ['weather'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🌝',
    description: 'full moon face',
    category: 'Travel & Places',
    aliases: ['full_moon_with_face'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌞',
    description: 'sun with face',
    category: 'Travel & Places',
    aliases: ['sun_with_face'],
    keys: ['summer'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪐',
    description: 'ringed planet',
    category: 'Travel & Places',
    aliases: ['ringed_planet'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '⭐',
    description: 'star',
    category: 'Travel & Places',
    aliases: ['star'],
    keys: [],
    unicode_version: '5.1',
    ios_version: '6.0',
  },
  {
    value: '🌟',
    description: 'glowing star',
    category: 'Travel & Places',
    aliases: ['star2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌠',
    description: 'shooting star',
    category: 'Travel & Places',
    aliases: ['stars'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌌',
    description: 'milky way',
    category: 'Travel & Places',
    aliases: ['milky_way'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '☁️',
    description: 'cloud',
    category: 'Travel & Places',
    aliases: ['cloud'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⛅',
    description: 'sun behind cloud',
    category: 'Travel & Places',
    aliases: ['partly_sunny'],
    keys: ['weather', 'cloud'],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '⛈️',
    description: 'cloud with lightning and rain',
    category: 'Travel & Places',
    aliases: ['cloud_with_lightning_and_rain'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '🌤️',
    description: 'sun behind small cloud',
    category: 'Travel & Places',
    aliases: ['sun_behind_small_cloud'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌥️',
    description: 'sun behind large cloud',
    category: 'Travel & Places',
    aliases: ['sun_behind_large_cloud'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌦️',
    description: 'sun behind rain cloud',
    category: 'Travel & Places',
    aliases: ['sun_behind_rain_cloud'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌧️',
    description: 'cloud with rain',
    category: 'Travel & Places',
    aliases: ['cloud_with_rain'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌨️',
    description: 'cloud with snow',
    category: 'Travel & Places',
    aliases: ['cloud_with_snow'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌩️',
    description: 'cloud with lightning',
    category: 'Travel & Places',
    aliases: ['cloud_with_lightning'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌪️',
    description: 'tornado',
    category: 'Travel & Places',
    aliases: ['tornado'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌫️',
    description: 'fog',
    category: 'Travel & Places',
    aliases: ['fog'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌬️',
    description: 'wind face',
    category: 'Travel & Places',
    aliases: ['wind_face'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🌀',
    description: 'cyclone',
    category: 'Travel & Places',
    aliases: ['cyclone'],
    keys: ['swirl'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌈',
    description: 'rainbow',
    category: 'Travel & Places',
    aliases: ['rainbow'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌂',
    description: 'closed umbrella',
    category: 'Travel & Places',
    aliases: ['closed_umbrella'],
    keys: ['weather', 'rain'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '☂️',
    description: 'umbrella',
    category: 'Travel & Places',
    aliases: ['open_umbrella'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '☔',
    description: 'umbrella with rain drops',
    category: 'Travel & Places',
    aliases: ['umbrella'],
    keys: ['rain', 'weather'],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    value: '⛱️',
    description: 'umbrella on ground',
    category: 'Travel & Places',
    aliases: ['parasol_on_ground'],
    keys: ['beach_umbrella'],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '⚡',
    description: 'high voltage',
    category: 'Travel & Places',
    aliases: ['zap'],
    keys: ['lightning', 'thunder'],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    value: '❄️',
    description: 'snowflake',
    category: 'Travel & Places',
    aliases: ['snowflake'],
    keys: ['winter', 'cold', 'weather'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '☃️',
    description: 'snowman',
    category: 'Travel & Places',
    aliases: ['snowman_with_snow'],
    keys: ['winter', 'christmas'],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '⛄',
    description: 'snowman without snow',
    category: 'Travel & Places',
    aliases: ['snowman'],
    keys: ['winter'],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '☄️',
    description: 'comet',
    category: 'Travel & Places',
    aliases: ['comet'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '🔥',
    description: 'fire',
    category: 'Travel & Places',
    aliases: ['fire'],
    keys: ['burn'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💧',
    description: 'droplet',
    category: 'Travel & Places',
    aliases: ['droplet'],
    keys: ['water'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🌊',
    description: 'water wave',
    category: 'Travel & Places',
    aliases: ['ocean'],
    keys: ['sea'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎃',
    description: 'jack-o-lantern',
    category: 'Activities',
    aliases: ['jack_o_lantern'],
    keys: ['halloween'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎄',
    description: 'Christmas tree',
    category: 'Activities',
    aliases: ['christmas_tree'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎆',
    description: 'fireworks',
    category: 'Activities',
    aliases: ['fireworks'],
    keys: ['festival', 'celebration'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎇',
    description: 'sparkler',
    category: 'Activities',
    aliases: ['sparkler'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧨',
    description: 'firecracker',
    category: 'Activities',
    aliases: ['firecracker'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '✨',
    description: 'sparkles',
    category: 'Activities',
    aliases: ['sparkles'],
    keys: ['shiny'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎈',
    description: 'balloon',
    category: 'Activities',
    aliases: ['balloon'],
    keys: ['party', 'birthday'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎉',
    description: 'party popper',
    category: 'Activities',
    aliases: ['tada'],
    keys: ['hooray', 'party'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎊',
    description: 'confetti ball',
    category: 'Activities',
    aliases: ['confetti_ball'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎋',
    description: 'tanabata tree',
    category: 'Activities',
    aliases: ['tanabata_tree'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎍',
    description: 'pine decoration',
    category: 'Activities',
    aliases: ['bamboo'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎎',
    description: 'Japanese dolls',
    category: 'Activities',
    aliases: ['dolls'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎏',
    description: 'carp streamer',
    category: 'Activities',
    aliases: ['flags'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎐',
    description: 'wind chime',
    category: 'Activities',
    aliases: ['wind_chime'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎑',
    description: 'moon viewing ceremony',
    category: 'Activities',
    aliases: ['rice_scene'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧧',
    description: 'red envelope',
    category: 'Activities',
    aliases: ['red_envelope'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🎀',
    description: 'ribbon',
    category: 'Activities',
    aliases: ['ribbon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎁',
    description: 'wrapped gift',
    category: 'Activities',
    aliases: ['gift'],
    keys: ['present', 'birthday', 'christmas'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎗️',
    description: 'reminder ribbon',
    category: 'Activities',
    aliases: ['reminder_ribbon'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎟️',
    description: 'admission tickets',
    category: 'Activities',
    aliases: ['tickets'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎫',
    description: 'ticket',
    category: 'Activities',
    aliases: ['ticket'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎖️',
    description: 'military medal',
    category: 'Activities',
    aliases: ['medal_military'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏆',
    description: 'trophy',
    category: 'Activities',
    aliases: ['trophy'],
    keys: ['award', 'contest', 'winner'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏅',
    description: 'sports medal',
    category: 'Activities',
    aliases: ['medal_sports'],
    keys: ['gold', 'winner'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🥇',
    description: '1st place medal',
    category: 'Activities',
    aliases: ['1st_place_medal'],
    keys: ['gold'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥈',
    description: '2nd place medal',
    category: 'Activities',
    aliases: ['2nd_place_medal'],
    keys: ['silver'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥉',
    description: '3rd place medal',
    category: 'Activities',
    aliases: ['3rd_place_medal'],
    keys: ['bronze'],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '⚽',
    description: 'soccer ball',
    category: 'Activities',
    aliases: ['soccer'],
    keys: ['sports'],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '⚾',
    description: 'baseball',
    category: 'Activities',
    aliases: ['baseball'],
    keys: ['sports'],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '🥎',
    description: 'softball',
    category: 'Activities',
    aliases: ['softball'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🏀',
    description: 'basketball',
    category: 'Activities',
    aliases: ['basketball'],
    keys: ['sports'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏐',
    description: 'volleyball',
    category: 'Activities',
    aliases: ['volleyball'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🏈',
    description: 'american football',
    category: 'Activities',
    aliases: ['football'],
    keys: ['sports'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏉',
    description: 'rugby football',
    category: 'Activities',
    aliases: ['rugby_football'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎾',
    description: 'tennis',
    category: 'Activities',
    aliases: ['tennis'],
    keys: ['sports'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥏',
    description: 'flying disc',
    category: 'Activities',
    aliases: ['flying_disc'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🎳',
    description: 'bowling',
    category: 'Activities',
    aliases: ['bowling'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏏',
    description: 'cricket game',
    category: 'Activities',
    aliases: ['cricket_game'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🏑',
    description: 'field hockey',
    category: 'Activities',
    aliases: ['field_hockey'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🏒',
    description: 'ice hockey',
    category: 'Activities',
    aliases: ['ice_hockey'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🥍',
    description: 'lacrosse',
    category: 'Activities',
    aliases: ['lacrosse'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🏓',
    description: 'ping pong',
    category: 'Activities',
    aliases: ['ping_pong'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🏸',
    description: 'badminton',
    category: 'Activities',
    aliases: ['badminton'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🥊',
    description: 'boxing glove',
    category: 'Activities',
    aliases: ['boxing_glove'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥋',
    description: 'martial arts uniform',
    category: 'Activities',
    aliases: ['martial_arts_uniform'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🥅',
    description: 'goal net',
    category: 'Activities',
    aliases: ['goal_net'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '⛳',
    description: 'flag in hole',
    category: 'Activities',
    aliases: ['golf'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '⛸️',
    description: 'ice skate',
    category: 'Activities',
    aliases: ['ice_skate'],
    keys: ['skating'],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '🎣',
    description: 'fishing pole',
    category: 'Activities',
    aliases: ['fishing_pole_and_fish'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🤿',
    description: 'diving mask',
    category: 'Activities',
    aliases: ['diving_mask'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🎽',
    description: 'running shirt',
    category: 'Activities',
    aliases: ['running_shirt_with_sash'],
    keys: ['marathon'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎿',
    description: 'skis',
    category: 'Activities',
    aliases: ['ski'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛷',
    description: 'sled',
    category: 'Activities',
    aliases: ['sled'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥌',
    description: 'curling stone',
    category: 'Activities',
    aliases: ['curling_stone'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🎯',
    description: 'bullseye',
    category: 'Activities',
    aliases: ['dart'],
    keys: ['target'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪀',
    description: 'yo-yo',
    category: 'Activities',
    aliases: ['yo_yo'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🪁',
    description: 'kite',
    category: 'Activities',
    aliases: ['kite'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🔫',
    description: 'water pistol',
    category: 'Activities',
    aliases: ['gun'],
    keys: ['shoot', 'weapon'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎱',
    description: 'pool 8 ball',
    category: 'Activities',
    aliases: ['8ball'],
    keys: ['pool', 'billiards'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔮',
    description: 'crystal ball',
    category: 'Activities',
    aliases: ['crystal_ball'],
    keys: ['fortune'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪄',
    description: 'magic wand',
    category: 'Activities',
    aliases: ['magic_wand'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🎮',
    description: 'video game',
    category: 'Activities',
    aliases: ['video_game'],
    keys: ['play', 'controller', 'console'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕹️',
    description: 'joystick',
    category: 'Activities',
    aliases: ['joystick'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎰',
    description: 'slot machine',
    category: 'Activities',
    aliases: ['slot_machine'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎲',
    description: 'game die',
    category: 'Activities',
    aliases: ['game_die'],
    keys: ['dice', 'gambling'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧩',
    description: 'puzzle piece',
    category: 'Activities',
    aliases: ['jigsaw'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧸',
    description: 'teddy bear',
    category: 'Activities',
    aliases: ['teddy_bear'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪅',
    description: 'piñata',
    category: 'Activities',
    aliases: ['pinata'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪩',
    description: 'mirror ball',
    category: 'Activities',
    aliases: ['mirror_ball'],
    keys: ['disco', 'party'],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🪆',
    description: 'nesting dolls',
    category: 'Activities',
    aliases: ['nesting_dolls'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '♠️',
    description: 'spade suit',
    category: 'Activities',
    aliases: ['spades'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♥️',
    description: 'heart suit',
    category: 'Activities',
    aliases: ['hearts'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♦️',
    description: 'diamond suit',
    category: 'Activities',
    aliases: ['diamonds'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♣️',
    description: 'club suit',
    category: 'Activities',
    aliases: ['clubs'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♟️',
    description: 'chess pawn',
    category: 'Activities',
    aliases: ['chess_pawn'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🃏',
    description: 'joker',
    category: 'Activities',
    aliases: ['black_joker'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🀄',
    description: 'mahjong red dragon',
    category: 'Activities',
    aliases: ['mahjong'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🎴',
    description: 'flower playing cards',
    category: 'Activities',
    aliases: ['flower_playing_cards'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎭',
    description: 'performing arts',
    category: 'Activities',
    aliases: ['performing_arts'],
    keys: ['theater', 'drama'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🖼️',
    description: 'framed picture',
    category: 'Activities',
    aliases: ['framed_picture'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎨',
    description: 'artist palette',
    category: 'Activities',
    aliases: ['art'],
    keys: ['design', 'paint'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧵',
    description: 'thread',
    category: 'Activities',
    aliases: ['thread'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪡',
    description: 'sewing needle',
    category: 'Activities',
    aliases: ['sewing_needle'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🧶',
    description: 'yarn',
    category: 'Activities',
    aliases: ['yarn'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪢',
    description: 'knot',
    category: 'Activities',
    aliases: ['knot'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '👓',
    description: 'glasses',
    category: 'Objects',
    aliases: ['eyeglasses'],
    keys: ['glasses'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕶️',
    description: 'sunglasses',
    category: 'Objects',
    aliases: ['dark_sunglasses'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🥽',
    description: 'goggles',
    category: 'Objects',
    aliases: ['goggles'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥼',
    description: 'lab coat',
    category: 'Objects',
    aliases: ['lab_coat'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🦺',
    description: 'safety vest',
    category: 'Objects',
    aliases: ['safety_vest'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '👔',
    description: 'necktie',
    category: 'Objects',
    aliases: ['necktie'],
    keys: ['shirt', 'formal'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👕',
    description: 't-shirt',
    category: 'Objects',
    aliases: ['shirt', 'tshirt'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👖',
    description: 'jeans',
    category: 'Objects',
    aliases: ['jeans'],
    keys: ['pants'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧣',
    description: 'scarf',
    category: 'Objects',
    aliases: ['scarf'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧤',
    description: 'gloves',
    category: 'Objects',
    aliases: ['gloves'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧥',
    description: 'coat',
    category: 'Objects',
    aliases: ['coat'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧦',
    description: 'socks',
    category: 'Objects',
    aliases: ['socks'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '👗',
    description: 'dress',
    category: 'Objects',
    aliases: ['dress'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👘',
    description: 'kimono',
    category: 'Objects',
    aliases: ['kimono'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥻',
    description: 'sari',
    category: 'Objects',
    aliases: ['sari'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🩱',
    description: 'one-piece swimsuit',
    category: 'Objects',
    aliases: ['one_piece_swimsuit'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🩲',
    description: 'briefs',
    category: 'Objects',
    aliases: ['swim_brief'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🩳',
    description: 'shorts',
    category: 'Objects',
    aliases: ['shorts'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '👙',
    description: 'bikini',
    category: 'Objects',
    aliases: ['bikini'],
    keys: ['beach'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👚',
    description: 'woman’s clothes',
    category: 'Objects',
    aliases: ['womans_clothes'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪭',
    description: 'folding hand fan',
    category: 'Objects',
    aliases: ['folding_hand_fan'],
    keys: ['sensu'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '👛',
    description: 'purse',
    category: 'Objects',
    aliases: ['purse'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👜',
    description: 'handbag',
    category: 'Objects',
    aliases: ['handbag'],
    keys: ['bag'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👝',
    description: 'clutch bag',
    category: 'Objects',
    aliases: ['pouch'],
    keys: ['bag'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛍️',
    description: 'shopping bags',
    category: 'Objects',
    aliases: ['shopping'],
    keys: ['bags'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎒',
    description: 'backpack',
    category: 'Objects',
    aliases: ['school_satchel'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🩴',
    description: 'thong sandal',
    category: 'Objects',
    aliases: ['thong_sandal'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '👞',
    description: 'man’s shoe',
    category: 'Objects',
    aliases: ['mans_shoe', 'shoe'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👟',
    description: 'running shoe',
    category: 'Objects',
    aliases: ['athletic_shoe'],
    keys: ['sneaker', 'sport', 'running'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🥾',
    description: 'hiking boot',
    category: 'Objects',
    aliases: ['hiking_boot'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🥿',
    description: 'flat shoe',
    category: 'Objects',
    aliases: ['flat_shoe'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '👠',
    description: 'high-heeled shoe',
    category: 'Objects',
    aliases: ['high_heel'],
    keys: ['shoe'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👡',
    description: 'woman’s sandal',
    category: 'Objects',
    aliases: ['sandal'],
    keys: ['shoe'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🩰',
    description: 'ballet shoes',
    category: 'Objects',
    aliases: ['ballet_shoes'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '👢',
    description: 'woman’s boot',
    category: 'Objects',
    aliases: ['boot'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪮',
    description: 'hair pick',
    category: 'Objects',
    aliases: ['hair_pick'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '👑',
    description: 'crown',
    category: 'Objects',
    aliases: ['crown'],
    keys: ['king', 'queen', 'royal'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '👒',
    description: 'woman’s hat',
    category: 'Objects',
    aliases: ['womans_hat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎩',
    description: 'top hat',
    category: 'Objects',
    aliases: ['tophat'],
    keys: ['hat', 'classy'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎓',
    description: 'graduation cap',
    category: 'Objects',
    aliases: ['mortar_board'],
    keys: ['education', 'college', 'university', 'graduation'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧢',
    description: 'billed cap',
    category: 'Objects',
    aliases: ['billed_cap'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪖',
    description: 'military helmet',
    category: 'Objects',
    aliases: ['military_helmet'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '⛑️',
    description: 'rescue worker’s helmet',
    category: 'Objects',
    aliases: ['rescue_worker_helmet'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '📿',
    description: 'prayer beads',
    category: 'Objects',
    aliases: ['prayer_beads'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '💄',
    description: 'lipstick',
    category: 'Objects',
    aliases: ['lipstick'],
    keys: ['makeup'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💍',
    description: 'ring',
    category: 'Objects',
    aliases: ['ring'],
    keys: ['wedding', 'marriage', 'engaged'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💎',
    description: 'gem stone',
    category: 'Objects',
    aliases: ['gem'],
    keys: ['diamond'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔇',
    description: 'muted speaker',
    category: 'Objects',
    aliases: ['mute'],
    keys: ['sound', 'volume'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔈',
    description: 'speaker low volume',
    category: 'Objects',
    aliases: ['speaker'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔉',
    description: 'speaker medium volume',
    category: 'Objects',
    aliases: ['sound'],
    keys: ['volume'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔊',
    description: 'speaker high volume',
    category: 'Objects',
    aliases: ['loud_sound'],
    keys: ['volume'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📢',
    description: 'loudspeaker',
    category: 'Objects',
    aliases: ['loudspeaker'],
    keys: ['announcement'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📣',
    description: 'megaphone',
    category: 'Objects',
    aliases: ['mega'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📯',
    description: 'postal horn',
    category: 'Objects',
    aliases: ['postal_horn'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔔',
    description: 'bell',
    category: 'Objects',
    aliases: ['bell'],
    keys: ['sound', 'notification'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔕',
    description: 'bell with slash',
    category: 'Objects',
    aliases: ['no_bell'],
    keys: ['volume', 'off'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎼',
    description: 'musical score',
    category: 'Objects',
    aliases: ['musical_score'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎵',
    description: 'musical note',
    category: 'Objects',
    aliases: ['musical_note'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎶',
    description: 'musical notes',
    category: 'Objects',
    aliases: ['notes'],
    keys: ['music'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎙️',
    description: 'studio microphone',
    category: 'Objects',
    aliases: ['studio_microphone'],
    keys: ['podcast'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎚️',
    description: 'level slider',
    category: 'Objects',
    aliases: ['level_slider'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎛️',
    description: 'control knobs',
    category: 'Objects',
    aliases: ['control_knobs'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎤',
    description: 'microphone',
    category: 'Objects',
    aliases: ['microphone'],
    keys: ['sing'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎧',
    description: 'headphone',
    category: 'Objects',
    aliases: ['headphones'],
    keys: ['music', 'earphones'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📻',
    description: 'radio',
    category: 'Objects',
    aliases: ['radio'],
    keys: ['podcast'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎷',
    description: 'saxophone',
    category: 'Objects',
    aliases: ['saxophone'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪗',
    description: 'accordion',
    category: 'Objects',
    aliases: ['accordion'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🎸',
    description: 'guitar',
    category: 'Objects',
    aliases: ['guitar'],
    keys: ['rock'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎹',
    description: 'musical keyboard',
    category: 'Objects',
    aliases: ['musical_keyboard'],
    keys: ['piano'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎺',
    description: 'trumpet',
    category: 'Objects',
    aliases: ['trumpet'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎻',
    description: 'violin',
    category: 'Objects',
    aliases: ['violin'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪕',
    description: 'banjo',
    category: 'Objects',
    aliases: ['banjo'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🥁',
    description: 'drum',
    category: 'Objects',
    aliases: ['drum'],
    keys: [],
    unicode_version: '',
    ios_version: '10.2',
  },
  {
    value: '🪘',
    description: 'long drum',
    category: 'Objects',
    aliases: ['long_drum'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪇',
    description: 'maracas',
    category: 'Objects',
    aliases: ['maracas'],
    keys: ['shaker'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '🪈',
    description: 'flute',
    category: 'Objects',
    aliases: ['flute'],
    keys: ['recorder'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '📱',
    description: 'mobile phone',
    category: 'Objects',
    aliases: ['iphone'],
    keys: ['smartphone', 'mobile'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📲',
    description: 'mobile phone with arrow',
    category: 'Objects',
    aliases: ['calling'],
    keys: ['call', 'incoming'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '☎️',
    description: 'telephone',
    category: 'Objects',
    aliases: ['phone', 'telephone'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '📞',
    description: 'telephone receiver',
    category: 'Objects',
    aliases: ['telephone_receiver'],
    keys: ['phone', 'call'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📟',
    description: 'pager',
    category: 'Objects',
    aliases: ['pager'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📠',
    description: 'fax machine',
    category: 'Objects',
    aliases: ['fax'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔋',
    description: 'battery',
    category: 'Objects',
    aliases: ['battery'],
    keys: ['power'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪫',
    description: 'low battery',
    category: 'Objects',
    aliases: ['low_battery'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🔌',
    description: 'electric plug',
    category: 'Objects',
    aliases: ['electric_plug'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💻',
    description: 'laptop',
    category: 'Objects',
    aliases: ['computer'],
    keys: ['desktop', 'screen'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🖥️',
    description: 'desktop computer',
    category: 'Objects',
    aliases: ['desktop_computer'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🖨️',
    description: 'printer',
    category: 'Objects',
    aliases: ['printer'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⌨️',
    description: 'keyboard',
    category: 'Objects',
    aliases: ['keyboard'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '🖱️',
    description: 'computer mouse',
    category: 'Objects',
    aliases: ['computer_mouse'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🖲️',
    description: 'trackball',
    category: 'Objects',
    aliases: ['trackball'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '💽',
    description: 'computer disk',
    category: 'Objects',
    aliases: ['minidisc'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💾',
    description: 'floppy disk',
    category: 'Objects',
    aliases: ['floppy_disk'],
    keys: ['save'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💿',
    description: 'optical disk',
    category: 'Objects',
    aliases: ['cd'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📀',
    description: 'dvd',
    category: 'Objects',
    aliases: ['dvd'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧮',
    description: 'abacus',
    category: 'Objects',
    aliases: ['abacus'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🎥',
    description: 'movie camera',
    category: 'Objects',
    aliases: ['movie_camera'],
    keys: ['film', 'video'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎞️',
    description: 'film frames',
    category: 'Objects',
    aliases: ['film_strip'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '📽️',
    description: 'film projector',
    category: 'Objects',
    aliases: ['film_projector'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🎬',
    description: 'clapper board',
    category: 'Objects',
    aliases: ['clapper'],
    keys: ['film'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📺',
    description: 'television',
    category: 'Objects',
    aliases: ['tv'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📷',
    description: 'camera',
    category: 'Objects',
    aliases: ['camera'],
    keys: ['photo'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📸',
    description: 'camera with flash',
    category: 'Objects',
    aliases: ['camera_flash'],
    keys: ['photo'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '📹',
    description: 'video camera',
    category: 'Objects',
    aliases: ['video_camera'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📼',
    description: 'videocassette',
    category: 'Objects',
    aliases: ['vhs'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔍',
    description: 'magnifying glass tilted left',
    category: 'Objects',
    aliases: ['mag'],
    keys: ['search', 'zoom'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔎',
    description: 'magnifying glass tilted right',
    category: 'Objects',
    aliases: ['mag_right'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🕯️',
    description: 'candle',
    category: 'Objects',
    aliases: ['candle'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '💡',
    description: 'light bulb',
    category: 'Objects',
    aliases: ['bulb'],
    keys: ['idea', 'light'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔦',
    description: 'flashlight',
    category: 'Objects',
    aliases: ['flashlight'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏮',
    description: 'red paper lantern',
    category: 'Objects',
    aliases: ['izakaya_lantern', 'lantern'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪔',
    description: 'diya lamp',
    category: 'Objects',
    aliases: ['diya_lamp'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '📔',
    description: 'notebook with decorative cover',
    category: 'Objects',
    aliases: ['notebook_with_decorative_cover'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📕',
    description: 'closed book',
    category: 'Objects',
    aliases: ['closed_book'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📖',
    description: 'open book',
    category: 'Objects',
    aliases: ['book', 'open_book'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📗',
    description: 'green book',
    category: 'Objects',
    aliases: ['green_book'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📘',
    description: 'blue book',
    category: 'Objects',
    aliases: ['blue_book'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📙',
    description: 'orange book',
    category: 'Objects',
    aliases: ['orange_book'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📚',
    description: 'books',
    category: 'Objects',
    aliases: ['books'],
    keys: ['library'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📓',
    description: 'notebook',
    category: 'Objects',
    aliases: ['notebook'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📒',
    description: 'ledger',
    category: 'Objects',
    aliases: ['ledger'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📃',
    description: 'page with curl',
    category: 'Objects',
    aliases: ['page_with_curl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📜',
    description: 'scroll',
    category: 'Objects',
    aliases: ['scroll'],
    keys: ['document'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📄',
    description: 'page facing up',
    category: 'Objects',
    aliases: ['page_facing_up'],
    keys: ['document'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📰',
    description: 'newspaper',
    category: 'Objects',
    aliases: ['newspaper'],
    keys: ['press'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗞️',
    description: 'rolled-up newspaper',
    category: 'Objects',
    aliases: ['newspaper_roll'],
    keys: ['press'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '📑',
    description: 'bookmark tabs',
    category: 'Objects',
    aliases: ['bookmark_tabs'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔖',
    description: 'bookmark',
    category: 'Objects',
    aliases: ['bookmark'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏷️',
    description: 'label',
    category: 'Objects',
    aliases: ['label'],
    keys: ['tag'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '💰',
    description: 'money bag',
    category: 'Objects',
    aliases: ['moneybag'],
    keys: ['dollar', 'cream'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪙',
    description: 'coin',
    category: 'Objects',
    aliases: ['coin'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '💴',
    description: 'yen banknote',
    category: 'Objects',
    aliases: ['yen'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💵',
    description: 'dollar banknote',
    category: 'Objects',
    aliases: ['dollar'],
    keys: ['money'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💶',
    description: 'euro banknote',
    category: 'Objects',
    aliases: ['euro'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💷',
    description: 'pound banknote',
    category: 'Objects',
    aliases: ['pound'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💸',
    description: 'money with wings',
    category: 'Objects',
    aliases: ['money_with_wings'],
    keys: ['dollar'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💳',
    description: 'credit card',
    category: 'Objects',
    aliases: ['credit_card'],
    keys: ['subscription'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🧾',
    description: 'receipt',
    category: 'Objects',
    aliases: ['receipt'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '💹',
    description: 'chart increasing with yen',
    category: 'Objects',
    aliases: ['chart'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '✉️',
    description: 'envelope',
    category: 'Objects',
    aliases: ['envelope'],
    keys: ['letter', 'email'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '📧',
    description: 'e-mail',
    category: 'Objects',
    aliases: ['email', 'e-mail'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📨',
    description: 'incoming envelope',
    category: 'Objects',
    aliases: ['incoming_envelope'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📩',
    description: 'envelope with arrow',
    category: 'Objects',
    aliases: ['envelope_with_arrow'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📤',
    description: 'outbox tray',
    category: 'Objects',
    aliases: ['outbox_tray'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📥',
    description: 'inbox tray',
    category: 'Objects',
    aliases: ['inbox_tray'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📦',
    description: 'package',
    category: 'Objects',
    aliases: ['package'],
    keys: ['shipping'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📫',
    description: 'closed mailbox with raised flag',
    category: 'Objects',
    aliases: ['mailbox'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📪',
    description: 'closed mailbox with lowered flag',
    category: 'Objects',
    aliases: ['mailbox_closed'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📬',
    description: 'open mailbox with raised flag',
    category: 'Objects',
    aliases: ['mailbox_with_mail'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📭',
    description: 'open mailbox with lowered flag',
    category: 'Objects',
    aliases: ['mailbox_with_no_mail'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📮',
    description: 'postbox',
    category: 'Objects',
    aliases: ['postbox'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗳️',
    description: 'ballot box with ballot',
    category: 'Objects',
    aliases: ['ballot_box'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '✏️',
    description: 'pencil',
    category: 'Objects',
    aliases: ['pencil2'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '✒️',
    description: 'black nib',
    category: 'Objects',
    aliases: ['black_nib'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🖋️',
    description: 'fountain pen',
    category: 'Objects',
    aliases: ['fountain_pen'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🖊️',
    description: 'pen',
    category: 'Objects',
    aliases: ['pen'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🖌️',
    description: 'paintbrush',
    category: 'Objects',
    aliases: ['paintbrush'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🖍️',
    description: 'crayon',
    category: 'Objects',
    aliases: ['crayon'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '📝',
    description: 'memo',
    category: 'Objects',
    aliases: ['memo', 'pencil'],
    keys: ['document', 'note'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💼',
    description: 'briefcase',
    category: 'Objects',
    aliases: ['briefcase'],
    keys: ['business'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📁',
    description: 'file folder',
    category: 'Objects',
    aliases: ['file_folder'],
    keys: ['directory'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📂',
    description: 'open file folder',
    category: 'Objects',
    aliases: ['open_file_folder'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗂️',
    description: 'card index dividers',
    category: 'Objects',
    aliases: ['card_index_dividers'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '📅',
    description: 'calendar',
    category: 'Objects',
    aliases: ['date'],
    keys: ['calendar', 'schedule'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📆',
    description: 'tear-off calendar',
    category: 'Objects',
    aliases: ['calendar'],
    keys: ['schedule'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗒️',
    description: 'spiral notepad',
    category: 'Objects',
    aliases: ['spiral_notepad'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🗓️',
    description: 'spiral calendar',
    category: 'Objects',
    aliases: ['spiral_calendar'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '📇',
    description: 'card index',
    category: 'Objects',
    aliases: ['card_index'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📈',
    description: 'chart increasing',
    category: 'Objects',
    aliases: ['chart_with_upwards_trend'],
    keys: ['graph', 'metrics'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📉',
    description: 'chart decreasing',
    category: 'Objects',
    aliases: ['chart_with_downwards_trend'],
    keys: ['graph', 'metrics'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📊',
    description: 'bar chart',
    category: 'Objects',
    aliases: ['bar_chart'],
    keys: ['stats', 'metrics'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📋',
    description: 'clipboard',
    category: 'Objects',
    aliases: ['clipboard'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📌',
    description: 'pushpin',
    category: 'Objects',
    aliases: ['pushpin'],
    keys: ['location'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📍',
    description: 'round pushpin',
    category: 'Objects',
    aliases: ['round_pushpin'],
    keys: ['location'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📎',
    description: 'paperclip',
    category: 'Objects',
    aliases: ['paperclip'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🖇️',
    description: 'linked paperclips',
    category: 'Objects',
    aliases: ['paperclips'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '📏',
    description: 'straight ruler',
    category: 'Objects',
    aliases: ['straight_ruler'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📐',
    description: 'triangular ruler',
    category: 'Objects',
    aliases: ['triangular_ruler'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '✂️',
    description: 'scissors',
    category: 'Objects',
    aliases: ['scissors'],
    keys: ['cut'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🗃️',
    description: 'card file box',
    category: 'Objects',
    aliases: ['card_file_box'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🗄️',
    description: 'file cabinet',
    category: 'Objects',
    aliases: ['file_cabinet'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🗑️',
    description: 'wastebasket',
    category: 'Objects',
    aliases: ['wastebasket'],
    keys: ['trash'],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🔒',
    description: 'locked',
    category: 'Objects',
    aliases: ['lock'],
    keys: ['security', 'private'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔓',
    description: 'unlocked',
    category: 'Objects',
    aliases: ['unlock'],
    keys: ['security'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔏',
    description: 'locked with pen',
    category: 'Objects',
    aliases: ['lock_with_ink_pen'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔐',
    description: 'locked with key',
    category: 'Objects',
    aliases: ['closed_lock_with_key'],
    keys: ['security'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔑',
    description: 'key',
    category: 'Objects',
    aliases: ['key'],
    keys: ['lock', 'password'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🗝️',
    description: 'old key',
    category: 'Objects',
    aliases: ['old_key'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🔨',
    description: 'hammer',
    category: 'Objects',
    aliases: ['hammer'],
    keys: ['tool'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪓',
    description: 'axe',
    category: 'Objects',
    aliases: ['axe'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '⛏️',
    description: 'pick',
    category: 'Objects',
    aliases: ['pick'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '⚒️',
    description: 'hammer and pick',
    category: 'Objects',
    aliases: ['hammer_and_pick'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🛠️',
    description: 'hammer and wrench',
    category: 'Objects',
    aliases: ['hammer_and_wrench'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🗡️',
    description: 'dagger',
    category: 'Objects',
    aliases: ['dagger'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⚔️',
    description: 'crossed swords',
    category: 'Objects',
    aliases: ['crossed_swords'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '💣',
    description: 'bomb',
    category: 'Objects',
    aliases: ['bomb'],
    keys: ['boom'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪃',
    description: 'boomerang',
    category: 'Objects',
    aliases: ['boomerang'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🏹',
    description: 'bow and arrow',
    category: 'Objects',
    aliases: ['bow_and_arrow'],
    keys: ['archery'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🛡️',
    description: 'shield',
    category: 'Objects',
    aliases: ['shield'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🪚',
    description: 'carpentry saw',
    category: 'Objects',
    aliases: ['carpentry_saw'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🔧',
    description: 'wrench',
    category: 'Objects',
    aliases: ['wrench'],
    keys: ['tool'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪛',
    description: 'screwdriver',
    category: 'Objects',
    aliases: ['screwdriver'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🔩',
    description: 'nut and bolt',
    category: 'Objects',
    aliases: ['nut_and_bolt'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⚙️',
    description: 'gear',
    category: 'Objects',
    aliases: ['gear'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🗜️',
    description: 'clamp',
    category: 'Objects',
    aliases: ['clamp'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⚖️',
    description: 'balance scale',
    category: 'Objects',
    aliases: ['balance_scale'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🦯',
    description: 'white cane',
    category: 'Objects',
    aliases: ['probing_cane'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🔗',
    description: 'link',
    category: 'Objects',
    aliases: ['link'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⛓️',
    description: 'chains',
    category: 'Objects',
    aliases: ['chains'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '9.1',
  },
  {
    value: '🪝',
    description: 'hook',
    category: 'Objects',
    aliases: ['hook'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🧰',
    description: 'toolbox',
    category: 'Objects',
    aliases: ['toolbox'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧲',
    description: 'magnet',
    category: 'Objects',
    aliases: ['magnet'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪜',
    description: 'ladder',
    category: 'Objects',
    aliases: ['ladder'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '⚗️',
    description: 'alembic',
    category: 'Objects',
    aliases: ['alembic'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🧪',
    description: 'test tube',
    category: 'Objects',
    aliases: ['test_tube'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧫',
    description: 'petri dish',
    category: 'Objects',
    aliases: ['petri_dish'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧬',
    description: 'dna',
    category: 'Objects',
    aliases: ['dna'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🔬',
    description: 'microscope',
    category: 'Objects',
    aliases: ['microscope'],
    keys: ['science', 'laboratory', 'investigate'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔭',
    description: 'telescope',
    category: 'Objects',
    aliases: ['telescope'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📡',
    description: 'satellite antenna',
    category: 'Objects',
    aliases: ['satellite'],
    keys: ['signal'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💉',
    description: 'syringe',
    category: 'Objects',
    aliases: ['syringe'],
    keys: ['health', 'hospital', 'needle'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🩸',
    description: 'drop of blood',
    category: 'Objects',
    aliases: ['drop_of_blood'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '💊',
    description: 'pill',
    category: 'Objects',
    aliases: ['pill'],
    keys: ['health', 'medicine'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🩹',
    description: 'adhesive bandage',
    category: 'Objects',
    aliases: ['adhesive_bandage'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🩼',
    description: 'crutch',
    category: 'Objects',
    aliases: ['crutch'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🩺',
    description: 'stethoscope',
    category: 'Objects',
    aliases: ['stethoscope'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🩻',
    description: 'x-ray',
    category: 'Objects',
    aliases: ['x_ray'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🚪',
    description: 'door',
    category: 'Objects',
    aliases: ['door'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛗',
    description: 'elevator',
    category: 'Objects',
    aliases: ['elevator'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪞',
    description: 'mirror',
    category: 'Objects',
    aliases: ['mirror'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪟',
    description: 'window',
    category: 'Objects',
    aliases: ['window'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🛏️',
    description: 'bed',
    category: 'Objects',
    aliases: ['bed'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🛋️',
    description: 'couch and lamp',
    category: 'Objects',
    aliases: ['couch_and_lamp'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🪑',
    description: 'chair',
    category: 'Objects',
    aliases: ['chair'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🚽',
    description: 'toilet',
    category: 'Objects',
    aliases: ['toilet'],
    keys: ['wc'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪠',
    description: 'plunger',
    category: 'Objects',
    aliases: ['plunger'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🚿',
    description: 'shower',
    category: 'Objects',
    aliases: ['shower'],
    keys: ['bath'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛁',
    description: 'bathtub',
    category: 'Objects',
    aliases: ['bathtub'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪤',
    description: 'mouse trap',
    category: 'Objects',
    aliases: ['mouse_trap'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪒',
    description: 'razor',
    category: 'Objects',
    aliases: ['razor'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🧴',
    description: 'lotion bottle',
    category: 'Objects',
    aliases: ['lotion_bottle'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧷',
    description: 'safety pin',
    category: 'Objects',
    aliases: ['safety_pin'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧹',
    description: 'broom',
    category: 'Objects',
    aliases: ['broom'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧺',
    description: 'basket',
    category: 'Objects',
    aliases: ['basket'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧻',
    description: 'roll of paper',
    category: 'Objects',
    aliases: ['roll_of_paper'],
    keys: ['toilet'],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪣',
    description: 'bucket',
    category: 'Objects',
    aliases: ['bucket'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🧼',
    description: 'soap',
    category: 'Objects',
    aliases: ['soap'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🫧',
    description: 'bubbles',
    category: 'Objects',
    aliases: ['bubbles'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🪥',
    description: 'toothbrush',
    category: 'Objects',
    aliases: ['toothbrush'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🧽',
    description: 'sponge',
    category: 'Objects',
    aliases: ['sponge'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🧯',
    description: 'fire extinguisher',
    category: 'Objects',
    aliases: ['fire_extinguisher'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🛒',
    description: 'shopping cart',
    category: 'Objects',
    aliases: ['shopping_cart'],
    keys: [],
    unicode_version: '9.0',
    ios_version: '10.2',
  },
  {
    value: '🚬',
    description: 'cigarette',
    category: 'Objects',
    aliases: ['smoking'],
    keys: ['cigarette'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⚰️',
    description: 'coffin',
    category: 'Objects',
    aliases: ['coffin'],
    keys: ['funeral'],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🪦',
    description: 'headstone',
    category: 'Objects',
    aliases: ['headstone'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '⚱️',
    description: 'funeral urn',
    category: 'Objects',
    aliases: ['funeral_urn'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🧿',
    description: 'nazar amulet',
    category: 'Objects',
    aliases: ['nazar_amulet'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🪬',
    description: 'hamsa',
    category: 'Objects',
    aliases: ['hamsa'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🗿',
    description: 'moai',
    category: 'Objects',
    aliases: ['moyai'],
    keys: ['stone'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪧',
    description: 'placard',
    category: 'Objects',
    aliases: ['placard'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🪪',
    description: 'identification card',
    category: 'Objects',
    aliases: ['identification_card'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '🏧',
    description: 'ATM sign',
    category: 'Symbols',
    aliases: ['atm'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚮',
    description: 'litter in bin sign',
    category: 'Symbols',
    aliases: ['put_litter_in_its_place'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚰',
    description: 'potable water',
    category: 'Symbols',
    aliases: ['potable_water'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '♿',
    description: 'wheelchair symbol',
    category: 'Symbols',
    aliases: ['wheelchair'],
    keys: ['accessibility'],
    unicode_version: '4.1',
    ios_version: '6.0',
  },
  {
    value: '🚹',
    description: 'men’s room',
    category: 'Symbols',
    aliases: ['mens'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚺',
    description: 'women’s room',
    category: 'Symbols',
    aliases: ['womens'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚻',
    description: 'restroom',
    category: 'Symbols',
    aliases: ['restroom'],
    keys: ['toilet'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚼',
    description: 'baby symbol',
    category: 'Symbols',
    aliases: ['baby_symbol'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚾',
    description: 'water closet',
    category: 'Symbols',
    aliases: ['wc'],
    keys: ['toilet', 'restroom'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛂',
    description: 'passport control',
    category: 'Symbols',
    aliases: ['passport_control'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛃',
    description: 'customs',
    category: 'Symbols',
    aliases: ['customs'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛄',
    description: 'baggage claim',
    category: 'Symbols',
    aliases: ['baggage_claim'],
    keys: ['airport'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛅',
    description: 'left luggage',
    category: 'Symbols',
    aliases: ['left_luggage'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⚠️',
    description: 'warning',
    category: 'Symbols',
    aliases: ['warning'],
    keys: ['wip'],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    value: '🚸',
    description: 'children crossing',
    category: 'Symbols',
    aliases: ['children_crossing'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⛔',
    description: 'no entry',
    category: 'Symbols',
    aliases: ['no_entry'],
    keys: ['limit'],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '🚫',
    description: 'prohibited',
    category: 'Symbols',
    aliases: ['no_entry_sign'],
    keys: ['block', 'forbidden'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚳',
    description: 'no bicycles',
    category: 'Symbols',
    aliases: ['no_bicycles'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚭',
    description: 'no smoking',
    category: 'Symbols',
    aliases: ['no_smoking'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚯',
    description: 'no littering',
    category: 'Symbols',
    aliases: ['do_not_litter'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚱',
    description: 'non-potable water',
    category: 'Symbols',
    aliases: ['non-potable_water'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚷',
    description: 'no pedestrians',
    category: 'Symbols',
    aliases: ['no_pedestrians'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📵',
    description: 'no mobile phones',
    category: 'Symbols',
    aliases: ['no_mobile_phones'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔞',
    description: 'no one under eighteen',
    category: 'Symbols',
    aliases: ['underage'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '☢️',
    description: 'radioactive',
    category: 'Symbols',
    aliases: ['radioactive'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '☣️',
    description: 'biohazard',
    category: 'Symbols',
    aliases: ['biohazard'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '⬆️',
    description: 'up arrow',
    category: 'Symbols',
    aliases: ['arrow_up'],
    keys: [],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    value: '↗️',
    description: 'up-right arrow',
    category: 'Symbols',
    aliases: ['arrow_upper_right'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '➡️',
    description: 'right arrow',
    category: 'Symbols',
    aliases: ['arrow_right'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '↘️',
    description: 'down-right arrow',
    category: 'Symbols',
    aliases: ['arrow_lower_right'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⬇️',
    description: 'down arrow',
    category: 'Symbols',
    aliases: ['arrow_down'],
    keys: [],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    value: '↙️',
    description: 'down-left arrow',
    category: 'Symbols',
    aliases: ['arrow_lower_left'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⬅️',
    description: 'left arrow',
    category: 'Symbols',
    aliases: ['arrow_left'],
    keys: [],
    unicode_version: '4.0',
    ios_version: '6.0',
  },
  {
    value: '↖️',
    description: 'up-left arrow',
    category: 'Symbols',
    aliases: ['arrow_upper_left'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '↕️',
    description: 'up-down arrow',
    category: 'Symbols',
    aliases: ['arrow_up_down'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '↔️',
    description: 'left-right arrow',
    category: 'Symbols',
    aliases: ['left_right_arrow'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '↩️',
    description: 'right arrow curving left',
    category: 'Symbols',
    aliases: ['leftwards_arrow_with_hook'],
    keys: ['return'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '↪️',
    description: 'left arrow curving right',
    category: 'Symbols',
    aliases: ['arrow_right_hook'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⤴️',
    description: 'right arrow curving up',
    category: 'Symbols',
    aliases: ['arrow_heading_up'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⤵️',
    description: 'right arrow curving down',
    category: 'Symbols',
    aliases: ['arrow_heading_down'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🔃',
    description: 'clockwise vertical arrows',
    category: 'Symbols',
    aliases: ['arrows_clockwise'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔄',
    description: 'counterclockwise arrows button',
    category: 'Symbols',
    aliases: ['arrows_counterclockwise'],
    keys: ['sync'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔙',
    description: 'BACK arrow',
    category: 'Symbols',
    aliases: ['back'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔚',
    description: 'END arrow',
    category: 'Symbols',
    aliases: ['end'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔛',
    description: 'ON! arrow',
    category: 'Symbols',
    aliases: ['on'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔜',
    description: 'SOON arrow',
    category: 'Symbols',
    aliases: ['soon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔝',
    description: 'TOP arrow',
    category: 'Symbols',
    aliases: ['top'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛐',
    description: 'place of worship',
    category: 'Symbols',
    aliases: ['place_of_worship'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '⚛️',
    description: 'atom symbol',
    category: 'Symbols',
    aliases: ['atom_symbol'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🕉️',
    description: 'om',
    category: 'Symbols',
    aliases: ['om'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '✡️',
    description: 'star of David',
    category: 'Symbols',
    aliases: ['star_of_david'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '☸️',
    description: 'wheel of dharma',
    category: 'Symbols',
    aliases: ['wheel_of_dharma'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '☯️',
    description: 'yin yang',
    category: 'Symbols',
    aliases: ['yin_yang'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '✝️',
    description: 'latin cross',
    category: 'Symbols',
    aliases: ['latin_cross'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '☦️',
    description: 'orthodox cross',
    category: 'Symbols',
    aliases: ['orthodox_cross'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '☪️',
    description: 'star and crescent',
    category: 'Symbols',
    aliases: ['star_and_crescent'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '☮️',
    description: 'peace symbol',
    category: 'Symbols',
    aliases: ['peace_symbol'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '🕎',
    description: 'menorah',
    category: 'Symbols',
    aliases: ['menorah'],
    keys: [],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🔯',
    description: 'dotted six-pointed star',
    category: 'Symbols',
    aliases: ['six_pointed_star'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🪯',
    description: 'khanda',
    category: 'Symbols',
    aliases: ['khanda'],
    keys: [],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '♈',
    description: 'Aries',
    category: 'Symbols',
    aliases: ['aries'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♉',
    description: 'Taurus',
    category: 'Symbols',
    aliases: ['taurus'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♊',
    description: 'Gemini',
    category: 'Symbols',
    aliases: ['gemini'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♋',
    description: 'Cancer',
    category: 'Symbols',
    aliases: ['cancer'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♌',
    description: 'Leo',
    category: 'Symbols',
    aliases: ['leo'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♍',
    description: 'Virgo',
    category: 'Symbols',
    aliases: ['virgo'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♎',
    description: 'Libra',
    category: 'Symbols',
    aliases: ['libra'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♏',
    description: 'Scorpio',
    category: 'Symbols',
    aliases: ['scorpius'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♐',
    description: 'Sagittarius',
    category: 'Symbols',
    aliases: ['sagittarius'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♑',
    description: 'Capricorn',
    category: 'Symbols',
    aliases: ['capricorn'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♒',
    description: 'Aquarius',
    category: 'Symbols',
    aliases: ['aquarius'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '♓',
    description: 'Pisces',
    category: 'Symbols',
    aliases: ['pisces'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⛎',
    description: 'Ophiuchus',
    category: 'Symbols',
    aliases: ['ophiuchus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔀',
    description: 'shuffle tracks button',
    category: 'Symbols',
    aliases: ['twisted_rightwards_arrows'],
    keys: ['shuffle'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔁',
    description: 'repeat button',
    category: 'Symbols',
    aliases: ['repeat'],
    keys: ['loop'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔂',
    description: 'repeat single button',
    category: 'Symbols',
    aliases: ['repeat_one'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '▶️',
    description: 'play button',
    category: 'Symbols',
    aliases: ['arrow_forward'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⏩',
    description: 'fast-forward button',
    category: 'Symbols',
    aliases: ['fast_forward'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⏭️',
    description: 'next track button',
    category: 'Symbols',
    aliases: ['next_track_button'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    value: '⏯️',
    description: 'play or pause button',
    category: 'Symbols',
    aliases: ['play_or_pause_button'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    value: '◀️',
    description: 'reverse button',
    category: 'Symbols',
    aliases: ['arrow_backward'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⏪',
    description: 'fast reverse button',
    category: 'Symbols',
    aliases: ['rewind'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⏮️',
    description: 'last track button',
    category: 'Symbols',
    aliases: ['previous_track_button'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.1',
  },
  {
    value: '🔼',
    description: 'upwards button',
    category: 'Symbols',
    aliases: ['arrow_up_small'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⏫',
    description: 'fast up button',
    category: 'Symbols',
    aliases: ['arrow_double_up'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔽',
    description: 'downwards button',
    category: 'Symbols',
    aliases: ['arrow_down_small'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⏬',
    description: 'fast down button',
    category: 'Symbols',
    aliases: ['arrow_double_down'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⏸️',
    description: 'pause button',
    category: 'Symbols',
    aliases: ['pause_button'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⏹️',
    description: 'stop button',
    category: 'Symbols',
    aliases: ['stop_button'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⏺️',
    description: 'record button',
    category: 'Symbols',
    aliases: ['record_button'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '⏏️',
    description: 'eject button',
    category: 'Symbols',
    aliases: ['eject_button'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🎦',
    description: 'cinema',
    category: 'Symbols',
    aliases: ['cinema'],
    keys: ['film', 'movie'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔅',
    description: 'dim button',
    category: 'Symbols',
    aliases: ['low_brightness'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔆',
    description: 'bright button',
    category: 'Symbols',
    aliases: ['high_brightness'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📶',
    description: 'antenna bars',
    category: 'Symbols',
    aliases: ['signal_strength'],
    keys: ['wifi'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🛜',
    description: 'wireless',
    category: 'Symbols',
    aliases: ['wireless'],
    keys: ['wifi'],
    unicode_version: '15.0',
    ios_version: '16.4',
  },
  {
    value: '📳',
    description: 'vibration mode',
    category: 'Symbols',
    aliases: ['vibration_mode'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📴',
    description: 'mobile phone off',
    category: 'Symbols',
    aliases: ['mobile_phone_off'],
    keys: ['mute', 'off'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '♀️',
    description: 'female sign',
    category: 'Symbols',
    aliases: ['female_sign'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '♂️',
    description: 'male sign',
    category: 'Symbols',
    aliases: ['male_sign'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '⚧️',
    description: 'transgender symbol',
    category: 'Symbols',
    aliases: ['transgender_symbol'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '✖️',
    description: 'multiply',
    category: 'Symbols',
    aliases: ['heavy_multiplication_x'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '➕',
    description: 'plus',
    category: 'Symbols',
    aliases: ['heavy_plus_sign'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '➖',
    description: 'minus',
    category: 'Symbols',
    aliases: ['heavy_minus_sign'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '➗',
    description: 'divide',
    category: 'Symbols',
    aliases: ['heavy_division_sign'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🟰',
    description: 'heavy equals sign',
    category: 'Symbols',
    aliases: ['heavy_equals_sign'],
    keys: [],
    unicode_version: '14.0',
    ios_version: '15.4',
  },
  {
    value: '♾️',
    description: 'infinity',
    category: 'Symbols',
    aliases: ['infinity'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '‼️',
    description: 'double exclamation mark',
    category: 'Symbols',
    aliases: ['bangbang'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '⁉️',
    description: 'exclamation question mark',
    category: 'Symbols',
    aliases: ['interrobang'],
    keys: [],
    unicode_version: '3.0',
    ios_version: '6.0',
  },
  {
    value: '❓',
    description: 'red question mark',
    category: 'Symbols',
    aliases: ['question'],
    keys: ['confused'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '❔',
    description: 'white question mark',
    category: 'Symbols',
    aliases: ['grey_question'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '❕',
    description: 'white exclamation mark',
    category: 'Symbols',
    aliases: ['grey_exclamation'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '❗',
    description: 'red exclamation mark',
    category: 'Symbols',
    aliases: ['exclamation', 'heavy_exclamation_mark'],
    keys: ['bang'],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '〰️',
    description: 'wavy dash',
    category: 'Symbols',
    aliases: ['wavy_dash'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '💱',
    description: 'currency exchange',
    category: 'Symbols',
    aliases: ['currency_exchange'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💲',
    description: 'heavy dollar sign',
    category: 'Symbols',
    aliases: ['heavy_dollar_sign'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⚕️',
    description: 'medical symbol',
    category: 'Symbols',
    aliases: ['medical_symbol'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '♻️',
    description: 'recycling symbol',
    category: 'Symbols',
    aliases: ['recycle'],
    keys: ['environment', 'green'],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    value: '⚜️',
    description: 'fleur-de-lis',
    category: 'Symbols',
    aliases: ['fleur_de_lis'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '9.1',
  },
  {
    value: '🔱',
    description: 'trident emblem',
    category: 'Symbols',
    aliases: ['trident'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '📛',
    description: 'name badge',
    category: 'Symbols',
    aliases: ['name_badge'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔰',
    description: 'Japanese symbol for beginner',
    category: 'Symbols',
    aliases: ['beginner'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '⭕',
    description: 'hollow red circle',
    category: 'Symbols',
    aliases: ['o'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '✅',
    description: 'check mark button',
    category: 'Symbols',
    aliases: ['white_check_mark'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '☑️',
    description: 'check box with check',
    category: 'Symbols',
    aliases: ['ballot_box_with_check'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '✔️',
    description: 'check mark',
    category: 'Symbols',
    aliases: ['heavy_check_mark'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '❌',
    description: 'cross mark',
    category: 'Symbols',
    aliases: ['x'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '❎',
    description: 'cross mark button',
    category: 'Symbols',
    aliases: ['negative_squared_cross_mark'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '➰',
    description: 'curly loop',
    category: 'Symbols',
    aliases: ['curly_loop'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '➿',
    description: 'double curly loop',
    category: 'Symbols',
    aliases: ['loop'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '〽️',
    description: 'part alternation mark',
    category: 'Symbols',
    aliases: ['part_alternation_mark'],
    keys: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    value: '✳️',
    description: 'eight-spoked asterisk',
    category: 'Symbols',
    aliases: ['eight_spoked_asterisk'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '✴️',
    description: 'eight-pointed star',
    category: 'Symbols',
    aliases: ['eight_pointed_black_star'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '❇️',
    description: 'sparkle',
    category: 'Symbols',
    aliases: ['sparkle'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '©️',
    description: 'copyright',
    category: 'Symbols',
    aliases: ['copyright'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '®️',
    description: 'registered',
    category: 'Symbols',
    aliases: ['registered'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '™️',
    description: 'trade mark',
    category: 'Symbols',
    aliases: ['tm'],
    keys: ['trademark'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '#️⃣',
    description: 'keycap: #',
    category: 'Symbols',
    aliases: ['hash'],
    keys: ['number'],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '*️⃣',
    description: 'keycap: *',
    category: 'Symbols',
    aliases: ['asterisk'],
    keys: [],
    unicode_version: '',
    ios_version: '9.1',
  },
  {
    value: '0️⃣',
    description: 'keycap: 0',
    category: 'Symbols',
    aliases: ['zero'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '1️⃣',
    description: 'keycap: 1',
    category: 'Symbols',
    aliases: ['one'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '2️⃣',
    description: 'keycap: 2',
    category: 'Symbols',
    aliases: ['two'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '3️⃣',
    description: 'keycap: 3',
    category: 'Symbols',
    aliases: ['three'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '4️⃣',
    description: 'keycap: 4',
    category: 'Symbols',
    aliases: ['four'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '5️⃣',
    description: 'keycap: 5',
    category: 'Symbols',
    aliases: ['five'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '6️⃣',
    description: 'keycap: 6',
    category: 'Symbols',
    aliases: ['six'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '7️⃣',
    description: 'keycap: 7',
    category: 'Symbols',
    aliases: ['seven'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '8️⃣',
    description: 'keycap: 8',
    category: 'Symbols',
    aliases: ['eight'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '9️⃣',
    description: 'keycap: 9',
    category: 'Symbols',
    aliases: ['nine'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🔟',
    description: 'keycap: 10',
    category: 'Symbols',
    aliases: ['keycap_ten'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔠',
    description: 'input latin uppercase',
    category: 'Symbols',
    aliases: ['capital_abcd'],
    keys: ['letters'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔡',
    description: 'input latin lowercase',
    category: 'Symbols',
    aliases: ['abcd'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔢',
    description: 'input numbers',
    category: 'Symbols',
    aliases: ['1234'],
    keys: ['numbers'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔣',
    description: 'input symbols',
    category: 'Symbols',
    aliases: ['symbols'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔤',
    description: 'input latin letters',
    category: 'Symbols',
    aliases: ['abc'],
    keys: ['alphabet'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🅰️',
    description: 'A button (blood type)',
    category: 'Symbols',
    aliases: ['a'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🆎',
    description: 'AB button (blood type)',
    category: 'Symbols',
    aliases: ['ab'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🅱️',
    description: 'B button (blood type)',
    category: 'Symbols',
    aliases: ['b'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🆑',
    description: 'CL button',
    category: 'Symbols',
    aliases: ['cl'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🆒',
    description: 'COOL button',
    category: 'Symbols',
    aliases: ['cool'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🆓',
    description: 'FREE button',
    category: 'Symbols',
    aliases: ['free'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: 'ℹ️',
    description: 'information',
    category: 'Symbols',
    aliases: ['information_source'],
    keys: [],
    unicode_version: '3.0',
    ios_version: '6.0',
  },
  {
    value: '🆔',
    description: 'ID button',
    category: 'Symbols',
    aliases: ['id'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: 'Ⓜ️',
    description: 'circled M',
    category: 'Symbols',
    aliases: ['m'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🆕',
    description: 'NEW button',
    category: 'Symbols',
    aliases: ['new'],
    keys: ['fresh'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🆖',
    description: 'NG button',
    category: 'Symbols',
    aliases: ['ng'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🅾️',
    description: 'O button (blood type)',
    category: 'Symbols',
    aliases: ['o2'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🆗',
    description: 'OK button',
    category: 'Symbols',
    aliases: ['ok'],
    keys: ['yes'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🅿️',
    description: 'P button',
    category: 'Symbols',
    aliases: ['parking'],
    keys: [],
    unicode_version: '5.2',
    ios_version: '6.0',
  },
  {
    value: '🆘',
    description: 'SOS button',
    category: 'Symbols',
    aliases: ['sos'],
    keys: ['help', 'emergency'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🆙',
    description: 'UP! button',
    category: 'Symbols',
    aliases: ['up'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🆚',
    description: 'VS button',
    category: 'Symbols',
    aliases: ['vs'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈁',
    description: 'Japanese “here” button',
    category: 'Symbols',
    aliases: ['koko'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈂️',
    description: 'Japanese “service charge” button',
    category: 'Symbols',
    aliases: ['sa'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈷️',
    description: 'Japanese “monthly amount” button',
    category: 'Symbols',
    aliases: ['u6708'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈶',
    description: 'Japanese “not free of charge” button',
    category: 'Symbols',
    aliases: ['u6709'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈯',
    description: 'Japanese “reserved” button',
    category: 'Symbols',
    aliases: ['u6307'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🉐',
    description: 'Japanese “bargain” button',
    category: 'Symbols',
    aliases: ['ideograph_advantage'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈹',
    description: 'Japanese “discount” button',
    category: 'Symbols',
    aliases: ['u5272'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈚',
    description: 'Japanese “free of charge” button',
    category: 'Symbols',
    aliases: ['u7121'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🈲',
    description: 'Japanese “prohibited” button',
    category: 'Symbols',
    aliases: ['u7981'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🉑',
    description: 'Japanese “acceptable” button',
    category: 'Symbols',
    aliases: ['accept'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈸',
    description: 'Japanese “application” button',
    category: 'Symbols',
    aliases: ['u7533'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈴',
    description: 'Japanese “passing grade” button',
    category: 'Symbols',
    aliases: ['u5408'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈳',
    description: 'Japanese “vacancy” button',
    category: 'Symbols',
    aliases: ['u7a7a'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '㊗️',
    description: 'Japanese “congratulations” button',
    category: 'Symbols',
    aliases: ['congratulations'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '㊙️',
    description: 'Japanese “secret” button',
    category: 'Symbols',
    aliases: ['secret'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🈺',
    description: 'Japanese “open for business” button',
    category: 'Symbols',
    aliases: ['u55b6'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🈵',
    description: 'Japanese “no vacancy” button',
    category: 'Symbols',
    aliases: ['u6e80'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔴',
    description: 'red circle',
    category: 'Symbols',
    aliases: ['red_circle'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🟠',
    description: 'orange circle',
    category: 'Symbols',
    aliases: ['orange_circle'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟡',
    description: 'yellow circle',
    category: 'Symbols',
    aliases: ['yellow_circle'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟢',
    description: 'green circle',
    category: 'Symbols',
    aliases: ['green_circle'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🔵',
    description: 'blue circle',
    category: 'Symbols',
    aliases: ['large_blue_circle'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🟣',
    description: 'purple circle',
    category: 'Symbols',
    aliases: ['purple_circle'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟤',
    description: 'brown circle',
    category: 'Symbols',
    aliases: ['brown_circle'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '⚫',
    description: 'black circle',
    category: 'Symbols',
    aliases: ['black_circle'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '6.0',
  },
  {
    value: '⚪',
    description: 'white circle',
    category: 'Symbols',
    aliases: ['white_circle'],
    keys: [],
    unicode_version: '4.1',
    ios_version: '6.0',
  },
  {
    value: '🟥',
    description: 'red square',
    category: 'Symbols',
    aliases: ['red_square'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟧',
    description: 'orange square',
    category: 'Symbols',
    aliases: ['orange_square'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟨',
    description: 'yellow square',
    category: 'Symbols',
    aliases: ['yellow_square'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟩',
    description: 'green square',
    category: 'Symbols',
    aliases: ['green_square'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟦',
    description: 'blue square',
    category: 'Symbols',
    aliases: ['blue_square'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟪',
    description: 'purple square',
    category: 'Symbols',
    aliases: ['purple_square'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '🟫',
    description: 'brown square',
    category: 'Symbols',
    aliases: ['brown_square'],
    keys: [],
    unicode_version: '12.0',
    ios_version: '13.0',
  },
  {
    value: '⬛',
    description: 'black large square',
    category: 'Symbols',
    aliases: ['black_large_square'],
    keys: [],
    unicode_version: '5.1',
    ios_version: '6.0',
  },
  {
    value: '⬜',
    description: 'white large square',
    category: 'Symbols',
    aliases: ['white_large_square'],
    keys: [],
    unicode_version: '5.1',
    ios_version: '6.0',
  },
  {
    value: '◼️',
    description: 'black medium square',
    category: 'Symbols',
    aliases: ['black_medium_square'],
    keys: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    value: '◻️',
    description: 'white medium square',
    category: 'Symbols',
    aliases: ['white_medium_square'],
    keys: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    value: '◾',
    description: 'black medium-small square',
    category: 'Symbols',
    aliases: ['black_medium_small_square'],
    keys: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    value: '◽',
    description: 'white medium-small square',
    category: 'Symbols',
    aliases: ['white_medium_small_square'],
    keys: [],
    unicode_version: '3.2',
    ios_version: '6.0',
  },
  {
    value: '▪️',
    description: 'black small square',
    category: 'Symbols',
    aliases: ['black_small_square'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '▫️',
    description: 'white small square',
    category: 'Symbols',
    aliases: ['white_small_square'],
    keys: [],
    unicode_version: '',
    ios_version: '6.0',
  },
  {
    value: '🔶',
    description: 'large orange diamond',
    category: 'Symbols',
    aliases: ['large_orange_diamond'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔷',
    description: 'large blue diamond',
    category: 'Symbols',
    aliases: ['large_blue_diamond'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔸',
    description: 'small orange diamond',
    category: 'Symbols',
    aliases: ['small_orange_diamond'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔹',
    description: 'small blue diamond',
    category: 'Symbols',
    aliases: ['small_blue_diamond'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔺',
    description: 'red triangle pointed up',
    category: 'Symbols',
    aliases: ['small_red_triangle'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔻',
    description: 'red triangle pointed down',
    category: 'Symbols',
    aliases: ['small_red_triangle_down'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '💠',
    description: 'diamond with a dot',
    category: 'Symbols',
    aliases: ['diamond_shape_with_a_dot_inside'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔘',
    description: 'radio button',
    category: 'Symbols',
    aliases: ['radio_button'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔳',
    description: 'white square button',
    category: 'Symbols',
    aliases: ['white_square_button'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🔲',
    description: 'black square button',
    category: 'Symbols',
    aliases: ['black_square_button'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏁',
    description: 'chequered flag',
    category: 'Flags',
    aliases: ['checkered_flag'],
    keys: ['milestone', 'finish'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🚩',
    description: 'triangular flag',
    category: 'Flags',
    aliases: ['triangular_flag_on_post'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🎌',
    description: 'crossed flags',
    category: 'Flags',
    aliases: ['crossed_flags'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🏴',
    description: 'black flag',
    category: 'Flags',
    aliases: ['black_flag'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏳️',
    description: 'white flag',
    category: 'Flags',
    aliases: ['white_flag'],
    keys: [],
    unicode_version: '7.0',
    ios_version: '9.1',
  },
  {
    value: '🏳️‍🌈',
    description: 'rainbow flag',
    category: 'Flags',
    aliases: ['rainbow_flag'],
    keys: ['pride'],
    unicode_version: '6.0',
    ios_version: '10.0',
  },
  {
    value: '🏳️‍⚧️',
    description: 'transgender flag',
    category: 'Flags',
    aliases: ['transgender_flag'],
    keys: [],
    unicode_version: '13.0',
    ios_version: '14.0',
  },
  {
    value: '🏴‍☠️',
    description: 'pirate flag',
    category: 'Flags',
    aliases: ['pirate_flag'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇦🇨',
    description: 'flag: Ascension Island',
    category: 'Flags',
    aliases: ['ascension_island'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇦🇩',
    description: 'flag: Andorra',
    category: 'Flags',
    aliases: ['andorra'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇪',
    description: 'flag: United Arab Emirates',
    category: 'Flags',
    aliases: ['united_arab_emirates'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇫',
    description: 'flag: Afghanistan',
    category: 'Flags',
    aliases: ['afghanistan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇬',
    description: 'flag: Antigua & Barbuda',
    category: 'Flags',
    aliases: ['antigua_barbuda'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇮',
    description: 'flag: Anguilla',
    category: 'Flags',
    aliases: ['anguilla'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇱',
    description: 'flag: Albania',
    category: 'Flags',
    aliases: ['albania'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇲',
    description: 'flag: Armenia',
    category: 'Flags',
    aliases: ['armenia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇴',
    description: 'flag: Angola',
    category: 'Flags',
    aliases: ['angola'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇶',
    description: 'flag: Antarctica',
    category: 'Flags',
    aliases: ['antarctica'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇦🇷',
    description: 'flag: Argentina',
    category: 'Flags',
    aliases: ['argentina'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇸',
    description: 'flag: American Samoa',
    category: 'Flags',
    aliases: ['american_samoa'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇹',
    description: 'flag: Austria',
    category: 'Flags',
    aliases: ['austria'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇺',
    description: 'flag: Australia',
    category: 'Flags',
    aliases: ['australia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇼',
    description: 'flag: Aruba',
    category: 'Flags',
    aliases: ['aruba'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇦🇽',
    description: 'flag: Åland Islands',
    category: 'Flags',
    aliases: ['aland_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇦🇿',
    description: 'flag: Azerbaijan',
    category: 'Flags',
    aliases: ['azerbaijan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇦',
    description: 'flag: Bosnia & Herzegovina',
    category: 'Flags',
    aliases: ['bosnia_herzegovina'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇧',
    description: 'flag: Barbados',
    category: 'Flags',
    aliases: ['barbados'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇩',
    description: 'flag: Bangladesh',
    category: 'Flags',
    aliases: ['bangladesh'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇪',
    description: 'flag: Belgium',
    category: 'Flags',
    aliases: ['belgium'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇫',
    description: 'flag: Burkina Faso',
    category: 'Flags',
    aliases: ['burkina_faso'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇬',
    description: 'flag: Bulgaria',
    category: 'Flags',
    aliases: ['bulgaria'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇭',
    description: 'flag: Bahrain',
    category: 'Flags',
    aliases: ['bahrain'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇮',
    description: 'flag: Burundi',
    category: 'Flags',
    aliases: ['burundi'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇯',
    description: 'flag: Benin',
    category: 'Flags',
    aliases: ['benin'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇱',
    description: 'flag: St. Barthélemy',
    category: 'Flags',
    aliases: ['st_barthelemy'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇧🇲',
    description: 'flag: Bermuda',
    category: 'Flags',
    aliases: ['bermuda'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇳',
    description: 'flag: Brunei',
    category: 'Flags',
    aliases: ['brunei'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇴',
    description: 'flag: Bolivia',
    category: 'Flags',
    aliases: ['bolivia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇶',
    description: 'flag: Caribbean Netherlands',
    category: 'Flags',
    aliases: ['caribbean_netherlands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇧🇷',
    description: 'flag: Brazil',
    category: 'Flags',
    aliases: ['brazil'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇸',
    description: 'flag: Bahamas',
    category: 'Flags',
    aliases: ['bahamas'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇹',
    description: 'flag: Bhutan',
    category: 'Flags',
    aliases: ['bhutan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇻',
    description: 'flag: Bouvet Island',
    category: 'Flags',
    aliases: ['bouvet_island'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇧🇼',
    description: 'flag: Botswana',
    category: 'Flags',
    aliases: ['botswana'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇾',
    description: 'flag: Belarus',
    category: 'Flags',
    aliases: ['belarus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇧🇿',
    description: 'flag: Belize',
    category: 'Flags',
    aliases: ['belize'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇦',
    description: 'flag: Canada',
    category: 'Flags',
    aliases: ['canada'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇨',
    description: 'flag: Cocos (Keeling) Islands',
    category: 'Flags',
    aliases: ['cocos_islands'],
    keys: ['keeling'],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇨🇩',
    description: 'flag: Congo - Kinshasa',
    category: 'Flags',
    aliases: ['congo_kinshasa'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇫',
    description: 'flag: Central African Republic',
    category: 'Flags',
    aliases: ['central_african_republic'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇬',
    description: 'flag: Congo - Brazzaville',
    category: 'Flags',
    aliases: ['congo_brazzaville'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇭',
    description: 'flag: Switzerland',
    category: 'Flags',
    aliases: ['switzerland'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇮',
    description: 'flag: Côte d’Ivoire',
    category: 'Flags',
    aliases: ['cote_divoire'],
    keys: ['ivory'],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇰',
    description: 'flag: Cook Islands',
    category: 'Flags',
    aliases: ['cook_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇱',
    description: 'flag: Chile',
    category: 'Flags',
    aliases: ['chile'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇲',
    description: 'flag: Cameroon',
    category: 'Flags',
    aliases: ['cameroon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇳',
    description: 'flag: China',
    category: 'Flags',
    aliases: ['cn'],
    keys: ['china'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇨🇴',
    description: 'flag: Colombia',
    category: 'Flags',
    aliases: ['colombia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇵',
    description: 'flag: Clipperton Island',
    category: 'Flags',
    aliases: ['clipperton_island'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇨🇷',
    description: 'flag: Costa Rica',
    category: 'Flags',
    aliases: ['costa_rica'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇺',
    description: 'flag: Cuba',
    category: 'Flags',
    aliases: ['cuba'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇻',
    description: 'flag: Cape Verde',
    category: 'Flags',
    aliases: ['cape_verde'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇼',
    description: 'flag: Curaçao',
    category: 'Flags',
    aliases: ['curacao'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇽',
    description: 'flag: Christmas Island',
    category: 'Flags',
    aliases: ['christmas_island'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇨🇾',
    description: 'flag: Cyprus',
    category: 'Flags',
    aliases: ['cyprus'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇨🇿',
    description: 'flag: Czechia',
    category: 'Flags',
    aliases: ['czech_republic'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇩🇪',
    description: 'flag: Germany',
    category: 'Flags',
    aliases: ['de'],
    keys: ['flag', 'germany'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇩🇬',
    description: 'flag: Diego Garcia',
    category: 'Flags',
    aliases: ['diego_garcia'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇩🇯',
    description: 'flag: Djibouti',
    category: 'Flags',
    aliases: ['djibouti'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇩🇰',
    description: 'flag: Denmark',
    category: 'Flags',
    aliases: ['denmark'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇩🇲',
    description: 'flag: Dominica',
    category: 'Flags',
    aliases: ['dominica'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇩🇴',
    description: 'flag: Dominican Republic',
    category: 'Flags',
    aliases: ['dominican_republic'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇩🇿',
    description: 'flag: Algeria',
    category: 'Flags',
    aliases: ['algeria'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇪🇦',
    description: 'flag: Ceuta & Melilla',
    category: 'Flags',
    aliases: ['ceuta_melilla'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇪🇨',
    description: 'flag: Ecuador',
    category: 'Flags',
    aliases: ['ecuador'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇪🇪',
    description: 'flag: Estonia',
    category: 'Flags',
    aliases: ['estonia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇪🇬',
    description: 'flag: Egypt',
    category: 'Flags',
    aliases: ['egypt'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇪🇭',
    description: 'flag: Western Sahara',
    category: 'Flags',
    aliases: ['western_sahara'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇪🇷',
    description: 'flag: Eritrea',
    category: 'Flags',
    aliases: ['eritrea'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇪🇸',
    description: 'flag: Spain',
    category: 'Flags',
    aliases: ['es'],
    keys: ['spain'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇪🇹',
    description: 'flag: Ethiopia',
    category: 'Flags',
    aliases: ['ethiopia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇪🇺',
    description: 'flag: European Union',
    category: 'Flags',
    aliases: ['eu', 'european_union'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇫🇮',
    description: 'flag: Finland',
    category: 'Flags',
    aliases: ['finland'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇫🇯',
    description: 'flag: Fiji',
    category: 'Flags',
    aliases: ['fiji'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇫🇰',
    description: 'flag: Falkland Islands',
    category: 'Flags',
    aliases: ['falkland_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇫🇲',
    description: 'flag: Micronesia',
    category: 'Flags',
    aliases: ['micronesia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇫🇴',
    description: 'flag: Faroe Islands',
    category: 'Flags',
    aliases: ['faroe_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇫🇷',
    description: 'flag: France',
    category: 'Flags',
    aliases: ['fr'],
    keys: ['france', 'french'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇬🇦',
    description: 'flag: Gabon',
    category: 'Flags',
    aliases: ['gabon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇧',
    description: 'flag: United Kingdom',
    category: 'Flags',
    aliases: ['gb', 'uk'],
    keys: ['flag', 'british'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇬🇩',
    description: 'flag: Grenada',
    category: 'Flags',
    aliases: ['grenada'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇪',
    description: 'flag: Georgia',
    category: 'Flags',
    aliases: ['georgia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇫',
    description: 'flag: French Guiana',
    category: 'Flags',
    aliases: ['french_guiana'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇬',
    description: 'flag: Guernsey',
    category: 'Flags',
    aliases: ['guernsey'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇬🇭',
    description: 'flag: Ghana',
    category: 'Flags',
    aliases: ['ghana'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇮',
    description: 'flag: Gibraltar',
    category: 'Flags',
    aliases: ['gibraltar'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇱',
    description: 'flag: Greenland',
    category: 'Flags',
    aliases: ['greenland'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇬🇲',
    description: 'flag: Gambia',
    category: 'Flags',
    aliases: ['gambia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇳',
    description: 'flag: Guinea',
    category: 'Flags',
    aliases: ['guinea'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇵',
    description: 'flag: Guadeloupe',
    category: 'Flags',
    aliases: ['guadeloupe'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇬🇶',
    description: 'flag: Equatorial Guinea',
    category: 'Flags',
    aliases: ['equatorial_guinea'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇷',
    description: 'flag: Greece',
    category: 'Flags',
    aliases: ['greece'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇸',
    description: 'flag: South Georgia & South Sandwich Islands',
    category: 'Flags',
    aliases: ['south_georgia_south_sandwich_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇬🇹',
    description: 'flag: Guatemala',
    category: 'Flags',
    aliases: ['guatemala'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇺',
    description: 'flag: Guam',
    category: 'Flags',
    aliases: ['guam'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇼',
    description: 'flag: Guinea-Bissau',
    category: 'Flags',
    aliases: ['guinea_bissau'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇬🇾',
    description: 'flag: Guyana',
    category: 'Flags',
    aliases: ['guyana'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇭🇰',
    description: 'flag: Hong Kong SAR China',
    category: 'Flags',
    aliases: ['hong_kong'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇭🇲',
    description: 'flag: Heard & McDonald Islands',
    category: 'Flags',
    aliases: ['heard_mcdonald_islands'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇭🇳',
    description: 'flag: Honduras',
    category: 'Flags',
    aliases: ['honduras'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇭🇷',
    description: 'flag: Croatia',
    category: 'Flags',
    aliases: ['croatia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇭🇹',
    description: 'flag: Haiti',
    category: 'Flags',
    aliases: ['haiti'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇭🇺',
    description: 'flag: Hungary',
    category: 'Flags',
    aliases: ['hungary'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇮🇨',
    description: 'flag: Canary Islands',
    category: 'Flags',
    aliases: ['canary_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇮🇩',
    description: 'flag: Indonesia',
    category: 'Flags',
    aliases: ['indonesia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇮🇪',
    description: 'flag: Ireland',
    category: 'Flags',
    aliases: ['ireland'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇮🇱',
    description: 'flag: Israel',
    category: 'Flags',
    aliases: ['israel'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇮🇲',
    description: 'flag: Isle of Man',
    category: 'Flags',
    aliases: ['isle_of_man'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇮🇳',
    description: 'flag: India',
    category: 'Flags',
    aliases: ['india'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇮🇴',
    description: 'flag: British Indian Ocean Territory',
    category: 'Flags',
    aliases: ['british_indian_ocean_territory'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇮🇶',
    description: 'flag: Iraq',
    category: 'Flags',
    aliases: ['iraq'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇮🇷',
    description: 'flag: Iran',
    category: 'Flags',
    aliases: ['iran'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇮🇸',
    description: 'flag: Iceland',
    category: 'Flags',
    aliases: ['iceland'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇮🇹',
    description: 'flag: Italy',
    category: 'Flags',
    aliases: ['it'],
    keys: ['italy'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇯🇪',
    description: 'flag: Jersey',
    category: 'Flags',
    aliases: ['jersey'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇯🇲',
    description: 'flag: Jamaica',
    category: 'Flags',
    aliases: ['jamaica'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇯🇴',
    description: 'flag: Jordan',
    category: 'Flags',
    aliases: ['jordan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇯🇵',
    description: 'flag: Japan',
    category: 'Flags',
    aliases: ['jp'],
    keys: ['japan'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇰🇪',
    description: 'flag: Kenya',
    category: 'Flags',
    aliases: ['kenya'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇬',
    description: 'flag: Kyrgyzstan',
    category: 'Flags',
    aliases: ['kyrgyzstan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇭',
    description: 'flag: Cambodia',
    category: 'Flags',
    aliases: ['cambodia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇮',
    description: 'flag: Kiribati',
    category: 'Flags',
    aliases: ['kiribati'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇲',
    description: 'flag: Comoros',
    category: 'Flags',
    aliases: ['comoros'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇳',
    description: 'flag: St. Kitts & Nevis',
    category: 'Flags',
    aliases: ['st_kitts_nevis'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇵',
    description: 'flag: North Korea',
    category: 'Flags',
    aliases: ['north_korea'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇷',
    description: 'flag: South Korea',
    category: 'Flags',
    aliases: ['kr'],
    keys: ['korea'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇰🇼',
    description: 'flag: Kuwait',
    category: 'Flags',
    aliases: ['kuwait'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇾',
    description: 'flag: Cayman Islands',
    category: 'Flags',
    aliases: ['cayman_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇰🇿',
    description: 'flag: Kazakhstan',
    category: 'Flags',
    aliases: ['kazakhstan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇦',
    description: 'flag: Laos',
    category: 'Flags',
    aliases: ['laos'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇧',
    description: 'flag: Lebanon',
    category: 'Flags',
    aliases: ['lebanon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇨',
    description: 'flag: St. Lucia',
    category: 'Flags',
    aliases: ['st_lucia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇮',
    description: 'flag: Liechtenstein',
    category: 'Flags',
    aliases: ['liechtenstein'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇰',
    description: 'flag: Sri Lanka',
    category: 'Flags',
    aliases: ['sri_lanka'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇷',
    description: 'flag: Liberia',
    category: 'Flags',
    aliases: ['liberia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇸',
    description: 'flag: Lesotho',
    category: 'Flags',
    aliases: ['lesotho'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇹',
    description: 'flag: Lithuania',
    category: 'Flags',
    aliases: ['lithuania'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇺',
    description: 'flag: Luxembourg',
    category: 'Flags',
    aliases: ['luxembourg'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇻',
    description: 'flag: Latvia',
    category: 'Flags',
    aliases: ['latvia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇱🇾',
    description: 'flag: Libya',
    category: 'Flags',
    aliases: ['libya'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇦',
    description: 'flag: Morocco',
    category: 'Flags',
    aliases: ['morocco'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇨',
    description: 'flag: Monaco',
    category: 'Flags',
    aliases: ['monaco'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇲🇩',
    description: 'flag: Moldova',
    category: 'Flags',
    aliases: ['moldova'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇪',
    description: 'flag: Montenegro',
    category: 'Flags',
    aliases: ['montenegro'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇫',
    description: 'flag: St. Martin',
    category: 'Flags',
    aliases: ['st_martin'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇲🇬',
    description: 'flag: Madagascar',
    category: 'Flags',
    aliases: ['madagascar'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇭',
    description: 'flag: Marshall Islands',
    category: 'Flags',
    aliases: ['marshall_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇲🇰',
    description: 'flag: North Macedonia',
    category: 'Flags',
    aliases: ['macedonia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇱',
    description: 'flag: Mali',
    category: 'Flags',
    aliases: ['mali'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇲',
    description: 'flag: Myanmar (Burma)',
    category: 'Flags',
    aliases: ['myanmar'],
    keys: ['burma'],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇳',
    description: 'flag: Mongolia',
    category: 'Flags',
    aliases: ['mongolia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇴',
    description: 'flag: Macao SAR China',
    category: 'Flags',
    aliases: ['macau'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇵',
    description: 'flag: Northern Mariana Islands',
    category: 'Flags',
    aliases: ['northern_mariana_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇶',
    description: 'flag: Martinique',
    category: 'Flags',
    aliases: ['martinique'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇲🇷',
    description: 'flag: Mauritania',
    category: 'Flags',
    aliases: ['mauritania'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇸',
    description: 'flag: Montserrat',
    category: 'Flags',
    aliases: ['montserrat'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇹',
    description: 'flag: Malta',
    category: 'Flags',
    aliases: ['malta'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇺',
    description: 'flag: Mauritius',
    category: 'Flags',
    aliases: ['mauritius'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇲🇻',
    description: 'flag: Maldives',
    category: 'Flags',
    aliases: ['maldives'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇼',
    description: 'flag: Malawi',
    category: 'Flags',
    aliases: ['malawi'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇽',
    description: 'flag: Mexico',
    category: 'Flags',
    aliases: ['mexico'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇾',
    description: 'flag: Malaysia',
    category: 'Flags',
    aliases: ['malaysia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇲🇿',
    description: 'flag: Mozambique',
    category: 'Flags',
    aliases: ['mozambique'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇦',
    description: 'flag: Namibia',
    category: 'Flags',
    aliases: ['namibia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇨',
    description: 'flag: New Caledonia',
    category: 'Flags',
    aliases: ['new_caledonia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇪',
    description: 'flag: Niger',
    category: 'Flags',
    aliases: ['niger'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇫',
    description: 'flag: Norfolk Island',
    category: 'Flags',
    aliases: ['norfolk_island'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇳🇬',
    description: 'flag: Nigeria',
    category: 'Flags',
    aliases: ['nigeria'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇮',
    description: 'flag: Nicaragua',
    category: 'Flags',
    aliases: ['nicaragua'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇱',
    description: 'flag: Netherlands',
    category: 'Flags',
    aliases: ['netherlands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇴',
    description: 'flag: Norway',
    category: 'Flags',
    aliases: ['norway'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇵',
    description: 'flag: Nepal',
    category: 'Flags',
    aliases: ['nepal'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇷',
    description: 'flag: Nauru',
    category: 'Flags',
    aliases: ['nauru'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇳🇺',
    description: 'flag: Niue',
    category: 'Flags',
    aliases: ['niue'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇳🇿',
    description: 'flag: New Zealand',
    category: 'Flags',
    aliases: ['new_zealand'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇴🇲',
    description: 'flag: Oman',
    category: 'Flags',
    aliases: ['oman'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇦',
    description: 'flag: Panama',
    category: 'Flags',
    aliases: ['panama'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇪',
    description: 'flag: Peru',
    category: 'Flags',
    aliases: ['peru'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇫',
    description: 'flag: French Polynesia',
    category: 'Flags',
    aliases: ['french_polynesia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇵🇬',
    description: 'flag: Papua New Guinea',
    category: 'Flags',
    aliases: ['papua_new_guinea'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇭',
    description: 'flag: Philippines',
    category: 'Flags',
    aliases: ['philippines'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇰',
    description: 'flag: Pakistan',
    category: 'Flags',
    aliases: ['pakistan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇱',
    description: 'flag: Poland',
    category: 'Flags',
    aliases: ['poland'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇲',
    description: 'flag: St. Pierre & Miquelon',
    category: 'Flags',
    aliases: ['st_pierre_miquelon'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇵🇳',
    description: 'flag: Pitcairn Islands',
    category: 'Flags',
    aliases: ['pitcairn_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇵🇷',
    description: 'flag: Puerto Rico',
    category: 'Flags',
    aliases: ['puerto_rico'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇸',
    description: 'flag: Palestinian Territories',
    category: 'Flags',
    aliases: ['palestinian_territories'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇹',
    description: 'flag: Portugal',
    category: 'Flags',
    aliases: ['portugal'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇼',
    description: 'flag: Palau',
    category: 'Flags',
    aliases: ['palau'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇵🇾',
    description: 'flag: Paraguay',
    category: 'Flags',
    aliases: ['paraguay'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇶🇦',
    description: 'flag: Qatar',
    category: 'Flags',
    aliases: ['qatar'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇷🇪',
    description: 'flag: Réunion',
    category: 'Flags',
    aliases: ['reunion'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇷🇴',
    description: 'flag: Romania',
    category: 'Flags',
    aliases: ['romania'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇷🇸',
    description: 'flag: Serbia',
    category: 'Flags',
    aliases: ['serbia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇷🇺',
    description: 'flag: Russia',
    category: 'Flags',
    aliases: ['ru'],
    keys: ['russia'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇷🇼',
    description: 'flag: Rwanda',
    category: 'Flags',
    aliases: ['rwanda'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇦',
    description: 'flag: Saudi Arabia',
    category: 'Flags',
    aliases: ['saudi_arabia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇧',
    description: 'flag: Solomon Islands',
    category: 'Flags',
    aliases: ['solomon_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇨',
    description: 'flag: Seychelles',
    category: 'Flags',
    aliases: ['seychelles'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇩',
    description: 'flag: Sudan',
    category: 'Flags',
    aliases: ['sudan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇪',
    description: 'flag: Sweden',
    category: 'Flags',
    aliases: ['sweden'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇬',
    description: 'flag: Singapore',
    category: 'Flags',
    aliases: ['singapore'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇭',
    description: 'flag: St. Helena',
    category: 'Flags',
    aliases: ['st_helena'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇸🇮',
    description: 'flag: Slovenia',
    category: 'Flags',
    aliases: ['slovenia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇯',
    description: 'flag: Svalbard & Jan Mayen',
    category: 'Flags',
    aliases: ['svalbard_jan_mayen'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇸🇰',
    description: 'flag: Slovakia',
    category: 'Flags',
    aliases: ['slovakia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇱',
    description: 'flag: Sierra Leone',
    category: 'Flags',
    aliases: ['sierra_leone'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇲',
    description: 'flag: San Marino',
    category: 'Flags',
    aliases: ['san_marino'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇳',
    description: 'flag: Senegal',
    category: 'Flags',
    aliases: ['senegal'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇴',
    description: 'flag: Somalia',
    category: 'Flags',
    aliases: ['somalia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇷',
    description: 'flag: Suriname',
    category: 'Flags',
    aliases: ['suriname'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇸',
    description: 'flag: South Sudan',
    category: 'Flags',
    aliases: ['south_sudan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇹',
    description: 'flag: São Tomé & Príncipe',
    category: 'Flags',
    aliases: ['sao_tome_principe'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇻',
    description: 'flag: El Salvador',
    category: 'Flags',
    aliases: ['el_salvador'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇽',
    description: 'flag: Sint Maarten',
    category: 'Flags',
    aliases: ['sint_maarten'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇾',
    description: 'flag: Syria',
    category: 'Flags',
    aliases: ['syria'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇸🇿',
    description: 'flag: Eswatini',
    category: 'Flags',
    aliases: ['swaziland'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇦',
    description: 'flag: Tristan da Cunha',
    category: 'Flags',
    aliases: ['tristan_da_cunha'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇹🇨',
    description: 'flag: Turks & Caicos Islands',
    category: 'Flags',
    aliases: ['turks_caicos_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇩',
    description: 'flag: Chad',
    category: 'Flags',
    aliases: ['chad'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇹🇫',
    description: 'flag: French Southern Territories',
    category: 'Flags',
    aliases: ['french_southern_territories'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇬',
    description: 'flag: Togo',
    category: 'Flags',
    aliases: ['togo'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇭',
    description: 'flag: Thailand',
    category: 'Flags',
    aliases: ['thailand'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇯',
    description: 'flag: Tajikistan',
    category: 'Flags',
    aliases: ['tajikistan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇰',
    description: 'flag: Tokelau',
    category: 'Flags',
    aliases: ['tokelau'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇹🇱',
    description: 'flag: Timor-Leste',
    category: 'Flags',
    aliases: ['timor_leste'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇲',
    description: 'flag: Turkmenistan',
    category: 'Flags',
    aliases: ['turkmenistan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇳',
    description: 'flag: Tunisia',
    category: 'Flags',
    aliases: ['tunisia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇴',
    description: 'flag: Tonga',
    category: 'Flags',
    aliases: ['tonga'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇷',
    description: 'flag: Turkey',
    category: 'Flags',
    aliases: ['tr'],
    keys: ['turkey'],
    unicode_version: '8.0',
    ios_version: '9.1',
  },
  {
    value: '🇹🇹',
    description: 'flag: Trinidad & Tobago',
    category: 'Flags',
    aliases: ['trinidad_tobago'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇻',
    description: 'flag: Tuvalu',
    category: 'Flags',
    aliases: ['tuvalu'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇹🇼',
    description: 'flag: Taiwan',
    category: 'Flags',
    aliases: ['taiwan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇹🇿',
    description: 'flag: Tanzania',
    category: 'Flags',
    aliases: ['tanzania'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇺🇦',
    description: 'flag: Ukraine',
    category: 'Flags',
    aliases: ['ukraine'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇺🇬',
    description: 'flag: Uganda',
    category: 'Flags',
    aliases: ['uganda'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇺🇲',
    description: 'flag: U.S. Outlying Islands',
    category: 'Flags',
    aliases: ['us_outlying_islands'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇺🇳',
    description: 'flag: United Nations',
    category: 'Flags',
    aliases: ['united_nations'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🇺🇸',
    description: 'flag: United States',
    category: 'Flags',
    aliases: ['us'],
    keys: ['flag', 'united', 'america'],
    unicode_version: '6.0',
    ios_version: '6.0',
  },
  {
    value: '🇺🇾',
    description: 'flag: Uruguay',
    category: 'Flags',
    aliases: ['uruguay'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇺🇿',
    description: 'flag: Uzbekistan',
    category: 'Flags',
    aliases: ['uzbekistan'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇻🇦',
    description: 'flag: Vatican City',
    category: 'Flags',
    aliases: ['vatican_city'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇻🇨',
    description: 'flag: St. Vincent & Grenadines',
    category: 'Flags',
    aliases: ['st_vincent_grenadines'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇻🇪',
    description: 'flag: Venezuela',
    category: 'Flags',
    aliases: ['venezuela'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇻🇬',
    description: 'flag: British Virgin Islands',
    category: 'Flags',
    aliases: ['british_virgin_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇻🇮',
    description: 'flag: U.S. Virgin Islands',
    category: 'Flags',
    aliases: ['us_virgin_islands'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇻🇳',
    description: 'flag: Vietnam',
    category: 'Flags',
    aliases: ['vietnam'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇻🇺',
    description: 'flag: Vanuatu',
    category: 'Flags',
    aliases: ['vanuatu'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇼🇫',
    description: 'flag: Wallis & Futuna',
    category: 'Flags',
    aliases: ['wallis_futuna'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇼🇸',
    description: 'flag: Samoa',
    category: 'Flags',
    aliases: ['samoa'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇽🇰',
    description: 'flag: Kosovo',
    category: 'Flags',
    aliases: ['kosovo'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇾🇪',
    description: 'flag: Yemen',
    category: 'Flags',
    aliases: ['yemen'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇾🇹',
    description: 'flag: Mayotte',
    category: 'Flags',
    aliases: ['mayotte'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '9.0',
  },
  {
    value: '🇿🇦',
    description: 'flag: South Africa',
    category: 'Flags',
    aliases: ['south_africa'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇿🇲',
    description: 'flag: Zambia',
    category: 'Flags',
    aliases: ['zambia'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🇿🇼',
    description: 'flag: Zimbabwe',
    category: 'Flags',
    aliases: ['zimbabwe'],
    keys: [],
    unicode_version: '6.0',
    ios_version: '8.3',
  },
  {
    value: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    description: 'flag: England',
    category: 'Flags',
    aliases: ['england'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    description: 'flag: Scotland',
    category: 'Flags',
    aliases: ['scotland'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
  {
    value: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    description: 'flag: Wales',
    category: 'Flags',
    aliases: ['wales'],
    keys: [],
    unicode_version: '11.0',
    ios_version: '12.1',
  },
]

export const emojis = rawEmojis.map((emoji) => ({
  ...emoji,
  content: emoji.value,
}))
