import * as Sentry from '@sentry/browser'
import { AnyAction, Dispatch } from 'redux'
import { applyNamespace, createAction } from 'redux-ts-helpers'
import { NamespacedMap } from 'redux-ts-helpers/lib/applyNamespace'
import { FluxAction } from '../../util/commonRedux'
import handleError from '../../util/handleError'
import { BaseModel, PaginatedResults } from '../models'
import {
  CreateResourceActions,
  CreateResourceOptions,
  CreateResourceReducer,
  CreateResourceState,
  ReduxResource,
} from './types'

interface ReduxPayloadFunction<TStore, TReturn = any> {
  (dispatch: Dispatch, getState: () => TStore): TReturn
}

/**
 * Creates a set of redux actions and a reducer for a given REST Resource.
 */
export default function createReduxResource<TModel extends BaseModel>(
  options: CreateResourceOptions<TModel>
): ReduxResource<TModel> {
  // We need to keep track of the resource's reducer state. Because one of these
  // reducers can be placed anywhere in Redux, it's either keep track of it in
  // here or provide some sort of `getResourceReducerFromUsersStore` function.
  let _state: CreateResourceState<TModel>
  const { constants, actions } = createResourceActions(options, () => _state)
  const reducer = createResourceReducer<TModel>(
    constants,
    (state) => (_state = state),
    options.reducer || ((state) => state)
  )

  return {
    actions,
    constants,
    reducer,
  }
}

function createResourceActions<TModel extends BaseModel>(
  options: CreateResourceOptions<TModel>,
  getResourceState: () => CreateResourceState<TModel>
) {
  const constants = applyNamespace(options.namespace, {
    create: 0,
    list: 0,
    update: 0,
    get: 0,
    destroy: 0,
    setVisible: 0,
    recordResults: 0,
    recordPaginatedResults: 0,
    updateFilter: 0,
    setPage: 0,
    setPageAndRefresh: 0,
  })

  function refresh(): FluxAction<
    ReduxPayloadFunction<any, Promise<PaginatedResults<TModel>>>
  > {
    return {
      type: constants.list,
      async payload(dispatch) {
        const resourceState = getResourceState()
        const filter = { ...resourceState.filter }
        filter.page = resourceState.currentPage
        if (filter.perPage === undefined) {
          filter.perPage = resourceState.perPage
        }

        try {
          const results = await options.resource.list(filter)

          dispatch(actions.recordResults(results.results))
          dispatch(actions.recordPaginatedResults(results))
          dispatch(actions.setVisible(results.results.map((x) => x.id!)))

          return results
        } catch (e) {
          Sentry.withScope((scope) => {
            scope.setTag('category', 'redux-resource')
            scope.setTag('file', 'createReduxResource.ts')
            scope.setTag('resource', options.namespace)
            scope.setLevel(Sentry.Severity.Error)
            Sentry.addBreadcrumb({
              category: 'redux-resource',
              message: `Failed getting list for namespace: ${options.namespace}`,
            })
            handleError(e)
          })

          throw e
        }
      },
    }
  }

  const actions: CreateResourceActions<TModel> = {
    setVisible: createAction<number[]>(constants.setVisible),
    recordResults: createAction<TModel[]>(constants.recordResults),
    recordPaginatedResults: createAction<PaginatedResults<any>>(
      constants.recordPaginatedResults
    ),
    updateFilter: createAction<Record<string, any>>(constants.updateFilter),
    setPage: createAction<number>(constants.setPage),
    // refreshDebounced: debounce(300, refresh),
    list: refresh,

    setPageAndRefresh: (page: number) => ({
      type: constants.setPageAndRefresh,
      payload(dispatch: Dispatch<any>) {
        dispatch(actions.setPage(page))

        return dispatch(actions.list())
      },
    }),

    create: (data) => ({
      type: constants.create,
      payload: options.resource.create(data),
    }),

    update: (data) => ({
      type: constants.update,
      payload: options.resource.update(data),
    }),

    get: (id, force = false) => ({
      type: constants.get,
      async payload() {
        const state = getResourceState()
        if (!force && state.all[id]) {
          return
        }

        return options.resource.get(id)
      },
    }),

    destroy: (id) => ({
      type: constants.destroy,
      payload: options.resource.destroy(id),
      meta: id,
    }),
  }

  return { actions, constants }
}

export function buildInitialState<TModel>(): CreateResourceState<TModel> {
  return {
    filter: {},
    all: {},
    visible: [],
    count: 0,
    numPages: 1,
    perPage: 20,
    currentPage: 1,
  }
}

function createResourceReducer<TModel extends BaseModel>(
  constants: NamespacedMap<CreateResourceActions<TModel>>,
  onUpdate: (state: CreateResourceState<TModel>) => void,
  reducer: CreateResourceReducer<TModel>
) {
  function propagateUpdate(state: CreateResourceState<TModel>) {
    onUpdate(state)

    return state
  }

  const initialState = buildInitialState<TModel>()

  onUpdate(initialState)

  return function resourceReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
      case constants.setVisible: {
        const actionTyped = action as FluxAction<number[]>

        return propagateUpdate({
          ...state,
          visible: actionTyped.payload,
        })
      }

      case constants.setPage: {
        const actionTyped = action as FluxAction<number>

        return propagateUpdate({
          ...state,
          currentPage: actionTyped.payload,
        })
      }

      case constants.recordResults: {
        const actionTyped = action as FluxAction<TModel[]>
        const results = actionTyped.payload

        const newAll = results.reduce(
          (memo, x) => {
            memo[x.id!] = x

            return memo
          },
          { ...state.all }
        )

        return propagateUpdate({
          ...state,
          all: newAll,
        })
      }

      case constants.recordPaginatedResults: {
        const actionTyped = action as FluxAction<PaginatedResults<any>>
        const results = actionTyped.payload

        return propagateUpdate({
          ...state,
          count: results.count,
          perPage: results.perPage,
          currentPage: results.currentPage,
          numPages: results.numPages,
        })
      }

      case constants.updateFilter: {
        const actionTyped = action as FluxAction<Record<string, any>>

        return propagateUpdate({
          ...state,
          filter: actionTyped.payload,
        })
      }

      case `${constants.create}/success`:
      case `${constants.update}/success`:
      case `${constants.get}/success`: {
        const actionTyped = action as FluxAction<TModel>

        // payload can be undefined, in the case of `.get` when data already exists.
        if (action.payload === undefined) {
          return state
        }

        return propagateUpdate({
          ...state,
          all: {
            ...state.all,
            [actionTyped.payload.id!]: actionTyped.payload,
          },
        })
      }

      case `${constants.destroy}/success`: {
        const actionTyped = action as FluxAction<undefined, number>
        const newAll = { ...state.all }
        delete newAll[actionTyped.meta!]

        return propagateUpdate({
          ...state,
          all: newAll,
          visible: state.visible.filter((x) => x !== actionTyped.meta),
        })
      }
    }

    return propagateUpdate(reducer(state, action))
  }
}
