import { default as CardContent } from '@mui/material/CardContent'
import { default as Icon } from '@mui/material/Icon'
import { default as Paper } from '@mui/material/Paper'
import { default as Typography } from '@mui/material/Typography'
import * as React from 'react'

interface Props {
  children: React.ReactNode
}

// tslint:disable-next-line:variable-name
const HelperText = ({ children }: Props) => (
  <Paper
    elevation={0}
    style={{
      maxWidth: 600,
      margin: '0 auto',
    }}
  >
    <CardContent>
      <Typography style={{ float: 'left', marginRight: '2em' }}>
        <Icon fontSize="large">info</Icon>
      </Typography>
      <Typography>{children}</Typography>
    </CardContent>
  </Paper>
)

export default HelperText
