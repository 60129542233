import * as React from 'react'
import { default as FormControl } from '@mui/material/FormControl'
import { default as InputLabel } from '@mui/material/InputLabel'
import { default as MenuItem } from '@mui/material/MenuItem'
import { default as Select, SelectChangeEvent } from '@mui/material/Select'
import { NameAndValue } from '../../../admin/mapdata/types'
import {
  MapColorProfile,
  MapColorProfileVisualization,
} from '../../../graphql/types'
import { FormColorStopPicker } from '../../../UI/FormColorStopPicker'
import { FormColorStops } from '../../../UI/FormColorStops'
import TranslationTextField from '../../../UI/TranslationTextField/TranslationTextField'
import LabelInputs from './LabelInputs'
import ColorProfileVisualizationForm from './visualizations/ColorProfileVisualizationForm'

export type MapColorProfileData = Removes<
  MapColorProfile,
  string,
  'name' | 'description'
> & {
  dataStops: [any, string, string][]
}

interface Props {
  instance: Partial<MapColorProfileData>
  onChange: (v: NameAndValue) => void
  visualizations: (Partial<MapColorProfileVisualization> | undefined)[]
}

const types = ['Class', 'Value']
const displays = ['Class', 'Step', 'Continuous']

class ColorProfileFrom extends React.PureComponent<Props> {
  render() {
    const { instance, onChange, visualizations } = this.props

    if (!instance) {
      return null
    }

    return (
      <React.Fragment>
        <div className="grid-xs-12">
          <TranslationTextField
            required
            name="name"
            label={`Name`}
            value={instance.name ?? {}}
            onChange={onChange}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            name="description"
            label={`Description`}
            value={instance.description ?? {}}
            onChange={onChange}
          />
        </div>
        <ColorProfileVisualizationForm
          instances={visualizations}
          onChange={onChange}
        />
        <div className="grid-xs-12">
          <FormControl fullWidth>
            <InputLabel required shrink={!!instance.type}>
              Type{' '}
            </InputLabel>
            <Select
              name="type"
              fullWidth
              onChange={this.handleSelectChange}
              value={instance.type ?? ''}
            >
              {types.map((type) => (
                <MenuItem key={type} value={type.toLowerCase()}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="grid-xs-12">
          <FormControl fullWidth>
            <InputLabel required shrink={!!instance.display}>
              display!
            </InputLabel>
            <Select
              name="display"
              fullWidth
              onChange={this.handleChange}
              value={instance.display ?? []}
            >
              {displays.map((display) => (
                <MenuItem
                  key={display}
                  value={display.toLowerCase()}
                  disabled={
                    instance.type === 'class' &&
                    display.toLowerCase() !== 'class'
                  }
                >
                  {display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="grid-xs-12">
          <LabelInputs
            name="minLabel"
            label="Min Label"
            value={instance.minLabel ?? {}}
            onChange={onChange}
          />
        </div>
        <div className="grid-xs-12">
          <LabelInputs
            name="maxLabel"
            label="Max Label"
            value={instance.maxLabel ?? {}}
            onChange={onChange}
          />
        </div>
        <div className="grid-xs-12">
          <LabelInputs
            name="noDataLabel"
            label="nodata label"
            value={instance.noDataLabel ?? {}}
            onChange={onChange}
          />
        </div>
        <div className="grid-xs-12">
          <LabelInputs
            name="coverageLabel"
            label="Coverage label"
            value={instance.coverageLabel ?? {}}
            onChange={onChange}
          />
        </div>
        {instance.type && (
          <React.Fragment>
            <div className="grid-xs-12">
              <FormColorStops
                required
                name="dataStops"
                label={`data stops`}
                type={instance.type ?? 'value'}
                value={instance.dataStops}
                onChange={onChange}
              />
            </div>
            <div className="grid-xs-12">
              <FormColorStopPicker
                name="noDataColor"
                label={`noData Color`}
                value={instance.noDataColor}
                onChange={onChange}
              />
            </div>
            <div className="grid-xs-12">
              <FormColorStopPicker
                name="coverageColor"
                label={`Coverage Color`}
                value={instance.coverageColor}
                onChange={onChange}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  handleSelectChange = (e: SelectChangeEvent) => {
    this.props.onChange(e.target)
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChange(e.target)
}

export default ColorProfileFrom
