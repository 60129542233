import * as React from 'react'
import { default as Checkbox } from '@mui/material/Checkbox'
import { default as FormControlLabel } from '@mui/material/FormControlLabel'
import { default as TextField } from '@mui/material/TextField'
import { default as Typography } from '@mui/material/Typography'
import { NameAndValue } from '../../../../admin/mapdata/types'
import {
  MapColorProfileVisualization,
  MapLayerSettings,
} from '../../../../graphql/types'
import { VisualizationMode } from '../../../../ProductSettings/store/types'

interface Props {
  instances: (Partial<MapColorProfileVisualization> | undefined)[]
  onChange: (v: NameAndValue) => void
}

const visualizations: NonNullable<MapLayerSettings['visualization']>[] = [
  'absolute',
  'relative',
  'threshold',
]

class ColorProfileVisualizationForm extends React.PureComponent<Props> {
  render() {
    const { instances } = this.props
    const modes = instances.map((inst) => inst?.visualization)

    return (
      <React.Fragment>
        <Typography variant="subtitle1">Visualizations</Typography>
        {visualizations.map((mode) => {
          const checked = modes.includes(mode)

          return (
            <div key={mode} className="grid-xs-12">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={this.handleModeChange}
                    name={mode}
                  />
                }
                label={mode}
              />
              {checked &&
                this.renderVisualizationInputs(
                  instances.find((inst) => !!(inst?.visualization === mode))
                )}
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  renderVisualizationInputs(instance: this['props']['instances'][0]) {
    if (!instance) {
      return null
    }

    return (
      <div className="grid" style={{ gridGap: '1em', marginLeft: '4em' }}>
        <div className="grid-xs-12">
          <TextField
            required
            name="order"
            type="number"
            label={`Order`}
            InputLabelProps={{
              shrink: instance.order !== undefined,
            }}
            value={instance.order}
            autoComplete="off"
            onChange={(e) =>
              this.handleChange(instance, e.target.name, e.target.value)
            }
          />
        </div>
        <div className="grid-xs-12">
          <FormControlLabel
            control={
              <Checkbox
                checked={!!instance.default}
                onChange={(e) =>
                  this.handleChange(instance, e.target.name, e.target.checked)
                }
                name="default"
              />
            }
            label="Default"
          />
        </div>
      </div>
    )
  }

  handleChange = (
    instance: this['props']['instances'][0],
    name: string,
    value: any
  ) => {
    if (!instance) {
      return
    }

    const instances = [...this.props.instances]
    const index = instances.findIndex(
      (inst) => !!(inst?.visualization === instance.visualization)
    )

    const newInstance = instances[index]

    if (newInstance) {
      newInstance[name] = value
    }

    this.updateInstances(instances)
  }

  handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const instances = [...this.props.instances]
    const { name: visualization, checked } = e.target

    if (checked) {
      // add visualization
      instances.push({
        visualization: visualization as VisualizationMode,
      })
    } else {
      // remove visualization
      const index = instances.findIndex(
        (inst) => !!(inst?.visualization === visualization)
      )

      instances.splice(index, 1)
    }

    this.updateInstances(instances)
  }

  updateInstances = (instances: this['props']['instances']) => {
    this.props.onChange({
      name: 'MapColorProfileVisualizations',
      value: instances,
    })
  }
}

export default ColorProfileVisualizationForm
