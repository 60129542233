import { Alert, Slide, Snackbar } from "@mui/material"
import * as React from "react";
import { NotificationProps } from "./NotificationProps";
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage';
import { useRedux } from "../../hooks/useRedux";

export const DefaultNotification = ({ currentNotification, dismissNotification, open, anchorOrigin }: NotificationProps) => {
    const [state] = useRedux()
    const preferredLanguage = selectPreferredLanguage(state)
    return (
        <>
            <Snackbar
              open={open}
              onClose={(_e, r) => { 
                  if(r === 'timeout'){
                      dismissNotification(0)
                  }
                }
              }
              autoHideDuration={currentNotification.autoDismiss === undefined || currentNotification?.autoDismiss ? 2000 : null}
              TransitionComponent={Slide}
              anchorOrigin={anchorOrigin}
            >
              <Alert 
                onClose={() => dismissNotification(0)} 
                variant="standard" 
                severity={currentNotification.severity} 
                sx={{ width: '100%', maxWidth: '350px' }}>
                {typeof currentNotification.message === 'string' 
                    ? currentNotification.message 
                    : currentNotification.message[preferredLanguage]}
              </Alert>
            </Snackbar> 
          </> 
    )
}