import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Grid, Stack } from '@mui/material'

import warnConfirm from '../../../../admin/warnConfirm'
import { AsyncResult } from '../../../../hooks/useAsync'
import { useRedux } from '../../../../hooks/useRedux'

import { CommentField } from '../../../../UI/CommentField/CommentField'

import { createDeliveryProcGroupComment } from '../createDeliveryProcGroupComment'
import { deleteComment } from '../deleteComment'
import { DeliveryProcGroupComments as DeliveryProcGroupCommentsFetch } from '../fetchDeliveryProcGroupComments'
import {
  refreshGetDeliveryProcGroup,
  selectDeliveryProcGroup,
} from './deliveryProcGroupSelectors'
import { setDeliveryProcGroupQACompleteStatus } from './setDeliveryProcGroupQACompleteStatus'
import DeliveryProcGroupComment from './DeliveryProcGroupComment'
import { selectMe } from '../../../../data/selectMe'

interface Props {
  deliveryProcGroupCommentsFetch: AsyncResult<DeliveryProcGroupCommentsFetch>
  setIsLoading: (isLoading: boolean) => void
}

export const DeliveryProcGroupComments = ({
  deliveryProcGroupCommentsFetch: [
    deliveryProcGroupCommentFetcher,
    refreshComments,
  ],
  setIsLoading,
}: Props) => {
  const [state] = useRedux()
  const me = selectMe(state)

  const deliveryProcGroup = selectDeliveryProcGroup(state)

  const selectedCommentId = state.router.searchParams?.selectedCommentId

  React.useEffect(() => {
    if (selectedCommentId) {
      const section = document.querySelector(`#comment-${selectedCommentId}`)
      section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [selectedCommentId])

  const { deliveryId, procGroup } = useParams<{
    deliveryId: string
    procGroup: string
  }>()

  const [replyToCommentId, setReplyToCommentId] = React.useState<
    undefined | string
  >(undefined)

  const handleSubmitComment = async (
    comment: string,
    mentionEmails?: string[],
    actionSet: Set<string> = new Set()
  ) => {
    if (!comment) {
      return
    }
    setIsLoading(true)
    await createDeliveryProcGroupComment(
      {
        deliveryId,
        procGroup,
        content: comment,
        replyToCommentId,
      },
      mentionEmails
    )

    if (actionSet.has('approve')) {
      await setDeliveryProcGroupQACompleteStatus(
        { deliveryId, procGroup },
        'complete'
      )
      refreshGetDeliveryProcGroup()
    } else if (actionSet.has('fail')) {
      await setDeliveryProcGroupQACompleteStatus(
        { deliveryId, procGroup },
        'failed'
      )
      refreshGetDeliveryProcGroup()
    }
    setReplyToCommentId(undefined)
    refreshComments()
    setIsLoading(false)
  }

  const handleReplyToComment = (commentId: string) => {
    setReplyToCommentId(commentId)
  }

  const handleCancelReply = () => {
    setReplyToCommentId(undefined)
  }

  const handleDeleteComment = async (commentId: string) => {
    if (
      await warnConfirm({
        title: 'Delete Comment',
        message: 'Are you sure you would like to delete this comment?',
      })
    ) {
      setIsLoading(true)
      await deleteComment(commentId)
      setIsLoading(false)
      refreshComments()
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ height: '100%', paddingY: 1, overflowY: 'scroll' }}
      spacing={1}
    >
      <Grid item xs={12} sm={10}>
        <Stack spacing={1} sx={{ height: '100%' }}>
          {deliveryProcGroupCommentFetcher.result?.map((comment) => (
            <DeliveryProcGroupComment
              key={comment.id}
              selectedCommentId={selectedCommentId}
              replyToCommentId={replyToCommentId}
              deliveryProcGroup={deliveryProcGroup}
              comment={comment}
              handleCancelReply={handleCancelReply}
              handleDeleteComment={handleDeleteComment}
              handleReplyToComment={handleReplyToComment}
              handleSubmitComment={handleSubmitComment}
            />
          ))}
          {!replyToCommentId ? (
            <CommentField
              actions={
                deliveryProcGroup?.UploadJob?.Status?.status === 'complete' &&
                me?.roles.includes('admin')
                  ? ['approve', 'fail']
                  : []
              }
              placeholder="Type a comment..."
              onSubmit={handleSubmitComment}
              maxLength={500}
            />
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  )
}
