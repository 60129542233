import * as React from 'react'
import {
  Grid,
  Icon,
  InputAdornment,
  List,
  ListItemButton,
  TextField,
} from '@mui/material'
import { OrganizationData } from '../../../../data/selectOrganizationList'
import fuzzySearch from '../../../../util/fuzzySearch'

interface ChooseOrganizationProps {
  organizations: OrganizationData[]
  selectedOrganization?: string
  onSelectOrganization: (id: string) => void
}
export const ChooseOrganization = ({
  organizations,
  selectedOrganization,
  onSelectOrganization,
}: ChooseOrganizationProps) => {
  const [filter, setFilter] = React.useState('')
  const filterAdornmentIcon = filter ? 'cancel' : 'search'

  const filteredOrganizations = (
    filter
      ? organizations.filter(({ name }) => fuzzySearch(filter, name))
      : organizations
  ).sort((orgA, orgB) => orgA.name.localeCompare(orgB.name))

  return (
    <Grid container sx={{ my: 2, width: '100%' }}>
      {(organizations.length > 5 || filter !== '') && (
        <TextField
          fullWidth
          placeholder="Filter!"
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon onClick={() => setFilter('')}>{filterAdornmentIcon}</Icon>
              </InputAdornment>
            ),
          }}
        />
      )}
      <List sx={{ width: '100%' }}>
        {filteredOrganizations.map((organization) => (
          <ListItemButton
            key={organization.id}
            onClick={() => onSelectOrganization(organization.id)}
            selected={organization.id === selectedOrganization}
          >
            {organization.name}
          </ListItemButton>
        ))}
      </List>
    </Grid>
  )
}
