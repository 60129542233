import { ActionSuccessType } from 'redux-async-payload'
import createReducer from 'redux-ts-helpers/lib/createReducer'
import { v4 as uuid } from 'uuid'

import * as actions from './actions'
import { resetPostGISData } from './resetPostGISData'
import { PostGISState } from './types'

export const initialState: PostGISState = {
  fitSelectedParcels: false,

  parcelFilterInfo: {
    advancedFilterOpen: false,
    searchTerm: '',
    metaFilters: {
      varietals: [],
      rootstock: [],
      trellises: [],
    },
  },

  focusedPoint: null,
  focusedPolygon: null,

  isLayerDrawerOpen: false,
  userPosition: null,
  userPositionError: null,

  bounds: [
    [-85, -85],
    [85, 85],
  ],
  pitch: 0,
  bearing: 0,
  isPollingUserPosition: false,

  productIdBySourceId: {}, // ! todo remove

  focusedSoilLayer: null,
  isMeasureToolActive: false,
  isStatsZonesToolActive: false,
  isGPSOffsetMarkerVisible: false,
  mapDataHash: '',
}

export const reducer = createReducer<PostGISState>(initialState, {
  'postgis/resetPostGISData': (
    state,
    action: ReturnType<typeof resetPostGISData>
  ) => {
    return {
      ...initialState,
    }
  },

  [actions.constants.replaceParcelFilterInfo]: (
    state,
    action: ReturnType<typeof actions.replaceParcelFilterInfo>
  ) => ({
    ...state,
    parcelFilterInfo: action.payload,
  }),
  [actions.constants.setFitSelectedParcels]: (
    state,
    action: ReturnType<typeof actions.setFitSelectedParcels>
  ) => ({
    ...state,
    fitSelectedParcels: action.payload,
  }),

  [actions.constants.setFocusedPoint]: (
    state,
    action: ReturnType<typeof actions.setFocusedPoint>
  ) => ({
    ...state,
    focusedPoint: action.payload,
  }),

  [actions.constants.setFocusedPolygon]: (
    state,
    action: ReturnType<typeof actions.setFocusedPolygon>
  ) => ({
    ...state,
    focusedPolygon: action.payload,
  }),

  [actions.constants.toggleLayerDrawer]: (
    state,
    action: ReturnType<typeof actions.toggleLayerDrawer>
  ) => ({
    ...state,
    isLayerDrawerOpen: !state.isLayerDrawerOpen,
  }),

  [actions.constants.setUserPosition]: (
    state,
    action: ReturnType<typeof actions.setUserPosition>
  ) => ({
    ...state,
    userPosition: action.payload,
  }),

  [actions.constants.setUserPositionError]: (
    state,
    action: ReturnType<typeof actions.setUserPositionError>
  ) => ({
    ...state,
    userPositionError: action.payload,
  }),

  [`${actions.constants.setIsPollingUserPosition}/success`]: (
    state,
    action: ActionSuccessType<typeof actions.setIsPollingUserPosition>
  ) => {
    const payload = action.payload

    return {
      ...state,
      isPollingUserPosition: payload,
    }
  },

  [actions.constants.setPosition]: (
    state,
    action: ReturnType<typeof actions.setPosition>
  ) => ({
    ...state,
    bounds: action.payload,
  }),

  [actions.constants.setPitch]: (
    state,
    action: ReturnType<typeof actions.setPitch>
  ) => ({
    ...state,
    pitch: action.payload,
  }),

  [actions.constants.setBearing]: (
    state,
    action: ReturnType<typeof actions.setBearing>
  ) => ({
    ...state,
    bearing: action.payload,
  }),

  [actions.constants.setFocusedBlockId]: (
    state,
    action: ReturnType<typeof actions.setFocusedBlockId>
  ) => ({
    ...state,
    focusedBlockId: action.payload,
  }),

  [`${actions.constants.fetchProducts}/success`]: (state, action) => ({
    ...state,
    ...action.payload,
  }),

  [actions.constants.setFocusedSoilLayer]: (
    state,
    action: ReturnType<typeof actions.setFocusedSoilLayer>
  ) => ({
    ...state,
    focusedSoilLayer: action.payload,
  }),
  [actions.constants.toggleMeasureToolActive]: (state) => {
    if (state.isMeasureToolActive) {
      return {
        ...state,
        isMeasureToolActive: false,
        isStatsZonesToolActive: false,
      }
    }

    return { ...state, isMeasureToolActive: true }
  },
  [actions.constants.toggleStatsZonesToolActive]: (state) => ({
    ...state,
    isStatsZonesToolActive: !state.isStatsZonesToolActive,
  }),
  [actions.constants.setStatsZonesToolActive]: (
    state,
    action: ReturnType<typeof actions.setStatsZonesToolActive>
  ) => ({
    ...state,
    isStatsZonesToolActive: action.payload,
    statsCustomZones: undefined,
  }),
  [actions.constants.setStatsCustomZones]: (
    state,
    action: ReturnType<typeof actions.setStatsCustomZones>
  ) => ({
    ...state,
    statsCustomZones: action.payload,
  }),
  [actions.constants.toggleGPSOffsetMarkerVisible]: (state) => ({
    ...state,
    isGPSOffsetMarkerVisible: !state.isGPSOffsetMarkerVisible,
  }),
  [actions.constants.mapDataUpdated]: (
    state,
    action: ReturnType<typeof actions.mapDataUpdated>
  ) => ({
    ...state,
    mapDataHash: uuid(),
    map: action.payload,
  }),
})
