import { useState, useEffect } from 'react'

export const useDeferredValue = <T>(value: T, delay = 500): T => {
  const [deferredValue, setDeferredValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDeferredValue(value)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, delay])

  return deferredValue
}
