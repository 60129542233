import * as React from 'react'
import { ButtonBase, Checkbox, Stack, Tooltip, Typography, styled } from '@mui/material'
import i18n, {keys} from '../../i18n';

interface Props {
  selected: boolean
  onSelect: () => void
  label?: string
}

const ProjectTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-align: left;
`

const StyledStack = styled(ButtonBase)`
  overflow: hidden;
  border-bottom: 1px solid #424242;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 16px;
  cursor: pointer;
  justify-content: flex-start;
`

export const SelectAllButton = ({selected, onSelect, label}: Props) => {
  return (
    <StyledStack
      onClick={(e) => {
        e.stopPropagation()
        onSelect()
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={selected}
          />
          <Stack direction="row" spacing={0.5}>
            <Tooltip title={'Select All Notes'}>
              <ProjectTitle noWrap>
                {label || i18n.t(keys.selectAllNotesLabel)}
              </ProjectTitle>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </StyledStack>
  )
}
