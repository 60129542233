import * as React from 'react'
import { default as swal } from 'sweetalert'

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  List,
  Tooltip,
  Typography,
} from '@mui/material'

import HelperText from '../admin/mapdata/utils/HelperText'
import { Row } from '../admin/UI/Row'
import warnConfirm from '../admin/warnConfirm'
import { selectMe } from '../data/selectMe'
import { selectOrganizationId } from '../data/selectOrganizationId'
import { GroupFileData } from '../data/types'
import { getSelectedFlightDate } from '../data/userSelectionRedux'
import * as api from '../graphql/api'
import { DeliveryGroupFile } from '../graphql/types'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { showFeedback } from '../redux/notifications/redux'
import { AppDispatchProps, RootStore } from '../redux/types'
import LoadingOverlay from '../UI/LoadingOverlay'
import { copyToClipboard } from '../util/copyToClipboard'
import { resetDownloadsDialog } from './redux'
import { selectAvailableGroupDownloads } from './selectAvailableGroupDownloads'
import { selectGroupFilesByFilename } from './selectDeliveryGroupFiles'
import {
  refreshGroupAndAvailableFiles,
  selectGroupAndAvailableFiles,
} from './selectGroupAndAvailableFiles'

interface State {
  selectedFiles: Set<string>
  isLoading: boolean
}

class GroupDownloadsDialog extends React.Component<
  ReduxProps & AppDispatchProps,
  State
> {
  state: State = { selectedFiles: new Set<string>(), isLoading: false }

  interval: NodeJS.Timer
  componentDidMount() {
    this.interval = setInterval(() => {
      refreshGroupAndAvailableFiles()
    }, 2000)
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  render() {
    const {
      selectedGroup,
      open,
      selectedDate,
      avalableDownloads,
      groupFilesByFilename,
      me,
    } = this.props
    const { selectedFiles, isLoading } = this.state

    if (!selectedGroup || !selectedDate) {
      return null
    }

    const groupDownloadCount = Object.keys(groupFilesByFilename).length

    return (
      <Dialog open={open} disableEnforceFocus>
        <DialogTitle>{i18n.t(keys.generic.download)}</DialogTitle>
        <DialogContent>
          <HelperText>
            <Typography variant="body2" paragraph>
              <span>{i18n.t(keys.groupDownloadByRequest)}</span>
              <span>{i18n.t(keys.groupDownloadToRequest)}</span>
              <Icon style={{ transform: 'translate(-4px, 7px)' }}>add</Icon>
            </Typography>
            <Typography variant="body2">
              {i18n.t(keys.groupDownloadMayTakeAMinute)}
            </Typography>
            <Typography variant="body2">
              {i18n.t(keys.groupDownloadOnceGenerated)}
              <Icon style={{ transform: 'translate(0px, 7px)' }}>
                cloud_download
              </Icon>
              {i18n.t(keys.groupDownloadOnceGeneratedPost)}
            </Typography>
          </HelperText>
          <Row
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              paragraph
              variant="subtitle1"
              style={{ textAlign: 'left' }}
            >
              {`${selectedGroup.name} - ${i18n.toDateLong(selectedDate)}`}
            </Typography>
          </Row>

          {isLoading && <LoadingOverlay />}
          <Row
            style={{
              justifyContent: 'space-between',
            }}
          >
            <Row style={{ opacity: groupDownloadCount === 0 ? 0.5 : 1 }}>
              <Checkbox
                indeterminate={
                  0 < selectedFiles.size &&
                  selectedFiles.size < groupDownloadCount
                }
                disabled={groupDownloadCount === 0}
                checked={
                  groupDownloadCount > 0 &&
                  selectedFiles.size === groupDownloadCount
                }
                onChange={() => this.handleGroupDownloadSelectAll()}
              />
              <div
                style={{ cursor: 'pointer', userSelect: 'none' }}
                onClick={() => this.handleGroupDownloadSelectAll()}
              >
                {i18n.t(keys.generic.selectAll)}
              </div>
            </Row>
            <Row>
              {(me?.roles.length ?? 0) > 0 && groupDownloadCount > 0 && (
                <Tooltip
                  title={i18n.t(keys.reprocessDownloadAll)}
                  placement="top"
                >
                  <IconButton onClick={() => this.reprocessAll()} size="large">
                    <Icon>sync</Icon>
                  </IconButton>
                </Tooltip>
              )}
              {groupDownloadCount < avalableDownloads.length && (
                <Tooltip
                  title={i18n.t(keys.requestDownloadAll)}
                  placement="top"
                >
                  <IconButton
                    onClick={() => this.requestDownloadAll()}
                    size="large"
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </Tooltip>
              )}
            </Row>
          </Row>
          <List sx={{ width: '100%' }}>
            {avalableDownloads.map((filename) => {
              const selected = selectedFiles.has(filename)
              const dgf = groupFilesByFilename[filename]
              const isDownloadable = dgf?.status === 'complete'

              return (
                <>
                  <Row
                    key={filename}
                    style={{
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Row
                      style={{
                        width: '80%',
                        opacity: isDownloadable ? 1 : 0.5,
                      }}
                    >
                      <Checkbox
                        disabled={!isDownloadable}
                        checked={selected}
                        onChange={
                          isDownloadable
                            ? () => this.handleGroupDownloadSelected(filename)
                            : undefined
                        }
                      />
                      <Typography
                        noWrap
                        style={{
                          cursor: isDownloadable ? 'pointer' : 'default',
                          userSelect: 'none',
                        }}
                        onClick={
                          isDownloadable
                            ? () => this.handleGroupDownloadSelected(filename)
                            : undefined
                        }
                      >
                        {filename}
                      </Typography>
                    </Row>
                    <Row>{this.renderStatus(filename, dgf)}</Row>
                  </Row>
                  <Divider />
                </>
              )
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={selectedFiles.size <= 0}
            size="small"
            variant="contained"
            color="primary"
            onClick={this.handleDownloadClick}
          >
            {i18n.t(keys.generic.download)}
          </Button>
          <Button size="small" onClick={this.handleClose}>
            {i18n.t(keys.generic.close)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderStatus = (
    filename: string,
    dgf: Pick<
      DeliveryGroupFile,
      'status' | 'filename' | 'groupId' | 'deliveryId' | 'file'
    >
  ) => {
    const { me } = this.props
    if (dgf) {
      if (dgf.status === 'complete') {
        return (
          <Row
            style={{
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginLeft: 8,
            }}
          >
            {(me?.roles.length ?? 0) > 0 && (
              <Tooltip title={i18n.t(keys.reprocessDownload)} placement="top">
                <IconButton onClick={() => this.reprocess(dgf)} size="large">
                  <Icon>sync</Icon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={i18n.t(keys.download)} placement="top">
              <IconButton onClick={() => this.downloadSingle(dgf)} size="large">
                <Icon>cloud_download</Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title={i18n.t(keys.copyUrl)} placement="top">
              <IconButton onClick={() => this.copyUrl(dgf)} size="large">
                <Icon>content_copy</Icon>
              </IconButton>
            </Tooltip>
          </Row>
        )
      }

      if (dgf.status === 'pending' || dgf.status === 'processing') {
        return (
          <Tooltip title={i18n.t(keys.downloadPending)} placement="top">
            <CircularProgress
              style={{ marginRight: 12 }}
              size={24}
              thickness={4}
              color="inherit"
            />
          </Tooltip>
        )
      }

      if (dgf.status === 'error') {
        return (
          <Tooltip title={i18n.t(keys.downloadError)} placement="top">
            <Icon style={{ marginRight: 12 }}>error</Icon>
          </Tooltip>
        )
      }
    }

    return (
      <Tooltip title={i18n.t(keys.requestDownload)} placement="top">
        <IconButton onClick={() => this.requestDownload(filename)} size="large">
          <Icon>add</Icon>
        </IconButton>
      </Tooltip>
    )
  }

  handleClose = () => {
    const { dispatch } = this.props

    dispatch(resetDownloadsDialog())
  }

  handleDownloadClick = async () => {
    const { selectedGroup, dispatch, groupFilesByFilename } = this.props
    const { selectedFiles } = this.state

    dispatch(resetDownloadsDialog())

    if (selectedGroup?.deliveryGroupFiles) {
      for (const filename of Array.from(selectedFiles)) {
        const dgf = groupFilesByFilename[filename]
        api.deliveryGroupFile.download(dgf)
      }
    }
  }

  copyUrl = async (
    dgf: Pick<GroupFileData, 'deliveryId' | 'groupId' | 'filename' | 'file'>
  ) => {
    const { dispatch } = this.props
    if (await copyToClipboard(api.deliveryGroupFile.getUrl(dgf))) {
      dispatch(
        showFeedback({ message: i18n.t(keys.copySuccess), severity: 'success' })
      )
    }
  }

  downloadSingle = async (
    dgf: Pick<GroupFileData, 'deliveryId' | 'groupId' | 'filename' | 'file'>
  ) => api.deliveryGroupFile.download(dgf)

  reprocess = async ({
    deliveryId,
    groupId,
    filename,
  }: Pick<GroupFileData, 'deliveryId' | 'groupId' | 'filename' | 'status'>) => {
    if (
      await warnConfirm({
        title: i18n.t(keys.confirmReprocessDownload),
        message: i18n.t(keys.confirmReprocessDownloadMessage, {
          filename,
        }),
        action: i18n.t(keys.requestDownload),
        cancel: i18n.t(keys.generic.cancel),
      })
    ) {
      this.setState({ isLoading: true }, async () => {
        try {
          await api.deliveryGroupFile.reprocess({
            deliveryId,
            groupId,
            filename,
          })
        } catch (error) {
          await swal({
            title: i18n.t(keys.downloadRequestErrorTitle),
            text: i18n.t(keys.downloadRequestError),
            dangerMode: true,
          })
        } finally {
          this.setState({ isLoading: false })
          refreshGroupAndAvailableFiles()
        }
      })
    }
  }

  reprocessAll = async () => {
    const { groupFilesByFilename } = this.props
    const groupFiles = Object.values(groupFilesByFilename)
    if (
      await warnConfirm({
        title: i18n.t(keys.confirmReprocessDownload),
        message: i18n.t(keys.confirmReprocessDownloadMessage, {
          filename: i18n.t(keys.allFiles),
        }),
        action: i18n.t(keys.requestDownload),
        cancel: i18n.t(keys.generic.cancel),
      })
    ) {
      this.setState({ isLoading: true }, async () => {
        try {
          for (const { deliveryId, groupId, filename } of groupFiles) {
            await api.deliveryGroupFile.reprocess({
              deliveryId,
              groupId,
              filename,
            })
          }
        } catch (error) {
          await swal({
            title: i18n.t(keys.downloadRequestErrorTitle),
            text: i18n.t(keys.downloadRequestError),
            dangerMode: true,
          })
        } finally {
          this.setState({ isLoading: false })
          refreshGroupAndAvailableFiles()
        }
      })
    }
  }

  requestDownloadAll = async () => {
    const {
      selectedGroup,
      organizationId,
      avalableDownloads,
      groupFilesByFilename,
    } = this.props
    if (selectedGroup && organizationId) {
      const { deliveryId, groupId } = selectedGroup
      if (
        await warnConfirm({
          title: i18n.t(keys.confirmRequestDownload),
          message: i18n.t(keys.confirmRequestDownloadMessage, {
            filename: i18n.t(keys.allFiles),
          }),
          action: i18n.t(keys.requestDownload),
          cancel: i18n.t(keys.generic.cancel),
        })
      ) {
        this.setState({ isLoading: true }, async () => {
          try {
            for (const filename of avalableDownloads) {
              if (groupFilesByFilename[filename]) {
                continue
              }

              await api.deliveryGroupFile.requestDownload({
                deliveryId,
                groupId,
                filename,
                organizationId,
              })
            }

            await swal({
              title: i18n.t(keys.downloadRequested),
              text: i18n.t(keys.downloadRequestedCheckBack, {
                filename: i18n.t(keys.allFiles),
              }),
            })
          } catch (error) {
            await swal({
              title: i18n.t(keys.downloadRequestErrorTitle),
              text: i18n.t(keys.downloadRequestError),
              dangerMode: true,
            })
          } finally {
            this.setState({ isLoading: false })
            refreshGroupAndAvailableFiles()
          }
        })
      }
    }
  }

  requestDownload = async (filename: string) => {
    const { selectedGroup, organizationId } = this.props
    if (selectedGroup && organizationId) {
      const { deliveryId, groupId } = selectedGroup

      this.setState({ isLoading: true }, async () => {
        try {
          await api.deliveryGroupFile.requestDownload({
            deliveryId,
            groupId,
            filename,
            organizationId,
          })

          await swal({
            title: i18n.t(keys.downloadRequested),
            text: i18n.t(keys.downloadRequestedCheckBack, { filename }),
          })
        } catch (error) {
          await swal({
            title: i18n.t(keys.downloadRequestErrorTitle),
            text: i18n.t(keys.downloadRequestError),
            dangerMode: true,
          })
        } finally {
          this.setState({ isLoading: false })
          refreshGroupAndAvailableFiles()
        }
      })
    }
  }

  handleGroupDownloadSelectAll = () => {
    const { selectedFiles } = this.state
    const { groupFilesByFilename } = this.props
    const groupFiles = Object.entries(groupFilesByFilename)
    if (selectedFiles.size === 0) {
      this.setState({
        selectedFiles: new Set(
          groupFiles
            .filter(([, dgf]) => dgf.status === 'complete')
            .map(([filename]) => filename)
        ),
      })
    } else {
      this.setState({
        selectedFiles: new Set<string>(),
      })
    }
  }

  handleGroupDownloadSelected = (filename: string) => {
    const { selectedFiles } = this.state
    const newSelectedFiles = new Set(selectedFiles)

    if (newSelectedFiles.has(filename)) {
      newSelectedFiles.delete(filename)
    } else {
      newSelectedFiles.add(filename)
    }

    this.setState({ selectedFiles: newSelectedFiles })
  }
}

const mapState = (state: RootStore) => ({
  me: selectMe(state),
  open: state.groupDownloads.isGroupDownloadsDialogOpen,
  selectedGroup: state.groupDownloads.selectedGroup,
  organizationId: selectOrganizationId(state),
  avalableDownloads: selectAvailableGroupDownloads(state),
  groupFilesByFilename: selectGroupFilesByFilename(state),
  selectedDate: getSelectedFlightDate(state),
  groupAndAvailableFiles: selectGroupAndAvailableFiles(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  GroupDownloadsDialog
)
