import { postJson } from '../vvapi/apiResource/createApiResource'

export const acceptInvitationExisting = async (token: string) =>
  await postJson(`/api/v3/invitation/${token}/accept/existing`)

export const acceptInvitationNew = async (
  user: {
    firstName: string
    lastName: string
    email: string
    password: string
  },
  token: string
) =>
  await postJson(`/api/v3/invitation/${token}/accept/new`, { body: { user } })
