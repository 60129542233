import { createSelector } from 'reselect'
import { selectFilteredMapLayers } from './selectFilteredMapSources'

export const selectAvailableProductIds = createSelector(
  [selectFilteredMapLayers],
  (mapLayers) => {
    const mapLayerDefIds = new Set<string>()

    mapLayers.forEach((mapLayer) => mapLayerDefIds.add(mapLayer.mapLayerDef.id))

    return Array.from(mapLayerDefIds)
  }
)

export const selectInactiveProductIds = createSelector(
  [selectFilteredMapLayers],
  (mapLayers) => {
    const mapLayerDefIds = new Set<string>()

    mapLayers
      .filter((ml) => !ml.enabled)
      .forEach((mapLayer) => mapLayerDefIds.add(mapLayer.mapLayerDef.id))

    return mapLayerDefIds
  }
)
