import {
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../i18n'

interface Props {
  name: string
  instructions: string
  onNameChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  onInstructionsChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
}

const characterLimit = 500

const OptionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '14.4px',
  letterSpacing: '-0.035em',
  textAlign: 'left',
}))

const OptionStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  flexDirection: 'column',
}))

export const CreateProjectForm = ({
  name,
  instructions,
  onNameChange,
  onInstructionsChange,
}: Props) => {
  return (
    <Stack direction="column" gap={4} width={432}>
      <OptionStack>
        <OptionTitle>{i18n.t(keys.projectNameLabel)}</OptionTitle>
        <TextField
          variant="outlined"
          size="small"
          placeholder={i18n.t(keys.projectNamePlaceholder)}
          value={name}
          onChange={onNameChange}
        />
      </OptionStack>
      <OptionStack>
        <OptionTitle>{i18n.t(keys.projectInstructionsLabel)}</OptionTitle>
        <FormControl error={(instructions?.length ?? 0) > characterLimit}>
          <TextField
            variant="outlined"
            size="small"
            rows={5}
            placeholder={i18n.t(keys.notesProjectInstructionsPlaceholder)}
            value={instructions ?? ''}
            multiline
            error={(instructions?.length ?? 0) > characterLimit}
            onChange={onInstructionsChange}
          />
          <FormHelperText>{`${
            instructions?.length ?? '-'
          }/${characterLimit}`}</FormHelperText>
        </FormControl>
      </OptionStack>
    </Stack>
  )
}
