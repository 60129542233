import * as React from 'react'
import { default as Dialog } from '@mui/material/Dialog'
import { default as DialogActions } from '@mui/material/DialogActions'
import { default as DialogContent } from '@mui/material/DialogContent'
import { default as DialogTitle } from '@mui/material/DialogTitle'
import errorAlert from '../../../admin/errorAlert'
import { NameAndValue } from '../../../admin/mapdata/types'
import { Button } from '@mui/material'
import * as api from '../../../graphql/api'
import {
  MapColorProfile,
  MapColorProfileVisualization,
  Model,
} from '../../../graphql/types'
import i18n, { keys } from '../../../i18n'
import { objectPick } from '../../../util/objectPick'
import assertDefinedFields from '../utils/assertDefinedFields'
import ColorProfileForm, { MapColorProfileData } from './ColorProfileForm'
import ColorProfilePreview from './ColorProfilePreview'
import updateOrCreateVisualizations from './visualizations/updateOrCreateVisualizations'

interface Props {
  mapLayerDefId: string
  open: boolean
  onSave(): void
  onClose(): void
}

interface State {
  colorProfile: Partial<
    Partial<MapColorProfileData & { dataStops: [any, string, string][] }>
  >
}

class CreateColorProfileDialog extends React.PureComponent<Props, State> {
  state: State = {
    colorProfile: {},
  }

  render() {
    const { colorProfile } = this.state
    const { MapColorProfileVisualizations: visualizations = [] } = colorProfile

    return (
      <Dialog
        id="CreateColorProfileDialog"
        fullWidth
        open={!!this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Create Color Profile</DialogTitle>
        <DialogContent>
          <div className="grid">
            <ColorProfileForm
              instance={colorProfile}
              onChange={this.handleChange}
              visualizations={visualizations}
            />
          </div>
        </DialogContent>
        <DialogActions className="align-right">
          <ColorProfilePreview {...colorProfile} />
          <Button onClick={this.props.onClose}>
            {i18n.t(keys.generic.cancel)}
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave}>
            {i18n.t(keys.generic.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleChange = ({ name, value }: NameAndValue) => {
    const { colorProfile } = this.state
    this.setState({
      colorProfile: {
        ...colorProfile,
        [name]: value,
      },
    })
  }

  handleSave = async () => {
    const { mapLayerDefId } = this.props
    const { colorProfile } = this.state
    const { dataStops, MapColorProfileVisualizations: visualizations } =
      colorProfile

    const required = objectPick(colorProfile, ['name', 'type', 'display'])
    const optional = objectPick(colorProfile, [
      'description',
      'noDataColor',
      'coverageColor',
      'minLabel',
      'maxLabel',
      'noDataLabel',
      'coverageLabel',
    ])

    try {
      if (assertDefinedFields(required)) {
        const createdReq = await api.mapColorProfile.create<Model>({
          input: {
            mapLayerDefId,
            ...required,
            ...optional,
            dataStops: (dataStops ?? []).map(([val, color]) => [
              val,
              color,
            ]) as any,
          },
          returning: `{
            id
          }`,
        })

        if (createdReq.data && visualizations) {
          // add visualizations
          const mapColorProfileId = createdReq.data.id

          // no await; we don't want to keep the modal up if the
          // subsequent requests failed.
          createVisualizations({
            mapColorProfileId,
            visualizations,
          })
        }

        this.props.onSave()
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'
      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }
      softError(e, 'Failed to Create Color Profile', message)
    }
  }
}

/**
 * extracted so that we can use a separate try/catch and async/await here,
 * and avoid it in the save method
 */
const createVisualizations = async ({
  visualizations,
  mapColorProfileId,
}: {
  visualizations: (Partial<MapColorProfileVisualization> | undefined)[]
  mapColorProfileId: MapColorProfile['id']
}) => {
  try {
    // add visualizations
    await updateOrCreateVisualizations({
      mapColorProfileId,
      visualizations,
    })
  } catch (e) {
    let message =
      'Please try again or contact us if you require additional assistance.'
    if (/GraphQL error/.test(e.message)) {
      message = e.message.replace(/GraphQL error:?\s?/, '')
    }
    softError(e, 'Failed to Create Color Profile Visualizations', message)
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default CreateColorProfileDialog
