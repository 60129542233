import { ListItem, ListItemText } from '@mui/material'
import * as React from 'react'
import { PureComponent } from 'react'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { updatePreferences } from '../../redux/preferences/redux'
import { AppDispatchProps } from '../../redux/types'
import ListItemCheckbox from '../../UI/ListItemCheckbox'
import { isMobileDevice } from '../../util/getDeviceType'

interface Props {
  isEnabled: boolean
}

class LayerDrawerCursorLocationToggle extends PureComponent<
  Props & AppDispatchProps
> {
  render() {
    return (
      <ListItem button onClick={this.toggle} disabled={isMobileDevice()}>
        <ListItemCheckbox checked={this.props.isEnabled && !isMobileDevice()} />
        <ListItemText primary={i18n.t(keys.layerDrawer.toggleCursorLocation)} />
      </ListItem>
    )
  }

  toggle = () => {
    this.props.dispatch(
      updatePreferences({ showCursorLocation: !this.props.isEnabled })
    )
  }
}

export default connect<Props, {}, AppDispatchProps>((state) => ({
  isEnabled: state.preferences.showCursorLocation,
}))(LayerDrawerCursorLocationToggle)
