import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Typography } from '@mui/material'
import { default as TablePagination } from '@mui/material/TablePagination'

import {
  PAGE_SIZE_OPTIONS,
  selectPagingInfo,
  url,
  urls,
} from '../../appNavigation/urls'
import { connect } from '../../redux/connect'
import { RootStore } from '../../redux/types'
import { createTableActionIconButton } from '../../UI/Table/createTableActionIconButton'
import { Table } from '../../UI/Table/Table'
import { objectToString } from '../../util/objectToString'
import { JobStatusChip } from '../Maestro/JobStatusChip'
import {
  DataSetFlightObject,
  selectListDataSetFlights,
} from './selectListDataSetFlights'

interface State {
  selection: DataSetFlightObject[]
}

interface Props {
  deleteFlights: (flightPKs: string[]) => Promise<any>
}

class DataSetFlightsTable extends React.PureComponent<
  ReduxProps & RouteComponentProps & Props,
  State
> {
  state: State = {
    selection: [],
  }

  handleSelectionChange = (selection: State['selection']) =>
    this.setState({ selection })

  render() {
    const { dataSetFlightsSelector, deleteFlights } = this.props
    const { selection } = this.state
    const pagination = this.renderPagination()

    // eslint-disable-next-line no-self-compare
    return dataSetFlightsSelector.data?.flights.length ?? 0 > 0 ? (
      <>
        {pagination}
        <Table
          stickyOffset={50}
          rows={dataSetFlightsSelector.data?.flights ?? []}
          onSelectionChange={this.handleSelectionChange}
          selection={selection}
          formatter={[
            {
              header: () => 'Organization',
              data: ({ flight }) => flight.Organization.name,
            },
            {
              header: () => 'Target Delivery Date',
              data: ({ flight }) => flight.Delivery.TargetDelivery.date,
            },
            {
              header: () => 'Delivery Comment',
              data: ({ flight }) => flight.Delivery.comment,
            },
            {
              header: () => 'Created By',
              data: ({ flight }) =>
                `${flight.CreatedBy.firstName} ${flight.CreatedBy.lastName}`,
            },
            {
              header: () => 'Updated By',
              data: ({ flight }) =>
                `${flight.UpdatedBy.firstName} ${flight.UpdatedBy.lastName}`,
            },
            {
              header: () => 'Split Proc Groups Job',
              data: ({ splitProcGroupsJob }) => {
                return (
                  <JobStatusChip
                    jobStatusAndId={{
                      status: splitProcGroupsJob.Status?.status ?? 'none',
                      id: splitProcGroupsJob.id,
                      output: objectToString(
                        splitProcGroupsJob.LatestJobAttempt?.output ?? {}
                      ),
                    }}
                  />
                )
              },
            },
          ]}
          actions={[
            {
              selectionRequired: true,
              multi: false,
              button: createTableActionIconButton({
                icon: 'arrow_forward',
                title: 'View Delivery',
                key: 'view-delivery',
              }),
              action: ([datasetFlight]) =>
                this.props.history.push(
                  url(urls.editDelivery, {
                    deliveryId: datasetFlight.flight.deliveryId,
                  })
                ),
            },
            {
              selectionRequired: true,
              multi: true,
              button: createTableActionIconButton({
                title: 'Delete Delivery',
                icon: 'delete',
                key: 'delete-delivery',
              }),
              action: async (flights) => {
                await deleteFlights(
                  flights.map(
                    ({ flight: { deliveryId, date } }) =>
                      `${deliveryId}/${date}`
                  )
                )
                this.setState({ selection: [] })
              },
            },
          ]}
        />
        {pagination}
      </>
    ) : (
      <Typography style={{ textAlign: 'center', padding: 24 }}>
        No Associated Deliveries
      </Typography>
    )
  }

  renderPagination = () => {
    const {
      pagingInfo: { page, pageSize },
      dataSetFlightsSelector,
    } = this.props

    if (dataSetFlightsSelector.data) {
      return (
        <TablePagination
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          component="div"
          count={dataSetFlightsSelector.data.count}
          page={page}
          rowsPerPage={pageSize}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      )
    }

    return null
  }

  handleChangePage = (_event: any, page: number) =>
    this.props.history.push(
      url(urls.editDataSet, this.props.match.params, {
        page,
        pageSize: this.props.pagingInfo.pageSize,
      })
    )

  handleChangeRowsPerPage = (event: any) =>
    this.props.history.push(
      url(urls.editDataSet, this.props.match.params, {
        page: 0,
        pageSize: event.target.value,
      })
    )
}

const mapState = (state: RootStore) => ({
  sort: String(state.router.searchParams.sort ?? 'asc'),
  pagingInfo: selectPagingInfo(state),
  dataSetFlightsSelector: selectListDataSetFlights(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(
  withRouter(DataSetFlightsTable)
)
