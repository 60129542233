import { Translation } from '../graphql/types'
import { createHasuraResource } from './createHasuraResource'
import { Model } from './Model'

export { gql } from './createHasuraResource'

export interface AccessRole {
  id: string
  name: Translation
  description: Translation
  scope: 'global' | 'org'
}

export interface AdminRole {
  userId: string
  role: string
}

export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
}

export const api = {
  accessRoles: createHasuraResource<Model<AccessRole, 'id'>>({
    schema: 'public',
    name: 'AccessRole',
    pkTypes: { id: 'String' },
  }),

  adminRoles: createHasuraResource<
    Model<
      AdminRole,
      'userId' | 'role',
      'userId' | 'role',
      'role',
      'UserRole_pkey'
    >
  >({
    schema: 'public',
    name: 'UserRole',
    pkTypes: { userId: 'Int', role: 'String' },
  }),

  users: createHasuraResource<
    Model<
      User,
      'id',
      'email' | 'firstName' | 'lastName',
      'email' | 'firstName' | 'lastName',
      'User_pkey' | 'User_email_key'
    >
  >({
    schema: 'public',
    name: 'User',
    pkTypes: { id: 'Int' },
  }),
}
