import * as _ from 'lodash'
import { createSelector } from 'reselect'
import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import * as api from '../../graphql/api'
import { TargetDelivery } from '../../graphql/types'
import { RootStore } from '../../redux/types'
import { indexArrayMappedKey } from '../../util/indexArray'

const targetDeliveryObjectReturning = `{
    orderId 
    date
    Order {
      id
      organizationId
      comment
      quoteId
    }
    TargetDelivery_Parcel_Packages {
      Package {
        name(path:"en")
      }
      Parcel {
        name
      }
    }
    Delivery {
      id
      organizationId
      Flights {
        deliveryId
        date
        DataSetFlights {
          dataSetId
        }
      }
    }
}`

const getOrganizationId = (state: RootStore) =>
  state.router.searchParams.organizationId

const getTargetDeliveryPK = (state: RootStore) => ({
  orderId: state.router.searchParams.orderId,
  targetDeliveryDate: state.router.searchParams.targetDeliveryDate,
})

const {
  selector: selectGetOrganizationTargetDeliveries,
  refresh: refreshGetOrganizationTargetDeliveries,
} = createAsyncSelector({
  resource: 'ListOranizationDeliveries',
  inputs: {
    organizationId: getOrganizationId,
  },
  fetcher: async ({ organizationId }) => {
    if (!organizationId) {
      return
    }

    const { data } = await api.targetDelivery.list<TargetDelivery>({
      where: {
        Order: { organizationId: { _eq: organizationId } },
      },
      returning: targetDeliveryObjectReturning,
      order_by: '[{ date: desc }]'
    })

    return data
  },
})

export {
  selectGetOrganizationTargetDeliveries,
  refreshGetOrganizationTargetDeliveries,
}

export const selectOrganizationTargetDeliveries = createSelector(
  selectGetOrganizationTargetDeliveries,
  (getOrganizationTargetDeliveriesSelector) =>
    getOrganizationTargetDeliveriesSelector?.data
)

export const selectOrganizationTargetDeliveriesByPK = createSelector(
  selectOrganizationTargetDeliveries,
  (targetDeliveries) =>
    indexArrayMappedKey(
      targetDeliveries ?? [],
      ({ orderId, date }) => `${orderId}/${date}`
    )
)

export const selectSelectedTargetDelivery = createSelector(
  selectOrganizationTargetDeliveriesByPK,
  getTargetDeliveryPK,
  (deliveriesById, { orderId, targetDeliveryDate }) => {
    if (!orderId || !targetDeliveryDate || !deliveriesById) {
      return
    }

    return deliveriesById[`${orderId}/${targetDeliveryDate}`] as
      | TargetDelivery
      | undefined
  }
)

export const selectOrder = createSelector(
  selectSelectedTargetDelivery,
  (selectedTargetDelivery) => {
    if (!selectedTargetDelivery) {
      return
    }

    return selectedTargetDelivery!.Order
  }
)

export const selectTargetDeliveryPackages = createSelector(
  selectSelectedTargetDelivery,
  (targetDelivery) => {
    if (!targetDelivery) {
      return
    }

    return _.uniq(
      targetDelivery.TargetDelivery_Parcel_Packages!.map(
        (opp) => opp.Package!.name
      )
    )
  }
)

export const selectTargetDeliveryParcels = createSelector(
  selectSelectedTargetDelivery,
  (targetDelivery) => {
    if (!targetDelivery) {
      return
    }

    return _.uniq(
      targetDelivery.TargetDelivery_Parcel_Packages!.map(
        (opp) => opp.Parcel!.name
      )
    )
  }
)
