import { DocumentNode } from 'graphql'

import { selectPagingInfo } from '../../../appNavigation/urls'
import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import { gql, query } from '../../../graphql/client'
import { ParcelDataSetMigration } from './ParcelDataSetMigration'

const GET_PARCEL_DATA_SET_MIGRATIONS_FOR_ORGANIZATION = gql`
  query GET_PARCEL_DATA_SET_MIGRATIONS_FOR_ORGANIZATION(
    $organizationId: Int!
    $limit: Int!
    $offset: Int!
  ) {
    parcelDataSetMigrations: ParcelDataSet(
      limit: $limit
      offset: $offset
      order_by: { acquisitionDate: asc }
      where: {
        deletedAt: { _is_null: true }
        Parcel: {
          organizationId: { _eq: $organizationId }
          Organization: { betaEnabled: { _eq: true } }
        }
      }
    ) {
      id
      parcel: Parcel {
        group: Group {
          id
          name
        }
        id
        name
        organization: Organization {
          id
          name
        }
      }
      acquisitionDate
      subscriptionProduct: SubscriptionProduct {
        scheduledAcquisitionDate
      }
      deliveryParcel: DeliveryParcel {
        deliveryId
        parcelId
        migratedAt
        migratedByVersion
      }
    }
    info: ParcelDataSet_aggregate(
      where: {
        deletedAt: { _is_null: true }
        Parcel: {
          organizationId: { _eq: $organizationId }
          Organization: { betaEnabled: { _eq: true } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const GET_PARCEL_DATA_SET_MIGRATIONS = gql`
  query GET_PARCEL_DATA_SET_MIGRATIONS($limit: Int!, $offset: Int!) {
    parcelDataSetMigrations: ParcelDataSet(
      limit: $limit
      offset: $offset
      order_by: { Parcel: { organizationId: asc } }
      where: {
        deletedAt: { _is_null: true }
        Parcel: { Organization: { betaEnabled: { _eq: true } } }
      }
    ) {
      id
      parcel: Parcel {
        group: Group {
          id
          name
        }
        id
        name
        organization: Organization {
          id
          name
        }
      }
      acquisitionDate
      subscriptionProduct: SubscriptionProduct {
        scheduledAcquisitionDate
      }
      deliveryParcel: DeliveryParcel {
        deliveryId
        parcelId
        migratedAt
        migratedByVersion
      }
    }
    info: ParcelDataSet_aggregate(
      where: {
        deletedAt: { _is_null: true }
        Parcel: { Organization: { betaEnabled: { _eq: true } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const {
  selector: selectParcelDataSetMigrations,
  refresh: refreshParcelDataSetMigrations,
} = createAsyncSelector({
  resource: 'ParcelDataSetMigrations',
  inputs: {
    organizationId: (state) => state.router.params.organizationId,
    pagingInfo: selectPagingInfo,
  },
  fetcher: async ({ organizationId, pagingInfo: { page, pageSize } }) => {
    if (!organizationId) {
      return { data: [], info: { page, pageSize, count: 0 } }
    }

    let variables
    let migrationQuery: DocumentNode

    if (organizationId === 'all') {
      variables = {
        offset: page * pageSize,
        limit: pageSize,
      }
      migrationQuery = GET_PARCEL_DATA_SET_MIGRATIONS
    } else {
      variables = {
        offset: page * pageSize,
        limit: pageSize,
        organizationId: Number(organizationId),
      }
      migrationQuery = GET_PARCEL_DATA_SET_MIGRATIONS_FOR_ORGANIZATION
    }
    const {
      parcelDataSetMigrations,
      info: {
        aggregate: { count },
      },
    } = await query<{
      parcelDataSetMigrations: ParcelDataSetMigration[]
      info: { aggregate: { count: number } }
    }>({
      variables,
      query: migrationQuery,
    })

    return { data: parcelDataSetMigrations, info: { count, page, pageSize } }
  },
})

export { selectParcelDataSetMigrations, refreshParcelDataSetMigrations }
