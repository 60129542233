import * as React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Icon,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { DeliveryParcel } from './fetchSourceAndDestinationParcels'
import { ChooseDestinationParcelDialog } from './ChooseDestinationParcelDialog'

interface MapParcelsProps {
  parcelMap: Record<number, number>
  groups: Record<
    number,
    {
      name: string
      id: number
    }
  >
  orderedGroupsIds: number[]
  orderedParcelIdsByGroup: Record<number, number[]>
  sourceParcelsByGroup: Record<number, Record<number, DeliveryParcel>>
  destinationParcelsByName: Record<string, Record<string, DeliveryParcel>>
  selectedSourceParcels: Set<number>
  onUpdateParcelMap: (parcelMap: Record<number, number>) => void
}
export const MapParcels = ({
  parcelMap,
  groups,
  sourceParcelsByGroup,
  destinationParcelsByName,
  onUpdateParcelMap,
  selectedSourceParcels,
  orderedGroupsIds,
  orderedParcelIdsByGroup,
}: MapParcelsProps) => {
  const theme = useTheme()

  const [expandedGroup, setExpandedGroup] = React.useState<number | undefined>()
  const [mappingSourceParcel, setMappingSourceParcel] = React.useState<
    DeliveryParcel | undefined
  >()

  const handleExpandGroup = (groupId: number) => {
    if (expandedGroup === groupId) {
      setExpandedGroup(undefined)
    } else {
      setExpandedGroup(groupId)
    }
  }

  const handleSkipAllForGroup = (groupId: number) => {
    const newParcelMap = { ...parcelMap }
    for (const parcelId of Object.keys(sourceParcelsByGroup[groupId] ?? [])) {
      newParcelMap[parcelId] = -1
    }

    onUpdateParcelMap(newParcelMap)
  }

  const handleMapAllMatchingForGroup = (groupId: number) => {
    const newParcelMap = { ...parcelMap }

    for (const parcelId of Object.keys(sourceParcelsByGroup[groupId] ?? {})) {
      const { parcel } = sourceParcelsByGroup[groupId][parcelId]

      newParcelMap[parcel.id] =
        destinationParcelsByName[parcel.group.name]?.[parcel.name]?.parcel
          ?.id ?? -1
    }

    onUpdateParcelMap(newParcelMap)
  }

  const [parcelIdsByGroup, selectedGroupIds] = React.useMemo(() => {
    const parcelIds: Record<number, number[]> = {}
    const groupIds: number[] = []
    for (const groupId of orderedGroupsIds) {
      const ids: number[] = []
      for (const parcelId of orderedParcelIdsByGroup[groupId] ?? []) {
        if (selectedSourceParcels.has(parcelId)) {
          ids.push(parcelId)
        }
      }
      if (ids.length > 0) {
        parcelIds[groupId] = ids
        groupIds.push(groupId)
      }
    }

    return [parcelIds, groupIds]
  }, [orderedParcelIdsByGroup, orderedGroupsIds])

  const destinationParcelsById = React.useMemo(() => {
    const parcelsById: Record<number, DeliveryParcel> = {}

    for (const groupParcels of Object.values(destinationParcelsByName)) {
      for (const parcel of Object.values(groupParcels)) {
        parcelsById[parcel.parcel.id] = parcel
      }
    }

    return parcelsById
  }, [destinationParcelsByName])

  const handleCancelChooseDestinationParcel = () => {
    setMappingSourceParcel(undefined)
  }

  const handleChooseDestinationParcel = (parcelId: number) => {
    if (mappingSourceParcel) {
      onUpdateParcelMap({
        ...parcelMap,
        [mappingSourceParcel.parcel.id]: parcelId,
      })

      setMappingSourceParcel(undefined)
    }
  }

  return (
    <>
      <ChooseDestinationParcelDialog
        sourceParcel={mappingSourceParcel}
        destinationParcelsByName={destinationParcelsByName}
        onCancel={handleCancelChooseDestinationParcel}
        onSubmit={handleChooseDestinationParcel}
      />
      <Stack sx={{ my: 2 }}>
        {selectedGroupIds.map((groupId) => {
          const group = groups[groupId]

          return (
            <Accordion
              key={groupId}
              disableGutters
              sx={{ background: 'var(--background-light)' }}
              expanded={expandedGroup === groupId}
              onChange={() => handleExpandGroup(groupId)}
              TransitionProps={{
                unmountOnExit: true,
                appear: false,
                enter: false,
                exit: false,
              }}
            >
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}
                sx={{ p: 1 }}
              >
                <Stack
                  sx={{ width: '100%', px: 1 }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>{group.name}</Typography>
                  <Stack direction="row" spacing={1}>
                    <Button
                      onClick={(ev) => {
                        ev.stopPropagation()
                        handleMapAllMatchingForGroup(groupId)
                      }}
                      variant="outlined"
                      sx={{
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.divider,
                      }}
                    >
                      Auto-Match
                    </Button>
                    <Button
                      onClick={(ev) => {
                        ev.stopPropagation()
                        handleSkipAllForGroup(groupId)
                      }}
                      variant="outlined"
                      sx={{
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.divider,
                      }}
                    >
                      Skip All
                    </Button>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 3 }}>
                {parcelIdsByGroup[groupId].map((parcelId) => {
                  const sourceParcel = sourceParcelsByGroup[groupId][parcelId]
                  const destinationParcelId =
                    parcelMap[sourceParcel.parcel.id] ?? -1
                  const destinationParcel =
                    destinationParcelId === -1
                      ? undefined
                      : destinationParcelsById[destinationParcelId]

                  return (
                    <Grid
                      key={sourceParcel.parcel.id}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} justifyContent="center">
                        <Typography>{sourceParcel.parcel.name}</Typography>
                      </Grid>
                      <Grid item xs={6} justifyContent="center">
                        <Button
                          sx={{
                            m: 1,
                            textDecoration: 'underline',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          variant="text"
                          onClick={() => setMappingSourceParcel(sourceParcel)}
                        >
                          {destinationParcel
                            ? `${destinationParcel.parcel.group.name} | ${destinationParcel.parcel.name}`
                            : 'Skip'}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  )
                })}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Stack>
    </>
  )
}
