interface Worker {
  (...args: any[]): Promise<any>
}

interface Job {
  args: any[]
  resolve: (result: any) => void
  reject: (err: any) => void
}

interface Cancelable {
  empty: () => void
}

interface Queryable {
  getLength: () => number
}

type Queue<F> = F & Cancelable & Queryable

/**
 * It wraps an asynchronous function to make sure no subsequent calls are
 * executed simultaneously; each call waits until the previous call is finished
 *
 * Example:
 *  ```ts
 *  const fetchStats = (id: number) => fetch(`/stats/${id}`).then(a => a.json())
 *  const queuedFetchStats = queue(fetchStats)
 *  const stats = await queuedFetchStats(1234)
 *  ```
 */
export default function queue<F extends Worker>(
  worker: F,
  concurrency: number = 1
) {
  const jobs: Job[] = []
  const processing: Map<symbol, Job> = new Map()
  let workers = 0

  /**
   * Handle concurrent workers
   */
  const startWorkers = () => {
    /**
     * Each worker drains the queue
     */
    const drain = async () => {
      workers += 1
      while (jobs.length > 0) {
        const job = jobs.shift()

        if (!job) {
          continue
        }

        // add job to processing to remove from active queue
        // and keep a reference so we can clear all jobs
        const symbol = Symbol()
        processing.set(symbol, job)

        try {
          job.resolve(await worker(...job.args))
        } catch (err) {
          job.reject(err)
        }

        // remove processed job (after resolved or rejected)
        processing.delete(symbol)
      }
      workers -= 1
    }

    const newWorkers = concurrency - workers

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    for (const _ of Array.from({ length: newWorkers })) {
      drain()
    }
  }

  const queuedFunction = ((...args: any[]) =>
    new Promise((resolve, reject) => {
      jobs.push({
        args,
        resolve,
        reject,
      })

      startWorkers()
    })) as Queue<F>

  const allJobs = () => [...jobs, ...Array.from(processing.values())]

  queuedFunction.empty = () => {
    const outstanding = allJobs()
    while (outstanding.length > 0) {
      outstanding.shift()!.reject(new Error('Aborted'))
    }
    processing.clear()
  }

  queuedFunction.getLength = () => allJobs().length

  return queuedFunction
}
