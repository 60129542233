import { api, gql } from '../../../hasura/api'

export const deleteAdminRoleByPK = async (userId: string, role: string) => {
  const choice = await sweetAlert(
    `Are you sure you want to delete this AdminRole?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Delete',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (choice == null || choice === false) {
    return
  }

  return await api.adminRoles.deleteByPK<{ userId: string; role: string }>(
    {
      pk: { userId, role },
    },
    gql`
      {
        userId
        role
      }
    `
  )
}
