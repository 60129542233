import { createSelector } from 'reselect'

import fuzzySearch from '../util/fuzzySearch'
import { isNumericIdEqual } from '../util/isNumericIdEqual'
import { selectDeliveryParcels } from './selectDelivery'
import { ParcelData, selectParcels } from './selectOrgMapData'
import { selectParcelFilter } from './selectParcelFilter'
import { selectParcelFilterInfo } from './selectParcelFilterInfo'
import { ParcelFilterInfo } from '../postgis/types'
import { Fetched } from '../AsyncSelector/createAsyncSelector'

export const getFilteredParcels = (
  parcels: ParcelData[],
  parcelFilterInfo: ParcelFilterInfo,
  {
    data: parcelFilter,
  }: Fetched<Promise<{ parcels: { parcelId: string }[] } | null | undefined>>
) => {
  let filterParcels = parcels

  if (parcelFilterInfo.advancedFilterOpen && parcelFilter) {
    filterParcels = filterParcels.filter(
      (parcel) =>
        !!parcelFilter.parcels.find(({ parcelId }) =>
          isNumericIdEqual(parcel.id, parcelId)
        )
    )
  }

  if (parcelFilterInfo?.searchTerm?.length > 0) {
    filterParcels = filterParcels.filter(
      (parcel) =>
        fuzzySearch(parcelFilterInfo.searchTerm, parcel.group.name) ||
        fuzzySearch(parcelFilterInfo.searchTerm, parcel.name)
    )
  }

  return filterParcels
}

export const selectFilteredParcels = createSelector(
  [selectParcels, selectParcelFilterInfo, selectParcelFilter],
  getFilteredParcels
)

export const selectFilteredDeliveryParcels = createSelector(
  [selectDeliveryParcels, selectFilteredParcels],
  (deliveryParcels, filteredParcels) => {
    const parcelIds = new Set(filteredParcels.map(({ id }) => id))

    return deliveryParcels.filter(({ parcelId }) => parcelIds.has(parcelId))
  }
)
