import { gql, query } from '../../../../../graphql/client'
import {
  Delivery,
  DeliveryGroup,
  Languages,
  Order,
  Organization,
  Package,
  Parcel,
} from '../../../../../graphql/types'
import compareNumeric from '../../../../../util/compareNumeric'

export interface ParcelData {
  id: Parcel['id']
  name: string
  geometry: GeoJSON.Polygon
  retiredAt?: string

  group: GroupData
  packages: any[]
}

export interface GroupData {
  id: string
  deliveryId: Delivery['id']
  groupId: DeliveryGroup['groupId']
  name: string

  organization: OrganizationData
  parcels: ParcelData[]
}

export interface OrganizationData {
  id: Organization['id']
  name: string

  groups: GroupData[]
}

export interface OrderData {
  id: Order['id']

  organization: OrganizationData
  targetDeliveries: {
    orderId: string
    date: string
    flightNoticeSentAt?: string
    delivery?: { id: string }
    updatedAt?: string
    createdAt?: string
    inhibitFlightNotice: boolean
    targetDelivery_Parcel_Packages: {
      orderId: string
      targetDeliveryDate: string
      parcelId: string
      package: string
    }[]
  }[]
}

export interface PackageData {
  code: Package['code']
  name: string
  description: string
}

export const FETCH_ORDER_PACKAGE_SELECTION_DATA = gql`
  query FETCH_ORDER_PACKAGE_SELECTION_DATA($orderId: uuid!, $lang: String!) {
    order: Order_by_pk(id: $orderId) {
      id

      organization: Organization {
        id
        name

        groups: OrganizationGroups(where: { deletedAt: { _is_null: true } }) {
          id
          name

          parcels: Parcels(where: { deletedAt: { _is_null: true } }) {
            id
            name
            geometry
            retiredAt
          }
        }
      }

      targetDeliveries: TargetDeliveries(order_by: [{ date: asc }]) {
        orderId
        date
        flightNoticeSentAt
        updatedAt
        createdAt
        inhibitFlightNotice
        delivery: Delivery {
          id
        }

        targetDelivery_Parcel_Packages: TargetDelivery_Parcel_Packages {
          orderId
          targetDeliveryDate
          parcelId
          package
        }
      }
    }

    packages: Package {
      code
      name(path: $lang)
      description(path: $lang)
    }
  }
`

export interface FetchOrderData {
  order: OrderData
  packages: PackageData[]
}

function mapData(data: FetchOrderData) {
  const { order, packages } = data
  let parcels: ParcelData[] = []

  order.organization.groups.forEach((group) => {
    group.organization = order.organization

    group.parcels.forEach((parcel) => {
      parcel.group = group
    })

    group.parcels.sort((a, b) => compareNumeric(a.name, b.name))

    parcels = parcels.concat(group.parcels)
  })

  order.organization.groups.sort((a, b) => compareNumeric(a.name, b.name))

  return {
    order,
    packages,
    organization: order.organization,
    groups: order.organization.groups,
    parcels,
  }
}

export type FetchOrderPackageSelection = ReturnType<typeof mapData>

export async function fetchOrderPackageSelection(
  orderId: string,
  lang: Languages
) {
  const data = await query<FetchOrderData>({
    variables: {
      orderId,
      lang,
    },
    query: FETCH_ORDER_PACKAGE_SELECTION_DATA,
  })

  if (!data) {
    return
  }

  return mapData(data)
}
