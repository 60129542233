import * as React from 'react'

import { Icon, IconButton, List, ListItem, Tooltip } from '@mui/material'

import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import ResetSettings from '../ProductSettings/UI/ResetSettings'
import { updatePreferences } from '../redux/preferences/redux'
import ColorSchemeList from './ColorSchemeList'

export function ColorDrawerToggle() {
  const [state, dispatch] = useRedux()
  const { preferences } = state

  const toggleColorDrawer = (event: any) => {
    event.preventDefault()

    if (
      preferences.showSidebarRight &&
      preferences.sidebarTabRight === 'colors'
    ) {
      dispatch(updatePreferences({ showSidebarRight: false }))
    } else {
      dispatch(
        updatePreferences({ showSidebarRight: true, sidebarTabRight: 'colors' })
      )
    }
  }

  return (
    <Tooltip title={i18n.t(keys.legend.changeColorSchemeTooltip)}>
      <div>
        <IconButton onClick={toggleColorDrawer} size="large">
          <Icon>palette</Icon>
        </IconButton>
      </div>
    </Tooltip>
  )
}

export default function ColorDrawer() {
  return (
    <List dense>
      <ColorSchemeList />
      <ListItem>
        <ResetSettings />
      </ListItem>
    </List>
  )
}
