import { createSelector } from 'reselect'
import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'

import { RootStore } from '../redux/types'
import { RequestError } from '../util/request'
import { getJson } from '../vvapi/apiResource/createApiResource'

export interface GetUserInviteData {
  organizationId: string
  email: string
  role: string
  organization: {
    name: string
  }
  invitedBy: {
    firstName: string
    lastName: string
    email: string
  }
  user?: {
    id: number
  }
  createdAt: string
  expiresAt: string
  accepted: boolean
}

const getOrganizationUserInviteByToken = async ({
  inviteToken,
}: {
  inviteToken: string
}) => {
  try {
    const { invite } = await getJson<{ invite: GetUserInviteData }>(
      `/api/v3/user-invitation/${inviteToken}`
    )

    return invite
  } catch (error) {
    if (error instanceof RequestError) {
      if (error.response.status === 403) {
        return 'INVALID_TOKEN'
      }
    }

    throw error
  }
}

const fetchInvite = async ({ inviteToken }: { inviteToken: string }) => {
  if (!inviteToken) {
    return
  }

  return await getOrganizationUserInviteByToken({
    inviteToken,
  })
}

const { selector: selectGetUserInvite, refresh: refreshGetUserInvite } =
  createAsyncSelector({
    resource: 'GetUserInvite',
    inputs: {
      inviteToken: (state: RootStore) => state.router.params.token,
    },
    fetcher: fetchInvite,
  })

export { selectGetUserInvite, refreshGetUserInvite }

export const selectUserInvite = createSelector(
  selectGetUserInvite,
  (getUserInvite) => getUserInvite.data
)
