import { selectPagingInfo } from '../../appNavigation/urls'
import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import * as api from '../../graphql/api'
import { RootStore } from '../../redux/types'
import { FILTER, filterBy } from '../../UI/Table/filterRows'
import { ORDER, orderBy } from '../../UI/Table/orderRows'
import { tableFormatters } from './tableFormatters'

export interface ListMapSourceDefsData {
  id: string
  name: string
  createdAt: string
  updatedAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }
  MapSourceDefFilenames: [
    {
      filename: string
    }
  ]
}

const { selector: selectListMapSourceDefs, refresh: refreshListMapSourceDefs } =
  createAsyncSelector({
    resource: 'ListMapSourceDefsData',
    inputs: {
      lang: selectPreferredLanguage,
      searchParams: (state: RootStore) => state.router.searchParams,
      pagingInfo: selectPagingInfo,
    },
    fetcher: async ({ lang, searchParams, pagingInfo: { page, pageSize } }) => {
      const order = ORDER.deserialize(searchParams.orderBy ?? '')
      const filter = FILTER.deserialize(searchParams.filter ?? '')

      const offset = page * pageSize
      const limit = pageSize

      const { data, info } = await api.mapSourceDef.list<ListMapSourceDefsData>(
        {
          limit,
          offset,
          order_by: orderBy(tableFormatters, order),
          where: filterBy(tableFormatters, filter) as any,
          returning: `{
        id
        name(path: "${lang}")
        CreatedBy {
          firstName
        }
        UpdatedBy {
          firstName
        }
        createdAt
        updatedAt
        MapSourceDefFilenames {
          filename
        }
      }`,
        }
      )

      if (!data) {
        throw new Error('No Data')
      }

      return {
        data,
        info: {
          order,
          filter,
          page,
          pageSize,
          count: info?.count ?? 0,
        },
      }
    },
  })

export { selectListMapSourceDefs, refreshListMapSourceDefs }
