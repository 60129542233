import * as React from 'react'
import { Table } from '../../UI/Table/Table'
import { Form } from '../UI/Form'
import { EditPackageData } from './selectEditPackage'

class SelectMapLayerDefFrom extends Form<EditPackageData> {
  render() {
    const { mapLayerDefs = [], selectedMapLayerDefs = [] } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <Table
            title="Select MapLayerDefs"
            rows={mapLayerDefs}
            formatter={[
              {
                header: () => 'MapLayerDef',
                data: ({ name }) => name,
              },
            ]}
            selection={selectedMapLayerDefs}
            onSelectionChange={this.handleSelectionChange}
          />
        </div>
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }

  handleSelectionChange = (
    selectedMapLayerDefs: EditPackageData['selectedMapLayerDefs']
  ) =>
    this.updateFormData({
      selectedMapLayerDefs,
    })
}

export default SelectMapLayerDefFrom
