const passwordRegexes = {
  lowercase: /(.*[a-z])/,
  upperCase: /(.*[A-Z])/,
  minLength: /(.{6,})/,
  number: /(.*[0-9])/,
  specialChars: /(.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~])/,  // eslint-disable-line  no-useless-escape
}

enum PasswordStrength {
  None = 0,
  Weak = 1,
  Moderate = 2,
  Ok = 3,
  Good = 4,
  Strong = 5,
}

export const passwordStrength = (password: string) => {
  let strength: PasswordStrength = 0
  for (const regex of Object.values(passwordRegexes)) {
    if (password.match(regex)) {
      strength += 1
    }
  }

  return strength
}
