import * as gravatar from 'gravatar'
import MUIRichTextEditor from 'mui-rte'
import * as React from 'react'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material'

import i18n from '../../../../i18n'
import { CommentField } from '../../../../UI/CommentField/CommentField'
import { commentFieldDecorators } from '../../../../UI/CommentField/commentFieldDecorators'
import { isNumericIdEqual } from '../../../../util/isNumericIdEqual'
import { Comment } from '../fetchDeliveryProcGroupComments'
import { useRedux } from '../../../../hooks/useRedux'
import { selectMe } from '../../../../data/selectMe'
import { DeliveryProcGroup } from '../../../../graphql/types'

interface DeliveryProcGroupCommentProps {
  deliveryProcGroup: DeliveryProcGroup | undefined
  comment: Comment
  selectedCommentId?: string
  replyToCommentId?: string
  handleDeleteComment: (commentId: string) => void
  handleSubmitComment: (
    comment: string,
    mentionEmails?: string[],
    actionSet?: Set<string>
  ) => void
  handleCancelReply: () => void
  handleReplyToComment: (commentId: string) => void
  canReply?: boolean
}

export const DeliveryProcGroupComment = ({
  deliveryProcGroup,
  comment,
  selectedCommentId,
  replyToCommentId,
  handleDeleteComment,
  handleSubmitComment,
  handleCancelReply,
  handleReplyToComment,
  canReply = true,
}: DeliveryProcGroupCommentProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const [state] = useRedux()
  const theme = useTheme()
  const me = selectMe(state)

  React.useEffect(() => {
    if (ref.current && selectedCommentId === comment.id) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [selectedCommentId])

  const transformComment = (data: string) => {
    try {
      JSON.parse(data)

      return data
    } catch (e) {
      return JSON.stringify({
        blocks: [
          {
            key: '',
            text: data,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      })
    }
  }

  return (
    <Card
      ref={ref}
      key={comment.id}
      variant="outlined"
      sx={{
        background:
          comment.id === selectedCommentId
            ? theme.palette.action.hover
            : undefined,
      }}
    >
      <CardHeader
        sx={{ p: 1 / 2 }}
        avatar={
          <Avatar
            variant="rounded"
            style={{ marginRight: 12 }}
            src={gravatar.url(comment.author.email, {
              d: 'mm',
              r: 'g',
            })}
          />
        }
        title={`${comment.author.firstName} ${comment.author.lastName} (${comment.author.email})`}
        subheader={i18n.toDateTimeShort(comment.createdAt)}
        action={
          isNumericIdEqual(comment.author.id, me?.id) ? (
            <Tooltip title="Delete Comment" placement="left">
              <IconButton
                size="small"
                onClick={() => handleDeleteComment(comment.id)}
              >
                <Icon sx={{ fontSize: 10 }}>delete</Icon>
              </IconButton>
            </Tooltip>
          ) : null
        }
      />
      <CardContent sx={{ p: 1 / 2 }}>
        <MUIRichTextEditor
          toolbar={false}
          readOnly
          defaultValue={transformComment(comment.content)}
          decorators={commentFieldDecorators}
        />
        {comment.replies?.length ? (
          <Stack sx={{ paddingTop: 1 }} spacing={1}>
            {comment.replies?.map((reply) => (
              <DeliveryProcGroupComment
                key={reply.id}
                deliveryProcGroup={deliveryProcGroup}
                comment={reply}
                selectedCommentId={selectedCommentId}
                replyToCommentId={replyToCommentId}
                handleCancelReply={handleCancelReply}
                handleDeleteComment={handleDeleteComment}
                handleReplyToComment={handleReplyToComment}
                handleSubmitComment={handleSubmitComment}
                canReply={false}
              />
            ))}
          </Stack>
        ) : null}
      </CardContent>
      <CardActions>
        {canReply ? (
          replyToCommentId === comment.id ? (
            <CommentField
              actions={
                deliveryProcGroup?.UploadJob?.Status?.status === 'complete' &&
                me?.roles.includes('admin')
                  ? ['approve', 'fail']
                  : []
              }
              maxLength={500}
              placeholder="Reply to comment"
              onSubmit={handleSubmitComment}
              canCancel
              onCancelComment={handleCancelReply}
              autoFocus
            />
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleReplyToComment(comment.id)}
            >
              Reply
            </Button>
          )
        ) : null}
      </CardActions>
    </Card>
  )
}

export default DeliveryProcGroupComment
