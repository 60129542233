import * as React from 'react'

import EnhancedTableToolbar from '../../UI/EnhancedTable/EnhancedTableToolbar'

import MaestroHome from './MaestroHome'

import useAsync from '../../hooks/useAsync'
import { client, gql } from '../../graphql/client'
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table'
import { Job } from './JobTable/types'
import {
  ArrowForward,
  Pause,
  PlayArrow,
  ScheduleSend,
} from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { url, urls } from '../../appNavigation/urls'
import i18n, { keys } from '../../i18n'
import TooltipIconButton from '../../UI/TooltipIconButton'
import { useRedux } from '../../hooks/useRedux'
import { refreshPauseAllStatus, selectPauseAllStatus } from './maestroSelectors'
import { pauseAllJobs, unpauseAllJobs } from '../../vvapi/maestro'
import { ScheduleJobDialog } from './Reprocessing/ScheduleJobDialog'

interface RunningJob {
  jobId: string
  job: Pick<Job, 'id' | 'taskName'>
  worker: string
  startedAt: string
}

const EMPTY_RUNNING_JOBS: RunningJob[] = []

export const MaestroRunning = () => {
  const [state] = useRedux()
  const [reprocessingDialogOpen, setReprocessingDialogOpen] =
    React.useState(false)

  const pauseAllStatusSelector = selectPauseAllStatus(state)

  const pauseOrUnpauseAllJobs = async () => {
    const pauseAllStatus = pauseAllStatusSelector?.data ?? false

    if (pauseAllStatus) {
      await unpauseAllJobs()
    } else {
      await pauseAllJobs()
    }

    refreshPauseAllStatus()
  }

  const renderActions = () => {
    const pauseAllStatus = pauseAllStatusSelector?.data ?? false

    return (
      <>
        <TooltipIconButton
          title="Schedule Reprocessing Job"
          onClick={() => setReprocessingDialogOpen(true)}
        >
          <ScheduleSend />
        </TooltipIconButton>
        <TooltipIconButton
          title={
            pauseAllStatus
              ? i18n.t(keys.unpauseAllJobs)
              : i18n.t(keys.pauseAllJobs)
          }
          onClick={pauseOrUnpauseAllJobs}
        >
          {pauseAllStatus ? <PlayArrow /> : <Pause />}
        </TooltipIconButton>
      </>
    )
  }
  const history = useHistory()
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25, //customize the default page size
  })

  const [sorting, setSorting] = React.useState<MRT_SortingState>([])

  const [maestroRunning, refreshMaestroRunning] = useAsync(async () => {
    const order_by = sorting.map((sort) => {
      return { [sort.id]: sort.desc ? 'desc' : 'asc' }
    })

    const { runningJobs } = await client.request<{ runningJobs: RunningJob[] }>(
      {
        query: gql`
          query MAESTRO_RUNNING(
            $limit: Int!
            $offset: Int!
            $order_by: [maestro_JobAttempt_order_by!]
          ) {
            runningJobs: maestro_JobAttempt(
              limit: $limit
              offset: $offset
              order_by: $order_by
              where: { status: { _eq: "running" } }
            ) {
              jobId
              worker
              job: Job {
                id
                taskName
              }
              startedAt
            }
          }
        `,
        variables: {
          limit: pagination.pageSize,
          offset: pagination.pageIndex * pagination.pageSize,
          order_by,
        },
      }
    )

    return runningJobs
  }, [pagination.pageIndex, pagination.pageSize, sorting])

  const onCloseReprocessDialog = () => {
    setReprocessingDialogOpen(false)
  }

  const table = useMaterialReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    getRowId: (row) => row.jobId,
    data: maestroRunning?.result ?? EMPTY_RUNNING_JOBS,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 75, 100],
    },
    columns: [
      {
        header: 'Task Name',
        accessorKey: 'job.taskName',
      },
      {
        header: 'Worker',
        accessorKey: 'worker',
      },
      {
        header: 'Started',
        accessorFn: (row) => i18n.toDateTimeShort(row.startedAt),
      },
      {
        enableColumnActions: false,
        enableHiding: false,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        header: 'Actions',
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              history.push(url(urls.job, { jobId: row.id }))
            }}
          >
            <ArrowForward />
          </IconButton>
        ),
      },
    ] as MRT_ColumnDef<RunningJob>[],

    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    onSortingChange: setSorting,
    state: {
      pagination,
      isLoading: maestroRunning.status === 'pending', //cell skeletons and loading overlay
      showProgressBars: maestroRunning.status === 'pending', //progress bars while refetching
    },
  })

  return (
    <MaestroHome>
      <EnhancedTableToolbar
        style={{ paddingLeft: 0, paddingRight: 0 }}
        title={`Maestro Running Jobs`}
        numSelected={0}
        onClickRefresh={refreshMaestroRunning}
        actions={renderActions}
      />
      <MaterialReactTable table={table} />
      <ScheduleJobDialog
        open={reprocessingDialogOpen}
        onClose={onCloseReprocessDialog}
      />
    </MaestroHome>
  )
}
