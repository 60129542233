import * as React from 'react'
import { Box, InputLabel, Stack, useTheme } from '@mui/material'
import CurrencyInput, {
  CurrencyInputOnChangeValues,
} from 'react-currency-input-field'

export const RateMapNumberInput = ({
  label,
  value,
  onChange,
  id,
  currency,
  startAdornment,
  locale,
}: {
  currency?: boolean
  id?: string
  onChange: (value: number | null) => void
  value?: number | null
  label?: string
  startAdornment?: string
  locale?: string
}) => {
  const theme = useTheme()

  const [values, setValues] = React.useState<
    Pick<CurrencyInputOnChangeValues, 'value' | 'float'> | undefined
  >({
    value: (value ?? 0).toString(),
    float: value === undefined ? null : value,
  })

  const floatValue = React.useMemo(() => values?.float ?? null, [values])
  const stringValue = React.useMemo(() => {
    if (values?.value === '') {
      return '0'
    }
    if (values?.value === undefined) {
      return '0'
    }
    if (values?.value === 'NaN') {
      return '0'
    }
    return values?.value
  }, [values])

  React.useEffect(() => {
    if (floatValue === null || floatValue === undefined) {
      if (value !== 0) {
        onChange(0)
      }
    } else if (floatValue !== value) {
      onChange(floatValue)
    }
  }, [floatValue, onChange, value])

  const decimalScale = React.useMemo(() => {
    if (currency) {
      return 2
    }
    return 0
  }, [currency])

  return (
    <Stack gap={1} sx={{ width: '100%', overflow: 'hidden' }}>
      {label && (
        <InputLabel
          htmlFor={id}
          sx={{
            textTransform: 'uppercase',
            fontWeight: 400,
            fontSize: '12px',
            letterSpacing: '0.4px',
          }}
        >
          {label}
        </InputLabel>
      )}
      <Box
        component="div"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1 / 4,
          px: 1,
          borderRadius: '8px',
          color: theme.palette.text.primary,
          background: 'transparent',
          border: `1px solid #8C8A88`,
          minHeight: 10,
          height: 24,

          '&:hover': {
            borderColor: theme.palette.primary.main,
          },

          '&:focus-visible': {
            outline: 0,
          },
        }}
      >
        <CurrencyInput
          prefix={
            Boolean(currency) && startAdornment ? startAdornment : undefined
          }
          decimalScale={decimalScale}
          style={
            {
              verticalAlign: 'middle',
              margin: 0,
              width: '100%',
              border: 'none',
              background: 'transparent',
              borderRadius: 'inherit',
              outline: 0,
              color: 'inherit',
              fontWeight: '400',
              lineHeight: '15px',
              letterSpacing: '0.4px',
              textAlign: 'left',
              textWrap: 'nowrap',
              textOverflow: 'ellipsis',
              fontSize: '12px',
              '&:focus': {
                borderColor: theme.palette.primary.main,
              },
            } as any
          }
          id={id}
          value={stringValue}
          onValueChange={(_v, _n, values) => {
            setValues(values)
          }}
          intlConfig={{
            locale: locale || 'en',
          }}
          groupSeparator={locale === 'fr' ? ' ' : ','}
          decimalSeparator={
            Boolean(currency) ? (locale === 'fr' ? ',' : '.') : undefined
          }
          allowNegativeValue={false}
          allowDecimals={Boolean(currency)}
        />
      </Box>
    </Stack>
  )
}
