import { createSelector } from '@reduxjs/toolkit'
import {
  MapEditorToolType,
  MapEditorToolsState,
} from '../../../redux/map-editor-pages/redux'
import { RootStore } from '../../../redux/types'

const getPageDrawerOptions = (
  state: MapEditorToolsState,
  pageType: MapEditorToolType
) => state?.[pageType]?.drawerOptions

export const selectDrawerOptions = createSelector(
  [
    (state: RootStore) => state.mapEditorTools,
    (_: RootStore, pageType: MapEditorToolType) => pageType,
  ],
  (toolPages, pageType) => getPageDrawerOptions(toolPages, pageType)
)

export const selectDrawerOption = createSelector(
  [
    selectDrawerOptions,
    (_state: RootStore, _pageType: MapEditorToolType, id: string) => id,
  ],
  (sideBarOptions, id) => sideBarOptions[id]
)
