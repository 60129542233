import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import { makeStyles } from '@mui/styles'
import * as React from 'react'

const useStyles = makeStyles({
  listItemText: {
    whiteSpace: 'normal',
  },
})

// tslint:disable-next-line:function-name
export default React.forwardRef(function WrappingMenuItem(
  props: MenuItemProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const classes = useStyles()
  return (
    <MenuItem
      ref={ref}
      {...(props as any)}
      classes={{
        root: classes.listItemText,
      }}
    />
  )
})
