import { createSelector } from 'reselect'
import { selectMapLayerDefs } from './selectListMapSourceDefs'
import { selectSelectedDeliveryParcels } from './selectSelectedDeliveryParcels'

export const selectFilteredMapSources = createSelector(
  [selectSelectedDeliveryParcels],
  (mapDeliveryParcels) => {
    return mapDeliveryParcels
      .map(({ mapSources }) => mapSources)
      .reduce((acc, mapSources) => [...acc, ...mapSources], [])
  }
)

export const selectFilteredMapLayers = createSelector(
  [selectSelectedDeliveryParcels, selectMapLayerDefs],
  (mapDeliveryParcels, mapLayerDefs) => {
    return mapDeliveryParcels
      .map(({ mapLayers }) => mapLayers)
      .reduce((acc, mapLayers) => [...acc, ...mapLayers], [])
      .sort(
        (a, b) =>
          mapLayerDefs.findIndex(({ id }) => a.mapLayerDefId === id) -
          mapLayerDefs.findIndex(({ id }) => b.mapLayerDefId === id)
      )
  }
)
