import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { default as TablePagination } from '@mui/material/TablePagination'

import {
  DEFAULT_PAGE_INFO,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import EnhancedTableToolbar from '../../../UI/EnhancedTable/EnhancedTableToolbar'
import { ORDER } from '../../../UI/Table/orderRows'
import { Table } from '../../../UI/Table/Table'
import { TableOrder } from '../../../UI/Table/types'
import StatusHome from '../StatusHome'
import StatusSummary from '../StatusSummary'
import {
  refreshGetDeliveryStatus,
  selectGetDeliveryStatus,
} from './selectGetDeliveryStatus'
import {
  refreshListDeliveryStatus,
  selectListDeliveryStatus,
} from './selectListDeliveryStatus'
import { tableFormatter } from './tableFormatter'

interface State {
  statusFilter: string[]
}

class DeliveryStatus extends React.PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps,
  State
> {
  _fetched = false

  state: State = {
    statusFilter: ['error'],
  }

  getInfo = () => {
    return this.props.listDeliveryStatus.data
      ? this.props.listDeliveryStatus.data.info
      : { ...DEFAULT_PAGE_INFO, order: [] }
  }

  handleOrderChange = (order: TableOrder) =>
    this.replaceURL({ page: 0, orderBy: ORDER.serialize(order) })

  render() {
    const { getDeliveryStatus, listDeliveryStatus } = this.props
    const { order } = this.getInfo()
    const pagination = this.renderPagination()

    const rows = listDeliveryStatus.data ? listDeliveryStatus.data.data : []

    return (
      <StatusHome>
        <div id="DeliveryStatus" className="Paper">
          <EnhancedTableToolbar
            title={`Delivery Status`}
            numSelected={0}
            onClickRefresh={this.handleRefresh}
          />
          <StatusSummary summary={getDeliveryStatus} />
          {pagination}
          <AsyncSelectorStatusOverlay requests={listDeliveryStatus}>
            <Table
              stickyOffset={64}
              rows={rows}
              formatter={tableFormatter}
              order={order}
              onOrderChange={this.handleOrderChange}
            />
          </AsyncSelectorStatusOverlay>
          {pagination}
        </div>
      </StatusHome>
    )
  }

  renderPagination() {
    const { count, pageSize, page } = this.getInfo()

    return (
      <TablePagination
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    )
  }

  updateURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) => {
    const { pageSize: dPageSize, page: dPage, order } = this.getInfo()

    const {
      page = dPage,
      pageSize = dPageSize,
      orderBy = ORDER.serialize(order),
    } = options

    this.props.history.push(
      url(urls.deliveryStatus, {}, { page, pageSize, orderBy })
    )
  }

  replaceURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) => {
    const { pageSize: dPageSize, page: dPage, order } = this.getInfo()

    const {
      page = dPage,
      pageSize = dPageSize,
      orderBy = ORDER.serialize(order),
    } = options

    this.props.history.replace(
      url(urls.deliveryStatus, {}, { page, pageSize, orderBy })
    )
  }

  handleChangePage = (_event: any, page: number) => this.updateURL({ page })

  handleChangeRowsPerPage = (event: any) =>
    this.replaceURL({ page: 0, pageSize: event.target.value })

  handleRefresh = () => {
    refreshGetDeliveryStatus()
    refreshListDeliveryStatus()
  }
}

const mapState = (state: RootStore) => {
  return {
    getDeliveryStatus: selectGetDeliveryStatus(state),
    listDeliveryStatus: selectListDeliveryStatus(state),
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(DeliveryStatus)
)
