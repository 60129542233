import { selectPagingInfo } from '../../appNavigation/urls'
import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { gql, query } from '../../graphql/client'
import { Job, JobAttempt } from '../../graphql/types'

export interface DataSetFlightObject {
  flight: {
    deliveryId: string
    date: string
    comment: string

    dataSetId?: string

    CreatedBy: {
      firstName: string
      lastName: string
    }
    UpdatedBy: {
      firstName: string
      lastName: string
    }

    Delivery: {
      comment: string
      TargetDelivery: {
        date: string
      }
    }

    Organization: {
      name: string
    }
  }
  splitProcGroupsJob: {
    id: Job['id']
    isPending: Job['isPending']
    Status: Job['Status']
    LatestJobAttempt?: Pick<JobAttempt, 'attempt' | 'output'>
  }
}

const {
  selector: selectListDataSetFlights,
  refresh: refreshListDataSetFlights,
} = createAsyncSelector({
  resource: 'ListDataSetFlights',
  inputs: {
    dataSetId: (state) => state.router.params.dataSetId,
    pagingInfo: selectPagingInfo,
  },
  fetcher: async ({ dataSetId, pagingInfo: { page, pageSize } }) => {
    if (!dataSetId) {
      return
    }

    const offset = page * pageSize
    const limit = pageSize

    const {
      dataSetFlights,
      dataSetFlightsAgg: {
        aggregate: { count },
      },
    } = await query<{
      dataSetFlights: DataSetFlightObject[]
      dataSetFlightsAgg: {
        aggregate: {
          count: number
        }
      }
    }>({
      query: gql`
        query DATASET_FLIGHTS($dataSetId: Int!, $limit: Int!, $offset: Int!) {
          dataSetFlights: DataSet_Flight(
            where: { dataSetId: { _eq: $dataSetId } }
            limit: $limit
            offset: $offset
          ) {
            splitProcGroupsJob: SplitProcGroupsJob {
              id
              isPending
              Status {
                status
              }
              LatestJobAttempt {
                attempt
                output
              }
            }
            flight: Flight {
              deliveryId
              date
              comment

              CreatedBy {
                firstName
                lastName
              }
              UpdatedBy {
                firstName
                lastName
              }

              Delivery {
                comment
                TargetDelivery {
                  date
                }
              }
              Organization {
                name
              }
            }
          }
          dataSetFlightsAgg: DataSet_Flight_aggregate(
            where: { dataSetId: { _eq: $dataSetId } }
          ) {
            aggregate {
              count
            }
          }
        }
      `,
      variables: { dataSetId, limit, offset },
    })

    return { count, flights: dataSetFlights }
  },
})

export { selectListDataSetFlights, refreshListDataSetFlights }
