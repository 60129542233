import { NoteWithTemplate, OrgFeatureGeometry } from '../vvapi/models'

export default function notesToGeoJSON(
  notes: (NoteWithTemplate | undefined)[],
  selectedNoteId?: string,
  focusedNoteId?: string
) {
  const noteGeojson = notes.filter(Boolean).map((note) => {
    let priority = 0
    if (note!.id === selectedNoteId) {
      priority = 1
    }

    if (note!.id === focusedNoteId) {
      priority = 1
    }

    return {
      id: note!.id,
      type: 'Feature',
      geometry: note!.feature.geometry,
      properties: {
        priority,
        color: note?.pinColor ?? '#2196F3',
        id: note!.id,
        meta: note!.content,
      },
    } as GeoJSON.Feature<OrgFeatureGeometry>
  })

  return {
    features: noteGeojson,
    type: 'FeatureCollection',
  } as GeoJSON.FeatureCollection<OrgFeatureGeometry>
}
