import * as React from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'

import {
  Button,
  Icon,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'

import errorAlert from '../../../admin/errorAlert'
import { default as MaestroJobTable } from '../../../admin/Maestro/JobTable/JobTable'
import warnConfirm from '../../../admin/warnConfirm'
import {
  DEFAULT_PAGE_INFO,
  RouteParams,
  selectPagingInfo,
  url,
  urls,
} from '../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import * as api from '../../../graphql/api'
import { Model } from '../../../graphql/types'
import { connect } from '../../../redux/connect'
import { AppDispatchProps, RootStore } from '../../../redux/types'
import { ORDER } from '../../../UI/Table/orderRows'
import { PaginationChange, TableOrder } from '../../../UI/Table/types'
import { CopyDeliveryPage } from './CopyDeliveryPage'
import DeliveryStatus from './Status/DeliveryStatus'
import DeliveryUpload from './DeliveryUpload'
import ProcGroupsNavigation from './ProcessingGroups/ProcGroupsNavigation'
import {
  GetDeliveryData,
  refreshGetDelivery,
  selectGetDelivery,
} from './selectGetDelivery'
import {
  refreshListDeliveryJobs,
  selectListDeliveryJobs,
} from './selectListDeliveryJobs'
import { toggleWatchDelivery } from './toggleWatchDelivery'
import { Watchers } from './Watchers'
import { selectMe } from '../../../data/selectMe'
import { DeliveryFormDialog } from './DeliveryFormDialog'
import OrderPage from '../Order/OrderPage'

const ADMIN_TABS = [
  'proc-groups',
  'status',
  'maestro',
  'upload-final-products',
  'copy',
]

const DATA_PROCESSOR_TABS = ['proc-groups', 'upload-final-products']

interface State {
  editDialogOpen: boolean
}

class EditDelivery extends React.PureComponent<
  RouteComponentProps<RouteParams> & ReduxProps,
  State
> {
  state: Readonly<State> = { editDialogOpen: false }

  getDeliveryJobsInfo = () => {
    return this.props.deliveryJobs.data
      ? this.props.deliveryJobs.data.info
      : { ...DEFAULT_PAGE_INFO, order: [] }
  }

  getBackUrl = () => {
    const delivery = this.props.delivery.data

    if (!delivery) {
      return
    }

    const {
      TargetDelivery: { orderId },
    } = delivery

    return url(urls.editOrder, { orderId })
  }

  handleToggleWatch = async (userId: string, watch: boolean) => {
    if (!this.props.delivery.data) {
      return
    }

    await toggleWatchDelivery(this.props.delivery.data.id, userId, watch)
    refreshGetDelivery()
  }

  render() {
    return (
      <>
        {this.props.delivery.data && (
          <DeliveryFormDialog
            open={this.state.editDialogOpen}
            delivery={this.props.delivery.data}
            onSave={this.handleUpdate}
            onDelete={this.handleDelete}
            onClose={() => this.setState({ editDialogOpen: false })}
          />
        )}
        <OrderPage
          title={`Edit Delivery`}
          backTo={this.getBackUrl}
          backToTitle="Order"
        >
          <div id="NewDelivery" className="MapDataContainerSmall">
            {this.renderEditMapDelivery()}
          </div>
        </OrderPage>
      </>
    )
  }

  getCurrentTab = () => {
    const { match, location } = this.props

    return location.pathname.slice(match.url.length + 1).split('/')[0]
  }

  renderEditMapDelivery = () => {
    const { user } = this.props
    const currentTab = this.getCurrentTab()
    const TABS = user?.roles.includes('admin')
      ? ADMIN_TABS
      : DATA_PROCESSOR_TABS

    return (
      <div style={{ margin: 0, padding: 16, width: '100%' }}>
        <AsyncSelectorStatusOverlay requests={this.props.delivery}>
          <Paper sx={{ p: 2, marginBottom: 2 }}>
            <Stack
              direction="row"
              sx={{ width: '100%' }}
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="flex-start" spacing={1}>
                <Typography variant="h5">
                  {this.props.delivery.data?.comment}
                </Typography>
                <IconButton
                  onClick={() => this.setState({ editDialogOpen: true })}
                >
                  <Icon sx={{ fontSize: '16px !important' }}>edit</Icon>
                </IconButton>
              </Stack>
              <Watchers
                watched={this.props.delivery.data}
                onToggleWatch={this.handleToggleWatch}
              />
            </Stack>
          </Paper>
          <div className="Paper">
            <Tabs value={currentTab}>
              {TABS.map((tab) => (
                <Tab
                  style={{ padding: 8, minWidth: 50, minHeight: 20 }}
                  key={tab}
                  label={tab.split('-').join(' ')}
                  value={tab}
                  onClick={() => this.setTab(tab)}
                  component={Button}
                />
              ))}
            </Tabs>
          </div>
          {this.renderTab()}
        </AsyncSelectorStatusOverlay>
      </div>
    )
  }

  setTab = (tab: string) => {
    this.props.history.replace(`${this.props.match.url}/${tab}`)
  }

  renderTab = () => {
    const { match } = this.props

    return (
      <Switch>
        <Route
          exact
          path={`${match.path}/status`}
          component={this.renderStatus}
        />
        <Route
          exact
          path={`${match.path}/maestro`}
          component={this.renderMaestro}
        />
        <Route
          path={`${match.path}/proc-groups`}
          component={ProcGroupsNavigation}
        />
        <Route
          exact
          path={`${match.path}/upload-final-products`}
          component={DeliveryUpload}
        />
        <Route
          exact
          path={`${match.path}/upload-raw-data`}
          component={this.renderUploadRawData}
        />
        <Route
          exact
          path={`${match.path}/copy`}
          component={this.renderCopyDelivery}
        />
        <Route exact path="">
          <Redirect to={`${match.url}/proc-groups`} />
        </Route>
      </Switch>
    )
  }

  updateURL = (options: {
    page?: number
    pageSize?: number
    orderBy?: string
  }) => {
    const {
      pageSize: dPageSize,
      page: dPage,
      order,
    } = this.getDeliveryJobsInfo()

    const {
      page = dPage,
      pageSize = dPageSize,
      orderBy = ORDER.serialize(order),
    } = options

    this.props.history.replace(
      url({ url: this.props.location.pathname }, this.props.match.params, {
        page,
        pageSize,
        orderBy,
      })
    )
  }

  renderStatus = () => <DeliveryStatus />

  renderCopyDelivery = () => <CopyDeliveryPage />

  handlePaginationChange = (pagination: PaginationChange) =>
    this.updateURL(pagination)

  handleOrderChange = (order: TableOrder) =>
    this.updateURL({ page: 0, orderBy: ORDER.serialize(order) })

  renderMaestro = () => {
    const data = this.props.deliveryJobs.data
      ? this.props.deliveryJobs.data.data
      : []

    const info = this.getDeliveryJobsInfo()

    return (
      <div className="Paper" style={{ position: 'relative' }}>
        <AsyncSelectorStatusOverlay requests={this.props.deliveryJobs}>
          <MaestroJobTable
            order={info.order}
            rows={data}
            pagination={info}
            onPaginationChange={this.handlePaginationChange}
            onOrderChange={this.handleOrderChange}
            selectable
            onRefresh={refreshListDeliveryJobs}
          />
        </AsyncSelectorStatusOverlay>
      </div>
    )
  }

  renderUploadRawData = () => {
    return (
      <div className="Paper">
        <Button disabled>Coming Soon</Button>
      </div>
    )
  }

  handleUpdate = async ({ comment }: GetDeliveryData) => {
    const { deliveryId } = this.props.match.params
    try {
      await api.delivery.update<Model>({
        pk: { id: deliveryId! },
        input: {
          comment,
        },
      })

      this.setState({ editDialogOpen: false }, () => {
        refreshGetDelivery()
      })
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Create Delivery', message, this.state)
    }
  }

  handleDelete = async () => {
    const { deliveryId } = this.props.match.params

    try {
      if (
        await warnConfirm({
          title: 'Confirm Delete',
          message:
            'Are you sure you would like to delete this delivery (no undo)?',
        })
      ) {
        await api.delivery.delete<Model>({
          pk: { id: deliveryId! },
        })

        this.setState({ editDialogOpen: false }, () => {
          const url = this.getBackUrl()
          if (url) {
            this.props.history.replace(url)
          } else {
            this.props.history.replace(urls.mapView.url)
          }
        })
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'

      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }

      softError(e, 'Failed to Delete Delivery', message, this.state)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'EditDelivery',
    },
  })

const mapState = (state: RootStore) => ({
  tab: state.router.params.tab,
  preferredLanguage: selectPreferredLanguage(state),
  delivery: selectGetDelivery(state),

  deliveryJobs: selectListDeliveryJobs(state),
  selectedJobId: state.router.searchParams['selected-job'],
  pagingInfo: selectPagingInfo(state),
  user: selectMe(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(EditDelivery)
)
