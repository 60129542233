import { default as Icon } from '@mui/material/Icon'
import { default as Input } from '@mui/material/Input'
import { default as InputLabel } from '@mui/material/InputLabel'
import * as React from 'react'
import { randomColor } from './ColorPicker'
import FormColorPicker from './FormColorPicker'
import TooltipIconButton from './TooltipIconButton'

interface NameAndValue {
  name: string
  value?: string | null
}

interface Props extends NameAndValue {
  label: string
  required?: boolean
  onChange(e: NameAndValue): void
}

export class FormColorStopPicker extends React.PureComponent<Props> {
  render() {
    const { label, value, required = false } = this.props

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <InputLabel required={required}>{label}</InputLabel>
          <TooltipIconButton
            disabled={!!value}
            tooltipProps={{
              placement: 'right',
            }}
            title={`Add a Stop!`}
            onClick={this.handleAddStop}
          >
            <Icon fontSize="small">add_circle</Icon>
          </TooltipIconButton>
        </div>

        {value && (
          <div className="multi-text-fields">
            <Input
              fullWidth
              readOnly
              autoComplete="off"
              startAdornment={
                <div style={{ margin: '0px 8px' }}>
                  <FormColorPicker
                    name="__stop__"
                    value={value}
                    onChange={this.handleStopColorChange}
                  />
                </div>
              }
              endAdornment={
                <TooltipIconButton
                  tooltipProps={{
                    placement: 'right',
                  }}
                  title={`Remove !`}
                  onClick={this.handleRemoveStop}
                >
                  <Icon fontSize="small">close</Icon>
                </TooltipIconButton>
              }
            />
          </div>
        )}
      </div>
    )
  }

  onChange = (value?: string) => {
    const { name, onChange } = this.props

    onChange({
      name,
      value,
    })
  }

  handleAddStop = () => {
    this.onChange(randomColor())
  }

  handleStopColorChange = (e: any) => {
    this.onChange(e.value)
  }

  handleRemoveStop = () => {
    this.onChange()
  }
}
