import * as React from 'react'
import { connect } from 'react-redux'
import { UrlInfo, urlMatch } from '../appNavigation/urls'
import { Icon, ListItemIcon, ListItemText } from '@mui/material'
import { RootStore } from '../redux/types'
import WrappingMenuItem from '../UI/WrappingMenuItem'

interface Props {
  title: string
  icon: React.ReactNode | string
  exact?: boolean
  path: UrlInfo
  disabled?: boolean
  onClick: () => void
}

/**
 * Displays a MenuItem that disables when the path is the current page.
 */
class HideOnPageMenuItem extends React.Component<Props & ReduxProps> {
  render() {
    const { title, icon, onClick, disabled } = this.props

    return (
      <WrappingMenuItem onClick={onClick} disabled={this.isDisabled() || disabled}>
        <ListItemIcon>
          {typeof icon === 'string' ? <Icon>{icon}</Icon> : icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </WrappingMenuItem>
    )
  }

  isDisabled = () => {
    const { path, routerPath } = this.props

    if (!routerPath) {
      return false
    }

    return urlMatch(path, routerPath)
  }
}

const mapState = (state: RootStore) => ({
  routerPath: state.router.path,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(HideOnPageMenuItem)
