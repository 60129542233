import * as shpwrite from '@mapbox/shp-write'
import JSZip from 'jszip'
import centerOfMass from '@turf/center-of-mass'
import { lookup } from '@esri/proj-codes'
import proj4 from 'proj4'
import { Position } from '@turf/helpers'

const projectGeometries = (
  featureCollection: GeoJSON.FeatureCollection<
    GeoJSON.MultiPolygon | GeoJSON.Polygon
  >,
  destinationEpsg: number
) => {
  const geometries: (Position[][] | Position[][][])[] = []

  if (destinationEpsg === 4326) {
    return featureCollection.features.map((f) => f.geometry.coordinates)
  }

  proj4.defs(
    'EPSG:4326',
    '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'
  )
  proj4.defs(`EPSG:${destinationEpsg}`, lookup(destinationEpsg).wkt)

  const projectFunc = proj4('EPSG:4326', `EPSG:${destinationEpsg}`).forward

  for (const feature of featureCollection.features) {
    const geometry = feature.geometry
    if (geometry.type === 'Polygon') {
      const projectedCoordinates: Position[][] = []
      for (const coordinate of geometry.coordinates) {
        const projectedRings: Position[] = []
        for (const ring of coordinate) {
          projectedRings.push(projectFunc(ring))
        }
        projectedCoordinates.push(projectedRings)
      }
      geometries.push(projectedCoordinates)
    } else if (geometry.type === 'MultiPolygon') {
      const projectedCoordinates: Position[][][] = []

      for (const poly of geometry.coordinates) {
        const projectedPolies: Position[][] = []
        for (const coordinate of poly) {
          const projectedRings: Position[] = []
          for (const ring of coordinate) {
            projectedRings.push(projectFunc(ring))
          }
          projectedPolies.push(projectedRings)
        }
        projectedCoordinates.push(projectedPolies)
      }
      geometries.push(projectedCoordinates)
    }
  }

  return geometries
}

const getEPSG = (
  geojson: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon | GeoJSON.Polygon>
) => {
  const center = centerOfMass(geojson)
  if (center && center.geometry) {
    const [longitude, latitude] = center.geometry.coordinates

    return (
      32700 -
      Math.round((45 + latitude) / 90) * 100 +
      Math.round((183 + longitude) / 6)
    )
  }
  return 4326
}

export const geojsonToShapefile = async (
  geojson: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon | GeoJSON.Polygon>,
  fileName: string
) => {
  const zip = new JSZip()

  const epsg = getEPSG(geojson)
  const wkt = lookup(epsg).wkt

  const geometries = projectGeometries(geojson, epsg)

  const data = geojson.features.map((f) => f.properties as object)

  shpwrite.write(data, 'POLYGON', geometries, function (err, files) {
    if (err) {
      throw err
    }

    zip.file(fileName + '.shp', files.shp.buffer, { binary: true })
    zip.file(fileName + '.shx', files.shx.buffer, { binary: true })
    zip.file(fileName + '.dbf', files.dbf.buffer, { binary: true })
    zip.file(fileName + '.prj', wkt)
  })

  return zip.generateAsync({ type: 'blob' })
}
