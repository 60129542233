import * as React from 'react'

import {
  DEFAULT_PAGE_INFO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../../appNavigation/urls'
import { selectUsersById } from '../../../data/selectUserList'
import { FeatureFlag } from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import { useRedux } from '../../../hooks/useRedux'
import { useHistory, useSearchParams } from '../../../hooks/useRouter'
import { createTableActionIconButton } from '../../../UI/Table/createTableActionIconButton'
import { ORDER } from '../../../UI/Table/orderRows'
import { Table } from '../../../UI/Table/Table'
import {
  PaginationChange,
  TableAction,
  TableOrder,
} from '../../../UI/Table/types'
import { AdminPage } from '../AdminPage'
import { tableFormatter } from './featureFlagTableFormatters'
import { deleteFeatureFlag, fetchFeatureFlags } from './queries'

export const FeatureFlags = () => {
  const {
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    order = '',
    filter = '',
  } = useSearchParams()
  const [state] = useRedux()
  const history = useHistory()

  const users = selectUsersById(state)

  const [fetchedFeatureFlags, refresh] = useAsync(fetchFeatureFlags(users), [
    Number(page),
    Number(pageSize),
    order,
    filter,
  ])

  const updateSearchParams = (update: {
    page?: number
    pageSize?: number
    order?: string
    filter?: string
  }) => {
    history.push(
      url(
        urls.admin_admins,
        {},
        {
          page: Number(page),
          pageSize: Number(pageSize),
          order,
          filter,
          ...update,
        }
      )
    )
  }

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateSearchParams(pagination)

  // tslint:disable-next-line: no-shadowed-variable
  const handleOrderChange = (order: TableOrder) => {
    updateSearchParams({ order: ORDER.serialize(order) })
  }

  const rows = React.useMemo(
    () => fetchedFeatureFlags?.result?.data ?? [],
    [fetchedFeatureFlags?.result?.data]
  )
  const info = React.useMemo(
    () =>
      fetchedFeatureFlags?.result?.info ?? { ...DEFAULT_PAGE_INFO, order: [] },
    [fetchedFeatureFlags?.result?.info]
  )

  const { newBtn, editBtn, deleteBtn, refreshBtn } = tableActions(
    history,
    refresh
  )

  return (
    <AdminPage title="Admins">
      <div className="Paper">
        <Table
          title="Feature Flags"
          rows={rows}
          actions={[newBtn, editBtn, deleteBtn, refreshBtn]}
          formatter={tableFormatter}
          order={info.order}
          onOrderChange={handleOrderChange}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pagination={info}
          onPaginationChange={handlePaginationChange}
        />
      </div>
    </AdminPage>
  )
}

const tableActions = (
  history: ReturnType<typeof useHistory>,
  refresh: () => void
) => {
  const refreshBtn: TableAction<FeatureFlag> = {
    button: createTableActionIconButton({
      key: 'autorenew',
      title: 'Refresh',
      icon: 'autorenew',
    }),
    action: refresh,
  }

  const newBtn: TableAction<FeatureFlag> = {
    button: createTableActionIconButton({
      key: 'add',
      title: 'New Feature Flag',
      icon: 'add',
    }),
    action: () => history.push(url(urls.admin_feature_flags_new)),
  }

  const editBtn: TableAction<FeatureFlag> = {
    selectionRequired: true,
    button: createTableActionIconButton({
      key: 'edit',
      title: 'Edit Feature Flag',
      icon: 'edit',
    }),
    action: ([{ id }]) =>
      history.push(url(urls.admin_features_flags_edit, { id })),
  }

  const deleteBtn: TableAction<FeatureFlag> = {
    selectionRequired: true,
    button: createTableActionIconButton({
      key: 'delete',
      title: 'Delete Feature Flag',
      icon: 'delete',
    }),
    action: async (rows) => {
      for (const row of rows) {
        await deleteFeatureFlag(row)
      }
      refresh()
    },
  }

  return { newBtn, editBtn, deleteBtn, refreshBtn }
}
