import applyNamespace from 'redux-ts-helpers/lib/applyNamespace'
import createAction from 'redux-ts-helpers/lib/createAction'
import createReducer from 'redux-ts-helpers/lib/createReducer'
import {
  ProductSettings,
  ProductSettingsState,
  VisualizationMode,
} from './types'

// Actions
export const constants = applyNamespace('ProductSettings', {
  updateProductSettings: 0,
  toggleVisualization: 0,
  resetProductSettings: 0,
  updateCoverage: 0,
  clearCoverage: 0,
})

interface UpdateProductSettings {
  productId?: string
  settings: ProductSettings
}
interface UpdateCoverage {
  productId: string
  groupId: string
  date: string
  coverage: number
}

interface ClearCoverage {
  productId: string
  groupId: string
  date: string
}

interface ToggleVisualization {
  productId: string
  currentVisualization: ProductSettings['visualization']
}

export const updateProductSettings = createAction<UpdateProductSettings>(
  constants.updateProductSettings
)

export const updateCoverage = createAction<UpdateCoverage>(
  constants.updateCoverage
)

export const clearCoverage = createAction<ClearCoverage>(
  constants.clearCoverage
)

export const toggleVisualization = createAction<ToggleVisualization>(
  constants.toggleVisualization
)

interface ResetProductSettings {
  productId?: string
}

export const resetProductSettings = createAction<ResetProductSettings>(
  constants.resetProductSettings
)

export const initialState: ProductSettingsState = {
  userProductSettings: {},
}

export const reducer = createReducer(initialState, {
  [constants.clearCoverage]: (
    state,
    { payload }: ReturnType<typeof clearCoverage>
  ) => {
    const { productId, groupId, date } = payload
    const coverage = {
      ...(state.userProductSettings?.[productId]?.coverage ?? {}),
    }

    delete coverage[`${groupId}_${date}`]

    return {
      ...state,
      userProductSettings: {
        ...(state.userProductSettings ?? {}),
        [productId]: {
          ...(state.userProductSettings?.[productId] ?? {}),
          coverage,
        },
      },
    }
  },
  [constants.updateCoverage]: (
    state,
    { payload }: ReturnType<typeof updateCoverage>
  ) => {
    const { productId, groupId, date, coverage } = payload

    return {
      ...state,
      userProductSettings: {
        ...(state.userProductSettings ?? {}),
        [productId]: {
          ...(state.userProductSettings?.[productId] ?? {}),
          coverage: {
            ...(state.userProductSettings?.[productId]?.coverage ?? {}),
            [`${groupId}_${date}`]: coverage,
          },
        },
      },
    }
  },
  [constants.updateProductSettings]: (
    state,
    { payload }: ReturnType<typeof updateProductSettings>
  ) => {
    const { productId, settings } = payload

    if (!productId) {
      return state
    }

    const productSettings = state.userProductSettings[productId]

    if (
      settings.numberOfGroups !== undefined &&
      productSettings?.numberOfGroups !== settings.numberOfGroups
    ) {
      settings.isolatedStops = []
    }

    const colorIdByVisualization = productSettings?.colorIdByVisualization

    // if vis is new
    if (
      !state.userProductSettings[productId] ||
      state.userProductSettings[productId]?.visualization ||
      (settings.visualization &&
        settings.visualization !==
          state.userProductSettings[productId]?.visualization)
    ) {
      return {
        ...state,
        userProductSettings: {
          ...state.userProductSettings,
          [productId]: {
            ...productSettings,
            ...settings,
            previousVisualization:
              state.userProductSettings[productId]?.visualization,
            colorIdByVisualization: {
              ...colorIdByVisualization,
              ...settings.colorIdByVisualization,
            },
          },
        },
      }
    }

    return {
      ...state,
      userProductSettings: {
        ...state.userProductSettings,
        [productId]: {
          ...productSettings,
          ...settings,
          colorIdByVisualization: {
            ...colorIdByVisualization,
            ...settings.colorIdByVisualization,
          },
        },
      },
    }
  },
  [constants.toggleVisualization]: (
    state,
    { payload }: ReturnType<typeof toggleVisualization>
  ) => {
    const { productId, currentVisualization } = payload

    if (
      state.userProductSettings?.[productId]?.previousVisualization &&
      // sometimes the 'previousVisualization' is set the same as the current,
      // which causes it to get stuck
      state.userProductSettings[productId]?.previousVisualization !==
        currentVisualization
    ) {
      return {
        ...state,
        userProductSettings: {
          ...(state.userProductSettings ?? {}),
          [productId]: {
            ...(state.userProductSettings[productId] ?? {}),
            visualization:
              state.userProductSettings[productId]?.previousVisualization,
            previousVisualization:
              state.userProductSettings[productId]?.visualization,
          },
        },
      }
    }
    if (currentVisualization === 'relative') {
      return {
        ...state,
        userProductSettings: {
          ...(state.userProductSettings ?? {}),
          [productId]: {
            ...(state.userProductSettings[productId] ?? {}),
            visualization: 'absolute' as VisualizationMode,
            previousVisualization:
              state.userProductSettings[productId]?.visualization,
          },
        },
      }
    }

    return {
      ...state,
      userProductSettings: {
        ...(state.userProductSettings ?? {}),
        [productId]: {
          ...(state.userProductSettings[productId] ?? {}),
          visualization: 'relative' as VisualizationMode,
          previousVisualization:
            state.userProductSettings[productId]?.visualization,
        },
      },
    }
  },
  [constants.resetProductSettings]: (
    state,
    { payload }: ReturnType<typeof resetProductSettings>
  ) => {
    const { productId } = payload

    if (!productId) {
      return state
    }

    return {
      ...state,
      userProductSettings: {
        ...state.userProductSettings,
        [productId]: {},
      },
    }
  },
})
