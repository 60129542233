import { client, gql } from '../../../../graphql/client'

export const copyDelivery = async (
  sourceDeliveryId: string,
  destinationDeliveryId: string,
  parcelMap: Record<number, number>
) => {
  await client.request({
    query: gql`
      mutation CREATE_COPY_DELIVERY_JOB(
        $sourceDeliveryId: String!
        $destinationDeliveryId: String!
        $parcelMap: jsonb!
      ) {
        insert_maestro_Job_one(
          object: {
            taskName: "copy-delivery"
            pipelineId: "00000000-0000-0000-0000-000000000000"
            input: {
              sourceDeliveryId: $sourceDeliveryId
              destinationDeliveryId: $destinationDeliveryId
              parcelMap: $parcelMap
            }
            JobTags: {
              data: [
                { key: "sourceDeliveryId", value: $sourceDeliveryId }
                { key: "destinationDeliveryId", value: $destinationDeliveryId }
              ]
            }
          }
        ) {
          id
        }
      }
    `,
    variables: { sourceDeliveryId, destinationDeliveryId, parcelMap },
  })
}
