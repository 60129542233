import * as React from 'react'

export const useConfirmBrowserExit = (enabled = true) => {
  React.useEffect(() => {
    function listener(e: BeforeUnloadEvent) {
      if (enabled) {
        e.preventDefault()
        e.returnValue = true;
      }
    }

    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [enabled])
}
