import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'
import { SAMPLE_PLAN_POINTS } from '../../../../pages/SamplePlan/hooks/util/sampleLayerHelper'
import { FocusedPoint } from '../../../../postgis/types'
import { setFocusedPoint } from '../../../../postgis/actions'

export class SampleActionGenerator implements IMapActionGenerator {
  key = 'sample'
  priority = Priority.Sample

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
  }: IMapActionGeneratorParams): IMapAction[] {
    // Find the qualifying features.
    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) => f.layer.id === SAMPLE_PLAN_POINTS
    )

    // Return array of functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q, i) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        if (i === 0) {
          const properties = { 
            ...q.properties,
            mapSourceId: q.sourceLayer,
          }
          const focusedPoint: Writable<FocusedPoint> = {
            properties,
            type: 'Feature',
            geometry: q.geometry as GeoJSON.Point,
          }

          dispatch(setFocusedPoint(focusedPoint))
        }
      },
    }))
  }
}
