import { DeepPartial } from 'redux'
import { objectOmit } from '../util/objectOmit'
import { objectPick } from '../util/objectPick'
import { latestKnownVersion } from './migrateStore'
import { RootStore } from './types'

/**
 * This is a huge list of state keys which we
 * want to save to the database constantly;
 * It should be as light-weight as possible
 */
export function serializeRedux(
  state: DeepPartial<RootStore>
): DeepPartial<RootStore> {
  const {
    app = {},
    postgis = {},
    colorScheme = {},
    userSelection = {},
    reports = {},
    ProductSettings = {},
    preferences = {},
    notes = {},
  } = state

  return {
    app: {
      ...objectPick(app, [
        // 'storeVersion',
        'isPreferencesDialogOpen',
      ]),
      // always pass latestKnownVersion
      storeVersion: latestKnownVersion,
    },
    postgis: objectPick(postgis, [
      // 'parcelFilterInfo',
      // 'focusedPoint',
      // 'isLayerDrawerOpen',
      // 'userPosition',
      // 'userPositionError',
      'bounds',
      // 'pitch',
      // 'bearing',
      // 'isPollingUserPosition',
      // 'focusedBlockId',
    ]),
    colorScheme: objectPick(colorScheme, ['drawerProductType', 'showStats']),
    reports: objectPick(reports, ['selectedGroupId', 'reportFormValues']),
    userSelection: {
      ...objectPick(userSelection, [
        'expandedGroupIds',
        'selectedBackgroundProductId',
        // 'selectedDeliveryId',
        'selectedFlightDate',
        'selectedGroupId',
        // 'selectedGroupName',
        'selectedLegendProductId',
        'selectedOrganizationId',
        'selectedParcelIds',
        'selectedLayerByGroup'
      ]),
      // if the user unselects everything the next time they
      // visit the app they may be confused that no data is selected.
      // So we set [] to undefined, allowing the app to select the
      // first product in the list by default.
      selectedProductIds:
        userSelection.selectedProductIds?.length === 0
          ? undefined
          : userSelection.selectedProductIds,
    },
    ProductSettings: objectPick(ProductSettings, ['userProductSettings']),
    preferences: objectOmit(preferences, [
      'gpsOffset',
      'showSpeedTracker',
      'isSpeedTrackerSoundEnabled',
      'showGPS',
    ]),
    notes: objectPick(notes, ['selectedProjectIds']),
  }
}
