import filterTruthy from '../../util/filterTruthy'
import { OrderBy, TableFormatter, TableOrder } from './types'

export function orderRows<T>(
  rows: T[],
  tableFormatter: TableFormatter<T>[],
  order: TableOrder
) {
  const sorters = order
    .map(({ index, direction }) => {
      const formatter = tableFormatter[index]

      const compare = formatter?.compare

      if (compare) {
        return (a: T, b: T) =>
          direction === 'asc' ? compare(a, b) : compare(b, a)
      }

      return null
    })
    .filter(filterTruthy)

  return rows.slice().sort((a, b) => {
    let result = 0
    for (const compare of sorters) {
      result = compare(a, b)

      if (result !== 0) {
        return result
      }
    }

    return result
  })
}

export const ORDER = {
  serialize(order: TableOrder) {
    return order
      .map(({ index, direction }) => `${index}:${direction}`)
      .join(',')
  },

  deserialize(str: string) {
    return str
      ? str.split(',').map((o) => {
          const [index, direction] = o.split(':')

          return { direction, index: Number(index) } as OrderBy
        })
      : ([] as TableOrder)
  },
}

export function orderBy<T>(
  tableFormatter: TableFormatter<T>[],
  order: TableOrder
) {
  const result = order
    .map(({ index, direction }) => {
      const formatter = tableFormatter[index]

      if (formatter?.orderBy) {
        return formatter.orderBy(direction)
      }

      return null
    })
    .filter(filterTruthy)

  return result.length > 0 ? `[\n${result.join(',\n')}\n]` : undefined
}
