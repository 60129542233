import i18n from '../../../../i18n'
import { TableFormatter } from '../../../../UI/Table/types'
import { ListOrdersData } from '../selectListOrders'

export const ORDER_LIST_SEARCH = 'ORDER-LIST-SEARCH'

const commentColumn: TableFormatter<ListOrdersData> = {
  header: () => 'Comment',
  data: ({ comment }) => comment,
  orderBy: (direction) => `
    {
      comment: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    comment: {
      [`_${op}`]: value
    }
  }),
  filterId: ORDER_LIST_SEARCH
}

const flightDateColumn: TableFormatter<ListOrdersData> = {
  header: () => 'Last Flight Date',
  data: ({ TargetDeliveries_aggregate }) =>
    !!TargetDeliveries_aggregate?.aggregate.max.date
      ? i18n.toDateShort(TargetDeliveries_aggregate?.aggregate.max.date)
      : '-',
  orderBy: (direction) => `
    {
        TargetDeliveries_aggregate: {
          max: {
            date: ${direction}
          }
        }
    }
    `,
}

const createdByColumn: TableFormatter<ListOrdersData> = {
  header: () => 'Created By',
  data: ({ CreatedBy }) =>
    `${CreatedBy.firstName} ${CreatedBy.lastName} | ${CreatedBy.email}`,
  orderBy: (direction) => `
      {
        CreatedBy: {
            firstName: ${direction}
        }
      },
      {
        CreatedBy: {
            lastName: ${direction}
        }
      }
    `,
}
const createdAtColumn: TableFormatter<ListOrdersData> = {
  header: () => 'Created At',
  data: ({ createdAt }) => i18n.toDateTimeShort(createdAt),
  orderBy: (direction) => `
      {
        createdAt: ${direction}
      }
    `,
}

export const tableFormatter = [
  commentColumn,
  flightDateColumn,
  createdByColumn,
  createdAtColumn
]
