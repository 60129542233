import './DataSetDeliveryLinkDialog.scss'

import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputAdornment,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from '@mui/material'

import { url, urls } from '../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectOrganizationList } from '../../data/selectOrganizationList'
import { Organization, TargetDelivery } from '../../graphql/types'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { RootStore } from '../../redux/types'
import fuzzySearch from '../../util/fuzzySearch'
import { isNumericIdEqual } from '../../util/isNumericIdEqual'
import { Column } from '../UI/Column'
import { Row } from '../UI/Row'
import {
  selectGetOrganizationTargetDeliveries,
  selectOrder,
  selectOrganizationTargetDeliveriesByPK,
  selectSelectedTargetDelivery,
  selectTargetDeliveryPackages,
  selectTargetDeliveryParcels,
} from './selectGetOrganizationTargetDeliveries'

interface State {
  organizationFilter: string
}

interface Props {
  open: boolean
  onOrganizationSelected: (organizationId: string) => void
  onTargetDeliverySelected: (targetDeliveryPK: string) => void
  onLinkDelivery: (delivery: TargetDelivery) => void
  onClose?: () => void
}

class DataSetDeliveryLinkDialog extends React.Component<
  Props & ReduxProps & RouteComponentProps,
  State
> {
  state: State = {
    organizationFilter: '',
  }

  render = () => {
    const {
      open,
      onClose,
      onOrganizationSelected,
      organizationsSelector,
      onTargetDeliverySelected,
      targetDeliverySelector,
      targetDeliveriesByPK,
      targetDeliveryPackages,
      targetDeliveryOrder,
      targetDeliveryParcels,
      selectedTargetDelivery,
      searchParams: { organizationId, orderId, targetDeliveryDate },
    } = this.props

    const selectedTargetDeliveryPK = `${orderId}/${targetDeliveryDate}`

    const organizations = (organizationsSelector.data ?? []) as Organization[]

    const { organizationFilter } = this.state

    const orgFilterAdornmentIcon = organizationFilter ? 'cancel' : 'search'

    const filteredOrganizations = (
      organizationFilter
        ? organizations.filter(({ name }) =>
          fuzzySearch(organizationFilter, name)
        )
        : organizations
    ).sort((orgA, orgB) => orgA.name.localeCompare(orgB.name))

    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="link-delivery-dialog-title"
      >
        <DialogTitle id="link-delivery-dialog-title">Link Delivery</DialogTitle>
        <DialogContent>
          <Row
            style={{ alignItems: 'stretch', justifyContent: 'stretch' }}
            className="miller-columns"
          >
            <Column className="miller-column">
              <Typography variant="subtitle1">Organization: </Typography>
              <TextField
                fullWidth
                placeholder="Filter!"
                value={organizationFilter}
                onChange={this.handleOrganizationFilterChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon onClick={this.handleOrganizationFilterClear}>
                        {orgFilterAdornmentIcon}
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
              <MenuList className="miller-column-list">
                {filteredOrganizations.map(({ name, id }) => (
                  <MenuItem
                    className={
                      isNumericIdEqual(organizationId, id)
                        ? 'selected-item'
                        : ''
                    }
                    key={id}
                    value={id}
                    onClick={() => {
                      onOrganizationSelected(id)
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </MenuList>
            </Column>
            <div className="miller-column-divider" />
            <Column className="miller-column">
              <Typography variant="subtitle1">Target Delivery Date:</Typography>
              <AsyncSelectorStatusOverlay
                style={{ width: '100%', height: '100%' }}
                requests={targetDeliverySelector}
              >
                <MenuList className="miller-column-list">
                  {targetDeliveriesByPK &&
                    Object.entries(targetDeliveriesByPK).map(
                      ([pk, targetDelivery]) => (
                        <MenuItem
                          className={
                            selectedTargetDeliveryPK === pk
                              ? 'selected-item'
                              : ''
                          }
                          key={pk}
                          value={pk}
                          onClick={() => {
                            onTargetDeliverySelected(pk)
                          }}
                        >
                          {(targetDelivery as TargetDelivery).date}
                        </MenuItem>
                      )
                    )}
                </MenuList>
              </AsyncSelectorStatusOverlay>
            </Column>
            <div className="miller-column-divider" />
            <Column className="miller-column">
              <Typography variant="subtitle1">Order Details:</Typography>
              <AsyncSelectorStatusOverlay
                style={{ width: '100%', height: '100%' }}
                requests={targetDeliverySelector}
              >
                {targetDeliveryOrder &&
                  targetDeliveryPackages &&
                  targetDeliveryParcels && (
                    <Column
                      style={{
                        justifyContent: 'space-between',
                        height: '100%',
                      }}
                    >
                      <Column className="order-details-column">
                        <div>
                          <Typography variant="body2">
                            Target Delivery Status:{' '}
                          </Typography>
                          <Typography variant="body1">
                            {selectedTargetDelivery!.Delivery
                              ? 'Delivered'
                              : 'Undelivered'}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="body2">Comment: </Typography>
                          <Typography variant="body1">
                            {targetDeliveryOrder.comment}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="body2">
                            Salesforce Quote Id:{' '}
                          </Typography>
                          <Typography variant="body1">
                            {targetDeliveryOrder.quoteId ?? 'NULL'}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="body2">Packages: </Typography>
                          <Typography variant="body1">
                            {targetDeliveryPackages.join(', ')}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="body2">Groups: </Typography>
                          <Typography variant="body1">
                            {targetDeliveryParcels.join(', ')}
                          </Typography>
                        </div>
                      </Column>
                      <Row
                        style={{
                          alignSelf: 'flex-end',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          onClick={this.handleViewOrder}
                          style={{ marginRight: 5 }}
                        >
                          View Order
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleLinkDelivery}
                        >
                          Link Delivery
                        </Button>
                      </Row>
                    </Column>
                  )}
              </AsyncSelectorStatusOverlay>
            </Column>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{i18n.t(keys.generic.close)}</Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleViewOrder = () => {
    const { targetDeliveryOrder } = this.props
    if (targetDeliveryOrder) {
      this.props.history.push(
        url(urls.editOrder, { orderId: targetDeliveryOrder.id }, {})
      )
    }
  }

  handleLinkDelivery = () => {
    const { selectedTargetDelivery, onLinkDelivery } = this.props
    if (selectedTargetDelivery) {
      onLinkDelivery(selectedTargetDelivery)
    }
  }

  handleOrganizationFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      organizationFilter: e.target.value,
    })
  }

  handleOrganizationFilterClear = () => {
    this.setState({ organizationFilter: '' })
  }
}

const mapState = (state: RootStore) => ({
  dataSetId: state.router.params.dataSetId,
  searchParams: state.router.searchParams,
  organizationsSelector: selectOrganizationList(state),
  targetDeliverySelector: selectGetOrganizationTargetDeliveries(state),
  targetDeliveriesByPK: selectOrganizationTargetDeliveriesByPK(state),
  targetDeliveryPackages: selectTargetDeliveryPackages(state),
  targetDeliveryParcels: selectTargetDeliveryParcels(state),
  targetDeliveryOrder: selectOrder(state),
  selectedTargetDelivery: selectSelectedTargetDelivery(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(
  withRouter(DataSetDeliveryLinkDialog)
)
