import {
  MapColorProfileData,
  MapLayerData,
  MapLayerDefData,
} from '../data/types'
import { DEFAULT_COVERAGE } from '../ProductSettings/constants'
import { ProductSettings } from '../ProductSettings/store/types'
import * as bbox from '../util/bbox'
import metersToPixelsAtMaxZoom from '../util/metersToPixelsAtMaxZoom'
import { colorProfileToPaint } from './paint/colorProfileToPaint'

export interface MakePaintProps {
  layer: MapLayerData
  product: MapLayerDefData
  settings: ProductSettings
  color?: MapColorProfileData
  mean?: number
}

export const dotSizeMap: Record<
  NonNullable<ProductSettings['dotSize']>,
  number
> = {
  small: 0.75,
  medium: 1,
  large: 1.5,
  extraLarge: 2.5,
}

export const makePaint = ({
  layer,
  product,
  settings,
  color,
}: MakePaintProps) => {
  const displayProperty = product.dataProperty
  const noDataProperty = product.noDataProperty

  if (layer.mapSource.type === 'raster') {
    return {
      'raster-opacity': settings.opacity ?? 1,
    }
  }

  const paint =
    displayProperty && color
      ? colorProfileToPaint({
          display: color.display,
          property: displayProperty.property,
          stops: color.dataStops,
          noData:
            noDataProperty && color.noDataColor
              ? {
                  property: noDataProperty.property,
                  value: noDataProperty.noDataValue,
                  color: color.noDataColor,
                }
              : undefined,
        })
      : undefined

  const sourceType = layer.mapLayerDef.mapSourceDef.type
  const bounds = bbox.parse(layer.mapSource.bounds)
  const latitude = bounds[1]

  if (sourceType === 'point') {
    if (settings.blockByBlockAverage) {
      return {
        'fill-color': paint,
        'fill-opacity': settings.opacity ?? 1,
        'fill-outline-color': 'transparent',
      }
    } else {
      const basePointPaint = {
        'circle-opacity': settings.opacity ?? 1,
        'circle-stroke-opacity': settings.opacity ?? 1,
        'circle-color': paint,
      }

      if (layer.mapLayerDef.coverageProperty) {
        return {
          ...basePointPaint,
          'circle-radius': getCoverageRadius(layer, settings, latitude),
        }
      }

      return {
        ...basePointPaint,
        'circle-radius': {
          base: 2,
          stops: [
            [0, 0],
            [13, 2 * dotSizeMap[settings.dotSize ?? 'medium']],
            [14, 2 * dotSizeMap[settings.dotSize ?? 'medium']],
            [15, 2 * dotSizeMap[settings.dotSize ?? 'medium']],
            [16, 3 * dotSizeMap[settings.dotSize ?? 'medium']],
            [17, 2 * dotSizeMap[settings.dotSize ?? 'medium']],
            [
              25,
              metersToPixelsAtMaxZoom(0.45, latitude) *
                dotSizeMap[settings.dotSize ?? 'medium'],
            ],
          ],
        },
        'circle-stroke-width': {
          stops: [
            [17, 0],
            [18, 1],
          ],
        },
        'circle-stroke-color': 'black',
      }
    }
  }
  if (sourceType === 'line') {
    return {
      'line-opacity': settings.opacity ?? 1,
      'line-width': {
        base: 2,
        stops: [
          [0, 0],
          [13, 0.8],
          [25, metersToPixelsAtMaxZoom(1.25, latitude)],
        ],
      },
      'line-color': paint,
    }
  }
  if (sourceType === 'zone') {
    return {
      'fill-outline-color': 'transparent',
      'fill-opacity': settings.opacity ?? 1,
      'fill-color': paint,
    }
  }

  return {}
}

// gets a radius which is complimentary to the stroke width of coverage-based data
// this prevents over-representation of data with smaller degrees of coverage
// this is interpolated by zoom, to progressively render more coverage-based
// data as the zoom level is increased
const getCoverageRadius = (
  layer: MapLayerData,
  settings: ProductSettings,
  latitude: number
) => [
  'interpolate',
  ['exponential', 2],
  ['zoom'],
  ...getZoomLevels(0, 0, layer, settings, 0),
  ...getZoomLevels(13, 13, layer, settings, 2),
  ...getZoomLevels(14, 14, layer, settings, 2),
  ...getZoomLevels(15, 15, layer, settings, 2),
  ...getZoomLevels(16, 16, layer, settings, 2),
  ...getZoomLevels(17, 17, layer, settings, 2),
  ...getZoomLevels(
    25,
    25,
    layer,
    settings,
    metersToPixelsAtMaxZoom(0.45, latitude)
  ),
]

const getZoomLevels = (
  min: number,
  max: number,
  layer: MapLayerData,
  settings: ProductSettings,
  base: number
) => {
  const arr: any[] = []
  let curr = min
  const coverageThreshold =
    settings.coverage?.[
      `${layer?.mapSource?.parcel?.groupId}_${layer?.mapSource?.flightDate}`
    ] ??
    layer.mapLayerDef.settings[0]?.coverage ??
    DEFAULT_COVERAGE

  while (curr <= max) {
    arr.push(curr, [
      'case',
      [
        '<',
        ['number', ['get', layer.mapLayerDef.coverageProperty.property], 0],
        ['number', coverageThreshold],
      ],
      0,
      [
        '!=',
        ['number', ['get', layer.mapLayerDef.noDataProperty.property], 0],
        ['number', layer.mapLayerDef.noDataProperty.noDataValue, -1],
      ],
      getCoverage(
        layer,
        settings,
        base * dotSizeMap[settings.dotSize ?? 'medium']
      ),
      0,
    ])

    curr += 1
  }

  return arr
}

export const getCoverage = (
  layer: MapLayerData,
  settings: ProductSettings,
  base: number
) => [
  'sqrt',
  [
    'number',
    [
      '*',
      [
        'min',
        [
          'max',
          [
            '/',
            [
              '-',
              [
                'number',
                ['get', layer.mapLayerDef.coverageProperty.property],
                0,
              ],
              ['literal', settings.coverageMin ?? 0],
            ],
            [
              'literal',
              (settings.coverageMax ?? 1) - (settings.coverageMin ?? 0),
            ],
          ],
          ['literal', 0],
        ],
        ['literal', 1],
      ],
      ['*', base, base],
    ],
  ],
]
