import * as React from 'react'
import { PureComponent } from 'react'
import {
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { updatePreferences } from '../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../redux/types'
import { UnitSystem } from '../util/units/unitSystem'

const unitSystemOptions: UnitSystem[] = [UnitSystem.Imperial, UnitSystem.Metric]

class PreferredUnits extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    const { unitSystem } = this.props

    return (
      <React.Fragment>
        <ListItem>
          <FormControl fullWidth>
            <InputLabel>{i18n.t(keys.units.unitSystemPicker)}</InputLabel>
            <Select
              value={unitSystem}
              onChange={this.handleChangeUnitSystem}
              fullWidth
            >
              {unitSystemOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {i18n.t(keys.units.unitSystem[option])}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
      </React.Fragment>
    )
  }

  handleChangeUnitSystem = (event: SelectChangeEvent) => {
    this.props.dispatch(
      updatePreferences({
        preferredUnitSystem: event.target.value as UnitSystem,
      })
    )
  }
}

const mapState = (state: RootStore) => ({
  unitSystem: state.preferences.preferredUnitSystem,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  PreferredUnits
)
