import * as React from 'react'
import { PureComponent } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Icon,
  IconButton,
  Typography,
} from '@mui/material'
import { VV_SURVEY_BUCKET } from '../../config'
import { objectToString } from '../../util/objectToString'
import vvapi from '../../vvapi'
import { JobStatusChip } from '../Maestro/JobStatusChip'
import { Row } from '../UI/Row'
import DataSetImageDetails from './DataSetImageDetails'
import DataSetImageTriggerProcessing from './DataSetImageTriggerProcessing'
import { ListDataSetImageData } from './selectListDataSetImages'
import { DataSetImageState } from '../../graphql/types'

interface Props {
  dataSetImage: ListDataSetImageData
}

interface State {
  showDetails?: boolean
}

class DataSetImageCard extends PureComponent<Props, State> {
  state: State = {}

  render() {
    const filenames = this.props.dataSetImage.filenames ?? []
    let originalFilenames: string[] = []
    if (this.props.dataSetImage.meta?.originalFilenames) {
      originalFilenames = this.props.dataSetImage.meta.originalFilenames
    }

    return (
      <Card style={{ margin: 5 }}>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography style={{ marginRight: 15 }}>
            {this.props.dataSetImage.id}
          </Typography>
          <div style={{ flex: 1, flexDirection: 'column', marginRight: 8 }}>
            {this.renderNotices()}
            {filenames.map((filename: string, i) => (
              <Typography key={i}>
                {`${filename}`} ({`${originalFilenames[i]}`})
              </Typography>
            ))}
          </div>
          <Row
            style={{
              marginLeft: 14,
              marginRight: 8,
              marginTop: 4,
              alignSelf: 'flex-start',
            }}
          >
            <Typography variant="caption" style={{ marginRight: 8 }}>
              Maestro Job:{' '}
            </Typography>
            <JobStatusChip
              placement="left"
              jobStatusAndId={{
                status: this.props.dataSetImage?.job?.Status?.status ?? '',
                id: this.props.dataSetImage?.job?.id,
                output: objectToString(
                  this.props.dataSetImage?.job?.LatestJobAttempt?.output ?? {}
                ),
              }}
            />
          </Row>
          <IconButton
            style={{ alignSelf: 'flex-start' }}
            onClick={() =>
              this.setState({ showDetails: !this.state.showDetails })
            }
            size="large"
          >
            <Icon>
              {this.state.showDetails
                ? 'keyboard_arrow_up'
                : 'keyboard_arrow_down'}
            </Icon>
          </IconButton>
        </CardContent>

        <CardActions style={{ flexWrap: 'wrap' }}>
          <DataSetImageTriggerProcessing
            disabled={
              this.props.dataSetImage.state ===
                DataSetImageState.RawUploadError ||
              this.props.dataSetImage.state ===
                DataSetImageState.RawUploadStarted
            }
            dataSetImageId={this.props.dataSetImage.id}
          />
          <Button variant="outlined" onClick={this.handleProcessedS3Click}>
            Processed S3
          </Button>
        </CardActions>

        {this.state.showDetails && (
          <CardContent>
            <DataSetImageDetails dataSetImage={this.props.dataSetImage} />
          </CardContent>
        )}
      </Card>
    )
  }

  handleProcessedS3Click = async () => {
    const { dataSetId, id: dataSetImageId } = this.props.dataSetImage
    const bucketSuffix = await vvapi.config.bucketSuffix()
    window.open(
      `https://s3.console.aws.amazon.com/s3/buckets/${VV_SURVEY_BUCKET}/working${bucketSuffix}/${dataSetId}/${dataSetImageId}/?region=us-east-1&tab=overview`,
      '_blank'
    )
  }

  /**
   * It renders notices/flags/messages for each image
   *
   * ? Should the messages be more generic? Flagged for progress states too?
   */
  renderNotices = () => {
    const { dataSetImage } = this.props
    const meta = dataSetImage.meta

    const status = dataSetImage.state ?? ''

    const statusColor = /error|failed/i.test(status)
      ? 'var(--error-color)'
      : /start/i.test(status)
      ? 'var(--info-color)'
      : /finish/i.test(status)
      ? 'var(--success-color)'
      : undefined

    return (
      <Row style={{ justifyContent: 'space-between' }}>
        {dataSetImage.state && (
          <Chip
            style={{ background: statusColor }}
            label={`State: ${dataSetImage.state}`}
          />
        )}
        {meta?.uploadMessage && <Chip label={`${meta.uploadMessage.text}`} />}
      </Row>
    )
  }
}

export default DataSetImageCard
