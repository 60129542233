import { MD5, enc, algo } from 'crypto-js'
import { Buffer } from 'buffer'
import { JSZipObject, loadAsync } from 'jszip'

export interface Checksum {
  checksumAlgorithm: 'md5'
  checksum: string
}

const readSlice = async (
  file: File,
  start: number,
  size: number
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader()
    const slice = file.slice(start, start + size)

    fileReader.onload = () => resolve(fileReader.result as string)
    fileReader.onerror = reject
    fileReader.readAsBinaryString(slice)
  })
}

const sliceSize = 2000

export const calculateMD5HashOld = (file: File) => {
  if (file.name.endsWith('.zip')) {
    return getZipMD5Hash(file)
  }

  return new Promise<Checksum>((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = () => {
      if (fileReader.result) {
        resolve({
          checksumAlgorithm: 'md5',
          checksum: MD5(`${fileReader.result}`).toString(),
        })
      }
    }

    fileReader.onerror = (e) => {
      reject(e)
    }

    const processNextPart = () => {
      fileReader.readAsBinaryString(file)
    }

    processNextPart()
  })
}

export const calculateMD5Hash = async (file: File): Promise<Checksum> => {
  if (file.name.endsWith('.zip')) {
    return getZipMD5Hash(file)
  }

  let md5 = algo.MD5.create()

  let bytesRead = 0
  while (bytesRead < file.size) {
    const slice = await readSlice(file, bytesRead, sliceSize)

    md5 = md5.update(slice)
    bytesRead += sliceSize
  }

  return {
    checksumAlgorithm: 'md5',
    checksum: enc.Hex.stringify(md5.finalize()),
  }
}

type JSZipObjectExt = JSZipObject & { _data: { crc32: number } }

const getZipMD5Hash = async (zipFile: File): Promise<Checksum> => {
  const zip = await loadAsync(zipFile)

  const buffers: Buffer[] = []
  for (const entry of Object.values(zip.files)) {
    buffers.push(Buffer.from(getHexCRCString(entry as JSZipObjectExt)))
  }

  return {
    checksumAlgorithm: 'md5',
    checksum: MD5(Buffer.concat(buffers).toString()).toString(),
  }
}

const getHexCRCString = (obj: JSZipObjectExt) => {
  // grab the "private" crc checksum
  const crcRaw = obj._data.crc32

  // convert the signed value to an unsigned int
  // tslint:disable-next-line:no-bitwise
  const crcUnsigned = crcRaw >>> 0

  // convery to a hex string
  return crcUnsigned.toString(16)
}
