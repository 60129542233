import { useRedux } from '../../../hooks/useRedux'
import * as React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { selectUsers, UserData } from '../../../data/selectUserList'

interface Props {
  selectedOrganizations: number[]
  onUsersSelected: (users: UserData[]) => void
  selectedUsers?: number[]
}

const ALL: UserData = {
  firstName: '',
  lastName: '',
  email: '',
  userOrgs: [],
  id: '-1',
}

export const UsersAutoComplete = ({
  selectedOrganizations,
  onUsersSelected,
  selectedUsers = [-1],
}: Props) => {
  const [state] = useRedux()

  const users = selectUsers(state)

  const adjustedUsers = React.useMemo(() => {
    if (selectedOrganizations.includes(-1)) {
      return [ALL, ...users]
    }

    return [
      ALL,
      ...users.filter((u) =>
        u.userOrgs.some((o) => selectedOrganizations.includes(o.organizationId))
      ),
    ]
  }, [users, selectedOrganizations])

  const memoizedSelectedUsers = React.useMemo(() => {
    const usersSet = new Set(selectedUsers)

    return adjustedUsers.filter((u) => usersSet.has(Number(u.id)))
  }, [adjustedUsers, selectedUsers])

  const handleUsersSelected = (_: any, value: UserData[]) => {
    if (
      (value.some((o) => o.id === '-1') &&
        !selectedUsers.some((o) => o === -1)) ||
      value.length === 0
    ) {
      onUsersSelected([ALL])
    } else {
      onUsersSelected(value.filter((o) => o.id !== '-1'))
    }
  }

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      value={memoizedSelectedUsers}
      options={adjustedUsers}
      getOptionLabel={(user) =>
        user.id === '-1' ? 'All' : `${user?.firstName} ${user?.lastName}`
      }
      defaultValue={[ALL]}
      onChange={handleUsersSelected}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Recieving Users" />
      )}
    />
  )
}
