import * as React from 'react'

interface Props {
  children: React.ReactNode
  onSubmit(): void
}

/**
 * Adds a grid class container for all forms;
 * adds ability to press "Enter" to submit
 */
class FormWrapper extends React.PureComponent<Props> {
  render() {
    return (
      <form
        className="grid grid-xs-12"
        onSubmit={this.handleSubmit}
        ref={this.onRef}
      >
        {this.props.children}
      </form>
    )
  }

  handleSubmit = (e: React.FormEvent | KeyboardEvent) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onSubmit()

    return false
  }

  onRef = (form: HTMLFormElement | null) => {
    if (form) {
      form.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.code === 'Enter') {
          const target = e.target as HTMLElement | undefined
          // allow enters on multiline
          if (target?.tagName === 'TEXTAREA') {
            return
          }

          const parent = target?.parentElement
          // allow enters to summon date dialogs date
          if (parent?.className.includes('DatePicker')) {
            e.preventDefault()

            return
          }

          this.handleSubmit(e)
        }
      })
    }
  }
}

export default FormWrapper
