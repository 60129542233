import * as React from 'react'
import MoreMenu from '../../UI/Table/MoreMenu'
import i18n, { keys } from '../../i18n'
import { deleteSamplePlan, duplicateSamplePlan, markAsComplete } from './queries'
import swal from 'sweetalert'

interface Props {
  samplePlanId: string
  samplePlanName: string
  refresh?: () => void
  onDelete?: () => void
  onDuplicate?: (duplicatedSamplePlanId: string) => void
}

export const SamplePlanMoreMenu = ({
  samplePlanId,
  samplePlanName,
  refresh,
  onDelete,
  onDuplicate,
}: Props) => {
  const options = (id: string) => [
    {
      title: i18n.t(keys.samplePlanMoreMenuMarkAsCompleteLabel),
      action: async () => {
        await markAsComplete(id)
        refresh?.()
      },
    },
    {
      title: i18n.t(keys.samplePlanDelete),
      action: async () => {
        swal(i18n.t(keys.samplePlanDelete), 
        i18n.t(keys.samplePlanDeleteConfirmation),
        "warning",
        {
          buttons: [i18n.t(keys.generic.cancel), i18n.t(keys.generic.delete)],
          dangerMode: true,
        }).then(async (value) => {
          if (value) {
            await deleteSamplePlan(id)
            refresh?.()
            onDelete?.()
          }
        })
      },
    },
    {
      title: i18n.t(keys.samplePlanDuplicate),
      action: async () =>
        swal(
          i18n.t(keys.samplePlanDuplicate),
          '',
          {
            content: {
                element: 'input',
                attributes: {
                    defaultValue: samplePlanName + ' - Copy',
                },
            },
            buttons: [i18n.t(keys.generic.cancel), i18n.t(keys.generic.confirm)],
        }).then(async (value) => {
            if(value === null) return
            const newName = value.length > 0 ? value : samplePlanName + ' - Copy'
            const samplePlan = await duplicateSamplePlan(id, newName)
            refresh?.()
            onDuplicate?.(samplePlan.id)
        }),
    },
  ]

  return <MoreMenu options={options(samplePlanId)} />
}
