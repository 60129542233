import * as React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Icon,
  Pagination,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Page from '../app/Page'
import { url, urls } from '../appNavigation/urls'
import { selectMapLayerDefsById } from '../data/selectListMapSourceDefs'
import { selectMe } from '../data/selectMe'
import { selectOrganizationId } from '../data/selectOrganizationId'
import { selectGroupDatesByDeliveryGroupId } from '../data/selectOrgMapData'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import useAsync from '../hooks/useAsync'
import { useInterval } from '../hooks/useInterval'
import { useRedux } from '../hooks/useRedux'
import { useHistory, useLocation, useSearchParams } from '../hooks/useRouter'
import i18n, { keys } from '../i18n'
import { showFeedback } from '../redux/notifications/redux'
import EnhancedTableToolbar from '../UI/EnhancedTable/EnhancedTableToolbar'
import { createTableActionIconButton } from '../UI/Table/createTableActionIconButton'
import { copyToClipboard } from '../util/copyToClipboard'
import { unionSet } from '../util/unionSet'
import { getDownloadUrl } from '../vvapi/apiResource/createApiResource'
import { DataDownloadSettings } from './DataDownloadSettingsForm'
import { downloadFile } from './downloadFile'
import { DownloadFilters } from './DownloadFilters'
import { DownloadsCards } from './DownloadsCards'
import { DownloadsTable } from './DownloadsTable'
import { fetchDownloads } from './fetchDownloads'
import { GeoPDFDownloadSettings } from './GeoPDFDownloadSettingsForm'
import { requestDownload } from './requestDownloads'
import { RequestDownloadsDialog } from './RequestDownloadsDialog'
import { Download, DownloadRequest } from './types'

export const Downloads = () => {
  const theme = useTheme()
  const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesMedium = useMediaQuery(theme.breakpoints.down('md'))

  const [state, dispatch] = useRedux()

  const me = selectMe(state)

  const canCreateDownload =
    me?.orgPermissions?.includes('Download-create') ?? false
  const organizationId = selectOrganizationId(state)
  const productsById = selectMapLayerDefsById(state)
  const preferredLanguage = selectPreferredLanguage(state)
  const deliveryGroupsDatesById = selectGroupDatesByDeliveryGroupId(state)

  const location = useLocation()

  const { page = 0, pageSize = 5, filter } = useSearchParams()
  const history = useHistory()

  const [downloads, refreshDownloads, hasDownloadsRefreshed] = useAsync(
    fetchDownloads,
    [organizationId, Number(page), Number(pageSize), filter, me?.id]
  )

  useInterval(() => {
    refreshDownloads()
  }, 10000)

  React.useEffect(() => {
    setSelectedDownloads({})
  }, [filter, page, pageSize])

  const [selectedDownloads, setSelectedDownloads] = React.useState<
    Record<string, Set<string>>
  >({})
  const [requestDialogOpen, setRequestDialogOpen] = React.useState(false)

  const handleChangePage = (
    _event: React.ChangeEvent<HTMLButtonElement>,
    page: number
  ) => {
    history.replace(
      url({ url: location.pathname }, {}, { page: page - 1, pageSize })
    )
  }

  const selectDownloads = (requestId: string, ids: string[], all = false) => {
    const newSelectedDownloads = { ...selectedDownloads }

    if (!ids?.length) {
      return
    }

    if (all) {
      if (newSelectedDownloads[requestId]) {
        delete newSelectedDownloads[requestId]
      } else {
        newSelectedDownloads[requestId] = new Set(ids)
      }
    } else {
      for (const id of ids) {
        if (newSelectedDownloads[requestId]?.has(id)) {
          newSelectedDownloads[requestId]?.delete(id)
        } else {
          if (!newSelectedDownloads[requestId]) {
            newSelectedDownloads[requestId] = new Set()
          }
          newSelectedDownloads[requestId].add(id)
        }
      }
    }

    setSelectedDownloads(newSelectedDownloads)
  }

  const selectAllDownloads = () => {
    if (Object.keys(selectedDownloads).length > 0) setSelectedDownloads({})
    else {
      const newSelectedDownloads: Record<string, Set<string>> = {}
      const allDownloads = {}
      const downloadRequests = downloads.result?.downloadRequests

      downloadRequests?.forEach((request: DownloadRequest) => {
        allDownloads[request.id] = request.Downloads
      })

      for (const requestId of Object.keys(allDownloads)) {
        const filteredAllDownloads = allDownloads[requestId]?.filter(
          ({ status }: { status: string }) => status === 'complete'
        )

        if (!filteredAllDownloads.length) {
          continue
        }

        newSelectedDownloads[requestId] = new Set(
          filteredAllDownloads.map((download: Download) => download.id)
        )
      }

      setSelectedDownloads(newSelectedDownloads)
    }
  }

  const handleDownloadRequestSubmit = async (
    name: string,
    selectedGroups: Set<string>,
    selectedDeliveries: Set<string>,
    settings: GeoPDFDownloadSettings | DataDownloadSettings
  ) => {
    if (!organizationId) {
      return
    }

    await requestDownload({
      name,
      selectedGroups,
      selectedDeliveries,
      settings,
      preferredLanguage,
      productsById,
      deliveryGroupsDatesById,
      organizationId,
    })

    setRequestDialogOpen(false)
    refreshDownloads()

    dispatch(
      showFeedback({
        message: i18n.t(keys.downloadRequested),
        severity: 'success',
      })
    )
  }

  const renderStatus = ({
    status,
    id,
    file,
    filename,
    requestId,
  }: Pick<Download, 'id' | 'status' | 'file' | 'filename' | 'requestId'>) => {
    switch (status) {
      case 'complete':
        return [
          createTableActionIconButton({
            title: i18n.t(keys.download),
            icon: 'download',
            key: 'download',
          })({
            disabled: !file,
            onClick: (ev) => {
              ev.stopPropagation()
              ev.preventDefault()

              downloadFile(id, file, filename, me!.id)
              refreshDownloads()
            },
          }),
          createTableActionIconButton({
            title: i18n.t(keys.copyUrl),
            icon: 'content_copy',
            key: 'copy',
          })({
            disabled: !file,
            onClick: async (ev) => {
              ev.preventDefault()
              ev.stopPropagation()

              if (
                await copyToClipboard(
                  getDownloadUrl(
                    `/api/v3/download/${id}?downloadToken=${
                      file!.downloadToken
                    }`
                  )
                )
              ) {
                dispatch(
                  showFeedback({
                    message: i18n.t(keys.copySuccess),
                    severity: 'success',
                  })
                )
              }
            },
          }),
        ]
      case 'pending':
      case 'processing':
        return (
          <Tooltip
            title={i18n.t(keys.downloadPending)}
            placement="top"
            disableInteractive
          >
            <CircularProgress
              sx={{ marginRight: theme.spacing() }}
              size={24}
              thickness={4}
              color="inherit"
            />
          </Tooltip>
        )
      case 'error':
        return (
          <Tooltip
            title={i18n.t(keys.downloadError)}
            placement="top"
            disableInteractive
          >
            <Icon>error</Icon>
          </Tooltip>
        )
      default:
        return null
    }
  }

  const renderActions = () => {
    const requestDownloadBtn = (
      <Button
        disabled={downloads.status !== 'resolved' && !hasDownloadsRefreshed}
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setRequestDialogOpen(true)}
      >
        <Icon>add</Icon>
        {i18n.t(keys.requestDownload)}
      </Button>
    )

    const selectedDownloadsCount = allSelectedDownloads.size
    const downloadBtn = (
      <Button
        disabled={downloads.status !== 'resolved' && !hasDownloadsRefreshed}
        size="small"
        variant="outlined"
        color="primary"
        onClick={async (ev) => {
          ev.stopPropagation()
          ev.preventDefault()
          if (selectedDownloads) {
            for (const selections of Object.values(selectedDownloads)) {
              const ids = Array.from(selections)

              const allDownloads: Download[] = []

              const downloadRequests = downloads.result?.downloadRequests
              downloadRequests?.forEach((request: DownloadRequest) => {
                allDownloads.push(...request.Downloads)
              })

              for (const id of ids) {
                const download = allDownloads.find((d) => d.id === id)
                if (download && download.file) {
                  await downloadFile(
                    id,
                    download.file,
                    download.filename,
                    me!.id
                  )
                  refreshDownloads()
                }
              }
            }
          }
        }}
      >
        <Icon>download</Icon>
        {`${i18n.t(keys.download)}${
          selectedDownloadsCount ? '(' + selectedDownloadsCount + ')' : ''
        }`}
      </Button>
    )

    if (
      selectedDownloads !== undefined &&
      Object.values(selectedDownloads).length > 0
    ) {
      return (
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            gap: theme.spacing(),
          }}
        >
          {downloadBtn}
          {canCreateDownload ? requestDownloadBtn : null}
        </Box>
      )
    }

    return <>{canCreateDownload ? requestDownloadBtn : null}</>
  }

  const renderFilters = (withTitle: boolean) => (
    <DownloadFilters withTitle={withTitle} />
  )

  const allSelectedDownloads = Object.values(selectedDownloads).reduce(
    (curr, next) => {
      return unionSet(curr, next)
    },
    new Set<string>()
  )

  return (
    <Page
      title={i18n.t(keys.downloads)}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <Container maxWidth="xl" sx={{ padding: theme.spacing(2) }}>
        <RequestDownloadsDialog
          open={requestDialogOpen}
          onCancelled={() => setRequestDialogOpen(false)}
          onSubmit={handleDownloadRequestSubmit}
        />
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            sx={
              matchesMedium
                ? { pr: 0, mb: theme.spacing() }
                : { pr: theme.spacing(8) }
            }
          >
            {matchesMedium ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ px: theme.spacing(4) }}
                >
                  <Typography align="center" variant="h6">
                    {i18n.t(keys.filterBy)}:
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: theme.spacing(4) }}>
                  {renderFilters(false)}
                </AccordionDetails>
              </Accordion>
            ) : (
              renderFilters(true)
            )}
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <Paper
              sx={{
                px: { xs: theme.spacing(4), lg: theme.spacing(8) },
                py: theme.spacing(4),
              }}
            >
              <EnhancedTableToolbar
                numSelected={allSelectedDownloads.size}
                hideSelected={true}
                title={i18n.t(keys.downloads)}
                actions={renderActions}
                instruction={i18n.t(keys.downloadTableInstruction, {
                  request: i18n.t(keys.requestDownload),
                })}
                style={{
                  padding: 0,
                  marginTop: 0,
                  minHeight: 'unset',
                  height: 'unset',
                }}
              />
              <Stack>
                {matchesSmall ? (
                  <DownloadsCards
                    selectAllDownloads={selectAllDownloads}
                    selectedDownloads={selectedDownloads}
                    selectDownloads={selectDownloads}
                    renderStatus={renderStatus}
                    deliveryGroupsDatesById={deliveryGroupsDatesById}
                    hasDownloadsRefreshed={hasDownloadsRefreshed}
                    downloads={downloads}
                  />
                ) : (
                  <DownloadsTable
                    selectAllDownloads={selectAllDownloads}
                    selectedDownloads={selectedDownloads}
                    selectDownloads={selectDownloads}
                    renderStatus={renderStatus}
                    deliveryGroupsDatesById={deliveryGroupsDatesById}
                    hasDownloadsRefreshed={hasDownloadsRefreshed}
                    downloads={downloads}
                  />
                )}
              </Stack>
            </Paper>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={matchesMedium ? 'center' : 'flex-end'}
            >
              <Pagination
                shape="rounded"
                color="primary"
                page={Number(page) + 1}
                count={Math.ceil(
                  (downloads.result?.count ?? 0) / Number(pageSize)
                )}
                onChange={handleChangePage}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  pt: theme.spacing(4),
                  '.MuiPagination-ul > li > .Mui-selected': { color: '#fff' },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
