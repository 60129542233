import './JsonViewer.scss'

import * as React from 'react'

import { Button, Typography } from '@mui/material'

import { objectToString } from '../../util/objectToString'
import { Column } from './Column'
import { Row } from './Row'

interface Props {
  json: Record<string, any> | null
}

interface State {
  showAsJson: boolean
}

const buttonStyle = {
  padding: 5,
  width: 45,
  minWidth: 0,
}

export class JsonViewer extends React.Component<Props, State> {
  state: State = { showAsJson: false }
  render() {
    const { json } = this.props
    const { showAsJson } = this.state

    return (
      <Column style={{ width: '100%' }}>
        <Row style={{ justifyContent: 'space-between', alignSelf: 'flex-end' }}>
          <Typography>Show as:</Typography>
          <Row style={{ marginLeft: 8 }}>
            <Button
              onClick={() => {
                this.setState({ showAsJson: false })
              }}
              style={{
                ...buttonStyle,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              size="small"
              variant={!showAsJson ? 'contained' : 'outlined'}
              color={!showAsJson ? 'primary' : undefined}
            >
              Text
            </Button>
            <Button
              onClick={() => {
                this.setState({ showAsJson: true })
              }}
              style={{
                ...buttonStyle,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              size="small"
              variant={showAsJson ? 'contained' : 'outlined'}
              color={showAsJson ? 'primary' : undefined}
            >
              JSON
            </Button>
          </Row>
        </Row>
        {showAsJson ? (
          <Column style={{ flex: 1, width: '100%' }}>
            <pre className="attempt-logs">
              <code>{JSON.stringify(json, null, 2)}</code>
            </pre>
          </Column>
        ) : (
          <Column style={{ flex: 1, width: '100%' }}>
            <pre className="attempt-logs wrap">
              {json !== null &&
                objectToString(json).map((s, index) => <p key={index}>{s}</p>)}
            </pre>
          </Column>
        )}
      </Column>
    )
  }
}
