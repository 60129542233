import { Languages } from '../../../graphql/types'

interface IHistoricalCalculationTypeProps {
  key: string
  xAxisLabel: Partial<Record<Languages, string>>
  yAxisLabel: Partial<Record<Languages, string>>
}

type HistoricalLabel = Partial<Record<Languages, string>>

interface WeightedAverageWithError extends IHistoricalCalculationTypeProps {
  key: 'weightedAvgWithError'
  mean: string
  meanLabel: HistoricalLabel
  stdev: string
  stdevLabel: HistoricalLabel
  weight: string
  weightLabel: HistoricalLabel
}

const getInitializedHistoricalCalculationTypePropertyMap = (): ReadonlyMap<
  string,
  IHistoricalCalculationTypeProps
> => {
  return new Map<string, IHistoricalCalculationTypeProps>([
    [
      'weightedAvgWithError',
      {
        xAxisLabel: { en: '', fr: '' },
        yAxisLabel: { en: '', fr: '' },
        mean: '',
        meanLabel: { en: '', fr: '' },
        stdev: '',
        stdevLabel: { en: '', fr: '' },
        weight: '',
        weightLabel: { en: '', fr: '' },
      } as WeightedAverageWithError,
    ],
  ])
}

export {
  IHistoricalCalculationTypeProps,
  WeightedAverageWithError,
  getInitializedHistoricalCalculationTypePropertyMap,
  HistoricalLabel,
}
