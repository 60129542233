import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import Icon from '@mui/material/Icon'
import * as React from 'react'

export type Selection = 'unselected' | 'partially-selected' | 'selected'

export interface SelectBoxProps extends CheckboxProps {
  selection?: Selection
}

export const selectionOf = (selected: number, total: number) =>
  selected > 0
    ? selected === total
      ? 'selected'
      : 'partially-selected'
    : 'unselected'

export class SelectBox extends React.PureComponent<SelectBoxProps> {
  render() {
    const { selection, style, ...rest } = this.props

    return (
      <Checkbox
        color="primary"
        checked={selection === 'selected'}
        indeterminate={selection === 'partially-selected'}
        indeterminateIcon={<Icon>add_box</Icon>}
        style={{ padding: 4, ...style }}
        {...rest}
      />
    )
  }
}
