import { v4 as uuidv4 } from 'uuid'
import { ReqMessage, ResMessage } from './types'

export function createManager(worker: Worker) {
  const jobs = new Map<
    string,
    { reject: (error: any) => void; resolve: (data: any) => void }
  >()

  worker.addEventListener('message', async (event) => {
    const data: ResMessage = event.data

    if (!data || data.$ !== '$res') {
      return
    }

    event.stopImmediatePropagation()
    event.stopPropagation()

    const { id, result } = data

    const job = jobs.get(id)
    jobs.delete(id)

    if (!job) {
      // tslint:disable-next-line: no-console
      console.warn(
        `received unrecognized job: ${JSON.stringify(data, null, 2)}`
      )

      return
    }

    if (result?.type === 'resolve') {
      job.resolve(result.value)
    } else {
      job.reject(result?.value)
    }
  })

  function getId(): string {
    const id = uuidv4()

    if (!jobs.has(id)) {
      return id
    }

    return getId()
  }

  return {
    invoke<R>(method: string, ...args: any[]) {
      return new Promise<R>((resolve, reject) => {
        const message: ReqMessage = {
          method,
          args,
          id: getId(),
          $: '$req',
        }

        jobs.set(message.id, { resolve, reject })
        worker.postMessage(message)
      })
    },
  }
}
