import './SamplePlanDashboard.scss'

import * as React from 'react'
import Page from '../../../app/Page'
import i18n, { keys } from '../../../i18n'
import {
  DEFAULT_PAGE_INFO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  url,
  urls,
} from '../../../appNavigation/urls'
import {
  Button,
  CircularProgress,
  Hidden,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { Search } from '../../../UI/Table/Search'
import { useHistory, useSearchParams } from '../../../hooks/useRouter'
import { fetchAllOrganizationLocations, fetchSamplePlans } from '../queries'
import useAsync from '../../../hooks/useAsync'
import { useRedux } from '../../../hooks/useRedux'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import { tableFormatterWithScope } from './samplePlanTableFormatter'
import { PaginationChange, TableOrder } from '../../../UI/Table/types'
import { ORDER } from '../../../UI/Table/orderRows'
import { Table } from '../../../UI/Table/Table'
import { Add } from '@mui/icons-material'
import { SamplePlanNew } from './SamplePlanNew/SamplePlanNew'
import { DropdownTableFilter } from '../../../UI/Table/DropdownTableFilter/DropdownTableFilter'
import { ReactComponent as SamplePlanIcon } from '../../SamplePlan/assets/sample_plan_icon.svg'

const searchBarFilterId = 'sample-dashboard-search-filter'
const inlineFilterId = 'sample-dashboard-inline-filter'

const TableContainer = styled('div')(({ theme }) => ({
  width: '100%',
  overflowX: 'scroll',
}))

export const SamplePlanDashboard = () => {
  const [newSamplePlanDialogOpen, setNewSamplePlanDialogOpen] =
    React.useState(false)

  const [state] = useRedux()
  const {
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    filter,
    order,
  } = useSearchParams()
  const history = useHistory()

  const organizationId = selectOrganizationId(state)

  const [samplePlans, refreshSamplePlans] = useAsync(
    fetchSamplePlans(Number(organizationId)),
    [Number(page), Number(pageSize), order, filter],
    [organizationId]
  )

  const [organizationLocations] = useAsync(fetchAllOrganizationLocations, [
    Number(organizationId),
  ])

  const updateSearchParams = (update: {
    page?: number
    pageSize?: number
    order?: string
    filter?: string
  }) => {
    history.push(
      url(
        urls.samplePlans,
        {},
        {
          page: Number(page),
          pageSize: Number(pageSize),
          order,
          filter,
          ...update,
        }
      )
    )
  }

  const rows = samplePlans.result ? samplePlans.result.data : []
  const info = samplePlans.result
    ? samplePlans.result.info
    : { ...DEFAULT_PAGE_INFO, order: [] }

  const handlePaginationChange = (pagination: PaginationChange) =>
    updateSearchParams(pagination)

  const handleOrderChange = (order: TableOrder) => {
    updateSearchParams({ order: ORDER.serialize(order) })
  }

  const tableFormatter = tableFormatterWithScope({
    refresh: refreshSamplePlans,
    onResultClick: (id: string) =>
      history.push(url(urls.samplePlanResults, { samplePlanId: id })),
    searchBarFilterId,
    inlineFilterId,
    history,
  })

  return (
    <Page
      title={i18n.t(keys.samplePlanDashboard)}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <Stack className="sample-plan-dashboard" spacing={2}>
        <Stack className="sample-plan-options" direction="column" spacing={2}>
          <Paper
            className="sample-plan-header"
            elevation={0}
            sx={{ width: '100%' }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Search
                  tableFormatters={tableFormatter}
                  filterId={searchBarFilterId}
                />
                <DropdownTableFilter
                  filterId={inlineFilterId}
                  options={
                    organizationLocations?.result
                      ? organizationLocations?.result?.data?.map(
                          (ol) => ol.name
                        )
                      : []
                  }
                  tableFormatters={tableFormatter}
                  allOptionTitle={i18n.t(keys.allLocations)}
                />
              </Stack>
              <Hidden smDown>
                <Button
                  variant="outlined"
                  onClick={() => setNewSamplePlanDialogOpen(true)}
                  startIcon={<Add />}
                >
                  {i18n.t(keys.samplePlanCreateNewLabel)}
                </Button>
              </Hidden>
            </Stack>
          </Paper>
        </Stack>
        <Paper className="sample-plan-paper" elevation={0}>
          <TableContainer>
          {rows.length > 0 ? (
            <Table
              rows={rows}
              formatter={tableFormatter}
              order={info.order}
              onOrderChange={handleOrderChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              pagination={info}
              onPaginationChange={handlePaginationChange}
              onRowClick={(sp) =>
                history.push(url(urls.samplePlan, { samplePlanId: sp.id }))
              }
              rowClass={() => ['clickable']}
            />
          ) : (
            <Stack>
              {samplePlans.status === 'pending' ? (
                <Stack sx={{ height: 265 }} justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" />
                </Stack>
              ) : (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  gap={2}
                  sx={{ height: 265 }}
                >
                  <SamplePlanIcon />
                  <Typography>
                    {i18n.t(keys.samplePlanNoSamplePlans)}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => setNewSamplePlanDialogOpen(true)}
                    startIcon={<Add />}
                  >
                    {i18n.t(keys.samplePlanCreateNewLabel)}
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
          </TableContainer> 
        </Paper>
      </Stack>
      <SamplePlanNew
        open={newSamplePlanDialogOpen}
        onClose={(samplePlanId?: string) => {
          refreshSamplePlans()
          setNewSamplePlanDialogOpen(false)
          // navigate to the new sample plan
          if (!!samplePlanId) {
            history.push(url(urls.samplePlan, { samplePlanId: samplePlanId }))
          }
        }}
      />
    </Page>
  )
}
