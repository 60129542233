interface AsyncReduceWorker<T, R> {
  (acc: R, item: T, index: number): Promise<R>
}

interface AsyncWorker<T, R> {
  (item: T): Promise<R>
}

interface ThrottleAsyncOptions {
  limit: number
}

/**
 * asyncReduce
 *
 * @param arr            Array to be reduced.
 * @param options.limit  Limit of jobs to run in parallel.
 * @param initialValue   The initial value.
 * @param func           Function to reduce with.
 */
const asyncReduce = <T, R>(
  arr: T[],
  { limit }: ThrottleAsyncOptions,
  initialValue: R,
  func: AsyncReduceWorker<T, R>
) =>
  new Promise<R>((resolve, reject) => {
    const queue = arr.map((item, index) => ({ item, index }))
    let result = initialValue
    let workers = 0
    let rejected = false
    let resolved = false

    async function drain() {
      if (resolved || rejected) {
        return
      }

      if (queue.length === 0 && workers === 0) {
        resolved = true
        resolve(result)
      }

      while (workers < limit && queue.length > 0) {
        const job = queue.shift()!

        workers += 1

        try {
          result = await func(result, job.item, job.index)
        } catch (e) {
          rejected = true
          reject(e)
        } finally {
          workers -= 1
          drain()
        }
      }
    }

    for (let i = 0; i < limit; i += 1) {
      drain()
    }
  })

/**
 * asyncMap
 *
 * @param arr            Array to be mapped.
 * @param options.limit  Limit of jobs to run in parallel.
 * @param func           Function to map with.
 */
const asyncMap = <T, R>(
  arr: T[],
  options: ThrottleAsyncOptions,
  func: AsyncWorker<T, R>
) =>
  asyncReduce(arr, options, [] as R[], async (acc, item, index) => {
    acc[index] = await func(item)

    return acc
  })

/**
 * asyncForEach
 *
 * @param arr            Array to be mapped.
 * @param options.limit  Limit of jobs to run in parallel.
 * @param func           Function to map with.
 */
const asyncForEach = <T>(
  arr: T[],
  options: ThrottleAsyncOptions,
  func: AsyncWorker<T, void>
) =>
  asyncReduce(arr, options, undefined, async (acc, item) => {
    await func(item)

    return acc
  })

export { asyncReduce, asyncMap, asyncForEach }
