import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import * as api from '../../graphql/api'
import { DataSetImageMeta, DataSetImageState, Job } from '../../graphql/types'

export interface ListDataSetImageData {
  id: string
  createdAt: string
  updatedAt: string
  dataSetId: string

  center: GeoJSON.Point
  bbox: GeoJSON.Polygon
  meta?: DataSetImageMeta
  filenames?: string[]
  altitude?: number
  gpsTime?: string
  elevation?: number
  yaw?: number
  pitch?: number
  roll?: number
  state?: DataSetImageState
  isCritical?: boolean
  log?: string
  job?: Job
}

const dataSetImageObjectReturning = `{
  id
  createdAt
  updatedAt
  dataSetId
  center
  bbox
  meta
  filenames
  altitude
  gpsTime
  elevation
  yaw
  pitch
  roll
  state
  isCritical
  log
  job: Job {
    id
    taskName
    Status {
      status
    }
    LatestJobAttempt {
      output
      status
      startedAt
      finishedAt
    }
  }
}`

const { selector: selectListDataSetImages, refresh: refreshListDataSetImages } =
  createAsyncSelector({
    resource: 'ListDataSetImages',
    inputs: {
      dataSetId: (state) => state.router.params.dataSetId,
    },
    fetcher: async ({ dataSetId }) => {
      if (!dataSetId) {
        return
      }

      return api.dataSetImage.list<ListDataSetImageData>({
        where: {
          dataSetId: { _eq: dataSetId },
        },
        order_by: '{ id: desc }',
        returning: dataSetImageObjectReturning,
      })
    },
  })

export { selectListDataSetImages, refreshListDataSetImages }
