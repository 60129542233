import * as React from 'react'
import { Component, HTMLAttributes } from 'react'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

interface Props extends HTMLAttributes<HTMLDivElement> {}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '90%',
      maxWidth: '60%',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '80%',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: 'none',
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
        width: '100%',
      },
    },
  })

class Container extends Component<Props & WithStyles<typeof styles>> {
  render() {
    const { classes, className, ...restProps } = this.props

    return (
      <div className={`${classes.root} ${className ?? ''}`} {...restProps}>
        {this.props.children}
      </div>
    )
  }
}

export default withStyles(styles)(Container)
