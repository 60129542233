import * as React from 'react'
import { PureComponent } from 'react'
import InputLabel from '@mui/material/InputLabel'
import { isEmpty } from 'lodash'
import {
  Checkbox,
  FormControl,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { selectParcelFilterInfo } from '../../data/selectParcelFilterInfo'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import * as actions from '../actions'
import { ParcelFilterInfo } from '../types'

const CLEAR_FILTER = 'CLEAR_FILTER'

interface Props {
  filterProperty: keyof ParcelFilterInfo['metaFilters']
  availableValuesSelector: (state: RootStore) => string[]

  inputLabel: string
  noAvailableValuesLabel: string
}

class MetaFilterSelect extends PureComponent<
  Props & ReduxProps & AppDispatchProps
> {
  render() {
    const { inputLabel } = this.props

    return (
      <ListItem>
        <FormControl fullWidth>
          <InputLabel>{inputLabel}</InputLabel>

          {this.renderAvailableValues()}
        </FormControl>
      </ListItem>
    )
  }

  renderAvailableValues = () => {
    const {
      availableValues,
      filterProperty,
      noAvailableValuesLabel,
      parcelFilterInfo,
    } = this.props

    const selectedValues = (parcelFilterInfo.metaFilters[filterProperty] ||
      []) as string[]

    return (
      <>
        <Select
          disabled={availableValues.length === 0}
          multiple
          value={
            availableValues.length === 0
              ? [noAvailableValuesLabel]
              : selectedValues
          }
          onChange={this.handleSelectionChange}
          renderValue={(selected: any[]) => selected.join(', ')}
        >
          <MenuItem value={CLEAR_FILTER} disabled={isEmpty(selectedValues)}>
            <ListItemText
              primary={i18n.t(keys.forms.parcelFilter.resetSelection)}
              onClick={this.resetSelection}
            />
          </MenuItem>

          {availableValues.map((value) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={selectedValues.includes(value)} />
              <ListItemText primary={value} />
            </MenuItem>
          ))}
          {availableValues.length === 0 && (
            <MenuItem
              disabled
              key={noAvailableValuesLabel}
              value={noAvailableValuesLabel}
            >
              <ListItemText primary={noAvailableValuesLabel} />
            </MenuItem>
          )}
        </Select>
      </>
    )
  }

  handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
    let selection = event.target.value
    if (typeof selection === 'string') {
      selection = selection.split(', ')
    }
    if (selection.includes(CLEAR_FILTER)) {
      return
    }

    this.props.dispatch(
      actions.setParcelFilterInfo({
        ...this.props.parcelFilterInfo,
        metaFilters: {
          ...this.props.parcelFilterInfo.metaFilters,
          [this.props.filterProperty]: selection,
        },
      })
    )
  }

  resetSelection = () => {
    this.props.dispatch(
      actions.setParcelFilterInfo({
        ...this.props.parcelFilterInfo,
        metaFilters: {
          ...this.props.parcelFilterInfo.metaFilters,
          [this.props.filterProperty]: [],
        },
      })
    )
  }
}

const mapState = (state: RootStore, props: Props) => ({
  parcelFilterInfo: selectParcelFilterInfo(state),
  availableValues: props.availableValuesSelector(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  MetaFilterSelect
)
