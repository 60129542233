import { client, gql } from '../../../../graphql/client'

export interface GetOrganizationDeliveriesResult {
  deliveries: {
    id: string
    targetDeliveryDate: string
    comment: string
    order: {
      comment: string
    }
  }[]
}

export const fetchOranizationDeliveries = async (
  [organizationId, currentDeliveryId]: [string | undefined, string | undefined],
  skip: () => void
) => {
  if (organizationId === undefined || currentDeliveryId === undefined) {
    return skip()
  }

  const { deliveries } = await client.request<GetOrganizationDeliveriesResult>({
    query: gql`
      query GET_ORGANIZATION_DELIVERIES(
        $organizationId: Int!
        $currentDeliveryId: uuid!
      ) {
        deliveries: Delivery(
          where: {
            organizationId: { _eq: $organizationId }
            id: { _neq: $currentDeliveryId }
          }
          order_by: { targetDeliveryDate: asc }
        ) {
          order: Order {
            comment
          }
          id
          targetDeliveryDate
          comment
        }
      }
    `,
    variables: { organizationId, currentDeliveryId },
  })

  return deliveries
}
