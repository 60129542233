import * as React from 'react'
import {
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { selectPreferredLanguage } from '../../../../../../data/selectPreferredLanguage'
import { useRedux } from '../../../../../../hooks/useRedux'
import {
  DataSourceData,
  MapSource,
  SamplePlanCreateDataSet,
  SamplingStatisticalMethodDataSource,
  SamplingStatisticalMethodField,
  Translation,
} from '../../../../../../graphql/types'
import { SamplePlanSetUpFormField } from './SamplePlanSetUpFormField'
import { SamplePlanSetUpFormDataSource } from './SamplePlanSetUpFormDataSource'
import { classnames } from '../../../../../../util/classnames'
import { ExpandMore } from '@mui/icons-material'
import { SamplePlanMethodInfo } from './SamplePlanMethodInfo'
import i18n, { keys } from '../../../../../../i18n'

interface Props {
  id: string
  name: Translation
  info: {
    sections: { title: Translation; description: Translation }[]
    image: string
  }
  dataSources: SamplingStatisticalMethodDataSource[] | undefined
  fields: SamplingStatisticalMethodField[] | undefined
  currentNewSamplingPlan: SamplePlanCreateDataSet
  onStatisticalMethodChanged: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  onStatisticalMethodFieldChanged: <T>(name: string, data: T) => void
  onStatisticalMethodDataSourceChanged: (
    name: string,
    data: DataSourceData,
    dataSources: MapSource[]
  ) => void
  setDataSourceWarningActive: (active: boolean) => void
}

export const SamplePlanMethodOption = ({
  id,
  name,
  info,
  dataSources,
  fields,
  currentNewSamplingPlan,
  onStatisticalMethodChanged,
  onStatisticalMethodFieldChanged,
  onStatisticalMethodDataSourceChanged,
  setDataSourceWarningActive,
}: Props) => {
  const [state] = useRedux()
  const theme = useTheme()
  const preferredLanguage = selectPreferredLanguage(state)
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)
  return (
    <Stack direction="column" spacing={1}>
      <Stack
        sx={{
          borderRadius: theme.spacing(1),
          backgroundColor:
            currentNewSamplingPlan?.statisticalMethodTypeId === id
              ? '#00000033'
              : 'transparent',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
        }}
      >
        <Stack direction="row" justifyContent={'space-between'}>
          <FormControlLabel
            control={
              <Radio
                checked={currentNewSamplingPlan?.statisticalMethodTypeId === id}
                value={id}
                onChange={onStatisticalMethodChanged}
              />
            }
            label={name[preferredLanguage]}
          />
          <Stack direction="row" alignItems="center">
            <Typography
              color="#FFFFFFB2"
              onClick={() => setIsInfoOpen(!isInfoOpen)}
              variant="overline"
            >
              {i18n.t(keys.moreInfo)}
            </Typography>
            <IconButton
              style={{ backgroundColor: 'transparent', color: '#FFFFFFB2' }}
              onClick={() => setIsInfoOpen(!isInfoOpen)}
              disableRipple
            >
              <ExpandMore
                className={classnames('accordion-icon', ['open', isInfoOpen])}
              />
            </IconButton>
          </Stack>
        </Stack>
        <SamplePlanMethodInfo
          open={isInfoOpen}
          sections={info.sections}
          image={info.image}
        />
      </Stack>

      {((!!fields && fields.length > 0) ||
        (!!dataSources && dataSources.length > 0)) && (
        <Stack
          direction="row"
          sx={{
            borderRadius: theme.spacing(1),
            backgroundColor:
              currentNewSamplingPlan?.statisticalMethodTypeId === id
                ? '#00000033'
                : 'transparent',
          }}
          padding={currentNewSamplingPlan?.statisticalMethodTypeId === id ? 2 : 0}
          spacing={1}
        >
          {fields &&
            fields.length > 0 &&
            fields.map((f) => (
              <SamplePlanSetUpFormField
                key={f.id}
                statisticalMethodTypeId={id}
                currentNewSamplingPlan={currentNewSamplingPlan}
                field={f}
                onFieldChanged={onStatisticalMethodFieldChanged}
              />
            ))}
          {dataSources &&
            dataSources.length > 0 &&
            dataSources.map((ds) => (
              <SamplePlanSetUpFormDataSource
                key={ds.id}
                statisticalMethodTypeId={id}
                currentNewSamplingPlan={currentNewSamplingPlan}
                dataSource={ds}
                onDataSourceChanged={onStatisticalMethodDataSourceChanged}
                setDataSourceWarningActive={setDataSourceWarningActive}
              />
            ))}
        </Stack>
      )}
    </Stack>
  )
}
