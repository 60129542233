import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  DEFAULT_PAGE_SIZE,
  url,
  UrlInfo,
  urlMatch,
  urls,
} from '../../appNavigation/urls'
import * as React from 'react'

interface NavTab {
  label: string
  value: UrlInfo
}

const NAV: NavTab[] = [
  {
    label: 'Delivery Status',
    value: urls.deliveryStatus,
  },
  {
    label: 'Delivery Group File',
    value: urls.deliveryGroupFileStatus,
  },
  {
    label: 'Migration Status',
    value: urls.migrationStatus,
  },
]

class StatusHomeNavigation extends React.PureComponent<RouteComponentProps> {
  render() {
    const { pathname } = this.props.history.location

    const tab = NAV.find(({ value }) => urlMatch(value, pathname))

    if (!tab) {
      throw new Error(`bad uri ${pathname}`)
    }

    return (
      <Tabs value={tab.value}>
        {NAV.map(({ label, value }) => (
          <Tab
            key={label}
            value={value}
            component={() =>
              this.renderTab({
                label,
                value,
                disabled: tab.value === value,
              })
            }
          />
        ))}
      </Tabs>
    )
  }

  /**
   * Need to render a button to get around a dark-theme issue;
   * also need to pass onClick here because the button steals the
   * click event from the Tab component
   */
  renderTab = ({
    label,
    value,
    disabled,
  }: {
    label: string
    value: UrlInfo
    disabled: boolean
  }) => {
    return (
      <Button
        disabled={disabled}
        onClick={() =>
          this.handleTabChange(
            url(value, {}, { page: 0, pageSize: DEFAULT_PAGE_SIZE })
          )
        }
      >
        {label}
      </Button>
    )
  }

  handleTabChange = (value: string) => {
    this.props.history.push(value)
  }
}

export default withRouter(StatusHomeNavigation)
