import * as React from 'react'
import { url, urls } from '../../../appNavigation/urls'
import { Button } from '@mui/material'
import i18n from '../../../i18n'
import LinkChild from '../../../UI/LinkChild'
import { TableFormatter } from '../../../UI/Table/types'
import { enabledStatus, fulfilledStatus } from './ShortStatus'
import { ListDeliveryStatus } from './types'

export const tableFormatter: TableFormatter<ListDeliveryStatus>[] = [
  {
    header: () => 'Organization',
    data: ({ Organization: { name } }) => name,
    orderBy: (direction) => `{ Organization: { name: ${direction} } }`,
  },
  {
    header: () => 'Delivery',
    data: ({ id }) => (
      <LinkChild
        to={url(urls.editDelivery, {
          deliveryId: id,
        })}
      >
        <Button variant="outlined">Delivery</Button>
      </LinkChild>
    ),
  },
  {
    header: () => 'Target Date',
    data: ({ TargetDelivery: { date } }) => i18n.toDateShort(date),
    orderBy: (direction) => `{ TargetDelivery: { date: ${direction} } }`,
  },
  {
    header: () => 'Comment',
    data: ({ comment }) => comment,
    orderBy: (direction) => `{ comment: ${direction} }`,
  },
  {
    header: () => 'Fulfilled',
    // tslint:disable-next-line: no-shadowed-variable
    data: ({ View_DeliveryStatus }) => fulfilledStatus(View_DeliveryStatus),
    orderBy: (direction) =>
      `{ View_DeliveryStatus: { fulfilled: ${direction} } }`,
  },
  {
    header: () => 'Enabled',
    // tslint:disable-next-line: no-shadowed-variable
    data: ({ View_DeliveryStatus }) => enabledStatus(View_DeliveryStatus),
    orderBy: (direction) =>
      `{ View_DeliveryStatus: { enabled: ${direction} } }`,
  },
]
