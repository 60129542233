import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'
import { SAMPLE_PLAN_POINTS } from '../../../../pages/SamplePlan/hooks/util/sampleLayerHelper'

export class EnterSampleGenerator implements IMapActionGenerator {
  key = 'sample-enter'
  priority = Priority.Sample

  generateActionsFromQualifyingFeatures({
    map,
    features,
  }: IMapActionGeneratorParams): IMapAction[] {
    // Find the qualifying features.
    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) => f.layer.id === SAMPLE_PLAN_POINTS
    )

    // Return array of functions that can execute the action for each of the qualifying features.
    return qualifiers.map(() => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        map.getCanvas().style.cursor = 'pointer'
      },
    }))
  }
}
