import { createManager } from '../../util/worker/manager'
import {
  getApiUrl,
  getAuthToken,
} from '../../vvapi/apiResource/createApiResource'
import { StatsInputs, StatsOutputs } from './types'

const worker = new Worker(new URL('./stats.worker.ts', import.meta.url))

const manager = createManager(worker)

export const calculateStatsOnWorker = async (inputs: Partial<StatsInputs>) =>
  manager.invoke<Partial<StatsOutputs>>('calculateStats', {
    ...inputs,
    token: getAuthToken(),
    apiUrl: getApiUrl(),
  })
