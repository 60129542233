import { createSelector } from 'reselect'
import { selectStats } from './stats'
import { ProductStats } from '../calculateStats/productStats'

const DEFAULT: ProductStats = {}

export const selectQuantileStops = createSelector(
  [selectStats],
  (stats) => stats?.productQuantileStops ?? DEFAULT
)

export const selectProductRanges = createSelector(
  [selectStats],
  (stats) => stats?.productRange ?? DEFAULT
)

export const selectProductStats = createSelector(
  [selectStats],
  (stats) => stats?.productStats ?? DEFAULT
)
