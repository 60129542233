import { Theme } from '@mui/material'

export const getTextColor = (status?: string, theme?: Theme) => {
  if (!status || status === 'N/A') {
    return '#000000'
  }

  return theme?.palette.text.primary
}

export const getColor = (status?: string, theme?: Theme) => {
  if (!status) {
    return
  }
  if (status === 'not-ready') {
    return 'hsl(0, 0%, 55%)'
  }
  if (status.includes('error')) {
    return 'hsl(1, 77%, 55%)'
  }
  if (status.includes('timeout')) {
    return 'hsl(0, 0%, 62%)'
  }
  if (status.includes('cancelled')) {
    return '#f5bc42'
  }
  if (status.includes('running')) {
    return 'hsl(134, 25%, 55%)'
  }
  if (status.includes('complete')) {
    return 'hsl(134, 43%, 50%)'
  }
  if (status === 'N/A') {
    return `rgba(255,255,255,0.5)`
  }
  if (status === 'queued') {
    return 'hsl(133, 21%, 47%)'
  }

  if (status === 'expired') {
    return `#C38CCF`
  }

  return theme?.palette.mode === 'dark'
    ? theme?.palette.grey[700]
    : theme?.palette.grey[400]
}
