import * as React from 'react'

import {
  Checkbox,
  Icon,
  ListItemText,
  MenuItem,
  Radio,
  Tooltip,
} from '@mui/material'

import { selectInactiveProductIds } from '../data/selectAvailableProductIds'
import { selectIsAdmin } from '../data/selectMe'
import { selectSelectedLegendProductId } from '../data/selectSelectedLegendProductId'
import { selectSelectedProductIds } from '../data/selectSelectedProductIds'
import { MapLayerDefData } from '../data/types'
import * as userSelection from '../data/userSelectionRedux'
import { MapLayerDefGroup } from '../graphql/types'
import { useRedux } from '../hooks/useRedux'
import {
  addArrayValues,
  removeArrayValues,
  toggleArrayValue,
} from '../util/toggleArrayValue'

interface Props {
  group: MapLayerDefGroup
  products: MapLayerDefData[]
}

export const DataLayerMenuGroupItem = ({ group, products }: Props) => {
  const [state, dispatch] = useRedux()

  const isAdmin = selectIsAdmin(state)
  const selectedLegendProductId = selectSelectedLegendProductId(state)
  const selectedProductIds = selectSelectedProductIds(state)
  const inactiveProducts = selectInactiveProductIds(state)
  const pointLayer = products.find(
    (product) =>
      product.mapSourceDef.type === 'point' &&
      product.dataProperty.type === 'value'
  )

  const productIds = [
    ...products.map((product) => product.id),
    ...(pointLayer ? [`${pointLayer.id}-block-by-block`] : []),
  ]
  const isSelected = selectedProductIds.some((selectedProductId) =>
    productIds.some((productId) => selectedProductId === productId)
  )
  const isSelectedLegend = productIds.some(
    (productId) => selectedLegendProductId === productId
  )

  const { id: groupId, name } = group

  const selectedLayer =
    state.userSelection.selectedLayerByGroup?.[groupId] ?? products[0]?.id

  const handleLayerToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    dispatch(
      userSelection.update({
        selectedProductIds: toggleArrayValue(selectedProductIds, selectedLayer),
        selectedLegendProductId: selectedLegendProductId ?? selectedLayer,
      })
    )
  }

  const handleLegendSelect = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    dispatch(
      userSelection.update({
        selectedProductIds: addArrayValues(
          removeArrayValues(selectedProductIds, productIds),
          [selectedLayer]
        ),
        selectedLegendProductId: selectedLayer,
      })
    )
  }

  const handleExclusiveLayerSelect = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    dispatch(
      userSelection.update({
        selectedProductIds: [selectedLayer],
        selectedLegendProductId: selectedLayer,
      })
    )
  }

  return (
    <MenuItem onClick={handleExclusiveLayerSelect} key={productIds.join(',')}>
      <Checkbox
        checked={isSelected}
        onClick={handleLayerToggle}
        color="primary"
      />
      <ListItemText primary={name} style={{ whiteSpace: 'break-spaces' }} />
      {isAdmin && inactiveProducts.has(groupId) && (
        <Tooltip
          title="Layer is inactive"
          style={{
            position: 'absolute',
            left: 0,
            opacity: 0.7,
            transform: 'scale(0.7)',
          }}
        >
          <Icon fontSize="inherit">visibility_off</Icon>
        </Tooltip>
      )}
      <Radio
        checked={isSelectedLegend}
        onClick={handleLegendSelect}
        color="primary"
      />
    </MenuItem>
  )
}
