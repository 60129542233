import * as api from '../../../graphql/api'
import { FetchedOrganization } from './fetchOrganizationById'

export const updateOrganization = async ({
  id,
  ...update
}: FetchedOrganization) => {
  const choice = await sweetAlert(
    `Are you sure you want to update this Organization?`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: 'Update',
        },
      },
      dangerMode: true,
    }
  )

  // sweetalert returns null for "cancel"
  if (!choice) {
    return false
  }

  await api.organization.update({ pk: { id }, input: update })

  return true
}
