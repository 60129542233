import * as React from 'react'

import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'

import {
  Box,
  Button,
  Checkbox,
  Icon,
  ListItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { ThemeContext } from '@emotion/react'
import { ChartOptions } from 'chart.js'
import { createSelector } from 'reselect'
import { toggleStats } from '../../colorScheme/actions'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import { selectSelectedDeliveryParcelsArea } from '../../data/selectSelectedDeliveryParcelsArea'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import { selectActiveProductSettings } from '../../ProductSettings/store/selectors/selectActiveProductSettings'
import { updatePreferences } from '../../redux/preferences/redux'
import ChartJS from '../../UI/ChartJS/ChartJS'
import abbreviateNumber from '../../util/abbreviateNumber'
import { classnames } from '../../util/classnames'
import { formatNum } from '../../util/formatNumber'
import { downloadCSV, downloadXLSX } from '../../util/table'
import { selectConversionsByProductId } from '../selectors/selectConversionsByProductId'
import { selectProductStats } from '../selectors/selectProductStats'
import {
  selectBestUnitsByProductId,
  selectCalculateStats,
  selectConvertedSelectedColorProfiles,
} from '../selectors/stats'
import { selectStatsData } from '../selectStatsData'
import { toChartStopData } from '../toStopData'
import { StatsTable } from './StatsTable'
import './StatsWindow.scss'

function getTotalPlants(unitType: string, count: number) {
  if (unitType === 'count') {
    return abbreviateNumber(count, 1)
  }

  return i18n.t(keys.generic.na)
}

function getUsagePlantsCount(
  count: number,
  countMissing: number,
  unitType: string
) {
  if (unitType === 'count') {
    return `${formatNum(
      Math.floor((count / (count + countMissing)) * 100),
      false,
      0,
      1
    )}%`
  }

  return i18n.t(keys.generic.na)
}

export interface ChartData {
  label: string
  color?: string
  size: number
}

const configureChartData = (data: ChartData[]) => {
  const labels: string[] = []
  const colors: string[] = []
  const values: number[] = []

  data.forEach((next) => {
    labels.push(next.label)
    colors.push(next.color!)
    values.push(next.size)
  })

  const total = values.reduce((acc, next) => acc + next, 0)
  const percents = values.map((value) => (100 * value) / total)

  const chartData = {
    labels,
    datasets: [
      {
        backgroundColor: colors,
        data: values,
      },
    ],
  }

  return {
    percents,
    chartData,
  }
}

function StatsTitle() {
  const [state] = useRedux()
  const { statsWindowDocked } = state.preferences

  const settings = selectActiveProductSettings(state)
  const productId = selectSelectedLegendProductId(state)
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId]

  const colorProfile = selectedColorProfiles?.[settings.visualization!]

  const productDef = selectMapLayerDefsById(state)[productId]
  const productStats = selectProductStats(state)[productId]

  const statsData = selectStatsData(state)

  if (!productStats || !colorProfile) {
    return null
  }

  return (
    <ListItem
      divider
      id="draggable-dialog-title"
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 64,
        cursor: statsWindowDocked ? "auto" : "move"
      }}>
      <Typography style={{ paddingLeft: 16 }} noWrap>
        {i18n.t(keys.stats.headerTitle, {
          productName: productDef.name,
        })}
      </Typography>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography noWrap>{i18n.t(keys.stats.download)}</Typography>
        <Button disabled={!statsData} onClick={() => downloadCSV(statsData!)}>
          {i18n.t(keys.generic.csv)}
        </Button>
        <Button disabled={!statsData} onClick={() => downloadXLSX(statsData!)}>
          {i18n.t(keys.generic.xlsx)}
        </Button>
      </Box>
    </ListItem>
  )
}

function ValueStatsBar() {
  const [state] = useRedux()

  const productId = selectSelectedLegendProductId(state)
  const productStats = selectProductStats(state)[productId]
  const convert = selectConversionsByProductId(state)[productId]
  const productDef = selectMapLayerDefsById(state)[productId]
  const selectedParcelsArea = selectSelectedDeliveryParcelsArea(state)

  if (
    !productStats ||
    !productDef ||
    !productDef.dataProperty ||
    productDef.dataProperty.type !== 'value'
  ) {
    return null
  }

  const hideUsageAndVariance = productDef.name === 'PureVine™ Change Detection'

  const { combined, weightType, valueType } = productStats

  const totalSize = combined.data.stats.size + combined.coverage.size

  const totalPlantCount = getTotalPlants(weightType!, totalSize)
  const usagePlantsCount = getUsagePlantsCount(
    totalSize,
    combined.noData.size,
    weightType!
  )
  const totalArea = `${formatNum(selectedParcelsArea.value, false, 1, 1)} ${selectedParcelsArea.symbol
    }`

  const coefficientOfVariation = productDef.dataProperty.measurementType === 'absolute' ? `${formatNum(
    (combined.data.stats.cv ?? 0) * 100,
    false,
    0,
    1
  )}%` : 'N/A'

  let mean = combined.data.stats.mean!
  let stDev = combined.data.stats.stdev!

  if (convert && (valueType === 'area' || valueType === 'length')) {
    mean = convert(mean)
    stDev = convert(stDev)
  }

  return (
    <StatsTable
      hideUsageAndVariance={hideUsageAndVariance}
      plantCount={{
        value: totalPlantCount,
        label: i18n.t(keys.units.plantCountLabel),
      }}
      area={{
        value: totalArea,
        label: i18n.t(keys.units.areaLabel),
      }}
      mean={{
        value: formatNum(mean, false),
        label: i18n.t(keys.stats.mean),
      }}
      stdev={{
        value: formatNum(stDev, false),
        label: i18n.t(keys.stats.stDev),
      }}
      usage={{
        value: usagePlantsCount,
        label: i18n.t(keys.stats.usage),
      }}
      variance={{
        value: coefficientOfVariation,
        label: i18n.t(keys.stats.coefficientOfVariation),
      }}
    />
  )
}

function StatsChart() {
  const [state] = useRedux()
  const isMobile = useMediaQuery('(max-height: 740px)')
  const theme = React.useContext(ThemeContext) as any
  const prepareChartData = selectChartData(state)

  const v = React.useMemo(
    () => prepareChartData?.(theme, isMobile),
    [prepareChartData, theme, isMobile]
  )

  if (v == null) {
    return null
  }

  return <ChartJS type="bar" data={v.chartData} options={v.chartOptions} />
}

const selectActiveProductVisualization = createSelector(
  [selectActiveProductSettings],
  (settings) => settings.visualization
)

const selectChartData = createSelector(
  [
    selectActiveProductVisualization,
    selectSelectedLegendProductId,
    selectConvertedSelectedColorProfiles,
    selectPreferredLanguage,
    (state) => state.preferences.preferredUnitSystem,
    selectMapLayerDefsById,
    selectProductStats,
    (state) => state.preferences.preferredStatistics,
    selectBestUnitsByProductId,
    (state) => state.preferences.statsWindowMinimized,
  ],
  (
    visualization,
    productId,
    selectedColorProfilesByProductId,
    lang,
    preferredUnitSystem,
    mapLayerDefsById,
    productStatsById,
    selectedStatisticsType,
    bestUnitsByProductId,
    statsWindowMinimized
  ) => {
    const selectedColorProfiles = selectedColorProfilesByProductId[productId]
    const colorProfile = selectedColorProfiles?.[visualization!]

    const productDef = mapLayerDefsById[productId]
    const productStats = productStatsById[productId]

    const unit = bestUnitsByProductId[productId]

    if (!productStats || !colorProfile) {
      return null
    }

    const {
      stops: data,
      labelX,
      labelY,
      useIntegers,
      unit: unitLabel,
    } = toChartStopData({
      preferredUnitSystem,
      unit,
      layerDef: productDef,
      colorProfile: colorProfile as any,
      stats: productStats,
      lang,
    })

    const lengthOrArea = ['area', 'length'].includes(productStats.weightType!)

    const minimumFractionDigits = lengthOrArea ? 1 : undefined
    const maximumFractionDigits = lengthOrArea ? 1 : undefined

    const { chartData, percents } = configureChartData(data)

    return (theme: any, isMobile: boolean) => {
      console.log({ statsWindowMinimized, isMobile })
      const chartOptions = createChartOptions(
        theme,
        useIntegers,
        labelY,
        labelX,
        selectedStatisticsType,
        percents,
        minimumFractionDigits,
        maximumFractionDigits,
        unitLabel,
        statsWindowMinimized || isMobile
      )

      return { chartData, chartOptions }
    }
  }
)

function createChartOptions(
  theme: any,
  useIntegers: boolean,
  labelY: string,
  labelX: string,
  selectedStatisticsType: string,
  percents: number[],
  minimumFractionDigits: number | undefined,
  maximumFractionDigits: number | undefined,
  unitLabel: string,
  statsWindowMinimized: boolean
): ChartOptions {
  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 32,
        right: 16,
      },
    },
    color: theme.palette.text.primary,
    borderColor: theme.palette.divider,
    datasets: {
      bar: {
        minBarLength: 5,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: theme.palette.text.primary,
          callback(label) {
            return formatNum(Number(label), useIntegers)
          },
        },
        title: {
          display: true,
          color: theme.palette.text.primary,
          text: labelY,
        },
        grid: {
          color: theme.palette.divider,
        },
      },
      x: {
        ticks: { display: false, color: theme.palette.text.primary },
        title: {
          display: !statsWindowMinimized,
          color: theme.palette.text.primary,
          text: labelX,
        },
        grid: {
          color: theme.palette.divider,
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        clamp: true,
        color: theme.palette.text.primary,
        anchor: 'end',
        align: 'top',

        formatter(value, ctx) {
          return selectedStatisticsType === 'percentages'
            ? `${formatNum(percents[ctx.dataIndex], false, 1, 1)}%`
            : formatNum(
              value,
              useIntegers,
              minimumFractionDigits,
              maximumFractionDigits
            )
        },
      },
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const label = useIntegers
              ? formatNum(Number(ctx.parsed.y), true)
              : `${formatNum(
                Number(ctx.parsed.y),
                false,
                minimumFractionDigits,
                maximumFractionDigits
              )} ${unitLabel}`

            return `${label} | ${formatNum(
              percents[ctx.dataIndex!],
              false,
              1,
              1
            )}%`
          },
        },
      },
    },
  }
}

export function StatsWindow() {
  const [state, dispatch] = useRedux()
  const theme = React.useContext(ThemeContext) as any
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { statsWindowMinimized, statsWindowTab, statsWindowDocked } = state.preferences
  const calculateStats = selectCalculateStats(state)

  const toggleMinimized = () => {
    dispatch(updatePreferences({ statsWindowMinimized: !statsWindowMinimized }))
  }

  const toggleDocked = () => {
    dispatch(updatePreferences({ statsWindowDocked: !statsWindowDocked }))
  }

  const toggleStatsWindow = () => {
    dispatch(toggleStats({}))
  }
  const setTab = (tab: any) => {
    dispatch(updatePreferences({ statsWindowTab: tab }))
  }

  return (
    <div
      className={classnames(
        'StatsWindow',
        ['minimized', statsWindowMinimized],
        ['stats', statsWindowTab === 'stats']
      )}
    >
      <AsyncSelectorStatusOverlay requests={calculateStats}>
        <div>
          <Checkbox
            className="StatsWindow-Minimize"
            checked={statsWindowMinimized}
            onClick={toggleMinimized}
            color="default"
            style={{ padding: 4 }}
            disableRipple
            icon={
              <Icon style={{ color: 'var(--color)' }}>keyboard_arrow_down</Icon>
            }
            checkedIcon={
              <Icon style={{ color: 'var(--color)' }}>keyboard_arrow_up</Icon>
            }
          />
          {!isMobile ? (
            <Tooltip
              title={i18n.t(statsWindowDocked ? keys.popOutStats : keys.dockStats)}
              placement="top"
            >
              <Checkbox
                className="StatsWindow-Dock"
                checked={statsWindowDocked}
                onClick={toggleDocked}
                color="default"
                style={{ padding: 4 }}
                disableRipple
                icon={<Icon className="pin-icon">push_pin</Icon>}
                checkedIcon={<Icon className="pin-icon icon-crossed">push_pin</Icon>}
              />
            </Tooltip>
          ) : null}
          <Checkbox
            className="StatsWindow-Close"
            checked={true}
            onClick={toggleStatsWindow}
            color="default"
            style={{ padding: 4 }}
            disableRipple
            icon={<Icon style={{ color: 'var(--color)' }}>close</Icon>}
            checkedIcon={<Icon style={{ color: 'var(--color)' }}>close</Icon>}
          />
          <div className="StatsWindow-Tabs" id="draggable-dialog-title" style={ statsWindowDocked ? {} : { cursor: "move" } }>
            <Tabs
              style={{ minHeight: 0 }}
              value={statsWindowTab}
              onChange={(event: any, selectedTab: any) => setTab(selectedTab)}
              centered
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                style={{ padding: 8, fontSize: 12, minHeight: 0 }}
                value="chart"
                label={i18n.t(keys.stats.chart)}
              />
              <Tab
                style={{ padding: 8, fontSize: 12, minHeight: 0 }}
                value="stats"
                label={i18n.t(keys.stats.stats)}
              />
            </Tabs>
          </div>
        </div>
        <div className="StatsWindow-Stats">
          <StatsTitle />
          <ValueStatsBar />
        </div>
        <div className="StatsWindow-Chart">
          <StatsChart />
        </div>
      </AsyncSelectorStatusOverlay>
    </div>
  )
}
