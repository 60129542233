import * as React from 'react'
import { Parcel, RateMap } from '../../../graphql/types'
import Exporter from '../../../hooks/useExportable/formats/formatters'
import {
  DownloadFunction,
  useExportable,
} from '../../../hooks/useExportable/useExportable'
import { useRateMapContext } from '../editor/RateMapContext'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../constants'
import { selectSelectedMapSources } from '../../../data/selectSelectedMapSources'
import { useRedux } from '../../../hooks/useRedux'
import { useGenerateAmendmentZones } from './useGenerateAmendmentZones'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { selectSelectedLegendProductId } from '../../../data/selectSelectedLegendProductId'
import { selectConvertedSelectedColorProfiles } from '../../../ProductStats/selectors/stats'
import { selectActiveProductSettings } from '../../../ProductSettings/store/selectors/selectActiveProductSettings'
import {
  feature,
  Feature,
  FeatureCollection,
  featureCollection,
  MultiPolygon,
  Polygon,
  Properties,
} from '@turf/helpers'
import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { unionMany } from '../../../util/unionMany'
import i18n, { keys } from '../../../i18n'
import area from './../../../util/units/area'
import { MapSourceData } from '../../../data/types'
import { getStopIndex } from '../../../ProductStats/calculateStats/calcStats'
import {
  getApiUrl,
  getAuthToken,
} from '../../../vvapi/apiResource/createApiResource'

const eviPropertyName = 'EVI'
const areaPropertyName = 'Area_m2'

const getMapSourceFeatures = async (sources: MapSourceData[]) => {
  let fcs: Record<string, FeatureCollection> = {}
  for (const source of sources) {
    const res = await fetch(
      `${getApiUrl()}/api/v3/map-source/${source.id}/product.geojson`,
      {
        headers: {
          Authorization: getAuthToken()!,
        },
      }
    )

    if (res.ok) {
      const geoJson = (await res.json()) as FeatureCollection<
        MultiPolygon | Polygon,
        Properties
      >

      fcs[source.parcelId!] = geoJson
    }
  }

  return fcs
}

export const useRateMapExportable = (): [DownloadFunction] => {
  const [state] = useRedux()
  const { rateMap, amendmentTypes, unitTypes } = useRateMapContext()
  const [rateMapAmendments] = useGenerateAmendmentZones()
  const preferredLanguage = selectPreferredLanguage(state)
  const preferredUnitSystem = state.preferences.preferredUnitSystem
  const productId = selectSelectedLegendProductId(state)
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId]
  const settings = selectActiveProductSettings(state)

  const selectedMapSources = selectSelectedMapSources(state)

  const rateMapMapSources = React.useMemo(() => {
    const pureVineZoneSources = selectedMapSources?.[pureVineZonesLayerID]
    const vigorZoneSources = selectedMapSources?.[vigorZonesLayerID]
    return [
      ...(pureVineZoneSources ? pureVineZoneSources : []),
      ...(vigorZoneSources ? vigorZoneSources : []),
    ]
  }, [selectedMapSources])

  const getRateMapZoneFeaturesByParcelIdAndStopIndex =
    React.useCallback(async () => {
      const featuresByParcel = await getMapSourceFeatures(rateMapMapSources)

      const colorProfile =
        selectedColorProfiles &&
        (selectedColorProfiles[settings.visualization!] ||
          selectedColorProfiles.absolute ||
          selectedColorProfiles.relative ||
          selectedColorProfiles.threshold)

      const dataStops = colorProfile?.dataStops?.map(([stop]) => stop) ?? []

      const filteredFeaturesByParcelIdAndStopIndex: Record<
        string,
        Record<string, Feature<Polygon | MultiPolygon, Properties>[]>
      > = {}
      for (const [parcelId, featureCollection] of Object.entries(
        featuresByParcel
      )) {
        filteredFeaturesByParcelIdAndStopIndex[parcelId] =
          filteredFeaturesByParcelIdAndStopIndex[parcelId] ?? {}

        for (const feature of featureCollection.features) {
          const stopIndex = getStopIndex(
            { value: feature.properties?.[eviPropertyName] },
            dataStops as number[],
            'value'
          )

          filteredFeaturesByParcelIdAndStopIndex[parcelId][stopIndex] =
            filteredFeaturesByParcelIdAndStopIndex[parcelId][stopIndex] ?? []
          filteredFeaturesByParcelIdAndStopIndex[parcelId][stopIndex].push(
            feature as Feature<Polygon | MultiPolygon, Properties>
          )
        }
      }

      return filteredFeaturesByParcelIdAndStopIndex
    }, [rateMapMapSources, selectedColorProfiles, settings.visualization])

  const formatCsv = React.useCallback(
    (
      rateMap: RateMap,
      parcelSelection: Parcel[],
      rateMapZoneFeaturesByParcelIdAndStopIndex: Record<
        string,
        Record<string, MapboxGeoJSONFeature[]>
      >
    ) => {
      if (!rateMapAmendments) {
        return []
      }

      const data: any[] = []

      const rateUnit = unitTypes[rateMap.unitTypeId].name[
        preferredUnitSystem
      ]?.[preferredLanguage]
        ?.replaceAll('/', '_')
        ?.toUpperCase()

      const amountUnit = rateUnit?.split('_')?.[0]

      const total: any = {
        [i18n.t(keys.stats.block)]: 'Total',
        EVI_Class: '-',
        Area_Ac: 0,
        Area_Ha: 0,
        [i18n.t(keys.rateMapAmendmentTypeTitle)]:
          amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
            preferredLanguage
          ] ??
          amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
          '',

        [`${i18n.t(keys.rateMapRate)}_${rateUnit}`]: '-',
        [`${i18n.t(keys.rateMapAmount)}_${amountUnit}`]: 0,
      }

      for (const parcel of parcelSelection) {
        for (const amendment of rateMapAmendments) {
          const zoneInfo =
            rateMapZoneFeaturesByParcelIdAndStopIndex[parcel.id]?.[
              amendment.stopIndex
            ]
          if (!zoneInfo || zoneInfo.length === 0) {
            continue
          }

          const zoneArea = zoneInfo?.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[areaPropertyName] ?? 0),
            0
          )

          const convertedAreaAcres = area.convert(
            zoneArea,
            area.units.squareMeter,
            area.units.acre,
            'acre'
          ).value

          const convertedAreaHectares = area.convert(
            zoneArea,
            area.units.squareMeter,
            area.units.hectare,
            'hectare'
          ).value

          const amount =
            (amendment.rateOfApplication ?? 0) *
            (preferredUnitSystem === 'metric'
              ? convertedAreaHectares
              : convertedAreaAcres)

          const row: any = {
            [i18n.t(keys.stats.block)]: parcel.name,
            EVI_Class: amendment.value,
            Area_Ac: convertedAreaAcres,
            Area_Ha: convertedAreaHectares,
            [i18n.t(keys.rateMapAmendmentTypeTitle)]:
              amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
                preferredLanguage
              ] ??
              amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
              '',
            [`${i18n.t(keys.rateMapRate)}_${rateUnit}`]:
              amendment.rateOfApplication,
            [`${i18n.t(keys.rateMapAmount)}_${amountUnit}`]: amount,
          }
          data.push(row)

          total.Area_Ac += row.Area_Ac
          total.Area_Ha += row.Area_Ha
          total[`${i18n.t(keys.rateMapAmount)}_${amountUnit}`] +=
            row?.[`${i18n.t(keys.rateMapAmount)}_${amountUnit}`] ?? 0
        }
      }

      data.push(total)

      return data
    },
    [
      rateMapAmendments,
      amendmentTypes,
      preferredLanguage,
      preferredUnitSystem,
      unitTypes,
    ]
  )

  const formatGeoJson = React.useCallback(
    (
      rateMap: RateMap,
      parcelSelection: Parcel[],
      rateMapZoneFeaturesByParcelIdAndStopIndex: Record<
        string,
        Record<string, MapboxGeoJSONFeature[]>
      >
    ) => {
      if (!rateMapZoneFeaturesByParcelIdAndStopIndex) {
        return featureCollection([])
      }

      const features: Feature<Polygon | MultiPolygon>[] = []

      const rateUnit = unitTypes[rateMap.unitTypeId].name[
        preferredUnitSystem
      ]?.[preferredLanguage]
        ?.replaceAll('/', '_')
        ?.toUpperCase()

      const amountUnit = rateUnit?.split('_')?.[0]

      for (const parcel of parcelSelection) {
        for (const amendment of rateMapAmendments) {
          const zoneInfo = rateMapZoneFeaturesByParcelIdAndStopIndex[
            parcel.id
          ]?.[amendment.stopIndex] as Feature<Polygon>[]
          if (!zoneInfo || zoneInfo.length === 0) {
            continue
          }
          const eviTotal = zoneInfo.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[eviPropertyName] ?? 0),
            0
          )
          const EVImean = eviTotal / zoneInfo.length

          const zoneArea = zoneInfo?.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[areaPropertyName] ?? 0),
            0
          )

          const convertedAreaAcres = area.convert(
            zoneArea,
            area.units.squareMeter,
            area.units.acre,
            'acre'
          ).value

          const convertedAreaHectares = area.convert(
            zoneArea,
            area.units.squareMeter,
            area.units.hectare,
            'hectare'
          ).value

          const amount =
            (amendment.rateOfApplication ?? 0) *
            (preferredUnitSystem === 'metric'
              ? convertedAreaHectares
              : convertedAreaAcres)

          const multiPolygon = unionMany(zoneInfo)

          if (multiPolygon) {
            features.push(
              feature(multiPolygon.geometry, {
                EVImean,
                EVI_Class: amendment.value,
                Area_Ac: convertedAreaAcres,
                Area_Ha: convertedAreaHectares,
                [i18n.t(keys.rateMapAmendmentTypeTitle)]:
                  amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
                    preferredLanguage
                  ] ??
                  amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
                  '',
                [`${i18n.t(keys.rateMapRate)}_${rateUnit}`]:
                  amendment.rateOfApplication,
                [`${i18n.t(keys.rateMapAmount)}_${amountUnit}`]: amount,
              })
            )
          }
        }
      }
      return featureCollection(features)
    },
    [
      amendmentTypes,
      preferredLanguage,
      preferredUnitSystem,
      rateMapAmendments,
      unitTypes,
    ]
  )

  const [download] = useExportable<RateMap>({
    data: rateMap ?? ({} as RateMap),
    formatters: [
      Exporter.exportCsv(formatCsv, 'csv'),
      Exporter.exportGeoJson(formatGeoJson, 'geojson'),
      Exporter.exportShapefile(formatGeoJson, 'shapefile'),
    ],
  })

  const downloadWithArgs = React.useCallback(
    async (
      formatName: string,
      downloadName: string,
      parcelSelection: Parcel[]
    ) => {
      const rateMapZoneFeaturesByParcelIdAndStopIndex =
        await getRateMapZoneFeaturesByParcelIdAndStopIndex()
      download(
        formatName,
        downloadName,
        parcelSelection,
        rateMapZoneFeaturesByParcelIdAndStopIndex
      )
    },
    [download, getRateMapZoneFeaturesByParcelIdAndStopIndex]
  )

  return [downloadWithArgs]
}
