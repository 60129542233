import { PureComponent } from 'react'

import bbox from '@turf/bbox'
import { connect } from '../../redux/connect'
import { RootStore } from '../../redux/types'
import withMap, { WithMap } from '../withMap'
import { selectNotesById } from '../../notes/selectors'

class FitSelectedNote extends PureComponent<WithMap & ReduxProps> {
  componentDidUpdate(prevProps: this['props']) {
    if (prevProps.fitEditingTimestamp !== this.props.fitEditingTimestamp) {
      this.fitSelectedNote()
    }
  }

  render() {
    return null
  }

  fitSelectedNote = () => {
    const { notes, noteId, map } = this.props

    const note = notes[noteId!]
    if (!note || !note.feature.geometry) {
      return
    }

    const bounds = bbox(note.feature.geometry)
    map.fitBounds(bounds as any, {
      padding: 30,
      duration: 0,
      maxZoom: 19,
    })
  }
}

const mapState = (state: RootStore) => ({
  notes: selectNotesById(state),
  noteId: state.notes.editingNoteId,
  fitEditingTimestamp: state.notes.fitEditingTimestamp,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, {}>(mapState)(withMap(FitSelectedNote))
