export class Heap<T> {
  private heap: T[]
  constructor(
    private comparator: (elementA: T, elementB: T) => boolean = (a, b) => a < b
  ) {
    this.heap = []
  }

  public push = (element: T) => {
    this.heap.push(element)

    let curr = this.heap.length - 1

    while (curr > 0) {
      let parent = Math.floor((curr - 1) / 2)
      if (this.comparator(this.heap[curr], this.heap[parent])) {
        // quick swap
        ;[this.heap[curr], this.heap[parent]] = [
          this.heap[parent],
          this.heap[curr],
        ]
        // update the index of newKey
        curr = parent
      } else {
        // if no swap, break, since we heap is stable now
        break
      }
    }
  }

  public getArray = () => {
    return this.heap
  }
}
