import './Table.scss'

import * as React from 'react'

import { Tooltip, Typography } from '@mui/material'

import { classnames } from '../../util/classnames'
import { toggleArrayValue } from '../../util/toggleArrayValue'
import { ExpandButton } from '../Expand/ExpandButton'
import { SelectBox, selectionOf } from '../SelectBox/SelectBox'
import { TablePagination, TablePaginationProps } from './TablePagination'
import { TableAction, TableFormatter, TableOrder } from './types'
import { ArrowDownward, ArrowUpward, FilterList, Sort } from '@mui/icons-material'
import i18n, { keys } from '../../i18n'

export interface TableProps<T> extends TablePaginationProps {
  stickyOffset?: number
  formatter: TableFormatter<T>[]
  actions?: TableAction<T>[]
  title?: string
  rows: T[]
  selection?: T[]
  rowClass?: (row: T) => string[]
  onRowClick?: (row: T) => void
  onSelectionChange?: (rows: T[]) => void
  order?: TableOrder
  onOrderChange?: (order: TableOrder) => void
  expansion?: T[]
  fixed?: boolean
  onExpansionChange?: (rows: T[]) => void
  renderDetailsRow?: (row: T) => React.ReactNode
}
const CLASS_NAMES_RETURN: string[] = []
const CLASS_NAMES = () => CLASS_NAMES_RETURN
const noop = () => undefined

export class Table<T> extends React.PureComponent<TableProps<T>> {
  handleOrderChange = (index: number) => {
    const { order = [], onOrderChange = noop } = this.props
    const newOrder = order.slice()

    const foundIndex = newOrder.findIndex((item) => item.index === index)

    if (foundIndex === -1) {
      newOrder.unshift({ index, direction: 'asc' })
    } else {
      const found = newOrder[foundIndex]
      newOrder.splice(foundIndex, 1)

      if (found.direction === 'asc') {
        newOrder.unshift({
          index,
          direction: 'desc',
        })
      }
    }

    onOrderChange(newOrder)
  }

  headerOrder = (index: number) => {
    const { order = [] } = this.props

    const direction = order.find((item) => item.index === index)
    const icon = direction ? (
      direction.direction === 'asc' ? (
        <ArrowUpward
          style={{
            verticalAlign: 'middle',
            fontSize: '12px',
            paddingLeft: '2px',
          }}
        />
      ) : (
        <ArrowDownward
          style={{
            verticalAlign: 'middle',
            fontSize: '12px',
            paddingLeft: '2px',
          }}
        />
      )
    ) : (
      <Sort
        style={{
          verticalAlign: 'middle',
          fontSize: '12px',
          paddingLeft: '2px',
        }}
      />
    )

    return (
      <Tooltip
        title={i18n.t(keys.orderIconTooltip)}
        enterDelay={500}
        placement="top"
        arrow
      >
        {icon}
      </Tooltip>
    )
  }

  headerFilter = () => {
    return (
      <Tooltip
        title={i18n.t(keys.filterIconTooltip)}
        enterDelay={500}
        placement="top"
        arrow
      >
        <FilterList
          style={{
            verticalAlign: 'middle',
            fontSize: '12px',
            paddingLeft: '2px',
          }}
        />
      </Tooltip>
    )
  }

  selectionHeader = () => {
    const {
      onExpansionChange,
      onSelectionChange,
      selection = [],
      rows,
      stickyOffset,
    } = this.props

    if (!onExpansionChange && !onSelectionChange) {
      return null
    }

    const headerSelection = selectionOf(selection.length, rows.length)

    return (
      <th className="TableHeader" style={{ top: stickyOffset }}>
        {onExpansionChange && <ExpandButton disabled style={{ opacity: 0 }} />}
        {onSelectionChange && (
          <SelectBox
            disabled={rows.length === 0}
            selection={headerSelection}
            onClick={() =>
              headerSelection === 'selected'
                ? onSelectionChange([])
                : onSelectionChange(rows)
            }
          />
        )}
      </th>
    )
  }

  selectionColumn = (row: T) => {
    const {
      onExpansionChange,
      onSelectionChange,
      expansion = [],
      selection = [],
    } = this.props
    if (!onExpansionChange && !onSelectionChange) {
      return null
    }

    return (
      <td className="TableData">
        {onExpansionChange && (
          <ExpandButton
            expanded={expansion.includes(row)}
            onClick={(e) => {
              this.handleRowExpantion(row)
              e.stopPropagation()
            }}
          />
        )}
        {onSelectionChange && (
          <SelectBox
            selection={selection.includes(row) ? 'selected' : 'unselected'}
            onClick={(e) => {
              this.handleRowSelection(row)
              e.stopPropagation()
            }}
          />
        )}
      </td>
    )
  }

  handleRowSelection = (row: T) => {
    const { selection = [], onSelectionChange } = this.props

    if (onSelectionChange) {
      onSelectionChange(toggleArrayValue(selection, row))
    }
  }

  handleRowExpantion = (row: T) => {
    const { expansion = [], onExpansionChange } = this.props

    if (onExpansionChange) {
      onExpansionChange(toggleArrayValue(expansion, row))
    }
  }

  renderTableActions() {
    const { actions, selection = [], onSelectionChange } = this.props

    if (!actions) {
      return null
    }

    const tableActions = onSelectionChange
      ? actions
      : actions.filter(({ selectionRequired }) => !selectionRequired)

    if (tableActions.length === 0) {
      return null
    }

    return (
      <div className="TableActions">
        {tableActions
          .map(({ selectionRequired, multi, action, button }, index) => {
            const disabled = selectionRequired
              ? multi
                ? selection.length === 0
                : selection.length !== 1
              : false

            return (
              <div className="TableAction" key={index}>
                {button({
                  disabled,
                  onClick: (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    action(selection)
                  },
                })}
              </div>
            )
          })
          .reverse()}
        {onSelectionChange && (
          <div className="TableAction">
            {`${selection.length} ${
              selection.length === 1 ? 'item' : 'items'
            } selected`}
          </div>
        )}
      </div>
    )
  }

  actionHeader = () => {
    const { actions, stickyOffset } = this.props

    if (!actions) {
      return null
    }

    const rowActions = actions.filter(
      ({ selectionRequired }) => selectionRequired
    )

    if (rowActions.length === 0) {
      return null
    }

    return (
      <th className="TableHeader" style={{ top: stickyOffset }}>
        <div className="TableRowActions">Actions</div>
      </th>
    )
  }

  actionColumn = (row: T) => {
    const { actions } = this.props

    if (!actions) {
      return null
    }

    const rowActions = actions.filter(
      ({ selectionRequired }) => selectionRequired
    )

    if (rowActions.length === 0) {
      return null
    }

    return (
      <td className="TableData">
        <div className="TableRowActions">
          {rowActions.map(({ button, action, disabled, visible }) => {
            if (visible ? visible([row]) : true) {
              return button({
                disabled: disabled ? disabled([row]) : false,
                onClick: (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  action([row])
                },
              })
            }

            return null
          })}
        </div>
      </td>
    )
  }

  detailsRow(row: T, key: string) {
    const {
      expansion = [],
      formatter,
      renderDetailsRow,
      actions = [],
    } = this.props

    if (!renderDetailsRow) {
      return null
    }

    let colSpan = formatter.length + 1

    const actionsRenderd = actions.find(
      ({ selectionRequired }) => !!selectionRequired
    )

    if (actionsRenderd) {
      colSpan += 1
    }

    return (
      expansion.includes(row) && (
        <tr key={key}>
          <td colSpan={colSpan} className="TableData">
            <div className="DetailsRow">{renderDetailsRow(row)}</div>
          </td>
        </tr>
      )
    )
  }

  render() {
    const {
      title,
      selection = [],
      rows,
      rowClass = CLASS_NAMES,
      onRowClick = noop,
      onOrderChange,
      stickyOffset,
    } = this.props

    const { formatter, fixed } = this.props

    const pagination = <TablePagination {...this.props} />

    return (
      <div className="TableRoot">
        {title && (
          <div className="TableTitle">
            <h2 className="titleText">{title}</h2>
            {this.renderTableActions()}
          </div>
        )}
        {pagination}
        <table
          className="Table"
          style={{ tableLayout: fixed ? 'fixed' : undefined }}
        >
          <thead className="TableHead">
            <tr className="TableRow" key="selection-header">
              {this.selectionHeader()}
              {formatter.map(
                ({ header, data, orderBy, compare, filter, filterBy }, index) => {
                  const orderable = orderBy != null || compare != null
                  const filterable = filterBy != null || filter != null

                  // if there is no header or data, table formatter is for filtering and ordering only. 
                  if(!header && !data){
                    return null
                  }

                  return (
                    <td
                      key={index}
                      className={classnames('TableHeader', [
                        'orderable',
                        orderable,
                      ])}
                      style={{ top: stickyOffset }}
                      onClick={() => orderable && this.handleOrderChange(index)}
                    >
                      {orderable && onOrderChange && (
                        <Tooltip title={'Sort'}>
                          <>
                            <Typography variant="overline">
                              {header?.()}
                            </Typography>
                            {this.headerOrder(index)}
                            {filterable ? this.headerFilter() : null}
                          </>
                        </Tooltip>
                      )}
                      {orderable && !onOrderChange && (
                        <>
                          <Typography variant="overline">{header?.()}</Typography>
                          {this.headerOrder(index)}
                          {filterable ? this.headerFilter() : null}
                        </>
                      )}
                      {!orderable && (
                        <>
                          <Typography variant="overline">{header?.()}</Typography>
                          {filterable ? this.headerFilter() : null}
                        </>
                      )}
                    </td>
                  )
                }
              )}
              {this.actionHeader()}
            </tr>
          </thead>
          <tbody className="TableBody">
            {rows.map((row, rowIndex) => [
              <tr
                key={`row-${rowIndex}`}
                className={classnames(
                  'TableRow',
                  ['selected', selection.includes(row)],
                  ...rowClass(row)
                )}
                onClick={() => {
                  onRowClick(row)
                  this.handleRowSelection(row)
                }}
              >
                {this.selectionColumn(row)}
                {formatter.map(
                  (
                    { header, data, colClass = CLASS_NAMES, span = () => 1 },
                    colIndex
                  ) => {
                    const colSpan = span(row)
                    if (colSpan === 0) {
                      return null
                    }

                    // if there is no header or data, table formatter is for filtering and ordering only.
                    if (!header && !data) {
                      return null
                    }

                    return (
                      <td
                        colSpan={colSpan}
                        key={colIndex}
                        className={classnames('TableData', ...colClass(row))}
                      >
                        {data?.(row)}
                      </td>
                    )
                  }
                )}
                {this.actionColumn(row)}
              </tr>,
              this.detailsRow(row, `row-details-${rowIndex}`),
            ])}
          </tbody>
        </table>
        {pagination}
      </div>
    )
  }
}
