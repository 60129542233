import { Button, Tooltip } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import * as React from 'react'
import { TableAction } from './types'

export const createTableActionButton =
  ({
    title,
    ...props
  }: {
    title: string
    style?: React.CSSProperties
    color?: ButtonProps['color']
    variant?: ButtonProps['variant']
    children?: ButtonProps['children']
    key: string
  }): TableAction<any>['button'] =>
  ({ disabled, onClick }) => {
    const { children } = props

    return (
      <Tooltip title={title} key={props.key}>
        <Button {...props} disabled={disabled} onClick={onClick}>
          {children}
        </Button>
      </Tooltip>
    )
  }
