import * as React from 'react'
import { Divider, Stack, Typography, useTheme } from '@mui/material'
import i18n, { keys } from '../../../../../i18n'
import { SamplePlanCompleteFormProps } from '../types/SamplePlanFormProps'
import { MapContext, MapContextProvider } from '../../../../../map/withMap'
import { LoadingControlBackdrop } from '../../../../../UI/Loading/LoadingControlBackdrop'
import useAsync from '../../../../../hooks/useAsync'
import { generateSamples } from '../../../queries'
import { SamplePlanMap } from '../../../SamplePlanMap'
import { useSamplePlanMapSourceAndLayers } from '../../../hooks/useSamplePlanMapSourceAndLayers'
import { selectPreferredLanguage } from '../../../../../data/selectPreferredLanguage'
import { useRedux } from '../../../../../hooks/useRedux'
import { selectMe } from '../../../../../data/selectMe'
import { selectOrganizationId } from '../../../../../data/selectOrganizationId'
import { MapSource } from '../../../../../graphql/types'

interface Props {
  dataSources?: MapSource[]
}

type Ref = HTMLDivElement

const SamplePlanConfirmationWithMapContext = (
  props: SamplePlanCompleteFormProps<Ref> & Props
) => {
  return (
    <div ref={props.forwardedRef}>
      <MapContextProvider key={`NEW-SP-CONFIRM-MAP-collapsed`}>
        <SamplePlanConfirmationForm {...props} />
      </MapContextProvider>
    </div>
  )
}

const SamplePlanConfirmationForm = ({
  currentNewSamplingPlan,
  completedSamplingPlan,
  setCompletedSamplingPlan,
  registerValidation,
  stage,
  dataSources,
}: SamplePlanCompleteFormProps<Ref> & Props) => {
  const [state] = useRedux()
  const organizationId = selectOrganizationId(state)
  const me = selectMe(state)
  const [mapLoading, setMapLoading] = React.useState(true)
  const preferredLanguage = selectPreferredLanguage(state)
  const { map } = React.useContext(MapContext)
  const sampleCountStackRef = React.useRef<HTMLDivElement>(null)
  const theme = useTheme()

  const [fetchCreatedSamplePlan] = useAsync(generateSamples, [
    currentNewSamplingPlan,
    organizationId,
    me?.id,
    completedSamplingPlan,
  ])

  React.useEffect(() => {
    if (!fetchCreatedSamplePlan?.result?.data) {
      return
    }
    setCompletedSamplingPlan(fetchCreatedSamplePlan?.result?.data)
  }, [fetchCreatedSamplePlan?.result?.data, setCompletedSamplingPlan])

  useSamplePlanMapSourceAndLayers({
    parcels: currentNewSamplingPlan.selectedParcels,
    selectedParcels: currentNewSamplingPlan.selectedParcels,
    samples: completedSamplingPlan?.Samples,
    boundsPadding: 60,
    samplePlanBlocks: completedSamplingPlan?.SamplePlanBlocks ?? [],
    showGeneratedFeatures: true,
    dataSources: dataSources,
    fields: currentNewSamplingPlan?.statisticalMethodFields,
  })

  React.useEffect(() => {
    if (!map) {
      return
    }
    // wait until animation is completed so map is rendered correctly.
    setMapLoading(true)
    const timer = setTimeout(() => {
      map.resize()
      setMapLoading(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [map])

  React.useEffect(() => {
    registerValidation(() => true, stage)
  }, [registerValidation, stage])

  const SamplePlanInfoSection = ({
    title,
    children,
  }: {
    title?: string
    children: React.ReactNode
  }) => {
    return (
      <Stack
        direction="column"
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.spacing(1),
          padding: theme.spacing(2),
        }}
      >
        <Stack direction="row">
          <Typography
            variant="overline"
            color="#FFFFFFB2"
            fontSize={14}
            lineHeight="normal"
          >
            {title}
          </Typography>
        </Stack>
        {children}
      </Stack>
    )
  }

  const SamplePlanValueText = ({ label }: { label: string }) => {
    return (
      <Typography fontWeight={600} fontSize={16}>
        {label}
      </Typography>
    )
  }

  return (
    <Stack className="sample-plan-new-stage-form" direction="row" spacing={2}>
      <Stack
        direction="column"
        spacing={2}
        sx={{ width: '30%', overflowY: 'auto', overflowX: 'hidden' }}
      >
        <SamplePlanInfoSection title={i18n.t(keys.samplePlanNewStep1Label)}>
          <Typography fontWeight={600} fontSize={16}>
            {currentNewSamplingPlan?.samplePlanName ?? '-'}
          </Typography>
        </SamplePlanInfoSection>
        <SamplePlanInfoSection title={i18n.t(keys.samplePlanNewStep2Label)}>
          <Typography fontWeight={600} fontSize={16}>
            {!currentNewSamplingPlan.NoteForm?.organizationId
              ? i18n.t(
                  `noteForm.reservedNames.${currentNewSamplingPlan?.NoteForm?.name}`
                )
              : currentNewSamplingPlan?.NoteForm?.name ?? '-'}
          </Typography>
        </SamplePlanInfoSection>
        <SamplePlanInfoSection title={i18n.t(keys.samplePlanNewStep3Label)}>
          <Typography fontWeight={600} fontSize={16}>
            {currentNewSamplingPlan?.selectedParcels?.[0].OrganizationGroup
              ?.name ?? '-'}
          </Typography>
        </SamplePlanInfoSection>
        <SamplePlanInfoSection title={i18n.t(keys.samplePlanNewStep4Label)}>
          <Stack direction="column" spacing={2}>
            <Stack direction="column">
              <Typography
                fontWeight={400}
                fontSize={16}
                color="#FFFFFFB2"
              >{`${i18n.t(
                keys.samplePlanNewStatisticalMethodLabel
              )}: `}</Typography>
              <SamplePlanValueText
                label={`${
                  currentNewSamplingPlan?.SamplingStatisticalMethod?.name?.[
                    preferredLanguage
                  ] ?? '-'
                }`}
              />
            </Stack>
            {currentNewSamplingPlan?.SamplingStatisticalMethod
              ?.SamplingStatisticalMethodFields &&
              currentNewSamplingPlan?.SamplingStatisticalMethod?.SamplingStatisticalMethodFields?.map(
                (f) => {
                  return (
                    <Stack key={f.id} direction="column">
                      <Typography
                        fontWeight={400}
                        fontSize={16}
                        color="#FFFFFFB2"
                      >{`${f.name?.[preferredLanguage]}: `}</Typography>
                      <SamplePlanValueText
                        label={`${
                          currentNewSamplingPlan?.statisticalMethodFields?.[
                            f.type
                          ] ?? '-'
                        }`}
                      />
                    </Stack>
                  )
                }
              )}
            {currentNewSamplingPlan?.SamplingStatisticalMethod
              ?.SamplingStatisticalMethodDataSources &&
              currentNewSamplingPlan?.SamplingStatisticalMethod?.SamplingStatisticalMethodDataSources.map(
                (ds) => {
                  return (
                    <Stack key={ds.id} direction="column">
                      <Typography
                        fontWeight={400}
                        fontSize={16}
                        color="#FFFFFFB2"
                      >{`${ds.name?.[preferredLanguage]}: `}</Typography>
                      <SamplePlanValueText
                        label={`${
                          currentNewSamplingPlan
                            ?.statisticalMethodDataSources?.[ds.id]
                            ?.flightDate ?? '-'
                        }`}
                      />
                    </Stack>
                  )
                }
              )}
          </Stack>
        </SamplePlanInfoSection>
        <SamplePlanInfoSection title={i18n.t(keys.samplePlanNewStep5Label)}>
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ width: '80%' }}
          >
            <Stack direction="column" spacing={2} mt={1}>
              <Typography
                variant="subtitle1"
                lineHeight={0.75}
                fontSize={16}
                color="#FFFFFFB2"
              >
                {i18n.t(keys.samplePlanBlocksColumnLabel)}
              </Typography>
              <Stack direction="column" spacing={1}>
                {currentNewSamplingPlan?.blockSamplesData &&
                  currentNewSamplingPlan.blockSamplesData.map((bsd) => {
                    return (
                      <Typography
                        key={bsd.parcelId}
                        fontSize={16}
                        fontWeight={600}
                      >{`${
                        currentNewSamplingPlan.selectedParcels?.find(
                          (sp) => sp.id === bsd.parcelId
                        )?.name
                      }`}</Typography>
                    )
                  })}
              </Stack>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              mt={1}
              ref={sampleCountStackRef}
            >
              <Typography
                variant="subtitle1"
                lineHeight={0.75}
                fontSize={16}
                color="#FFFFFFB2"
              >
                {i18n.t(keys.samplePlanSamplesColumnLabel)}
              </Typography>
              <Stack direction="column" spacing={1}>
                {currentNewSamplingPlan?.blockSamplesData &&
                  currentNewSamplingPlan.blockSamplesData.map((bsd) => {
                    return (
                      <Typography
                        key={bsd.parcelId}
                        fontSize={16}
                        fontWeight={600}
                      >{`${bsd.sampleCount}`}</Typography>
                    )
                  })}
              </Stack>
            </Stack>
          </Stack>
          <Divider style={{ width: '70%', marginTop: '2px' }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ width: '80%', marginTop: '2px' }}
          >
            <Stack direction="column" spacing={0.5}>
              <Stack direction="column">
                <Typography fontSize={16} fontWeight={600}>
                  {i18n.t(keys.stats.total)}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              spacing={0.5}
              style={{ width: sampleCountStackRef?.current?.offsetWidth }}
            >
              <Stack direction="column">
                <Typography fontSize={16} fontWeight={600}>
                  {currentNewSamplingPlan?.blockSamplesData?.reduce(
                    (total, bsd) => bsd.sampleCount + total,
                    0
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </SamplePlanInfoSection>
        <SamplePlanInfoSection title={i18n.t(keys.projectInstructions)}>
          <Typography fontWeight={600} fontSize={16}>
            {currentNewSamplingPlan?.projectInstructions ?? '-'}
          </Typography>
        </SamplePlanInfoSection>
      </Stack>
      <LoadingControlBackdrop
        open={mapLoading || !completedSamplingPlan}
        containerStyle={{ width: '70%', height: '100%' }}
        infoLabel={i18n.t(keys.samplePlanNewGenerateLabel)}
      >
        <SamplePlanMap />
      </LoadingControlBackdrop>
    </Stack>
  )
}

export default React.forwardRef<Ref, SamplePlanCompleteFormProps<Ref> & Props>(
  (props, ref) => (
    <SamplePlanConfirmationWithMapContext {...props} forwardedRef={ref} />
  )
)
