import * as React from 'react'
import { NoteFormV2 } from '../vvapi/models'
import useAsync, { AsyncResult } from '../hooks/useAsync'
import { useRedux } from '../hooks/useRedux'
import { selectOrganization } from '../data/selectOrganization'
import { client, gql } from '../graphql/client'
import { indexArray } from '../util/indexArray'

const EMPTY_NOTE_FORMS: AsyncResult<Record<string, NoteFormV2>>[0] = {
  status: 'pending',
}
const NOOP = () => {}

export const NotesTemplatesContext = React.createContext<
  AsyncResult<Record<string, NoteFormV2>>
>([EMPTY_NOTE_FORMS, NOOP, false])

export const useNoteTemplates = () => React.useContext(NotesTemplatesContext)

export const NotesTemplatesProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state] = useRedux()

  const isLoggedIn = state.login.isLoggedIn
  const organization = selectOrganization(state)

  const templateAsyncResult = useAsync(
    async ([], skip) => {
      if (!organization || !isLoggedIn) {
        skip()
        return undefined
      }

      const { noteForms } = await client.request<{
        noteForms: NoteFormV2[]
      }>({
        query: gql`
          query GET_ORGANZIATION_NOTE_FORMS($organizationId: Int!) {
            noteForms: NoteForm(
              where: {
                _or: [
                  { organizationId: { _eq: $organizationId } }
                  { organizationId: { _is_null: true } }
                ]
              }
            ) {
              id
              organizationId
              name
              pinColor
              createdAt
              fields
              createdBy: CreatedBy {
                firstName
                lastName
                email
              }
            }
          }
        `,
        variables: {
          organizationId: organization.id,
        },
      })

      return indexArray(noteForms, 'id')
    },
    [],
    [organization, isLoggedIn]
  )

  return (
    <NotesTemplatesContext.Provider value={templateAsyncResult}>
      {children}
    </NotesTemplatesContext.Provider>
  )
}
