import { Button } from '@mui/material'
import * as React from 'react'

import { url, urls } from '../../../appNavigation/urls'
import i18n from '../../../i18n'
import LinkChild from '../../../UI/LinkChild'
import { TableFormatter } from '../../../UI/Table/types'
import { ListDeliveryGroupFileStatus } from './types'

const organization: TableFormatter<ListDeliveryGroupFileStatus> = {
  header: () => 'Organization',
  data: ({ Organization: { name } }) => name,
  orderBy: (direction) => `
    {
      Organization: {
        name: ${direction}
      }
    }
  `,
  filterBy: (op, value) => ({
    Organization: {
      name: { [`_${op}`]: value },
    },
  }),
}

const group: TableFormatter<ListDeliveryGroupFileStatus> = {
  header: () => 'Group',
  data: ({ DeliveryGroup: { name } }) => name,
  orderBy: (direction) => `
    {
      DeliveryGroup: {
        name: ${direction}
      }
    }
  `,
  filterBy: (op, value) => ({
    DeliveryGroup: {
      name: { [`_${op}`]: value },
    },
  }),
}

const filename: TableFormatter<ListDeliveryGroupFileStatus> = {
  header: () => 'File',
  // tslint:disable-next-line: no-shadowed-variable
  data: ({ filename }) => filename,
  orderBy: (direction) => `
    {
      filename: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    filename: { [`_${op}`]: value },
  }),
}

const uploadedAt: TableFormatter<ListDeliveryGroupFileStatus> = {
  header: () => 'Uploaded At',
  // tslint:disable-next-line: no-shadowed-variable
  data: ({ uploadedAt }) => i18n.toDateTimeShort(uploadedAt),
  orderBy: (direction) => `
    {
      uploadedAt: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    uploadedAt: { [`_${op}`]: value },
  }),
}

const status: TableFormatter<ListDeliveryGroupFileStatus> = {
  header: () => 'Status',
  // tslint:disable-next-line: no-shadowed-variable
  data: ({ status, jobId }) =>
    jobId ? (
      <LinkChild
        to={url(urls.job, {
          jobId,
        })}
      >
        <Button>{status}</Button>
      </LinkChild>
    ) : (
      <span>{status}</span>
    ),
  orderBy: (direction) => `
    {
      status: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    status: { [`_${op}`]: value },
  }),
}

const statusUpdatedAt: TableFormatter<ListDeliveryGroupFileStatus> = {
  header: () => 'Status Updated At',
  // tslint:disable-next-line: no-shadowed-variable
  data: ({ statusUpdatedAt }) => i18n.toDateTimeShort(statusUpdatedAt),
  orderBy: (direction) => `
    {
      statusUpdatedAt: ${direction}
    }
  `,
  filterBy: (op, value) => ({
    statusUpdatedAt: { [`_${op}`]: value },
  }),
}

export const formatters = {
  organization,
  group,
  filename,
  uploadedAt,
  status,
  statusUpdatedAt,
}

export const tableFormatter = [
  organization,
  group,
  filename,
  uploadedAt,
  status,
  statusUpdatedAt,
]
