import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { default as swal } from 'sweetalert'
import i18n, { keys } from '../i18n'

class ClipboardCopy extends React.Component<{ text: string }> {
  render() {
    const { text } = this.props

    return (
      <textarea
        tabIndex={1}
        style={
          {
            width: '100%',
            height: '100%',
            color: 'black',
            userSelect: 'all',
          } as React.CSSProperties
        }
      >
        {text}
      </textarea>
    )
  }
}

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    if (
      !!(navigator as any).clipboard &&
      !!(navigator as any).clipboard.writeText
    ) {
      await (navigator as any).clipboard.writeText(text)

      return true
    }

    return await Promise.resolve(copyToClipboardFallback(text))
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.error(err)

    const wrapper = document.createElement('div')
    wrapper.setAttribute('z-index', '1000001')
    ReactDOM.render(<ClipboardCopy text={text} />, wrapper)
    const el = wrapper.firstChild

    await swal({
      title: i18n.t(keys.copyFailed),
      text: `${i18n.t(keys.copyFailedDetail)} \n\n `,
      content: {
        element: el as Node,
        attributes: {
          text,
        },
      },
    })

    return false
  }
}

const copyToClipboardFallback = (text: string) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  document.execCommand('copy')
  textArea.remove()

  return true
}
