import { BBox } from "@turf/helpers"
import { Parcel } from "../../../../graphql/types"
import bbox from "@turf/bbox"

const calculateParcelBounds = (parcels: Parcel[]) => {
    // set bounds.
    let bounds: BBox = [
      Number.MAX_VALUE,
      Number.MAX_VALUE,
      -Number.MAX_VALUE,
      -Number.MAX_VALUE,
    ]
  
    for (const parcel of parcels) {
      const parcelBounds = bbox(parcel.geometry)
      bounds = [
        Math.min(parcelBounds[0], bounds[0]),
        Math.min(parcelBounds[1], bounds[1]),
        Math.max(parcelBounds[2], bounds[2]),
        Math.max(parcelBounds[3], bounds[3]),
      ]
    }
  
    return bounds
}

export default calculateParcelBounds