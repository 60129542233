import { Unit } from './Unit'
import { UnitSystem } from './unitSystem'

class UnitAndValue<UnitType extends string> {
  constructor(
    public value: number,
    public unit?: UnitType,
    public symbol?: string
  ) {}

  public toString = (): string => {
    return `${this.value.toFixed(2)} ${this.symbol}`
  }
}

/**
 *
 * @param units One of the units should have a value of `1`,
 *   all others should have a value equal to the factor between them.
 *
 * e.g.
 * ```ts
 * {
 *   yard: {
 *     value: 0.3333333333333,
 *     symbol: 'yd'
 *   },
 *   foot: {
 *     value: 1,
 *     symbol: 'ft'
 *   },
 *   inch: {
 *     value: 12,
 *     symbol: 'in'
 *   },
 * }
 * ```
 */
// tslint:disable-next-line: variable-name

interface UnitsBySystem<
  MetricUnitType extends string,
  ImperialUnitType extends string
> {
  [UnitSystem.Metric]: Record<MetricUnitType, Unit>
  [UnitSystem.Imperial]: Record<ImperialUnitType, Unit>
}

// eslint-disable-next-line import/no-anonymous-default-export
export default <MetricUnitType extends string, ImperialUnitType extends string>(
  unitsBySystem: UnitsBySystem<MetricUnitType, ImperialUnitType>
) => {
  type UnitType = MetricUnitType | ImperialUnitType

  const convert = (
    value: number,
    inputUnit: Unit,
    outputUnit: Unit,
    outputkey: UnitType
  ): UnitAndValue<UnitType> =>
    new UnitAndValue(
      (value / inputUnit.value) * outputUnit.value,
      outputkey,
      outputUnit.symbol
    )

  const bestUnit = (
    value: number,
    inputUnit: Unit,
    inputKey: UnitType,
    outputUnits: PartialRecord<UnitType, Unit>,
    significate = 1
  ): UnitAndValue<UnitType> => {
    const orderedUnits = (Object.entries(outputUnits) as [UnitType, Unit][])
      .sort(([_a, aUnit], [_b, bUnit]) => aUnit.value - bUnit.value)
      .filter(([_key, unit]) => unit.display)

    let converted = new UnitAndValue(value, inputKey, inputUnit.symbol)

    for (const [outputKey, unit] of orderedUnits) {
      converted = convert(value, inputUnit, unit, outputKey as UnitType)
      if (converted.value >= Math.pow(10, -significate)) {
        return converted
      }
    }

    return converted
  }

  const units = Object.assign(
    {},
    unitsBySystem[UnitSystem.Imperial],
    unitsBySystem[UnitSystem.Metric]
  )

  return {
    convert,
    bestUnit,
    units,
    unitsBySystem,
  }
}
