import * as React from 'react'
import { Typography } from '@mui/material'
import { Form } from '../../../admin/UI/Form'
import { TextField } from '@mui/material'
import i18n from '../../../i18n'
import { GetDeliveryData } from './selectGetDelivery'

export default class DeliveryForm extends Form<GetDeliveryData> {
  render() {
    const {
      CreatedBy,
      UpdatedBy,
      createdAt,
      updatedAt,
      comment = '',
    } = this.getFormData()

    return (
      <div className="grid">
        <div className="grid-xs-12">
          <h2>{this.props.type === 'edit' ? '' : 'New '}Delivery</h2>
        </div>
        <div className="grid-xs-12">
          <TextField
            name="comment"
            label={`Comment`}
            multiline
            fullWidth
            value={comment}
            onChange={this.handleCommentChange}
            InputLabelProps={{
              shrink: !!comment,
            }}
          />
        </div>
        {CreatedBy && UpdatedBy && (
          <div className="grid-xs-12">
            <Typography variant="caption">
              Created By: {CreatedBy.firstName} - {i18n.toDateShort(createdAt!)}
            </Typography>
            <Typography variant="caption">
              Updated By: {UpdatedBy.firstName} -{' '}
              {i18n.toDateTimeShort(updatedAt!)}
            </Typography>
          </div>
        )}
        <div className="grid-xs-12">{this.renderFormButtons()}</div>
      </div>
    )
  }

  handleCommentChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => this.updateFormData({ comment: event.target.value })
}
