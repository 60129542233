import * as React from 'react'

import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { alpha, Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import i18n, { keys } from '../../../../../i18n'
import { useRedux } from '../../../../../hooks/useRedux'
import { selectSelectedProduct } from '../../../../../postgis/selectSelectedProduct'
import RateMapColorLegend from './RateMapColorLegend'
import { selectVisualizationName } from '../../../../../stats/selectors/selectVisualizationName'

export const LEGEND_ELEMENT_ID = 'VVMapLegend'
export const MAX_WIDTH_LEGEND_PX = 538

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      pointerEvents: 'none!important' as any,
    },
    mainColumn: {
      pointerEvents: 'all',
      maxWidth: MAX_WIDTH_LEGEND_PX,
      margin: 'auto',
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      overflow: 'hidden',
    },
    info: {
      position: 'relative',
      height: 48,
    },
    infoLeft: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
    },
    infoRight: {
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'flex',
    },
    legendContainer: {
      cursor: 'pointer',
    },
    title: {
      paddingTop: theme.spacing(1 / 2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    legend: {
      maxWidth: MAX_WIDTH_LEGEND_PX,
      width: '100%',
    },
  })
)

const RateMapLegend = () => {
  const [state] = useRedux()
  const classes = useStyles()
  const product = selectSelectedProduct(state)
  const visualizationInfo = selectVisualizationName(state).filtered

  return (
    <div className={classes.root}>
      <Paper className={classes.mainColumn} id={LEGEND_ELEMENT_ID}>
        <div className={classes.info}>
          <div className={classes.title}>
            <Typography variant="subtitle1">
              {product?.name ?? i18n.t(keys.legend.noDataSelected)}
            </Typography>
            <Typography variant="caption">{visualizationInfo}</Typography>
          </div>
        </div>
        <div
          className={classes.legendContainer}
          id="colorSchemeRendererContainer"
        >
          <RateMapColorLegend />
        </div>
      </Paper>
    </div>
  )
}

export default RateMapLegend
