import * as React from 'react'
import i18n, { keys } from '../../i18n'
import { Button, Stack, styled } from '@mui/material'
import { PlaylistAdd } from '@mui/icons-material'

interface Props {
  noteMode: 'map' | 'select'
  setNoteMode: (mode: 'map' | 'select') => void
  style?: React.CSSProperties
}

const StyledButton = styled(Button)`
  color: #ffffff;
`

export const SelectButton = ({
    noteMode,
    setNoteMode,
    style
}: Props) => {
  return (
    <Stack style={style} flex={1}>
      <StyledButton
        variant="outlined"
        color='inherit'
        startIcon={<PlaylistAdd />}
        onClick={() => setNoteMode(noteMode === 'map' ? 'select' : 'map')}
        style={{
          backgroundColor: noteMode === 'select' ? '#ffffff' : 'inherit',
          color: noteMode === 'select' ? '#000000' : 'inherit',
        }
        }
      >
        {i18n.t(keys.selectNotesLabel)}
      </StyledButton>
    </Stack>
  )
}
