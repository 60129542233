import './DownloadsTable.scss'

import * as React from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Checkbox,
  Collapse,
  IconButton,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  tableRowClasses,
} from '@mui/material'

import { DeliveryGroupDate } from '../data/selectOrgMapData'
import i18n, { keys } from '../i18n'
import { Download, DownloadRequest, DownloadResult } from './types'
import { unionSet } from '../util/unionSet'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: 800,
    borderColor: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 12,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    borderColor: theme.palette.text.primary,
    borderWidth: 0.5,
    [`&.new`]: {
      fontWeight: theme.typography.fontWeightBold,
      [`.${tableCellClasses.body}`]: {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
}))

const StyledTableHead = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.head}`]: {
    borderColor: theme.palette.text.primary,
    borderBottomWidth: 1,
    borderBottom: 'solid',
  },
}))

interface Props {
  selectedDownloads: Record<string, Set<string>>
  renderStatus: ({
    status,
    id,
    file,
    filename,
  }: Pick<Download, 'status' | 'file' | 'filename'> & {
    id: string
  }) => JSX.Element | React.ReactNode[] | null
  downloads: {
    status: 'pending' | 'resolved' | 'rejected'
    result?: void | DownloadResult | undefined
    error?: any
  }
  hasDownloadsRefreshed: boolean
  deliveryGroupsDatesById: Record<string, DeliveryGroupDate[]>
  selectAllDownloads: () => void
  selectDownloads: (
    requestId: string,
    downloadIds: string[],
    all?: boolean
  ) => void
}

interface DownloadRowProps {
  key: string
  selectedDownloads?: Set<string>
  request: DownloadRequest
  deliveryGroupsDatesById: Record<string, DeliveryGroupDate[]>
  selectDownloads: (
    requestId: string,
    downloadIds: string[],
    all?: boolean
  ) => void
  renderStatus: ({
    status,
    id,
    file,
    filename,
  }: Pick<Download, 'status' | 'file' | 'filename'> & {
    id: string
  }) => JSX.Element | React.ReactNode[] | null
}

const DownloadRow = ({
  request,
  selectDownloads,
  renderStatus,
  selectedDownloads,
  deliveryGroupsDatesById,
}: DownloadRowProps) => {
  const [open, setOpen] = React.useState(false)
  const [status, setStatus] = React.useState('pending')

  const selectedDownloadSize = selectedDownloads?.size ?? 0

  React.useEffect(() => {
    // if all downloads in request are 'complete' or 'unprocessed'
    // it should display as complete
    if (
      request?.Downloads?.every(
        (x) => x.status === 'complete' || x.status === 'unprocessed'
      )
    ) {
      setStatus('complete')
    } else if (request?.Downloads?.some((x) => x.status === 'error')) {
      setStatus('error')
    }
  }, [request])

  return (
    <React.Fragment key={request.id}>
      <StyledTableRow
        hover
        role="checkbox"
        onClick={(ev) => {
          ev.stopPropagation()
          setOpen(!open)
        }}
        sx={{ cursor: 'pointer' }}
      >
        <StyledTableCell padding="checkbox">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(ev) => {
              ev.stopPropagation()
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell padding="checkbox" className="select">
          <Checkbox
            onClick={(ev) => {
              ev.stopPropagation()
              selectDownloads(
                request.id,
                request?.Downloads.filter(
                  ({ status }: { status: string }) => status === 'complete'
                ).map(({ id }: { id: string }) => id),
                true
              )
            }}
            checked={selectedDownloadSize > 0}
            indeterminate={
              selectedDownloadSize > 0 &&
              selectedDownloadSize < request?.Downloads.length
            }
            color="primary"
          />
        </StyledTableCell>
        <StyledTableCell>{request?.name ?? ''}</StyledTableCell>
        <StyledTableCell>
          {i18n.toDateShort(request?.createdAt ?? '')}
        </StyledTableCell>
        <StyledTableCell>
          {i18n.toDateShort(request?.expiresAt ?? '')}
        </StyledTableCell>
        <StyledTableCell className={`status ${status}`}>
          {status === 'complete'
            ? i18n.t(keys.readyForDownload)
            : i18n.t(status)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {request?.Downloads?.length}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{ padding: 0, border: 'none' }} colSpan={7}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <Table className="downloads" sx={{ borderColor: 'white' }}>
              <TableHead>
                <StyledTableRow hover role="checkbox" tabIndex={-1}>
                  <StyledTableCell
                    sx={{ width: '5%', borderStyle: 'hidden', p: 0 }}
                  />
                  <StyledTableCell sx={{ width: '5%', p: 0 }} />
                  <StyledTableCell className="group-name">
                    {i18n.t(keys.groupName)}
                  </StyledTableCell>
                  <StyledTableCell className="flight-date">
                    {i18n.t(keys.reports.flightDate)}
                  </StyledTableCell>
                  <StyledTableCell className="filename">
                    {i18n.t(keys.filename)}
                  </StyledTableCell>
                  <StyledTableCell className="status">
                    {i18n.t(keys.status)}
                  </StyledTableCell>
                  <StyledTableCell
                    className="actions"
                    padding="checkbox"
                    align="right"
                    sx={{ pr: 0 }}
                  >
                    {i18n.t(keys.actions)}
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {request?.Downloads?.map(
                  ({
                    id,
                    filename,
                    status,
                    file,
                    organizationGroup,
                    delivery,
                    downloadLogs,
                  }) => {
                    const isNew = downloadLogs.length === 0
                    const deliveryGroupId = `${delivery?.id}_${organizationGroup?.id}`
                    const isCheckboxEnabled = status === 'complete'

                    const deliveryGroups =
                      deliveryGroupsDatesById[deliveryGroupId!] ?? []
                    let dates: string
                    const groupName = organizationGroup.name

                    if (deliveryGroups.length === 1) {
                      dates = deliveryGroups[0].flightDate
                    } else {
                      const maxDate = Math.max(
                        ...deliveryGroups.map((dg) => Date.parse(dg.flightDate))
                      )
                      const minDate = Math.min(
                        ...deliveryGroups.map((dg) => Date.parse(dg.flightDate))
                      )
                      dates = `${i18n.toDateShort(minDate)}-${i18n.toDateShort(
                        maxDate
                      )}`
                    }

                    return (
                      <StyledTableRow
                        hover
                        key={id}
                        className={isNew ? 'new' : undefined}
                        onClick={() =>
                          isCheckboxEnabled && selectDownloads(request.id, [id])
                        }
                        role="checkbox"
                        aria-checked={selectedDownloads?.has(id) ?? false}
                        tabIndex={-1}
                      >
                        <StyledTableCell
                          sx={{ width: '5%', borderStyle: 'hidden', p: 0 }}
                        />
                        <StyledTableCell padding="checkbox" className="select">
                          <Checkbox
                            color="primary"
                            checked={selectedDownloads?.has(id) ?? false}
                            disabled={!isCheckboxEnabled}
                          />
                        </StyledTableCell>
                        <StyledTableCell className="group-name">
                          {groupName}
                        </StyledTableCell>
                        <StyledTableCell className="flight-date">
                          {dates}
                        </StyledTableCell>
                        <StyledTableCell className="filename">
                          {i18n.t(`filenames.${filename}`, {
                            defaultValue: filename,
                          })}
                        </StyledTableCell>
                        <StyledTableCell className={`status ${status}`}>
                          {status.toLowerCase() === 'complete'
                            ? i18n.t(keys.readyForDownload)
                            : i18n.t(status)}
                        </StyledTableCell>
                        <StyledTableCell
                          className="actions"
                          padding="checkbox"
                          align="right"
                        >
                          {renderStatus({ status, id, file, filename })}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  )
}

const downloadSkeleton = () => {
  const skeletonRows = []

  for (let i = 0; i < 5; i++) {
    skeletonRows.push(
      <StyledTableRow key={i}>
        <StyledTableCell>
          <Skeleton variant="rectangular" width="100%" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton variant="rectangular" width="100%" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton variant="rectangular" width="100%" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton variant="rectangular" width="100%" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton variant="rectangular" width="100%" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton variant="rectangular" width="100%" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton variant="rectangular" width="100%" />
        </StyledTableCell>
      </StyledTableRow>
    )
  }

  return skeletonRows
}

export const DownloadsTable = ({
  selectDownloads,
  selectedDownloads,
  downloads,
  hasDownloadsRefreshed,
  renderStatus,
  selectAllDownloads,
  deliveryGroupsDatesById,
}: Props) => {
  const allSelectedDownloads = Object.values(selectedDownloads).reduce(
    (curr, next) => {
      return unionSet(curr, next)
    },
    new Set<string>()
  )

  const allDownloads: Download[] = []

  downloads.result?.downloadRequests?.forEach((request: DownloadRequest) => {
    allDownloads.push(...request.Downloads)
  })

  return (
    <Table className="downloads">
      <TableHead>
        <StyledTableHead>
          <StyledTableCell padding="checkbox" className="select">
            <Checkbox
              color="primary"
              indeterminate={
                !!allSelectedDownloads.size &&
                allSelectedDownloads.size < allDownloads.length
              }
              checked={allSelectedDownloads.size === allDownloads.length}
              onChange={selectAllDownloads}
            />
          </StyledTableCell>
          <StyledTableCell sx={{ width: '5%', p: 0 }} />
          <StyledTableCell className="download-name">
            {i18n.t(keys.name)}
          </StyledTableCell>
          <StyledTableCell className="date-requested">
            {i18n.t(keys.dateRequested)}
          </StyledTableCell>
          <StyledTableCell className="expiration-date">
            {i18n.t(keys.expirationDate)}
          </StyledTableCell>
          <StyledTableCell className="status">
            {i18n.t(keys.status)}
          </StyledTableCell>
          <StyledTableCell
            className="files-number"
            align="right"
            sx={{ pr: 0 }}
          >
            {i18n.t(keys.numberOfFiles)}
          </StyledTableCell>
        </StyledTableHead>
      </TableHead>
      <TableBody>
        {downloads.status !== 'resolved' && !hasDownloadsRefreshed
          ? downloadSkeleton()
          : downloads.result?.downloadRequests?.map(
              (request: DownloadRequest) => (
                <DownloadRow
                  key={request.id}
                  selectedDownloads={selectedDownloads[request.id]}
                  selectDownloads={selectDownloads}
                  renderStatus={renderStatus}
                  deliveryGroupsDatesById={deliveryGroupsDatesById}
                  request={request}
                />
              )
            )}
      </TableBody>
    </Table>
  )
}
