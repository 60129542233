import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { RootStore } from '../../../redux/types'

export interface ListFlightsData {
  organizationId: string
  deliveryId: string
  date: string
  comment?: string
  createdAt: string
  updatedAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }
}

const list = ({ deliveryId }: { deliveryId: string }) =>
  api.flight.list<ListFlightsData>({
    where: { deliveryId: { _eq: deliveryId } },
    returning: `{
      organizationId
      deliveryId
      date
      comment
      createdAt
      updatedAt

      CreatedBy {
        firstName
      }
      UpdatedBy {
        firstName
      }
    }`,
  })

const { selector: selectListFlights, refresh: refreshListFlights } =
  createAsyncSelector({
    resource: 'ListFlightsData',
    inputs: {
      deliveryId: (state: RootStore) => state.router.params.deliveryId,
    },
    fetcher: async ({ deliveryId }) => {
      if (!deliveryId) {
        return
      }

      const { data } = await list({ deliveryId })

      if (!data) {
        throw new Error('No Data')
      }

      return data
    },
  })

export { selectListFlights, refreshListFlights }
