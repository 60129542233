import * as React from 'react'
import { default as Input } from '@mui/material/Input'
import { default as Typography } from '@mui/material/Typography'
import { NameAndValue } from '../../../admin/mapdata/types'

interface Props {
  name: string
  value?: number[]
  onChange(e: NameAndValue): void
}

class RangeFormInputs extends React.PureComponent<Props> {
  render() {
    const [min = '', max = ''] = this.props.value ?? []

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <Input
          name="min"
          type="number"
          onChange={this.onChange}
          value={min}
          autoComplete="off"
        />
        <Typography style={{ margin: '0 2em' }}>-</Typography>
        <Input
          name="max"
          type="number"
          onChange={this.onChange}
          value={max}
          autoComplete="off"
        />
      </div>
    )
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, name, value } = this.props
    const index = e.target.name === 'min' ? 0 : 1
    const newValue = value === undefined ? [undefined, undefined] : [...value]

    newValue[index] = e.target.valueAsNumber

    onChange({
      name,
      value: newValue,
    })
  }
}

export default RangeFormInputs
