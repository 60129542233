import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { selectOrganizationId } from './selectOrganizationId'
import { selectParcelFilterWhere } from './selectParcelFilterWhere'

export interface ParcelFilter {
  parcels: {
    parcelId: string
  }[]
}

const parcelFilterQuery = gql`
  query PARCEL_FILTER(
    $parcelFilters: View_ParcelFilter_bool_exp!
    $organizationId: Int!
  ) {
    organization: Organization_by_pk(id: $organizationId) {
      orgParcels: ParcelFilters {
        parcels: ParcelFilters(distinct_on: parcelId, where: $parcelFilters) {
          parcelId
        }
      }
    }
  }
`

const filteredParcelFetcher = async ({
  organizationId,
  parcelFiltersWhere,
}: {
  organizationId: string | undefined
  parcelFiltersWhere: any[]
}) => {
  if (!organizationId) {
    return null
  }
  const parcelFilters = parcelFiltersWhere.slice()

  if (parcelFilters.length === 0) {
    return null
  }

  const {
    organization: {
      orgParcels: { parcels },
    },
  } = await query<{
    deliveries: { deliveryId: string }[]
    organization: { orgParcels: { parcels: { parcelId: string }[] } }
  }>({
    query: parcelFilterQuery,
    variables: {
      organizationId,
      parcelFilters: {
        _and: parcelFilters,
      },
    },
  })

  if (!parcels) {
    return
  }

  return { parcels }
}

const { selector: selectParcelFilter, refresh: refreshParcelFilter } =
  createAsyncSelector({
    resource: 'me.organization.parcelFilter',
    inputs: {
      organizationId: selectOrganizationId,
      parcelFiltersWhere: selectParcelFilterWhere,
    },
    fetcher: filteredParcelFetcher,
  })

export { selectParcelFilter, refreshParcelFilter, filteredParcelFetcher }
