import * as React from 'react'
import { default as swal } from 'sweetalert'

import {
  Avatar,
  Button,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import warnConfirm from '../../../../admin/warnConfirm'
import {
  enableFulfilledLayers,
  setLayersEnabled,
  STATUS_COUNTS,
} from '../enableLayers'
import { DetailedDeliveryStatus } from '../selectGetDetailedDeliveryStatus'

interface Props {
  deliveryId: string
  status: Record<DetailedDeliveryStatus, number>
  onRefresh: () => void
}

const EXPECTED = ['fulfilled', 'enabled']

class LayerStatus extends React.PureComponent<Props> {
  render() {
    const { status } = this.props

    return (
      <div className="grid-xs-6">
        <div
          className="grid Paper"
          style={{ gridGap: '1em', marginTop: 8, padding: 24 }}
        >
          <div className="grid-xs-6">
            <Typography variant="h6">Layer Statuses:</Typography>
          </div>
          <div className="actions grid-xs-6">
            <Button variant="contained" onClick={this.refresh}>
              Refresh Statuses
            </Button>
          </div>
          <Table style={{ gridColumn: '4 / span 6' }}>
            <TableBody>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell />
                <TableCell>Count</TableCell>
              </TableRow>
              {STATUS_COUNTS.map((statusKey) => (
                <TableRow key={statusKey}>
                  <TableCell padding="checkbox">
                    <Tooltip title={HELP_TEXT[statusKey]}>
                      {this.renderAvatar(statusKey)}
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={HELP_TEXT[statusKey]}>
                      <Typography>{DISPLAY[statusKey] || statusKey}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={HELP_TEXT[statusKey]}>
                      <>
                        {status
                          ? EXPECTED.includes(statusKey)
                            ? `${status[statusKey]}/${status.expectedCount}`
                            : `${status[statusKey]}`
                          : 0}
                      </>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {this.renderEnableButton()}
        </div>
      </div>
    )
  }

  renderAvatar = (status: DetailedDeliveryStatus) => {
    const style: React.CSSProperties = {
      color: 'var(--color)',
      backgroundColor: 'var(--background)',
      width: 23,
      height: 23,
      verticalAlign: 'middle',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    }
    let icon = 'info'

    switch (status) {
      case 'enabledCount':
      case 'fulfilledCount':
        style.backgroundColor = 'var(--green)'
        icon = 'check_circle_outline'
        break
      case 'missingCount':
        style.backgroundColor = 'var(--red)'
        icon = 'error_outline'
        break
    }

    return (
      <Avatar style={style}>
        <Icon fontSize="small">{icon}</Icon>
      </Avatar>
    )
  }

  renderEnableButton = () => {
    const { status } = this.props

    if (!status) {
      return null
    }

    if (status.fulfilledCount === 0) {
      return null
    }

    const layersEnabled = status.fulfilledCount === status.enabledCount

    return (
      layersEnabled !== undefined && (
        <div className="grid-xs-12 align-right">
          <Button
            style={{ marginRight: 8 }}
            variant="contained"
            color="secondary"
            onClick={() => this.handleToggleLayersEnabled(true)}
          >
            Disable All Layers
          </Button>
          <Button
            style={{ marginRight: 8 }}
            variant="contained"
            color="primary"
            onClick={() => this.handleToggleLayersEnabled(false)}
          >
            Enable All Layers
          </Button>

          <Button
            variant="contained"
            onClick={() => this.handleEnableFulfilledLayers()}
          >
            Enable Fulfilled Layers
          </Button>
        </div>
      )
    )
  }

  refresh = () => {
    const { onRefresh } = this.props

    if (onRefresh) {
      onRefresh()
    }
  }

  handleEnableFulfilledLayers = async () => {
    const deliveryId = this.props.deliveryId

    if (
      await warnConfirm({
        title: 'Enable Fulfilled Layers',
        message: 'This will enable only fulfilled layers. Are you sure?',
      })
    ) {
      await enableFulfilledLayers(deliveryId)

      this.refresh()
    }
  }

  handleToggleLayersEnabled = async (layersEnabled: boolean) => {
    const value = this.props.deliveryId

    if (await confirmToggleEnable(layersEnabled, 'delivery')) {
      await setLayersEnabled({
        value,
        enabled: !layersEnabled,
      })

      this.refresh()
    }
  }
}

const DISPLAY: Partial<Record<DetailedDeliveryStatus, string>> = {
  enabledCount: 'Enabled',
  expectedCount: 'Expected',
  fulfilledCount: 'Fulfilled',
  missingCount: 'Missing',
  extraCount: 'Extra',
  readyCount: 'Ready',
  totalCount: 'Total',
}

const HELP_TEXT: Record<DetailedDeliveryStatus, string> = {
  enabledCount: `Enabled layers are finished layers which a user can now view`,
  expectedCount: 'Total number of layers to be fulfilled',
  fulfilledCount: `Layers are fulfilled when they are finished and are part of the package`,
  missingCount: `Map Source files were not uploaded`,
  readyCount: `Layers are ready when they are finished even if they are not part of the package`,
  extraCount: `Layers are extra when they are uploaded and they are not part of the package`,
  totalCount: 'Total number of layers',
}

const confirmToggleEnable = async (
  enabled: boolean,
  type: 'flight' | 'delivery'
) => {
  const action = enabled ? 'Deactivate' : 'Activate'
  const choice = await swal(
    `You are about to ${action.toLowerCase()} all layers for this ${type}`,
    {
      buttons: {
        cancel: true,
        confirm: {
          text: `${action} Layers`,
        },
      },
      dangerMode: true,
    }
  )

  // swal returns null for "cancel"
  if (!choice) {
    return false
  }

  return true
}

export default LayerStatus
