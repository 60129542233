import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
  setFocusedSoilLayer,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import * as notes from '../../../../notes/redux'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class BlockActionGenerator implements IMapActionGenerator {
  key = 'block'
  priority = Priority.Block

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
    state,
  }: IMapActionGeneratorParams): IMapAction[] {
    if (state.notes.editingNoteId !== undefined) {
      return []
    }

    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) => f.layer.id === 'FIELD-BOUNDS-LABEL'
    )

    // Return functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        dispatch(notes.actions.setEditingNoteId(undefined))
        dispatch(setFocusedPoint(null))
        dispatch(setFocusedPolygon(null))
        dispatch(setFocusedBlockId(q.properties?.id))
        dispatch(setFocusedSoilLayer(null))
      },
    }))
  }
}
