import * as React from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, ButtonProps, Menu } from '@mui/material'

interface Props extends React.PropsWithChildren<{}>, ButtonProps {
  title: string
}

export const MenuButton = ({ children, title, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        {...props}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {title}
      </Button>

      <Menu onClose={handleClose} anchorEl={anchorEl} open={open}>
        {children}
      </Menu>
    </>
  )
}
