import * as React from 'react'
import { Checkbox, FormControlLabel, FormLabel, MenuItem, Typography } from '@mui/material'
import { default as TextField } from '@mui/material/TextField'
import { LanguageSelection } from '../../app/types'
import * as api from '../../graphql/api'
import { MapSourceDef, MapSourceProperty } from '../../graphql/types';
import i18n, { keys } from '../../i18n'
import TranslationTextField from '../../UI/TranslationTextField/TranslationTextField'
import errorAlert from '../errorAlert'
import RelatedModelSelect from '../mapdata/RelatedModelSelect'
import { NameAndValue } from '../mapdata/types'
import { Form } from '../UI/Form'
import { GetMapLayerDefData } from './selectGetMapLayerDef'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { getInitializedHistoricalCalculationTypePropertyMap, HistoricalLabel } from './types/HistoricalChartCalculationType';

interface Props {
  preferredLanguage: LanguageSelection
}

type MapSourceFields = Pick<MapSourceDef, 'id' | 'name'>
type MapSourcePropertyFields = Pick<MapSourceProperty, 'id' | 'name'>

export default class MapLayerDefForm extends Form<GetMapLayerDefData, Props> {
  render() {
    const {
      mapSourceDefId,
      name = {},
      description = {},
      enabled = false,
      historicalChartCalculationType,
      historicalChartCalculationProperties,
      order = 0,
      dataProperty,
      noDataProperty,
      coverageProperty,
      weightProperty,
      pdfFilename,
      pdfFilenameTranslations = {},
      layerGroupLabel = {},
      CreatedBy,
      UpdatedBy,
      createdAt,
      updatedAt,
    } = this.getFormData()

    return (
      <div className="grid Paper">
        <div className="grid-xs-12">
          <h2>{this.props.type === 'edit' ? 'Edit' : 'New'} MapLayerDef</h2>
        </div>
        <div className="grid-xs-12">
          <RelatedModelSelect
            required
            disabled={!!mapSourceDefId}
            name="mapSourceDefId"
            label={'Map Source Definition'}
            query={this.getAllMapSources}
            display={this.getTranslatedName}
            onChange={this.updateFormDataFromNameAndValue}
            value={mapSourceDefId}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            required
            name="name"
            label={`Name`}
            value={name}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            name="description"
            label={`Description`}
            value={description}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="pdfFilename"
            type="text"
            label={`PDF Filename`}
            value={pdfFilename ?? ''}
            autoComplete="off"
            onChange={this.updateFormDataFromChangeEvent}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            name="pdfFilenameTranslations"
            label={`PDF Filename Translations`}
            value={pdfFilenameTranslations}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <TranslationTextField
            name="layerGroupLabel"
            label={`Layer Group Label`}
            value={layerGroupLabel}
            onChange={this.updateFormDataFromNameAndValue}
          />
        </div>
        <div className="grid-xs-12">
          <FormControlLabel
            control={
              <Checkbox
                checked={enabled}
                onChange={this.updateFormDataFromChangeEvent}
                name="enabled"
              />
            }
            label="Layer enabled for customers to view"
          />
        </div>
        <div className="grid-xs-12">
          <TextField
            name="order"
            type="number"
            label={`Order`}
            value={order}
            autoComplete="off"
            onChange={this.updateFormDataFromChangeEvent}
          />
        </div>
        <div className="grid-xs-12"> 
          <FormControl>
            <FormLabel>Historical Chart Calculation</FormLabel>
            <Select
              name="historicalChartCalculationType"
              onChange={this.handlehistoricChartTypeChange}
              value={historicalChartCalculationType ?? null}>
              <MenuItem key={'none'} value={null as any}>
                None
              </MenuItem>
              {this.getHistoricalChartCalcTypeList().map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {historicalChartCalculationType !== '' && historicalChartCalculationProperties &&
            Object.entries(historicalChartCalculationProperties)
            ?.filter((p) => p[0] !== 'key') // Don't display the 'key' property
            .map((p) => {
              return (<div className="grid-xs-12">
                {this.getFormFromPropertyType(p)}
              </div>)
            })
          }
        {/* IMPORTANT `key`: re-render property model selects if map source changes */}
        <React.Fragment key={mapSourceDefId}>
          <div className="grid-xs-12">
            <RelatedModelSelect
              name="dataProperty"
              label={'Data Property'}
              query={this.getAllMapSourceProperties}
              display={this.getTranslatedName}
              onChange={this.updateFormDataFromNameAndValue}
              value={dataProperty}
            />
          </div>
          <div className="grid-xs-12">
            <RelatedModelSelect
              name="noDataProperty"
              label={'No Data Property'}
              query={this.getAllMapSourceProperties}
              display={this.getTranslatedName}
              onChange={this.updateFormDataFromNameAndValue}
              value={noDataProperty || undefined}
            />
          </div>
          <div className="grid-xs-12">
            <RelatedModelSelect
              name="coverageProperty"
              label={'Coverage Property'}
              query={this.getAllMapSourceProperties}
              display={this.getTranslatedName}
              onChange={this.updateFormDataFromNameAndValue}
              value={coverageProperty || undefined}
            />
          </div>
          <div className="grid-xs-12">
            <RelatedModelSelect
              name="weightProperty"
              label={'Weight Property'}
              query={this.getAllMapSourceProperties}
              display={this.getTranslatedName}
              onChange={this.updateFormDataFromNameAndValue}
              value={weightProperty || undefined}
            />
          </div>
        </React.Fragment>
        {CreatedBy && UpdatedBy && (
          <div className="grid-xs-12">
            <Typography variant="subtitle1">
              Created By: {CreatedBy.firstName} - {i18n.toDateShort(createdAt!)}
            </Typography>
            <Typography variant="subtitle1">
              Updated By: {UpdatedBy.firstName} -{' '}
              {i18n.toDateTimeShort(updatedAt!)}
            </Typography>
          </div>
        )}
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }

  getAllMapSources = async (): Promise<MapSourceFields[]> => {
    try {
      const sources = await api.mapSourceDef.list<MapSourceFields>({
        returning: `{
          id
          name
        }`,
      })

      return sources.data ?? []
    } catch (e) {
      softError(
        e,
        'Failed loading source definitions',
        'Please try again',
        this.state
      )

      return []
    }
  }

  getAllMapSourceProperties = async (): Promise<MapSourcePropertyFields[]> => {
    const { mapSourceDefId } = this.getFormData()

    if (!mapSourceDefId) {
      return []
    }

    try {
      const sources = await api.mapSourceDef.get<
        Pick<MapSourceDef, 'id' | 'MapSourceProperties'>
      >({
        pk: { id: mapSourceDefId },
        returning: `{
        MapSourceProperties {
          id
          name
        }
      }`,
      })

      return sources.data!.MapSourceProperties ?? []
    } catch (e) {
      softError(
        e,
        'Failed loading source properties',
        'Please try again',
        this.state
      )

      return []
    }
  }

  getTranslatedName = (obj: MapSourceDef | MapSourceProperty) =>
    obj.name[this.props.preferredLanguage] ?? '[no translation provided]'

  /** need to wipe the data properties if source definition changes */
  handleSourceDefChange = (nameAndValue: NameAndValue) =>
    this.updateFormData({
      dataProperty: undefined,
      noDataProperty: undefined,
      coverageProperty: undefined,
      weightProperty: undefined,
      [nameAndValue.name]: nameAndValue.value,
    })

  getHistoricalChartCalcTypeList = () => {
    return Array.from(getInitializedHistoricalCalculationTypePropertyMap().keys())
  }

  handlehistoricChartTypeChange = (event: SelectChangeEvent<string>) => {
    this.updateFormDataFromSelectChangeEvent(event)

    // Additionally apply a blank set of historic stats properties.
    const properties = getInitializedHistoricalCalculationTypePropertyMap().get(event.target.value)
    this.setState(({ formData }) => ({
      formData: {
        ...(formData as any),
        historicalChartCalculationProperties: {
          ...properties,
        },
      },
    }))
  }

  handleHistoricChartTypePropChange = (nameAndValue: NameAndValue) => {
    this.setState(({ formData }) => {
      return ({
        formData: {
          ...(formData as any),
          historicalChartCalculationProperties: {
            ...(this.props.data?.historicalChartCalculationProperties),
            ...(formData?.historicalChartCalculationProperties),
            ...{ [nameAndValue.name]: nameAndValue.value }
          },
        },
      })
    })
  }

  getFormFromPropertyType = (p: [string, string | HistoricalLabel]) => {
    const label = i18n.t(keys.historicChartConfig[p[0]])
    if(typeof p[1] === "string") {
      return (
        <TextField
          key={p[0]}
          name={p[0]}
          type="string"
          label={label}
          value={p[1]}
          autoComplete="off"
          onChange={(e) => this.handleHistoricChartTypePropChange(e.target as unknown as NameAndValue)}/>
      )
    }

    return (
      <TranslationTextField
        key={p[0]}
        name={p[0]}
        label={label}
        value={p[1] as HistoricalLabel}
        onChange={this.handleHistoricChartTypePropChange}/>
    )
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })
