import './LayerDrawerGPSOffsetToggle.scss'

import * as React from 'react'

import { Button, Icon, ListItem, Typography } from '@mui/material'
import distance from '@turf/distance'
import { point } from '@turf/helpers'

import i18n, { keys } from '../../i18n'
import withMap, { WithMap } from '../../map/withMap'
import { connect } from '../../redux/connect'
import { updatePreferences } from '../../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { UnitSystem } from '../../util/units/unitSystem'
import {
  setIsPollingUserPosition,
  toggleGPSOffsetMarkerVisible,
} from '../actions'

interface State {
  lng: number | undefined
  lat: number | undefined
  xOffset: number | undefined
  yOffset: number | undefined
}

class LayerDrawerGPSOffsetToggle extends React.PureComponent<
  ReduxProps & AppDispatchProps & WithMap
> {
  state: State = {
    xOffset: undefined,
    yOffset: undefined,
    lng: undefined,
    lat: undefined,
  }

  componentDidUpdate(prevProps: this['props']) {
    const { position, gpsOffset } = this.props

    if (
      position !== prevProps.position ||
      gpsOffset.lng !== prevProps.gpsOffset.lng ||
      gpsOffset.lat !== prevProps.gpsOffset.lat
    ) {
      this.handleSetOffsetValues()
    }
  }

  handleSetOffsetValues = () => {
    const { position, gpsOffset, preferredUnitSystem } = this.props

    let xOffset
    let yOffset

    if (position) {
      const { longitude, latitude } = position.coords

      xOffset = distance(
        point([longitude, latitude]),
        point([longitude + gpsOffset.lng, latitude]),
        { units: 'meters' }
      )

      yOffset = distance(
        point([longitude, latitude]),
        point([longitude, latitude + gpsOffset.lat]),
        { units: 'meters' }
      )

      if (preferredUnitSystem === UnitSystem.Imperial) {
        xOffset = xOffset * 3.28
        yOffset = yOffset * 3.28
      }

      // Math.round does toFixed(2) keeping the number type
      xOffset = Math.round(xOffset * 1e1) / 1e1
      yOffset = Math.round(yOffset * 1e1) / 1e1

      if (gpsOffset.lng < 0) {
        xOffset = -xOffset
      }
      if (gpsOffset.lat < 0) {
        yOffset = -yOffset
      }

      this.setState({ xOffset, yOffset })
    } else {
      this.props.dispatch(setIsPollingUserPosition(true))
    }
  }

  handleMapPositionAdjust = () => {
    this.props.dispatch(toggleGPSOffsetMarkerVisible(null))
  }

  handleMapPositionReset = () => {
    this.props.dispatch(
      updatePreferences({
        gpsOffset: { lng: 0, lat: 0 },
      })
    )
  }

  render() {
    const { preferredUnitSystem } = this.props
    const { xOffset = 0, yOffset = 0 } = this.state
    const units = preferredUnitSystem === UnitSystem.Metric ? 'm' : 'ft'

    const xOffsetRenderValue =
      xOffset < -99 ? '<-99' : xOffset > 99 ? '>99' : xOffset
    const yOffsetRenderValue =
      yOffset < -99 ? '<-99' : yOffset > 99 ? '>99' : yOffset

    return (
      <ListItem className="gps-toggle">
        <Button fullWidth onClick={this.handleMapPositionAdjust}>
          <Icon>edit_location_alt</Icon>
          {i18n.t(keys.adjustMapPosition)}
        </Button>

        <div className="offset-values__container">
          <Typography variant="caption" className="offset-values__title">
            {i18n.t(keys.offset)}({units})
          </Typography>
          <Typography variant="caption" className="offset-values__value--title">
            X:
          </Typography>
          <span className="offset-values__value">{xOffsetRenderValue}</span>

          <Typography variant="caption" className="offset-values__value--title">
            Y:
          </Typography>
          <span className="offset-values__value">{yOffsetRenderValue}</span>

          <Button
            onClick={this.handleMapPositionReset}
            className="offset-values__btn--reset"
          >
            <Icon>block</Icon>
          </Button>
        </div>
      </ListItem>
    )
  }
}

const mapState = (state: RootStore) => ({
  isEnabled: state.preferences.showGPS,
  preferredUnitSystem: state.preferences.preferredUnitSystem,
  gpsOffset: state.preferences.gpsOffset,
  position: state.postgis.userPosition,
  isGPSOffsetMarkerVisible: state.postgis.isGPSOffsetMarkerVisible,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withMap(LayerDrawerGPSOffsetToggle)
)
