import * as React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material'

import { NameAndValue } from '../../../../admin/mapdata/types'
import DatePickerYMD from '../../../../admin/mapdata/utils/DatePickerYMD'
import { selectListPackages } from '../../../../admin/Package/selectListPackages'
import { DeliveryProcGroup } from '../../../../graphql/types'
import { useRedux } from '../../../../hooks/useRedux'
import dateFromYMD from '../../../../admin/mapdata/dateFromYMD'
import dateToYMD from '../../../../admin/mapdata/dateToYMD'

export interface DeliveryProcGroupPackages {
  deliveryId: string
  organizationId: string
  procGroup: string
  flightDate: string
  packages: string[]
  nasLocation: string
}

interface Props {
  open: boolean
  deliveryProcGroup: Pick<
    DeliveryProcGroup,
    'deliveryId' | 'flightDate' | 'procGroup' | 'Packages' | 'organizationId'
  >
  onCancel: () => void
  onSubmit: (deliveryProcGroupPackages: DeliveryProcGroupPackages) => void
}

export const AddPackageToProcGroupDialog = ({
  open,
  deliveryProcGroup,
  onCancel,
  onSubmit,
}: Props) => {
  const [state] = useRedux()
  const theme = useTheme()

  const listPackages = selectListPackages(state)

  const [selectedPackages, setSelectedPackages] = React.useState<string[]>([])
  const [nasLocation, setNasLocation] = React.useState<string>('')
  const [flightDate, setFlightDate] = React.useState<string | undefined>(
    undefined
  )
  const packages = listPackages.data?.data ?? []

  const existingPackages = new Set(
    deliveryProcGroup?.Packages?.map((p) => p.package) ?? []
  )

  React.useEffect(() => {
    if (open === false) {
      setTimeout(handleClose, theme.transitions.duration.leavingScreen)
    }
  }, [open, theme.transitions.duration.leavingScreen])

  const handleClose = () => {
    setSelectedPackages([])
    setNasLocation('')
    setFlightDate(undefined)
  }

  const handleSelectPackages = (ev: SelectChangeEvent<string[]>) => {
    if (typeof ev.target.value === 'string') {
      setSelectedPackages(ev.target.value.split(','))
    } else {
      setSelectedPackages(ev.target.value)
    }
  }

  const handleSetNASLocation = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNasLocation(ev.target.value.replace('L:/', ''))
  }

  const handleFlightDateUpdate = (v: NameAndValue) => {
    setFlightDate(dateToYMD(v.value))
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleSubmit = () => {
    onSubmit({
      deliveryId: deliveryProcGroup.deliveryId,
      procGroup: deliveryProcGroup.procGroup,
      organizationId: deliveryProcGroup.organizationId!,
      packages: selectedPackages,
      flightDate: flightDate!,
      nasLocation: nasLocation!,
    })
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Set Proc Group Packages</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={12} md={8}>
            <FormControl fullWidth>
              <InputLabel required>Packages</InputLabel>
              <Select
                name="type"
                fullWidth
                multiple
                onChange={handleSelectPackages}
                value={selectedPackages}
              >
                {packages.map((p) => (
                  <MenuItem
                    key={p.code}
                    value={p.code}
                    disabled={existingPackages.has(p.code)}
                  >
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={8}>
            <FormControl fullWidth>
              <DatePickerYMD
                required
                label={`Flight Date`}
                name="flightDate"
                value={flightDate ? dateFromYMD(flightDate) : null}
                onChange={handleFlightDateUpdate}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                label="NAS Location"
                value={nasLocation}
                onChange={handleSetNASLocation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">L:/</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            nasLocation === '' ||
            packages.length === 0 ||
            flightDate === undefined
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
