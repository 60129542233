import * as React from 'react'

import { Checkbox, FormControlLabel } from '@mui/material'

import dateFromYMD from '../../../admin/mapdata/dateFromYMD'
import dateToYMD from '../../../admin/mapdata/dateToYMD'
import DatePickerYMD from '../../../admin/mapdata/utils/DatePickerYMD'
import { Form } from '../../../admin/UI/Form'
import { ListTargetDeliveriesData } from './selectListTargetDeliveries'

export default class DeliveryTargetForm extends Form<ListTargetDeliveriesData> {
  render() {
    const { date, inhibitFlightNotice } = this.getFormData()

    return (
      <div className="grid">
        <div className="grid-xs-12">
          <DatePickerYMD
            required
            label={`Flight Date`}
            name="date"
            value={date ? dateFromYMD(date) : null}
            onChange={(nameAndValue) =>
              this.updateFormDataFromNameAndValue({
                ...nameAndValue,
                value: dateToYMD(nameAndValue.value),
              })
            }
          />
        </div>
        <div className="grid-xs-12">
          <FormControlLabel
            label="Disable Automatic Flight Notice"
            control={
              <Checkbox
                checked={inhibitFlightNotice}
                onChange={this.updateFormDataFromChangeEvent}
                name="inhibitFlightNotice"
              />
            }
          />
        </div>
        <div className="grid-xs-12 align-right">{this.renderFormButtons()}</div>
      </div>
    )
  }
}
