import { Drawer, DrawerProps, Stack, styled } from '@mui/material'
import * as React from 'react'

interface ContainedDrawerProps extends DrawerProps {
  drawerWidth?: number
}

const ContainedDrawerRoot = styled(Drawer)<ContainedDrawerProps>(
  ({ theme, drawerWidth, anchor }) => ({
    pointerEvents: 'auto',
    top: 0,
    left: anchor === 'left' ? 0 : 'auto',
    right: anchor === 'right' ? 0 : 'auto',
    position: 'absolute',
    height: '100%',
    '& .MuiDrawer-root': {
      position: 'absolute',
      width: drawerWidth ? `${drawerWidth}px !important` : undefined,
    },
    '& .MuiPaper-root': {
      position: 'absolute',
      overflow: 'hidden',
      width: drawerWidth ? `${drawerWidth}px !important` : undefined,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
)

const DrawerStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default,
}))

/**
 * A drawer that opens from the side of a containing element.
 * The parent container should have `position: relative` set.
 * @returns A drawer that opens from the side of a containing element.
 */
export const ContainedDrawer = ({
  open,
  children,
  ...otherProps
}: ContainedDrawerProps) => {
  return (
    <ContainedDrawerRoot variant="persistent" open={open} {...otherProps}>
      <DrawerStack direction="column">{children}</DrawerStack>
    </ContainedDrawerRoot>
  )
}
