import { useTheme } from '@mui/styles'
import * as React from 'react'

export const ActionDecorator = (props: any) => {
  const theme = useTheme()
  if (props.decoratedText.toLowerCase().includes('fail')) {
    return (
      <strong
        style={{
          background: theme.palette.error.main,
          color: theme.palette.error.contrastText,
          borderRadius: theme.shape.borderRadius / 2,
          paddingLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(0.5),
        }}
      >
        {props.decoratedText.replace('/__', '')}
      </strong>
    )
  }
  if (props.decoratedText.toLowerCase().includes('approve')) {
    return (
      <strong
        style={{
          background: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          borderRadius: theme.shape.borderRadius / 2,
          paddingLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(0.5),
        }}
      >
        {props.decoratedText.replace('/__', '')}
      </strong>
    )
  }

  return null
}
