import { useEffect, useState } from 'react'

const cancellable = (cb: (...args: any[]) => any) => {
  let cancelled = false

  const cancel = () => {
    cancelled = true
  }

  const callback = (...args: any[]) => {
    if (!cancelled) {
      cb(...args)
    }
  }

  return {
    cancel,
    callback,
  }
}

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T

export type AsyncResult<R> = [
  { status: 'pending' | 'resolved' | 'rejected'; result?: R; error?: any },
  (invalidateHasRefreshed?: boolean) => void,
  boolean
]

export default function useAsync<
  Func extends (args: any[], skip: () => void) => any
>(func: Func, args: any[], dependencies: any[] = []) {
  const [hasRefreshed, setHasRefreshed] = useState(false)
  const [status, setStatus] = useState('pending')
  const [result, setResult] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [shouldRefresh, setShouldRefresh] = useState(false)

  const handleResolve = (newResult: any) => {
    setStatus('resolved')
    setResult(newResult)
    setError(undefined)
    setHasRefreshed(true)
  }
  const handleReject = (newError: any) => {
    setStatus('rejected')
    setResult(undefined)
    setError(newError)
    setHasRefreshed(false)

    // tslint:disable-next-line: no-console
    console.error(newError)
  }

  let resolved = cancellable(handleResolve)
  let rejected = cancellable(handleReject)

  const skip = () => {
    setStatus('pending')
    resolved.cancel()
    rejected.cancel()
  }

  useEffect(() => {
    skip()

    resolved = cancellable(handleResolve) // eslint-disable-line react-hooks/exhaustive-deps
    rejected = cancellable(handleReject) // eslint-disable-line react-hooks/exhaustive-deps

    func(args, skip).then(resolved.callback, rejected.callback)
  }, [shouldRefresh, ...(dependencies as any[]), ...(args as any[])])

  const refresh = (invalidateHasRefreshed?: boolean) => {
    setStatus('pending')
    if (invalidateHasRefreshed) {
      setHasRefreshed(false)
    } else {
      setHasRefreshed(true)
    }
    setShouldRefresh((x) => !x)
  }

  return [{ status, result, error }, refresh, hasRefreshed] as AsyncResult<
    Awaited<ReturnType<Func>>
  >
}
