import bbox from '@turf/bbox'
import { BBox } from '@turf/helpers'
import { GetDeliveryData } from '../../../data/types'
import { client, gql } from '../../../graphql/client'
import { MapboxGL } from '../../../map/MapboxGL'
import mapboxSatelliteJson from '../../../map/styles/mapbox-satellite.json'

export const fetchParcelsAndBounds = async (
  deliveryId: string,
  procGroup: string
) => {
  const style = {
    ...mapboxSatelliteJson,
    sources: { ...mapboxSatelliteJson.sources },
    layers: [...mapboxSatelliteJson.layers],
  } as MapboxGL.Style
  const { parcels } = await client.request<Pick<GetDeliveryData, 'parcels'>>({
    query: gql`
      query ProcGroupDeliveryParcels($deliveryId: uuid!, $procGroup: String!) {
        parcels: DeliveryParcel(
          where: {
            deliveryId: { _eq: $deliveryId }
            meta: { _contains: { procGroup: $procGroup } }
          }
        ) {
          deliveryId
          groupId
          parcelId
          name
          meta
          geometry

          parcel: Parcel {
            id
            name
            meta
            retiredAt
            group: Group {
              id
              name
            }
          }

          mapSources: MapSources {
            id
            mapSourceDefId
            deliveryId
            parcelId
            meta
            flightDate

            type
            scheme
            attribution
            tileSize
            minzoom
            maxzoom
            bounds
            tiles

            mapLayers: MapLayers {
              id
              deliveryId
              mapSourceId
              mapLayerDef: MapLayerDef {
                id
                name
                mapSourceDef: MapSourceDef {
                  id
                  type
                }
              }
              enabled
            }
          }
        }
      }
    `,
    variables: { deliveryId, procGroup },
  })

  let bounds: BBox = [
    Number.MAX_VALUE,
    Number.MAX_VALUE,
    -Number.MAX_VALUE,
    -Number.MAX_VALUE,
  ]

  const productMapLayerDefsById: Record<
    string,
    Pick<
      GetDeliveryData,
      'parcels'
    >['parcels'][0]['mapLayers'][0]['mapLayerDef']
  > = {}
  const backgroundMapLayerDefsById: Record<
    string,
    Pick<
      GetDeliveryData,
      'parcels'
    >['parcels'][0]['mapLayers'][0]['mapLayerDef']
  > = {}

  parcels.forEach((parcel) => {
    if (!parcel) {
      return
    }
    const parcelBounds = bbox(parcel.geometry)
    bounds = [
      Math.min(parcelBounds[0], bounds[0]),
      Math.min(parcelBounds[1], bounds[1]),
      Math.max(parcelBounds[2], bounds[2]),
      Math.max(parcelBounds[3], bounds[3]),
    ]
    parcel.mapLayers = []
    parcel.mapSources.forEach((mapSource) => {
      mapSource.mapLayers.forEach((mapLayer) => {
        mapLayer.mapSource = mapSource

        mapSource.mapLayers.forEach((mapLayer) => {
          parcel.mapLayers.push(mapLayer)

          if (mapLayer.mapLayerDef.mapSourceDef.type === 'raster-background') {
            backgroundMapLayerDefsById[mapLayer.mapLayerDef.id] =
              mapLayer.mapLayerDef
          } else {
            productMapLayerDefsById[mapLayer.mapLayerDef.id] =
              mapLayer.mapLayerDef
          }
        })
      })
    })
  })

  return {
    style,
    parcels,
    bounds,
    productMapLayerDefsById,
    backgroundMapLayerDefsById,
  }
}

export type ParcelsAndBounds = Awaited<ReturnType<typeof fetchParcelsAndBounds>>
