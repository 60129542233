import { MapColorProfileData, MapLayerDefData } from '../data/types'
import {
  ProductSettings,
  VisualizationMode,
} from '../ProductSettings/store/types'
import { QuantileStops } from '../ProductStats/calculateStats/quantileStops'
import { ProductRange } from '../ProductStats/calculateStats/types'
import group from './transformations/group'
import resample from './transformations/resample'
import stretch from './transformations/stretch'
import threshold from './transformations/threshold'

export function applyColorProfileSettings(
  product: MapLayerDefData,
  colorProfile: MapColorProfileData,
  visualization: VisualizationMode,
  settings?: ProductSettings,
  range?: ProductRange[string],
  quantileStops?: QuantileStops[string]
): MapColorProfileData {
  if (colorProfile.type === 'class') {
    return colorProfile
  }

  let display = colorProfile.display
  let dataStops = colorProfile.dataStops

  if (settings) {
    const quantile =
      settings.groupBy === 'equal-areas' &&
      settings.numberOfGroups &&
      settings.numberOfGroups > 1 &&
      quantileStops

    if (visualization === 'relative') {
      if (quantile) {
        display = 'step'
        dataStops = group(dataStops, settings.numberOfGroups!, quantileStops)
      } else if (
        settings.groupBy === 'value-ranges' &&
        settings.numberOfGroups &&
        settings.numberOfGroups > 1
      ) {
        display = 'step'
        dataStops = resample(dataStops, settings.numberOfGroups, 'spacing')
      }
    } else if (visualization === 'threshold' && settings.threshold) {
      display = 'step'
      dataStops = threshold(dataStops, settings.threshold)
    }

    if (display === 'continuous') {
      const stopRange = [
        dataStops[0][0] as number,
        dataStops[dataStops.length - 1][0] as number,
      ] as [number, number]

      dataStops = stretch(dataStops, stopRange, true)

      dataStops = resample(dataStops, 10, 'linear')

      if (visualization !== 'relative') {
        dataStops = stretch(dataStops, stopRange)
      }
    }

    const absoluteRange = product.dataProperty?.range ?? [0, 1]

    const stretchRange =
      visualization === 'relative' && !quantile
        ? range || absoluteRange
        : absoluteRange

    if (!quantile && stretchRange && visualization !== 'absolute') {
      dataStops = stretch(dataStops, stretchRange)
    }
  }

  return { ...colorProfile, display, dataStops }
}
