import { default as swal } from 'sweetalert'

interface SuccessAlert {
  title: string
  message?: string
  tags?: Record<string, string>
  extras?: Record<string, any>
}

/**
 * It displays an success message on the screen.
 * For internal admin screens only.
 */
const successAlert = async ({ title, message }: SuccessAlert) => {
  return swal({
    title,
    text: message ?? '',
    icon: 'success',
  })
}

export default successAlert
